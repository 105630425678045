import React, { useMemo } from "react";
import { Route as ReactRouterRoute, Redirect } from "react-router-dom";

const RouteWithSubRoutes = (props) => {
	const { redirect, component: Component, path, noHasAuth, ...restProps } = props;

	const token = localStorage.getItem("token");
	const loginState = localStorage.getItem("login_state");

	const hasToken = useMemo(() => {
		if ((token === "" || token === null) && loginState !== "logging_in") {
			return false;
		}

		return true;
	}, [token, loginState]);

	if (redirect) {
		return <Redirect {...restProps} />;
	}

	if (!noHasAuth && !hasToken) {
		return <Redirect to={{ pathname: "/login", state: { from: props.location } }} />;
	}

	return (
		<ReactRouterRoute
			path={path}
			render={(routerProps) => {
				return <Component {...routerProps} {...restProps} />;
			}}
		/>
	);
};

export default RouteWithSubRoutes;
