import React from "react";
import styled from "styled-components";
import { EuiButton } from "@elastic/eui";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMicrophone, faMicrophoneSlash } from "@fortawesome/pro-solid-svg-icons";

/**
 * Component for Phone Button
 */
const PhoneButton = (props) => {
    const { isMuted, onChangeMute } = props;

    return (
        <ButtonWrapper>
            <EuiButton
                onClick={() => {
                    onChangeMute();
                }}
                className="button"
                color="text"
            >
                <FontAwesomeIcon
                    icon={isMuted ? faMicrophoneSlash : faMicrophone}
                    className="button-icon"
                />
            </EuiButton>
        </ButtonWrapper>
    );
};

export default PhoneButton;

const ButtonWrapper = styled.div`
    margin-left: auto;
    height: var(--sp-12);

    .button {
        padding: 0 var(--sp-2);
        height: 100%;
        width: 100%;
        min-width: unset;
        border: none;
        box-shadow: none !important;

        > span {
            padding: 0 !important;
        }
    }

    .button-icon {
        width: var(--sp-5_5);
    }
`;
