import React from "react";
import styled from "styled-components";
import { EuiAvatar } from "@elastic/eui";

import variables from "assets/css/variables.scss";

/**
 * Main Components
 */
const VerticalStep = (props) => {
	const { steps, ...restProps } = props;

	if (!steps || steps.length === 0) return <></>;

	return (
		<Wrapper {...restProps}>
			<div role="tablist" className="steps">
				{steps.map((step, index) => (
					<div className="step" key={index}>
						<div className="step-name">
							<EuiAvatar size="s" name={step.title} />
						</div>

						<div className="step-content">
							{step.date && <div className="step-date">{step.date}&nbsp;-&nbsp;</div>}
							{step.content}
						</div>
					</div>
				))}
			</div>
		</Wrapper>
	);
};

export default VerticalStep;

/**
 * Styled Components
 */
const Wrapper = styled.div`
	.steps {
		.step {
			display: flex;
			position: relative;
			margin-bottom: 1rem;

			.step-name {
				margin-right: 0.5rem;
			}
			.step-content {
				color: ${variables.baseColor};
				display: flex;
				margin-top: auto;
				margin-bottom: auto;
			}

			&:not(:last-child):after {
				content: "";
				position: absolute;
				width: 2px;
				height: 50%;
				background-color: ${variables.borderColor};
				left: 11px;
				top: 24px;
			}
		}
	}
`;
