import React, { useState } from "react";
import { cloneDeep, size } from "lodash";

import { faGlobe } from "@fortawesome/pro-light-svg-icons/faGlobe";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheckCircle } from "@fortawesome/pro-solid-svg-icons/faCheckCircle";

import { hasPrimaryChanged } from "helpers/utilities";
import { AsyncEditableContent } from "components/Common/asyncComponents";

/**
 * Constants
 */
const TRACKING_KEY = "person_external_link_id";
const DISPLAY_KEY = "url_value";

/**
 * Main Component for Person Website
 */
const PersonWebsite = (props) => {
	const { personId, data, syncData, loadData } = props;

	const [loading, setLoading] = useState(false);

	const onAddHandler = (editedData, setEditState, addedItemNewIndex, setAddedItemNewIndex) => {
		if (
			!editedData ||
			!setEditState ||
			addedItemNewIndex === undefined ||
			addedItemNewIndex === null ||
			setAddedItemNewIndex === undefined ||
			setAddedItemNewIndex === null
		)
			return;

		const newData = editedData.length > 0 ? cloneDeep(editedData) : [];
		const personNewId = "new__" + addedItemNewIndex;

		newData.push({
			person_external_link_id: personNewId,
			person_main_ref_id: personId,
			headline_value: "Personal Website",
			link_type: "personal-site",
			url_value: "",
		});

		setEditState(newData);
		setAddedItemNewIndex(addedItemNewIndex + 1);
	};

	const onSaveHandler = (editedData, data) => {
		/**
		 * Determine deleted external links.
		 */

		// Get all tracking ids for both.
		const dataExternalLinks = [];
		const editedDataExternalLinks = [];

		for (let i in data) {
			// dataExternalLinks.push(data[i]['person_external_link_id']);
			dataExternalLinks.push({
				person_external_link_id: data[i]["person_external_link_id"],
			});
		}

		for (let i in editedData) {
			editedDataExternalLinks.push(editedData[i]["person_external_link_id"]);
		}

		// Determine removed tracking ids from the "editedData" (we will delete these)
		const deletedItems = [];

		for (let i in data) {
			const personalExternalLinkId = data[i]["person_external_link_id"];

			if (!editedDataExternalLinks.includes(personalExternalLinkId)) {
				deletedItems.push({
					person_external_link_id: personalExternalLinkId,
				});
			}
		}

		/**
		 * Determine edited external links.
		 */

		// Do updates (Check similar ids and if they are different, then push them into update container)
		const editedItems = {};

		for (let i in data) {
			for (let j in editedData) {
				const {
					person_external_link_id: dataPersonExternalLinkId,
					url_value: personalSiteValue,
				} = data[i];

				const {
					person_external_link_id: editedPersonExternalLinkId,
					url_value: editedDataValue,
				} = editedData[j];

				const editedDataPersonExternalLinkId = editedData[j]["person_external_link_id"];

				if (dataPersonExternalLinkId === editedPersonExternalLinkId) {
					if (personalSiteValue !== editedDataValue) {
						let filterList = [
							"person_external_link_id",
							"person_main_ref_id",
							"headline_value",
							"link_type",
							"url_value",
						];
						let filteredEditedData = {
							...editedData[j],
						};

						for (let i in filteredEditedData) {
							if (!filterList.includes(i)) {
								delete filteredEditedData[i];
							}
						}

						editedItems[editedDataPersonExternalLinkId] = {
							...filteredEditedData,
							url_value: editedDataValue,
						};
					}
				}
			}
		}

		/**
		 * Do insert (pending).
		 */
		const addedItems = [];

		for (let i in editedData) {
			const personExternalLinkId = editedData[i][TRACKING_KEY];

			if (personExternalLinkId.toString().search(/new__/) !== -1) {
				const newValue = editedData[i][DISPLAY_KEY];

				if (newValue !== "") {
					let addedData = cloneDeep(editedData[i]);

					delete addedData.person_external_link_id;

					addedItems.push(addedData);
				}
			}
		}

		/**
		 * Perform delete, update, and add operations if any variables change.
		 */

		const isPrimaryUpdated = hasPrimaryChanged(editedData, data, TRACKING_KEY);

		if (
			size(deletedItems) !== 0 ||
			size(editedItems) !== 0 ||
			size(addedItems) !== 0 ||
			isPrimaryUpdated
		) {
			const addedItemsComposed = [];
			const editedItemsComposed = [];
			const deletedItemsComposed = [];

			const isPrimaryUpdatedData = [];

			for (let i in addedItems) {
				addedItemsComposed.push({
					// action: ADD_UPDATE_PERSON_EXTERNAL_LINK,
					args: addedItems[i],
				});
			}

			for (let i in editedItems) {
				editedItemsComposed.push({
					// action: ADD_UPDATE_PERSON_EXTERNAL_LINK,
					args: editedItems[i],
				});
			}

			for (let i in deletedItems) {
				deletedItemsComposed.push({
					// action: DELETE_PERSON_EXTERNAL_LINK,
					args: deletedItems[i],
				});
			}

			// Append an is primary command.
			if (isPrimaryUpdated) {
				isPrimaryUpdatedData.push({
					args: {
						person_main_ref_id: personId,
						person_table_type_c: "external_link",
						person_record_ref_id: isPrimaryUpdated,
					},
				});
			}

			// Update this sync data
			syncData(
				deletedItemsComposed,
				editedItemsComposed,
				addedItemsComposed,
				isPrimaryUpdatedData,
				setLoading,
				loadData
			);
		} else {
			alert("not changing anything");
		}
	};

	return (
		<AsyncEditableContent
			isLoading={loading}
			icon={faGlobe}
			hasPrimaryOption={true}
			data={data}
			placeholder={"Personal Website"}
			editTrackingKey={TRACKING_KEY}
			editDisplayKey={DISPLAY_KEY}
			onAddHandler={onAddHandler}
			onSaveHandler={onSaveHandler}
			render={(data) => {
				return data?.map((item, id) => {
					if (!item) return <></>;

					const { person_external_link_id, url_value, is_primary } = item;

					return (
						<div key={person_external_link_id ?? `person-external-link-id-${id}`}>
							<a href={url_value ?? ""}>{url_value ?? ""}</a>
							{is_primary === 1 ? (
								<span style={{ marginLeft: "5px", color: "#006BB4" }}>
									<FontAwesomeIcon icon={faCheckCircle} />
								</span>
							) : null}
						</div>
					);
				});
			}}
		/>
	);
};

export default PersonWebsite;
