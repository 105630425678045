import React, { useState, useMemo } from "react";
import { cloneDeep, size } from "lodash";

import { faEnvelope } from "@fortawesome/pro-light-svg-icons/faEnvelope";
import { faCheckCircle } from "@fortawesome/pro-solid-svg-icons/faCheckCircle";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { hasPrimaryChanged } from "helpers/utilities";
import { AsyncEditableContent } from "components/Common/asyncComponents";
import { workEmailIcon } from "components/Common/Icons";

/**
 * Constants
 */
const DISPLAY_KEY = "email";
const EMAIL_EDITOR_TYPES = {
	personal: {
		type: "personal",
		trackingKey: "person_email_id",
		placeholder: "Personal Email",
		icon: faEnvelope,
		emailType: "personal",
	},
	employment: {
		type: "employment",
		trackingKey: "person_email_id",
		placeholder: "Work Email",
		icon: faCheckCircle,
		customIcon: workEmailIcon,
		emailType: "work",
	},
};

/**
 * Main Component for Person Email
 */
const PersonEmail = (props) => {
	const { personId, data, syncData, loadData, type } = props;

	const [loading, setLoading] = useState(false);

	const emailEditorType = useMemo(() => {
		const _typeKey = Object.getOwnPropertyNames(EMAIL_EDITOR_TYPES).find(
			(key) => EMAIL_EDITOR_TYPES[key].type === type
		);

		return _typeKey ? EMAIL_EDITOR_TYPES[_typeKey] : undefined;
	}, [type]);

	const onAddHandler = (editedData, setEditState, addedItemNewIndex, setAddedItemNewIndex) => {
		if (
			!editedData ||
			!setEditState ||
			addedItemNewIndex === undefined ||
			addedItemNewIndex === null ||
			setAddedItemNewIndex === undefined ||
			setAddedItemNewIndex === null
		)
			return;

		const newData = editedData.length > 0 ? cloneDeep(editedData) : [];
		const newId = "new__" + addedItemNewIndex;

		newData.push({
			person_email_id: newId,
			email_type: emailEditorType.emailType,
			email: "",
			email_value: "",
			person_main_id: personId,
		});

		setEditState(newData);
		setAddedItemNewIndex(addedItemNewIndex + 1);
	};

	const onSaveHandler = (editedData, data) => {
		if (!editedData || !data) return;

		/**
		 * Determine deleted external links.
		 */

		// Get all tracking ids for both.
		const editedDataEmail = [];

		for (let i in editedData) {
			editedDataEmail.push(editedData[i][emailEditorType.trackingKey]);
		}

		// Determine removed tracking ids from the "editedData" (we will delete these)
		let deletedItems = [];

		for (let i in data) {
			const personalEmailId = data[i][emailEditorType.trackingKey];

			if (!editedDataEmail.includes(personalEmailId)) {
				// deletedItems.push(personalEmailId);
				deletedItems.push({
					person_email_id: personalEmailId,
				});
			}
		}

		/**
		 * Determine edited external links.
		 */

		// Do updates (Check similar ids and if they are different, then push them into update container)
		let editedItems = {};

		for (let i in data) {
			for (let j in editedData) {
				const { person_email_id: dataPersonEmailId, email } = data[i];

				const { person_email_id: editedPersonEmailId, email: editedDataValue } = editedData[j];

				const editedDataPersonEmailId = editedData[j][emailEditorType.trackingKey];

				if (dataPersonEmailId === editedPersonEmailId) {
					if (email !== editedDataValue) {
						let filterList = ["person_email_id", "person_main_id", "email_type", "email_value"];
						let filteredEditedData = {
							...editedData[j],
						};

						for (let i in filteredEditedData) {
							if (!filterList.includes(i)) {
								delete filteredEditedData[i];
							}
						}

						editedItems[editedDataPersonEmailId] = {
							...filteredEditedData,
							email_value: editedDataValue,
						};
					}
				}
			}
		}

		/**
		 * Do insert (pending).
		 */
		let addedItems = [];

		for (let i in editedData) {
			const personEmailId = editedData[i][emailEditorType.trackingKey];

			if (personEmailId.toString().search(/new__/) !== -1) {
				const newValue = editedData[i][DISPLAY_KEY];

				if (newValue !== "") {
					let addedData = cloneDeep(editedData[i]);

					addedItems.push(addedData);
				}
			}
		}

		const isPrimaryUpdated = hasPrimaryChanged(editedData, data, emailEditorType.trackingKey);

		/**
		 * Perform delete, update, and add operations if any variables change.
		 */
		if (
			size(deletedItems) !== 0 ||
			size(editedItems) !== 0 ||
			size(addedItems) !== 0 ||
			isPrimaryUpdated
		) {
			// Format json output for the "Add Personal Email" API.
			if (size(addedItems) !== 0) {
				for (let i in addedItems) {
					addedItems[i]["email_value"] = addedItems[i][DISPLAY_KEY];

					delete addedItems[i][emailEditorType.trackingKey];
					delete addedItems[i][DISPLAY_KEY];
				}
			}

			// Such cheesy prefix names "composed", it just means my original items are enhanced so it can be passed to
			// callAPICommand utility function which enforces structure
			const addedItemsComposed = [];
			const editedItemsComposed = [];
			const deletedItemsComposed = [];

			for (let i in addedItems) {
				addedItemsComposed.push({
					// action: ADD_UPDATE_PERSON_EMAIL,
					args: addedItems[i],
				});
			}

			for (let i in editedItems) {
				editedItemsComposed.push({
					// action: ADD_UPDATE_PERSON_EMAIL,
					args: editedItems[i],
				});
			}

			for (let i in deletedItems) {
				deletedItemsComposed.push({
					// action: DELETE_PERSON_EMAIL,
					args: deletedItems[i],
				});
			}

			const isPrimaryUpdatedData = [];

			// Append an is primary command.
			if (isPrimaryUpdated) {
				isPrimaryUpdatedData.push({
					args: {
						person_main_ref_id: personId,
						person_table_type_c: "email",
						person_record_ref_id: isPrimaryUpdated,
					},
				});
			}

			syncData(
				deletedItemsComposed,
				editedItemsComposed,
				addedItemsComposed,
				isPrimaryUpdatedData,
				setLoading,
				loadData
			);
		} else {
			alert("not changing anything");
		}
	};

	if (!emailEditorType) return <></>;

	return (
		<AsyncEditableContent
			isLoading={loading}
			icon={emailEditorType.icon}
			customIcon={emailEditorType.customIcon}
			data={data}
			hasPrimaryOption={true}
			placeholderer={emailEditorType.placeholder}
			editTrackingKey={emailEditorType.trackingKey}
			editDisplayKey={DISPLAY_KEY}
			onSaveHandler={onSaveHandler}
			onAddHandler={onAddHandler}
			render={(data) => {
				return data.map((email) => {
					if (!email) return <></>;

					const { person_email_id, email: personEmail, is_primary } = email;

					return (
						<div
							key={person_email_id}
							style={{
								display: "flex",
								alignItems: "center",
								alignContent: "center",
							}}
						>
							<a href={personEmail}>{personEmail}</a>
							{is_primary === 1 ? (
								<span style={{ marginLeft: "5px", color: "#006BB4" }}>
									<FontAwesomeIcon icon={faCheckCircle} />
								</span>
							) : null}
						</div>
					);
				});
			}}
		/>
	);
};

export default PersonEmail;
