import React, { useState } from "react";
import styled from "styled-components";

import EditableItem from "../EditableItem";
import EditEmailItem from "./EditEmailItem";
import StaticEmailItem from "./StaticEmailItem";

const EditableEmail = (props) => {
	const {
		personId = null,
		email,
		isPrimary,
		onSave,
		onDelete,
		onSendMessage,
		hasIcon = true,
	} = props;

	const [editState, setEditState] = useState(false);

	const handleSave = (data, primary) => {
		setEditState(false);
		onSave(data, primary);
	};

	const handleCancel = () => {
		setEditState(false);
	};

	const handleEdit = () => {
		setEditState(true);
	};

	const handleDelete = () => {
		onDelete(email?.id);
	};

	const handleValidate = () => { };

	const handleChangeStatus = (newStatus) => {
		const newEmail = {
			...email,
			global_email_details: {
				...email?.global_email_details,
				email_status_c: newStatus,
			},
		};

		handleSave(newEmail, isPrimary);
	};

	const handleSendMessage = () => {
		onSendMessage(email);
	};

	return (
		<Wrapper>
			<EditableItem
				editState={editState}
				renderStaticState={
					<StaticEmailItem
						personId={personId}
						email={email}
						isPrimary={isPrimary}
						hasIcon={hasIcon}
						onEdit={handleEdit}
						onDelete={handleDelete}
						onValidate={handleValidate}
						onChangeStatus={handleChangeStatus}
						onSendMessage={handleSendMessage}
					/>
				}
				renderEditState={
					<EditEmailItem
						email={email}
						emailPrimary={isPrimary}
						onSave={handleSave}
						onCancel={handleCancel}
					/>
				}
			/>
		</Wrapper>
	);
};

export default EditableEmail;

const Wrapper = styled.div``;
