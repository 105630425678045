import React, { useState, useEffect } from "react";
import styled from "styled-components";

import { EuiFieldText, EuiCheckbox, EuiFlexGroup, EuiFlexItem } from "@elastic/eui";

import { SaveCancelBtn } from "components/Common";
import EmailPhoneRangeSelectionItem from "../EmailPhoneRangeSelectionItem";
import { GLOBAL_EMAIL_TYPE_OPTIONS } from "components/global/constants";

/**
 * Main Components
 */
const EditEmailItem = (props) => {
	const { email, emailPrimary, onSave, onCancel, className } = props;

	// state variable
	const [primary, setPrimary] = useState(false);
	const [data, setData] = useState(null);
	const [isSelectedType, setIsSelectedType] = useState(false); // 0 --> Person	1 --> Work

	const emailDetail = data?.global_email_details;

	const handleEmailPrimary = (e) => {
		setPrimary(e.target.checked);
	};

	const handleChangeEmailType = (val) => {
		const _data = {
			...data,
			email_type_c: val,
		};

		setData(_data);
	};

	const handleEmailVal = (e) => {
		const _data = {
			...data,
			global_email_details: {
				email_status_c: data?.global_email_details?.email_status_c,
				email_status_date: data?.global_email_details?.email_status_date,
				email_value: e.target.value,
			},
		};

		setData(_data);
	};

	const handleSave = () => {
		onSave(data, primary);
	};

	const handleCancel = () => {
		onCancel();
	};

	const handleChangeEmailRange = (newRange) => {
		setIsSelectedType(newRange);
		const typeId = newRange ? 1 : 0;
		handleChangeEmailType(GLOBAL_EMAIL_TYPE_OPTIONS[typeId].value);
	};

	useEffect(() => {
		setPrimary(emailPrimary);
	}, [emailPrimary]);

	useEffect(() => {
		setData(email);
		if (email) {
			const emailTypeInfo = GLOBAL_EMAIL_TYPE_OPTIONS.find(
				(type) => type.value === email.email_type_c
			);
			setIsSelectedType(emailTypeInfo?.range || false);
		}
	}, [email]);

	return (
		<Wrapper className={className}>
			<EuiFlexGroup gutterSize="m" direction="column">
				<EuiFlexItem>
					<div className="setting__container">
						<EmailPhoneRangeSelectionItem
							range={isSelectedType}
							onChange={handleChangeEmailRange}
							style={{
								marginRight: 3,
							}}
						/>
						<EuiFieldText
							placeholder="Email"
							name="email"
							value={
								emailDetail?.email_value ? emailDetail?.email_value : ""
							}
							onChange={(e) => handleEmailVal(e)}
							className="email-input"
						/>
						<EuiCheckbox
							id={"extension-email"}
							checked={primary}
							onChange={(e) => handleEmailPrimary(e)}
							className="email-primary"
						/>
					</div>
				</EuiFlexItem>
				<EuiFlexItem>
					<SaveCancelBtn
						onChangeSave={handleSave}
						onChangeCancel={handleCancel}
					/>
				</EuiFlexItem>
			</EuiFlexGroup>
		</Wrapper>
	);
};

export default EditEmailItem;

/**
 * Styled Components
 */
const Wrapper = styled.div`
	display: flex;

	.email-input {
		border-radius: 3px;
	}

	.badge-button-container {
		border: var(--sp-1) solid;
		width: var(--sp-18);
		height: var(--sp-35);
		border-radius: var(--sp-2);
		border-color: #d3dae6;
		margin-top: var(--sp-6);
		margin-left: var(--sp-4);
	}

	.person-badge-button {
		margin-top: var(--sp-2);
		margin-bottom: var(--sp-3);
		width: var(--sp-16);
		align-items: center;
	}

	.work-badge-button {
		width: var(--sp-31);
		margin-bottom: var(--sp-6);
		margin-top: var(--sp-2);
		align-items: center;
	}

	.setting__container {
		display: flex;

		.euiFormControlLayout {
			height: fit-content;
			width: 70%;
		}
	}

	.email-input {
		border-radius: 0 var(--sp-2) var(--sp-2) 0;
	}

	.euiFormControlLayout {
		width: 82%;
		border-radius: var(--sp-2);
	}

	.euiSuperSelectControl {
		height: var(--sp-24);
		line-height: var(--sp-27);
		padding-right: var(--sp-24);
		padding-left: var(--sp-5);
	}

	.euiFormControlLayoutIcons--right {
		right: 6px;
	}

	.email-primary {
		margin-top: auto;
		margin-bottom: auto;
		margin-left: 0.3rem;
	}

	.euiCheckbox .euiCheckbox__input + .euiCheckbox__square {
		border-radius: 50%;
	}
`;

const euiButtonPerson = {
	background: "#D3DAE6",
	color: "black",
	border: "0",
	float: "left",
};

const euiButtonWork = {
	background: "#98A2B3",
	color: "white",
	border: "0",
	float: "left",
};
