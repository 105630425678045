import React, { Fragment, useState } from "react";
import styled from "styled-components";
import { EuiFlexGroup, EuiFlexItem, EuiButtonIcon, EuiFieldText } from "@elastic/eui";

/**
 * Main Components
 */
const AddData = (props) => {
	const { handleValue, ...restProps } = props;

	const [isAddData, setIsAddData] = useState(false);
	const [value, setValue] = useState("");

	const handleAddData = () => {
		setIsAddData(true);
	};

	const changeValue = (event) => {
		setValue(event.target.value);
	};

	const handlePushData = () => {
		if (value !== "") {
			handleValue(value);
		} else {
			setIsAddData(false);
		}
		setValue("");
	};

	const handleCancel = () => {
		setIsAddData(false);
	};

	return (
		<Wrapper {...restProps}>
			{isAddData ? (
				<Fragment>
					<EuiFlexItem grow={false}>
						<EuiFieldText placeholder="Add Data" value={value} onChange={changeValue} aria-label="add data" compressed />
					</EuiFlexItem>
					<EuiFlexItem className="ml-2" grow={false}>
						<EuiButtonIcon color="success" onClick={handlePushData} iconType="check" aria-label="Save" />
					</EuiFlexItem>
					<EuiFlexItem className="ml-2" grow={false}>
						<EuiButtonIcon color="danger" onClick={handleCancel} iconType="cross" aria-label="Trash" />
					</EuiFlexItem>
				</Fragment>
			) : (
				<EuiFlexItem grow={false}>
					<EuiButtonIcon color="primary" onClick={handleAddData} iconType="plusInCircleFilled" aria-label="Add" />
				</EuiFlexItem>
			)}
		</Wrapper>
	);
};

export default AddData;

/**
 * Styled Components
 */
const Wrapper = styled.div`
	display: flex;

	.euiFormControlLayout--compressed {
		height: 25px;
	}
`;
