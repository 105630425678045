import React, { Fragment } from "react";
import CustomQualifierIcon from "../CustomQualifierIcon/CustomQualifierIcon";

import { EuiBadge } from "@elastic/eui";

const CustomQualifierBadge = (props) => {
	const qualifier = props.data.name;
	//Text Property
	const text = props.data.text;
	const textName = text?.name;
	const textColor = text?.textColor;
	const textIsBold = text?.isBold;
	const textIsItalic = text?.isItalic;
	const textIsUnderlined = text?.isUnderlined;
	const textIsVisible = text?.isVisible;

	//Badge Property
	const badge = props.data.badge;
	const badgeColor = badge?.badgeColor;
	const badgeIsVisible = badge?.isVisible;

	//Icon Property
	const icon = props.data.icon;
	const iconColor = icon?.iconColor;
	const iconType = icon?.iconType;
	const iconIsVisible = icon?.isVisible;

	return (
		<Fragment>
			{badgeIsVisible ? (
				<EuiBadge color={badgeColor ? badgeColor : "primary"}>
					{iconIsVisible && (
						<CustomQualifierIcon
							data={{
								color: iconColor ? iconColor : "black",
								type: iconType ? iconType : "person",
							}}
						/>
					)}{" "}
					<span
						style={{
							color: textColor,
							fontWeight: textIsBold ? "bold" : "normal",
							fontStyle: textIsItalic ? "italic" : "normal",
							textDecoration: textIsUnderlined ? "underline" : "none",
							display: textIsVisible ? "inline" : "none",
						}}
					>
						{textName ? textName : qualifier}
					</span>
				</EuiBadge>
			) : (
				<EuiBadge color="#ffffff">
					{iconIsVisible && (
						<CustomQualifierIcon
							data={{
								color: iconColor ? iconColor : "black",
								type: iconType ? iconType : "person",
							}}
						/>
					)}{" "}
					<span
						style={{
							color: textColor,
							fontWeight: textIsBold ? "bold" : "normal",
							fontStyle: textIsItalic ? "italic" : "normal",
							textDecoration: textIsUnderlined ? "underline" : "none",
							display: textIsVisible ? "inline" : "none",
						}}
					>
						{textName ? textName : qualifier}
					</span>
				</EuiBadge>
			)}
		</Fragment>
	);
};

export default CustomQualifierBadge;
