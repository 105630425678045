import React, { useEffect, useState, useMemo } from "react";
import { useDispatch } from "react-redux";
import { get } from "lodash";

import { loadPersonAndRasData } from "helpers/utilities";
import PersonalInformationHeader from "../../Pages/Person/PersonEdit/PersonalInformation/PersonalInformationHeader/PersonalInformationHeader";
import { _getAccounts, _getGlobals } from "helpers/selectors";
import { getGCSValue } from "components/global/utils";

/**
 * Component for Person Information Detail and RAS
 */
const PersonalInformationDetailsAndRas = (props) => {
    const { id, details, isEditable, onSaveRAS, onSaveQualifier } = props;

    const dispatch = useDispatch();
    const [editable, setIsEditable] = useState(true);

    const globals = _getGlobals();
    const accounts = _getAccounts();

    const {
        firstName,
        nickName,
        middleName,
        lastName,
        linkedInAvatar,
        owners,
        assignees,
        accountsHash,
        personRasRestrictions,
    } = useMemo(() => {
        const accountsHash = {};
        const assignees = [];
        const owners = [];
        const rasRRecords = get(details, "associations.ras_assignment_main");

        if (accounts.length > 0) {
            for (let i in accounts) {
                const { id, name_first: nameFirst, name_last: nameLast } = accounts[i];

                accountsHash[id] = nameFirst + " " + nameLast;
            }
        }

        if (rasRRecords && globals?.length > 0) {
            const ownerAssignType = getGCSValue(
                globals,
                "ras_assignment_main",
                "assignment_type_c",
                "owner"
            );
            const assignedAssignType = getGCSValue(
                globals,
                "ras_assignment_main",
                "assignment_type_c",
                "assigned"
            );

            for (let i in rasRRecords) {
                const { assignment_type_c: assignmentTypeC } = rasRRecords[i];

                if (assignmentTypeC === ownerAssignType) {
                    owners.push(rasRRecords[i]);
                }

                if (assignmentTypeC === assignedAssignType) {
                    assignees.push(rasRRecords[i]);
                }
            }
        }

        return {
            firstName: get(details, "name_first", ""),
            nickName: get(details, "name_nick", ""),
            middleName: get(details, "name_middle", ""),
            lastName: get(details, "name_last", ""),
            linkedInAvatar:
                details?.person_external_link?.filter(
                    (link) => link?.global_link_type_c === 3
                )?.[0]?.global_url_details?.url_value ?? "",
            owners,
            assignees,
            accountsHash,
            personRasRestrictions: get(details, "associations.ras_restriction_main", []),
        };
    }, [details, accounts, globals]);

    useEffect(() => {
        if (!isEditable) {
            setIsEditable(false);
        }
    }, []);

    const loadData = () => {
        loadPersonAndRasData(dispatch, id);
    };

    return (
        <React.Fragment>
            <PersonalInformationHeader
                personId={id}
                details={details}
                onSaveRAS={onSaveRAS}
                onSaveQualifier={onSaveQualifier}
                personRasRestrictions={personRasRestrictions}
                accountsHash={accountsHash}
                accounts={accounts}
                assignees={assignees}
                owners={owners}
                linkedInAvatar={linkedInAvatar}
                firstName={firstName}
                nickName={nickName}
                middleName={middleName}
                lastName={lastName}
                editable={editable}
                globals={globals}
            />
        </React.Fragment>
    );
};

export default PersonalInformationDetailsAndRas;
