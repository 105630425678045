import API from "api";

import { campaign_item, campaign_main, campaign_execution } from "core/model";

import { makeSort, makeFieldContent, makeTableJoin } from "./commonService";

/** ---------------- Generic API ---------------------- */
const getCampaignList = (query) => {
	return API().Campaigns().getCampaignList(query);
};

const getCampaignMainList = ({
	searchTerm = "",
	pageNumber = 1,
	pageCount = 30,
	params = null,
}) => {
	const query = {
		page_number: pageNumber,
		page_count: pageCount,
		table_main: campaign_main._name,
		fields: [
			makeFieldContent(campaign_main, campaign_main.id),
			makeFieldContent(campaign_main, campaign_main.campaign_name),
			makeFieldContent(campaign_main, campaign_main.campaign_type_c),
			makeFieldContent(campaign_main, campaign_main.campaign_status_c),
			makeFieldContent(campaign_main, campaign_main.record_state_sc),
			makeFieldContent(campaign_main, campaign_main.campaign_details),
			makeFieldContent(campaign_main, campaign_main.workflow_main_ref_id),
		],
		sort: [makeSort(campaign_main, campaign_main.campaign_name)],
		search_fields: [
			{
				table: campaign_main._name,
				field: campaign_main.id,
			},
			{
				table: campaign_main._name,
				field: campaign_main.campaign_name,
			},
		],
		search_type: "term",
		search_term: searchTerm,
		...(params ? { ...params } : {}),
	};

	return API().GenericAPI().getTableData(query);
};

const getAllCampaignMainList = () => {
	const query = {
		page_number: 1,
		page_count: 1000,
		table_main: campaign_main._name,
		table_join: makeTableJoin([campaign_item, campaign_execution]),
		fields: [
			makeFieldContent(campaign_main, campaign_main.id),
			makeFieldContent(campaign_main, campaign_main.campaign_name),
			makeFieldContent(campaign_main, campaign_main.campaign_type_c),
			makeFieldContent(campaign_main, campaign_main.campaign_status_c),
			makeFieldContent(campaign_main, campaign_main.record_state_sc),
			makeFieldContent(campaign_main, campaign_main.campaign_details),
			makeFieldContent(campaign_main, campaign_main.workflow_main_ref_id),

			makeFieldContent(campaign_item, campaign_item.id),
			makeFieldContent(campaign_item, campaign_item.item_disposition_c),
			makeFieldContent(campaign_item, campaign_item.item_object_ref_id),
			makeFieldContent(campaign_item, campaign_item.item_object_subtype_sc),
			makeFieldContent(campaign_item, campaign_item.item_object_type_sc),
			makeFieldContent(campaign_item, campaign_item.item_priority),
			makeFieldContent(campaign_item, campaign_item.item_state_c),
			makeFieldContent(campaign_item, campaign_item.item_object_type_sc),

			makeFieldContent(campaign_execution, campaign_execution.id),
			makeFieldContent(campaign_execution, campaign_execution.execution_details),
			makeFieldContent(campaign_execution, campaign_execution.execution_result_c),
			makeFieldContent(campaign_execution, campaign_execution.execution_state_c),
		],
		search_fields: [
			{
				table: campaign_main._name,
				field: campaign_main.record_state_sc,
			},
		],
		search_type: "term",
		search_term: 1, // get_gss('RECORD_STATE', 'NORMAL')
	};

	return API().GenericAPI().getTableData(query);
};

const getCampaignDetail = ({ id = -1 }) => {
	const query = {
		object: "campaign",
		record_main_id: id,
	};

	return API().GenericAPI().getMainData(query);
};

const updateCampaign = (data) => {
	const request = {
		object: "campaign",
		data: data || {},
	};

	return API().GenericAPI().updateTableData(request);
};

const deleteCampaign = (data) => {
	const request = {
		object: "campaign",
		data: data || {},
	};

	return API().GenericAPI().deleteTableData(request);
};

const executeCampaign = (request) => {
	return API().Campaigns().executeCampaign(request);
};

const deleteCampaignItems = (params) => {
	const request = {
		campaign_id: params?.campaign_id || null,
		data: params?.data || [],
	};

	return API().Campaigns().deleteCampaignData(request);
};

const saveCampaignItems = (params) => {
	const request = {
		data: params || [],
	};

	return API().Campaigns().saveCampaignData(request);
};

const actionCopyCampaign = (params) => {
	const query = params || {};

	return API().Campaigns().actionCopyCampaign(query);
};

const actionWorkflowSkip = (params) => {
	const query = params || {};

	return API().Campaigns().actionWorkflowSkip(query);
};

/* ---------------------------------------------------------- */

export default {
	getCampaignList,
	getCampaignMainList,
	getCampaignDetail,
	updateCampaign,
	deleteCampaign,
	saveCampaignItems,
	deleteCampaignItems,
	executeCampaign,
	actionCopyCampaign,
	getAllCampaignMainList,
	actionWorkflowSkip,
};
