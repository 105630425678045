import React, { useEffect, useMemo, useState } from "react";
import styled from "styled-components";
import {
	EuiFlexGroup,
	EuiFlexItem,
	EuiButtonIcon,
	EuiButtonEmpty,
	EuiFieldText,
	EuiIcon,
} from "@elastic/eui";
import { get } from "lodash";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPen } from "@fortawesome/pro-light-svg-icons";

import * as MODEL from "core/model";
import { DatePickerItem } from "components/Common";
import AmsTaskStatusItem from "./AmsTaskStatusItem";
import AmsTaskPriorityItem from "./AmsTaskPriorityItem";

import { AmsReminderItem } from "../AmsReminderItem";
import AmsTaskTypeItem from "./AmsTaskTypeItem";
import { getLocaleDate, getUTCDate } from "components/global/utils";

/**
 * Component for Static Item for AMS Task List Item
 */
const AmsTaskListItem = (props) => {
	const { data, onChangeTask, onDeleteTask, onUpdateReminder, onDeleteReminder } =
		props;

	const [isEdit, setIsEdit] = useState(false);
	const [isHovered, setIsHovered] = useState(false);
	const [amsTaskName, setAmsTaskName] = useState("");

	const { taskId, taskStatus, taskPriority, taskType, taskDueDate, taskReminders } =
		useMemo(() => {
			const taskId = get(data, `${MODEL.ams_activity_task.id}`, null);
			const taskStatus = get(data, `${MODEL.ams_activity_task.task_status_c}`, 1);
			const taskPriority = get(
				data,
				`${MODEL.ams_activity_task.task_priority_c}`,
				1
			);
			const taskType = get(data, `${MODEL.ams_activity_task.task_type_c}`, 1);
			const taskDueDateUTC = get(
				data,
				`${MODEL.ams_activity_task.task_due_date}`,
				null
			);
			const taskDueDate = getLocaleDate(taskDueDateUTC);
			const taskReminders = get(data, `${MODEL.ams_activity_reminder._name}`, []);

			return {
				taskId,
				taskStatus,
				taskPriority,
				taskType,
				taskDueDate,
				taskReminders,
			};
		}, [data]);

	const handleMouseEnter = () => {
		if (!isEdit) {
			setIsHovered(true);
		} else {
			setIsHovered(false);
		}
	};

	const handleMouseLeave = () => {
		if (!isEdit) {
			setIsHovered(false);
		}
	};

	const handleClickEdit = () => {
		setIsEdit(true);
	};

	const handleSave = () => {
		setIsEdit(false);
		onChangeTask({
			...data,
			[MODEL.ams_activity_task.task_name]: amsTaskName,
		});
	};

	const handleCancel = () => {
		// Initialize task name
		const taskName = get(data, `${MODEL.ams_activity_task.task_name}`, "");
		setAmsTaskName(taskName);

		setIsEdit(false);
	};

	const handleClickDelete = () => {
		onDeleteTask(data);
	};

	const handleChangeStatus = (newStatus) => {
		onChangeTask({
			...data,
			[MODEL.ams_activity_task.task_status_c]: newStatus,
		});
	};

	const handleChangeType = (newType) => {
		onChangeTask({
			...data,
			[MODEL.ams_activity_task.task_type_c]: newType,
		});
	};

	const handleChangePriority = (newPriority) => {
		onChangeTask({
			...data,
			[MODEL.ams_activity_task.task_priority_c]: newPriority,
		});
	};

	const handleChangeDueDate = (newDate) => {
		onChangeTask({
			...data,
			[MODEL.ams_activity_task.task_due_date]: getUTCDate(newDate),
		});
	};

	const handleChangeTaskName = (e) => {
		setAmsTaskName(e.target.value);
	};

	useEffect(() => {
		const taskName = get(data, `${MODEL.ams_activity_task.task_name}`, "");

		setAmsTaskName(taskName);
	}, [data]);

	return (
		<Wrapper onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
			<EuiFlexGroup direction="column" gutterSize="xs">
				<EuiFlexItem>
					<EuiFlexGroup gutterSize="s">
						<EuiFlexItem grow={false}>
							<AmsTaskStatusItem
								status={taskStatus}
								onChangeStatus={handleChangeStatus}
							/>
						</EuiFlexItem>
						<EuiFlexItem grow={false}>
							<AmsTaskPriorityItem
								priority={taskPriority}
								onChangePriority={handleChangePriority}
							/>
						</EuiFlexItem>
						<EuiFlexItem>
							<EuiFlexGroup direction="column" gutterSize="s">
								<EuiFlexItem>
									<EuiFlexGroup gutterSize="m" alignItems="center">
										<EuiFlexItem grow={false}>
											<AmsTaskTypeItem
												type={taskType}
												onChange={handleChangeType}
											/>
										</EuiFlexItem>
										<EuiFlexItem className="task-name">
											{isEdit ? (
												<EuiFieldText
													value={amsTaskName}
													onChange={handleChangeTaskName}
													className="textfield-fit-conent"
												/>
											) : (
												<p>{amsTaskName}</p>
											)}
										</EuiFlexItem>
										{isEdit ? (
											<EuiFlexItem grow={false}>
												<EuiFlexGroup
													gutterSize="s"
													justifyContent="center"
												>
													<EuiFlexItem grow={false}>
														<EuiButtonIcon
															color="success"
															onClick={handleSave}
															iconType="check"
														/>
													</EuiFlexItem>
													<EuiFlexItem grow={false}>
														<EuiButtonIcon
															color="danger"
															onClick={handleCancel}
															iconType="cross"
														/>
													</EuiFlexItem>
												</EuiFlexGroup>
											</EuiFlexItem>
										) : isHovered ? (
											<EuiFlexItem grow={false}>
												<EuiFlexGroup
													gutterSize="s"
													alignItems="center"
												>
													<EuiFlexItem>
														<EuiButtonEmpty
															className="button-fit-content"
															onClick={handleClickEdit}
														>
															<FontAwesomeIcon
																icon={faPen}
																color="var(--eui-primary-color)"
																fontSize={16}
															/>
														</EuiButtonEmpty>
													</EuiFlexItem>
													<EuiFlexItem>
														<EuiButtonEmpty
															className="button-fit-content"
															onClick={handleClickDelete}
														>
															<EuiIcon
																type="trash"
																color="danger"
																size="m"
															/>
														</EuiButtonEmpty>
													</EuiFlexItem>
												</EuiFlexGroup>
											</EuiFlexItem>
										) : null}
									</EuiFlexGroup>
								</EuiFlexItem>
								<EuiFlexItem>
									<EuiFlexGroup gutterSize="xs" alignItems="center">
										<EuiFlexItem grow={false}>
											<p className="type-x-small semibold">
												Due:&nbsp;
											</p>
										</EuiFlexItem>
										<EuiFlexItem grow={false}>
											<span
												className="type-small-medium"
												style={{ color: "var(--link-color)" }}
											>
												<DatePickerItem
													value={taskDueDate}
													typeDate="MMM D, YYYY @ h:mma"
													onChange={handleChangeDueDate}
												/>
											</span>
										</EuiFlexItem>
										<EuiFlexItem grow={false}>
											<AmsReminderItem
												data={taskReminders}
												taskId={taskId}
												onUpdateReminder={onUpdateReminder}
												onDeleteReminder={onDeleteReminder}
											/>
										</EuiFlexItem>
									</EuiFlexGroup>
								</EuiFlexItem>
							</EuiFlexGroup>
						</EuiFlexItem>
					</EuiFlexGroup>
				</EuiFlexItem>
			</EuiFlexGroup>
		</Wrapper>
	);
};

export default AmsTaskListItem;

/**
 * Styled Components
 */
const Wrapper = styled.div`
	display: flex;

	.badge-item {
		padding: 0 var(--sp-2);
	}

	.setting-icon-button {
		.euiButtonContent.euiButtonEmpty__content {
			padding: 0 !important;
		}
	}

	.icon-button-img {
		align-items: center;
		display: flex;
	}

	.task-name {
		.euiFormControlLayout {
			height: fit-content;
		}
	}

	.add-owner {
		font-size: var(--sp-4) !important;
	}
`;
