import React, { useState, useMemo, useEffect } from "react";
import styled from "styled-components";
import { EuiText, EuiFlexGroup, EuiFlexItem } from "@elastic/eui";
import { cloneDeep, concat, get, remove, set } from "lodash";

import { SVGIcon } from "components/Common";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCommentsAlt, faStickyNote } from "@fortawesome/pro-duotone-svg-icons";

import * as MODEL from "core/model";
import { EditableSelect, TextAreaEditor } from "components/Common";
import { getGCSValue, getGSSValue } from "components/global/utils";

import {
	useAccount,
	useGlobal,
	useCredential,
	useMacro,
	useNotification,
	useQualifier,
	useCms,
} from "core/useHooks";

import {
	SendButton,
	SaveButton,
	ReplyButton,
	LoggedButton,
	OutboundButton,
	PersonPhoneBadgeItem,
	AddPersonPhoneItem,
} from "../components";
import { addressBookDuoNoteIcon, amsRecruitIcon } from "components/Common/Icons/Icons";

import { MESSAGE_STATES, VALIDATE_MESSAGE } from "./constants";
import RelatedItem from "./RelatedItem";
import { MESSAGE_MANUAL_QUALIFIER_NAMES, PERSON_SELECT_TYPES } from "../constants";
import { MessageAms } from "../../MessageAms";

import useTranlation from "./useTranlation";

/**
 * Component for sending chat
 */
const MessageSenderChat = (props) => {
	const {
		onSendSmsMessage,
		savePersonPhone,
		deletePerson,
		savePersonEmail,
		person,
		data,
		getCmsDraftList,
		getCmsMainList,
	} = props;
	const { translateTexts } = useTranlation(person);
	const { macroOptions } = useMacro();
	const { account, accounts } = useAccount();
	const { globals, gssList } = useGlobal();
	const { credentialsByAccount } = useCredential();
	const { setNotificationMessage } = useNotification();
	const { qualifiers, saveQualifiers } = useQualifier();
	const { callUpdateCmsCommunicaionDraft } = useCms();

	const [toPersons, setToPersons] = useState([]);
	const [selectedCredential, setSelectedCredential] = useState(null);
	const [relateds, setRelateds] = useState([]);
	const [message, setMessage] = useState("");
	const [plainMessage, setPlainMessage] = useState("");
	const [messageStatus, setMessageStatus] = useState(MESSAGE_STATES.send.value);
	const [isRecruiting, setRecruiting] = useState(true);
	const [commDirect, setCommDirect] = useState(true); // false: 0 - incoming, true: 1 - outgoing,
	const [validationMessage, setValidationMessage] = useState("");

	const { credentialOptions } = useMemo(() => {
		const linkedInTypeC = getGCSValue(
			globals,
			"credential_main",
			"credential_type_c",
			"sms-plivo"
		);
		const authenticatedStatusC = getGCSValue(
			globals,
			MODEL.credential_main._name,
			MODEL.credential_main.credential_status_c,
			"authenticated"
		);
		const credentialOptions = [];
		if (credentialsByAccount?.length && globals?.length) {
			credentialsByAccount.forEach((credential) => {
				const credentialName =
					get(
						credential,
						`${MODEL.credential_main._name}.${MODEL.credential_main.credential_name}`
					) || "-";
				const credentialId =
					get(
						credential,
						`${MODEL.credential_main._name}.${MODEL.credential_main.id}`
					) || "-";
				const credentialTypeC = get(
					credential,
					`${MODEL.credential_main._name}.${MODEL.credential_main.credential_type_c}`
				);

				const credentialStatusC = get(
					credential,
					`${MODEL.credential_main._name}.${MODEL.credential_main.credential_status_c}`
				);

				if (
					credentialTypeC === linkedInTypeC //&&
					// credentialStatusC === authenticatedStatusC
				) {
					credentialOptions.push({
						id: credentialId,
						value: credentialId,
						label: credentialName,
						info: credential,
					});
				}
			});
		}

		setSelectedCredential(credentialOptions?.[0]);

		return { credentialOptions };
	}, [credentialsByAccount, globals, setSelectedCredential]);

	const handleChangeCredential = (val) => {
		const credential = credentialOptions?.find((item) => item.value === val);
		setSelectedCredential(credential);
	};

	const getAddPersons = (oldPersons, newPersons) => {
		const addedPersons = [];
		newPersons.forEach((person) => {
			const existedPerson = oldPersons.find((oldPerson) => {
				let isRight = false;
				if (oldPerson.type === person.type) {
					if (person.type === PERSON_SELECT_TYPES.direct.value) {
						isRight = oldPerson.value === person.value;
					} else {
						isRight = oldPerson.info.id === person.info.id;
					}
				}

				return isRight;
			});
			if (!existedPerson) {
				addedPersons.push(person);
			}
		});
		const updatedPersons = concat(oldPersons, addedPersons);
		return updatedPersons;
	};

	const handleSaveToUser = (presons) => {
		const newPersons = getAddPersons(toPersons, presons);
		setToPersons(newPersons);
	};

	const handleDeleteToPerson = (person) => {
		const newToPersons = cloneDeep(toPersons);
		remove(newToPersons, (toPerson) => {
			return toPerson.id === person.id;
		});

		setToPersons(newToPersons);
	};

	const handleChangeToUsers = (idx, newPerson) => {
		const newToPersons = cloneDeep(toPersons);
		set(newToPersons, `[${idx}]`, newPerson);

		setToPersons(newToPersons);
	};

	const changeEditVal = (value, text) => {
		setMessage(value);
		setPlainMessage(text);
	};

	// Functions related to Message
	const handleChangeMessage = (state) => {
		setMessageStatus(state);
	};

	// Send Message
	const getPhoneFromPerson = (person) => {
		const selectedPhoneIndex = person.selected || 0;
		const phone = get(
			person,
			`info.${MODEL.person_phone._name}[${selectedPhoneIndex}].${MODEL.global_phone_details._name}`
		);
		const phoneNumber =
			(phone?.[MODEL.global_phone_details.phone_country_code] || "") +
			(phone?.[MODEL.global_phone_details.phone_number] || "");
		return phoneNumber;
	};

	const getPhoneFromAccount = (person) => {
		const selectedPhoneIndex = person.selected || 0;
		const phone = get(
			person,
			`info.${MODEL.global_phone_details._name}[${selectedPhoneIndex}]`
		);
		return phone;
	};

	const getReceiver = () => {
		let toRecipients = "";
		toPersons.forEach((person) => {
			let phone = "";
			if (person.type === PERSON_SELECT_TYPES.direct.value) {
				phone = person.value;
			} else if (person.type === PERSON_SELECT_TYPES.person.value) {
				phone = getPhoneFromPerson(person);
			} else {
				phone = getPhoneFromAccount(person);
			}
			let phoneNumber = phone;
			if (!phoneNumber.includes("+")) {
				phoneNumber = "+" + phoneNumber;
			}

			if (phone) {
				if (toRecipients === "") {
					toRecipients = phoneNumber;
				} else {
					toRecipients = toRecipients + "<" + phoneNumber;
				}
			}
		});
		return toRecipients;
	};

	const getSendInfo = (isReply) => {
		let senderPhoneInfo = get(
			selectedCredential,
			`info.credential_main.global_phone_details`
		);
		let sender = `${senderPhoneInfo?.phone_country_code}${senderPhoneInfo?.phone_number}`;
		if (sender && !sender?.includes("+")) {
			sender = "+" + sender;
		}
		const receiver = getReceiver();

		return {
			sender,
			receiver,
		};
	};

	const handleSendMessage = (isReply) => {
		if (message === "") {
			setValidationMessage(VALIDATE_MESSAGE.message.value);
			return;
		}

		const { sender, receiver } = getSendInfo(isReply);

		if (sender == null) {
			setValidationMessage(VALIDATE_MESSAGE.sender.value);
			return;
		} else if (receiver == null) {
			setValidationMessage(VALIDATE_MESSAGE.receiver.value);
			return;
		}
		setValidationMessage("");

		const texts = [plainMessage];
		translateTexts(texts).then((res) => {
			const translatedtexts = res.map((translatedtext, index) =>
				translatedtext["error"] ? texts[index] : translatedtext["results"]
			);
			onSendSmsMessage(
				sender,
				receiver,
				translatedtexts[0],
				() => getCmsMainList(),
				() => {}
			);
		});
	};

	const handleSaveMessage = (isReply) => {
		if (message === "") {
			setValidationMessage(VALIDATE_MESSAGE.message.value);
			return;
		}

		const { sender, receiver } = getSendInfo(isReply);

		if (sender == null) {
			setValidationMessage(VALIDATE_MESSAGE.sender.value);
			return;
		} else if (receiver == null) {
			setValidationMessage(VALIDATE_MESSAGE.receiver.value);
			return;
		} else if (account == null) {
			setValidationMessage(VALIDATE_MESSAGE.login.value);
			return;
		}
		setValidationMessage("");

		if (account?.id) {
			const params = {
				[MODEL.cms_communication_draft.account_main_ref_id]: account?.id,
				[MODEL.cms_communication_draft.credential_main_ref_id]: null,
				[MODEL.cms_communication_draft.comm_platform_c]: getGCSValue(
					globals,
					"cms_communication_main",
					"comm_platform_c",
					"manual"
				),
				[MODEL.cms_communication_draft.conversation_type_c]: getGCSValue(
					globals,
					"cms_conversation_main",
					"conversation_type_c",
					"sms"
				),
				[MODEL.cms_communication_draft.member_object_type_sc]: getGSSValue(
					gssList,
					"OBJECT_BASE",
					"PERSON"
				),
				[MODEL.cms_communication_draft.member_object_subtype_sc]: getGSSValue(
					gssList,
					"OBJECT_PERSON",
					"MAIN"
				),
				[MODEL.cms_communication_draft.member_object_record_id]: person?.id,
				[MODEL.cms_communication_draft.comm_details]: {
					details: {
						sender,
						receiver,
						plainMessage,
					},
					comm_direction: commDirect ? 1 : 0,
					comm_type: isRecruiting,
				},
			};

			const cmsQualifier = qualifiers.find(
				(qualifier) =>
					qualifier[MODEL.qualifier_main.qualifier_name] ===
					(isRecruiting
						? MESSAGE_MANUAL_QUALIFIER_NAMES.recruiting
						: MESSAGE_MANUAL_QUALIFIER_NAMES.sales)
			);

			new Promise((resolve, reject) => {
				if (cmsQualifier) {
					callUpdateCmsCommunicaionDraft(
						[params],
						(res) => {
							const cmsDraftId =
								res?.response?.procedure_results
									?.cms_communication_draft?.[0]?.id;
							if (cmsDraftId) {
								saveQualifiers(
									cmsDraftId,
									"cms-communication-draft",
									[cmsQualifier.id],
									(res) => {
										resolve({ error: false, data: res });
									},
									(err) => {
										reject({
											error: true,
											data: err,
											message:
												"Saved a new cms_communication_draft record, but failed to assign qualifiers to the record.",
										});
									}
								);
							} else {
								reject({
									error: true,
									data: res,
									message:
										"Saved a new cms_communication_draft record, but we are not getting its id from response.",
								});
							}
						},
						(err) => {
							reject({
								error: true,
								data: err,
								message: "Failed to save cms_communication_draft record.",
							});
						}
					);
				} else {
					reject({
						error: true,
						message:
							"Did not find a proper qualifier. Please check qualifier list.",
					});
				}
			})
				.then((res) => {
					handleChangeMessage(MESSAGE_STATES.logged.value);
					getCmsDraftList();
					setNotificationMessage({
						type: "normal",
						message: "Chat message Saved",
						status: "success",
					});
				})
				.catch((err) => {
					setNotificationMessage({
						type: "normal",
						message:
							err.message + ` ${err.data ? JSON.stringify(err.data) : ""}`,
						status: "error",
					});
				});
		}
		// handleChangeMessage(MESSAGE_STATES.logged.value);
	};

	const handleReplyMessage = () => {
		handleChangeMessage(MESSAGE_STATES.send.value);
	};

	const handleLoggedMessage = () => {};

	const handleOutboundMessage = () => {
		setCommDirect(true);
	};

	const handleInboundMessage = () => {
		setCommDirect(false);
	};

	// Functions related to Relation
	const addRelateds = (value) => {
		let _relateds = [...relateds];
		if (!relateds.includes(value)) {
			_relateds.push(value);
		}
		setRelateds(_relateds);
	};

	useEffect(() => {
		const commDetails = data?.comm_details?.details;
		if (commDetails) {
			const initMessage = commDetails.plainMessage;

			if (initMessage) {
				setMessage(initMessage);
			}
		}

		const personPrimary = get(person, `${MODEL.person_primary_setting._name}`);
		const personPhones = get(person, `${MODEL.person_phone._name}`);
		let selected = 0;
		if (personPhones?.length) {
			// find primary phone
			if (globals?.length && gssList?.length && personPrimary?.length) {
				const objectValue = getGSSValue(gssList, "OBJECT_BASE", "PERSON");
				const tableValue = getGSSValue(gssList, "OBJECT_PERSON", "PHONE");
				const primaryLevelC = getGCSValue(
					globals,
					"global",
					"primary_level_c",
					"primary"
				);
				const primaryInfo = personPrimary.find(
					(primary) =>
						primary?.ref_object_base_sc === objectValue &&
						primary?.ref_object_table_sc === tableValue &&
						primary?.primary_level_gc === primaryLevelC
				);

				if (primaryInfo) {
					selected = personPhones.findIndex(
						(phone) => phone?.id === primaryInfo.ref_object_record_id
					);
					selected = selected > -1 ? selected : 0;
				}
			}
		}

		const initData = {
			label: person?.name_first + " " + person?.name_last,
			id: person?.id,
			value: person?.id,
			info: person,
			type: PERSON_SELECT_TYPES.person.value,
			selected: selected,
		};
		setToPersons([initData]);
	}, [person, globals, gssList, data]);

	// Buttons
	const renderButtons = () => {
		switch (messageStatus) {
			case MESSAGE_STATES.send.value:
				return (
					<div className="cms-buttons-wrapper">
						<div className="left-side">
							<SVGIcon
								icon={
									isRecruiting ? amsRecruitIcon : addressBookDuoNoteIcon
								}
								onClick={() =>
									isRecruiting
										? setRecruiting(false)
										: setRecruiting(true)
								}
								className="recruiting-sales-button"
							/>
							<FontAwesomeIcon
								icon={faCommentsAlt}
								color="var(--eui-darkest-shade-color)"
								className="message-status-icon"
								onClick={() =>
									setMessageStatus(MESSAGE_STATES.logNotes.value)
								}
							/>
						</div>
						<div className="right-side">
							<SendButton onSend={() => handleSendMessage()} />
						</div>
					</div>
				);

			case MESSAGE_STATES.logged.value:
				return (
					<div className="cms-buttons-wrapper">
						<div className="left-side">
							<FontAwesomeIcon
								icon={faStickyNote}
								color="var(--eui-text-disabled-color)"
								className="message-status-icon"
								onClick={() =>
									setMessageStatus(MESSAGE_STATES.send.value)
								}
							/>
							<LoggedButton onLogged={() => handleLoggedMessage()} />
						</div>
						<div className="right-side">
							<ReplyButton onReply={() => handleReplyMessage()} />
						</div>
					</div>
				);

			case MESSAGE_STATES.logNotes.value:
				return (
					<div className="cms-buttons-wrapper">
						<div className="left-side">
							<SVGIcon
								icon={
									isRecruiting ? amsRecruitIcon : addressBookDuoNoteIcon
								}
								onClick={() =>
									isRecruiting
										? setRecruiting(false)
										: setRecruiting(true)
								}
								className="recruiting-sales-button"
							/>
							<FontAwesomeIcon
								icon={faStickyNote}
								color="var(--eui-primary-color)"
								className="message-status-icon"
								onClick={() =>
									setMessageStatus(MESSAGE_STATES.send.value)
								}
							/>
							<OutboundButton
								onOutbound={() => handleOutboundMessage()}
								onInbound={() => handleInboundMessage()}
							/>
						</div>
						<div className="right-side">
							<SaveButton onSave={() => handleSaveMessage()} />
						</div>
					</div>
				);
			default:
				return null;
		}
	};

	return (
		<Wrapper>
			<EuiFlexGroup direction="column" gutterSize="xs">
				<EuiFlexItem>
					<div className="d-flex mb-1">
						<EuiText className="label my-auto mr-4">From:</EuiText>
						<EditableSelect
							options={credentialOptions}
							value={selectedCredential?.value}
							onChange={handleChangeCredential}
							hasUnderline={true}
							panelWidth={200}
						/>
						{/* <EuiCheckbox
                            id={"sendor-phone"}
                            className="my-auto mr-4"
                            checked={true}
                            onChange={() => {}}
                        /> */}
					</div>
					<div className="d-flex mb-1 mt-2">
						<EuiText className="label mr-4">To:</EuiText>
						<div className="icon-badges">
							<EuiFlexGroup gutterSize="s" alignItems="center" wrap={true}>
								{toPersons &&
									toPersons.length > 0 &&
									toPersons.map((item, index) => {
										return (
											<EuiFlexItem
												key={"to-person-item-" + index}
												grow={false}
											>
												<PersonPhoneBadgeItem
													detail={item}
													onRemove={() =>
														handleDeleteToPerson(item)
													}
													onChangeItem={(phoneIndex) => {
														handleChangeToUsers(index, {
															...item,
															selected: phoneIndex,
														});
													}}
												/>
											</EuiFlexItem>
										);
									})}
								<EuiFlexItem grow={false}>
									<AddPersonPhoneItem
										onSave={handleSaveToUser}
										accounts={accounts}
										savePersonPhone={savePersonPhone}
										deletePerson={deletePerson}
										savePersonEmail={savePersonEmail}
									/>
								</EuiFlexItem>
							</EuiFlexGroup>
						</div>
					</div>
				</EuiFlexItem>
				<EuiFlexItem>
					<EuiFlexGroup gutterSize="s" direction="column">
						<EuiFlexItem>
							<EuiFlexGroup gutterSize="s" direction="column">
								<EuiFlexItem>
									<TextAreaEditor
										id="message-phone-sender"
										editorVal={message}
										autocompletOptions={macroOptions}
										changeEditVal={changeEditVal}
									/>
								</EuiFlexItem>
								<EuiFlexItem>{renderButtons()}</EuiFlexItem>
							</EuiFlexGroup>
						</EuiFlexItem>
						<EuiFlexItem>
							<RelatedItem
								message={validationMessage}
								items={relateds}
								onAddItem={addRelateds}
							/>
						</EuiFlexItem>
					</EuiFlexGroup>
				</EuiFlexItem>
				<EuiFlexItem>
					<MessageAms person={person} subject={""} message={plainMessage} />
				</EuiFlexItem>
			</EuiFlexGroup>
		</Wrapper>
	);
};

export default MessageSenderChat;

/**
 * Styled Components
 */
const Wrapper = styled.div`
	.label {
		color: var(--light-color);
		font-size: 0.75rem;
		font-weight: 600;
		margin-right: 0.3rem;
		margin-top: 0.25rem;
	}

	.icon-badges {
		flex-wrap: wrap;
		display: flex;
	}

	.euiCheckbox .euiCheckbox__input + .euiCheckbox__square {
		border-radius: 50%;
	}

	.primary-color {
		color: var(--link-color);
	}
`;
