/**
 * Company
 */
export const FETCH_COMPANY = "@@company/FETCH_COMPANY";
export const SET_COMPANY = "@@company/SET_COMPANY";
export const SET_COMPANY_PAGINATION = "@@company/SET_COMPANY_PAGINATION";
export const SET_COMPANY_DETAILS = "@@company/SET_COMPANY_DETAILS";
export const SET_COMPANY_LOADING = "@@company/SET_COMPANY_LOADING";
export const SET_COMPANY_SEARCH_VALUE = "@@company/SET_COMPANY_SEARCH_VALUE";
export const ADD_UPDATE_COMPANY_MAIN = "@@company/ADD_UPDATE_COMPANY_MAIN";
// export const ADD_UPDATE_COMPANY_EXTERNAL_LINK = '@@company/ADD_UPDATE_COMPANY_EXTERNAL_LINK'
// export const DELETE_COMPANY_EXTERNAL_LINK = '@@company/DELETE_COMPANY_EXTERNAL_LINK';

/* ------------------------ NEW ----------------------------- */
// Company List with total property
export const GET_COMPANY_LIST_NEW = "@@company/GET_COMPANY_LIST_NEW";
export const SET_COMPANY_LIST_NEW = "@@company/SET_COMPANY_LIST_NEW";
export const REQUEST_COMPANY_LIST_NEW = "@@company/REQUEST_COMPANY_LIST_NEW";
export const RECEIVED_COMPANY_LIST_NEW = "@@company/RECEIVED_COMPANY_LIST_NEW";
export const SET_COMPANY_LIST_PARAMS_NEW = "@@company/SET_COMPANY_LIST_PARAMS_NEW";

// Company Main Table
export const GET_COMPANY_MAINLIST_NEW = "@@company/GET_COMPANY_MAINLIST_NEW";
export const SET_COMPANY_MAINLIST_NEW = "@@company/SET_COMPANY_MAINLIST_NEW";
export const REQUEST_COMPANY_MAINLIST_NEW = "@@company/REQUEST_COMPANY_MAINLIST_NEW";
export const RECEIVED_COMPANY_MAINLIST_NEW = "@@company/RECEIVED_COMPANY_MAINLIST_NEW";

// Company Detail
export const GET_COMPANY_DETAIL_NEW = "@@company/GET_COMPANY_DETAIL_NEW";
export const SET_COMPANY_DETAIL_NEW = "@@company/SET_COMPANY_DETAIL_NEW";
export const REQUEST_COMPANY_JOBLIST_NEW = "@@company/REQUEST_COMPANY_JOBLIST_NEW";
export const RECEIVED_COMPANY_JOBLIST_NEW = "@@company/RECEIVED_COMPANY_JOBLIST_NEW";
export const SET_COMPANY_JOBLIST_NEW = "@@company/SET_COMPANY_JOBLIST_NEW";
export const SET_COMPANY_JOBLIST_PARAMS_NEW = "@@company/SET_COMPANY_JOBLIST_PARAMS_NEW";
/* ---------------------------------------------------------- */
