import {
    AsyncLogin,
    AsyncDashboard,
    AsyncSIT,
    AsyncPerson,
    AsyncJob,
    AsyncCampaign,
    AsyncCompany,
    AsyncSystemConfiguration,
    AsyncSourcing,
    AsyncMessaging,
    AsyncAccountEdit,
    AsyncMyProfile,
} from "./async-components";
import { routeTabInfo } from "./routeInfo";

const getRoutes = () => {
    const routes = [
        {
            path: routeTabInfo.root.path,
            redirect: true,
            exact: true,
            to: routeTabInfo.login.path,
        },
        {
            path: routeTabInfo.login.path,
            exact: true,
            component: AsyncLogin,
            noHasAuth: true,
        },
        {
            path: routeTabInfo.dashboard.path,
            exact: true,
            component: AsyncDashboard,
        },
        {
            path: routeTabInfo.sit.path,
            exact: true,
            component: AsyncSIT,
        },
        {
            path: `${routeTabInfo.person.path}/:${routeTabInfo.person.subRoute.label}?`,
            component: AsyncPerson,
        },
        {
            path: `${routeTabInfo.company.path}/:${routeTabInfo.company.subRoute.label}?`,
            component: AsyncCompany,
        },
        {
            path: `${routeTabInfo.job.path}/:${routeTabInfo.job.subRoute.label}?`,
            component: AsyncJob,
        },
        {
            path: `${routeTabInfo.campaign.path}/:${routeTabInfo.campaign.subRoute.label}?`,
            component: AsyncCampaign,
        },
        {
            path: `${routeTabInfo.systemConfiguration.path}/:${routeTabInfo.systemConfiguration.subRoute.label}?`,
            component: AsyncSystemConfiguration,
        },
        {
            path: routeTabInfo.sourcing.path,
            component: AsyncSourcing,
        },
        {
            path: routeTabInfo.messaging.path,
            component: AsyncMessaging,
        },
        {
            path: routeTabInfo.account.path,
            component: AsyncAccountEdit,
        },
        {
            path: routeTabInfo.profile.path,
            component: AsyncMyProfile,
        },
    ];

    return routes;
};

export default getRoutes;
