import React from "react";
import { EuiFlexItem, EuiFlexGroup, EuiIcon } from "@elastic/eui";
import styled from "styled-components";

import * as MODEL from "core/model";

import MessageAmsReminderListItem from "./MessageAmsReminderListItem";

/**
 * Component for displaying Reminder list of AMS
 */
const MessageAmsReminderList = (props) => {
	const { data, onUpdateReminder, onDeleteReminder } = props;

	const handleAddNewReminder = () => {
		const newReminder = {
			id: null,
			[MODEL.ams_activity_reminder.reminder_type_c]: 1, // browser
			[MODEL.ams_activity_reminder.reminder_triggered]: false, // false
			[MODEL.ams_activity_reminder.reminder_result_c]: 1, // pending
			[MODEL.ams_activity_reminder.reminder_time_value]: 15, // 0
			[MODEL.ams_activity_reminder.reminder_time_frame_c]: 1, // minutes
			[MODEL.ams_activity_reminder.reminder_time_type_c]: 1, // before
			checked: false,
		};

		onUpdateReminder(null, newReminder);
	};

	const handleUpdateReminder = (id, reminder) => {
		onUpdateReminder(id, reminder);
	};

	const handleDeleteReminder = (id) => {
		onDeleteReminder(id);
	};

	return (
		<Wrapper>
			<EuiFlexGroup direction="column" gutterSize="xs">
				{data?.map((reminder, id) => {
					return (
						<EuiFlexItem key={`ams-reminder-list-item-${id}`}>
							<MessageAmsReminderListItem
								data={reminder}
								onUpdateReminder={(reminder) =>
									handleUpdateReminder(id, reminder)
								}
								onDeleteReminder={() => handleDeleteReminder(id)}
							/>
						</EuiFlexItem>
					);
				})}
				<EuiFlexItem grow={false}>
					<div
						className="add-reminder_container"
						onClick={handleAddNewReminder}
					>
						<p className="add-reminder-icon">
							<EuiIcon type="plusInCircle"></EuiIcon>
						</p>
						<p className="type-small-default add-reminder-label">Reminder</p>
					</div>
				</EuiFlexItem>
			</EuiFlexGroup>
		</Wrapper>
	);
};

export default MessageAmsReminderList;

const Wrapper = styled.div`
	display: flex;
	width: 100%;
	flex-direction: column;

	.add-reminder_container {
		display: flex;
		width: fit-content;
		color: var(--eui-link-color);
		cursor: pointer;
		align-items: center;

		.add-reminder-icon {
			svg {
				margin-top: calc(-1 * var(--sp-2));
			}
		}

		.add-reminder-label {
			margin-left: var(--sp-4);
		}
	}
`;
