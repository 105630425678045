import React from "react";
import { ThemeProvider } from "styled-components";
import { createBrowserHistory } from "history";
import { Router } from "react-router-dom";
import { Provider } from "react-redux";
import { PersistGate } from "redux-persist/integration/react";

import App from "./App";
import configureStore from "store";
import EmpowerTheme from "global/rootTheme";

const { store, persistor } = configureStore();
window.__store = store;

const Root = () => {
    const history = createBrowserHistory();

    return (
        <Provider store={store}>
            <PersistGate loading={null} persistor={persistor}>
                <ThemeProvider theme={EmpowerTheme}>
                    <Router history={history}>
                        <App />
                    </Router>
                </ThemeProvider>
            </PersistGate>
        </Provider>
    );
};

export default Root;
