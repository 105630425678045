import * as actionTypes from "./actionTypes";

/**
 * Functions
 */
function setCompanyDetails(state, data) {
	return {
		...state,
		company: {
			...state.company,
			details: data,
		},
	};
}

function setCompanies(state, payload) {
	return {
		...state,
		companies: [...payload],
	};
}

function setCompaniesWithPagination(state, payload) {
	return {
		...state,
		companies: payload.data,
		pagination: {
			...state.pagination,
			totalCount: payload.totalCount,
			currentPage: payload.page,
			currentPerPage: payload.perPage,
		},
	};
}

function setLoadingCompany(state, payload) {
	return {
		...state,
		loading: payload,
	};
}

function setCompanySearchValue(state, payload) {
	return {
		...state,
		companySearchValue: payload,
	};
}

/* ------------------------- NEW ------------------------ */
// Company Main Table
const requestCompanyMainList_NEW = (state) => {
	return {
		...state,
		companyMainList_NEW: {
			...state.companyMainList_NEW,
			isRequesting: true,
			isReceived: false,
		},
	};
};

const receivedCompanyMainList_NEW = (state) => {
	return {
		...state,
		companyMainList_NEW: {
			...state.companyMainList_NEW,
			isRequesting: false,
			isReceived: true,
		},
	};
};

function setCompanyMainList_NEW(state, payload) {
	return {
		...state,
		companyMainList_NEW: {
			...state.companyMainList_NEW,
			...payload,
			isRequesting: false,
			isReceived: true,
		},
	};
}

// Company List
function setCompanList_NEW(state, payload) {
	return {
		...state,
		companyList_NEW: {
			...state.companyList_NEW,
			...(payload || {}),
			isRequesting: false,
			isReceived: true,
		},
	};
}

const requestCompanyList_NEW = (state) => {
	return {
		...state,
		companyList_NEW: {
			...state.companyList_NEW,
			isRequesting: true,
			isReceived: false,
		},
	};
};

const receivedCompanyList_NEW = (state) => {
	return {
		...state,
		companyList_NEW: {
			...state.companyList_NEW,
			isRequesting: false,
			isReceived: true,
		},
	};
};

const setCompanyListParams_NEW = (state, payload) => {
	return {
		...state,
		companyList_NEW: {
			...state.companyList_NEW,
			...(payload || {}),
		},
	};
};

// Company Detail
const setCompanyDetail_NEW = (state, payload) => {
	return {
		...state,
		company_NEW: {
			...state.company_NEW,
			...payload,
		},
	};
};

const requestCompanyJobList_NEW = (state) => {
	return {
		...state,
		company_NEW: {
			...state.company_NEW,
			jobs: {
				...state.company_NEW.jobs,
				isRequesting: true,
				isReceived: false,
			},
		},
	};
};

const receivedCompanyJobList_NEW = (state) => {
	return {
		...state,
		company_NEW: {
			...state.company_NEW,
			jobs: {
				...state.company_NEW.jobs,
				isRequesting: false,
				isReceived: true,
			},
		},
	};
};

const setCompanyJobList_NEW = (state, payload) => {
	return {
		...state,
		company_NEW: {
			...state.company_NEW,
			jobs: {
				...state.company_NEW.jobs,
				...(payload || {}),
				isRequesting: false,
				isReceived: true,
			},
		},
	};
};
/* ------------------------------------------------------ */

/**
 * Initial State
 */
const initialState = {
	company: {
		details: {},
	},
	companies: [],
	pagination: {
		totalCount: 0,
		entriesPerPage: [30, 50, 100, 200],
		currentPage: 1,
		currentPerPage: 30,
	},
	loading: false,
	companySearchValue: "",

	/* ------------------------- NEW ------------------------- */
	companyMainList_NEW: {
		totalCount: 0,
		pageCount: 20,
		pageNumber: 1,
		data: [],
		searchTerm: "",
		isRequesting: false,
		isReceived: false,
	},

	companyList_NEW: {
		totalCount: 0,
		per_page: 30,
		page: 1,
		data: [],
		searchTerm: "",
		isRequesting: false,
		isReceived: false,
	},

	company_NEW: {
		jobs: {
			totalCount: 0,
			per_page: 5,
			page: 1,
			data: [],
			isRequesting: false,
			isReceived: false,
		},
	},
	/* ------------------------------------------------------- */
};

const Reducer = (state = initialState, action) => {
	switch (action.type) {
		case actionTypes.SET_COMPANY:
			return setCompanies(state, action.payload);

		case actionTypes.SET_COMPANY_PAGINATION:
			return setCompaniesWithPagination(state, action.payload);

		case actionTypes.SET_COMPANY_DETAILS:
			return setCompanyDetails(state, action.payload);

		case actionTypes.SET_COMPANY_LOADING:
			return setLoadingCompany(state, action.payload);

		case actionTypes.SET_COMPANY_SEARCH_VALUE:
			return setCompanySearchValue(state, action.payload);

		/* ------------------------- NEW ------------------------- */
		// Company List
		case actionTypes.SET_COMPANY_LIST_NEW:
			return setCompanList_NEW(state, action.payload);

		case actionTypes.REQUEST_COMPANY_LIST_NEW:
			return requestCompanyList_NEW(state);

		case actionTypes.RECEIVED_COMPANY_LIST_NEW:
			return receivedCompanyList_NEW(state);

		case actionTypes.SET_COMPANY_LIST_PARAMS_NEW:
			return setCompanyListParams_NEW(state, action.payload);

		// Company Main Table
		case actionTypes.SET_COMPANY_MAINLIST_NEW:
			return setCompanyMainList_NEW(state, action.payload);

		case actionTypes.REQUEST_COMPANY_MAINLIST_NEW:
			return requestCompanyMainList_NEW(state);

		case actionTypes.RECEIVED_COMPANY_MAINLIST_NEW:
			return receivedCompanyMainList_NEW(state);

		// Detail
		case actionTypes.SET_COMPANY_DETAIL_NEW:
			return setCompanyDetail_NEW(state, action.payload);

		case actionTypes.REQUEST_COMPANY_JOBLIST_NEW:
			return requestCompanyJobList_NEW(state);

		case actionTypes.RECEIVED_COMPANY_JOBLIST_NEW:
			return receivedCompanyJobList_NEW(state);

		case actionTypes.SET_COMPANY_JOBLIST_NEW:
			return setCompanyJobList_NEW(state, action.payload);
		/* ------------------------------------------------------- */

		default:
			return state;
	}
};

export default Reducer;
