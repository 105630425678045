import React from "react";
import { EuiText } from "@elastic/eui";
/**
 * Component for Location
 */
const Location = (props) => {
	const {
		location: { city, state },
	} = props;

	return (
		<div className="table-column-location">
			<EuiText size="s">{!city ? "City N/A" : city},</EuiText>
			<EuiText size="s">{!state ? "State N/A" : state}</EuiText>
		</div>
	);
};

export default Location;
