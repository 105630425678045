import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { EuiFlexGroup, EuiFlexItem, EuiFieldText, EuiCheckbox } from "@elastic/eui";

import { SaveCancelBtn } from "components/Common";

/**
 * Main Components
 */
const EditAddress = (props) => {
	const {
		idx,
		address,
		addressPrimary,
		isPrimaryCheck = true,
		onChangeAddress,
		onChangeAddressPrimary,
		hasSaveButton = false,
		onSave,
		onCancel,
		...restProps
	} = props;

	// state variable
	const [primary, setPrimary] = useState(false);

	const addressDetail = address?.global_address_details;

	const handleChange = (event) => {
		// Tracking ID.
		const key = event.target.name;
		const value = event.target.value;

		let _address = address;
		_address.global_address_details = {
			...address?.global_address_details,
			[key]: value,
		};

		onChangeAddress(_address);
	};

	const handleAddressPrimary = (e) => {
		onChangeAddressPrimary(idx, e.target.checked);
		setPrimary(e.target.checked);
	};

	const handleSave = (e) => {
		onSave(e);
	};

	const handleCancel = () => {
		onCancel();
	};

	useEffect(() => {
		setPrimary(addressPrimary);
	}, [addressPrimary]);

	return (
		<Wrapper {...restProps}>
			<EuiFlexGroup gutterSize="s" direction="column">
				<EuiFlexItem>
					<EuiFieldText
						placeholder={"Address Line 1"}
						name="address_line_1"
						value={addressDetail?.address_line_1 || ""}
						onChange={handleChange}
					/>
				</EuiFlexItem>
				<EuiFlexItem>
					<EuiFieldText
						placeholder={"Address Line 2"}
						name="address_line_2"
						value={addressDetail?.address_line_2 || ""}
						onChange={handleChange}
					/>
				</EuiFlexItem>
				<EuiFlexItem>
					<EuiFieldText
						placeholder={"City"}
						name="address_city"
						value={addressDetail?.address_city || ""}
						onChange={handleChange}
					/>
				</EuiFlexItem>
				<EuiFlexItem>
					<EuiFlexGroup gutterSize="none">
						<EuiFlexItem grow={7}>
							<EuiFieldText
								placeholder={"State"}
								name="address_state"
								value={addressDetail?.address_state || ""}
								onChange={handleChange}
							/>
						</EuiFlexItem>
						<EuiFlexItem grow={3}>
							<EuiFieldText
								placeholder={"Zip Code"}
								name="address_zip"
								value={addressDetail?.address_zip || ""}
								onChange={handleChange}
							/>
						</EuiFlexItem>
					</EuiFlexGroup>
				</EuiFlexItem>
				<EuiFlexItem className="d-flex">
					<EuiFieldText
						placeholder={"Country"}
						name="address_country"
						value={addressDetail?.address_country || ""}
						onChange={handleChange}
					/>
					{isPrimaryCheck && (
						<EuiCheckbox
							id={"company-address-" + idx}
							checked={primary}
							onChange={(e) => handleAddressPrimary(e)}
							className="address-valid ml-2"
						/>
					)}
				</EuiFlexItem>
				{hasSaveButton ? (
					<EuiFlexItem>
						<SaveCancelBtn
							onChangeSave={handleSave}
							onChangeCancel={handleCancel}
						/>
					</EuiFlexItem>
				) : null}
			</EuiFlexGroup>
		</Wrapper>
	);
};

export default EditAddress;

/**
 * Styled Components
 */
const Wrapper = styled.div`
	.address-valid {
		margin-top: auto;
		margin-bottom: auto;
		margin-left: auto;
	}

	.euiCheckbox .euiCheckbox__input + .euiCheckbox__square {
		border-radius: 50%;
	}

	.euiFormControlLayout {
		height: fit-content;
	}
`;
