import { get } from "lodash";
import React, { useMemo, useState } from "react";
import styled from "styled-components";
import {
	EuiFlexGroup,
	EuiFlexItem,
	EuiButton,
	EuiIcon,
	EuiButtonEmpty,
} from "@elastic/eui";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMinus, faMousePointer } from "@fortawesome/pro-light-svg-icons";

import {
	CMS_COMM_DIRECTION_TYPE_LIST,
	CMS_COMM_PLATFORM_TYPE_LIST,
	CMS_CONVERSATION_TYPE_LIST,
} from "../constants";
import { menuDotsHorizontal } from "components/Common/Icons";
import { getDate } from "helpers/utilities";
import CmsDraftEditItemSettingPopover from "./CmsDraftEditItemSettingPopover";
import * as MODEL from "core/model";

/**
 * Component for Header of CMS Draft Item with editable feature
 */
const CmsDraftEditItemHeader = (props) => {
	const { data, onChangeEdit, onDeleteCms } = props;

	const [isOpenPopover, setIsOpenPopover] = useState(false);

	const {
		relatedRecords,
		cmsDirection,
		cmsPlatform,
		cmsCreatedDate,
		cmsConversationType,
	} = useMemo(() => {
		const relatedRecords = get(data, "related", []) || [];

		// Get comm_details
		const commDetails = data?.[MODEL.cms_communication_draft.comm_details];

		// Get direction information
		const cmsDirectionValue = commDetails?.comm_direction;
		const cmsDirection =
			Object.values(CMS_COMM_DIRECTION_TYPE_LIST).find(
				(item) => item.value === cmsDirectionValue
			) || CMS_COMM_DIRECTION_TYPE_LIST.incoming;

		// Get CMS platform
		const cmsPlatformValue = get(data, "comm_platform_c");
		const cmsPlatform =
			Object.values(CMS_COMM_PLATFORM_TYPE_LIST).find(
				(item) => item.value === cmsPlatformValue
			) || CMS_COMM_PLATFORM_TYPE_LIST.manual;

		// Get CMS conversation type
		const cmsConversationTypeValue =
			data?.[MODEL.cms_communication_draft.conversation_type_c];
		const cmsConversationType =
			Object.values(CMS_CONVERSATION_TYPE_LIST).find(
				(item) => item.value === cmsConversationTypeValue
			) || CMS_CONVERSATION_TYPE_LIST.email;

		// Created Data
		const cmsCreatedDate = get(data, "date_created");

		return {
			relatedRecords,
			cmsDirection,
			cmsPlatform,
			cmsCreatedDate,
			cmsConversationType,
		};
	}, [data]);

	const handleClickEdit = () => {
		onChangeEdit();
	};
	const handleOpenPopover = () => {
		setIsOpenPopover(!isOpenPopover);
	};

	const handleClosePopover = () => {
		setIsOpenPopover(false);
	};

	const handleClickEditCms = () => {};

	const handleClickDelete = () => {
		onDeleteCms();
	};

	return (
		<Wrapper>
			<EuiFlexGroup gutterSize="l" alignItems="center">
				<EuiFlexItem>
					<EuiFlexGroup gutterSize="xs" alignItems="center">
						<EuiFlexItem grow={false}>
							<div className="cms-platform-icon">
								{cmsConversationType.editIcon ?? cmsConversationType.icon}
							</div>
						</EuiFlexItem>
						<EuiFlexItem className="direction-icon" grow={false}>
							{cmsDirection.icon}
						</EuiFlexItem>
						<EuiFlexItem grow={false}>
							<span className="cms-delivered-status type-small-definication-term">
								Manual
							</span>
						</EuiFlexItem>
						<EuiFlexItem grow={false}>
							<span className="cms-date type-small-definication-term">
								{cmsCreatedDate
									? getDate(cmsCreatedDate, "MM/DD/YYYY @ h:mm a")
									: "-"}
							</span>
						</EuiFlexItem>
						<EuiFlexItem grow={false}>
							<EuiIcon type="eye" color="var(--link-color)" />
						</EuiFlexItem>
						<EuiFlexItem grow={false}>
							<FontAwesomeIcon
								icon={faMousePointer}
								size="lg"
								color="var(--link-color)"
							/>
						</EuiFlexItem>
					</EuiFlexGroup>
				</EuiFlexItem>
				<EuiFlexItem grow={false}>
					<EuiFlexGroup gutterSize="none" alignItems="center">
						<EuiFlexItem>
							<CmsDraftEditItemSettingPopover
								button={
									<EuiButtonEmpty
										size="xs"
										className="setting-icon-button"
										onClick={handleOpenPopover}
									>
										<div className="icon-button-img">
											{menuDotsHorizontal("var(--primary-color)")}
										</div>
									</EuiButtonEmpty>
								}
								isOpenPopover={isOpenPopover}
								onClosePopover={handleClosePopover}
								onEdit={handleClickEditCms}
								onDelete={handleClickDelete}
							/>
						</EuiFlexItem>
						<EuiFlexItem>
							<EuiButton
								color="text"
								className="button-fit-content close-button"
								onClick={handleClickEdit}
							>
								<FontAwesomeIcon icon={faMinus} size="1x" />
							</EuiButton>
						</EuiFlexItem>
					</EuiFlexGroup>
				</EuiFlexItem>
			</EuiFlexGroup>
		</Wrapper>
	);
};

export default CmsDraftEditItemHeader;

/**
 * Styled Components
 */
const Wrapper = styled.div`
	width: 100%;
	background: var(--eui-lightest-shade-color);
	padding: var(--sp-3_5) var(--sp-8);

	.close-button {
		background: var(--eui-lightest-shade-color);
		border-color: var(--transparent);

		min-width: 0 !important;
		height: auto !important;
		line-height: 1 !important;
		margin-left: var(--sp-5);

		.euiButtonContent.euiButton__content {
			padding: var(--sp-2) !important;
		}

		.euiButton__text {
			font-size: var(--sp-6);
		}

		svg {
			width: var(--sp-6);
			height: var(--sp-6);
		}
	}

	.more-button {
		.euiButtonEmpty__text {
			margin-top: -7px;
		}
	}

	.cms-platform-icon {
		display: flex;
		justify-content: center;
		align-items: center;
		width: var(--sp-12);
		height: var(--sp-12);
		background: var(--eui-secondary-color);
		border-radius: var(--sp-6);
		box-sizing: border-box;
		color: var(--white-color);

		margin-right: var(--sp-1);
	}

	.direction-icon {
		svg {
			width: var(--sp-8);
			height: auto;
		}
	}

	.cms-delivered-status {
		color: var(--eui-secondary-color);
	}

	.cms-date {
		color: var(--light-color);
	}
`;
