import React from "react";

import {
	faUsers,
	faClipboardListCheck,
	faCommentsAlt,
	faStopwatch,
	faMinusCircle,
	faLongArrowDown,
	faLongArrowUp,
	faClipboardCheck,
	faPhoneAlt,
	faEnvelope,
	faCommentAltLines,
} from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircle, faStopCircle, faCheck } from "@fortawesome/pro-regular-svg-icons";
import { cancelIcon } from "components/Common/Icons";
import { faLinkedinIn } from "@fortawesome/free-brands-svg-icons/faLinkedinIn";

export const AMS_NOTE_TYPE_LIST = {
	generic: {
		key: "generic",
		value: 1,
		label: "Generic",
		icon: faCommentsAlt,
	},
	meeting: {
		key: "meeting",
		value: 4,
		label: "Meeting",
		icon: faUsers,
	},
	logistics: {
		key: "logistics",
		value: 2,
		label: "Logistics",
		icon: faClipboardListCheck,
	},
};

export const AMS_ACTIVITY_TYPE_LIST = {
	outreach: {
		key: "outreach",
		value: 1,
		label: "Outreach",
		icon: faCommentsAlt,
	},
	meeting: {
		key: "meeting",
		value: 4,
		label: "Meeting",
		icon: faUsers,
	},
	logistics: {
		key: "logistics",
		value: 2,
		label: "Notes/Admin",
		icon: faClipboardListCheck,
	},
	planning: {
		key: "planning",
		value: 3,
		label: "Planning",
		icon: faStopwatch,
	},
};

export const AMS_STATUS_LIST = {
	pending: {
		key: "pending",
		value: 1,
		label: "Not Started",
		className: "pending",
		color: "var(--eui-darkest-shade-color)",
		icon: <FontAwesomeIcon icon={faCircle} size="lg" />,
	},
	inProgress: {
		key: "inProgress",
		value: 2,
		label: "In Progress",
		className: "in-progress",
		color: "var(--success-color)",
		icon: <FontAwesomeIcon icon={faStopCircle} size="lg" />,
	},
	complete: {
		key: "complete",
		value: 3,
		label: "Complete",
		className: "complete",
		color: "var(--success-color)",
		icon: <FontAwesomeIcon icon={faCheck} size="lg" />,
	},
	cancelled: {
		key: "cancelled",
		value: 4,
		label: "Cancelled",
		className: "cancelled",
		color: "var(--eui-danger-color)",
		icon: cancelIcon(),
	},
};

export const AMS_TASK_STATUS_LIST = {
	pending: {
		key: "pending",
		value: 1,
		label: "Pending",
		className: "pending",
		color: "var(--eui-darkest-shade-color)",
		icon: <FontAwesomeIcon icon={faCircle} size="lg" />,
	},
	deferred: {
		key: "deferred",
		value: 2,
		label: "Deferred",
		className: "pending",
		color: "var(--eui-warning-color)",
		icon: <FontAwesomeIcon icon={faMinusCircle} size="lg" />,
	},
	inProgress: {
		key: "inProgress",
		value: 3,
		label: "In Progress",
		className: "in-progress",
		color: "var(--success-color)",
		icon: <FontAwesomeIcon icon={faStopCircle} size="lg" />,
	},
	complete: {
		key: "complete",
		value: 4,
		label: "Complete",
		className: "complete",
		color: "var(--success-color)",
		icon: <FontAwesomeIcon icon={faCheck} size="lg" />,
	},
	cancelled: {
		key: "cancelled",
		value: 5,
		label: "Cancelled",
		className: "cancelled",
		color: "var(--eui-danger-color)",
		icon: cancelIcon(),
	},
};

export const AMS_TASK_PRIORITY_LIST = {
	high: {
		key: "high",
		value: 2,
		label: "High",
		className: "high",
		color: "var(--eui-danger-color)",
		icon: <FontAwesomeIcon icon={faLongArrowUp} size="lg" />,
	},
	normal: {
		key: "normal",
		value: 1,
		label: "Normal",
		className: "normal",
		color: "var(--eui-warning-color)",
		icon: <FontAwesomeIcon icon={faLongArrowUp} size="lg" />,
	},
	low: {
		key: "low",
		value: 0,
		label: "Low",
		className: "low",
		color: "var(--eui-secondary-color)",
		icon: <FontAwesomeIcon icon={faLongArrowDown} size="lg" />,
	},
};

export const AMS_TASK_TYPE_LIST = {
	taskItem: {
		key: "task-item",
		value: 1,
		label: "Task Item",
		className: "task-item",
		icon: <FontAwesomeIcon icon={faClipboardCheck} size="lg" />,
	},
	phone: {
		key: "phone",
		value: 2,
		label: "Phone",
		className: "phone",
		icon: <FontAwesomeIcon icon={faPhoneAlt} size="lg" />,
	},
	email: {
		key: "email",
		value: 3,
		label: "Email",
		className: "email",
		icon: <FontAwesomeIcon icon={faEnvelope} size="lg" />,
	},
	sms: {
		key: "sms",
		value: 4,
		label: "Text",
		className: "text-sms",
		icon: <FontAwesomeIcon icon={faCommentAltLines} size="lg" />,
	},
	linkedIn: {
		key: "linkedIn",
		value: 5,
		label: "LinkedIn",
		className: "linkedIn",
		icon: <FontAwesomeIcon icon={faLinkedinIn} size="lg" />,
	},
	meeting: {
		key: "meeting",
		value: 6,
		label: "Meeting",
		className: "meeting",
		icon: <FontAwesomeIcon icon={faUsers} size="lg" />,
	},
};
