import React, { useState, useMemo } from "react";
import styled from "styled-components";
import { EuiFlexGroup, EuiFlexItem } from "@elastic/eui";

import { CompensationItem } from "components/Common";
import { arrowRightLight, arrowLeftLight } from "components/Common/Icons";

/**
 * Component for Compensation List
 */
const CompensationPlans = (props) => {
	const { compensations } = props;

	const [selectedIndex, setSelectedIndex] = useState(0);

	const { enableNext, enablePrev } = useMemo(() => {
		let _enableNext = false;
		let _enablePrev = false;

		if (compensations && compensations.length > 0) {
			const len = compensations.length;

			if (selectedIndex === 0) {
				_enablePrev = false;
			} else {
				_enablePrev = true;
			}

			if (selectedIndex === len - 1) {
				_enableNext = false;
			} else {
				_enableNext = true;
			}
		}

		return {
			enableNext: _enableNext,
			enablePrev: _enablePrev,
		};
	}, [compensations, selectedIndex]);

	const handlePrevButton = () => {
		if (!compensations) return;

		const _index = selectedIndex;

		if (_index - 1 >= 0) {
			setSelectedIndex(_index - 1);
		}
	};

	const handleNextButton = () => {
		if (!compensations) return;

		const _index = selectedIndex;

		if (_index + 1 < compensations?.length) {
			setSelectedIndex(_index + 1);
		}
	};

	if (!compensations || compensations.length === 0) return <></>;

	return (
		<Wrapper>
			<EuiFlexGroup
				gutterSize="s"
				alignItems="center"
				justifyContent="spaceBetween"
			>
				<EuiFlexItem
					grow={false}
					className={`arrow-button ${!enablePrev ? "disable" : ""}`}
					onClick={handlePrevButton}
				>
					{arrowLeftLight()}
				</EuiFlexItem>
				<EuiFlexItem>
					{compensations?.map((compensation, id) => {
						const key = `compensation-compensations-item-${id}`;
						if (id === selectedIndex) {
							return (
								<CompensationItem
									key={key}
									compensation={compensation}
									itemId={id}
								/>
							);
						}

						return <div key={key}></div>;
					})}
				</EuiFlexItem>
				<EuiFlexItem
					grow={false}
					className={`arrow-button ${!enableNext ? "disable" : ""}`}
					onClick={handleNextButton}
				>
					{arrowRightLight()}
				</EuiFlexItem>
			</EuiFlexGroup>
		</Wrapper>
	);
};

export default CompensationPlans;

const Wrapper = styled.div`
	display: flex;
	align-compensations: center;
	justify-content: center;
	margin-top: 10px;
	margin-bottom: 7px;
	width: 100%;

	& .arrow-button {
		cursor: pointer;

		&.disable {
			cursor: default;

			svg {
				opacity: 0.5;
			}
		}
	}
`;
