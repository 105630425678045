import React from "react";
import styled from "styled-components";

import { AccordionWithHeader } from "components/Common";
import ActivityHeader from "./AmsListViewHeader";
import HistoryActivityContent from "./HistoryActivityContent";

/**
 * Main Components
 */
const HistoryActivities = (props) => {
    const { isLoading, ...restProps } = props;

    return (
        <Wrapper>
            <AccordionWithHeader
                {...restProps}
                headerItems={<ActivityHeader title="Activity & Communication History" />}
                isLoading={isLoading}
                isOpen={isLoading ? "closed" : "open"}
            >
                <HistoryActivityContent />
            </AccordionWithHeader>
        </Wrapper>
    );
};

export default HistoryActivities;

const Wrapper = styled.div``;
