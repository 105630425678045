import React, { useEffect, useState } from "react";
import { EuiFieldText, EuiSpacer } from "@elastic/eui";
import { get } from "lodash";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faStar } from "@fortawesome/pro-light-svg-icons";

import { Popover, SaveCancelBtn } from "components/Common";

/**
 * Component for Company Name Popover
 */
const EditableCompanyNamePopover = (props) => {
	const { name, onSave } = props;

	const [detail, setDetail] = useState({
		name: "",
		alias: "",
	});

	const handleSave = (closePopover) => {
		const successCallback = () => {
			closePopover();
		};

		onSave(detail, successCallback);
	};

	const handleCancel = (closePopover) => {
		closePopover();
	};

	const handleChange = (e) => {
		const key = e.target.name;
		const value = e.target.value;
		const newDetail = {
			...detail,
			[key]: value,
		};

		setDetail(newDetail);
	};

	useEffect(() => {
		const companyName = get(name, "name");
		const companyAlias = get(name, "alias");

		const newDetail = {
			name: companyName,
			alias: companyAlias,
		};

		setDetail(newDetail);
	}, [name]);

	return (
		<Popover
			display={(closePopover, openPopover, onButtonClick) => {
				return (
					<FontAwesomeIcon
						onClick={onButtonClick}
						style={{ color: "#006BB4", cursor: "pointer" }}
						icon={faStar}
					/>
				);
			}}
			render={(closePopover, openPopover) => {
				return (
					<form
						onSubmit={(e) => {
							e.preventDefault();
							handleSave(closePopover);
						}}
					>
						<EuiFieldText
							value={detail.name}
							name="name"
							onChange={handleChange}
							compressed
							placeholder="Name"
						/>

						<EuiSpacer size="xs" />

						<EuiFieldText
							value={detail.alias}
							name="alias"
							onChange={handleChange}
							compressed
							placeholder="Alias"
						/>

						<EuiSpacer size="xs" />

						<SaveCancelBtn
							onChangeSave={() => handleSave(closePopover)}
							onChangeCancel={() => handleCancel(closePopover)}
						/>
					</form>
				);
			}}
		/>
	);
};

export default React.memo(EditableCompanyNamePopover);
