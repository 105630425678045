import * as MODEL from "core/model";

/**
 * Constants For Compensation Plan
 */
export const WORK_TYPES = {
	perm: { key: "permanent", label: "Permanent", id: 1 },
	contract: { key: "contract", label: "Contract", id: 2 },
	contractToHire: { key: "contract-to-hire", label: "Contract to Hire", id: 3 },
};

export const PAY_CONSTRUCTURES = {
	salary: {
		key: "salary",
		label: "Salary",
		value: 1,
		initData: {
			[MODEL.compensation_financial.pay_type_c]: 1,
			[MODEL.compensation_financial.rate_fixed]: 0,
			[MODEL.compensation_financial.rate_low]: 0,
			[MODEL.compensation_financial.rate_high]: 0,
		},
	},
	hourly: {
		key: "hourly",
		label: "Hourly",
		value: 2,
		initData: {
			[MODEL.compensation_financial.pay_type_c]: 2,
			[MODEL.compensation_financial.rate_fixed]: 0,
			[MODEL.compensation_financial.rate_low]: 0,
			[MODEL.compensation_financial.rate_high]: 0,
			[MODEL.compensation_financial.pay_frequency_c]: 1, // default - hour
		},
	},
	overtime: {
		key: "overtime",
		label: "Overtime",
		value: 3,
		initData: {
			[MODEL.compensation_financial.pay_type_c]: 3,
			[MODEL.compensation_financial.rate_overtime]: 0,
		},
	},
	dblOvertime: {
		key: "dblOvertime",
		label: "DBL Overtime",
		value: 4,
		initData: {
			[MODEL.compensation_financial.pay_type_c]: 4,
			[MODEL.compensation_financial.rate_doubletime]: 0,
		},
	},
	commission: {
		key: "commission",
		label: "Commission",
		value: 5,
		initData: {
			[MODEL.compensation_financial.pay_type_c]: 5,
			[MODEL.compensation_financial.rate_fixed]: 0,
			[MODEL.compensation_financial.rate_low]: 0,
			[MODEL.compensation_financial.rate_high]: 0,
		},
	},
	performanceBonus: {
		key: "performanceBonus",
		label: "Performance Bonus",
		value: 6,
		initData: {
			[MODEL.compensation_financial.pay_type_c]: 6,
			[MODEL.compensation_financial.rate_fixed]: 0,
		},
	},
};

export const BENEFIT_OTHER_STRUCTURE = {
	total_other_value: {
		key: "total_other_value",
		label: "Total Other Value",
		value: 0,
		initData: {
			benefit_type_c: 0,
			benefit_details: {
				benefit_type: 0, // other
				benefit_type_category: "total_other_value",
				benefit_value: "0",
				benefit_unit_type: "dollar",
			},
		},
	},
	other: {
		key: "other",
		label: "Other",
		value: 0,
		initData: {
			benefit_type_c: 0,
			benefit_details: {
				benefit_type: 0, // other
				benefit_type_category: "other",
				benefit_value: "",
			},
		},
	},
};

export const BENEFIT_FINANCIAL_STRUCTURE = {
	total_financial_value: {
		key: "total_financial_value",
		label: "Total Financial value",
		value: 1,
		initData: {
			benefit_type_c: 1,
			benefit_details: {
				benefit_type: 1, // financial
				benefit_type_category: "total_financial_value",
				benefit_value: "0",
				benefit_unit_type: "dollar",
			},
		},
	},
	signing_bonus: {
		key: "signing_bonus",
		label: "Signing Bonus",
		value: 1,
		initData: {
			benefit_type_c: 1,
			benefit_details: {
				benefit_type: 1, // financial
				benefit_type_category: "signing_bonus",
				benefit_value: "0",
				benefit_unit_type: "dollar",
			},
		},
	},
	stock: {
		key: "stock",
		label: "Stock",
		value: 1,
		initData: {
			benefit_type_c: 1,
			benefit_details: {
				benefit_type: 1, // financial
				benefit_type_category: "stock",
				benefit_value: "0",
				benefit_unit_type: "dollar",
			},
		},
	},
	yearly: {
		key: "yearly",
		label: "Yearly",
		value: 1,
		initData: {
			benefit_type_c: 1,
			benefit_details: {
				benefit_type: 1, // financial
				benefit_type_category: "yearly",
				benefit_value: "0",
				benefit_unit_type: "dollar",
			},
		},
	},
	f401k: {
		key: "f401k",
		label: "401k",
		value: 1,
		initData: {
			benefit_type_c: 1,
			benefit_details: {
				benefit_type: 1, // financial
				benefit_type_category: "f401k",
				benefit_value: "0",
				benefit_unit_type: "dollar",
			},
		},
	},
	relocation: {
		key: "relocation",
		label: "Relocation",
		value: 1,
		initData: {
			benefit_type_c: 1,
			benefit_details: {
				benefit_type: 1, // financial
				benefit_type_category: "relocation",
				benefit_value: "0",
				benefit_unit_type: "dollar",
			},
		},
	},
	performance: {
		key: "performance",
		label: "Performance",
		value: 1,
		initData: {
			benefit_type_c: 1,
			benefit_details: {
				benefit_type: 1, // financial
				benefit_type_category: "performance",
				benefit_value: "0",
				benefit_unit_type: "dollar",
			},
		},
	},
	education: {
		key: "education",
		label: "Education",
		value: 1,
		initData: {
			benefit_type_c: 1,
			benefit_details: {
				benefit_type: 1, // financial
				benefit_type_category: "education",
				benefit_value: "0",
				benefit_unit_type: "dollar",
			},
		},
	},
};

export const BENEFIT_HEALTH_STRUCTURE = {
	total_health_value: {
		key: "total_health_value",
		label: "Total Health Value",
		value: 2,
		initData: {
			benefit_type_c: 2,
			benefit_details: {
				benefit_type: 2, // health
				benefit_type_category: "total_health_value",
				benefit_value: "0",
				benefit_unit_type: "dollar",
			},
		},
	},
	personal_dental: {
		key: "personal_dental",
		label: "Personal Dental",
		value: 2,
		initData: {
			benefit_type_c: 2,
			benefit_details: {
				benefit_type: 2, // health
				benefit_type_category: "personal_dental",
				benefit_value: "0",
				benefit_unit_type: "dollar",
			},
		},
	},
	personal_medical: {
		key: "personal_medical",
		label: "Personal Medical",
		value: 2,
		initData: {
			benefit_type_c: 2,
			benefit_details: {
				benefit_type: 2, // health
				benefit_type_category: "personal_medical",
				benefit_value: "0",
				benefit_unit_type: "dollar",
			},
		},
	},
	personal_vision: {
		key: "personal_vision",
		label: "Personal Vision",
		value: 2,
		initData: {
			benefit_type_c: 2,
			benefit_details: {
				benefit_type: 2, // health
				benefit_type_category: "personal_vision",
				benefit_value: "0",
				benefit_unit_type: "dollar",
			},
		},
	},
	dependent_dental: {
		key: "dependent_dental",
		label: "Dependent Dental",
		value: 2,
		initData: {
			benefit_type_c: 2,
			benefit_details: {
				benefit_type: 2, // health
				benefit_type_category: "dependent_dental",
				benefit_value: "0",
				benefit_unit_type: "dollar",
			},
		},
	},
	dependent_medical: {
		key: "dependent_medical",
		label: "Dependent Medical",
		value: 2,
		initData: {
			benefit_type_c: 2,
			benefit_details: {
				benefit_type: 2, // health
				benefit_type_category: "dependent_medical",
				benefit_value: "0",
				benefit_unit_type: "dollar",
			},
		},
	},
	dependent_vision: {
		key: "dependent_vision",
		label: "Dependent Vision",
		value: 2,
		initData: {
			benefit_type_c: 2,
			benefit_details: {
				benefit_type: 2, // health
				benefit_type_category: "dependent_vision",
				benefit_value: "0",
				benefit_unit_type: "dollar",
			},
		},
	},
	life_insurance: {
		key: "life_insurance",
		label: "Life Insurance",
		value: 2,
		initData: {
			benefit_type_c: 2,
			benefit_details: {
				benefit_type: 2, // health
				benefit_type_category: "life_insurance",
				benefit_value: "0",
				benefit_unit_type: "dollar",
			},
		},
	},
	hsa: {
		key: "hsa",
		label: "Hsa",
		value: 2,
		initData: {
			benefit_type_c: 2,
			benefit_details: {
				benefit_type: 2, // health
				benefit_type_category: "hsa",
				benefit_value: "0",
				benefit_unit_type: "dollar",
			},
		},
	},
	flex: {
		key: "flex",
		label: "Flex",
		value: 2,
		initData: {
			benefit_type_c: 2,
			benefit_details: {
				benefit_type: 2, // health
				benefit_type_category: "flex",
				benefit_value: "0",
				benefit_unit_type: "dollar",
			},
		},
	},
};

export const BENEFIT_TIME_OFF_STRUCTURE = {
	days_pto: {
		key: "days_pto",
		label: "Days Pto",
		value: 8,
		initData: {
			benefit_type_c: 8,
			benefit_details: {
				benefit_type: 8, // time off
				benefit_type_category: "days_pto",
				benefit_value: "0",
				benefit_unit_type: "dollar",
			},
		},
	},
	days_sick: {
		key: "days_sick",
		label: "Days Sick",
		value: 8,
		initData: {
			benefit_type_c: 8,
			benefit_details: {
				benefit_type: 8, // time off
				benefit_type_category: "days_sick",
				benefit_value: "0",
				benefit_unit_type: "dollar",
			},
		},
	},
	paid_holiday: {
		key: "paid_holiday",
		label: "Paid Holiday",
		value: 8,
		initData: {
			benefit_type_c: 8,
			benefit_details: {
				benefit_type: 8, // time off
				benefit_type_category: "paid_holiday",
				benefit_value: "0",
				benefit_unit_type: "dollar",
			},
		},
	},
};

export const BENEFIT_TYPE_STRUCTURE = {
	other: {
		key: "other",
		label: "Other",
		value: 0,
		categories: BENEFIT_OTHER_STRUCTURE,
	},
	financial: {
		key: "financial",
		label: "Financial",
		value: 1,
		categories: BENEFIT_FINANCIAL_STRUCTURE,
	},
	health: {
		key: "health",
		label: "Health",
		value: 2,
		categories: BENEFIT_HEALTH_STRUCTURE,
	},
	time_off: {
		key: "time_off",
		label: "Time Off",
		value: 8,
		categories: BENEFIT_TIME_OFF_STRUCTURE,
	},
};

export const BENEFIT_TYPES = {
	// health: { key: "health", label: "Health" },
	timeOff: { key: "timeOff", label: "Time Off" },
	other: { key: "Other", label: "Other" },
	// financial: { key: "financial", label: "Financial" },
};

export const BENEFIT_OTHER_DETAIL_TYPES = {
	financial: { key: "financial", label: "Financial" },
	health: { key: "health", label: "Health" },
};

export const BENEFIT_TIMEOFF_TYPES = {
	pto: { key: "pto", label: "PTO" },
	paidHolidays: { key: "paidHolidays", label: "Paid Holidays" },
	sickTime: { key: "sickTime", label: "Sick Time" },
};

export const BENEFIT_HEALTH_TYPES = {
	hsaContribution: { key: "hsaContribution", label: "HSA Contribution" },
	vision: { key: "vision", label: "Vision Paid", unit: "/year" },
	hsa: { key: "hsa", label: "HSA Paid" },
	medical: { key: "medical", label: "Medical Paid", unit: "/year" },
	dental: { key: "dental", label: "Dental Paid", unit: "/year" },
	lifeInsurance: { key: "lifeInsurance", label: "Life Insurance" },
	paidMedicalLeave: { key: "paidMedicalLeave", label: "Paid Medical Leave" },
};

export const BENEFIT_FINANCIAL_TYPES = {
	companyBonus: { key: "companyBonus", label: "Company Bonus" },
	annualRsu: { key: "annualRsu", label: "Annual RSUs" },
	bonusStock: { key: "bonusStock", label: "Bonus Stock" },
	k401Matching: { key: "k401matching", label: "401k Matching" },
	k401: { key: "k401", label: "401(K)" },
	flexSpending: { key: "flexSpending", label: "Flex Spending" },
	signingBonus: { key: "signingBonus", label: "Signing Bonus" },
	education: { key: "education", label: "Education" },
};

export const LOCATION_COMMUTE_TYPES = {
	relocate: { key: "relocate", label: "Will Not Relocate" },
	remote: { key: "remote", label: "Remote Required" },
};

export const TRAVEL_TYPES = {
	undefined: { key: "undefined", label: "Undefined" },
	required: { key: "required", label: "Travel Required" },
	notRequired: { key: "notRequired", label: "Not Required" },
};
