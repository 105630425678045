import { objectChecker } from "helpers/utilities";
import { credentialVerificationNotification } from "helpers/actions";

/**
 * Handler for General APIs
 *
 * @param {*} dispatch
 * @param {*} response
 */
export const handleSocketAPIResponse = (dispatch, response) => {
	const id = objectChecker(response, ["id"]);

	switch (id) {
		case "get-account-detail":
			break;
		case "api-response":
			break;
		default:
			break;
	}
};

export const handleSocketCredentialResponse = (dispatch, response) => {
	dispatch(credentialVerificationNotification(response));
};

export const handlerSocketDansResponse = (dispatch, response) => {
	console.log("response for Dans: ", response);
};
