import React from "react";
import styled from "styled-components";
import {
    EuiFieldText
} from "@elastic/eui";

import {
    websiteIcon,
} from "components/Common/Icons";
import { SVGIcon } from "components/Common";
import { EuiSelect, EuiComboBox } from "@elastic/eui";
import {
	_getCompanyMainList_NEW,
} from "helpers/selectors";
/**
 * Main Components
 */
const StaticCompany = (props) => {

	const {
        company,
		...restProps
	} = props;

	return (
		<Wrapper {...restProps} className="mb-2">
            <SVGIcon
                icon={websiteIcon}
                className="mr-2"
            />
            <div>
                {company || ''}
            </div>
		</Wrapper>
	);
};

export default StaticCompany;

/**
 * Styled Components
 */
const Wrapper = styled.div`
    display: flex;
    width: 100%;
`;
