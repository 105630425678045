import React from "react";
import styled from "styled-components";
import { EuiButton } from "@elastic/eui";

import variables from "assets/css/variables.scss";

const SVGIconBtnGroups = (props) => {
	const { options, selected, color = variables.primaryBtnColor, iconColor = variables.primaryBtnColor, onChange, ...restProps } = props;

	const onBtnClick = (id) => {
		onChange(id);
	};

	return (
		<Wrapper color={color} {...restProps}>
			{options &&
				options.length > 0 &&
				options.map((elem, index) => (
					<EuiButton size="s" key={index} className={selected === elem.id ? "selected" : ""} onClick={() => onBtnClick(elem.id)}>
						{elem.icon(selected === elem.id ? "#ffffff" : iconColor)}
					</EuiButton>
				))}
		</Wrapper>
	);
};

export default SVGIconBtnGroups;

/**
 * Styled Component
 */
const Wrapper = styled.div`

    .euiButton {
        border-radius: 0;
        color: ${(props) => props.color};
        border-color: ${variables.btnBorderColor};
        min-width: 0;
        width: 34px;
        height: 32px;
        box-shadow: unset;

        &: hover,
        &: focus,
        &: active {
            transform: unset;
            box-shadow: unset;
            animation: unset;
        }
        &: first-child {
            border-radius: 4px 0 0 4px;
        }
        &: last-child {
            border-radius: 0 4px 4px 0;
        }
        &: not(:first-child) {
            margin-left: -1px;
        }

        .euiButtonContent {
            padding: 0;
        }

        .euiButton__text {
            margin: auto;
            display: flex;
        }
    }

    .euiButton.selected {
        color: #ffffff;
        background: ${(props) => props.color};
        border-color: ${(props) => props.color};
        &: hover,
        &: focus,
        &: active {
            background: ${(props) => props.color};
            transform: none;
        }
    }
`;
