import React, { useCallback } from "react";
import styled from "styled-components";
import { EuiFlexGroup, EuiFlexItem, EuiIcon } from "@elastic/eui";
import { NavLink } from "react-router-dom";

import { companyMarkIcon, jobIcon } from "components/Common/Icons";
import useLayout from "components/Layout/useLayout";
import { getGCSLabel } from "components/global/utils";

/**
 * Main Components
 */
const GlobalSearchJob = (props) => {
	const { data, ...restProps } = props;

	const { globals } = useLayout();

	// common functions
	const getJobStatus = useCallback(
		(status) => {
			if (globals?.length) {
				return getGCSLabel(globals, "job_main", "job_status_c", status);
			}
		},
		[globals]
	);

	if (!data || data?.length <= 0) return null;

	return (
		<Wrapper {...restProps}>
			<EuiFlexGroup>
				<EuiFlexItem className="d-flex">
					<div className="pr-4">{jobIcon()}</div>
					<div className="search-title pr-2">Job</div>
					<div className="search-count">({data.length})</div>
				</EuiFlexItem>
			</EuiFlexGroup>
			{data.map((job, index) => {
				const jobInfo = job?.job_main;
				const { id, job_name, job_status_c } = jobInfo;

				return (
					<EuiFlexGroup
						className="search-card"
						gutterSize="s"
						key={"global-search-job-" + index}
					>
						<EuiFlexItem grow={1} className="d-flex content-center">
							{companyMarkIcon()}
						</EuiFlexItem>
						<EuiFlexItem grow={8}>
							<div className="d-flex">
								<NavLink
									className="pr-2 search-ellipsis"
									to={`/jobs/${id}`}
								>
									{job_name}
								</NavLink>
							</div>
							<div className="d-flex">
								<div className="search-ellipsis">
									<span>$20,000 Value</span>
									<EuiIcon type="dot" size="s" />
									<span>{getJobStatus(job_status_c)}</span>
								</div>
							</div>
						</EuiFlexItem>
						<EuiFlexItem grow={2}></EuiFlexItem>
					</EuiFlexGroup>
				);
			})}
		</Wrapper>
	);
};

export default GlobalSearchJob;

/**
 * Styled Components
 */
const Wrapper = styled.div`
	padding-bottom: 16px;
`;
