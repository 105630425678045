import API from "api";
import {
	job_application,
	job_application_rating,
	job_compensation,
	job_contact,
	job_interview,
	job_location,
	job_main,
	job_note,
	job_publication,
	job_requirement_sit,
} from "core/model";
import {
	makeSort,
	makeTableJoin,
	makeFieldContent,
	makeExternalJoin,
} from "./commonService";

/** ---------------- Generic API ---------------------- */
const updateJob = (data) => {
	const request = {
		object: "job",
		data: data || {},
	};

	return API().GenericAPI().updateTableData(request);
};

const deleteJob = (data) => {
	const request = {
		object: "job",
		data: data || {},
	};

	return API().GenericAPI().deleteTableData(request);
};

const getJobDetail = ({ id }) => {
	const query = {
		object: "job",
		record_main_id: id,
	};

	return API().GenericAPI().getMainData(query);
};

const getJobMainList = ({
	searchTerm = "",
	pageNumber = 1,
	pageCount = 20,
	params = null,
}) => {
	const query = {
		page_number: pageNumber,
		page_count: pageCount,
		table_main: job_main._name,
		fields: [
			makeFieldContent(job_main, job_main.id),
			makeFieldContent(job_main, job_main.job_name),
			makeFieldContent(job_main, job_main.job_detail),
			makeFieldContent(job_main, job_main.job_status_c),
			makeFieldContent(job_main, job_main.job_state_c),
			makeFieldContent(job_main, job_main.job_priority_c),
			makeFieldContent(job_main, job_main.company_main_ref_id),
			makeFieldContent(job_main, job_main.date_start),
			makeFieldContent(job_main, job_main.date_end),
			makeFieldContent(job_main, job_main.number_openings),
		],
		sort: [makeSort(job_main, job_main.job_name)],
		search_fields: [
			{
				table: job_main._name,
				field: job_main.id,
			},
			{
				table: job_main._name,
				field: job_main.job_name,
			},
		],
		search_type: "term",
		search_term: searchTerm,
		...(params ? { ...params } : {}),
	};

	return API().GenericAPI().getTableData(query);
};

const getJobListByCompany = ({
	companyId = -1,
	page = 1,
	per_page = 20,
	params = null,
}) => {
	const query = {
		page_number: page,
		page_count: per_page,
		table_main: job_main._name,
		table_join: makeTableJoin([
			job_contact,
			// job_note,
			job_application,
			// job_application_rating,
			// job_requirement_sit,
			// job_location,
			// job_compensation,
			// job_publication,
			// job_interview,
		]),
		fields: [
			makeFieldContent(job_main, job_main.id),
			makeFieldContent(job_main, job_main.job_name),
			makeFieldContent(job_main, job_main.job_detail),
			makeFieldContent(job_main, job_main.job_status_c),
			makeFieldContent(job_main, job_main.job_state_c),
			makeFieldContent(job_main, job_main.job_priority_c),
			makeFieldContent(job_main, job_main.company_main_ref_id),
			makeFieldContent(job_main, job_main.date_start),
			makeFieldContent(job_main, job_main.date_end),
			makeFieldContent(job_main, job_main.number_openings),

			makeFieldContent(job_application, job_application.id),
			makeFieldContent(job_application, job_application.person_main_ref_id),
			makeFieldContent(job_application, job_application.owner_account_ref_id),
			makeFieldContent(job_application, job_application.application_type_c),
			makeFieldContent(job_application, job_application.application_stage_c),
			makeFieldContent(job_application, job_application.application_source_type_c),
			makeFieldContent(job_application, job_application.application_source_ref_id),
			makeFieldContent(
				job_application,
				job_application.submission_compensation_main_ref_id
			),
			makeFieldContent(
				job_application,
				job_application.placement_compensation_main_ref_id
			),
			makeFieldContent(job_application, job_application.application_details),

			makeFieldContent(job_application_rating, job_application_rating.id),
			makeFieldContent(
				job_application_rating,
				job_application_rating.rating_type_c
			),
			makeFieldContent(
				job_application_rating,
				job_application_rating.rating_state_c
			),
			makeFieldContent(
				job_application_rating,
				job_application_rating.rating_reason_c
			),
			makeFieldContent(job_application_rating, job_application_rating.rating_value),

			makeFieldContent(job_requirement_sit, job_requirement_sit.id),
			makeFieldContent(job_requirement_sit, job_requirement_sit.sit_main_ref_id),
			makeFieldContent(job_requirement_sit, job_requirement_sit.sit_level_qc),
			makeFieldContent(job_requirement_sit, job_requirement_sit.sit_type_qc),
			makeFieldContent(job_requirement_sit, job_requirement_sit.requirement_type_c),
		],
		sort: [makeSort(job_main, job_main.job_name)],
		search_fields: [
			{
				table: job_main._name,
				field: job_main.company_main_ref_id,
			},
		],
		search_type: "ref",
		search_ref: [companyId],
		...(params ? { ...params } : {}),
	};

	return API().GenericAPI().getTableData(query);
};

/** --------------------------------------------------------- */

/** ----------------------- Custom API ---------------------- */
const getJobList = (params) => {
	return API().Jobs().getJobList(params);
};

const getJobApplicationList = (params) => {
	const custom_filter = params?.custom_filter;
	const query = {
		job_main_id: params?.jobId || -1,
		page_number: params?.page || 1,
		page_count: params?.per_page || 30,
		...(custom_filter ? { custom_filter } : {}),
		// ...(params?.filters ? { filters: params.filters } : {}),
		// ...(params?.sort ? { sort: params.sort } : {}),
		// ...(params?.search_term ? { search_term: params.search_term } : {}),
	};

	return API().Jobs().getJobApplicationList(query);
};

const getJobApplicationsForPerson = (params) => {
	return API().Jobs().getJobApplicationsForPerson(params);
};

const getJobContactsForPerson = (params) => {
	return API().Jobs().getJobList(params);
};
/* ---------------------------------------------------------- */
export default {
	updateJob,
	deleteJob,
	getJobList,
	getJobDetail,
	getJobMainList,
	getJobListByCompany,
	getJobApplicationList,
	getJobApplicationsForPerson,
	getJobContactsForPerson,
};
