import {
	AMS_REMINDER_RESULT_TYPE_LIST,
	AMS_REMINDER_TYPE_LIST,
} from "components/global/constants";
import * as MODEL from "core/model";

export const getNeedShowForReminder = (reminder) => {
	return (
		reminder[MODEL.ams_activity_reminder.reminder_triggered] === 1 &&
		reminder[MODEL.ams_activity_reminder.reminder_result_c] ===
			AMS_REMINDER_RESULT_TYPE_LIST.pending.value &&
		reminder[MODEL.ams_activity_reminder.reminder_time_type_c] ===
			AMS_REMINDER_TYPE_LIST.browser.value
	);
};
