import React, { memo } from "react";
import Theme from "./theme";
import Base from "./base";

export default memo(function AppGlobalStyles() {
    return (
        <>
            <Theme />
            <Base />
        </>
    );
});
