import { get } from "lodash";

import API from "api";
import * as actionTypes from "./actionTypes";
import { getResultFromApiResponse } from "helpers/utilities";
import Service from "core/service";
import * as MODEL from "core/model";
import { setNotification } from "store/notification";

export const openQualifierFlyout = () => {
	return async (dispatch) => {
		dispatch({
			type: actionTypes.SET_QUALIFIERS_FLYOUT,
			payload: true,
		});
	};
};

export const closeFlyout = () => {
	return async (dispatch) => {
		dispatch({
			type: actionTypes.SET_QUALIFIERS_FLYOUT,
			payload: false,
		});
	};
};

export const changeNodeQualifier = (data) => {
	return async (dispatch) => {
		try {
			const result = await Service.qualifier.updateQualifier({
				qualifier_main: {
					id: data.qualifierId,
					qualifier_parent_id: data.parentQualifierId,
				},
			});
			if (result) {
				dispatch({
					type: actionTypes.SET_QUALIFIER_NODE,
					payload: true,
				});
			}
		} catch (err) {
			alert("Error changeNodeQualifier: " + JSON.stringify(err));
		}
	};
};

export const getThemeDetail = (id) => {
	return async (dispatch) => {
		try {
			const result = await Service.theme.getThemeDetail({ id });

			const data = get(result, "data");

			if (data?.length > 0) {
				dispatch({
					type: actionTypes.GET_QUALIFIER_THEME_DETAIL,
					payload: data?.[0]?.theme_item_main,
				});
			} else {
				dispatch({
					type: actionTypes.GET_QUALIFIER_THEME_DETAIL,
					payload: null,
				});
			}
		} catch (err) {
			alert("Error themedetail: " + JSON.stringify(err));
			dispatch({
				type: actionTypes.GET_QUALIFIER_THEME_DETAIL,
				payload: null,
			});
		}
	};
};

export const assignQualifier = (data, successCallback) => {
	return async (dispatch) => {
		try {
			const result = await API()
				.GenericAPI()
				.updateTableData({
					object: "qualifier",
					qualifier_assignment: {
						qualifier_main_ref_id: data.qualifier_main_ref_id,
						object_type_c: 1,
						// object_type_c: `get_gcs('qualifier_assignment','object_type_c','${data.object_type_c}')`,
						object_record_ref_id: data.object_record_ref_id,
					},
				});

			if (result) {
				if (successCallback) {
					successCallback(data.object_record_ref_id);
				}
			}
		} catch (err) {
			alert(`Error assigning qualifier: ${JSON.stringify(err)}`);
		}
	};
};

export const unassignQualifer = (data, successCallback) => {
	return async (dispatch) => {
		try {
			const result = await API()
				.GenericAPI()
				.deleteTableData({
					object: "qualifier",
					qualifier_assignment: {
						id: data.qualifier_assignment_id,
					},
				});

			if (result) {
				if (successCallback) {
					successCallback(data.object_record_ref_id);
				}
			}
		} catch (err) {
			alert(`Error unassigning qualifier: ${JSON.stringify(err)}`);
		}
	};
};

/* ---------------------------------- NEW ---------------------------------------------------------- */
export const setQualifiers = (data) => {
	return {
		type: actionTypes.SET_QUALIFIERS,
		payload: data,
	};
};

export const setQualifier = (
	id,
	qualifierName,
	parentId,
	themeId,
	associations,
	override,
	action,
	theme
) => {
	return {
		type: actionTypes.SET_QUALIFIER_ID,
		payload: {
			id,
			qualifierName,
			parentId,
			themeId,
			associations,
			override: override,
			action,
			theme,
		},
	};
};

export const updateQualifier_NEW = (params, successCallback, failCallback) => {
	return async (dispatch) => {
		if (!params) {
			failCallback(null);
		}

		try {
			let payload = params;

			const result = await Service.qualifier.updateQualifier(payload);

			if (!get(result, "error")) {
				const res = getResultFromApiResponse(result);
				if (successCallback) {
					successCallback(res?.procedure_results);
				}
			}
		} catch (err) {
			if (failCallback) {
				failCallback(err);
			}
		}
	};
};

export const requestQualifierMainList_NEW = () => {
	return {
		type: actionTypes.REQUEST_QUALIFIER_MAINLIST_NEW,
	};
};

export const setQualifierMainList_NEW = (data) => {
	return {
		type: actionTypes.SET_QUALIFIER_MAINLIST_NEW,
		payload: data,
	};
};

export const getQualifierMainList_NEW = (params, successCallback, failCallback) => {
	return async (dispatch) => {
		dispatch(requestQualifierMainList_NEW());

		const _params = {
			searchTerm: params?.searchTerm ?? "",
			pageNumber: params?.pageNumber ?? 1,
			pageCount: params?.pageCount ?? 30,
			param: params?.param ?? null,
		};

		const payload = {
			searchTerm: _params.searchTerm,
			pageNumber: _params.pageNumber,
			pageCount: _params.pageCount,
			totalCount: 0,
			data: [],
		};

		try {
			const data = await Service.qualifier.getQualifierMainList(_params);

			if (data?.data) {
				const qualifiers = data?.data?.map((item) => item.qualifier_main);
				payload.data = qualifiers;
				payload.totalCount = qualifiers.length;

				if (successCallback) {
					successCallback(qualifiers);
				}
			} else {
				if (failCallback) {
					failCallback(data);
				}
			}
		} catch (err) {
			if (failCallback) {
				failCallback(err);
			}
		} finally {
			dispatch(setQualifierMainList_NEW(payload));
		}
	};
};

export const getAllQualifiers = (successCallback, failCallback) => {
	return async (dispatch) => {
		let res = [];
		try {
			const result = await Service.qualifier.getQualifierList();

			const data = get(result, "response.data");
			if (data?.length) {
				data.forEach((item) => {
					const mainQualifier = item?.qualifier_main;
					res.push(mainQualifier);
				});

				if (successCallback) {
					successCallback(res);
				}
			} else {
				if (failCallback) {
					failCallback(result);
				}
			}
		} catch (err) {
			if (failCallback) {
				failCallback(err);
			}
		} finally {
			dispatch(setQualifiers(res));
		}
	};
};

// Theme
export const editTheme = (params, successCallback, failCallback) => {
	return async (dispatch) => {
		let res = null;
		try {
			const result = await Service.theme.updateTheme({
				theme_item_main: {
					id: params.themeId,
					item_name: params.themeName,
					item_category: params.category,
					item_details: params.themeDetails,
				},
			});

			if (!get(result, "error")) {
				res = getResultFromApiResponse(result);
				if (successCallback) {
					successCallback(res?.procedure_results);
				}
			} else {
				if (failCallback) {
					failCallback(result);
				}
			}
		} catch (err) {
			if (failCallback) {
				failCallback(err);
			}
		} finally {
			dispatch({
				type: actionTypes.UPDATE_QUALIFIER_THEME,
				payload: res,
			});
		}
	};
};

export const createTheme = (params, successCallback, failCallback) => {
	return async (dispatch) => {
		let res = null;
		try {
			const result = await Service.theme.updateTheme({
				theme_item_main: {
					item_name: params.themeName,
					item_category: params.category,
					item_details: params.themeDetails,
				},
			});

			if (!get(result, "error")) {
				res = getResultFromApiResponse(result);
				if (successCallback) {
					successCallback(res?.procedure_results);
				}
			} else {
				if (failCallback) {
					failCallback(result);
				}
			}
		} catch (err) {
			if (failCallback) {
				failCallback(err);
			}
		} finally {
			dispatch({
				type: actionTypes.CREATE_QUALIFIER_THEME,
				payload: res,
			});
		}
	};
};

export const createQualifier = (params, successCallback, failCallback) => {
	return async (dispatch) => {
		let res = {};
		try {
			const associations =
				params?.associations?.map((associationTypeC) => {
					return {
						[MODEL.qualifier_association._name]: {
							[MODEL.qualifier_association.association_type_c]:
								associationTypeC,
						},
					};
				}) ?? [];

			const query = [
				{
					[MODEL.qualifier_main._name]: {
						[MODEL.qualifier_main.qualifier_name]: params?.qualifierName,
						[MODEL.qualifier_main.qualifier_override]: params?.override
							? 1
							: 0,
						[MODEL.qualifier_main.qualifier_parent_id]:
							params?.qualifierID || 0, //since we are adding the qualifier based from parent
						[MODEL.qualifier_main.theme_item_main_ref_id]: params?.themeId,
					},
				},
				...associations,
			];

			const result = await Service.qualifier.updateQualifier(query);

			if (!get(result, "error")) {
				res = getResultFromApiResponse(result);
				if (successCallback) {
					successCallback(res?.procedure_results);
				}
			} else {
				if (failCallback) {
					failCallback(result);
				}
			}
		} catch (err) {
			if (err?.errors?.length) {
				err.errors.map((item) => {
					const err = JSON.parse(item);
					if (err?.database_error?.internal?.SP_ERROR_CODE === -2) {
						// duplicate name
						dispatch(
							setNotification({
								type: "error",
								message:
									"Duplicate Name Qualifier of " +
									params?.qualifierName +
									". " +
									"Please enter the other name which is not duplicated.",
								status: "error",
							})
						);
					}
				});
			}
			if (failCallback) {
				failCallback(err);
			}
		} finally {
			dispatch({
				type: actionTypes.CREATE_QUALIFIER,
				payload: res,
			});
		}
	};
};

export const editQualifier = (params, successCallback, failCallback) => {
	return async (dispatch) => {
		let res = {};
		try {
			const associations =
				params?.associations?.map((associationTypeC) => {
					return {
						[MODEL.qualifier_association._name]: {
							[MODEL.qualifier_association.association_type_c]:
								associationTypeC,
							[MODEL.qualifier_association.qualifier_main_ref_id]:
								params?.qualifierID,
						},
					};
				}) ?? [];

			const query = [
				{
					[MODEL.qualifier_main._name]: {
						[MODEL.qualifier_main.id]: params?.qualifierID,
						[MODEL.qualifier_main.qualifier_name]: params?.qualifierName,
						[MODEL.qualifier_main.qualifier_override]: params?.override,
						[MODEL.qualifier_main.qualifier_parent_id]:
							params?.parentQualifierId, //since we are adding the qualifier based from parent
						[MODEL.qualifier_main.theme_item_main_ref_id]: params?.themeId,
					},
				},
				...associations,
			];

			const result = await Service.qualifier.updateQualifier(query);

			if (!get(result, "error")) {
				res = getResultFromApiResponse(result);
				if (successCallback) {
					successCallback(res?.procedure_results);
				}
			} else {
				if (failCallback) {
					failCallback(result);
				}
			}
		} catch (err) {
			if (failCallback) {
				failCallback(err);
			}
		} finally {
			dispatch({
				type: actionTypes.UPDATE_QUALIFIER,
				payload: res,
			});
		}
	};
};

export const deleteQualifier = (params, successCallback, failCallback) => {
	return async (dispatch) => {
		let res = {};
		try {
			const result = await Service.qualifier.deleteQualifier(params);

			if (!get(result, "error")) {
				res = getResultFromApiResponse(result);
				if (successCallback) {
					successCallback(res?.procedure_results);
				}
			} else {
				if (failCallback) {
					failCallback(result);
				}
			}
		} catch (err) {
			if (failCallback) {
				failCallback(err);
			}
		} finally {
			dispatch({
				type: actionTypes.DELETE_QUALIFIER,
				payload: res,
			});
		}
	};
};
