import React from "react";
import styled from "styled-components";

/**
 * Main Components
 */
const SVGIcon = (props) => {
	const { Cursor, width = 14, height = 14, icon, onClick, ...restProps } = props;

	return (
		<Wrapper
			{...restProps}
			style={{
				width: width,
				height: height,
				cursor: Cursor,
			}}
			onClick={onClick}
		>
			{icon ? icon() : null}
		</Wrapper>
	);
};

export default SVGIcon;

/**
 * Styled Components
 */
const Wrapper = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
`;
