import React from "react";
import { EuiFlexGroup, EuiFlexItem } from "@elastic/eui";
import styled from "styled-components";

import { useGlobalFlyout } from "core/useHooks";

import WorkflowHistoryItem from "./WorkflowHistoryItem";
import { get, orderBy } from "lodash";

/**
 * Hook for Workflow History Flyout
 */
const useWorkflowHistoryFlyout = () => {
	const { setFlyout } = useGlobalFlyout();

	const setWorkflowHistoryFlyout = (items = [], workflow, onClose) => {
		const nodes = get(workflow, "workflow_construct.flows") || [];
		const formattedItems = items?.map((item) => {
			const itemTime = item?.time;
			const formattedTime = itemTime ? new Date(item.time).getTime() : 0;
			return {
				...item,
				formattedTime,
			};
		});
		const orderedItems = orderBy(formattedItems, "formattedTime", "desc");

		const paramsFlyout = {
			size: "m",
			title: "",
			maxWidth: "450px",
			header: <span className="type-title-medium">Workflow History</span>,
			body: (
				<BodyContainer gutterSize="none" direction="column">
					{orderedItems?.map((item, index) => {
						return (
							<EuiFlexItem key={`workflow-history-items-${index}`}>
								<WorkflowHistoryItem data={item} nodes={nodes} />
							</EuiFlexItem>
						);
					})}
					{!items?.length && (
						<div className="no-items-label">
							<span>No Available Items</span>
						</div>
					)}
				</BodyContainer>
			),
			footer: "",
			isOpen: true,
			...(onClose ? { closeFlyout: onClose } : {}),
		};

		setFlyout(paramsFlyout);
	};

	return {
		setWorkflowHistoryFlyout,
	};
};

export default useWorkflowHistoryFlyout;

const BodyContainer = styled(EuiFlexGroup)`
	.no-items-label {
		width: 100%;
		padding: var(--sp-5);
		text-align: center;
	}
`;
