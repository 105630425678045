import API from "api";

import { SET_AUTH_INFO } from "./actionTypes";
import { objectChecker } from "helpers/utilities";

export const login = (username, password, successCallback, failCallback) => {
	return async (dispatch) => {
		let token = "";
		let id = null;
		let email = username;

		try {
			const payload = {
				email: username,
				password,
			};

			const result = await API().Auth().login(payload);
			token = objectChecker(result, ["data", "data", "token"]);
			id = objectChecker(result, ["data", "data", "account_id"]);

			if (token && id) {
				successCallback(token, id);
			} else {
				failCallback();
			}
		} catch (err) {
			failCallback(err);
		} finally {
			dispatch(
				setAuthInfo({
					email,
					token,
				})
			);
		}
	};
};

export const setAuthInfo = (info) => {
	return {
		type: SET_AUTH_INFO,
		payload: info,
	};
};
