import React, { useMemo, useState } from "react";
import styled from "styled-components";
import { EuiText, EuiPopover } from "@elastic/eui";

import { removeIcon, menuDotsHorizontal } from "components/Common/Icons/Icons";
import { OBJECT_SIT_TYPES } from "./constants";
import { truncate } from "helpers/utilities";

/**
 * Main Components
 */
const SITItem = (props) => {
	const {
		objectSitType = 1,
		label,
		onDelete,
		onChangeObjectSitType,
		editable = true,
		compressed = false,
	} = props;

	const [isHovered, setIsHovered] = useState(false);
	const [isOpenMenu, setIsOpenMenu] = useState(false);

	const { options, selectedOption } = useMemo(() => {
		const options = [
			OBJECT_SIT_TYPES.careerMover,
			OBJECT_SIT_TYPES.core,
			OBJECT_SIT_TYPES.exclude,
		];

		const selectedOption = Object.values(OBJECT_SIT_TYPES).find(
			(item) => item.value === objectSitType
		);

		return { options, selectedOption };
	}, [objectSitType]);

	const handleMouseEnter = () => {
		setIsHovered(true);
	};

	const handleMouseLeave = () => {
		setIsHovered(false);
	};

	const handleToggleSettingBar = () => {
		setIsOpenMenu(!isOpenMenu);
	};

	const handleChangeSitType = (newSitTypeC) => {
		const newTypeC =
			objectSitType === newSitTypeC ? OBJECT_SIT_TYPES.support.value : newSitTypeC;
		onChangeObjectSitType(newTypeC);
		setIsOpenMenu(false);
		setIsHovered(false);
	};

	const handleDelete = () => {
		onDelete();
		setIsOpenMenu(false);
		setIsHovered(false);
	};

	return (
		<Wrapper onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
			<div className="label">
				<EuiText size="xs">
					<span style={{ color: selectedOption?.color }}>
						{compressed ? truncate(label, 30) : label || ""}
					</span>
				</EuiText>
			</div>
			{isHovered ? (
				<div className="setting-bar__container">
					{editable && (
						<EuiPopover
							button={
								<div
									className="item-button setting-button"
									onClick={() => handleToggleSettingBar()}
								>
									{menuDotsHorizontal("var(--primary-color)")}
								</div>
							}
							isOpen={isOpenMenu}
							closePopover={() => setIsOpenMenu(false)}
							className="setting-bar-button__container"
							hasArrow={false}
							anchorPosition="downLeft"
							panelPaddingSize="none"
							panelStyle={{
								marginTop: -1,
								marginLeft: 10,
								minWidth: 24,
							}}
						>
							<MenuWrapper>
								{options.map((option) => {
									const selected = option.value === objectSitType;
									return (
										<div
											key={`sit-seting-item-${option.key}`}
											className={`menu-item ${
												selected ? "selected" : ""
											}`}
											onClick={() =>
												handleChangeSitType(option.value)
											}
										>
											<div className="icon">{option.icon}</div>
										</div>
									);
								})}
							</MenuWrapper>
						</EuiPopover>
					)}
					<div className="item-button close-button" onClick={handleDelete}>
						{removeIcon()}
					</div>
				</div>
			) : null}
		</Wrapper>
	);
};

export default SITItem;

/**
 * Styled Components
 */
const Wrapper = styled.div`
	display: flex;
	border: solid 1px var(--border-color);
	min-height: 1.6667rem;
	padding: 0 0.75rem;
	border-radius: 2rem;
	cursor: default;
	position: relative;
	min-width: 4.5rem;
	justify-content: center;

	.label {
		align-items: center;
		display: flex;
		font-weight: 600;
		line-height: 1.5rem;
	}

	.setting-bar__container {
		position: absolute;
		top: 0;
		right: 0;
		display: flex;
		flex-direction: row;
		height: 1.6667rem;
		box-sizing: border-box;
	}

	.setting-bar-button__container {
		margin-top: -2px;
		margin-right: -1px;
	}

	.item-button {
		display: flex;
		align-items: center;
		justify-content: center;
		width: 1.6667rem;
		height: 1.6667rem;
		border: 1px solid var(--border-color);
		box-sizing: border-box;
		border-radius: 0;
		background: #ffffffee;
		cursor: pointer;

		&.setting-button {
			width: 2rem;
		}

		&.close-button {
			border-radius: 0 1rem 1rem 0;
			margin-top: -1px;
			margin-right: -1px;
		}
	}
`;

const MenuWrapper = styled.div`
	.menu-item {
		cursor: pointer;
		width: 100%;
		height: 1.66667rem;
		display: flex;
		justify-content: center;
		align-items: center;

		&:not(:last-of-type) {
			border-bottom: 1px solid #00000033;
		}

		.icon {
			align-items: center;
			display: flex;
			justify-content: center;
			padding-top: 2px;
		}

		&.selected {
			background: var(--link-color);

			.icon {
				path {
					fill: #ffffff !important;
				}
			}
		}
	}
`;
