import React from "react";
import styled from "styled-components";

import CredentialNotification from "./CredentialNotification";
import SourcingNotification from "./SourcingNotification";
import NormalNotification from "./NomalNotification";

/**
 * Main Component for Notification
 */
const Notifications = (props) => {
	return (
		<Wrapper>
			<NormalNotification />
			<CredentialNotification {...props} />
			<SourcingNotification />
		</Wrapper>
	);
};

export default Notifications;

/**
 * Styled Components
 */
const Wrapper = styled.div`
	.euiToastHeader__title {
		font-size: medium;
	}
	.euiToast__closeButton {
		top: var(--sp-4);
		right: var(--sp-4);
	}
	.euiToast {
		border-radius: var(--sp-2);
		padding: var(--sp-2_5) var(--sp-5);
	}
`;
