import React, { useEffect, useState } from "react";
import Popover from "../../../../../Common/Popover/Popover";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faStar } from "@fortawesome/pro-light-svg-icons";
import { EuiFieldText, EuiSpacer } from "@elastic/eui";
import { objectChecker } from "../../../../../../helpers/utilities";
import EditControls from "../../../../../Common/EditControls";
import { useDispatch } from "react-redux";
import { fetchPersonDetail, updatePersonMain } from "../../../../../../store/person";

const PersonNamePopoverEdit = React.memo(({ _personDetails }) => {
	const [personDetails, setPersonDetails] = useState({});
	const dispatch = useDispatch();

	const handleSave = (editData, closePopover) => {
		const personId = editData["id"];
		const firstName = editData["firstName"];
		const lastName = editData["lastName"];
		const middleName = editData["middleName"];
		const nickName = editData["nickName"];

		dispatch(
			updatePersonMain(
				personId,
				firstName,
				middleName,
				lastName,
				nickName,
				() => {
					dispatch(fetchPersonDetail(personId));
					closePopover();
				},
				() => {
					alert("Something went wrong when trying to update the person");
				}
			)
		);
	};

	const updateFormField = (key, value) => {
		const newPersonDetails = {
			...personDetails,
			[key]: value,
		};

		setPersonDetails(newPersonDetails);
	};

	useEffect(() => {
		const personID = objectChecker(_personDetails, ["detail", "ID"]);
		const newFirstName = objectChecker(_personDetails, ["detail", "name_first"]);
		const newLastName = objectChecker(_personDetails, ["detail", "name_last"]);
		const newMiddleName = objectChecker(_personDetails, ["detail", "name_middle"]);
		const newNickName = objectChecker(_personDetails, ["detail", "name_nick"]);

		const newPersonDetails = {
			id: personID,
			firstName: newFirstName,
			lastName: newLastName,
			middleName: newMiddleName,
			nickName: newNickName,
		};

		setPersonDetails(newPersonDetails);
	}, [_personDetails]);

	const { firstName, lastName, middleName, nickName } = personDetails;

	return (
		<Popover
			display={(closePopover, openPopover, onButtonClick) => {
				return <FontAwesomeIcon onClick={onButtonClick} style={{ color: "#006BB4" }} icon={faStar} />;
			}}
			render={(closePopover, openPopover) => {
				return (
					<React.Fragment>
						<form
							onSubmit={(e) => {
								e.preventDefault();
								handleSave(personDetails, closePopover);
							}}
						>
							<EuiFieldText
								value={firstName}
								onChange={(e) => updateFormField("firstName", e.target.value)}
								compressed
								placeholder="First Name"
							/>

							<EuiSpacer size="xs" />

							<EuiFieldText
								value={middleName}
								onChange={(e) => updateFormField("middleName", e.target.value)}
								compressed
								placeholder="Middle Name"
							/>

							<EuiSpacer size="xs" />

							<EuiFieldText
								value={lastName}
								onChange={(e) => updateFormField("lastName", e.target.value)}
								compressed
								placeholder="Last Name"
							/>

							<EuiSpacer size="xs" />

							<EuiFieldText
								value={nickName}
								onChange={(e) => updateFormField("nickName", e.target.value)}
								compressed
								placeholder="Nickname"
							/>

							<EditControls
								handleCancel={closePopover}
								editData={personDetails}
								setEditClicked={() => {}}
								// onSaveHandler={(editData) => handleSave(editData, closePopover)}
								onSaveHandler={(editData) => {}}
							/>
						</form>
					</React.Fragment>
				);
			}}
		/>
	);
});

export default PersonNamePopoverEdit;
