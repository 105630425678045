import React, { useState, useMemo } from "react";
import styled from "styled-components";
import {
	EuiButton,
	EuiButtonEmpty,
	EuiContextMenuItem,
	EuiContextMenuPanel,
	EuiFlexGroup,
	EuiFlexItem,
	EuiIcon,
	EuiPopover,
} from "@elastic/eui";

/**
 * Component for Buttons with Popover
 */
const ButtonsPopover = (props) => {
	const {
		buttons,
		minWidth = 0,
		color = "primary",
		isEmpty = false,
		panelStyle = {},
		hasLabelWrapper = false,
		menuItemStyle = {},
	} = props;

	const hasOneButton = buttons?.length === 1;

	const [isPopoverOpenBottomMenu, setBottomMenu] = useState(false);

	const closePopoverBottomMenu = () => {
		setBottomMenu(false);
	};
	const openMenu = () => {
		setBottomMenu(!isPopoverOpenBottomMenu);
	};

	const additionButtons = useMemo(() => {
		const items = buttons.slice(1);
		const renderButtons = [];
		items.forEach((item, id) => {
			const isHidden = item.hidden;
			if (isHidden) {
				return null;
			}
			renderButtons.push(
				<EuiContextMenuItem
					key={`buttons-list-item-${id}-${item.label}`}
					icon={item.icon}
					onClick={() => {
						if (!hasLabelWrapper) {
							item.callback();
						}
						closePopoverBottomMenu();
					}}
					disabled={item.disabled}
					style={menuItemStyle}
				>
					{item.label}
				</EuiContextMenuItem>
			);
		});

		return renderButtons;
	}, [buttons, hasLabelWrapper, menuItemStyle]);

	if (!buttons?.length) {
		return null;
	}

	const handleOpenMenu = () => {
		if (additionButtons.length > 0) {
			openMenu();
		}
	};

	const buttonDropdown = isEmpty ? (
		<EuiButtonEmpty
			onClick={() => {
				handleOpenMenu();
			}}
			fill
			color={color}
		>
			<EuiIcon type="arrowDown" />
		</EuiButtonEmpty>
	) : (
		<EuiButton
			className="dropdown-button"
			onClick={() => {
				handleOpenMenu();
			}}
			fill
			color={color}
		>
			<EuiIcon type="arrowDown" />
		</EuiButton>
	);

	return (
		<Wrapper>
			<EuiFlexGroup gutterSize="xs">
				<EuiFlexItem className="left-main-button" grow={false}>
					{isEmpty ? (
						<EuiButtonEmpty
							className={`select-main-button`}
							onClick={() => {
								buttons[0].callback();
							}}
							fill
							color={color}
							disabled={buttons[0].disabled}
						>
							{buttons[0].label}
						</EuiButtonEmpty>
					) : (
						<EuiButton
							className={`select-main-button ${hasOneButton && "only-one"}`}
							onClick={() => {
								buttons[0].callback();
							}}
							fill
							color={color}
							disabled={buttons[0].disabled}
						>
							{buttons[0].label}
						</EuiButton>
					)}
				</EuiFlexItem>
				{!hasOneButton && (
					<EuiFlexItem className="right-dropdown" grow={false}>
						<EuiPopover
							button={buttonDropdown}
							isOpen={isPopoverOpenBottomMenu}
							closePopover={closePopoverBottomMenu}
							panelPaddingSize="none"
							anchorPosition="downRight"
							hasArrow={false}
							panelStyle={{
								minWidth: minWidth,
								...panelStyle,
							}}
						>
							<EuiContextMenuPanel items={additionButtons} />
						</EuiPopover>
					</EuiFlexItem>
				)}
			</EuiFlexGroup>
		</Wrapper>
	);
};

export default ButtonsPopover;

const Wrapper = styled.div`
	display: flex;
	position: relative;
	width: 100%;

	.select-main-button {
		&:not(&.only-one) {
			border-top-right-radius: 0px;
			border-bottom-right-radius: 0px;
			border-right: none;
		}
	}

	.dropdown-button {
		border-top-left-radius: 0px;
		border-bottom-left-radius: 0px;
		border-left: solid 1px white;
		min-width: 0;
	}

	.left-main-button {
		margin-right: 0 !important;
	}

	.right-dropdown {
		margin-left: 0 !important;
	}
`;
