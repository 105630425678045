import React, { useState } from "react";
import { EuiPopover } from "@elastic/eui";

const Popover = (props) => {
    const {
        render,
        display,
        paddingSize = "s",
        position = "downCenter",
        hasArrow = true,
        panelStyle = {},
    } = props;

    const [isPopoverOpen, setIsPopoverOpen] = useState(false);

    const onButtonClick = () => setIsPopoverOpen((isPopoverOpen) => !isPopoverOpen);
    const closePopover = () => setIsPopoverOpen(false);
    const openPopover = () => setIsPopoverOpen(true);

    return (
        <EuiPopover
            anchorPosition={position}
            ownFocus
            button={display(closePopover, openPopover, onButtonClick)}
            isOpen={isPopoverOpen}
            panelPaddingSize={paddingSize}
            closePopover={closePopover}
            hasArrow={hasArrow}
            panelStyle={panelStyle}
        >
            <div>{render(closePopover, openPopover)}</div>
        </EuiPopover>
    );
};

export default Popover;
