import * as actionTypes from "./actionTypes";
import * as MODEL from "core/model";

/**
 * Functions
 */
const setJobSelectedSkills = (state, payload) => {
	const newState = { ...state };
	newState[payload.type] = payload.data;

	return newState;
};

const setJob = (state, payload) => {
	return {
		...state,
		job: payload,
	};
};

const getJobs = (state, payload) => {
	return {
		...state,
		getJobStatus: payload,
	};
};

const setJobs = (state, payload) => {
	return {
		...state,
		jobs: payload?.data || [],
		totalCount: payload?.totalCount,
		pagination: {
			totalCount: payload?.totalCount,
			currentPage: payload?.pageNumber,
		},
	};
};

/* ------------------------- NEW ------------------------ */
// Job List
function setJobList_NEW(state, payload) {
	return {
		...state,
		jobList_NEW: {
			...state.jobList_NEW,
			...(payload || {}),
		},
	};
}

// Job Detail
function setJobDetail_NEW(state, payload) {
	return {
		...state,
		job_NEW: {
			...state.job_NEW,
			...(payload || {}),
		},
	};
}

// Job Application
function setJobApplication_NEW(state, payload) {
	return {
		...state,
		jobApplication_NEW: {
			...state.jobApplication_NEW,
			...(payload || {}),
		},
	};
}

// Job Main Table
const requestJobMainList_NEW = (state) => {
	return {
		...state,
		jobMainList_NEW: {
			...state.jobMainList_NEW,
			isRequesting: true,
			isReceived: false,
		},
	};
};

const receivedJobMainList_NEW = (state) => {
	return {
		...state,
		jobMainList_NEW: {
			...state.jobMainList_NEW,
			isRequesting: false,
			isReceived: true,
		},
	};
};

const setJobMainList_NEW = (state, payload) => {
	return {
		...state,
		jobMainList_NEW: {
			...state.jobMainList_NEW,
			...payload,
			isRequesting: false,
			isReceived: true,
		},
	};
};

// Job Application List
function setJobApplicationList_NEW(state, payload) {
	return {
		...state,
		jobApplicationList_NEW: {
			...state.jobApplicationList_NEW,
			...(payload || {}),
			isRequesting: false,
			isReceived: true,
		},
	};
}

const requestJobApplicationList_NEW = (state) => {
	return {
		...state,
		jobApplicationList_NEW: {
			...state.jobApplicationList_NEW,
			isRequesting: true,
			isReceived: false,
		},
	};
};

const receivedJobApplicationList_NEW = (state) => {
	return {
		...state,
		jobApplicationList_NEW: {
			...state.jobApplicationList_NEW,
			isRequesting: false,
			isReceived: true,
		},
	};
};

const setJobApplicationListParams_NEW = (state, payload) => {
	return {
		...state,
		jobApplicationList_NEW: {
			...state.jobApplicationList_NEW,
			...(payload || {}),
		},
	};
};

const setJobApplicationsForPerson = (state, payload) => {
	return {
		...state,
		jobApplicationsForPerson: payload,
	};
};

const setJobContactsForPerson = (state, payload) => {
	return {
		...state,
		jobContactsForPerson: payload,
	};
};
/* ------------------------------------------------------ */

/**
 * Initial State
 */
const initialState = {
	getJobStatus: {},
	jobs: [],

	// Selected lists
	selectedSkills: null,
	selectedIndustries: null,
	selectedTitles: null,

	job: null,
	totalCount: 0,
	pagination: {
		totalCount: 0,
		currentPage: 1,
	},

	/* ------------------------- NEW ------------------------- */
	jobMainList_NEW: {
		totalCount: 0,
		pageCount: 30,
		pageNumber: 1,
		data: [],
		isRequesting: false,
		isReceived: false,
	},
	jobList_NEW: {
		totalCount: 0,
		page: 1,
		per_page: 30,
		filters: {
			job_status: "active",
		},
		data: [],
		loading: false,
	},
	job_NEW: {},
	jobApplicationList_NEW: {
		totalCount: 0,
		page: 1,
		per_page: 30,
		// filters: {},
		custom_filter: null, // 'pipelined', 'inprocess', 'placed', 'rejected'
		data: [],
		[MODEL.job_application.job_main_ref_id]: 0,
		isRequesting: false,
		isReceived: false,
	},

	jobApplicationsForPerson: [],
	jobContactsForPerson: [],
	/* ------------------------------------------------------- */
};

/**
 * Reducer
 */
const Reducer = (state = initialState, action) => {
	switch (action.type) {
		case actionTypes.GET_JOBS:
			return getJobs(state, action.payload);

		case actionTypes.SET_JOBS:
			return setJobs(state, action.payload);

		case actionTypes.SET_JOB:
			return setJob(state, action.payload);

		case actionTypes.SET_JOB_SELECTED_SKILLS:
			return setJobSelectedSkills(state, action.payload);

		/* ------------------------- NEW ------------------------- */
		/* ------- Job List ------- */
		case actionTypes.SET_JOB_LIST_NEW:
			return setJobList_NEW(state, action.payload);

		case actionTypes.SET_JOB_DETAIL_NEW:
			return setJobDetail_NEW(state, action.payload);

		/* ------- Job Main Table ------- */
		case actionTypes.SET_JOB_MAINLIST_NEW:
			return setJobMainList_NEW(state, action.payload);

		case actionTypes.REQUEST_JOB_MAINLIST_NEW:
			return requestJobMainList_NEW(state);

		case actionTypes.RECEIVED_JOB_MAINLIST_NEW:
			return receivedJobMainList_NEW(state);

		// Job Application List
		case actionTypes.SET_JOB_APPLICATION_LIST_NEW:
			return setJobApplicationList_NEW(state, action.payload);

		case actionTypes.REQUEST_JOB_APPLICATION_LIST_NEW:
			return requestJobApplicationList_NEW(state);

		case actionTypes.RECEIVED_JOB_APPLICATION_LIST_NEW:
			return receivedJobApplicationList_NEW(state);

		case actionTypes.SET_JOB_APPLICATION_LIST_PARAMS_NEW:
			return setJobApplicationListParams_NEW(state, action.payload);

		// Job Application List for person
		case actionTypes.JOB_APPLICATIONS_FOR_PERSON:
			return setJobApplicationsForPerson(state, action.payload);

		// Job Contacts List for person
		case actionTypes.JOB_CONTACTS_FOR_PERSON:
			return setJobContactsForPerson(state, action.payload);
		/* ------------------------------------------------------- */

		default:
			return state;
	}
};

export default Reducer;
