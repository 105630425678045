import React, { useState } from "react";
import styled, { css } from "styled-components";

/**
 * Component for Static Item Base
 */
const StaticItemBase = (props) => {
	const {
		children,
		extraSetting,
		iconGroups,
		disabled,
		hasShadow = true,
		...restProps
	} = props;

	const [isHovered, setIsHoverd] = useState(false);

	const handleMouseEnter = () => {
		if (!disabled) {
			setIsHoverd(true);
		}
	};

	const handleMouseLeave = () => {
		if (!disabled) {
			setIsHoverd(false);
		}
	};

	return (
		<Wrapper
			{...restProps}
			onMouseEnter={handleMouseEnter}
			onMouseLeave={handleMouseLeave}
			disabled={disabled}
			hasShadow={hasShadow}
		>
			{children}
			{isHovered ? (
				<HoverIconGroups>{iconGroups}</HoverIconGroups>
			) : (
				<IconGroups>{iconGroups}</IconGroups>
			)}
			{extraSetting && (
				<ExtraSettingWrapper>
					{isHovered ? extraSetting : null}
				</ExtraSettingWrapper>
			)}
		</Wrapper>
	);
};

export default StaticItemBase;

const Wrapper = styled.div`
	display: flex;
	width: 100%;
	position: relative;
	box-sizing: border-box;
	padding: var(--sp-3);
	background: transparent;
	cursor: default;
	border-radius: var(--sp-2);
	min-height: var(--sp-15);

	&:hover {
		${({ disabled, hasShadow }) =>
			!disabled &&
			hasShadow &&
			css`
				box-shadow: 0px 12px 24px rgba(65, 78, 101, 0.1),
					0px 6px 12px rgba(65, 78, 101, 0.1),
					0px 4px 4px rgba(65, 78, 101, 0.1), 0px 2px 2px rgba(65, 78, 101, 0.1);
			`}
	}
`;

const IconGroups = styled.div`
	padding: 0 8px;
	display: flex;
`;

const HoverIconGroups = styled.div`
	display: flex;
	position: absolute;
	right: 80px;
	top: 8px;
	background: #fff;
	padding: 0 8px;
`;

const ExtraSettingWrapper = styled.div`
	display: flex;
	position: absolute;
	top: var(--sp-3);
	right: var(--sp-3);
	background: #ffffff;
`;
