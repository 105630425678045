import React, { useRef, useEffect } from "react";
import styled from "styled-components";
import { Editor } from "@tinymce/tinymce-react";

import { TINYMCE_DEFAULT_HEIGHT, TINYMCE_APIKEY } from "components/global/constants";

/**
 * Main Components
 */
const TextAreaEditor = (props) => {
	const { editorVal, autocompletOptions, changeEditVal, disabled, ...restProps } =
		props;

	const editorRef = useRef(null);
	const editorRefCurrent = editorRef?.current;

	const autoCompleteSetup = (editor) => {
		const onMatches = function (rng, text, pattern) {
			const format = /[^a-zA-Z0-9]/;

			if (!format.test(pattern)) {
				return true;
			} else {
				return false;
			}
		};

		const onAction = function (autocompleteApi, rng, value) {
			editor.selection.setRng(rng);
			editor.insertContent(value);
			autocompleteApi.hide();
		};

		const getMatchedChars = function (pattern) {
			if (autocompletOptions?.length) {
				return autocompletOptions.filter(function (char) {
					return (
						char.value.toLowerCase().indexOf(pattern) !== -1 ||
						char.value.toUpperCase().indexOf(pattern) !== -1
					);
				});
			} else {
				return [];
			}
		};

		/* An autocompleter that allows you to insert special characters */
		editor.ui.registry.addAutocompleter("specialchars", {
			ch: "[",
			minChars: 0,
			columns: 1,
			highlightOn: ["char_name"],
			matches: onMatches,
			onAction: onAction,
			fetch: function (pattern) {
				return new window.tinymce.util.Promise(function (resolve) {
					var results = getMatchedChars(pattern).map(function (char) {
						return {
							type: "cardmenuitem",
							value: "[" + char.value + "]",
							items: [
								{
									type: "cardcontainer",
									direction: "vertical",
									items: [
										{
											type: "cardtext",
											text: char.value,
											name: "char_name",
										},
									],
								},
							],
						};
					});
					resolve(results);
				});
			},
		});
	};

	useEffect(() => {
		if (editorRefCurrent && autocompletOptions?.length > 0) {
			autoCompleteSetup(editorRefCurrent);
		}
	}, [editorRefCurrent, autocompletOptions]);

	return (
		<Wrapper {...restProps}>
			<input
				id="my-file"
				type="file"
				name="my-file"
				style={{ display: "none" }}
				onChange=""
				disabled={disabled}
			/>
			<Editor
				onInit={(evt, editor) => (editorRef.current = editor)}
				value={editorVal}
				apiKey={TINYMCE_APIKEY}
				disabled={disabled}
				init={{
					height: TINYMCE_DEFAULT_HEIGHT,
					menubar: false,
					statusbar: false,
					plugins: "emoticons charmap image",
					toolbar: "emoticons charmap | image",
					image_title: true,
					file_browser_callback_types: "image",
					file_picker_callback: function (callback, value, meta) {
						if (meta.filetype === "image") {
							const input = document.getElementById("my-file");
							input.click();
							input.onchange = function () {
								const file = input.files[0];
								const reader = new FileReader();
								reader.onload = function (e) {
									callback(e.target.result, {
										alt: file.name,
									});
								};
								reader.readAsDataURL(file);
							};
						}
					},
					paste_data_images: true,
					setup: function (editor) {},
					entity_encoding: "raw",
					content_style:
						"body { font-family: Inter_Regular; font-size:12px; color: #343741; }",
				}}
				onEditorChange={(newValue, editor) => {
					const text = editor.getContent({ format: "text" });
					changeEditVal(newValue, text);
				}}
			/>
		</Wrapper>
	);
};

export default TextAreaEditor;

/**
 * Styled Components
 */
const Wrapper = styled.div``;
