/**
 * Globals
 */
export const FETCH_GLOBAL_VALUES = "@@globals/FETCH_GLOBAL_VALUES";
export const SET_GLOBALS = "@@globals/SET_GLOBALS";
export const UPDATE_GLOBAL_CUSTOM_FIELD = "@@globals/UPDATE_GLOBAL_CUSTOM_FIELD";
export const ADD_GLOBAL_CUSTOM_FIELD = "@@globals/ADD_GLOBAL_CUSTOM_FIELD";

/* ------------------------- NEW ------------------------ */
export const UPDATE_GLOBAL_RECORD_NEW = "@@globals/UPDATE_GLOBAL_RECORD_NEW";
export const SET_GLOBAL_STATIC_SETTING_NEW = "@@globals/SET_GLOBAL_STATIC_SETTING_NEW";
/* ------------------------------------------------------ */
