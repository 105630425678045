import { EuiFlexGroup, EuiFlexItem, EuiText } from "@elastic/eui";
import React, { useEffect, useMemo, useState } from "react";
import styled from "styled-components";
import { get } from "lodash";

import { AccordionWithHeader } from "components/Common";
import * as MODEL from "core/model";

import AmsListViewHeader from "./AmsListViewHeader";
import AmsItem from "./AmsItem";
import AmsNewItem from "./AmsNewItem";
import { PrevNextButtonsItem } from "../CustomComponents";

/**
 * Main Component for AMS List view
 */
const AmsListView = (props) => {
	const {
		list,
		person,
		onUpdateAms,
		onDeleteAms,
		onSaveEmail,
		onDeleteEmail,
		onSavePhone,
		onDeletePhone,
		onSaveRAS,
		loadPersonAmsData,
		onChangeAmsListParams,
		globalSearchItems,
		getGlobalSearchItems,
	} = props;

	const [data, setData] = useState([]);
	const [showNewItem, setShowNewItem] = useState(false);

	const { page, totalPages, isLoading } = useMemo(() => {
		const page = list.pageNumber;
		const pageCount = list.pageCount || 1;
		const totalCount = list.totalCount;
		const totalPages = Math.ceil(totalCount / pageCount);
		const isLoading = list.isRequesting;

		return {
			page,
			totalPages,
			isLoading,
		};
	}, [list]);

	const handleChangePageNumber = (newPage) => {
		const params = {
			pageNumber: newPage,
		};
		onChangeAmsListParams(params);
	};

	const handleAddNewItem = () => {
		setShowNewItem(true);
	};

	const handleCancelNewItem = () => {
		setShowNewItem(false);
	};

	const handleSaveNewItem = (newAmsData, rasData) => {
		onUpdateAms(
			newAmsData,
			(res) => {
				const amsMainId = get(
					res,
					`${MODEL.ams_activity_main._name}[0].id`,
					null
				);

				if (amsMainId) {
					// onSaveRAS has loadPersonAmsData.
					onSaveRAS(amsMainId, null, rasData);
				} else {
					// Reload AMS data for Person
					loadPersonAmsData();
				}

				// Close add AMS item after new AMS item is created.
				setShowNewItem(false);
			},
			(err) => {
				// Close add AMS item after new AMS item is created.
				setShowNewItem(false);
			}
		);
	};

	const handleDeleteAms = (ams) => {
		const amsId = get(ams, MODEL.ams_activity_main.id, 0);
		if (amsId) {
			const params = {
				[MODEL.ams_activity_main._name]: {
					[MODEL.ams_activity_main.id]: amsId,
				},
			};

			onDeleteAms(params, () => {
				if (data?.length === 1) {
					const newPage = Math.max(page - 1, 1);
					const params = {
						pageNumber: newPage,
					};
					onChangeAmsListParams(params);
				} else {
					loadPersonAmsData();
				}
			});
		}
	};

	useEffect(() => {
		setData(list?.data || []);
	}, [list]);

	return (
		<Wrapper>
			<AccordionWithHeader
				headerItems={
					<AmsListViewHeader
						title="Notes & Activities"
						isPlusIcon={true}
						onAddNewItem={handleAddNewItem}
						isLoading={isLoading}
					/>
				}
				isLoading={false}
				isOpen={"open"}
			>
				<div className="content__wrapper">
					{showNewItem && (
						<AmsNewItem
							person={person}
							onSavePhone={onSavePhone}
							onDeletePhone={onDeletePhone}
							onSaveEmail={onSaveEmail}
							onDeleteEmail={onDeleteEmail}
							onSaveRAS={onSaveRAS}
							onCancel={handleCancelNewItem}
							onSave={handleSaveNewItem}
						/>
					)}
					{data?.map((elem, index) => (
						<AmsItem
							data={elem}
							key={index}
							person={person}
							onUpdateAms={onUpdateAms}
							onDeleteAms={onDeleteAms}
							onDeleteAmsItem={() => handleDeleteAms(elem)}
							onSavePhone={onSavePhone}
							onDeletePhone={onDeletePhone}
							onSaveEmail={onSaveEmail}
							onDeleteEmail={onDeleteEmail}
							onSaveRAS={onSaveRAS}
							loadPersonAmsData={loadPersonAmsData}
							globalSearchItems={globalSearchItems}
							getGlobalSearchItems={getGlobalSearchItems}
						/>
					))}
					{data?.length > 0 && (
						<Footer>
							<EuiFlexGroup
								justifyContent="spaceBetween"
								gutterSize="none"
								alignItems="center"
							>
								<EuiFlexItem className="page-number">
									<EuiText size="xs" color="subdued">
										<p>
											{page} of {totalPages}
										</p>
									</EuiText>
								</EuiFlexItem>
								<EuiFlexItem grow={false} className="d-flex">
									<PrevNextButtonsItem
										page={page}
										totalPageCount={totalPages}
										onChange={handleChangePageNumber}
									/>
								</EuiFlexItem>
							</EuiFlexGroup>
						</Footer>
					)}
					{!(data?.length > 0) && !showNewItem ? (
						<div className="no-available-label">
							<span>No available data</span>
						</div>
					) : null}
				</div>
			</AccordionWithHeader>
		</Wrapper>
	);
};

export default AmsListView;

const Wrapper = styled.div`
	.content__wrapper {
		padding-bottom: var(--sp-4);
	}

	.no-available-label {
		display: flex;
		width: 100%;
		justify-content: center;
		padding: var(--sp-4);
	}
`;

const Footer = styled.div`
	padding: var(--sp-8) var(--sp-4);
`;
