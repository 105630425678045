import API from "api";
import { qualifier_main } from "core/model";
import { accountId } from "SocketProvider";
import { makeSort, makeFieldContent } from "./commonService";

/** ---------------- Generic API ---------------------- */
const updateQualifier = (data) => {
	const request = {
		object: "qualifier",
		data: data || {},
	};

	return API().GenericAPI().updateTableData(request);
};

const getQualifierMainList = ({
	searchTerm = "",
	pageNumber = 1,
	pageCount = 30,
	params = null,
}) => {
	const query = {
		page_number: pageNumber,
		page_count: pageCount,
		table_main: qualifier_main._name,
		fields: [
			makeFieldContent(qualifier_main, qualifier_main.id),
			makeFieldContent(qualifier_main, qualifier_main.qualifier_name),
			makeFieldContent(qualifier_main, qualifier_main.qualifier_parent_id),
			makeFieldContent(qualifier_main, qualifier_main.qualifier_children_count),
			makeFieldContent(qualifier_main, qualifier_main.qualifier_override),
			makeFieldContent(qualifier_main, qualifier_main.theme_item_main_ref_id),
		],
		sort: [
			makeSort(qualifier_main, qualifier_main.qualifier_name),
			makeSort(qualifier_main, qualifier_main.qualifier_parent_id),
		],
		search_fields: [
			{
				table: qualifier_main._name,
				field: qualifier_main.id,
			},
			{
				table: qualifier_main._name,
				field: qualifier_main.qualifier_name,
			},
		],
		search_type: "term",
		search_term: searchTerm,
		...(params ? { ...params } : {}),
	};

	return API().GenericAPI().getTableData(query);
};

const getQualifierList = () => {
	const query = {
		page_number: 1,
		page_count: 1000,
		// account_main_id: accountId, // added for security group setting
	};

	return API().Qualifier().getQualifireList(query);
};

const deleteQualifier = (data) => {
	const query = {
		object: "qualifier",
		data: data || {},
	};

	return API().GenericAPI().deleteTableData(query);
};

const getQualiferDetail = ({ id }) => {
	const query = {
		object: "qualifier",
		record_main_id: id,
		account_main_id: accountId,
	};

	return API().GenericAPI().getMainData(query);
};
/** --------------------------------------------------------- */

/** ----------------------- Custom API ---------------------- */

/* ---------------------------------------------------------- */
export default {
	updateQualifier,
	getQualifierMainList,
	getQualifierList,
	deleteQualifier,
	getQualiferDetail,
};
