import loadable from "@loadable/component";

export const AsyncEditableContent = loadable(() => import("./EditableContent/EditableContent"));
export const AsyncPersonAddress = loadable(() => import("./Person/PersonAddress"));
export const AsyncPersonCompany = loadable(() => import("./Person/PersonCompany"));
export const AsyncPersonPhone = loadable(() => import("./Person/PersonPhone"));
export const AsyncPersonEmail = loadable(() => import("./Person/PersonEmail"));
export const AsyncPersonPosition = loadable(() => import("./Person/PersonPosition"));
export const AsyncPersonSocialMedia = loadable(() => import("./Person/PersonSocialMedia"));
export const AsyncPersonWebsite = loadable(() => import("./Person/PersonWebsite"));
export const AsyncBulkActionPopover = loadable(() => import("./BulkActionPopover/BulkActionPopover"));
