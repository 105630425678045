import React, { useState } from "react";
import { EuiIcon } from "@elastic/eui";
import styled from "styled-components";

import AmsNoteListNewItem from "./AmsNoteListNewItem";
import AmsNoteListItem from "./AmsNoteListItem";

/**
 * Component for displaying Note list of AMS
 */
const AmsNoteList = (props) => {
    const { data, onSaveNote, onDeleteNote } = props;

    const [isAddNewNote, setIsAddNewNote] = useState(false);

    const handleClickAddNote = () => {
        setIsAddNewNote(true);
    };

    const handleSaveNewNote = (note) => {
        onSaveNote(note);
        setIsAddNewNote(false);
    };

    const handleCancelNewNote = (note) => {
        setIsAddNewNote(false);
    };

    return (
        <Wrapper>
            {data?.map((note, id) => {
                return (
                    <AmsNoteListItem
                        key={`ams-note-list-item-${id}`}
                        data={note}
                        onSaveNote={onSaveNote}
                        onDeleteNote={onDeleteNote}
                    />
                );
            })}
            {isAddNewNote ? (
                <AmsNoteListNewItem
                    onSave={handleSaveNewNote}
                    onCancel={handleCancelNewNote}
                />
            ) : (
                <div className="add-note_container" onClick={handleClickAddNote}>
                    <p className="add-note-icon">
                        <EuiIcon type="plusInCircle"></EuiIcon>
                    </p>
                    <p className="type-small-default add-note-label">Note</p>
                </div>
            )}
        </Wrapper>
    );
};

export default AmsNoteList;

const Wrapper = styled.div`
    display: flex;
    width: 100%;
    flex-direction: column;

    .add-note_container {
        display: flex;
        width: fit-content;
        color: var(--eui-link-color);
        cursor: pointer;
        align-items: center;

        .add-note-icon {
            margin-left: var(--sp-2_5);
        }

        .add-note-label {
            margin-left: var(--sp-4);
        }
    }
`;
