/**
 * Person
 */
export const FETCH_PEOPLE = "@@person/FETCH_PEOPLE";
export const SET_FETCH_PERSON_LOADING = "@@person/SET_FETCH_PERSON_LOADING";
export const SET_PERSON = "@@person/SET_PERSON";
export const SET_PERSON_LIST = "@@person/SET_PERSON_LIST";
export const SET_PERSON_RAS = "@@person/SET_PERSON_RAS";
export const SET_PERSON_RAS_RESTRICTIONS = "@@person/SET_PERSON_RAS_RESTRICTIONS";
export const SET_PERSON_DETAIL = "@@person/SET_PERSON_DETAIL";
export const SET_PERSON_FLYOUT_VISIBILITY = "@@person/SET_PERSON_FLYOUT_VISIBILITY";
export const SET_SELECTED_PERSON_TITLES = "@@person/SET_SELECTED_PERSON_TITLES";
export const SET_SELECTED_PERSON_SKILLS = "@@person/SET_SELECTED_PERSON_SKILLS";
export const SET_SELECTED_PERSON_INDUSTRIES = "@@person/SET_SELECTED_PERSON_INDUSTRIES";
export const SET_SIT = "@@person/SET_SIT";
export const BULK_UPDATE_PERSON_ADDRESS = "@@person/BULK_UPDATE_PERSON_ADDRESS";
export const BULK_UPDATE_PERSON_SIT = "@@person/BULK_UPDATE_PERSON_SIT";
export const ADD_UPDATE_PERSON_MAIN = "@@person/ADD_UPDATE_PERSON_MAIN";
export const SET_PERSON_SEARCH_VALUE = "@@person/SET_PERSON_SEARCH_VALUE";
export const SET_PERSON_ENRICHMENT_DETAILS = "@@person/SET_PERSON_ENRICHMENT_DETAILS";

/** ----------------- NEW -------------------- */
// Person Detail
export const GET_PERSON_DETAIL_NEW = "@@PERSON/GET_PERSON_DETAIL_NEW";
export const SET_PERSON_DETAIL_NEW = "@@PERSON/SET_PERSON_DETAIL_NEW";
export const SET_PERSON_FILE_VALUE = "@@PERSON/SET_PERSON_FILE_VALUE";

// Person Flyout
export const GET_PERSON_DETAIL_FLYOUT = "@@PERSON/GET_PERSON_DETAIL_FLYOUT";
export const SET_PERSON_DETAIL_FLYOUT = "@@PERSON/SET_PERSON_DETAIL_FLYOUT";

// Person Main Table
export const GET_PERSON_MAINLIST_NEW = "@@PERSON/GET_PERSON_MAINLIST_NEW";
export const SET_PERSON_MAINLIST_NEW = "@@PERSON/SET_PERSON_MAINLIST_NEW";
export const REQUEST_PERSON_MAINLIST_NEW = "@@PERSON/REQUEST_PERSON_MAINLIST_NEW";
export const RECEIVED_PERSON_MAINLIST_NEW = "@@PERSON/RECEIVED_PERSON_MAINLIST_NEW";
export const SET_OPEN_PERSON_LIST_FILTER = "@@PERSON/SET_OPEN_PERSON_LIST_FILTER";

// Person AMS Table
export const GET_PERSON_AMS_LIST = "@@PERSON/GET_PERSON_AMS_LIST";
export const SET_PERSON_AMS_LIST = "@@PERSON/SET_PERSON_AMS_LIST";
export const SET_PERSON_AMS_LIST_PARAMS = "@@PERSON/SET_PERSON_AMS_LIST_PARAMS";
export const REQUEST_PERSON_AMS_LIST = "@@PERSON/REQUEST_PERSON_AMS_LIST";
export const RECEIVED_PERSON_AMS_LIST = "@@PERSON/RECEIVED_PERSON_AMS_LIST";

// Person CMS Table
export const GET_PERSON_CMS_LIST = "@@PERSON/GET_PERSON_CMS_LIST";
export const SET_PERSON_CMS_LIST = "@@PERSON/SET_PERSON_CMS_LIST";
export const SET_PERSON_CMS_LIST_PARAMS = "@@PERSON/SET_PERSON_CMS_LIST_PARAMS";
export const REQUEST_PERSON_CMS_LIST = "@@PERSON/REQUEST_PERSON_CMS_LIST";
export const RECEIVED_PERSON_CMS_LIST = "@@PERSON/RECEIVED_PERSON_CMS_LIST";

// Person CMS Draft Table
export const GET_PERSON_CMS_DRAFT_LIST = "@@PERSON/GET_PERSON_CMS_DRAFT_LIST";
export const SET_PERSON_CMS_DRAFT_LIST = "@@PERSON/SET_PERSON_CMS_DRAFT_LIST";
export const SET_PERSON_CMS_DRAFT_LIST_PARAMS =
	"@@PERSON/SET_PERSON_CMS_DRAFT_LIST_PARAMS";
export const REQUEST_PERSON_CMS_DRAFT_LIST = "@@PERSON/REQUEST_PERSON_CMS_DRAFT_LIST";
export const RECEIVED_PERSON_CMS_DRAFT_LIST = "@@PERSON/RECEIVED_PERSON_CMS_DRAFT_LIST";

// All Person List Ids.
export const PERSON_ALL_IDS = "@@PERSON/PERSON_ALL_IDS";
/** ------------------------------------------- */
