import React from "react";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faStickyNote } from "@fortawesome/pro-light-svg-icons";
import { amsActivityLogoIcon } from "components/Common/Icons";

export const ACTIVITY_VIEW_TYPES = {
	note: {
		key: "note",
		label: "Simple Note",
		value: 1,
		icon: <FontAwesomeIcon icon={faStickyNote} size="lg" />,
	},
	activity: {
		key: "activity",
		label: "Activity View",
		value: 2,
		icon: amsActivityLogoIcon(),
	},
};

export const AMS_REFERENCE_NAME_SYSTEMS = {
	person: { key: "person", value: "PERSON" },
	job: { key: "job", value: "JOB" },
	company: { key: "company", value: "COMPANY" },
};

export const AMS_REMINDER_TIME_TYPE_LIST = {
	minutes: {
		key: "minutes",
		value: 1,
		label: "Minutes",
		className: "minutes",
		momentType: "minutes",
	},
	hours: {
		key: "hours",
		value: 2,
		label: "Hours",
		className: "hours",
		momentType: "hours",
	},
	days: {
		key: "days",
		value: 3,
		label: "Days",
		className: "days",
		momentType: "days",
	},
};

export const AMS_REMINDER_TIME_FRAME_LIST = {
	before: {
		key: "before",
		value: 1,
		label: "Before Activity",
		className: "before",
	},
	after: {
		key: "after",
		value: 2,
		label: "After Activity",
		className: "after",
	},
};

export const AMS_REMINDER_TYPE_LIST = {
	browser: {
		key: "browser",
		value: 1,
		label: "On Screen",
		className: "browser",
	},
	sms: {
		key: "sms",
		value: 2,
		label: "Text",
		className: "sms",
	},
	email: {
		key: "email",
		value: 3,
		label: "Email",
		className: "email",
	},
};

export const AMS_REMINDER_RESULT_TYPE_LIST = {
	pending: {
		key: "pending",
		value: 1,
	},
	complete: {
		key: "complete",
		value: 2,
	},
};
