const global_address_details = {
    _name: 'global_address_details',
    id: 'id',
    address_name: 'address_name',
    address_line_1: 'address_line_1',
    address_line_2: 'address_line_2',
    address_line_3: 'address_line_3',
    address_city: 'address_city',
    address_state: 'address_state',
    address_zip: 'address_zip',
    address_country: 'address_country',
    address_confirmed_c: 'address_confirmed_c',
    data_location_master_ref_id: 'data_location_master_ref_id',
}

const global_email_details = {
    _name: 'global_email_details',
    id: 'id',
    email_value: 'email_value',
    email_status_c: 'email_status_c',
    email_status_date: 'email_status_date'
}

const global_static_setting = {
    _name: 'global_static_setting',
    id: 'id',
    setting_category: 'setting_category',
    setting_name: 'setting_name',
    setting_value: 'setting_value',
}

const global_media_details = {
    _name: 'global_media_details',
    id: 'id',
    media_type_c: 'media_type_c',
    media_ref_id: 'media_ref_id',
}

const global_url_details = {
    _name: 'global_url_details',
    id: 'id',
    url_value: 'url_value',
    url_confirmed_c: 'url_confirmed_c',
}

const global_custom_setting = {
    _name: 'global_custom_setting',
    id: 'id',
    name_system: 'name_system',
    name_field: 'name_field',
    name_value: 'name_value',
    custom_value: 'custom_value',
    custom_enabled: 'custom_enabled'
}

const global_log_activity = {
    _name: 'global_log_activity',
    id: 'id',
    date_created: 'date_created',
    account_main_ref_id: 'account_main_ref_id',
    global_table_ref_id: 'global_table_ref_id',
    log_object_c: 'log_object_c',
    log_type_c: 'log_type_c',
    log_action_c: 'log_action_c',
    global_log_detail_ref_id: 'global_log_detail_ref_id',
}

const global_password_details = {
    _name: 'global_password_details',
    id: 'id',
    password_value: 'password_value'
}

const global_log_detail = {
    _name: 'global_log_detail',
    id: 'id',
    detail_text: 'detail_text'
}

const global_phone_details = {
    _name: 'global_phone_details',
    id: 'id',
    phone_number: 'phone_number',
    phone_country_code: 'phone_country_code',
    phone_extension: 'phone_extension',
    phone_status_c: 'phone_status_c'
}

const global_log_get_details = {
    _name: 'global_log_get_details',
    id: 'id',
    date_created: 'date_created',
    account_main_ref_id: 'account_main_ref_id',
    object_base_type_c: 'object_base_type_c',
    in_sql: 'in_sql',
    out_result: 'out_result',
    out_result_details: 'out_result_details'
}

export {
    global_address_details,
    global_email_details,
    global_static_setting,
    global_media_details,
    global_url_details,
    global_custom_setting,
    global_log_activity,
    global_password_details,
    global_log_detail,
    global_phone_details,
    global_log_get_details
}