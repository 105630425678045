import { combineReducers } from "redux";

import { reducer as authReducer } from "./auth";

import { reducer as loaderReducer } from "./loader";
import { reducer as globalReducer } from "./global";
import { reducer as errorReducer } from "./error";
import { reducer as openTabsReducer } from "./open-tabs";
import { reducer as flyoutReducer } from "./flyout";

import { reducer as dashboardReducer } from "./dashboard";
import { reducer as accountReducer } from "./account";
import { reducer as campaignReducer } from "./campaign";
import { reducer as companyReducer } from "./company";
import { reducer as credentialReducer } from "./credential";
import { reducer as jobReducer } from "./job";
import { reducer as messagingReducer } from "./messaging";
import { reducer as notificationReducer } from "./notification";
import { reducer as personReducer } from "./person";
import { reducer as qualifierReducer } from "./qualifier";
import { reducer as rasReducer } from "./ras";
import { reducer as securityGroupReducer } from "./security-group";
import { reducer as sitReducer } from "./sit";
import { reducer as sourcingReducer } from "./sourcing";
import { reducer as systemLogsReducer } from "./system-logs";
import { reducer as templateReducer } from "./template";
import { reducer as themeReducer } from "./theme";
import { reducer as macrosReducer } from "./macros";
import { reducer as amsReducer } from "./ams";
import { reducer as globalSearchReducer } from "./global-search";
import { reducer as plivoReducer } from "./plivo";
import { reducer as messageReducer } from "./message";
import { reducer as cmsReducer } from "./cms";
import { reducer as compensationReducer } from "./compensation";

export const rootReducer = combineReducers({
    auth: authReducer,
    plivo: plivoReducer,

    loader: loaderReducer,
    global: globalReducer,
    error: errorReducer,
    openTab: openTabsReducer,
    flyout: flyoutReducer,

    dashboard: dashboardReducer,
    account: accountReducer,
    campaign: campaignReducer,
    company: companyReducer,
    credential: credentialReducer,
    job: jobReducer,
    message: messageReducer,
    messaging: messagingReducer,
    notification: notificationReducer,
    person: personReducer,
    qualifier: qualifierReducer,
    ras: rasReducer,
    securityGroup: securityGroupReducer,
    sit: sitReducer,
    sourcing: sourcingReducer,
    template: templateReducer,
    theme: themeReducer,
    macro: macrosReducer,

    systemLogs: systemLogsReducer,
    ams: amsReducer,
    cms: cmsReducer,

    compensation: compensationReducer,

    globalSearch: globalSearchReducer,
});

export const initialState = {};
