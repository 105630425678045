import React, { useEffect, useMemo, useRef } from "react";
import styled from "styled-components";
import { get, orderBy } from "lodash";

import Message from "./Message";
import { useCms } from "core/useHooks";
import { useGlobal } from "core/useHooks";
import { getGSSValue } from "components/global/utils";
import { useAccount } from "core/useHooks";
import { usePerson } from "core/useHooks";

import { MESSAGE_ITEM_TYPES } from "./constants";

/**
 * Main Components
 */
const MessageList = (props) => {
	const { person } = props;

	const personId = get(person, "id");

	const messagesEndRef = useRef();

	const { account } = useAccount();
	const { gssList } = useGlobal();
	const { getPersonCommunicationList, getPersonCommunicationDraftList } = useCms();
	const { personCmsManualList, personCmsAutomatedList } = usePerson();

	const { messages } = useMemo(() => {
		let messages = [];
		if (personCmsAutomatedList?.data?.length) {
			personCmsAutomatedList.data.forEach((item) => {
				messages.push({
					type: MESSAGE_ITEM_TYPES.main.value,
					value: item,
					createdAt: new Date(item.date_created).getTime(),
				});
			});
		}

		if (personCmsManualList?.data.length) {
			personCmsManualList.data.forEach((item) => {
				const draftRecord = item.cms_communication_draft;
				messages.push({
					type: MESSAGE_ITEM_TYPES.draft.value,
					value: draftRecord,
					createdAt: new Date(draftRecord.date_created).getTime(),
				});
			});
		}

		messages = orderBy(messages, "createdAt", "asc");

		return {
			messages,
		};
	}, [personCmsManualList, personCmsAutomatedList]);

	useEffect(() => {
		if (messagesEndRef.current) {
			messagesEndRef.current.scroll({
				top: messagesEndRef.current.scrollHeight,
				left: 0,
				behavior: "smooth",
			});
		}
	}, [messages]);

	useEffect(() => {
		// Get the last 50 records for cms_communication_main
		const member_object_type = getGSSValue(gssList, "OBJECT_BASE", "PERSON");
		const member_object_subtype = getGSSValue(gssList, "OBJECT_PERSON", "MAIN");
		const member_object_record_id = personId ? Number(personId) : personId;

		const cmsMainParams = {
			cms_conversation_member: {
				member_object_type,
				member_object_subtype,
				member_object_record_id,
			},
			pageCount: 50,
			pageNumber: 1,
		};

		getPersonCommunicationList(cmsMainParams);

		// Get the last 50 records for cms_communication_draft
		const account_main_id = account?.id;
		const member_object_type_sc = getGSSValue(gssList, "OBJECT_BASE", "PERSON");
		const member_object_subtype_sc = getGSSValue(gssList, "OBJECT_PERSON", "MAIN");

		const cmsDraftParams = {
			account_main_id,
			member_object_type_sc,
			member_object_subtype_sc,
			member_object_record_id,
			pageCount: 50,
			pageNumber: 1,
		};

		getPersonCommunicationDraftList(cmsDraftParams);
	}, [account.id, gssList, personId]);

	return (
		<Wrapper ref={messagesEndRef}>
			{messages?.map((message, index) => (
				<Message key={"message-list-" + index} message={message} />
			))}
		</Wrapper>
	);
};

export default MessageList;

/**
 * Styled Components
 */
const Wrapper = styled.div`
	padding: var(--sp-4);
	height: 350px;
	overflow-y: auto;
	border-bottom: solid 1px var(--border-color);
`;
