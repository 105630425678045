import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useDispatch } from "react-redux";
import styled from "styled-components";
import {
    EuiPopover,
    EuiFlexItem,
    EuiButtonIcon,
    EuiFlexGroup,
    EuiComboBox,
    EuiButton,
    EuiFieldText,
    EuiFieldSearch,
} from "@elastic/eui";
import { cloneDeep, get, includes, remove } from "lodash";

import { _getGlobalSearchItems } from "helpers/selectors";
import { getGlobalSearchItems } from "helpers/actions";
import * as MODEL from "core/model";
import { rectangleTIcon } from "components/Common/Icons";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUserPlus } from "@fortawesome/pro-light-svg-icons";

import { PERSON_SELECT_TYPES } from "../constants";
import GlobalSearchPerson from "components/Common/GlobalSearch/GlobalSearchPerson";

/**
 * Component for Adding Email of Persons
 */
const AddPersonEmailItem = (props) => {
    const { onSave, accounts, savePersonPhone, deletePerson, savePersonEmail } = props;

    const dispatch = useDispatch();

    const [isOpenPopover, setIsOpenPopover] = useState(false);
    const [selectedPersons, setSelectedPersons] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [searchValue, setSeachValue] = useState("");
    const [selectedType, setSelectedType] = useState(PERSON_SELECT_TYPES.person.value);

    const globalSearchItems = _getGlobalSearchItems();
    const persons = globalSearchItems?.people || [];

    const listAccountRecords = useMemo(() => {
        const records = [];
        if (accounts?.length > 0) {
            accounts.forEach((item) => {
                const accountInfo = item;
                if (accountInfo?.name_first || accountInfo?.name_last) {
                    records.push({
                        label: accountInfo?.name_first + " " + accountInfo?.name_last,
                        id: accountInfo?.id,
                        value: accountInfo?.id,
                        info: accountInfo,
                        type: PERSON_SELECT_TYPES.account.value,
                    });
                }
            });
        }

        return records;
    }, [accounts]);

    const setLoading = () => setIsLoading(true);
    const clearLoading = () => setIsLoading(false);

    const getPersons = (search_term) => {
        setLoading();
        dispatch(
            getGlobalSearchItems(
                { search_term },
                () => {
                    clearLoading();
                },
                () => {
                    clearLoading();
                }
            )
        );
    };

    const handleChangeOptions = (selectedOptions) => {
        const formatedSelectedOptions = selectedOptions.map((option) => {
            return {
                ...option,
                selected: 0,
            };
        });
        setSelectedPersons(formatedSelectedOptions);
    };

    const handleChangeEmail = (e) => {
        const email = e.target.value;
        const newItem = {
            value: email,
            label: email,
            info: email,
            type: PERSON_SELECT_TYPES.direct.value,
            selected: 0,
        };
        setSelectedPersons([newItem]);
    };

    const onSearchChangePerson = useCallback((searchValue) => {
        if (selectedPersons.length === 0) {
            getPersons(searchValue);
        }
    }, []);

    const handleOpenPopover = () => {
        setIsOpenPopover(true);
    };

    const handleClosePopover = () => {
        setIsOpenPopover(false);
        setSelectedPersons([]);
        setSelectedType(PERSON_SELECT_TYPES.person.value);
        setSeachValue("");
    };

    const handleTogglePopover = () => {
        if (isOpenPopover) {
            handleClosePopover();
        } else {
            handleOpenPopover();
        }
    };

    const handleSave = () => {
        if (onSave) {
            onSave(selectedPersons);
        }
        handleClosePopover();
    };

    const handleCancel = () => {
        handleClosePopover();
    };

    const handleChangeSelectType = (newType) => {
        if (newType === selectedType) {
            setSelectedType(PERSON_SELECT_TYPES.person.value);
        } else {
            setSelectedType(newType);
        }
    };

    const handleSearchItem = (val) => {
        getPersons(val);
        setSeachValue(val);
    };

    const showSuggestions = () => {
        handleSearchItem(searchValue);
    };

    const handleGlobalSearch = () => {
        getPersons(searchValue);
    };

    const handleChangeSeletedPersonItems = (personId, checked) => {
        if (personId !== undefined || personId !== null) {
            const personIds = cloneDeep(selectedPersons).map((person) => person.value);
            const isPersonId = includes(personIds, personId);

            if (checked) {
                // Add person id to selectedItems
                if (!isPersonId) {
                    personIds.push(personId);
                }
            } else {
                // Delete person Id from selectedItems
                if (isPersonId) {
                    remove(personIds, (id) => id === personId);
                }
            }

            const newPersons = [];
            if (persons?.length > 0) {
                persons.forEach((item) => {
                    const personInfo = get(item, `${MODEL.person_main._name}`);
                    if (includes(personIds, personInfo?.id)) {
                        if (personInfo?.name_first || personInfo?.name_last) {
                            newPersons.push({
                                label:
                                    personInfo?.name_first + " " + personInfo?.name_last,
                                id: personInfo?.id,
                                value: personInfo?.id,
                                info: personInfo,
                                type: PERSON_SELECT_TYPES.person.value,
                                selected: 0,
                            });
                        }
                    }
                });
            }

            setSelectedPersons(newPersons);
        }
    };

    useEffect(() => {
        // Simulate initial load.
        onSearchChangePerson("");
    }, [onSearchChangePerson]);

    useEffect(() => {
        setSelectedPersons([]);
    }, [selectedType]);

    const renderSelector = () => {
        if (selectedType === PERSON_SELECT_TYPES.person.value) {
            return (
                <EuiFieldSearch
                    id="cms-email-add-search-item-person"
                    value={searchValue}
                    onChange={(e) => handleSearchItem(e.target.value)}
                    placeholder="Select people"
                    aria-label="global-search"
                    isClearable={false}
                    onFocus={showSuggestions}
                />
            );
        } else if (selectedType === PERSON_SELECT_TYPES.direct.value) {
            return (
                <EuiFieldText
                    id="cms-email-add-search-item-direct"
                    placeholder="Enter email"
                    value={selectedPersons[0]?.value || ""}
                    onChange={handleChangeEmail}
                />
            );
        } else {
            return (
                <EuiComboBox
                    id="cms-email-add-search-item-account"
                    options={listAccountRecords}
                    placeholder="Search users"
                    selectedOptions={selectedPersons}
                    onChange={handleChangeOptions}
                    onSearchChange={onSearchChangePerson}
                    singleSelection={{ asPlainText: true }}
                />
            );
        }
    };

    return (
        <Wrapper
            button={
                <div className={`button-wrapper`}>
                    <EuiButtonIcon
                        color="primary"
                        onClick={handleTogglePopover}
                        iconType="plusInCircleFilled"
                        aria-label="Add"
                    />
                </div>
            }
            isOpen={isOpenPopover}
            closePopover={handleClosePopover}
            panelPaddingSize="s"
            anchorPosition="downCenter"
            panelStyle={{
                minWidth: 250,
            }}
        >
            <BodyContainer direction="column" gutterSize="s">
                <EuiFlexItem grow={false}>
                    <EuiFlexGroup
                        gutterSize="xs"
                        alignItems="center"
                        style={{ background: "var(--bg-color)" }}
                    >
                        <EuiFlexItem>{renderSelector()}</EuiFlexItem>
                        <EuiFlexItem grow={false}>
                            <EuiFlexGroup
                                className="select__buttons--container"
                                gutterSize="none"
                                alignItems="center"
                            >
                                <EuiFlexItem>
                                    <EuiButton
                                        className="button-fit-content select-button"
                                        onClick={() =>
                                            handleChangeSelectType(
                                                PERSON_SELECT_TYPES.direct.value
                                            )
                                        }
                                        color={
                                            PERSON_SELECT_TYPES.direct.value ===
                                            selectedType
                                                ? "primary"
                                                : "text"
                                        }
                                        fill={
                                            PERSON_SELECT_TYPES.direct.value ===
                                            selectedType
                                        }
                                    >
                                        {rectangleTIcon()}
                                    </EuiButton>
                                </EuiFlexItem>
                                <EuiFlexItem>
                                    <EuiButton
                                        className="button-fit-content select-button"
                                        onClick={() =>
                                            handleChangeSelectType(
                                                PERSON_SELECT_TYPES.account.value
                                            )
                                        }
                                        color={
                                            PERSON_SELECT_TYPES.account.value ===
                                            selectedType
                                                ? "primary"
                                                : "text"
                                        }
                                        fill={
                                            PERSON_SELECT_TYPES.account.value ===
                                            selectedType
                                        }
                                    >
                                        <FontAwesomeIcon icon={faUserPlus} />
                                    </EuiButton>
                                </EuiFlexItem>
                            </EuiFlexGroup>
                        </EuiFlexItem>
                    </EuiFlexGroup>
                </EuiFlexItem>
                {selectedType === PERSON_SELECT_TYPES.person.value && (
                    <EuiFlexItem>
                        <GlobalSearchPerson
                            data={persons}
                            onGlobalSearch={handleGlobalSearch}
                            selectedItems={selectedPersons.map((person) => person.value)}
                            onChangeSeletedItems={handleChangeSeletedPersonItems}
                            savePersonPhone={savePersonPhone}
                            deletePerson={deletePerson}
                            savePersonEmail={savePersonEmail}
                        />
                    </EuiFlexItem>
                )}
                {onSave && (
                    <EuiFlexItem grow={false}>
                        <EuiFlexGroup gutterSize="s" justifyContent="center">
                            <EuiFlexItem grow={false}>
                                <EuiButtonIcon
                                    color="success"
                                    onClick={handleSave}
                                    iconType="check"
                                />
                            </EuiFlexItem>
                            <EuiFlexItem grow={false}>
                                <EuiButtonIcon
                                    color="danger"
                                    onClick={handleCancel}
                                    iconType="cross"
                                />
                            </EuiFlexItem>
                        </EuiFlexGroup>
                    </EuiFlexItem>
                )}
            </BodyContainer>
        </Wrapper>
    );
};

export default AddPersonEmailItem;

const Wrapper = styled(EuiPopover)`
    .button-wrapper {
        display: flex;
        align-items: flex-start;
    }
`;

const BodyContainer = styled(EuiFlexGroup)`
    .euiFormControlLayout {
        height: fit-content;
    }
    .select__buttons--container {
        height: 100%;
        background: var(--bg-color);
        border-radius: 0 var(--sp-1) var(--sp-1) 0;
        margin-top: var(--sp-0_5);

        .select-button {
            border-color: transparent;
            border-radius: 0;
            box-shadow: unset;
            width: var(--sp-15) !important;
        }

        svg {
            height: var(--sp-8);
            width: auto;
        }
    }
`;
