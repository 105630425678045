import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import ActivityCard from './ActivityCard';

import { personActivities } from './dummy';

/**
 * Main Components
 */
const OpenActivityContent = (props) => {
    const { ...restProps } = props;

    const [data, setData] = useState(null);

    // test data
    useEffect(() => {
        setData(personActivities);
    }, []);

    return (
        <Wrapper {...restProps}>
            {data &&
                data.length > 0 &&
                data.map((elem, index) => (
                    <ActivityCard data={elem} key={index} />
                ))}
        </Wrapper>
    );
};

export default OpenActivityContent;

/**
 * Styled Components
 */
const Wrapper = styled.div`
    padding: 10px 0px;
`;
