import React, { useMemo, useState } from "react";
import styled from "styled-components";
import {
	EuiFlexGroup,
	EuiFlexItem,
	EuiText,
	EuiButtonIcon,
	EuiPopover,
} from "@elastic/eui";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
	faLaptop,
	faPlaneDeparture,
	faTruckMoving,
} from "@fortawesome/pro-regular-svg-icons";
import { get } from "lodash";

import { WORK_TYPES } from "./constants";
import { stopMask } from "components/Common/Icons";

import * as MODEL from "core/model";

/**
 * Component for Compensation Overview Item
 */
const CompensationOverviewItem = (props) => {
	const { compensation, editable = false, onOpenItem } = props;

	const [showPopover, setShowPopover] = useState(false);

	const closeShowPopover = () => setShowPopover(false);
	const toggleShowPopover = (e) => {
		e.stopPropagation();
		setShowPopover(!showPopover);
	};

	const { workType, isRemote, hasTravel } = useMemo(() => {
		const financial = get(
			compensation,
			`${MODEL.compensation_financial._name}[0]`,
			{}
		);
		const workType = Object.values(WORK_TYPES).find(
			(type) =>
				type.id === financial?.[MODEL.compensation_financial.financial_type_c]
		);
		const location = get(compensation, `${MODEL.compensation_location._name}[0]`, {});
		const commute = get(compensation, `${MODEL.compensation_commute._name}[0]`, {});
		const isRemote = location?.location_type_c === 4;
		const hasTravel = !!commute?.[MODEL.compensation_commute.travel_required];

		return {
			workType,
			isRemote,
			hasTravel,
		};
	}, [compensation]);

	const handleEditCompensation = () => {
		if (editable) {
			onOpenItem();
		}
	};

	const handleCopyCompensation = () => {
		console.log("copy compensation");
	};

	const handleDeleteCompensation = () => {
		console.log("delete compensation");
	};

	return (
		<Wrapper editable={editable}>
			<EuiFlexGroup
				gutterSize="none"
				direction="row"
				alignItems="center"
				justifyContent="spaceBetween"
				onClick={handleEditCompensation}
			>
				<EuiFlexItem>
					<EuiFlexGroup
						gutterSize="s"
						direction="column"
						alignItems={editable ? "flexStart" : "center"}
					>
						<EuiFlexItem>
							<EuiText>
								<strong className="contract-summary">
									{`$${
										compensation?.payStructure?.salary?.toLocaleString(
											"en-US"
										) || "-"
									} `}
									{`($${compensation?.payStructure?.hourly || "-"}/hr)`}
									{` - ${workType?.label || "-"}`}
								</strong>
							</EuiText>
						</EuiFlexItem>
						<EuiFlexItem>
							<EuiFlexGroup
								gutterSize="s"
								direction="row"
								alignItems="center"
								justifyContent={editable ? "flexStart" : "center"}
								className="contents"
							>
								<EuiFlexItem className="location" grow={false}>
									<EuiText color="subdued" size="s">
										{compensation?.location?.address || "-"} {"<"}{" "}
										{compensation?.location?.distance || "-"} miles
									</EuiText>
								</EuiFlexItem>
								<EuiFlexItem className="icon" grow={false}>
									<FontAwesomeIcon icon={faLaptop} />
								</EuiFlexItem>
								<EuiFlexItem className="icon" grow={false}>
									<FontAwesomeIcon icon={faPlaneDeparture} />
									{!hasTravel ? stopMask() : null}
								</EuiFlexItem>
								<EuiFlexItem className="icon" grow={false}>
									<FontAwesomeIcon icon={faTruckMoving} />
									{!isRemote ? stopMask() : null}
								</EuiFlexItem>
							</EuiFlexGroup>
						</EuiFlexItem>
					</EuiFlexGroup>
				</EuiFlexItem>
				{editable ? (
					<EuiFlexItem grow={false}>
						<EuiPopover
							ownFocus
							button={
								<EuiButtonIcon
									className="right-button"
									aria-label="add compensation"
									iconType="arrowRight"
									onClick={toggleShowPopover}
								/>
							}
							isOpen={showPopover}
							closePopover={closeShowPopover}
							anchorPosition="rightCenter"
							hasArrow={false}
							panelPaddingSize="l"
							panelClassName="compensation-additional-buttons-container"
							buffer={[10, 10, 10, 10]}
						>
							<EuiFlexGroup
								grow={false}
								className="additional-buttons"
								direction="column"
							>
								<EuiButtonIcon
									className="copy-button"
									aria-label="copy compensation"
									iconType="copy"
									color="text"
									onClick={handleCopyCompensation}
								/>
								<EuiButtonIcon
									className="copy-button"
									aria-label="copy compensation"
									iconType="trash"
									onClick={handleCopyCompensation}
									color="text"
								/>
							</EuiFlexGroup>
						</EuiPopover>
					</EuiFlexItem>
				) : null}
			</EuiFlexGroup>
		</Wrapper>
	);
};

export default CompensationOverviewItem;

const Wrapper = styled.div`
	display: flex;
	position: relative;
	align-items: center;
	justify-content: left;

	${(props) => props.editable && `cursor: pointer;`}

	.contract-summary {
		font-size: 14px;
		color: #006bb4;
	}

	.euiCard__description {
		display: none;
	}

	.contents {
		margin-top: -8px;
	}

	.location {
		& > div {
			white-space: nowrap;
		}
	}

	.icon {
		position: relative;
		align-self: center;

		& > svg:first-child {
			color: #69707d;
		}

		& .stop-mask {
			position: absolute;
		}
	}

	.right-button {
		color: #006bb4;
	}
`;
