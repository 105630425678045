import { useDispatch } from "react-redux";

import { addPersonMessage, removePersonMessage } from "helpers/actions";
import { _getMessageList } from "helpers/selectors";
import { get } from "lodash";

/**
 * Hooks for Message
 */
const useMessage = () => {
	const dispatch = useDispatch();

	const messages = _getMessageList();

	const addMessage = (personId, message, successCallBack, failCallBack) => {
		const params = {
			personId,
			message,
		};
		dispatch(addPersonMessage(params, successCallBack, failCallBack));
	};

	const removeMessage = (personId, messageId, successCallBack, failCallBack) => {
		const params = {
			personId,
			messageId,
		};
		dispatch(removePersonMessage(params, successCallBack, failCallBack));
	};

	const getMessagesForPerson = (personId) => {
		const personMessage = get(messages, `${personId}`) || [];
		return personMessage;
	};

	return {
		messages,
		addMessage,
		removeMessage,
		getMessagesForPerson,
	};
};

export default useMessage;
