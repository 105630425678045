import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { EuiAccordion, EuiIcon } from "@elastic/eui";

const AmsListAccordion = (props) => {
	const { children, isOpen = null, title } = props;

	const [open, setOpen] = useState(false);

	useEffect(() => {
		if (isOpen !== null) {
			setOpen(isOpen);
		}
	}, [isOpen]);

	const onToggle = () => {
		setOpen(!open);
	};

	return (
		<Wrapper>
			<div className="header-wrapper">
				<div className="header-title">{title}</div>
				<div className="toggle-button__container">
					<button onClick={onToggle}>
						<EuiIcon type={open ? "arrowDown" : "arrowUp"} />
					</button>
				</div>
			</div>
			<EuiAccordion
				id={`ams-list-container-${title}`}
				paddingSize="s"
				arrowDisplay="none"
				buttonContent={null}
				forceState={open ? "open" : "closed"}
				onClick={() => {}}
				className="ams-list-content__container"
			>
				{children}
			</EuiAccordion>
		</Wrapper>
	);
};

export default AmsListAccordion;

const Wrapper = styled.div`
	width: 100%;

	.header-wrapper {
		width: 100%;
		display: flex;
		justify-content: space-between;
		align-items: center;
		background: var(--eui-lightest-shade-color);
		padding: var(--sp-6) var(--sp-4);

		.header-title {
			display: flex;
			font-weight: 600;
			font-size: var(--sp-6);
			line-height: var(--sp-9);
		}

		.toggle-button__container {
			display: flex;
			color: var(--link-color);
		}
	}

	.ams-list-content__container {
		margin-top: calc(var(--sp-2_5) * -1);
		background-color: var(--white-color);
		border-radius: var(--sp-2);
		padding: 0 !important;
		margin: 0 !important;
	}
`;
