import React, { useState, useMemo } from "react";
import styled from "styled-components";
import { EuiFlexGroup, EuiFlexItem, EuiButtonEmpty, EuiPopover } from "@elastic/eui";

import {
	amsPhoneBookIcon,
	amsPhoneIcon,
	phoneCellIcon,
	workIcon,
	emailIcon,
	emailGlobeIcon,
} from "components/Common/Icons/Icons";
import { EditableEmail, EditablePhone } from "components/Common";

/**
 * Component for displaying information of AMS Personal Contact
 */
const AmsCompanyContactItem = (props) => {
	const {
		type,
		emails = [],
		phones = [],
		onSaveEmail,
		onDeleteEmail,
		onSavePhone,
		onDeletePhone,
	} = props;

	const [isOpenPopover, setIsOpenPopover] = useState(false);

	const handleClosePopover = () => {
		setIsOpenPopover(false);
	};

	const handleClickOpen = () => {
		setIsOpenPopover(true);
	};

	const renderButton = () => {
		const iconPhone = type === "contact" ? phoneCellIcon() : workIcon();
		const iconEmail = type === "contact" ? emailGlobeIcon() : emailIcon();
		return (
			<EuiButtonEmpty
				onClick={handleClickOpen}
				className="button-fit-content edit-button"
			>
				<EuiFlexGroup gutterSize="s" alignItems="center">
					<EuiFlexItem grow={false}>
						{type === "contact" ? amsPhoneIcon() : amsPhoneBookIcon()}
					</EuiFlexItem>

					{phones.length > 0 ? (
						<EuiFlexItem grow={false}>{iconPhone}</EuiFlexItem>
					) : null}

					{emails.length > 0 ? (
						<EuiFlexItem grow={false}>{iconEmail}</EuiFlexItem>
					) : null}
				</EuiFlexGroup>
			</EuiButtonEmpty>
		);
	};

	const handleSaveEmail = (data) => {
		onSaveEmail(data);
	};

	const handleDeleteEmail = (emailId) => {
		onDeleteEmail(emailId);
	};

	const handleSavePhone = (data) => {
		onSavePhone(data);
	};

	const handleDeletePhone = (phoneId) => {
		onDeletePhone(phoneId);
	};

	const { emailItems } = useMemo(() => {
		const emailItems = emails?.map((item, idx) => {
			return (
				<EditableEmail
					key={`email-existing-item-${idx}`}
					email={item}
					isPrimary={false}
					onSave={handleSaveEmail}
					onDelete={handleDeleteEmail}
				/>
			);
		});

		return { emailItems };
	}, [emails]);

	const { phoneItems } = useMemo(() => {
		const phoneItems = phones?.map((item, idx) => {
			return (
				<EditablePhone
					key={`phone-existing-item-${idx}`}
					phone={item}
					isPrimary={false}
					onSave={handleSavePhone}
					onDelete={handleDeletePhone}
					isFixedType={false}
					fixedType={0}
				/>
			);
		});

		return { phoneItems };
	}, [phones]);

	return (
		<Wrapper>
			<EuiPopover
				isOpen={isOpenPopover}
				button={renderButton()}
				closePopover={handleClosePopover}
				anchorPosition="downCenter"
				hasArrow={true}
				panelStyle={{
					width: 275,
					padding: "0.5rem 0.75rem",
					marginTop: -10,
				}}
			>
				<EuiFlexGroup direction="column" gutterSize="s">
					<EuiFlexItem>
						<EuiFlexGroup gutterSize="none" alignItems="center">
							<p>Personal</p>
						</EuiFlexGroup>
					</EuiFlexItem>
					<EuiFlexItem>
						<EuiFlexGroup gutterSize="s" direction="column">
							{phoneItems?.map((item, idx) => {
								return (
									<EuiFlexItem key={`ams-company-phone-item-${idx}`}>
										{item}
									</EuiFlexItem>
								);
							}) || null}
							{emailItems?.map((item, idx) => {
								return (
									<EuiFlexItem key={`ams-company-email-item-${idx}`}>
										{item}
									</EuiFlexItem>
								);
							}) || null}
						</EuiFlexGroup>
					</EuiFlexItem>
				</EuiFlexGroup>
			</EuiPopover>
		</Wrapper>
	);
};

export default AmsCompanyContactItem;

const Wrapper = styled.div`
	display: flex;

	.edit-button {
		.euiButtonContent.euiButtonEmpty__content {
			// padding: 0 !important;
		}
	}
`;
