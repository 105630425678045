/**
 * Email Type List for Workflow Email node
 */
export const WORKFLOW_EMAIL_TYPES = {
	toPersonal: {
		key: "toPersonalEmail",
		id: "toPersonalEmail",
		label: "Send to Personal Emails",
		value: 1,
		details: { email_type_c: 1, type: "to" },
	},
	ccPersonal: {
		key: "ccPersonalEmail",
		id: "ccPersonalEmail",
		label: "CC Other Personal Emails",
		value: 2,
		details: { email_type_c: 1, type: "cc" },
	},
	toWork: {
		key: "toWorkEmail",
		id: "toWorkEmail",
		label: "Send to Work Emails",
		value: 3,
		details: { email_type_c: 2, type: "to" },
	},
	ccWork: {
		key: "ccWorkEmail",
		id: "ccWorkEmail",
		label: "CC Other Work Emails",
		value: 4,
		details: { email_type_c: 2, type: "cc" },
	},
};

/**
 * Phone Type List for Workflow SMS node
 *
 * recipients: [
 *	{
 *		phone_type: "cell"      // Send to Personal Cell,
 *		phone_type_c: : 2       // 'global', 'phone_type_c', 'cell', '2'
 *	},
 *	{
 *		phone_type: "workcell"  // Send to Work Cell,
 *		phone_type_c: 5         // 'global', 'phone_type_c', 'workcell', '5'
 *	}
 * ]
 */
export const WORKFLOW_PHONE_TYPES = {
	toPersonalCell: {
		key: "toPersonalCell",
		id: "toPersonalCell",
		label: "Send to Personal Cell",
		value: 1,
		details: { phone_type_c: 2, phone_type: "cell" },
	},
	toWorkCell: {
		key: "toWorkCell",
		id: "toWorkCell",
		label: "Send to Work Cell",
		value: 2,
		details: { phone_type_c: 2, phone_type: "workcell" },
	},
};

/**
 * Workflow Types
 */
export const WORKFLOW_TYPE_LIST = {
	generic: { key: "generic", value: 1 },
	drip: { key: "drip", value: 2 },
};
