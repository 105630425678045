import React, { useMemo, useState } from "react";
import styled from "styled-components";
import { EuiPopover, EuiSelect } from "@elastic/eui";

import * as MODEL from "core/model";

/**
 * Component for Message Account Setting Item with editable feature
 */
const MessageAccountSettingItem = (props) => {
	const { accounts, ownerId, account, onChange } = props;

	const [isOpenPopover, setIsOpenPopover] = useState(false);

	const owner = accounts?.find((item) => item.id === ownerId);

	const isMe = owner && owner?.id === account?.id;
	const ownerFirstName = owner?.[MODEL.account_main.name_first] || "";
	const ownerLastName = owner?.[MODEL.account_main.name_last] || "";
	const ownerName = isMe ? "me" : `${ownerFirstName} ${ownerLastName || "-"}`;

	const listOwners = useMemo(() => {
		const owners = [];
		if (accounts && accounts.length > 0) {
			accounts.forEach((item) => {
				if (item?.name_first || item?.name_last) {
					owners.push({
						text: item.name_first + " " + item.name_last,
						id: item.id,
						value: item.id,
					});
				}
			});
		}

		return owners;
	}, [accounts]);

	const handleOpenPopover = () => {
		setIsOpenPopover(true);
	};

	const handleClosePopover = () => {
		setIsOpenPopover(false);
	};

	const handleChangeAccountId = (e) => {
		const newOwnerId = Number(e.target.value);
		const newOwner = accounts.find((item) => item.id === newOwnerId);

		if (onChange) {
			onChange(newOwner);
		}

		setIsOpenPopover(false);
	};

	return (
		<EuiPopover
			isOpen={isOpenPopover}
			button={
				<MessageOwner
					onClick={handleOpenPopover}
					className="cursor-pointer hover-underline"
					style={{ color: "var(--eui-link-color)" }}
				>
					{ownerName}
				</MessageOwner>
			}
			closePopover={handleClosePopover}
			panelStyle={{
				width: 170,
				padding: "0.5rem 0.75rem",
			}}
		>
			<EuiSelect
				id="message-ams-account-setting-item"
				options={listOwners}
				value={owner?.id || ""}
				onChange={handleChangeAccountId}
			/>
		</EuiPopover>
	);
};

export default MessageAccountSettingItem;

const MessageOwner = styled.div`
	font-size: var(--sp-7);
	line-height: var(--sp-7);
`;
