import React, { useEffect, useMemo, useState } from "react";
import styled from "styled-components";
import { EuiPopover } from "@elastic/eui";

import EditableRAS from "./EditableRAS";
import StaticRASItem from "./StaticRASItem";
import { useAccount } from "core/useHooks";

/**
 * Component for RAS Item
 */
const RASItem = (props) => {
	const {
		owners,
		assignees,
		restriction,
		onSave,
		editable = true,
		compressed = false,
		initLableStyle = {},
	} = props;

	const { accounts, account } = useAccount();

	const [isPopoverOpen, setIsPopoverOpen] = useState(false);

	const accountsHash = useMemo(() => {
		const hashes = {};
		if (accounts?.length > 0) {
			accounts.forEach((account) => {
				if (account.id) {
					const { name_first: nameFirst, name_last: nameLast } = account;
					const name = nameFirst + " " + nameLast;

					hashes[account.id] = name;
				}
			});
		}

		return hashes;
	}, [accounts]);

	const { ownersHash, hasAccountOwner } = useMemo(() => {
		let hasAccountOwner = false;
		const ownersHash = {};
		if (owners?.length > 0) {
			owners.forEach((owner) => {
				const { assignment_ref_id, id } = owner;
				if (id && assignment_ref_id) {
					ownersHash[assignment_ref_id] = owner;
					if (assignment_ref_id === account?.id) {
						hasAccountOwner = true;
					}
				}
			});
		}

		return { ownersHash, hasAccountOwner };
	}, [owners, account]);

	const assigneesHash = useMemo(() => {
		const hashes = {};
		if (assignees?.length > 0) {
			assignees.forEach((assignee) => {
				const { assignment_ref_id, id } = assignee;
				if (id && assignment_ref_id) {
					hashes[assignment_ref_id] = assignee;
				}
			});
		}

		return hashes;
	}, [assignees]);

	const closePopover = () => {
		setIsPopoverOpen(false);
	};

	const handleCancel = () => {
		closePopover();
	};

	const handleChangePopover = () => {
		if (editable && hasAccountOwner) {
			setIsPopoverOpen((isPopoverOpen) => !isPopoverOpen);
		}
	};

	const handleSaveRAS = (data) => {
		// data = {
		// 		deleteOwnerIds,
		// 		addOwnerIds,
		// 		deleteAssigneeIds,
		// 		addAssigneeIds,
		// }
		onSave(data);
		closePopover();
	};

	useEffect(() => {
		if (!editable) {
			setIsPopoverOpen(false);
		}
	}, [editable]);

	return (
		<Wrapper editable={editable && hasAccountOwner}>
			<EuiPopover
				style={{
					display: "flex",
					justifyContent: "center",
					alignContent: "center",
					marginTop: compressed ? "0" : "4px",
					marginLeft: compressed ? "0" : "4px",
				}}
				ownFocus
				initialFocus
				button={
					<div onClick={handleChangePopover} className="static-item--container">
						<StaticRASItem
							ownersHash={ownersHash}
							assigneesHash={assigneesHash}
							restriction={restriction}
							accountsHash={accountsHash}
							editable={editable && hasAccountOwner}
							compressed={compressed}
							initLableStyle={initLableStyle}
						/>
					</div>
				}
				isOpen={isPopoverOpen}
				closePopover={closePopover}
			>
				<EditableRAS
					accountsHash={accountsHash}
					ownersHash={ownersHash}
					assigneesHash={assigneesHash}
					restriction={restriction}
					onSave={handleSaveRAS}
					onCancel={handleCancel}
				/>
			</EuiPopover>
		</Wrapper>
	);
};

export default RASItem;

const Wrapper = styled.div`
	.static-item--container {
		cursor: ${({ editable }) => (editable ? "pointer" : "default")};
	}
`;
