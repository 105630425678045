import React from "react";
import styled from "styled-components";
import { arrowLeftLight, arrowRightLight } from "components/Common/Icons";


const SliderContainer = (props) => {
	const { children, settings, ...restProps } = props;

	const defaultSettings = {
		infinite: true,
		speed: 500,
		slidesToShow: 1,
		slidesToScroll: 1,
		nextArrow: <ArrowWrapper>{arrowRightLight()}</ArrowWrapper>,
		prevArrow: <ArrowWrapper>{arrowLeftLight()}</ArrowWrapper>,
		className: "slider-container",
		easing: true,
	};

	return (
		<Wrapper {...restProps}>

		</Wrapper>
	);
};

export default SliderContainer;

const Wrapper = styled.div`
	.slick-track > div > div {
		display: flex;
	}
`;

const ArrowWrapper = styled.button`
	display: flex;
	align-items: center;
	justify-content: center;
`;
