import { get } from "lodash";

import * as actionTypes from "./actionTypes";
import { getResultFromApiResponse } from "helpers/utilities";
import Service from "core/service";

/* ---------------------------------- NEW ---------------------------------------------------------- */
export const setRasAssignments = (data) => {
    return {
        type: actionTypes.SET_RAS_ASSIGNMENTS,
        payload: data,
    };
};

export const setRasRestrictions = (data) => {
    return {
        type: actionTypes.SET_RAS_RESTRICTIONS,
        payload: data,
    };
};

export const updateRAS_NEW = (params, successCallback, failCallback) => {
    return async (dispatch) => {
        if (!params) {
            failCallback(null);
        }

        try {
            let payload = params;

            const result = await Service.ras.updateRAS(payload);

            if (!get(result, "error")) {
                if (successCallback) {
                    const res = getResultFromApiResponse(result);
                    successCallback(res?.procedure_results);
                }
            } else {
                failCallback(result);
            }
        } catch (err) {
            if (failCallback) {
                failCallback(err);
            }
        }
    };
};

export const deleteRAS_NEW = (params, successCallback, failCallback) => {
    return async (dispatch) => {
        if (!params) {
            failCallback(null);
        }

        try {
            let payload = params;

            const result = await Service.ras.deleteRAS(payload);

            if (!get(result, "error")) {
                if (successCallback) {
                    const res = getResultFromApiResponse(result);
                    successCallback(res?.procedure_results);
                }
            } else {
                failCallback(result);
            }
        } catch (err) {
            if (failCallback) {
                failCallback(err);
            }
        }
    };
};

export const getRASAssignmentByObject = (id, type, successCallback, failCallback) => {
    return async (dispatch) => {
        let data = [];
        try {
            const result = await Service.ras.getRASAssignmentByObject({
                object_ref_id: id,
                object_type_c: type,
            });

            data = get(result, "records[0]", []);

            if (data) {
                if (successCallback) {
                    successCallback(data);
                }
            } else {
                if (failCallback) {
                    failCallback(data);
                }
                data = [];
            }
        } catch (err) {
            if (failCallback) {
                failCallback(err);
            }
        } finally {
            dispatch(setRasAssignments(data));
        }
    };
};

export const getRASRestrictionByObject = (id, type, successCallback, failCallback) => {
    return async (dispatch) => {
        let data = [];
        try {
            const result = await Service.ras.getRASRestrictionByObject({
                object_ref_id: id,
                object_type_c: type,
            });

            data = get(result, "records[0]", []);

            if (data) {
                if (successCallback) {
                    successCallback(data);
                }
            } else {
                if (failCallback) {
                    failCallback(data);
                }
                data = [];
            }
        } catch (err) {
            if (failCallback) {
                failCallback(err);
            }
        } finally {
            dispatch(setRasRestrictions(data));
        }
    };
};
