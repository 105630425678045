const company_main = {
	_name: "company_main",
	id: "id",
	company_name: "company_name",
	company_alias: "company_alias",
	company_size_c: "company_size_c",
	company_type_c: "company_type_c",
	company_date_founded: "company_date_founded",
};

const company_email = {
	_name: "company_email",
	id: "id",
	company_main_ref_id: "company_main_ref_id",
	email_type_c: "email_type_c",
	global_email_details_ref_id: "global_email_details_ref_id",
};

const company_attachment = {
	_name: "company_attachment",
	id: "id",
	company_main_ref_id: "company_main_ref_id",
	attachment_type_c: "attachment_type_c",
	system_file_ref_id: "system_file_ref_id",
};

const company_sit = {
	_name: "company_sit",
	id: "id",
	company_main_ref_id: "company_main_ref_id",
	sit_main_ref_id: "sit_main_ref_id",
	sit_level_gc: "sit_level_gc",
	sit_focus_gc: "sit_focus_gc",
};

const company_external_link = {
	_name: "company_external_link",
	id: "id",
	company_main_ref_id: "company_main_ref_id",
	headline_value: "headline_value",
	global_link_type_c: "global_link_type_c",
	global_url_details_ref_id: "global_url_details_ref_id",
};

const company_phone = {
	_name: "company_phone",
	id: "id",
	company_main_ref_id: "company_main_ref_id",
	phone_type_c: "phone_type_c",
	global_phone_details_ref_id: "global_phone_details_ref_id",
};

const company_log_activity = {
	_name: "company_log_activity",
	id: "id",
	date_created: "date_created",
	account_main_ref_id: "account_main_ref_id",
	company_table_ref_id: "company_table_ref_id",
	log_object_c: "log_object_c",
	log_type_c: "log_type_c",
	log_action_c: "log_action_c",
	log_details: "log_details",
};

const company_log_detail = {
	_name: "company_log_detail",
	id: "id",
	detail_text: "detail_text",
};

const company_primary_setting = {
	_name: "company_primary_setting",
	id: "id",
	company_main_ref_id: "company_main_ref_id",
	primary_level_gc: "primary_level_gc",
	ref_object_base_sc: "ref_object_base_sc",
	ref_object_table_sc: "ref_object_table_sc",
	ref_object_record_id: "ref_object_record_id",
};

const company_internal_link = {
	_name: "company_internal_link",
	id: "id",
	company_main_ref_id: "company_main_ref_id",
	internal_link_type_sc: "internal_link_type_sc",
	source_table_sc: "source_table_sc",
	dest_table_sc: "dest_table_sc",
	source_record_id: "source_record_id",
	dest_record_id: "dest_record_id",
};

const company_address = {
	_name: "company_address",
	id: "id",
	company_main_ref_id: "company_main_ref_id",
	address_type_c: "address_type_c",
	global_address_details_ref_id: "global_address_details_ref_id",
};

const company_summary = {
	_name: "company_summary",
	id: "id",
	company_main_ref_id: "company_main_ref_id",
	summary_details: "summary_details",
	summary_type_c: "summary_type_c",
};

const company_support_data = {
	_name: "company_support_data",
	id: "id",
	company_main_ref_id: "company_main_ref_id",
	data_name: "data_name",
	data_value: "data_value",
	data_order: "data_order",
	data_source_c: "data_source_c",
};

export {
	company_main,
	company_email,
	company_attachment,
	company_sit,
	company_external_link,
	company_phone,
	company_log_activity,
	company_log_detail,
	company_primary_setting,
	company_address,
	company_summary,
	company_support_data,
	company_internal_link,
};
