import { COMPENSATION_EDIT_STATUES } from "components/global/constants";

export const compensation_summary_items_dummy = {
	summary: {
		key: "summary",
		label: "Summary",
		value: COMPENSATION_EDIT_STATUES.complete.value,
	},
	title: {
		key: "title",
		label: "Title",
		value: COMPENSATION_EDIT_STATUES.complete.value,
	},
	skill: {
		key: "skill",
		label: "Skills",
		value: COMPENSATION_EDIT_STATUES.complete.value,
	},
	industry: {
		key: "industry",
		label: "Industries",
		value: COMPENSATION_EDIT_STATUES.complete.value,
	},
	workType: {
		key: "workType",
		label: "Work Type",
		value: COMPENSATION_EDIT_STATUES.complete.value,
	},
	payStructure: {
		key: "payStructure",
		label: "Pay Structure",
		value: COMPENSATION_EDIT_STATUES.complete.value,
	},
	benefit: {
		key: "benefit",
		label: "Benefit",
		value: COMPENSATION_EDIT_STATUES.edit.value,
		items: {
			timeOff: {
				key: "timeOff",
				label: "",
				value: COMPENSATION_EDIT_STATUES.complete.value,
			},
			health: {
				key: "health",
				label: "",
				value: COMPENSATION_EDIT_STATUES.complete.value,
			},
			financial: {
				key: "financial",
				label: "",
				value: COMPENSATION_EDIT_STATUES.none.value,
			},
		},
	},
	location: {
		key: "locaction",
		label: "Location",
		items: {
			state: {
				key: "state",
				label: "",
				value: COMPENSATION_EDIT_STATUES.complete.value,
			},
			city: { key: "city", label: "", value: COMPENSATION_EDIT_STATUES.none.value },
			zipCode: {
				key: "zipCode",
				label: "",
				value: COMPENSATION_EDIT_STATUES.none.value,
			},
		},
		value: COMPENSATION_EDIT_STATUES.edit.value,
	},
	other: {
		key: "other",
		label: "Other",
		value: COMPENSATION_EDIT_STATUES.none.value,
		items: {
			state: {
				key: "state",
				label: "",
				value: COMPENSATION_EDIT_STATUES.none.value,
			},
			city: { key: "city", label: "", value: COMPENSATION_EDIT_STATUES.none.value },
			zipCode: {
				key: "zipCode",
				label: "",
				value: COMPENSATION_EDIT_STATUES.none.value,
			},
		},
	},
};
