import React, { useMemo } from "react";
import styled from "styled-components";
import { cloneDeep } from "lodash";

import {
	AmsTaskPriorityItem,
	AmsTaskTypeItem,
	DatePickerItem,
	EditableGeneralName,
} from "components/Common";

import * as MODEL from "core/model";
import { useAms, useAccount } from "core/useHooks";
import { getLocaleDate, getUTCDate } from "components/global/utils";

const ReminderNotificationItem = (props) => {
	const { activity, task, personName, selected, onClick } = props;

	const { updateAms } = useAms();
	const { getAccountAmsList } = useAccount();

	const { taskName, taskDueDate, taskType, taskPriority } = useMemo(() => {
		const {
			task_name: taskName,
			task_due_date: taskDueDateUTC,
			task_type_c: taskType,
			task_priority_c: taskPriority,
		} = task || {};

		const taskDueDate = getLocaleDate(taskDueDateUTC);

		return {
			taskName,
			taskDueDate,
			taskType,
			taskPriority,
		};
	}, [task]);

	const updateTask = (newTaskParams) => {
		const newTask = {
			...cloneDeep(task),
			...newTaskParams,
		};

		// Get information for ams_activity_main
		const amsMainInfo = {
			[MODEL.ams_activity_main._name]: {
				[MODEL.ams_activity_main.id]: activity?.id,
			},
		};

		// Get information for ams_activity_task
		if (newTask.name !== undefined) {
			delete newTask.name;
		}
		if (newTask[MODEL.ams_activity_reminder._name] !== undefined) {
			delete newTask[MODEL.ams_activity_reminder._name];
		}
		if (newTask["associations"] !== undefined) {
			delete newTask["associations"];
		}

		const amsTaskInfo = {
			[MODEL.ams_activity_task._name]: newTask,
		};

		// Save updated AMS items
		const updateParams = [amsMainInfo, amsTaskInfo];
		updateAms(updateParams, (res) => {
			getAccountAmsList();
		});
	};

	const handleChangeTaskName = (newName) => {
		const params = {
			[MODEL.ams_activity_task.task_name]: newName,
		};

		updateTask(params);
	};

	const handleTaskDueDate = (newDate) => {
		const params = {
			[MODEL.ams_activity_task.task_due_date]: getUTCDate(newDate),
		};

		updateTask(params);
	};

	const handleChangeType = (newType) => {
		const params = {
			[MODEL.ams_activity_task.task_type_c]: newType,
		};

		updateTask(params);
	};

	const handleChangeProiority = (newPriority) => {
		const params = {
			[MODEL.ams_activity_task.task_priority_c]: newPriority,
		};

		updateTask(params);
	};

	return (
		<Wrapper className={`${selected && "selected"}`} onClick={onClick}>
			<div className="reminder_notification_header--container">
				<DatePickerItem
					value={taskDueDate}
					typeDate="MMM, DD YYYY @ hh:mma"
					onChange={handleTaskDueDate}
				/>
				<span className="type-x-small person_name">&nbsp;for {personName}</span>
			</div>
			<div className="reminder_notification_body--container">
				<div className="task_notification_priority">
					<AmsTaskPriorityItem
						priority={taskPriority}
						isEdit={true}
						onChangePriority={handleChangeProiority}
					/>
				</div>
				<div className="task_notification_type">
					<AmsTaskTypeItem
						type={taskType}
						isEdit={true}
						onChange={handleChangeType}
					/>
				</div>
				<EditableGeneralName
					name={taskName}
					onSave={handleChangeTaskName}
					className="task_notification_name"
				/>
			</div>
		</Wrapper>
	);
};

export default ReminderNotificationItem;

const Wrapper = styled.div`
	display: flex;
	flex-direction: column;
	cursor: pointer;
	border-radius: var(--sp-2);
	padding: var(--sp-2);

	.reminder_notification_header {
		&--container {
			display: flex;
			align-items: center;
		}
	}

	.person_name {
		color: var(--link-color);
	}

	.task_notification_priority {
		margin-right: var(--sp-4);
	}

	.task_notification_type {
		margin-right: var(--sp-2);
	}

	.task_notification_name {
		h3 {
			font-size: var(--sp-6);
			line-height: var(--sp-6);
			color: var(--link-color);
		}
		button {
			min-width: 0;
			min-height: 0;
			padding: 0;
		}
		svg {
			width: var(--sp-8);
			height: auto;
		}
	}

	.reminder_notification_body--container {
		display: flex;
		width: 100%;
		margin-top: var(--sp-2);
		align-items: center;
	}

	&.selected {
		background: var(--eui-focus-background-color);
	}

	&:hover {
		background: var(--eui-focus-background-color);
	}
`;
