import React, { useMemo } from "react";
import styled from "styled-components";
import { EuiText, EuiFlexItem, EuiFlexGroup } from "@elastic/eui";
import { isNumber } from "lodash";

import { getDate } from "helpers/utilities";
import { JobApplicantionStageItem } from "./JobApplicantionStageItem";

import variables from "assets/css/variables.scss";

/**
 * Application Item
 */
const ApplicationItem = (props) => {
	const { application, accounts, onChangeStage } = props;

	const { applicationStage, accountName, activeTime } = useMemo(() => {
		const { application_log_activity, application_stage_c } = application;

		const account = accounts?.find(
			(_account) => _account.id === application_log_activity?.account_main_ref_id
		);
		const accountName = `${account?.name_first || "-"} ${account?.name_last || ""}`;
		const activeTime = getDate(application_log_activity?.date_created);

		const applicationStage = application_stage_c;

		return {
			applicationStage,
			accountName: accountName,
			activeTime: activeTime,
		};
	}, [application, accounts]);

	const handleChangeStage = (newStage) => {
		if (onChangeStage) {
			onChangeStage(newStage);
		}
	};

	if (!application) {
		return <></>;
	}

	return (
		<Wrapper>
			<EuiFlexGroup direction="column" gutterSize="xs">
				<EuiFlexItem>
					{isNumber(applicationStage) ? (
						<JobApplicantionStageItem
							onChangeStatus={handleChangeStage}
							status={applicationStage}
							showLabel={true}
						/>
					) : (
						<span>-</span>
					)}
				</EuiFlexItem>
				<EuiFlexItem>
					<EuiText className="log-info">
						{activeTime} via {accountName}
					</EuiText>
				</EuiFlexItem>
			</EuiFlexGroup>
		</Wrapper>
	);
};

export default ApplicationItem;

const Wrapper = styled.div`
	display: flex;
	box-sizing: border-box;
	align-items: center;
	width: fit-content;

	.log-info {
		color: ${variables.lightColor};
		font-weight: 600;
		font-size: 9px;
		line-height: 18px;
	}
`;

const Stage = styled.div`
	display: flex;
	padding: 1px 8px;
	background: ${({ background }) => background};
	color: ${({ color }) => color};
	font-weight: 500;
	font-size: 10.5px;
	line-height: 18px;
	border-radius: 4px;
	width: fit-content;
	justify-cotent: center;
	align-items: center;
`;
