import React from "react";
import { EuiDatePicker, EuiPortal } from "@elastic/eui";

const DatePicker = (props) => {
    const { selected, placeholder = "", onChange } = props;

    return (
        <EuiDatePicker
            selected={selected}
            onChange={onChange}
            popperProps={{
                innerRef: (ref) => {
                    if (ref) {
                        ref.style.zIndex = "9005";
                    }
                },
            }}
            popperContainer={({ children }) => (
                <>{children && <EuiPortal>{children}</EuiPortal>}</>
            )}
            placeholder={placeholder}
        />
    );
};

export default DatePicker;
