const sit_main = {
	_name: "sit_main",
	id: "id",
	sit_name: "sit_name",
	sit_type_c: "sit_type_c",
	sit_parent_id: "sit_parent_id",
	sit_children_count: "sit_children_count",
	sit_order_value: "sit_order_value",

	/** Alias */
	ID: "ID",
};

const sit_log_activity = {
	_name: "sit_log_activity",
	id: "id",
	date_created: "date_created",
	account_main_ref_id: "account_main_ref_id",
	sit_table_ref_id: "sit_table_ref_id",
	log_object_c: "log_object_c",
	log_type_c: "log_type_c",
	log_action_c: "log_action_c",
	sit_log_detail_ref_id: "sit_log_detail_ref_id",
};

const sit_log_detail = {
	_name: "sit_log_detail",
	id: "id",
	detail_text: "detail_text",
};

const sit_keyword = {
	_name: "sit_keyword",
	id: "id",
	sit_main_ref_id: "sit_main_ref_id",
	keyword: "keyword",
	case_sensitive: "case_sensitive",
};

export { sit_main, sit_log_activity, sit_log_detail, sit_keyword };
