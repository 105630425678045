import { get } from "lodash";

import { objectChecker } from "helpers/utilities";
import Service from "core/service";

import * as MODEL from "core/model";
import { getResultFromApiResponse } from "helpers/utilities";

import * as actionTypes from "./actionTypes";
import { logError } from "../error";

export const setCompany = (data) => {
	return {
		type: actionTypes.SET_COMPANY,
		payload: data,
	};
};

export const setCompanyWithPagination = (data) => {
	//alert(JSON.stringify({ perPage: data.perPage, currentPage: data.page }))

	return {
		type: actionTypes.SET_COMPANY_PAGINATION,
		payload: data,
	};
};

export const setCompanyLoading = (data) => {
	return {
		type: actionTypes.SET_COMPANY_LOADING,
		payload: data,
	};
};

export const setCompanyDetails = (data) => {
	return async (dispatch) => {
		dispatch({
			type: actionTypes.SET_COMPANY_DETAILS,
			payload: data,
		});
	};
};

export const setCompanySearchValue = (data) => {
	return async (dispatch) => {
		dispatch({
			type: actionTypes.SET_COMPANY_SEARCH_VALUE,
			payload: data,
		});
	};
};

export const addUpdateCompanyMainData = (data) => {
	return async (dispatch) => {
		dispatch({
			type: actionTypes.ADD_UPDATE_COMPANY_MAIN,
			payload: data,
		});
	};
};

/* -------------------- NEW -------------------- */
const setCompanyMainList_NEW = (data) => {
	return {
		type: actionTypes.SET_COMPANY_MAINLIST_NEW,
		payload: data,
	};
};

const setCompanyList_NEW = (data) => {
	return {
		type: actionTypes.SET_COMPANY_LIST_NEW,
		payload: data,
	};
};

const setCompanyDetail_NEW = (data) => {
	return {
		type: actionTypes.SET_COMPANY_DETAIL_NEW,
		payload: data,
	};
};

export const requestCompanyMainList_NEW = () => {
	return {
		type: actionTypes.REQUEST_COMPANY_MAINLIST_NEW,
	};
};

export const getCompanyMainList_NEW = (params) => {
	return async (dispatch) => {
		dispatch(requestCompanyMainList_NEW());

		const _params = {
			searchTerm: params?.searchTerm ?? "",
			pageNumber: params?.pageNumber ?? 1,
			pageCount: params?.pageCount ?? 30,
			param: params?.param ?? null,
		};

		const payload = {
			searchTerm: _params.searchTerm,
			pageNumber: _params.pageNumber,
			pageCount: _params.pageCount,
			totalCount: 0,
			data: [],
		};

		try {
			const data = await Service.company.getCompanyMainList(_params);

			if (data?.data) {
				const companies = data?.data?.map((item) => item.company_main);
				payload.data = companies;
				payload.totalCount = companies.length;
			}
		} catch (err) {
			console.log("getCompanyMainList_NEW error : ", err);
		} finally {
			dispatch(setCompanyMainList_NEW(payload));
		}
	};
};

export const updateCompany_NEW = (params, successCallback, failCallback) => {
	return async (dispatch) => {
		if (!params) {
			failCallback(null);
		}

		try {
			let payload = params;

			const result = await Service.company.updateCompany(payload);

			if (!objectChecker(result, ["error"])) {
				if (successCallback) {
					const res = getResultFromApiResponse(result);
					successCallback(res?.procedure_results);
				}
			}
		} catch (err) {
			if (failCallback) {
				failCallback(err);
			}
		}
	};
};

export const fetchAllCompany = (
	page = 1,
	perPage = 30,
	search = "",
	filters = {},
	sort = [],
	callback = null
) => {
	return async (dispatch) => {
		dispatch(setCompanyLoading(true));

		let tableData = {
			data: [],
			totalCount: 0,
			page: 0,
		};

		let payload = {
			page: page,
			per_page: perPage,
			search_term: search,
			filters,
		};

		if (sort.length > 0) payload.sort = sort;

		try {
			const result = await Service.company.getCompanyList(payload);
			const data = objectChecker(result, ["response", "data"]);
			const totalCount = objectChecker(result, ["response", "total_count"]);
			tableData = {
				data: Array.isArray(data) ? data : [],
				totalCount: data && totalCount ? totalCount : 0,
				page: data && page ? page : 0,
				perPage,
			};

			if (callback) {
				callback();
			}
		} catch (err) {
			dispatch(
				logError("fetch_failure", "none", {
					Error: "Error fetching company data: " + JSON.stringify(err),
				})
			);
		} finally {
			dispatch(setCompanyList_NEW(tableData));
			dispatch(setCompanyLoading(false));
		}
	};
};

/**
 * Save company list into store
 */
const requestCompanyList_NEW = () => {
	return {
		type: actionTypes.REQUEST_COMPANY_LIST_NEW,
	};
};

export const setCompanyListParams_NEW = (data) => {
	return {
		type: actionTypes.SET_COMPANY_LIST_PARAMS_NEW,
		payload: data,
	};
};

/**
 * Get Company List
 *
 * @param {*} params
 * @param {*} successCallback
 * @param {*} failCallback
 */
export const getCompanyList_NEW = (params, successCallback, failCallback) => {
	return async (dispatch, getState) => {
		dispatch(requestCompanyList_NEW());

		const state = getState();
		const curCompanyList = state.company.companyList_NEW;

		const _params = {
			page_number: params?.page ?? curCompanyList.page ?? 1,
			page_count: params?.per_page ?? curCompanyList.per_page ?? 30,
		};

		const sortInfo = params?.sort ?? curCompanyList.sort;

		const payload = {
			page: _params.page_number,
			per_page: _params.page_count,
			totalCount: 0,
			data: [],
			filters: _params.filters,
			search_term: _params?.search_term,
		};

		if (sortInfo?.length > 0) {
			_params.sort = sortInfo;
			payload.sort = sortInfo;
		}

		try {
			const result = await Service.company.getCompanyList(_params);
			const data = get(result, "response.data", {});
			const totalCount = get(result, "response.total_count", 0);

			if (data) {
				payload.data = data;
				payload.totalCount = totalCount || 0;

				if (successCallback) {
					successCallback();
				}
			}
		} catch (err) {
			dispatch(
				logError("fetch_failure", "none", {
					Error: "Error fetching company data: " + JSON.stringify(err),
				})
			);

			if (failCallback) {
				failCallback();
			}
		} finally {
			dispatch(setCompanyList_NEW(payload));
		}
	};
};

/**
 * Get detail information for Company
 * id : item's ID in company_main table
 */
export const getCompanyDetail_NEW = (id = 0) => {
	return async (dispatch) => {
		try {
			const data = await Service.company.getCompanyDetail({ id });
			const companyDetail = get(data, `data[0].${MODEL.company_main._name}`, null);

			if (companyDetail) {
				dispatch(setCompanyDetail_NEW(companyDetail));
			} else {
				dispatch(setCompanyDetail_NEW(null));
			}
		} catch (err) {
			dispatch(setCompanyDetail_NEW(null));
		} finally {
		}
	};
};

/**
 * Get Job list related to Company
 * id : item's ID in company_main table
 */
export const requestCompanyJobList_NEW = () => {
	return {
		type: actionTypes.REQUEST_COMPANY_JOBLIST_NEW,
	};
};

export const setCompanyJobListParams_NEW = (data) => {
	return {
		type: actionTypes.SET_COMPANY_JOBLIST_PARAMS_NEW,
		payload: data,
	};
};

const setCompanyJobList_NEW = (data) => {
	return {
		type: actionTypes.SET_COMPANY_JOBLIST_NEW,
		payload: data,
	};
};

export const getJobListByCompany_NEW = (params, successCallback, failCallback) => {
	return async (dispatch, getState) => {
		dispatch(requestCompanyJobList_NEW());

		const state = getState();
		const curJobList = state.company.company_NEW.jobs;
		const curJobId = state.company.company_NEW.id;

		const _params = {
			page: params?.page ?? curJobList.page ?? 1,
			per_page: params?.per_page ?? curJobList.per_page ?? 5,
			companyId: params?.companyId ?? curJobId ?? -1,
		};

		const payload = {
			page: _params.page,
			per_page: _params.per_page,
			totalCount: 0,
			data: [],
		};

		try {
			const data = await Service.job.getJobListByCompany(params);
			const jobList = data?.data || [];

			if (jobList) {
				payload.data = jobList;
				payload.totalCount = jobList?.length || 0;

				if (successCallback) {
					successCallback();
				}
			}
		} catch (err) {
			dispatch(
				logError("fetch_failure", "none", {
					Error: "Error fetching company job data: " + JSON.stringify(err),
				})
			);

			if (failCallback) {
				failCallback();
			}
		} finally {
			dispatch(setCompanyJobList_NEW(payload));
		}
	};
};

/**
 * Remove job from company
 */
export const removeJobFromCompany_NEW = (jobId, successCallback, failCallback) => {
	return async () => {
		try {
			const params = {
				[MODEL.job_main._name]: {
					[MODEL.job_main.id]: jobId,
					[MODEL.job_main.company_main_ref_id]: null,
				},
			};

			const result = await Service.job.updateJob(params);

			if (!get(result, "error")) {
				if (successCallback) {
					const res = getResultFromApiResponse(result);
					successCallback(res?.procedure_results);
				}
			}
		} catch (err) {
			if (failCallback) {
				failCallback(err);
			}
		}
	};
};

/**
 * Add sits from company
 */
export const addCompanySITs_NEW = (companyId, ids, successCallback, failCallback) => {
	return async () => {
		try {
			if (ids?.length > 0) {
				const params = ids?.map((id) => {
					return {
						[MODEL.company_sit._name]: {
							[MODEL.company_sit.company_main_ref_id]: companyId,
							[MODEL.company_sit.sit_main_ref_id]: id,
						},
					};
				});

				const result = await Service.company.updateCompany(params);

				if (!get(result, "error")) {
					if (successCallback) {
						const res = getResultFromApiResponse(result);
						successCallback(res?.procedure_results);
					}
				}
			} else {
				if (successCallback) {
					successCallback({ error: false });
				}
			}
		} catch (err) {
			if (failCallback) {
				failCallback(err);
			}
		}
	};
};

/**
 * delete sits from company
 */
export const deleteCompanySITs_NEW = (companyId, ids, successCallback, failCallback) => {
	return async () => {
		try {
			if (ids?.length > 0) {
				const params = ids?.map((id) => {
					return {
						[MODEL.company_sit._name]: {
							[MODEL.company_sit.id]: id,
						},
					};
				});

				const result = await Service.company.deleteCompany(params);

				if (!get(result, "error")) {
					if (successCallback) {
						const res = getResultFromApiResponse(result);
						successCallback(res?.procedure_results);
					}
				}
			} else {
				if (successCallback) {
					successCallback({ error: false });
				}
			}
		} catch (err) {
			if (failCallback) {
				failCallback(err);
			}
		}
	};
};

export const deleteCompany_NEW = (params, successCallback, failCallback) => {
	return async (dispatch) => {
		if (!params) {
			failCallback(null);
		}

		try {
			let payload = params;

			const result = await Service.company.deleteCompany(payload);

			if (!objectChecker(result, ["error"])) {
				if (successCallback) {
					const res = getResultFromApiResponse(result);
					successCallback(res?.procedure_results);
				}
			}
		} catch (err) {
			if (failCallback) {
				failCallback(err);
			}
		}
	};
};
/* --------------------------------------------- */
