import Service from "core/service";
import { objectChecker, getResultFromApiResponse, removeEmpty } from "helpers/utilities";
import { get } from "lodash";
import { logError } from "store/error";
import * as actionTypes from "./actionTypes";

export const setTemplatesByAccount = (data) => {
	return {
		type: actionTypes.TEMPLATE_BY_ACCOUNT,
		payload: data,
	};
};

export const getTemplateByAccount = (accountId) => {
	return async (dispatch, getState) => {
		try {
			const state = getState();
			const gssList = state.global.gssList_NEW;
			const result = await Service.template.getTemplateByAccount({
				accountId,
				gssList,
			});

			if (!objectChecker(result, ["error"])) {
				const res = objectChecker(result, ["response", "data"], []);
				if (res?.length) {
					dispatch(setTemplatesByAccount(res));
				} else {
					dispatch(setTemplatesByAccount(null));
				}
			}
		} catch (err) {
			dispatch(
				logError("fetch_failure", "none", {
					Error: "Error fetching template by account: " + JSON.stringify(err),
				})
			);
			dispatch(setTemplatesByAccount(null));
		}
	};
};

export const setTemplatesByCredential = (data) => {
	return {
		type: actionTypes.TEMPLATE_BY_CREDENTIAL,
		payload: data,
	};
};

export const getTemplateByCredential = (credentialId) => {
	return async (dispatch, getState) => {
		try {
			const state = getState();
			const gssList = state.global.gssList_NEW;
			const result = await Service.template.getTemplateByCredentail({
				credentialId,
				gssList,
			});

			if (!objectChecker(result, ["error"])) {
				const res = objectChecker(result, ["response", "data"], []);
				if (res?.length) {
					dispatch(setTemplatesByCredential(res));
				} else {
					dispatch(setTemplatesByCredential(null));
				}
			}
		} catch (err) {
			dispatch(
				logError("fetch_failure", "none", {
					Error: "Error fetching template by account: " + JSON.stringify(err),
				})
			);
			dispatch(setTemplatesByCredential(null));
		}
	};
};

export const updateTemplate = (params, successCallback, failCallback) => {
	return async (dispatch) => {
		if (!params) {
			failCallback(null);
		}

		try {
			let payload = params;

			const result = await Service.template.updateTemplate(payload);

			if (!objectChecker(result, ["error"])) {
				if (successCallback) {
					const res = getResultFromApiResponse(result);
					successCallback(res?.procedure_results);
				}
			}
		} catch (err) {
			if (failCallback) {
				failCallback(err);
			}
		}
	};
};

export const deleteTemplate = (params, successCallback, failCallback) => {
	return async (dispatch) => {
		if (!params) {
			failCallback(null);
		}

		try {
			let payload = params;

			const result = await Service.template.deleteTemplate(payload);

			if (!objectChecker(result, ["error"])) {
				if (successCallback) {
					const res = getResultFromApiResponse(result);
					successCallback(res?.procedure_results);
				}
			}
		} catch (err) {
			if (failCallback) {
				failCallback(err);
			}
		}
	};
};
