import { createGlobalStyle } from "styled-components";

export default createGlobalStyle`
    .p-0 {
        padding: 0 !important;
    }
    
    .fit-height {
        height: 100%;
        width: auto;
    }

    .fit-parent {
        width: 100%;
        height: 100%;
    }
    
    .text-center {
        text-align: center;
    }

    h3 {
        font-weight: 500;
        font-size: var(--sp-10);
        line-height: var(--sp-16);
    }

    .type-h3 {
        font-weight: 500;
        font-size: var(--sp-10);
        line-height: var(--sp-16);
    }

    .type-h6 {
        font-weight: bold;
        font-size: var(--sp-6);
        line-height: var(--sp-8);
    }

    .cursor-pointer {
        cursor: pointer;
    }

    .type-x-small {
        font-size: var(--sp-6);
        line-height: var(--sp-9);
    }

    .semibold {
        font-weight: 600;
    }
  
    .type-small-small {
        font-size: var(--sp-5_5);
        font-weight: bold;
        line-height: var(--sp-9);
    }

    .type-small-medium {
        font-size: var(--sp-5_5);
        color: var(--eui-darkshade-color);
        font-weight: 500;
        line-height: var(--sp-9);
    }

    .type-small-bold {
        font-size: var(--sp-6);
        font-weight: bold;
        line-height: var(--sp-10_5);
    }

    .type-small-default {
        font-size: var(--sp-7);
        line-height: var(--sp-12);
    }

    .type-small-definication-term {
        font-size: var(--sp-4_5);
        line-height: var(--sp-9);
        font-weight: 600;
    }

    .text-notification {
        font-weight: 500;
        font-size: var(--sp-6);
        line-height: var(--sp-8);
    }

    .type-p-regular {
        font-weight: bold;
        font-size: var(--sp-8);
        line-height: var(--sp-12);
    }

    .type-p-default {
        font-size: var(--sp-8);
        line-height: var(--sp-12);
    }

    .type-p-medium {
        font-size: var(--sp-7);
        line-height: var(--sp-10_5);
        font-weight: 500;
    }

    .type-sp-regular {
        font-size: var(--sp-7);
        line-height: var(--sp-10_5);
    }

    .type-p-small-regular {
        font-weight: bold;
        font-size: var(--sp-6);
        line-height: var(--sp-10_5);
    }

    .type-title-medium {
        font-weight: bold;
        font-size: var(--sp-13_5);
        line-height: var(--sp-16);
    }
    
    .badge-hollow-border {
        border: var(--sp-px) solid var(--border-color);
        box-sizing: border-box;
        border-radius: var(--sp-6);
    }

    .button-fit-content {
        min-width: 0 !important;
        height: auto !important;
        line-height: 1 !important;
        width: fit-content !important;

        .euiButtonContent.euiButton__content {
            padding: var(--sp-2) !important;
        }
        .euiButtonEmpty {
            height: fit-content !important;
        }

        .euiButtonEmpty__content {
            padding: 0 !important;
        }
    }

    .search-width-fit-content { 
        .euiFormControlLayout {
            width: 100% !important;
            max-width: unset;
            height: fit-content !important;

            input {
                max-width: unset;
            }
        } 
    }

    .textfield-fit-conent {
        max-width: 100% !important;
    }

    .select-fit-content {
        .euiFormControlLayout {
            height: fit-content !important;
        }      
    }

    .text-underline {
        text-decoration: underline;
    }

    .hover-underline {
        &:hover {
            text-decoration: underline;
        }
    }
`;
