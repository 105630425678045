import API from "api";
import { global_static_setting } from "core/model";
import {
	makeSort,
	makeTableJoin,
	makeFieldContent,
	makeExternalJoin,
} from "./commonService";
import { MAX_RECORD_CNT } from "./constant";

/** ---------------- Generic API ---------------------- */
const updateGlobalRecord = (data) => {
	return API().GenericAPI().updateTableData(data);
};

const getGSSList = () => {
	const query = {
		limit_records: MAX_RECORD_CNT,
		table_main: global_static_setting._name,
		fields: [
			makeFieldContent(global_static_setting, global_static_setting.id),
			makeFieldContent(
				global_static_setting,
				global_static_setting.setting_category
			),
			makeFieldContent(global_static_setting, global_static_setting.setting_name),
			makeFieldContent(global_static_setting, global_static_setting.setting_value),
		],
		search_type: "term",
		search_id: "",
	};

	return API().GenericAPI().getTableData(query);
};

const updateGlobal = (data) => {
	const query = {
		object: "global",
		data: data || {},
	};

	return API().GenericAPI().updateTableData(query);
};

const matchGlobalRecords = (data) => {
	return API().Global().matchGlobalRecords(data);
};
/** --------------------------------------------------------- */

/** ----------------------- Custom API ---------------------- */

/* ---------------------------------------------------------- */
export default {
	updateGlobalRecord,
	getGSSList,
	updateGlobal,
	matchGlobalRecords,
};
