import React, { useMemo, useState } from "react";
import styled from "styled-components";
import { EuiPopover, EuiFlexGroup, EuiFlexItem, EuiButtonIcon } from "@elastic/eui";
import { get } from "lodash";

import * as MODEL from "core/model";
import { circleIcon } from "components/Common/Icons";

import { CustomBadge } from "components/Common";
import { PERSON_SELECT_TYPES } from "../constants";
import StaticEmailItem from "components/Common/Items/Email/StaticEmailItem";

/**
 * Component for Showing Email of Person
 */
const PersonEmailBadgeItem = (props) => {
	const { onRemove, detail, onChangeItem } = props;

	const [isOpenPopover, setIsOpenPopover] = useState(false);

	const { emails, personName, selectedEmailLabel, hasEmails } = useMemo(() => {
		let emails = [];
		let personName = "";
		let selectedEmailLabel = "";

		const detailType = get(detail, "type");
		let hasEmails = true;

		if (detailType === PERSON_SELECT_TYPES.person.value) {
			// Get person name
			const firstName =
				get(detail, `info.${MODEL.person_main.name_first}`, []) || "";
			const lastName = get(detail, `info.${MODEL.person_main.name_last}`, []) || "";
			personName = `${firstName} ${lastName}`;

			// Get person emails
			const personEmails =
				get(detail, `info.${MODEL.person_email._name}`, []) || [];
			emails.push(...personEmails);

			// Get selected email
			const selectedEmail = emails.find(
				(email, index) => index === detail.selected
			);
			if (selectedEmail) {
				selectedEmailLabel =
					selectedEmail.global_email_details?.email_value || personName;
			} else {
				selectedEmailLabel = personName;
			}
		} else if (detailType === PERSON_SELECT_TYPES.account.value) {
			// Get account name
			const firstName =
				get(detail, `info.${MODEL.account_main.name_first}`, []) || "";
			const lastName =
				get(detail, `info.${MODEL.account_main.name_last}`, []) || "";
			personName = `${firstName} ${lastName}`;

			// Get account emails
			const accountEmails =
				get(detail, `info.${MODEL.global_email_details._name}`, []) || [];
			const formatedEmails = accountEmails.map((email) => {
				return {
					global_email_details: email,
				};
			});
			emails.push(...formatedEmails);

			// Get selected email
			const selectedEmail = emails.find(
				(email, index) => index === detail.selected
			);
			if (selectedEmail) {
				selectedEmailLabel =
					selectedEmail.global_email_details?.email_value || personName;
			} else {
				selectedEmailLabel = personName;
			}
		} else {
			personName = get(detail, `value`);
			hasEmails = false;
			selectedEmailLabel = personName;
		}

		return { emails, personName, selectedEmailLabel, hasEmails };
	}, [detail]);

	const handleOpenPopover = () => {
		if (hasEmails && !!emails?.length) {
			setIsOpenPopover(true);
		}
	};

	const handleClosePopover = () => {
		setIsOpenPopover(false);
	};

	const handleChageEmail = (index) => {
		onChangeItem(index);
	};

	return (
		<Wrapper
			button={
				<div className={`button-wrapper`}>
					<CustomBadge
						className={`message-custom-badge-item ${
							hasEmails && !emails?.length ? "no-email" : ""
						}`}
						icon={circleIcon()}
						label={selectedEmailLabel}
						onRemove={() => onRemove(detail)}
						onClickLabel={handleOpenPopover}
					/>
				</div>
			}
			isOpen={isOpenPopover}
			closePopover={handleClosePopover}
			panelPaddingSize="s"
			anchorPosition="downCenter"
			panelStyle={{
				minWidth: 200,
			}}
			repositionOnScroll={true}
		>
			<BodyContainer direction="column" gutterSize="s">
				<EuiFlexGroup gutterSize="s" direction="column">
					<EuiFlexItem>
						<span className="type-small-bold">{personName}</span>
					</EuiFlexItem>
					<EuiFlexItem>
						<div className="person-email-list__container">
							{emails?.map((email, idx) => {
								const isSelected =
									(detail.selected === undefined && idx === 0) ||
									detail.selected === idx;
								return (
									<div
										key={`person-email-list-item-${idx}`}
										className="person-email-list__item"
									>
										<EuiButtonIcon
											iconType="checkInCircleFilled"
											className={`person-email-list__item-check-icon ${
												isSelected ? "selected" : ""
											}`}
											onClick={() => handleChageEmail(idx)}
										/>
										<StaticEmailItem
											email={email}
											disabled={true}
											hasIcon={false}
											hasIconLabel={true}
										/>
									</div>
								);
							})}
						</div>
					</EuiFlexItem>
				</EuiFlexGroup>
			</BodyContainer>
		</Wrapper>
	);
};

export default PersonEmailBadgeItem;

const Wrapper = styled(EuiPopover)`
	.button-wrapper {
		display: flex;
		align-items: flex-start;
	}

	.message-custom-badge-item {
		padding: 0 var(--sp-4);

		&.no-email {
			.label {
				color: var(--eui-danger-color);
			}
		}
	}
`;

const BodyContainer = styled(EuiFlexGroup)`
	.person-email-list {
		&__container {
			display: flex;
			flex-direction: column;
			gap: 0;
		}
		&__item {
			display: flex;
			gap: 0;
			align-items: center;

			&-check-icon {
				min-height: unset;
				height: fit-content;
				color: var(--eui-darkest-shade-color);

				&.selected {
					color: var(--eui-link-color);
				}
			}
		}
	}
`;
