import React, { useState, useMemo } from "react";
import styled from "styled-components";
import {
	EuiButton,
	EuiContextMenuItem,
	EuiContextMenuPanel,
	EuiFlexGroup,
	EuiFlexItem,
	EuiIcon,
	EuiPopover,
} from "@elastic/eui";

/**
 * Component for Buttons with Popover
 */
const ButtonsWrapper = (props) => {
	const { buttons, color = "primary" } = props;

	const [isPopoverOpenBottomMenu, setBottomMenu] = useState(false);
	const [buttonIndex, setButtonIndex] = useState(0);

	const closePopoverBottomMenu = () => {
		setBottomMenu(false);
	};
	const openMenu = () => {
		if (buttons?.length > 1) {
			setBottomMenu(!isPopoverOpenBottomMenu);
		}
	};

	const additionButtons = useMemo(() => {
		const items = buttons.slice();
		return items.map((item, id) => {
			return (
				<EuiContextMenuItem
					key={`buttons-list-item-${id}-${item.label}`}
					icon={item.icon}
					onClick={() => {
						item.callback();
						setButtonIndex(id);
						closePopoverBottomMenu();
					}}
					disabled={item.disabled}
				>
					{item.label}
				</EuiContextMenuItem>
			);
		});
	}, [buttons]);

	if (!buttons?.length) {
		return null;
	}

	const buttonDropdown = (
		<EuiButton
			onClick={() => {
				openMenu();
			}}
			fill
			color={color}
			className="dropdown-button"
		>
			<EuiIcon type="arrowDown" style={{ marginTop: -2 }} />
		</EuiButton>
	);

	const buttonDisplay = (id) => {
		return (
			<EuiButton
				onClick={() => {
					buttons[id].callback();
				}}
				fill
				className="main-button"
				color={color}
			>
				<span>{buttons[id].label}</span>
			</EuiButton>
		);
	};

	return (
		<Wrapper>
			<EuiFlexGroup gutterSize="none">
				<EuiFlexItem
					style={{
						marginRight: 0,
					}}
					grow={false}
				>
					{buttonDisplay(buttonIndex)}
				</EuiFlexItem>
				<EuiFlexItem
					style={{
						marginLeft: 0,
					}}
					grow={false}
				>
					<EuiPopover
						button={buttonDropdown}
						isOpen={isPopoverOpenBottomMenu}
						closePopover={closePopoverBottomMenu}
						panelPaddingSize="none"
						anchorPosition="downRight"
						hasArrow={false}
					>
						<EuiContextMenuPanel items={additionButtons} />
					</EuiPopover>
				</EuiFlexItem>
			</EuiFlexGroup>
		</Wrapper>
	);
};

export default ButtonsWrapper;

const Wrapper = styled.div`
	display: flex;
	position: relative;
	width: 100%;
	height: 100%;

	.euiButton {
		> span {
			padding: 0;
		}
	}

	.main-button {
		border-top-right-radius: 0;
		border-bottom-right-radius: 0;
		border-right: none;
		padding: 0 var(--sp-2);
		min-width: 50px;

		.euiButton__text {
			margin-top: -1px;
		}
	}

	.dropdown-button {
		border-top-left-radius: 0;
		border-bottom-left-radius: 0;
		min-width: 0;
		height: var(--sp-12); // button height
		width: var(--sp-12);
	}
`;
