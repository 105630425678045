import React, { useMemo } from "react";
import styled from "styled-components";
import { EuiFlexGroup, EuiFlexItem, EuiLink, EuiBadge, EuiButton } from "@elastic/eui";
import { get } from "lodash";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
	faArrowRight,
	faBell,
	faCheck,
	faStickyNote,
	faTimes,
} from "@fortawesome/pro-light-svg-icons";
import { faPlus } from "@fortawesome/pro-regular-svg-icons";

import { getDate, isValidDate } from "helpers/utilities";
import * as MODEL from "core/model";

import { AMS_NOTE_TYPE_LIST } from "../constants";
import AmsStateItem from "../AmsStatusItem";

/**
 * Main Components
 */
const AmsStaticNewItem = (props) => {
	const {
		data,
		// accounts,
		// owners,
		// assignees,
		// restriction,
		onChangeEdit,
		onUpdateAms,
		// onSaveRAS,
	} = props;

	const {
		amsName,
		amsStatus,
		amsType,
		amsFromDate,
		amsToDate,
		amsUpdatedDate,
		countTasks,
		countCompletedTasks,
		countReminders,
		countNotes,
		relatedRecords,
		countRelateRecords,
	} = useMemo(() => {
		const amsName = get(data, MODEL.ams_activity_main.activity_name, "-");
		const amsStatus = get(data, MODEL.ams_activity_main.activity_status_c, 1);
		const amsFromDate = get(
			data,
			`${MODEL.ams_activity_time._name}.${MODEL.ams_activity_time.date_start}`,
			null
		);
		const amsToDate = get(
			data,
			`${MODEL.ams_activity_time._name}.${MODEL.ams_activity_time.date_end}`,
			null
		);
		const amsUpdatedDate = get(data, "date_created", null);

		const amsTypeC = get(data, MODEL.ams_activity_main.activity_type_c, 3);
		const amsType =
			Object.values(AMS_NOTE_TYPE_LIST).find((item) => item.value === amsTypeC) ||
			AMS_NOTE_TYPE_LIST.meeting;

		const tasks = get(data, `${MODEL.ams_activity_task._name}`, []);
		const countReminders = tasks?.reduce(
			(count, task) => count + task?.ams_activity_reminder?.length || 0,
			0
		);
		const countNotes = data?.ams_activity_note?.length || 0;
		const countTasks = tasks?.length || 0;
		const countCompletedTasks = tasks?.reduce(
			(count, task) => count + (task?.task_status_c === 3 ? 1 : 0),
			0
		);

		const relatedRecords =
			get(data, `${MODEL.ams_activity_reference._name}`, [])?.map((item) => {
				const details = get(item, "details", null);
				const name = `${details?.name_first || "-"} ${details?.name_last || ""}`;
				return {
					...item,
					name,
					status: true,
				};
			}) || [];
		const countRelateRecords = relatedRecords?.length || 0;

		return {
			amsName,
			amsStatus,
			amsFromDate,
			amsToDate,
			amsUpdatedDate,
			amsType,
			countTasks,
			countCompletedTasks,
			countReminders,
			countNotes,
			relatedRecords,
			countRelateRecords,
		};
	}, [data]);

	const formatedDate = (date) => {
		// Check valid date
		if (isValidDate(Date)) {
			// If it is valid date
			return getDate(date, "MMM DD, YYYY @ H:m a");
		}

		// If it is invalid date
		return date ? "N/A" : "-";
	};

	const handleClickAmsName = () => {};

	const handleClickTaskDelete = () => {};

	const handleClickEdit = () => {
		onChangeEdit();
	};

	const handleChangeStatus = (newStatus) => {
		const params = {
			[MODEL.ams_activity_main.activity_status_c]: newStatus,
		};
		onUpdateAms(params);
	};

	return (
		<Wrapper>
			<EuiFlexGroup gutterSize="s">
				<EuiFlexItem grow={false}>
					<AmsStateItem
						status={amsStatus}
						onChangeStatus={handleChangeStatus}
					/>
				</EuiFlexItem>
				<EuiFlexItem>
					<EuiFlexGroup direction="column" gutterSize="s">
						<EuiFlexItem>
							<EuiLink
								onClick={handleClickAmsName}
								className="type-x-small semibold"
							>
								{amsName}
							</EuiLink>
						</EuiFlexItem>
						<EuiFlexItem>
							{amsFromDate && amsToDate ? (
								<p className="type-small-medium">
									<span>{formatedDate(amsFromDate)}</span>
									&nbsp;&nbsp;&nbsp;
									<FontAwesomeIcon icon={faArrowRight} />
									&nbsp;&nbsp;&nbsp;
									<span>{formatedDate(amsToDate)}</span>
								</p>
							) : (
								<p className="type-small-medium">
									<span>Updated {formatedDate(amsUpdatedDate)}</span>
								</p>
							)}
						</EuiFlexItem>
						<EuiFlexItem>
							<EuiFlexGroup gutterSize="s" alignItems="center">
								<EuiFlexItem grow={false}>
									<FontAwesomeIcon
										icon={amsType.icon}
										color="var(--eui-darkshade-color)"
										size="lg"
									/>
								</EuiFlexItem>
								{countReminders ? (
									<EuiFlexItem grow={false}>
										<EuiFlexGroup gutterSize="xs" alignItems="center">
											<EuiFlexItem grow={false}>
												<div className="alarm-icon">
													<FontAwesomeIcon
														icon={faBell}
														size="lg"
														color="var(--eui-primary-color)"
													/>
												</div>
											</EuiFlexItem>
											<EuiFlexItem>
												<EuiBadge
													color="default"
													className="badge-item text-notification"
												>
													{countReminders}
												</EuiBadge>
											</EuiFlexItem>
										</EuiFlexGroup>
									</EuiFlexItem>
								) : null}
								{countNotes ? (
									<EuiFlexItem grow={false}>
										<EuiFlexGroup gutterSize="xs" alignItems="center">
											<EuiFlexItem grow={false}>
												<div className="alarm-icon">
													<FontAwesomeIcon
														icon={faStickyNote}
														size="lg"
													/>
												</div>
											</EuiFlexItem>
											<EuiFlexItem>
												<EuiBadge
													color="default"
													className="badge-item text-notification"
												>
													{countNotes}
												</EuiBadge>
											</EuiFlexItem>
										</EuiFlexGroup>
									</EuiFlexItem>
								) : null}
								{countTasks ? (
									<EuiFlexItem grow={false}>
										<EuiFlexGroup gutterSize="xs" alignItems="center">
											<EuiFlexItem grow={false}>
												<div className="alarm-icon">
													<FontAwesomeIcon
														icon={faCheck}
														size="lg"
														color="var(--eui-primary-color)"
													/>
												</div>
											</EuiFlexItem>
											<EuiFlexItem>
												<EuiBadge
													color="default"
													className="badge-item text-notification"
												>
													{countCompletedTasks}/{countTasks}
												</EuiBadge>
											</EuiFlexItem>
										</EuiFlexGroup>
									</EuiFlexItem>
								) : null}
								<EuiFlexItem>
									<EuiFlexGroup gutterSize="xs" alignItems="center">
										{relatedRecords?.slice(0, 2)?.map((item, id) => {
											return (
												<EuiFlexItem
													key={`ams-task-list-item-${id}`}
													grow={false}
												>
													<div className="badge-hollow-border task-badge__container">
														<div
															className={`task-badge-icon ${
																item?.status
																	? "active"
																	: ""
															}`}
														/>
														<div className="task-badge-label">
															<EuiLink>
																<span>{item?.name}</span>
															</EuiLink>
														</div>
														<div
															className="task-badge-delete"
															onClick={
																handleClickTaskDelete
															}
														>
															<FontAwesomeIcon
																icon={faTimes}
																size="sm"
															/>
														</div>
													</div>
												</EuiFlexItem>
											);
										})}
										{countRelateRecords > 2 ? (
											<EuiFlexItem grow={false}>
												<EuiLink>
													+{countRelateRecords - 2}
												</EuiLink>
											</EuiFlexItem>
										) : null}
									</EuiFlexGroup>
								</EuiFlexItem>
							</EuiFlexGroup>
						</EuiFlexItem>
					</EuiFlexGroup>
				</EuiFlexItem>
				<EuiFlexItem grow={false}>
					<EuiFlexGroup direction="column" gutterSize="m" alignItems="flexEnd">
						<EuiFlexItem grow={false}>
							<EuiButton
								color="text"
								className="button-fit-content plus-button"
								onClick={handleClickEdit}
								fullWidth={false}
							>
								<FontAwesomeIcon icon={faPlus} size="1x" />
							</EuiButton>
						</EuiFlexItem>
						{/* <EuiFlexItem grow={false}>
							<RASItem
								restriction={restriction}
								owners={owners}
								assignees={assignees}
								accounts={accounts}
								onSave={onSaveRAS}
							/>
						</EuiFlexItem> */}
					</EuiFlexGroup>
				</EuiFlexItem>
			</EuiFlexGroup>
		</Wrapper>
	);
};

export default AmsStaticNewItem;

/**
 * Styled Components
 */
const Wrapper = styled.div`
	width: 100%;
	padding: var(--sp-4) var(--sp-5);
	border-bottom: solid var(--sp-px) var(--border-color);

	.alarm-icon {
		display: flex;
		align-items: center;
		height: 100%;
	}

	.badge-item {
		padding: 0 var(--sp-2);
	}

	.task-badge__container {
		display: inline-flex;
		flex-direction: row;
		align-items: center;
		padding: var(--sp-px) var(--sp-4);
		background: var(--white-color);

		border: var(--sp-px) solid var(--border-color);
		box-sizing: border-box;
		border-radius: var(--sp-6);

		.task-badge-icon {
			margin-right: var(--sp-1_5);
			width: var(--sp-4);
			height: var(--sp-4);
			border-radius: 50%;
			background: var(--eui-darkshade-color);

			&.active {
				background: var(--eui-vis0-color);
			}
		}

		.task-badge-label {
			margin-right: var(--sp-2_5);
		}
	}

	.plus-button {
		background: var(--eui-lightest-shade-color);
		border-color: var(--transparent);

		min-width: 0 !important;
		height: auto !important;
		line-height: 1 !important;

		.euiButtonContent.euiButton__content {
			padding: var(--sp-2) !important;
		}

		.euiButton__text {
			font-size: var(--sp-6);
		}

		svg {
			width: var(--sp-6);
			height: var(--sp-6);
		}
	}
`;
