import React, { useState, useEffect, useMemo } from "react";
import styled from "styled-components";
import PhoneInput, { parsePhoneNumber } from "react-phone-number-input";
import "react-phone-number-input/style.css";
import { EuiFieldText, EuiCheckbox, EuiFlexGroup, EuiFlexItem } from "@elastic/eui";

import { SaveCancelBtn } from "components/Common";
import EmailPhoneRangeSelectionItem from "../EmailPhoneRangeSelectionItem";
import PhoneTypeSelectionItem from "./PhoneTypeSelectionItem";
import { GLOBAL_PHONE_TYPE_OPTIONS } from "components/global/constants";

/**
 * Main Components
 */
const EditPhoneItem = (props) => {
	const {
		phone,
		phonePrimary,
		onSave,
		onCancel,
		className,
		isFixedType = false,
		fixedType,
	} = props;

	console.log("===", phone);
	// state variable
	const [primary, setPrimary] = useState(false);
	const [data, setData] = useState(null);
	const [phoneWholeNumber, setPhoneWholeNumber] = useState(null);
	const [isSelectedType, setIsSelectedType] = useState(false); // false --> Person	1 --> Work

	const phoneDetail = data?.global_phone_details;

	const options = useMemo(() => {
		let phoneTypeOptions = [];
		let option = {};

		const phoneType = GLOBAL_PHONE_TYPE_OPTIONS.find(
			(item) => item.value === fixedType
		);

		if (isFixedType && phoneType) {
			return [
				{
					value: phoneType.value,
					inputDisplay: phoneType.inputDisplay,
				},
			];
		}

		GLOBAL_PHONE_TYPE_OPTIONS.forEach((item) => {
			if (item.range === isSelectedType) {
				option = {
					value: item.value,
					inputDisplay: item.inputDisplay,
				};
				phoneTypeOptions.push(option);
			}
		});

		return phoneTypeOptions;
	}, [isFixedType, fixedType, isSelectedType]);

	const handlePhonePrimary = (e) => {
		setPrimary(e.target.checked);
	};

	const handleChangePhone = (val) => {
		if (val) {
			const phoneNumber = parsePhoneNumber(val);
			const _data = {
				...data,
				global_phone_details: {
					phone_status_c: data?.global_phone_details?.phone_status_c,
					phone_extension: data?.global_phone_details?.phone_extension,
					phone_number: val,
					phone_country_code: phoneNumber?.countryCallingCode,
				},
			};

			setPhoneWholeNumber(val);
			setData(_data);
		}
	};

	const handleChangePhoneType = (val) => {
		const _data = {
			...data,
			phone_type_c: val,
		};

		setData(_data);
	};

	const handlePhoneExtVal = (e) => {
		const _data = {
			...data,
			global_phone_details: {
				phone_country_code: data?.phone_country_code,
				phone_status_c: data?.phone_status_c,
				phone_number: data?.phone_number,
				phone_extension: e.target.value,
			},
		};

		setData(_data);
	};

	const handleSave = () => {
		let _data = { ...data };
		_data = {
			..._data,
			global_phone_details: {
				phone_country_code: _data?.phone_country_code,
				phone_extension: _data?.phone_extension,
				phone_status_c: _data?.phone_status_c,
				phone_number: phoneWholeNumber,
			},
		};
		onSave(_data, primary);
	};

	const handleCancel = () => {
		onCancel();
	};

	const handleChangePhoneRange = (newRange) => {
		setIsSelectedType(newRange);
	};

	useEffect(() => {
		setPrimary(phonePrimary);
	}, [phonePrimary]);

	useEffect(() => {
		setData(phone);
		if (phone?.global_phone_details) {
			const phoneDetail = phone?.global_phone_details;
			if (phoneDetail?.phone_country_code && phoneDetail?.phone_number) {
				setPhoneWholeNumber(
					"+" + phoneDetail?.phone_country_code + phoneDetail?.phone_number
				);
			}
		}

		if (phone) {
			const emailTypeInfo = GLOBAL_PHONE_TYPE_OPTIONS.find(
				(type) => type.value === phone.phone_type_c
			);
			setIsSelectedType(emailTypeInfo?.range || false);
		}
	}, [phone]);

	return (
		<Wrapper className={className}>
			<EuiFlexGroup gutterSize="m" direction="column">
				<EuiFlexItem>
					<div className="setting__container">
						<EmailPhoneRangeSelectionItem
							range={isSelectedType}
							onChange={handleChangePhoneRange}
							style={{
								marginRight: 3,
							}}
						/>
						<PhoneTypeSelectionItem
							type={data?.phone_type_c ? data?.phone_type_c : 2}
							options={options}
							onChange={handleChangePhoneType}
							style={{
								marginRight: 3,
							}}
						/>
						<PhoneInput
							placeholder="Enter phone number"
							value={phoneWholeNumber}
							onChange={handleChangePhone}
							defaultCountry={phoneDetail?.phone_country_code || "US"}
						/>
						{isSelectedType == true && (
							<div className="ext-input">
								<EuiFieldText
									placeholder="Ext"
									value={
										phoneDetail?.phone_extension
											? phoneDetail?.phone_extension
											: ""
									}
									onChange={(e) => handlePhoneExtVal(e)}
								/>
							</div>
						)}

						<EuiCheckbox
							id={"extension-phone"}
							checked={primary}
							onChange={(e) => handlePhonePrimary(e)}
							className="phone-primary"
						/>
					</div>
				</EuiFlexItem>
				<EuiFlexItem>
					<SaveCancelBtn
						onChangeSave={handleSave}
						onChangeCancel={handleCancel}
					/>
				</EuiFlexItem>
			</EuiFlexGroup>
		</Wrapper>
	);
};

export default EditPhoneItem;

/**
 * Styled Components
 */
const Wrapper = styled.div`
	display: flex;

	.setting__container {
		display: flex;

		.euiFormControlLayout {
			height: fit-content;
		}
	}

	.badge-button-container {
		border: var(--sp-1) solid var(--eui-light-shade-color);
		width: var(--sp-18);
		height: var(--sp-35);
		border-radius: var(--sp-2);
		margin-top: var(--sp-6);
		margin-left: var(--sp-4);
	}

	.person-badge-button {
		margin-top: var(--sp-2);
		margin-bottom: var(--sp-3);
		width: var(--sp-16);
		align-items: center;
	}

	.work-badge-button {
		width: var(--sp-31);
		margin-bottom: var(--sp-6);
		margin-top: var(--sp-2);
		align-items: center;
	}

	.euiSuperSelectControl {
		height: var(--sp-13);
		line-height: var(--sp-15);
		padding-right: var(--sp-15);
		padding-left: var(--sp-3);
		border-radius: var(--sp-2);
		-webkit-appearance: none;
		-moz-appearance: none;
		appearance: none;
		-ms-expand: {
			display: none;
		}
	}

	.ext-input {
	}

	.euiFormControlLayoutIcons--right {
		right: var(--sp-5);
	}

	.euiFieldText {
		width: var(--sp-20);
		padding: var(--sp-3);
	}

	.phone-primary {
		margin-top: auto;
		margin-bottom: auto;
		margin-left: 0.3rem;
	}

	.euiCheckbox .euiCheckbox__input + .euiCheckbox__square {
		border-radius: 50%;
	}
`;

const euiButtonPerson = {
	background: "#D3DAE6",
	color: "black",
	border: "0",
	float: "left",
};

const euiButtonWork = {
	background: "var(--light-color)",
	color: "white",
	border: "0",
	float: "left",
};
