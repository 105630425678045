import React from "react";
import styled from "styled-components";
import { EuiButton } from "@elastic/eui";

import variables from "assets/css/variables.scss";

/**
 * Main Components
 */
const SVGIconButton = (props) => {
    const { icon, size, onChange, ...restProps } = props;

    const onBtnClick = (e) => {
        onChange(e);
    };

    return (
        <CustomEuiButton size="s" onClick={(e) => onBtnClick(e)} csize={size} {...restProps}>
            {icon()}
        </CustomEuiButton>
    );
};

export default SVGIconButton;

/**
 * Styled Components
 */
const CustomEuiButton = styled(EuiButton)`
    border-radius: 0;
    border-color: ${variables.btnBorderColor};
    min-width: 0;
    box-shadow: unset;
    border-radius: 4px;
    width: ${(props) => props.csize}px !important;
    height: ${(props) => props.csize}px !important;
    min-width: ${(props) => props.csize}px !important;
    min-height: ${(props) => props.csize}px !important;

    &: hover,
    &: focus,
    &: active,
    &:active:not(:disabled),
    &:hover:not(:disabled) {
        transform: unset;
        box-shadow: unset;
        animation: unset;
    }

    .euiButtonContent {
        padding: 0;
    }
    .euiButton__text {
        overflow: inherit !important;
    }
`;
