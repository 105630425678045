import React, { useState } from "react";
import { EuiIcon } from "@elastic/eui";
import styled from "styled-components";

import CompensationNoteListNewItem from "./CompensationNoteListNewItem";
import CompensationNoteListItem from "./CompensationNoteListItem";

/**
 * Component for displaying Note list of AMS
 */
const CompensationNoteList = (props) => {
	const { data, onSaveNote, onDeleteNote } = props;

	const [isAddNewNote, setIsAddNewNote] = useState(false);

	const handleClickAddNote = () => {
		setIsAddNewNote(true);
	};

	const handleSaveNewNote = (note) => {
		onSaveNote(note);
		setIsAddNewNote(false);
	};

	const handleCancelNewNote = (note) => {
		setIsAddNewNote(false);
	};

	return (
		<Wrapper>
			{data?.map((note, id) => {
				return (
					<CompensationNoteListItem
						key={`ams-note-list-item-${id}`}
						data={note}
						onSaveNote={onSaveNote}
						onDeleteNote={onDeleteNote}
					/>
				);
			})}
			<CompensationNoteListNewItem
				onSave={handleSaveNewNote}
				onCancel={handleCancelNewNote}
			/>
		</Wrapper>
	);
};

export default CompensationNoteList;

const Wrapper = styled.div`
	display: flex;
	width: 100%;
	flex-direction: column;

	.add-note_container {
		display: flex;
		width: fit-content;
		color: var(--eui-link-color);
		cursor: pointer;
		align-items: center;

		.add-note-icon {
			margin-left: var(--sp-2_5);
		}

		.add-note-label {
			margin-left: var(--sp-4);
		}
	}
`;
