import { MAIN_DASHBOARD_DATA } from "./actionTypes";

/**
 * Functions
 */
const setMainData = (state, payload) => {
    return {
        ...state,
        mainData: payload,
    };
};

/**
 * Initial State
 */
const initialState = {
    mainData: null,
};

const Reducer = (state = initialState, action) => {
    switch (action.type) {
        case MAIN_DASHBOARD_DATA:
            return setMainData(state, action.payload);

        default:
            return state;
    }
};

export default Reducer;
