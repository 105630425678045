import {
    ADD_ACCOUNT_TO_SECURITY_GROUP,
    SAVE_SECURITY_GROUP,
    DELETE_SECURITY_GROUP,
    EDIT_SECURITY_GROUP,
    GET_SECURITY_ACCESS_LEVEL,
    GET_SECURITY_GROUP_MEMBER_ACCESS,
    GET_SECURITY_GROUP_MEMBERS,
    REMOVE_ACCOUNT_FROM_SECURITY_GROUP,
    REMOVE_SECURITY_ACCESS_FOR_GROUP,
    SAVE_SECURITY_ACCESS_FOR_GROUP,
    SET_SECURITY_GROUP,
    SET_SECURITY_MAIN,
} from "./actionTypes";

/**
 * Functions
 */
const setSecurityMain = (state, payload) => {
    return {
        ...state,
        securityMain: payload,
    };
};

const setSecurityGroup = (state, payload) => {
    return {
        ...state,
        securityGroups: [...payload],
    };
};

const getSecurityAccessLevel = (state, payload) => {
    return {
        ...state,
        securityObjectAccess: payload,
    };
};

// const getSecurityGroupAccess = (state, payload) => {
// 	return {
// 		...state,
// 		currentSelectedGroup: payload,
// 	};
// };

const deleteSecurityGroup = (state, payload) => {
    return {
        ...state,
        deleteSecurityGroup: payload,
    };
};

const saveSecurityGroup = (state, payload) => {
    return {
        ...state,
        saveSecurityGroup: payload,
    };
};

const editSecurityGroup = (state, payload) => {
    return {
        ...state,
        editSecurityGroup: payload,
    };
};

const getSecurityGroupMembers = (state, payload) => {
    return {
        ...state,
        securityGroupMember: payload,
    };
};

const removeAccountFromSecurityGroup = (state, payload) => {
    return {
        ...state,
        removeFromSecurityGroup: payload,
    };
};

const addAccountToSecurityGroup = (state, payload) => {
    return {
        ...state,
        addFromSecurityGroup: payload,
    };
};

const getSecurityGroupMemberAccess = (state, payload) => {
    return {
        ...state,
        groupAccessList: payload,
    };
};

const saveSecurityAccessForGroup = (state, payload) => {
    return {
        ...state,
        saveSecurityAccessForGroup: payload,
    };
};

const removeSecurityAccessForGroup = (state, payload) => {
    return {
        ...state,
        removeSecurityAccessForGroup: payload,
    };
};

/**
 * Initial State
 */
const initialState = {
    securityMain: {},
    securityGroups: [],
    loading: false,
    securityObjectAccess: {},
    deleteSecurityGroup: {},
    saveSecurityGroup: {},
    securityGroupMember: [],
    removeFromSecurityGroup: {},
    addFromSecurityGroup: {},
    groupAccessList: [],
    saveSecurityAccessForGroup: {},
    removeSecurityAccessForGroup: {},
};

/**
 * Reducer
 */
const Reducer = (state = initialState, action) => {
    switch (action.type) {
        case SET_SECURITY_MAIN:
            return setSecurityMain(state, action.payload);

        case SET_SECURITY_GROUP:
            return setSecurityGroup(state, action.payload);

        case GET_SECURITY_ACCESS_LEVEL:
            return getSecurityAccessLevel(state, action.payload);

        // case GET_SECURITY_GROUP_ACCESS:
        // 	return getSecurityGroupAccess(state, action.payload);

        case DELETE_SECURITY_GROUP:
            return deleteSecurityGroup(state, action.payload);

        case SAVE_SECURITY_GROUP:
            return saveSecurityGroup(state, action.payload);

        case EDIT_SECURITY_GROUP:
            return editSecurityGroup(state, action.payload);

        case GET_SECURITY_GROUP_MEMBERS:
            return getSecurityGroupMembers(state, action.payload);

        case REMOVE_ACCOUNT_FROM_SECURITY_GROUP:
            return removeAccountFromSecurityGroup(state, action.payload);

        case ADD_ACCOUNT_TO_SECURITY_GROUP:
            return addAccountToSecurityGroup(state, action.payload);

        case GET_SECURITY_GROUP_MEMBER_ACCESS:
            return getSecurityGroupMemberAccess(state, action.payload);

        case SAVE_SECURITY_ACCESS_FOR_GROUP:
            return saveSecurityAccessForGroup(state, action.payload);

        case REMOVE_SECURITY_ACCESS_FOR_GROUP:
            return removeSecurityAccessForGroup(state, action.payload);

        default:
            return state;
    }
};

export default Reducer;
