import React, { useState } from "react";
import styled from "styled-components";

import EditableItem from "../EditableItem";
import EditLinkItem from "./EditLinkItem";
import StaticLinkItem from "./StaticLinkItem";

const EditableLink = (props) => {
    const {
        link,
        isPrimary,
        staticShow = false,
        onSave,
        onDelete,
        hasIcon = true,
    } = props;

    const [editState, setEditState] = useState(false);

    const handleSave = (data, primary) => {
        setEditState(false);
        onSave(data, primary);
    };

    const handleCancel = () => {
        setEditState(false);
    };

    const handleEdit = () => {
        setEditState(true);
    };

    const handleDelete = () => {
        onDelete(link?.id);
    };

    const handleValidate = () => {};

    const handleChangeStatus = (newStatus) => {
        const newPhone = {
            ...link,
            global_link_details: {
                ...link?.global_link_details,
                link_status_c: newStatus,
            },
        };

        handleSave(newPhone, isPrimary);
    };

    return (
        <Wrapper>
            <EditableItem
                editState={editState}
                renderStaticState={
                    <StaticLinkItem
                        link={link}
                        isPrimary={isPrimary}
                        staticShow={staticShow}
                        hasIcon={hasIcon}
                        onEdit={handleEdit}
                        onDelete={handleDelete}
                        onValidate={handleValidate}
                        onChangeStatus={handleChangeStatus}
                    />
                }
                renderEditState={
                    <EditLinkItem
                        link={link}
                        linkPrimary={isPrimary}
                        onSave={handleSave}
                        onCancel={handleCancel}
                    />
                }
            />
        </Wrapper>
    );
};

export default EditableLink;

const Wrapper = styled.div``;
