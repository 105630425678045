import React, { useState, useMemo, useEffect } from "react";
import styled from "styled-components";
import {
	EuiText,
	EuiFlexGroup,
	EuiFlexItem,
	EuiDatePicker,
	EuiSuperSelect,
	EuiFieldSearch,
	EuiFieldText,
} from "@elastic/eui";
import { cloneDeep, concat, get, remove, set } from "lodash";
import moment from "moment";

import { SVGIcon } from "components/Common";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCommentsAlt, faStickyNote } from "@fortawesome/pro-duotone-svg-icons";

import * as MODEL from "core/model";
import { EditableSelect, TextInputEditor, TextEditor } from "components/Common";
import {
	addressBookDuoNoteIcon,
	mapMarkerAltIcon,
	generalPhoneIcon,
	webIcon,
	amsRecruitIcon,
} from "components/Common/Icons/Icons";
import {
	useMacro,
	useCms,
	useAccount,
	useGlobal,
	useCredential,
	usePerson,
	useNotification,
	useQualifier,
} from "core/useHooks";
import { getGCSValue, getGSSValue } from "components/global/utils";

import { SaveButton, LoggedButton, OutboundButton } from "../components";

import RelatedItem from "./RelatedItem";
import {
	AddPersonEmailItem,
	PersonEmailBadgeItem,
	ReplyButton,
	SendButton,
} from "components/Pages/Message/MessageEditor/components";
import { MESSAGE_MANUAL_QUALIFIER_NAMES, PERSON_SELECT_TYPES } from "../constants";
import { MESSAGE_STATES } from "../../constants";
import { VALIDATE_MESSAGE } from "./constants";
import { MessageAms } from "../../MessageAms";

import useTranlation from "./useTranlation";
/**
 * Constants
 */
const groupOptions = [
	{
		value: 1,
		label: "location",
		inputDisplay: mapMarkerAltIcon(),
		name: "Location",
		placeholder: "Search address",
	},
	{
		value: 2,
		label: "phone",
		inputDisplay: generalPhoneIcon(),
		name: "Phone",
		placeholder: "Enter phone number",
	},
	{
		value: 3,
		label: "web",
		inputDisplay: webIcon(),
		name: "Web link",
		placeholder: "Enter web link",
	},
];

/**
 * Component for sending group
 */
const MessageSenderGroup = (props) => {
	const { person, initEmail, data, getCmsDraftList, getCmsMainList } = props;

	const { translateTexts } = useTranlation(person);
	const { macroOptions } = useMacro();
	const { callCreateMeetingEvent } = useCms();
	const { account, accounts } = useAccount();
	const { globals, gssList } = useGlobal();
	const { credentialsByAccount } = useCredential();
	const { savePersonPhone, deletePerson, savePersonEmail } = usePerson();
	const { setNotificationMessage } = useNotification();
	const { qualifiers, saveQualifiers } = useQualifier();
	const { callUpdateCmsCommunicaionDraft } = useCms();

	// email value
	const [requiredUsers, setRequiredUsers] = useState([]);
	const [optionalUsers, setOptionalUsers] = useState([]);
	const [searchValue, setSeachValue] = useState("");
	const [startDate, setStartDate] = useState(moment());
	const [endDate, setEndDate] = useState(moment());
	const [selectedGroup, setSelectedGroup] = useState(groupOptions[0]);
	const [relateds, setRelateds] = useState([]);
	const [subject, setSubject] = useState("");
	const [message, setMessage] = useState("");
	const [plainMessage, setPlainMessage] = useState("");
	const [messageStatus, setMessageStatus] = useState(MESSAGE_STATES.send.value);
	const [selectedCredential, setSelectedCredential] = useState(null);
	const [isRecruiting, setRecruiting] = useState(true);
	const [commDirect, setCommDirect] = useState(true); // false: 0 - incoming, true: 1 - outgoing,
	const [validationMessage, setValidationMessage] = useState("");

	const { credentialOptions } = useMemo(() => {
		const linkedInTypeC = getGCSValue(
			globals,
			"credential_main",
			"credential_type_c",
			"o365"
		);
		const authenticatedStatusC = getGCSValue(
			globals,
			MODEL.credential_main._name,
			MODEL.credential_main.credential_status_c,
			"authenticated"
		);
		const credentialOptions = [];
		if (credentialsByAccount?.length && globals?.length) {
			credentialsByAccount.forEach((credential) => {
				const credentialName =
					get(
						credential,
						`${MODEL.credential_main._name}.${MODEL.credential_main.credential_name}`
					) || "-";
				const credentialId =
					get(
						credential,
						`${MODEL.credential_main._name}.${MODEL.credential_main.id}`
					) || "-";
				const credentialTypeC = get(
					credential,
					`${MODEL.credential_main._name}.${MODEL.credential_main.credential_type_c}`
				);

				const credentialStatusC = get(
					credential,
					`${MODEL.credential_main._name}.${MODEL.credential_main.credential_status_c}`
				);

				if (
					credentialTypeC === linkedInTypeC //&&
					// credentialStatusC === authenticatedStatusC
				) {
					credentialOptions.push({
						id: credentialId,
						value: credentialId,
						label: credentialName,
					});
				}
			});
		}

		setSelectedCredential(credentialOptions?.[0]);

		return { credentialOptions };
	}, [credentialsByAccount, globals, setSelectedCredential]);

	const handleChangeCredential = (val) => {
		const credential = credentialOptions?.find((item) => item.value === val);
		setSelectedCredential(credential);
	};

	const getAddPersons = (oldPersons, newPersons) => {
		const addedPersons = [];
		newPersons.forEach((person) => {
			const existedPerson = oldPersons.find((oldPerson) => {
				let isRight = false;
				if (oldPerson.type === person.type) {
					if (person.type === PERSON_SELECT_TYPES.direct.value) {
						isRight = oldPerson.value === person.value;
					} else {
						isRight = oldPerson.info.id === person.info.id;
					}
				}

				return isRight;
			});
			if (!existedPerson) {
				addedPersons.push(person);
			}
		});
		const updatedPersons = concat(oldPersons, addedPersons);
		return updatedPersons;
	};

	const handleSaveRequiredUser = (persons) => {
		const newPersons = getAddPersons(requiredUsers, persons);
		setRequiredUsers(newPersons);
	};

	const handleDeleteRequiredPerson = (person) => {
		const newToPersons = cloneDeep(requiredUsers);
		remove(newToPersons, (toPerson) => {
			return toPerson.id === person.id;
		});

		setRequiredUsers(newToPersons);
	};

	const handleChangeRequiredUsers = (idx, newPerson) => {
		const newToPersons = cloneDeep(requiredUsers);
		set(newToPersons, `[${idx}]`, newPerson);

		setRequiredUsers(newToPersons);
	};

	const handleSaveOptionalUser = (persons) => {
		const newPersons = getAddPersons(optionalUsers, persons);
		setOptionalUsers(newPersons);
	};

	const handleDeleteOptionalPerson = (person) => {
		const newToPersons = cloneDeep(optionalUsers);
		remove(newToPersons, (toPerson) => {
			return toPerson.id === person.id;
		});

		setOptionalUsers(newToPersons);
	};

	const handleChangeOptionalUsers = (idx, newPerson) => {
		const newToPersons = cloneDeep(optionalUsers);
		set(newToPersons, `[${idx}]`, newPerson);

		setOptionalUsers(newToPersons);
	};

	const handleGroupType = (value) => {
		const group = groupOptions.find((item) => item.value === value);
		setSelectedGroup(group);
	};

	const handleSearchOnChange = (e) => {
		setSeachValue(e.target.value);
	};

	const handleChangeStartDate = (date) => {
		setStartDate(date);
	};

	const handleChangeEndDate = (date) => {
		setEndDate(date);
	};

	const handleSubject = (val) => {
		setSubject(val);
	};

	const changeEditAreaVal = (value, text) => {
		setMessage(value);
		setPlainMessage(text);
	};

	const getEmailFromPerson = (person) => {
		const selectedEmailIndex = person.selected || 0;
		const email = get(
			person,
			`info.${MODEL.person_email._name}[${selectedEmailIndex}].${MODEL.global_email_details._name}.${MODEL.global_email_details.email_value}`
		);
		return email;
	};

	const getEmailFromAccount = (person) => {
		const selectedEmailIndex = person.selected || 0;
		const email = get(
			person,
			`info.${MODEL.global_email_details._name}[${selectedEmailIndex}].${MODEL.global_email_details.email_value}`
		);
		return email;
	};

	const getRequiredPersons = () => {
		const requiredPersons = [];

		requiredUsers.forEach((person) => {
			let emailAddress = "";
			if (person.type === PERSON_SELECT_TYPES.direct.value) {
				emailAddress = person.value;
			} else if (person.type === PERSON_SELECT_TYPES.person.value) {
				emailAddress = getEmailFromPerson(person);
			} else {
				emailAddress = getEmailFromAccount(person);
			}

			if (emailAddress) {
				requiredPersons.push({
					emailAddress: {
						address: emailAddress,
					},
					type: "required",
				});
			}
		});
		return requiredPersons;
	};

	const getOptionalPersons = () => {
		const optionsPersons = [];

		optionalUsers.forEach((person) => {
			let emailAddress = "";
			if (person.type === PERSON_SELECT_TYPES.direct.value) {
				emailAddress = person.value;
			} else if (person.type === PERSON_SELECT_TYPES.person.value) {
				emailAddress = getEmailFromPerson(person);
			} else {
				emailAddress = getEmailFromAccount(person);
			}

			if (emailAddress) {
				optionsPersons.push({
					emailAddress: {
						address: emailAddress,
					},
					type: "optional",
				});
			}
		});
		return optionsPersons;
	};

	// Functions related to meeting
	const handleChangeMessage = (state) => {
		setMessageStatus(state);
	};

	const getSendInfo = (isReply, translatedSubject, translatedMessage) => {
		const credentialId = selectedCredential?.value;
		const event = {
			subject: (isReply ? "Re: " : "") + translatedSubject,
			body: {
				contentType: "Html",
				content: translatedMessage,
			},
			start: {
				dateTime: startDate.utc().format(),
				timeZone: "Pacific Standard Time",
			},
			end: {
				dateTime: endDate.utc().format(),
				timeZone: "Pacific Standard Time",
			},
			location: {
				displayName: searchValue,
			},
			allowNewTimeProposals: true,
		};

		const requiredPersons = getRequiredPersons();
		const optionalPersons = getOptionalPersons();

		event.attendees = [...requiredPersons, ...optionalPersons];

		return {
			credentialId,
			event,
		};
	};

	const handleSendMessage = () => {
		if (subject === "") {
			setValidationMessage(VALIDATE_MESSAGE.subject.value);
			return;
		}
		if (message === "") {
			setValidationMessage(VALIDATE_MESSAGE.message.value);
			return;
		}

		switch (selectedGroup) {
			case groupOptions[0]:
				// Send meeting event
				const { credentialId, event } = getSendInfo();
				if (credentialId == null) {
					setValidationMessage(VALIDATE_MESSAGE.credential.value);
					return;
				}
				setValidationMessage("");

				callCreateMeetingEvent(
					credentialId,
					event,
					(res) => {
						//saveMessage(1);
						if (res.error) {
							setNotificationMessage({
								type: "normal",
								message: res.message,
								status: "error",
							});
						} else {
							getCmsMainList();
							setNotificationMessage({
								type: "normal",
								message: "Created new meeting",
								status: "success",
							});
						}
					},
					(err) => {
						//saveMessage(-1);
						setNotificationMessage({
							type: "normal",
							message:
								err?.message || "Something failed to create new meeting",
							status: "error",
						});
					}
				);
				break;
			case groupOptions[1]:
				break;
			case groupOptions[2]:
				break;
			default:
				return null;
		}
	};

	const handleSaveMessage = (isReply) => {
		if (subject === "") {
			setValidationMessage(VALIDATE_MESSAGE.subject.value);
			return;
		}
		if (message === "") {
			setValidationMessage(VALIDATE_MESSAGE.message.value);
			return;
		}

		// Save a record for cms_communication_draft
		const texts = [subject, message];
		translateTexts(texts).then((res) => {
			const translatedtexts = res.map((translatedtext, index) =>
				translatedtext["error"] ? texts[index] : translatedtext["results"]
			);
			const { credentialId, event } = getSendInfo(
				isReply,
				translatedtexts[0],
				translatedtexts[1]
			);

			if (credentialId == null) {
				setValidationMessage(VALIDATE_MESSAGE.credential.value);
				return;
			}
			setValidationMessage("");

			if (account?.id && credentialId) {
				const params = {
					[MODEL.cms_communication_draft.account_main_ref_id]: account?.id,
					[MODEL.cms_communication_draft.credential_main_ref_id]: credentialId,
					[MODEL.cms_communication_draft.comm_platform_c]: getGCSValue(
						globals,
						"cms_communication_main",
						"comm_platform_c",
						"manual"
					),
					[MODEL.cms_communication_draft.conversation_type_c]: getGCSValue(
						globals,
						"cms_conversation_main",
						"conversation_type_c",
						"video"
					),
					[MODEL.cms_communication_draft.member_object_type_sc]: getGSSValue(
						gssList,
						"OBJECT_BASE",
						"PERSON"
					),
					[MODEL.cms_communication_draft.member_object_subtype_sc]: getGSSValue(
						gssList,
						"OBJECT_PERSON",
						"MAIN"
					),
					[MODEL.cms_communication_draft.member_object_record_id]: person?.id,
					[MODEL.cms_communication_draft.comm_details]: {
						details: event,
						comm_direction: commDirect ? 1 : 0,
						comm_type: isRecruiting,
					},
				};

				const cmsQualifier = qualifiers.find(
					(qualifier) =>
						qualifier[MODEL.qualifier_main.qualifier_name] ===
						(isRecruiting
							? MESSAGE_MANUAL_QUALIFIER_NAMES.recruiting
							: MESSAGE_MANUAL_QUALIFIER_NAMES.sales)
				);

				new Promise((resolve, reject) => {
					if (cmsQualifier) {
						callUpdateCmsCommunicaionDraft(
							[params],
							(res) => {
								const cmsDraftId =
									res?.response?.procedure_results
										?.cms_communication_draft?.[0]?.id;
								if (cmsDraftId) {
									saveQualifiers(
										cmsDraftId,
										"cms-communication-draft",
										[cmsQualifier.id],
										(res) => {
											resolve({ error: false, data: res });
										},
										(err) => {
											reject({
												error: true,
												data: err,
												message:
													"Saved a new cms_communication_draft record, but failed to assign qualifiers to the record.",
											});
										}
									);
								} else {
									reject({
										error: true,
										data: res,
										message:
											"Saved a new cms_communication_draft record, but we are not getting its id from response.",
									});
								}
							},
							(err) => {
								reject({
									error: true,
									data: err,
									message:
										"Failed to save cms_communication_draft record.",
								});
							}
						);
					} else {
						reject({
							error: true,
							message:
								"Did not find a proper qualifier. Please check qualifier list.",
						});
					}
				})
					.then((res) => {
						handleChangeMessage(MESSAGE_STATES.logged.value);
						getCmsDraftList();
						setNotificationMessage({
							type: "normal",
							message: "Group Message Saved",
							status: "success",
						});
					})
					.catch((err) => {
						setNotificationMessage({
							type: "normal",
							message:
								err.message +
								` ${err.data ? JSON.stringify(err.data) : ""}`,
							status: "error",
						});
					});
			}
		});
	};

	const handleReplyMessage = () => {
		handleChangeMessage(MESSAGE_STATES.send.value);
	};

	const handleLoggedMessage = () => {};

	const handleOutboundMessage = () => {
		setCommDirect(true);
	};

	const handleInboundMessage = () => {
		setCommDirect(false);
	};

	// Functions related to Relation
	const addRelateds = (value) => {
		let _relateds = [...relateds];
		if (!relateds.includes(value)) {
			_relateds.push(value);
		}
		setRelateds(_relateds);
	};

	useEffect(() => {
		const commDetails = data?.comm_details?.details;
		if (commDetails) {
			const initSubject = commDetails.subject;
			const initMessage = commDetails.body?.content;

			if (initSubject) {
				setSubject(initSubject);
			}
			if (initMessage) {
				setMessage(initMessage);
			}
		}

		const personType = get(person, `type`) || "person";
		const personTypeInfo = Object.values(PERSON_SELECT_TYPES).find(
			(item) => item.key === personType
		);
		let personPrimary = null;
		let personEmails = null;
		let personInfo = person;
		if (personType === "person") {
			personPrimary = get(person, `${MODEL.person_primary_setting._name}`);
			personEmails = get(person, `${MODEL.person_email._name}`);
		} else if (personType === "account") {
			// If person type is "account"
			const personEmailsValue = get(person, `${MODEL.global_email_details._name}`);
			personEmails = [personEmailsValue];
			personInfo[MODEL.global_email_details._name] = personEmails;
		} else {
			personEmails = [];
		}
		let selected = 0;
		if (personEmails?.length) {
			if (initEmail) {
				selected = personEmails.findIndex((email) => email?.id === initEmail?.id);
				selected = selected > -1 ? selected : 0;
			} else {
				// find primary email
				if (globals?.length && gssList?.length && personPrimary?.length) {
					const objectValue = getGSSValue(gssList, "OBJECT_BASE", "PERSON");
					const tableValue = getGSSValue(gssList, "OBJECT_PERSON", "EMAIL");
					const primaryLevelC = getGCSValue(
						globals,
						"global",
						"primary_level_c",
						"primary"
					);

					const primaryInfo = personPrimary.find(
						(primary) =>
							primary?.ref_object_base_sc === objectValue &&
							primary?.ref_object_table_sc === tableValue &&
							primary?.primary_level_gc === primaryLevelC
					);

					if (primaryInfo) {
						selected = personEmails.findIndex(
							(email) => email?.id === primaryInfo.ref_object_record_id
						);
						selected = selected > -1 ? selected : 0;
					}
				}
			}
		}

		const initData = {
			label: person?.name_first + " " + person?.name_last,
			id: person?.id,
			value:
				personTypeInfo.value === PERSON_SELECT_TYPES.direct.value
					? initEmail
					: person?.id,
			info: person,
			type: personTypeInfo.value,
			selected: selected,
		};
		setRequiredUsers([initData]);
	}, [person, globals, gssList, initEmail, data]);

	// Buttons
	const renderButtons = () => {
		switch (messageStatus) {
			case MESSAGE_STATES.send.value:
				return (
					<div className="cms-buttons-wrapper">
						<div className="left-side">
							<SVGIcon
								icon={
									isRecruiting ? amsRecruitIcon : addressBookDuoNoteIcon
								}
								onClick={() =>
									isRecruiting
										? setRecruiting(false)
										: setRecruiting(true)
								}
								className="recruiting-sales-button"
							/>
							<FontAwesomeIcon
								icon={faCommentsAlt}
								color="var(--eui-darkest-shade-color)"
								className="message-status-icon"
								onClick={() =>
									setMessageStatus(MESSAGE_STATES.logNotes.value)
								}
							/>
						</div>
						<div className="right-side">
							<SendButton onSend={() => handleSendMessage()} />
						</div>
					</div>
				);

			case MESSAGE_STATES.logged.value:
				return (
					<div className="cms-buttons-wrapper">
						<div className="left-side">
							<FontAwesomeIcon
								icon={faStickyNote}
								color="var(--eui-text-disabled-color)"
								className="message-status-icon"
								onClick={() =>
									setMessageStatus(MESSAGE_STATES.send.value)
								}
							/>
							<LoggedButton onLogged={() => handleLoggedMessage()} />
						</div>
						<div className="right-side">
							<ReplyButton onReply={() => handleReplyMessage()} />
						</div>
					</div>
				);

			case MESSAGE_STATES.logNotes.value:
				return (
					<div className="cms-buttons-wrapper">
						<div className="left-side">
							<SVGIcon
								icon={
									isRecruiting ? amsRecruitIcon : addressBookDuoNoteIcon
								}
								onClick={() =>
									isRecruiting
										? setRecruiting(false)
										: setRecruiting(true)
								}
								className="recruiting-sales-button"
							/>
							<FontAwesomeIcon
								icon={faStickyNote}
								color="var(--eui-primary-color)"
								className="message-status-icon"
								onClick={() =>
									setMessageStatus(MESSAGE_STATES.send.value)
								}
							/>
							<OutboundButton
								onOutbound={() => handleOutboundMessage()}
								onInbound={() => handleInboundMessage()}
							/>
						</div>
						<div className="right-side">
							<SaveButton onSave={() => handleSaveMessage()} />
						</div>
					</div>
				);
			default:
				return null;
		}
	};

	return (
		<Wrapper>
			<EuiFlexGroup direction="column" gutterSize="xs">
				<EuiFlexItem>
					<div className="d-flex mb-1">
						<EuiText className="label my-auto mr-4">From:</EuiText>
						<EditableSelect
							options={credentialOptions}
							value={selectedCredential?.value}
							onChange={handleChangeCredential}
							hasUnderline={true}
							panelWidth={200}
						/>
						{/* <EuiCheckbox
							id={"sendor-email"}
							className="my-auto mr-4"
							checked={true}
							onChange={() => {}}
						/> */}
					</div>
					<div className="d-flex mb-1">
						<EuiText className="label mt-2 mr-4">Required:</EuiText>
						<div className="icon-badges">
							<EuiFlexGroup gutterSize="s" alignItems="center" wrap={true}>
								{requiredUsers &&
									requiredUsers.length > 0 &&
									requiredUsers.map((item, index) => {
										return (
											<EuiFlexItem
												key={"required-person-item-" + index}
												grow={false}
											>
												<PersonEmailBadgeItem
													detail={item}
													onRemove={() =>
														handleDeleteRequiredPerson(item)
													}
													onChangeItem={(emailIndex) => {
														handleChangeRequiredUsers(index, {
															...item,
															selected: emailIndex,
														});
													}}
												/>
											</EuiFlexItem>
										);
									})}
								<EuiFlexItem grow={false}>
									<AddPersonEmailItem
										onSave={handleSaveRequiredUser}
										accounts={accounts}
										savePersonPhone={savePersonPhone}
										deletePerson={deletePerson}
										savePersonEmail={savePersonEmail}
									/>
								</EuiFlexItem>
							</EuiFlexGroup>
						</div>
					</div>
					<div className="d-flex mb-1">
						<EuiText className="label mt-2 mr-4">Optional:</EuiText>
						<div className="icon-badges">
							<EuiFlexGroup gutterSize="s" alignItems="center" wrap={true}>
								{optionalUsers &&
									optionalUsers.length > 0 &&
									optionalUsers.map((item, index) => {
										return (
											<EuiFlexItem
												key={"optional-person-item-" + index}
												grow={false}
											>
												<PersonEmailBadgeItem
													detail={item}
													onRemove={() =>
														handleDeleteOptionalPerson(item)
													}
													onChangeItem={(emailIndex) => {
														handleChangeOptionalUsers(index, {
															...item,
															selected: emailIndex,
														});
													}}
												/>
											</EuiFlexItem>
										);
									})}
								<EuiFlexItem grow={false}>
									<AddPersonEmailItem
										onSave={handleSaveOptionalUser}
										accounts={accounts}
										savePersonPhone={savePersonPhone}
										deletePerson={deletePerson}
										savePersonEmail={savePersonEmail}
									/>
								</EuiFlexItem>
							</EuiFlexGroup>
						</div>
					</div>
					<div className="d-flex mb-1">
						<EuiText className="label mr-4">{selectedGroup.name}:</EuiText>
						<div className="icon-badges">
							<SearchAddress>
								<EuiSuperSelect
									size="s"
									options={groupOptions}
									valueOfSelected={selectedGroup.value}
									onChange={handleGroupType}
								/>
								{selectedGroup.value === 1 ? (
									<EuiFieldSearch
										value={searchValue}
										onChange={(e) => handleSearchOnChange(e)}
										placeholder={selectedGroup.placeholder}
									/>
								) : (
									<EuiFieldText
										placeholder={selectedGroup.placeholder}
										value={searchValue}
										onChange={(e) => handleSearchOnChange(e)}
									/>
								)}
							</SearchAddress>
						</div>
					</div>
					<div className="d-flex mb-1">
						<EuiText className="label mr-4">Start:</EuiText>
						<div className="icon-badges">
							<EuiDatePicker
								showTimeSelect
								selected={startDate}
								onChange={handleChangeStartDate}
								showIcon={false}
							/>
						</div>
					</div>
					<div className="d-flex mb-1">
						<EuiText className="label mr-4">End:</EuiText>
						<div className="icon-badges">
							<EuiDatePicker
								showTimeSelect
								selected={endDate}
								onChange={handleChangeEndDate}
								showIcon={false}
							/>
						</div>
					</div>
				</EuiFlexItem>
				<EuiFlexItem>
					<EuiFlexGroup gutterSize="s" direction="column">
						<EuiFlexItem>
							<EuiFlexGroup gutterSize="s" direction="column">
								<EuiFlexItem>
									<TextInputEditor
										editorVal={subject}
										autocompletOptions={macroOptions}
										changeEditVal={handleSubject}
									/>
								</EuiFlexItem>
								<EuiFlexItem>
									<TextEditor
										id="message-group-editor"
										editorVal={message}
										autocompletOptions={macroOptions}
										changeEditVal={changeEditAreaVal}
									/>
								</EuiFlexItem>
								<EuiFlexItem>{renderButtons()}</EuiFlexItem>
							</EuiFlexGroup>
						</EuiFlexItem>
						<EuiFlexItem>
							<RelatedItem
								message={validationMessage}
								items={relateds}
								onAddItem={addRelateds}
							/>
						</EuiFlexItem>
					</EuiFlexGroup>
				</EuiFlexItem>
				<EuiFlexItem>
					<MessageAms
						person={person}
						subject={subject}
						message={plainMessage}
					/>
				</EuiFlexItem>
			</EuiFlexGroup>
		</Wrapper>
	);
};

export default MessageSenderGroup;

/**
 * Styled Components
 */
const Wrapper = styled.div`
	.euiText {
		width: 40px;
	}
	.label {
		color: var(--light-color);
		font-size: 0.75rem;
		font-weight: 600;
		margin-right: 0.3rem;
		margin-top: 0.25rem;
	}

	.icon-badges {
		flex-wrap: wrap;
		display: flex;
		align-items: center;
	}
	.message-custom-badge-item {
		height: fit-content !important;
	}

	.euiCheckbox .euiCheckbox__input + .euiCheckbox__square {
		border-radius: 50%;
	}

	.primary-color {
		color: var(--link-color);
	}

	.euiDatePicker {
		border: 0;
		box-shadow: unset;
		background-color: transparent;
		color: var(--link-color);
		cursor: pointer;
		padding: 0;
	}

	.euiFormControlLayout {
		height: 30px;
	}

	.euiSuperSelectControl {
		height: 25px;
		line-height: 28px;
		padding-right: 25px;
		padding-left: 6px;
	}

	.euiFieldSearch {
		height: 25px;
		line-height: 28px;
		font-size: 1rem;
	}

	.euiFormControlLayoutIcons--right {
		right: 6px;
	}
`;

const SearchAddress = styled.div`
	display: flex;
`;
