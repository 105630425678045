import React from "react";
import { EuiPopover, EuiIcon, EuiContextMenu, EuiText } from "@elastic/eui";
import styled from "styled-components";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPen, faCheckCircle } from "@fortawesome/pro-light-svg-icons";

import { PHONE_STATUS_OPTIONS } from "./constants";

const flattenPanelTree = (tree, array = []) => {
	array.push(tree);

	if (tree.items) {
		tree.items.forEach((item) => {
			if (item.panel) {
				flattenPanelTree(item.panel, array);
				item.panel = item.panel.id;
			}
		});
	}

	return array;
};

/**
 * Component for Phone Setting Popover
 */
const PhoneSettingPopover = (props) => {
	const { button, isOpenPopover, onClosePopover, onEdit, onDelete, onChangeStatus } =
		props;

	const panelTree = {
		id: 0,
		items: [
			{
				name: (
					<EuiText size="s">
						<span>Edit</span>
					</EuiText>
				),
				icon: (
					<FontAwesomeIcon icon={faPen} className="button-icon" fontSize={16} />
				),
				onClick: () => {
					onEdit();
					onClosePopover();
				},
			},
			{
				name: (
					<EuiText size="s">
						<span>Delete</span>
					</EuiText>
				),
				icon: <EuiIcon type="trash" color="danger" size="s" />,
				onClick: () => {
					onDelete();
					onClosePopover();
				},
			},
			{
				name: (
					<EuiText size="s">
						<span>Update Status</span>
					</EuiText>
				),
				icon: (
					<FontAwesomeIcon
						icon={faCheckCircle}
						className="button-icon"
						fontSize={16}
						color={"var(--success-color)"}
					/>
				),
				panel: {
					id: 1,
					title: "Update Status",
					items: PHONE_STATUS_OPTIONS.map((item) => {
						return {
							name: (
								<EuiText size="s">
									<span>{item.label}</span>
								</EuiText>
							),
							icon: item.icon,
							onClick: () => {
								onChangeStatus(item.value);
								onClosePopover();
							},
						};
					}),
				},
			},
		],
	};

	const panels = flattenPanelTree(panelTree);

	return (
		<EuiPopover
			isOpen={isOpenPopover}
			button={button}
			closePopover={onClosePopover}
			anchorPosition="downRight"
			hasArrow={false}
			panelStyle={{
				padding: 0,
				marginTop: -3,
			}}
			withTitle={false}
		>
			<MenuContainer initialPanelId={0} panels={panels} />
		</EuiPopover>
	);
};

export default PhoneSettingPopover;

const MenuContainer = styled(EuiContextMenu)`
	width: 12rem !important;

	.euiContextMenuItem {
		padding: 0.5rem 1rem;
	}
`;
