import React, {
    useState
} from "react";
import styled from "styled-components";
import {
    EuiFilterGroup,
    EuiFlexGroup,
    EuiFlexItem,
    EuiPopover,
    EuiFilterButton,
} from '@elastic/eui'


/**
 * Main Components
 */
const MessageFilter = (props) => {

	const {
		...restProps
	} = props;
    
    const [isPopoverOpen, setIsPopoverOpen] = useState(false);
    const [numFilters, setnumFilters] = useState(0);

    const onButtonClick = (e) => {
        e.preventDefault();
        setIsPopoverOpen(!isPopoverOpen);
    };

    const closePopover = () => {
        setIsPopoverOpen(false);
    };

    const filterButton = (
        <EuiFilterButton
            iconSide="left"
            onClick={(e) => onButtonClick(e)}
            isSelected={isPopoverOpen}
            numFilters={numFilters}
            hasActiveFilters={false}
            numActiveFilters={0}
            size="s"
        >
            Filter
        </EuiFilterButton>
    );

	return (
		<Wrapper {...restProps}>
			<EuiFlexGroup tabIndex={0} gutterSize="xs">
                <EuiFlexItem grow={false}>
                    <EuiFilterGroup fullWidth={true}>
                        <EuiPopover
                            id="popover"
                            button={filterButton}
                            isOpen={isPopoverOpen}
                            closePopover={closePopover}
                            panelPaddingSize="none"
                            withTitle
                            size="s"
                            tabIndex={0}
                            initialFocus="[id=New Records-row]"
                        >
                            <div tabIndex={0} style={{ padding: "16px" }}>
                                pannel
                            </div>
                        </EuiPopover>
                    </EuiFilterGroup>
                </EuiFlexItem>
            </EuiFlexGroup>
		</Wrapper>
	);
};

export default MessageFilter;

/**
 * Styled Components
 */
const Wrapper = styled.div`

`;
