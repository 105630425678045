import API from "api";
import { getGSSValue } from "components/global/utils";

import {
	template_main,
	template_details,
	template_permission,
	template_attachment,
	template_log_activity,
	template_association,
} from "core/model";
import { objectChecker } from "helpers/utilities";

import {
	makeTableJoin,
	makeFieldContent,
	makeExternalJoin,
	makeLogJoin,
	makeRefJoinTable,
	getLogTypesActions,
	makeSearchField,
} from "./commonService";

const getTemplateDetail = ({ ids, gssList = [] }) => {
	let query = {};
	const mainQuery = {
		table_main: template_main._name,
		table_join: makeTableJoin([
			template_details,
			template_permission,
			template_attachment,
		]),
		fields: [
			makeFieldContent(template_main, template_main.id),
			makeFieldContent(template_main, template_main.template_name),
			makeFieldContent(template_main, template_main.template_type_c),
			makeFieldContent(template_main, template_main.template_status_c),
			makeFieldContent(template_main, template_main.template_state_sc),

			// template_details
			makeFieldContent(template_details, template_details.id),
			makeFieldContent(template_details, template_details.template_delivery_type_c),
			makeFieldContent(template_details, template_details.template_subject),
			makeFieldContent(template_details, template_details.template_body),
			makeFieldContent(template_details, template_details.template_settings),
			makeFieldContent(template_details, template_details.template_note),

			// template_permission
			makeFieldContent(template_permission, template_permission.id),
			makeFieldContent(
				template_permission,
				template_permission.security_group_ref_id
			),

			// template_attachment
			makeFieldContent(template_attachment, template_attachment.id),
			makeFieldContent(template_attachment, template_attachment.attachment_type_sc),
			makeFieldContent(template_attachment, template_attachment.attachment_ref_id),
			makeFieldContent(template_attachment, template_attachment.attachment_details),
		],
		search_type: "id",
		search_id: ids,
	};

	query = { ...query, ...mainQuery };
	if (gssList?.length) {
		const logQuery = {
			log_system: "template",
			log_join: [getLogTypesActions(template_main, gssList)],
			log_fields: [
				makeFieldContent(template_main, template_log_activity.id),
				makeFieldContent(template_main, template_log_activity.date_created),
				makeFieldContent(template_main, template_log_activity.log_object_c),
				makeFieldContent(template_main, template_log_activity.log_type_c),
				makeFieldContent(template_main, template_log_activity.log_action_c),
			],
		};
		query = { ...query, ...logQuery };
	}
	return API().GenericAPI().getTableData(query);
};

const getTemplateByAccount = ({ accountId, gssList = [] }) => {
	if (gssList?.length > 0) {
		const query = {
			association: {
				ref_object_base_sc: getGSSValue(gssList, "OBJECT_BASE", "ACCOUNT"),
				ref_object_table_sc: getGSSValue(gssList, "OBJECT_ACCOUNT", "MAIN"),
				ref_object_record_id: parseInt(accountId, 10),
			},
		};

		return API().Template().getTemplateList(query);
	}
};

const getTemplateByCredentail = ({ credentialId, gssList = [] }) => {
	if (gssList?.length > 0) {
		const query = {
			association: {
				ref_object_base_sc: getGSSValue(gssList, "OBJECT_BASE", "CREDENTIAL"),
				ref_object_table_sc: getGSSValue(gssList, "OBJECT_CREDENTIAL", "MAIN"),
				ref_object_record_id: parseInt(credentialId, 10),
			},
		};

		return API().Template().getTemplateList(query);
	}
};

const getTemplate = ({ id }) => {
	const query = {
		object: "template",
		record_main_id: id,
	};

	return API().GenericAPI().getMainData(query);
};

const updateTemplate = (data) => {
	const query = {
		object: "template",
		data: data || {},
	};

	return API().GenericAPI().updateTableData(query);
};

const deleteTemplate = (data) => {
	const query = {
		object: "template",
		...data,
	};

	return API().GenericAPI().deleteTableData(query);
};

const getTemplateList = (params) => {
	const query = {
		page_number: params?.page || 1,
		page_count: params?.per_page || 30,
		search_name_term: params?.search_name_term || null,
		search_subject_term: params?.search_subject_term || null,
		search_body_term: params?.search_body_term || null,
		association: params?.association,
	};
	return API().Template().getTemplateList(query);
};

export default {
	getTemplate,
	getTemplateDetail,
	updateTemplate,
	deleteTemplate,
	getTemplateByAccount,
	getTemplateList,
	getTemplateByCredentail,
};
