import Service from "core/service";
import { objectChecker } from "helpers/utilities";
import { logError } from "store/error";
import * as actionTypes from "./actionTypes";

export const setMacrosList = (data) => {
	return {
		type: actionTypes.MACROS_LIST,
		payload: data,
	};
};

export const getMacrosList = () => {
	return async (dispatch) => {
		try {
			const result = await Service.macro.getMacroList();
			const data = objectChecker(result, ["response", "data"]);

			if (data?.length) {
				const list = [];
				data.map((item) => {
					list.push(item?.macro_main);
				});
				dispatch(setMacrosList(list));
			} else {
				dispatch(setMacrosList(null));
			}
		} catch (err) {
			dispatch(
				logError("fetch_failure", "none", {
					Error: "Error fetching macro list: " + JSON.stringify(err),
				})
			);
			dispatch(setMacrosList(null));
		}
	};
};

export const getTranslatedMessage = (params, successCallback, failCallback) => {
	return async (dispatch) => {
		try {
			const result = await Service.macro.processTemplateMacro(params);
			const data = objectChecker(result, ["response", "data"]);

			if (data) {
				if (successCallback) {
					successCallback(data);
				}
			} else {
				if (failCallback) {
					failCallback(result);
				}
			}
		} catch (err) {
			dispatch(
				logError("fetch_failure", "none", {
					Error:
						"Error fetching process template macro: " + JSON.stringify(err),
				})
			);
			if (failCallback) {
				failCallback(err);
			}
		}
	};
};
