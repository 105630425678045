import axios from "axios";

const API_ENDPOINT_SANDBOX = "https://api.sandbox.crm.empower.associates/api/v1";
const API_ENDPOINT_DEVELOPMENT = "https://api.development.crm.empower.associates/api/v1";

const url = window.location.href;

let defaultBaseUrl = API_ENDPOINT_SANDBOX;

if (url.search("sandbox") !== -1) {
    defaultBaseUrl = API_ENDPOINT_SANDBOX;
} else if (url.search("development") !== -1) {
    defaultBaseUrl = API_ENDPOINT_DEVELOPMENT;
}

const baseUrl = process.env.REACT_APP_API_URL || defaultBaseUrl;

const instance = axios.create({
    baseURL: baseUrl,
});

export default instance;
