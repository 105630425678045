import React, { useMemo, useState } from "react";
import styled from "styled-components";
import {
	EuiFlexGroup,
	EuiFlexItem,
	EuiButtonEmpty,
	EuiButtonIcon,
	EuiPopover,
} from "@elastic/eui";

/**
 * Wrapper Component for Address, Email, Links Item components
 */
const CommonItemView = (props) => {
	const {
		title,
		icon,
		items = [],
		mainItem,
		showCount = false,
		newItem,
		isEdit,
		setIsEdit,
		className,
		maxCount,
	} = props;

	const [isOpenPopover, setIsOpenPopover] = useState(false);

	const countOfItems = useMemo(() => {
		return items?.length || 0;
	}, [items]);

	const handleAddItem = () => {
		setIsOpenPopover(!isOpenPopover);
	};

	const handleAddItemInModal = () => {
		setIsEdit(true);
	};

	const handleShowAllItems = () => {
		setIsOpenPopover(!isOpenPopover);
		setIsEdit(false);
	};

	const handleClosePopover = () => {
		setIsOpenPopover(false);
		setIsEdit(false);
	};

	return (
		<Wrapper className={`${className} ${!mainItem ? "empty" : ""}`}>
			<EuiFlexGroup gutterSize="xs">
				{icon ? (
					<EuiFlexItem grow={false} className="item-icon">
						{icon}
					</EuiFlexItem>
				) : null}
				{mainItem ? <EuiFlexItem>{mainItem}</EuiFlexItem> : null}
				{maxCount === undefined || (maxCount && countOfItems < maxCount) ? (
					<EuiFlexItem grow={false}>
						<EuiPopover
							isOpen={isOpenPopover}
							button={
								countOfItems > 1 ? (
									<EuiButtonEmpty
										onClick={handleShowAllItems}
										className="plus-button"
									>
										{showCount
											? countOfItems
											: `+${countOfItems - 1}`}
									</EuiButtonEmpty>
								) : (
									<AddButton
										iconType="plusInCircleFilled"
										onClick={handleAddItem}
										color="primary"
										className="plus-button"
									/>
								)
							}
							closePopover={handleClosePopover}
							anchorPosition="downRight"
							hasArrow={false}
							panelStyle={{
								width: 290,
								padding: "0.5rem 0.75rem",
								marginTop: -10,
							}}
						>
							<EuiFlexGroup direction="column" gutterSize="s">
								<EuiFlexItem>
									<EuiFlexGroup gutterSize="s" direction="column">
										{items?.map((item, idx) => {
											if (item[1]) {
												return (
													<EuiFlexGroup
														gutterSize="s"
														direction="row"
													>
														<EuiFlexItem
															key={`${title}-item-${idx}`}
														>
															{item[0]}
														</EuiFlexItem>
													</EuiFlexGroup>
												);
											} else {
												return (
													<EuiFlexGroup
														gutterSize="s"
														direction="row"
													>
														<EuiFlexItem
															key={`${title}-item-${idx}`}
														>
															{item[0]}
														</EuiFlexItem>
													</EuiFlexGroup>
												);
											}
										}) || null}
										{isEdit ? (
											<EuiFlexItem>{newItem}</EuiFlexItem>
										) : null}
									</EuiFlexGroup>
								</EuiFlexItem>
								<EuiFlexItem>
									<EuiFlexGroup
										gutterSize="none"
										alignItems="center"
										direction="row"
									>
										{!isEdit ? (
											<AddButton
												iconType="plusInCircleFilled"
												onClick={handleAddItemInModal}
												color="primary"
												className="plus-button"
											/>
										) : null}
									</EuiFlexGroup>
								</EuiFlexItem>
							</EuiFlexGroup>
						</EuiPopover>
					</EuiFlexItem>
				) : null}
			</EuiFlexGroup>
		</Wrapper>
	);
};

export default CommonItemView;

const Wrapper = styled.div`
	cursor: pointer;
	width: 100%;

	.item-icon {
		min-width: 16px;
		align-items: center;
	}

	.item-edit-popover--wrapper {
		min-width: 275px;
	}

	.euiPopover euiPopover--anchorDownRight,
	.euiPopover__anchor {
		line-height: 1;
	}

	.plus-button {
		height: fit-content;
		.euiButtonContent.euiButtonEmpty__content {
			padding: 0;

			.euiButtonEmpty__text {
				line-height: 1;
			}
		}
	}

	&.empty {
		& > div {
			align-items: center;
		}
	}

	&:not(.empty) {
		.plus-button {
			margin-top: 0.333rem;
		}

		&.address-view {
			.item-icon {
				margin-top: 0.75rem;
			}
		}

		&.email-view {
			.item-icon {
				margin-top: 0.875rem;
			}
		}

		&.phone-view {
			.item-icon {
				margin-top: 0.875rem;
			}
		}

		&.link-view {
			.item-icon {
				margin-top: 0.9rem;
			}
		}
	}
`;

const AddButton = styled(EuiButtonIcon)`
	padding: 0 !important;
	min-width: 0 !important;
	min-height: 0 !important;
	line-height: 1 !important;
	margin-left: 0.5em;
`;
