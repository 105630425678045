import React from "react";
import {
	EuiFlyout,
	EuiFlyoutBody,
	EuiFlyoutFooter,
	EuiFlyoutHeader,
	EuiTitle,
} from "@elastic/eui";
import styled from "styled-components";
import { isEmpty } from "lodash";

const Flyout = (props) => {
	const {
		className,
		title,
		header = () => {},
		body,
		footer,
		size = "s",
		ownFocus = false,
		closeFlyout,
	} = props;

	return (
		<Wrapper
			className={className}
			onClose={closeFlyout}
			ownFocus={ownFocus}
			size={size}
			aria-labelledby="flyoutSmallTitle"
		>
			<EuiFlyoutHeader hasBorder>
				{isEmpty(header()) ? (
					<EuiTitle size="m">
						<h2 id="flyoutSmallTitle">{title}</h2>
					</EuiTitle>
				) : (
					header()
				)}
			</EuiFlyoutHeader>
			<EuiFlyoutBody>{body()}</EuiFlyoutBody>
			{footer && <EuiFlyoutFooter>{footer()}</EuiFlyoutFooter>}
		</Wrapper>
	);
};

export default Flyout;

const Wrapper = styled(EuiFlyout)`
	.euiFlyoutFooter {
		margin-bottom: 0 !important;
	}
`;
