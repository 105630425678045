import React from "react";
import { EuiAvatar, EuiFlexGroup, EuiFlexItem, EuiText } from "@elastic/eui";
import { peopleIcon } from "components/Common/Icons";
import { NavLink } from "react-router-dom";
import styled from "styled-components";
import { orderBy } from "lodash";
import AmsPersonContactItem from "components/Common/Ams/AmsEditItem/AmsEditActivityItem/AmsPersonContactItem";
import useLayout from "components/Layout/useLayout";
import * as MODEL from "core/model";
import { sortByKey } from "helpers/utilities";
import { getEmailTypeInfo, getPhoneTypeInfo } from "components/global/utils";

/**
 * Main Components
 */
const GlobalSearchPerson = (props) => {
	const { data, onGlobalSearch, ...restProps } = props;

	const { savePersonPhone, deletePerson, savePersonEmail } = useLayout();

	// common functions
	/**
	 * Get currentCompany from person information
	 *
	 * @param {*} person_support
	 * @param {*} person_history_work
	 */
	const getCurrentCompany = (person_history_work) => {
		const currentCompany = {
			companyId: null,
			companyName: "",
			jobTitle: "",
		};

		// Check if work_history has the company_name for the most recent START date
		const sortedHistory = sortByKey(person_history_work, "date_start", -1);
		if (sortedHistory?.[0]?.company_main) {
			currentCompany.companyId = sortedHistory?.[0]?.company_main?.id;
			currentCompany.companyName = sortedHistory?.[0]?.company_main?.company_name;
		}
		currentCompany.jobTitle = sortedHistory?.[0]?.work_title;

		return currentCompany;
	};

	const handleSavePhone = (personId, data) => {
		savePersonPhone(personId, data, (res) => {
			onGlobalSearch();
		});
	};

	const handleDeletePhone = (phoneId) => {
		const query = {
			[MODEL.person_phone._name]: {
				[MODEL.person_phone.id]: phoneId,
			},
		};
		deletePerson(query, () => {
			onGlobalSearch();
		});
	};

	const handleSaveEmail = (personId, data) => {
		savePersonEmail(personId, data, (res) => {
			onGlobalSearch();
		});
	};

	const handleDeleteEmail = (emailId) => {
		const query = {
			[MODEL.person_email._name]: {
				[MODEL.person_email.id]: emailId,
			},
		};
		deletePerson(query, () => {
			onGlobalSearch();
		});
	};

	if (!data || data?.length <= 0) return null;

	return (
		<Wrapper {...restProps}>
			<EuiFlexGroup>
				<EuiFlexItem className="d-flex">
					<div className="pr-4">{peopleIcon()}</div>
					<div className="search-title pr-2">People</div>
					<div className="search-count">({data.length})</div>
				</EuiFlexItem>
			</EuiFlexGroup>
			{data.map((person, index) => {
				const personInfo = person?.person_main;
				const {
					id: personId,
					person_external_link: externalLinks,
					name_first,
					name_last,
					person_history_work: work_history,
					person_email: emails = [],
					person_phone: phones = [],
					person_external_link: linkes = [],
				} = personInfo;

				const personPhotoUrl =
					externalLinks?.filter((link) => link?.global_link_type_c === 3)?.[0]
						?.global_url_details?.url_value ?? "";
				const personName = `${name_first} ${name_last}`;

				const emailsContact = [];
				const emailsWork = [];
				if (emails?.length > 0) {
					emails.forEach((email) => {
						const emailTypeInfo = getEmailTypeInfo(email.email_type_c);

						if (!emailTypeInfo?.range) {
							emailsContact.push(email);
						} else {
							emailsWork.push(email);
						}
					});
				}

				const phonesContact = [];
				const phonesWork = [];
				if (phones?.length > 0) {
					phones.forEach((phone) => {
						const phoneTypeInfo = getPhoneTypeInfo(phone.phone_type_c);

						if (!phoneTypeInfo?.range) {
							phonesContact.push(phone);
						} else {
							phonesWork.push(phone);
						}
					});
				}

				const linkesContact = [];
				if (linkes?.length > 0) {
					linkes.forEach((link) => {
						linkesContact.push(link);
					});
				}

				const currentCompany = getCurrentCompany(work_history);

				return (
					<EuiFlexGroup
						className="search-card"
						gutterSize="s"
						key={"global-search-person-" + index}
					>
						<EuiFlexItem grow={1}>
							<EuiAvatar
								className="mt-2"
								imageUrl={personPhotoUrl || ""}
								name={name_first + " " + name_last}
							/>
						</EuiFlexItem>
						<EuiFlexItem grow={8}>
							<div className="d-flex">
								<NavLink
									className="pr-2 search-ellipsis"
									to={`/person/${personId}`}
								>
									{personName}
								</NavLink>
							</div>
							<div className="d-flex">
								<EuiText size="s" className="search-ellipsis">
									{currentCompany.jobTitle || "Job Title N/A"}
								</EuiText>
							</div>
							<div className="d-flex">
								<NavLink
									className="search-ellipsis"
									to={`/company/${currentCompany?.companyId}`}
								>
									{currentCompany.companyName}
								</NavLink>
							</div>
						</EuiFlexItem>
						<EuiFlexItem grow={2}>
							<EuiFlexGroup direction="column" gutterSize="xs">
								<EuiFlexItem>
									<AmsPersonContactItem
										type="contact"
										personId={personId}
										emails={emailsContact}
										phones={phonesContact}
										linkes={linkesContact}
										onSavePhone={(data) =>
											handleSavePhone(personId, data)
										}
										onDeletePhone={handleDeletePhone}
										onSaveEmail={(data) =>
											handleSaveEmail(personId, data)
										}
										onDeleteEmail={handleDeleteEmail}
									/>
								</EuiFlexItem>
								<EuiFlexItem>
									<AmsPersonContactItem
										type="work"
										personId={personId}
										emails={emailsWork}
										phones={phonesWork}
										onSavePhone={(data) =>
											handleSavePhone(personId, data)
										}
										onDeletePhone={handleDeletePhone}
										onSaveEmail={(data) =>
											handleSaveEmail(personId, data)
										}
										onDeleteEmail={handleDeleteEmail}
									/>
								</EuiFlexItem>
							</EuiFlexGroup>
						</EuiFlexItem>
					</EuiFlexGroup>
				);
			})}
		</Wrapper>
	);
};

export default GlobalSearchPerson;

/**
 * Styled Components
 */
const Wrapper = styled.div`
	padding-bottom: 16px;
`;
