import React, { useEffect, useRef } from "react";
import styled from "styled-components";
import { Editor } from "@tinymce/tinymce-react";

import { TINYMCE_DEFAULT_HEIGHT, TINYMCE_APIKEY } from "components/global/constants";
import $ from "jquery";

/**
 * Main Components
 */
const TextEditor = (props) => {
	const {
		editorVal,
		isToolbar = true,
		autocompletOptions,
		changeEditVal,
		disabled,
		draggable = false,
		...restProps
	} = props;

	const editorRef = useRef(null);
	const editorRefCurrent = editorRef?.current;

	const autoCompleteSetup = (editor) => {
		const onMatches = function (rng, text, pattern) {
			const format = /[^a-zA-Z0-9]/;

			if (!format.test(pattern)) {
				return true;
			} else {
				return false;
			}
		};

		const onAction = function (autocompleteApi, rng, value) {
			editor.selection.setRng(rng);
			editor.insertContent(value);
			autocompleteApi.hide();
		};

		const getMatchedChars = function (pattern) {
			if (autocompletOptions?.length) {
				return autocompletOptions.filter(function (char) {
					return (
						char.value.toLowerCase().indexOf(pattern) !== -1 ||
						char.value.toUpperCase().indexOf(pattern) !== -1
					);
				});
			} else {
				return [];
			}
		};

		/* An autocompleter that allows you to insert special characters */
		editor.ui.registry.addAutocompleter("specialchars", {
			ch: "[",
			minChars: 0,
			columns: 1,
			highlightOn: ["char_name"],
			matches: onMatches,
			onAction: onAction,
			fetch: function (pattern) {
				return new window.tinymce.util.Promise(function (resolve) {
					var results = getMatchedChars(pattern).map(function (char) {
						return {
							type: "cardmenuitem",
							value: "[" + char.value + "]",
							items: [
								{
									type: "cardcontainer",
									direction: "vertical",
									items: [
										{
											type: "cardtext",
											text: char.value,
											name: "char_name",
										},
									],
								},
							],
						};
					});
					resolve(results);
				});
			},
		});
	};

	useEffect(() => {
		if (editorRefCurrent && autocompletOptions?.length > 0) {
			autoCompleteSetup(editorRefCurrent);
		}
	}, [editorRefCurrent, autocompletOptions]);

	useEffect(() => {
		if (isToolbar) {
			$(".tox-editor-header").show();
		} else {
			$(".tox-editor-header").hide();
		}
	}, [isToolbar]);

	const draggableOption = draggable
		? {
				elementpath: false,
				resize: true,
				branding: false,
		  }
		: {
				statusbar: false,
		  };

	return (
		<Wrapper {...restProps}>
			<input
				id="my-file"
				type="file"
				name="my-file"
				style={{ display: "none" }}
				onChange=""
				disabled={disabled}
			/>
			<Editor
				onInit={(evt, editor) => (editorRef.current = editor)}
				value={editorVal}
				apiKey={TINYMCE_APIKEY}
				init={{
					height: TINYMCE_DEFAULT_HEIGHT,
					menubar: false,
					plugins:
						"lists advlist autosave charmap code codesample emoticons image imagetools link " +
						"print searchreplace tabfocus " +
						"template",
					advlist_bullet_styles: "square circle disc",
					advlist_number_styles:
						"lower-alpha lower-greek lower-roman upper-alpha upper-roman",
					autosave_interval: "20s",
					fontsize_formats:
						"8pt 9pt 10pt 11pt 12pt 14pt 18pt 24pt 30pt 36pt 48pt 60pt 72pt 96pt",
					toolbar:
						"fontselect fontsizeselect | charmap |" +
						"bold italic underline | backcolor | alignleft aligncenter " +
						"alignright alignjustify | bullist numlist outdent indent | " +
						"print | searchreplace | " +
						"template | link | emoticons | image | code | codesample | undo redo",
					icons: "small",
					skin: "small",
					image_title: true,
					file_browser_callback_types: "image",
					file_picker_callback: function (callback, value, meta) {
						if (meta.filetype === "image") {
							const input = document.getElementById("my-file");
							input.click();
							input.onchange = function () {
								const file = input.files[0];
								const reader = new FileReader();
								reader.onload = function (e) {
									callback(e.target.result, {
										alt: file.name,
									});
								};
								reader.readAsDataURL(file);
							};
						}
					},
					paste_data_images: true,
					setup: function (editor) {},
					entity_encoding: "raw",
					content_style:
						"body { font-family: Inter_Regular; font-size:12px; color: #343741; }",
					...draggableOption,
				}}
				onEditorChange={(newValue, editor) => {
					const text = editor.getContent({ format: "text" });
					changeEditVal(newValue, text);
				}}
				disabled={disabled}
			/>
		</Wrapper>
	);
};

export default TextEditor;

/**
 * Styled Components
 */
const Wrapper = styled.div`
	.tox .tox-tbtn--bespoke .tox-tbtn__select-label {
		width: auto !important;
	}

	.tox-statusbar {
		border-top: none !important;
	}
`;
