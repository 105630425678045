import React, { useState } from "react";
import styled from "styled-components";

import EditableItem from "../EditableItem";
import StaticAddressItem from "./StaticAddressItem";
import EditAddressItem from "./EditAddressItem";

/**
 * Component for Address Editable Item
 */
const EditableAddress = (props) => {
    const { address, isPrimary, onSave, onDelete, hasIcon = true } = props;

    const [editState, setEditState] = useState(false);

    const handleSave = (data, primary) => {
        setEditState(false);
        onSave(data, primary);
    };

    const handleCancel = () => {
        setEditState(false);
    };

    const handleEdit = () => {
        setEditState(true);
    };
    const handleDelete = () => {
        onDelete(address?.id);
    };

    if (!address) {
        return null;
    }

    return (
        <Wrapper>
            <EditableItem
                editState={editState}
                renderStaticState={
                    <StaticAddressItem
                        address={address}
                        isPrimary={isPrimary}
                        hasIcon={hasIcon}
                        onEdit={handleEdit}
                        onDelete={handleDelete}
                    />
                }
                renderEditState={
                    <EditAddressItem
                        address={address}
                        addressPrimary={isPrimary}
                        onSave={handleSave}
                        onCancel={handleCancel}
                    />
                }
            />
        </Wrapper>
    );
};

export default EditableAddress;

const Wrapper = styled.div``;
