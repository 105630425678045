import React, { useState } from "react";
import { faBriefcase } from "@fortawesome/pro-light-svg-icons";
import { cloneDeep, size } from "lodash";

import { AsyncEditableContent } from "components/Common/asyncComponents";

/**
 * Constants
 */
const TRACKING_KEY = "person_history_work_id";
const DISPLAY_KEY = "work_title";

/**
 * Main Component for Person Position
 */
const PersonPosition = (props) => {
	let { personId, data, syncData, loadData, isSingleEntry } = props;

	const [loading, setLoading] = useState(false);

	if (!isSingleEntry) {
		isSingleEntry = false;
	}

	const onAddHandler = (editedData, setEditState, addedItemNewIndex, setAddedItemNewIndex) => {
		if (
			!editedData ||
			!setEditState ||
			addedItemNewIndex === undefined ||
			addedItemNewIndex === null ||
			setAddedItemNewIndex === undefined ||
			setAddedItemNewIndex === null
		)
			return;

		const newData = editedData.length > 0 ? cloneDeep(editedData) : [];
		const newId = "new__" + addedItemNewIndex;

		newData.push({
			[TRACKING_KEY]: newId,
			[DISPLAY_KEY]: "",
		});

		setEditState(newData);
		setAddedItemNewIndex(addedItemNewIndex + 1);
	};

	const onSaveHandler = (editedData, data) => {
		if (!editedData || !data) return;

		/**
		 * Determine edited external links.
		 */

		// Do updates (Check similar ids and if their display values are different, then push them into update container)
		const editedItems = {};

		for (let i in data) {
			for (let j in editedData) {
				const { person_work_history_id: personWorkHistoryId, work_title: workTitleValue } = data[i];

				const {
					person_work_history_id: editedPersonWorkHistoryId,
					work_title: editedWorkTitleValue,
				} = editedData[j];

				const editedDataId = editedData[j][TRACKING_KEY];

				if (personWorkHistoryId === editedPersonWorkHistoryId) {
					if (workTitleValue !== editedWorkTitleValue) {
						let filterList = ["person_work_history_id", "person_main_ref_id", "work_title"];

						let filteredEditedData = {
							...editedData[j],
						};

						// Hack: Due to some PK inconsistencies I will manually do some data transformations
						// This PK expected from this route is "person_work_history_id", and not "person_history_work_id"
						filteredEditedData["person_work_history_id"] =
							editedData[j]["person_history_work_id"];

						for (let i in filteredEditedData) {
							if (!filterList.includes(i)) {
								delete filteredEditedData[i];
							}
						}

						editedItems[editedDataId] = {
							...filteredEditedData,
							work_title: editedWorkTitleValue,
						};
					}
				}
			}
		}

		/**
		 * Do insert (pending).
		 */
		const addedItems = [];

		for (let i in editedData) {
			const id = editedData[i][TRACKING_KEY];

			// Push all of it

			if (id.toString().search(/new__/) !== -1) {
				const newValue = editedData[i][DISPLAY_KEY];

				if (newValue !== "") {
					let addedData = cloneDeep(editedData[i]);

					addedItems.push({
						...addedData,
						person_main_ref_id: personId,
					});
				}
			}
		}

		// Prep added items
		for (let i in addedItems) {
			delete addedItems[i][TRACKING_KEY];
		}

		if (size(editedItems) !== 0 || size(addedItems) !== 0) {
			const newEditedItems = [];

			for (let i in editedItems) {
				newEditedItems.push({
					args: editedItems[i],
				});
			}

			syncData([], newEditedItems, [], [], setLoading, loadData);
		}
	};

	return (
		<AsyncEditableContent
			isLoading={loading}
			icon={faBriefcase}
			data={data}
			placeholder={"Employment Title"}
			editTrackingKey={TRACKING_KEY}
			editDisplayKey={DISPLAY_KEY}
			onAddHandler={onAddHandler}
			isSingleEntry={isSingleEntry}
			onSaveHandler={onSaveHandler}
			render={(data) =>
				data.map((workHistory, id) => {
					return (
						<div key={`person-position-content-${workHistory["person_history_work_id"]}-${id}`}>
							{workHistory["work_title"]}
						</div>
					);
				})
			}
		/>
	);
};

export default PersonPosition;
