import React from "react";
import styled from "styled-components";
import { EuiCheckbox } from "@elastic/eui";

import { SVGIcon } from "components/Common";
import { homeIcon } from "components/Common/Icons/Icons";
import { GLOBAL_PHONE_TYPE_OPTIONS } from "components/global/constants";
/**
 * Main Components
 */
const StaticPhone = (props) => {
	const { phone, primary = false, ...restProps } = props;

	const icon =
		GLOBAL_PHONE_TYPE_OPTIONS.find((item) => item.value === phone?.phone_type_c)
			?.icon || homeIcon;

	return (
		<Wrapper {...restProps} className="mb-2">
			<SVGIcon icon={icon} className="mr-2" />
			<div className="d-flex">
				{phone?.global_phone_details?.phone_number || ""}
				{primary && (
					<EuiCheckbox
						id={"static-phone-value"}
						checked={true}
						onChange={() => {}}
						className="phone-valid ml-2"
					/>
				)}
			</div>
		</Wrapper>
	);
};

export default StaticPhone;

/**
 * Styled Components
 */
const Wrapper = styled.div`
	display: flex;
	width: 100%;

	.icon-button {
		margin: auto 0 0 0.5rem;
	}

	.euiFormControlLayout {
		height: 25px;
	}

	.email-valid {
		margin-top: auto;
		margin-bottom: auto;
		margin-left: auto;
	}

	.euiCheckbox .euiCheckbox__input + .euiCheckbox__square {
		border-radius: 50%;
	}
`;
