import React, { useState } from "react";
import styled from "styled-components";
import { EuiFlexGroup, EuiFlexItem, EuiButtonEmpty } from "@elastic/eui";

import { menuDotsHorizontal } from "components/Common/Icons";
import EducationSettingPopover from "./EducationSettingPopover";

import variables from "assets/css/variables.scss";

/**
 * Component for Address Setting Bar
 */
const EducationSettingBar = (props) => {
	const { onEdit, onDelete } = props;

	const [isOpenPopover, setIsOpenPopover] = useState(false);

	const handleOpenPopover = () => {
		setIsOpenPopover(!isOpenPopover);
	};
	const handleClosePopover = () => setIsOpenPopover(false);

	return (
		<Wrapper>
			<EuiFlexGroup gutterSize="s" alignItems="center">
				<EuiFlexItem>
					<EducationSettingPopover
						button={
							<EuiButtonEmpty
								size="xs"
								className="setting-icon-button"
								onClick={handleOpenPopover}
							>
								<div className="icon-button-img">
									{menuDotsHorizontal(variables.primaryColor)}
								</div>
							</EuiButtonEmpty>
						}
						isOpenPopover={isOpenPopover}
						onClosePopover={handleClosePopover}
						onEdit={onEdit}
						onDelete={onDelete}
					/>
				</EuiFlexItem>
			</EuiFlexGroup>
		</Wrapper>
	);
};

export default EducationSettingBar;

const Wrapper = styled.div`
	& > div {
		max-width: 6rem;
	}
	.setting-icon-button {
		padding: 0 !important;
		height: fit-content !important;

		span {
			padding: 0;
			line-height: 1.5rem;
		}

		.icon-button-img {
			display: flex;
			align-items: center;
		}
	}
`;
