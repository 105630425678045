import React from "react";
import styled from "styled-components";
import { EuiSpacer } from "@elastic/eui";

const Spacer = ({ size, color, height }) => {
	return (
		<SpacerWrapper
			style={{
				backgroundColor: color,
			}}
			height={height}
		>
			<EuiSpacer size={size} className="spacer-line" />
		</SpacerWrapper>
	);
};

export default Spacer;

const SpacerWrapper = styled.div`
	.spacer-line {
		${(props) => props.height !== undefined && `height: ${props.height}px !important;`}
	}
`;
