import React from "react";
import styled from "styled-components";
import { EuiText } from "@elastic/eui";

import {
	JobPipelineIcon,
	JobInProcessIcon,
	JobPlacedIcon,
	rightArrowIcon,
} from "components/Common/Icons";
import { isNil } from "lodash";

/**
 * Component for Job Application Counts
 *
 * data = {
 *  countPipeline,
 *  countInProcess,
 *  countPlaced
 * }
 */
const JobApplicationCountsItem = (props) => {
	const { data } = props;

	const getBadgeIcon = (icon, value) => {
		const disabled = !value || value <= 0 ? true : false;
		return (
			<JobBadgeIcon>
				{disabled ? icon("#C2C3C6") : icon()}
				<EuiText
					size="s"
					className="pl-1"
					style={{
						color: disabled
							? "var(--eui-text-disabled-color)"
							: "var(--eui-text-color)",
					}}
				>
					{isNil(value) ? "-" : value}
				</EuiText>
			</JobBadgeIcon>
		);
	};

	return (
		<Wrapper>
			{getBadgeIcon(JobPipelineIcon, data?.countPipeline)}
			<div className="arrow-icon">{rightArrowIcon()}</div>
			{getBadgeIcon(JobInProcessIcon, data?.countInProcess)}
			<div className="arrow-icon">{rightArrowIcon()}</div>
			{getBadgeIcon(JobPlacedIcon, data?.countPlaced)}
		</Wrapper>
	);
};

export default JobApplicationCountsItem;

/**
 * Styled Components
 */
const Wrapper = styled.div`
	display: flex;
	flex-wrap: wrap;
	row-gap: var(--sp-4);
	.arrow-icon {
		margin: auto var(--sp-1);
		display: flex;
	}
`;

const JobBadgeIcon = styled.div`
	display: flex;
	flex-direction: row;
	width: var(--sp-20);
	height: var(--sp-10);
	justify-content: center;
	align-items: center;
	padding: var(--sp-0_5) var(--sp-2);
	box-shadow: 0px 0.7px 1.4px rgba(0, 0, 0, 0.07), 0px 1.9px 4px rgba(0, 0, 0, 0.05),
		0px 4.5px 10px rgba(0, 0, 0, 0.05);
	border-radius: var(--sp-1_5);
`;
