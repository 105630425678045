import React from "react";
import { EuiButton, EuiLink } from "@elastic/eui";
import styled from "styled-components";

import { SVGIconBtnGroups } from "components/Common";
import { MESSAGE_BTNS } from "../constants";
import {
    emailIcon,
    linkTypeLinkedInIcon,
    chatIcon,
    phoneIcon,
    groupIcon,
    urlLinkIcon,
    alertIcon,
    checkPureIcon,
} from "components/Common/Icons/Icons";

/**
 * Main Components
 */
const MessageEditorHeader = (props) => {
    const { selectedId, handleSelected } = props;

    const buttonChatIcons = [
        {
            id: MESSAGE_BTNS.EMAIL_BTN,
            icon: emailIcon,
        },
        {
            id: MESSAGE_BTNS.LINKEDIN_BTN,
            icon: linkTypeLinkedInIcon,
        },
        {
            id: MESSAGE_BTNS.CHAT_BTN,
            icon: chatIcon,
        },
    ];

    const buttonCallIcons = [
        {
            id: MESSAGE_BTNS.PHONE_BTN,
            icon: phoneIcon,
        },
        {
            id: MESSAGE_BTNS.GROUP_BTN,
            icon: groupIcon,
        },
    ];

    const onChangeChatIcons = (btnId) => {
        handleSelected(btnId);
    };

    const onChangePhoneIcons = (btnId) => {
        handleSelected(btnId);
    };

    const onChangeUrlLinkIcon = (btnId) => {
        handleSelected(btnId);
    };

    return (
        <Wrapper>
            <SVGIconBtnGroups
                className="group-btns"
                options={buttonChatIcons}
                selected={selectedId}
                iconColor={"var(--base-color)"}
                onChange={onChangeChatIcons}
            />

            <SVGIconBtnGroups
                className="group-btns"
                options={buttonCallIcons}
                selected={selectedId}
                iconColor={"var(--base-color)"}
                onChange={onChangePhoneIcons}
            />

            <EuiButton
                size="s"
                className="url-btn"
                onClick={() => onChangeUrlLinkIcon(MESSAGE_BTNS.URL_LINK_BTN)}
            >
                {urlLinkIcon()}
            </EuiButton>
            <div className="scheduled">
                <div className="label">
                    **Scheduled for&nbsp;
                    <EuiLink>Oct 31, 2020 @9:30pm</EuiLink>
                </div>
                <div className="icons">
                    {alertIcon()}
                    <div className="badge-cnt">1</div>

                    {checkPureIcon()}
                    <div className="badge-cnt">2</div>
                </div>
            </div>
        </Wrapper>
    );
};

export default MessageEditorHeader;

/**
 * Styled Components
 */
const Wrapper = styled.div`
    display: flex;
    width: 100%;
    padding-bottom: 0.5rem;

    .group-btns {
        margin-right: 1.5rem;
    }

    .url-btn {
        border-radius: 0;
        border: 0;
        min-width: 34px;
        height: 32px;
        box-shadow: unset;

        &:hover,
        &:focus,
        &:active {
            transform: unset;
            box-shadow: unset;
            animation: unset;
        }

        .euiButtonContent {
            padding: 0;
        }

        .euiButton__text {
            margin: auto;
            display: flex;
        }
    }

    .scheduled {
        display: flex;
        flex-direction: column;
        margin-left: auto;

        .label {
            color: var(--link-color);
        }

        .icons {
            display: flex;
            flex-direction: row;
            margin-left: auto;

            svg {
                margin-top: auto;
                margin-bottom: auto;
                margin-left: 1rem;
            }

            .badge-cnt {
                background: var(--badge-color);
                border-radius: 4px;
                padding: 0 4px;
                margin-left: 0.2rem;
            }
        }
    }
`;
