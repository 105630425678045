import React, { useState, useMemo } from "react";

import { global_phone_details } from "core/model";

import CommonItemView from "../CommonItemView";
import EditPhoneItem from "./EditPhoneItem";
import EditablePhone from "./EditablePhone";

import { phoneWorkHomeIcon } from "components/Common/Icons/Icons";

import { useGlobal } from "core/useHooks";
import { GLOBAL_PHONE_TYPE_OPTIONS } from "components/global/constants";

/**
 * Component for displaying Phone Items
 */
const PhoneItemsView = (props) => {
	const {
		personId = null,
		phones,
		primaryPhoneId,
		onSavePhone,
		onDeletePhone,
		onSavePrimary,
		onCallPhone = () => {},
		maxCount,
		isFixedType = false,
		fixedType = 0,
	} = props;

	const newPhone = {
		phone_type_c: isFixedType ? fixedType : 2,
		[global_phone_details._name]: {
			[global_phone_details.phone_number]: undefined,
			[global_phone_details.phone_country_code]: undefined,
			[global_phone_details.phone_extension]: undefined,
			[global_phone_details.phone_status_c]: null,
		},
	};

	const [isEditAddPhone, setIsEditAddPhone] = useState(false);

	const { globals } = useGlobal();

	const handleDelete = (phoneId) => {
		onDeletePhone(phoneId);
	};

	const handleSaveNewPhone = (data, primary) => {
		onSavePhone(data, (res, successCallBack) => {
			const newPhone = res?.find((item) => item.id);
			if (primary) {
				onSavePrimary(newPhone?.id, successCallBack, successCallBack);
			} else {
				if (successCallBack) {
					successCallBack();
				}
			}
		});

		setIsEditAddPhone(false);
	};

	const handleCancelNewPhone = () => {
		setIsEditAddPhone(false);
	};

	const getPhonePrimary = (itemIndex) => {
		let res = false;
		if (phones?.[itemIndex]?.id) {
			if (phones?.[itemIndex]?.id === primaryPhoneId) {
				res = true;
			}
		}
		return res;
	};

	const handleSavePhone = (data, primary) => {
		onSavePhone(data, (res, successCallBack) => {
			if (primary || (!primary && data.id === primaryPhoneId)) {
				onSavePrimary(
					primary ? data?.id : null,
					successCallBack,
					successCallBack
				);
			} else {
				if (successCallBack) {
					successCallBack();
				}
			}
		});
	};

	const { phoneItems, phoneMainItem } = useMemo(() => {
		const phoneItems = phones?.map((item, idx) => {
			let isPhoneWorkType = false;
			GLOBAL_PHONE_TYPE_OPTIONS.map((itemType) => {
				isPhoneWorkType =
					itemType.value == 3 || itemType.value == 5 || itemType.value == 6;
			});

			return [
				<EditablePhone
					key={`phone-existing-item-${idx}`}
					personId={personId}
					phone={item}
					isPrimary={getPhonePrimary(idx)}
					onSave={handleSavePhone}
					onDelete={handleDelete}
					onCallPhone={onCallPhone}
					isFixedType={isFixedType}
					fixedType={fixedType}
				/>,
				isPhoneWorkType,
			];
		});

		let mainPhoneIndex = 0;
		const primayIndex = phones?.findIndex((item) => item.id === primaryPhoneId);
		if (primayIndex !== -1) {
			mainPhoneIndex = primayIndex;
		}
		const phoneMainItem = phones?.[mainPhoneIndex] ? (
			<EditablePhone
				personId={personId}
				phone={phones[mainPhoneIndex]}
				phoneIndex={mainPhoneIndex}
				isPrimary={getPhonePrimary(mainPhoneIndex)}
				onSave={handleSavePhone}
				onDelete={handleDelete}
				onCallPhone={onCallPhone}
				hasIcon={false}
				isFixedType={isFixedType}
				fixedType={fixedType}
			/>
		) : null;

		return { phoneItems, phoneMainItem };
	}, [phones, primaryPhoneId]);

	return (
		<CommonItemView
			title="Personal"
			icon={phoneWorkHomeIcon()}
			items={phoneItems}
			mainItem={phoneMainItem}
			newItem={
				<EditPhoneItem
					phone={newPhone}
					phonePrimary={false}
					onSave={handleSaveNewPhone}
					onCancel={handleCancelNewPhone}
					isFixedType={isFixedType}
					fixedType={fixedType}
				/>
			}
			isEdit={isEditAddPhone}
			setIsEdit={setIsEditAddPhone}
			className="phone-view"
			maxCount={maxCount}
			isFixedType={isFixedType}
			fixedType={fixedType}
		/>
	);
};

export default PhoneItemsView;
