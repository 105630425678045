import { useEffect } from "react";
import { useDispatch } from "react-redux";
import {
	removeSourcingUrlSearch,
	setSourcingFlyout,
	getSourcingList_NEW,
	setSourcingListParams_NEW,
	getSourcingDetail_NEW,
	getSourcingPeopleList_NEW,
	setSourcingPeopleListParams_NEW,
	getCompanyMainList_NEW,
	getPersonMainList_NEW,
	getQualifierMainList_NEW,
	getCampaignMainList_NEW,
	createSourcing,
	runSourcingProcess,
	setSourcingEditFlyout,
	deleteSourcing,
	getCampaignList_NEW,
	getSourcingProcessProgress,
} from "helpers/actions";
import {
	_getGlobals,
	_getSourcingList_NEW,
	_getSourcingPeopleList_NEW,
	_getSourcingDetail_NEW,
	_getSourcingSave,
} from "helpers/selectors";
import { MID_CNT_PER_PAGE } from "components/global/constants";

/**
 * Use apis for manual create record
 */
const useSourcing = () => {
	const dispatch = useDispatch();

	const globals = _getGlobals();
	const sourcingListInfo = _getSourcingList_NEW();
	const sourcingPeopleListInfo = _getSourcingPeopleList_NEW();
	const sourcingDetail = _getSourcingDetail_NEW();
	const saveSourcing = _getSourcingSave();

	return {
		globals,
		sourcingListInfo,
		sourcingPeopleListInfo,
		sourcingDetail,
		saveSourcing,

		getAllSourcingList: (pageNumber, successCallback, failCallback) => {
			const params = {
				page_number: pageNumber || 1,
				page_count: MID_CNT_PER_PAGE,
			};
			dispatch(getSourcingList_NEW(params, successCallback, failCallback));
		},

		setSourcingListParams: (param) => {
			dispatch(setSourcingListParams_NEW(param));
		},

		getSourcingPeopleList: (params, successCallback, failCallback) => {
			dispatch(getSourcingPeopleList_NEW(params, successCallback, failCallback));
		},

		setSourcingPeopleListParams: (param) => {
			dispatch(setSourcingPeopleListParams_NEW(param));
		},

		getSourcingDetail: (id) => {
			dispatch(getSourcingDetail_NEW(id));
		},

		setSourcingFlyout: (open) => {
			dispatch(setSourcingFlyout(open));
		},

		setSourcingEditFlyout: (open) => {
			dispatch(setSourcingEditFlyout(open));
		},

		removeSourcingUrlSearch: () => {
			dispatch(removeSourcingUrlSearch());
		},

		getPersons: (searchTerm) => {
			dispatch(getPersonMainList_NEW({ searchTerm, pageCount: 10 }));
		},

		getCompanies: (searchTerm) => {
			dispatch(getCompanyMainList_NEW({ searchTerm, pageCount: 10 }));
		},

		getQualifiers: (searchTerm) => {
			dispatch(getQualifierMainList_NEW({ searchTerm, pageCount: 10 }));
		},

		getCampaigns: (searchTerm) => {
			dispatch(getCampaignMainList_NEW({ searchTerm, pageCount: 10 }));
		},

		createSourcing: (payload, successCallBack, failCallBack) => {
			return new Promise((resolve, reject) => {
				dispatch(
					createSourcing(
						payload,
						(res) => {
							if (successCallBack) successCallBack(res);
							resolve(res);
						},
						(err) => {
							if (failCallBack) failCallBack(err);
							reject(err);
						}
					)
				);
			});
		},

		runSourcingProcess: (payload) => {
			dispatch(runSourcingProcess(payload));
		},

		deleteSourcing: (query) => {
			dispatch(deleteSourcing(query));
		},

		getCampaignList: (params, successCallBack, failCallBack) => {
			dispatch(getCampaignList_NEW(params, successCallBack, failCallBack));
		},

		getSourcingProcessProgress: (params, successCallBack, failCallBack) => {
			dispatch(getSourcingProcessProgress(params, successCallBack, failCallBack));
		},
	};
};

export default useSourcing;
