import { applyMiddleware, compose, createStore } from "redux";
import { persistStore, persistReducer } from "redux-persist";
import thunk from "redux-thunk";

import { rootReducer, initialState } from "./rootReducer";
import persistConfig from "./persistConfig";

const middleware = [thunk];

const persistedReducer = persistReducer(persistConfig, rootReducer);

export default function configreStore() {
    const store = createStore(
        persistedReducer,
        initialState,
        compose(
            applyMiddleware(...middleware),
            window.devToolsExtension ? window.devToolsExtension() : (f) => f
        )
    );

    const persistor = persistStore(store);

    return {
        store,
        persistor,
    };
}
