import * as actionTypes from "./actionTypes";

/**
 * Functions
 */
const setSourcingCustomFilter = (state, payload) => {
	return {
		...state,
		customFilters: {
			...state.customFilters,
			[payload.key]: payload.value,
		},
	};
};

const hideRecordsFromSourcingSearch = (state, payload) => {
	alert("hiding from soourcing list");

	// Pass "filtered" sourcing people list here

	return {
		...state,
		sourcingPeopleList: [],
		sourcingPeopleListCount: 3,
	};
};

const getSourcingPeopleList = (state, payload) => {
	return {
		...state,
		sourcingPeopleList: [...payload.data],
		sourcingPeopleListCount: payload.totalCount,
		setType: payload.setType,
	};
};

const getSourcingDetail = (state, payload) => {
	return {
		...state,
		sourcingDetail: payload,
	};
};

const getCredentialList = (state, payload) => {
	return {
		...state,
		credentials: payload,
	};
};

const setSourcingFlyout = (state, payload) => {
	return {
		...state,
		sourcingFlyout: payload,
	};
};

const setSourcingId = (state, payload) => {
	return {
		...state,
		sourcingId: payload,
	};
};

const setSourcingEditFlyout = (state, payload) => {
	return {
		...state,
		sourcingEditFlyout: payload,
	};
};

const setSourcingSearchId = (state, payload) => {
	return {
		...state,
		sourcingSearchId: payload,
	};
};

const saveSourcing = (state, payload) => {
	return {
		...state,
		saveSourcing: payload,
	};
};

const createSourcingDraft = (state, payload) => {
	return {
		...state,
		createSourcingDraft: payload,
	};
};

const addURLSourcingSearch = (state, payload) => {
	return {
		...state,
		urlSourcingSearch: [...state.urlSourcingSearch, payload],
	};
};

const removeUrlSourcingSearch = (state, payload) => {
	const newUrlSourcingSearch = state.urlSourcingSearch.filter(
		(item) => item.index !== payload
	);

	return {
		...state,
		urlSourcingSearch: newUrlSourcingSearch,
	};
};

const deleteSourcing = (state, payload) => {
	return {
		...state,
		deleteSourcing: payload,
	};
};

const runSourcingProcess = (state, payload) => {
	return {
		...state,
		runSourcingProcess: payload,
	};
};

const removeSourcingUrlSearch = (state, payload) => {
	return {
		...state,
		urlSourcingSearch: [],
	};
};

const setPageActive = (state, payload) => {
	return {
		...state,
		activePage: payload,
	};
};

const setSourcingFilter = (state, payload) => {
	return {
		...state,
		sourcingFilter: payload,
	};
};

const setSourcingSearchValue = (state, payload) => {
	return {
		...state,
		sourcingSearchValue: payload,
	};
};

const getSourcingSelectPopoverOptions = (state, payload) => {
	return {
		...state,
		sourcingSelectPopoverOptions: [...payload],
	};
};

const setSourcingSelectedPopoverOption = (state, payload) => {
	return {
		...state,
		sourcingSelectedPopoverOption: payload,
	};
};

const setSourcingProcessId = (state, payload) => {
	return {
		...state,
		sourcingProcessId: payload,
	};
};

const setSourcingPerPage = (state, payload) => {
	return {
		...state,
		sourcingPerPage: payload,
	};
};

/** -------------------- NEW ------------------------- */
// Sourcing List
function setSourcingList_NEW(state, payload) {
	return {
		...state,
		sourcingList_NEW: {
			...state.sourcingList_NEW,
			...(payload || {}),
			isRequesting: true,
			isReceived: false,
		},
	};
}

const requestSourcingList_NEW = (state) => {
	return {
		...state,
		sourcingList_NEW: {
			...state.sourcingList_NEW,
			isRequesting: true,
			isReceived: false,
		},
	};
};

const receivedSourcingList_NEW = (state) => {
	return {
		...state,
		sourcingList_NEW: {
			...state.sourcingList_NEW,
			isRequesting: false,
			isReceived: true,
		},
	};
};

const setSourcingListParams_NEW = (state, payload) => {
	return {
		...state,
		sourcingList_NEW: {
			...state.sourcingList_NEW,
			...(payload || {}),
		},
	};
};

// Sourcing People List
function setSourcingPeopleList_NEW(state, payload) {
	return {
		...state,
		sourcingPeopleList_NEW: {
			...state.sourcingPeopleList_NEW,
			...(payload || {}),
			isRequesting: false,
			isReceived: true,
		},
	};
}

const requestSourcingPeopleList_NEW = (state) => {
	return {
		...state,
		sourcingPeopleList_NEW: {
			...state.sourcingPeopleList_NEW,
			isRequesting: true,
			isReceived: false,
		},
	};
};

const receivedSourcingPeopleList_NEW = (state) => {
	return {
		...state,
		sourcingPeopleList_NEW: {
			...state.sourcingPeopleList_NEW,
			isRequesting: false,
			isReceived: true,
		},
	};
};

const setSourcingPeopleListParams_NEW = (state, payload) => {
	return {
		...state,
		sourcingPeopleList_NEW: {
			...state.sourcingPeopleList_NEW,
			...(payload || {}),
		},
	};
};

// Sourcing Main Table
const setSourcingMainList_NEW = (state, payload) => {
	return {
		...state,
		sourcingMainList_NEW: {
			...state.sourcingMainList_NEW,
			...payload,
			isRequesting: false,
			isReceived: true,
		},
	};
};

// Sourcing Detail
const setSourcingDetail_NEW = (state, payload) => {
	return {
		...state,
		sourcing_NEW: {
			...state.sourcing_NEW,
			...payload,
		},
	};
};

// Get sourcing process progress
const setGetSourcingProcessProgress = (state, payload) => {
	return {
		...state,
		sourcingProcessProgress: payload,
	};
};

/**
 * Initial State
 */
const initialState = {
	sourcingList: [],
	sourcingListTotalCount: 0,
	sourcingPeopleList: [],
	sourcingPeopleListCount: 0,
	sourcingSearchId: 0,
	sourcingDetail: {},
	loading: false,
	credentials: [],
	sourcingFlyout: false,
	sourcingId: 0,
	sourcingEditFlyout: false,
	sourcingEditDetail: {},
	createSourcing: {},
	createSourcingDraft: {},
	urlSourcingSearch: [],
	deleteSourcing: {},
	runSourcingProcess: {},
	activePage: 0,
	sourcingFilter: {},
	sourcingSearchValue: "",
	sourcingSelectPopoverOptions: [],
	sourcingSelectedPopoverOption: {
		ID: "",
		NAME: "All Searches",
	},
	sourcingProcessId: "",
	sourcingPerPage: 30,
	setType: null,

	customFilters: {
		hidden: false,
	},

	bulkActionStates: {
		loading: null,
		successfullyAffectedRecords: null,
	},

	/* ------------------------- NEW ------------------------- */
	sourcingMainList_NEW: {
		totalCount: 0,
		page: 1,
		per_page: 30,
		data: [],
		searchTerm: "",
		isRequesting: false,
		isReceived: false,
	},

	sourcingList_NEW: {
		totalCount: 0,
		page: 1,
		per_page: 30,
		filters: {},
		data: [],
		isRequesting: false,
		isReceived: false,
	},

	sourcingPeopleList_NEW: {
		totalCount: 0,
		page: 1,
		per_page: 30,
		filters: {},
		data: [],
		sourcing_search_id: 0,
		isRequesting: false,
		isReceived: false,
	},

	sourcing_NEW: {},
	/* ------------------------------------------------------- */
};

/**
 * Reducer
 */
const Reducer = (state = initialState, action) => {
	switch (action.type) {
		case actionTypes.GET_PEOPLE_LIST_BY_SOURCING_SEARCH_ID:
			return getSourcingPeopleList(state, action.payload);

		case actionTypes.GET_SOURCING_DETAIL:
			return getSourcingDetail(state, action.payload);

		case actionTypes.GET_ALL_SOURCING_CREDENTIAL:
			return getCredentialList(state, action.payload);

		case actionTypes.SET_SOURCING_FLYOUT:
			return setSourcingFlyout(state, action.payload);

		case actionTypes.SET_SOURCING_ID:
			return setSourcingId(state, action.payload);

		case actionTypes.SET_SOURCING_EDIT_FLYOUT:
			return setSourcingEditFlyout(state, action.payload);

		case actionTypes.SET_SOURCING_SEARCH_ID:
			return setSourcingSearchId(state, action.payload);

		case actionTypes.SAVE_SOURCING:
			return saveSourcing(state, action.payload);

		case actionTypes.ADD_URL_SOURCING_SEARCH:
			return addURLSourcingSearch(state, action.payload);

		case actionTypes.REMOVE_URL_SOURCING_SEARCH:
			return removeUrlSourcingSearch(state, action.payload);

		case actionTypes.DELETE_SOURCING:
			return deleteSourcing(state, action.payload);

		case actionTypes.RUN_SOURCING_NEW:
			return runSourcingProcess(state, action.payload);

		case actionTypes.REMOVE_SOURCING_URL_SEARCH:
			return removeSourcingUrlSearch(state, action.payload);

		case actionTypes.SET_ACTIVE_PAGE:
			return setPageActive(state, action.payload);

		case actionTypes.SET_SOURCING_FILTER:
			return setSourcingFilter(state, action.payload);

		case actionTypes.SET_SOURCING_CUSTOM_FILTER:
			return setSourcingCustomFilter(state, action.payload);

		case actionTypes.SET_SOURCING_SEARCH_VALUE:
			return setSourcingSearchValue(state, action.payload);

		case actionTypes.CREATE_SOURCING_DRAFT:
			return createSourcingDraft(state, action.payload);

		case actionTypes.HIDE_FROM_SOURCING_SEARCH:
			return hideRecordsFromSourcingSearch(state, action.payload);

		case actionTypes.GET_SOURCING_SELECT_POPOVER_OPTIONS:
			return getSourcingSelectPopoverOptions(state, action.payload);

		case actionTypes.SET_SOURCING_SELECTED_POPOVER_OPTION:
			return setSourcingSelectedPopoverOption(state, action.payload);

		case actionTypes.SET_SOURCING_PROCESS_ID:
			return setSourcingProcessId(state, action.payload);

		case actionTypes.SET_SOURCING_PER_PAGE:
			return setSourcingPerPage(state, action.payload);

		// ----------------------- NEW -----------------------
		// List
		case actionTypes.SET_SOURCING_LIST_NEW:
			return setSourcingList_NEW(state, action.payload);

		case actionTypes.REQUEST_SOURCING_LIST_NEW:
			return requestSourcingList_NEW(state);

		case actionTypes.RECEIVED_SOURCING_LIST_NEW:
			return receivedSourcingList_NEW(state);

		case actionTypes.SET_SOURCING_LIST_PARAMS_NEW:
			return setSourcingListParams_NEW(state, action.payload);

		// Sourcing People List
		case actionTypes.SET_SOURCING_PEOPLE_LIST_NEW:
			return setSourcingPeopleList_NEW(state, action.payload);

		case actionTypes.REQUEST_SOURCING_PEOPLE_LIST_NEW:
			return requestSourcingPeopleList_NEW(state);

		case actionTypes.RECEIVED_SOURCING_PEOPLE_LIST_NEW:
			return receivedSourcingPeopleList_NEW(state);

		case actionTypes.SET_SOURCING_PEOPLE_LIST_PARAMS_NEW:
			return setSourcingPeopleListParams_NEW(state, action.payload);

		// Main List
		case actionTypes.SET_SOURCING_MAINLIST_NEW:
			return setSourcingMainList_NEW(state, action.payload);

		// Detail
		case actionTypes.SET_SOURCING_DETAIL_NEW:
			return setSourcingDetail_NEW(state, action.payload);

		// Get sourcing process progress
		case actionTypes.GET_SOURCING_PROCESS_PORGRESS:
			return setGetSourcingProcessProgress(state, action.payload);

		default:
			return state;
	}
};

export default Reducer;
