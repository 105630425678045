import React from "react";
import { EuiFlexItem, EuiFlexGroup } from "@elastic/eui";
import styled from "styled-components";

import { EditableNumber, EditableNumberRange } from "components/Common";
import { set } from "lodash";

/**
 * Component for Hourly Item
 */
const HourlyItem = (props) => {
	const { data, onChange } = props;

	const { value, low, high } = data;

	const handleChangeValue = (newValue) => {
		const _data = set(data, `value`, newValue);
		onChange(_data);
	};
	const handleChangeLowValue = (newLow) => {
		const _data = set(data, `low`, newLow);
		onChange(_data);
	};
	const handleChangeHighValue = (newHigh) => {
		const _data = set(data, `high`, newHigh);
		onChange(_data);
	};

	return (
		<Wrapper>
			<EuiFlexGroup gutterSize="s">
				<EuiFlexItem>
					<EditableNumber
						value={value}
						prepend="$"
						append="/hr"
						onChange={handleChangeValue}
					/>
				</EuiFlexItem>
				<EuiFlexItem grow={false}>
					<EuiFlexGroup gutterSize="xs">
						<EuiFlexItem grow={false}>
							<span>(</span>
						</EuiFlexItem>
						<EuiFlexItem grow={false}>
							<EditableNumberRange
								low={low}
								high={high}
								prepend="$"
								onChangeLow={handleChangeLowValue}
								onChangeHigh={handleChangeHighValue}
							/>
						</EuiFlexItem>
						<EuiFlexItem grow={false}>
							<span>)</span>
						</EuiFlexItem>
					</EuiFlexGroup>
				</EuiFlexItem>
			</EuiFlexGroup>
		</Wrapper>
	);
};

export default HourlyItem;

const Wrapper = styled.div`
	display: flex;
`;
