import React from "react";
import { EuiFlexGroup, EuiFlexItem, EuiCheckbox, EuiText } from "@elastic/eui";
import { htmlIdGenerator } from "@elastic/eui/lib/services";
import styled from "styled-components";

import variables from "assets/css/variables.scss";

const CheckBoxWithRoundCheckIcon = (props) => {
	const { label, onChange, checked } = props;

	const handleChange = (e) => {
		onChange(e.target.checked);
	};

	return (
		<Wrapper>
			<EuiFlexGroup gutterSize="s" direction="row">
				<EuiFlexItem grow={false}>
					<EuiCheckbox id={htmlIdGenerator()()} label="" checked={checked} onChange={handleChange} className="d-flex check-option" />
				</EuiFlexItem>
				<EuiFlexItem>{label}</EuiFlexItem>
			</EuiFlexGroup>
		</Wrapper>
	);
};

export default CheckBoxWithRoundCheckIcon;

const Wrapper = styled.div`
	display: flex;
	position: relative;
	width: 100%;

	.check-option {
		.euiCheckbox__square {
			border-radius: 50%;
			border-color: ${variables.lightColor};
			background-color: ${variables.lightColor};
			background-image: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' width='10' height='8' viewBox='0 0 10 8'><path d='M.375 2.625L3.375 5.625M3.375 5.625L8.625.375' fill='none' fill-rule='evenodd' stroke='rgb%28255, 255, 255%29' stroke-linecap='round' stroke-width='1.5' transform='translate(.5 1)'/></svg>");
		}
	}
`;
