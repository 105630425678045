import React from "react";
import styled from "styled-components";

import { checkIcon } from "components/Common/Icons/Icons";

const Address = (props) => {
	const {
		index = null,
		address,
		...restProps
	} = props;

	const getCombinedFields = (data, field, field2) => {
		if (data[field] && data[field2]) {
			return data[field] + " " + data[field2];
		} else if (data[field]) {
			return data[field];
		} else {
			return data[field2];
		}
	};

	return (
		<Wrapper {...restProps}>
			{index != null && <div className="index">{index + 1}.</div>}
			<div className="address">
				{address?.address_line_1 && <div className="address-entry">{address?.address_line_1}</div>}
				{address?.address_line_2 && <div className="address-entry">{address?.address_line_2}</div>}
				{address?.address_city && <div className="address-entry">{address?.address_city}</div>}
				{address?.address_state && <div className="address-entry">{address?.address_state}</div>}
				{(address?.address_zip || address?.address_country) && 
					<div className="address-entry">
						{getCombinedFields(address, "address_zip", "address_country")}
					</div>
				}
			</div>
			{address?.is_valid && <div className="check-icon">{checkIcon()}</div>}
		</Wrapper>
	);
};

export default Address;

/**
 * Styled Component
 */
const Wrapper = styled.div`
	display: flex;
	flex-direction: row;

	.index {
		font-weight: 700;
		padding-right: 1rem;
	}

	.address {
		.address-entry {
			text-align: left;
		}
	}

	.check-icon {
		margin-left: auto;
	}
`;
