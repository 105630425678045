import React, { useMemo } from "react";
import styled from "styled-components";
import { EuiFlexGroup, EuiFlexItem } from "@elastic/eui";

import { ScreeningSummary, SIT, CompensationPlans } from "components/Common";
import * as MODEL from "core/model";
import { getGCSValue } from "components/global/utils";
import { useGlobal } from "core/useHooks";

/**
 * Main Components for Screening Overview
 */
const ScreeningOverview = (props) => {
	/**
	 * Use parameters of Props as the following format
	 */
	const {
		personId,
		compensations,
		sits,
		onEditSIT,
		onChangeObjectSitType,
		onDeleteSit,
	} = props;

	const { globals } = useGlobal();

	const { skillKeyValue, titleKeyValue, industryKeyValue, skills, titles, industies } =
		useMemo(() => {
			let skillKey = -1;
			let titleKey = -1;
			let industryKey = -1;
			const skills = [];
			const titles = [];
			const industies = [];

			if (globals?.length > 0) {
				skillKey = getGCSValue(globals, "sit_main", "sit_type_c", "skill");
				titleKey = getGCSValue(globals, "sit_main", "sit_type_c", "title");
				industryKey = getGCSValue(globals, "sit_main", "sit_type_c", "industry");

				if (sits?.length > 0) {
					sits.forEach((item) => {
						const sitType =
							item?.[MODEL.sit_main._name]?.[MODEL.sit_main.sit_type_c];

						switch (sitType) {
							case skillKey:
								skills.push(item);
								break;
							case titleKey:
								titles.push(item);
								break;
							case industryKey:
								industies.push(item);
								break;
							default:
								break;
						}
					});
				}
			}

			return {
				skillKeyValue: skillKey,
				titleKeyValue: titleKey,
				industryKeyValue: industryKey,
				skills,
				titles,
				industies,
			};
		}, [globals, sits]);

	const handleEditSIT = (type) => {
		onEditSIT(type);
	};

	return (
		<Wrapper>
			<EuiFlexGroup direction="column" gutterSize="s">
				<EuiFlexItem>
					<CompensationPlans compensations={compensations} />
				</EuiFlexItem>
				<EuiFlexItem>
					<ScreeningSummary personId={personId} />
				</EuiFlexItem>
				<EuiFlexItem>
					<SIT
						sits={titles}
						sitType={titleKeyValue}
						title="Title(s) Desired"
						typeLabel="title"
						onEditSIT={handleEditSIT}
						onChangeObjectSitType={onChangeObjectSitType}
						onDeleteItem={onDeleteSit}
					/>
				</EuiFlexItem>
				<EuiFlexItem>
					<SIT
						sits={skills}
						sitType={skillKeyValue}
						title="Skills"
						typeLabel="skill"
						onEditSIT={handleEditSIT}
						onChangeObjectSitType={onChangeObjectSitType}
						onDeleteItem={onDeleteSit}
					/>
				</EuiFlexItem>
				<EuiFlexItem>
					<SIT
						sits={industies}
						sitType={industryKeyValue}
						title="Industies"
						typeLabel="industry"
						onEditSIT={handleEditSIT}
						onChangeObjectSitType={onChangeObjectSitType}
						onDeleteItem={onDeleteSit}
					/>
				</EuiFlexItem>
				{/* <EuiFlexItem>
					<SIT title="Education" typeLabel="education" />
				</EuiFlexItem> */}
				{/* <EuiFlexItem>
					<SIT title="Visa" typeLabel="visa" />
				</EuiFlexItem> */}
			</EuiFlexGroup>
		</Wrapper>
	);
};

export default ScreeningOverview;

/**
 * Styled Components
 */
const Wrapper = styled.div`
	padding: 0px 10px;
`;
