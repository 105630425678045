/**
 * Qualifiers
 */
export const FETCH_QUALIFIERS = "@@qualifiers/FETCH_QUALIFIERS";
export const SET_QUALIFIERS = "@@qualifiers/SET_QUALIFIERS";
export const SET_QUALIFIERS_FLYOUT = "@@qualifiers/SET_QUALIFIERS_FLYOUT";
export const SET_QUALIFIER_ID = "@@qualifiers/SET_QUALIFIER_ID";
export const SET_QUALIFIER_NODE = "@@qualifiers/SET_QUALIFIER_NODE";
export const DELETE_QUALIFIER = "@@qualifiers/DELETE_QUALIFIER";
export const GET_QUALIFIER_THEME_DETAIL = "@@qualifiers/GET_QUALIFIER_THEME_DETAIL";
export const UPDATE_QUALIFIER_THEME = "@@qualifiers/UPDATE_QUALIFIER_THEME";
export const CREATE_QUALIFIER_THEME = "@@qualifiers/CREATE_QUALIFIER_THEME";
export const CREATE_QUALIFIER = "@@qualifiers/CREATE_QUALIFIER";
export const UPDATE_QUALIFIER = "@@qualifiers/UPDATE_QUALIFIER";

/* ------------------ NEW ----------------- */
// Qualifier Main Table
export const SET_QUALIFIER_MAINLIST_NEW = "@@qualifiers/SET_QUALIFIER_MAINLIST_NEW";
export const GET_QUALIFIER_MAINLIST_NEW = "@@qualifiers/GET_QUALIFIER_MAINLIST_NEW";
export const REQUEST_QUALIFIER_MAINLIST_NEW =
	"@@qualifiers/REQUEST_QUALIFIER_MAINLIST_NEW";
export const RECEIVED_QUALIFIER_MAINLIST_NEW =
	"@@qualifiers/RECEIVED_QUALIFIER_MAINLIST_NEW";
