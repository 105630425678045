import React, { useState, useCallback, useEffect } from "react";
import styled from "styled-components";
import {
	EuiFormControlLayout,
	EuiFlexGroup,
	EuiFlexItem,
	EuiPopover,
} from "@elastic/eui";
import _ from "lodash";
import { peopleIcon, jobIcon, companyIcon } from "components/Common/Icons";
import useLayout from "components/Layout/useLayout";
import GlobalSearchPerson from "./GlobalSearchPerson";
import GlobalSearchCompany from "./GlobalSearchCompany";
import GlobalSearchJob from "./GlobalSearchJob";

// constant
const CAT_ICONS = {
	PEOPLE: "PEOPLE",
	COMPANY: "COMPANY",
	JOB: "JOB",
};

const GlobalSearch = () => {
	const { globalSearchItems, getGlobalSearchItem } = useLayout();

	const [searchValue, setSeachValue] = useState("");
	const [isPopoverOpen, setIsPopoverOpen] = useState(false);
	const [selectedCatIcon, setSelectedCatIcon] = useState(null);
	const [selectedSearchItems, setSelectedSearchItems] = useState(globalSearchItems);

	// effect
	useEffect(() => {
		getSelectedSearchItems(selectedCatIcon);
	}, [globalSearchItems]);

	// functions
	const showSuggestions = () => {
		toggleIsPopoverOpen(true);
		handleSearchItem(searchValue);
	};

	const handleSearchItem = (val) => {
		getGlobalSearchItems(val);
		setSeachValue(val);
	};

	const toggleIsPopoverOpen = (shouldBeOpen = !isPopoverOpen) => {
		setIsPopoverOpen(shouldBeOpen);
	};

	// calling search items by using useLayout
	const getGlobalSearchItems = useCallback(
		// debounce calling
		_.debounce((val) => {
			getGlobalSearchItem(val);
		}, 500),
		[]
	);

	// change the selected category icon
	const handleChangeCategory = (val) => {
		let _selectedCatIcon = selectedCatIcon;

		if (selectedCatIcon === val) {
			_selectedCatIcon = null;
		} else {
			_selectedCatIcon = val;
		}

		setSelectedCatIcon(_selectedCatIcon);
		getSelectedSearchItems(_selectedCatIcon);
	};

	const getSelectedSearchItems = (selectedCat) => {
		let _selectedSearchItems = null;
		if (selectedCat === CAT_ICONS.PEOPLE) {
			_selectedSearchItems = {
				people: globalSearchItems?.people,
			};
		} else if (selectedCat === CAT_ICONS.COMPANY) {
			_selectedSearchItems = {
				companies: globalSearchItems?.companies,
			};
		} else if (selectedCat === CAT_ICONS.JOB) {
			_selectedSearchItems = {
				jobs: globalSearchItems?.jobs,
			};
		} else {
			_selectedSearchItems = globalSearchItems;
		}
		setSelectedSearchItems(_selectedSearchItems);
	};

	const handleGlobalSearch = () => {
		getGlobalSearchItem(searchValue);
	};

	const clearSearchValue = () => {
		setSeachValue("");
		getGlobalSearchItem("");
	};

	// render UI for search
	const input = (
		<EuiFormControlLayout clear={{ onClick: () => clearSearchValue() }} icon="search">
			<input
				type="text"
				className="euiFieldSearch"
				aria-label="global-search"
				placeholder="Search"
				value={searchValue}
				onChange={(e) => handleSearchItem(e.target.value)}
				onFocus={showSuggestions}
			/>
		</EuiFormControlLayout>
	);

	return (
		<Wrapper>
			<EuiPopover
				button={input}
				isOpen={isPopoverOpen}
				closePopover={() => {
					toggleIsPopoverOpen(false);
				}}
				buffer={0}
				hasArrow={false}
				anchorPosition="downRight"
				panelPaddingSize="none"
				panelClassName="search-pannel"
			>
				<SearchPanel>
					<EuiFlexGroup direction="column">
						<EuiFlexItem className="search-header">
							<CategoryIcon
								onClick={(e) => handleChangeCategory(CAT_ICONS.PEOPLE)}
								className={
									selectedCatIcon === CAT_ICONS.PEOPLE ? "selected" : ""
								}
							>
								{peopleIcon()}
							</CategoryIcon>
							<CategoryIcon
								onClick={(e) => handleChangeCategory(CAT_ICONS.COMPANY)}
								className={
									selectedCatIcon === CAT_ICONS.COMPANY
										? "selected"
										: ""
								}
							>
								{companyIcon()}
							</CategoryIcon>
							<CategoryIcon
								onClick={(e) => handleChangeCategory(CAT_ICONS.JOB)}
								className={
									selectedCatIcon === CAT_ICONS.JOB ? "selected" : ""
								}
							>
								{jobIcon()}
							</CategoryIcon>
						</EuiFlexItem>
						<EuiFlexItem className="search-body">
							<GlobalSearchPerson
								data={selectedSearchItems?.people}
								onGlobalSearch={handleGlobalSearch}
							/>
							<GlobalSearchCompany
								data={selectedSearchItems?.companies}
								onGlobalSearch={handleGlobalSearch}
							/>
							<GlobalSearchJob data={selectedSearchItems?.jobs} />
						</EuiFlexItem>
					</EuiFlexGroup>
				</SearchPanel>
			</EuiPopover>
		</Wrapper>
	);
};

export default GlobalSearch;

/**
 * Styled components
 */
const Wrapper = styled.div`
	.euiFormControlLayout {
		height: unset;
	}
`;

const SearchPanel = styled.div`
	.search-header {
		display: flex;
		flex-direction: row;
		padding: 4px;
		justify-content: center;
		border-bottom: solid 1px var(--border-color);
	}

	.search-body {
		padding: 0 10px 10px 10px;
		margin-top: 0 !important;
	}

	.search-title {
		font-size: 14px;
		color: var(--base-color);
		text-decoration: underline;
	}

	.search-count {
		font-size: 14px;
		color: var(--ligth-color);
	}

	.search-ellipsis {
		text-overflow: ellipsis;
		overflow: hidden;
		white-space: nowrap;
		width: 250px;
	}

	.search-card {
		padding: 2px 0;
		border-bottom: 1px solid var(--border-color);
	}
`;

const CategoryIcon = styled.div`
	width: fit-content;
	padding: 4px 8px;
	display: flex;
	margin: auto 0;
	border-bottom: 2px solid transparent;
	border-radius: 2px;

	&:hover {
		background: var(--self-bg-color);
	}

	&.selected {
		border-bottom: 2px solid var(--eui-link-color);
	}
`;
