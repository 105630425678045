import React, { useState, useMemo } from "react";

import { global_email_details } from "core/model";

import CommonItemView from "../CommonItemView";
import EditEmailItem from "./EditEmailItem";
import EditableEmail from "./EditableEmail";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEnvelope } from "@fortawesome/pro-light-svg-icons";

import { getGCSValue } from "components/global/utils";
import { useGlobal } from "core/useHooks";

import { checkIcon } from "components/Common/Icons/Icons"

/**
 * Component for displaying Email Items
 */
const EmailItemsView = (props) => {
	const {
		personId = null,
		emails,
		primaryEmailId,
		onSaveEmail,
		onDeleteEmail,
		onSavePrimary,
		onSendMessage,
	} = props;

	const newEmail = {
		email_type_c: 1,
		[global_email_details._name]: {
			[global_email_details.email_value]: undefined,
			[global_email_details.email_status_date]: undefined,
			[global_email_details.email_status_c]: null,
		},
	};
	const { globals } = useGlobal();
	const mailWorkType = getGCSValue(globals, "global", "email_type_c", "work");

	const [isEditAddEmail, setIsEditAddEmail] = useState(false);

	const saveEmailPrimary = (primaryEmailId) => {
		onSavePrimary(primaryEmailId);
	};

	const handleDelete = (emailId) => {
		onDeleteEmail(emailId);
	};

	const handleSaveNewEmail = (data, primary) => {
		onSaveEmail(data, (res, successCallBack) => {
			const newEmail = res?.find((item) => item.id);
			if (primary) {
				saveEmailPrimary(newEmail?.id);
			} else {
				if (successCallBack) {
					successCallBack();
				}
			}
		});

		setIsEditAddEmail(false);
	};

	const handleCancelNewEmail = () => {
		setIsEditAddEmail(false);
	};

	const getEmailPrimary = (itemIndex) => {
		let res = false;
		if (emails?.[itemIndex]?.id) {
			if (emails?.[itemIndex]?.id === primaryEmailId) {
				res = true;
			}
		}
		return res;
	};

	const handleSaveEmail = (data, primary) => {
		onSaveEmail(data, (res, successCallBack) => {
			if (primary || (!primary && data.id === primaryEmailId)) {
				saveEmailPrimary(primary ? data?.id : null);
			} else {
				if (successCallBack) {
					successCallBack();
				}
			}
		});
	};

	const { emailItems, emailMainItem } = useMemo(() => {
		let isEmailWorkType = false;
		const emailItems = emails?.map((item, idx) => {
			isEmailWorkType = item.email_type_c == mailWorkType ? true : false;
			return [
				<EditableEmail
					key={`email-existing-item-${idx}`}
					personId={personId}
					email={item}
					isPrimary={getEmailPrimary(idx)}
					onSave={handleSaveEmail}
					onDelete={handleDelete}
					onSendMessage={onSendMessage}
				/>,
				isEmailWorkType,
			];
		});

		let mainEmailIndex = 0;
		const primayIndex = emails?.findIndex((item) => item.id === primaryEmailId);
		if (primayIndex !== -1) {
			mainEmailIndex = primayIndex;
		}
		const emailMainItem = emails?.[mainEmailIndex] ? (
			<EditableEmail
				personId={personId}
				email={emails[mainEmailIndex]}
				emailIndex={mainEmailIndex}
				isPrimary={getEmailPrimary(mainEmailIndex)}
				onSave={handleSaveEmail}
				onDelete={handleDelete}
				onSendMessage={onSendMessage}
				hasIcon={false}
			/>
		) : null;

		return { emailItems, emailMainItem };
	}, [emails, primaryEmailId]);

	return (
		<CommonItemView
			title="Personal"
			icon={<FontAwesomeIcon icon={faEnvelope} />}
			items={emailItems}
			mainItem={emailMainItem}
			newItem={
				<EditEmailItem
					email={newEmail}
					emailPrimary={false}
					onSave={handleSaveNewEmail}
					onCancel={handleCancelNewEmail}
				/>
			}
			isEdit={isEditAddEmail}
			setIsEdit={setIsEditAddEmail}
			className="email-view"
		/>
	);
};

export default EmailItemsView;
