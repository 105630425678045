import React, { useState, useMemo } from "react";
import styled from "styled-components";

/**
 * Main component for Text with Show more button
 */
const TextWithShowMore = (props) => {
	const { text, maxlength, ...restProps } = props;

	const [isEntireText, setIsEntireText] = useState(false);

	const { wrappedText, isNeedWrap } = useMemo(() => {
		if (!text || !maxlength) {
			return {
				wrappedText: "-",
				isNeedWrap: false,
			};
		}

		let _isNeedWrap = false;
		let _wrappedText = text;

		if (text.length > maxlength) {
			_isNeedWrap = true;
		}

		if (!isEntireText && _isNeedWrap) {
			_wrappedText = text.slice(0, maxlength) + "...";
		}

		return {
			wrappedText: _wrappedText || "-",
			isNeedWrap: _isNeedWrap,
		};
	}, [text, maxlength, isEntireText]);

	if (!maxlength) return <></>;

	return (
		<Wrapper {...restProps}>
			<span>{wrappedText}</span>{" "}
			{isNeedWrap ? (
				<div
					onClick={() => setIsEntireText(!isEntireText)}
					className="show-more-button"
				>
					{isEntireText ? "(Show less)" : "(Show more)"}
				</div>
			) : null}
		</Wrapper>
	);
};

export default TextWithShowMore;

const Wrapper = styled.div`
	display: inline;
	position: relative;

	.show-more-button {
		cursor: pointer;
		font-size: 0.875rem;
		line-height: 1.5rem;
		font-style: italic;
		color: #98a2b3;
	}
`;
