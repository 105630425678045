import React from "react";
import { EuiLoadingSpinner } from "@elastic/eui";
import { faExclamationTriangle } from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export const CMS_TRACKING_TYPES = {
	none: { key: "none", value: 0 },
	sent: { key: "sent", value: 1, icon: <EuiLoadingSpinner size="m" /> },
	sentConfirmed: {
		key: "sent-confirmed",
		value: 2,
	},
	bounced: { key: "bounced", value: 3 },
	read: { key: "read", value: 4 },
	clicked: { key: "clicked", value: 5 },
	replied: { key: "replied", value: 6 },
	error: {
		key: "error",
		value: 7,
		icon: (
			<FontAwesomeIcon icon={faExclamationTriangle} color="var(--danger-color)" />
		),
	},
	busy: { key: "busy", value: 8 },
	answered: { key: "answered", value: 9 },
	noAnswer: { key: "no-answer", value: 1 },
	noAnswerMessage: { key: "no-answer-message", value: 11 },
	voicemailManual: { key: "voicemail-manual", value: 12 },
	voicemailAutomated: { key: "voicemail-automated", value: 13 },
	note: { key: "note", value: 14 },
};
