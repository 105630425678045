import { useDispatch, useSelector } from "react-redux";
import { cloneDeep } from "lodash";

import { setNotification, setMessages } from "helpers/actions";
import { objectChecker } from "../../helpers/utilities";

import Service from "core/service";
import { accountId } from "SocketProvider";

/**
 * Use Hook for notification through socket
 */
const useSocketHandlerNotification = () => {
	const dispatch = useDispatch();
	const messages = useSelector((state) => state.messaging.messages);
	const getMessageSentConfirmation = async () => {
		try {
			// Get notifications
			const result = await Service.account.getAccountNotifications({
				id: accountId,
			});

			// Filter notifications that are from "SENT LINKED-IN" messages
			let notifications = objectChecker(result, ["data"]);

			if (notifications) {
				for (let i in notifications) {
					let { detail_value } = notifications[i]?.account_notification;

					const detailValue = JSON.parse(detail_value);

					if (detailValue["id"] === "message-from-crawler") {
						const message = objectChecker(detailValue, [
							"data",
							"messages",
							0,
							"message",
						]);

						// Check if there are any pending messages that are sent from the UI which matches this account
						for (let i in messages) {
							const { sending, content } = messages[i];

							if (sending) {
								// Compare this text against the
								if (content === message) {
									const newMessages = [...messages];
									const newMessage = cloneDeep(messages[i]);

									newMessage.sending = false;

									newMessages[i] = newMessage;

									dispatch(setMessages(newMessages));
								}
							}
						}
					}
				}
			}
		} catch (err) {
			alert("Notification error: " + JSON.stringify(err));
		}
	};

	const handleSocketNotificationResponse = (dispatch, response) => {
		if (response.type === "send-message") {
			getMessageSentConfirmation();
		} else {
			// Normal Notifications
			dispatch(setNotification(response));
		}
	};

	return {
		handleSocketNotificationResponse,
	};
};

export default useSocketHandlerNotification;
