import React from "react";
import styled from "styled-components";
import { EuiButtonEmpty, EuiLoadingSpinner } from "@elastic/eui";

import ActivityFilter from "./ActivityFilter";
import { plusIcon } from "components/Common/Icons/Icons";

/**
 * Main Components
 */
const AmsListViewHeader = (props) => {
	const { title, isPlusIcon = false, onAddNewItem, isLoading } = props;

	const onHandleAdd = () => {
		if (onAddNewItem) {
			onAddNewItem();
		}
	};

	return (
		<Wrapper>
			<div className="header_title--container">
				<p className="title">{title}</p>
				{isLoading && <EuiLoadingSpinner size="m" />}
			</div>

			<ActivityFilter />
			{isPlusIcon && (
				<EuiButtonEmpty onClick={() => onHandleAdd()} className="icon-btn">
					{plusIcon()}
				</EuiButtonEmpty>
			)}
		</Wrapper>
	);
};

export default AmsListViewHeader;

/**
 * Styled Components
 */
const Wrapper = styled.div`
	display: flex;
	justify-content: space-between;

	.header_title--container {
		display: flex;
		align-items: center;
		gap: var(--sp-4);
		min-width: var(--sp-65);
	}

	.title {
		font-weight: bold;
		margin-top: auto;
		margin-bottom: auto;
		margin-right: auto;
	}

	.icon-btn {
		height: 32px;
	}
`;
