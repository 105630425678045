import React, { Fragment, useEffect, useState } from "react";
import PropTypes from "prop-types";
import { useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
    EuiComboBox,
    EuiContextMenu,
    EuiFieldText,
    EuiFormRow,
    EuiLoadingContent,
    EuiSelect,
    EuiSpacer,
    EuiText,
} from "@elastic/eui";

import EditControls from "../EditControls";
import {
    bulkUpdatePersonAddresses,
    bulkUpdatePersonSIT,
    saveCampaigns,
    updatePersonDisplaySettings,
    fetchCampaigns,
    setSourcingCustomFilter,
    setAccountSettingValues,
} from "helpers/actions";
import API from "api";
import { objectChecker } from "helpers/utilities";

import BulkActionPopoverNotification from "./BulkActionPopoverNotification/BulkActionPopoverNotification";
import { PAGINATION_PER_PAGE_LIST } from "components/global/constants";

/**
 * Main Component for Bulk Action Popover
 */
const BulkActionPopover = (props) => {
    console.log("is this calling???");
    const { qualifiers, setIsPopoverOpen, data } = props;

    const selectedPersonIds = [];
    data.selected.map((item) => {
        selectedPersonIds.push(item.person_main_id);
    });
    const dispatch = useDispatch();
    const sits = useSelector((state) => state.sit.sits);

    const accounts = useSelector((state) => state.account.accounts);
    const accountSettings = useSelector((state) => state.account.accountSettings);
    const campaigns = useSelector((state) => state.campaign.campaigns);
    const campaignOptions = [];
    const [personBulkUpdateAddressSuccess, setPersonBulkUpdateAddressSuccess] =
        useState(false);
    const [personBulkAddressLoading, setPersonBulkAddressLoading] = useState(false);
    const [personBulkUpdateSITLoading, setPersonBulkUpdateSITLoading] = useState(false);
    const [personBulkUpdateSITSuccess, setPersonBulkUpdateSITSuccess] = useState(false);
    const [personBulkAssignQualifierSuccess, setPersonBulkAssignQualifierSuccess] =
        useState(false);
    const [personAssignQualifierLoading, setPersonAssignQualifierLoading] =
        useState(false);
    const [personBulkUpdateRASSuccess, setPersonBulkUpdateRASSuccess] = useState(false);
    const [personBulkUpdateRASLoading, setPersonBulkUpdateRASLoading] = useState(false);

    const [assignedCampaign, setAssignedCampaign] = useState(null);
    const [removedCampaign, setRemovedCampaign] = useState(null);

    /**
     * Hide from list states
     */
    const [hideFromListLoading, setHideFromListLoading] = useState(null);
    const [hideFromListSuccessAffectedRecords, setHideFromListSuccessAffectedRecords] =
        useState(null);
    const [hideFromListFailAffectedRecords, setHideFromListFailAffectedRecords] =
        useState(null);
    const [noneSelected, setNoneSelected] = useState(false);

    const sourcingPeopleList = useSelector((state) => state.sourcing.sourcingPeopleList);

    const location = useLocation();

    useEffect(() => {
        if (campaigns.length > 0) {
            const { campaign_main_id: campaignMainId } = campaigns[0];

            if (assignedCampaign === null && removedCampaign === null) {
                setAssignedCampaign(campaignMainId);
                setRemovedCampaign(campaignMainId);
            }
        }
    }, [campaigns]);

    useEffect(() => {
        dispatch(
            fetchCampaigns({
                page: 1,
                per_page: PAGINATION_PER_PAGE_LIST,
                filters: {},
            })
        );
    }, []);

    for (let i in campaigns) {
        const { campaign_main_id: campaignMainId, campaign_name: campaignName } =
            campaigns[i];

        campaignOptions.push({
            value: campaignMainId,
            text: campaignName,
        });
    }

    //Qualifiers
    const options = [];
    if (qualifiers && qualifiers.length > 0) {
        qualifiers.map((item) => {
            options.push({
                label: item.qualifier_name,
                id: item.ID,
            });
        });
    }
    const [selectedQualifierOptions, setSelectedQualifierOptions] = useState([]);
    const onChangeQualifier = (selectedOption) => {
        setSelectedQualifierOptions(selectedOption);
    };

    /**
     * Determine current page
     */
    const isPageInSourcing = () => {
        const currentRoute = location.pathname;

        return currentRoute === "/sourcing";
    };

    const isPageInPerson = () => {
        const currentRoute = location.pathname;

        return currentRoute === "/person";
    };

    //Skill Options
    const skillOptions = [];
    if (sits && sits.length > 0) {
        sits.filter((data) => data.sit_type_c === 1).map((item) => {
            skillOptions.push({
                label: item.sit_name,
                id: item.ID,
            });
        });
    }
    const [selectedSkillOption, setSelectedSkillOption] = useState([]);
    const onChangeSelectedSkill = (selectedOption) => {
        setSelectedSkillOption(selectedOption);
    };

    //Title Options
    const titleOptions = [];
    if (sits && sits.length > 0) {
        sits.filter((data) => data.sit_type_c === 2).map((item) => {
            titleOptions.push({
                label: item.sit_name,
                id: item.ID,
            });
        });
    }
    const [selectedTitleOption, setSelectedTitleOption] = useState([]);
    const onChangeSelectedTitle = (selectedOption) => {
        setSelectedTitleOption(selectedOption);
    };

    //Industry Options
    const industryOptions = [];
    if (sits && sits.length > 0) {
        sits.filter((data) => data.sit_type_c === 3).map((item) => {
            industryOptions.push({
                label: item.sit_name,
                id: item.ID,
            });
        });
    }
    const [selectedIndustryOption, setSelectedIndustryOption] = useState([]);
    const onChangeSelectedIndustryOption = (selectedOption) => {
        setSelectedIndustryOption(selectedOption);
    };

    //Owner and Assigned
    const ownerOptions = [];
    if (accounts && accounts.length > 0) {
        accounts.map((item) => {
            ownerOptions.push({
                label: item.name_first + " " + item.name_last,
                id: item.id,
            });
        });
    }
    const [selectedOwnerOption, setSelectedOwnerOption] = useState([]);
    const onChangeSelectedOwnerOption = (selectedOption) => {
        setSelectedOwnerOption(selectedOption);
    };
    const [selectedAssignedOption, setSelectedAssignedOption] = useState([]);
    const onChangeSelectedAssignedOption = (selectedOption) => {
        setSelectedAssignedOption(selectedOption);
    };

    const [selectedOptions, setSelected] = useState([]);

    const getSelectedPersons = () => {
        if (isPageInSourcing()) {
            // return data['selected'].map(person => person.person_main_id)
            return data["selected"].map((person) => person);
        }

        if (isPageInPerson()) {
        }

        return [];
    };

    const onSaveHandlerCampaigns = async () => {
        // alert('hehueuheuh')

        const personIds = data["selected"].map((person) => person.person_main_id);

        // alert(JSON.stringify({personIds}))

        let personList = await API()
            .Person()
            .peopleList({
                per_page: personIds.length,
                filters: {
                    person_ids: personIds,
                },
            });

        personList = objectChecker(personList, ["data", "people"]);

        dispatch(saveCampaigns(assignedCampaign, removedCampaign));
    };

    const onChange = (selectedOptions) => {
        setSelected(selectedOptions);
    };

    const onCreateOption = (searchValue, flattenedOptions = []) => {
        const normalizedSearchValue = searchValue.trim().toLowerCase();

        if (!normalizedSearchValue) {
            return;
        }

        const newOption = {
            label: searchValue,
        };

        // Create the option if it doesn't exist.
        if (
            flattenedOptions.findIndex(
                (option) => option.label.trim().toLowerCase() === normalizedSearchValue
            ) === -1
        ) {
            options.push(newOption);
        }

        // Select the option.
        setSelected([...selectedOptions, newOption]);
    };

    const [initialPanel, setInitialPanel] = useState(0);

    const handleCancel = () => {
        console.log("test manage campaign");
        setIsPopoverOpen(false);
    };

    //Bulk Updates
    //Address
    const [zipCode, setZipCode] = useState("");
    const [city, setCity] = useState("");
    const [countryState, setCountryState] = useState("");
    const selected = props.data.selected;
    const addressData = {
        zipCode,
        city,
        countryState,
        selected,
    };
    const addressEditData = {};
    const onSaveAddressHandler = async (editData, saveHandlerData) => {
        try {
            const updateAddressParams = [];

            const personIds = data["selected"].map((person) => person.person_main_id);

            let personList = await API()
                .Person()
                .peopleList({
                    per_page: personIds.length,
                    filters: {
                        person_ids: personIds,
                    },
                });

            personList = objectChecker(personList, ["data", "people"]);

            if (personList) {
                // Determine if there are already existing addresses
                for (let i in personList) {
                    let { physical_addresses: physicalAddresses } = personList[i];

                    if (physicalAddresses) {
                        physicalAddresses = JSON.parse(physicalAddresses);

                        /*const address_name = objectChecker(editedData, ['address_name'], "")
						const address_line_1 = objectChecker(editedData, ['address_line_1'], "")
						const address_city = objectChecker(editedData, ['address_city'], "")
						const address_state = objectChecker(editedData, ['address_state'], "")*/

                        // I will only concern myself with the FIRST entry whatever address a person has
                        if (physicalAddresses.length > 0) {
                            // Push update commands
                            // Update: Zip, City, State
                            updateAddressParams.push({
                                person_address_id:
                                    physicalAddresses[0]["person_address_id"],
                                person_main_id: personList[i]["ID"],
                                address_zip: zipCode,
                                address_city: city,
                                address_state: countryState,
                                address_type: "home",
                            });
                        } else {
                            // Push insert commands
                            updateAddressParams.push({
                                person_main_id: personList[i],
                                address_zip: zipCode,
                                address_city: city,
                                address_state: countryState,
                                // This needs to be set to work in order for it to "work" lol
                                address_type: "home",
                            });
                        }

                        console.log("parsed physicalAddresses", physicalAddresses);
                    }
                }
            }

            setPersonBulkAddressLoading(true);

            dispatch(
                bulkUpdatePersonAddresses(updateAddressParams, () =>
                    setPersonBulkAddressLoading(false)
                )
            );

            /**
             * Update this, fetch all persons highlighted
             */
        } catch (err) {
            alert("Bulk update person address error: " + JSON.stringify(err));
        }
    };

    //Qualifiers
    const qualifierUpdateData = {
        selected,
        selectedQualifierOptions,
    };
    const onSaveQualifierHandler = (editData, data) => {
        console.log("qualifiers", data);
        const { selected, selectedQualifierOptions } = data;
        const args = [];
        if (selected.length > 0) {
            selected.map((item) => {
                if (selectedQualifierOptions.length > 0) {
                    selectedQualifierOptions.map((qualifier) => {
                        args.push({
                            qualifier_main_ref_id: qualifier.id,
                            object_type: "person",
                            object_record_ref_id: item.id,
                        });
                    });
                }
            });
        }
    };

    //SIT
    const onSaveSITHandler = (edit, data) => {
        const {
            selectedIndustryOption,
            selectedSkillOption,
            selectedTitleOption,
            selected,
        } = data;

        const personIds = [];
        if (selected.length > 0) {
            selected.map((data) => {
                personIds.push(data.person_main_id);
            });
        }

        const SIT = [];
        selectedIndustryOption.map((data) => {
            SIT.push(data.id);
        });
        selectedSkillOption.map((data) => {
            SIT.push(data.id);
        });
        selectedTitleOption.map((data) => {
            SIT.push(data.id);
        });

        const args = {
            sit_level: "aware",
            person_main_ref_id: personIds,
            sit_main_ref_id: SIT,
        };

        dispatch(bulkUpdatePersonSIT(args));
    };
    const sitUpdateData = {
        selectedIndustryOption,
        selectedSkillOption,
        selectedTitleOption,
        selected,
    };

    //Owner and Assigned
    const onSaveOwnerAssignedHanlder = (editData, data) => {
        const args = [];
        const { selectedOwnerOption, selectedAssignedOption, selected } = data;
        if (selected.length > 0) {
            selected.map((item) => {
                if (selectedOwnerOption.length > 0) {
                    selectedOwnerOption.map((owner) => {
                        args.push({
                            object_type: "person",
                            object_ref_id: item.id,
                            assignment_type: "owner",
                            assignment_ref_id: owner.id,
                        });
                    });
                }
                if (selectedAssignedOption.length > 0) {
                    selectedAssignedOption.map((assigned) => {
                        args.push({
                            object_type: "person",
                            object_ref_id: item.id,
                            assignment_type: "assigned",
                            assignment_ref_id: assigned.id,
                        });
                    });
                }
            });
        }
    };
    const ownerAssignedUpdateData = {
        selected,
        selectedOwnerOption,
        selectedAssignedOption,
    };

    const onHideFromListSourcing = () => {
        const selectedPersons = getSelectedPersons();
        const selectedPersonIds = selectedPersons.map(
            (selectedPerson) => selectedPerson.person_main_id
        );

        if (selectedPersonIds.length > 0) {
            setHideFromListLoading(true);

            dispatch(
                updatePersonDisplaySettings(
                    "add",
                    selectedPersonIds,
                    () => {
                        const sourcingHiddenItems = objectChecker(accountSettings, [
                            "sourcing_hidden_items",
                        ]);

                        if (sourcingHiddenItems) {
                            const newAccountSettings = { ...accountSettings };

                            const currentSourcingHiddenItems =
                                newAccountSettings["sourcing_hidden_items"];
                            const newSourcingHiddenItems = [
                                ...currentSourcingHiddenItems,
                            ];

                            // Add these new selected IDS if they are not existing
                            for (let i in selectedPersonIds) {
                                const selectedPersonId = selectedPersonIds[i];

                                if (!newSourcingHiddenItems.includes(selectedPersonId)) {
                                    newSourcingHiddenItems.push(selectedPersonId);
                                }
                            }

                            newAccountSettings["sourcing_hidden_items"] =
                                newSourcingHiddenItems;

                            // Update local settings and set "hidden" filter to true
                            dispatch(setAccountSettingValues(newAccountSettings));
                            dispatch(setSourcingCustomFilter("hidden", true));

                            // Dispatch loading
                        }

                        setHideFromListLoading(false);
                    },
                    (err) => {
                        setHideFromListLoading(false);
                    }
                )
            );
        } else {
            setNoneSelected(true);
        }
    };

    const renderHideFromList = () => {
        if (noneSelected) {
            return <p>No persons selected</p>;
        }

        return (
            <div>
                <div
                    style={{
                        marginTop: "10px",
                    }}
                >
                    <BulkActionPopoverNotification
                        loading={hideFromListLoading}
                        affectedRecordsCount={hideFromListSuccessAffectedRecords}
                        type={"Success"}
                    />
                </div>

                <div
                    style={{
                        marginTop: "10px",
                    }}
                >
                    <BulkActionPopoverNotification
                        loading={hideFromListLoading}
                        type={"Failure"}
                        affectedRecordsCount={hideFromListFailAffectedRecords}
                    />
                </div>
            </div>
        );
    };

    const renderUpdateSIT = () => {
        return (
            <div tabIndex={0} style={{ padding: 16 }}>
                {personBulkUpdateSITSuccess ? (
                    <EuiText>Successfully Updated {props.data.size} Records</EuiText>
                ) : (
                    <Fragment>
                        {personBulkUpdateSITLoading ? (
                            <EuiLoadingContent lines={3} />
                        ) : (
                            <Fragment>
                                <EuiFormRow label="Add Titles" hasChildLabel={false}>
                                    <EuiComboBox
                                        placeholder="Add Titles"
                                        options={titleOptions}
                                        selectedOptions={selectedTitleOption}
                                        onChange={onChangeSelectedTitle}
                                        onCreateOption={onCreateOption}
                                        isClearable={true}
                                    />
                                </EuiFormRow>
                                <EuiFormRow label="Add Skills" hasChildLabel={false}>
                                    <EuiComboBox
                                        placeholder="Add Skills"
                                        options={skillOptions}
                                        selectedOptions={selectedSkillOption}
                                        onChange={onChangeSelectedSkill}
                                        onCreateOption={onCreateOption}
                                        isClearable={true}
                                    />
                                </EuiFormRow>
                                <EuiFormRow label="Add Industries" hasChildLabel={false}>
                                    <EuiComboBox
                                        placeholder="Add Industries"
                                        options={industryOptions}
                                        selectedOptions={selectedIndustryOption}
                                        onChange={onChangeSelectedIndustryOption}
                                        onCreateOption={onCreateOption}
                                        isClearable={true}
                                    />
                                </EuiFormRow>
                                <EuiSpacer />
                                <EditControls
                                    editData={{}}
                                    data={sitUpdateData}
                                    handleCancel={handleCancel}
                                    onSaveHandler={onSaveSITHandler}
                                    setEditClicked={() => {}}
                                />
                            </Fragment>
                        )}
                    </Fragment>
                )}
            </div>
        );
    };

    const panels = [
        {
            id: 0,
            title: data.isSelectedAll
                ? "All items selected"
                : data.size + " items selected",
            items: [
                {
                    name: "Manage Campaigns",
                    panel: 1,
                    onClick: () => {
                        setInitialPanel(1);
                    },
                },
                {
                    name: "Bulk Update",
                    panel: 2,
                },
                {
                    name: "Hide from List",
                    panel: 8,
                    onClick: () => {
                        // If page is sourcing, get current
                        if (isPageInSourcing()) {
                            onHideFromListSourcing();
                        }

                        /*isPageInSourcing()
						isPageInPerson()*/
                    },
                },
            ],
        },
        {
            id: 1,
            title: "Manage Campaigns",
            content: (
                <div tabIndex={0} style={{ padding: 16 }}>
                    <pre>
                        {removedCampaign ? JSON.stringify({ removedCampaign }) : ""}
                    </pre>

                    <pre>
                        {assignedCampaign ? JSON.stringify({ assignedCampaign }) : ""}
                    </pre>

                    <EuiFormRow label="Assign to Campaign" hasChildLabel={false}>
                        <EuiSelect
                            onChange={(e) => setAssignedCampaign(e.target.value)}
                            value={assignedCampaign}
                            options={campaignOptions}
                        />
                    </EuiFormRow>
                    <EuiFormRow label="Remove from Campaign" hasChildLabel={false}>
                        <EuiSelect
                            onChange={(e) => setRemovedCampaign(e.target.value)}
                            value={removedCampaign}
                            options={campaignOptions}
                        />
                    </EuiFormRow>
                    <EuiSpacer />

                    <EditControls
                        editData={addressEditData}
                        data={addressData}
                        handleCancel={handleCancel}
                        onSaveHandler={onSaveHandlerCampaigns}
                        setEditClicked={() => {}}
                    />
                </div>
            ),
        },
        {
            id: 2,
            title: "Bulk Update",
            items: [
                {
                    name: "City, State, Zip",
                    panel: 3,
                },
                {
                    name: "Source",
                    panel: 4,
                    disabled: true,
                },
                {
                    name: "Qualifiers",
                    panel: 5,
                    disabled: true,
                },
                {
                    name: "Skill, Industry, Title",
                    panel: 6,
                    disabled: true,
                },
                {
                    name: "Owners/Assigned",
                    panel: 7,
                    disabled: true,
                },
            ],
        },
        {
            id: 3,
            title: "City, State, Zip",
            content: (
                <div style={{ padding: 16 }}>
                    {personBulkUpdateAddressSuccess ? (
                        <EuiText>Successfully Updated {props.data.size} Records</EuiText>
                    ) : (
                        <Fragment>
                            {personBulkAddressLoading ? (
                                <EuiLoadingContent lines={3} />
                            ) : (
                                <Fragment>
                                    <EuiFormRow label="Zip Code">
                                        <EuiFieldText
                                            value={zipCode}
                                            onChange={(e) => {
                                                setZipCode(e.target.value);
                                            }}
                                            name="zipCode"
                                        />
                                    </EuiFormRow>
                                    <EuiFormRow label="City">
                                        <EuiFieldText
                                            value={city}
                                            onChange={(e) => setCity(e.target.value)}
                                            name="city"
                                        />
                                    </EuiFormRow>
                                    <EuiFormRow label="State">
                                        <EuiFieldText
                                            value={countryState}
                                            onChange={(e) =>
                                                setCountryState(e.target.value)
                                            }
                                            name="State"
                                        />
                                    </EuiFormRow>
                                    <EuiSpacer />

                                    {personBulkAddressLoading ? (
                                        <EuiLoadingContent lines={2} />
                                    ) : (
                                        <EditControls
                                            editData={addressEditData}
                                            data={addressData}
                                            handleCancel={handleCancel}
                                            onSaveHandler={onSaveAddressHandler}
                                            setEditClicked={() => {}}
                                        />
                                    )}
                                </Fragment>
                            )}
                        </Fragment>
                    )}
                </div>
            ),
        },
        {
            id: 4,
            title: "Select Source",
            content: (
                <div tabIndex={0} style={{ padding: 16 }}>
                    <EuiFormRow label="Select Source" hasChildLabel={false}>
                        <EuiSelect
                            hasNoInitialSelection
                            options={[
                                { value: "option_one", text: "Option one" },
                                { value: "option_two", text: "Option two" },
                                { value: "option_three", text: "Option three" },
                            ]}
                        />
                    </EuiFormRow>
                    <EuiSpacer />
                    <EditControls handleCancel={handleCancel} />
                </div>
            ),
        },
        {
            id: 5,
            title: "Update Qualifiers",
            content: (
                <div tabIndex={0} style={{ padding: 16 }}>
                    {personBulkAssignQualifierSuccess ? (
                        <EuiText>Successfully Updated {props.data.size} Records</EuiText>
                    ) : (
                        <Fragment>
                            {personAssignQualifierLoading ? (
                                <EuiLoadingContent lines={3} />
                            ) : (
                                <Fragment>
                                    <EuiFormRow
                                        label="Add Qualifiers"
                                        hasChildLabel={false}
                                    >
                                        <EuiComboBox
                                            placeholder="Add Qualifiers"
                                            options={options}
                                            selectedOptions={selectedQualifierOptions}
                                            onChange={onChangeQualifier}
                                            onCreateOption={onCreateOption}
                                            isClearable={true}
                                        />
                                    </EuiFormRow>
                                    <EuiFormRow
                                        label="Remove Qualifiers"
                                        hasChildLabel={false}
                                    >
                                        <EuiComboBox
                                            placeholder="Remove Qualifiers"
                                            options={options}
                                            selectedOptions={selectedOptions}
                                            onChange={onChange}
                                            onCreateOption={onCreateOption}
                                            isClearable={true}
                                        />
                                    </EuiFormRow>

                                    <EuiSpacer />
                                    <EditControls
                                        editData={addressEditData}
                                        data={qualifierUpdateData}
                                        handleCancel={handleCancel}
                                        onSaveHandler={onSaveQualifierHandler}
                                        setEditClicked={() => {}}
                                    />
                                </Fragment>
                            )}
                        </Fragment>
                    )}
                </div>
            ),
        },
        {
            id: 6,
            title: "Update SIT",
            content: renderUpdateSIT(),
        },
        {
            id: 7,
            title: "Update Owner/Assigned",
            content: (
                <div tabIndex={0} style={{ padding: 16 }}>
                    {personBulkUpdateRASSuccess ? (
                        <EuiText>Successfully Updated {props.data.size} Records</EuiText>
                    ) : (
                        <Fragment>
                            {personBulkUpdateRASLoading ? (
                                <EuiLoadingContent lines={3} />
                            ) : (
                                <Fragment>
                                    <EuiFormRow label="Owner" hasChildLabel={false}>
                                        <EuiComboBox
                                            placeholder="Add Owner"
                                            options={ownerOptions}
                                            selectedOptions={selectedOwnerOption}
                                            onChange={onChangeSelectedOwnerOption}
                                            onCreateOption={onCreateOption}
                                            isClearable={true}
                                        />
                                    </EuiFormRow>
                                    <EuiFormRow label="Assigned" hasChildLabel={false}>
                                        <EuiComboBox
                                            placeholder="Add Assigned"
                                            options={ownerOptions}
                                            selectedOptions={selectedAssignedOption}
                                            onChange={onChangeSelectedAssignedOption}
                                            onCreateOption={onCreateOption}
                                            isClearable={true}
                                        />
                                    </EuiFormRow>
                                    <EuiSpacer />
                                    <EditControls
                                        editData={{}}
                                        data={ownerAssignedUpdateData}
                                        handleCancel={handleCancel}
                                        onSaveHandler={onSaveOwnerAssignedHanlder}
                                        setEditClicked={() => {}}
                                    />
                                </Fragment>
                            )}
                        </Fragment>
                    )}
                </div>
            ),
        },
        {
            id: 8,
            title: "Hide From List",
            content: renderHideFromList(),
        },
    ];

    return <EuiContextMenu initialPanelId={initialPanel} panels={panels} />;
};

BulkActionPopover.propTypes = {
    qualifiers: PropTypes.array,
    data: PropTypes.shape({
        size: PropTypes.number,
        selected: PropTypes.array,
        isSelectedAll: PropTypes.bool,
    }),
    setType: PropTypes.string,
    setIsPopoverOpen: PropTypes.func,
};

export default BulkActionPopover;
