import React from "react";
import styled from "styled-components";
import { EuiFlexGroup, EuiFlexItem, EuiSpacer, EuiBetaBadge, EuiAvatar, EuiText } from "@elastic/eui";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronRight } from "@fortawesome/pro-solid-svg-icons/faChevronRight";

import { linkedInIcon, returnBackIcon } from "components/Common/Icons/Icons";
import variables from "assets/css/variables.scss";

/**
 * Main Components
 */
const ActivityCard = (props) => {
    const { data, isShowRightReturn = false, ...restProps } = props;

    const gotoActivityDetail = () => {
        console.log("gotoActivity Detail");
    };

    return (
        <Wrapper {...restProps}>
            <EuiFlexGroup>
                <EuiFlexItem grow={2}>
                    <EuiFlexGroup>
                        <EuiFlexItem>{linkedInIcon()}</EuiFlexItem>
                        <EuiFlexItem>{returnBackIcon()}</EuiFlexItem>
                    </EuiFlexGroup>
                    <EuiSpacer size="s" />
                    <EuiFlexGroup>
                        <EuiFlexItem className="light-span">scheduled</EuiFlexItem>
                    </EuiFlexGroup>
                    <EuiFlexGroup>
                        <EuiFlexItem className="light-span">12/01/2021</EuiFlexItem>
                    </EuiFlexGroup>
                </EuiFlexItem>

                <EuiFlexItem grow={9}>
                    <EuiFlexGroup>
                        <EuiFlexItem className="content-header">
                            Call Regarding Job 1156 - Software Engineer
                        </EuiFlexItem>
                    </EuiFlexGroup>
                    <EuiFlexGroup>
                        <EuiFlexItem>Becasue this is the test.</EuiFlexItem>
                    </EuiFlexGroup>
                    <EuiFlexGroup className="badge-items">
                        <EuiFlexItem grow={false} className="badge">
                            <EuiBetaBadge label="1234" />
                        </EuiFlexItem>
                        <EuiFlexItem grow={false} className="badge">
                            <EuiBetaBadge label="1234" />
                        </EuiFlexItem>
                    </EuiFlexGroup>
                </EuiFlexItem>

                <EuiFlexItem grow={1}>
                    <EuiFlexGroup direction="column" className="right-side">
                        <EuiFlexItem>
                            <EuiFlexGroup>
                                {isShowRightReturn && (
                                    <EuiFlexItem>{returnBackIcon(variables.linkColor)}</EuiFlexItem>
                                )}
                                <EuiFlexItem>
                                    <EuiText className="icon">
                                        <button onClick={gotoActivityDetail}>
                                            <FontAwesomeIcon icon={faChevronRight} />
                                        </button>
                                    </EuiText>
                                </EuiFlexItem>
                            </EuiFlexGroup>
                        </EuiFlexItem>
                        <EuiFlexItem className="avatar">
                            <EuiAvatar size="s" name={"Jack Wang"} />
                        </EuiFlexItem>
                    </EuiFlexGroup>
                </EuiFlexItem>
            </EuiFlexGroup>
        </Wrapper>
    );
};

export default ActivityCard;

/**
 * Styled Components
 */
const Wrapper = styled.div`
    padding: 8px 10px;
    border-bottom: solid 1px ${variables.borderColor};

    .light-span {
        color: ${variables.lightColor};
        font-size: 0.75rem;
        font-weight: 600;
    }

    .content-header {
        color: ${variables.linkColor};
    }

    .badge-items {
        margin: 0.5rem 0 0 0;
    }
    .badge {
        margin: 4px 4px 0px 0px;
    }

    .right-side {
        margin-left: auto;
        .icon {
            color: ${variables.linkColor};
            margin-right: 0.5rem;
        }
        .avatar {
            margin-left: auto;
        }
    }
`;
