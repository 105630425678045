import React, { useEffect } from "react";
import styled from "styled-components";

const NotFound = (props) => {
	return <Wrapper>Not found page.....</Wrapper>;
};

export default NotFound;

const Wrapper = styled.div`
	display: flex;
	position: relative;
	width: 100%;
`;
