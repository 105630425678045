import React, { useState, useMemo } from "react";
import styled from "styled-components";
import {
	EuiFlexGroup,
	EuiFlexItem,
	EuiSelect,
	EuiText,
	EuiFieldNumber,
	EuiRadio,
} from "@elastic/eui";
import { htmlIdGenerator } from "@elastic/eui/lib/services";

import { SelectWithHeader } from "components/Common";
import RoundButtonWithPlusIcon from "../../CustomComponents/RoundButtonWithPlusIcon";
import {
	WILL_SPONSOR_TRANSFER,
	JOB_SCREENING_SPONSOR_TYPES,
} from "components/global/constants";

import variables from "assets/css/variables.scss";

/**
 * Main Components
 */
const ScreeningOtherJob = (props) => {
	const { ...restProps } = props;

	const [willSponsor, setWillSponsor] = useState(
		WILL_SPONSOR_TRANSFER.willNotSponsor.key
	);

	const curSponsors = useMemo(() => {
		return Object.getOwnPropertyNames(JOB_SCREENING_SPONSOR_TYPES).map((key) => {
			return {
				...JOB_SCREENING_SPONSOR_TYPES[key],
				value: undefined,
			};
		});
	}, []);

	const optionsSponsorTransfer = [
		{ value: "transfer", text: "Transfer" },
		{ value: "transfer1", text: "Transfer 1" },
	];

	const optionsWorkShift = [
		{ value: "normal", text: "Normal" },
		{ value: "test", text: "Test" },
	];

	const [selectedSponsorTransfer, setSelectedSponsorTransfer] = useState(
		optionsSponsorTransfer?.[0].value
	);
	const [selectedWorkShift, setSelectedWorkShift] = useState(
		optionsWorkShift?.[0].value
	);
	const [daysNoticeRequired, setDaysNoticeRequired] = useState(0);

	const onChangeSponsorTransfer = (e) => {
		setSelectedSponsorTransfer(e.target.value);
	};

	const onChangeWorkShift = (value) => {
		setSelectedWorkShift(value);
	};

	const onChangeDaysNoticeRequired = (e) => {
		setDaysNoticeRequired(e.target.value);
	};

	const handleChangeWillSponsor = (e) => {
		setWillSponsor(e.target.value);
	};

	const handleClickSponsorItem = () => {};

	return (
		<Wrapper {...restProps}>
			<EuiFlexGroup gutterSize="m" direction="column">
				<EuiFlexItem grow={false}>
					<EuiFlexGroup gutterSize="xs" direction="column">
						<EuiFlexItem>
							<EuiText size="xs" textAlign="left" className="item-title">
								Maximum Acceptible Notice
							</EuiText>
						</EuiFlexItem>
						<EuiFlexItem grow={false} className="input-days">
							<EuiFieldNumber
								placeholder=""
								value={daysNoticeRequired}
								onChange={onChangeDaysNoticeRequired}
								min={0}
								append={<EuiText size="s">days</EuiText>}
								compressed={true}
								fullWidth={false}
								aria-label="notice required"
							/>
						</EuiFlexItem>
					</EuiFlexGroup>
				</EuiFlexItem>
				<EuiFlexItem grow={false}>
					<SelectWithHeader
						id="person-sourcing-other-work-shift"
						label="Work Shift"
						options={optionsWorkShift}
						value={selectedWorkShift}
						onChange={onChangeWorkShift}
						ariaLabel="work shift"
					/>
				</EuiFlexItem>
				<EuiFlexItem>
					<EuiFlexGroup gutterSize="xs" direction="column">
						<EuiFlexItem>
							<EuiRadio
								id={htmlIdGenerator()()}
								checked={
									WILL_SPONSOR_TRANSFER.willNotSponsor.key ===
									willSponsor
								}
								label={WILL_SPONSOR_TRANSFER.willNotSponsor.label}
								onChange={(e) => handleChangeWillSponsor(e)}
								className={`will-sponsor-option ${
									WILL_SPONSOR_TRANSFER.willNotSponsor.key !==
									willSponsor
										? "disabled"
										: ""
								}`}
								value={WILL_SPONSOR_TRANSFER.willNotSponsor.key}
							/>
						</EuiFlexItem>
						<EuiFlexItem>
							<EuiFlexGroup alignItems="center">
								<EuiFlexItem grow={false}>
									<EuiRadio
										id={htmlIdGenerator()()}
										checked={
											WILL_SPONSOR_TRANSFER.willSponsor.key ===
											willSponsor
										}
										label={WILL_SPONSOR_TRANSFER.willSponsor.label}
										onChange={(e) => handleChangeWillSponsor(e)}
										className={`will-sponsor-option ${
											WILL_SPONSOR_TRANSFER.willSponsor.key !==
											willSponsor
												? "disabled"
												: ""
										}`}
										value={WILL_SPONSOR_TRANSFER.willSponsor.key}
									/>
								</EuiFlexItem>
								<EuiFlexItem grow={false} className="detail-select">
									<EuiSelect
										id={htmlIdGenerator()()}
										options={optionsSponsorTransfer}
										value={selectedSponsorTransfer}
										onChange={onChangeSponsorTransfer}
										aria-label="work authorization second item"
										disabled={
											WILL_SPONSOR_TRANSFER.willSponsor.key !==
											willSponsor
										}
									/>
								</EuiFlexItem>
							</EuiFlexGroup>
						</EuiFlexItem>
						{WILL_SPONSOR_TRANSFER.willSponsor.key == willSponsor ? (
							<EuiFlexItem className="sponsor-select-items-container">
								<EuiFlexGroup gutterSize="s" direction="column">
									<EuiFlexItem>
										<EuiFlexGroup gutterSize="xs" direction="column">
											{curSponsors.map((item) => {
												if (item.value !== undefined) {
													return (
														<EuiFlexItem
															key={`job-sponsor-transfer-value-${item.key}`}
														>
															<EuiFlexGroup
																gutterSize="xs"
																direction="row"
																className="detail"
																justifyContent="spaceBetween"
															>
																<EuiFlexItem grow={false}>
																	<EuiText className="detail-label">{`${item.label}: `}</EuiText>
																</EuiFlexItem>
																<EuiFlexItem grow={false}>
																	<EuiText
																		className="detail-value"
																		textAlign="right"
																	>
																		<span className="value">
																			{`$${
																				item?.value?.value?.toLocaleString(
																					"en-US"
																				) || "-"
																			}`}
																		</span>
																		{`(`}
																		<span className="value">
																			{`$${Math.floor(
																				(item
																					?.value
																					?.min ||
																					0) /
																					1000
																			)}k - $${Math.floor(
																				(item
																					?.value
																					?.max ||
																					0) /
																					1000
																			)}k`}
																		</span>
																		{`)`}
																	</EuiText>
																</EuiFlexItem>
															</EuiFlexGroup>
														</EuiFlexItem>
													);
												}
												return (
													<div
														key={`job-sponsor-transfer-${item.key}`}
													></div>
												);
											})}
										</EuiFlexGroup>
									</EuiFlexItem>
									<EuiFlexItem>
										<EuiFlexGroup gutterSize="s" direction="row" wrap>
											{curSponsors.map((item) => {
												if (item.value === undefined) {
													return (
														<EuiFlexItem
															key={`job-sponsor-transfer-empty-${item.key}`}
															grow={false}
														>
															<RoundButtonWithPlusIcon
																label={item.label}
																onClick={
																	handleClickSponsorItem
																}
															/>
														</EuiFlexItem>
													);
												}
												return (
													<div
														key={`job-sponsor-transfer-empty-${item.key}`}
													></div>
												);
											})}
										</EuiFlexGroup>
									</EuiFlexItem>
								</EuiFlexGroup>
							</EuiFlexItem>
						) : null}
					</EuiFlexGroup>
				</EuiFlexItem>
			</EuiFlexGroup>
		</Wrapper>
	);
};

export default ScreeningOtherJob;

/**
 * Styled Components
 */
const Wrapper = styled.div`
	padding: 14px 10px 0 10px;

	.item-title {
		font-weight: 600;
		color: ${variables.lightColor};
	}

	.detail-value {
		color: ${variables.linkColor};
	}

	.detail-select {
		& > div {
			height: auto;
		}
	}

	.will-sponsor-option {
		display: flex;

		.euiRadio__circle {
			background-image: none !important;
			top: 1px;
		}

		label {
			font-size: 1rem !important;
			line-height: 1.71 !important;
			color: #1a1c21;
		}

		&.disabled {
			label {
				color: ${variables.lightColor};
			}
		}
	}

	.sponsor-select-items-container {
		margin-left: 2.2rem;
	}
`;
