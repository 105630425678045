import React, { useEffect, useMemo, useState } from "react";
import { cloneDeep, get } from "lodash";
import * as MODEL from "core/model";

import { AmsEditActivityItem } from "./AmsEditActivityItem";
import { AmsEditNoteItem } from "./AmsEditNoteItem";
import { differences, getGCSValue, getGSSValue } from "components/global/utils";
import { useGlobal } from "core/useHooks";

/**
 * Component for AMS Item with editable feature
 */
const AmsEditItem = (props) => {
	const {
		onChangeEdit,
		data,
		person,
		account,
		accounts,
		owners,
		assignees,
		restriction,
		onUpdateAms,
		onDeleteAms,
		onSaveEmail,
		onDeleteEmail,
		onSavePhone,
		onDeletePhone,
		onSaveRAS,
		loadPersonAmsData,
		globalSearchItems,
		getGlobalSearchItems,
	} = props;

	const { gssList, globals } = useGlobal();

	const [viewType, setViewType] = useState(1);

	const handleChangeViewType = (newType) => {
		setViewType(newType);

		const params = {
			[MODEL.ams_activity_main._name]: {
				id: data?.id,
				[MODEL.ams_activity_main.activity_view_type_c]: newType,
			},
		};
		onUpdateAms(params);
	};

	const handleUpdateNote = (_note) => {
		const note = cloneDeep(_note);

		// Get information for ams_activity_main
		const amsMainInfo = {
			[MODEL.ams_activity_main._name]: {
				[MODEL.ams_activity_main.id]: data.id,
			},
		};

		// Get information for ams_activity_note
		delete note.index;
		delete note[MODEL.ams_activity_note.date_created];
		if (note.name !== undefined) {
			delete note.name;
		}
		const amsNoteInfo = {
			[MODEL.ams_activity_note._name]: note,
		};

		// Save updated AMS items
		const updateParams = [amsMainInfo, amsNoteInfo];
		onUpdateAms(updateParams, (res) => {
			loadPersonAmsData();
		});
	};

	const handleDeleteNote = (note) => {
		// Delete AMS items
		const deleteNotes = {
			[MODEL.ams_activity_note._name]: {
				[MODEL.ams_activity_note.id]: note.id,
			},
		};

		// Save updated AMS items
		const deleteParams = [deleteNotes];
		onDeleteAms(deleteParams, (res) => {
			loadPersonAmsData();
		});
	};

	const handleChangeAmsType = (type) => {
		// Get information for ams_activity_main
		const amsMainInfo = {
			[MODEL.ams_activity_main._name]: {
				[MODEL.ams_activity_main.id]: data.id,
				[MODEL.ams_activity_main.activity_type_c]: type,
			},
		};

		// Save updated AMS items
		const updateParams = [amsMainInfo];
		onUpdateAms(updateParams, (res) => {
			loadPersonAmsData();
		});
	};

	const handleChangeAmsName = (name) => {
		// Get information for ams_activity_main
		const amsMainInfo = {
			[MODEL.ams_activity_main._name]: {
				[MODEL.ams_activity_main.id]: data.id,
				[MODEL.ams_activity_main.activity_name]: name,
			},
		};

		// Save updated AMS items
		const updateParams = [amsMainInfo];
		onUpdateAms(updateParams, (res) => {
			loadPersonAmsData();
		});
	};

	const handleChangeAmsStartTime = (startDate) => {
		// Get information for ams_activity_main
		const amsMainInfo = {
			[MODEL.ams_activity_main._name]: {
				[MODEL.ams_activity_main.id]: data.id,
			},
		};

		// Get information for ams_activity_time
		const amsTimeId = data[MODEL.ams_activity_time._name]?.[0]?.id || null;
		const amsTimeInfo = {
			[MODEL.ams_activity_time._name]: {
				[MODEL.ams_activity_time.id]: amsTimeId,
				...(startDate ? { [MODEL.ams_activity_time.date_start]: startDate } : {}),
			},
		};

		// Save updated AMS items
		const updateParams = [amsMainInfo, amsTimeInfo];
		onUpdateAms(updateParams, (res) => {
			loadPersonAmsData();
		});
	};

	const handleChangeAmsEndTime = (endDate) => {
		// Get information for ams_activity_main
		const amsMainInfo = {
			[MODEL.ams_activity_main._name]: {
				[MODEL.ams_activity_main.id]: data.id,
			},
		};

		// Get information for ams_activity_time
		const amsTimeId = data[MODEL.ams_activity_time._name]?.[0]?.id || null;
		const amsTimeInfo = {
			[MODEL.ams_activity_time._name]: {
				[MODEL.ams_activity_time.id]: amsTimeId,
				...(endDate ? { [MODEL.ams_activity_time.date_end]: endDate } : {}),
			},
		};

		// Save updated AMS items
		const updateParams = [amsMainInfo, amsTimeInfo];
		onUpdateAms(updateParams, (res) => {
			loadPersonAmsData();
		});
	};

	const getReferenceInfo = (ids = [], ref, objBase) => {
		// ids: object's id array
		// ref: PERSON/COMPANY/JOB
		// objBase: OBJECT_PERSON/OBJECT_COMPANY/OBJECT_JOB
		const references = [];
		const categoryC = getGCSValue(
			globals,
			"ams_activity_reference",
			"reference_category_c",
			"associated"
		);
		const objTypeSC = getGSSValue(gssList, "OBJECT_BASE", ref);
		const objSubtypeSC = getGSSValue(gssList, objBase, "MAIN");
		ids.forEach((id) => {
			const info = {
				[MODEL.ams_activity_reference._name]: {
					[MODEL.ams_activity_reference.reference_category_c]: categoryC,
					[MODEL.ams_activity_reference.reference_object_type_sc]: objTypeSC,
					[MODEL.ams_activity_reference.reference_object_subtype_sc]:
						objSubtypeSC,
					[MODEL.ams_activity_reference.reference_object_record_ref_id]: id,
				},
			};

			references.push(info);
		});

		return references;
	};

	const handleUpdateReference = (refList) => {
		// refList is the result of Global Search Item
		// refList = {
		// 	person: [], // person id's array
		// 	company: [], // company id's array
		// 	job: [], // job id's array
		// }

		// Save associations related to PCJ
		const personIds = refList.person;
		const companyIds = refList.company;
		const jobIds = refList.job;

		const oldPersonIds = [];
		const oldCompanyIds = [];
		const oldJobIds = [];

		// Check person ids
		const newPersonIds = differences(personIds, oldPersonIds);
		const personReferences = getReferenceInfo(
			newPersonIds,
			"PERSON",
			"OBJECT_PERSON"
		);

		// Check company ids
		const newCompanyIds = differences(companyIds, oldCompanyIds);
		const companyReferences = getReferenceInfo(
			newCompanyIds,
			"COMPANY",
			"OBJECT_COMPANY"
		);

		// Check job ids
		const newJobIds = differences(jobIds, oldJobIds);
		const jobReferences = getReferenceInfo(newJobIds, "JOB", "OBJECT_JOB");

		// Get information for ams_activity_main
		const amsMainInfo = {
			[MODEL.ams_activity_main._name]: {
				[MODEL.ams_activity_main.id]: data.id,
			},
		};

		// Get information for ams_activity_reference
		const amsReferenceInfo = [
			...personReferences,
			...companyReferences,
			...jobReferences,
		];

		// Save updated AMS items
		const updateParams = [amsMainInfo, ...amsReferenceInfo];

		if (!!amsReferenceInfo.length) {
			onUpdateAms(updateParams, (res) => {
				loadPersonAmsData();
			});
		}
	};

	const handleDeleteReference = (reference) => {
		// Delete AMS items
		const deleteReferences = {
			[MODEL.ams_activity_reference._name]: {
				[MODEL.ams_activity_reference.id]: reference.id,
			},
		};

		// Save updated AMS items
		const deleteParams = [deleteReferences];
		onDeleteAms(deleteParams, (res) => {
			loadPersonAmsData();
		});
	};

	const handleUpdateTask = (_task) => {
		const task = cloneDeep(_task);

		// Get information for ams_activity_main
		const amsMainInfo = {
			[MODEL.ams_activity_main._name]: {
				[MODEL.ams_activity_main.id]: data.id,
			},
		};

		// Get information for ams_activity_task
		delete task.index;
		if (task.name !== undefined) {
			delete task.name;
		}
		if (task[MODEL.ams_activity_reminder._name] !== undefined) {
			delete task[MODEL.ams_activity_reminder._name];
		}

		const amsTaskInfo = {
			[MODEL.ams_activity_task._name]: task,
		};

		// Save updated AMS items
		const updateParams = [amsMainInfo, amsTaskInfo];
		onUpdateAms(updateParams, (res) => {
			loadPersonAmsData();
		});
	};

	const handleDeleteTask = (task) => {
		// Delete AMS items
		const deleteTasks = {
			[MODEL.ams_activity_task._name]: {
				[MODEL.ams_activity_task.id]: task.id,
			},
		};

		// Save updated AMS items
		const deleteParams = [deleteTasks];
		onDeleteAms(deleteParams, (res) => {
			loadPersonAmsData();
		});
	};

	const handleUpdateReminder = (_reminder) => {
		// Get information for ams_activity_reminder
		const reminder = cloneDeep(_reminder);
		delete reminder.index;
		if (reminder.name !== undefined) {
			delete reminder.name;
		}
		const amsReminderInfo = {
			[MODEL.ams_activity_reminder._name]: reminder,
		};

		// Save updated AMS items
		const updateParams = [amsReminderInfo];
		onUpdateAms(updateParams, (res) => {
			loadPersonAmsData();
		});
	};

	const handleDeleteReminder = (reminder) => {
		// Delete AMS items
		const deleteReminders = {
			[MODEL.ams_activity_reminder._name]: {
				[MODEL.ams_activity_reminder.id]: reminder.id,
			},
		};

		// Save updated AMS items
		const deleteParams = [deleteReminders];
		onDeleteAms(deleteParams, (res) => {
			loadPersonAmsData();
		});
	};

	const handleChangeAmsStatus = (newStatus) => {
		const params = {
			[MODEL.ams_activity_main._name]: {
				id: data?.id,
				[MODEL.ams_activity_main.activity_status_c]: newStatus,
			},
		};
		onUpdateAms(params, (res) => {
			loadPersonAmsData();
		});
	};

	useEffect(() => {
		const activityViewType = get(
			data,
			MODEL.ams_activity_main.activity_view_type_c,
			1
		);
		setViewType(activityViewType);
	}, [data]);

	if (viewType === 1) {
		return (
			<AmsEditNoteItem
				data={data}
				person={person}
				account={account}
				accounts={accounts}
				restriction={restriction}
				owners={owners}
				assignees={assignees}
				onChangeEdit={onChangeEdit}
				onChangeViewType={() => handleChangeViewType(2)}
				onChangeAmsType={handleChangeAmsType}
				onChangeAmsName={handleChangeAmsName}
				onChangeAmsStatus={handleChangeAmsStatus}
				onUpdateNote={handleUpdateNote}
				onDeleteNote={handleDeleteNote}
				onSaveRAS={onSaveRAS}
			/>
		);
	} else {
		return (
			<AmsEditActivityItem
				data={data}
				person={person}
				account={account}
				accounts={accounts}
				restriction={restriction}
				owners={owners}
				assignees={assignees}
				onChangeEdit={onChangeEdit}
				onChangeViewType={() => handleChangeViewType(1)}
				onChangeAmsType={handleChangeAmsType}
				onChangeAmsName={handleChangeAmsName}
				onChangeAmsStatus={handleChangeAmsStatus}
				onUpdateNote={handleUpdateNote}
				onDeleteNote={handleDeleteNote}
				onChangeAmsStartTime={handleChangeAmsStartTime}
				onChangeAmsEndTime={handleChangeAmsEndTime}
				onUpdateTask={handleUpdateTask}
				onDeleteTask={handleDeleteTask}
				onUpdateReminder={handleUpdateReminder}
				onDeleteReminder={handleDeleteReminder}
				onUpdateReference={handleUpdateReference}
				onDeleteReference={handleDeleteReference}
				onSavePhone={onSavePhone}
				onDeletePhone={onDeletePhone}
				onSaveEmail={onSaveEmail}
				onDeleteEmail={onDeleteEmail}
				onSaveRAS={onSaveRAS}
				globalSearchItems={globalSearchItems}
				getGlobalSearchItems={getGlobalSearchItems}
			/>
		);
	}
};

export default AmsEditItem;
