import {
    sitCoreIcon,
    sitExcludeIcon,
    sitCareerMoverIcon,
} from "components/Common/Icons/Icons";

export const OBJECT_SIT_TYPES = {
    support: {
        key: "support",
        label: "Support",
        value: 1,
        color: "var(--eui-text-color)",
    },
    core: {
        key: "core",
        label: "Core",
        value: 2,
        icon: sitCoreIcon(),
        color: "var(--eui-primary-color)",
    },
    exclude: {
        key: "exclude",
        label: "Exclude",
        value: 3,
        icon: sitExcludeIcon(),
        color: "var(--eui-danger-color)",
    },
    careerMover: {
        key: "career-mover",
        label: "Career Mover",
        value: 4,
        icon: sitCareerMoverIcon(),
        color: "#FF6901",
    },
    parsed: { key: "parsed", label: "Parsed", value: 5, color: "var(--eui-text-color)" },
};
