/**
 * SIT
 */
export const FETCH_SIT = "@@sit/FETCH_SIT";
export const SET_SIT_DATA = "@@sit/SET_SIT_DATA";
export const UPDATE_SIT_PARENT = "@@sit/UPDATE_SIT_PARENT";
export const DELETE_SIT = "@@sit/DELETE_SIT";
export const ADD_UPDATE_SIT = "@@sit/ADD_UPDATE_SIT";
export const GET_SIT_KEYWORDS = "@@sit/GET_SIT_KEYWORDS";
export const SET_SIT_MODE = "@@sit/SET_SIT_MODE";
export const SET_FLYOUT_VISIBLE = "@@sit/SET_FLYOUT_VISIBLE";
export const UPDATE_SIT_ORDER = "@@sit/UPDATE_SIT_ORDER";
export const UPDATE_SIT_KEYWORD = "@@sit/UPDATE_SIT_KEYWORD";
export const UPDATE_SIT_KEYWORD_CASE_SENSITIVE =
	"@@sit/UPDATE_SIT_KEYWORD_CASE_SENSITIVE";
export const CREATE_SIT_KEYWORD = "@@sit/CREATE_SIT_KEYWORD";

/* ----------------------- NEW --------------------- */
// SIT List
export const GET_SIT_LIST_NEW = "@@sit/GET_SIT_LIST_NEW";
export const SET_SIT_LIST_NEW = "@@sit/SET_SIT_LIST_NEW";

// SIT Keywords
export const GET_SIT_KEYWORDS_NEW = "@@sit/GET_SIT_KEYWORDS_NEW";
export const SET_SIT_KEYWORDS_NEW = "@@sit/SET_SIT_KEYWORDS_NEW";

// SIT Main List
export const GET_SIT_MAINLIST_NEW = "@@sit/GET_SIT_MAINLIST_NEW";
export const SET_SIT_MAINLIST_NEW = "@@sit/SET_SIT_MAINLIST_NEW";
export const REQUEST_SIT_MAINLIST_NEW = "@@sit/REQUEST_SIT_MAINLIST_NEW";
export const RECEIVED_SIT_MAINLIST_NEW = "@@sit/RECEIVED_SIT_MAINLIST_NEW";
export const SET_SIT_MAINLIST_LOADING = "@@sit/SET_SIT_MAINLIST_LOADING";

/* ------------------------------------------------- */
