import { createGlobalStyle } from "styled-components";

export const style = `
:root {
    /* Sizing/Spacing */
    --unit: 0.16666667; // 2px

    --sp-0: 0px;
    --sp-px: 1px;
    --sp-0_5: calc(var(--unit) * 0.5rem);
    --sp-1: calc(var(--unit) * 1rem);
    --sp-1_5: calc(var(--unit) * 1.5rem);
    --sp-2: calc(var(--unit) * 2rem);
    --sp-2_5: calc(var(--unit) * 2.5rem);
    --sp-3: calc(var(--unit) * 3rem);
    --sp-3_5: calc(var(--unit) * 3.5rem);
    --sp-4: calc(var(--unit) * 4rem);
    --sp-4_5: calc(var(--unit) * 4.5rem);
    --sp-5: calc(var(--unit) * 5rem);
    --sp-5_5: calc(var(--unit) * 5.5rem);
    --sp-6: calc(var(--unit) * 6rem);
    --sp-6_5: calc(var(--unit) * 6.5rem);
    --sp-7: calc(var(--unit) * 7rem);
    --sp-7_5: calc(var(--unit) * 7.5rem);
    --sp-8: calc(var(--unit) * 8rem);
    --sp-8_5: calc(var(--unit) * 8.5rem);
    --sp-9: calc(var(--unit) * 9rem);
    --sp-9_5: calc(var(--unit) * 9.5rem);
    --sp-10: calc(var(--unit) * 10rem);
    --sp-10_5: calc(var(--unit) * 10.5rem);
    --sp-11: calc(var(--unit) * 11rem);
    --sp-12: calc(var(--unit) * 12rem);
    --sp-12_5: calc(var(--unit) * 12.5rem);
    --sp-13: calc(var(--unit) * 13rem);
    --sp-13_5: calc(var(--unit) * 13.5rem);
    --sp-14: calc(var(--unit) * 14rem);
    --sp-15: calc(var(--unit) * 15rem);
    --sp-16: calc(var(--unit) * 16rem);
    --sp-17: calc(var(--unit) * 17rem);
    --sp-17_5: calc(var(--unit) * 17.5rem);
    --sp-18: calc(var(--unit) * 18rem);
    --sp-19: calc(var(--unit) * 19rem);
    --sp-20: calc(var(--unit) * 20rem);
    --sp-21: calc(var(--unit) * 21rem);
    --sp-22: calc(var(--unit) * 22rem);
    --sp-23: calc(var(--unit) * 23rem);
    --sp-24: calc(var(--unit) * 24rem);
    --sp-25: calc(var(--unit) * 25rem);
    --sp-26: calc(var(--unit) * 26rem);
    --sp-27: calc(var(--unit) * 27rem);
    --sp-28: calc(var(--unit) * 28rem);
    --sp-29: calc(var(--unit) * 29rem);
    --sp-30: calc(var(--unit) * 30rem);
    --sp-35: calc(var(--unit) * 35rem);
    --sp-40: calc(var(--unit) * 40rem);
    --sp-45: calc(var(--unit) * 45rem);
    --sp-47_5: calc(var(--unit) * 47.5rem);
    --sp-55: calc(var(--unit) * 55rem);
    --sp-60: calc(var(--unit) * 60rem);
    --sp-65: calc(var(--unit) * 65rem);

    --br: var(--sp-1_5);
    --br-lg: var(--sp-2_5);

    /* App size stuff */
    --sp-container: calc(var(--unit) * 254rem);

    /* Colors */
    /* Eui Colors */
    --eui-primary-color: #006bb4;
    --eui-secondary-color: #017D73;
    --eui-danger-color: #bd271e;
    --eui-warning-color: #F5A700;
    --eui-text-color: #343741;
    --eui-link-color: #006bb4;
    --eui-darkshade-color: #69707D;
    --eui-vis0-color: #5BBAA0;
    --eui-lightest-shade-color: #F5F7FA;
    --eui-darkest-shade-color: #343741;
    --eui-text-disabled-color: #C2C3C6;
    --eui-disabled-text-color: #ABB4C4;
    --eui-light-shade-color: #d3dae6;
    --eui-success-color: #007e77;
    --eui-complete-color: #8A6A0A;
    --eui-failed-color: #C54D46;
    --eui-cancelled-color: #C54D46;
    --eui-medium-shade-color: #98A2B3;
    --core-warning-color: #FEC514;
    --eui-form-background-color: #FBFCFD;
    --eui-badge-primary-color: #79AAD9;
    --eui-red-color: #ff0000;
    --eui-buttons-text-normal-text-color: #5A606B;
    --eui-buttons-text-normal-primary-color: #005EC4;
    --eui-buttons-background-normal-default-text-color: rgba(105, 112, 125, 0.2);
    --eui-buttons-background-normal-default-primary-color: rgba(0, 109, 228, 0.2);
    --eui-buttons-background-normal-default-disabled-color: rgba(171, 180, 196, 0.1);
    --eui-focus-background-color: rgba(0, 119, 204, 0.1);
    
    /* Main Colors */
    --base-color: #000000;
    --primary-color: #343741;
    --light-color: #98a2b3;
    --success-color: #017d73;
    --danger-color: #bd271e;
    --secondary-color: #017d73;
    --warning-color: #f5a700;
    --white-color: #ffffff;
    --transparent: transparent;
    --linkein-color: #0077B7;
    --disabled-color: #afb0b3;

    /* Font Colors */
    --text-color: #343741;
    --text-disabled-color: #C2C3C6;

    /* Border Colors */
    --border-color: #d3dae6;
    --br: var(--sp-1);
    --br-lg: var(--sp-2_5);

    /* Un regular colors */
    --title-color: #1a1c21;
    --section-title-color: #98a2b3;
    --link-color: #006bb4;
    --info-color: #69707d;
    --lightest-shade-color: #f5f7fa;
    --badge-color: #e0e5ee;
    --vis5-behind-text-color: #f1d86f;
    --warning-text-color: #9b6900;

    /* Button colors */
    --primary-btn-color: #006bb4;
    --btn-border-color: #c2c3c6;

    /* Button background colors */
    --hovered-btn-bg-color: #eef2f7;
    --bg-color: #e9edf3;
    --self-bg-color: #dbeeff;
    --empty-shade-bg-color: #eaeef4;

    --code-block-number-color: #00a69b;
    --empower-core: #f05c22;
    --screening-background: #ddedff;

    --button-secondary-color: #008C83;
    --button-disabled-color: rgba(105, 112, 125, 0.1);

    /* Shadow */
    --shadow-bottom-medium: 0px 0.9px 4px -1px rgba(0, 0, 0, 0.08), 0px 2.6px 8px -1px rgba(0, 0, 0, 0.06), 0px 5.7px 12px -1px rgba(0, 0, 0, 0.05), 0px 15px 15px -1px rgba(0, 0, 0, 0.04);

    /* others */
    --blue-3: #56ccf2;
    --orange: #f2994a;
    --green-1: #219653;
    --gray-1: #333333;

    --badge-secondary: #6dccb1;
`;

export default createGlobalStyle`${style}`;
