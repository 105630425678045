import React from "react";
import { EuiFlexGroup, EuiFlexItem, EuiIcon } from "@elastic/eui";
import styled from "styled-components";

import * as MODEL from "core/model";

import AmsReminderListItem from "./AmsReminderListItem";

/**
 * Component for displaying Reminder list of AMS
 */
const AmsReminderList = (props) => {
	const { data, onChangeReminder, onDeleteReminder, loadPersonAmsData } = props;

	const handleClickAddReminder = () => {
		const newReminder = {
			id: null,
			[MODEL.ams_activity_reminder.reminder_type_c]: 1, // browser
			[MODEL.ams_activity_reminder.reminder_triggered]: false, // false
			[MODEL.ams_activity_reminder.reminder_result_c]: 1, // pending
			[MODEL.ams_activity_reminder.reminder_time_value]: 15, // 0
			[MODEL.ams_activity_reminder.reminder_time_frame_c]: 1, // minutes
			[MODEL.ams_activity_reminder.reminder_time_type_c]: 1, // before
			[MODEL.ams_activity_reminder.reminder_details]: null,
		};

		onChangeReminder(newReminder);
	};

	const handleChangeReminder = (reminder) => {
		onChangeReminder(reminder);
	};

	return (
		<Wrapper>
			<EuiFlexGroup gutterSize="m" direction="column">
				<EuiFlexItem>
					<EuiFlexGroup gutterSize="none" justifyContent="spaceBetween">
						<EuiFlexItem>
							<p className="type-small-bold">Reminder</p>
						</EuiFlexItem>
						<EuiFlexItem grow={false}>
							<div
								className="add-reminder_container"
								onClick={handleClickAddReminder}
							>
								<p className="add-reminder-icon">
									<EuiIcon type="plusInCircleFilled" />
								</p>
							</div>
						</EuiFlexItem>
					</EuiFlexGroup>
				</EuiFlexItem>
				<EuiFlexItem>
					<EuiFlexGroup direction="column" gutterSize="s">
						{data?.map((reminder, id) => {
							return (
								<EuiFlexItem key={`ams-reminder-list-item-${id}`}>
									<AmsReminderListItem
										data={reminder}
										onDeleteReminder={onDeleteReminder}
										onChangeReminder={(reminder) =>
											handleChangeReminder(reminder)
										}
										loadPersonAmsData={loadPersonAmsData}
									/>
								</EuiFlexItem>
							);
						})}
					</EuiFlexGroup>
				</EuiFlexItem>
			</EuiFlexGroup>
		</Wrapper>
	);
};

export default AmsReminderList;

const Wrapper = styled.div`
	display: flex;

	.add-reminder_container {
		display: flex;
		width: fit-content;
		color: var(--eui-link-color);
		cursor: pointer;
		align-items: center;

		.add-reminder-icon {
			margin-left: var(--sp-2_5);
		}

		.add-reminder-label {
			margin-left: var(--sp-4);
		}
	}
`;
