import API from "../../api";
import {
	GET_SYSTEM_ERROR_LOGS,
	SET_SYSTEM_ERROR_LOGS,
	GET_SYSTEM_ACTIVITY_LOGS,
	SET_SYSTEM_ACTIVITY_LOGS,
} from "./actionTypes";
import { objectChecker } from "../../helpers/utilities";

export const getSystemErrorLogs = (data) => {
	return {
		type: GET_SYSTEM_ERROR_LOGS,
		payload: data,
	};
};

export const setSystemErrorLogs = (data) => {
	return {
		type: SET_SYSTEM_ERROR_LOGS,
		payload: data,
	};
};

export const fetchSystemErrorLogs = (
	pageNumber = 1,
	perPage = 30,
	filters = { error_source: "database" }
) => {
	return async (dispatch) => {
		//dispatch(setLoading(true))
		dispatch(getSystemErrorLogs({ message: "Fetching system error logs from server" }));
		try {
			const params = {
				page: pageNumber,
				per_page: perPage,
				filters,
			};

			const result = await API().SystemLogs().getSystemErrorLogs(params);

			console.log("params", JSON.stringify(params));

			/*alert(JSON.stringify({
				result, params 
			}))*/

			const data = objectChecker(result, ["response", "error_logs"]);
			const totalCount = objectChecker(result, ["response", "total_count"]);
			if (data && totalCount) {
				console.log("system error logs params", JSON.stringify(params));
				//dispatch(setLoading(false))
				dispatch(
					getSystemErrorLogs({
						message: "Success getting system error logs",
						error: null,
					})
				);
				dispatch(setSystemErrorLogs({ data, totalCount }));
			} else {
				//alert('System Error Logs empty: ' + JSON.stringify({ totalCount }))
				dispatch(setSystemErrorLogs({ data: [], totalCount: 0 }));
			}
		} catch (error) {
			dispatch(getSystemErrorLogs({ message: "Error System Logs", error }));
			dispatch(setSystemErrorLogs([]));
		}
	};
};

export const getSystemActivityLogs = (data) => {
	return {
		type: GET_SYSTEM_ACTIVITY_LOGS,
		payload: data,
	};
};

export const setSystemActivityLogs = (data) => {
	return {
		type: SET_SYSTEM_ACTIVITY_LOGS,
		payload: data,
	};
};

export const fetchSystemActivityLogs = (pageNumber = 1, perPage = 20, filters = {}) => {
	return async (dispatch) => {
		//dispatch(setLoading(true))
		dispatch(
			getSystemActivityLogs({
				message: "Fetching system activity logs from server",
			})
		);
		try {
			const params = {
				page: pageNumber,
				per_page: perPage,
				filters,
			};

			const result = await API().SystemLogs().getSystemActivityLogs(params);

			console.log("params", JSON.stringify(params));

			/*alert(JSON.stringify({
				result, params 
			}))*/

			const data = objectChecker(result, ["response", "activity_logs"]);
			const totalCount = objectChecker(result, ["response", "total_count"]);
			if (data && totalCount) {
				console.log("system error logs params", JSON.stringify(params));
				//dispatch(setLoading(false))
				dispatch(
					getSystemActivityLogs({
						message: "Success getting system activity logs",
						error: null,
					})
				);
				dispatch(setSystemActivityLogs({ data, totalCount }));
			} else {
				//alert('System Error Logs empty: ' + JSON.stringify({ totalCount }))
				dispatch(setSystemActivityLogs({ data: [], totalCount: 0 }));
			}
		} catch (error) {
			dispatch(getSystemActivityLogs({ message: "Error fetching Activity Logs", error }));
			dispatch(setSystemActivityLogs({ data: [], totalCount: 0 }));
		}
	};
};
