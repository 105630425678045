import {
	REMOVE_NOTIFICATION,
	SET_NOTIFICATION,
	PROGRESS_NOTIFICATION,
} from "./actionTypes";

/**
 * Functions
 */

const setNotification = (state, payload) => {
	return {
		...state,
		// notifications: [...state.notifications, payload],
		notifications: [payload],
	};
};

const removeNotification = (state, payload) => {
	return {
		...state,
		notifications: state.notifications.filter((toast) => toast.id !== payload.id),
	};
};

const setProgressNotification = (state, payload) => {
	return {
		...state,
		progressNotification: payload,
	};
};

/**
 * Initial State
 */
const initialState = {
	notifications: [],
	progressNotification: null,
};

/**
 * Reducer
 */
const Reducer = (state = initialState, action) => {
	switch (action.type) {
		case SET_NOTIFICATION:
			return setNotification(state, action.payload);
		case REMOVE_NOTIFICATION:
			return removeNotification(state, action.payload);
		case PROGRESS_NOTIFICATION:
			return setProgressNotification(state, action.payload);
		default:
			return state;
	}
};

export default Reducer;
