import React from "react";
import styled from "styled-components";

import { SVGIcon, EditTitle } from "components/Common";
import {briefcaseIcon} from "components/Common/Icons";

/**
 * Main Components
 */
const PersonJobTitle = (props) => {

	const {
        title,
        onChangeTitle,
		...restProps
	} = props;

    const handleChangeTitle = (data) => {
        onChangeTitle(data);
    }

	return (
		<Wrapper {...restProps}>
            <SVGIcon
                icon={briefcaseIcon}
                className="mt-2 mr-2"
            />
            <EditTitle
                title={title}
                onChangeTitle={handleChangeTitle}
            />
        </Wrapper>
	);
};

export default PersonJobTitle;

/**
 * Styled Components
 */
const Wrapper = styled.div`
    display: flex;

    .euiFormControlLayout {
        height: 30px;
    }
`;
