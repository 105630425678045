import React, { useState, useMemo } from "react";
import styled from "styled-components";

import {
	EuiPopover,
	EuiContextMenuPanel,
	EuiButtonEmpty,
	EuiContextMenuItem,
} from "@elastic/eui";
import { PAGINATION_PER_PAGE_LIST } from "components/global/constants";

/**
 * Component for selecting per_page in table
 */
const PerPageItem = (props) => {
	const { perPage = PAGINATION_PER_PAGE_LIST[0], onChange, type = 0 } = props;

	const [isPopoverOpen, setIsPopoverOpen] = useState(false);

	const onButtonClick = () => setIsPopoverOpen((isPopoverOpen) => !isPopoverOpen);
	const closePopover = () => setIsPopoverOpen(false);

	const handleChange = (perPage) => {
		onChange(perPage);
		closePopover();
	};

	const menuItems = useMemo(() => {
		return PAGINATION_PER_PAGE_LIST.map((perPage, id) => {
			return (
				<EuiContextMenuItem
					key={`${perPage} rows`}
					onClick={() => handleChange(perPage)}
					tabIndex={id}
				>
					{perPage}
				</EuiContextMenuItem>
			);
		});
	}, []);

	const renderButton = () => {
		if (type === 0) {
			return (
				<EuiButtonEmpty
					size="xs"
					color="text"
					iconType="arrowDown"
					iconSide="right"
					onClick={onButtonClick}
					tabIndex={0}
				>
					Display {perPage} rows
				</EuiButtonEmpty>
			);
		} else if (type === 1) {
			return (
				<EuiButtonEmpty
					size="xs"
					color="text"
					onClick={onButtonClick}
					tabIndex={0}
				>
					Display <span className="link-button-text">{perPage}</span> rows
				</EuiButtonEmpty>
			);
		}

		return null;
	};

	return (
		<Wrapper>
			<EuiPopover
				button={renderButton()}
				isOpen={isPopoverOpen}
				closePopover={closePopover}
				panelPaddingSize="none"
			>
				<EuiContextMenuPanel items={menuItems} />
			</EuiPopover>
		</Wrapper>
	);
};

export default PerPageItem;

const Wrapper = styled.div`
	.link-button-text {
		color: var(--link-color);
		text-decoration: underline;
	}
`;
