import React from "react";
import { EuiFlexItem, EuiFlexGroup, EuiCheckbox } from "@elastic/eui";
import styled from "styled-components";

import {
	EditableNumber,
	AmsReminderTypeItem,
	AmsReminderTimeTypeItem,
	AmsReminderTimeFrameItem,
} from "components/Common";

import * as MODEL from "core/model";

/**
 * Component for displaying Reminder List Item of AMS
 */
const MessageAmsReminderListItem = (props) => {
	const { data, onUpdateReminder } = props;

	// Reference ams_activity_reminder model
	const reminder = {
		id: data?.id || null,
		index: data?.index,
		checked: data?.checked || false,
		reminder_type_c: data?.reminder_type_c,
		reminder_time_value: data?.reminder_time_value,
		reminder_time_frame_c: data?.reminder_time_frame_c,
		reminder_time_type_c: data?.reminder_time_type_c,
		reminder_triggered: data?.[MODEL.ams_activity_reminder.reminder_triggered],
		reminder_result_c: data?.[MODEL.ams_activity_reminder.reminder_result_c] || 1,
	};

	const isEditable = !reminder.reminder_triggered;

	const handleChangeCheckedAms = (e) => {
		const checked = e.target.checked;

		onUpdateReminder({
			...reminder,
			checked,
		});
	};

	const handleReminderType = (newType) => {
		const newReminder = {
			...reminder,
			[MODEL.ams_activity_reminder.reminder_type_c]: newType,
		};

		onUpdateReminder(newReminder);
	};

	const handleReminderTimeType = (newTimeType) => {
		const newReminder = {
			...reminder,
			[MODEL.ams_activity_reminder.reminder_time_type_c]: newTimeType,
		};

		onUpdateReminder(newReminder);
	};

	const handleReminderTimeFrame = (newFrame) => {
		const newReminder = {
			...reminder,
			[MODEL.ams_activity_reminder.reminder_time_frame_c]: newFrame,
		};

		onUpdateReminder(newReminder);
	};

	const handleSaveTimeValue = (timeValue) => {
		const newReminder = {
			...reminder,
			[MODEL.ams_activity_reminder.reminder_time_value]: timeValue,
		};

		onUpdateReminder(newReminder);
	};

	return (
		<Wrapper className="type-small-default">
			<EuiFlexGroup gutterSize="xs" alignItems="center">
				<EuiFlexItem grow={false}>
					<EuiCheckbox
						id={`person-message-ams-check-item-${reminder.index}`}
						label=""
						checked={reminder.checked}
						onChange={handleChangeCheckedAms}
						className="message-ams-check-item"
					/>
				</EuiFlexItem>
				<EuiFlexItem grow={false}>
					<p>Include</p>
				</EuiFlexItem>
				<EuiFlexItem grow={false}>
					<AmsReminderTypeItem
						type={reminder.reminder_type_c}
						onChange={handleReminderType}
						isEdit={isEditable}
					/>
				</EuiFlexItem>
				<EuiFlexItem grow={false}>
					<p>reminder for</p>
				</EuiFlexItem>
				<EuiFlexItem grow={false}>
					<EditableNumber
						value={reminder.reminder_time_value}
						isEdit={isEditable}
						onSave={handleSaveTimeValue}
					/>
				</EuiFlexItem>
				<EuiFlexItem grow={false}>
					<AmsReminderTimeTypeItem
						type={reminder.reminder_time_type_c}
						onChange={handleReminderTimeType}
						isEdit={isEditable}
					/>
				</EuiFlexItem>
				<EuiFlexItem grow={false}>
					<AmsReminderTimeFrameItem
						frame={reminder.reminder_time_frame_c}
						onChange={handleReminderTimeFrame}
						isEdit={isEditable}
					/>
				</EuiFlexItem>
				<EuiFlexItem grow={false}>
					<p>task</p>
				</EuiFlexItem>
			</EuiFlexGroup>
		</Wrapper>
	);
};

export default MessageAmsReminderListItem;

const Wrapper = styled.div`
	display: flex;
	width: 100%;
	position: relative;

	.message-ams-check-item {
		margin-right: var(--sp-2);
		margin-top: 0 !important;
	}

	.reminder-time-frame-item-label {
		margin-top: var(--sp-2);
	}

	.reminder-time-type-item-label {
		margin-top: var(--sp-2);
	}

	.reminder-type-item-label {
		margin-top: var(--sp-2);
	}
`;
