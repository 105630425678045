import Service from "core/service";
import * as MODEL from "core/model";

// CMS communication draft record
export const updateCmsCommunicaionDraft = (params, successCallback, failCallback) => {
	return async (dispatch) => {
		try {
			const queries = params?.map((item) => {
				return {
					[MODEL.cms_communication_draft._name]: item,
				};
			});
			const result = await Service.cms.updateCms(queries);

			if (result && !result?.error) {
				if (successCallback) {
					successCallback(result);
				}
			} else {
				if (failCallback) {
					failCallback(result);
				}
			}
		} catch (err) {
			if (failCallback) {
				failCallback(err);
			}
		}
	};
};
