import React from "react";
import styled from "styled-components";

import { organization, person, star, user, warning } from "../Icons/Icons";

const CustomQualifierIcon = (props) => {
	const color = props.data.color;
	const type = props.data.type;

	const icon = () => {
		switch (type) {
			case "star":
				return star(color);
			case "person":
				return person(color);
			case "user":
				return user(color);
			case "warning":
				return warning(color);
			case "organization":
				return organization(color);
			default:
				return star();
		}
	};

	return <Wrapper>{icon()}</Wrapper>;
};

export default CustomQualifierIcon;

const Wrapper = styled.span`
	svg {
		margin-bottom: -2px;
	}
`;
