import { useDispatch } from "react-redux";

import { deleteCompany_NEW, updateCompany_NEW } from "helpers/actions";
import * as MODEL from "core/model";

/**
 * Hooks for Company
 */
const useCompany = () => {
	const dispatch = useDispatch();

	const deleteCompany = (query, successCallBack, failCallBack) => {
		dispatch(deleteCompany_NEW(query, successCallBack, failCallBack));
	};

	const saveCompanyPhone = (companyId, phone, successCallBack, failCallBack) => {
		const global = phone?.global_phone_details;
		const query = {
			[MODEL.company_phone._name]: {
				[MODEL.company_phone.id]: phone?.id,
				[MODEL.company_phone.company_main_ref_id]: companyId,
				[MODEL.company_phone.phone_type_c]: phone?.phone_type_c,
				[MODEL.global_phone_details._name]: {
					[MODEL.global_phone_details.id]: global?.id,
					[MODEL.global_phone_details.phone_number]: global?.phone_number,
					[MODEL.global_phone_details.phone_country_code]:
						global?.phone_country_code,
					[MODEL.global_phone_details.phone_extension]: global?.phone_extension,
					[MODEL.global_phone_details.phone_status_c]: global?.phone_status_c,
				},
			},
		};

		return new Promise((resolve, reject) => {
			dispatch(
				updateCompany_NEW(
					query,
					(res) => {
						if (successCallBack) successCallBack(res);
						resolve(res);
					},
					(err) => {
						if (failCallBack) failCallBack(err);
						reject(err);
					}
				)
			);
		});
	};

	const saveCompanyEmail = (companyId, email, successCallBack, failCallBack) => {
		const global = email?.global_email_details;
		const query = {
			[MODEL.company_email._name]: {
				[MODEL.company_email.id]: email?.id,
				[MODEL.company_email.company_main_ref_id]: companyId,
				[MODEL.company_email.email_type_c]: email?.email_type_c,
				[MODEL.global_email_details._name]: {
					[MODEL.global_email_details.id]: global?.id,
					[MODEL.global_email_details.email_value]: global?.email_value,
					[MODEL.global_email_details.email_status_date]:
						global?.email_status_date,
					[MODEL.global_email_details.email_status_c]: global?.email_status_c,
				},
			},
		};

		return new Promise((resolve, reject) => {
			dispatch(
				updateCompany_NEW(
					query,
					(res) => {
						if (successCallBack) successCallBack(res);
						resolve(res);
					},
					(err) => {
						if (failCallBack) failCallBack(err);
						reject(err);
					}
				)
			);
		});
	};

	return {
		deleteCompany,
		saveCompanyPhone,
		saveCompanyEmail,
	};
};

export default useCompany;
