import React from "react";
import "./EditControls.scss";

function EditControls(props) {
	const {
		editData,
		data,
		// What happens when you press the "Cancel" button
		handleCancel,
		setEditClicked,
		// What happens when you press the "Save" button
		onSaveHandler,
		// Override styles here
		styleOverrides,
		alwaysEditMode,
		setEditData,
	} = props;

	let styles = {};

	if (styleOverrides) {
		styles = styleOverrides;
	}

	return (
		<div style={styles}>
			<div id="edit-controls">
				<button
					onClick={handleCancel}
					className="edit-controls--children btn-cancel"
				>
					Cancel
				</button>

				<button
					onClick={() => {
						if (!alwaysEditMode) {
							setEditClicked(false);
						}

						onSaveHandler(editData, data, setEditData);
					}}
					className="edit-controls--children btn-save"
				>
					Save
				</button>
			</div>
		</div>
	);
}

export default EditControls;
