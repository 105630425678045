import React from "react";
import styled from "styled-components";

import { SVGIcon, Address } from "components/Common";
import { mapMarkerAltIcon } from "components/Common/Icons";

/**
 * Main Components
 */
const StaticAddress = (props) => {
	const { address, ...restProps } = props;

	return (
		<Wrapper {...restProps} className="mb-2">
			<SVGIcon icon={mapMarkerAltIcon} className="mr-2" />
			<Address address={address?.global_address_details || ""} />
		</Wrapper>
	);
};

export default StaticAddress;

/**
 * Styled Components
 */
const Wrapper = styled.div`
	display: flex;
`;
