import React from "react";
import styled from "styled-components";
import { EuiFlexItem, EuiText, EuiFlexGroup } from "@elastic/eui";

import { STEP_STATUES } from "components/global/constants";

import variables from "assets/css/variables.scss";

/**
 * Main Components
 */
const HorizontalStepbarWithRoundCircle = (props) => {
	const { maxstep, step, steps, ...restProps } = props;

	if (!maxstep || step === undefined || step === null) return <></>;

	return (
		<Wrapper {...restProps}>
			<EuiFlexGroup direction="row" gutterSize="none">
				{Array(maxstep)
					.fill(0)
					.map((val, index) => {
						let stepStatus = STEP_STATUES.complete;

						if (index === step) {
							stepStatus = STEP_STATUES.selected;
						} else if (index > step) {
							stepStatus = STEP_STATUES.incomplete;
						}

						return (
							<EuiFlexItem
								role="tab"
								className={`step ${stepStatus.className ?? "none"}`}
								key={`step-bar-${index}`}
								maxstep={maxstep}
							>
								<EuiFlexGroup
									gutterSize="xs"
									direction="column"
									alignItems="center"
								>
									<EuiFlexItem>
										<EuiText className="step-number">
											<span>{index + 1}</span>
										</EuiText>
									</EuiFlexItem>
									{steps && steps?.[index] ? (
										<EuiFlexItem className="step-label">
											<EuiText>
												<span>{steps?.[index]?.label}</span>
											</EuiText>
										</EuiFlexItem>
									) : null}
								</EuiFlexGroup>
							</EuiFlexItem>
						);
					})}
			</EuiFlexGroup>
		</Wrapper>
	);
};

export default HorizontalStepbarWithRoundCircle;

/**
 * Styled Components
 */
const Wrapper = styled.div`
	display: flex;
	width: 100%;

	> div {
		width: 100%;
	}

	.step {
		display: flex;
		position: relative;
		align-items: center;
		width: calc(100% / ${(props) => props.maxstep || 1});

		&:not(:first-child):before {
			content: "";
			position: absolute;
			width: 50%;
			height: 2px;
			top: 15px;
			left: 0;
			background-color: ${variables.borderColor};
			z-index: 0;
		}

		&:not(:last-child):after {
			content: "";
			position: absolute;
			width: 50%;
			height: 2px;
			top: 15px;
			right: 0;
			background-color: ${variables.borderColor};
			z-index: 0;
		}

		.step-number {
			display: flex;
			width: 2.667rem;
			height: 2.667rem;
			line-height: 1.5;
			font-size: 1.333rem;
			border-radius: 2.667rem;
			text-align: center;
			color: ${variables.primaryColor};
			background-color: ${variables.lightestShadeColor};
			z-index: 1;

			justify-content: center;
			align-items: center;

			span {
				margin-left: -1px;
			}
		}

		&.selected {
			&:not(:first-child):before {
				background-color: ${variables.linkColor};
			}

			.step-number {
				border: solid 2px ${variables.linkColor};
			}
		}

		&.complete {
			&:not(:first-child):before {
				background-color: ${variables.linkColor};
			}

			&:not(:last-child):after {
				background-color: ${variables.linkColor};
			}

			.step-number {
				color: #ffffff;
				background: ${variables.linkColor};
			}
		}

		.step-label {
			span {
				font-size: 1rem;
				line-height: 1.5;
				text-align: center;
				color: ${variables.primaryColor};
			}
		}
	}
`;
