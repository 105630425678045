import React, { useEffect } from "react";
import { useLocation } from "react-router-dom";
import {
    EuiFlyout,
    EuiFlyoutBody,
    EuiFlyoutFooter,
    EuiFlyoutHeader,
    EuiTitle,
} from "@elastic/eui";
import styled from "styled-components";
import { isEmpty } from "lodash";

import { useGlobalFlyout } from "core/useHooks";

/**
 * Global Flyout
 */
const GlobalFlyout = () => {
    const location = useLocation();
    const { pathname } = location;

    const { globalFlyoutParams, closeFlyout: closeGlobalFlyout } = useGlobalFlyout();

    const {
        className = "",
        title = "",
        header = null,
        body = null,
        footer = null,
        size = "s",
        ownFocus = false,
        closeFlyout,
        isOpen,
        maxWidth = "unset",
    } = globalFlyoutParams;

    const handleClose = () => {
        if (closeFlyout) {
            closeFlyout();
        }
        closeGlobalFlyout();
    };

    useEffect(() => {
        closeGlobalFlyout();
    }, [pathname]);

    if (!isOpen) return <div />;

    return (
        <Wrapper
            className={className}
            onClose={handleClose}
            ownFocus={ownFocus}
            size={size}
            maxWidth={maxWidth}
        >
            <EuiFlyoutHeader hasBorder>
                {isEmpty(header) ? (
                    <EuiTitle size="m">
                        <h2>{title}</h2>
                    </EuiTitle>
                ) : (
                    header
                )}
            </EuiFlyoutHeader>
            <EuiFlyoutBody>{body}</EuiFlyoutBody>
            {footer && <EuiFlyoutFooter>{footer}</EuiFlyoutFooter>}
        </Wrapper>
    );
};

export default GlobalFlyout;

const Wrapper = styled(EuiFlyout)`
    .euiFlyoutFooter {
        margin-bottom: 0 !important;
    }
`;
