import Service from "core/service";
import { objectChecker } from "helpers/utilities";
import { logError } from "store/error";
import * as actionTypes from "./actionTypes";

export const setDashboardMainData = (data) => {
    return {
        type: actionTypes.MAIN_DASHBOARD_DATA,
        payload: data,
    };
};

export const getDashboardMainData = () => {
    return async (dispatch) => {
        try {
            const result = await Service.dashboard.getMainData();
            const data = objectChecker(result, ["response", "count_records"]);

            if (data) {
                dispatch(setDashboardMainData(data));
            } else {
                dispatch(setDashboardMainData(null));
            }
        } catch (err) {
            dispatch(
                logError("fetch_failure", "none", {
                    Error: "Error fetching dashboard data: " + JSON.stringify(err),
                })
            );
            dispatch(setDashboardMainData(null));
        }
    };
};
