import React, { useMemo } from "react";
import styled from "styled-components";
import { EuiFlexGroup, EuiFlexItem, EuiButton, EuiLink } from "@elastic/eui";
import { get, truncate } from "lodash";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus } from "@fortawesome/pro-regular-svg-icons";

import { RASItem } from "components/Common";
import CmsRelatedBadgeItem from "./CmsRelatedBadgeItem";
import {
	CMS_COMM_DIRECTION_TYPE_LIST,
	CMS_COMM_PLATFORM_TYPE_LIST,
	CMS_CONVERSATION_TYPE_LIST,
} from "./constants";
import { getDate } from "helpers/utilities";
import { getGCSLabel } from "components/global/utils";
import { useGlobal } from "core/useHooks";
import * as MODEL from "core/model";

/**
 * Main Components for Static Item of CMS Draft record
 */
const CmsDraftStaticItem = (props) => {
	const { data, accounts, owners, assignees, restriction, onChangeEdit } = props;

	const { globals } = useGlobal();

	const {
		relatedRecords,
		cmsDirection,
		cmsPlatform,
		cmsCreatedDate,
		cmsMessage,
		cmsSubject,
		cmsBodyHtml,
		cmsTrackingState,
		cmsConversationType,
	} = useMemo(() => {
		const relatedRecords = get(data, "related", []) || [];

		// Get comm_details
		const commDetails = data?.[MODEL.cms_communication_draft.comm_details];

		// Get direction information
		const cmsDirectionValue = commDetails?.comm_direction;
		const cmsDirection =
			Object.values(CMS_COMM_DIRECTION_TYPE_LIST).find(
				(item) => item.value === cmsDirectionValue
			) || CMS_COMM_DIRECTION_TYPE_LIST.incoming;

		// Get CMS platform
		const cmsPlatformValue = get(data, "comm_platform_c");
		const cmsPlatform =
			Object.values(CMS_COMM_PLATFORM_TYPE_LIST).find(
				(item) => item.value === cmsPlatformValue
			) || CMS_COMM_PLATFORM_TYPE_LIST.manual;

		// Get CMS conversation type
		const cmsConversationTypeValue =
			data?.[MODEL.cms_communication_draft.conversation_type_c];
		const cmsConversationType =
			Object.values(CMS_CONVERSATION_TYPE_LIST).find(
				(item) => item.value === cmsConversationTypeValue
			) || CMS_CONVERSATION_TYPE_LIST.email;

		// Created Data
		const cmsCreatedDate = get(data, "date_created");

		// Comm Details
		const details = commDetails?.details;
		let cmsMessage = "";
		let cmsSubject = details?.subject || "";
		let cmsBodyHtml = details?.body?.content || "";

		return {
			relatedRecords,
			cmsDirection,
			cmsPlatform,
			cmsCreatedDate,
			commDetails,
			cmsMessage,
			cmsSubject,
			cmsBodyHtml,
			cmsConversationType,
		};
	}, [data]);

	const handleClickEdit = () => {
		onChangeEdit();
	};
	return (
		<Wrapper>
			<EuiFlexGroup gutterSize="m">
				<EuiFlexItem grow={false}>
					<EuiFlexGroup direction="column" gutterSize="none">
						<EuiFlexItem grow={false}>
							<EuiFlexGroup gutterSize="none">
								<EuiFlexItem className="platform-icon">
									{cmsConversationType.icon}
								</EuiFlexItem>
								<EuiFlexItem className="direction-icon">
									{cmsDirection.icon}
								</EuiFlexItem>
							</EuiFlexGroup>
						</EuiFlexItem>
						<EuiFlexItem grow={false}>
							<span className="type-small-definication-term cms-state">
								Manual
							</span>
						</EuiFlexItem>
						<EuiFlexItem grow={false}>
							<span className="type-small-definication-term cms-date">
								{cmsCreatedDate
									? getDate(cmsCreatedDate, "MM/DD/YYYY")
									: "-"}
							</span>
						</EuiFlexItem>
					</EuiFlexGroup>
				</EuiFlexItem>

				<EuiFlexItem>
					<EuiFlexGroup direction="column" gutterSize="none">
						<EuiFlexItem grow={false}>
							<EuiLink>
								<span className="cms-title">
									{cmsSubject ? cmsSubject : truncate(cmsMessage, 30)}
								</span>
							</EuiLink>
						</EuiFlexItem>
						<EuiFlexItem grow={false}>
							{!cmsBodyHtml && (
								<span className="cms-description">
									{cmsMessage || "-"}
								</span>
							)}
							{cmsBodyHtml && (
								<div
									dangerouslySetInnerHTML={{ __html: cmsBodyHtml }}
								></div>
							)}
						</EuiFlexItem>
						<EuiFlexItem grow={false}>
							<EuiFlexGroup className="badge-items" gutterSize="s">
								{relatedRecords?.slice(0, 2)?.map((item, id) => {
									return (
										<EuiFlexItem
											key={`ams-task-list-item-${id}`}
											grow={false}
										>
											<CmsRelatedBadgeItem data={item} />
										</EuiFlexItem>
									);
								})}
							</EuiFlexGroup>
						</EuiFlexItem>
					</EuiFlexGroup>
				</EuiFlexItem>

				<EuiFlexItem grow={false}>
					<EuiFlexGroup direction="column" gutterSize="xs" alignItems="flexEnd">
						<EuiFlexItem grow={false}>
							<EuiButton
								color="text"
								className="button-fit-content plus-button"
								onClick={handleClickEdit}
								fullWidth={false}
							>
								<FontAwesomeIcon icon={faPlus} size="1x" />
							</EuiButton>
						</EuiFlexItem>
						{/* <EuiFlexItem grow={false}>
							<RASItem
								restriction={restriction}
								owners={owners}
								assignees={assignees}
								accounts={accounts}
								onSave={onSaveRAS}
							/>
						</EuiFlexItem> */}
					</EuiFlexGroup>
				</EuiFlexItem>
			</EuiFlexGroup>
		</Wrapper>
	);
};

export default CmsDraftStaticItem;

/**
 * Styled Components
 */
const Wrapper = styled.div`
	width: 100%;
	padding: var(--sp-4) var(--sp-5);
	border-bottom: solid var(--sp-px) var(--border-color);

	.badge-item {
		padding: 0 var(--sp-2);
	}

	.platform-icon {
		min-width: var(--sp-10);
		text-align: center;
		margin-right: var(--sp-4);
	}

	.direction-icon {
		svg {
			width: var(--sp-8);
			height: auto;
		}
	}

	.cms-state {
		color: var(--light-color);
		margin-top: var(--sp-3);
	}

	.cms-date {
		color: var(--light-color);
	}

	.cms-title,
	.cms-description {
		text-overflow: ellipsis;
		max-width: 300px;
		overflow: hidden;
		white-space: nowrap;
	}

	.badge-items {
		margin-top: var(--sp-2_5);
	}

	.plus-button {
		background: var(--eui-lightest-shade-color);
		border-color: var(--transparent);

		min-width: 0 !important;
		height: auto !important;
		line-height: 1 !important;

		.euiButtonContent.euiButton__content {
			padding: var(--sp-2) !important;
		}

		.euiButton__text {
			font-size: var(--sp-6);
		}

		svg {
			width: var(--sp-6);
			height: var(--sp-6);
		}
	}
`;
