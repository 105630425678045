import React from "react";
import styled from "styled-components";

/**
 * Main Components
 */
const JobSkill = (props) => {
    const { ...restProps } = props;

    return <Wrapper {...restProps}>Job Skills</Wrapper>;
};

export default JobSkill;

/**
 * Styled Components
 */
const Wrapper = styled.div``;
