/**
 * Here the name convention is the same with the name convention of the table in database.
 * So here the same name will be used.
 */
const account_main = {
	_name: "account_main",
	id: "id",
	account_title: "account_title",
	name_screen: "name_screen",
	name_first: "name_first",
	name_last: "name_last",
	global_email_details_ref_id: "global_email_details_ref_id",
	global_password_details_ref_id: "global_password_details_ref_id",
	account_type_c: "account_type_c",
	record_state_sc: "record_state_sc",
};

const account_message_link = {
	_name: "account_message_link",
	id: "id",
	original_message_ref_id: "original_message_ref_id",
	reply_message_ref_id: "reply_message_ref_id",
};

const account_message = {
	_name: "account_message",
	id: "id",
	sender_main_ref_id: "sender_main_ref_id",
	receiver_main_ref_id: "receiver_main_ref_id",
	message_details: "message_details",
	date_sent: "date_sent",
	date_read: "date_read",
};

const account_notification_subscription = {
	_name: "account_notification_subscription",
	id: "id",
	subscriber_account_main_ref_id: "subscriber_account_main_ref_id",
	object_type_c: "object_type_c",
	object_ref_id: "object_ref_id",
};

const account_sit = {
	_name: "account_sit",
	id: "id",
	account_main_ref_id: "account_main_ref_id",
	sit_main_ref_id: "sit_main_ref_id",
	sit_level_gc: "sit_level_gc",
	sit_focus_gc: "sit_focus_gc",
};

const account_service_list = {
	_name: "account_service_list",
	id: "id",
	name: "name",
	type_c: "type_c",
	details: "details",
};

const account_service_link = {
	_name: "account_service_link",
	id: "id",
	account_main_ref_id: "account_main_ref_id",
	account_service_list_ref_id: "account_service_list_ref_id",
};

const account_role_list = {
	_name: "account_role_list",
	id: "id",
	name: "name",
	type_c: "type_c",
};

const account_role_link = {
	_name: "account_role_link",
	id: "id",
	account_main_ref_id: "account_main_ref_id",
	account_role_list_ref_id: "account_role_list_ref_id",
};

const account_log_activity = {
	_name: "account_log_activity",
	id: "id",
	date_created: "date_created",
	account_main_ref_id: "account_main_ref_id",
	account_table_ref_id: "account_table_ref_id",
	log_object_c: "log_object_c",
	log_type_c: "log_type_c",
	log_action_c: "log_action_c",
	log_details: "log_details",
};

const account_activity = {
	_name: "account_activity",
	id: "id",
	account_main_ref_id: "account_main_ref_id",
	activity_type_c: "activity_type_c",
	activity_details: "activity_details",
};

const account_address = {
	_name: "account_address",
	id: "id",
	account_main_ref_id: "account_main_ref_id",
	address_type_c: "address_type_c",
	global_address_details_ref_id: "global_address_details_ref_id",
};

const account_email = {
	_name: "account_email",
	id: "id",
	account_main_ref_id: "account_main_ref_id",
	email_type_c: "email_type_c",
	global_email_details_ref_id: "global_email_details_ref_id",
};

const account_phone = {
	_name: "account_phone",
	id: "id",
	account_main_ref_id: "account_main_ref_id",
	phone_type_c: "phone_type_c",
	global_phone_details_ref_id: "global_phone_details_ref_id",
};

const account_notification = {
	_name: "account_notification",
	id: "id",
	date_created: "date_created",
	date_viewed: "date_viewed",
	notification_type_c: "notification_type_c",
	account_main_ref_id: "account_main_ref_id",
	detail_value: "detail_value",
};

const account_setting = {
	_name: "account_setting",
	id: "id",
	account_main_ref_id: "account_main_ref_id",
	setting_type_c: "setting_type_c",
	setting_value: "setting_value",
};

const account_department_list = {
	_name: "account_department_list",
	id: "id",
	name: "name",
	type_c: "type_c",
};

const account_department_link = {
	_name: "account_department_link",
	id: "id",
	account_main_ref_id: "account_main_ref_id",
	account_department_list_ref_id: "account_department_list_ref_id",
};

export {
	account_main,
	account_message_link,
	account_message,
	account_notification_subscription,
	account_sit,
	account_service_list,
	account_service_link,
	account_role_list,
	account_role_link,
	account_log_activity,
	account_activity,
	account_address,
	account_phone,
	account_email,
	account_notification,
	account_setting,
	account_department_list,
	account_department_link,
};
