import React, { useState } from "react";
import styled from "styled-components";
import {
	EuiFieldNumber,
	EuiButtonEmpty,
	EuiPopover,
	EuiFlexGroup,
	EuiFlexItem,
} from "@elastic/eui";

/**
 * Component for Editable Number
 */
const EditableNumberRange = (props) => {
	const {
		low,
		high,
		onChangeLow,
		onChangeHigh,
		prepend,
		append,
		maximumFractionDigits = 0,
	} = props;

	const [isOpenPopover, setIsOpenPopover] = useState(false);

	const handleOpenPopover = () => {
		setIsOpenPopover(true);
	};

	const handleClosePopover = () => {
		setIsOpenPopover(false);
	};

	const handleChangeLow = (e) => {
		const newLow = Number(e.target.value);
		onChangeLow(newLow);
	};

	const handleChangeHigh = (e) => {
		const newHigh = Number(e.target.value);
		onChangeHigh(newHigh);
	};

	return (
		<Wrapper>
			<EuiPopover
				button={
					<EuiButtonEmpty
						className="button-fit-content"
						onClick={handleOpenPopover}
					>
						<p className="type-x-small">
							<span>
								{prepend ? prepend : ""}
								{low
									? low.toLocaleString("en-US", {
											maximumFractionDigits: maximumFractionDigits,
									  })
									: 0}
								{append ? append : ""}
							</span>
							<span>&nbsp;-&nbsp;</span>
							<span>
								{prepend ? prepend : ""}
								{high
									? high.toLocaleString("en-US", {
											maximumFractionDigits: maximumFractionDigits,
									  })
									: 0}
								{append ? append : ""}
							</span>
						</p>
					</EuiButtonEmpty>
				}
				isOpen={isOpenPopover}
				closePopover={handleClosePopover}
				panelPaddingSize="s"
				anchorPosition="downCenter"
				panelStyle={{
					width: 107,
				}}
			>
				<EuiFlexGroup direction="column" gutterSize="xs" alignItems="center">
					<EuiFlexItem>
						<EuiFieldNumber
							placeholder="Enter low value"
							value={low}
							onChange={handleChangeLow}
							compressed
							min={0}
						/>
					</EuiFlexItem>
					<EuiFlexItem>
						<span>to</span>
					</EuiFlexItem>
					<EuiFlexItem>
						<EuiFieldNumber
							placeholder="Enter high value"
							value={high}
							onChange={handleChangeHigh}
							compressed
							min={0}
						/>
					</EuiFlexItem>
				</EuiFlexGroup>
			</EuiPopover>
		</Wrapper>
	);
};

export default EditableNumberRange;

const Wrapper = styled.div`
	display: flex;
	position: relative;
	width: 100%;
`;
