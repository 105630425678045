import React from "react";
import styled from "styled-components";

import { SVGIcon, EditAddress } from "components/Common";
import { mapMarkerAltIcon } from "components/Common/Icons";

/**
 * Main Components
 */
const PersonJobAddress = (props) => {
	const { address, onChangeAddress, ...restProps } = props;

	const handleChangeAddress = (data) => {
		onChangeAddress(data);
	};

	return (
		<Wrapper {...restProps}>
			<SVGIcon icon={mapMarkerAltIcon} className="mt-2 mr-2" />
			<EditAddress
				address={address}
				isPrimaryCheck={false}
				onChangeAddress={handleChangeAddress}
			/>
		</Wrapper>
	);
};

export default PersonJobAddress;

/**
 * Styled Components
 */
const Wrapper = styled.div`
	display: flex;

	.euiFormControlLayout {
		height: 30px;
	}
`;
