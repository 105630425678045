import React from "react";
import styled from "styled-components";

import ButtonsWrapper from "./ButtonsWrapper";

/**
 * Component for Send Button
 */
const SendButton = (props) => {
	const { onSend } = props;

	const buttons = [
		{
			label: "Send",
			callback: () => onSend(),
			icon: "",
		},
	];

	return (
		<ButtonWrapper>
			<ButtonsWrapper buttons={buttons} color="secondary" />
		</ButtonWrapper>
	);
};

export default SendButton;

const ButtonWrapper = styled.div`
	margin-left: auto;
	min-width: 74px;
	height: var(--sp-12);
`;
