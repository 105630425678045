import {
	SET_MESSAGES,
	REMOVE_MESSAGES,
	ADD_MESSAGE,
	SET_MESSAGE_CHATBOX,
	SET_RELATED_SELECTED_OPTIONS,
	SET_MESSAGING_PERSON_FLYOUT_VISIBLE,
	SET_SELECTED_CREDENTIAL_ID,
} from "./actionTypes";
import API from "api";
import { objectChecker } from "helpers/utilities";
import Service from "core/service";

export const setMessagingPersonFlyoutVisible = (data) => {
	return {
		type: SET_MESSAGING_PERSON_FLYOUT_VISIBLE,
		payload: data,
	};
};

export const setRelatedSelectedOptions = (data) => {
	return {
		type: SET_RELATED_SELECTED_OPTIONS,
		payload: data,
	};
};

export const setMessages = (data) => {
	return {
		type: SET_MESSAGES,
		payload: data,
	};
};

export const removeMessages = (data) => {
	return {
		type: REMOVE_MESSAGES,
		payload: data,
	};
};

export const setSelectedCredentialId = (data) => {
	return {
		type: SET_SELECTED_CREDENTIAL_ID,
		payload: data,
	};
};

export const sendLinkedInMessage = (
	credentialId,
	personReferenceId,
	message,
	successCallback,
	failCallback
) => {
	return async (dispatch) => {
		try {
			const params = {
				credentialId,
				person_reference_id: personReferenceId,
				message,
			};

			const result = await API().Messaging().sendLinkedinMessage(params);

			if (successCallback) {
				successCallback(result);
			}
		} catch (err) {
			if (failCallback) {
				failCallback(err);
			}
		}
	};
};

export const addMessage = (data) => {
	return {
		type: ADD_MESSAGE,
		payload: data,
	};
};

export const setMessageChatbox = (data) => {
	return {
		type: SET_MESSAGE_CHATBOX,
		payload: data,
	};
};

export const getCMSConversationMessages = (personId) => {
	return async (dispatch) => {
		try {
			const result = await API().Messaging().getCMSConversationMessages({
				person_id: personId,
			});

			let messages = objectChecker(result, ["data", "messages"]);

			if (messages[0]) {
				messages = messages[0];

				// Format these data
				const container = [];

				for (let i in messages) {
					let {
						conversation_main_id: conversationMainId,
						message_details_id: messageDetailsId,
						message_details: messageDetails,
						message_timestamp: messageTimestamp,
						messenger_type: messengerType,
						member_name: memberName,
						message_type: messageType,
						avatar,
					} = messages[i];

					messageDetails = JSON.parse(messageDetails)["message"];

					container.push({
						message_id: messageDetailsId,
						channel: messageType === "message" ? "linkedIn" : "",
						sender: "test@test.com",
						cc: ["melissa@gmail.com", "charlene@gmail.com"],
						recipients: ["steven@gmail.com"],
						subject: "FW Testing Emails",
						message: messageDetails,
						messenger_type: messengerType,
						comments: [],
						tags: [],
						timestamp: messageTimestamp,
					});
				}

				dispatch(setMessages(container));
			}
		} catch (err) {
			// will do later. (CMS is not implemented yet.)
			// alert("Something went wrong when trying to fetch the person RAS: " + JSON.stringify(err));
		}
	};
};

export const actionCmsLinkedInMessage = (params, successCallback, failCallback) => {
	return async (dispatch) => {
		const {
			credentialId: credentialMainId,
			message,
			title,
			name,
			profileURL,
		} = params;

		try {
			const queryParams = {
				credentialMainId,
				profileURL,
				message,
				safe: true,
				name,
				title,
			};

			const result = await API().Messaging().actionCmsLinkedinMessage(queryParams);

			if (successCallback) {
				successCallback(result);
			}
		} catch (err) {
			if (failCallback) {
				failCallback(err);
			}
		}
	};
};

export const actionSendLinkedinConnectionRequest = (
	params,
	successCallback,
	failCallback
) => {
	return async (dispatch) => {
		const {
			credentialId: credentialMainId,
			message,
			recipient,
			title,
			name,
			profileURL,
		} = params;

		try {
			const queryParams = {
				credentialMainId,
				profileURL,
				connectionMessage: message,
				emailAddressOfRecipient: recipient,
				safe: true,
				name,
				title,
			};

			const result = await API()
				.Messaging()
				.actionSendLinkedinConnectionRequest(queryParams);

			if (successCallback) {
				successCallback(result);
			}
		} catch (err) {
			if (failCallback) {
				failCallback(err);
			}
		}
	};
};

export const sendEmailO365Message = (params, successCallback, failCallback) => {
	return async (dispatch) => {
		const { credentialId: credential_main_ref_id, message } = params;

		try {
			const queryParams = {
				credential_main_ref_id,
				message,
			};

			const result = await API().Messaging().sendCmsEmailO365Message(queryParams);

			if (successCallback) {
				successCallback(result);
			}
		} catch (err) {
			if (failCallback) {
				failCallback(err);
			}
		}
	};
};

export const saveEmailO365Message = (params, successCallback, failCallback) => {
	return async (dispatch) => {
		try {
			const result = await Service.cms.updateConversation(params);
			if (successCallback) {
				successCallback(result);
			}
		} catch (err) {
			if (failCallback) {
				failCallback(err);
			}
		}
	};
};
export const sendSmsMessage = (params, successCallback, failCallback) => {
	return async (dispatch) => {
		const { sender, receiver, message } = params;

		try {
			const queryParams = {
				sender,
				receiver,
				message,
			};

			const result = await API().Messaging().sendCmsSms(queryParams);

			if (successCallback) {
				successCallback(result);
			}
		} catch (err) {
			if (failCallback) {
				failCallback(err);
			}
		}
	};
};

export const createMeetingEvent = (params, successCallback, failCallback) => {
	return async (dispatch) => {
		const { credentialId: credential_main_ref_id, event } = params;

		try {
			const queryParams = {
				credential_main_ref_id,
				event,
			};

			const result = await API().Messaging().creatMeetingEvent(queryParams);

			if (successCallback) {
				successCallback(result);
			}
		} catch (err) {
			if (failCallback) {
				failCallback(err);
			}
		}
	};
};
