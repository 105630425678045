import {
	socialUnknownIcon,
	socialLinkedInIcon,
	socialFacebookIcon,
	socialTwitterIcon,
} from "components/Common/Icons/Icons";

export const LINK_TYPE_OPTIONS = [
	{
		value: 1,
		label: "generic",
		icon: socialUnknownIcon,
		inputDisplay: socialUnknownIcon(),
	},
	{
		value: 2,
		label: "linkedin-profile",
		icon: socialLinkedInIcon,
		inputDisplay: socialLinkedInIcon(),
	},
	{
		value: 3,
		label: "linkedin-avatar",
		icon: socialLinkedInIcon,
		inputDisplay: socialLinkedInIcon(),
	},
	{
		value: 4,
		label: "personal-site",
		icon: socialFacebookIcon,
		inputDisplay: socialFacebookIcon(),
	},
	{
		value: 5,
		label: "business-site",
		icon: socialTwitterIcon,
		inputDisplay: socialTwitterIcon(),
	},
	{
		value: 6,
		label: "linkedin-profile-salesnav",
		icon: socialLinkedInIcon,
		inputDisplay: socialLinkedInIcon(),
	},
];
