import React from "react";
import { EuiFlexGroup, EuiFlexItem } from "@elastic/eui";

import { CompanyPersonHeaderDisplay, RASItem } from "components/Common";

import PersonNamePopoverEdit from "./PersonNamePopoverEdit";

/**
 * Main component for Personal Information Header
 */
function PersonalInformationHeader(props) {
	const {
		personId,
		accounts,
		onSaveRAS,
		onSaveQualifier,
		personRasRestrictions,
		assignees,
		owners,
		linkedInAvatar,
		firstName,
		nickName,
		middleName,
		lastName,
		editable,
		details,
		globals,
	} = props;

	const restriction = personRasRestrictions?.[0];

	let description = "";

	if (firstName !== false) {
		description =
			firstName +
			` ${nickName ? `(${nickName})` : ""} ${middleName || ""} ` +
			lastName;
	}

	// This displays a clickable icon based on the state if it is a favourite or not,
	// and it is also wrapped on the popover icon
	const editButton = () => {
		return <PersonNamePopoverEdit _personDetails={details} />;
	};

	const handleSaveRAS = (data) => {
		onSaveRAS(data);
	};

	return (
		<EuiFlexGroup id="personal-information-header" gutterSize="s">
			<EuiFlexItem>
				<CompanyPersonHeaderDisplay
					description={description}
					avatarLink={linkedInAvatar}
					// favourite={editButton()}
					editable={editable}
					recordId={personId}
					firstName={firstName}
					middleName={middleName}
					lastName={lastName}
					nickName={nickName}
					globals={globals}
					details={details}
					onSave={onSaveQualifier}
				/>
			</EuiFlexItem>
			<EuiFlexItem grow={false}>
				<RASItem
					restriction={restriction}
					owners={owners}
					assignees={assignees}
					recordId={personId}
					recordTypeC={1}
					accounts={accounts}
					onSave={handleSaveRAS}
				/>
			</EuiFlexItem>
		</EuiFlexGroup>
	);
}

export default PersonalInformationHeader;
