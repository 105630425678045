import React, { useMemo } from "react";
import styled from "styled-components";
import { get } from "lodash";

import AccordionWithHeader from "components/Common/AccordionWithHeader";
import { useMessage } from "core/useHooks";

import MessageHeader from "./MessageHeader";
import MessagePanel from "./MessagePanel";

/**
 * Main Components
 */
const MessageFlyoutBody = (props) => {
	const { isLoading = false, person, params } = props;

	const { getMessagesForPerson } = useMessage();

	const { messages } = useMemo(() => {
		let messages = [];
		const personId = get(person, "id");
		if (personId) {
			messages = getMessagesForPerson(personId);
		}

		return {
			messages,
		};
	}, [person, getMessagesForPerson]);

	return (
		<Wrapper>
			<AccordionWithHeader
				headerItems={
					<MessageHeader title="Messaging" count={messages?.length || 0} />
				}
				isLoading={isLoading}
				isOpen={isLoading ? "closed" : "open"}
			>
				<MessagePanel person={person} params={params} />
			</AccordionWithHeader>
		</Wrapper>
	);
};

export default MessageFlyoutBody;

/**
 * Styled Components
 */
const Wrapper = styled.div`
	.euiAccordion-isOpen {
		.euiAccordion__childWrapper {
			overflow: unset;
		}
	}
`;
