/**
 * Constants For Compensation Plan
 */
export const WORK_TYPES = {
	perm: { key: "permanent", label: "Perm", id: 1 },
	contract: { key: "contract", label: "Contract", id: 2 },
	contractToHire: { key: "contract-to-hire", label: "Contract to Hire", id: 3 },
};

export const WILL_SPONSOR_TRANSFER = {
	willSponsor: { key: "willSponsor", label: "Will Sponsor/Transfer Visa" },
	willNotSponsor: {
		key: "willNotSponsor",
		label: "Will Not Sponsor/Transfer Visa",
	},
};

export const JOB_SCREENING_SPONSOR_TYPES = {
	h1b: { key: "h1b", label: "H1-B" },
	l1: { key: "l1", label: "L1" },
	l2: { key: "l2", label: "L2" },
	ead: { key: "ead", label: "EAD" },
	h4ead: { key: "h4ead", label: "H4EAD" },
	tn: { key: "tn", label: "TN" },
	other: { key: "other", label: "Other" },
};

export const COMPENSATION_EDIT_STATUES = {
	none: { key: "none", value: "None", color: "danger" },
	edit: { key: "edit", value: "Edit", color: "warning" },
	complete: { key: "complete", value: "Complete", color: "secondary" },
};

export const COMPENSATION_SUMMARY_ITEMS = {
	summary: { key: "summary", label: "Summary" },
	title: { key: "title", label: "Title" },
	skill: { key: "skill", label: "Skills" },
	industry: { key: "industry", label: "Industries" },
	workType: { key: "workType", label: "Work Type" },
	payStructure: { key: "payStructure", label: "Pay Structure" },
	benefit: {
		key: "benefit",
		label: "Benefit",
		items: {
			timeOff: { key: "timeOff", label: "" },
			health: { key: "health", label: "" },
			financial: { key: "financial", label: "" },
		},
	},
	location: {
		key: "locaction",
		label: "Location",
		items: {
			state: { key: "state", label: "" },
			city: { key: "city", label: "" },
			zipCode: { key: "zipCode", label: "" },
		},
	},
	other: {
		key: "other",
		label: "Other",
		items: {
			state: { key: "state", label: "" },
			city: { key: "city", label: "" },
			zipCode: { key: "zipCode", label: "" },
		},
	},
};

export const RATING_INTEREST_TYPES = {
	decline: { key: "decline", label: "Decline", value: 0 },
	considering: { key: "considering", label: "Considering", value: 1 },
	proceed: { key: "proceed", label: "Proceed", value: 2 },
};

export const RATING_INTEREST_LEVEL_ICONS = {
	minus: { key: "minus", id: 0, color: "#BD271E" },
	plus: { key: "plus", id: 1, color: "#017D73" },
};

export const RATING_INTEREST_LEVEL_STATES = {
	0: {
		value: 0,
		label: "Below Average Interest",
		className: "below-average-interest",
		icon: RATING_INTEREST_LEVEL_ICONS.minus.key,
		icons: 2,
	},
	1: {
		value: 1,
		label: "Average Interest",
		className: "average-interest",
		icon: RATING_INTEREST_LEVEL_ICONS.minus.key,
		icons: 1,
	},
	2: { value: 2, label: "No Interest", className: "no-interest" },
	3: {
		value: 3,
		label: "Above Average Interest",
		className: "above-average-interest",
		icon: RATING_INTEREST_LEVEL_ICONS.plus.key,
		icons: 1,
	},
	4: {
		value: 4,
		label: "Exceptional Interest",
		className: "exceptional-interest",
		icon: RATING_INTEREST_LEVEL_ICONS.plus.key,
		icons: 2,
	},
};
