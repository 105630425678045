import API from "api";
import Service from "core/service";

import * as actionTypes from "./actionTypes";
import { getResultFromApiResponse, objectChecker } from "helpers/utilities";
import { logError } from "../error";
import { get } from "lodash";
import { object } from "prop-types";
import { STANDARD_CNT_PER_PAGE } from "components/global/constants";

//////////////////////////////////////////////////////////////////////////////////////////////////////////

export const setPeopleSourcingList = (data, setType = null) => {
	return {
		type: actionTypes.GET_PEOPLE_LIST_BY_SOURCING_SEARCH_ID,
		payload: {
			data: data,
			totalCount: data.length,
			setType,
		},
	};
};

export const getSourcingPeopleList = (params, caller) => {
	return async (dispatch) => {
		try {
			if (!params.filters.sourcing_process_id) {
				delete params.filters.sourcing_process_id;
			}

			const result = await API().Sourcing().getSourcingPeopleList(params);

			const data = objectChecker(result, ["data", "people_list"]);
			const totalCount = objectChecker(result, ["data", "total_count"]);
			const sourcingPeopleListData = {
				data: data || [],
				totalCount: totalCount || 0,
			};

			dispatch({
				type: actionTypes.GET_PEOPLE_LIST_BY_SOURCING_SEARCH_ID,
				payload: sourcingPeopleListData,
			});
		} catch (err) {
			logError("fetch_failure", "none", {
				Error: "Error fetching sourcing people data: " + JSON.stringify(err),
			});
			//alert('Error fetching sourcing people data: ' + JSON.stringify(err))
		}
	};
};

export const getCredentialList = (params) => {
	return async (dispatch) => {
		try {
			const _params = {
				page_number: params?.page || 1,
				page_count: params?.per_page || 1000,
			};
			const result = await API().Credential().getCredentialList(_params);

			const data = get(result, "response.data");

			if (data) {
				dispatch({
					type: actionTypes.GET_ALL_SOURCING_CREDENTIAL,
					payload: data,
				});
			}
		} catch (err) {
			logError("fetch_failure", "none", {
				Error: "Error fetching sourcing credential data: " + JSON.stringify(err),
			});

			//alert('Error fetching sourcing credential data: ' + JSON.stringify(err))
		}
	};
};

export const setSourcingId = (id) => {
	return async (dispatch) => {
		try {
			dispatch({
				type: actionTypes.SET_SOURCING_ID,
				payload: id,
			});
		} catch (err) {
			logError("fetch_failure", "none", {
				Error: "Error fetching sourcing id data: " + JSON.stringify(err),
			});
			//alert('Error fetching sourcing id data: ' + JSON.stringify(err))
		}
	};
};

export const setSourcingEditFlyout = (params) => {
	return async (dispatch) => {
		try {
			dispatch({
				type: actionTypes.SET_SOURCING_EDIT_FLYOUT,
				payload: params,
			});
		} catch (err) {
			logError("fetch_failure", "none", {
				Error:
					"Error fetching edit flyout sourcing id data: " + JSON.stringify(err),
			});
			//alert('Error fetching sourcing id data: ' + JSON.stringify(err))
		}
	};
};

export const setSourcingSearchId = (id) => {
	console.log("id: ", id);
	return async (dispatch) => {
		try {
			dispatch({
				type: actionTypes.SET_SOURCING_SEARCH_ID,
				payload: id,
			});
		} catch (err) {
			logError("fetch_failure", "none", {
				Error: "Error fetching sourcing search id data: " + JSON.stringify(err),
			});
			//alert('Error fetching sourcing search id data: ' + JSON.stringify(err))
		}
	};
};

export const createSourcing = (params, successCallback, failCallback) => {
	return async (dispatch) => {
		try {
			const result = await Service.sourcing.updateSourcing(params);

			if (result) {
				dispatch({
					type: actionTypes.SAVE_SOURCING,
					payload: result.data,
				});
			}
			if (!objectChecker(result, ["error"])) {
				if (successCallback) {
					const res = getResultFromApiResponse(result);
					successCallback(res?.procedure_results);
				}
			}
		} catch (err) {
			logError("failure", "none", {
				Error: "Error create Sourcing: " + JSON.stringify(err),
			});
			if (failCallback) {
				failCallback(err);
			}
		}
	};
};

export const createSourcingDraft = (params) => {
	return async (dispatch) => {
		try {
			const result = await API().Sourcing().createSourcingDraft(params);

			if (result) {
				dispatch({
					type: actionTypes.CREATE_SOURCING_DRAFT,
					payload: result.data,
				});
			}
		} catch (err) {
			logError("failure", "none", {
				Error: "Error create Sourcing draft: " + JSON.stringify(err),
			});
			//alert('Error Add create Sourcing draft: ' + JSON.stringify(err))
		}
	};
};

export const addURLSourcingSearch = (url) => {
	return async (dispatch) => {
		try {
			dispatch({
				type: actionTypes.ADD_URL_SOURCING_SEARCH,
				payload: url,
			});
		} catch (err) {
			logError("failure", "none", {
				Error: "Error adding sourcing URL search data: " + JSON.stringify(err),
			});
			//alert('Error adding sourcing search data: ' + JSON.stringify(err))
		}
	};
};

export const removeURLSourcingSearch = (id) => {
	return async (dispatch) => {
		try {
			dispatch({
				type: actionTypes.REMOVE_URL_SOURCING_SEARCH,
				payload: id,
			});
		} catch (err) {
			logError("failure", "none", {
				Error: "Error removing sourcing URL search data: " + JSON.stringify(err),
			});
		}
	};
};

export const deleteSourcing = (params) => {
	return async (dispatch) => {
		try {
			const result = await Service.sourcing.deleteSourcing(params);

			if (result) {
				dispatch({
					type: actionTypes.DELETE_SOURCING,
					payload: result,
				});
			}
		} catch (err) {
			logError("failure", "none", {
				Error: "Error delete Sourcing: " + JSON.stringify(err),
			});
			//alert('Error delete Sourcing: ' + JSON.stringify(err))
		}
	};
};

export const runSourcingProcess = (params) => {
	return async (dispatch) => {
		try {
			const result = await API().Sourcing().runSourcingProcess(params);
			if (result) {
				dispatch({
					type: actionTypes.RUN_SOURCING_NEW,
					payload: result,
				});
			}
		} catch (err) {
			logError("failure", "none", {
				Error: "Error run Sourcing:  " + JSON.stringify(err),
			});
		}
	};
};

export const setActivePage = (page) => {
	return async (dispatch) => {
		dispatch({
			type: actionTypes.SET_ACTIVE_PAGE,
			payload: page,
		});
	};
};

export const setSourcingFilter = (filter) => {
	return async (dispatch) => {
		dispatch({
			type: actionTypes.SET_SOURCING_FILTER,
			payload: filter,
		});
	};
};

export const setSourcingCustomFilter = (key, value) => {
	return async (dispatch) => {
		dispatch({
			type: actionTypes.SET_SOURCING_CUSTOM_FILTER,
			payload: {
				key,
				value,
			},
		});
	};
};

export const setSourcingSearchValue = (value) => {
	return async (dispatch) => {
		dispatch({
			type: actionTypes.SET_SOURCING_SEARCH_VALUE,
			payload: value,
		});
	};
};

export const updateSourcingHiddenItems = (values) => {
	return async (dispatch) => {
		dispatch({
			type: actionTypes.SET_SOURCING_HIDDEN_ITEMS,
			payload: values,
		});
	};
};

export const getSourcingSelectPopoverOptions = (id) => {
	return async (dispatch) => {
		try {
			const result = await null;
			/** ---------------------------------------------------------------------------------- */

			const data = objectChecker(result, ["data"]);

			if (data) {
				dispatch({
					type: actionTypes.GET_SOURCING_SELECT_POPOVER_OPTIONS,
					payload: data,
				});
			}
		} catch (err) {
			logError("fetch_failure", "none", {
				Error:
					"Error fetching sourcing select popover options data: " +
					JSON.stringify(err),
			});
		}
	};
};

export const setSourcingSelectedPopoverOption = (payload) => {
	return async (dispatch) => {
		dispatch({
			type: actionTypes.SET_SOURCING_SELECTED_POPOVER_OPTION,
			payload: payload,
		});
	};
};

export const setSourcingProcessId = (payload) => {
	return async (dispatch) => {
		dispatch({
			type: actionTypes.SET_SOURCING_PROCESS_ID,
			payload: payload,
		});
	};
};

export const setSourcingPerPage = (payload) => {
	return async (dispatch) => {
		dispatch({
			type: actionTypes.SET_SOURCING_PER_PAGE,
			payload: payload,
		});
	};
};

export const bulkEnrichRecords = (payload, successCallback, failCallback) => {
	return async (dispatch) => {
		try {
			const result = await API().Sourcing().bulkEnrichRecords(payload);

			if (result) {
				if (successCallback) {
					successCallback(result);
				}
			}
		} catch (err) {
			if (failCallback) {
				failCallback(err);
			}
		}
	};
};

/** ======================= NEW ==================== */
export const removeSourcingUrlSearch = () => {
	return async (dispatch) => {
		dispatch({
			type: actionTypes.REMOVE_SOURCING_URL_SEARCH,
		});
	};
};

export const setSourcingFlyout = (params) => {
	return async (dispatch) => {
		try {
			dispatch({
				type: actionTypes.SET_SOURCING_FLYOUT,
				payload: params,
			});
		} catch (err) {
			logError("fetch_failure", "none", {
				Error: "Error fetching sourcing flyout data: " + JSON.stringify(err),
			});
		}
	};
};

/**
 * Save sourcing main list data into store
 */
const setSourcingMainList_NEW = (data) => {
	return {
		type: actionTypes.SET_SOURCING_MAINLIST_NEW,
		payload: data,
	};
};

/**
 * Save sourcing list data into store
 */
const setSourcingList_NEW = (data) => {
	return {
		type: actionTypes.SET_SOURCING_LIST_NEW,
		payload: data,
	};
};

const requestSourcingList_NEW = () => {
	return {
		type: actionTypes.REQUEST_SOURCING_LIST_NEW,
	};
};

export const setSourcingListParams_NEW = (data) => {
	return {
		type: actionTypes.SET_SOURCING_LIST_PARAMS_NEW,
		payload: data,
	};
};

/**
 * Get Sourcing List
 *
 * @param {*} params
 * @param {*} successCallback
 * @param {*} failCallback
 */
export const getSourcingList_NEW = (params, successCallback, failCallback) => {
	return async (dispatch, getState) => {
		dispatch(requestSourcingList_NEW());

		const state = getState();
		const curSourcingList = state.sourcing.sourcingList_NEW;

		const _params = {
			page_number: params?.page_number ?? curSourcingList.page_number ?? 1,
			page_count: params?.page_count ?? curSourcingList.page_count ?? 30,
			search_term: params?.search_term ?? curSourcingList?.search_term ?? undefined,
			// sourcing_owner_account_id: accountId, // blocked in the development mode
		};

		const sortInfo = params?.sort ?? curSourcingList.sort;

		const payload = {
			page_number: _params.page_number,
			page_count: _params.page_count,
			totalCount: 0,
			data: [],
			search_term: _params.search_term,
			// sourcing_owner_account_id: _params.sourcing_owner_account_id, // blocked in the development mode
		};

		if (sortInfo?.length > 0) {
			_params.sort = sortInfo;
			payload.sort = sortInfo;
		}

		try {
			const result = await Service.sourcing.getSourcingList(_params);
			const data = objectChecker(result, ["response", "data"]);
			const totalCount = objectChecker(result, ["response", "count_total"]);

			if (data) {
				const sourcings = data?.map((item) => ({
					...item?.sourcing_main,
					count_total: item?.count_total,
					count_created: item?.count_created,
					count_duplicate: item?.count_duplicate,
				}));
				payload.data = sourcings;
				payload.totalCount = totalCount || 0;

				if (successCallback) {
					successCallback();
				}
			}
		} catch (err) {
			dispatch(
				logError("fetch_failure", "none", {
					Error: "Error fetching sourcing data: " + JSON.stringify(err),
				})
			);

			if (failCallback) {
				failCallback();
			}
		} finally {
			dispatch(setSourcingList_NEW(payload));
		}
	};
};

/**
 * Save sourcing peolpe list into store
 */
const setSourcingPeopleList_NEW = (data) => {
	return {
		type: actionTypes.SET_SOURCING_PEOPLE_LIST_NEW,
		payload: data,
	};
};

const requestSourcingPeopleList_NEW = () => {
	return {
		type: actionTypes.REQUEST_SOURCING_PEOPLE_LIST_NEW,
	};
};

export const setSourcingPeopleListParams_NEW = (data) => {
	return {
		type: actionTypes.SET_SOURCING_PEOPLE_LIST_PARAMS_NEW,
		payload: data,
	};
};

/**
 * Get Sourcing People List
 *
 * @param {*} params
 * @param {*} successCallback
 * @param {*} failCallback
 */
export const getSourcingPeopleList_NEW = (params, successCallback, failCallback) => {
	return async (dispatch, getState) => {
		const state = getState();
		const curSourcingPeopleList = state.sourcing.sourcingPeopleList_NEW;

		if (curSourcingPeopleList.isRequesting) return;

		dispatch(requestSourcingPeopleList_NEW());

		// check if the first calling for person list or next page
		let isFirstCalling = true;
		if (curSourcingPeopleList?.sourcing_main_id !== 0) {
			if (
				!params ||
				params?.sourcing_main_id === curSourcingPeopleList?.sourcing_main_id
			) {
				isFirstCalling = false;
			}
		}

		const processed_result_c =
			params?.processed_result_c ??
			curSourcingPeopleList.processed_result_c ??
			null;
		const _params = {
			sourcing_main_id:
				params?.sourcing_main_id ?? curSourcingPeopleList.sourcing_main_id ?? 0,
			page_number: params?.page_number ?? curSourcingPeopleList.page_number ?? 1,
			page_count: params?.page_count ?? curSourcingPeopleList.page_count ?? 10, // STANDARD_CNT_PER_PAGE,
			count_total: params?.page_count ?? curSourcingPeopleList.page_count ?? 0,
			processed_result_c: processed_result_c === 0 ? null : processed_result_c,
			filters: params?.filters ?? curSourcingPeopleList.filters ?? {},
			search_term: params?.search_term ?? curSourcingPeopleList.search_term ?? "",
		};

		const sortInfo = params?.sort ?? curSourcingPeopleList.sort;

		const payload = {
			sourcing_main_id: _params.sourcing_main_id,
			page_number: _params.page_number,
			page_count: _params.page_count,
			count_total: _params.count_total,
			processed_result_c: _params.processed_result_c,
			data: [],
			filters: _params.filters,
			search_term: _params?.search_term,
			ids: [],
		};

		if (sortInfo?.length > 0) {
			_params.sort = sortInfo;
			payload.sort = sortInfo;
		}

		try {
			const result = await Service.sourcing.getSourcingPeopleList(_params);
			let idsRes = [];
			if (isFirstCalling) {
				idsRes = await Service.sourcing.getSourcingPeopleIdList(_params);
			}

			const data = objectChecker(result, ["response", "data"]);
			const ids = objectChecker(idsRes, ["response"], []);

			if (data) {
				payload.data = data;
				payload.count_total = objectChecker(result, ["response", "count_total"]);
				payload.ids = ids;

				if (successCallback) {
					successCallback();
				}
			}
		} catch (err) {
			dispatch(
				logError("fetch_failure", "none", {
					Error: "Error fetching sourcing people data: " + JSON.stringify(err),
				})
			);

			if (failCallback) {
				failCallback();
			}
		} finally {
			dispatch(setSourcingPeopleList_NEW(payload));
		}
	};
};

/**
 * Save sourcing detail data into store
 */
const setSourcingDetail_NEW = (data) => {
	return {
		type: actionTypes.SET_SOURCING_DETAIL_NEW,
		payload: data,
	};
};

/**
 * Get Sourcing Detail data with ID
 *
 * @param {*} id - Sourcing ID
 * @returns
 */
export const getSourcingDetail_NEW = (id = 0) => {
	return async (dispatch) => {
		let detail = {};
		try {
			const result = await Service.sourcing.getSourcingDetail({ id });

			if (!objectChecker(result, ["error"])) {
				const res = result?.data?.[0]?.sourcing_main;
				detail = res;
			}
		} finally {
			dispatch(setSourcingDetail_NEW(detail));
		}
	};
};

const setSourcingProcessProgress = (data) => {
	return {
		type: actionTypes.GET_SOURCING_PROCESS_PORGRESS,
		payload: data,
	};
};

/**
 * Get sourcing process progress
 */
export const getSourcingProcessProgress = (params, successCallback, failCallback) => {
	return async (dispatch) => {
		try {
			const result = await Service.sourcing.getSourcingProcessProgress(params);

			const message = objectChecker(result, ["response", "message"]);
			dispatch(setSourcingProcessProgress(message));

			if (message) {
				if (successCallback) {
					successCallback({ message });
				}
			} else {
				if (failCallback) {
					failCallback({ error: "Unknown Error" });
				}
			}
		} catch (err) {
			console.log("err: ", err);

			dispatch(
				logError("fetch_failure", "none", {
					Error: "Error get sourcing process progress: " + JSON.stringify(err),
				})
			);
			dispatch(setSourcingProcessProgress(null));
			if (failCallback) {
				failCallback({ error: "Unknown Error" });
			}
		}
	};
};
