import {
	CREATE_ACCOUNT,
	GET_ACCOUNT_DETAIL,
	SET_ACCOUNT_SETTINGS,
	SET_ACCOUNTS,
	SET_ACCOUNTLIST,
	UPDATE_ACCOUNT_FIELD,
	UPDATE_ACCOUNT_PASSWORD,
	GET_ACCOUNT_LOG_ACTIVITIES,
	FETCH_ACCOUNT_CAMPAIGN_DEFAULTS,
	SET_ACCOUNT_CAMPAIGN_DEFAULTS,
	SET_ACCOUNT_AMS_LIST,
	REQUEST_ACCOUNT_AMS_LIST,
	RECEIVED_ACCOUNT_AMS_LIST,
	SET_ACCOUNT_AMS_LIST_PARAMS,
} from "./actionTypes";

const setAccountSettings = (state, payload) => {
	return {
		...state,
		accountSettings: payload,
	};
};

const setAccounts = (state, payload) => {
	return {
		...state,
		accounts: [...payload],
	};
};

const setAccountList = (state, payload) => {
	return {
		...state,
		accountList: [...payload],
	};
};

const getAccountDetail = (state, payload) => {
	return {
		...state,
		accountDetail: payload,
	};
};

const createAccount = (state, payload) => {
	return {
		...state,
		createAccount: payload,
	};
};

const updateAccountField = (state, payload) => {
	return {
		...state,
		updateAccount: payload,
	};
};

const updateAccountPassword = (state, payload) => {
	return {
		...state,
		updateAccountPassword: payload,
	};
};

const getAccountLogsActivity = (state, payload) => {
	return {
		...state,
		accountLogs: payload,
	};
};

const getAccountCampaignDefaults = (state, payload) => {
	return {
		...state,
		accountCampaignDefaults: payload,
	};
};

const setDataAccountCampaignDefaults = (state, payload) => {
	return {
		...state,
		setAccountCampaignDefaults: payload,
	};
};

// Account AMS Table
const requestAccountAmsList = (state) => {
	return {
		...state,
		accountAmsList: {
			...state.accountAmsList,
			isRequesting: true,
			isReceived: false,
		},
	};
};

const receivedAccountAmsList = (state) => {
	return {
		...state,
		accountAmsList: {
			...state.accountAmsList,
			isRequesting: false,
			isReceived: true,
		},
	};
};

const setAccountAmsList = (state, payload) => {
	return {
		...state,
		accountAmsList: {
			...state.accountAmsList,
			...payload,
			isRequesting: false,
			isReceived: true,
		},
	};
};

const setAccountAmsListParams = (state, payload) => {
	return {
		...state,
		accountAmsList: {
			...state.accountAmsList,
			...payload,
		},
	};
};

const initialState = {
	accounts: [],
	accountList: [],
	loading: false,
	accountDetail: {},
	createAccount: {},
	updateAccount: {},
	updateAccountPassword: {},
	accountSettings: null,
	accountLogs: [],
	accountCampaignDefaults: [],
	setAccountCampaignDefaults: {},
	accountAmsList: {
		totalCount: 0,
		pageCount: 1000,
		pageNumber: 1,
		isRequesting: false,
		isReceived: false,
		data: [],
		filters: {
			amsAssignedAccountId: null,
		},
	},
};

const Reducer = (state = initialState, action) => {
	switch (action.type) {
		case SET_ACCOUNTS:
			return setAccounts(state, action.payload);
		case SET_ACCOUNTLIST:
			return setAccountList(state, action.payload);
		case GET_ACCOUNT_DETAIL:
			return getAccountDetail(state, action.payload);
		case CREATE_ACCOUNT:
			return createAccount(state, action.payload);
		case UPDATE_ACCOUNT_FIELD:
			return updateAccountField(state, action.payload);
		case UPDATE_ACCOUNT_PASSWORD:
			return updateAccountPassword(state, action.payload);
		case SET_ACCOUNT_SETTINGS:
			return setAccountSettings(state, action.payload);
		case GET_ACCOUNT_LOG_ACTIVITIES:
			return getAccountLogsActivity(state, action.payload);
		case FETCH_ACCOUNT_CAMPAIGN_DEFAULTS:
			return getAccountCampaignDefaults(state, action.payload);
		case SET_ACCOUNT_CAMPAIGN_DEFAULTS:
			return setDataAccountCampaignDefaults(state, action.payload);
		/* ------- Account AMS Table ------- */
		case SET_ACCOUNT_AMS_LIST:
			return setAccountAmsList(state, action.payload);

		case REQUEST_ACCOUNT_AMS_LIST:
			return requestAccountAmsList(state);

		case RECEIVED_ACCOUNT_AMS_LIST:
			return receivedAccountAmsList(state);

		case SET_ACCOUNT_AMS_LIST_PARAMS:
			return setAccountAmsListParams(state, action.payload);
		default: {
			return state;
		}
	}
};

export default Reducer;
