import {
	REMOVE_NOTIFICATION,
	SET_NOTIFICATION,
	PROGRESS_NOTIFICATION,
} from "./actionTypes";

export const setNotification = (data) => {
	return {
		type: SET_NOTIFICATION,
		payload: data,
	};
};

export const removeNotification = (data) => {
	return {
		type: REMOVE_NOTIFICATION,
		payload: data,
	};
};

export const setProgressNotification = (data) => {
	return {
		type: PROGRESS_NOTIFICATION,
		payload: data,
	};
};
