import * as actionTypes from "./actionTypes";

/**
 * Functions
 */

/** --------------------------- */

/**
 * Initial State
 */
const initialState = {};

/**
 * Reducer
 */
const Reducer = (state = initialState, action) => {
	switch (action.type) {
		default:
			return state;
	}
};

export default Reducer;
