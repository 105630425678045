import React, { useEffect, useState, useMemo } from "react";
import { EuiComboBox, EuiFormRow, EuiSelect } from "@elastic/eui";
import { size, clone } from "lodash";
import styled from "styled-components";

import { SaveCancelBtn } from "components/Common";
import { getAddDeleteListFromRecords } from "components/global/utils";

const restrictionTypeOptions = [
	{
		value: 0,
		text: "None",
	},
	{
		value: 1,
		text: "Creator",
	},
	{
		value: 2,
		text: "Owner Assigned",
	},
];

/**
 * Component for Editable RAS
 */
const EditableRAS = (props) => {
	const { accountsHash, ownersHash, assigneesHash, restriction, onCancel, onSave } =
		props;

	const [assigneesSelected, setAssigneesSelected] = useState([]);
	const [ownersSelected, setOwnersSelected] = useState([]);
	const [restrictionType, setRestrictionType] = useState(
		restrictionTypeOptions[0].value
	);

	const optionsAccount = useMemo(() => {
		const options = [];
		if (accountsHash && size(accountsHash) > 0) {
			Object.entries(accountsHash).forEach(([id, name]) => {
				if (id) {
					options.push({
						label: name,
						id: id,
						value: id,
					});
				}
			});
		}

		return options;
	}, [accountsHash]);

	const onOwnerChange = (selectedOptions) => {
		setOwnersSelected([...selectedOptions]);
	};

	const onAssignedToChange = (selectedOptions) => {
		setAssigneesSelected([...selectedOptions]);
	};

	const onSaveHandler = () => {
		// Procss owner information
		const seletedOwnerAccountIds = ownersSelected.map((owner) => owner.id);
		const { deleteIds: deleteOwnerIds, addIds: addOwnerIds } =
			getAddDeleteListFromRecords(
				Object.keys(ownersHash),
				seletedOwnerAccountIds,
				ownersHash
			);

		// Procss assignee information
		const seletedAssigneeAccountIds = assigneesSelected.map((owner) => owner.id);
		const { deleteIds: deleteAssigneeIds, addIds: addAssigneeIds } =
			getAddDeleteListFromRecords(
				Object.keys(assigneesHash),
				seletedAssigneeAccountIds,
				assigneesHash
			);

		onSave({
			deleteOwnerIds,
			addOwnerIds,
			deleteAssigneeIds,
			addAssigneeIds,
			restrictionTypeC: restrictionType,
		});
	};

	const handleChangeRestriction = (e) => {
		const newRestriction = parseInt(e.target.value, 10);
		setRestrictionType(newRestriction);
	};

	/**
	 * Listen to state changes from parent, and update your own state.
	 */
	useEffect(() => {
		const listOwner = [];

		if (accountsHash && size(accountsHash) && ownersHash && size(ownersHash)) {
			Object.keys(ownersHash).forEach((id) => {
				listOwner.push({
					label: accountsHash[id],
					id: id,
					value: id,
				});
			});
		}

		setOwnersSelected(clone(listOwner));
	}, [ownersHash, accountsHash]);

	useEffect(() => {
		const listAssign = [];

		if (accountsHash && size(accountsHash) && assigneesHash && size(assigneesHash)) {
			Object.keys(assigneesHash).forEach((id) => {
				listAssign.push({
					label: accountsHash[id],
					id: id,
					value: id,
				});
			});
		}

		setAssigneesSelected(clone(listAssign));
	}, [assigneesHash, accountsHash]);

	useEffect(() => {
		if (restriction) {
			setRestrictionType(restriction["restriction_type_c"]);
		}
	}, [restriction]);

	return (
		<Wrapper>
			<EuiFormRow fullWidth label="Owner">
				<EuiComboBox
					placeholder="Select record owner"
					options={optionsAccount}
					selectedOptions={ownersSelected}
					onChange={onOwnerChange}
					isClearable={true}
					fullWidth
					append
				/>
			</EuiFormRow>

			<EuiFormRow fullWidth label="Assigned To">
				<EuiComboBox
					placeholder="Select record assigned to"
					options={optionsAccount}
					selectedOptions={assigneesSelected}
					onChange={onAssignedToChange}
					isClearable={true}
					fullWidth
					append
				/>
			</EuiFormRow>

			<EuiFormRow fullWidth label="Restriction Type">
				<EuiSelect
					placeholder="Select restriction type"
					options={restrictionTypeOptions}
					value={restrictionType}
					onChange={handleChangeRestriction}
				/>
			</EuiFormRow>

			<SaveCancelBtn onChangeCancel={onCancel} onChangeSave={onSaveHandler} />
		</Wrapper>
	);
};

export default EditableRAS;

const Wrapper = styled.div`
	width: 250px;
`;
