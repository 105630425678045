/** @format */

import React, { useEffect, useRef, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPen } from "@fortawesome/pro-light-svg-icons/faPen";
import { faTimes } from "@fortawesome/pro-light-svg-icons/faTimes";

import {
    EuiButtonEmpty,
    EuiFieldText,
    EuiFlexGroup,
    EuiFlexItem,
    EuiLoadingContent,
    EuiRadio,
    EuiToolTip,
} from "@elastic/eui";
import cloneDeep from "lodash/cloneDeep";
import size from "lodash/size";
import isEqual from "lodash/isEqual";
import EditControls from "../EditControls";
import { objectChecker } from "../../../helpers/utilities";

// import {faPen, faTimes} from "@fortawesome/pro-light-svg-icons";

/**
 * This component has a generic but customizable edit UI.
 */

function EditableContent(props) {
    const {
        icon,
        iconStyleOverrides,
        render,
        data,
        onSaveHandler,
        onAddHandler,
        onChangeHandler,
        editDisplayKey,
        placeholder,
        editTrackingKey,
        editDisplayOverride,
        isSingleEntry,
        isLoading,
        hasPrimaryOption,
        fullWidth,

        alwaysEditMode,
        saveAndCloseAlwaysOnDisplay,
        handleCancelOverride,
        hideIcon,
        customIcon = () => false,

        // get the edit data by calling save function.
        getDataCmd = false,
        getDataHandler,
    } = props;

    const [isHovered, setIsHovered] = useState(false);
    const [editClicked, setEditClickedOriginal] = useState(false);
    const [editData, setEditData] = useState(null);
    const [addedItemNewIndex, setAddedItemNewIndex] = useState(0);
    const [selectedPrimary, setSelectedPrimary] = useState(null);

    const setEditClicked = (data) => {
        setEditClickedOriginal(data);
    };

    /**
     * Handle saving of prev length
     */

    const prevEditDataRef = useRef();

    useEffect(() => {
        if (editData) {
            prevEditDataRef.current = editData;
        }
    });

    const prevEditData = prevEditDataRef.current;

    /**
     * end of Handle saving of prev length
     */

    const resetStates = () => {
        setEditData(null);
        setSelectedPrimary({});
    };

    const syncEditData = () => {
        let newData = [];

        if (size(data) > 0) {
            newData = cloneDeep(data);
        }

        setEditData(newData);
    };

    // Find the is primary in the data and loops it
    const syncSelectedPrimary = () => {};

    const handleMouseEnter = (event) => {
        if (!editClicked) {
            setIsHovered(true);
        }
    };

    const handleMouseLeave = (event) => {
        setIsHovered(false);
    };

    const handleClick = (event) => {
        setEditClicked((prevEditClicked) => !prevEditClicked);

        if (editClicked) {
            resetStates();
        }
    };

    // I am going to make this overridable
    const handleCancel = (event) => {
        if (handleCancelOverride) {
            handleCancelOverride(setEditClicked, setEditData, setSelectedPrimary);
        } else {
            setEditClicked(!editClicked);

            if (editClicked) {
                resetStates();
            }
        }
    };

    let editIcons = (
        <React.Fragment>
            {
                // JSON.stringify(editData)
            }

            <EditControls
                editData={editData}
                data={data}
                getDataCmd={getDataCmd}
                handleCancel={handleCancel}
                setEditClicked={setEditClicked}
                alwaysEditMode={alwaysEditMode}
                onSaveHandler={onSaveHandler}
                onChangeHandler={onChangeHandler}
                setEditData={setEditData}
                styleOverrides={{
                    width: "300px",
                }}
            />
        </React.Fragment>
    );

    /**
     * Handle alwaysEditMode
     */

    useEffect(() => {
        if (alwaysEditMode) {
            setEditClicked(true);
        }
    }, []);

    /**
     * end of Handle alwaysEditMode
     */

    useEffect(() => {
        if (!editClicked) {
            syncEditData();
            syncSelectedPrimary();
        } else if (JSON.stringify(data) !== JSON.stringify(editData)) {
            syncEditData();
            syncSelectedPrimary();
        }

        if (editClicked && size(editData) === 0) {
            // Toggle add.
            if (onAddHandler) {
                onAddHandler(
                    editData,
                    setEditData,
                    addedItemNewIndex,
                    setAddedItemNewIndex
                );
            }
        }
    }, [data, editClicked]);

    let iconStyles = {
        border: "0.5px solid #FFFFFF",
    };

    if (isHovered) {
        iconStyles = {
            ...iconStyles,
            background: "#EEF2F7",
            border: "0.5px solid #D3DAE6",
        };
    }

    if (editClicked) {
        iconStyles = {
            ...iconStyles,
            background: "#EEF2F7",
            border: "0.5px solid #D3DAE6",
            marginBottom: "0px",
        };
    }

    if (iconStyleOverrides) {
        iconStyles = {
            ...iconStyles,
            ...iconStyleOverrides,
        };
    }

    const getIcon = () => {
        return isHovered === false ? icon : faPen;
    };

    /**
     * This deviates from the normal pattern of state updates because we are updating an array.
     * We track it by passing the tracking key and looping the array.
     */
    const handleOnChange = (event, editTrackingKey, editTrackingValue) => {
        // Add this check in case if we have to call this manually.. Ugh so many anti patterns,
        // but cannot be helped.
        if (objectChecker(event, ["preventDefault"])) {
            event.preventDefault();
        }

        if (!editData) return;

        let newEditContainer = cloneDeep(editData);

        for (let i in newEditContainer) {
            if (
                newEditContainer?.[i]?.[editTrackingKey] &&
                newEditContainer?.[i]?.[editTrackingKey] === editTrackingValue
            ) {
                newEditContainer[i][editDisplayKey] = event.target.value;

                setEditData(newEditContainer);
                break;
            }
        }
    };

    // handleOnChangeManual will accept a value instead of getting that value through an event.
    const handleOnChangeManual = (value, editTrackingKey, editTrackingValue) => {
        if (!editData) return;

        let newEditContainer = cloneDeep(editData);

        for (let i in newEditContainer) {
            if (newEditContainer[i][editTrackingKey] === editTrackingValue) {
                newEditContainer[i][editDisplayKey] = value;

                setEditData(newEditContainer);
                break;
            }
        }
    };

    /**
     * useEffects to handle dynamic additions of new characters.`
     */

    // When you click edit, add a new entry automatically
    useEffect(() => {
        if (editClicked) {
            if (isSingleEntry) {
                if (editData.length === 0) {
                    onAddHandler(
                        editData,
                        setEditData,
                        addedItemNewIndex,
                        setAddedItemNewIndex
                    );
                }
            } else {
                onAddHandler(
                    editData,
                    setEditData,
                    addedItemNewIndex,
                    setAddedItemNewIndex
                );
            }
        }
    }, [editClicked]);

    // Super verbose refactor of this auto edit function so Frank and I can understand better.
    // When you type in a value on the latest entry, automatically add a new form field
    useEffect(() => {
        // Fail if not in edit mode
        if (!editClicked) {
            return;
        }

        // Fail if single entry.
        if (isSingleEntry) {
            return;
        }

        // Fail is editData is not yet initialized.
        if (!editData) {
            return;
        }

        // Fail is not is array
        if (!Array.isArray(editData)) {
            return;
        }

        // Fail if array length is 0.
        if (editData.length === 0) {
            return;
        }

        // Fail if lengths of both edited and previously edited aren't the same.
        if (!(editData.length === prevEditData.length)) {
            return;
        }

        const latestEntry = editData[editData.length - 1];
        const prevLatestEntry = prevEditData[prevEditData.length - 1];

        if (!isEqual(latestEntry, prevLatestEntry)) {
            onAddHandler(editData, setEditData, addedItemNewIndex, setAddedItemNewIndex);
        }
    }, [editData]);

    // get the data when getDataCmd is set as true
    useEffect(() => {
        if (getDataCmd === true) {
            getDataHandler(editData, data, setEditData);
        }
    }, [getDataCmd]);

    const handleRemove = (editTrackingKey, editTrackingValue) => {
        let newEditContainer = cloneDeep(editData);

        for (let i in newEditContainer) {
            if (newEditContainer[i][editTrackingKey] === editTrackingValue) {
                newEditContainer.splice(i, 1);

                setEditData(newEditContainer);
                break;
            }
        }
    };

    const handlePrimaryOptionChecked = (data) => {
        const newEditData = [...editData];

        for (let i in editData) {
            if (data[editTrackingKey] === newEditData[i][editTrackingKey]) {
                newEditData[i]["is_primary"] = 1;
            } else {
                newEditData[i]["is_primary"] = 0;
            }
        }

        setEditData(newEditData);
    };

    const getPrimaryOption = (data) => {
        return (
            <EuiRadio
                checked={data["is_primary"] === 1 ? true : false}
                onChange={(e) => handlePrimaryOptionChecked(data)}
            />
        );
    };

    let editDisplay = () => {
        // Return custom edit display if provided
        if (editDisplayOverride) {
            return editDisplayOverride(
                editData,
                setEditData,
                editTrackingKey,
                editDisplayKey,
                handleRemove,
                // Expose selector for primary record
                getPrimaryOption,
                handleOnChange,
                handleOnChangeManual
            );
        }

        // return null;

        if (typeof data === "object") {
            // Handle object updates here.
        }

        if (Array.isArray(data)) {
            return (
                <div>
                    {editData.map((val, id) => {
                        const editVal = val?.[editTrackingKey];
                        const editEditVal = val?.[editTrackingKey];
                        const editkey = `${editVal ?? editTrackingKey}-${id}`;

                        return (
                            <div key={editkey} className={"edit-display"}>
                                <EuiFieldText
                                    style={{
                                        padding: "0 0 0 5px",
                                        margin: "0",
                                        // height: '1.4rem',
                                    }}
                                    compressed
                                    placeholder={placeholder}
                                    value={editEditVal}
                                    onChange={(e) =>
                                        handleOnChange(e, editTrackingKey, editVal)
                                    }
                                />

                                <div className={"edit-controls"}>
                                    <EuiFlexGroup
                                        style={{
                                            display: "flex",
                                            alignItems: "center",
                                        }}
                                        gutterSize="none"
                                        responsive={false}
                                    >
                                        {!isSingleEntry && (
                                            <>
                                                <EuiFlexItem grow={false}>
                                                    <EuiToolTip
                                                        position="bottom"
                                                        content="Remove"
                                                    >
                                                        <EuiButtonEmpty
                                                            onClick={() =>
                                                                handleRemove(
                                                                    editTrackingKey,
                                                                    editVal
                                                                )
                                                            }
                                                            size="s"
                                                            color="danger"
                                                            style={{ marginLeft: 3 }}
                                                        >
                                                            <FontAwesomeIcon
                                                                icon={faTimes}
                                                                size="sm"
                                                            />
                                                        </EuiButtonEmpty>
                                                    </EuiToolTip>
                                                </EuiFlexItem>
                                                {hasPrimaryOption && (
                                                    <EuiFlexItem grow={false}>
                                                        {getPrimaryOption(val)}
                                                    </EuiFlexItem>
                                                )}
                                            </>
                                        )}
                                    </EuiFlexGroup>
                                </div>
                            </div>
                        );
                    })}
                </div>
            );
        }
    };

    const renderLoading = () => (
        <div style={{ width: "100px" }}>
            <EuiLoadingContent lines={2} />
        </div>
    );

    const containerClass = fullWidth ? "" : "edit-container";

    return (
        <div className={containerClass}>
            {!hideIcon && (
                <button
                    onMouseEnter={handleMouseEnter}
                    onMouseLeave={handleMouseLeave}
                    onClick={handleClick}
                    className={"icon-button"}
                >
                    <div>
                        {customIcon() ? (
                            isHovered ? (
                                <FontAwesomeIcon
                                    className={"icon-styles"}
                                    style={iconStyles}
                                    icon={getIcon()}
                                />
                            ) : (
                                <div className={"icon-styles"} style={iconStyles}>
                                    {customIcon()}
                                </div>
                            )
                        ) : (
                            <FontAwesomeIcon
                                className={"icon-styles"}
                                style={iconStyles}
                                icon={getIcon()}
                            />
                        )}
                    </div>
                    <div className="editable-content"></div>
                </button>
            )}

            <div
                style={{
                    marginTop: "auto",
                    marginBottom: "auto",
                }}
            >
                {isLoading ? (
                    renderLoading()
                ) : (
                    <>
                        {!editClicked && !alwaysEditMode ? render(data) : editDisplay()}
                        {editClicked && saveAndCloseAlwaysOnDisplay && editIcons}
                    </>
                )}
            </div>
        </div>
    );
}

export default EditableContent;
