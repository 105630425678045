import React from "react";
import styled from "styled-components";

import ButtonsWrapper from "./ButtonsWrapper";

/**
 * Component for Save Button
 */
const SaveButton = (props) => {
	const { onSave } = props;

	const buttons = [
		{
			label: "Save",
			callback: () => onSave(),
			icon: "",
		},
	];

	return (
		<ButtonWrapper>
			<ButtonsWrapper buttons={buttons} color="primary" />
		</ButtonWrapper>
	);
};

export default SaveButton;

const ButtonWrapper = styled.div`
	margin-left: auto;
	min-width: 74px;
	height: var(--sp-12);
`;
