import React from "react";
import styled from "styled-components";
import { EuiLoadingContent } from "@elastic/eui";

/**
 * Item component has a editable icon
 */
const EditableItem = (props) => {
	const { isLoading, renderStaticState, renderEditState, editState } = props;

	const renderLoading = () => (
		<div style={{ width: "100px" }}>
			<EuiLoadingContent lines={2} />
		</div>
	);

	return (
		<Wrapper>
			<div
				style={{
					marginTop: "auto",
					marginBottom: "auto",
				}}
			>
				{isLoading ? (
					renderLoading()
				) : (
					<>{editState ? renderEditState : renderStaticState}</>
				)}
			</div>
		</Wrapper>
	);
};

export default EditableItem;

const Wrapper = styled.div``;
