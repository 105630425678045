/**
 * Tab information for every path
 */
export const routeTabInfo = {
    root: {
        path: "/",
        hasTab: false,
    },
    login: {
        path: "/login",
        hasTab: false,
    },
    dashboard: {
        path: "/dashboard",
        hasTab: true,
        label: "Dashboard",
        icon: "dashboardApp",
    },
    sit: {
        path: "/sit",
        hasTab: true,
        label: "Sit",
    },
    person: {
        path: "/person",
        hasTab: true,
        label: "Person",
        icon: "user",
        subRoute: {
            label: "id",
            items: {
                id: {
                    path: "id",
                },
            },
        },
    },
    company: {
        path: "/company",
        hasTab: true,
        label: "Company",
        icon: "visBarVertical",
        subRoute: {
            label: "id",
            items: {
                id: {
                    path: "id",
                },
            },
        },
    },
    job: {
        path: "/jobs",
        hasTab: true,
        label: "Job",
        icon: "inspect",
        subRoute: {
            label: "id",
            items: {
                id: {
                    path: "id",
                },
            },
        },
    },
    campaign: {
        path: "/campaigns",
        hasTab: true,
        label: "Campaigns",
        icon: "calendar",
        subRoute: {
            label: "id",
            items: {
                id: {
                    path: "id",
                },
            },
        },
    },
    systemConfiguration: {
        path: "/system-configuration",
        hasTab: true,
        label: "System Configuration",
        subRoute: {
            label: "subPage",
            items: {
                userAccount: {
                    path: "user-accounts",
                    label: "User Accounts",
                },
                credential: {
                    path: "credential-management-system",
                    label: "Credential Management",
                },
                qualifier: {
                    path: "qualifier-system",
                    label: "Qualifiers",
                },
                security: {
                    path: "security-group",
                    label: "Security",
                },
                global: {
                    path: "global-values",
                    label: "Global Values",
                },
                sit: {
                    path: "sit",
                    label: "SIT",
                },
                systemLogs: {
                    path: "system-logs",
                    label: "System Logs",
                },
            },
        },
    },
    sourcing: {
        path: "/sourcing",
        hasTab: true,
        label: "Sourcing",
        icon: "reporter",
    },
    messaging: {
        path: "/messaging",
        hasTab: true,
        label: "Messaging",
        icon: "email",
    },
    account: {
        path: "/account-editor",
        hasTab: false,
    },
    profile: {
        path: "/profile",
        hasTab: true,
        label: "Profile",
    },
};
