import React from "react";
import styled from "styled-components";
import {
    EuiFieldText
} from "@elastic/eui";

/**
 * Main Components
 */
const EditCompany = (props) => {

	const {
        website,
        onChangeWebsite,
		...restProps
	} = props;

    const handleChangeWebsite = (e) => {
        onChangeWebsite(e.target.value);
    }

	return (
		<Wrapper {...restProps}>
			<EuiFieldText
                placeholder="Company"
                value={website ? website : ''}
                onChange={(e) => handleChangeWebsite(e)}
            />
		</Wrapper>
	);
};

export default EditCompany;

/**
 * Styled Components
 */
const Wrapper = styled.div`

`;
