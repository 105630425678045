/**
 * Constants for Plivo
 */
export const PLIVO_CALL_STATES = {
    idle: { key: "idle", value: 0, label: "Idle" },
    onCalling: { key: "onCalling", value: 1, label: "On calling" },
    onCallRemoteRinging: {
        key: "onCallRemoteRinging",
        value: 2,
        label: "On call-remote-ringing",
    },
    onMediaConnected: { key: "onMediaConnected", value: 3, label: "On Media Connected" },

    answered: { key: "answered", value: 4, label: "Answered" },

    terminated: { key: "terminated", value: 5, label: "Terminated" },
    faild: { key: "failed", value: 6, label: "Call Failed" },
};
