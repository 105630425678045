import { _getCrendentialsByAccount } from "helpers/selectors";
import { useDispatch } from "react-redux";
import { getCredentialsByAccount_NEW } from "store/credential";

/**
 * Hooks for Credential
 */
const useCredential = () => {
	const dispatch = useDispatch();

	const credentialsByAccount = _getCrendentialsByAccount();

	const getCredentialsByAccount = (accountId) => {
		dispatch(getCredentialsByAccount_NEW(accountId));
	};

	return {
		credentialsByAccount,
		getCredentialsByAccount,
	};
};

export default useCredential;
