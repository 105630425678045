import React from "react";
import styled from "styled-components";
import { EuiButtonIcon } from "@elastic/eui";

/**
 * Main Components
 */
const SaveCancelIconBtn = (props) => {
    const { onChangeCancel, onChangeSave } = props;

    return (
        <Wrapper>
            <EuiButtonIcon color="success" onClick={onChangeSave} iconType="check" />
            <EuiButtonIcon color="danger" onClick={onChangeCancel} iconType="cross" />
        </Wrapper>
    );
};

export default SaveCancelIconBtn;

/**
 * Styled Components
 */
const Wrapper = styled.div`
    display: flex;
    justify-content: center;
    margin-left: auto;
    margin-right: auto;

    .euiButtonIcon {
        border: solid 1px var(--eui-light-shade-color);
        border-radius: 3px;
        margin: 0 var(--sp-1);
    }
`;
