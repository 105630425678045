const sourcing_main = {
    _name: 'sourcing_main',
    id: 'id',
    sourcing_name: 'sourcing_name',
    sourcing_type_c: 'sourcing_type_c',
    sourcing_status_c: 'sourcing_status_c',
    sourcing_owner_account_ref_id: 'sourcing_owner_account_ref_id',
    sourcing_threshold: 'sourcing_threshold',
    sourcing_max_records: 'sourcing_max_records',
    sourcing_result_c: 'sourcing_result_c',
    sourcing_result_value: 'sourcing_result_value',
    default_credential_ref_id: 'default_credential_ref_id',
}

const sourcing_qualifier_setting = {
    _name: 'sourcing_qualifier_setting',
    id: 'id',
    sourcing_main_ref_id: 'sourcing_main_ref_id',
    qualifier_association_type_c: 'qualifier_association_type_c',
    qualifier_main_ref_id: 'qualifier_main_ref_id',
}

const sourcing_permission = {
    _name: 'sourcing_permission',
    id: 'id',
    sourcing_main_ref_id: 'sourcing_main_ref_id',
    security_group_ref_id: 'security_group_ref_id',
}

const sourcing_search = {
    _name: 'sourcing_search',
    id: 'id',
    sourcing_main_ref_id: 'sourcing_main_ref_id',
    search_order: 'search_order',
    search_value: 'search_value',
    search_max_records: 'search_max_records',
    credential_main_ref_id: 'credential_main_ref_id',
}

const sourcing_item = {
    _name: 'sourcing_item',
    id: 'id',
    sourcing_process_ref_id: 'sourcing_process_ref_id',
    item_status_c: 'item_status_c',
    item_type_c: 'item_type_c',
    item_result_c: 'item_result_c',
    item_record_type_c: 'item_record_type_c',
    item_source_type_c: 'item_source_type_c',
    item_flag_c: 'item_flag_c',
    item_threshold_value: 'item_threshold_value',
}

const sourcing_item_data = {
    _name: 'sourcing_item_data',
    id: 'id',
    sourcing_item_ref_id: 'sourcing_item_ref_id',
    data_JSON: 'data_JSON',
}

const sourcing_log_activity = {
    _name: 'sourcing_log_activity',
    id: 'id',
    date_created: 'date_created',
    account_main_ref_id: 'account_main_ref_id',
    sourcing_table_ref_id: 'sourcing_table_ref_id',
    log_object_c: 'log_object_c',
    log_type_c: 'log_type_c',
    log_action_c: 'log_action_c',
    sourcing_log_detail_ref_id: 'sourcing_log_detail_ref_id',
}

const sourcing_log_detail = {
    _name: 'sourcing_log_detail',
    id: 'id',
    detail_text: 'detail_text',
}

const sourcing_process = {
    _name: 'sourcing_process',
    id: 'id',
    sourcing_search_ref_id: 'sourcing_search_ref_id',
    process_type_c: 'process_type_c',
    process_state_c: 'process_state_c',
    date_process_started: 'date_process_started',
    date_process_ended: 'date_process_ended',
    process_details: 'process_details',
}

const sourcing_ras_setting = {
    _name: 'sourcing_ras_setting',
    id: 'id',
    sourcing_main_ref_id: 'sourcing_main_ref_id',
    ras_type_c: 'ras_type_c',
    ras_sub_type_c: 'ras_sub_type_c',
    ras_ref_id: 'ras_ref_id',
}

export {
    sourcing_main,
    sourcing_qualifier_setting,
    sourcing_permission,
    sourcing_search,
    sourcing_item,
    sourcing_item_data,
    sourcing_log_activity,
    sourcing_log_detail,
    sourcing_process,
    sourcing_ras_setting,
}