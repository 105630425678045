/**
 * Messaging
 */
export const SET_MESSAGES = "@@messaging/SET_MESSAGES";
export const REMOVE_MESSAGES = "@@messaging/REMOVE_MESSAGES";
export const ADD_MESSAGE = "@@messaging/ADD_MESSAGE";
export const SET_MESSAGE_CHATBOX = "@@messaging/SET_MESSAGE_CHATBOX";
export const SET_RELATED_SELECTED_OPTIONS = "@@messaging/SET_RELATED_SELECTED_OPTIONS";
export const SET_RELATED_OPTIONS = "@@messaging/SET_RELATED_OPTIONS";
export const SET_MESSAGING_PERSON_FLYOUT_VISIBLE = "@@messaging/SET_MESSAGING_PERSON_FLYOUT_VISIBLE";
export const SET_SELECTED_CREDENTIAL_ID = "@@messaging/SET_SELECTED_CREDENTIAL_ID";
