import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { EuiFlexGroup, EuiFlexItem, EuiFieldText, EuiCheckbox } from "@elastic/eui";
import { clone } from "lodash";

import { SaveCancelBtn } from "components/Common";

/**
 * Main Components
 */
const EditAddressItem = (props) => {
	const { address, addressPrimary, onSave, onCancel, className } = props;

	// state variable
	const [primary, setPrimary] = useState(false);
	const [data, setData] = useState(null);

	const handleChange = (event) => {
		// Tracking ID.
		const key = event.target.name;
		const value = event.target.value;

		let _address = clone(data);
		_address.global_address_details = {
			...data?.global_address_details,
			[key]: value,
		};

		setData(_address);
	};

	const handleAddressPrimary = (e) => {
		setPrimary(e.target.checked);
	};

	const handleSave = () => {
		onSave(data, primary);
	};

	const handleCancel = () => {
		onCancel();
	};

	useEffect(() => {
		setPrimary(addressPrimary);
	}, [addressPrimary]);

	useEffect(() => {
		setData(address);
	}, [address]);

	const addressDetail = data?.global_address_details;

	return (
		<Wrapper className={className}>
			<EuiFlexGroup gutterSize="s" direction="column">
				<EuiFlexItem>
					<EuiFieldText
						placeholder={"Address Line 1"}
						name="address_line_1"
						value={addressDetail?.address_line_1 || ""}
						onChange={handleChange}
					/>
				</EuiFlexItem>
				<EuiFlexItem>
					<EuiFieldText
						placeholder={"Address Line 2"}
						name="address_line_2"
						value={addressDetail?.address_line_2 || ""}
						onChange={handleChange}
					/>
				</EuiFlexItem>
				<EuiFlexItem>
					<EuiFieldText
						placeholder={"City"}
						name="address_city"
						value={addressDetail?.address_city || ""}
						onChange={handleChange}
					/>
				</EuiFlexItem>
				<EuiFlexItem>
					<EuiFlexGroup gutterSize="none">
						<EuiFlexItem grow={7}>
							<EuiFieldText
								placeholder={"State"}
								name="address_state"
								value={addressDetail?.address_state || ""}
								onChange={handleChange}
							/>
						</EuiFlexItem>
						<EuiFlexItem grow={3}>
							<EuiFieldText
								placeholder={"Zip Code"}
								name="address_zip"
								value={addressDetail?.address_zip || ""}
								onChange={handleChange}
							/>
						</EuiFlexItem>
					</EuiFlexGroup>
				</EuiFlexItem>
				<EuiFlexItem className="d-flex">
					<EuiFieldText
						placeholder={"Country"}
						name="address_country"
						value={addressDetail?.address_country || ""}
						onChange={handleChange}
					/>
					<EuiCheckbox
						id={"edit-address"}
						checked={primary}
						onChange={(e) => handleAddressPrimary(e)}
						className="address-valid ml-2"
					/>
				</EuiFlexItem>
				<EuiFlexItem>
					<SaveCancelBtn
						onChangeSave={handleSave}
						onChangeCancel={handleCancel}
					/>
				</EuiFlexItem>
			</EuiFlexGroup>
		</Wrapper>
	);
};

export default EditAddressItem;

/**
 * Styled Components
 */
const Wrapper = styled.div`
	.address-valid {
		margin-top: auto;
		margin-bottom: auto;
		margin-left: auto;
	}

	.euiCheckbox .euiCheckbox__input + .euiCheckbox__square {
		border-radius: 50%;
	}

	.euiFormControlLayout {
		height: fit-content;
	}
`;
