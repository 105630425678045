import { useDispatch } from "react-redux";

import { _getQualifiers } from "helpers/selectors";
import { updateQualifier_NEW } from "helpers/actions";
import { getGCSValue } from "components/global/utils";
import useGlobal from "./useGlobal";

import * as MODEL from "core/model";

/**
 * Hooks for Qualifier
 */
const useQualifier = () => {
	const dispatch = useDispatch();

	const { globals } = useGlobal();

	const qualifiers = _getQualifiers();

	const saveQualifiers = (
		object_ref_id,
		object_type,
		ids,
		successCallback,
		failCallback
	) => {
		if (ids?.length > 0) {
			const qualifierAssignments = [];
			const object_type_c = getGCSValue(
				globals,
				"qualifier_assignment",
				"object_type_c",
				object_type
			);

			ids.forEach((id) => {
				qualifierAssignments.push({
					[MODEL.qualifier_assignment._name]: {
						[MODEL.qualifier_assignment.object_type_c]: object_type_c,
						[MODEL.qualifier_assignment.object_record_ref_id]: object_ref_id,
						[MODEL.qualifier_assignment.qualifier_main_ref_id]: id,
					},
				});
			});

			dispatch(
				updateQualifier_NEW(qualifierAssignments, successCallback, failCallback)
			);
		} else {
			if (successCallback) {
				successCallback(ids);
			}
		}
	};

	return {
		qualifiers,
		saveQualifiers,
	};
};

export default useQualifier;
