import { chatIcon, emailIcon, groupIcon, linkedInIcon } from "components/Common/Icons";

export const NOTIFICATION_TYPES = {
	normal: {
		key: "normal",
		type: "normal",
		value: "normal",
		label: "",
		icon: null,
	},
	fromCrawler: {
		key: "from-crawler",
		type: "from-crawler",
		value: "from-crawler",
		label: "",
		icon: null,
	},
	connectionRequestForCmsLinkedIn: {
		key: "connection-request-for-cms-linkedin",
		type: "connection-request-for-cms-linkedin",
		value: 0,
		label: "Connection Request",
		icon: linkedInIcon(),
	},
	messageForCmsLinkedIn: {
		key: "message-for-cms-linkedin",
		type: "message-for-cms-linkedin",
		value: 1,
		label: "Message",
		icon: linkedInIcon(),
	},
	inMailForCmsLinkedIn: {
		key: "inmail-for-cms-linkedin",
		type: "inmail-for-cms-linkedin",
		value: 2,
		label: "InMail",
		icon: linkedInIcon(),
	},
	cmsEmail: {
		key: "cms-email",
		type: "cms-email",
		value: 3,
		label: "Email",
		icon: emailIcon(),
	},
	cmsMeeting: {
		key: "cms-meeting",
		type: "cms-meeting",
		value: 4,
		label: "Meeting",
		icon: groupIcon(),
	},
	cmsSms: {
		key: "cms-sms",
		type: "cms-sms",
		value: 5,
		label: "SMS",
		icon: chatIcon(),
	},
	general: {
		key: "general",
		type: "general",
		value: 6,
		label: "General",
		icon: chatIcon(),
	},
	amsOnScreenNotification: {
		key: "ams_onscreen_notification",
		type: "ams_onscreen_notification",
		value: 7,
		label: "AMS On Screen Notification",
		icon: chatIcon(),
	},
};

export const CMS_LINKEDIN_STATES = {
	queued: {
		key: "queued",
		type: "queued",
		value: 0,
	},
	delivered: {
		key: "delivered",
		type: "delivered",
		value: 1,
	},
};
