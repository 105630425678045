import React from "react";
import { NavLink } from "react-router-dom";
import {
    EuiHeader,
    EuiHeaderLinks,
    EuiHeaderLogo,
    EuiHeaderSectionItem,
    EuiText,
} from "@elastic/eui";
import loadable from "@loadable/component";

import UserHeaderMenu from "./UserHeaderMenu";
import UserAppMenu from "./UserAppMenu";
import NotificationItem from "./NotificationItem";
import { GlobalSearch } from "./GlobalSearch";

import {
    campaignIcon,
    companyIcon,
    dashboardIcon,
    jobIcon,
    peopleIcon,
    sourcingIcon,
} from "components/Common/Icons";

const HeaderCreateRecord = loadable(() => import("./HeaderCreateRecord"));

const desktopSections = [
    {
        items: [
            <EuiHeaderLogo
                style={{ width: "130px" }}
                className="main-header-logo"
                iconType="https://www.empower.associates/wp-content/uploads/2019/05/empowerassociates.png"
                aria-label="Go to home page"
            />,
            <EuiHeaderLinks gutterSize="xs">
                <NavLink to="/dashboard" className="header-item">
                    <div className="header-item__icon">{dashboardIcon()}</div>
                    <EuiText size="s" className="header-item__text">
                        Dashboard
                    </EuiText>
                </NavLink>
                <NavLink to="/campaigns" className="header-item">
                    <div className="header-item__icon">{campaignIcon()}</div>
                    <EuiText size="s" className="header-item__text">
                        Campaigns
                    </EuiText>
                </NavLink>
                <NavLink to="/person" className="header-item">
                    <div className="header-item__icon">{peopleIcon()}</div>
                    <EuiText size="s" className="header-item__text">
                        People
                    </EuiText>
                </NavLink>
                <NavLink to="/company" className="header-item">
                    <div className="header-item__icon">{companyIcon()}</div>
                    <EuiText size="s" className="header-item__text">
                        Companies
                    </EuiText>
                </NavLink>
                <NavLink to="/sourcing" className="header-item">
                    <div className="header-item__icon">{sourcingIcon()}</div>
                    <EuiText size="s" className="header-item__text">
                        Sourcing
                    </EuiText>
                </NavLink>
                <NavLink to="/jobs" className="header-item">
                    <div className="header-item__icon">{jobIcon()}</div>
                    <EuiText size="s" className="header-item__text">
                        Jobs
                    </EuiText>
                </NavLink>
            </EuiHeaderLinks>,
        ],
        borders: "none",
    },
    {
        items: [
            <EuiHeaderSectionItem border="none">
                <UserAppMenu />
                <HeaderCreateRecord />
                <GlobalSearch />
                <NotificationItem />
                <UserHeaderMenu />
            </EuiHeaderSectionItem>,
        ],
        borders: "none",
        side: "right",
    },
];

const Header = () => {
    return <EuiHeader sections={desktopSections} id="root-main-header" />;
};

export default Header;
