import API from "api";

/** ---------------- Generic API ---------------------- */
const updateWorkflow = (data) => {
	const request = {
		object: "workflow",
		data: data || {},
	};

	return API().GenericAPI().updateTableData(request);
};

const deleteWorkflow = (data) => {
	const request = {
		object: "workflow",
		data: data || {},
	};

	return API().GenericAPI().deleteTableData(request);
};

const getWorkflow = ({ id }) => {
	const query = {
		object: "workflow",
		record_main_id: id,
	};

	return API().GenericAPI().getMainData(query);
};

const updateWorkflowTemplate = (data) => {
	const request = {
		object: "workflow",
		data: data || {},
	};

	return API().GenericAPI().updateTableData(request);
};

const deleteWorkflowTemplate = (data) => {
	const request = {
		object: "workflow",
		data: data || {},
	};

	return API().GenericAPI().deleteTableData(request);
};

const getWorkflowTemplate = ({ id }) => {
	const query = {
		object: "workflow_template",
		record_main_id: id,
	};

	return API().GenericAPI().getMainData(query);
};

/** --------------------------------------------------------- */

/** ----------------------- Custom API ---------------------- */
const getWorkflowList = (data) => {
	const query = {
		page_number: data?.page || 1,
		page_count: data?.per_page || 30,
	};
	return API().Workflow().getWorkflowList(query);
};

const getWorkflowItemsList = (data) => {
	const query = {
		page_number: data?.page || 0,
		page_count: data?.per_page || 30,
		workflow_main_id: data?.workflow_main_id || 0,
		filter: data?.filter,
		...(data?.states ? { states: data?.states } : {}), // queue stats
	};
	return API().Workflow().getWorkflowItemsList(query);
};

const getWorkflowItemCount = (data) => {
	const query = {
		record_id: data?.record_id || 0,
	};
	return API().Workflow().getWorkflowItemCount(query);
};

const getWorkflowSummary = ({ id }) => {
	const query = {
		workflow_main_ref_id: id,
	};

	return API().Workflow().getWorkflowSummary(query);
};

const getWorkflowStepDetails = ({ id, nodeId }) => {
	const query = {
		workflow_main_ref_id: id,
		item_node_id: nodeId,
	};

	return API().Workflow().getWorkflowStepDetails(query);
};

const getWorkflowTemplateList = (data) => {
	const query = {
		page_number: data?.page_number || 0,
		page_count: data?.page_count || 30,
	};
	return API().Workflow().getWorkflowTemplateList(query);
};

const actionWorkflowRestart = (data) => {
	const query = {
		workflow_item: data || [],
	};

	return API().Workflow().actionWorkflowRestart(query);
};

/* ---------------------------------------------------------- */

export default {
	getWorkflowList,
	updateWorkflow,
	deleteWorkflow,
	getWorkflow,
	getWorkflowItemsList,
	getWorkflowItemCount,
	getWorkflowSummary,
	getWorkflowStepDetails,
	getWorkflowTemplateList,
	updateWorkflowTemplate,
	deleteWorkflowTemplate,
	getWorkflowTemplate,
	actionWorkflowRestart,
};
