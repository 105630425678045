import React, { useEffect, useMemo, useState } from "react";
import {
	EuiButton,
	EuiConfirmModal,
	EuiFlexGroup,
	EuiFlexItem,
	EuiHeaderSectionItemButton,
	EuiIcon,
	EuiOverlayMask,
	EuiPopover,
	EuiSelect,
} from "@elastic/eui";
import styled from "styled-components";

import { REMINDER_SNOOZE_LIST } from "./constants";
import ActivityNotificationItem from "./ActivityNotificationItem";
import { useAccount, useAms } from "core/useHooks";
import * as MODEL from "core/model";
import {
	AMS_REMINDER_RESULT_TYPE_LIST,
	AMS_REMINDER_TIME_FRAME_LIST,
	AMS_REMINDER_TIME_TYPE_LIST,
} from "components/global/constants";
import { getNeedShowForReminder } from "./utils";

/**
 * Component for Notification Item to show reminders of account
 */
const NotificationItem = () => {
	const { account, accountAmsList, getAccountAmsList } = useAccount();
	const { updateAms, deleteAms } = useAms();

	const [isOpen, setIsOpen] = useState(false);
	const [selectedSnooze, setSelectedSnooze] = useState(REMINDER_SNOOZE_LIST[0].value);
	const [selectedReminder, setSelectedReminder] = useState(null);
	const [isOpenRemoveModal, setIsOpenRemoveModal] = useState(false);

	const isSelectedReminder = selectedReminder !== null;

	const { amsList, triggerdReminderNumber, triggerdReminderIds } = useMemo(() => {
		const amsList = [];
		const triggerdReminderIds = [];
		let triggerdReminderNumber = 0;

		(accountAmsList?.data || []).forEach((activity) => {
			let hasTriggeredReminder = false;
			const tasks = activity[MODEL.ams_activity_task._name] || [];
			tasks.forEach((task) => {
				const reminders = task[MODEL.ams_activity_reminder._name] || [];
				reminders.forEach((reminder) => {
					if (getNeedShowForReminder(reminder)) {
						hasTriggeredReminder = true;
						triggerdReminderNumber++;
						triggerdReminderIds.push(reminder.id);
					}
				});
			});

			if (hasTriggeredReminder) {
				amsList.push(activity);
			}
		});

		return {
			amsList,
			triggerdReminderNumber,
			triggerdReminderIds,
		};
	}, [accountAmsList]);

	const closeMenu = () => {
		setIsOpen(false);
	};

	const handleOpenReminderPopover = () => {
		setIsOpen(!isOpen);
	};

	const handleChangeSnooze = (e) => {
		const newSnooze = e.target.value;
		setSelectedSnooze(newSnooze);
	};

	const handleSelectReminder = (reminder) => {
		if (reminder?.id === selectedReminder?.id) {
			setSelectedReminder(null);
		} else {
			setSelectedReminder(reminder);
		}
	};

	const handleUpdateReminderTime = () => {
		if (!selectedReminder) {
			return;
		}

		// Get information for ams_activity_reminder
		const reminder = {
			[MODEL.ams_activity_reminder.id]: selectedReminder.id,
			[MODEL.ams_activity_reminder.reminder_time_value]:
				selectedReminder[MODEL.ams_activity_reminder.reminder_time_value],
			[MODEL.ams_activity_reminder.reminder_triggered]: 0,
		};

		// Update time
		const reminderTimeTypeC =
			selectedReminder[MODEL.ams_activity_reminder.reminder_time_type_c];
		const timeEffect =
			AMS_REMINDER_TIME_FRAME_LIST.before.value === reminderTimeTypeC ? -1 : 1;
		let reminderTimeValue = reminder[MODEL.ams_activity_reminder.reminder_time_value];

		if (reminderTimeTypeC === AMS_REMINDER_TIME_TYPE_LIST.minutes.value) {
			// For minutes
			reminderTimeValue =
				reminderTimeValue + timeEffect * Math.trunc(selectedSnooze / 60);
		} else if (reminderTimeTypeC === AMS_REMINDER_TIME_TYPE_LIST.hours.value) {
			// For hours
			reminderTimeValue =
				reminderTimeValue + timeEffect * Math.trunc(selectedSnooze / 3600);
		}
		reminder[MODEL.ams_activity_reminder.reminder_time_value] =
			Math.abs(reminderTimeValue);

		if (reminderTimeValue < 0) {
			reminder[MODEL.ams_activity_reminder.reminder_time_frame_c] =
				AMS_REMINDER_TIME_FRAME_LIST.after.value;
		}

		const amsReminderInfo = {
			[MODEL.ams_activity_reminder._name]: reminder,
		};

		// Save updated AMS items
		const updateParams = [amsReminderInfo];
		updateAms(updateParams, (res) => {
			getAccountAmsList();
		});
	};

	const handleDismissReminder = () => {
		if (!selectedReminder) {
			return;
		}

		// Get information for ams_activity_reminder
		const reminder = {
			[MODEL.ams_activity_reminder.id]: selectedReminder.id,
			[MODEL.ams_activity_reminder.reminder_result_c]:
				AMS_REMINDER_RESULT_TYPE_LIST.complete.value,
		};

		const amsReminderInfo = {
			[MODEL.ams_activity_reminder._name]: reminder,
		};

		// Save updated AMS items
		const updateParams = [amsReminderInfo];
		updateAms(updateParams, (res) => {
			getAccountAmsList();
		});
	};

	const handleRemoveAllReminders = () => {
		// Remove all reminders
		const deleteParams = triggerdReminderIds.map((id) => {
			return {
				[MODEL.ams_activity_reminder._name]: {
					[MODEL.ams_activity_reminder.id]: id,
				},
			};
		});
		deleteAms(
			deleteParams,
			(res) => {
				getAccountAmsList();
				closeRemoveRemindersModal();
			},
			(err) => {
				closeRemoveRemindersModal();
			}
		);
	};

	const openRemoveRemindersModal = () => {
		setIsOpenRemoveModal(true);
	};
	const closeRemoveRemindersModal = () => {
		setIsOpenRemoveModal(false);
	};

	useEffect(() => {
		if (account?.id) {
			getAccountAmsList(account?.id);
		}
	}, [account]);

	useEffect(() => {
		if (triggerdReminderNumber === 0 || !isOpen) {
			setSelectedReminder(null);
		}
	}, [isOpen, triggerdReminderNumber]);

	const userAvatar = (
		<EuiHeaderSectionItemButton
			aria-controls="headerUserMenu"
			aria-expanded={isOpen}
			aria-haspopup="true"
			notification={triggerdReminderNumber || 0}
			aria-label="Account menu"
			onClick={handleOpenReminderPopover}
		>
			<EuiIcon type="bell" size="m" />
		</EuiHeaderSectionItemButton>
	);

	return (
		<Wrapper>
			<EuiPopover
				ownFocus
				button={userAvatar}
				isOpen={isOpen}
				anchorPosition="downRight"
				closePopover={closeMenu}
				panelPaddingSize="none"
				hasArrow={false}
			>
				<ContentContainer>
					<div className="reminder_popover_header--container">
						<EuiFlexGroup gutterSize="s" alignItems="center">
							<EuiFlexItem grow={false}>
								<EuiIcon type="bell" size="m" />
							</EuiFlexItem>
							<EuiFlexItem grow={false}>
								<span className="type-small-default">Reminders</span>
							</EuiFlexItem>
							<EuiFlexItem></EuiFlexItem>
							<EuiFlexItem grow={false}>
								<div
									className="dismiss_all_button"
									onClick={() =>
										triggerdReminderNumber &&
										openRemoveRemindersModal()
									}
								>
									<span className="type-x-small">Dismiss All</span>
								</div>
							</EuiFlexItem>
						</EuiFlexGroup>
					</div>
					<div className="reminder_popover_body--container">
						<EuiFlexGroup gutterSize="xs" direction="column">
							<EuiFlexItem>
								<div className="activity_list--container">
									{amsList?.map((item, id) => {
										return (
											<ActivityNotificationItem
												key={`activity_list-item-${id}`}
												activity={item}
												selectedReminder={selectedReminder}
												onSelectReminder={handleSelectReminder}
											/>
										);
									})}
									{!amsList?.length && (
										<EuiFlexItem>
											<span className="type-small-bold text-center">
												No Reminders
											</span>
										</EuiFlexItem>
									)}
								</div>
							</EuiFlexItem>
							<EuiFlexItem>
								<EuiFlexGroup gutterSize="xs" alignItems="center">
									<EuiFlexItem className="snooze_list">
										<EuiSelect
											options={REMINDER_SNOOZE_LIST}
											value={selectedSnooze}
											onChange={handleChangeSnooze}
											disabled={!isSelectedReminder}
										/>
									</EuiFlexItem>
									<EuiFlexItem>
										<EuiButton
											color="text"
											fill
											size="s"
											className={`snooze_button set_snooze ${
												!isSelectedReminder && "disabled"
											}`}
											disabled={!isSelectedReminder}
											onClick={handleUpdateReminderTime}
										>
											Snooze
										</EuiButton>
									</EuiFlexItem>
									<EuiFlexItem></EuiFlexItem>
									<EuiFlexItem>
										<EuiButton
											color="text"
											fill
											size="s"
											className={`snooze_button dismiss_snooze ${
												!isSelectedReminder && "disabled"
											}`}
											disabled={!isSelectedReminder}
											onClick={handleDismissReminder}
										>
											Dismiss
										</EuiButton>
									</EuiFlexItem>
								</EuiFlexGroup>
							</EuiFlexItem>
						</EuiFlexGroup>
					</div>
				</ContentContainer>
			</EuiPopover>
			{isOpenRemoveModal && (
				<EuiOverlayMask>
					<EuiConfirmModal
						title="Dismiss All Reminders?"
						onCancel={closeRemoveRemindersModal}
						onConfirm={handleRemoveAllReminders}
						cancelButtonText="Cancel"
						confirmButtonText="Continue"
						buttonColor="danger"
						defaultFocusedButton="confirm"
					/>
				</EuiOverlayMask>
			)}
		</Wrapper>
	);
};

export default NotificationItem;

const Wrapper = styled.div``;

const ContentContainer = styled.div`
	min-width: 318px;

	.reminder_popover {
		&_header {
			&--container {
				display: flex;
				padding: var(--sp-3) var(--sp-4);
				min-height: var(--sp-18);
				box-shadow: inset 0px -1px 0 var(--eui-light-shade-color);
			}
		}

		&_body {
			&--container {
				display: flex;
				padding: var(--sp-4);
			}
		}
	}

	.dismiss_all_button {
		color: var(--eui-buttons-text-normal-text-color);
		cursor: pointer;
		&:hover {
			text-decoration: underline;
		}
	}

	.snooze_list {
		.euiFormControlLayout {
			height: fit-content;
			min-width: var(--sp-60);
		}
		select {
			border: 1px solid rgba(15, 39, 118, 0.1);
			box-sizing: border-box;
			box-shadow: 0px 1px 1px rgba(152, 162, 179, 0.1),
				0px 3px 2px rgba(152, 162, 179, 0.1);
			border-radius: var(--br);
		}
	}

	.snooze_button {
		min-width: 0;
		border: none;

		&.set_snooze {
			background: var(
				--eui-buttons-background-normal-default-text-color
			) !important;
			color: var(--eui-buttons-text-normal-primary-color) !important;
		}

		&.dismiss_snooze {
			background: var(
				--eui-buttons-background-normal-default-primary-color
			) !important;
			color: var(--eui-buttons-text-normal-primary-color) !important;
		}

		&.disabled {
			background: var(
				--eui-buttons-background-normal-default-disabled-color
			) !important;
			color: var(--eui-disabled-text-color) !important;
		}
	}

	.activity_list--container {
		display: grid;
		width: 100%;
		grid-template-columns: 1fr;
		gap: var(--sp-1);
	}
`;
