import React, { useEffect, useMemo, Suspense } from "react";
import { Route, Switch, useLocation } from "react-router-dom";
import { useDispatch } from "react-redux";
import styled from "styled-components";

import useSocketHandler from "core/socket/useSocketHandler";
import useDansSocket from "core/socket/useDansSocket";
import plivoService from "core/api/plivo.service";

import {
    getAccountDetail,
    fetchAllGlobals,
    getGSSList_NEW,
    getAllSit,
    fetchAllAccount,
    getAllQualifiers,
    getMacrosList,
} from "helpers/actions";
import getRoutes from "components/global/routes";
import {
    RouteWithSubRoutes,
    NotFound,
    ErrorBoundary,
} from "components/global/components";
import { _getAuthToken, _getGlobals, _getGSSList } from "helpers/selectors";
import Layout from "components/Layout/Layout";

import { AppGlobalStyles } from "global/global-styles";

import "@elastic/eui/dist/eui_theme_light.css";
import "./App.scss";

/**
 * Main Component for App
 */
function App() {
    const dispatch = useDispatch();
    const location = useLocation();
    const { pathname } = location;

    const isNoLoginPage = pathname !== "/login";
    const authToken = _getAuthToken();
    const gssList = _getGSSList();
    const globals = _getGlobals();
    const keepLogin = localStorage.getItem("keep_login");

    const routers = useMemo(() => {
        return getRoutes();
    }, []);

    // Listen to socket events
    useSocketHandler();
    useDansSocket();

    useEffect(() => {
        dispatch(fetchAllGlobals());
        dispatch(getGSSList_NEW());
    }, []);

    useEffect(() => {
        if (dispatch) {
            // init Plivo
            plivoService.init(dispatch);
        }
    }, [dispatch]);

    useEffect(() => {
        if (gssList && authToken && isNoLoginPage) {
            dispatch(getAccountDetail());
            dispatch(fetchAllAccount());
            dispatch(getAllSit());
            dispatch(getAllQualifiers());
            dispatch(getMacrosList());
        }

        if (authToken && keepLogin === "true" && !isNoLoginPage) {
            window.location.replace("/dashboard");
        }
    }, [globals, gssList, authToken, dispatch, isNoLoginPage]);

    return (
        <AppWrapper className="App">
            <AppGlobalStyles />
            <ErrorBoundary>
                <Layout hasAuth={!!authToken && isNoLoginPage}>
                    <Suspense fallback={<div>Loading ...</div>}>
                        <Switch>
                            {routers.map((route, i) => {
                                return <RouteWithSubRoutes key={i} {...route} />;
                            })}
                            <Route component={NotFound} />
                        </Switch>
                    </Suspense>
                </Layout>
            </ErrorBoundary>
        </AppWrapper>
    );
}

export default App;

const AppWrapper = styled.div`
    display: flex;
`;
