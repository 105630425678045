import React, { useState, useEffect, useRef } from "react";

import {
	EuiButtonEmpty,
	EuiButtonIcon,
	EuiButtonGroup,
	EuiPopover,
	EuiPopoverFooter,
	EuiFlexGroup,
	EuiFlexItem,
	EuiText,
	EuiTextAlign,
	EuiToken,
	EuiDragDropContext,
	EuiDraggable,
	EuiDroppable,
	EuiIcon,
	euiDragDropReorder,
} from "@elastic/eui";

import "./Sort.scss";

const Sort = ({ sortList, handleSortData }) => {
	const loaded = useRef(false);
	const [isSortMenuPopoverOpen, setIsSortMenuPopoverOpen] = useState(false);
	const [isSortListPopoverOpen, setIsSortListPopoverOpen] = useState(false);

	const onSortMenuButtonClick = () => {
		setIsSortMenuPopoverOpen(!isSortMenuPopoverOpen);
		closeSortListPopover();
	};

	const onSortListButtonClick = () => {
		setIsSortListPopoverOpen(!isSortListPopoverOpen);
	};

	const closeSortMenuPopover = () => {
		setIsSortMenuPopoverOpen(false);
		closeSortListPopover();
	};

	const closeSortListPopover = () => {
		setIsSortListPopoverOpen(false);
	};

	const [unsortedFieldList, setUnsortedFieldList] = useState([...sortList]);
	const [sortedFieldList, setSortedFieldList] = useState([]);

	useEffect(() => {
		if (loaded.current) {
			handleSortData(sortedFieldList);
		} else {
			loaded.current = true;
		}
	}, [sortedFieldList]);

	const handleSelectedSortList = (field) => {
		if (unsortedFieldList.length === 1) closeSortListPopover();

		setUnsortedFieldList(unsortedFieldList.filter((value, key) => value.id !== field.id));
		setSortedFieldList((sortedFieldList) => [...sortedFieldList, field]);
	};

	const handleDeselectedSortList = (field) => {
		setSortedFieldList(sortedFieldList.filter((value, key) => value.id !== field.id));
		setUnsortedFieldList((unsortedFieldList) => [...unsortedFieldList, field]);
	};

	const handleClearSortList = () => {
		setSortedFieldList([]);
		setUnsortedFieldList([...sortList]);
		closeSortListPopover();
	};

	const sortOptions = [
		{ id: "asc", label: "Asc" },
		{ id: "desc", label: "Desc" },
	];

	const handleSortOptionChange = (id, sortDirection) => {
		let list = [...sortedFieldList];
		const index = sortedFieldList.findIndex((obj) => obj.id == id);
		setSortedFieldList(list, (list[index].sort_dir = sortDirection));
	};

	const onDragEnd = ({ source, destination }) => {
		if (source && destination) {
			const items = euiDragDropReorder(sortedFieldList, source.index, destination.index);
			setSortedFieldList(items);
		}
	};

	const SortMenuPopover = () => {
		const sortMenuButton = (
			<EuiButtonEmpty
				className="euiDataGrid__controlBtn"
				size="xs"
				color="text"
				iconType="sortable"
				iconSide="left"
				onClick={(e) => onSortMenuButtonClick(e)}
			>
				{sortedFieldList.length > 0 ? `${sortedFieldList.length} field(s) sorted` : "Sort Fields"}
			</EuiButtonEmpty>
		);

		return (
			<EuiPopover
				id="popover"
				button={sortMenuButton}
				isOpen={isSortMenuPopoverOpen}
				closePopover={closeSortMenuPopover}
			>
				{sortedFieldList.length > 0 ? (
					<EuiDragDropContext onDragEnd={onDragEnd}>
						<EuiDroppable droppableId="sort-list-droppable-area" spacing="s">
							{sortedFieldList.map((field, index) => (
								<EuiDraggable
									spacing="s"
									key={field.id}
									index={index}
									draggableId={field.id}
									customDragHandle={true}
								>
									{(provided) => (
										<EuiFlexGroup
											key={`sorted-${field.id}`}
											gutterSize="xs"
											alignItems="center"
											direction="row"
										>
											<EuiFlexItem grow={false}>
												<EuiButtonIcon
													aria-label="Remove from sort list"
													iconType="cross"
													onClick={() => handleDeselectedSortList(field)}
												></EuiButtonIcon>
											</EuiFlexItem>
											<EuiFlexItem grow={false}>
												<EuiToken iconType={field.token} />
											</EuiFlexItem>
											<EuiFlexItem>
												<EuiText size="xs">{field.label}</EuiText>
											</EuiFlexItem>
											<EuiFlexItem>
												<EuiButtonGroup
													legend={`${field.label} Sort Direction Button Group`}
													options={sortOptions}
													buttonSize="s"
													idSelected={field.sort_dir}
													onChange={(optionId) =>
														handleSortOptionChange(field.id, optionId)
													}
													className="sort-option-group"
												/>
											</EuiFlexItem>
											<EuiFlexItem grow={false}>
												<div {...provided.dragHandleProps}>
													<EuiIcon type="grab" />
												</div>
											</EuiFlexItem>
										</EuiFlexGroup>
									)}
								</EuiDraggable>
							))}
						</EuiDroppable>
					</EuiDragDropContext>
				) : (
						<EuiText size="s">
							<EuiTextAlign textAlign="center">
								<p>Currently no fields are sorted</p>
							</EuiTextAlign>
						</EuiText>
					)}

				<EuiPopoverFooter>
					<EuiFlexGroup justifyContent="spaceBetween">
						<EuiFlexItem grow={false}>
							<SortListPopover />
						</EuiFlexItem>
						{sortedFieldList.length > 0 ? (
							<EuiFlexItem grow={false}>
								<EuiButtonEmpty size="xs" onClick={() => handleClearSortList([])}>
									Clear Sorting
								</EuiButtonEmpty>
							</EuiFlexItem>
						) : null}
					</EuiFlexGroup>
				</EuiPopoverFooter>
			</EuiPopover>
		);
	};

	const SortListPopover = () => {
		const sortListButton = (
			<EuiButtonEmpty
				size="xs"
				iconType={isSortListPopoverOpen ? "arrowUp" : "arrowDown"}
				iconSide="right"
				onClick={() => onSortListButtonClick()}
			>
				Pick fields to sort by
			</EuiButtonEmpty>
		);

		return (
			unsortedFieldList &&
			unsortedFieldList.length > 0 && (
				<EuiPopover
					id="popover"
					button={sortListButton}
					isOpen={isSortListPopoverOpen}
					closePopover={() => { }}
				>
					{unsortedFieldList.map((field) => (
						<EuiFlexGroup
							key={`unsorted-${field.id}`}
							gutterSize="s"
							alignItems="center"
							direction="row"
							onClick={() => (!field.disabled ? handleSelectedSortList(field) : null)}
							className={`unsorted-list-row ${field.disabled ? "sort-option-disabled" : ""}`}
						>
							<EuiFlexItem grow={false}>
								<EuiToken iconType={field.token} />
							</EuiFlexItem>

							<EuiFlexItem grow={false}>
								<EuiText size="xs">{field.label}</EuiText>
							</EuiFlexItem>
						</EuiFlexGroup>
					))}
				</EuiPopover>
			)
		);
	};

	return <SortMenuPopover />;
};

export default Sort;
