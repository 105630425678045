export const JOB_APPLICANT_STATUS_LIST = {
	new: {
		key: "new",
		value: 1,
		label: "New",
		icon: "-",
		color: "var(--button-disabled-color)",
	},
	rejected: {
		key: "rejected",
		value: 13,
		label: "Rejected",
		icon: "R",
		color: "var(--eui-cancelled-color)",
	},
	contacted: {
		key: "contacted",
		value: 2,
		label: "Contacted",
		icon: "C",
		color: "var(--eui-darkshade-color)",
	},
	screening: {
		key: "screening",
		value: 3,
		label: "Qualifying",
		icon: "Q",
		color: "var(--eui-primary-color)",
	},
	internalSubmission: {
		key: "internalSubmission",
		value: 4,
		label: "Internal Submission",
		icon: "IS",
		color: "var(--eui-primary-color)",
	},
	// dark: {
	// 	key: "dark",
	// 	value: 6,
	// 	label: "Dark",
	// 	icon: "D",
	// 	color: "var(--gray-1)",
	// },
	submission: {
		key: "submission",
		value: 5,
		label: "Submission",
		icon: "S",
		color: "var(--eui-secondary-color)",
	},
	interview: {
		key: "interview",
		value: 6,
		label: "Interview",
		icon: "I",
		color: "var(--eui-secondary-color)",
	},
	interviewFeedback: {
		key: "interviewFeedback",
		value: 7,
		label: "Interview Feedback",
		icon: "IF",
		color: "var(--eui-secondary-color)",
	},
	offerPending: {
		key: "offerPending",
		value: 8,
		label: "Offer Pending",
		icon: "OP",
		color: "var(--core-warning-color)",
	},
	offerSent: {
		key: "offerSent",
		value: 9,
		label: "Offer Sent",
		icon: "OS",
		color: "var(--core-warning-color)",
	},
	offerDecision: {
		key: "offerDecision",
		value: 10,
		label: "Offer Decision",
		icon: "OD",
		color: "var(--core-warning-color)",
	},
	placed: {
		key: "placed",
		value: 11,
		label: "Placed",
		icon: "P",
		color: "var(--warning-text-color)",
	},
	fallOff: {
		key: "fallOff",
		value: 12,
		label: "Fall Off",
		icon: "FO",
		color: "var(--eui-cancelled-color)",
	},
};
