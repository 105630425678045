import React, { useState } from "react";
import styled from "styled-components";
import { cloneDeep, size } from "lodash";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faLink } from "@fortawesome/pro-solid-svg-icons/faLink";
import { faTwitter } from "@fortawesome/free-brands-svg-icons/faTwitter";
import { faShareAlt } from "@fortawesome/pro-light-svg-icons/faShareAlt";
import { faFacebook } from "@fortawesome/free-brands-svg-icons/faFacebook";
import { faInstagram } from "@fortawesome/free-brands-svg-icons/faInstagram";
import { faCheckCircle } from "@fortawesome/pro-solid-svg-icons/faCheckCircle";

import { hasPrimaryChanged } from "helpers/utilities";
import { AsyncEditableContent } from "components/Common/asyncComponents";

/**
 * Main Component for Person Social Media
 */
const PersonSocialMedia = (props) => {
	const { personId, data, syncData, loadData } = props;

	const [loading, setLoading] = useState(false);

	const onAddHandler = (editedData, setEditState, addedItemNewIndex, setAddedItemNewIndex) => {
		let newData = null;

		if (editedData.length > 0) {
			newData = cloneDeep(editedData);
		} else {
			newData = [];
		}

		const personNewId = "new__" + addedItemNewIndex;

		newData.push({
			person_external_link_id: personNewId,
			person_main_ref_id: personId,
			headline_value: "URL",
			link_type: "personal-site",
			url_value: "",
		});

		setEditState(newData);
		setAddedItemNewIndex(addedItemNewIndex + 1);
	};

	const onSaveHandler = (editedData, data) => {
		if (!editedData || !data) return;

		/**
		 * Determine deleted external links.
		 */

		// Get all tracking ids for both.
		const dataExternalLinks = [];
		const editedDataExternalLinks = [];

		for (let i in data) {
			dataExternalLinks.push(data[i]["person_external_link_id"]);
		}

		for (let i in editedData) {
			editedDataExternalLinks.push(editedData[i]["person_external_link_id"]);
		}

		// Determine removed tracking ids from the "editedData" (we will delete these)
		const deletedItems = [];

		for (let i in data) {
			const personalExternalLinkId = data[i]["person_external_link_id"];

			if (!editedDataExternalLinks.includes(personalExternalLinkId)) {
				deletedItems.push(personalExternalLinkId);
			}
		}

		/**
		 * Determine edited external links.
		 */

		// Do updates (Check similar ids and if they are different, then push them into update container)
		const editedItems = {};

		for (let i in data) {
			for (let j in editedData) {
				const {
					person_external_link_id: dataPersonExternalLinkId,
					url_value: personalSiteValue,
				} = data[i];

				const {
					person_external_link_id: editedPersonExternalLinkId,
					url_value: editedDataValue,
				} = editedData[j];

				const editedDataPersonExternalLinkId = editedData[j]["person_external_link_id"];

				if (dataPersonExternalLinkId === editedPersonExternalLinkId) {
					if (personalSiteValue !== editedDataValue) {
						let filterList = [
							"person_external_link_id",
							"person_main_ref_id",
							"headline_value",
							"link_type",
							"url_value",
						];
						let filteredEditedData = {
							...editedData[j],
						};

						for (let i in filteredEditedData) {
							if (!filterList.includes(i)) {
								delete filteredEditedData[i];
							}
						}

						editedItems[editedDataPersonExternalLinkId] = {
							...filteredEditedData,
							url_value: editedDataValue,
						};
					}
				}
			}
		}

		/**
		 * Do insert (pending).
		 */
		const addedItems = [];

		for (let i in editedData) {
			const personExternalLinkId = editedData[i]["person_external_link_id"];

			if (personExternalLinkId.toString().search(/new__/) !== -1) {
				const newValue = editedData[i]["url_value"];

				if (newValue !== "") {
					let addedData = cloneDeep(editedData[i]);

					delete addedData.person_external_link_id;

					addedItems.push(addedData);
				}
			}
		}

		/**
		 * Perform delete, update, and add operations if any variables change.
		 */
		const isPrimaryUpdated = hasPrimaryChanged(editedData, data, "person_external_link_id");

		if (
			size(deletedItems) !== 0 ||
			size(editedItems) !== 0 ||
			size(addedItems) !== 0 ||
			isPrimaryUpdated
		) {
			if (size(addedItems) !== 0) {
				for (let i in addedItems) {
					const { url_value } = addedItems[i];

					if (url_value.search("facebook.com") !== -1) {
						addedItems[i]["link_type"] = "facebook";
					} else if (url_value.search("instagram.com") !== -1) {
						addedItems[i]["link_type"] = "instagram";
					} else if (url_value.search("twitter.com") !== -1) {
						addedItems[i]["link_type"] = "twitter";
					} else {
						addedItems[i]["link_type"] = "generic";
					}
				}
			}

			if (size(editedItems) !== 0) {
				for (let i in editedItems) {
					const { url_value } = editedItems[i];

					if (url_value.search("facebook.com") !== -1) {
						editedItems[i]["link_type"] = "facebook";
					} else if (url_value.search("instagram.com") !== -1) {
						editedItems[i]["link_type"] = "instagram";
					} else if (url_value.search("twitter.com") !== -1) {
						editedItems[i]["link_type"] = "twitter";
					} else {
						editedItems[i]["link_type"] = "generic";
					}
				}
			}

			const addedItemsComposed = [];
			const editedItemsComposed = [];
			const deletedItemsComposed = [];

			for (let i in addedItems) {
				addedItemsComposed.push({
					// action: ADD_UPDATE_PERSON_EXTERNAL_LINK,
					args: addedItems[i],
				});
			}

			for (let i in editedItems) {
				editedItemsComposed.push({
					// action: ADD_UPDATE_PERSON_EXTERNAL_LINK,
					args: editedItems[i],
				});
			}

			for (let i in deletedItems) {
				deletedItemsComposed.push({
					// action: DELETE_PERSON_EXTERNAL_LINK,
					args: deletedItems[i],
				});
			}

			const isPrimaryUpdatedData = [];

			// Append an is primary command.
			if (isPrimaryUpdated) {
				isPrimaryUpdatedData.push({
					args: {
						person_main_ref_id: personId,
						person_table_type_c: "external_link",
						person_record_ref_id: isPrimaryUpdated,
					},
				});
			}

			syncData(
				deletedItemsComposed,
				editedItemsComposed,
				addedItemsComposed,
				isPrimaryUpdatedData,
				setLoading,
				loadData
			);
		} else {
			alert("nothing to edit");
		}
	};

	const renderData = (data) => {
		return (
			<RenderDataWrapper>
				{data.map((item, id) => {
					if (!item) return <></>;

					const { url_value, person_external_link_id, link_type, is_primary } = item;

					const getIconType = (linkType) => {
						if (linkType === "generic") {
							return faLink;
						}

						if (linkType === "facebook") {
							return faFacebook;
						}

						if (linkType === "twitter") {
							return faTwitter;
						}

						if (linkType === "instagram") {
							return faInstagram;
						}
					};

					const socialUrl = url_value?.includes("https://")
						? url_value
						: `https://${url_value ?? ""}`;

					return (
						<div key={person_external_link_id ?? `person-external-link-id-${id}`}>
							<a target="_blank" href={socialUrl}>
								<FontAwesomeIcon icon={getIconType(link_type)} />
								&nbsp;
							</a>
							{is_primary === 1 ? (
								<span style={{ margin: "0 5px 0 5px", color: "#006BB4" }}>
									<FontAwesomeIcon icon={faCheckCircle} />
								</span>
							) : null}
						</div>
					);
				})}
			</RenderDataWrapper>
		);
	};

	return (
		<AsyncEditableContent
			isLoading={loading}
			icon={faShareAlt}
			data={data}
			placeholder={"URL"}
			hasPrimaryOption={true}
			editTrackingKey={"person_external_link_id"}
			editDisplayKey={"url_value"}
			onAddHandler={onAddHandler}
			onSaveHandler={onSaveHandler}
			render={renderData}
		/>
	);
};

export default PersonSocialMedia;

const RenderDataWrapper = styled.div`
	display: flex;
	flex-direction: row;
`;
