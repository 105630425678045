import React, { useEffect, useState } from "react";
import { EuiFieldText, EuiFlexItem, EuiFlexGroup, EuiButtonIcon } from "@elastic/eui";
import styled from "styled-components";

import useToggle from "components/Hooks/useToggle";

/**
 * Component for General Name
 */
const EditableGeneralName = (props) => {
	const { name: initName, onSave, styleName = {}, ...restProps } = props;

	const [name, setName] = useState("");

	const [isHovered, setHovered] = useToggle(false);
	const [isEditable, setEditable] = useToggle(false);

	const hoverHanlder = () => {
		setHovered();
	};

	const handleEdit = (e) => {
		setEditable();
	};

	const handleChangeName = (e) => {
		e.stopPropagation();
		setName(e.target.value);
	};

	const handleSaveName = (e) => {
		e.stopPropagation();
		onSave(name);
		setEditable();
	};

	const handleCancle = (e) => {
		e.stopPropagation();
		setEditable();
		setName(initName);
	};

	useEffect(() => {
		setName(initName);
	}, [initName]);

	return (
		<Wrapper onMouseEnter={hoverHanlder} onMouseLeave={hoverHanlder} {...restProps}>
			<EuiFlexGroup gutterSize="s" alignItems="center">
				{!isEditable ? (
					<EuiFlexItem>
						<h3 style={styleName}>{name || "-"}</h3>
					</EuiFlexItem>
				) : (
					<>
						<EuiFlexItem grow={false}>
							<EuiFieldText value={name} onChange={handleChangeName} />
						</EuiFlexItem>
						<EuiFlexItem grow={false}>
							<EuiButtonIcon
								color="success"
								onClick={handleSaveName}
								iconType="check"
							/>
						</EuiFlexItem>
						<EuiFlexItem grow={false}>
							<EuiButtonIcon
								color="danger"
								onClick={handleCancle}
								iconType="cross"
							/>
						</EuiFlexItem>
					</>
				)}

				{isHovered && !isEditable ? (
					<EuiFlexItem grow={false}>
						<EuiButtonIcon onClick={handleEdit} iconType="pencil" />
					</EuiFlexItem>
				) : null}
			</EuiFlexGroup>
		</Wrapper>
	);
};

export default React.memo(EditableGeneralName);

const Wrapper = styled.div`
	display: flex;

	.euiFormControlLayout {
		height: fit-content;
	}
`;
