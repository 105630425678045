import React from "react";
import styled from "styled-components";
import { EuiFlexGroup, EuiFlexItem, EuiTabbedContent } from "@elastic/eui";
import JobDetail from "./JobDetail";
import JobOverview from "./JobOverview";

/**
 * Main Components
 */
const JobMain = (props) => {
    const { application, saveOverview, ...restProps } = props;

    // functions
    const handleSaveOverview = (value) => {
        saveOverview(value);
    };

    // tabs
    const tabs = [
        {
            id: "overview",
            name: "Overview",
            content: (
                <JobOverview application={application} saveValue={handleSaveOverview} />
            ),
        },
        {
            id: "detail",
            name: "Deal Details",
            content: <JobDetail />,
        },
    ];
    return (
        <Wrapper {...restProps}>
            <EuiFlexGroup gutterSize="s" direction="row" justifyContent="spaceBetween">
                <EuiFlexItem>
                    <EuiTabbedContent
                        tabs={tabs}
                        initialSelectedTab={tabs[0]}
                        autoFocus="selected"
                        onTabClick={() => {}}
                        className="main-tab"
                    />
                </EuiFlexItem>
            </EuiFlexGroup>
        </Wrapper>
    );
};

export default JobMain;

/**
 * Styled Components
 */
const Wrapper = styled.div`
    .euiTab__content {
        font-size: 1.25rem;
    }
`;
