import React, { useMemo, useState } from "react";
import styled from "styled-components";
import { EuiPopover, EuiFlexGroup, EuiFlexItem } from "@elastic/eui";

import { AMS_REMINDER_TIME_TYPE_LIST } from "components/global/constants";

/**
 * Component for AMS Reminder Time Type Item with editable feature
 */
const AmsReminderTimeTypeItem = (props) => {
	const { onChange, type = 1, isEdit = true } = props;

	const [isOpenPopover, setIsOpenPopover] = useState(false);

	const { amsReminderTimeType } = useMemo(() => {
		const amsReminderTimeType =
			Object.values(AMS_REMINDER_TIME_TYPE_LIST).find(
				(item) => item.value === type
			) || AMS_REMINDER_TIME_TYPE_LIST.minutes;

		return {
			amsReminderTimeType,
		};
	}, [type]);

	const handleOpenPopover = () => {
		if (isEdit) {
			setIsOpenPopover(true);
		} else {
			setIsOpenPopover(false);
		}
	};

	const handleClosePopover = () => {
		setIsOpenPopover(false);
	};

	const handleChangeType = (newType) => {
		if (onChange) {
			onChange(newType);
		}

		setIsOpenPopover(false);
	};

	return (
		<EuiPopover
			isOpen={isOpenPopover}
			button={
				<div
					onClick={handleOpenPopover}
					className="cursor-pointer hover-underline reminder-time-type-item-label"
					style={{ color: "var(--eui-link-color)" }}
				>
					<p>{amsReminderTimeType.label}</p>
				</div>
			}
			closePopover={handleClosePopover}
			panelStyle={{
				width: 110,
				padding: "0.5rem 0.75rem",
			}}
		>
			<EuiFlexGroup direction="column" gutterSize="s">
				{Object.values(AMS_REMINDER_TIME_TYPE_LIST).map((item) => {
					return (
						<EuiFlexItem key={`ams-status-item-${item.value}`}>
							<StatusListItem
								gutterSize="s"
								alignItems="center"
								className="cursor-pointer"
								onClick={() => handleChangeType(item.value)}
							>
								<EuiFlexItem>
									<p>{item.label}</p>
								</EuiFlexItem>
							</StatusListItem>
						</EuiFlexItem>
					);
				})}
			</EuiFlexGroup>
		</EuiPopover>
	);
};

export default AmsReminderTimeTypeItem;

const StatusListItem = styled(EuiFlexGroup)`
	svg {
		width: var(--sp-8);
		height: auto;
	}
`;
