import React from "react";
import styled from "styled-components";
import { EuiFlexGrid, EuiFlexItem, EuiFlexGroup } from "@elastic/eui";

import EditableAddress from "./EditableAddress";
import { PhoneItemsView } from "../Phone";
import { GLOBAL_PHONE_TYPE_OPTIONS } from "components/global/constants";

/**
 * Component for displaying Address List
 */
const AddressList = (props) => {
	const {
		addresses,
		primaryAddressId,
		primaryPhoneId,
		onSaveAddress,
		onDeleteAddress,
		onSavePrimaryAddress,
		onSavePhone,
		onDeletePhone,
		onSavePrimaryPhone,
		linkList,
	} = props;

	const saveAddressPrimary = (primaryAddressId) => {
		onSavePrimaryAddress(primaryAddressId);
	};

	const handleDelete = (addressId) => {
		onDeleteAddress(addressId);
	};

	const getAddressPrimary = (itemIndex) => {
		let res = false;
		if (addresses?.[itemIndex]?.id) {
			if (addresses?.[itemIndex]?.id === primaryAddressId) {
				res = true;
			}
		}
		return res;
	};

	const handleSaveAddress = (data, primary) => {
		onSaveAddress(data, (res, successCallBack) => {
			if (primary || (!primary && data.id === primaryAddressId)) {
				saveAddressPrimary(primary ? data?.id : null);
			} else {
				if (successCallBack) {
					successCallBack();
				}
			}
		});
	};

	const handleSavePhone = (address, phone, successCallBack) => {
		onSavePhone(address, phone, successCallBack);
	};

	const handleDeletePhone = (phoneId) => {
		onDeletePhone(phoneId);
	};

	const handleSavePrimaryPhone = (primaryPhoneId, successCallBack, failCallBack) => {
		onSavePrimaryPhone(primaryPhoneId, successCallBack, failCallBack);
	};

	return (
		<Wrapper>
			<EuiFlexGrid columns={3} direction="row" gutterSize="s">
				{addresses?.map((address, idx) => {
					const phones = linkList[idx]?.slice(0, 1);
					return (
						<EuiFlexItem key={`address-list-item-${idx}`}>
							<EuiFlexGroup gutterSize="none">
								<EuiFlexItem grow={false}>
									<span className="address-index-number">
										{idx + 1}.
									</span>
								</EuiFlexItem>
								<EuiFlexItem>
									<EuiFlexGroup gutterSize="none" direction="column">
										<EuiFlexItem>
											<EditableAddress
												address={address}
												isPrimary={getAddressPrimary(idx)}
												onSave={handleSaveAddress}
												onDelete={handleDelete}
												hasIcon={false}
											/>
										</EuiFlexItem>
										<EuiFlexItem>
											<PhoneItemsView
												primaryPhoneId={primaryPhoneId}
												phones={phones}
												onSavePhone={(
													phoneData,
													successCallBack
												) =>
													handleSavePhone(
														address,
														phoneData,
														successCallBack
													)
												}
												onDeletePhone={handleDeletePhone}
												onSavePrimary={handleSavePrimaryPhone}
												maxCount={1}
												isFixedType={true}
												fixedType={
													GLOBAL_PHONE_TYPE_OPTIONS[2].value
												}
											/>
										</EuiFlexItem>
									</EuiFlexGroup>
								</EuiFlexItem>
							</EuiFlexGroup>
						</EuiFlexItem>
					);
				})}
				{!addresses?.length ? (
					<EuiFlexItem>
						<span className="no-items">No available locations</span>
					</EuiFlexItem>
				) : null}
			</EuiFlexGrid>
		</Wrapper>
	);
};

export default AddressList;

const Wrapper = styled.div`
	.address-index-number {
		font-size: 1rem;
		font-weight: bold;
		line-height: 1.5;
		margin-top: 0.5rem;
		min-width: 1.5rem;
		text-align: center;
	}
	.no-items {
	}
`;
