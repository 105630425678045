import React, { useState, useMemo, useEffect } from "react";
import styled from "styled-components";
import { cloneDeep } from "lodash";
import moment from "moment";

import * as MODEL from "core/model";
import { getGCSValue, getGSSValue } from "components/global/utils";
import { SaveCancelBtn } from "../CustomComponents";

import { AmsEditNewItem } from "./AmsEditItem";
import { refineObject } from "helpers/utilities";
import { useAccount, useGlobal } from "core/useHooks";

const getToday = () => {
	return moment().format("YYYY-MM-DD H:mm:ss");
};

const AmsNewItem = (props) => {
	const {
		person,
		onSaveEmail,
		onDeleteEmail,
		onSavePhone,
		onDeletePhone,
		onCancel,
		onSave,
		globalSearchItems,
		getGlobalSearchItems,
	} = props;

	const { globals, gssList } = useGlobal();
	const { accounts, account } = useAccount();

	const [data, setData] = useState({
		[MODEL.ams_activity_main.activity_name]: "New Activity",
		[MODEL.ams_activity_main.activity_type_c]: 1, // generic
		[MODEL.ams_activity_main.activity_status_c]: 1, // pending
		[MODEL.ams_activity_main.activity_view_type_c]: 1, // note view
		[MODEL.ams_activity_main.activity_location_c]: 1, // phone
		[MODEL.ams_activity_main.activity_creator_account_ref_id]: account?.id,
		[MODEL.ams_activity_task._name]: [],
		[MODEL.ams_activity_note._name]: [],
		[MODEL.ams_activity_time._name]: [
			{
				[MODEL.ams_activity_time.date_start]: getToday(),
				[MODEL.ams_activity_time.date_end]: getToday(),
			},
		],
	});

	const [ownerIds, setOwnerIds] = useState([]);
	const [assignIds, setAssignIds] = useState([]);
	const [restrictionValue, setRestrictionValue] = useState(null);

	const { owners } = useMemo(() => {
		const owners = [];

		if (ownerIds?.length > 0 && globals?.length > 0) {
			const ownerAssignTypeC = getGCSValue(
				globals,
				"ras_assignment_main",
				"assignment_type_c",
				"owner"
			);

			const ownerObejctTypeC = getGCSValue(
				globals,
				"ras_assignment_main",
				"object_type_c",
				"activity"
			);

			ownerIds.forEach((id, index) => {
				const owner = {
					[MODEL.ras_assignment_main.id]: index + 1,
					[MODEL.ras_assignment_main.object_ref_id]: 0,
					[MODEL.ras_assignment_main.object_type_c]: ownerObejctTypeC,
					[MODEL.ras_assignment_main.assignment_type_c]: ownerAssignTypeC,
					[MODEL.ras_assignment_main.assignment_ref_id]: id,
				};
				owners.push(owner);
			});
		}

		return {
			owners,
		};
	}, [ownerIds, globals]);

	const { assignees } = useMemo(() => {
		const assignees = [];

		if (assignIds?.length > 0 && globals?.length > 0) {
			const assigneeAssignTypeC = getGCSValue(
				globals,
				"ras_assignment_main",
				"assignment_type_c",
				"assigned"
			);

			const assigneeObejctTypeC = getGCSValue(
				globals,
				"ras_assignment_main",
				"object_type_c",
				"activity"
			);

			assignIds.forEach((id, index) => {
				const assignee = {
					[MODEL.ras_assignment_main.id]: index + 1,
					[MODEL.ras_assignment_main.assignment_ref_id]: id,
					[MODEL.ras_assignment_main.assignment_type_c]: assigneeAssignTypeC,
					[MODEL.ras_assignment_main.object_ref_id]: 0,
					[MODEL.ras_assignment_main.object_type_c]: assigneeObejctTypeC,
				};
				assignees.push(assignee);
			});
		}

		return {
			assignees,
		};
	}, [assignIds, globals]);

	const { restriction } = useMemo(() => {
		let restriction = null;

		if (globals?.length > 0 && restrictionValue) {
			const restrictionObejctTypeC = getGCSValue(
				globals,
				"ras_assignment_main",
				"object_type_c",
				"activity"
			);

			restriction = {
				[MODEL.ras_restriction_main.id]: 1,
				[MODEL.ras_restriction_main.object_type_c]: restrictionObejctTypeC,
				[MODEL.ras_restriction_main.object_ref_id]: 0,
				[MODEL.ras_restriction_main.restriction_type_c]: restrictionValue,
			};
		}

		return {
			restriction,
		};
	}, [restrictionValue, globals]);

	const handleSaveRAS = (rasData) => {
		const {
			addOwnerIds,
			deleteOwnerIds,
			addAssigneeIds,
			deleteAssigneeIds,
			restrictionTypeC,
		} = rasData;

		const newOwnerIds = cloneDeep(ownerIds);
		const newAssignIds = cloneDeep(assignIds);

		// Update ownerIds
		deleteOwnerIds.forEach((idx) => {
			const index = idx - 1;
			newOwnerIds.splice(index, 1);
		});
		addOwnerIds.forEach((id) => {
			const ownerId = Number(id);
			newOwnerIds.push(ownerId);
		});

		// Update assignIds
		deleteAssigneeIds.forEach((idx) => {
			const index = idx - 1;
			newAssignIds.splice(index, 1);
		});
		addAssigneeIds.forEach((id) => {
			const ownerId = Number(id);
			newAssignIds.push(ownerId);
		});

		// Update restriction
		const newRestrictionValue = restrictionTypeC ? restrictionTypeC : null;

		// Update RAS information
		setOwnerIds(newOwnerIds);
		setAssignIds(newAssignIds);
		setRestrictionValue(newRestrictionValue);

		handleSave(data, newOwnerIds, newAssignIds, newRestrictionValue);
	};

	const handleCancel = () => {
		onCancel();
	};

	const handleSave = (data, ownerIds, assignIds, restrictionValue) => {
		const newAmsData = cloneDeep(data);

		// Get ams_activity_main information for AMS
		const amsMainInfo = {
			[MODEL.ams_activity_main._name]: {
				[MODEL.ams_activity_main.activity_name]:
					newAmsData[MODEL.ams_activity_main.activity_name],
				[MODEL.ams_activity_main.activity_status_c]:
					newAmsData[MODEL.ams_activity_main.activity_status_c],
				[MODEL.ams_activity_main.activity_type_c]:
					newAmsData[MODEL.ams_activity_main.activity_type_c],
				[MODEL.ams_activity_main.activity_view_type_c]:
					newAmsData[MODEL.ams_activity_main.activity_view_type_c],
				[MODEL.ams_activity_main.activity_creator_account_ref_id]:
					newAmsData[MODEL.ams_activity_main.activity_creator_account_ref_id],
			},
		};

		// Get ams_activity_time information for AMS
		const timeData = newAmsData[MODEL.ams_activity_time._name]?.[0];
		const amsTimeInfo = {
			[MODEL.ams_activity_time._name]: {
				[MODEL.ams_activity_time.date_start]:
					timeData[MODEL.ams_activity_time.date_start],
				[MODEL.ams_activity_time.date_end]:
					timeData[MODEL.ams_activity_time.date_end],
			},
		};

		// Get ams_activity_note information for AMS
		const notes = newAmsData[MODEL.ams_activity_note._name];
		const amsNoteInfo =
			notes.map((note) => {
				delete note.index;
				delete note.id;
				delete note.date_created;
				const newNote = refineObject(note);
				return {
					[MODEL.ams_activity_note._name]: newNote,
				};
			}) || [];

		// Get ams_activity_task information for AMS
		const tasks = newAmsData[MODEL.ams_activity_task._name];
		const amsTaskInfo =
			tasks.map((task) => {
				delete task.index;
				delete task.id;
				const newNTask = refineObject(task);

				return {
					[MODEL.ams_activity_task._name]: newNTask,
				};
			}) || [];

		// Get ams_activity_reference information for AMS
		const objTypeSC = getGSSValue(gssList, "OBJECT_BASE", "PERSON");
		const objSubtypeSC = getGSSValue(gssList, "OBJECT_PERSON", "MAIN");
		const categoryC = getGCSValue(
			globals,
			"ams_activity_reference",
			"reference_category_c",
			"assigned"
		);
		const amsReferenceInfo = {
			[MODEL.ams_activity_reference._name]: {
				[MODEL.ams_activity_reference.reference_object_type_sc]: objTypeSC,
				[MODEL.ams_activity_reference.reference_object_subtype_sc]: objSubtypeSC,
				[MODEL.ams_activity_reference.reference_object_record_ref_id]: person?.id,
				[MODEL.ams_activity_reference.reference_category_c]: categoryC,
			},
		};

		const newAmsInfo = [
			amsMainInfo,
			amsReferenceInfo,
			amsTimeInfo,
			...amsNoteInfo,
			...amsTaskInfo,
		];

		// Save RAS information
		const rasData = {
			addOwnerIds: ownerIds,
			deleteOwnerIds: [],
			addAssigneeIds: assignIds,
			deleteAssigneeIds: [],
			restrictionTypeC: restrictionValue,
		};

		// Save activity information
		onSave(newAmsInfo, rasData);
	};

	const handleUpdateAmsData = (amsData) => {
		const oldData = cloneDeep(data);
		const newData = {
			...oldData,
			...amsData,
		};

		setData(newData);
		handleSave(newData, ownerIds, assignIds, restrictionValue);
	};

	const handleDeleteAmsData = (amsData) => {
		const newData = cloneDeep(data);

		// If amsData notetask
		if (amsData[MODEL.ams_activity_task._name]) {
			const taskIndex = amsData[MODEL.ams_activity_task._name];
			newData[MODEL.ams_activity_task._name].splice(taskIndex, 1);
		}

		// If amsData is note
		if (amsData[MODEL.ams_activity_note._name]) {
			const noteIndex = amsData[MODEL.ams_activity_note._name];
			newData[MODEL.ams_activity_note._name].splice(noteIndex, 1);
		}

		setData(newData);
		handleSave(newData, ownerIds, assignIds, restrictionValue);
	};

	useEffect(() => {
		const accountId = account?.id;
		if (accountId) {
			const newData = cloneDeep(data);
			newData[MODEL.ams_activity_main.activity_creator_account_ref_id] = accountId;

			setData(newData);
			setOwnerIds([accountId]);
			setAssignIds([accountId]);
		}
	}, [account]);

	return (
		<Wrapper>
			<AmsEditNewItem
				data={data}
				person={person}
				account={account}
				accounts={accounts}
				restriction={restriction}
				owners={owners}
				assignees={assignees}
				onChangeEdit={() => {}}
				onUpdateAms={handleUpdateAmsData}
				onDeleteAms={handleDeleteAmsData}
				onSavePhone={onSavePhone}
				onDeletePhone={onDeletePhone}
				onSaveEmail={onSaveEmail}
				onDeleteEmail={onDeleteEmail}
				onSaveRAS={handleSaveRAS}
				globalSearchItems={globalSearchItems}
				getGlobalSearchItems={getGlobalSearchItems}
			/>
			<div className="ams-save-cancel-buttons">
				<SaveCancelBtn
					onChangeCancel={handleCancel}
					onChangeSave={() => handleSave(data, ownerIds, assignIds, null)}
				/>
			</div>
		</Wrapper>
	);
};

export default AmsNewItem;

const Wrapper = styled.div`
	display: flex;
	position: relative;
	flex-direction: column;
	width: 100%;

	border-bottom: var(--sp-px) solid var(--border-color);
	margin-bottom: var(--sp-8);

	.ams-save-cancel-buttons {
		margin-top: var(--sp-4);
		margin-bottom: var(--sp-4);
	}
`;
