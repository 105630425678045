import * as actionTypes from "./actionTypes";

/**
 * Functions
 */
const setRasRestrictions = (state, payload) => {
	return {
		...state,
		restriction: [...state.restriction, payload],
	};
};

const setRasAssignments = (state, payload) => {
	return {
		...state,
		assgiment: [...state.restriction, payload],
	};
};

/**
 * Initial State
 */
const initialState = {
	restriction: [],
	assgiment: [],
};

/**
 * Reducer
 */
const Reducer = (state = initialState, action) => {
	switch (action.type) {
		case actionTypes.SET_RAS_RESTRICTIONS:
			return setRasRestrictions(state, action.payload);

		case actionTypes.SET_RAS_ASSIGNMENTS:
			return setRasAssignments(state, action.payload);

		default: {
			return state;
		}
	}
};

export default Reducer;
