import React, { useMemo } from "react";
import styled from "styled-components";
import { pBadgeIcon, wBadgeIcon } from "components/Common/Icons/Icons";

import EditableSelect from "../CustomComponents/EditableSelect";

/**
 * Selection for Personal/Work in phoene/email
 */
const EmailPhoneRangeSelectionItem = (props) => {
	const { range, onChange, ...restProps } = props;

	const optionPhoneRanges = useMemo(() => {
		return [
			{
				id: "personal",
				value: false,
				label: <RangeIconWrapper>{pBadgeIcon()}</RangeIconWrapper>,
			},
			{
				id: "work",
				value: true,
				label: <RangeIconWrapper>{wBadgeIcon()}</RangeIconWrapper>,
			},
		];
	}, []);

	return (
		<Wrapper {...restProps}>
			<EditableSelect
				options={optionPhoneRanges}
				value={range}
				onChange={onChange}
				hasUnderline={false}
				panelWidth={30}
				panelStyle={{
					padding: "var(--sp-2)",
					marginLeft: "5px",
					marginTop: "4px",
				}}
				hasArrow={false}
			/>
		</Wrapper>
	);
};

export default EmailPhoneRangeSelectionItem;

const Wrapper = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;
	min-width: var(--sp-12);
	min-height: var(--sp-12);
	background: var(--eui-form-background-color);
	border: var(--sp-0_5) solid var(--border-color);
	border-radius: var(--sp-1_5);
	padding-left: 1px;
`;

const RangeIconWrapper = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
`;
