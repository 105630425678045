/**
 * Sourcing
 */
export const GET_PEOPLE_LIST_BY_SOURCING_SEARCH_ID =
	"@@sourcing/GET_PEOPLE_LIST_BY_SOURCING_SEARCH_ID";
export const GET_SOURCING_DETAIL = "@@sourcing/GET_SOURCING_DETAIL";
export const GET_ALL_SOURCING_CREDENTIAL = "@@sourcing/GET_ALL_SOURCING_CREDENTIAL";
export const SET_SOURCING_FLYOUT = "@@sourcing/SET_SOURCING_FLYOUT";
export const SET_SOURCING_ID = "@@sourcing/SET_SOURCING_ID";
export const SET_SOURCING_EDIT_FLYOUT = "@@sourcing/SET_SOURCING_EDIT_FLYOUT";
export const SET_SOURCING_EDIT_DATA = "@@sourcing/SET_SOURCING_EDIT_DATA";
export const SET_SOURCING_SEARCH_ID = "@@sourcing/SET_SOURCING_SEARCH_ID";
export const SAVE_SOURCING = "@@sourcing/SAVE_SOURCING";
export const ADD_URL_SOURCING_SEARCH = "@@sourcing/ADD_URL_SOURCING_SEARCH";
export const REMOVE_URL_SOURCING_SEARCH = "@@sourcing/REMOVE_URL_SOURCING_SEARCH";
export const EDIT_SOURCING = "@@sourcing/EDIT_SOURCING";
export const DELETE_SOURCING = "@@sourcing/DELETE_SOURCING";
export const RESTART_SOURCING_PROCESS = "@@sourcing/RESTART_SOURCING_PROCESS";
export const REMOVE_SOURCING_URL_SEARCH = "@@sourcing/REMOVE_SOURCING_URL_SEARCH";
export const SET_ACTIVE_PAGE = "@@sourcing/SET_ACTIVE_PAGE";
export const SET_SOURCING_FILTER = "@@sourcing/SET_SOURCING_FILTER";
export const SET_SOURCING_SEARCH_VALUE = "@@sourcing/SET_SOURCING_SEARCH_VALUE";
export const CREATE_SOURCING_DRAFT = "@@sourcing/CREATE_SOURCING_DRAFT";
export const HIDE_FROM_SOURCING_SEARCH = "@@sourcing/HIDE_FROM_SOURCING_SEARCH";
export const SET_PEOPLE_SOURCING_LIST = "@@sourcing/SET_PEOPLE_SOURCING_LIST";
export const SET_SOURCING_CUSTOM_FILTER = "@@sourcing/SET_SOURCING_CUSTOM_FILTER";
export const SET_SOURCING_HIDDEN_ITEMS = "@@sourcing/SET_SOURCING_HIDDEN_ITEMS";
export const GET_SOURCING_SELECT_POPOVER_OPTIONS =
	"@@sourcing/GET_SOURCING_SELECT_POPOVER_OPTIONS";
export const SET_SOURCING_SELECTED_POPOVER_OPTION =
	"@@sourcing/SET_SOURCING_SELECTED_POPOVER_OPTION";
export const SET_SOURCING_PROCESS_ID = "@@sourcing/SET_SOURCING_PROCESS_ID";
export const SET_SOURCING_PER_PAGE = "@@sourcing/SET_SOURCING_PER_PAGE";

/** ---------------------- NEW ----------------------- */
// Sourcing List for all properties
export const SET_SOURCING_LIST_NEW = "@@sourcing/SET_SOURCING_LIST_NEW";
export const GET_SOURCING_LIST_NEW = "@@sourcing/GET_SOURCING_LIST_NEW";
export const REQUEST_SOURCING_LIST_NEW = "@@sourcing/REQUEST_SOURCING_LIST_NEW";
export const RECEIVED_SOURCING_LIST_NEW = "@@sourcing/RECEIVED_SOURCING_LIST_NEW";
export const SET_SOURCING_LIST_PARAMS_NEW = "@@sourcing/SET_SOURCING_LIST_PARAMS_NEW";

// Sourcing People List for all properties
export const SET_SOURCING_PEOPLE_LIST_NEW = "@@sourcing/SET_SOURCING_PEOPLE_LIST_NEW";
export const GET_SOURCING_PEOPLE_LIST_NEW = "@@sourcing/GET_SOURCING_PEOPLE_LIST_NEW";
export const REQUEST_SOURCING_PEOPLE_LIST_NEW =
	"@@sourcing/REQUEST_SOURCING_PEOPLE_LIST_NEW";
export const RECEIVED_SOURCING_PEOPLE_LIST_NEW =
	"@@sourcing/RECEIVED_SOURCING_PEOPLE_LIST_NEW";
export const SET_SOURCING_PEOPLE_LIST_PARAMS_NEW =
	"@@sourcing/SET_SOURCING_PEOPLE_LIST_PARAMS_NEW";

// Sourcing Main List
export const SET_SOURCING_MAINLIST_NEW = "@@sourcing/SET_SOURCING_MAINLIST_NEW";
export const GET_SOURCING_MAINLIST_NEW = "@@sourcing/GET_SOURCING_MAINLIST_NEW";

// Sourcing Detail
export const SET_SOURCING_DETAIL_NEW = "@@sourcing/SET_SOURCING_DETAIL_NEW";
export const GET_SOURCING_DETAIL_NEW = "@@sourcing/GET_SOURCING_DETAIL_NEW";

// Run Sourcing
export const RUN_SOURCING_NEW = "@@sourcing/RUN_SOURCING_NEW";

// Get Sourcing process progress
export const GET_SOURCING_PROCESS_PORGRESS = "@@sourcing/GET_SOURCING_PROCESS_PORGRESS";
