/**
 * Here the name convention is the same with the name convention of the table in database.
 * So here the same name will be used.
 */
const security_main = {
    _name: "security_main",
    id: "id",
    security_name: "security_name",
    security_type_c: "security_type_c",
};

const security_group = {
    _name: "security_group",
    id: "id",
    group_name: "group_name",
    group_owner_account_ref_id: "group_owner_account_ref_id",
    security_main_ref_id: "security_main_ref_id",
};

const security_group_member = {
    _name: "security_group_member",
    id: "id",
    account_main_ref_id: "account_main_ref_id",
    security_group_ref_id: "security_group_ref_id",
};

const security_token_internal = {
    _name: "security_token_internal",
    id: "id",
    account_main_ref_id: "account_main_ref_id",
    date_created: "date_created",
    token_value: "token_value",
    token_status_c: "token_status_c",
};

const security_group_access = {
    _name: "security_group_access",
    id: "id",
    security_group_ref_id: "security_group_ref_id",
    object_base_sc: "object_base_sc",
    security_access_sc: "security_access_sc",
};

const security_group_external = {
    _name: "security_group_external",
    id: "id",
    account_main_ref_id: "account_main_ref_id",
    date_created: "date_created",
    token_value: "token_value",
    token_type_c: "token_type_c",
    date_blacklisted: "date_blacklisted",
};

const security_log_activity = {
    _name: "security_log_activity",
    id: "id",
    date_created: "date_created",
    account_main_ref_id: "account_main_ref_id",
    security_table_ref_id: "security_table_ref_id",
    log_object_c: "log_object_c",
    log_type_c: "log_type_c",
    log_action_c: "log_action_c",
    security_log_detail_ref_id: "security_log_detail_ref_id",
};

const security_log_detail = {
    _name: "security_log_detail",
    id: "id",
    detail_text: "detail_text",
};

export {
    security_main,
    security_group,
    security_group_member,
    security_token_internal,
    security_group_access,
    security_group_external,
    security_log_activity,
    security_log_detail,
};
