import React, { useMemo, useState } from "react";
import styled from "styled-components";
import { EuiFlexGroup, EuiFlexItem, EuiButton, EuiLink } from "@elastic/eui";
import { get } from "lodash";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus, faMinus } from "@fortawesome/pro-regular-svg-icons";

import { RASItem } from "components/Common";
import CmsRelatedBadgeItem from "./CmsRelatedBadgeItem";
import { CMS_COMM_DIRECTION_TYPE_LIST, CMS_COMM_PLATFORM_TYPE_LIST } from "./constants";
import { getDate, truncate } from "helpers/utilities";
import { getGCSLabel } from "components/global/utils";
import { useAccount, useGlobal } from "core/useHooks";

/**
 * Main Components for Static Item for CMS Communicatoin Main table
 */
const CmsAutomatedStaticItem = (props) => {
	const { data, owners, assignees, restriction, onChangeEdit } = props;

	const { globals } = useGlobal();

	const { accounts } = useAccount();
	const [expanded, setExpanded] = useState(false);

	const {
		relatedRecords,
		cmsDirection,
		cmsPlatform,
		cmsCreatedDate,
		cmsMessage,
		cmsSubject,
		cmsBodyHtml,
		cmsTrackingState,
	} = useMemo(() => {
		const relatedRecords = get(data, "related", []) || [];

		// Get direction information
		const cmsDirectionValue = get(data, "comm_direction_c");
		const cmsDirection =
			Object.values(CMS_COMM_DIRECTION_TYPE_LIST).find(
				(item) => item.value === cmsDirectionValue
			) || CMS_COMM_DIRECTION_TYPE_LIST.incoming;

		// Get CMS platform
		const cmsPlatformValue = get(data, "comm_platform_c");
		const cmsPlatform =
			Object.values(CMS_COMM_PLATFORM_TYPE_LIST).find(
				(item) => item.value === cmsPlatformValue
			) || CMS_COMM_PLATFORM_TYPE_LIST.manual;

		// Created Data
		const cmsCreatedDate = get(data, "date_created");

		// Comm Details
		const commDetails = get(data, "comm_details");
		let cmsMessage = get(commDetails, cmsPlatform.detailKey);
		let cmsSubject = get(commDetails, "subject");
		let cmsBodyHtml = "";

		switch (cmsPlatform.value) {
			case CMS_COMM_PLATFORM_TYPE_LIST.spark.value:
				cmsMessage = commDetails?.body;
				cmsBodyHtml = commDetails?.body_html;
				break;

			case CMS_COMM_PLATFORM_TYPE_LIST.o365.value:
				cmsMessage = commDetails?.body?.content;
				cmsBodyHtml = commDetails?.body_html?.content;
				break;

			default:
				break;
		}

		// Get CMS Method (Auto - 2: Manual - 1)
		const cmsTrackingList = get(data, "cms_communication_tracking");
		const cmsTrackingLastItem = cmsTrackingList?.length
			? cmsTrackingList[cmsTrackingList.length - 1]
			: null;
		const cmsStateCode = cmsTrackingLastItem
			? get(cmsTrackingLastItem, "tracking_type_c")
			: null;
		const cmsTrackingState = cmsStateCode
			? getGCSLabel(
					globals,
					"cms_communication_tracking",
					"tracking_type_c",
					cmsStateCode
			  )
			: "";

		return {
			relatedRecords,
			cmsDirection,
			cmsPlatform,
			cmsCreatedDate,
			commDetails,
			cmsMessage,
			cmsSubject,
			cmsBodyHtml,
			cmsTrackingState,
		};
	}, [data, globals, expanded]);

	const handleClickExpand = () => {
		setExpanded(!expanded);
	};
	return (
		<Wrapper>
			<EuiFlexGroup gutterSize="m">
				<EuiFlexItem grow={false}>
					<EuiFlexGroup direction="column" gutterSize="none">
						<EuiFlexItem grow={false}>
							<EuiFlexGroup gutterSize="none">
								<EuiFlexItem className="platform-icon">
									{cmsPlatform.icon}
								</EuiFlexItem>
								<EuiFlexItem className="direction-icon">
									{cmsDirection.icon}
								</EuiFlexItem>
							</EuiFlexGroup>
						</EuiFlexItem>
						<EuiFlexItem grow={false}>
							<span className="type-small-definication-term cms-state">
								{cmsTrackingState || "-"}
							</span>
						</EuiFlexItem>
						<EuiFlexItem grow={false}>
							<span className="type-small-definication-term cms-date">
								{cmsCreatedDate
									? getDate(cmsCreatedDate, "MM/DD/YYYY")
									: "-"}
							</span>
						</EuiFlexItem>
					</EuiFlexGroup>
				</EuiFlexItem>

				<EuiFlexItem>
					<EuiFlexGroup
						direction="column"
						gutterSize="none"
						className={`subject_body--container ${expanded && "expended"}`}
					>
						{cmsPlatform.value !==
							CMS_COMM_PLATFORM_TYPE_LIST.linkedIn.value && (
							<EuiFlexItem grow={false}>
								<EuiLink>
									<span className="cms-title">
										{cmsSubject
											? cmsSubject
											: truncate(cmsMessage, 30)}
									</span>
								</EuiLink>
							</EuiFlexItem>
						)}
						<EuiFlexItem grow={false}>
							{!cmsBodyHtml && (
								<span className="cms-description">
									{cmsMessage || "-"}
								</span>
							)}
							{cmsBodyHtml && (
								<div
									dangerouslySetInnerHTML={{ __html: cmsBodyHtml }}
								></div>
							)}
						</EuiFlexItem>
						<EuiFlexItem grow={false}>
							<EuiFlexGroup className="badge-items" gutterSize="s">
								{relatedRecords?.slice(0, 2)?.map((item, id) => {
									return (
										<EuiFlexItem
											key={`ams-task-list-item-${id}`}
											grow={false}
										>
											<CmsRelatedBadgeItem data={item} />
										</EuiFlexItem>
									);
								})}
							</EuiFlexGroup>
						</EuiFlexItem>
					</EuiFlexGroup>
				</EuiFlexItem>

				<EuiFlexItem grow={false}>
					<EuiFlexGroup direction="column" gutterSize="xs" alignItems="flexEnd">
						<EuiFlexItem grow={false}>
							<EuiButton
								color="text"
								className="button-fit-content plus-button"
								onClick={handleClickExpand}
								fullWidth={false}
							>
								<FontAwesomeIcon
									icon={expanded ? faMinus : faPlus}
									size="1x"
								/>
							</EuiButton>
						</EuiFlexItem>
						{/* <EuiFlexItem grow={false}>
							<RASItem
								restriction={restriction}
								owners={owners}
								assignees={assignees}
								accounts={accounts}
								onSave={onSaveRAS}
							/>
						</EuiFlexItem> */}
					</EuiFlexGroup>
				</EuiFlexItem>
			</EuiFlexGroup>
		</Wrapper>
	);
};

export default CmsAutomatedStaticItem;

/**
 * Styled Components
 */
const Wrapper = styled.div`
	width: 100%;
	padding: var(--sp-4) var(--sp-5);
	border-bottom: solid var(--sp-px) var(--border-color);

	.badge-item {
		padding: 0 var(--sp-2);
	}

	.platform-icon {
		min-width: var(--sp-10);
		text-align: center;
		margin-right: var(--sp-4);
	}

	.direction-icon {
		svg {
			width: var(--sp-8);
			height: auto;
		}
	}

	.cms-state {
		color: var(--light-color);
		margin-top: var(--sp-3);
	}

	.cms-date {
		color: var(--light-color);
	}

	.cms-title {
		text-overflow: ellipsis;
		max-width: 300px;
		overflow: hidden;
		white-space: nowrap;
	}

	.cms-description {
		word-break: break-all;
	}

	.badge-items {
		margin-top: var(--sp-2_5);
	}

	.plus-button {
		background: var(--eui-lightest-shade-color);
		border-color: var(--transparent);

		min-width: 0 !important;
		height: auto !important;
		line-height: 1 !important;

		.euiButtonContent.euiButton__content {
			padding: var(--sp-2) !important;
		}

		.euiButton__text {
			font-size: var(--sp-6);
		}

		svg {
			width: var(--sp-6);
			height: var(--sp-6);
		}
	}

	.subject_body--container {
		&:not(&.expended) {
			overflow: hidden;
			max-height: var(--sp-27);
		}
	}
`;
