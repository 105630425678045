import React, { useMemo } from "react";
import styled from "styled-components";
import { EuiLink, EuiAvatar } from "@elastic/eui";

import {
	emailIcon,
	linkedInIcon,
	groupIcon,
	circleIcon,
	chatIcon,
	phoneIcon,
} from "components/Common/Icons/Icons";
import { CustomBadge } from "components/Common";
import { MESSAGE_TYPES } from "../constants";
import { getDate } from "helpers/utilities";
import { MESSAGE_ITEM_TYPES, MESSAGE_DIRECTIONS } from "./constants";
import { useAccount } from "core/useHooks";
import { CMS_TRACKING_TYPES } from "components/global/constants";
import * as MODEL from "core/model";

/**
 * Main Components
 *
 * message = {
 *  type, 1: cms_communication_main, 2: cms_communication_draft
 *  value,
 *  createdAt,
 * }
 */
const Message = (props) => {
	const { message, ...restProps } = props;

	const { accounts, getAccountName } = useAccount();

	const {
		messageType,
		messageSubject,
		messageContent,
		isIncoming,
		createdAt,
		accountName,
		lastTrackingType,
	} = useMemo(() => {
		const messageItemType = message.type;
		const messageInfo = message.value;

		let messageDirection = MESSAGE_DIRECTIONS.outcoming.value;
		let messageType = null;
		let isIncoming = false;
		let messageSubject = "";
		let messageContent = "";
		let accountName = "";
		let lastTrackingType = CMS_TRACKING_TYPES.none;

		if (messageItemType === MESSAGE_ITEM_TYPES.main.value) {
			// For cms_communication_main
			// Get message direction
			messageDirection = messageInfo.comm_direction_c;

			// Get subject and content for message
			const commDetails = messageInfo?.comm_details;
			let cmsMessage = commDetails?.cmsPlatform?.detailKey;
			let cmsBodyHtml = "";

			messageSubject = commDetails?.subject;

			// Get message type
			switch (messageInfo.comm_platform_c) {
				case 0: // manual
					messageType = null;
					break;
				case 1: // linkedin
					messageType = MESSAGE_TYPES.linkedin;
					cmsMessage = commDetails?.message;
					break;
				case 2: // spark
					messageType = MESSAGE_TYPES.email;
					cmsMessage = commDetails?.body;
					cmsBodyHtml = commDetails?.body_html;
					break;
				case 3: // plivo
					messageType = MESSAGE_TYPES.chat;
					cmsMessage = commDetails?.text || commDetails?.sms;
					cmsBodyHtml = commDetails?.body_html;
					break;
				case 4: // phone
					messageType = MESSAGE_TYPES.phone;
					cmsMessage = commDetails?.body?.content || commDetails?.call;
					cmsBodyHtml = commDetails?.body_html?.content;
					break;
				case 5: // o365
					messageType = MESSAGE_TYPES.email;
					cmsMessage = commDetails?.body?.content;
					cmsBodyHtml = commDetails?.body_html?.content;
					break;
				case 8: // msTeam
					messageType = MESSAGE_TYPES.group;
					break;
				default:
					break;
			}

			messageContent = cmsBodyHtml || cmsMessage;

			// Get cms_communication_tracking
			const trackings = messageInfo?.[MODEL.cms_communication_tracking._name] || [];
			const lastTrackingInfo =
				trackings.length > 0 ? trackings[trackings.length - 1] : null;
			lastTrackingType =
				Object.values(CMS_TRACKING_TYPES).find(
					(item) =>
						item.value ===
						lastTrackingInfo?.[
							MODEL.cms_communication_tracking.tracking_type_c
						]
				) || CMS_TRACKING_TYPES.none;
		} else {
			// For cms_communication_draft
			messageDirection = messageInfo.comm_details.comm_direction_c;

			// Get subject and content for message
			messageSubject = messageInfo?.comm_details?.details?.subject || "";
			messageContent = messageInfo?.comm_details?.details?.message || "";

			// Get message type
			switch (messageInfo.conversation_type_c) {
				case 1: // email
					messageType = MESSAGE_TYPES.email;
					messageSubject = messageInfo?.comm_details?.details?.subject || "";
					messageContent =
						messageInfo?.comm_details?.details?.body?.content || "";
					break;
				case 2: // message, linkedin
					messageType = MESSAGE_TYPES.linkedin;
					messageSubject = messageInfo?.comm_details?.details?.title || "";
					messageContent =
						messageInfo?.comm_details?.details?.plainMessage || "";
					break;
				case 3: // sms
					messageType = MESSAGE_TYPES.chat;
					break;
				case 4: // phone
					messageType = MESSAGE_TYPES.phone;
					break;
				case 5: // video
					messageType = MESSAGE_TYPES.group;
					break;
				default:
					break;
			}

			// Get accout name
			const accountId = messageInfo?.account_main_ref_id;
			const account = accounts?.find((item) => item.id === accountId);
			accountName = getAccountName(account);
		}

		isIncoming = messageDirection === MESSAGE_DIRECTIONS.incoming.value;

		return {
			messageDirection,
			messageType,
			isIncoming,
			messageSubject,
			messageContent,
			createdAt: message.createdAt,
			accountName,
			lastTrackingType,
		};
	}, [message, accounts]);

	const showIcon = (iconName) => {
		switch (iconName) {
			case MESSAGE_TYPES.email:
				return emailIcon();
			case MESSAGE_TYPES.linkedin:
				return linkedInIcon();
			case MESSAGE_TYPES.chat:
				return chatIcon();
			case MESSAGE_TYPES.phone:
				return phoneIcon();
			case MESSAGE_TYPES.group:
				return groupIcon();
			case MESSAGE_TYPES.urlLink:
				return circleIcon();
			default:
				return circleIcon();
		}
	};

	return (
		<Wrapper className={`${isIncoming && "incoming"}`} {...restProps}>
			<div className="message_header--container">
				<div className="message_type">{showIcon(messageType)}</div>
				{lastTrackingType.icon && (
					<div className="message_tracking_type">{lastTrackingType.icon}</div>
				)}
				<div className="message_title">
					<EuiLink>{messageSubject}</EuiLink>
				</div>
				<div className="date">{getDate(createdAt, "MM/DD/YYYY @ h:mm:ss a")}</div>
			</div>
			<div className="message_body--container">
				{!isIncoming && (
					<div className="mr-3">
						<EuiAvatar name={accountName || ""} />
					</div>
				)}
				<div className="message_body">
					<div dangerouslySetInnerHTML={{ __html: messageContent }}></div>
					<div className="related">
						{message.related &&
							message.related.length > 0 &&
							message.related.map((related, index) => (
								<CustomBadge
									key={"message-badge-" + index}
									icon={related.icon && showIcon(related.icon)}
									label={related.content}
								/>
							))}
					</div>
				</div>
				{isIncoming && (
					<div className="ml-3">
						<EuiAvatar name={accountName || ""} />
					</div>
				)}
			</div>
		</Wrapper>
	);
};

export default Message;

/**
 * Styled Components
 */
const Wrapper = styled.div`
	padding-bottom: var(--sp-12);

	.message {
		&_header--container {
			display: flex;
			align-items: center;
			margin-bottom: var(--sp-2);
		}

		&_title,
		&_tracking_type,
		&_type {
			display: flex;
			align-items: center;
		}

		&_title {
			padding-left: var(--sp-3);
		}

		&_tracking_type {
			margin-left: var(--sp-4);
			margin-right: var(--sp-1);
		}

		&_type {
			margin-left: var(--sp-20);
		}

		&_body {
			background: var(--bg-color);
			border-radius: var(--sp-12);
			border-bottom-right-radius: var(--sp-12);
			border-top-left-radius: var(--sp-12);
			padding: var(--sp-9);
			width: 100%;
		}

		&_body--container {
			display: flex;
		}
	}

	&.incoming {
		.message {
			&_type {
				margin-left: 0;
			}

			&_body {
				background: var(--self-bg-color);
				border-bottom-right-radius: 0;
				border-top-left-radius: var(--sp-12);
			}
		}
	}

	.date {
		font-size: var(--sp-4_5);
		color: var(--section-title-color);
		font-weight: 600;
		margin-left: auto;
	}

	.related {
		display: flex;
		margin-left: auto;
		justify-content: flex-end;
	}
`;
