/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useCallback, useMemo } from "react";
import { useHistory } from "react-router-dom";
import { useDispatch } from "react-redux";
import { size, get, isNumber } from "lodash";

import {
	_getOpenTabs,
	_getCompanyDetail_NEW,
	_getCampaignDetail,
	_getPersonDetails_NEW,
	_getJobDetail_NEW,
	_getAccountDetail,
	_getGlobals,
} from "helpers/selectors";
import { setOpenTabs, setAccountSettingsOpenTabs } from "helpers/actions";
import { routeTabInfo } from "components/global/routeInfo";

import * as MODEL from "core/model";
import { getGCSValue } from "components/global/utils";
import { useAccount } from "core/useHooks";

/**
 * Accepts open tabs and has functions to
 */
/**
 * TAB_FORMAT = {
 *	name: "",
 *	route: "",
 *	isActive: 0,
 *	avatar_link: "",
 * };
 */
const MAX_NUMBER_OF_TABS = 10;

/**
 * Use Hook list for open tabs
 */
const useOpenTabs = () => {
	const { getAccountDetail } = useAccount();

	const dispatch = useDispatch();
	const history = useHistory();
	const pathname = history.location.pathname;

	const accountDetail = _getAccountDetail();
	const accountSettingsLoaded = size(accountDetail) > 0;
	const openTabs = _getOpenTabs();
	const company = _getCompanyDetail_NEW();
	const job = _getJobDetail_NEW();
	const campaign = _getCampaignDetail();
	const person = _getPersonDetails_NEW();
	const globals = _getGlobals();

	const accountSettings = useMemo(() => {
		const _accountSettings = accountDetail?.[MODEL.account_setting._name];
		let settings = {};
		if (globals?.length && _accountSettings) {
			const settingTypeC = getGCSValue(
				globals,
				"account_setting",
				"setting_type_c",
				"empower-crm"
			);
			settings = _accountSettings?.find(
				(item) => item[MODEL.account_setting.setting_type_c] === settingTypeC
			);
		}

		return settings;
	}, [globals, accountDetail]);

	// Person
	const personDetail = useMemo(() => {
		if (size(person) > 0) {
			const personDetailId = get(person, "id", "");
			const personFirstName = get(person, "name_first", "");
			const personLastName = get(person, "name_last", "");
			const personAvatarLink =
				person?.["person_external_link"]
					?.filter((val) => val.global_link_type_c === 3)
					.map((val) => {
						return val.global_url_details?.url_value;
					}) || null;

			const personName = personFirstName + " " + personLastName;
			const route = `/person/${personDetailId}`;
			const name = `${personName}(${personDetailId})`;
			const avatar_link = personAvatarLink?.[0] ?? "";

			return {
				name,
				route,
				avatar_link,
			};
		}
	}, [person]);

	// For "company/:id"
	const companyDetail = useMemo(() => {
		if (size(company) > 0) {
			const companyID = get(company, "id", "");
			const companyDescription = get(company, "company_name", "");
			const route = `/company/${companyID}`;
			const name = `${companyDescription}(${companyID})`;

			return {
				name,
				route,
			};
		}
		return {
			name: "",
			route: "",
		};
	}, [company]);

	// For "jobs/:id"
	const jobDetail = useMemo(() => {
		if (size(job) > 0) {
			const jobID = get(job, "id", "");
			const jobDescription = get(job, "job_name", "");
			const route = `/jobs/${jobID}`;
			const name = `${jobDescription}(${jobID})`;

			return {
				name,
				route,
			};
		}
		return {
			name: "",
			route: "",
		};
	}, [job]);

	// For "campaign/:id"
	const campaignDetail = useMemo(() => {
		const isCampaignViewPage = pathname.includes("/campaigns/");
		if (isCampaignViewPage) {
			if (size(campaign) > 0) {
				const { id, campaign_name: campaignName } = campaign;
				const route = `/campaigns/${id}`;
				const name = `${campaignName}(${id})`;

				return {
					name,
					route,
				};
			}
		}
		return {
			name: "",
			route: "",
		};
	}, [campaign]);

	useEffect(() => {
		if (personDetail) {
			updateTabInfo(
				personDetail.name,
				personDetail.route,
				personDetail.avatar_link
			);
		}
	}, [personDetail]);

	useEffect(() => {
		if (companyDetail) {
			updateTabInfo(companyDetail.name, companyDetail.route, "");
		}
	}, [companyDetail]);

	useEffect(() => {
		if (jobDetail) {
			updateTabInfo(jobDetail.name, jobDetail.route, "");
		}
	}, [jobDetail]);

	useEffect(() => {
		if (campaignDetail) {
			updateTabInfo(campaignDetail.name, campaignDetail.route, "");
		}
	}, [campaignDetail]);

	const saveOpenTabs = (data) => {
		const tabs = data?.map((tab, id) => {
			return {
				name: tab.name || "",
				route: tab.route,
				avatar_link: tab.avatar_link || "",
				isActive: tab.isActive ? 1 : 0,
				order: id,
			};
		});

		if (globals?.length) {
			const settingTypeC = getGCSValue(
				globals,
				"account_setting",
				"setting_type_c",
				"empower-crm"
			);

			const payload = {
				[MODEL.account_setting._name]: {
					[MODEL.account_setting.id]: accountSettings?.id,
					[MODEL.account_setting.account_main_ref_id]: accountDetail?.id,
					[MODEL.account_setting.setting_type_c]: settingTypeC,
					[MODEL.account_setting.setting_value]: {
						tabs_open: tabs,
					},
				},
			};

			// Payload
			dispatch(
				setAccountSettingsOpenTabs(payload, (res) => {
					// in the case the account setting is not set yet.
					if (!accountSettings?.id) {
						getAccountDetail();
					}
				})
			);
		}
	};

	const setTabList = (data) => {
		dispatch(setOpenTabs(data));
		if (accountSettingsLoaded) {
			saveOpenTabs(data);
		}
	};

	const setActiveTab = useCallback(
		(idx) => {
			if (openTabs?.length > 0 && isNumber(idx)) {
				const _openTabs = openTabs?.map((tab, id) => {
					if (idx === id) {
						history.push(tab.route);
					}
					return {
						...tab,
						isActive: idx === id ? 1 : 0,
					};
				});

				setTabList(_openTabs);
			}
		},
		[openTabs]
	);

	const closeTab = (tabIdx) => {
		if (!openTabs || openTabs.length === 0) {
			return;
		}

		const lengthOfOpenTabs = openTabs.length;
		if (lengthOfOpenTabs === 1) {
			alert("You cannot delete the last tab");
			return;
		}

		// Get new tabs
		const newTabs = [];
		openTabs.forEach((tab, id) => {
			if (tabIdx !== id) {
				newTabs.push(tab);
			}
		});

		const nextActiveTabIndex = Math.min(tabIdx, lengthOfOpenTabs - 2);

		// Set isActive for next tab
		newTabs.forEach((tab, id) => {
			tab.isActive = id === nextActiveTabIndex ? 1 : 0;
		});

		history.push(newTabs[nextActiveTabIndex].route);
		setTabList(newTabs);
	};

	const addRouteToTabs = (tabs, route) => {
		const parsedRoute = route.split("/");
		const mainRoute = Object.values(routeTabInfo).find(
			(tab) => tab.hasTab && `/${parsedRoute[1]}` === tab.path
		);
		const details = {
			person: personDetail,
			company: companyDetail,
			jobs: jobDetail,
			campaigns: campaignDetail,
		};

		let activeTabId = tabs.findIndex((tab) => tab.isActive);
		if (mainRoute) {
			// Check main route for current path
			if (mainRoute.path === route) {
				if (mainRoute.hasTab) {
					tabs.push({
						name: mainRoute.label,
						route: route,
						icon: mainRoute.icon,
						avatar_link: null,
					});
					activeTabId = tabs.length - 1;
				}
			} else {
				const subpath = parsedRoute[2];
				const detail = details[mainRoute.path];

				const getTabInfo = (initInfo, index) => {
					let tabInfo = null;
					if (detail) {
						tabInfo = { ...initInfo, ...detail };
					} else {
						tabInfo = { ...initInfo };
					}

					if (isNumber(index)) {
						tabs[index] = tabInfo;
						activeTabId = index;
					} else {
						tabs.push(tabInfo);
						activeTabId = tabs.length - 1;
					}
				};

				// Check sub path
				if (subpath && mainRoute.hasTab) {
					const subRoute = Object.values(mainRoute.subRoute.items).find(
						(item) => item.path === "id" || item.path === subpath
					);
					if (subRoute) {
						if (subRoute.path === "id") {
							// Replace tab
							const replaceTabIndex = tabs.findIndex((tab) =>
								tab.route.includes(`${mainRoute.path}/`)
							);
							if (replaceTabIndex !== -1) {
								getTabInfo(
									{
										name: subRoute.label,
										route: route,
										icon: null,
									},
									replaceTabIndex
								);
							} else {
								getTabInfo({
									name: subRoute.label,
									route: route,
									icon: null,
								});
							}
						} else {
							getTabInfo({
								name: subRoute.label,
								route: route,
								icon: null,
							});
						}
					}
				}
			}
		}

		if (activeTabId === -1) {
			activeTabId = tabs.length - 1;
		}
		tabs.forEach((tab, id) => {
			tab.isActive = id === activeTabId ? 1 : 0;
		});

		const startIndex = Math.max(0, tabs.length - MAX_NUMBER_OF_TABS);
		const newTabs = tabs.slice(startIndex);

		return newTabs;
	};

	const addTab = (route) => {
		const tabs = openTabs.map((tab) => ({ ...tab }));
		const newTabs = addRouteToTabs(tabs, route);
		setTabList(newTabs);
	};

	const updateTabInfo = (name, route, avatar_link) => {
		const tabs = openTabs.map((tab) => {
			if (tab.route === route) {
				return {
					...tab,
					name,
					route,
					avatar_link,
				};
			} else {
				return tab;
			}
		});
		setTabList(tabs);
	};

	useEffect(() => {
		const tabIdx = openTabs?.findIndex((tab) => tab.route === pathname);
		if (tabIdx === -1) {
			addTab(pathname);
		} else {
			if (!openTabs[tabIdx].isActive) {
				setActiveTab(tabIdx);
			}
		}
	}, [pathname, openTabs]);

	useEffect(() => {
		let tabSettings = [];
		if (accountSettingsLoaded && accountSettings) {
			tabSettings = get(accountSettings, "setting_value.tabs_open", []);
		}

		if (tabSettings?.length > 0) {
			let tabs = [];
			tabSettings.forEach((tab) => {
				const parsedTabRoute = tab.route.split("/");

				// Check the main path
				const mainPath = parsedTabRoute[1];
				if (mainPath) {
					const mainRoute = Object.values(routeTabInfo).find((route) =>
						route.path.includes(mainPath)
					);

					if (mainRoute) {
						// Check sub path
						if (parsedTabRoute.length > 2) {
							tabs.push({
								...tab,
								icon: null,
							});
						} else {
							tabs.push({
								...tab,
								icon: mainRoute.icon,
								name: mainRoute.label,
							});
						}
					}
				}
			});

			// Check openTabs
			if (openTabs?.length > 0) {
				let activeTabId = tabs.findIndex((tab) => tab.isActive);
				openTabs.forEach((tab) => {
					const tabIdx = tabs?.findIndex((_tab) => _tab.route === tab.route);
					if (tabIdx === -1) {
						tabs = addRouteToTabs([...tabs], tab.route);
						activeTabId = tabs.length - 1;
					} else {
						tabs[tabIdx].isActive = 1;
						activeTabId = tabIdx;
					}
				});
				if (activeTabId !== -1) {
					tabs.forEach((tab, id) => {
						if (id === activeTabId) {
							tab.isActive = 1;
						} else {
							tab.isActive = 0;
						}
					});
				}
			}

			setTabList(tabs);
		}
	}, [accountSettingsLoaded, accountSettings]);

	return {
		openTabs,
		closeTab,
		history,
		setActiveTab,
		setTabList,
	};
};

export default useOpenTabs;
