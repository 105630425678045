import API from "api";

import { sit_keyword, sit_log_activity, sit_main } from "core/model";

import {
	makeSort,
	makeTableJoin,
	makeFieldContent,
	makeExternalJoin,
} from "./commonService";

/** ---------------- Generic API ---------------------- */
const getSitList = ({ pageNumber = 1, pageCount = 30, params = null, type_c = -1 }) => {
	const query = {
		page_number: pageNumber,
		page_count: pageCount,
		table_main: sit_main._name,
		table_join: makeTableJoin([sit_keyword]),
		fields: [
			makeFieldContent(sit_main, sit_main.id, sit_main.ID),
			makeFieldContent(sit_main, sit_main.sit_name),
			makeFieldContent(sit_main, sit_main.sit_order_value),
			makeFieldContent(sit_main, sit_main.sit_parent_id),
			makeFieldContent(sit_main, sit_main.sit_type_c),
			makeFieldContent(sit_main, sit_main.sit_children_count),

			makeFieldContent(sit_keyword, sit_keyword.id),
			makeFieldContent(sit_keyword, sit_keyword.keyword),
			makeFieldContent(sit_keyword, sit_keyword.case_sensitive),
		],
		sort: [
			makeSort(sit_main, sit_main.sit_name),
			makeSort(sit_main, sit_main.sit_order_value),
		],
		search_fields: [
			{
				table: sit_main._name,
				field: sit_main.sit_type_c,
			},
		],
		search_type: "ref",
		search_ref: [type_c],
		...(params ? { ...params } : {}),
	};

	return API().GenericAPI().getTableData(query);
};

const getSitListByKeyword = ({ sitTypeC, keyword }) => {
	const query = {
		keyword: `%${keyword}%`,
		sit_type_c: sitTypeC,
		page_number: 1,
		page_count: 1000,
	};

	return API().SIT().getSitListByKeyword(query);
};

const getSitAllMainList = () => {
	const query = {
		page_number: 0,
		page_count: 1000,
	};
	return API().SIT().getSitList(query);
};

const getSitKeywords = ({
	pageNumber = 1,
	pageCount = 30,
	params = null,
	main_id = -1,
}) => {
	const query = {
		limit_records: 1000,
		page_number: pageNumber,
		page_count: pageCount,
		table_main: sit_keyword._name,
		fields: [
			makeFieldContent(sit_keyword, sit_keyword.id),
			makeFieldContent(sit_keyword, sit_keyword.keyword),
			makeFieldContent(sit_keyword, sit_keyword.case_sensitive),
			makeFieldContent(sit_keyword, sit_keyword.sit_main_ref_id),
		],
		sort: [makeSort(sit_keyword, sit_keyword.keyword)],
		search_fields: [
			{
				table: sit_keyword._name,
				field: sit_keyword.sit_main_ref_id,
			},
		],
		search_type: "ref",
		search_ref: [main_id],
		...(params ? { ...params } : {}),
	};

	return API().GenericAPI().getTableData(query);
};

const updateSit = (data) => {
	const query = {
		object: "sit",
		data: data || {},
	};

	return API().GenericAPI().updateTableData(query);
};

const deleteSit = (data) => {
	const query = {
		object: "sit",
		data: data || {},
	};

	return API().GenericAPI().deleteTableData(query);
};
/** --------------------------------------------------------- */

/** ----------------------- Custom API ---------------------- */
/* ---------------------------------------------------------- */

export default {
	getSitList,
	getSitListByKeyword,
	getSitKeywords,
	getSitAllMainList,
	updateSit,
	deleteSit,
};
