import { getGSSValue } from "components/global/utils";

const makeFieldContent = (table, field, alias = null) => {
	if (table) {
		if (alias) {
			return {
				table: table._name,
				field: field,
				alias: alias,
			};
		} else {
			return {
				table: table._name,
				field: field,
			};
		}
	}
};

const makeTableJoin = (tables) => {
	if (tables && tables.length > 0) {
		const res = [];
		tables.map((table) => {
			res.push(table._name);
		});
		return res;
	}
};

const makeExternalJoin = (parent, child) => {
	if (parent && child) {
		return {
			parent: parent._name,
			child: child._name,
		};
	}
};

const makeRefJoinTable = (table) => {
	if (table) {
		return table._name;
	}
};

const makeSort = (table, field, sort = "asc") => {
	if (table && field) {
		return {
			table: table._name,
			field: field,
			type: sort,
		};
	}
};

const makeSearchField = (table, field) => {
	if (table && field) {
		return {
			table: table._name,
			field: field,
		};
	}
};

const makeLogJoin = (table, types, actions) => {
	if (table && types && actions) {
		return {
			table: table._name,
			type: types,
			action: actions
		};
	}
};

const getLogTypesActions = (tableName, gssList) => {
	if (tableName && gssList?.length) {
		// add logTypes
		let logTypes = [];
		logTypes.push(getGSSValue(gssList, 'ACTIVITY_LOG_TYPE', 'GENERIC') || 1);
	
		// add logActions
		let logActions = [];
		const createAction = getGSSValue(gssList, 'GENERIC_ACTION', 'CREATE');
		const modifyAction = getGSSValue(gssList, 'GENERIC_ACTION', 'MODIFY');
		if (createAction) {
			logActions.push(createAction);	
		}
		if (modifyAction) {
			logActions.push(modifyAction);	
		}
	
		return makeLogJoin(tableName, logTypes, logActions);
	} else {
		return '';
	}
};

export {
	makeFieldContent,
	makeTableJoin,
	makeExternalJoin,
	makeRefJoinTable,
	makeSort,
	makeSearchField,
	makeLogJoin,
	getLogTypesActions,
};
