import { SIT_TYPES } from "components/global/constants";
import * as actionTypes from "./actionTypes";

/**
 * Functions
 */
const setFlyoutVisible = (state, payload) => {
	return {
		...state,
		flyoutVisible: payload,
	};
};

const setSitMode = (state, payload) => {
	return {
		...state,
		sitMode: payload,
	};
};

const setSit = (state, payload) => {
	return {
		...state,
		sits: [...payload],
	};
};

const updateSitParent = (state, payload) => {
	return {
		...state,
		updateParent: payload,
	};
};

const deleteSit = (state, payload) => {
	return {
		...state,
		delete: payload,
	};
};

const addUpdateSit = (state, payload) => {
	return {
		...state,
		addUpdate: payload,
	};
};

const setSitKeywords = (state, payload) => {
	return {
		...state,
		keywords: payload,
	};
};

const updateSITKeyword = (state, payload) => {
	return {
		...state,
		updateSITKeyword: payload,
	};
};

const updateSITKeywordCaseSensitive = (state, payload) => {
	return {
		...state,
		updateSITKeywordCaseSensitive: payload,
	};
};

const createSITKeyword = (state, payload) => {
	return {
		...state,
		createSITKeyword: payload,
	};
};

/* -------------------------- NEW --------------------------------------- */
const setSitList_NEW = (state, payload) => {
	return {
		...state,
		sitList_NEW: payload,
	};
};

const setSitKeywords_NEW = (state, payload) => {
	return {
		...state,
		sitKeywords_NEW: payload,
	};
};

const requestSitMainList_NEW = (state, payload) => {
	return {
		...state,
		sitMainList_NEW: {
			...state.sitMainList_NEW,
			[payload]: {
				...state.sitMainList_NEW[payload],
				isRequesting: true,
				isReceived: false,
			},
		},
	};
};

const receivedSitMainList_NEW = (state, payload) => {
	return {
		...state,
		sitMainList_NEW: {
			...state.sitMainList_NEW,
			[payload]: {
				...state.sitMainList_NEW[payload],
				isRequesting: false,
				isReceived: true,
			},
		},
	};
};

const setSitMainList_NEW = (state, payload) => {
	return {
		...state,
		sitMainList_NEW: {
			...state.sitMainList_NEW,
			...payload,
		},
	};
};

const setSitListLoading = (state, payload) => {
	return {
		...state,
		loading: payload,
	};
};
/* ---------------------------------------------------------------------- */

/**
 * Initial State
 */
const initialState = {
	sits: [],
	loading: false,
	updateParent: {},
	delete: {},
	addUpdate: {},
	keywords: {},
	sitMode: {},
	updateSITOrderData: {},
	updateSITKeyword: {},
	updateSITKeywordCaseSensitive: {},
	createSITKeyword: {},
	// Sit Flyout Handler
	flyoutVisible: false,

	/* ------------------------------ NEW -------------------------- */
	sitList_NEW: [],
	sitKeywords_NEW: [],
	sitMainList_NEW: {
		[SIT_TYPES.skill.key]: {
			totalCount: 0,
			pageCount: 30,
			pageNumber: 1,
			searchTerm: "",
			isRequesting: false,
			isReceived: false,
			data: [],
		},
		[SIT_TYPES.industry.key]: {
			totalCount: 0,
			pageCount: 30,
			pageNumber: 1,
			searchTerm: "",
			isRequesting: false,
			isReceived: false,
			data: [],
		},
		[SIT_TYPES.title.key]: {
			totalCount: 0,
			pageCount: 30,
			pageNumber: 1,
			searchTerm: "",
			isRequesting: false,
			isReceived: false,
			data: [],
		},
	},
	/* ------------------------------------------------------------- */
};

/**
 * Reducer
 */
const Reducer = (state = initialState, action) => {
	switch (action.type) {
		case actionTypes.SET_SIT_DATA:
			return setSit(state, action.payload);

		case actionTypes.UPDATE_SIT_PARENT:
			return updateSitParent(state, action.payload);

		case actionTypes.DELETE_SIT:
			return deleteSit(state, action.payload);

		case actionTypes.ADD_UPDATE_SIT:
			return addUpdateSit(state, action.payload);

		case actionTypes.GET_SIT_KEYWORDS:
			return setSitKeywords(state, action.payload);

		case actionTypes.SET_SIT_MODE:
			return setSitMode(state, action.payload);

		case actionTypes.SET_FLYOUT_VISIBLE:
			return setFlyoutVisible(state, action.payload);

		case actionTypes.UPDATE_SIT_KEYWORD:
			return updateSITKeyword(state, action.payload);

		case actionTypes.UPDATE_SIT_KEYWORD_CASE_SENSITIVE:
			return updateSITKeywordCaseSensitive(state, action.payload);

		case actionTypes.CREATE_SIT_KEYWORD:
			return createSITKeyword(state, action.payload);

		/* ----------------------- NEW ---------------------------------------- */
		case actionTypes.SET_SIT_LIST_NEW:
			return setSitList_NEW(state, action.payload);

		case actionTypes.SET_SIT_KEYWORDS_NEW:
			return setSitKeywords_NEW(state, action.payload);

		case actionTypes.SET_SIT_MAINLIST_NEW:
			return setSitMainList_NEW(state, action.payload);

		case actionTypes.REQUEST_SIT_MAINLIST_NEW:
			return requestSitMainList_NEW(state, action.payload);

		case actionTypes.RECEIVED_SIT_MAINLIST_NEW:
			return receivedSitMainList_NEW(state, action.payload);

		case actionTypes.SET_SIT_MAINLIST_LOADING:
			return setSitListLoading(state, action.payload);
		/* -------------------------------------------------------------------- */

		default:
			return state;
	}
};

export default Reducer;
