import { useDispatch } from "react-redux";

import { instance } from "../../SocketProvider";
import {
	handleSocketAPIResponse,
	handleSocketCredentialResponse,
	handlerSocketDansResponse,
} from "./utils";
import useSocketHandlerNotification from "./useSocketHandlerNotification";

/**
 * Handler for All Sockets
 *
 * We register all socket events here
 * and process related actions.
 */
const useSocketHandler = () => {
	const dispatch = useDispatch();

	const { handleSocketNotificationResponse } = useSocketHandlerNotification();

	const onResponses = [
		{
			name: "api-response",
			handler: handleSocketAPIResponse,
		},
		{
			name: "credential-verification",
			handler: handleSocketCredentialResponse,
		},
		{
			name: "notification",
			handler: handleSocketNotificationResponse,
		},
		{
			name: "dans-response",
			handler: handlerSocketDansResponse,
		},
	];

	for (let i in onResponses) {
		const { name, handler } = onResponses[i];

		instance.on(name, (response) => {
			handler(dispatch, response);
		});
	}

	return;
};

export default useSocketHandler;
