import React, { useEffect, useState } from "react";
import styled from "styled-components";
import {
    EuiFlexGroup,
    EuiFlexItem,
    EuiText,
    EuiFieldText,
    EuiButtonIcon,
} from "@elastic/eui";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPen } from "@fortawesome/pro-light-svg-icons";

import variables from "assets/css/variables.scss";

const EditablePersonName = (props) => {
    const { description, favourite, onSaveName, name } = props;

    const [innerName, setInnerName] = useState({
        main: "",
        alias: "",
    });
    const [editState, setEditState] = useState(false);
    console.log("editState: ", editState);
    const [hoverState, setHoverState] = useState(false);
    console.log("hoverState: ", hoverState);

    const handleChangeName = (e) => {
        setInnerName({ ...innerName, [e.target.name]: e.target.value });
    };

    const handleSave = () => {
        onSaveName(innerName);
    };

    const handleCancel = () => {
        setEditState(false);
    };

    useEffect(() => {
        const { main, alias } = name;

        setInnerName({
            main,
            alias,
        });
    }, [name]);

    return (
        <Wrapper
            onMouseEnter={() => !editState && setHoverState(true)}
            onMouseLeave={() => !editState && setHoverState(false)}
        >
            {!editState ? (
                <EuiFlexGroup gutterSize="xs" alignItems="center">
                    <EuiFlexItem grow={false}>
                        <EuiText size="s">{description}</EuiText>
                    </EuiFlexItem>

                    <EuiFlexItem grow={false}>{favourite}</EuiFlexItem>

                    {hoverState ? (
                        <EuiFlexItem grow={false}>
                            <button
                                onClick={() => setEditState(true)}
                                className="icon-button mt-2"
                            >
                                <FontAwesomeIcon
                                    className="icon-styles"
                                    icon={faPen}
                                    style={
                                        editState
                                            ? {
                                                  background: variables.hoveredBtnBgColor,
                                              }
                                            : ""
                                    }
                                />
                            </button>
                        </EuiFlexItem>
                    ) : null}
                </EuiFlexGroup>
            ) : (
                <EuiFlexGroup gutterSize="xs" className="mt-2">
                    <EuiFlexItem grow={false}>
                        <EuiFieldText
                            placeholder="Name"
                            name="main"
                            value={innerName.main}
                            compressed
                            onChange={(e) => handleChangeName(e)}
                        />
                    </EuiFlexItem>
                    <EuiFlexItem grow={false}>
                        <EuiFieldText
                            placeholder="Alias"
                            name="alias"
                            value={innerName.middle}
                            compressed
                            onChange={(e) => handleChangeName(e)}
                        />
                    </EuiFlexItem>
                    <EuiFlexItem grow={false}>
                        <div className="button-icon-wrapper">
                            <EuiButtonIcon
                                className="ml-0"
                                style={{
                                    color: variables.successColor,
                                }}
                                onClick={handleSave}
                                iconType="check"
                                aria-label="save"
                            />
                        </div>
                    </EuiFlexItem>
                    <EuiFlexItem grow={false}>
                        <div className="button-icon-wrapper">
                            <EuiButtonIcon
                                className="ml-0"
                                style={{
                                    color: variables.warningColor,
                                }}
                                onClick={handleCancel}
                                iconType="cross"
                                aria-label="cancel"
                            />
                        </div>
                    </EuiFlexItem>
                </EuiFlexGroup>
            )}
        </Wrapper>
    );
};

export default EditablePersonName;

const Wrapper = styled.div`
    display: flex;
    align-content: center;
    align-items: center;
    font-size: 1rem;
    line-height: 1.5rem;
    height: 25px;

    p {
        font-weight: 600;
    }

    button {
        padding: 0px;
        min-height: 18px;
        min-width: 18px;
        margin-left: 8px;
    }

    input {
        &[name="first"],
        &[name="last"] {
            min-width: 80px;
            width: 80px;
        }

        &[name="middle"],
        &[name="nick"] {
            min-width: 65px;
            width: 65px;
        }
    }
`;
