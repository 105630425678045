import React from "react";
import { EuiText } from "@elastic/eui";

import { AddData, CustomBadge } from "components/Common";
import { circleIcon } from "components/Common/Icons";
import styled from "styled-components";

/**
 * Component for Related Items
 */
const RelatedItem = (props) => {
	const { message, items, onAddItem } = props;

	return (
		<Wrapper className="d-flex mb-1">
			<EuiText className="label mt-2 mr-4">Related</EuiText>
			<div className="icon-badges">
				{items &&
					items.length > 0 &&
					items.map((related, index) => (
						<CustomBadge
							key={"related-badge-" + index}
							icon={circleIcon()}
							label={related}
							className="mr-4 mt-2"
						/>
					))}
				<AddData handleValue={onAddItem} className="py-1" />
			</div>
			{message?.length > 0 && <div className="message">* {message}</div>}
		</Wrapper>
	);
};

export default RelatedItem;

const Wrapper = styled.div`
	.message {
		position: absolute;
		right: var(--sp-6_5);
		display: flex;
		box-sizing: border-box;
		border-radius: var(--sp-3);
		color: var(--eui-red-color);
	}
`;
