import API from "api";

import { company_main } from "core/model";

import { makeSort, makeFieldContent } from "./commonService";

/** ---------------- Generic API ---------------------- */
const getCompanyMainList = ({
	searchTerm = "",
	limit = 10,
	pageNumber = 1,
	pageCount = 20,
	params = null,
}) => {
	const query = {
		limit_records: limit,
		page_number: pageNumber,
		page_count: pageCount,
		table_main: company_main._name,
		fields: [
			makeFieldContent(company_main, company_main.id),
			makeFieldContent(company_main, company_main.company_name),
			makeFieldContent(company_main, company_main.company_alias),
			makeFieldContent(company_main, company_main.company_date_founded),
			makeFieldContent(company_main, company_main.company_size_c),
			makeFieldContent(company_main, company_main.company_type_c),
		],
		sort: [
			makeSort(company_main, company_main.company_name),
			makeSort(company_main, company_main.company_alias),
		],
		search_fields: [
			{
				table: company_main._name,
				field: company_main.id,
			},
			{
				table: company_main._name,
				field: company_main.company_name,
			},
			{
				table: company_main._name,
				field: company_main.company_alias,
			},
		],
		search_type: "term",
		search_term: searchTerm,
		...(params ? { ...params } : {}),
	};

	return API().GenericAPI().getTableData(query);
};

const updateCompany = (data) => {
	const request = {
		object: "company",
		data: data || {},
	};

	return API().GenericAPI().updateTableData(request);
};

const deleteCompany = (data) => {
	const request = {
		object: "company",
		data: data || {},
	};

	return API().GenericAPI().deleteTableData(request);
};

const getCompanyDetail = ({ id }) => {
	const query = {
		object: "company",
		record_main_id: id,
	};

	return API().GenericAPI().getMainData(query);
};

/** --------------------------------------------------------- */

/** ----------------------- Custom API ---------------------- */
const getCompanyList = (data) => {
	return API().Company().companyList(data);
};
/* ---------------------------------------------------------- */
export default {
	getCompanyMainList,
	getCompanyList,
	updateCompany,
	deleteCompany,
	getCompanyDetail,
};
