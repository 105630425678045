import { PLIVO_CALL_STATES } from "components/global/constants";

export const MESSAGE_STATES = {
	send: { key: "send", label: "Send", value: "send" },
	logged: { key: "logged", label: "Logged", value: "logged" },
	logNotes: { key: "logNotes", label: "Log Notes Only", value: "logNotes" },
};

export const LINKEDIN_ACTION_TYPES = {
	CONNENCTION_REQUEST: {
		key: "CONNENCTION_REQUEST",
		label: "Connection Request",
		value: "CONNENCTION_REQUEST",
	},
	STANDARD_MESSAGE: {
		key: "STANDARD_MESSAGE",
		label: "Standard Message",
		value: "STANDARD_MESSAGE",
	},
	INMAIL: { key: "INMAIL", label: "In Mail", value: "INMAIL", disabled: true },
};

export const CALL_STATES = {
	send: { key: "send", label: "Send", value: "send" },
	logged: { key: "logged", label: "Logged", value: "logged" },
	logNotes: { key: "logNotes", label: "Log Notes Only", value: "logNotes" },
	end: { key: "end", label: "End & Save Notes Only", value: "end" },
	ended: { key: "ended", label: "Ended", value: "ended" },
};

export const PLIVO_CONNECTING_STATE_LIST = [
	PLIVO_CALL_STATES.onCalling.value,
	PLIVO_CALL_STATES.onCallRemoteRinging.value,
	PLIVO_CALL_STATES.onMediaConnected.value,
];

export const PLIVO_IDLE_STATE_LIST = [
	PLIVO_CALL_STATES.idle.value,
	PLIVO_CALL_STATES.terminated.value,
	PLIVO_CALL_STATES.faild.value,
];

export const PLIVO_CALLED_STATE_LIST = [PLIVO_CALL_STATES.answered.value];

export const CONVERSATION_TYPES = {
	email: { key: "email", label: "Email", value: 1 },
	message: { key: "message", label: "Message", value: 2 },
	sms: { key: "sms", label: "Sms", value: 3 },
	phone: { key: "phone", label: "Phone", value: 4 },
	video: { key: "video", label: "Video", value: 5 },
};

export const COMM_PLATFORMS = {
	manual: { key: "manual", label: "Manual", value: 0 },
	linkedin: { key: "linkedin", label: "Linkedin", value: 1 },
	spark: { key: "spark", label: "Spark", value: 2 },
	plivo: { key: "plivo", label: "Plivo", value: 3 },
	phone: { key: "phone", label: "Phone", value: 4 },
	o365: { key: "o365", label: "O365", value: 5 },
	import: { key: "import", label: "Import", value: 6 },
	skype: { key: "skype", label: "Skype", value: 7 },
	msteams: { key: "ms-teams", label: "Ms-teams", value: 8 },
	zoom: { key: "zoom", label: "Zoom", value: 9 },
};

export const COMM_DIRECTIONS = {
	incoming: { key: "incoming", label: "Incoming", value: 0 },
	outgoing: { key: "outgoing", label: "Outgoing", value: 1 },
};

export const TRACKING_METHODS = {
	manual: { key: "manual", label: "Manual", value: 1 },
	automated: { key: "manual", label: "Automated", value: 2 },
};

export const COMMUNICATION_TYPE = {
	recruiting: { key: "recruiting", label: "Recruiting", value: 1 },
	sales: { key: "sales", label: "Sales", value: 2 },
};

export const VALIDATE_MESSAGE = {
	login: { key: "login", label: "login", value: "You are not logged in. Please login" },
	credential: { key: "credential", label: "credential", value: "You don't have any credential." },
	subject: { key: "subject", label: "subject", value: "The subject is empty. Please input subject." },
	message: { key: "message", label: "message", value: "The message is empty. Please input message." },
	sender: { key: "sender", label: "sender", value: "Please select a sender" },
	receiver: { key: "receiver", label: "receiver", value: "Please select a reciver" },
};

