import API from "api";
import Service from "core/service";

import { objectChecker, getResultFromApiResponse } from "helpers/utilities";
import * as actionTypes from "./actionTypes";
import { logError } from "../error";

import { global_static_setting } from "core/model";

export const setGlobals = (data) => {
	return {
		type: actionTypes.SET_GLOBALS,
		payload: data,
	};
};

export const fetchAllGlobals = () => {
	return async (dispatch) => {
		try {
			const result = await API().Global().globalList({});
			if (result) {
				dispatch(setGlobals(result));
			} else {
				dispatch(setGlobals([]));
			}
		} catch (err) {
			logError("fetch_failure", "none", {
				Error: "Error fetching global data: " + JSON.stringify(err),
			});
		}
	};
};

export const updateGlobalCustomField = (params) => {
	return async (dispatch) => {
		try {
			const result = await API().Global().updateGlobalCustomField(params);
			if (result) {
				dispatch({
					type: actionTypes.UPDATE_GLOBAL_CUSTOM_FIELD,
					payload: result,
				});
			}
		} catch (err) {
			//alert('Error update global data: ' + JSON.stringify(err))
			logError("fetch_failure", "none", {
				Error: "Error update global data: " + JSON.stringify(err),
			});
		}
	};
};

export const addGlobalCustomField = (params) => {
	return async (dispatch) => {
		try {
			const result = await API().Global().addGlobalCustomField(params);
			if (result) {
				dispatch({
					type: actionTypes.ADD_GLOBAL_CUSTOM_FIELD,
					payload: result,
				});
			}
		} catch (err) {
			//alert('Error update global data: ' + JSON.stringify(err))
			logError("fetch_failure", "none", {
				Error: "Error add global data: " + JSON.stringify(err),
			});
		}
	};
};

/* --------------------------------- NEW ------------------------------ */
export const updateGlobalRecord = (params, successCallback, failCallback) => {
	return async (dispatch) => {
		try {
			const payload = {
				object: "global",
				data: params,
			};

			const result = await Service.global.updateGlobalRecord(payload);

			if (!objectChecker(result, ["error"])) {
				if (successCallback) {
					const res = getResultFromApiResponse(result);
					successCallback(res?.procedure_results);
				}
			} else {
				if (failCallback) {
					failCallback(result);
				}
			}
		} catch (err) {
			if (failCallback) {
				failCallback(err);
			}
		}
	};
};

export const setGSSList_NEW = (data) => {
	return {
		type: actionTypes.SET_GLOBAL_STATIC_SETTING_NEW,
		payload: data,
	};
};

export const getGSSList_NEW = () => {
	return async (dispatch) => {
		try {
			const data = await Service.global.getGSSList();
			const gssList = data?.data ? data?.data : null;

			if (gssList && gssList.length > 0) {
				const list = gssList.map((item) => {
					return item[global_static_setting._name];
				});
				dispatch(setGSSList_NEW(list));
			} else {
				dispatch(setGSSList_NEW(null));
			}
		} catch (err) {
			dispatch(setGSSList_NEW(null));
		}
	};
};

export const checkMatchGlobalRecords = (payload, successCallback, failCallback) => {
	return async (dispatch) => {
		try {
			const data = await Service.global.matchGlobalRecords(payload);
			const res = objectChecker(data, ["response"], null);

			if (res) {
				if (successCallback) {
					successCallback(res);
				}
			}
		} catch (err) {
			if (failCallback) {
				failCallback(err);
			}
		}
	};
};
/* -------------------------------------------------------------------- */
