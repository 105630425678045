export const object_campaign_name = "campaign";

export const campaign_main = {
	_name: "campaign_main",
	id: "id",
	campaign_name: "campaign_name",
	campaign_type_c: "campaign_type_c",
	campaign_status_c: "campaign_status_c",
	record_state_sc: "record_state_sc",
	campaign_details: "campaign_details",
	workflow_main_ref_id: "workflow_main_ref_id",

	/** Alias */
	campaign_main_id: "campaign_main_id",
	ID: "ID",
};

export const campaign_item = {
	_name: "campaign_item",
	id: "id",
	campaign_main_ref_id: "campaign_main_ref_id",
	item_state_c: "item_state_c",
	item_disposition_c: "item_disposition_c",
	item_object_type_sc: "item_object_type_sc",
	item_object_subtype_sc: "item_object_subtype_sc",
	item_object_ref_id: "item_object_ref_id",
	item_priority: "item_priority",
};

export const campaign_item_details = {
	_name: "campaign_item_details",
	id: "id",
	campaign_main_ref_id: "campaign_main_ref_id",
	item_details: "item_details",
};

export const campaign_execution = {
	_name: "campaign_execution",
	id: "id",
	campaign_main_ref_id: "campaign_main_ref_id",
	execution_state_c: "execution_state_c",
	execution_result_c: "execution_result_c",
	execution_details: "execution_details",
};

export const campaign_permission = {
	_name: "campaign_permission",
	id: "id",
	campaign_main_ref_id: "campaign_main_ref_id",
	security_group_ref_id: "security_group_ref_id",
};

export const campaign_credential_link = {
	_name: "campaign_credential_link",
	id: "id",
	campaign_main_ref_id: "campaign_main_ref_id",
	credential_main_ref_id: "credential_main_ref_id",
};

export const campaign_log_activity = {
	_name: "campaign_log_activity",
	id: "id",
	date_created: "date_created",
	account_main_ref_id: "account_main_ref_id",
	campaign_table_ref_id: "campaign_table_ref_id",
	log_object_c: "log_object_c",
	log_type_c: "log_type_c",
	log_action_c: "log_action_c",
	campaign_log_detail_ref_id: "campaign_log_detail_ref_id",
};

export const campaign_log_detail = {
	_name: "campaign_log_detail",
	id: "id",
	detail_text: "detail_text",
};

export const campaign_accociation = {
	_name: "campaign_association",
	id: "id",
	campaign_object_table_sc: "campaign_object_table_sc",
	campaign_object_ref_id: "campaign_object_ref_id",
	ref_object_base_sc: "ref_object_base_sc",
	ref_object_table_sc: "ref_object_table_sc",
	ref_object_record_id: "ref_object_record_id",
};
