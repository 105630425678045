import React from "react";
import { EuiIcon, EuiFlexGroup, EuiFlexItem, EuiButtonEmpty } from "@elastic/eui";
import styled from "styled-components";
import { size } from "lodash";

import AvatarWithPicture from "components/Common/AvatarWithPicture";

/**
 * Component for Static RAS Item
 */
const StaticRASItem = (props) => {
	const {
		ownersHash,
		assigneesHash,
		accountsHash,
		restriction,
		editable,
		compressed = false,
		initLableStyle = {},
	} = props;

	const renderOwners = () => {
		if (
			!ownersHash ||
			!size(ownersHash) ||
			!accountsHash ||
			size(accountsHash) === 0
		) {
			return null;
		}

		const items = [];

		Object.keys(ownersHash).forEach((idx) => {
			const accountName = accountsHash?.[idx];
			const owner = ownersHash[idx];

			if (accountName) {
				items.push(
					<EuiFlexItem key={`ras-owner-item-static-${owner.id}-${idx}`}>
						<AvatarWithPicture initials={accountName} />
					</EuiFlexItem>
				);
			}
		});

		return items;
	};

	const renderAssignees = () => {
		if (
			!assigneesHash ||
			!size(assigneesHash) ||
			!accountsHash ||
			size(accountsHash) === 0
		) {
			return null;
		}

		const items = [];

		Object.keys(assigneesHash).forEach((idx) => {
			const accountName = accountsHash?.[idx];
			const assignee = assigneesHash[idx];
			if (accountName) {
				items.push(
					<EuiFlexItem key={`ras-assignee-item-static-${assignee?.id}-${idx}`}>
						<AvatarWithPicture initials={accountName} />
					</EuiFlexItem>
				);
			}
		});

		return items;
	};

	const renderSeparator = () => {
		if (
			((ownersHash && size(ownersHash)) ||
				(assigneesHash && size(assigneesHash))) &&
			restriction?.restriction_type_c
		) {
			return (
				<div className="lock-icon-wrapper">
					<EuiIcon type="lock" size="s" />
				</div>
			);
		}

		return ownersHash && assigneesHash ? (
			<span className="owner-space">&nbsp;|&nbsp;</span>
		) : null;
	};

	const renderCompressed = () => {
		const countRas =
			(Object.keys(ownersHash).length || 0) +
			(Object.keys(assigneesHash).length || 0);
		const firstOwnerId = Object.keys(ownersHash)?.[0];
		const firstAssignedId = Object.keys(assigneesHash)?.[0];
		const accountName = firstOwnerId
			? accountsHash?.[firstOwnerId]
			: firstAssignedId
			? accountsHash?.[firstAssignedId]
			: "-";
		const plusText = !countRas || countRas <= 1 ? "" : `+${countRas - 1}`;

		return (
			<EuiFlexGroup gutterSize="xs" alignItems="center">
				<EuiFlexItem key={`ras-assignee-item-static`}>
					<AvatarWithPicture initials={accountName} />
				</EuiFlexItem>
				<EuiFlexItem>
					<span style={{ color: "var(--link-color)" }}>{plusText}</span>
				</EuiFlexItem>
			</EuiFlexGroup>
		);
	};

	if ((!ownersHash || !size(ownersHash)) && (!assigneesHash || !size(assigneesHash))) {
		if (editable) {
			return (
				<Wrapper className={compressed && "compressed"}>
					<EuiButtonEmpty compressed={compressed} style={initLableStyle}>
						<span style={initLableStyle}>Add Owner</span>
					</EuiButtonEmpty>
				</Wrapper>
			);
		} else {
			return null;
		}
	}

	if (compressed) {
		return renderCompressed();
	}

	return (
		<Wrapper>
			<EuiFlexGroup gutterSize="xs" alignItems="center">
				<EuiFlexItem>
					<EuiFlexGroup gutterSize="xs">{renderOwners()}</EuiFlexGroup>
				</EuiFlexItem>
				<EuiFlexItem grow={false}>{renderSeparator()}</EuiFlexItem>
				<EuiFlexItem>
					<EuiFlexGroup gutterSize="xs">{renderAssignees()}</EuiFlexGroup>
				</EuiFlexItem>
			</EuiFlexGroup>
		</Wrapper>
	);
};

export default StaticRASItem;

const Wrapper = styled.div`
	display: flex;

	&.compressed {
		.euiButtonEmpty.euiButtonEmpty--primary {
			height: fit-content;
		}
	}
`;
