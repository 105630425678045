import { useDispatch } from "react-redux";
import {
    deleteCompany_NEW,
    deletePerson_NEW,
    getGlobalSearchItems,
    logoutAccount,
    updateCompany_NEW,
    updatePerson_NEW,
} from "helpers/actions";
import { _getGlobals, _getGlobalSearchItems } from "helpers/selectors";
import * as MODEL from "core/model";

const useLayout = () => {
    const dispatch = useDispatch();

    const globals = _getGlobals();

    const globalSearchItems = _getGlobalSearchItems();

    return {
        globals,
        globalSearchItems,

        getGlobalSearchItem: (term) => {
            dispatch(getGlobalSearchItems({ search_term: term }));
        },

        savePersonPhone: (personId, phone, successCallBack, failCallBack) => {
            const global = phone?.global_phone_details;
            const query = {
                [MODEL.person_phone._name]: {
                    [MODEL.person_phone.id]: phone?.id,
                    [MODEL.person_phone.person_main_ref_id]: personId,
                    [MODEL.person_phone.phone_type_c]: phone?.phone_type_c,
                    [MODEL.global_phone_details._name]: {
                        [MODEL.global_phone_details.id]: global?.id,
                        [MODEL.global_phone_details.phone_number]: global?.phone_number,
                        [MODEL.global_phone_details.phone_country_code]:
                            global?.phone_country_code,
                        [MODEL.global_phone_details.phone_extension]:
                            global?.phone_extension,
                        [MODEL.global_phone_details.phone_status_c]:
                            global?.phone_status_c,
                    },
                },
            };

            return new Promise((resolve, reject) => {
                dispatch(
                    updatePerson_NEW(
                        query,
                        (res) => {
                            if (successCallBack) successCallBack(res);
                            resolve(res);
                        },
                        (err) => {
                            if (failCallBack) failCallBack(err);
                            reject(err);
                        }
                    )
                );
            });
        },

        savePersonEmail: (personId, email, successCallBack, failCallBack) => {
            const global = email?.global_email_details;
            const query = {
                [MODEL.person_email._name]: {
                    [MODEL.person_email.id]: email?.id,
                    [MODEL.person_email.person_main_ref_id]: personId,
                    [MODEL.person_email.email_type_c]: email?.email_type_c,
                    [MODEL.global_email_details._name]: {
                        [MODEL.global_email_details.id]: global?.id,
                        [MODEL.global_email_details.email_value]: global?.email_value,
                        [MODEL.global_email_details.email_status_date]:
                            global?.email_status_date,
                        [MODEL.global_email_details.email_status_c]:
                            global?.email_status_c,
                    },
                },
            };

            return new Promise((resolve, reject) => {
                dispatch(
                    updatePerson_NEW(
                        query,
                        (res) => {
                            if (successCallBack) successCallBack(res);
                            resolve(res);
                        },
                        (err) => {
                            if (failCallBack) failCallBack(err);
                            reject(err);
                        }
                    )
                );
            });
        },

        deletePerson: (query, successCallBack, failCallBack) => {
            dispatch(deletePerson_NEW(query, successCallBack, failCallBack));
        },

        // company edit
        saveCompanyPhone: (companyId, phone, successCallBack, failCallBack) => {
            const global = phone?.global_phone_details;
            const query = {
                [MODEL.company_phone._name]: {
                    [MODEL.company_phone.id]: phone?.id,
                    [MODEL.company_phone.company_main_ref_id]: companyId,
                    [MODEL.company_phone.phone_type_c]: phone?.phone_type_c,
                    [MODEL.global_phone_details._name]: {
                        [MODEL.global_phone_details.id]: global?.id,
                        [MODEL.global_phone_details.phone_number]: global?.phone_number,
                        [MODEL.global_phone_details.phone_country_code]:
                            global?.phone_country_code,
                        [MODEL.global_phone_details.phone_extension]:
                            global?.phone_extension,
                        [MODEL.global_phone_details.phone_status_c]:
                            global?.phone_status_c,
                    },
                },
            };

            return new Promise((resolve, reject) => {
                dispatch(
                    updateCompany_NEW(
                        query,
                        (res) => {
                            if (successCallBack) successCallBack(res);
                            resolve(res);
                        },
                        (err) => {
                            if (failCallBack) failCallBack(err);
                            reject(err);
                        }
                    )
                );
            });
        },

        saveCompanyEmail: (companyId, email, successCallBack, failCallBack) => {
            const global = email?.global_email_details;
            const query = {
                [MODEL.company_email._name]: {
                    [MODEL.company_email.id]: email?.id,
                    [MODEL.company_email.company_main_ref_id]: companyId,
                    [MODEL.company_email.email_type_c]: email?.email_type_c,
                    [MODEL.global_email_details._name]: {
                        [MODEL.global_email_details.id]: global?.id,
                        [MODEL.global_email_details.email_value]: global?.email_value,
                        [MODEL.global_email_details.email_status_date]:
                            global?.email_status_date,
                        [MODEL.global_email_details.email_status_c]:
                            global?.email_status_c,
                    },
                },
            };

            return new Promise((resolve, reject) => {
                dispatch(
                    updateCompany_NEW(
                        query,
                        (res) => {
                            if (successCallBack) successCallBack(res);
                            resolve(res);
                        },
                        (err) => {
                            if (failCallBack) failCallBack(err);
                            reject(err);
                        }
                    )
                );
            });
        },

        deleteCompany: (query, successCallBack, failCallBack) => {
            dispatch(deleteCompany_NEW(query, successCallBack, failCallBack));
        },

        // logout
        logoutAccount: (query, successCallBack, failCallBack) => {
            dispatch(logoutAccount(query, successCallBack, failCallBack));
        },
    };
};

export default useLayout;
