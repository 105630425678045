/******************************************************************************
 * Constants related to Global Table
 *
 * such as link_type_c, etc
 *
 *****************************************************************************/

import {
	emailGlobeIcon,
	emailIcon,
	linkTyeDribbleIcon,
	linkTypeBehanceIcon,
	linkTypeFacebookIcon,
	linkTypeGeneralIcon,
	linkTypeInstagramIcon,
	linkTypeLinkedInIcon,
	linkTypeLinkedinSalesIcon,
	linkTypePinterestIcon,
	linkTypeProfileIcon,
	linkTypeStackOverflowIcon,
	linkTypeTumblrIcon,
	linkTypeTwitterIcon,
	cellIcon,
	workIcon,
	homeIcon,
	businessPhoneIcon,
	phoneHomeIcon,
	pBadgeIcon,
	wBadgeIcon,
} from "components/Common/Icons";

/**
 * List for link_type_c
 *
 * refer to global/link_type_c
 *
 *  # -- link_type_c
 *  CALL CORE_update_global_custom_setting('global', 'link_type_c', 'generic', 1);
 *  CALL CORE_update_global_custom_setting('global', 'link_type_c', 'linkedin-profile', 2);
 *  CALL CORE_update_global_custom_setting('global', 'link_type_c', 'linkedin-avatar', 3);
 *  CALL CORE_update_global_custom_setting('global', 'link_type_c', 'personal-site', 4);
 *  CALL CORE_update_global_custom_setting('global', 'link_type_c', 'business-site', 5);
 *  CALL CORE_update_global_custom_setting('global', 'link_type_c', 'linkedin-profile-salesnav', 6);
 *  CALL CORE_update_global_custom_setting('global', 'link_type_c', 'icon', 7);
 *  CALL CORE_update_global_custom_setting('global', 'link_type_c', 'social-site', 8);
 *  CALL CORE_update_global_custom_setting('global', 'link_type_c', 'professional-site', 9);
 *
 */
export const GLOBAL_LINK_TYPES = {
	generic: { key: "generic", value: 1 },
	linkedinProfile: { key: "linkedin-profile", value: 2 },
	linkedinAvatar: { key: "linkedin-avatar", value: 3 },
	personalSite: { key: "personal-site", value: 4 },
	businessSite: { key: "business-site", value: 5 },
	linkedinProfileSalesnav: { key: "linkedin-profile-salesnav", value: 6 },
	icon: { key: "icon", value: 7 },
	socialSite: { key: "social-site", value: 8 },
	professionalSite: { key: "professional-site", value: 9 },
};

export const AVAILABLE_LINK_LIST = {
	facebook: {
		linkUrl: "facebook.com",
		icon: linkTypeFacebookIcon,
		linkType: GLOBAL_LINK_TYPES.socialSite,
	},
	linkdInProfile: {
		linkUrl: "linkedin.com/in/",
		icon: linkTypeLinkedInIcon,
		linkType: GLOBAL_LINK_TYPES.linkedinProfile,
	},
	linkedInSales: {
		linkUrl: "linkedin.com/sales",
		icon: linkTypeLinkedinSalesIcon,
		linkType: GLOBAL_LINK_TYPES.linkedinProfileSalesnav,
	},
	profileDisplayphoto: {
		linkUrl: "profile-displayphoto",
		icon: linkTypeProfileIcon,
		linkType: GLOBAL_LINK_TYPES.linkedinAvatar,
	},
	twitter: {
		linkUrl: "twitter.com",
		icon: linkTypeTwitterIcon,
		linkType: GLOBAL_LINK_TYPES.socialSite,
	},
	tumblr: {
		linkUrl: "tumblr.com",
		icon: linkTypeTumblrIcon,
		linkType: GLOBAL_LINK_TYPES.professionalSite,
	},
	dribble: {
		linkUrl: "dribbble.com",
		icon: linkTyeDribbleIcon,
		linkType: GLOBAL_LINK_TYPES.professionalSite,
	},
	stackoverflow: {
		linkUrl: "stackoverflow.com",
		icon: linkTypeStackOverflowIcon,
		linkType: GLOBAL_LINK_TYPES.professionalSite,
	},
	behance: {
		linkUrl: "behance.net",
		icon: linkTypeBehanceIcon,
		linkType: GLOBAL_LINK_TYPES.professionalSite,
	},
	instagram: {
		linkUrl: "instagram.com",
		icon: linkTypeInstagramIcon,
		linkType: GLOBAL_LINK_TYPES.socialSite,
	},
	pinterest: {
		linkUrl: "pinterest.com",
		icon: linkTypePinterestIcon,
		linkType: GLOBAL_LINK_TYPES.socialSite,
	},
	// general link type
	general: {
		linkUrl: "",
		icon: linkTypeGeneralIcon,
		linkType: GLOBAL_LINK_TYPES.generic,
	},
};

export const GLOBAL_EMAIL_PHONE_RANGES = [
	{
		value: false,
		label: "Personal",
		iconLabel: "p:",
		icon: pBadgeIcon,
	},
	{
		value: true,
		label: "Work",
		iconLabel: "w:",
		icon: wBadgeIcon,
	},
];

export const GLOBAL_EMAIL_TYPE_OPTIONS = [
	{
		value: 1,
		label: "Personal",
		iconLabel: "p:",
		icon: emailIcon,
		inputDisplay: emailIcon(),
		range: false,
	},
	{
		value: 2,
		label: "Work",
		iconLabel: "w:",
		icon: emailGlobeIcon,
		inputDisplay: emailGlobeIcon(),
		range: true,
	},
];

export const GLOBAL_PHONE_TYPE_OPTIONS = [
	{
		value: 2,
		label: "Cell",
		icon: cellIcon,
		inputDisplay: cellIcon(),
		range: false,
	},
	{
		value: 1,
		label: "Home",
		icon: homeIcon,
		inputDisplay: homeIcon(),
		range: false,
	},
	{
		value: 4,
		label: "Other",
		icon: phoneHomeIcon,
		inputDisplay: phoneHomeIcon(),
		range: false,
	},
	{
		value: 5,
		label: "Workcell",
		icon: cellIcon,
		inputDisplay: cellIcon(),
		range: true,
	},
	{
		value: 3,
		label: "Work",
		icon: workIcon,
		inputDisplay: workIcon(),
		range: true,
	},
	{
		value: 6,
		label: "Business",
		icon: businessPhoneIcon,
		inputDisplay: businessPhoneIcon(),
		range: true,
	},
];
