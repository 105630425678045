import API from "api";

import { person_email, person_main } from "core/model";

import { account_main, global_email_details } from "core/model";

import {
	makeSort,
	makeFieldContent,
	makeRefJoinTable,
	makeExternalJoin,
	makeTableJoin,
} from "./commonService";

/** ---------------- Generic API ---------------------- */
const getPersonMainList = ({
	searchTerm = "",
	pageNumber = 1,
	pageCount = 20,
	params = null,
}) => {
	const query = {
		page_number: pageNumber,
		page_count: pageCount,
		table_main: person_main._name,
		table_join: makeTableJoin([person_email]),
		external_join: [makeExternalJoin(person_email, global_email_details)],
		fields: [
			makeFieldContent(person_main, person_main.id),
			makeFieldContent(person_main, person_main.date_created),
			makeFieldContent(person_main, person_main.name_first),
			makeFieldContent(person_main, person_main.name_last),
			makeFieldContent(person_main, person_main.name_middle),
			makeFieldContent(person_main, person_main.name_nick),
			makeFieldContent(person_main, person_main.date_birth),

			// person email
			makeFieldContent(person_email, person_email.id),
			makeFieldContent(person_email, person_email.email_type_c),
			makeFieldContent(global_email_details, global_email_details.id),
			makeFieldContent(global_email_details, global_email_details.email_value),
			makeFieldContent(global_email_details, global_email_details.email_status_c),
			makeFieldContent(
				global_email_details,
				global_email_details.email_status_date
			),
		],
		sort: [
			makeSort(person_main, person_main.name_first),
			makeSort(person_main, person_main.name_last),
		],
		search_fields: [
			{
				table: person_main._name,
				field: person_main.id,
			},
			{
				table: person_main._name,
				field: person_main.name_first,
			},
			{
				table: person_main._name,
				field: person_main.name_last,
			},
			{
				table: person_main._name,
				field: person_main.name_middle,
			},
			{
				table: person_main._name,
				field: person_main.name_nick,
			},
		],
		search_type: "term",
		search_term: searchTerm,
		...(params ? { ...params } : {}),
	};

	return API().GenericAPI().getTableData(query);
};

const getPersonDetial = ({ id }) => {
	const query = {
		object: "person",
		record_main_id: id,
	};

	return API().GenericAPI().getMainData(query);
};

const updatePerson = (data) => {
	const query = {
		object: "person",
		data: data || {},
	};

	return API().GenericAPI().updateTableData(query);
};

const deletePerson = (data) => {
	const query = {
		object: "person",
		data: data || {},
	};

	return API().GenericAPI().deleteTableData(query);
};

const getZipAreas = (params) => {
	const query = {
		zip_code: params?.zip_code || 0,
		radius: params?.radius || 0,
	};

	return API().Person().getZipAreas(query);
};
/** --------------------------------------------------------- */

/** ----------------------- Custom API ---------------------- */
const getPersonList = (data) => {
	return API().Person().personList(data);
};

const getPersonIdList = (data) => {
	const query = {
		...data,
		array_only: true,
	};
	return API().Person().personList(query);
};
/* ---------------------------------------------------------- */

/** ----------------------- REST API ------------------------ */
const uploadFiles = (data) => {
	return API().Person().uploadFiles_NEW(data);
};

const parseFile = (data) => {
	return API().Person().parseFile_NEW(data);
};
/* ---------------------------------------------------------- */

export default {
	getPersonDetial,
	getPersonMainList,
	updatePerson,
	deletePerson,
	getPersonList,
	getPersonIdList,
	uploadFiles,
	parseFile,
	getZipAreas,
};
