import { useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
	actionWorkflowRestart,
	getCampaignWorkflowItems,
	setWorkflowItemsParams,
	getWorkflowItemCounts,
	getCampaignDetail_NEW,
	setWorkflowItems,
	setWorkflowItemCounts,
	createCampaign,
	updateCampaign,
	getCampaignList_NEW,
	deleteGeneralCampaign,
	getJobMainListForCreateCampaign,
	executeCampaign,
	getCampaignListBySearch,
	getCampaignListByPerson,
	copyCampaign,
	deleteCampaignItems,
	deleteCampaign,
	setWorkflowItemPriority,
	getAllCampaignList,
	setCampaignListParams,
	actionWorkflowSkip,
	updateWorkflowNormal,
} from "helpers/actions";
import {
	_getCampaignDetail,
	_getCampaignList,
	_getCampaignWorkflowList,
	_getAllCampaignMainList,
} from "helpers/selectors";
import { isArray } from "lodash";
import { CAMPAIGN_EXECUTION_TYPES } from "components/global/constants";

import * as MODEL from "core/model";

import useGlobal from "./useGlobal";
import { changeFormat, getGCSLists } from "components/global/utils";

/**
 * Hooks for Campaign
 */
const useCampaign = () => {
	const dispatch = useDispatch();

	const { globals } = useGlobal();

	const workflowItemList = _getCampaignWorkflowList();
	const campaignDetail = _getCampaignDetail();
	const campaignList = _getCampaignList();
	const campaignAllMainList = _getAllCampaignMainList();

	const campaignListBySearch = useSelector(
		(state) => state.campaign.campaignListBySearch?.data || []
	);
	const campaignListByPerson = useSelector(
		(state) => state.campaign.campaignListByPerson?.data || []
	);

	const campaignTypes = useMemo(() => {
		const types = [];
		if (globals && globals.length > 0) {
			const data = getGCSLists(globals, "campaign_main", "campaign_type_c");

			if (isArray(data)) {
				data.forEach((campaign) => {
					types.push({
						text: changeFormat(campaign.text),
						value: campaign.value,
					});
				});
			}
		}

		return types;
	}, [globals]);

	// Campaign
	const callCreateCampaign = (data, successCallback, failCallback) => {
		dispatch(createCampaign(data, successCallback, failCallback));
	};

	const callUpdateCampaign = (data, successCallback, failCallback) => {
		dispatch(updateCampaign(data, successCallback, failCallback));
	};

	const getCampaignDetail = (id, successCallback, failCallback) => {
		dispatch(getCampaignDetail_NEW(id, successCallback, failCallback));
	};

	const getCampaignList = (params, successCallback, failCallback) => {
		dispatch(getCampaignList_NEW(params, successCallback, failCallback));
	};

	const updateCampaignListParams = (params, successCallback, failCallback) => {
		dispatch(setCampaignListParams(params, successCallback, failCallback));
	};

	const callGetAllCampaignList = (successCallback, failCallback) => {
		dispatch(getAllCampaignList(successCallback, failCallback));
	};

	const callDeleteCampaign = (id, successCallback, failCallback) => {
		dispatch(deleteCampaign(id, successCallback, failCallback));
	};

	const callDeleteGeneralCampaign = (id, successCallback, failCallback) => {
		dispatch(deleteGeneralCampaign(id, successCallback, failCallback));
	};

	const callCopyCampaign = (id, successCallback, failCallback) => {
		dispatch(copyCampaign(id, successCallback, failCallback));
	};

	const getCampaignJobListForCreate = (searchTerm, successCallback, failCallback) => {
		dispatch(
			getJobMainListForCreateCampaign(searchTerm, successCallback, failCallback)
		);
	};

	const callExecuteCampaign = (id, successCallback, failCallback) => {
		const data = {
			id: id,
			execution: CAMPAIGN_EXECUTION_TYPES.start.value,
		};
		dispatch(executeCampaign(data, successCallback, failCallback));
	};

	const stopCampaign = (id, successCallback, failCallback) => {
		const data = {
			id: id,
			execution: CAMPAIGN_EXECUTION_TYPES.stop.value,
		};
		dispatch(executeCampaign(data, successCallback, failCallback));
	};

	const restartCampaign = (id, successCallback, failCallback) => {
		const data = {
			id: id,
			execution: CAMPAIGN_EXECUTION_TYPES.start.value,
		};
		dispatch(executeCampaign(data, successCallback, failCallback));
	};

	const pauseCampaign = (id, successCallback, failCallback) => {
		const data = {
			id: id,
			execution: CAMPAIGN_EXECUTION_TYPES.pause.value,
		};
		dispatch(executeCampaign(data, successCallback, failCallback));
	};

	const cancelCampaign = (id, successCallback, failCallback) => {
		const data = {
			id: id,
			execution: CAMPAIGN_EXECUTION_TYPES.stop.value,
		};
		dispatch(executeCampaign(data, successCallback, failCallback));
	};

	const updateWorkflow = (params, successCallback, failCallback) => {
		dispatch(updateWorkflowNormal(params, successCallback, failCallback));
	};

	// Workflow
	const updateWorkflowItems = (params) => {
		dispatch(setWorkflowItems(params));
	};

	const getWorkflowItems = (params, successCallback, failCallback) => {
		dispatch(getCampaignWorkflowItems(params, successCallback, failCallback));
	};

	const setWorkflowItemListParams = (params, successCallback, failCallback) => {
		dispatch(setWorkflowItemsParams(params, successCallback, failCallback));
	};

	const restartWorkflow = (params, successCallback, failCallback) => {
		dispatch(actionWorkflowRestart(params, successCallback, failCallback));
	};

	const getWorkflowItemCountList = (params, successCallback, failCallback) => {
		dispatch(getWorkflowItemCounts(params, successCallback, failCallback));
	};

	const updateWorkflowItemCountList = (params) => {
		dispatch(setWorkflowItemCounts(params));
	};

	const updateWorkflowItemPriority = (id, priority, successCallback, failCallback) => {
		dispatch(
			setWorkflowItemPriority(
				id,
				priority,
				MODEL.workflow_item._name,
				successCallback,
				failCallback
			)
		);
	};

	const updateWorkflowItemQueuePriority = (
		id,
		priority,
		successCallback,
		failCallback
	) => {
		dispatch(
			setWorkflowItemPriority(
				id,
				priority,
				MODEL.workflow_item_queue._name,
				successCallback,
				failCallback
			)
		);
	};

	// get campaign List by search and person
	const callGetCampaignListBySearch = (params, sucessCallback, failCallback) => {
		dispatch(getCampaignListBySearch(params, sucessCallback, failCallback));
	};

	const callGetCampaignListByPerson = (params, sucessCallback, failCallback) => {
		dispatch(getCampaignListByPerson(params, sucessCallback, failCallback));
	};

	const callDeleteCampaignItems = (params, successCallback, failCallback) => {
		dispatch(deleteCampaignItems(params, successCallback, failCallback));
	};

	// Campaign Item
	const skipWorkflowForCampaign = (params, successCallback, failCallback) => {
		dispatch(actionWorkflowSkip(params, successCallback, failCallback));
	};

	return {
		workflowItemList,
		campaignDetail,
		campaignList,
		campaignListBySearch,
		campaignListByPerson,
		campaignAllMainList,

		campaignTypes,

		getCampaignDetail,
		createCampaign: callCreateCampaign,
		updateCampaign: callUpdateCampaign,
		getCampaignList,
		updateCampaignListParams,
		getAllCampaignList: callGetAllCampaignList,
		deleteCampaign: callDeleteCampaign,
		deleteGeneralCampaign: callDeleteGeneralCampaign,
		copyCampaign: callCopyCampaign,
		getCampaignJobListForCreate,

		executeCampaign: callExecuteCampaign,
		stopCampaign,
		restartCampaign,
		pauseCampaign,
		cancelCampaign,

		updateWorkflow,
		updateWorkflowItems,
		getWorkflowItems,
		restartWorkflow,
		setWorkflowItemListParams,
		getWorkflowItemCounts: getWorkflowItemCountList,
		updateWorkflowItemCounts: updateWorkflowItemCountList,

		getCampaignListBySearch: callGetCampaignListBySearch,
		getCampaignListByPerson: callGetCampaignListByPerson,

		deleteCampaignItems: callDeleteCampaignItems,

		updateWorkflowItemPriority,
		updateWorkflowItemQueuePriority,
		skipWorkflowForCampaign,
	};
};

export default useCampaign;
