import Service from "core/service";
import { get } from "lodash";
import { logError } from "store/error";
import * as actionTypes from "./actionTypes";

export const setThemeList = (data) => {
	return {
		type: actionTypes.THEME_LIST,
		payload: data,
	};
};

export const getThemeList = (params) => {
	return async (dispatch) => {
		try {
			const result = await Service.theme.getThemeList(params);

			const data = get(result, "response.data");

			if (data) {
				dispatch(setThemeList(data));
			} else {
				dispatch(setThemeList(null));
			}
		} catch (err) {
			dispatch(
				logError("fetch_failure", "none", {
					Error: "Error fetching theme list: " + JSON.stringify(err),
				})
			);
			dispatch(setThemeList(null));
		}
	};
};
