import { get } from "lodash";

import Service from "core/service";

import { getResultFromApiResponse } from "helpers/utilities";

export const updateAms = (params, successCallback, failCallback) => {
    return async (dispatch) => {
        if (!params) {
            failCallback(null);
        }

        try {
            let payload = params;

            const result = await Service.ams.updateAms(payload);

            if (!get(result, "error")) {
                if (successCallback) {
                    const res = getResultFromApiResponse(result);
                    successCallback(res?.procedure_results);
                }
            }
        } catch (err) {
            if (failCallback) {
                failCallback(err);
            }
        }
    };
};

export const deleteAms = (params, successCallback, failCallback) => {
    return async (dispatch) => {
        if (!params) {
            failCallback(null);
        }

        try {
            let payload = params;

            const result = await Service.ams.deleteAms(payload);

            if (!get(result, "error")) {
                if (successCallback) {
                    const res = getResultFromApiResponse(result);
                    successCallback(res?.procedure_results);
                }
            }
        } catch (err) {
            if (failCallback) {
                failCallback(err);
            }
        }
    };
};
/* --------------------------------------------- */
