import { useDispatch } from "react-redux";

import { _getMacroList } from "helpers/selectors";
import { getMacrosList, getTranslatedMessage } from "helpers/actions";

/**
 * Hooks for Macro
 */
const useMacro = () => {
	const dispatch = useDispatch();

	const macroList = _getMacroList();
	let macroOptions = [];
	if (macroList?.length) {
		macroList.map((item) => {
			macroOptions.push({
				value: item?.macro_name,
			});
		});
	}

	const getMacroTranslatedString = (str, successfullCallback, failCallback) => {
		dispatch(getTranslatedMessage(str, successfullCallback, failCallback));
	};

	return {
		macroList,
		macroOptions,

		getMacroList: () => {
			dispatch(getMacrosList());
		},
		getMacroTranslatedString,
	};
};

export default useMacro;
