import React, { useState } from "react";
import styled from "styled-components";
import { EuiFlexGroup, EuiFlexItem, EuiRadio, EuiText } from "@elastic/eui";
import { htmlIdGenerator } from "@elastic/eui/lib/services";

import { TRAVEL_TYPES } from "./constants";

/**
 * Component for Compensation Travel
 */
const CompensationTravel = (props) => {
	const { data, onChange } = props;

	const [selectedItemKey, setSelectedItemKey] = useState(TRAVEL_TYPES.undefined.key);

	const [estimateValue, setEstimateValue] = useState(0);

	const handleChangeItem = (e) => {
		setSelectedItemKey(e.target.value);
	};

	return (
		<Wrapper>
			<EuiFlexGroup gutterSize="m" direction="column" alignItems="flexStart">
				<EuiFlexItem grow={1} className="type-title">
					<EuiText textAlign="center">
						<span>Travel</span>
					</EuiText>
				</EuiFlexItem>
				<EuiFlexItem className="type-content">
					<EuiFlexGroup gutterSize="s" direction="column">
						<EuiFlexItem>
							<EuiFlexGroup gutterSize="s" direction="row">
								<EuiFlexItem grow={false}>
									<EuiRadio
										id={htmlIdGenerator()()}
										checked={
											TRAVEL_TYPES.undefined.key === selectedItemKey
										}
										label=""
										onChange={(e) => handleChangeItem(e)}
										value={TRAVEL_TYPES.undefined.key}
										className="radio-option"
									/>
								</EuiFlexItem>
								<EuiFlexItem>
									<EuiText
										className={`undefined detail detail-label ${
											TRAVEL_TYPES.undefined.key === selectedItemKey
												? ""
												: "disable"
										}`}
										size="s"
										textAlign="left"
									>
										{TRAVEL_TYPES.undefined.label}
									</EuiText>
								</EuiFlexItem>
							</EuiFlexGroup>
						</EuiFlexItem>
						<EuiFlexItem>
							<EuiFlexGroup
								gutterSize="s"
								direction="row"
								className="detail"
							>
								<EuiFlexItem grow={false}>
									<EuiRadio
										id={htmlIdGenerator()()}
										checked={
											TRAVEL_TYPES.required.key === selectedItemKey
										}
										label=""
										onChange={(e) => handleChangeItem(e)}
										value={TRAVEL_TYPES.required.key}
										className="radio-option"
									/>
								</EuiFlexItem>
								<EuiFlexItem grow={false}>
									<EuiFlexGroup gutterSize="s" direction="column">
										<EuiFlexItem>
											<EuiText
												className={`required detail detail-label ${
													TRAVEL_TYPES.required.key ===
													selectedItemKey
														? ""
														: "disable"
												}`}
												size="s"
												textAlign="left"
											>
												Willing to Travel (
												<span className="value">30</span>
												%)
											</EuiText>
										</EuiFlexItem>
										<EuiFlexItem>
											<EuiText
												className={`required detail detail-label ${
													TRAVEL_TYPES.required.key ===
													selectedItemKey
														? ""
														: "disable"
												}`}
												size="s"
												textAlign="left"
											>
												<span>Minimum&nbsp;</span>
												<span className="value">5</span>
												<span>&nbsp;days&nbsp;per&nbsp;</span>
												<span className="value">week</span>
											</EuiText>
										</EuiFlexItem>
									</EuiFlexGroup>
								</EuiFlexItem>
							</EuiFlexGroup>
						</EuiFlexItem>
						<EuiFlexItem>
							<EuiFlexGroup
								gutterSize="s"
								direction="row"
								className="detail"
							>
								<EuiFlexItem grow={false}>
									<EuiRadio
										id={htmlIdGenerator()()}
										checked={
											TRAVEL_TYPES.notRequired.key ===
											selectedItemKey
										}
										label=""
										onChange={(e) => handleChangeItem(e)}
										value={TRAVEL_TYPES.notRequired.key}
										className="radio-option"
									/>
								</EuiFlexItem>
								<EuiFlexItem grow={false}>
									<EuiText
										className={`notRequired detail detail-label ${
											TRAVEL_TYPES.notRequired.key ===
											selectedItemKey
												? ""
												: "disable"
										}`}
										size="s"
										textAlign="left"
									>
										{TRAVEL_TYPES.notRequired.label}
									</EuiText>
								</EuiFlexItem>
							</EuiFlexGroup>
						</EuiFlexItem>
					</EuiFlexGroup>
				</EuiFlexItem>
			</EuiFlexGroup>
		</Wrapper>
	);
};

export default CompensationTravel;

const Wrapper = styled.div`
	display: flex;
	position: relative;
	width: 100%;

	.type-title {
		width: 100%;

		span {
			font-weight: 600;
			font-size: 0.75rem;
			line-height: 2;

			color: var(--light-color);
		}
	}

	.type-content {
		width: calc(100% - 16px);
		margin-top: 0;
	}

	.detail {
		.detail-label,
		.detail-value {
			font-size: 1rem;
			line-height: 1.5;
		}

		.value {
			color: var(--eui-link-color);
		}
	}

	.radio-option {
		.euiRadio__circle {
			background-image: none !important;
		}
	}

	.undefined {
		display: flex;
		justify-content: space-between;
	}

	.disable {
		color: var(--light-color) !important;

		span {
			color: var(--light-color) !important;
		}
	}
`;
