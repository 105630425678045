import { _getAuthToken } from "helpers/selectors";

/**
 * Hooks for User
 */
const useAccount = () => {
	const token = _getAuthToken();

	return {
		token,
	};
};

export default useAccount;
