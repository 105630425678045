import * as actionTypes from "./actionTypes";

export const updateGlobalFlyoutParams = (params) => {
	return {
		type: actionTypes.SET_FLYOUT_PARAMS,
		payload: params,
	};
};

export const openGlobalFlyout = () => {
	return {
		type: actionTypes.OPEN_FLYOUT,
	};
};

export const closeGlobalFlyout = () => {
	return {
		type: actionTypes.CLOSE_FLYOUT,
	};
};
