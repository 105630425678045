import React, { useState, useEffect } from "react";
import styled from "styled-components";
import "react-phone-number-input/style.css";
import PhoneInput, { parsePhoneNumber } from "react-phone-number-input";
import { EuiSuperSelect, EuiFieldText, EuiCheckbox } from "@elastic/eui";

import {
	cellIcon,
	otherPhoneIcon,
	workCellIcon,
	workIcon,
	homeIcon,
} from "components/Common/Icons/Icons";

/**
 * Main Components
 */
const EditPhone = (props) => {
	const {
		idx,
		value,
		phoneCountryCode,
		phoneType = 1,
		phoneExtVal = "Ext",
		phonePrimary = true,
		onChangePhone,
		onChangePhoneType,
		onChangePhoneExtVal,
		onChangePhonePrimary,
		...restProps
	} = props;

	const [primary, setPrimary] = useState(false);

	const handleChangePhone = (val) => {
		if (val) {
			const phoneNumber = parsePhoneNumber(val);
			onChangePhone(idx, {
				countryCode: phoneNumber?.countryCallingCode,
				val: val,
			});
		}
	};

	const handleChangePhoneType = (val) => {
		onChangePhoneType(idx, val);
	};

	const handlePhoneExtVal = (e) => {
		onChangePhoneExtVal(idx, e.target.value);
	};

	const handlePhonePrimary = (e) => {
		onChangePhonePrimary(idx, e.target.checked);
	};

	const phoneTypeOptions = [
		{
			value: 1,
			label: "home",
			inputDisplay: homeIcon(),
		},
		{
			value: 2,
			label: "cell",
			inputDisplay: cellIcon(),
		},
		{
			value: 3,
			label: "work",
			inputDisplay: workIcon(),
		},
		{
			value: 4,
			label: "workcell",
			inputDisplay: workCellIcon(),
		},
		{
			value: 5,
			label: "other",
			inputDisplay: otherPhoneIcon(),
		},
	];

	useEffect(() => {
		setPrimary(phonePrimary);
	}, [phonePrimary]);

	return (
		<Wrapper {...restProps}>
			<EuiSuperSelect
				size="s"
				options={phoneTypeOptions}
				valueOfSelected={phoneType === 0 ? 1 : phoneType}
				onChange={handleChangePhoneType}
			/>
			<PhoneInput
				placeholder="Enter phone number"
				value={value}
				defaultCountry="US"
				onChange={handleChangePhone}
			/>
			<EuiFieldText
				placeholder="Ext"
				value={phoneExtVal ? phoneExtVal : ""}
				onChange={(e) => handlePhoneExtVal(e)}
			/>
			<EuiCheckbox
				id={"extension-phone-" + idx}
				checked={primary}
				onChange={(e) => handlePhonePrimary(e)}
				className="phone-valid"
			/>
		</Wrapper>
	);
};

export default EditPhone;

/**
 * Styled Components
 */
const Wrapper = styled.div`
	display: flex;

	.euiSuperSelectControl {
		height: 25px;
		line-height: 28px;
		padding-right: 25px;
		padding-left: 6px;
	}

	.euiFormControlLayoutIcons--right {
		right: 6px;
	}

	.euiFieldText {
		width: 40px;
		padding: 4px;
	}

	.phone-valid {
		margin-top: auto;
		margin-bottom: auto;
		margin-left: auto;
	}

	.euiCheckbox .euiCheckbox__input + .euiCheckbox__square {
		border-radius: 50%;
	}
`;
