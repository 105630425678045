import React from "react";
import styled from "styled-components";
import {
	EuiFlexGroup,
	EuiFlexItem,
	EuiButton,
	EuiBetaBadge,
	EuiAvatar,
} from "@elastic/eui";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { linkedInIcon, returnBackIcon } from "components/Common/Icons/Icons";
import { faPlus } from "@fortawesome/pro-light-svg-icons";

/**
 * Main Components
 */
const AmsCommunicationItem = (props) => {
	const { data } = props;

	const handleClickAdd = () => {
		console.log("gotoActivity Detail");
	};

	return (
		<Wrapper>
			<EuiFlexGroup gutterSize="s" direction="column">
				<EuiFlexItem>
					<EuiFlexGroup>
						<EuiFlexItem grow={2}>
							<EuiFlexGroup gutterSize="xs" direction="column">
								<EuiFlexItem>
									<EuiFlexGroup gutterSize="s">
										<EuiFlexItem>{linkedInIcon()}</EuiFlexItem>
										<EuiFlexItem>{returnBackIcon()}</EuiFlexItem>
									</EuiFlexGroup>
								</EuiFlexItem>
								<EuiFlexItem grow={false}>
									<p className="light-span">scheduled</p>
								</EuiFlexItem>
								<EuiFlexItem grow={false}>
									<p className="light-span">scheduled</p>
								</EuiFlexItem>
							</EuiFlexGroup>
						</EuiFlexItem>

						<EuiFlexItem grow={9}>
							<EuiFlexGroup gutterSize="xs" direction="column">
								<EuiFlexItem>
									<p className="content-header">
										Call Regarding Job 1156 - Software Engineer
									</p>
								</EuiFlexItem>
								<EuiFlexItem grow={false}>
									<p>
										on then I need to push it back because I have a
										meeting that I will need
									</p>
								</EuiFlexItem>
							</EuiFlexGroup>
						</EuiFlexItem>

						<EuiFlexItem grow={1}>
							<EuiFlexGroup direction="column" className="right-side">
								<EuiFlexItem>
									<EuiButton
										color="text"
										className="button-fit-content plus-button"
										onClick={handleClickAdd}
									>
										<FontAwesomeIcon
											icon={faPlus}
											size="1x"
											color="var(--eui-darkest-shade-color)"
										/>
									</EuiButton>
								</EuiFlexItem>
								<EuiFlexItem className="avatar">
									<EuiAvatar size="s" name={"Jack Wang"} />
								</EuiFlexItem>
							</EuiFlexGroup>
						</EuiFlexItem>
					</EuiFlexGroup>
				</EuiFlexItem>
				<EuiFlexItem>
					<EuiFlexItem grow={false}>
						<EuiFlexGroup className="badge-items" gutterSize="s">
							<EuiFlexItem grow={false} className="badge">
								<EuiBetaBadge label="1234" />
							</EuiFlexItem>
							<EuiFlexItem grow={false} className="badge">
								<EuiBetaBadge label="1234" />
							</EuiFlexItem>
						</EuiFlexGroup>
					</EuiFlexItem>
				</EuiFlexItem>
			</EuiFlexGroup>
		</Wrapper>
	);
};

export default AmsCommunicationItem;

/**
 * Styled Components
 */
const Wrapper = styled.div`
	padding: 8px 10px;
	border-bottom: solid 1px var(--border-color);

	.light-span {
		color: var(--light-color);
		font-size: 0.75rem;
		font-weight: 600;
	}

	.content-header {
		color: var(--link-color);
	}

	.badge-items {
		margin: 0.5rem 0 0 0;
	}
	.badge {
		margin: 4px 4px 0px 0px;
	}

	.right-side {
		margin-left: auto;
		.icon {
			color: var(--link-color);
			margin-right: 0.5rem;
		}
		.avatar {
			margin-left: auto;
		}
	}

	.plus-button {
		background: var(--eui-lightest-shade-color);
		border-color: var(--transparent);
		min-width: 0 !important;
		height: auto !important;
		line-height: 1 !important;

		.euiButtonContent.euiButton__content {
			padding: var(--sp-2) !important;
		}

		.euiButton__text {
			font-size: var(--sp-6);
		}

		svg {
			width: var(--sp-6);
			height: var(--sp-6);
		}
	}
`;
