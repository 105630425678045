import API from "api";

const getMacroList = (data) => {
	return API().Macros().getMacroList();
};

const processTemplateMacro = (data) => {
	return API().Macros().processTemplateMacro(data);
};

export default {
	getMacroList,
	processTemplateMacro,
};
