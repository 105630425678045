import React from "react";
import styled from "styled-components";
import { EuiLink, EuiToolTip } from "@elastic/eui";

import { SVGIcon } from "components/Common";
import { getClickableLink } from "components/global/utils";
import { checkIcon } from "components/Common/Icons";

import StaticItemBase from "../StaticItemBase";
import LinkSettingBar from "./LinkSettingBar";
import { LINK_TYPE_OPTIONS } from "./constants";

/**
 * Main Component for Static Link Item
 */
const StaticLink = (props) => {
	const {
		link,
		isPrimary = false,
		staticShow = false,
		hasIcon = true,
		onEdit,
		onDelete,
	} = props;

	/** Icon for Link Type - default: Home Link icon */
	//const iconLinkType = LINK_TYPE_OPTIONS.find((item) => item.value === link?.global_link_type_c)?.icon || LINK_TYPE_OPTIONS[0].icon;
	const iconLinkType = LINK_TYPE_OPTIONS[5].icon;
	/** Automatically check if url is linkedin, facebook and twitter and get the Icon */
	/*const iconLinkType = useMemo(() => {
		let icon = null;

		if (link?.global_url_details?.url_value?.length > 1) {
			const url = link?.global_url_details?.url_value;

			const urlInfo = getLinkInfoFromUrl(url);
			icon = urlInfo?.icon || null;
		}

		return icon;
	}, [link]);*/
	/** Icon for Phone Type - default: Home Phone icon */
	/** Rendering */
	if (!link) {
		return null;
	}

	return (
		<Wrapper style={staticShow ? { marginTop: "0.6rem" } : { marginTop: "0" }}>
			{staticShow ? (
				iconLinkType ? (
					<EuiToolTip
						position="top"
						content={link?.global_url_details?.url_value}
					>
						<EuiLink
							href={getClickableLink(link?.global_url_details?.url_value)}
							target="_blank"
						>
							<SVGIcon icon={iconLinkType} className="mr-2" />
						</EuiLink>
					</EuiToolTip>
				) : null
			) : (
				<StaticItemBase
					extraSetting={<LinkSettingBar onEdit={onEdit} onDelete={onDelete} />}
				>
					{iconLinkType ? (
						<SVGIcon icon={iconLinkType} className="mr-2" />
					) : null}
					<StaticLinkWrapper>
						<EuiLink
							href={getClickableLink(link?.global_url_details?.url_value)}
							target="_blank"
						>
							{link?.global_url_details?.url_value || ""}
						</EuiLink>
						{isPrimary && <div className="check-icon">{checkIcon()}</div>}
					</StaticLinkWrapper>
				</StaticItemBase>
			)}
		</Wrapper>
	);
};

export default StaticLink;

/**
 * Styled Components
 */
const Wrapper = styled.div`
	display: flex;
	width: 100%;

	.link-valid {
		margin-top: auto;
		margin-bottom: auto;
		margin-left: auto;
	}

	.euiCheckbox .euiCheckbox__input + .euiCheckbox__square {
		border-radius: 50%;
	}
`;

const StaticLinkWrapper = styled.div`
	display: flex;
	flex-direction: row;
	align-items: center;
	width: calc(100% - 30px);

	.euiLink {
		white-space: nowrap;
		overflow: hidden;
		text-overflow: ellipsis;
	}

	.status-icon {
		margin-left: 1rem;
	}

	.check-icon {
		line-height: 1;
		margin-left: 0.5rem;
	}
`;
