import React, { useState } from "react";
import styled from "styled-components";
import { EuiFlexGroup, EuiFlexItem, EuiText, EuiButtonIcon } from "@elastic/eui";

import { CompensationItem, CompensationEditItem } from "components/Common";
import { newCompensation } from "./constants";

import variables from "assets/css/variables.scss";

/**
 * Main Components
 */
const ScreeningCompLocation = (props) => {
	const { compensations } = props;

	const [isEditNewCompensation, setIsEditNewCompensation] = useState(false);

	const handleAddCompensation = () => {
		setIsEditNewCompensation(true);
	};

	const handleCloseNewCompensation = () => {
		setIsEditNewCompensation(false);
	};

	return (
		<Wrapper>
			<EuiFlexGroup
				gutterSize="none"
				direction="column"
				alignItems="flexStart"
				justifyContent="flexStart"
			>
				<EuiFlexItem className="w-100 mb-2">
					<EuiFlexGroup
						direction="row"
						alignItems="center"
						justifyContent="spaceBetween"
					>
						<EuiFlexItem grow={false}>
							<EuiText textAlign="left" className="location-title">
								<small>Compensation Plans</small>
							</EuiText>
						</EuiFlexItem>
						{!isEditNewCompensation ? (
							<EuiFlexItem
								grow={false}
								className="add-compensation"
								onClick={handleAddCompensation}
							>
								<EuiButtonIcon
									className="add-compensation"
									aria-label="add compensation"
									iconType="plusInCircleFilled"
									onClick={handleAddCompensation}
								/>
							</EuiFlexItem>
						) : null}
					</EuiFlexGroup>
				</EuiFlexItem>
				<EuiFlexItem className="w-100">
					<EuiFlexGroup gutterSize="xs" direction="column">
						{compensations?.map((compensation, id) => {
							return (
								<EuiFlexItem key={"compensation-detail-edit-" + id}>
									<CompensationItem
										compensation={compensation}
										editable={true}
										itemId={id}
									/>
								</EuiFlexItem>
							);
						})}
						{isEditNewCompensation ? (
							<EuiFlexItem>
								<CompensationEditItem
									compensation={newCompensation}
									onCloseItem={handleCloseNewCompensation}
									itemId={-1}
								/>
							</EuiFlexItem>
						) : null}
					</EuiFlexGroup>
				</EuiFlexItem>
			</EuiFlexGroup>
		</Wrapper>
	);
};

export default ScreeningCompLocation;

/**
 * Styled Components
 */
const Wrapper = styled.div`
	padding: 10px 10px 0 10px;

	.add-compensation {
		cursor: pointer;
		svg {
			color: ${variables.linkColor};
		}
	}
`;
