import React, { useState, useMemo } from "react";
import styled from "styled-components";
import {
	EuiButton,
	EuiContextMenuItem,
	EuiContextMenuPanel,
	EuiFlexGroup,
	EuiFlexItem,
	EuiIcon,
	EuiPopover,
} from "@elastic/eui";

/**
 * Component for Buttons with Popover without Arrow Button
 */
const ButtonsPopoverWithoutArrow = (props) => {
	const { buttons, minWidth = 0, color = "primary" } = props;

	const [isPopoverOpenBottomMenu, setBottomMenu] = useState(false);

	const closePopoverBottomMenu = () => {
		setBottomMenu(false);
	};
	const openMenu = () => {
		setBottomMenu(!isPopoverOpenBottomMenu);
	};

	const additionButtons = useMemo(() => {
		const items = buttons.slice(1);
		const renderButtons = [];
		items.forEach((item, id) => {
			const isHidden = item.hidden;
			if (isHidden) {
				return null;
			}
			renderButtons.push(
				<EuiContextMenuItem
					key={`buttons-list-item-${id}-${item.label}`}
					icon={item.icon}
					onClick={() => {
						item.callback();
						closePopoverBottomMenu();
					}}
					disabled={item.disabled}
					style={{
						padding: "var(--sp-4) 0",
					}}
				>
					{item.label}
				</EuiContextMenuItem>
			);
		});

		return renderButtons;
	}, [buttons]);

	if (!buttons?.length) {
		return null;
	}

	const buttonDropdown = (
		<EuiButton
			className="open-button"
			onClick={() => {
				buttons[0].callback();
				openMenu();
			}}
			fill
			color={color}
			disabled={buttons[0].disabled}
		>
			{buttons[0].label}&nbsp;&nbsp;
			<EuiIcon type="arrowDown" />
		</EuiButton>
	);

	return (
		<Wrapper>
			<EuiFlexGroup gutterSize="xs">
				<EuiFlexItem
					style={{
						marginLeft: "0px",
					}}
					grow={false}
				>
					<EuiPopover
						button={buttonDropdown}
						isOpen={isPopoverOpenBottomMenu}
						closePopover={closePopoverBottomMenu}
						panelPaddingSize="none"
						anchorPosition="downRight"
						hasArrow={false}
						panelStyle={{
							minWidth: minWidth,
							padding: "0 var(--sp-4)",
						}}
					>
						<EuiContextMenuPanel items={additionButtons} />
					</EuiPopover>
				</EuiFlexItem>
			</EuiFlexGroup>
		</Wrapper>
	);
};

export default ButtonsPopoverWithoutArrow;

const Wrapper = styled.div`
	display: flex;
	position: relative;
	width: 100%;

	.open-button {
		min-width: fit-content;
		height: fit-content;

		.euiButtonContent.euiButton__content {
			padding: 0 var(--sp-3_5);
		}
	}
`;
