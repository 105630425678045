import { _getThemeList } from "helpers/selectors";
import { useDispatch } from "react-redux";
import { getThemeList } from "store/theme";

/**
 * Hooks for Theme
 */
const useTheme = () => {
	const dispatch = useDispatch();

	const themeList = _getThemeList();

	return {
		themeList,

		getThemeList: (params) => {
			dispatch(getThemeList(params));
		},
	};
};

export default useTheme;
