import { useSelector } from "react-redux";
import size from "lodash/size";

const UseAccountDetail = () => {
	const accountDetail = useSelector((state) => state.account.accountDetail);

	const accountDetailLoaded = () => {
		return size(accountDetail) !== 0;
	};

	return {
		accountDetailLoaded,
	};
};

export default UseAccountDetail;
