import React, { useEffect, useMemo, useState } from "react";
import styled from "styled-components";

import { EuiFieldText } from "@elastic/eui";
import variables from "assets/css/variables.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowRight, faBriefcase } from "@fortawesome/pro-regular-svg-icons";
import { SVGIcon, DatePicker } from "components/Common";
import { briefcaseIcon } from "components/Common/Icons";
import moment from "moment";
/**
 * Main Components
 */
const PersonJobTitlePeriod = (props) => {
	const {
		title,
		startDate,
		endDate,
		onChangeTitle,
		onChangeStartDate,
		onChangeEndDate,
	} = props;

	const handleTitle = (e) => {
		onChangeTitle(e.target.value);
	};

	const handleStartDate = (date) => {
		onChangeStartDate(date.format("YYYY-MM-DD"));
	};

	const handleEndDate = (date) => {
		onChangeEndDate(date.format("YYYY-MM-DD"));
	};

	const startTime = startDate ? moment(startDate, "YYYY-MM-DD") : moment();
	const endTime = endDate ? moment(endDate, "YYYY-MM-DD") : moment();

	return (
		<Wrapper className="mb-3">
			<SVGIcon icon={briefcaseIcon} className="mt-2 mr-2" />
			<EuiFieldText
				placeholder="Text field (global)"
				value={title ? title : ""}
				onChange={(e) => handleTitle(e)}
			/>
			<div className="sub-header">from</div>
			<DatePicker
				selected={startTime}
				onChange={handleStartDate}
				placeholder="Start"
			/>
			<FontAwesomeIcon icon={faArrowRight} className="sub-header-icon" />
			<DatePicker selected={endTime} onChange={handleEndDate} placeholder="End" />
		</Wrapper>
	);
};

export default PersonJobTitlePeriod;

/**
 * Styled Components
 */
const Wrapper = styled.div`
	display: flex;
	width: 100%;

	.icon-button {
		margin: auto 0 0 0.5rem;
	}

	.sub-header-icon {
		font-size: 1rem;
		color: ${variables.lightColor};
		margin: auto 0.5rem;
	}

	.sub-header {
		font-size: 0.75rem;
		font-weight: 600;
		color: ${variables.lightColor};
		margin: auto 0.5rem;
	}

	.euiFormControlLayout {
		height: 25px;
	}
`;
