import { useDispatch } from "react-redux";

import { _getPlivoInfo } from "helpers/selectors";
import {
	actionCmsLinkedInMessage,
	actionSendLinkedinConnectionRequest,
	sendEmailO365Message,
	sendLinkedInMessage,
	sendSmsMessage,
	setNotification,
	createMeetingEvent,
	getPersonCmsList,
	setPersonCmsListParams,
	saveEmailO365Message,
	getPersonCmsDraftList,
	setPersonCmsDraftListParams,
} from "helpers/actions";
import { updateCmsCommunicaionDraft } from "store/cms";

/**
 * Hooks for CMS
 */
const useCms = () => {
	const dispatch = useDispatch();

	const plivoInfo = _getPlivoInfo();

	const getNotificationMessage = (items) => {
		let notificationMessage = "";
		if (items?.length) {
			items.forEach((item, idx) => {
				if (idx === 0) {
					notificationMessage = item;
				} else {
					notificationMessage += " and " + item;
				}
			});
		}

		return notificationMessage;
	};

	const callSendLinkeInMessage = (
		credentialId,
		personId,
		message,
		successCallback,
		failCallback
	) => {
		if (!credentialId || !personId || !message) {
			let notificationItems = [];
			if (!credentialId) {
				notificationItems.push("Credential");
			}
			if (!personId) {
				notificationItems.push("Person ID");
			}
			if (!message) {
				notificationItems.push("Message");
			}
			const notificationMessage = getNotificationMessage(notificationItems);
			dispatch(
				setNotification({
					type: "normal",
					message:
						"You should enter correct data to send message. Please check " +
						notificationMessage +
						".",
					status: "error",
				})
			);
		} else {
			dispatch(
				sendLinkedInMessage(
					credentialId,
					personId,
					message,
					successCallback,
					failCallback
				)
			);
		}
	};

	const callSendLinkedinConnectionRequest = (
		credentialId,
		profileURL,
		message,
		title,
		name,
		recipient,
		successCallback,
		failCallback
	) => {
		if (!credentialId || !profileURL || !message || !recipient) {
			let notificationItems = [];
			if (!credentialId) {
				notificationItems.push("Credential");
			}
			if (!profileURL) {
				notificationItems.push("Profile URL");
			}
			if (!message) {
				notificationItems.push("Message");
			}
			if (!recipient) {
				notificationItems.push("Recipient");
			}
			const notificationMessage = getNotificationMessage(notificationItems);
			dispatch(
				setNotification({
					type: "normal",
					message:
						"You should enter correct data to send connection request. Please check " +
						notificationMessage +
						".",
					status: "error",
				})
			);
		} else {
			const params = {
				credentialId,
				profileURL,
				message,
				title,
				name,
				recipient,
			};
			dispatch(
				actionSendLinkedinConnectionRequest(params, successCallback, failCallback)
			);
		}
	};

	const callSendCmsLinkedInMessage = (
		credentialId,
		profileURL,
		message,
		title,
		name,
		successCallback,
		failCallback
	) => {
		if (!credentialId || !profileURL || !message) {
			let notificationItems = [];
			if (!credentialId) {
				notificationItems.push("Credential");
			}
			if (!profileURL) {
				notificationItems.push("Profile URL");
			}
			if (!message) {
				notificationItems.push("Message");
			}
			const notificationMessage = getNotificationMessage(notificationItems);
			dispatch(
				setNotification({
					type: "normal",
					message:
						"You should enter correct data to send message. Please check " +
						notificationMessage +
						".",
					status: "error",
				})
			);
		} else {
			const params = {
				credentialId,
				profileURL,
				message,
				title,
				name,
			};
			dispatch(actionCmsLinkedInMessage(params, successCallback, failCallback));
		}
	};

	const callSendEmailMessage = (
		credentialId,
		message,
		successCallback,
		failCallback
	) => {
		if (
			!credentialId ||
			!message ||
			!message.subject ||
			!message.body ||
			message.body.content === ""
		) {
			let notificationItems = [];
			if (!credentialId) {
				notificationItems.push("Credential");
			}
			if (!message.subject) {
				notificationItems.push("Subject");
			}
			if (!message.body || message.body.content === "") {
				notificationItems.push("Message content");
			}
			const notificationMessage = getNotificationMessage(notificationItems);
			dispatch(
				setNotification({
					type: "normal",
					message:
						"You should enter correct data to send message. Please check " +
						notificationMessage +
						".",
					status: "error",
				})
			);
		} else {
			const params = {
				credentialId,
				message,
			};
			dispatch(sendEmailO365Message(params, successCallback, failCallback));
		}
	};

	const callSaveEmailMessage = (
		credentialId,
		message,
		params,
		successCallback,
		failCallback
	) => {
		if (!message || !message.subject || !message.body) {
			let notificationItems = [];
			if (!credentialId) {
				notificationItems.push("Credential");
			}
			if (!message.subject) {
				notificationItems.push("Subject");
			}
			if (!message.body) {
				notificationItems.push("Body");
			}
			const notificationMessage = getNotificationMessage(notificationItems);
			dispatch(
				setNotification({
					type: "normal",
					message:
						"You should enter correct data to send message. Please check " +
						notificationMessage +
						".",
					status: "error",
				})
			);
		} else {
			dispatch(saveEmailO365Message(params, successCallback, failCallback));
		}
	};

	const callSendSmsMessage = (
		sender,
		receiver,
		message,
		successCallback,
		failCallback
	) => {
		if (!sender || !message || !receiver) {
			let notificationItems = [];
			if (!sender) {
				notificationItems.push("Sender");
			}
			if (!receiver) {
				notificationItems.push("Receiver");
			}
			if (!message) {
				notificationItems.push("Message");
			}
			const notificationMessage = getNotificationMessage(notificationItems);
			dispatch(
				setNotification({
					type: "normal",
					message:
						"You should enter correct data to send message. Please check " +
						notificationMessage +
						".",
					status: "error",
				})
			);
		} else {
			const params = {
				sender,
				receiver,
				message,
			};
			dispatch(sendSmsMessage(params, successCallback, failCallback));
		}
	};

	const callCreateMeetingEvent = (
		credentialId,
		event,
		successCallback,
		failCallback
	) => {
		const params = {
			credentialId,
			event,
		};
		dispatch(createMeetingEvent(params, successCallback, failCallback));
	};

	// Communication List
	const getPersonCommunicationList = (params, successCallBack, failCallBack) => {
		dispatch(getPersonCmsList(params, successCallBack, failCallBack));
	};

	const setPersonCommunicationListParams = (params) => {
		dispatch(setPersonCmsListParams(params));
	};

	// Communication Draft Table
	const getPersonCommunicationDraftList = (params, successCallBack, failCallBack) => {
		dispatch(getPersonCmsDraftList(params, successCallBack, failCallBack));
	};

	const setPersonCommunicationDraftListParams = (params) => {
		dispatch(setPersonCmsDraftListParams(params));
	};

	const callUpdateCmsCommunicaionDraft = (params, successCallback, failCallback) => {
		dispatch(updateCmsCommunicaionDraft(params, successCallback, failCallback));
	};

	return {
		plivoInfo,
		callSendLinkeInMessage,
		callSendLinkedinConnectionRequest,
		callSendCmsLinkedInMessage,
		callSendEmailMessage,
		callSendSmsMessage,
		callCreateMeetingEvent,
		getPersonCommunicationList,
		setPersonCommunicationListParams,
		callSaveEmailMessage,
		callUpdateCmsCommunicaionDraft,
		getPersonCommunicationDraftList,
		setPersonCommunicationDraftListParams,
	};
};

export default useCms;
