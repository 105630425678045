import React from "react";
import styled from "styled-components";
import { EuiFlexGroup, EuiFlexItem } from "@elastic/eui";

import { removeIcon } from "components/Common/Icons/Icons";

/**
 * Main Components
 */
const CustomBadge = (props) => {
    const {
        icon,
        label,
        hasCloseButton = true,
        onRemove,
        onClickLabel,
        ...restProps
    } = props;

    const handleRemove = (e) => {
        if (onRemove) {
            onRemove(e);
        }
    };

    const handleClickLabel = () => {
        if (onClickLabel) {
            onClickLabel();
        }
    };

    return (
        <Wrapper {...restProps}>
            <EuiFlexGroup gutterSize="none" direction="row" alignItems="center">
                {icon ? (
                    <EuiFlexItem grow={false}>
                        <div className="icon">{icon || ""}</div>
                    </EuiFlexItem>
                ) : null}
                {label ? (
                    <EuiFlexItem grow={false}>
                        <div
                            className="label"
                            onClick={handleClickLabel}
                            style={{ cursor: onClickLabel ? "pointer" : "default" }}
                        >
                            <span>{label || ""}</span>
                        </div>
                    </EuiFlexItem>
                ) : null}
                {hasCloseButton || onRemove ? (
                    <EuiFlexItem grow={false}>
                        <div className="close" onClick={handleRemove}>
                            {removeIcon()}
                        </div>
                    </EuiFlexItem>
                ) : null}
            </EuiFlexGroup>
        </Wrapper>
    );
};

export default CustomBadge;

/**
 * Styled Components
 */
const Wrapper = styled.div`
    padding: var(--sp-2) var(--sp-6);
    border-radius: 2rem;
    border: solid 1px var(--border-color);
    display: flex;
    min-height: var(--sp-10);

    .icon {
        padding-right: 0.5rem;
        display: flex;
        align-items: center;
        justify-content: center;
    }
    .label {
        margin-top: 0px !important;
        font-weight: 600;
        font-size: 9px;
        line-height: 18px;
    }
    .close {
        cursor: pointer;
        padding-left: 0.5rem;
    }
`;
