import React, { useState, useMemo } from "react";
import { EuiFlexItem } from "@elastic/eui";

import { global_url_details } from "core/model";

import CommonItemView from "../CommonItemView";
import EditLinkItem from "./EditLinkItem";
import EditableLink from "./EditableLink";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faShareAlt } from "@fortawesome/pro-regular-svg-icons";

const MAX_ICON_COUNTS = 10;
/**
 * Component for displaying Link Items
 */
const LinkItemsView = (props) => {
	const { links, primaryLinkId, onSaveLink, onDeleteLink, onSavePrimary } = props;

	// Set default Link Data
	// link-type : general
	const newLink = {
		global_link_type_c: 1,
		[global_url_details._name]: {
			[global_url_details.url_value]: undefined,
			[global_url_details.url_confirmed_c]: undefined,
		},
	};

	const [isEditAddLink, setIsEditAddLink] = useState(false);

	const saveLinkPrimary = (primaryLinkId) => {
		onSavePrimary(primaryLinkId);
	};

	const handleDelete = (linkId) => {
		onDeleteLink(linkId);
	};

	const handleSaveNewLink = (data, primary) => {
		onSaveLink(data, (res, successCallBack) => {
			const newLink = res?.find((item) => item.id);
			if (primary) {
				saveLinkPrimary(newLink?.id);
			} else {
				if (successCallBack) {
					successCallBack();
				}
			}
		});

		setIsEditAddLink(false);
	};

	const handleCancelNewLink = () => {
		setIsEditAddLink(false);
	};

	const getLinkPrimary = (itemIndex) => {
		let res = false;
		if (links?.[itemIndex]?.id) {
			if (links?.[itemIndex]?.id === primaryLinkId) {
				res = true;
			}
		}
		return res;
	};

	const handleSaveLink = (data, primary) => {
		onSaveLink(data, (res, successCallBack) => {
			if (primary || (!primary && data.id === primaryLinkId)) {
				saveLinkPrimary(primary ? data?.id : null);
			} else {
				if (successCallBack) {
					successCallBack();
				}
			}
		});
	};

	const { linkItems, linkMainItem } = useMemo(() => {
		const linkItems = links?.map((item, idx) => {
			return [
				<EditableLink
					key={`link-existing-item-${idx}`}
					link={item}
					isPrimary={getLinkPrimary(idx)}
					onSave={handleSaveLink}
					onDelete={handleDelete}
				/>,
				0,
			];
		});

		const linkMainItem = links?.length ? (
			<EuiFlexItem className="d-flex">
				{links.map(
					(item, idx) =>
						idx < MAX_ICON_COUNTS && (
							<div key={`link-multiple-existing-item-${idx}`}>
								<EditableLink link={item} staticShow={true} />
							</div>
						)
				)}
				{links?.length > MAX_ICON_COUNTS && <div className="mt-3">...</div>}
			</EuiFlexItem>
		) : null;

		return { linkItems, linkMainItem };
	}, [links, primaryLinkId]);

	return (
		<CommonItemView
			title="Communication URL"
			icon={<FontAwesomeIcon icon={faShareAlt} />}
			items={linkItems}
			mainItem={linkMainItem}
			showCount={true}
			newItem={
				<EditLinkItem
					link={newLink}
					linkPrimary={false}
					onSave={handleSaveNewLink}
					onCancel={handleCancelNewLink}
				/>
			}
			isEdit={isEditAddLink}
			setIsEdit={setIsEditAddLink}
			className="link-view"
		/>
	);
};

export default LinkItemsView;
