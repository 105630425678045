import React, { useState, useEffect, useRef, Fragment } from "react";
import {
	EuiEmptyPrompt,
	EuiTable,
	EuiTableBody,
	EuiTableHeader,
	EuiTableHeaderCell,
	EuiTableHeaderCellCheckbox,
	EuiTableRow,
	EuiTableRowCell,
	EuiTableRowCellCheckbox,
	EuiCheckbox,
	EuiText,
	EuiButtonEmpty,
	EuiContextMenuItem,
	EuiContextMenuPanel,
	EuiFlexGroup,
	EuiFlexItem,
	EuiPagination,
	EuiPopover,
	EuiProgress,
} from "@elastic/eui";

import {
	LEFT_ALIGNMENT,
	RIGHT_ALIGNMENT,
	CENTER_ALIGNMENT,
} from "@elastic/eui/lib/services";
import { faSyncAlt } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { TableWrapper } from "./styled";

import Sort from "./Sort/Sort";

/**
 * Component for Table
 */
const Table = ({
	columns,
	items,
	allIds,
	pagination,
	loading,
	bulkActionPopover,
	filter,
	searchValue,
	sortList,
	fetchTableData,
	optionButtons,
	usePerPage,
}) => {
	const loaded = useRef(false);
	const { totalCount, currentPage, perPage } = pagination;

	const [itemIdToSelectedMap, setItemIdToSelectedMap] = useState({});
	const [filteredSelectedIds, setFilteredSelectedIds] = useState({});
	const [isPopoverOpen, setIsPopoverOpen] = useState(false);
	const [rowSize, setRowSize] = useState(30);
	const [activePage, setActivePage] = useState((currentPage ?? 1) - 1); // Returned state result for currentPage is 1 so we deduct it by 1 to start at 0 which is 1 in arrays
	const [checkedRowsCount, setCheckedRowsCount] = useState(0);
	const [isSelectedAll, setIsSelectedAll] = useState(false);
	const [isPopoverActionListOpen, setIsPopoverActionListOpen] = useState(false);
	const [filterData, setFilterData] = useState({});
	const [sortData, setSortData] = useState([]);

	const pageCount = Math.ceil(totalCount / rowSize);

	// Check if selected item to map is true, then filter id and set new states for checkedRowsCount and filteredSelectedIds
	useEffect(() => {
		let filteredIds = Object.keys(itemIdToSelectedMap).filter((key) =>
			itemIdToSelectedMap[key] ? key : ""
		);
		setCheckedRowsCount(filteredIds.length);
		setFilteredSelectedIds(filteredIds);
	}, [itemIdToSelectedMap]);

	// On Page Load
	useEffect(() => {
		if (!totalCount) {
			fetchTableData(activePage + 1, rowSize, searchValue, filterData, sortData);
		}
	}, [JSON.stringify(pagination)]);

	// On Items list data change, clear selected items list
	useEffect(() => {
		setItemIdToSelectedMap({});
	}, [items]);

	// On Search Value Change
	useEffect(() => {
		if (loaded.current) {
			const timeout = setTimeout(() => {
				setActivePage(0);
				fetchTableData(1, rowSize, searchValue, filterData, sortData);
			}, 500);

			return () => clearTimeout(timeout);
		} else {
			loaded.current = true;
		}
	}, [searchValue]);

	// On Display Rows Change
	const handleRowSizeChange = (rowSize) => {
		setActivePage(0);
		fetchTableData(1, rowSize, searchValue, filterData, sortData);
	};

	// On Pagination Active Page Change
	const handlePageChange = (pageNumber) => {
		setActivePage(pageNumber);
		fetchTableData(pageNumber + 1, rowSize, searchValue, filterData, sortData);
	};

	// On Sort Data Change
	const handleSortDataChange = (newSortData) => {
		setSortData(newSortData);
		setActivePage(0);
		fetchTableData(1, rowSize, searchValue, filterData, newSortData);
	};

	// On Filter Data Change
	const handleFilterDataChange = (newFilterData) => {
		setFilterData(newFilterData);
		setActivePage(0);
		fetchTableData(1, rowSize, searchValue, newFilterData, sortData);
	};

	const onButtonClick = () => setIsPopoverOpen((isPopoverOpen) => !isPopoverOpen);
	const closePopover = () => setIsPopoverOpen(false);

	const popoverButton = () => {
		let _perPage = rowSize;
		if (usePerPage) {
			_perPage = perPage ?? rowSize;
		}
		return (
			<EuiButtonEmpty
				size="s"
				color="text"
				onClick={onButtonClick}
				className="custom-eui-table-popover-btn"
			>
				Display{" "}
				{_perPage && (
					<span className="custom-eui-table-popover-btn-link">{_perPage}</span>
				)}{" "}
				rows
			</EuiButtonEmpty>
		);
	};

	const renderContextMenuItems = () => {
		const menuItemOptions = [30, 50, 100, 200, 500, 1000];
		const menuItems = [];

		menuItemOptions.forEach((itemOption, itemOptionIndex) => {
			menuItems.push(
				<EuiContextMenuItem
					key={`${itemOption} rows`}
					onClick={() => {
						closePopover();
						setRowSize(itemOption);
						handleRowSizeChange(itemOption);
					}}
				>
					{itemOption}
				</EuiContextMenuItem>
			);
		});

		return menuItems;
	};
	const renderHeaderCells = () => {
		const tableHeaders = [];

		columns.forEach((column, columnIndex) => {
			if (column.isCheckbox) {
				tableHeaders.push(
					<EuiTableHeaderCellCheckbox
						key={`column-cell-${columnIndex}-${column.id}`}
						width={column.width}
						align={handleAlignment(column.alignment)}
						className={`custom-eui-table-header-cell ${
							column.className ?? ""
						}`}
						style={column.headerStyles ?? ""}
					>
						{renderSelectAllFromCurrentPage()}
					</EuiTableHeaderCellCheckbox>
				);
			} else {
				tableHeaders.push(
					<EuiTableHeaderCell
						key={`column-cell-${columnIndex}-${column.id}`}
						width={column.width}
						align={handleAlignment(column.alignment)}
						className={`custom-eui-table-header-cell ${
							column.className ?? ""
						}`}
						style={column.headerStyles ?? ""}
					>
						<span
							className="euiTableCellContent__text"
							title={column.label}
							style={column.labelStyles ?? null}
						>
							{column.label}
						</span>
					</EuiTableHeaderCell>
				);
			}
		});

		return tableHeaders.length ? tableHeaders : null;
	};

	const handleAlignment = (alignment) => {
		let align = LEFT_ALIGNMENT;

		switch (alignment) {
			case "left":
				align = LEFT_ALIGNMENT;
				break;
			case "right":
				align = RIGHT_ALIGNMENT;
				break;
			case "center":
				align = CENTER_ALIGNMENT;
				break;
			default:
				break;
		}

		return align;
	};

	const renderSelectAllFromCurrentPage = (mobile) => {
		return (
			<EuiCheckbox
				id="selectAllFromCurrentPageCheckbox"
				label={mobile ? "Select all from current page" : null}
				checked={isSelectedAll ? true : areAllItemsSelectedFromCurrentPage()}
				onChange={() => toggleAllFromCurrentPage()}
				disabled={isSelectedAll ? true : false}
				type={mobile ? null : "inList"}
			/>
		);
	};

	const renderLoading = () => {
		return <EuiProgress size="xs" color="primary" />;
	};

	const renderRows = () => {
		const rows = [];

		items.forEach((item, itemIndex) => {
			rows.push(renderRow(item, itemIndex));
		});

		return rows;
	};

	const renderRow = (item, id) => {
		return (
			<EuiTableRow
				key={`row-${item.ID}-${id}`}
				isSelected={isItemSelected(item.ID)}
				isSelectable={true}
				hasActions={true}
				className={`custom-eui-table-row}`}
			>
				{renderRowCells(item)}
			</EuiTableRow>
		);
	};

	const renderRowCells = (item) => {
		return columns.map((column, id) => {
			let cellContent = null;

			if (column.isCheckbox) {
				return (
					<EuiTableRowCellCheckbox key={`row-cell-${id}-${item.ID}`}>
						<EuiCheckbox
							id={`${item.ID}-checkbox`}
							checked={isSelectedAll ? true : isItemSelected(item.ID)}
							onChange={() => toggleItem(item.ID)}
							disabled={isSelectedAll ? true : false}
							type="inList"
						/>
					</EuiTableRowCellCheckbox>
				);
			}

			if (column.render) {
				cellContent = column.render(item);
			}

			return (
				<EuiTableRowCell
					key={`row-cell-${column.id}-${item.ID}`}
					align={column.alignment}
					truncateText={column && column.truncateText}
					textOnly={column.textOnly ?? true}
					style={column.cellStyles ?? ""}
				>
					{cellContent}
				</EuiTableRowCell>
			);
		});
	};

	const isItemSelected = (itemId) => {
		return itemIdToSelectedMap[itemId];
	};

	const toggleItem = (itemId) => {
		setItemIdToSelectedMap({
			...itemIdToSelectedMap,
			[itemId]: !itemIdToSelectedMap[itemId],
		});
	};

	const areAllItemsSelectedFromCurrentPage = () => {
		if (items.length === 0) {
			return false;
		}

		const indexOfUnselectedItem = items.findIndex((item) => !isItemSelected(item.ID));
		return indexOfUnselectedItem === -1;
	};

	const toggleAllFromCurrentPage = () => {
		const allSelectedFromCurrentPage = areAllItemsSelectedFromCurrentPage();
		const newItemIdToSelectedMap = {};
		items.forEach(
			(item) => (newItemIdToSelectedMap[item.ID] = !allSelectedFromCurrentPage)
		);

		setItemIdToSelectedMap(newItemIdToSelectedMap);
	};

	const toggleAll = () => {
		if (isSelectedAll) {
			setItemIdToSelectedMap({});
			setIsSelectedAll(false);
		} else {
			setIsSelectedAll(true);
			setFilteredSelectedIds(allIds);
		}
	};

	const handleReloadList = () => {
		fetchTableData(activePage + 1, rowSize, searchValue, filterData, sortData);
	};

	const { renderPopover } = bulkActionPopover
		? bulkActionPopover(
				filteredSelectedIds,
				setIsPopoverActionListOpen,
				isSelectedAll,
				handleReloadList,
				items
		  )
		: "";

	return (
		<TableWrapper>
			<EuiFlexGroup justifyContent="flexEnd" alignItems="center">
				{optionButtons ? <EuiFlexItem>{optionButtons}</EuiFlexItem> : null}
				<EuiFlexItem grow={false}>
					<EuiPagination
						pageCount={pageCount}
						activePage={activePage}
						onPageClick={handlePageChange}
					/>
				</EuiFlexItem>
				<EuiFlexItem grow={false}>
					<EuiButtonEmpty color="primary" onClick={handleReloadList}>
						<FontAwesomeIcon icon={faSyncAlt} />
					</EuiButtonEmpty>
				</EuiFlexItem>
			</EuiFlexGroup>

			<EuiFlexGroup
				alignItems="center"
				className="custom-eui-table-flex-group-header"
			>
				<EuiFlexItem grow={false}>
					<EuiButtonEmpty
						// className="custom-eui-table-select-all-btn"
						color="text"
						size="s"
						onClick={() => {
							toggleAll();
						}}
					>
						{isSelectedAll ? "Deselect All" : "Select All"}
					</EuiButtonEmpty>
				</EuiFlexItem>
				{(isSelectedAll || checkedRowsCount > 0) && (
					<EuiFlexItem grow={false}>
						<EuiPopover
							isOpen={isPopoverActionListOpen}
							panelPaddingSize="s"
							anchorPosition="downCenter"
							button={
								<EuiButtonEmpty
									size="xs"
									iconSide="right"
									iconType="arrowDown"
									color="text"
									className="euiDataGrid__controlBtn custom-eui-table-bulk-action-popover-btn"
									onClick={() =>
										setIsPopoverActionListOpen(!isPopoverOpen)
									}
								>
									Bulk Action
								</EuiButtonEmpty>
							}
							closePopover={() => setIsPopoverActionListOpen(false)}
							ownFocus={true}
						>
							{bulkActionPopover && renderPopover()}
						</EuiPopover>
					</EuiFlexItem>
				)}
				<EuiFlexItem grow={false}>
					<EuiText size="s">
						{isSelectedAll ? allIds?.length || 0 : checkedRowsCount} of{" "}
						{totalCount} Selected
					</EuiText>
				</EuiFlexItem>

				<EuiFlexItem></EuiFlexItem>

				{filter ? (
					<Fragment>
						<EuiFlexItem grow={false}>
							{filter(handleFilterDataChange, filterData)}
						</EuiFlexItem>
						<EuiFlexItem></EuiFlexItem>
					</Fragment>
				) : null}

				{sortList ? (
					<Fragment>
						<EuiFlexItem grow={false}>
							<Sort
								sortList={sortList}
								handleSortData={handleSortDataChange}
							/>
						</EuiFlexItem>
					</Fragment>
				) : null}

				<EuiFlexItem grow={false}>
					<EuiPopover
						button={popoverButton()}
						isOpen={isPopoverOpen}
						closePopover={closePopover}
						panelPaddingSize="none"
					>
						<EuiContextMenuPanel items={renderContextMenuItems()} />
					</EuiPopover>
				</EuiFlexItem>
			</EuiFlexGroup>

			{loading ? renderLoading() : null}
			<EuiTable>
				<EuiTableHeader className="custom-eui-table-header">
					{renderHeaderCells()}
				</EuiTableHeader>
				<EuiTableBody>{items?.length === 0 ? null : renderRows()}</EuiTableBody>
			</EuiTable>
			{items?.length === 0 ? <EuiEmptyPrompt body="No Data" /> : null}
			<EuiFlexGroup
				alignItems="center"
				justifyContent="spaceBetween"
				className="custom-eui-table-flex-group-footer"
			>
				<EuiFlexItem grow={false}></EuiFlexItem>
				<EuiFlexItem grow={false}>
					<EuiPagination
						pageCount={pageCount}
						activePage={activePage}
						onPageClick={handlePageChange}
					/>
				</EuiFlexItem>
			</EuiFlexGroup>
		</TableWrapper>
	);
};

export default Table;
