/**
 * Constants for Pagination
 */
export const PAGINATION_PER_PAGE_LIST = [30, 50, 100, 200];

/**
 * Stepbar State
 */
export const STEP_STATUES = {
	selected: { key: "selected", className: "selected" },
	incomplete: { key: "incomplete", className: "incomplete" },
	complete: { key: "complete", className: "complete" },
};

// Default Password
export const DEFAULT_PASSWORD = "root";

// Security
export const SECURITY_MAIN_ID = 1;

// Others
export const SMALL_CNT_PER_PAGE = 3;
export const CNT_PER_PAGE = 5;
export const MID_CNT_PER_PAGE = 10;
export const STANDARD_CNT_PER_PAGE = 30;
export const ROW_COUNT = 100;
export const SEARCH_MAX_RECORD = 50;

// Editor
/**
 * account info of TinyMCE.
 * email: jack.wang@empower.associates
 * password: TinyMCE1234
 * url: https://www.tiny.cloud/
 */
export const TINYMCE_DEFAULT_HEIGHT = 225;
export const TINYMCE_INPUT_HEIGHT = 25;
export const TINYMCE_DEFAULT_WIDTH = 400;
export const TINYMCE_APIKEY = "fi0n010uorvfclqqe1vngvstiguan42dsg9hz4idzd41j8pk";

/**
 * Notification
 */
export const NOTIFICATION_SHOW_TIME = 60000; // [ms]
