import React, { useMemo, useState } from "react";
import styled from "styled-components";
import { EuiPopover, EuiFlexGroup, EuiFlexItem } from "@elastic/eui";
import { AMS_STATUS_LIST } from "./constants";

/**
 * Component for AMS Status Item with editable feature
 */
const AmsStatusItem = (props) => {
	const { onChangeStatus, status = 1, showLabel = false } = props;

	const [isOpenPopover, setIsOpenPopover] = useState(false);

	const { amsStatus } = useMemo(() => {
		const amsStatus =
			Object.values(AMS_STATUS_LIST).find((item) => item.value === status) ||
			AMS_STATUS_LIST.pending;

		return {
			amsStatus,
		};
	}, [status]);

	const handleClosePopover = () => {
		setIsOpenPopover(false);
	};

	const handleChangeStatus = (newState) => {
		if (onChangeStatus) {
			onChangeStatus(newState);
		}

		setIsOpenPopover(false);
	};

	return (
		<Wrapper
			isOpen={isOpenPopover}
			button={
				<StatusLabel
					onClick={() => setIsOpenPopover(true)}
					className="cursor-pointer"
					style={{ color: amsStatus.color }}
				>
					{amsStatus.icon}
					{showLabel && (
						<span className="semibold">&nbsp;&nbsp;{amsStatus.label}</span>
					)}
				</StatusLabel>
			}
			closePopover={handleClosePopover}
			panelStyle={{
				width: 110,
				padding: "0.5rem 0.75rem",
			}}
		>
			<EuiFlexGroup direction="column" gutterSize="s">
				{Object.values(AMS_STATUS_LIST).map((item) => {
					return (
						<EuiFlexItem key={`ams-status-item-${item.value}`}>
							<StatusListItem
								gutterSize="s"
								alignItems="center"
								className="cursor-pointer"
								onClick={() => handleChangeStatus(item.value)}
							>
								<EuiFlexItem grow={false}>
									<div style={{ color: item.color }}>{item.icon}</div>
								</EuiFlexItem>
								<EuiFlexItem>{item.label}</EuiFlexItem>
							</StatusListItem>
						</EuiFlexItem>
					);
				})}
			</EuiFlexGroup>
		</Wrapper>
	);
};

export default AmsStatusItem;

const Wrapper = styled(EuiPopover)`
	display: flex !important;
`;

const StatusListItem = styled(EuiFlexGroup)`
	svg {
		width: var(--sp-8);
		height: auto;
	}
`;

const StatusLabel = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;
`;
