/**
 * Here the name convention is the same with the name convention of the table in database.
 * So here the same name will be used.
 */
const person_main = {
    _name: "person_main",
    id: "id",
    name_first: "name_first",
    name_middle: "name_middle",
    name_last: "name_last",
    name_nick: "name_nick",
    date_created: "date_created",
    date_birth: "date_birth",
    // created_by_main_account_ref_id: 'created_by_main_account_ref_id',
};

const person_attachment = {
    _name: "person_attachment",
    id: "id",
    person_main_ref_id: "person_main_ref_id",
    file_main_ref_id: "file_main_ref_id",
    attachment_details: "attachment_details",
};

const person_education = {
    _name: "person_education",
    id: "id",
    person_main_ref_id: "person_main_ref_id",
    name_school: "name_school",
    name_degree: "name_degree",
    name_specialization: "name_specialization",
    date_start: "date_start",
    date_end: "date_end",
};

const person_email = {
    _name: "person_email",
    id: "id",
    person_main_ref_id: "person_main_ref_id",
    email_type_c: "email_type_c",
    global_email_details_ref_id: "global_email_details_ref_id",
};

const person_phone = {
    _name: "person_phone",
    id: "id",
    person_main_ref_id: "person_main_ref_id",
    phone_type_c: "phone_type_c",
    global_phone_details_ref_id: "global_phone_details_ref_id",
};

const person_history_work = {
    _name: "person_history_work",
    id: "id",
    person_main_ref_id: "person_main_ref_id",
    company_name: "company_name",
    company_main_ref_id: "company_main_ref_id",
    work_title: "work_title",
    date_start: "date_start",
    date_end: "date_end",
    work_summary: "work_summary",
    person_email_ref_id: "person_email_ref_id",
    person_phone_ref_id: "person_phone_ref_id",
    person_address_ref_id: "person_address_ref_id",
    compensation_main_ref_id: "compensation_main_ref_id",
};

const person_language = {
    _name: "person_language",
    id: "id",
    person_main_ref_id: "person_main_ref_id",
    language_name: "language_name",
    language_proficiency_c: "language_proficiency_c",
};

const person_location_work = {
    _name: "person_location_work",
    id: "id",
    person_main_ref_id: "person_main_ref_id",
    location_type_gc: "location_type_gc",
    location_ref_id: "location_ref_id",
};

const person_sit = {
    _name: "person_sit",
    id: "id",
    person_main_ref_id: "person_main_ref_id",
    sit_main_ref_id: "sit_main_ref_id",
    sit_level_gc: "sit_level_gc",
    sit_focus_gc: "sit_focus_gc",
};

const person_primary_setting = {
    _name: "person_primary_setting",
    id: "id",
    person_main_ref_id: "person_main_ref_id",
    primary_level_gc: "primary_level_gc",
    ref_object_base_sc: "ref_object_base_sc",
    ref_object_table_sc: "ref_object_table_sc",
    ref_object_record_id: "ref_object_record_id",
};

const person_summary = {
    _name: "person_summary",
    id: "id",
    person_main_ref_id: "person_main_ref_id",
    summary_type_c: "summary_type_c",
    summary_details: "summary_details",
};

const person_support_data = {
    _name: "person_support_data",
    id: "id",
    person_main_ref_id: "person_main_ref_id",
    data_name: "data_name",
    data_source_c: "data_source_c",
    data_order: "data_order",
    data_value: "data_value",
};

const person_visa = {
    _name: "person_visa",
    id: "id",
    person_main_ref_id: "person_main_ref_id",
    visa_type_sc: "visa_type_sc",
    visa_status_sc: "visa_status_sc",
    date_expiration: "date_expiration",
};

const person_log_activity = {
    _name: "person_log_activity",
    id: "id",
    date_created: "date_created",
    account_main_ref_id: "account_main_ref_id",
    person_table_ref_id: "person_table_ref_id",
    log_object_c: "log_object_c",
    log_type_c: "log_type_c",
    log_action_c: "log_action_c",
    log_details: "log_details",
};

const person_address = {
    _name: "person_address",
    id: "id",
    person_main_ref_id: "person_main_ref_id",
    address_type_c: "address_type_c",
    global_address_details_ref_id: "global_address_details_ref_id",
};

const person_external_link = {
    _name: "person_external_link",
    id: "id",
    person_main_ref_id: "person_main_ref_id",
    headline_value: "headline_value",
    global_link_type_c: "global_link_type_c",
    global_url_details_ref_id: "global_url_details_ref_id",
};

const person_compensation = {
    _name: "person_compensation",
    id: "id",
    person_main_ref_id: "person_main_ref_id",
    compensation_main_ref_id: "compensation_main_ref_id",
};

const person_log_detail = {
    _name: "person_log_detail",
    id: "id",
    detail_text: "detail_text",
};
export {
    person_main,
    person_attachment,
    person_education,
    person_email,
    person_phone,
    person_history_work,
    person_language,
    person_location_work,
    person_sit,
    person_primary_setting,
    person_summary,
    person_support_data,
    person_visa,
    person_log_activity,
    person_address,
    person_external_link,
    person_compensation,
    person_log_detail,
};
