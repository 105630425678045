export const JOB_EDIT_TABS = {
	application: { key: "application", label: "Application" },
	placement: { key: "placement", label: "Placement Details" },
	messaging: { key: "messaging", label: "Messaging" },
};

/**
 * Job Application
 */
export const JOB_APPLICATION_RATINGS = [
	{
		key: "internal",
		label: "Internal",
		className: "internal",
		color: "var(--primary-color)",
	},
	{
		key: "candidate",
		label: "Candidate",
		className: "candidate",
		color: "#017D73",
	},
	{
		key: "client",
		label: "Client",
		className: "client",
		color: "#FF8B26",
	},
];

export const JOB_APPLICATION_PROFILE = {
	main: { key: "main", label: "Main", className: "profile" },
	profile: { key: "profile", label: "Profile", className: "profile" },
};

export const JOB_APPLICATION_FLOW_STEPS = [
	{ id: 0, key: "pipeline", label: "Pipeline" },
	{ id: 1, key: "submission", label: "Submission" },
	{ id: 2, key: "interview", label: "Interview" },
	{ id: 3, key: "offer", label: "Offer" },
	{ id: 4, key: "placement", label: "Placement" },
];

export const JOB_PIPELINE_SUBFLOW_STEPS = [
	{ id: 0, key: "new", label: "New" },
	{ id: 1, key: "screening", label: "Screening" },
	{ id: 2, key: "internal-submission", label: "Internal Submission" },
];

export const JOB_SUBMISSION_SUBFLOW_STEPS = [
	{ id: 0, key: "confirm-detials", label: "Confirm Details" },
	{ id: 1, key: "compose-email", label: "Compose Email" },
	{ id: 2, key: "feedback", label: "Feedback" },
];

export const JOB_INTERVIEW_SUBFLOW_STEPS = [
	{ id: 0, key: "coordinating", label: "Coordinating" },
	{ id: 1, key: "compose-invites", label: "Compose Invites" },
	{ id: 2, key: "feedback", label: "Feedback" },
];

export const JOB_OFFER_SUBFLOW_STEPS = [
	{ id: 0, key: "draft", label: "Draft Offer" },
	{ id: 1, key: "send", label: "Send" },
	{ id: 2, key: "decision", label: "Decision" },
];

export const JOB_APPLICATION_INTERVIEW_RESULT_STATES = {
	passed: { key: "passed", label: "Passed", color: "var(--success-color)" },
	fail: { key: "fail", label: "Fail", color: "var(--danger-color)" },
	edit: { key: "edit", label: "" },
};

export const ORDER_INTERVIEW_LABELS = [
	{ id: 0, key: "firstInterview", label: "First Interview" },
	{ id: 1, key: "secondInterview", label: "Second Interview" },
	{ id: 2, key: "thirdInterview", label: "Third Interview" },
];

/**
 * Job Placement
 */
export const JOB_PLACEMENT_FLOW_STEPS = [
	{ id: 0, key: "preparing", label: "Preparing" },
	{ id: 1, key: "submitted", label: "Submitted" },
	{ id: 2, key: "pending", label: "Pending" },
	{ id: 3, key: "active", label: "Active" },
	{ id: 4, key: "complete", label: "Complete" },
];

export const JOB_PLACEMENT_CREATE_SUBFLOW_STEPS = [
	{ id: 0, key: "overview", label: "Overview" },
	{ id: 1, key: "rates", label: "Rates" },
	{ id: 2, key: "confirm", label: "Confirm" },
];

export const JOB_PLACEMENT_TYPES = [
	{ id: 0, value: "option_one", text: "Contact" },
	{ id: 1, value: "option_two", text: "Contact two" },
	{ id: 2, value: "option_three", text: "Contact three" },
];

/**
 * Job Pipeline
 */
export const JOB_APPLICATION_PIPELINE_STEPS = [
	{
		id: 0,
		key: "new",
		tabId: 0,
		flowId: 0,
		subFlowId: 0,
		subSteps: JOB_PIPELINE_SUBFLOW_STEPS,
		label: "New",
		button: "Screen",
	},
	{
		id: 1,
		key: "screening",
		tabId: 0,
		flowId: 0,
		subFlowId: 1,
		subSteps: JOB_PIPELINE_SUBFLOW_STEPS,
		label: "Screeing",
		button: "Create Internal Submission",
	},
	{
		id: 2,
		key: "submission",
		tabId: 0,
		flowId: 0,
		subFlowId: 2,
		subSteps: JOB_PIPELINE_SUBFLOW_STEPS,
		label: "Internal Submission",
		button: "Log Internal Submission",
	},
	{
		id: 3,
		key: "clientSubmission",
		tabId: 0,
		flowId: 0,
		subFlowId: 3,
		subSteps: JOB_PIPELINE_SUBFLOW_STEPS,
		label: "Log Client Submission",
		button: "Log Client Submission",
	},
	{
		id: 4,
		key: "composeSubmission",
		tabId: 0,
		flowId: 1,
		subFlowId: 0,
		subSteps: JOB_SUBMISSION_SUBFLOW_STEPS,
		label: "Compose Submission",
		button: "Compose Submission",
	},
	{
		id: 5,
		key: "sendSubmission",
		tabId: 0,
		flowId: 1,
		subFlowId: 1,
		subSteps: JOB_SUBMISSION_SUBFLOW_STEPS,
		label: "Send Submission",
		button: "Send Submission",
	},
	{
		id: 6,
		key: "coordinateInterview",
		tabId: 0,
		flowId: 1,
		subFlowId: 2,
		subSteps: JOB_SUBMISSION_SUBFLOW_STEPS,
		label: "Coordinate Interview",
		button: "Coordinate Interview",
	},
	{
		id: 7,
		key: "firstInterviewCoordinate",
		tabId: 0,
		flowId: 2,
		subFlowId: 0,
		subSteps: JOB_INTERVIEW_SUBFLOW_STEPS,
		label: "First Interview",
		button: "",
	},
	{
		id: 8,
		key: "firstInterviewComposeInvite",
		tabId: 0,
		flowId: 2,
		subFlowId: 1,
		subSteps: JOB_INTERVIEW_SUBFLOW_STEPS,
		label: "First Interview",
		button: "",
	},
	{
		id: 9,
		key: "firstInterviewFeedback",
		tabId: 0,
		flowId: 2,
		subFlowId: 2,
		subSteps: JOB_INTERVIEW_SUBFLOW_STEPS,
		label: "First Interview",
		button: "",
	},
	{
		id: 10,
		key: "interviewCreateOffer",
		tabId: 0,
		flowId: 2,
		subFlowId: 3,
		subSteps: JOB_INTERVIEW_SUBFLOW_STEPS,
		label: "",
		button: "Create Offer",
		externalButton: "New Interview",
	},
	{
		id: 11,
		key: "offerDraft",
		tabId: 0,
		flowId: 3,
		subFlowId: 0,
		subSteps: JOB_OFFER_SUBFLOW_STEPS,
		label: "",
		button: "Log Offer",
	},
	{
		id: 12,
		key: "offerSend",
		tabId: 0,
		flowId: 3,
		subFlowId: 1,
		subSteps: JOB_OFFER_SUBFLOW_STEPS,
		label: "",
		button: "Send Offer",
	},
	{
		id: 13,
		key: "offerSend",
		tabId: 0,
		flowId: 3,
		subFlowId: 2,
		subSteps: JOB_OFFER_SUBFLOW_STEPS,
		label: "",
		button: "Create Placement",
	},
	{
		id: 14,
		key: "placementCreate",
		tabId: 1,
		startPage: true,
	},
];

export const JOB_PLACEMENT_PIPELINE_STEPS = [
	{
		id: 0,
		key: "termsAndContacts",
		flowId: 0,
		subFlowId: 0,
		subSteps: JOB_PLACEMENT_CREATE_SUBFLOW_STEPS,
		label: "Terms & Contacts",
		button: "Next",
	},
];
