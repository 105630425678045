const credential_main = {
	_name: "credential_main",
	id: "id",
	credential_owner_account_ref_id: "credential_owner_account_ref_id",
	credential_name: "credential_name",
	credential_type_c: "credential_type_c",
	credential_status_c: "credential_status_c",
	credential_status_datetime: "credential_status_datetime",
	credential_interval_datetime: "credential_interval_datetime",
	global_email_details_ref_id: "global_email_details_ref_id",
	global_password_details_ref_id: "global_password_details_ref_id",
	global_phone_details_ref_id: "global_phone_details_ref_id",
	record_state_sc: "record_state_sc",
};

const credential_log_activity = {
	_name: "credential_log_activity",
	id: "id",
	date_created: "date_created",
	account_main_ref_id: "account_main_ref_id",
	log_object_c: "log_object_c",
	log_type_c: "log_type_c",
	log_action_c: "log_action_c",
	log_details: "log_details",
};

const credential_proxy_detail = {
	_name: "credential_proxy_detail",
	id: "id",
	date_created: "date_created",
	credential_main_ref_id: "credential_main_ref_id",
	proxy_domain: "proxy_domain",
	proxy_ip: "proxy_ip",
	proxy_port: "proxy_port",
	proxy_username: "proxy_username",
	global_password_details_ref_id: "global_password_details_ref_id",
	proxy_status_c: "proxy_status_c",
};

const credential_external_verification = {
	_name: "credential_external_verification",
	id: "id",
	date_created: "date_created",
	credential_main_ref_id: "credential_main_ref_id",
	verification_details: "verification_details",
	verification_status_c: "verification_status_c",
};

const credential_setting = {
	_name: "credential_setting",
	id: "id",
	setting_value: "setting_value",
	setting_type_c: "setting_type_c",
	credential_main_ref_id: "credential_main_ref_id",
};

const credential_queue_activity = {
	_name: "credential_queue_activity",
	id: "id",
	credential_main_ref_id: "credential_main_ref_id",
	date_created: "date_created",
	activity_type_c: "activity_type_c",
	activity_priority_level: "activity_priority_level",
	activity_details: "activity_details",
	link_source_type_sc: "link_source_type_sc",
	link_source_subtype_sc: "link_source_subtype_sc",
	link_source_record_id: "link_source_record_id",
};

const credential_tracking_cookie = {
	_name: "credential_queue_activity",
	id: "id",
	credential_main_ref_id: "credential_main_ref_id",
	date_created: "date_created",
	data_category_c: "data_category_c",
	data_type_c: "data_type_c",
	data_type_ref_id: "data_type_ref_id",
	data_value: "data_value",
};

const credential_external_tracking = {
	_name: "credential_external_tracking",
	id: "id",
	credential_main_ref_id: "credential_main_ref_id",
	date_created: "date_created",
	activity_type_dc: "activity_type_dc",
	increment_value: "increment_value",
};

const credential_token = {
	_name: "credential_token",
	id: "id",
	credential_main_ref_id: "credential_main_ref_id",
	date_created: "date_created",
	token_value: "token_value",
	token_state_c: "token_state_c",
};

const credential_permission = {
	_name: "credential_permission",
	id: "id",
	credential_main_ref_id: "credential_main_ref_id",
	security_group_ref_id: "security_group_ref_id",
};

const credential_use = {
	_name: "credential_use",
	id: "id",
	date_created: "date_created",
	link_credential_share_id: "link_credential_share_id",
	use_units: "use_units",
	use_status_c: "use_status_c",
	use_details: "use_details",
};

const credential_relationship = {
	_name: "credential_relationship",
	id: "id",
	credential_main_ref_id: "credential_main_ref_id",
	relationship_type_c: "relationship_type_c",
	ref_object_base_sc: "ref_object_base_sc",
	ref_object_type_sc: "ref_object_type_sc",
	ref_object_record_id: "ref_object_record_id",
	relationship_details: "relationship_details",
};

const credential_share = {
	_name: "credential_share",
	id: "id",
	link_credential_main_id: "link_credential_main_id",
	link_account_main_id: "link_account_main_id",
	share_status_c: "share_status_c",
	share_access_c: "share_access_c",
	share_interval_datetime: "share_interval_datetime",
	share_details: "share_details",
};

export {
	credential_main,
	credential_log_activity,
	credential_proxy_detail,
	credential_external_verification,
	credential_setting,
	credential_queue_activity,
	credential_tracking_cookie,
	credential_external_tracking,
	credential_token,
	credential_permission,
	credential_use,
	credential_relationship,
	credential_share,
};
