import React from "react";
import styled from "styled-components";
import { STEP_STATUES } from "components/global/constants";

import variables from "assets/css/variables.scss";

/**
 * Main component for Horizental Step Progressbar with Arrow
 */
const HorizontalStepbarWithArrow = (props) => {
	const { step, steps } = props;

	if (!steps || steps.length === 0) {
		return <></>;
	}

	const maxSteps = steps.length;

	return (
		<Wrapper>
			{steps?.map((item, index) => {
				let stepState = STEP_STATUES.complete;

				if (index === step) {
					stepState = STEP_STATUES.selected;
				} else if (index > step) {
					stepState = STEP_STATUES.incomplete;
				}

				return (
					<StepBarContainer
						key={`horizontal-step-progress-bar-${index}`}
						maxSteps={maxSteps}
					>
						<StepArrowWrapper className={`${stepState?.className}`}>
							{index < maxSteps - 1 ? (
								<StepArrowCover>
									<StepArrow className="arrow" />
								</StepArrowCover>
							) : null}
						</StepArrowWrapper>
						<StepLabel>{item?.label || ""}</StepLabel>
					</StepBarContainer>
				);
			})}
		</Wrapper>
	);
};

export default HorizontalStepbarWithArrow;

const Wrapper = styled.div`
	display: table;
	overflow: hidden;
	margin: 0;
	min-width: 444px;
`;

const StepBarContainer = styled.div`
	position: relative;
	display: table-cell;
	width: calc(100% / ${(props) => props.maxSteps || 1});
`;

const StepArrowWrapper = styled.div`
	position: relative;
	height: 1.6667rem;

	background-color: ${variables.borderColor};

	&.complete {
		background-color: ${variables.linkColor};

		.arrow {
			background: ${variables.linkColor} !important;
		}
	}

	&.selected {
		background-color: ${variables.warningColor};

		.arrow {
			background: ${variables.warningColor} !important;
		}
	}
`;

const StepArrowCover = styled.div`
	position: absolute;
	overflow: hidden;
	width: 1.6667rem;
	height: 1.667rem;
	right: -1.667rem;
`;

const StepArrow = styled.div`
	position: absolute;
	right: 0.6667rem;
	z-index: 2;
	width: 2rem;
	height: 2rem;
	background: transparent;
	transform: rotate(45deg);
	border: 4px solid #fff;
	top: -2px;
`;

const StepLabel = styled.div`
	margin-top: 0.333rem;

	color: ${variables.primaryColor};
	text-align: center;
	font-size: 1rem;
	line-height: 1.5;
`;
