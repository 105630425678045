import React from "react";
import styled from "styled-components";
import { EuiButton } from "@elastic/eui";

const RoundButtonWithPlusIcon = (props) => {
	const { label, onClick } = props;

	return (
		<Wrapper>
			<EuiButton
				color="text"
				onClick={onClick}
				className="icon-button mb-0"
				size="s"
			>
				{`+ ${label || ""}`}
			</EuiButton>
		</Wrapper>
	);
};

export default RoundButtonWithPlusIcon;

const Wrapper = styled.div`
	display: flex;
	position: relative;

	box-sizing: border-box;
	border-radius: var(--sp-6);

	.icon-button {
		min-width: fit-content;
		height: fit-content;
		box-shadow: none;
		height: fit-content !important;

		border: 1px solid var(--border-color);
		border-radius: var(--sp-6);

		& > span {
			padding: 0;
			& > span {
				font-weight: bold;
				font-size: var(--sp-4_5);
				line-height: var(--sp-6);
				padding: 0 var(--sp-2_5);
				text-transform: uppercase;
			}
		}
	}
`;
