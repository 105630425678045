import React, { useEffect, useState } from "react";
import { cloneDeep, get } from "lodash";
import * as MODEL from "core/model";

import { AmsEditActivityItem } from "./AmsEditActivityItem";
import { AmsEditNoteItem } from "./AmsEditNoteItem";

/**
 * Component for AMS Item with editable feature
 */
const AmsEditNewItem = (props) => {
	const {
		onChangeEdit,
		data,
		person,
		account,
		accounts,
		owners,
		assignees,
		restriction,
		onUpdateAms,
		onDeleteAms,
		onSaveEmail,
		onDeleteEmail,
		onSavePhone,
		onDeletePhone,
		onSaveRAS,
		globalSearchItems,
		getGlobalSearchItems,
	} = props;

	const [viewType, setViewType] = useState(1);

	const handleChangeViewType = (newType) => {
		setViewType(newType);

		const params = {
			[MODEL.ams_activity_main.activity_view_type_c]: newType,
		};
		onUpdateAms(params);
	};

	const handleUpdateNote = (_note) => {
		// Get notes
		const activityNotes = get(data, MODEL.ams_activity_note._name, []) || [];
		const notes =
			activityNotes?.map((item, index) => {
				return {
					...item,
					index,
				};
			}) || [];

		// Get information for ams_activity_note
		const note = cloneDeep(_note);
		const oldNoteIndex = notes.findIndex((item) => item.index === note.index);

		if (note.name !== undefined) {
			delete note.name;
		}

		if (oldNoteIndex === -1) {
			note.index = notes?.length || 0;
			notes.push(note);
		} else {
			notes[oldNoteIndex] = note;
		}

		const amsNoteInfo = {
			[MODEL.ams_activity_note._name]: notes,
		};

		// Save updated AMS items
		const updateParams = amsNoteInfo;
		onUpdateAms(updateParams);
	};

	const handleDeleteNote = (note) => {
		// Delete AMS items
		const deleteNotes = {
			[MODEL.ams_activity_note._name]: note,
		};

		// Save updated AMS items
		const deleteParams = deleteNotes;
		onDeleteAms(deleteParams);
	};

	const handleChangeAmsType = (type) => {
		// Get information for ams_activity_main
		const amsMainInfo = {
			[MODEL.ams_activity_main.activity_type_c]: Number(type),
		};

		// Save updated AMS items
		const updateParams = amsMainInfo;
		onUpdateAms(updateParams);
	};

	const handleChangeAmsName = (name) => {
		// Get information for ams_activity_main
		const amsMainInfo = {
			[MODEL.ams_activity_main.activity_name]: name,
		};

		// Save updated AMS items
		const updateParams = amsMainInfo;
		onUpdateAms(updateParams);
	};

	const handleChangeAmsStartTime = (startDate) => {
		// Get information for ams_activity_time
		const amsTimeData = data[MODEL.ams_activity_time._name]?.[0];
		const amsTimeInfo = {
			[MODEL.ams_activity_time._name]: [
				{
					...amsTimeData,
					...(startDate
						? { [MODEL.ams_activity_time.date_start]: startDate }
						: {}),
				},
			],
		};

		// Save updated AMS items
		const updateParams = amsTimeInfo;
		onUpdateAms(updateParams);
	};

	const handleChangeAmsEndTime = (endDate) => {
		// Get information for ams_activity_time
		const amsTimeData = data[MODEL.ams_activity_time._name]?.[0];
		const amsTimeInfo = {
			[MODEL.ams_activity_time._name]: [
				{
					...amsTimeData,
					...(endDate ? { [MODEL.ams_activity_time.date_end]: endDate } : {}),
				},
			],
		};

		// Save updated AMS items
		const updateParams = amsTimeInfo;
		onUpdateAms(updateParams);
	};

	const handleUpdateTask = (_task) => {
		// Get task
		const activityTasks = get(data, MODEL.ams_activity_task._name, []) || [];
		const tasks =
			activityTasks?.map((item, index) => {
				return {
					...item,
					index,
				};
			}) || [];

		// Get information for ams_activity_task
		const task = cloneDeep(_task);
		const oldTaskIndex = tasks.findIndex((item) => item.index === task.index);

		if (task.name !== undefined) {
			delete task.name;
		}

		if (task[MODEL.ams_activity_reminder._name] !== undefined) {
			delete task[MODEL.ams_activity_reminder._name];
		}

		if (oldTaskIndex === -1) {
			task.index = tasks?.length || 0;
			tasks.push(task);
		} else {
			tasks[oldTaskIndex] = task;
		}

		const amsTaskInfo = {
			[MODEL.ams_activity_task._name]: tasks,
		};

		// Save updated AMS items
		const updateParams = amsTaskInfo;
		onUpdateAms(updateParams);
	};

	const handleDeleteTask = (task) => {
		// Delete AMS items
		const deleteTasks = {
			[MODEL.ams_activity_task._name]: task,
		};

		// Save updated AMS items
		const deleteParams = deleteTasks;
		onDeleteAms(deleteParams);
	};

	const handleUpdateReminder = (_reminder) => {
		// Get information for ams_activity_reminder
		const reminder = cloneDeep(_reminder);
		delete reminder.index;
		if (reminder.name !== undefined) {
			delete reminder.name;
		}
		const amsReminderInfo = {
			[MODEL.ams_activity_reminder._name]: reminder,
		};

		// Save updated AMS items
		const updateParams = amsReminderInfo;
		onUpdateAms(updateParams);
	};

	const handleDeleteReminder = (reminder) => {
		// Delete AMS items
		const deleteReminders = {
			[MODEL.ams_activity_reminder._name]: reminder,
		};

		// Save updated AMS items
		const deleteParams = deleteReminders;
		onDeleteAms(deleteParams);
	};

	const handleChangeAmsStatus = (newStatus) => {
		const params = {
			[MODEL.ams_activity_main.activity_status_c]: newStatus,
		};
		onUpdateAms(params);
	};

	useEffect(() => {
		const activityViewType = get(
			data,
			MODEL.ams_activity_main.activity_view_type_c,
			1
		);
		setViewType(activityViewType);
	}, [data]);

	if (viewType === 1) {
		return (
			<AmsEditNoteItem
				data={data}
				person={person}
				account={account}
				accounts={accounts}
				restriction={restriction}
				owners={owners}
				assignees={assignees}
				onChangeEdit={onChangeEdit}
				onChangeViewType={() => handleChangeViewType(2)}
				onChangeAmsType={handleChangeAmsType}
				onChangeAmsName={handleChangeAmsName}
				onChangeAmsStatus={handleChangeAmsStatus}
				onUpdateNote={handleUpdateNote}
				onDeleteNote={handleDeleteNote}
				onSaveRAS={onSaveRAS}
			/>
		);
	} else {
		return (
			<AmsEditActivityItem
				data={data}
				person={person}
				account={account}
				accounts={accounts}
				restriction={restriction}
				owners={owners}
				assignees={assignees}
				onChangeEdit={onChangeEdit}
				onChangeViewType={() => handleChangeViewType(1)}
				onChangeAmsType={handleChangeAmsType}
				onChangeAmsName={handleChangeAmsName}
				onChangeAmsStatus={handleChangeAmsStatus}
				onUpdateNote={handleUpdateNote}
				onDeleteNote={handleDeleteNote}
				onChangeAmsStartTime={handleChangeAmsStartTime}
				onChangeAmsEndTime={handleChangeAmsEndTime}
				onUpdateTask={handleUpdateTask}
				onDeleteTask={handleDeleteTask}
				onUpdateReminder={handleUpdateReminder}
				onDeleteReminder={handleDeleteReminder}
				onSavePhone={onSavePhone}
				onDeletePhone={onDeletePhone}
				onSaveEmail={onSaveEmail}
				onDeleteEmail={onDeleteEmail}
				onSaveRAS={onSaveRAS}
				globalSearchItems={globalSearchItems}
				getGlobalSearchItems={getGlobalSearchItems}
			/>
		);
	}
};

export default AmsEditNewItem;
