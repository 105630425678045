import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { EuiButtonGroup, EuiFlexGroup, EuiFlexItem, EuiButtonEmpty } from "@elastic/eui";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faDownload, faEllipsisH } from "@fortawesome/pro-solid-svg-icons";
import { TextEditor } from "components/Common";
/**
 * Main Components
 */
const JobDescription = (props) => {
    const { detail } = props;

    const options = [
        {
            id: "internal",
            label: "Internal",
        },
        {
            id: "publish",
            label: "Publish",
        },
    ];

    // state variables
    const [selectedOption, setSelectedOption] = useState(options[0].id);
    const [publicMessage, setPublicMessage] = useState("");
    const [internalMessage, setInternalMessage] = useState("");

    // effect
    useEffect(() => {
        setPublicMessage(detail?.public);
        setInternalMessage(detail?.internal);
    }, [detail]);

    // functions
    const onChangeOption = (id = null) => {
        setSelectedOption(id);
    };

    return (
        <Wrapper>
            <EuiFlexGroup justifyContent="spaceBetween" gutterSize="s">
                <EuiFlexItem className="my-auto">
                    <div>
                        <EuiButtonGroup
                            legend="This is a option group"
                            options={options}
                            idSelected={selectedOption}
                            onChange={(id) => onChangeOption(id)}
                            color="primary"
                            className="job-button-group"
                        />
                    </div>
                </EuiFlexItem>
            </EuiFlexGroup>
            <EuiFlexGroup>
                <EuiFlexItem>
                    <div
                        className={
                            "detail-container " +
                            (selectedOption === "internal" ? "show" : "hide")
                        }
                        dangerouslySetInnerHTML={{ __html: internalMessage }}
                    ></div>
                    <div
                        className={
                            "detail-container " +
                            (selectedOption === "publish" ? "show" : "hide")
                        }
                        dangerouslySetInnerHTML={{ __html: publicMessage }}
                    ></div>
                </EuiFlexItem>
            </EuiFlexGroup>
        </Wrapper>
    );
};

export default JobDescription;

/**
 * Styled Components
 */
const Wrapper = styled.div`
    .job-button-group {
        .euiFieldSearch,
        .euiButtonEmpty.euiButtonEmpty--small,
        .euiButton.euiButton--small {
            height: 24px;
            .euiButton__text,
            .euiButtonEmpty__text,
            .euiTab__content {
                font-size: 1rem;
            }
        }
    }
    .euiButton {
        min-width: unset;
    }

    .detail-container {
        height: 150px;
        overflow: auto;
        padding: 4px;
        border: solid 1px var(--border-color);
    }
    .show {
        display: block;
    }
    .hide {
        display: none;
    }
`;
