import { useDispatch } from "react-redux";

import { deleteJob_NEW, getJobList, updateJob_NEW } from "helpers/actions";
import { _getJobList_NEW } from "helpers/selectors";

/**
 * Hooks for Job
 */
const useJob = () => {
	const dispatch = useDispatch();

	const jobList = _getJobList_NEW();

	const getJobs = (params) => {
		dispatch(getJobList(params));
	};

	const updateJobInfo = (params, successCallback, failCallback) => {
		dispatch(updateJob_NEW(params, successCallback, failCallback));
	};

	const deleteJobInfo = (params, successCallback, failCallback) => {
		dispatch(deleteJob_NEW(params, successCallback, failCallback));
	};

	return {
		jobList,
		getJobList: getJobs,
		updateJobInfo,
		deleteJobInfo,
	};
};

export default useJob;
