import React from "react";
import { EuiFlexItem, EuiFlexGroup } from "@elastic/eui";
import styled from "styled-components";

import * as MODEL from "core/model";

import { EditableNumber } from "components/Common";
import { set } from "lodash";

/**
 * Component for Double Item
 */
const DoubletimeItem = (props) => {
	const { data, onChange } = props;

	const { value } = data;

	const handleChangeValue = (newValue) => {
		const _data = set(data, `value`, newValue);
		onChange(_data);
	};

	return (
		<Wrapper>
			<EuiFlexGroup gutterSize="s">
				<EuiFlexItem>
					<EditableNumber
						value={value}
						prepend="$"
						onChange={handleChangeValue}
					/>
				</EuiFlexItem>
			</EuiFlexGroup>
		</Wrapper>
	);
};

export default DoubletimeItem;

const Wrapper = styled.div`
	display: flex;
`;
