import React, { useState } from "react";
import styled from "styled-components";
import {
	EuiFlexGroup,
	EuiFlexItem,
	EuiLink,
	EuiButtonIcon,
	EuiFieldText,
} from "@elastic/eui";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faClipboardCheck, faBellPlus } from "@fortawesome/pro-light-svg-icons";

import * as MODEL from "core/model";
import { DatePickerItem } from "components/Common";
import AmsTaskStatusItem from "./AmsTaskStatusItem";
import AmsTaskPriorityItem from "./AmsTaskPriorityItem";
import { getUTCDate } from "components/global/utils";

import {
	AMS_TASK_PRIORITY_LIST,
	AMS_TASK_TYPE_LIST,
	AMS_TASK_STATUS_LIST,
} from "../../constants";
/**
 * Component for New Item for AMS Task List Item
 */
const AmsTaskListNewItem = (props) => {
	const { onSave, onCancel } = props;

	const [amsTaskStatus, setAmsTaskStatus] = useState(
		AMS_TASK_STATUS_LIST.pending.value
	);
	const [amsTaskPriority, setAmsTaskPriority] = useState(
		AMS_TASK_PRIORITY_LIST.normal.value
	);
	const [amsTaskDueDate, setAmsTaskDueDate] = useState(new Date().toISOString());
	const [amsTaskName, setAmsTaskName] = useState("");

	const handleSave = () => {
		const date = getUTCDate(amsTaskDueDate);
		const task = {
			id: null,
			index: -1,
			[MODEL.ams_activity_task.task_name]: amsTaskName,
			[MODEL.ams_activity_task.task_status_c]: amsTaskStatus,
			[MODEL.ams_activity_task.task_priority_c]: amsTaskPriority,
			[MODEL.ams_activity_task.task_due_date]: date,
			[MODEL.ams_activity_task.task_type_c]: AMS_TASK_TYPE_LIST.taskItem.value,
		};

		onSave(task);
	};

	const handleCancel = () => {
		onCancel();
	};

	const handleChangeStatus = (newStatus) => {
		setAmsTaskStatus(newStatus);
	};

	const handleChangePriority = (newPriority) => {
		setAmsTaskPriority(newPriority);
	};

	const handleChangeDueDate = (newDate) => {
		setAmsTaskDueDate(newDate);
	};

	const handleChangeTaskName = (e) => {
		setAmsTaskName(e.target.value);
	};

	return (
		<Wrapper>
			<EuiFlexGroup direction="column" gutterSize="xs">
				<EuiFlexItem>
					<EuiFlexGroup gutterSize="s">
						<EuiFlexItem grow={false}>
							<AmsTaskStatusItem
								status={amsTaskStatus}
								onChangeStatus={handleChangeStatus}
							/>
						</EuiFlexItem>
						<EuiFlexItem grow={false}>
							<AmsTaskPriorityItem
								priority={amsTaskPriority}
								onChangePriority={handleChangePriority}
							/>
						</EuiFlexItem>
						<EuiFlexItem>
							<EuiFlexGroup direction="column" gutterSize="s">
								<EuiFlexItem>
									<EuiFlexGroup gutterSize="m" alignItems="center">
										<EuiFlexItem grow={false}>
											<FontAwesomeIcon
												icon={faClipboardCheck}
												color="var(--link-color)"
												size="lg"
											/>
										</EuiFlexItem>
										<EuiFlexItem className="task-name">
											<EuiFieldText
												value={amsTaskName}
												onChange={handleChangeTaskName}
												className="textfield-fit-conent"
											/>
										</EuiFlexItem>
									</EuiFlexGroup>
								</EuiFlexItem>
								<EuiFlexItem>
									<EuiFlexGroup gutterSize="xs" alignItems="center">
										<EuiFlexItem grow={false}>
											<p className="type-x-small semibold">
												Due:&nbsp;
											</p>
										</EuiFlexItem>
										<EuiFlexItem grow={false}>
											<EuiLink>
												<p
													className="type-small-medium"
													style={{ color: "var(--link-color)" }}
												>
													<DatePickerItem
														value={amsTaskDueDate}
														typeDate="MMM D, YYYY @ h:ma"
														onChange={handleChangeDueDate}
													/>
												</p>
											</EuiLink>
										</EuiFlexItem>
										<EuiFlexItem grow={false}>
											<FontAwesomeIcon
												icon={faBellPlus}
												color="var(--link-color)"
												className="reminder-icon"
											/>
										</EuiFlexItem>
									</EuiFlexGroup>
								</EuiFlexItem>
							</EuiFlexGroup>
						</EuiFlexItem>
					</EuiFlexGroup>
				</EuiFlexItem>
				<EuiFlexItem grow={false}>
					<EuiFlexGroup gutterSize="s" justifyContent="center">
						<EuiFlexItem grow={false}>
							<EuiButtonIcon
								color="success"
								onClick={handleSave}
								iconType="check"
							/>
						</EuiFlexItem>
						<EuiFlexItem grow={false}>
							<EuiButtonIcon
								color="danger"
								onClick={handleCancel}
								iconType="cross"
							/>
						</EuiFlexItem>
					</EuiFlexGroup>
				</EuiFlexItem>
			</EuiFlexGroup>
		</Wrapper>
	);
};

export default AmsTaskListNewItem;

/**
 * Styled Components
 */
const Wrapper = styled.div`
	display: flex;

	.badge-item {
		padding: 0 var(--sp-2);
	}

	.setting-icon-button {
		.euiButtonContent.euiButtonEmpty__content {
			padding: 0 !important;
		}
	}

	.icon-button-img {
		align-items: center;
		display: flex;
	}

	.task-name {
		.euiFormControlLayout {
			height: fit-content;
		}
	}

	.reminder-icon {
		width: var(--sp-7);
		height: auto;
	}
`;
