import API from "api";
import { isNull, isUndefined, pickBy } from "lodash";

import {
	makeSort,
	makeTableJoin,
	makeFieldContent,
	makeExternalJoin,
} from "./commonService";

/** ---------------- Generic API ---------------------- */
const updateAms = (data) => {
	const request = {
		object: "ams",
		data: data || {},
	};

	return API().GenericAPI().updateTableData(request);
};

const deleteAms = (data) => {
	const request = {
		object: "ams",
		data: data || {},
	};

	return API().GenericAPI().deleteTableData(request);
};
/** --------------------------------------------------------- */

/** ----------------------- Custom API ---------------------- */
const getAmsList = (params) => {
	const query = {
		page_number: params?.page_number || 0,
		page_count: params?.page_count || 30,
		ams_activity_name: params?.ams_activity_name ?? null,
		ams_task_name: params?.ams_task_name ?? null,
		assigned_account_id: params?.ams_assigned_account_id ?? null,
		assigned_person_id: params?.ams_assigned_person_id ?? null,
		assigned_company_id: params?.ams_assigned_company_id ?? null,
		assigned_job_id: params?.ams_assigned_job_id ?? null,
	};
	const filteredQuery = pickBy(query, (item) => !isNull(item) && !isUndefined(item));
	return API().Ams().getAmsList(filteredQuery);
};

const getAmsReminders = (params) => {
	const query = {
		account_id: params?.account_id,
	};
	return API().Ams().getAmsReminders(query);
};
/* ---------------------------------------------------------- */

export default {
	getAmsList,
	updateAms,
	deleteAms,
	getAmsReminders,
};
