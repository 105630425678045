import { useDispatch } from "react-redux";

import { _getGlobalFlyout } from "helpers/selectors";
import {
	updateGlobalFlyoutParams,
	closeGlobalFlyout,
	openGlobalFlyout,
} from "helpers/actions";

/**
 * Hooks for Global Flyout
 */
const useGlobalFlyout = () => {
	const dispatch = useDispatch();

	const globalFlyoutParams = _getGlobalFlyout();

	const setFlyout = (params) => {
		if (params?.isOpen) {
			// Close flyout first and then open new flyout
			closeFlyout();
			setTimeout(() => {
				dispatch(updateGlobalFlyoutParams(params));
			}, 1);
		} else {
			dispatch(updateGlobalFlyoutParams(params));
		}
	};

	const closeFlyout = () => {
		dispatch(closeGlobalFlyout());
	};

	const openFlyout = () => {
		dispatch(openGlobalFlyout());
	};

	return {
		globalFlyoutParams,
		setFlyout,
		closeFlyout,
		openFlyout,
	};
};

export default useGlobalFlyout;
