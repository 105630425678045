import React from "react";
import styled from "styled-components";

import { SelectCreateEmail, SVGIcon } from "components/Common";
import { emailGlobeIcon } from "components/Common/Icons";

/**
 * Main Components
 */
const PersonJobEmail = (props) => {

	const {
        email,
        options,
        onChangeEmail,
		...restProps
	} = props;

    const handleChangeEmail = (val) => {
        onChangeEmail(val);
    }

	return (
		<Wrapper {...restProps} className="mb-3">
            <SVGIcon
                icon={emailGlobeIcon}
                className="mt-2 mr-2"
            />
            <SelectCreateEmail
                id={0}
                email={email}
                options={options}
                onChangeEmail={handleChangeEmail}
            />
		</Wrapper>
	);
};

export default PersonJobEmail;

/**
 * Styled Components
 */
const Wrapper = styled.div`
    display: flex;
    width: 100%;

    .icon-button {
        margin: auto 0 0 0.5rem;
    }

    .euiFormControlLayout {
        height: 25px;
    }
`;
