import * as actionTypes from "./actionTypes";

/**
 * Functions
 */
const getCampaigns = (state, payload) => {
	return {
		...state,
		message: payload.message,
		error: [...state.error, payload.error],
	};
};

const setCampaigns = (state, payload) => {
	return {
		...state,
		campaigns: payload.data,
		campaignTotalCount: payload.totalCount ?? 0,
		pagination: {
			totalCount: payload.totalCount ?? 0,
			currentPage: payload.pageNumber ?? 1,
		},
	};
};

const getCampaign = (state, payload) => {
	return {
		...state,
		message: payload.message,
		error: [...state.error, payload.error],
	};
};

const setCampaign = (state, payload) => {
	return {
		...state,
		campaign: payload,
	};
};

const setWorkflows = (state, payload) => {
	return {
		...state,
		workflows: payload,
	};
};

const setWorkflow = (state, payload) => {
	return {
		...state,
		workflow: payload,
	};
};

const saveWorkflowForCampaign = (state, payload) => {
	return {
		...state,
		saveWorkflow: payload,
	};
};

const setTemplates = (state, payload) => {
	return {
		...state,
		templates: payload,
	};
};

const saveCampaign = (state, payload) => {
	return {
		...state,
		saveCampaign: payload,
	};
};

const deleteCampaign = (state, payload) => {
	return {
		...state,
		deleteCampaign: payload,
	};
};

const saveTemplate = (state, payload) => {
	return {
		...state,
		saveTemplate: payload,
	};
};

const setTemplate = (state, payload) => {
	return {
		...state,
		template: payload,
	};
};

const saveCampaignWorkflowConfig = (state, payload) => {
	return {
		...state,
		saveCampaignWorkflowConfig: payload,
	};
};

const executeCampaignRequest = (state, payload) => {
	return {
		...state,
		loading: true,
	};
};

const executeCampaignSuccess = (state, payload) => {
	return {
		...state,
		loading: false,
		executeCampaign: payload,
	};
};

const executeCampaignFail = (state, payload) => {
	return {
		...state,
		loading: false,
		executeCampaignError: payload,
	};
};

const cancelCampaignRequest = (state, payload) => {
	return {
		...state,
		loading: true,
	};
};

const cancelCampaignSuccess = (state, payload) => {
	return {
		...state,
		loading: false,
		cancelCampaign: payload,
	};
};

const cancelCampaignFail = (state, payload) => {
	return {
		...state,
		loading: false,
		cancelCampaignError: payload,
	};
};

const copyCampaignRequest = (state) => {
	return {
		...state,
		loading: true,
	};
};

const copyCampaignSuccess = (state, payload) => {
	return {
		...state,
		loading: false,
		copyCampaign: payload,
	};
};

const copyCampaignFail = (state, payload) => {
	return {
		...state,
		loading: false,
		copyCampaignError: payload,
	};
};

/* --------------------------------- NEW ---------------------------- */
const requestCampaignList_NEW = (state) => {
	return {
		...state,
		campaignList_NEW: {
			...state.campaignList_NEW,
			isRequesting: true,
			isReceived: false,
		},
	};
};

const receivedCampaignList_NEW = (state) => {
	return {
		...state,
		campaignList_NEW: {
			...state.campaignList_NEW,
			isRequesting: false,
			isReceived: true,
		},
	};
};

const setCampaignList_NEW = (state, payload) => {
	return {
		...state,
		campaignList_NEW: payload,
	};
};

const setCampaignListParams = (state, payload) => {
	return {
		...state,
		campaignList_NEW: {
			...state.campaignList_NEW,
			...payload,
		},
	};
};

const setCampaignDetail_NEW = (state, payload) => {
	return {
		...state,
		campaign_NEW: payload,
	};
};

// Campaign Main Table
const requestCampaignMainList_NEW = (state) => {
	return {
		...state,
		campaignMainList_NEW: {
			...state.campaignMainList_NEW,
			isRequesting: true,
			isReceived: false,
		},
	};
};

const receivedCampaignMainList_NEW = (state) => {
	return {
		...state,
		campaignMainList_NEW: {
			...state.campaignMainList_NEW,
			isRequesting: false,
			isReceived: true,
		},
	};
};

const setAllCampaignMainList = (state, payload) => {
	return {
		...state,
		campaignAllList: {
			...state.campaignAllList,
			...payload,
		},
	};
};

const setCampaignMainList_NEW = (state, payload) => {
	return {
		...state,
		campaignMainList_NEW: {
			...state.campaignMainList_NEW,
			...payload,
			isRequesting: false,
			isReceived: true,
		},
	};
};

const setCampaignWorkflowItemsList = (state, payload) => {
	return {
		...state,
		workflowItems: {
			...state.workflowItems,
			...payload,
			isRequesting: false,
			isReceived: true,
		},
	};
};

const setCampaignWorkflowItemsListParams = (state, payload) => {
	return {
		...state,
		workflowItems: {
			...state.workflowItems,
			...payload,
		},
	};
};

const requestCampaignWorkflowItemsList = (state) => {
	return {
		...state,
		workflowItems: {
			...state.workflowItems,
			isRequesting: true,
			isReceived: false,
		},
	};
};

const receivedCampaignWorkflowItemsList = (state) => {
	return {
		...state,
		workflowItems: {
			...state.workflowItems,
			isRequesting: false,
			isReceived: true,
		},
	};
};

const setWorkflowItemCounts = (state, payload) => {
	return {
		...state,
		workflowItems: {
			...state.workflowItems,
			counts: payload,
		},
	};
};

const setCampaignPersonList = (state, payload) => {
	return {
		...state,
		campaignPersonList: {
			...state.campaignPersonList,
			...payload,
			isRequesting: false,
			isReceived: true,
		},
	};
};

const setCampaignPersonListParams = (state, payload) => {
	return {
		...state,
		campaignPersonList: {
			...state.campaignPersonList,
			...payload,
		},
	};
};

const requestCampaignPersonList = (state) => {
	return {
		...state,
		campaignPersonList: {
			...state.campaignPersonList,
			isRequesting: true,
			isReceived: false,
		},
	};
};

const receivedCampaignPersonList = (state) => {
	return {
		...state,
		campaignPersonList: {
			...state.campaignPersonList,
			isRequesting: false,
			isReceived: true,
		},
	};
};

// Job list for creating campaign
const setJobListForCreateCampaign = (state, payload) => {
	return {
		...state,
		create: {
			...state.create,
			jobs: {
				...payload,
			},
		},
	};
};

// Workflow Summary
const setWorkflowSummary = (state, payload) => {
	return {
		...state,
		workflowSummary: {
			...state.workflowSummary,
			...payload,
		},
	};
};

// Workflow Node Details
const setWorkflowNodeDetails = (state, payload) => {
	return {
		...state,
		workflowNodeDetails: {
			...state.workflowNodeDetails,
			...payload,
		},
	};
};

// Workflow Template
const setWorkflowTemplate = (state, payload) => {
	return {
		...state,
		workflowTemplate: {
			...state.workflowTemplate,
			...payload,
		},
	};
};

const setWorkflowTemplateList = (state, payload) => {
	return {
		...state,
		workflowTemplateList: payload,
	};
};

const setCampaignListBySearch = (state, payload) => {
	return {
		...state,
		campaignListBySearch: payload,
	};
};

const setCampaignListByPerson = (state, payload) => {
	return {
		...state,
		campaignListByPerson: payload,
	};
};
/* ------------------------------------------------------------------ */

/**
 * Initional State
 */
const initialState = {
	campaigns: [],
	campaignTotalCount: 0,
	message: "",
	error: "",
	campaign: {},
	workflows: [],
	workflow: {},
	saveWorkflowForCampaign: {},
	templates: [],
	template: {},
	saveCampaign: {},
	deleteCampaign: {},
	saveTemplate: {},
	saveCampaignWorkflowConfig: {},
	loading: false,
	executeCampaign: {},
	executeCampaignError: {},
	cancelCampaign: {},
	cancelCampaignError: {},
	copyCampaign: {},
	copyCampaignError: {},
	pagination: {},
	/** --------------- NEW ------------- */
	campaignList_NEW: {
		data: [],
		totalCount: 0,
		page_count: 30,
		page_number: 1,
		isRequesting: false,
		isReceived: false,
		filters: {
			campaign_name: "",
			campaign_status: null, // all: default
			execution_state_c: null, // all: default
			ras_owner_id: null, // all: default
			ras_assigned_id: null, // all: default
		},
	},
	campaignListBySearch: null,
	campaignListByPerson: null,
	campaign_NEW: {},
	campaignAllList: {
		totalCount: 0,
		data: [],
	},
	campaignMainList_NEW: {
		totalCount: 0,
		pageCount: 30,
		pageNumber: 1,
		data: [],
		searchTerm: "",
		isRequesting: false,
		isReceived: false,
	},
	workflowItems: {
		totalCount: 0,
		page: 1,
		per_page: 30,
		data: [],
		workflow_main_id: 0,
		filter: "queue",
		states: null,
		isRequesting: false,
		isReceived: false,
		counts: null,
	},
	campaignPersonList: {
		totalCount: 0,
		page: 1,
		page_count: 30,
		data: [],
		search_term: "",
		filter: [],
		isRequesting: false,
		isReceived: false,
	},
	create: {
		jobs: {
			data: [],
			searchTerm: "",
		},
		persons: {
			data: [],
			searchTerm: "",
		},
		company: {
			data: [],
			searchTerm: "",
		},
	},
	workflowSummary: {},
	workflowNodeDetails: {},
	workflowTemplateList: [],
};

/**
 * Reducer
 */
const Reducer = (state = initialState, action) => {
	switch (action.type) {
		case actionTypes.GET_CAMPAIGNS:
			return getCampaigns(state, action.payload);

		case actionTypes.SET_CAMPAIGNS:
			return setCampaigns(state, action.payload);

		case actionTypes.GET_CAMPAIGN:
			return getCampaign(state, action.payload);

		case actionTypes.SET_CAMPAIGN:
			return setCampaign(state, action.payload);

		case actionTypes.SET_WORKFLOWS:
			return setWorkflows(state, action.payload);

		case actionTypes.SET_WORKFLOW:
			return setWorkflow(state, action.payload);

		case actionTypes.SAVE_WORKFLOW:
			return saveWorkflowForCampaign(state, action.payload);

		case actionTypes.GET_TEMPLATES:
			return setTemplates(state, action.payload);

		case actionTypes.SAVE_CAMPAIGNS:
			return saveCampaign(state, action.payload);

		case actionTypes.DELETE_CAMPAIGNS:
			return deleteCampaign(state, action.payload);

		case actionTypes.SAVE_TEMPLATE:
			return saveTemplate(state, action.payload);

		case actionTypes.GET_TEMPLATE:
			return setTemplate(state, action.payload);

		case actionTypes.SAVE_WORKFLOW_CONFIG:
			return saveCampaignWorkflowConfig(state, action.payload);

		case actionTypes.EXECUTE_CAMPAIGN_REQUEST:
			return executeCampaignRequest(state, action.payload);

		case actionTypes.EXECUTE_CAMPAIGN_SUCCESS:
			return executeCampaignSuccess(state, action.payload);

		case actionTypes.EXECUTE_CAMPAIGN_FAIL:
			return executeCampaignFail(state, action.payload);

		case actionTypes.CANCEL_CAMPAIGN_REQUEST:
			return cancelCampaignRequest(state, action.payload);

		case actionTypes.CANCEL_CAMPAIGN_SUCCESS:
			return cancelCampaignSuccess(state, action.payload);

		case actionTypes.CANCEL_CAMPAIGN_FAIL:
			return cancelCampaignFail(state, action.payload);

		case actionTypes.COPY_CAMPAIGN_FAIL:
			return copyCampaignFail(state, action.payload);

		case actionTypes.COPY_CAMPAIGN_REQUEST:
			return copyCampaignRequest(state);

		case actionTypes.COPY_CAMPAIGN_SUCCESS:
			return copyCampaignSuccess(state, action.payload);

		/* ----------------------- NEW ------------------------------------------ */
		case actionTypes.REQUEST_CAMPAIGN_LIST_NEW:
			return requestCampaignList_NEW(state);

		case actionTypes.RECEIVED_CAMPAIGN_LIST_NEW:
			return receivedCampaignList_NEW(state);

		case actionTypes.SET_CAMPAIGN_LIST_NEW:
			return setCampaignList_NEW(state, action.payload);

		case actionTypes.SET_CAMPAIGN_LIST_PARAMS:
			return setCampaignListParams(state, action.payload);

		case actionTypes.SET_CAMPAIGN_DETAIL_NEW:
			return setCampaignDetail_NEW(state, action.payload);

		// Campaign Main Table
		case actionTypes.SET_ALL_CAMPAIGN_MAINLIST:
			return setAllCampaignMainList(state, action.payload);

		case actionTypes.SET_CAMPAIGN_MAINLIST_NEW:
			return setCampaignMainList_NEW(state, action.payload);

		case actionTypes.REQUEST_CAMPAIGN_MAINLIST_NEW:
			return requestCampaignMainList_NEW(state);

		case actionTypes.RECEIVED_CAMPAIGN_MAINLIST_NEW:
			return receivedCampaignMainList_NEW(state);

		// Workflow Items Table for a campaign
		case actionTypes.SET_CAMPAIGN_WORKFLOW_ITEMS_PARAMS:
			return setCampaignWorkflowItemsListParams(state, action.payload);

		case actionTypes.SET_CAMPAIGN_WORKFLOW_ITEMS_LIST:
			return setCampaignWorkflowItemsList(state, action.payload);

		case actionTypes.REQUEST_CAMPAIGN_WORKFLOW_ITEMS_LIST:
			return requestCampaignWorkflowItemsList(state);

		case actionTypes.RECEIVED_CAMPAIGN_WORKFLOW_ITEMS_LIST:
			return receivedCampaignWorkflowItemsList(state);
		case actionTypes.SET_WORKFLOW_ITEM_COUNTS:
			return setWorkflowItemCounts(state, action.payload);

		// Person Table for a campaign
		case actionTypes.SET_CAMPAIGN_PERSON_LIST_PARAMS:
			return setCampaignPersonListParams(state, action.payload);

		case actionTypes.SET_CAMPAIGN_PERSON_LIST:
			return setCampaignPersonList(state, action.payload);

		case actionTypes.REQUEST_CAMPAIGN_PERSON_LIST:
			return requestCampaignPersonList(state);

		case actionTypes.RECEIVED_CAMPAIGN_PERSON_LIST:
			return receivedCampaignPersonList(state);

		case actionTypes.SET_CAMPAIGN_CREATE_JOB_LIST:
			return setJobListForCreateCampaign(state, action.payload);

		// Workflow Summary
		case actionTypes.SET_WORKFLOW_SUMMARY:
			return setWorkflowSummary(state, action.payload);
		// Workflow Node Details
		case actionTypes.SET_WORKFLOW_NODE_DETAILS:
			return setWorkflowNodeDetails(state, action.payload);

		// Workflow Template
		case actionTypes.SET_WORKFLOW_TEMPLATE:
			return setWorkflowTemplate(state, action.payload);
		case actionTypes.SET_WORKFLOW_TEMPLATE_LIST:
			return setWorkflowTemplateList(state, action.payload);

		// campaign list by search and person
		case actionTypes.CAMPAIGN_LIST_BY_SEARCH:
			return setCampaignListBySearch(state, action.payload);
		case actionTypes.CAMPAIGN_LIST_BY_PERSON:
			return setCampaignListByPerson(state, action.payload);
		/* ---------------------------------------------------------------------- */

		default:
			return state;
	}
};

export default Reducer;
