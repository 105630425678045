import * as actionTypes from "./actionTypes";

/**
 * Functions
 */

function setSelectPersonTitles(state, data) {
	console.log({ data });
	return {
		...state,
		selectedTitles: data,
	};
}

function setSelectPersonIndustries(state, data) {
	console.log({ data });
	return {
		...state,
		selectedIndustries: data,
	};
}

function setSelectPersonSkills(state, data) {
	return {
		...state,
		selectedSkills: data,
	};
}

function setSit(state, data) {
	const sitType = data.type;

	alert(JSON.stringify("la la la la: " + { data }));

	if (sitType === "skill") {
		return {
			...state,
			skill: data.data,
		};
	}

	if (sitType === "industry") {
		return {
			...state,
			industry: data.data,
		};
	}

	if (sitType === "title") {
		return {
			...state,
			title: data.data,
		};
	}

	// Default.
	return {
		...state,
	};
}

function setPersonFlyoutVisibility(state, data) {
	return {
		...state,
		flyoutVisibility: !state.flyoutVisibility,
		flyoutMode: data,
	};
}

const setFetchPeopleLoading = (state, payload) => {
	return {
		...state,
		loading: payload,
	};
};

const setPersonDetail = (state, payload) => {
	return {
		...state,
		person: {
			...state.person,
			details: payload,
		},
	};
};

const setPersonRASRestriction = (state, payload) => {
	return {
		...state,
		person: {
			...state.person,
			restriction: payload,
		},
	};
};

const setPersonRAS = (state, payload) => {
	return {
		...state,
		person: {
			...state.person,
			ras: payload,
		},
	};
};

const setPersons = (state, payload) => {
	return {
		...state,
		persons: payload.data,
		pagination: {
			...state.pagination,
			totalCount: payload.totalCount,
			currentPage: payload.page,
			perPage: payload.perPage,
		},
		personList: {
			...state.personList,
			totalCount: payload.totalCount,
			page_number: payload.page,
			page_count: payload.perPage,
			filter: payload.filter,
		},
	};
};

function bulkUpdatePersonSIT(state, data) {
	console.log({ data });
	return {
		...state,
		bulkUpdatePersonSIT: data,
	};
}

const setPersonSearchValue = (state, payload) => {
	return {
		...state,
		personSearchValue: payload,
	};
};

const setPersonEnrichmentDetails = (state, payload) => {
	return {
		...state,
		person: {
			...state.person,
			enrichmentDetails: payload,
		},
	};
};

/** ---------- NEW ------------ */
const setPersonDetail_NEW = (state, payload) => {
	return {
		...state,
		person_NEW: payload,
	};
};

const setPersonSelectedFile_NEW = (state, payload) => {
	return {
		...state,
		personDetailSelectedFile: payload,
	};
};

// Person Main Table
const requestPersonMainList_NEW = (state) => {
	return {
		...state,
		personMainList_NEW: {
			...state.personMainList_NEW,
			isRequesting: true,
			isReceived: false,
		},
	};
};

const receivedPersonMainList_NEW = (state) => {
	return {
		...state,
		personMainList_NEW: {
			...state.personMainList_NEW,
			isRequesting: false,
			isReceived: true,
		},
	};
};

const setPersonMainList_NEW = (state, payload) => {
	return {
		...state,
		personMainList_NEW: {
			...state.personMainList_NEW,
			...payload,
			isRequesting: false,
			isReceived: true,
		},
	};
};

const setOpenPersonListFilter = (state, payload) => {
	return {
		...state,
		isOpenListFilter: payload,
	};
};

// Person AMS Table
const requestPersonAmsList = (state) => {
	return {
		...state,
		personAmsList: {
			...state.personAmsList,
			isRequesting: true,
			isReceived: false,
		},
	};
};

const receivedPersonAmsList = (state) => {
	return {
		...state,
		personAmsList: {
			...state.personAmsList,
			isRequesting: false,
			isReceived: true,
		},
	};
};

const setPersonAmsList = (state, payload) => {
	return {
		...state,
		personAmsList: {
			...state.personAmsList,
			...payload,
			isRequesting: false,
			isReceived: true,
		},
	};
};

const setPersonAmsListParams = (state, payload) => {
	return {
		...state,
		personAmsList: {
			...state.personAmsList,
			...payload,
		},
	};
};

// Person CMS Table
const requestPersonCmsList = (state) => {
	return {
		...state,
		personCmsList: {
			...state.personCmsList,
			isRequesting: true,
			isReceived: false,
		},
	};
};

const receivedPersonCmsList = (state) => {
	return {
		...state,
		personCmsList: {
			...state.personCmsList,
			isRequesting: false,
			isReceived: true,
		},
	};
};

const setPersonCmsList = (state, payload) => {
	return {
		...state,
		personCmsList: {
			...state.personCmsList,
			...payload,
			isRequesting: false,
			isReceived: true,
		},
	};
};

const setPersonCmsListParams = (state, payload) => {
	return {
		...state,
		personCmsList: {
			...state.personCmsList,
			...payload,
		},
	};
};

// Person CMS Draft Table
const requestPersonCmsDraftList = (state) => {
	return {
		...state,
		personCmsDraftList: {
			...state.personCmsDraftList,
			isRequesting: true,
			isReceived: false,
		},
	};
};

const receivedPersonCmsDraftList = (state) => {
	return {
		...state,
		personCmsDraftList: {
			...state.personCmsDraftList,
			isRequesting: false,
			isReceived: true,
		},
	};
};

const setPersonCmsDraftList = (state, payload) => {
	return {
		...state,
		personCmsDraftList: {
			...state.personCmsDraftList,
			...payload,
			isRequesting: false,
			isReceived: true,
		},
	};
};

const setPersonCmsDraftListParams = (state, payload) => {
	return {
		...state,
		personCmsDraftList: {
			...state.personCmsDraftList,
			...payload,
		},
	};
};

// Person Flyout
const setPersonDetailForFlyout = (state, payload) => {
	return {
		...state,
		personFlyout: {
			...state.personFlyout,
			...payload,
		},
	};
};

// Person All Ids
const setPersonAllIds = (state, payload) => {
	return {
		...state,
		ids: payload,
	};
};
/** --------------------------- */

/**
 * Initial State
 */
const initialState = {
	persons: [],
	personList: {
		totalCount: 0,
		page_count: 30,
		page_number: 1,
		searchTerm: "",
		isRequesting: false,
		isReceived: false,
		data: [],
		filter: {
			name: null,
			ras_owner: null,
			ras_assigned: null,
			radius_zip_codes: null,
			credential_LI_connection_degree: null,
			association_sit: null,
			association_qualifier: null,
			date_created_on: null,
			date_created_after: null,
			date_created_before: null,
			work_history_title: null,
			work_history_company: null,
		},
	},
	person: {
		details: {},
		ras: {},
		restriction: {},
		skills: {},
		industry: {},
		title: {},
		enrichmentDetails: [],
	},
	flyoutVisibility: false,
	flyoutMode: null,

	// Lists
	skill: [],
	industry: [],
	title: [],

	// Selected lists
	selectedSkills: [],
	selectedIndustries: [],
	selectedTitles: [],

	loading: false,
	pagination: {
		totalCount: 0,
		pages: [],
		entriesPerPage: [30, 50, 100, 200],
		currentPage: 1,
		perPage: 30,
	},
	bulkUpdateSIT: {},
	personSearchValue: "",

	/* ------------------ NEW ---------------- */
	personMainList_NEW: {
		totalCount: 0,
		pageCount: 30,
		pageNumber: 1,
		searchTerm: "",
		isRequesting: false,
		isReceived: false,
		data: [],
	},

	personAmsList: {
		totalCount: 0,
		pageCount: 3,
		pageNumber: 1,
		isRequesting: false,
		isReceived: false,
		data: [],
		filters: {
			amsTaskName: "",
			amsActivityName: "",
			amsAssignedPersonId: null,
		},
	},

	personCmsList: {
		totalCount: 0,
		pageCount: 5,
		pageNumber: 1,
		isRequesting: false,
		isReceived: false,
		data: [],
		filters: {},
	},

	personCmsDraftList: {
		totalCount: 0,
		pageCount: 5,
		pageNumber: 1,
		isRequesting: false,
		isReceived: false,
		data: [],
		filters: {},
	},

	// selected file
	personDetailSelectedFile: null,

	// Person Flyout
	personFlyout: null,

	// Open status of Filter for Person List
	isOpenListFilter: false,

	ids: null,
};

/**
 * Reducer
 */
const Reducer = (state = initialState, action) => {
	switch (action.type) {
		case actionTypes.SET_PERSON_RAS_RESTRICTIONS:
			return setPersonRASRestriction(state, action.payload);

		case actionTypes.SET_PERSON_DETAIL:
			return setPersonDetail(state, action.payload);

		case actionTypes.SET_PERSON_RAS:
			return setPersonRAS(state, action.payload);

		case actionTypes.SET_PERSON_LIST:
			return setPersons(state, action.payload);

		case actionTypes.SET_FETCH_PERSON_LOADING:
			return setFetchPeopleLoading(state, action.payload);

		case actionTypes.SET_PERSON_FLYOUT_VISIBILITY:
			return setPersonFlyoutVisibility(state, action.payload);

		case actionTypes.SET_SIT:
			return setSit(state, action.payload);

		case actionTypes.SET_SELECTED_PERSON_SKILLS:
			return setSelectPersonSkills(state, action.payload);

		case actionTypes.SET_SELECTED_PERSON_INDUSTRIES:
			return setSelectPersonIndustries(state, action.payload);

		case actionTypes.SET_SELECTED_PERSON_TITLES:
			return setSelectPersonTitles(state, action.payload);

		case actionTypes.BULK_UPDATE_PERSON_SIT:
			return bulkUpdatePersonSIT(state, action.payload);

		case actionTypes.SET_PERSON_SEARCH_VALUE:
			return setPersonSearchValue(state, action.payload);

		case actionTypes.SET_PERSON_ENRICHMENT_DETAILS:
			return setPersonEnrichmentDetails(state, action.payload);

		/** --------------- NEW --------------- */
		case actionTypes.SET_PERSON_DETAIL_NEW:
			return setPersonDetail_NEW(state, action.payload);

		case actionTypes.SET_PERSON_FILE_VALUE:
			return setPersonSelectedFile_NEW(state, action.payload);

		/* ------- Person Main Table ------- */
		case actionTypes.SET_PERSON_MAINLIST_NEW:
			return setPersonMainList_NEW(state, action.payload);

		case actionTypes.REQUEST_PERSON_MAINLIST_NEW:
			return requestPersonMainList_NEW(state);

		case actionTypes.RECEIVED_PERSON_MAINLIST_NEW:
			return receivedPersonMainList_NEW(state);

		case actionTypes.SET_OPEN_PERSON_LIST_FILTER:
			return setOpenPersonListFilter(state, action.payload);
		/** ------------------------------------*/

		/* ------- Person AMS Table ------- */
		case actionTypes.SET_PERSON_AMS_LIST:
			return setPersonAmsList(state, action.payload);

		case actionTypes.REQUEST_PERSON_AMS_LIST:
			return requestPersonAmsList(state);

		case actionTypes.RECEIVED_PERSON_AMS_LIST:
			return receivedPersonAmsList(state);

		case actionTypes.SET_PERSON_AMS_LIST_PARAMS:
			return setPersonAmsListParams(state, action.payload);

		/* ------- Person CMS Table ------- */
		case actionTypes.SET_PERSON_CMS_LIST:
			return setPersonCmsList(state, action.payload);

		case actionTypes.REQUEST_PERSON_CMS_LIST:
			return requestPersonCmsList(state);

		case actionTypes.RECEIVED_PERSON_CMS_LIST:
			return receivedPersonCmsList(state);

		case actionTypes.SET_PERSON_CMS_LIST_PARAMS:
			return setPersonCmsListParams(state, action.payload);

		/* ------- Person CMS Draft Table ------- */
		case actionTypes.SET_PERSON_CMS_DRAFT_LIST:
			return setPersonCmsDraftList(state, action.payload);

		case actionTypes.REQUEST_PERSON_CMS_DRAFT_LIST:
			return requestPersonCmsDraftList(state);

		case actionTypes.RECEIVED_PERSON_CMS_DRAFT_LIST:
			return receivedPersonCmsDraftList(state);

		case actionTypes.SET_PERSON_CMS_DRAFT_LIST_PARAMS:
			return setPersonCmsDraftListParams(state, action.payload);
		/** ------------------------------------*/

		/* ------- Person Flyout ------- */
		case actionTypes.SET_PERSON_DETAIL_FLYOUT:
			return setPersonDetailForFlyout(state, action.payload);

		/* ------- Person All Ids ------- */
		case actionTypes.PERSON_ALL_IDS:
			return setPersonAllIds(state, action.payload);

		default:
			return state;
	}
};

export default Reducer;
