import API from "api";
import { isEmpty, isNil, isNull, isUndefined, omitBy } from "lodash";

/** ---------------- Generic API ---------------------- */
const updateCms = (data) => {
	const request = {
		object: "cms",
		data: data || {},
	};

	return API().GenericAPI().updateTableData(request);
};

/** --------------------------------------------------------- */

/** ----------------------- Custom API ---------------------- */
const getCommunicationList = (params) => {
	const cmsConversationMain = omitBy(
		{
			conversation_type_c:
				params?.cms_conversation_main?.conversation_type_c ?? null,
			owner_account_main_ref_id:
				params?.cms_conversation_main?.owner_account_main_ref_id ?? null,
		},
		isNil
	);

	const cmsConversationMemeber = omitBy(
		{
			member_object_type_sc:
				params?.cms_conversation_member?.member_object_type ?? null,
			member_object_subtype_sc:
				params?.cms_conversation_member?.member_object_subtype ?? null,
			member_object_record_id:
				params?.cms_conversation_member?.member_object_record_id ?? null,
			member_communication_key:
				params?.cms_conversation_member?.member_communication_key ?? null,
		},
		isNil
	);

	const cmsCommunicationMain = omitBy(
		{
			date_created: params?.cms_communication_main?.date_created ?? null,
			comm_id_external: params?.cms_communication_main?.comm_id_external ?? null,
			comm_platform_c: params?.cms_communication_main?.comm_platform_c ?? null,
			comm_direction_c: params?.cms_communication_main?.comm_direction_c ?? null,
			search_text: params?.cms_communication_main?.search_text ?? null,
		},
		isNil
	);

	const query = {
		page_number: params?.page_number || 0,
		page_count: params?.page_count || 30,
		...(isEmpty(cmsConversationMain)
			? {}
			: { cms_conversation_main: cmsConversationMain }),
		...(isEmpty(cmsConversationMemeber)
			? {}
			: { cms_conversation_member: cmsConversationMemeber }),
		...(isEmpty(cmsCommunicationMain)
			? {}
			: { cms_communication_main: cmsCommunicationMain }),
	};

	return API().Cms().getCmsCommunicationList(query);
};

const getCommunicationDraftList = (params) => {
	const fitlers = omitBy(
		{
			account_main_id: params?.account_main_id ?? null,
			credental_main_id: params?.credental_main_id ?? null,
			member_object_type_sc: params?.member_object_type_sc ?? null,
			member_object_subtype_sc: params?.member_object_subtype_sc ?? null,
			member_object_record_id: params?.member_object_record_id ?? null,
		},
		isNil
	);

	const query = {
		page_number: params?.page_number || 0,
		page_count: params?.page_count || 30,
		...fitlers,
	};

	return API().Cms().getCmsCommunicationDraftList(query);
};
/* ---------------------------------------------------------- */

export default {
	updateCms,
	getCommunicationList,
	getCommunicationDraftList,
};
