import {
	checkMatchGlobalRecords,
	fetchAllGlobals,
	getGSSList_NEW,
} from "helpers/actions";
import { _getGlobals, _getGSSList } from "helpers/selectors";
import { useDispatch } from "react-redux";

const useGlobal = () => {
	const dispatch = useDispatch();

	const globals = _getGlobals();
	const gssList = _getGSSList();

	return {
		globals,
		gssList,

		getGlobals: () => {
			dispatch(fetchAllGlobals());
		},

		getGSSLists: () => {
			dispatch(getGSSList_NEW());
		},

		getMatchGlobalRecords: (params, successCallBack, failCallBack) => {
			dispatch(checkMatchGlobalRecords(params, successCallBack, failCallBack));
		},
	};
};

export default useGlobal;
