import React, { useState } from "react";
import { faGlobe } from "@fortawesome/pro-light-svg-icons/faGlobe";
import _ from "lodash";

import { AsyncEditableContent } from "components/Common/asyncComponents";

/**
 * Constants
 */
const TRACKING_KEY = "person_history_work_id";
const DISPLAY_KEY = "company_name";

/**
 * Main Components for Person Company Editor
 */
const PersonCompany = (props) => {
	const { personId, data, syncData, loadData, isSingleEntry } = props;

	const [loading, setLoading] = useState(false);

	const onAddHandler = (editedData, setEditState, addedItemNewIndex, setAddedItemNewIndex) => {
		if (
			!editedData ||
			!setEditState ||
			addedItemNewIndex === undefined ||
			addedItemNewIndex === null ||
			setAddedItemNewIndex === undefined ||
			setAddedItemNewIndex === null
		)
			return;

		const newData = editedData.length > 0 ? _.cloneDeep(editedData) : [];
		const newId = "new__" + addedItemNewIndex;

		newData.push({
			[TRACKING_KEY]: newId,
			[DISPLAY_KEY]: "",
		});

		setEditState(newData);
		setAddedItemNewIndex(addedItemNewIndex + 1);
	};

	const onSaveHandler = (editedData, data) => {
		if (!editedData || !data) return;

		/**
		 * Determine edited external links.
		 */

		// Do updates (Check similar ids and if their display values are different, then push them into update container)
		const editedItems = {};

		for (const [dataKey, dataValue] of Object.entries(data)) {
			if (!dataValue) return;

			for (const [editKey, editValue] of Object.entries(editedData)) {
				if (!editValue) return;

				const {
					person_work_history_id: personWorkHistoryId,
					[DISPLAY_KEY]: workTitleValue,
				} = dataValue;

				const {
					person_work_history_id: editedPersonWorkHistoryId,
					[DISPLAY_KEY]: editedWorkTitleValue,
				} = editValue;

				const editedDataId = editValue[TRACKING_KEY];

				if (personWorkHistoryId === editedPersonWorkHistoryId) {
					if (workTitleValue !== editedWorkTitleValue) {
						let filterList = ["person_work_history_id", "person_main_ref_id", [DISPLAY_KEY]];

						let filteredEditedData = {
							...editValue,
						};

						// Hack: Due to some PK inconsistencies I will manually do some data transformations
						// This PK expected from this route is "person_work_history_id", and not "person_history_work_id"
						filteredEditedData["person_work_history_id"] = editValue[TRACKING_KEY];

						for (let i in filteredEditedData) {
							if (!filterList.includes(i)) {
								delete filteredEditedData[i];
							}
						}

						editedItems[editedDataId] = {
							...filteredEditedData,
							[DISPLAY_KEY]: editedWorkTitleValue,
						};
					}
				}
			}
		}

		/**
		 * Do insert (pending).
		 */
		const addedItems = [];

		for (const editKey in editedData) {
			const id = editedData[editKey][TRACKING_KEY];

			// Push all of it
			if (id.toString().search(/new__/) !== -1) {
				const newValue = editedData[editKey][DISPLAY_KEY];

				if (newValue !== "") {
					const addedData = _.cloneDeep(editedData[editKey]);

					addedItems.push({
						...addedData,
						person_main_ref_id: personId,
					});
				}
			}
		}

		// Prep added items
		for (const addItemKey in addedItems) {
			delete addedItems[addItemKey][TRACKING_KEY];
		}

		if (_.size(editedItems) !== 0 || _.size(addedItems) !== 0) {
			const newEditedItems = [];

			for (const editKey in editedItems) {
				newEditedItems.push({
					args: editedItems[editKey],
				});
			}

			syncData([], newEditedItems, [], [], setLoading, loadData);
		}
	};

	return (
		<AsyncEditableContent
			isLoading={loading}
			icon={faGlobe}
			data={data}
			placeholder={"Employment Company"}
			editTrackingKey={TRACKING_KEY}
			editDisplayKey={DISPLAY_KEY}
			onAddHandler={onAddHandler}
			isSingleEntry={isSingleEntry || false}
			onSaveHandler={onSaveHandler}
			render={(data) =>
				data?.map((workHistory, id) => {
					return (
						<div key={`person-company-${workHistory?.[TRACKING_KEY]}-${id}`}>
							{workHistory?.[DISPLAY_KEY]}
						</div>
					);
				})
			}
		/>
	);
};

export default PersonCompany;
