import { SET_AUTH_INFO } from "./actionTypes";

/**
 * Functions
 */
const setAuthInfo = (state, payload) => {
	return {
		...state,
		auth: payload,
	};
};

/**
 * Initial State
 */
const initialState = {
	auth: {
		username: "",
		email: "",
		token: "",
	},
};

const Reducer = (state = initialState, action) => {
	switch (action.type) {
		case SET_AUTH_INFO:
			return setAuthInfo(state, action.payload);

		default:
			return state;
	}
};

export default Reducer;
