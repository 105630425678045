import React, { useEffect, useState } from "react";
import { EuiFormRow } from "@elastic/eui";
import {
	cloneDeep,
	difference,
	get,
	intersection,
	intersectionWith,
	keyBy,
	set,
} from "lodash";
import styled from "styled-components";
import { getTreeFromFlatData, toggleExpandedForAll } from "react-sortable-tree";
import DropdownTreeSelect from "react-dropdown-tree-select";
import "react-dropdown-tree-select/dist/styles.css";

import { getAddDeleteListFromRecords, getGCSValue } from "components/global/utils";
import { SaveCancelBtn } from "components/Common";
import * as MODEL from "core/model";

/**
 * Component for Editable Qaulifier
 */
const EditableQaulifier = (props) => {
	const { qualifiers, qualifierList, associationType, globals, onCancel, onSave } =
		props;

	// variables
	let selectedQualifiers = [];

	const [optionsQualifierTree, setOptionsQualifierTree] = useState([]);

	useEffect(() => {
		const options = [];
		const optionsInit = [];
		const associationTypeC = getGCSValue(
			globals,
			"qualifier_association",
			"association_type_c",
			associationType
		);

		const hashQualifiers = keyBy(qualifierList, "id");

		// Search parent node without null
		const getAssociationTypeC = (qualifier) => {
			const { qualifier_association } = qualifier;
			const association = qualifier_association?.find(
				(item) => item.association_type_c === associationTypeC
			);

			// If the current qualifier has any association.
			if (qualifier_association?.length > 0) {
				return association;
			}

			// If the current qualifier doesn't have any association.
			const parentQualifierId =
				qualifier?.[MODEL.qualifier_main.qualifier_parent_id];
			if (parentQualifierId === null) {
				// if root node
				return null;
			} else {
				const parentQualifier = hashQualifiers[parentQualifierId];
				return getAssociationTypeC(parentQualifier);
			}
		};

		if (qualifierList?.length > 0 && globals?.length > 0) {
			qualifierList.forEach((qualifier) => {
				const { id, qualifier_name } = qualifier;

				const association = getAssociationTypeC(qualifier);
				const inputQualifier = qualifiers?.find(
					(item) => item?.[MODEL.qualifier_main._name].id === id
				);

				if (id && association) {
					const optionCount = options.length;
					const initNode = {
						label: qualifier_name,
						id: id,
						value: id,
						index: optionCount,
						qualifier_parent_id: qualifier?.qualifier_parent_id,
						checked: false,
					};
					const node = {
						...initNode,
						checked: inputQualifier ? true : false,
					};
					optionsInit.push(initNode);
					options.push(node);
				}
			});
		}

		const optionTree = toggleExpandedForAll({
			treeData: getTreeFromFlatData({
				flatData: options,
				getKey: (node) => node.id, // resolve a node's key
				getParentKey: (node) =>
					node.qualifier_parent_id === 0 ? null : node.qualifier_parent_id, // resolve a node's parent's key
				rootKey: null, // The value of the parent key when there is no parent (i.e., at root level)
			}),
		});

		const optionTreeInit = toggleExpandedForAll({
			treeData: getTreeFromFlatData({
				flatData: optionsInit,
				getKey: (node) => node.id, // resolve a node's key
				getParentKey: (node) =>
					node.qualifier_parent_id === 0 ? null : node.qualifier_parent_id, // resolve a node's parent's key
				rootKey: null, // The value of the parent key when there is no parent (i.e., at root level)
			}),
		});

		const assignObjectPaths = (obj, stack) => {
			Object.keys(obj).forEach((k) => {
				const node = obj[k];
				if (node && typeof node === "object") {
					node.path = stack ? `${stack}.${k}` : `${k}`;
					assignObjectPaths(node, node.path);
				}
			});
		};

		assignObjectPaths(optionTreeInit);
		assignObjectPaths(optionTree);

		optionTree.forEach((parentItem) => {
			const children = get(parentItem, "children");
			if (children) {
				children.forEach((item) => {
					item.checked = parentItem.checked ? parentItem.checked : item.checked;
				});
			}
		});

		setOptionsQualifierTree(optionTree);
	}, [qualifierList, globals, associationType, qualifiers]);

	const onQualifierTreeChange = (currentNode, selectedNodes) => {
		selectedQualifiers = selectedNodes;
	};

	const onSaveHandler = () => {
		// Procss qualifier information
		const seletedQualifierAccountIds = selectedQualifiers.map(
			(qualifier) => qualifier.id
		);
		const hashedQualifiers = {};
		if (qualifiers?.length > 0) {
			qualifiers.forEach((item) => {
				const qualifierId = get(item, "qualifier_main.id", "");
				const qualifierName = get(item, "qualifier_main.qualifier_name", "");
				hashedQualifiers[qualifierId] = {
					id: item.id,
					value: qualifierName,
				};
			});
		}
		const oldQualifierIds =
			qualifiers?.map((item) => {
				const qualifierId = get(item, "qualifier_main.id", "");

				return qualifierId;
			}) || [];
		const { deleteIds: deleteQualifierIds, addIds: addQualifierIds } =
			getAddDeleteListFromRecords(
				oldQualifierIds,
				seletedQualifierAccountIds,
				hashedQualifiers
			);

		onSave({
			deleteQualifierIds,
			addQualifierIds,
		});
	};

	return (
		<Wrapper>
			<EuiFormRow fullWidth label="Qualifier" className="selet-item--wrapper">
				<DropdownTreeSelect
					data={optionsQualifierTree}
					onChange={onQualifierTreeChange}
					mode="multiSelect"
				/>
			</EuiFormRow>

			<SaveCancelBtn onChangeCancel={onCancel} onChangeSave={onSaveHandler} />
		</Wrapper>
	);
};

export default EditableQaulifier;

const Wrapper = styled.div`
	width: 200px;

	.selet-item--wrapper {
		margin-bottom: 1rem;
	}
`;
