import React from "react";
import styled from "styled-components";

import { SVGIcon } from "components/Common";
import { mapMarkerAltIcon, checkIcon } from "components/Common/Icons";

import StaticItemBase from "../StaticItemBase";
import AddressSettingBar from "./AddressSettingBar";

/**
 * Main Components
 */
const StaticAddressItem = (props) => {
	const { address, hasIcon = true, isPrimary, onEdit, onDelete } = props;

	const addressInfo = address?.global_address_details || "";

	const getCombinedFields = (data, field, field2) => {
		if (data[field] && data[field2]) {
			return data[field] + " " + data[field2];
		} else if (data[field]) {
			return data[field];
		} else {
			return data[field2];
		}
	};

	if (!address) {
		return null;
	}

	return (
		<Wrapper>
			<StaticItemBase
				extraSetting={<AddressSettingBar onEdit={onEdit} onDelete={onDelete} />}
			>
				{hasIcon ? <SVGIcon icon={mapMarkerAltIcon} className="mr-2" /> : null}
				<StaticAddressWrapper>
					<div className="address">
						{addressInfo?.address_line_1 && (
							<div className="address-entry">
								{addressInfo?.address_line_1}
							</div>
						)}
						{addressInfo?.address_line_2 && (
							<div className="address-entry">
								{addressInfo?.address_line_2}
							</div>
						)}
						{addressInfo?.address_city && (
							<div className="address-entry">
								{addressInfo?.address_city}
							</div>
						)}
						{addressInfo?.address_state && (
							<div className="address-entry">
								{addressInfo?.address_state}
							</div>
						)}
						{(addressInfo?.address_zip || addressInfo?.address_country) && (
							<div className="address-entry">
								{getCombinedFields(
									address,
									"address_zip",
									"address_country"
								)}
							</div>
						)}
					</div>
					{isPrimary && <div className="check-icon">{checkIcon()}</div>}
				</StaticAddressWrapper>
			</StaticItemBase>
		</Wrapper>
	);
};

export default StaticAddressItem;

/**
 * Styled Components
 */
const Wrapper = styled.div`
	display: flex;

	.index {
		font-weight: 700;
		padding-right: 1rem;
	}
`;

const StaticAddressWrapper = styled.div`
	display: flex;
	flex-direction: row;

	.index {
		font-weight: 700;
		padding-right: 1rem;
	}

	.address {
		.address-entry {
			text-align: left;
		}
	}

	.check-icon {
		margin-left: 0.5rem;
	}
`;
