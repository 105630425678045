import * as actionTypes from "./actionTypes";

/**
 * Initial State
 */
const initialState = {};

const Reducer = (state = initialState, action) => {
	switch (action.type) {
		default:
			return state;
	}
};

export default Reducer;
