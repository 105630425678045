import * as actionTypes from "./actionTypes";

/**
 * Functions
 */

/**
 * Initial State
 */
const initialState = {
	themeList: null,
};

/**
 * Reducer
 */
const Reducer = (state = initialState, action) => {
	switch (action.type) {
		default:
			return state;
	}
};

export default Reducer;
