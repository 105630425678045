import React, { useState } from "react";
import styled from "styled-components";

import EditableItem from "../EditableItem";
import StaticPhoneItem from "./StaticPhoneItem";
import EditPhoneItem from "./EditPhoneItem";

/**
 * Component for Phone Editable Item
 */
const EditablePhone = (props) => {
	const {
		personId = null,
		phone,
		isPrimary,
		onSave,
		onDelete,
		onCallPhone,
		hasIcon = true,
		isFixedType = false,
		fixedType,
	} = props;

	const [editState, setEditState] = useState(false);

	const handleSave = (data, primary) => {
		setEditState(false);
		onSave(data, primary);
	};

	const handleCancel = () => {
		setEditState(false);
	};

	const handleEdit = () => {
		setEditState(true);
	};

	const handleDelete = () => {
		onDelete(phone?.id);
	};

	const handleValidate = () => {};

	const handleChangeStatus = (newStatus) => {
		const newPhone = {
			...phone,
			global_phone_details: {
				...phone?.global_phone_details,
				phone_status_c: newStatus,
			},
		};

		handleSave(newPhone, isPrimary);
	};

	const handleCallPhone = () => {
		onCallPhone(phone);
	};

	if (!phone) {
		return null;
	}

	return (
		<Wrapper>
			<EditableItem
				editState={editState}
				renderStaticState={
					<StaticPhoneItem
						personId={personId}
						phone={phone}
						isPrimary={isPrimary}
						hasIcon={hasIcon}
						onEdit={handleEdit}
						onDelete={handleDelete}
						onValidate={handleValidate}
						onChangeStatus={handleChangeStatus}
						onCallPhone={handleCallPhone}
					/>
				}
				renderEditState={
					<EditPhoneItem
						phone={phone}
						phonePrimary={isPrimary}
						onSave={handleSave}
						onCancel={handleCancel}
						isFixedType={isFixedType}
						fixedType={fixedType}
					/>
				}
			/>
		</Wrapper>
	);
};

export default EditablePhone;

const Wrapper = styled.div``;
