import React from "react";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faExclamationTriangle } from "@fortawesome/pro-regular-svg-icons";
import { faCheckCircle } from "@fortawesome/pro-light-svg-icons";

export const PHONE_STATUS_OPTIONS = [
	{
		value: 1,
		label: "Valid",
		icon: <FontAwesomeIcon icon={faCheckCircle} color={"var(--success-color)"} />,
	},
	{
		value: 2,
		label: "Disconnected",
		icon: (
			<FontAwesomeIcon icon={faExclamationTriangle} color={"var(--border-color)"} />
		),
	},
	{
		value: 3,
		label: "Wrong Person",
		icon: (
			<FontAwesomeIcon
				icon={faExclamationTriangle}
				color={"var(--warning-color)"}
			/>
		),
	},
	{
		value: 4,
		label: "Invalid",
		icon: (
			<FontAwesomeIcon icon={faExclamationTriangle} color={"var(--danger-color)"} />
		),
	},
	{
		value: 5,
		label: "Opt-out",
		icon: (
			<FontAwesomeIcon icon={faExclamationTriangle} color={"var(--border-color)"} />
		),
	},
];
