import { useMemo } from "react";
import { useDispatch } from "react-redux";
import { uniqBy } from "lodash";

import {
    getCompanyMainList_NEW,
    getPersonMainList_NEW,
    getPersonDetail_NEW,
    getCompanyDetail_NEW,
    updateCompany_NEW,
    updatePerson_NEW,
    deletePerson_NEW,
    setPersonSelectedFile_NEW,
    deletePersonSITs_NEW,
    addPersonSITs_NEW,
    getSitListByKeyword,
    updateRAS_NEW,
    deleteRAS_NEW,
    updateQualifier_NEW,
    deleteQualifier,
    getPersonAmsList,
    updateAms,
    deleteAms,
    getJobList,
    updateJobDetail_NEW,
    getJobApplicationsForPerson,
    getJobContactsForPerson,
    setPersonAmsListParams,
    fetchAllPersons,
    getGlobalSearchItems,
} from "helpers/actions";
import {
    company_main,
    global_address_details,
    person_address,
    person_history_work,
    person_attachment,
    file_main,
    person_primary_setting,
    person_sit,
} from "core/model";
import {
    _getPersonDetails_NEW,
    _getPersonDetailSelectedFile_NEW,
    _getSITs,
    _getPersonAmsList,
    _getJobList_NEW,
    _getJobApplicationsForPerson,
    _getJobContactsForPerson,
    _getCompanyMainList_NEW,
    _getGlobalSearchItems,
    _getPersonCmsAutomatedList,
    _getPersonCmsManualList,
} from "helpers/selectors";
import { getGCSValue } from "components/global/utils";
import * as MODEL from "core/model";
import { useGlobal } from "core/useHooks";

const usePerson = () => {
    const dispatch = useDispatch();

    const { globals } = useGlobal();

    const allSits = _getSITs();
    const personDetails = _getPersonDetails_NEW();
    const personFile = _getPersonDetailSelectedFile_NEW();
    const personAmsList = _getPersonAmsList();
    const personCmsAutomatedList = _getPersonCmsAutomatedList();
    const personCmsManualList = _getPersonCmsManualList();

    const companyMainList = _getCompanyMainList_NEW();

    const jobList = _getJobList_NEW();
    const jobApplicationsForPeson = _getJobApplicationsForPerson();
    const jobContactsForPerson = _getJobContactsForPerson();

    const globalSearchItems = _getGlobalSearchItems();

    const sits = useMemo(() => {
        const _sits = [];
        if (personDetails && personDetails[person_sit._name]?.length > 0) {
            personDetails[person_sit._name].forEach((item) => {
                const sitInfo = item;
                _sits.push(sitInfo);
            });
        }

        const uniqSits = uniqBy(_sits, "id");
        return uniqSits;
    }, [personDetails]);

    return {
        allSits,
        sits,
        personDetails,
        personFile,
        personAmsList,
        personCmsAutomatedList,
        personCmsManualList,

        companyMainList,

        jobList,
        jobApplicationsForPeson,
        jobContactsForPerson,

        globalSearchItems,

        getPersons: (searchTerm) => {
            dispatch(getPersonMainList_NEW({ searchTerm, pageCount: 10 }));
        },

        fetchAllPersons: () => {
            dispatch(fetchAllPersons());
        },

        getPersonDetail: (id) => {
            dispatch(getPersonDetail_NEW(id));
        },

        getCompanies: (searchTerm) => {
            dispatch(getCompanyMainList_NEW({ searchTerm, pageCount: 10 }));
        },

        getCompanyDetail: (id) => {
            dispatch(getCompanyDetail_NEW(id));
        },

        saveCompany: (data, successCallBack, failCallBack) => {
            dispatch(updateCompany_NEW(data, successCallBack, failCallBack));
        },

        saveCompanyMain: (company, successCallBack, failCallBack) => {
            const query = {
                [company_main._name]: {
                    [company_main.id]: company?.company_main_ref_id,
                    [company_main.company_name]: company?.company_name,
                    [company_main.company_alias]: company?.company_alias,
                    [company_main.company_size_c]: company?.company_size_c,
                    [company_main.company_type_c]: company?.company_type_c,
                    [company_main.company_date_founded]: company?.company_date_founded,
                },
            };

            return new Promise((resolve, reject) => {
                dispatch(
                    updateCompany_NEW(
                        query,
                        (res) => {
                            if (successCallBack) successCallBack(res);
                            resolve(res);
                        },
                        (err) => {
                            if (failCallBack) failCallBack(err);
                            reject(err);
                        }
                    )
                );
            });
        },

        savePerson: (data, successCallBack, failCallBack) => {
            dispatch(updatePerson_NEW(data, successCallBack, failCallBack));
        },

        savePersonLink: (personId, link, successCallBack, failCallBack) => {
            const global = link?.global_url_details;
            const query = {
                [MODEL.person_external_link._name]: {
                    [MODEL.person_external_link.id]: link?.id,
                    [MODEL.person_external_link.person_main_ref_id]: personId,
                    [MODEL.person_external_link.global_link_type_c]:
                        link?.global_link_type_c,
                    [MODEL.global_url_details._name]: {
                        [MODEL.global_url_details.id]: global?.id,
                        [MODEL.global_url_details.url_value]: global?.url_value,
                        [MODEL.global_url_details.url_confirmed_c]:
                            global?.url_confirmed_c,
                    },
                },
            };

            return new Promise((resolve, reject) => {
                dispatch(
                    updatePerson_NEW(
                        query,
                        (res) => {
                            if (successCallBack) successCallBack(res);
                            resolve(res);
                        },
                        (err) => {
                            if (failCallBack) failCallBack(err);
                            reject(err);
                        }
                    )
                );
            });
        },

        savePersonPhone: (personId, phone, successCallBack, failCallBack) => {
            const global = phone?.global_phone_details;
            const query = {
                [MODEL.person_phone._name]: {
                    [MODEL.person_phone.id]: phone?.id,
                    [MODEL.person_phone.person_main_ref_id]: personId,
                    [MODEL.person_phone.phone_type_c]: phone?.phone_type_c,
                    [MODEL.global_phone_details._name]: {
                        [MODEL.global_phone_details.id]: global?.id,
                        [MODEL.global_phone_details.phone_number]: global?.phone_number,
                        [MODEL.global_phone_details.phone_country_code]:
                            global?.phone_country_code,
                        [MODEL.global_phone_details.phone_extension]:
                            global?.phone_extension,
                        [MODEL.global_phone_details.phone_status_c]:
                            global?.phone_status_c,
                    },
                },
            };

            return new Promise((resolve, reject) => {
                dispatch(
                    updatePerson_NEW(
                        query,
                        (res) => {
                            if (successCallBack) successCallBack(res);
                            resolve(res);
                        },
                        (err) => {
                            if (failCallBack) failCallBack(err);
                            reject(err);
                        }
                    )
                );
            });
        },

        savePersonAddress: (personId, address, successCallBack, failCallBack) => {
            const global =
                address?.global_address_details?.[0] || address?.global_address_details;
            const query = {
                [person_address._name]: {
                    [person_address.person_main_ref_id]: personId,
                    [person_address.id]: address?.id,
                    [person_address.address_type_c]: address?.address_type_c,
                    [global_address_details._name]: {
                        [global_address_details.id]: global?.id,
                        [global_address_details.address_name]: global?.address_name,
                        [global_address_details.address_line_1]: global?.address_line_1,
                        [global_address_details.address_line_2]: global?.address_line_2,
                        [global_address_details.address_line_3]: global?.address_line_3,
                        [global_address_details.address_city]: global?.address_city,
                        [global_address_details.address_state]: global?.address_state,
                        [global_address_details.address_zip]: global?.address_zip,
                        [global_address_details.address_country]: global?.address_country,
                        [global_address_details.address_confirmed_c]:
                            global?.address_confirmed_c,
                        [global_address_details.data_location_master_ref_id]:
                            global?.data_location_master_ref_id,
                    },
                },
            };

            return new Promise((resolve, reject) => {
                dispatch(
                    updatePerson_NEW(
                        query,
                        (res) => {
                            if (successCallBack) successCallBack(res);
                            resolve(res);
                        },
                        (err) => {
                            if (failCallBack) failCallBack(err);
                            reject(err);
                        }
                    )
                );
            });
        },

        savePersonEmail: (personId, email, successCallBack, failCallBack) => {
            const global = email?.global_email_details;
            const query = {
                [MODEL.person_email._name]: {
                    [MODEL.person_email.id]: email?.id,
                    [MODEL.person_email.person_main_ref_id]: personId,
                    [MODEL.person_email.email_type_c]: email?.email_type_c,
                    [MODEL.global_email_details._name]: {
                        [MODEL.global_email_details.id]: global?.id,
                        [MODEL.global_email_details.email_value]: global?.email_value,
                        [MODEL.global_email_details.email_status_date]:
                            global?.email_status_date,
                        [MODEL.global_email_details.email_status_c]:
                            global?.email_status_c,
                    },
                },
            };

            return new Promise((resolve, reject) => {
                dispatch(
                    updatePerson_NEW(
                        query,
                        (res) => {
                            if (successCallBack) successCallBack(res);
                            resolve(res);
                        },
                        (err) => {
                            if (failCallBack) failCallBack(err);
                            reject(err);
                        }
                    )
                );
            });
        },

        savePersonJobHistory: (personId, job, successCallBack, failCallBack) => {
            const query = {
                [person_history_work._name]: {
                    [person_history_work.id]: job?.id,
                    [person_history_work.person_main_ref_id]: personId,
                    [person_history_work.company_name]: job?.company_name,
                    [person_history_work.company_main_ref_id]: job?.company_main_ref_id,
                    [person_history_work.work_title]: job?.work_title,
                    [person_history_work.date_start]: job?.date_start,
                    [person_history_work.date_end]: job?.date_end,
                    [person_history_work.work_summary]: job?.work_summary,
                    [person_history_work.person_email_ref_id]: job?.person_email_ref_id,
                    [person_history_work.person_phone_ref_id]: job?.person_phone_ref_id,
                    [person_history_work.person_address_ref_id]:
                        job?.person_address_ref_id,
                    [person_history_work.compensation_main_ref_id]:
                        job?.compensation_main_ref_id,
                },
            };

            return new Promise((resolve, reject) => {
                dispatch(
                    updatePerson_NEW(
                        query,
                        (res) => {
                            if (successCallBack) successCallBack(res);
                            resolve(res);
                        },
                        (err) => {
                            if (failCallBack) failCallBack(err);
                            reject(err);
                        }
                    )
                );
            });
        },

        savePersonAttachment: (personId, attachment, successCallBack, failCallBack) => {
            const global = attachment?.file_main?.[0];
            const query = {
                [person_attachment._name]: {
                    [person_attachment.person_main_ref_id]: personId,
                    [person_attachment.id]: attachment?.id,
                    [person_attachment.file_main_ref_id]: attachment?.file_main_ref_id,
                    [file_main._name]: {
                        [file_main.id]: global?.id,
                        [file_main.file_type_c]: global?.file_type_c,
                        [file_main.file_name_original]: global?.file_name_original,
                        [file_main.file_name_internal]: global?.file_name_internal,
                        [file_main.file_storage_confirmed]:
                            global?.file_storage_confirmed,
                        [file_main.bucket_name]: global?.bucket_name,
                        [file_main.file_details]: global?.file_details,
                    },
                },
            };

            return new Promise((resolve, reject) => {
                dispatch(
                    updatePerson_NEW(
                        query,
                        (res) => {
                            if (successCallBack) successCallBack(res);
                            resolve(res);
                        },
                        (err) => {
                            if (failCallBack) failCallBack(err);
                            reject(err);
                        }
                    )
                );
            });
        },

        savePersonPrimary: (personId, primary, successCallBack, failCallBack) => {
            const query = {
                [person_primary_setting._name]: {
                    [person_primary_setting.person_main_ref_id]: personId,
                    [person_primary_setting.id]: primary?.id,
                    [person_primary_setting.primary_level_gc]: primary?.primary_level_gc,
                    [person_primary_setting.ref_object_base_sc]:
                        primary?.ref_object_base_sc,
                    [person_primary_setting.ref_object_table_sc]:
                        primary?.ref_object_table_sc,
                    [person_primary_setting.ref_object_record_id]:
                        primary?.ref_object_record_id,
                },
            };

            return new Promise((resolve, reject) => {
                dispatch(
                    updatePerson_NEW(
                        query,
                        (res) => {
                            if (successCallBack) successCallBack(res);
                            resolve(res);
                        },
                        (err) => {
                            if (failCallBack) failCallBack(err);
                            reject(err);
                        }
                    )
                );
            });
        },

        deletePerson: (query, successCallBack, failCallBack) => {
            dispatch(deletePerson_NEW(query, successCallBack, failCallBack));
        },

        setPersonSelectedFile: (file) => {
            dispatch(setPersonSelectedFile_NEW(file));
        },

        addSITIds: (personId, ids) => {
            return new Promise((resolve, reject) => {
                const successCallback = (res) => {
                    resolve({ error: false });
                };
                const failCallback = () => {
                    reject({ error: true });
                };
                dispatch(addPersonSITs_NEW(personId, ids, successCallback, failCallback));
            });
        },

        deleteSITIds: (personId, ids) => {
            return new Promise((resolve, reject) => {
                const successCallback = (res) => {
                    resolve({ error: false });
                };
                const failCallback = () => {
                    reject({ error: true });
                };
                dispatch(
                    deletePersonSITs_NEW(personId, ids, successCallback, failCallback)
                );
            });
        },

        getAllSitByKeyword: (sitTypeC, keyword, successCallback, failCallBack) => {
            dispatch(
                getSitListByKeyword(sitTypeC, keyword, successCallback, failCallBack)
            );
        },

        saveRASIds: (
            object_ref_id,
            object_type,
            oweners,
            assigneds,
            successCallback,
            failCallback
        ) => {
            const object_type_c = getGCSValue(
                globals,
                "ras_assignment_main",
                "object_type_c",
                object_type
            );
            const assignment_type_c_owner = getGCSValue(
                globals,
                "ras_assignment_main",
                "assignment_type_c",
                "owner"
            );
            const assignment_type_c_assigned = getGCSValue(
                globals,
                "ras_assignment_main",
                "assignment_type_c",
                "assigned"
            );

            const rasAssignmentList = [];

            oweners.forEach((id) => {
                rasAssignmentList.push({
                    [MODEL.ras_assignment_main._name]: {
                        object_type_c: object_type_c,
                        object_ref_id: object_ref_id,
                        assignment_type_c: assignment_type_c_owner,
                        assignment_ref_id: Number(id) || 0,
                    },
                });
            });

            assigneds.forEach((id) => {
                rasAssignmentList.push({
                    [MODEL.ras_assignment_main._name]: {
                        object_type_c: object_type_c,
                        object_ref_id: object_ref_id,
                        assignment_type_c: assignment_type_c_assigned,
                        assignment_ref_id: Number(id) || 0,
                    },
                });
            });

            dispatch(updateRAS_NEW(rasAssignmentList, successCallback, failCallback));
        },

        deleteRASIds: (oweners, assigneds, successCallback, failCallback) => {
            const rasAssignmentList = [];

            [...oweners, ...assigneds].forEach((id) => {
                rasAssignmentList.push({
                    [MODEL.ras_assignment_main._name]: {
                        [MODEL.ras_assignment_main.id]: id,
                    },
                });
            });

            dispatch(deleteRAS_NEW(rasAssignmentList, successCallback, failCallback));
        },

        updateRestriction: (
            object_ref_id,
            object_type,
            restriction_type_c,
            restriction_id,
            successCallback,
            failCallback
        ) => {
            const object_type_c = getGCSValue(
                globals,
                "ras_restriction_main",
                "object_type_c",
                object_type
            );

            if (restriction_type_c > 0) {
                const restriction = {
                    [MODEL.ras_restriction_main._name]: {
                        [MODEL.ras_restriction_main.id]: restriction_id,
                        [MODEL.ras_restriction_main.object_type_c]: object_type_c,
                        [MODEL.ras_restriction_main.object_ref_id]: object_ref_id,
                        [MODEL.ras_restriction_main.restriction_type_c]:
                            restriction_type_c,
                    },
                };

                dispatch(updateRAS_NEW(restriction, successCallback, failCallback));
            } else {
                if (restriction_id) {
                    const restriction = {
                        [MODEL.ras_restriction_main._name]: {
                            [MODEL.ras_restriction_main.id]: restriction_id,
                        },
                    };

                    dispatch(deleteRAS_NEW(restriction, successCallback, failCallback));
                } else {
                    if (successCallback) {
                        successCallback("no operation");
                    }
                }
            }
        },

        saveQualifiers: (
            object_ref_id,
            object_type,
            ids,
            successCallback,
            failCallback
        ) => {
            if (ids?.length > 0) {
                console.log("ids: ", ids);
                const qualifierAssignments = [];
                const object_type_c = getGCSValue(
                    globals,
                    "qualifier_assignment",
                    "object_type_c",
                    object_type
                );

                ids.forEach((id) => {
                    qualifierAssignments.push({
                        [MODEL.qualifier_assignment._name]: {
                            [MODEL.qualifier_assignment.object_type_c]: object_type_c,
                            [MODEL.qualifier_assignment.object_record_ref_id]:
                                object_ref_id,
                            [MODEL.qualifier_assignment.qualifier_main_ref_id]: id,
                        },
                    });
                });

                console.log("qualifierAssignments: ", qualifierAssignments);
                dispatch(
                    updateQualifier_NEW(
                        qualifierAssignments,
                        successCallback,
                        failCallback
                    )
                );
            } else {
                if (successCallback) {
                    successCallback(ids);
                }
            }
        },

        deleteQualifiers: (ids, successCallback, failCallback) => {
            const qualifierAssignments = [];

            [...(ids || [])].forEach((id) => {
                qualifierAssignments.push({
                    [MODEL.qualifier_assignment._name]: {
                        [MODEL.qualifier_assignment.id]: id,
                    },
                });
            });
            dispatch(
                deleteQualifier(qualifierAssignments, successCallback, failCallback)
            );
        },

        // AMS
        getAmsList: (params, successCallBack, failCallBack) => {
            dispatch(getPersonAmsList(params, successCallBack, failCallBack));
        },

        setAmsListParams: (params) => {
            dispatch(setPersonAmsListParams(params));
        },

        updateAms: (params, successCallBack, failCallBack) => {
            dispatch(updateAms(params, successCallBack, failCallBack));
        },

        deleteAms: (params, successCallBack, failCallBack) => {
            dispatch(deleteAms(params, successCallBack, failCallBack));
        },

        // Jobs
        getJobList: (params) => {
            dispatch(getJobList(params));
        },

        updateJob: (params, successCallBack, failCallBack) => {
            dispatch(updateJobDetail_NEW(params, successCallBack, failCallBack));
        },

        getJobApplicationsForPerson: (params) => {
            dispatch(getJobApplicationsForPerson(params));
        },

        getJobContactsForPerson: (params) => {
            dispatch(getJobContactsForPerson(params));
        },

        // Global Search
        getGlobalSearchItems: (term) => {
            dispatch(getGlobalSearchItems({ search_term: term }));
        },
    };
};

export default usePerson;
