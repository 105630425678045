import React, { useMemo } from "react";
import styled from "styled-components";

import * as MODEL from "core/model";
import { EuiFlexGroup, EuiFlexItem } from "@elastic/eui";
import usePerson from "components/Pages/Person/usePerson";
import AmsPersonContactItem from "components/Common/Ams/AmsEditItem/AmsEditActivityItem/AmsPersonContactItem";
import { getEmailTypeInfo, getPhoneTypeInfo } from "components/global/utils";
/**
 * Component for Contact
 */
const Contact = (props) => {
	const { contact } = props;

	const {
		getPersonDetail,
		deletePerson,
		savePersonPhone,
		savePersonEmail,
		savePersonLink,
	} = usePerson();

	const {
		personId,
		phonesContact,
		phonesWork,
		emailsContact,
		emailsWork,
		linkesContact,
	} = useMemo(() => {
		let phonesContact = [];
		let phonesWork = [];
		let emailsContact = [];
		let emailsWork = [];
		let linkesContact = [];

		const {
			id: personId,
			email: emails = [],
			phone: phones = [],
			link: linkes = [],
		} = contact;

		if (emails?.length > 0) {
			emails.forEach((email) => {
				const emailTypeInfo = getEmailTypeInfo(email.email_type_c);

				if (!emailTypeInfo?.range) {
					emailsContact.push(email);
				} else {
					emailsWork.push(email);
				}
			});
		}

		if (phones?.length > 0) {
			phones.forEach((phone) => {
				const phoneTypeInfo = getPhoneTypeInfo(phone.phone_type_c);

				if (!phoneTypeInfo?.range) {
					phonesContact.push(phone);
				} else {
					phonesWork.push(phone);
				}
			});
		}
		if (linkes?.length > 0) {
			linkes.forEach((link) => {
				const isLinkShowType = link.link_type_c === 2 || link.link_type_c === 6;
				if (isLinkShowType) linkesContact.push(link);
			});
		}

		return {
			personId,
			phonesContact,
			phonesWork,
			emailsContact,
			emailsWork,
			linkesContact,
		};
	}, [contact]);

	const handleSavePhone = (data, successCallBack) => {
		savePersonPhone(personId, data, (res) => {
			if (successCallBack) {
				successCallBack(res?.person_phone, () => {
					getPersonDetail(personId);
				});
			} else {
				getPersonDetail(personId);
			}
		});
	};

	const handleDeletePhone = (phoneId) => {
		const query = {
			[MODEL.person_phone._name]: {
				[MODEL.person_phone.id]: phoneId,
			},
		};
		deletePerson(query, () => {
			getPersonDetail(personId);
		});
	};

	const handleSaveEmail = (data, successCallBack) => {
		savePersonEmail(personId, data, (res) => {
			if (successCallBack) {
				successCallBack(res?.person_email, () => {
					getPersonDetail(personId);
				});
			} else {
				getPersonDetail(personId);
			}
		});
	};

	const handleDeleteEmail = (emailId) => {
		const query = {
			[MODEL.person_email._name]: {
				[MODEL.person_email.id]: emailId,
			},
		};
		deletePerson(query, () => {
			getPersonDetail(personId);
		});
	};

	const handleSaveLink = (data, successCallBack) => {
		savePersonLink(personId, data, (res) => {
			if (successCallBack) {
				successCallBack(res?.person_email, () => {
					getPersonDetail(personId);
				});
			} else {
				getPersonDetail(personId);
			}
		});
	};

	const handleDeleteLink = (linkId) => {
		const query = {
			[MODEL.person_external_link._name]: {
				[MODEL.person_external_link.id]: linkId,
			},
		};
		deletePerson(query, () => {
			getPersonDetail(personId);
		});
	};

	return (
		<Wrapper>
			<div className="icon-list__container">
				<EuiFlexGroup gutterSize="xs">
					<EuiFlexItem>
						<div className="icon-item_container">
							<AmsPersonContactItem
								type="contact"
								personId={personId}
								emails={emailsContact}
								phones={phonesContact}
								linkes={linkesContact}
								onSavePhone={handleSavePhone}
								onDeletePhone={handleDeletePhone}
								onSaveEmail={handleSaveEmail}
								onDeleteEmail={handleDeleteEmail}
								onSaveLink={handleSaveLink}
								onDeleteLink={handleDeleteLink}
							/>
						</div>
					</EuiFlexItem>
					<EuiFlexItem>
						<div className="icon-item_container">
							<AmsPersonContactItem
								type="work"
								personId={personId}
								emails={emailsWork}
								phones={phonesWork}
								onSavePhone={handleSavePhone}
								onDeletePhone={handleDeletePhone}
								onSaveEmail={handleSaveEmail}
								onDeleteEmail={handleDeleteEmail}
							/>
						</div>
					</EuiFlexItem>
				</EuiFlexGroup>
			</div>
		</Wrapper>
	);
};

export default Contact;

/**
 * Styled Components
 */
const Wrapper = styled.div`
	display: flex;
	justify-content: space-between;

	.title {
		font-weight: bold;
		margin-top: auto;
		margin-bottom: auto;
		margin-right: auto;
	}

	.icon-btn {
		height: 32px;
	}

	.icon-list__container {
		align-items: center;
		display: flex;
	}

	.icon-item_container {
		display: flex;
		align-items: center;
		justify-content: center;
		padding: var(--sp-1);
		border-radius: var(--sp-2);
		margin-left: 1.5em;
	}
`;
