import {
	GET_SYSTEM_ERROR_LOGS,
	SET_SYSTEM_ERROR_LOGS,
	GET_SYSTEM_ACTIVITY_LOGS,
	SET_SYSTEM_ACTIVITY_LOGS,
} from "./actionTypes";

/**
 * Functions
 */
const getSystemErrorLog = (state, payload) => {
	return {
		...state,
		message: payload.message,
		error: [...state.error, payload.error],
	};
};

const setSystemErrorLog = (state, payload) => {
	return {
		...state,
		systemErrorLogs: payload.data,
		systemErrorLogsTotalCount: payload.totalCount,
	};
};

const getSystemActivityLog = (state, payload) => {
	return {
		...state,
		activityLogsMessage: payload.message,
		activtyLogsError: [...state.error, payload.error],
	};
};

const setSystemActivityLog = (state, payload) => {
	return {
		...state,
		systemActivityLogs: payload.data,
		systemActivityLogsTotalCount: payload.totalCount,
	};
};

/**
 * Initial State
 */
const initialState = {
	systemErrorLogs: [],
	systemErrorLogsTotalCount: 0,
	error: "",
	message: "",
	systemActivityLogs: [],
	systemActivityLogsTotalCount: 0,
	activtyLogsError: "",
	activityLogsMessage: "",
};

/**
 * Reducer
 */
const Reducer = (state = initialState, action) => {
	switch (action.type) {
		case GET_SYSTEM_ERROR_LOGS:
			return getSystemErrorLog(state, action.payload);

		case SET_SYSTEM_ERROR_LOGS:
			return setSystemErrorLog(state, action.payload);

		case GET_SYSTEM_ACTIVITY_LOGS:
			return getSystemActivityLog(state, action.payload);

		case SET_SYSTEM_ACTIVITY_LOGS:
			return setSystemActivityLog(state, action.payload);

		default:
			return state;
	}
};

export default Reducer;
