import React from "react";
import styled from "styled-components";

import { EuiHeader, EuiText, EuiFlexGroup, EuiFlexItem } from "@elastic/eui";
import OpenTabs from "./OpenTabs";
import { getMeta, showDateFormat } from "helpers/utilities";
const { version } = require("../../../../package.json");

const SecondaryHeader = () => {
    const buildNumber = getMeta("build-version");
    const buildDate = getMeta("build-date");

    const renderVersion = (
        <div>
            <EuiFlexGroup direction="column" alignItems="center" className="mx-3">
                <EuiFlexItem className="m-0">
                    v{version}
                    {buildNumber.includes("%") ? "" : "." + buildNumber}
                </EuiFlexItem>
                <EuiFlexItem className="m-0">
                    {buildDate.includes("%") ? "" : showDateFormat(buildDate)}
                </EuiFlexItem>
            </EuiFlexGroup>
        </div>
    );

    const desktopSections = [
        {
            items: [<OpenTabs />],
            borders: "none",
            side: "left",
        },
        {
            items: [renderVersion],
        },
    ];

    return (
        <Wrapper>
            <EuiHeader sections={desktopSections} id="root-second-header">
                <EuiText>version number</EuiText>
            </EuiHeader>
        </Wrapper>
    );
};

export default SecondaryHeader;

/*
 * Styled Components
 */
const Wrapper = styled.div`
    .euiHeaderSectionItem--borderLeft:after {
        width: 0;
    }
`;
