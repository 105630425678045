import React, { useMemo } from "react";
import styled from "styled-components";
import {
	EuiFlexGroup,
	EuiFlexItem,
	EuiSwitch,
	EuiLink,
	EuiButton,
	EuiAvatar,
} from "@elastic/eui";
import { get } from "lodash";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMinus } from "@fortawesome/pro-light-svg-icons";

import { EditableGeneralName, RASItem, DatePickerItem } from "components/Common";
import { amsActivityLogoIcon } from "components/Common/Icons/Icons";

import { AMS_ACTIVITY_TYPE_LIST } from "../../constants";
import * as MODEL from "core/model";

import { AmsNoteList } from "../AmsNoteList";
import AmsActivityTypeItem from "./AmsActivityTypeItem";
import { AmsTaskList } from "../AmsTaskItem";
import AmsListAccordion from "../AmsListAccordion";
import AmsCommunicationItem from "../AmsCommunicationItem";
import AmsPersonContactItem from "./AmsPersonContactItem";
import AmsStatusItem from "../../AmsStatusItem";
import AmsRelatedRecordList from "../AmsRelatedRecordList";
import { getEmailTypeInfo, getPhoneTypeInfo } from "components/global/utils";

/**
 * Component for AMS Activity Item with editable feature
 */
const AmsEditActivityItem = (props) => {
	const {
		data,
		person,
		account,
		accounts,
		restriction,
		owners,
		assignees,
		onChangeEdit,
		onChangeViewType,
		onChangeAmsType,
		onChangeAmsName,
		onChangeAmsStatus,
		onUpdateNote,
		onDeleteNote,
		onChangeAmsStartTime,
		onChangeAmsEndTime,
		onUpdateTask,
		onDeleteTask,
		onUpdateReminder,
		onDeleteReminder,
		onUpdateReference,
		onSaveEmail,
		onDeleteEmail,
		onSavePhone,
		onDeletePhone,
		onSaveRAS,
		globalSearchItems,
		getGlobalSearchItems,
		onDeleteReference,
	} = props;

	const {
		personId,
		personName,
		personPhotoUrl,
		phonesContact,
		phonesWork,
		emailsContact,
		emailsWork,
	} = useMemo(() => {
		let personName = "";
		let personPhotoUrl = "";
		let phonesContact = [];
		let phonesWork = [];
		let emailsContact = [];
		let emailsWork = [];
		let personId = null;

		if (person) {
			const {
				person_external_link,
				name_first,
				name_last,
				person_email: emails = [],
				person_phone: phones = [],
				id,
			} = person;

			personId = id;
			// Get photo url from person_external_link, global_link_type_c = 3 for linkedin avartar
			personPhotoUrl =
				person_external_link?.filter(
					(link) => link[MODEL.person_external_link.global_link_type_c] === 3
				)?.[0]?.global_url_details?.url_value ?? "";
			personName = `${name_first} ${name_last}`;

			if (emails?.length > 0) {
				emails.forEach((email) => {
					const emailTypeInfo = getEmailTypeInfo(email.email_type_c);

					if (!emailTypeInfo?.range) {
						emailsContact.push(email);
					} else {
						emailsWork.push(email);
					}
				});
			}

			if (phones?.length > 0) {
				phones.forEach((phone) => {
					const phoneTypeInfo = getPhoneTypeInfo(phone.phone_type_c);

					if (!phoneTypeInfo?.range) {
						phonesContact.push(phone);
					} else {
						phonesWork.push(phone);
					}
				});
			}
		}

		return {
			personId,
			personName,
			personPhotoUrl,
			phonesContact,
			phonesWork,
			emailsContact,
			emailsWork,
		};
	}, [person]);

	const {
		amsStatus,
		selectedTypeValue,
		activityName,
		notes,
		tasks,
		startDate,
		endDate,
	} = useMemo(() => {
		// Get AMS Status
		const amsStatus = get(data, `${MODEL.ams_activity_main.activity_status_c}`, 1);

		// Get AMS type
		const activityTypeC = get(
			data,
			MODEL.ams_activity_main.activity_type_c,
			AMS_ACTIVITY_TYPE_LIST.outreach.value
		);
		const activityType =
			Object.values(AMS_ACTIVITY_TYPE_LIST).find(
				(item) => item.value === activityTypeC
			) || AMS_ACTIVITY_TYPE_LIST.outreach;
		const selectedTypeValue = activityType.value;

		// Get AMS name
		const activityName = get(data, MODEL.ams_activity_main.activity_name, "") || "";

		// Get account information
		let accountName = "";
		let accountPhotoUrl = "";
		if (account) {
			const { account_external_link, name_first, name_last } = account;
			accountPhotoUrl =
				account_external_link?.filter(
					(link) => link[MODEL.person_external_link.global_link_type_c] === 3
				)?.[0]?.global_url_details?.url_value ?? "";
			accountName = `${name_first} ${name_last}`;
		}

		// Get AMS Notes
		const activityNotes = get(data, MODEL.ams_activity_note._name, []) || [];
		const notes =
			activityNotes?.map((item, index) => {
				return {
					...item,
					index,
					name: accountName,
				};
			}) || [];

		// Get AMS Tasks
		const activityTasks = get(data, MODEL.ams_activity_task._name, []);
		const tasks =
			activityTasks?.map((item, index) => {
				return {
					...item,
					[MODEL.ams_activity_reminder._name]:
						item[MODEL.ams_activity_reminder._name] || [],
					index,
				};
			}) || [];

		// Get detail times
		const startDate = get(
			data,
			`${MODEL.ams_activity_time._name}[0].${MODEL.ams_activity_time.date_start}`,
			null
		);
		const endDate = get(
			data,
			`${MODEL.ams_activity_time._name}[0].${MODEL.ams_activity_time.date_end}`,
			null
		);

		return {
			amsStatus,
			selectedTypeValue,
			activityName,
			notes,
			tasks,
			startDate,
			endDate,
		};
	}, [data, account]);

	const handleClickEdit = () => {
		onChangeEdit();
	};

	const handleChangeType = (newType) => {
		onChangeAmsType(newType);
	};

	const handleSaveActivityName = (name) => {
		onChangeAmsName(name);
	};

	const handleSaveNote = (newNote) => {
		onUpdateNote(newNote);
	};

	const handleDeleteNote = (note) => {
		onDeleteNote(note);
	};

	const handleSaveTask = (newTask) => {
		onUpdateTask(newTask);
	};

	const handleDeleteTask = (task) => {
		onDeleteTask(task);
	};

	const handleUpdateReminder = (newReminder) => {
		onUpdateReminder(newReminder);
	};

	const handleDeleteReminder = (reminder) => {
		onDeleteReminder(reminder);
	};

	const handleStartDate = (newDate) => {
		onChangeAmsStartTime(newDate);
	};

	const handleEndDate = (newDate) => {
		onChangeAmsEndTime(newDate);
	};

	const handleChangeStatus = (newStatus) => {
		onChangeAmsStatus(newStatus);
	};

	const handleSaveReference = (newReference) => {
		onUpdateReference(newReference);
	};

	const handleDeleteReference = (reference) => {
		onDeleteReference(reference);
	};

	return (
		<Wrapper>
			<EuiFlexGroup gutterSize="m" direction="column">
				<EuiFlexItem>
					<div className="ams-edit-item-header__container">
						<EuiFlexGroup gutterSize="l" alignItems="center">
							<EuiFlexItem grow={false}>
								<EuiFlexGroup
									direction="column"
									gutterSize="s"
									justifyContent="center"
								>
									<EuiFlexItem>
										<EuiFlexGroup gutterSize="s">
											<EuiFlexItem grow={false}>
												{amsActivityLogoIcon()}
											</EuiFlexItem>
											<EuiFlexItem>
												<EuiSwitch
													label="Activity View"
													checked={true}
													onChange={onChangeViewType}
													className="activity-view-switch"
													compressed
												/>
											</EuiFlexItem>
										</EuiFlexGroup>
									</EuiFlexItem>
									<EuiFlexItem>
										<AmsStatusItem
											status={amsStatus}
											onChangeStatus={handleChangeStatus}
											showLabel={true}
										/>
									</EuiFlexItem>
								</EuiFlexGroup>
							</EuiFlexItem>
							<EuiFlexItem>
								<EuiFlexGroup
									gutterSize="s"
									alignItems="center"
									justifyContent="center"
								>
									<EuiFlexItem grow={false}>
										<EuiAvatar
											size="l"
											name={personName}
											imageUrl={personPhotoUrl}
										/>
									</EuiFlexItem>
									<EuiFlexItem grow={false}>
										<div className="icon-list__container">
											<EuiFlexGroup
												direction="column"
												gutterSize="xs"
											>
												<EuiFlexItem>
													<AmsPersonContactItem
														type="contact"
														personId={personId}
														emails={emailsContact}
														phones={phonesContact}
														onSavePhone={onSavePhone}
														onDeletePhone={onDeletePhone}
														onSaveEmail={onSaveEmail}
														onDeleteEmail={onDeleteEmail}
													/>
												</EuiFlexItem>
												<EuiFlexItem>
													<AmsPersonContactItem
														type="work"
														personId={personId}
														emails={emailsWork}
														phones={phonesWork}
														onSavePhone={onSavePhone}
														onDeletePhone={onDeletePhone}
														onSaveEmail={onSaveEmail}
														onDeleteEmail={onDeleteEmail}
													/>
												</EuiFlexItem>
											</EuiFlexGroup>
										</div>
									</EuiFlexItem>
								</EuiFlexGroup>
							</EuiFlexItem>
							<EuiFlexItem grow={false}>
								<RASItem
									restriction={restriction}
									owners={owners}
									assignees={assignees}
									accounts={accounts}
									onSave={onSaveRAS}
								/>
							</EuiFlexItem>
							<EuiFlexItem grow={false}>
								<EuiButton
									color="text"
									className="button-fit-content close-button"
									onClick={handleClickEdit}
								>
									<FontAwesomeIcon icon={faMinus} size="1x" />
								</EuiButton>
							</EuiFlexItem>
						</EuiFlexGroup>
					</div>
				</EuiFlexItem>
				<EuiFlexItem>
					<EuiFlexGroup
						direction="column"
						gutterSize="s"
						justifyContent="center"
					>
						<EuiFlexItem>
							<AmsActivityTypeItem
								activityType={selectedTypeValue}
								onChangeType={handleChangeType}
							/>
						</EuiFlexItem>
						<EuiFlexItem>
							<div className="option-subject__container">
								<div className="option-subject__wrapper">
									<EditableGeneralName
										name={activityName}
										onSave={handleSaveActivityName}
									/>
								</div>
							</div>
						</EuiFlexItem>
					</EuiFlexGroup>
				</EuiFlexItem>
				<EuiFlexItem>
					<div className="ams-task-list__wrapper">
						<AmsTaskList
							data={tasks}
							onSaveTask={handleSaveTask}
							onDeleteTask={handleDeleteTask}
							onUpdateReminder={handleUpdateReminder}
							onDeleteReminder={handleDeleteReminder}
						/>
					</div>
				</EuiFlexItem>
				<EuiFlexItem>
					<AmsListAccordion title="Note">
						<AmsNoteList
							data={notes}
							onSaveNote={handleSaveNote}
							onDeleteNote={handleDeleteNote}
						/>
					</AmsListAccordion>
				</EuiFlexItem>
				<EuiFlexItem>
					<AmsListAccordion title="Details">
						<EuiFlexGroup gutterSize="s" direction="column">
							<EuiFlexItem>
								<EuiFlexGroup gutterSize="xs" alignItems="center">
									<EuiFlexItem grow={false}>
										<p className="semibold detail-date">
											Start Time:
										</p>
									</EuiFlexItem>
									<EuiFlexItem>
										<div
											className="type-small-medium"
											style={{ color: "var(--link-color)" }}
										>
											<DatePickerItem
												value={startDate}
												typeDate="M/D/YYYY @ h:mma"
												onChange={handleStartDate}
											/>
										</div>
									</EuiFlexItem>
								</EuiFlexGroup>
							</EuiFlexItem>
							<EuiFlexItem>
								<EuiFlexGroup gutterSize="xs" alignItems="center">
									<EuiFlexItem grow={false}>
										<p className="semibold detail-date">End Time:</p>
									</EuiFlexItem>
									<EuiFlexItem>
										<EuiLink>
											<div
												className="type-small-medium"
												style={{ color: "var(--link-color)" }}
											>
												<DatePickerItem
													value={endDate}
													typeDate="M/D/YYYY @ h:mma"
													onChange={handleEndDate}
												/>
											</div>
										</EuiLink>
									</EuiFlexItem>
								</EuiFlexGroup>
							</EuiFlexItem>
						</EuiFlexGroup>
					</AmsListAccordion>
				</EuiFlexItem>
				<EuiFlexItem>
					<AmsListAccordion title="Related Records">
						<AmsRelatedRecordList
							data={data}
							globalSearchItems={globalSearchItems}
							getGlobalSearchItems={getGlobalSearchItems}
							onSaveReference={handleSaveReference}
							onDeleteReference={handleDeleteReference}
						/>
					</AmsListAccordion>
				</EuiFlexItem>
				<EuiFlexItem>
					<AmsListAccordion title="Related Communications">
						<AmsCommunicationItem />
					</AmsListAccordion>
				</EuiFlexItem>
			</EuiFlexGroup>
		</Wrapper>
	);
};

export default AmsEditActivityItem;

/**
 * Styled Components
 */
const Wrapper = styled.div`
	width: 100%;
	padding: var(--sp-3) var(--sp-4_5) var(--sp-4) var(--sp-4_5);
	border-bottom: solid var(--sp-px) var(--border-color);

	.ams-edit-item-header__container {
		width: 100%;
		background: var(--eui-lightest-shade-color);
		padding: var(--sp-3_5) var(--sp-8);

		.simple-note-switch {
			span {
				white-space: nowrap;
			}
		}

		.ams-type-list-buttons__container {
			.euiButton {
				min-width: var(--sp-17);
				min-height: var(--sp-16);

				&.euiButton--primary {
					color: var(--eui-text-color);

					&.euiButton--fill {
						color: var(--white-color);
					}
				}

				.euiButtonContent {
					padding: var(--sp-4);
				}
			}
		}
	}

	.close-button {
		background: var(--eui-lightest-shade-color);
		border-color: var(--transparent);

		min-width: 0 !important;
		height: auto !important;
		line-height: 1 !important;

		.euiButtonContent.euiButton__content {
			padding: var(--sp-2) !important;
		}

		.euiButton__text {
			font-size: var(--sp-6);
		}

		svg {
			width: var(--sp-6);
			height: var(--sp-6);
		}
	}

	.activity-type-item {
		display: flex;
	}

	.option-subject__container {
		display: flex;
		width: 100%;
		justify-content: center;

		.option-subject__wrapper {
			display: flex;
			width: fit-content;

			h3 {
				font-size: var(--sp-8) !important;
				line-height: var(--sp-12) !important;
			}
		}
	}

	.ams-task-list__wrapper {
		padding: 0 var(--sp-7);
	}

	.ams-note-list__wrapper {
		padding: 0 var(--sp-9);
	}

	.detail-date {
		color: var(--text-subdued-color);
	}

	.icon-list__container {
		background: var(--white-color);
		padding: var(--sp-2) var(--sp-2);
		border-radius: var(--sp-2);
	}
`;
