import API from "api";
import Service from "core/service";
import { objectChecker, getResultFromApiResponse } from "helpers/utilities";
import * as MODEL from "core/model";
import { get } from "lodash";

import { logError } from "../error";
import * as actionTypes from "./actionTypes";

export const setJobSelectedSkills = (data) => {
	return {
		type: actionTypes.SET_JOB_SELECTED_SKILLS,
		payload: data,
	};
};

export const setJobFlyoutVisibility = (type) => {
	return async (dispatch) => {
		dispatch({
			type: actionTypes.SET_JOB_FLYOUT_VISIBILITY,
			payload: type,
		});
	};
};

export const toggleJobFlyout = (type) => {
	return async (dispatch) => {
		dispatch(setJobFlyoutVisibility(type));
	};
};

/* ----------------------------- NEW ----------------------------------- */
const setJobList_NEW = (data) => {
	return {
		type: actionTypes.SET_JOB_LIST_NEW,
		payload: data,
	};
};

const setJobDetail_NEW = (data) => {
	return {
		type: actionTypes.SET_JOB_DETAIL_NEW,
		payload: data,
	};
};

export const updateJob_NEW = (params, successCallback, failCallback) => {
	return async (dispatch) => {
		if (!params) {
			failCallback(null);
		}

		try {
			let payload = params;

			const result = await Service.job.updateJob(payload);

			if (!objectChecker(result, ["error"])) {
				if (successCallback) {
					const res = getResultFromApiResponse(result);
					successCallback(res?.procedure_results);
				}
			} else {
				if (failCallback) {
					failCallback(result);
				}
			}
		} catch (err) {
			if (failCallback) {
				failCallback(err);
			}
		}
	};
};

export const deleteJob_NEW = (params, successCallback, failCallback) => {
	return async (dispatch) => {
		if (!params) {
			failCallback(null);
		}

		try {
			let payload = params;

			const result = await Service.job.deleteJob(payload);

			if (!objectChecker(result, ["error"])) {
				if (successCallback) {
					const res = getResultFromApiResponse(result);
					successCallback(res?.procedure_results);
				}
			} else {
				if (failCallback) {
					failCallback(result);
				}
			}
		} catch (err) {
			if (failCallback) {
				failCallback(err);
			}
		}
	};
};

export const updateJobDetail_NEW = (params, successCallback, failCallback) => {
	return async (dispatch) => {
		if (!params) {
			failCallback(null);
		}

		try {
			let payload = params;

			const result = await Service.job.updateJob(payload);

			if (!objectChecker(result, ["error"])) {
				if (successCallback) {
					const res = getResultFromApiResponse(result);
					successCallback(res?.procedure_results);
				}
			}
		} catch (err) {
			if (failCallback) {
				failCallback(err);
			}
		}
	};
};

export const getJobDetail_NEW = (id = 0) => {
	return async (dispatch) => {
		try {
			const result = await Service.job.getJobDetail({ id });

			if (!objectChecker(result, ["error"])) {
				const res = result?.data?.[0]?.job_main;
				dispatch(setJobDetail_NEW(res));
			} else {
				dispatch(setJobDetail_NEW({}));
			}
		} catch (err) {
			dispatch(setJobDetail_NEW({}));
		}
	};
};

export const getJobList = (
	_params = {
		page: 1,
		per_page: 30,
		search_name_term: null,
		filters: {},
	}
) => {
	return async (dispatch, getState) => {
		const state = getState();
		const curJobList = state.job.jobList_NEW;

		if (curJobList.loading) return;

		dispatch(setJobList_NEW({ loading: true }));

		const curFilters = curJobList.filters;
		const jobStateC = _params.filters?.job_state_c ?? curFilters?.job_state_c;

		const params = {
			page_number: _params.page ?? curJobList.page ?? 1,
			page_count: _params.per_page ?? curJobList.per_page ?? 30,
			search_name_term: _params?.search_name_term,
			...(jobStateC ? { job_state_c: jobStateC } : {}),
			// filters: _params.filters ?? {},
			// ...(_params.sort ? { sort: _params.sort } : {}),
		};

		const payload = {
			data: [],
			totalCount: 0,
			count_closed: 0,
			count_open: 0,
			count_total: 0,
			count_won: 0,
			page: params.page_number,
			per_page: params.page_count,
			search_name_term: params.search_name_term,
			filters: params.filters,
			...(params.sort ? { sort: params.sort } : {}),
			loading: false,
		};

		try {
			const result = await Service.job.getJobList(params);
			const data = get(result, "response[0].data");
			const totalCount = get(result, "response[0].count_total");

			if (data && totalCount) {
				payload.data = data;
				payload.totalCount = totalCount;
				payload.count_open = result?.response?.[0]?.count_open || 0;
				payload.count_closed = result?.response?.[0]?.count_closed || 0;
				payload.count_won = result?.response?.[0]?.count_won || 0;
			}
		} catch (error) {
			console.log("error: get job list => ", error);
		} finally {
			dispatch(setJobList_NEW(payload));
		}
	};
};

export const requestJobMainList_NEW = () => {
	return {
		type: actionTypes.REQUEST_JOB_MAINLIST_NEW,
	};
};

export const receivedJobMainList_NEW = () => {
	return {
		type: actionTypes.RECEIVED_JOB_MAINLIST_NEW,
	};
};

export const setJobMainList_NEW = (data) => {
	return {
		type: actionTypes.SET_JOB_MAINLIST_NEW,
		payload: data,
	};
};

export const getJobMainList_NEW = (params) => {
	return async (dispatch) => {
		dispatch(requestJobMainList_NEW());

		const _params = {
			searchTerm: params?.searchTerm ?? "",
			pageNumber: params?.pageNumber ?? 1,
			pageCount: params?.pageCount ?? 30,
			param: params?.param ?? null,
		};

		const payload = {
			searchTerm: _params.searchTerm,
			pageNumber: _params.pageNumber,
			pageCount: _params.pageCount,
			totalCount: 0,
			data: [],
		};

		try {
			const data = await Service.job.getJobMainList(_params);

			if (data?.data) {
				const jobs = data?.data?.map((item) => item.job_main);
				payload.data = jobs;
				payload.totalCount = jobs.length;
			}
		} catch (err) {
			console.log("getJobMainList_NEW error : ", err);
		} finally {
			dispatch(setJobMainList_NEW(payload));
		}
	};
};

/**
 * Update job description: job_detail of job_main table
 *
 * @param {*} id
 * @param {*} descriptoins
 * @param {*} successCallback
 * @param {*} failCallback
 * @returns
 */
export const updateJobDescriptions_NEW = (
	id,
	descriptoins,
	successCallback,
	failCallback
) => {
	return async (dispatch) => {
		const payload = [
			{
				[MODEL.job_main._name]: {
					[MODEL.job_main.id]: id,
					[MODEL.job_main.job_detail]: descriptoins,
				},
			},
		];

		dispatch(updateJob_NEW(payload, successCallback, failCallback));
	};
};

/**
 * Job Application List
 */
export const requestJobApplicationList_NEW = () => {
	return {
		type: actionTypes.REQUEST_JOB_APPLICATION_LIST_NEW,
	};
};

export const receivedJobApplicationList_NEW = () => {
	return {
		type: actionTypes.RECEIVED_JOB_APPLICATION_LIST_NEW,
	};
};

export const setJobApplicationList_NEW = (data) => {
	return {
		type: actionTypes.SET_JOB_APPLICATION_LIST_NEW,
		payload: data,
	};
};

/**
 * Get Job Application List
 *
 * @param {*} params
 * @param {*} successCallback
 * @param {*} failCallback
 */
export const getJobApplicationList_NEW = (params, successCallback, failCallback) => {
	return async (dispatch, getState) => {
		const state = getState();
		const curJobApplicationList = state.job.jobApplicationList_NEW;

		if (curJobApplicationList?.isRequesting) return;

		dispatch(requestJobApplicationList_NEW());

		const job_main_ref_id = MODEL.job_application.job_main_ref_id;
		const custom_filter =
			params?.custom_filter !== undefined
				? params?.custom_filter
				: curJobApplicationList.custom_filter ?? null;

		const _params = {
			jobId:
				params?.[job_main_ref_id] ?? curJobApplicationList[job_main_ref_id] ?? 0,
			page: params?.page ?? curJobApplicationList.page ?? 1,
			per_page: params?.per_page ?? curJobApplicationList.per_page ?? 30,
			...(custom_filter ? { custom_filter } : {}),
		};

		const sortInfo = params?.sort ?? curJobApplicationList.sort;

		const payload = {
			[job_main_ref_id]: _params.jobId,
			page: _params.page,
			per_page: _params.per_page,
			totalCount: 0,
			data: [],
			filters: _params.filters,
			search_term: _params?.search_term,
			custom_filter: custom_filter || null,
		};

		if (sortInfo?.length > 0) {
			_params.sort = sortInfo;
			payload.sort = sortInfo;
		}

		try {
			const result = await Service.job.getJobApplicationList(_params);
			const data = get(result, "response.job_application.data", []);
			const totalCount = get(result, "response.job_application.count_total", 0);

			if (data) {
				payload.data = data;
				payload.totalCount = totalCount || 0;

				if (successCallback) {
					successCallback();
				}
			}
		} catch (err) {
			dispatch(
				logError("fetch_failure", "none", {
					Error: "Error fetching job application data: " + JSON.stringify(err),
				})
			);

			if (failCallback) {
				failCallback();
			}
		} finally {
			dispatch(setJobApplicationList_NEW(payload));
		}
	};
};

/**
 * Add sits from job
 */
export const addJobSITs_NEW = (jobId, ids, successCallback, failCallback) => {
	return async () => {
		try {
			if (ids?.length > 0) {
				const params = ids?.map((id) => {
					return {
						[MODEL.job_requirement_sit._name]: {
							[MODEL.job_requirement_sit.job_main_ref_id]: jobId,
							[MODEL.job_requirement_sit.sit_main_ref_id]: id,
						},
					};
				});

				const result = await Service.job.updateJob(params);

				if (!get(result, "error")) {
					if (successCallback) {
						const res = getResultFromApiResponse(result);
						successCallback(res?.procedure_results);
					}
				}
			} else {
				if (successCallback) {
					successCallback({ error: false });
				}
			}
		} catch (err) {
			if (failCallback) {
				failCallback(err);
			}
		}
	};
};

/**
 * delete sits from job
 */
export const deleteJobSITs_NEW = (jobId, ids, successCallback, failCallback) => {
	return async () => {
		try {
			if (ids?.length > 0) {
				const params = ids?.map((id) => {
					return {
						[MODEL.job_requirement_sit._name]: {
							[MODEL.job_requirement_sit.id]: id,
						},
					};
				});

				const result = await Service.job.deleteJob(params);

				if (!get(result, "error")) {
					if (successCallback) {
						const res = getResultFromApiResponse(result);
						successCallback(res?.procedure_results);
					}
				}
			} else {
				if (successCallback) {
					successCallback({ error: false });
				}
			}
		} catch (err) {
			if (failCallback) {
				failCallback(err);
			}
		}
	};
};

export const setJobContactsForPerson = (data) => {
	return {
		type: actionTypes.JOB_CONTACTS_FOR_PERSON,
		payload: data,
	};
};

/**
 * Get Job Contacts List for person
 * @param {*} params
 * @param {*} successCallBack
 * @param {*} failCallBack
 */
export const getJobContactsForPerson = (params, successCallBack, failCallBack) => {
	return async (dispatch) => {
		try {
			const result = await Service.job.getJobContactsForPerson(params);
			const data = get(result, "response.data", []);
			if (data) {
				dispatch(setJobContactsForPerson(data));
				if (successCallBack) {
					successCallBack();
				}
			}
		} catch (err) {
			dispatch(
				logError("fetch_failure", "none", {
					Error:
						"Error fetching job contacts for person data: " +
						JSON.stringify(err),
				})
			);

			dispatch(setJobContactsForPerson(null));

			if (failCallBack) {
				failCallBack();
			}
		}
	};
};

export const setJobApplicationsForPerson = (data) => {
	return {
		type: actionTypes.JOB_APPLICATIONS_FOR_PERSON,
		payload: data,
	};
};

/**
 * Get Job Application List for person
 * @param {*} params
 * @param {*} successCallBack
 * @param {*} failCallBack
 */
export const getJobApplicationsForPerson = (params, successCallBack, failCallBack) => {
	return async (dispatch) => {
		try {
			const result = await Service.job.getJobApplicationsForPerson(params);
			const data = get(result, "data.job_application", []);
			if (data) {
				dispatch(setJobApplicationsForPerson(data));
				if (successCallBack) {
					successCallBack();
				}
			}
		} catch (err) {
			dispatch(
				logError("fetch_failure", "none", {
					Error:
						"Error fetching job application for person data: " +
						JSON.stringify(err),
				})
			);

			dispatch(setJobApplicationsForPerson(null));

			if (failCallBack) {
				failCallBack();
			}
		}
	};
};

/**
 * Get person list for job applicant table
 */
export const getPersonListForJobApplicant = (params, successCallback, failCallback) => {
	return async (dispatch) => {
		try {
			const result = await Service.person.getPersonList(params);
			const data = get(result, "response.data", null);

			if (data) {
				if (successCallback) {
					successCallback(data);
				}
			} else {
				if (failCallback) {
					failCallback(result);
				}
			}
		} catch (err) {
			dispatch(
				logError("fetch_failure", "none", {
					Error: "Error fetching job application data: " + JSON.stringify(err),
				})
			);

			if (failCallback) {
				failCallback(err);
			}
		}
	};
};

/* --------------------------------------------------------------------- */
