import React, { useState } from "react";
import styled from "styled-components";
import {
	EuiButtonEmpty,
	EuiPopover,
	EuiFlexItem,
	EuiButtonIcon,
	EuiFlexGroup,
	EuiTextArea,
} from "@elastic/eui";

/**
 * Component for Editable Textarea Item
 */
const EditableTextareaItem = (props) => {
	const { value, onChange, onSave } = props;

	const [isOpenPopover, setIsOpenPopover] = useState(false);
	const [data, setData] = useState(value);

	const handleOpenPopover = () => {
		setIsOpenPopover(true);
	};

	const handleClosePopover = () => {
		setIsOpenPopover(false);
	};

	const handleChangeValue = (e) => {
		const newValue = e.target.value;

		if (onChange) {
			onChange(newValue);
		}

		setData(newValue);
	};

	const handleSave = () => {
		if (onSave) {
			onSave(data);
		}
		setIsOpenPopover(false);
	};

	const handleCancel = () => {
		setData(value);
		setIsOpenPopover(false);
	};

	return (
		<Wrapper>
			<EuiPopover
				button={
					<>
						<EuiButtonEmpty
							className="button-fit-content"
							onClick={handleOpenPopover}
						>
							<p>{value}</p>
						</EuiButtonEmpty>
					</>
				}
				isOpen={isOpenPopover}
				closePopover={handleClosePopover}
				panelPaddingSize="s"
				anchorPosition="downCenter"
				panelStyle={{
					width: 200,
				}}
			>
				<EuiFlexGroup direction="column" gutterSize="s">
					<EuiFlexItem>
						<EuiTextArea
							placeholder="Placeholder text"
							value={data}
							onChange={handleChangeValue}
							resize="none"
							compressed
						/>
					</EuiFlexItem>
					{onSave && (
						<EuiFlexItem>
							<EuiFlexItem grow={false}>
								<EuiFlexGroup gutterSize="s" justifyContent="center">
									<EuiFlexItem grow={false}>
										<EuiButtonIcon
											color="success"
											onClick={handleSave}
											iconType="check"
										/>
									</EuiFlexItem>
									<EuiFlexItem grow={false}>
										<EuiButtonIcon
											color="danger"
											onClick={handleCancel}
											iconType="cross"
										/>
									</EuiFlexItem>
								</EuiFlexGroup>
							</EuiFlexItem>
						</EuiFlexItem>
					)}
				</EuiFlexGroup>
			</EuiPopover>
		</Wrapper>
	);
};

export default EditableTextareaItem;

const Wrapper = styled.div`
	display: flex;
	position: relative;
	width: 100%;
`;
