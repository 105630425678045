import React from "react";
import styled from "styled-components";
import moment from "moment";

import { ACTIVITY_VIEW_TYPES } from "components/global/constants";
import { AmsTaskPriorityItem, AmsTaskTypeItem } from "components/Common";
import { getLocaleDate } from "components/global/utils";

/**
 * Component to show notification for reminder
 * @param {*} props
 * props = {
 *      taskType,
 *      taskTime,
 *      recordName,
 *      recordLink,
 *      taskName,
 *      taskPriority: "Medium",
 *      activityName,
 *  }
 * @returns
 */
export const ActivityNotificationTitle = (props) => {
	const { activityName } = props;

	return (
		<Wrapper className="activity_notification--header">
			{ACTIVITY_VIEW_TYPES.activity.icon}
			<span className="type-small-default activity_name">
				{activityName || "-"}
			</span>
		</Wrapper>
	);
};

export const ActivityNotificationBody = (props) => {
	const { taskType, taskTime, recordName, taskName, taskPriority } = props;

	const taskDueDate = taskTime
		? moment(getLocaleDate(taskTime)).format("MMM, DD YYYY @ hh:mma")
		: "-";

	return (
		<Wrapper>
			<div className="reminder_notification_header--container">
				<span className="type-x-small">{taskDueDate}</span>
				<span className="type-x-small">&nbsp;for {recordName}</span>
			</div>
			<div className="reminder_notification_body--container">
				<div className="task_notification_priority">
					<AmsTaskPriorityItem priority={taskPriority} isEdit={false} />
				</div>
				<div className="task_notification_type">
					<AmsTaskTypeItem type={taskType} isEdit={false} />
				</div>
				<div>{taskName || "-"}</div>
			</div>
		</Wrapper>
	);
};

const Wrapper = styled.div`
	&.activity_notification {
		&--header {
			display: flex;
			align-items: center;

			.activity_name {
				color: var(--eui-darkshade-color);
				margin-left: var(--sp-4);
			}
		}
	}

	.reminder_notification_header {
		&--container {
			display: flex;
			align-items: center;
		}
	}

	.task_notification_priority {
		margin-right: var(--sp-4);
	}

	.task_notification_type {
		margin-right: var(--sp-2);
	}

	.task_notification_name {
		h3 {
			font-size: var(--sp-6);
			line-height: var(--sp-6);
			color: var(--link-color);
		}
		button {
			min-width: 0;
			min-height: 0;
			padding: 0;
		}
		svg {
			width: var(--sp-8);
			height: auto;
		}
	}

	.reminder_notification_body--container {
		display: flex;
		width: 100%;
		margin-top: var(--sp-2);
		align-items: center;
	}
`;
