import { useDispatch } from "react-redux";

import useSocketHandlerNotification from "./useSocketHandlerNotification";

import SocketService from "./socket";
import { SOCKET_TYPES } from "./constants";
import { useUser } from "core/useHooks";
import { useEffect } from "react";

/**
 * Handler for All Sockets
 *
 * We register all socket events here
 * and process related actions.
 */
const useDansSocket = () => {
	const dispatch = useDispatch();
	const { token } = useUser();
	const { handleSocketNotificationResponse } = useSocketHandlerNotification();

	const onResponses = [
		{
			name: "dans-response",
			handler: handleSocketNotificationResponse,
		},
		{
			name: "notification",
			handler: handleSocketNotificationResponse,
		},
	];

	if (SocketService.instance) {
		for (let i in onResponses) {
			const { name, handler } = onResponses[i];
			SocketService.instance.on(name, (response) => {
				handler(dispatch, response);
			});
		}
	}

	useEffect(() => {
		SocketService.init(token, SOCKET_TYPES.dansClient.type);
	}, [token]);

	return;
};

export default useDansSocket;
