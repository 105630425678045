import React from "react";
import { EuiAvatar } from "@elastic/eui";

/**
 * Accept an image, and the initials in case that image is not
 * provided.
 */

function AvatarWithPicture({ picture, initials, size, ...rest }) {
	//console.log({rest});

	let display = null;

	if (!size) {
		size = "s";
	}

	if (picture) {
		display = <EuiAvatar size={size} name={initials} imageUrl={picture.toString()} />;
	} else {
		if (initials) {
			display = <EuiAvatar {...rest} size={size} name={initials} />;
		} else {
			display = <EuiAvatar {...rest} size={size} name="N A" />;
		}
	}

	return display;
}

export default AvatarWithPicture;
