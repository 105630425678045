import React from 'react';

const Disabled = ({children}) => {
    return (
        <div
            onClick={() => alert('This feature is intended to be disabled')}
            style={{
                opacity: '.5',
                pointerEvents: 'none',
            }}
        >
            {children}
        </div>
    );
};

export default Disabled;
