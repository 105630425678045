import React, { useEffect, useState } from "react";
import {
	EuiFlexItem,
	EuiFlexGroup,
	EuiAvatar,
	EuiButtonEmpty,
	EuiButtonIcon,
	EuiTextArea,
} from "@elastic/eui";
import styled from "styled-components";

import { getDate } from "helpers/utilities";
import { menuDotsHorizontal } from "components/Common/Icons";

import CompensationNoteListNewItem from "./CompensationNoteListNewItem";

/**
 * Component for displaying Note List Item of AMS
 */
const CompensationNoteListItem = (props) => {
	const { data, onSaveNote, onDeleteNote } = props;

	// Reference ams_activity_note model
	const [note, setNote] = useState({
		id: null,
		index: 0,
		name: "",
		date_created: null,
		note_text: "",
	});
	const [isEdit, setIsEdit] = useState(false);
	const [isOpenPopover, setIsOpenPopover] = useState(false);

	const getInitData = () => {
		return {
			index: data?.index,
			id: data?.id || null,
			name: data?.name || "",
			date_created: data?.date_created || null,
			note_text: data?.note_text || "",
		};
	};

	const handleOpenPopover = () => {
		setIsOpenPopover(!isOpenPopover);
	};

	const handleClosePopover = () => {
		setIsOpenPopover(false);
	};

	const handleClickEdit = () => {
		setIsEdit(true);
	};

	const handleSave = () => {
		setIsEdit(false);
		onSaveNote(note);
	};

	const handleCancel = () => {
		const initData = getInitData();
		setNote(initData);
		setIsEdit(false);
	};

	const handleClickDelete = () => {
		onDeleteNote(note);
	};

	const handleChangeNoteText = (e) => {
		const noteText = e.target.value;
		setNote({ ...note, note_text: noteText });
	};

	useEffect(() => {
		const initData = getInitData();
		setNote(initData);
	}, [data]);

	return (
		<Wrapper>
			<EuiFlexGroup gutterSize="s">
				<EuiFlexItem grow={false}>
					<EuiAvatar size="s" name={note.name} />
				</EuiFlexItem>
				<EuiFlexItem>
					<div className="note__container">
						<EuiFlexGroup direction="column" gutterSize="s">
							<EuiFlexItem>
								<div className="note-content_container">
									<div className="note-date">
										<p>
											<span className="type-p-regular">
												{note.name}
											</span>
											&nbsp;
											<span>added a comment</span>
											&nbsp;
											<span>
												{note.date_created
													? getDate(
															note.date_created,
															"MMM D, YYYY"
													  )
													: "-"}
											</span>
										</p>
										<CompensationNoteListNewItem
											button={
												<EuiButtonEmpty
													size="xs"
													className="setting-icon-button"
													onClick={handleOpenPopover}
												>
													<div className="icon-button-img">
														{menuDotsHorizontal(
															"var(--primary-color)"
														)}
													</div>
												</EuiButtonEmpty>
											}
											isOpenPopover={isOpenPopover}
											onClosePopover={handleClosePopover}
											onEdit={handleClickEdit}
											onDelete={handleClickDelete}
										/>
									</div>
									<div className="note-comment">
										<EuiFlexGroup gutterSize="s" direction="column">
											<EuiFlexItem>
												{isEdit ? (
													<EuiTextArea
														placeholder="Enter note"
														value={note.note_text}
														onChange={handleChangeNoteText}
														className="textfield-fit-conent"
													/>
												) : (
													<p>{note.note_text}</p>
												)}
											</EuiFlexItem>
											{isEdit ? (
												<EuiFlexItem grow={false}>
													<EuiFlexGroup
														gutterSize="xs"
														justifyContent="center"
													>
														<EuiFlexItem grow={false}>
															<EuiButtonIcon
																color="success"
																onClick={handleSave}
																iconType="check"
															/>
														</EuiFlexItem>
														<EuiFlexItem grow={false}>
															<EuiButtonIcon
																color="danger"
																onClick={handleCancel}
																iconType="cross"
															/>
														</EuiFlexItem>
													</EuiFlexGroup>
												</EuiFlexItem>
											) : null}
										</EuiFlexGroup>
									</div>
								</div>
							</EuiFlexItem>
						</EuiFlexGroup>
					</div>
				</EuiFlexItem>
			</EuiFlexGroup>
			<div className="left-sidebar" />
		</Wrapper>
	);
};

export default CompensationNoteListItem;

const Wrapper = styled.div`
	display: flex;
	width: 100%;
	position: relative;

	.note__container {
		width: 100%;
		margin-bottom: var(--sp-5);
	}

	.left-sidebar {
		position: absolute;
		top: var(--sp-12);
		left: var(--sp-5_5);
		content: "";
		height: calc(100% - var(--sp-12));
		width: var(--sp-1);
		background: #d4dae5;
	}

	.icon-button-img {
		align-items: center;
		display: flex;
	}

	.note-content_container {
		width: 100%;

		.note-date {
			display: flex;
			justify-content: space-between;
			align-items: center;

			padding: 0 var(--sp-5);
			background: linear-gradient(0deg, #f5f7fa, #f5f7fa);
			border: 1px solid #d4dae5;
			box-sizing: border-box;
			border-radius: var(--sp-3) var(--sp-3) 0 0;
		}

		.note-comment {
			padding: var(--sp-3) var(--sp-4);
			border: 1px solid #d4dae5;
			box-sizing: border-box;
			border-radius: 0 0 var(--sp-3) var(--sp-3);
			border-top: none;

			.euiFormControlLayout {
				max-width: 100%;
			}
		}
	}
`;
