import API from "api";
import Service from "core/service";
import * as MODEL from "core/model";

import * as actionTypes from "./actionTypes";
import { logError } from "../error";
import { getResultFromApiResponse, objectChecker } from "helpers/utilities";
import { MAX_RECORD_CNT } from "core/service/constant";
import { setNotification } from "store/notification";

export const verifyCredential = (params) => {
	return async (dispatch) => {
		try {
			dispatch({
				type: actionTypes.SET_CREDENTIAL_MAIN_ID,
				payload: params.credential_main_id,
			});

			const result = await API().Credential().verifyCredential(params);

			if (result) {
				dispatch({
					type: actionTypes.VERIFY_CREDENTIAL,
					payload: result,
				});
			}
		} catch (err) {
			logError("failure", "none", {
				Error: "Error verify credential data: " + JSON.stringify(err),
			});
		}
	};
};

export const verifyO365Email = (params, successCallback, failCallback) => {
	return async (dispatch) => {
		try {
			const result = await API().Credential().verifyO365Email(params);

			if (result) {
				dispatch({
					type: actionTypes.VERIFY_O365_EMAIL,
					payload: result,
				});
				dispatch(
					setNotification({
						type: "normal",
						message: result?.message,
						status: "success",
					})
				);
				if (successCallback) {
					successCallback(result);
				}
			}
		} catch (err) {
			logError("failure", "none", {
				Error: "Error verify O365 email: " + JSON.stringify(err),
			});
			// alert("Error verify O365 email: " + JSON.stringify(err));
			dispatch(
				setNotification({
					type: "normal",
					message: err?.message,
					status: "error",
				})
			);
			if (failCallback) {
				failCallback(err);
			}
		}
	};
};

export const verifyVOIPPhone = (params, successCallback, failCallback) => {
	return async (dispatch) => {
		try {
			const result = await API().Credential().verifyVOIPPhone(params);

			if (result) {
				dispatch({
					type: actionTypes.VERIFY_VOIP_PHONE,
					payload: result,
				});
				dispatch(
					setNotification({
						type: "normal",
						message: result?.data,
						status: "success",
					})
				);
				if (successCallback) {
					successCallback(result);
				}
			}
		} catch (err) {
			logError("failure", "none", {
				Error: "Error verify VOIP Phone: " + JSON.stringify(err),
			});
			// alert("Error verify O365 email: " + JSON.stringify(err));
			dispatch(
				setNotification({
					type: "normal",
					message: err?.data,
					status: "error",
				})
			);
			if (failCallback) {
				failCallback(err);
			}
		}
	};
};

export const verifySMSPhone = (params, successCallback, failCallback) => {
	return async (dispatch) => {
		try {
			const result = await API().Credential().verifySMSPhone(params);

			if (result) {
				dispatch({
					type: actionTypes.VERIFY_SMS_PHONE,
					payload: result,
				});
				dispatch(
					setNotification({
						type: "normal",
						message: result?.message,
						status: "success",
					})
				);
				if (successCallback) {
					successCallback(result);
				}
			}
		} catch (err) {
			logError("failure", "none", {
				Error: "Error verify SMS Phone: " + JSON.stringify(err),
			});
			// alert("Error verify O365 email: " + JSON.stringify(err));
			dispatch(
				setNotification({
					type: "normal",
					message: err?.message,
					status: "error",
				})
			);
			if (failCallback) {
				failCallback(err);
			}
		}
	};
};

export const credentialVerificationNotification = (data) => {
	return {
		type: actionTypes.CREDENTIAl_VERIFICATION_NOFICATION,
		payload: data,
	};
};

export const deleteCredential = (params, successCallback, failCallback) => {
	return async (dispatch) => {
		try {
			const result = await Service.credential.deleteCredential(params);

			if (!objectChecker(result, ["error"])) {
				if (successCallback) {
					const res = getResultFromApiResponse(result);
					successCallback(res?.procedure_results);
				}
			}
		} catch (err) {
			if (failCallback) {
				failCallback(err);
			}
		}
	};
};

export const updateCredential = (params, successCallback, failCallback) => {
	return async (dispatch) => {
		try {
			const result = await Service.credential.updateCredential(params);

			if (!objectChecker(result, ["error"])) {
				if (successCallback) {
					const res = getResultFromApiResponse(result);
					successCallback(res?.procedure_results);
				}
			}
		} catch (err) {
			console.log("err in saving credential. err: ", err);
			if (failCallback) {
				failCallback(err);
			}
		}
	};
};

export const setCredentialsByAccount_NEW = (data) => {
	return {
		type: actionTypes.CREDENTIALS_BY_ACCOUNT,
		payload: data,
	};
};

export const getCredentialsByAccount_NEW = (accountId) => {
	return async (dispatch) => {
		try {
			const data = await Service.credential.getCredentialsByAccount({
				accountId,
			});

			if (!data?.error && !!data?.response?.data?.length) {
				const credentials = data?.response?.data
					?.filter((item) => item?.credential_main?.record_state_sc !== 2)
					?.map((item) => {
						return {
							credential_main: item?.credential_main,
						};
					});

				dispatch(setCredentialsByAccount_NEW(credentials));
			} else {
				dispatch(setCredentialsByAccount_NEW(null));
			}
		} catch (err) {
			dispatch(
				logError("fetch_failure", "none", {
					Error: "Error fetching credential data: " + JSON.stringify(err),
				})
			);
			dispatch(setCredentialsByAccount_NEW(null));
		}
	};
};

export const setCredentials_NEW = (data) => {
	return {
		type: actionTypes.ALL_CREDENTIALS,
		payload: data,
	};
};
export const getCredentials_NEW = () => {
	return async (dispatch) => {
		try {
			const data = await Service.credential.getCredentialList({
				page_count: MAX_RECORD_CNT,
			});

			const credentials = objectChecker(data, ["response", "data"]);

			if (credentials) {
				dispatch(setCredentials_NEW(credentials));
			} else {
				dispatch(setCredentials_NEW(null));
			}
		} catch (err) {
			dispatch(
				logError("fetch_failure", "none", {
					Error: "Error fetching credential data: " + JSON.stringify(err),
				})
			);
			dispatch(setCredentials_NEW(null));
		}
	};
};

/** ===================== NEW ============================= */
export const setCredentialDetails = (data) => {
	return async (dispatch) => {
		dispatch({
			type: actionTypes.CREDENTIAL_DETAILS,
			payload: data,
		});
	};
};

export const getCredentialDetails = ({ id = -1 }) => {
	return async (dispatch) => {
		try {
			const data = await Service.credential.getCredentialDetail({ id });
			const credentialDetail = objectChecker(data.data[0], [
				MODEL.credential_main._name,
			]);

			if (credentialDetail) {
				dispatch(setCredentialDetails(credentialDetail));
			} else {
				dispatch(setCredentialDetails(null));
			}
		} catch (err) {
			dispatch(setCredentialDetails(null));
		} finally {
		}
	};
};

export const setUnassignedPlivoPhone = (data) => {
	return {
		type: actionTypes.UNASSIGNED_PLIVO_PHONE,
		payload: data,
	};
};
export const getUnassignedPlivoPhone = () => {
	return async (dispatch) => {
		try {
			const data = await Service.credential.getUnassignedPlivoPhone({});

			const unassignedPhones = objectChecker(data, ["data"]);

			if (unassignedPhones) {
				dispatch(setUnassignedPlivoPhone(unassignedPhones));
			} else {
				dispatch(setUnassignedPlivoPhone(null));
			}
		} catch (err) {
			dispatch(
				logError("fetch_failure", "none", {
					Error:
						"Error fetching Unassigned Plive Phone data: " +
						JSON.stringify(err),
				})
			);
			dispatch(setUnassignedPlivoPhone(null));
		}
	};
};

export const setAssignedPlivoPhone = (data) => {
	return {
		type: actionTypes.UNASSIGNED_PLIVO_PHONE,
		payload: data,
	};
};
export const getAssignedPlivoPhone = () => {
	return async (dispatch) => {
		try {
			const data = await Service.credential.getAssignedPlivoPhone({});

			const assignedPhones = objectChecker(data, ["data"]);

			if (assignedPhones) {
				dispatch(setAssignedPlivoPhone(assignedPhones));
			} else {
				dispatch(setAssignedPlivoPhone(null));
			}
		} catch (err) {
			dispatch(
				logError("fetch_failure", "none", {
					Error:
						"Error fetching Assigned Plive Phone data: " +
						JSON.stringify(err),
				})
			);
			dispatch(setAssignedPlivoPhone(null));
		}
	};
};

export const saveLinkedInVerificationCode = (params) => {
	return async (dispatch) => {
		try {
			const data = await Service.credential.saveLinkedinVerificationCode(params);

			dispatch(
				setNotification({
					type: "normal",
					message: "saved veirification Code.",
					status: "success",
				})
			);
		} catch (err) {
			dispatch(
				logError("fetch_failure", "none", {
					Error:
						"Error fetching Unassigned Plive Phone data: " +
						JSON.stringify(err),
				})
			);
		}
	};
};

export const getMsgraphAuthorizeUrl = (params, successCallback, failCallback) => {
	return async (dispatch) => {
		try {
			const result = await Service.credential.getMsgraphAuthorizeUrl(params);

			if (!objectChecker(result, ["error"])) {
				if (successCallback) {
					const res = objectChecker(result, ["data"]);
					successCallback(res);
				}
			} else {
				if (failCallback) {
					failCallback(result);
				}
			}
		} catch (err) {
			console.log("err in get msgraph authorize url err: ", err);
			if (failCallback) {
				failCallback(err);
			}
		}
	};
};
