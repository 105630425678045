/**
 * Here the name convention is the same with the name convention of the table in database.
 * So here the same name will be used.
 */
/**
 * Models for CMS ( Communcaation Management System )
 */
const cms_conversation_main = {
	_name: "cms_conversation_main",
	id: "id",
	date_created: "date_created",
	conversation_type: "conversation_type",
	conversation_id_external: "conversation_id_external",
	owner_accont_main_ref_id: "owner_accont_main_ref_id",
	conversation_state_sc: "conversation_state_sc",
};

const cms_conversation_member = {
	_name: "cms_conversation_member",
	id: "id",
	date_joined: "date_joined",
	member_type_c: "member_type_c",
	member_originator: "member_originator",
	member_object_type_sc: "member_object_type_sc",
	member_object_subtype_sc: "member_object_subtype_sc",
	member_object_ref_id: "member_object_ref_id",
	member_custom_id: "member_custom_id",
	member_communication_key: "member_communication_key",
	cms_conversation_main_ref_id: "cms_conversation_main_ref_id",
};

const cms_communication_main = {
	_name: "cms_communication_main",
	id: "id",
	data_joined: "date_joined",
	comm_id_external: "comm_id_external",
	comm_platform_c: "comm_platform_c",
	comm_direcation_c: "comm_direcation_c",
	comm_state_sc: "comm_state_sc",
	comm_timestamp_sc: "comm_timestamp_sc",
	comm_details: "comm_details",
	member_communication_key: "member_communication_key",
	cms_conversation_main_ref_id: "cms_conversation_main_ref_id",
};

const cms_communication_object_link = {
	_name: "cms_communication_object_link",
	id: "id",
	link_type_c: "link_type_c",
	link_object_type_sc: "link_object_type_sc",
	link_object_subtype_sc: "link_object_subtype_sc",
	link_object_rocord_id: "link_object_rocord_id",
	cms_communication_main_ref_id: "cms_communication_main_ref_id",
};

const cms_communication_tracking = {
	_name: "cms_communication_tracking",
	id: "id",
	date_created: "date_created",
	tracking_type_c: "tracking_type_c",
	tracking_method_c: "tracking_method_c",
	tracking_details: "tracking_details",
	cms_communication_main_ref_id: "cms_communication_main_ref_id",
};

const cms_repository_unmapped = {
	_name: "cms_repository_unmapped",
	id: "id",
	date_created: "date_created",
	unmapped_reason_c: "unmapped_reason_c",
	unmapped_details: "unmapped_details",
	cms_conversation_main_ref_id: "cms_conversation_main_ref_id",
};

const cms_communication_archive = {
	_name: "cms_communication_archive",
	id: "id",
	archive_details: "archive_details",
	cms_conversation_main_ref_id: "cms_conversation_main_ref_id",
};

const cms_communication_attachment = {
	_name: "cms_communication_attachment",
	id: "id",
	file_main_ref_id: "file_main_ref_id",
	attachment_details: "attachment_details",
	cms_communication_main_ref_id: "cms_communication_main_ref_id",
};

const cms_association = {
	_name: "cms_association",
	id: "id",
	cms_object_table_sc: "cms_object_table_sc",
	cms_object_record_id: "cms_object_record_id",
	ref_object_base_sc: "ref_object_base_sc",
	ref_obejct_table_sc: "ref_obejct_table_sc",
	ref_obejct_record_id: "ref_obejct_record_id",
};

const cms_log_activity = {
	_name: "cms_log_activity",
	id: "id",
	date_created: "date_created",
	account_main_ref_id: "account_main_ref_id",
	log_object_c: "log_object_c",
	log_type_c: "log_type_c",
	log_action_c: "log_action_c",
	record_ref_id: "record_ref_id",
	log_details: "log_details",
};

const cms_communication_draft = {
	_name: "cms_communication_draft",
	id: "id",
	account_main_ref_id: "account_main_ref_id",
	member_object_type_sc: "member_object_type_sc",
	member_object_subtype_sc: "member_object_subtype_sc",
	member_object_record_id: "member_object_record_id",
	credential_main_ref_id: "credential_main_ref_id",
	conversation_type_c: "conversation_type_c",
	comm_platform_c: "comm_platform_c",
	comm_details: "comm_details",
};

export {
	cms_conversation_main,
	cms_conversation_member,
	cms_communication_main,
	cms_communication_object_link,
	cms_communication_tracking,
	cms_repository_unmapped,
	cms_communication_archive,
	cms_communication_attachment,
	cms_association,
	cms_log_activity,
	cms_communication_draft,
};
