import { useDispatch } from "react-redux";
import { updateAms, deleteAms, getPersonAmsList } from "helpers/actions";

/**
 * Hooks for AMS
 */
const useAms = () => {
	const dispatch = useDispatch();

	const handleUpdateAms = (params, successCallBack, failCallBack) => {
		dispatch(updateAms(params, successCallBack, failCallBack));
	};
	const handleDeleteAms = (params, successCallBack, failCallBack) => {
		dispatch(deleteAms(params, successCallBack, failCallBack));
	};

	const getAmsList = (params, successCallBack, failCallBack) => {
		dispatch(getPersonAmsList(params, successCallBack, failCallBack));
	};

	return {
		updateAms: handleUpdateAms,
		deleteAms: handleDeleteAms,
		getAmsList,
	};
};

export default useAms;
