import React, { useMemo } from "react";

import { EditableSelect } from "components/Common";
import { AMS_REMINDER_TYPE_LIST } from "components/global/constants";

/**
 * Component for AMS Reminder Type Item with editable feature
 */
const AmsReminderTypeItem = (props) => {
	const { onChange, type = 1, isEdit = true } = props;

	const options = useMemo(() => {
		const options = Object.values(AMS_REMINDER_TYPE_LIST).map((item) => {
			return {
				id: item.browser,
				value: item.value,
				label: item.label,
				info: item,
			};
		});
		return options;
	}, []);

	const handleChangeType = (newType) => {
		if (onChange) {
			onChange(newType);
		}
	};

	return (
		<EditableSelect
			options={options}
			value={type}
			onChange={handleChangeType}
			hasUnderline={false}
			disabled={!isEdit}
			panelWidth={95}
			labelStyle={{
				fontSize: "var(--sp-6)",
			}}
		/>
	);
};

export default AmsReminderTypeItem;
