import React, { useEffect, useState } from "react";
import styled from "styled-components";

import { useAccount, useCms, useGlobal, usePerson } from "core/useHooks";

import { MessageEditorHeader } from "../MessageEditor";
import { MESSAGE_BTNS } from "../constants";

import {
	MessageSenderEmail,
	MessageSenderLinkedIn,
	MessageSenderPhone,
	MessageSenderChat,
	MessageSenderGroup,
	MessageSenderUrlLink,
} from "./editors";
import { getGSSValue } from "components/global/utils";

/**
 * Main Components
 */
const MessageEditor = (props) => {
	const { hasHeader = true, person, params, data, isListItem } = props;

	const {
		callSendCmsLinkedInMessage: onSendLinkeInMessage,
		callSendLinkedinConnectionRequest: onSendLinkedinConnectionRequest,
		callSendEmailMessage: onSendEmailMessage,
		callSendSmsMessage: onSendSmsMessage,
		callSaveEmailMessage: onSaveEmailMessage,
		getPersonCommunicationDraftList,
		getPersonCommunicationList,
	} = useCms();
	const { account } = useAccount();
	const { gssList } = useGlobal();

	const { savePersonPhone, deletePerson, savePersonEmail } = usePerson();

	const [selectedId, setSelectedId] = useState(MESSAGE_BTNS.EMAIL_BTN);

	const handleSelected = (id) => {
		setSelectedId(id);
	};

	const getCmsMainList = () => {
		const personId = person?.id;
		// Get the last 50 records for cms_communication_main
		const member_object_type = getGSSValue(gssList, "OBJECT_BASE", "PERSON");
		const member_object_subtype = getGSSValue(gssList, "OBJECT_PERSON", "MAIN");
		const member_object_record_id = personId ? Number(personId) : personId;

		const cmsMainParams = {
			cms_conversation_member: {
				member_object_type,
				member_object_subtype,
				member_object_record_id,
			},
			...(!isListItem ? { pageCount: 50, pageNumber: 1 } : {}),
		};

		getPersonCommunicationList(cmsMainParams);
	};

	const getCmsDraftList = () => {
		// Get the last 50 records for cms_communication_draft
		const personId = person?.id;
		const account_main_id = account?.id;
		const member_object_type_sc = getGSSValue(gssList, "OBJECT_BASE", "PERSON");
		const member_object_subtype_sc = getGSSValue(gssList, "OBJECT_PERSON", "MAIN");
		const member_object_record_id = personId ? Number(personId) : personId;

		const cmsDraftParams = {
			account_main_id,
			member_object_type_sc,
			member_object_subtype_sc,
			member_object_record_id,
			...(!isListItem ? { pageCount: 50, pageNumber: 1 } : {}),
		};

		getPersonCommunicationDraftList(cmsDraftParams);
	};

	useEffect(() => {
		const type = params?.type;
		if (type) {
			setSelectedId(type);
		}
	}, [params]);

	const renderBody = () => {
		switch (selectedId) {
			case MESSAGE_BTNS.EMAIL_BTN:
				return (
					<MessageSenderEmail
						person={person}
						onSendEmailMessage={onSendEmailMessage}
						onSaveEmailMessage={onSaveEmailMessage}
						savePersonPhone={savePersonPhone}
						deletePerson={deletePerson}
						savePersonEmail={savePersonEmail}
						initEmail={params?.value}
						data={data ? data : null}
						getCmsDraftList={getCmsDraftList}
						getCmsMainList={getCmsMainList}
					/>
				);

			case MESSAGE_BTNS.LINKEDIN_BTN:
				return (
					<MessageSenderLinkedIn
						person={person}
						onSendLinkedinConnectionRequest={onSendLinkedinConnectionRequest}
						onSendLinkeInMessage={onSendLinkeInMessage}
						initLinkedIn={params?.value}
						data={data ? data : null}
						getCmsDraftList={getCmsDraftList}
						getCmsMainList={getCmsMainList}
					/>
				);

			case MESSAGE_BTNS.CHAT_BTN:
				return (
					<MessageSenderChat
						person={person}
						onSendSmsMessage={onSendSmsMessage}
						savePersonPhone={savePersonPhone}
						deletePerson={deletePerson}
						savePersonEmail={savePersonEmail}
						initPhone={params?.value}
						getCmsDraftList={getCmsDraftList}
						getCmsMainList={getCmsMainList}
					/>
				);

			case MESSAGE_BTNS.PHONE_BTN:
				return (
					<MessageSenderPhone
						person={person}
						savePersonPhone={savePersonPhone}
						deletePerson={deletePerson}
						savePersonEmail={savePersonEmail}
						initPhone={params?.value}
						getCmsDraftList={getCmsDraftList}
						getCmsMainList={getCmsMainList}
					/>
				);

			case MESSAGE_BTNS.GROUP_BTN:
				return (
					<MessageSenderGroup
						person={person}
						getCmsDraftList={getCmsDraftList}
						getCmsMainList={getCmsMainList}
					/>
				);

			case MESSAGE_BTNS.URL_LINK_BTN:
				return <MessageSenderUrlLink />;

			default:
				return null;
		}
	};

	return (
		<Wrapper>
			{hasHeader && (
				<MessageEditorHeader
					selectedId={selectedId}
					handleSelected={handleSelected}
				/>
			)}
			{renderBody()}
		</Wrapper>
	);
};

export default MessageEditor;

/**
 * Styled Components
 */
const Wrapper = styled.div`
	padding: var(--sp-2);

	.euiTextArea {
		padding: var(--sp-2_5);
		overflow: hidden;
		height: var(--sp-13_5);
		background: var(--empty-shade-bg-color);
		box-shadow: unset;
	}

	.cms-buttons-wrapper {
		display: flex;
		background: var(--eui-lightest-shade-color);
		border-radius: var(--sp-2);
		padding: var(--sp-3_5) var(--sp-2_5);

		.right-side {
			display: flex;
			align-items: center;
			margin-left: auto;
			gap: var(--sp-2);
		}

		.left-side {
			display: flex;
			align-items: center;
			margin-right: auto;
			gap: var(--sp-2);

			.recruiting-sales-button {
				cursor: pointer;
				width: var(--sp-10) !important;
				height: auto !important;
			}
		}

		.message-status-icon {
			height: var(--sp-10_5);
			width: auto;
			cursor: pointer;
		}
	}
`;
