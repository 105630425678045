import React, { useMemo } from "react";
import styled from "styled-components";
import {
    EuiDragDropContext,
    EuiDraggable,
    EuiDroppable,
    EuiPanel,
    euiDragDropReorder,
    EuiIcon,
} from "@elastic/eui";
import { htmlIdGenerator } from "@elastic/eui/lib/services";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/pro-light-svg-icons/faTimes";

import useOpenTabs from "../utils/useOpenTabs";
import AvatarWithPicture from "components/Common/AvatarWithPicture";

import variables from "assets/css/variables.scss";
import {
    campaignIcon,
    companyIcon,
    dashboardIcon,
    jobIcon,
    peopleIcon,
    sourcingIcon,
} from "components/Common/Icons";

const makeId = htmlIdGenerator();

/**
 * Main Component for Open Tabs in Second Header
 */
const OpenTabs = () => {
    const { openTabs, setActiveTab, closeTab, setTabList } = useOpenTabs();

    // get icon for catetory
    const getIconByName = (catName) => {
        let icon = null;
        switch (catName) {
            case "Dashboard":
                icon = dashboardIcon();
                break;
            case "Campaigns":
                icon = campaignIcon();
                break;
            case "Person":
                icon = peopleIcon();
                break;
            case "Company":
                icon = companyIcon();
                break;
            case "Sourcing":
                icon = sourcingIcon();
                break;
            case "Job":
                icon = jobIcon();
                break;
            default:
                icon = null;
        }
        return icon;
    };

    const tabList = useMemo(() => {
        if (!openTabs) {
            return null;
        }

        return openTabs.map((val, index) => {
            const { name, isActive, avatar_link, icon } = val;

            const matches = name?.match(/\b(\w)/g) || [];
            const initials = matches?.join(" ").toUpperCase() || "";
            const avatar = avatar_link || null;

            const onActiveTab = () => setActiveTab(index);
            const onCloseTab = (e) => {
                e.stopPropagation();
                closeTab(index);
            };

            const content = (
                <div onClick={onActiveTab} className={`tab ${isActive ? "active" : ""}`}>
                    <div className="tab-data">
                        {icon ? (
                            <span className="display-icon">{getIconByName(name)}</span>
                        ) : (
                            <span className="display-avatar">
                                <AvatarWithPicture
                                    key={index}
                                    initials={initials}
                                    picture={avatar}
                                />
                            </span>
                        )}

                        <span className="display-span">{name}</span>
                    </div>

                    <div onClick={onCloseTab} className="close-tab">
                        <FontAwesomeIcon icon={faTimes} color="#69707D" />
                    </div>
                </div>
            );

            return {
                content,
                id: makeId(),
                data: val,
            };
        });
    }, [closeTab, openTabs, setActiveTab]);

    const onDragEnd = ({ source, destination }) => {
        if (source && destination) {
            const items = euiDragDropReorder(tabList, source.index, destination.index);
            const _openTabs = items.map((item) => item.data);
            setTabList(_openTabs);
        }
    };

    // Render
    if (!tabList || tabList.length === 0) {
        return <></>;
    }

    return (
        <TabsWrapper>
            <EuiDragDropContext onDragEnd={onDragEnd}>
                <EuiDroppable
                    droppableId="open-tabs"
                    spacing="none"
                    className="tabs-container"
                    direction="horizontal"
                >
                    {tabList.map(({ content, id }, idx) => (
                        <EuiDraggable
                            spacing="none"
                            key={id}
                            index={idx}
                            draggableId={id}
                            className="tab-item"
                            mode="virtual"
                        >
                            {(provided, state) => (
                                <EuiPanel
                                    hasShadow={state.isDragging}
                                    className="tab-item-container"
                                >
                                    {content}
                                </EuiPanel>
                            )}
                        </EuiDraggable>
                    ))}
                </EuiDroppable>
            </EuiDragDropContext>
        </TabsWrapper>
    );
};

export default OpenTabs;

const TabsWrapper = styled.div`
    .tabs-container {
        display: flex;
        flex-direction: row;
        overflow-y: hidden;
        overflow-x: auto;
        background-color: #ffffff !important;
        padding: 0;
        box-shadow: 0 2px 2px -1px rgba(152, 162, 179, 0.3),
            0 1px 5px -2px rgba(152, 162, 179, 0.3);
    }

    .tab-item {
        display: flex;
        box-sizing: border-box;
        min-width: 140px;
        max-width: 200px;

        color: ${variables.primaryColor};
        height: 40px;
        background-color: #ffffff;

        .tab-item-container {
            align-items: center;
            display: flex;
            padding: 0;
            border-radius: 0;
            border: none;
            border-right: 1px solid #d3dae6;
            border-bottom: 0;

            & .tab {
                display: flex;
                position: relative;
                padding: 0 10px;
                width: 100%;
                height: 100%;
                align-items: center;
                margin-top: -2px;

                &.active {
                    // color: #006bb4;
                    border-bottom: 2px solid #006bb4;
                    background-color: #f5f7fa;

                    // & .display-avatar div,
                    // .display-icon svg {
                    //     filter: drop-shadow(0 0.1rem 0.1rem silver);
                    // }
                }

                & .close-tab {
                    display: none;
                    position: absolute;
                    right: 0;
                    margin-right: 4px;
                    height: 16px;
                    padding: 10px 6.5px;
                    background: #eef2f7;
                    border: 0.5px solid #d3dae6;
                    box-sizing: border-box;
                    border-radius: 2px;
                    text-align: center;
                    align-items: center;
                    justify-content: center;
                    cursor: pointer;
                }

                &:hover {
                    color: ${variables.primaryColor};
                    border-bottom: 2px solid #006bb4;
                    background-color: #f5f7fa;

                    .close-tab {
                        display: flex;
                    }
                }
            }

            & .tab-data {
                display: flex;
                align-items: center;
                margin: 0;
            }

            & span.display-icon,
            & span.display-avatar {
                margin-top: 0;
                margin-right: 5px;
            }

            & span.display-span {
                text-overflow: ellipsis;
                white-space: nowrap;
                max-width: 149px;
                overflow: hidden;
            }
        }

        &.euiDraggable--isDragging {
            & .tab {
                color: #006bb4;
                border-bottom: 2px solid #006bb4;
                background-color: #f5f7fa;
                margin-top: -1px;

                & .display-avatar div,
                .display-icon svg {
                    filter: drop-shadow(0 0.1rem 0.1rem silver);
                }

                .close-tab {
                    display: flex;
                }
            }
        }
    }
`;
