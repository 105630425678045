import API from "api";

import { credential_setting } from "core/model";

import { makeFieldContent } from "./commonService";

/** ---------------- Generic API ---------------------- */
const getCredentialsByAccount = ({ accountId }) => {
    const query = {
        owner_account_id: accountId,
        page_number: 1,
        page_count: 50,
    };

    return API().Credential().getCredentialList(query);
};

const getCredentialSettingById = (id) => {
    const query = {
        table_main: credential_setting._name,
        fields: [
            makeFieldContent(credential_setting, credential_setting.id),
            makeFieldContent(
                credential_setting,
                credential_setting.credential_main_ref_id
            ),
            makeFieldContent(credential_setting, credential_setting.setting_type_c),
            makeFieldContent(credential_setting, credential_setting.setting_value),
        ],
        search_type: "id",
        search_id: id,
    };

    return API().GenericAPI().getTableData(query);
};

const getCredentialDetail = ({ id }) => {
    const query = {
        object: "credential",
        record_main_id: id,
    };

    return API().GenericAPI().getMainData(query);
};

const updateCredential = (data) => {
    const query = {
        object: "credential",
        data: data || {},
    };

    return API().GenericAPI().updateTableData(query);
};

const deleteCredential = (data) => {
    const query = {
        object: "credential",
        ...data,
    };

    return API().GenericAPI().deleteTableData(query);
};
/** --------------------------------------------------------- */

/** ----------------------- Custom API ---------------------- */
const getCredentialList = (data) => {
    return API().Credential().getCredentialList(data);
};

const getUnassignedPlivoPhone = (data) => {
    return API().Credential().getUnassignedPlivoPhone(data);
};

const getAssignedPlivoPhone = (data) => {
    return API().Credential().getAssignedPlivoPhone(data);
};

const saveLinkedinVerificationCode = (data) => {
    return API().Credential().saveLinkedinVerificationCode(data);
};

const getMsgraphAuthorizeUrl = (data) => {
    return API().Credential().getMsgraphAuthorizeUrl(data);
};
/* ---------------------------------------------------------- */

export default {
    getCredentialDetail,
    getCredentialsByAccount,
    getCredentialList,
    getCredentialSettingById,
    updateCredential,
    deleteCredential,
    getUnassignedPlivoPhone,
    getAssignedPlivoPhone,
    saveLinkedinVerificationCode,
    getMsgraphAuthorizeUrl,
};
