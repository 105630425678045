export const REMINDER_SNOOZE_LIST = [
	{ label: "5 minutes", value: 300 },
	{ label: "10 minutes", value: 600 },
	{ label: "15 minutes", value: 900 },
	{ label: "20 minutes", value: 1200 },
	{ label: "25 minutes", value: 1500 },
	{ label: "30 minutes", value: 1800 },
	{ label: "45 minutes", value: 2700 },
	{ label: "1 hour", value: 3600 },
];
