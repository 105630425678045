import React from "react";
import styled from "styled-components";
import { EuiFlexGroup, EuiFlexItem, EuiTabbedContent } from "@elastic/eui";
import JobDescription from "./JobDescription";
import JobSkill from "./JobSkill";
import JobCompensation from "./JobCompensation";
/**
 * Main Components
 */
const JobOverview = (props) => {
    const { application, saveValue, ...restProps } = props;

    // functions
    const handleSaveValue = (value) => {
        saveValue(value);
    };
    // tabs
    const tabs = [
        {
            id: "description",
            name: "Description",
            content: (
                <JobDescription application={application} saveValue={handleSaveValue} />
            ),
        },
        {
            id: "skill",
            name: "Skills",
            content: <JobSkill />,
        },
        {
            id: "compensation",
            name: "Compensation",
            content: <JobCompensation />,
        },
    ];
    return (
        <Wrapper {...restProps}>
            <EuiFlexGroup gutterSize="s" direction="row" justifyContent="spaceBetween">
                <EuiFlexItem>
                    <EuiTabbedContent
                        tabs={tabs}
                        initialSelectedTab={tabs[0]}
                        autoFocus="selected"
                        onTabClick={() => {}}
                    />
                </EuiFlexItem>
            </EuiFlexGroup>
        </Wrapper>
    );
};

export default JobOverview;

/**
 * Styled Components
 */
const Wrapper = styled.div`
    .euiTab__content {
        font-size: 1rem !important;
    }
`;
