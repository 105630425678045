import * as actionTypes from "./actionTypes";

// Functions
const setPlivoLogin = (state, payload) => {
    return {
        ...state,
        login: payload,
    };
};

const setPlivoConnection = (state, payload) => {
    return {
        ...state,
        connection: payload,
    };
};

const setPlivoCallState = (state, payload) => {
    return {
        ...state,
        callState: {
            ...state.callState,
            ...payload,
        },
    };
};

// Initial State
const initialState = {
    login: false,
    connection: {
        state: "disconnect",
        eventReason: "init",
    },
    callState: {},
};

// Reducer
const Reducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.SET_PLIVO_LOGIN:
            return setPlivoLogin(state, action.payload);
        case actionTypes.SET_PLIVO_CONNECTION:
            return setPlivoConnection(state, action.payload);
        case actionTypes.SET_PLIVO_CALL_STATE:
            return setPlivoCallState(state, action.payload);
        default: {
            return state;
        }
    }
};

export default Reducer;
