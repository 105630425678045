import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { EuiAccordion, EuiLoadingContent, EuiText, EuiIcon } from "@elastic/eui";

/**
 * This component is a customized accordion header that has a header
 * and a body. It also has an inner state for open 1.
 */

function AccordionWithHeader({ children, headerItems, isLoading, isOpen = null }) {
	const [open, setOpen] = useState("open");

	useEffect(() => {
		if (isOpen !== null) {
			setOpen(isOpen);
		}
	}, [isOpen]);

	const onToggle = () => {
		if (open === "open") {
			setOpen("closed");
		} else {
			setOpen("open");
		}
	};

	const headerStyles = {
		borderRadius: "5px",
		border: "1px solid #D3DAE6",
		padding: "8px 10px",
		position: "relative",
		paddingRight: "50px",
		backgroundColor: "white",
		boxShadow: "0px 2px 2px rgba(152, 162, 179, 0.15)",
		borderBottomLeftRadius: "4px",
		borderBottomRightRadius: "4px",
	};

	const textStyles = {
		color: "#006BB4",
		marginRight: "5px",
	};

	const chevronStyles = {
		position: "absolute",
		right: 0,
		top: "50%",
		transform: "translate(-50%, -50%)",
	};

	const bodyStyles = {
		marginTop: "-5px",
		border: "1px solid #D3DAE6",
		backgroundColor: "white",
		boxShadow:
			"0px 1px 5px rgba(152, 162, 179, 0.15), 0px 2px 2px rgba(152, 162, 179, 0.15)",
		borderRadius: "4px",
		padding: "0!important",
		margin: "0!important",
	};

	return (
		<Wrapper>
			<div style={headerStyles} className="header-wrapper">
				<div>{isLoading ? <EuiLoadingContent lines={2} /> : headerItems}</div>
				<div style={chevronStyles}>
					<EuiText style={textStyles}>
						<button onClick={onToggle}>
							<EuiIcon type={open === "open" ? "arrowDown" : "arrowUp"} />
						</button>
					</EuiText>
				</div>
			</div>
			<EuiAccordion
				id={"my-accordion"}
				paddingSize={"none"}
				style={bodyStyles}
				arrowDisplay="none"
				buttonContent={null}
				forceState={open}
				onClick={() => {}}
			>
				{children}
			</EuiAccordion>
		</Wrapper>
	);
}

export default AccordionWithHeader;

const Wrapper = styled.div`
	width: 100%;
	.header-wrapper {
		background-color: var(--lightest-shade-color) !important;
	}
`;
