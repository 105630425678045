import React from "react";
import { EuiFlexGroup, EuiFlexItem, EuiSelect, EuiText } from "@elastic/eui";
import { htmlIdGenerator } from "@elastic/eui/lib/services";
import styled from "styled-components";

import variables from "assets/css/variables.scss";

const SelectWithHeader = (props) => {
	const { label, onChange, options, id, value, ariaLabel } = props;

	const handleChange = (e) => {
		onChange(e.target.value);
	};

	return (
		<Wrapper>
			<EuiFlexGroup gutterSize="xs" direction="column">
				<EuiFlexItem>
					<EuiText size="xs" textAlign="left" className="item-title">
						{label}
					</EuiText>
				</EuiFlexItem>
				<EuiFlexItem grow={false} className="detail-select">
					<EuiSelect id={id ?? htmlIdGenerator()()} options={options} value={value} onChange={handleChange} aria-label={ariaLabel} />
				</EuiFlexItem>
			</EuiFlexGroup>
		</Wrapper>
	);
};

export default SelectWithHeader;

const Wrapper = styled.div`
	.item-title {
		font-weight: 600;
		color: ${variables.lightColor};
	}

	.detail-value {
		color: ${variables.linkColor};
	}

	.detail-select {
		width: fit-content;

		& > div {
			height: auto;
		}
	}
`;
