import {
    JobStatusClosedCancelIcon,
    JobStatusClosedClientIcon,
    JobStatusClosedCompetitionIcon,
    JobStatusClosedContractIcon,
    JobStatusOpenAcceptingIcon,
    JobStatusOpenNotAcceptingIcon,
    JobStatusWonActiveIcon,
    JobStatusWonCompleteIcon,
    JobStatusWonFailedIcon,
} from "components/Common/Icons";

export function getJobStatusIcon(status) {
    switch (status) {
        case "Open Accepting":
            return JobStatusOpenAcceptingIcon();
        case "Open notaccepting":
            return JobStatusOpenNotAcceptingIcon();
        case "Closed cancelled":
            return JobStatusClosedCancelIcon();
        case "Closed contract terms":
            return JobStatusClosedContractIcon();
        case "Closed client filled":
            return JobStatusClosedClientIcon();
        case "Closed competition":
            return JobStatusClosedCompetitionIcon();
        case "Won active":
            return JobStatusWonActiveIcon();
        case "Won complete":
            return JobStatusWonCompleteIcon();
        case "Won falloff":
            return JobStatusWonFailedIcon();
        default:
            return JobStatusOpenAcceptingIcon();
    }
}
