import useMessageFlyout from "./MessageFlyout/useMessageFlyout";
import { useWorkflowHistoryFlyout } from "./Workflow";
import useSitFlyout from "./Sit/useSitFlyout";

const Flyouts = {
	useMessageFlyout,
	useWorkflowHistoryFlyout,
	useSitFlyout,
};

export default Flyouts;
