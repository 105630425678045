import React from "react";
import styled from "styled-components";

import { SelectCreateCompany, SVGIcon } from "components/Common";
import { websiteIcon } from "components/Common/Icons";

/**
 * Main Components
 */
const PersonJobCompany = (props) => {
	const { company, options, onChangeCompany, onChangeSearch, ...restProps } = props;

	const handleChangeCompany = (val) => {
		onChangeCompany(val);
	};

	const handleChangeSearch = (val) => {
		onChangeSearch(val);
	};

	return (
		<Wrapper {...restProps} className="mb-3">
			<SVGIcon icon={websiteIcon} className="mt-2 mr-2" />
			<SelectCreateCompany
				placeholder="Company"
				company={company ? company : {}}
				options={options}
				onChangeCompany={handleChangeCompany}
				onChangeSearch={handleChangeSearch}
			/>
		</Wrapper>
	);
};

export default PersonJobCompany;

/**
 * Styled Components
 */
const Wrapper = styled.div`
	display: flex;
	width: 100%;

	.icon-button {
		margin: auto 0 0 0.5rem;
	}

	.euiFormControlLayout {
		height: 25px;
	}
`;
