import { SET_LOADING } from "./actionTypes";

export const setLoading = (data) => {
	return async (dispatch) => {
		dispatch({
			type: SET_LOADING,
			payload: data,
		});
	};
};
