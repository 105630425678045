import React, { useState, useMemo } from "react";
import styled from "styled-components";
import { EuiFieldText } from "@elastic/eui";
import { faMapMarker } from "@fortawesome/pro-light-svg-icons/faMapMarker";
import { objectChecker } from "helpers/utilities";

import { AsyncEditableContent } from "components/Common/asyncComponents";

/**
 * Constants
 */
const ADDRESS_EDITOR_TYPES = {
	personal: {
		type: "personal",
		trackingKey: "person_phone_id",
	},
	employment: {
		type: "employment",
		trackingKey: "person_history_work_id",
	},
};

/**
 * Main Component for Address with editable feature
 */
const PersonAddress = (props) => {
	const { personId, data, syncData, loadData, type = ADDRESS_EDITOR_TYPES.personal.type } = props;

	const [loading, setLoading] = useState(false);

	const editType = useMemo(() => {
		const _typeKey = Object.getOwnPropertyNames(ADDRESS_EDITOR_TYPES).find(
			(key) => ADDRESS_EDITOR_TYPES[key].type === type
		);

		return _typeKey ? ADDRESS_EDITOR_TYPES[_typeKey] : undefined;
	}, [type]);

	const editDisplayOverride = (editData, setEditData) => {
		if (!editData || !setEditData) return <></>;
		if (editType === ADDRESS_EDITOR_TYPES.employment && (editData.length === 0 || !editData[0]))
			return <></>;

		let params;

		if (editType === ADDRESS_EDITOR_TYPES.employment) {
			params = editData[0];
		} else {
			params = editData;
		}

		const {
			address_line_1,
			address_line_2,
			address_city,
			address_state,
			address_zip,
			address_country,
		} = params;

		const handleChange = (event) => {
			// Tracking ID.
			const key = event.target.name;
			const value = event.target.value;

			const newEditedData = {
				...editData,
				[key]: value,
			};

			setEditData(newEditedData);
		};

		return (
			<EditorWrapper>
				<div className="address-section--1">
					<EuiFieldText
						className={'py-0'}
						placeholder={"Address Line 1"}
						name={"address_line_1"}
						value={address_line_1 || ""}
						onChange={handleChange}
					/>
				</div>
				<div className="address-section--2">
					<EuiFieldText
						className={'py-0'}
						placeholder={"Address Line 2"}
						name={"address_line_2"}
						value={address_line_2 || ""}
						onChange={handleChange}
					/>
				</div>
				<div className="address-section--3">
					<EuiFieldText
						className={'py-0'}
						placeholder={"City"}
						name={"address_city"}
						value={address_city || ""}
						onChange={handleChange}
					/>
				</div>
				<div className="address-section--4">
					<EuiFieldText
						className={'py-0'}
						placeholder={"State"}
						name={"address_state"}
						className="address-state"
						value={address_state || ""}
						onChange={handleChange}
					/>
					<EuiFieldText
						className={'py-0'}
						placeholder={"Zip Code"}
						name={"address_zip"}
						className="address-zip"
						value={address_zip || ""}
						onChange={handleChange}
					/>
				</div>

				<div className="address-section--5">
					<EuiFieldText
						className={'py-0'}
						placeholder={"Country"}
						name={"address_country"}
						value={address_country || ""}
						onChange={handleChange}
					/>
				</div>
			</EditorWrapper>
		);
	};

	const renderAddress = (address) => {
		if (!address) return <></>;
		if (editType === ADDRESS_EDITOR_TYPES.employment && (address.length === 0 || !address[0]))
			return <></>;

		let params;

		if (editType === ADDRESS_EDITOR_TYPES.employment) {
			params = address[0];
		} else {
			params = address;
		}

		const {
			address_line_1,
			address_line_2,
			address_city,
			address_state,
			address_zip,
			address_country,
		} = params;

		return (
			<div className="address">
				{address_line_1 && <div className="address-entry">{address_line_1}</div>}
				{address_line_2 && <div className="address-entry">{address_line_2}</div>}
				{address_city && <div className="address-entry">{address_city}</div>}
				{address_state && <div className="address-entry">{address_state}</div>}
				{(address_zip || address_country) && (
					<div className="address-entry">
						{address_zip ? `${address_zip} ` : ""}
						{address_country ?? ""}
					</div>
				)}
			</div>
		);
	};

	const handleOnSaveForPersonal = (editedData) => {
		if (!editedData) return;

		const {
			person_address_id,
			address_line_1,
			address_line_2,
			address_city,
			address_state,
			address_zip,
			address_country,
		} = editedData;

		let params = {
			person_main_id: personId,
			address_type: "home",
			person_address_id,
			address_line_1,
			address_line_2,
			address_city,
			address_state,
			address_zip,
			address_country,
		};

		for (let i in params) {
			if (params[i] === null || params[i].toString().trim() === "") {
				delete params[i];
			}
		}

		syncData([], [{ args: params }], [], [], setLoading, loadData);
	};

	const handleOnSaveForEmployment = (editedData) => {
		if (!editedData || editedData.length === 0) return;

		const paramData = editedData[0];
		const trackingKey = ADDRESS_EDITOR_TYPES.employment.trackingKey;

		const {
			address_line_1,
			address_line_2,
			address_city,
			address_state,
			address_zip,
			address_country,
		} = paramData;

		let params = {
			person_main_ref_id: personId,
			global_address_details: {
				address_line_1,
				address_line_2,
				address_city,
				address_state,
				address_zip,
				address_country,
			},
		};

		for (let i in params["global_address_details"]) {
			const data = params["global_address_details"][i];

			if (data === null || data.toString().trim() === "") {
				delete params["global_address_details"][i];
			}
		}

		// Clean the data to prevent unexpected edits.
		let filterList = ["person_main_ref_id", trackingKey, "global_address_details"];

		for (let i in params) {
			if (!filterList.includes(i)) {
				delete params[i];
			}
		}

		// Attach ID if existing (this will make it execute the update command instead of create)
		if (objectChecker(paramData, [trackingKey])) {
			params["person_work_history_id"] = paramData[trackingKey];
		}

		syncData([], [{ args: params }], [], [], setLoading, loadData);
	};

	const handleOnSave = (editData) => {
		switch (type) {
			case ADDRESS_EDITOR_TYPES.personal.type:
				handleOnSaveForPersonal(editData);
				break;

			case ADDRESS_EDITOR_TYPES.employment.type:
				handleOnSaveForEmployment(editData);
				break;

			default:
				console.log("Cannot save inproper type!!! The used type is ", type);
				break;
		}
	};

	if (!editType) return <></>;

	return (
		<AsyncEditableContent
			isLoading={loading}
			icon={faMapMarker}
			data={data}
			placeholder={"Home/Cell Phone"}
			editDisplayKey={"phone_number"}
			editTrackingKey={editType.trackingKey || ""}
			editDisplayOverride={editDisplayOverride}
			onSaveHandler={handleOnSave}
			render={renderAddress}
			onAddHandler={() => { }}
		/>
	);
};

export default PersonAddress;

const EditorWrapper = styled.div`
	margin-right: 10px;

	.euiFormControlLayout {
		height: 25px;
		margin-bottom: 4px;
	}

	.address-section--1 {
		display: flex;
		flex-direction: row;
	}

	.address-section--4 {
		display: flex;

		& > .euiFormControlLayout {
			width: 135px;
		}
	}
`;
