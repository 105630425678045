import React, { Fragment, useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import styled from "styled-components";
import {
    EuiAvatar,
    EuiButton,
    EuiButtonIcon,
    EuiFlexGroup,
    EuiFlexItem,
} from "@elastic/eui";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faStar, faBriefcase } from "@fortawesome/pro-regular-svg-icons";
import { faPen } from "@fortawesome/pro-light-svg-icons";

import AvatarWithPicture from "../AvatarWithPicture";

import variables from "assets/css/variables.scss";

/**
 * Main component for Company Header
 */
const CompanyHeader = (props) => {
    const { description, avatarLink, qualifiers, favourite, alias, id } = props;

    const [companyName, setCompanyName] = useState("");
    const [companyAlias, setCompanyAlias] = useState("");

    useEffect(() => {
        setCompanyName(description);
        setCompanyAlias(alias);
    }, [description, alias]);

    return (
        <Wrapper>
            <EuiFlexGroup gutterSize="s" alignItems="center">
                <EuiFlexItem
                    grow={false}
                    className="company-person-header-display--avatar"
                >
                    <AvatarWithPicture
                        size="s"
                        initials={description}
                        picture={avatarLink}
                    />
                </EuiFlexItem>
                <EuiFlexItem className="information-section">
                    <EuiFlexGroup gutterSize="xs" direction="column">
                        <EuiFlexItem className="information-section--name">
                            <p>{companyName}</p>
                            <FontAwesomeIcon icon={faStar} color={variables.linkColor} />
                        </EuiFlexItem>
                        <EuiFlexItem
                            grow={false}
                            className="information-section--favourite"
                        >
                            <EuiFlexGroup gutterSize="xs" alignItems="center">
                                <EuiFlexItem grow={false}>
                                    <EuiButton
                                        color="primary"
                                        size="s"
                                        fill
                                        onClick={() => console.log("Button clicked")}
                                        className="information-section--favourite-button active-button"
                                    >
                                        <div>
                                            <FontAwesomeIcon icon={faBriefcase} />
                                            <span>Active Client</span>
                                        </div>
                                    </EuiButton>
                                </EuiFlexItem>
                                <EuiFlexItem grow={false}>
                                    <EuiButton
                                        size="s"
                                        color="text"
                                        onClick={() => window.alert("Button clicked")}
                                        className="information-section--favourite-button edit-button"
                                    >
                                        <FontAwesomeIcon icon={faPen} />
                                    </EuiButton>
                                </EuiFlexItem>
                            </EuiFlexGroup>
                        </EuiFlexItem>
                    </EuiFlexGroup>
                </EuiFlexItem>
                <EuiFlexItem grow={false} className="information-section--identification">
                    <EuiFlexGroup gutterSize="s" alignItems="center">
                        <EuiFlexItem>
                            <AvatarIcon>
                                <EuiAvatar size="none" name="E L" type="user" />
                            </AvatarIcon>
                        </EuiFlexItem>
                        <EuiFlexItem>
                            <VerticalBar />
                        </EuiFlexItem>
                        <EuiFlexItem>
                            <AvatarIcon>
                                <EuiAvatar size="none" name="E L" type="user" />
                            </AvatarIcon>
                        </EuiFlexItem>
                        <EuiFlexItem>
                            <AvatarIcon>
                                <EuiAvatar size="none" name="E L" type="user" />
                            </AvatarIcon>
                        </EuiFlexItem>
                    </EuiFlexGroup>
                </EuiFlexItem>
            </EuiFlexGroup>
        </Wrapper>
    );
};

export default CompanyHeader;

const Wrapper = styled.div`
    display: flex;
    align-items: center;

    & > div {
        min-height: 55px;
    }

    .company-person-header-display--avatar {
        margin-right: 6px;
    }

    .information-section {
        .information-section--name {
            display: flex;
            align-content: center;
            align-items: center;
            flex-direction: row;

            font-size: 1rem;
            line-height: 1.5;

            p {
                font-weight: 600;
                margin-right: 2px;
            }
        }

        .information-section--favourite {
            .information-section--favourite-button {
                padding: 0px;
                min-height: 18px;
                min-width: 0;
                height: fit-content;

                &.active-button {
                    padding: 0 0.8333rem;

                    .euiButton__text {
                        font-size: 0.75rem;
                        line-height: 2;

                        div {
                            display: flex;
                        }

                        svg {
                            width: 1rem;
                            height: auto;
                            margin-right: 0.5rem;
                        }
                    }
                }

                &.edit-button {
                    padding: 0 3px;
                    box-shadow: none;
                    border: 1px solid #d3dae6;

                    .euiButton__text {
                        font-size: 1rem;
                        line-height: 1.6;
                    }
                }

                .euiButton__content {
                    padding: 0;
                }
            }
        }

        .information-section--description {
            display: flex;
        }

        .information-section--identification {
            display: flex;
            align-items: center;

            div:not(.euiPopover) {
                margin-right: 5px;
            }

            .euiPopover {
                height: 20px;
                margin-top: -14px;
            }
        }
    }

    .qualifier-popover-panel {
        width: 155px;

        .custom-context-menu-item {
            & .euiContextMenu__itemLayout {
                flex-direction: row-reverse;

                & svg {
                    margin-right: 0;
                }
            }
        }
    }
`;

const AvatarIcon = styled.div`
    display: flex;

    .euiAvatar {
        width: 1.6667rem;
        height: 1.6667rem;

        span {
            font-weight: 500;
            font-size: 0.875rem;
            line-height: 1.5rem;
        }
    }
`;

const VerticalBar = styled.div`
    display: flex;
    width: 1px;
    height: 2rem;
    background: ${variables.borderColor};
`;
