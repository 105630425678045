import API from "api";
import { ras_assignment_main, ras_restriction_main } from "core/model";

import {
	makeSort,
	makeTableJoin,
	makeFieldContent,
	makeExternalJoin,
	makeLogJoin,
	getLogTypesActions,
} from "./commonService";

/** ---------------- Generic API ---------------------- */
const updateRAS = (data) => {
	const request = {
		object: "ras",
		data: data || {},
	};

	return API().GenericAPI().updateTableData(request);
};

const deleteRAS = (data) => {
	const request = {
		object: "ras",
		data: data || {},
	};

	return API().GenericAPI().deleteTableData(request);
};

const getRASAssignmentByObject = ({
	params = null,
	pageNumber = 1,
	pageCount = 1000,
}) => {
	if (!params) {
		return new Promise((resolve, reject) => {
			resolve(null);
		});
	}

	const { object_type_c, object_ref_id } = params;

	const query = {
		limit_records: 1000,
		page_number: pageNumber,
		page_count: pageCount,
		table_main: ras_assignment_main._name,
		fields: [
			makeFieldContent(ras_assignment_main, ras_assignment_main.id),
			makeFieldContent(ras_assignment_main, ras_assignment_main.object_ref_id),
			makeFieldContent(ras_assignment_main, ras_assignment_main.object_type_c),
			makeFieldContent(ras_assignment_main, ras_assignment_main.assignment_ref_id),
			makeFieldContent(ras_assignment_main, ras_assignment_main.assignment_type_c),
		],
		search_fields: [
			{
				table: ras_assignment_main._name,
				field: ras_assignment_main.object_type_c,
			},
			{
				table: ras_assignment_main._name,
				field: ras_assignment_main.object_ref_id,
			},
		],
		search_type: "ref",
		search_ref: [object_type_c, object_ref_id],
	};

	return API().GenericAPI().getTableData(query);
};

const getRASRestrictionByObject = ({
	params = null,
	pageNumber = 1,
	pageCount = 1000,
}) => {
	if (!params) {
		return new Promise((resolve, reject) => {
			resolve(null);
		});
	}

	const { object_type_c, object_ref_id } = params;

	const query = {
		limit_records: 1000,
		page_number: pageNumber,
		page_count: pageCount,
		table_main: ras_restriction_main._name,
		fields: [
			makeFieldContent(ras_restriction_main, ras_restriction_main.id),
			makeFieldContent(ras_restriction_main, ras_restriction_main.object_ref_id),
			makeFieldContent(ras_restriction_main, ras_restriction_main.object_type_c),
			makeFieldContent(
				ras_restriction_main,
				ras_restriction_main.restriction_type_c
			),
		],
		search_fields: [
			{
				table: ras_restriction_main._name,
				field: ras_restriction_main.object_type_c,
			},
			{
				table: ras_restriction_main._name,
				field: ras_restriction_main.object_ref_id,
			},
		],
		search_type: "ref",
		search_ref: [object_type_c, object_ref_id],
	};

	return API().GenericAPI().getTableData(query);
};

/** --------------------------------------------------------- */

/** ----------------------- Custom API ---------------------- */

/* ---------------------------------------------------------- */
export default {
	updateRAS,
	deleteRAS,
	getRASAssignmentByObject,
	getRASRestrictionByObject,
};
