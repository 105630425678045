import React, { useMemo, useState } from "react";
import styled from "styled-components";
import { EuiPopover, EuiFlexGroup, EuiFlexItem, EuiButtonIcon } from "@elastic/eui";
import { get } from "lodash";

import * as MODEL from "core/model";
import { circleIcon } from "components/Common/Icons";

import { CustomBadge } from "components/Common";
import { PERSON_SELECT_TYPES } from "../constants";
import StaticPhoneItem from "components/Common/Items/Phone/StaticPhoneItem";

/**
 * Component for Showing Phone of Person
 */
const PersonPhoneBadgeItem = (props) => {
    const { onRemove, detail, onChangeItem } = props;

    const [isOpenPopover, setIsOpenPopover] = useState(false);

    const getPhoneNumber = (phone) => {
        const phoneNumber = get(phone, `phone_number`);
        const phoneCountrtCode = get(phone, `phone_country_code`);
        let totalPhoneNumber = `${phoneCountrtCode}${phoneNumber}`;
        if (!totalPhoneNumber.includes("+")) {
            totalPhoneNumber = "+" + totalPhoneNumber;
        }

        return totalPhoneNumber;
    };

    const { phones, personName, selectedPhoneLabel, hasPhones } = useMemo(() => {
        let phones = [];
        let personName = "";
        let selectedPhoneLabel = "";

        const detailType = get(detail, "type");

        let hasPhones = true;

        if (detailType === PERSON_SELECT_TYPES.person.value) {
            // Get person name
            const firstName =
                get(detail, `info.${MODEL.person_main.name_first}`, []) || "";
            const lastName = get(detail, `info.${MODEL.person_main.name_last}`, []) || "";
            personName = `${firstName} ${lastName}`;

            // Get person phones
            const personPhones =
                get(detail, `info.${MODEL.person_phone._name}`, []) || [];
            phones.push(...personPhones);

            // Get selected phone
            const selectedPhone = phones.find(
                (phone, index) => index === detail.selected
            );
            if (selectedPhone) {
                selectedPhoneLabel =
                    getPhoneNumber(selectedPhone.global_phone_details) || personName;
            } else {
                selectedPhoneLabel = personName;
            }
        } else if (detailType === PERSON_SELECT_TYPES.account.value) {
            // Get account name
            const firstName =
                get(detail, `info.${MODEL.account_main.name_first}`, []) || "";
            const lastName =
                get(detail, `info.${MODEL.account_main.name_last}`, []) || "";
            personName = `${firstName} ${lastName}`;

            // Get account phones
            const accountPhones =
                get(detail, `info.${MODEL.global_phone_details._name}`, []) || [];
            const formatedPhones = accountPhones.map((phone) => {
                return {
                    global_phone_details: phone,
                };
            });
            phones.push(...formatedPhones);

            // Get selected phone
            const selectedPhone = phones.find(
                (phone, index) => index === detail.selected
            );
            if (selectedPhone) {
                selectedPhoneLabel =
                    getPhoneNumber(selectedPhone.global_phone_details) || personName;
            } else {
                selectedPhoneLabel = personName;
            }
        } else {
            personName = get(detail, `value`);
            hasPhones = false;
            selectedPhoneLabel = personName;
        }

        return { phones, personName, selectedPhoneLabel, hasPhones };
    }, [detail]);

    const handleOpenPopover = () => {
        if (hasPhones && !!phones?.length) {
            setIsOpenPopover(true);
        }
    };

    const handleClosePopover = () => {
        setIsOpenPopover(false);
    };

    const handleChagePhone = (index) => {
        onChangeItem(index);
    };

    return (
        <Wrapper
            button={
                <div className={`button-wrapper`}>
                    <CustomBadge
                        className={`message-custom-badge-item ${
                            hasPhones && !phones?.length ? "no-phone" : ""
                        }`}
                        icon={circleIcon()}
                        label={selectedPhoneLabel}
                        onRemove={() => onRemove(detail)}
                        onClickLabel={handleOpenPopover}
                    />
                </div>
            }
            isOpen={isOpenPopover}
            closePopover={handleClosePopover}
            panelPaddingSize="s"
            anchorPosition="downCenter"
            panelStyle={{
                minWidth: 200,
            }}
            repositionOnScroll={true}
        >
            <BodyContainer direction="column" gutterSize="s">
                <EuiFlexGroup gutterSize="s" direction="column">
                    <EuiFlexItem>
                        <span className="type-small-bold">{personName}</span>
                    </EuiFlexItem>
                    <EuiFlexItem>
                        <div className="person-phone-list__container">
                            {phones?.map((phone, idx) => {
                                const isSelected =
                                    (detail.selected === undefined && idx === 0) ||
                                    detail.selected === idx;
                                return (
                                    <div
                                        key={`person-phone-list-item-${idx}`}
                                        className="person-phone-list__item"
                                    >
                                        <EuiButtonIcon
                                            iconType="checkInCircleFilled"
                                            className={`person-phone-list__item-check-icon ${
                                                isSelected ? "selected" : ""
                                            }`}
                                            onClick={() => handleChagePhone(idx)}
                                        />
                                        <StaticPhoneItem
                                            phone={phone}
                                            disabled={true}
                                            hasIcon={false}
                                            hasIconLabel={true}
                                        />
                                    </div>
                                );
                            })}
                        </div>
                    </EuiFlexItem>
                </EuiFlexGroup>
            </BodyContainer>
        </Wrapper>
    );
};

export default PersonPhoneBadgeItem;

const Wrapper = styled(EuiPopover)`
    .button-wrapper {
        display: flex;
        align-items: flex-start;
    }

    .message-custom-badge-item {
        padding: 0 var(--sp-4);

        &.no-phone {
            .label {
                color: var(--eui-danger-color);
            }
        }
    }
`;

const BodyContainer = styled(EuiFlexGroup)`
    .person-phone-list {
        &__container {
            display: flex;
            flex-direction: column;
            gap: 0;
        }
        &__item {
            display: flex;
            gap: 0;
            align-items: center;

            &-check-icon {
                min-height: unset;
                height: fit-content;
                color: var(--eui-darkest-shade-color);

                &.selected {
                    color: var(--eui-link-color);
                }
            }
        }
    }
`;
