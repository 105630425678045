import API from "api";

const updateCompensation = (data) => {
	const query = {
		object: "compensation",
		data: data || {},
	};

	return API().GenericAPI().updateTableData(query);
};

const getCompensationDetail = ({ accountId, gssList = [] }) => {
	const query = {
		object: "compensation",
		record_main_id: accountId,
	};
	return API().GenericAPI().getMainData(query);
};

const deleteCompensation = (data) => {
	const request = {
		object: "compensation",
		data: data || {},
	};

	return API().GenericAPI().deleteTableData(request);
};

export default {
	getCompensationDetail,
	updateCompensation,
	deleteCompensation,
};
