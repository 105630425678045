import React from "react";
import styled from "styled-components";

import variables from "assets/css/variables.scss";

/**
 * Main Components
 */
const HorizonStep = (props) => {
	const { steps, ...restProps } = props;

	if (!steps || steps.length === 0) return <></>;

	return (
		<Wrapper {...restProps}>
			<div role="tablist" className="steps">
				{steps.map((step, index) => (
					<div role="tab" className="step" tabIndex="0" title="Step" pass="1" key={index}>
						<div className={step.complete ? "step-number complete" : "step-number"}>{index + 1}</div>
						<div className="step-title">{step.title}</div>
					</div>
				))}
			</div>
		</Wrapper>
	);
};

export default HorizonStep;

/**
 * Styled Components
 */
const Wrapper = styled.div`
	.steps {
		display: flex;
		margin-top: 1rem;

		.step {
			flex-grow: 1;
			display: flex;
			flex-direction: column;
			position: relative;
			align-items: center;

			.step-number {
				width: 32px;
				height: 32px;
				display: inline-block;
				line-height: 32px;
				font-size: 1.3rem;
				border-radius: 32px;
				text-align: center;
				color: ${variables.primaryColor};
				background-color: ${variables.lightestShadeColor};
				z-index: 1;
				&.complete {
					border: solid 2px ${variables.linkColor};
				}
			}

			.step-title {
				color: ${variables.sectionTitleColor};
				font-size: 0.75rem;
				line-height: 1.5rem;
				margin-top: 8px;
				text-align: center;
				max-width: 100%;
			}

			&:not(:first-child):before {
				content: "";
				position: absolute;
				width: 50%;
				height: 2px;
				top: 15px;
				left: 0;
				background-color: ${variables.borderColor};
				z-index: 0;
			}

			&:not(:last-child):after {
				content: "";
				position: absolute;
				width: 50%;
				height: 2px;
				top: 15px;
				right: 0;
				background-color: ${variables.borderColor};
				z-index: 0;
			}
		}
	}
`;
