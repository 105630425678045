import React from "react";
import styled from "styled-components";
import { EuiTitle } from "@elastic/eui";

import PersonalInformationDetailsAndRas from "components/Common/Person/PersonalInformationDetailsAndRas";

/**
 * Main Components
 */
const MessageFlyoutHeader = (props) => {
	const { personId, person } = props;

	return (
		<Wrapper>
			{personId && (
				<PersonalInformationDetailsAndRas
					id={personId}
					onSaveRAS={() => {}}
					details={person}
				/>
			)}
			{!personId && (
				<EuiTitle size="s">
					<h2 id="message-flyout-header">&nbsp;</h2>
				</EuiTitle>
			)}
		</Wrapper>
	);
};

export default MessageFlyoutHeader;

/**
 * Styled Components
 */
const Wrapper = styled.div``;
