import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { EuiFormControlLayout, EuiInputPopover } from "@elastic/eui";

/**
 * Main Components
 */
const SearchAutocomplete = (props) => {
	const {
		placeholder = "Select",
		items = [],
		append = null,
		renderItem = null,
		onChange = () => {},
		onSelect = () => {},
		onSearch = () => {},
		clearVal = false,
	} = props;

	/** items props
	 * id: (required) will be used for showing search value.
	 * title: (required) will be used for search and value.
	 * subTitle: (optional)
	 * location: (optional)
	 * ...
	 */

	const [isPopoverOpen, setIsPopoverOpen] = useState(false);
	const [searchVal, setSearchVal] = useState("");
	const [suggestions, setSuggestions] = useState([]);
	const [highligtedId, setHighlightedId] = useState(null);

	// effect
	// get the options from props variables.
	useEffect(() => {
		setSuggestions(items);
		onSelect(null);
		const highlightItem = items?.find((item) => item.id === highligtedId);
		if (!highlightItem) {
			setHighlightedId(null);
		}
	}, [items]);

	useEffect(() => {
		if (clearVal) {
			setSearchVal("");
		}
	}, [clearVal]);

	// functions
	// show the suggestion list when the input is focused.
	const showSuggestions = () => {
		toggleIsPopoverOpen();
		handleSearchItem(searchVal);
	};

	const handleSearchItem = (val) => {
		const text = val;
		onSearch(text);
		onSelect(null);
		setSearchVal(text);
		onChange(text);
	};

	const handleItem = (index) => {
		setSearchVal(suggestions[index]?.title);
		setHighlightedId(suggestions[index]?.id);
		onSelect(suggestions[index]);
		onChange(suggestions[index]?.title);
	};

	const toggleIsPopoverOpen = (shouldBeOpen = !isPopoverOpen) => {
		setIsPopoverOpen(shouldBeOpen);
	};

	const handleClickAppend = () => {
		toggleIsPopoverOpen(false);
	};

	const clearSearchValue = () => {
		setSearchVal("");
		handleSearchItem("");
	};

	// render UI
	const input = (
		<EuiFormControlLayout clear={{ onClick: () => clearSearchValue() }} icon="search">
			<input
				type="text"
				className="euiFieldSearch"
				aria-label="global-search"
				placeholder={placeholder}
				value={searchVal}
				onChange={(e) => handleSearchItem(e.target.value)}
				onFocus={showSuggestions}
			/>
			<div onClick={handleClickAppend}>{append}</div>
		</EuiFormControlLayout>
	);

	return (
		<Wrapper>
			<EuiInputPopover
				input={input}
				isOpen={isPopoverOpen}
				closePopover={() => {
					toggleIsPopoverOpen(false);
				}}
				buffer={0}
			>
				{suggestions && (
					<SuggestionList className="list-field">
						{suggestions?.map((item, index) => (
							<SuggestionItem
								key={"suggestion" + index}
								onClick={() => {
									handleItem(index);
								}}
							>
								{renderItem(item, highligtedId === item?.id)}
							</SuggestionItem>
						))}
					</SuggestionList>
				)}
			</EuiInputPopover>
		</Wrapper>
	);
};

export default SearchAutocomplete;

/**
 * Styled Components
 */
const Wrapper = styled.div`
	position: relative;

	.euiFormControlLayout {
		.euiFormControlLayout__childrenWrapper {
			display: flex;
		}
	}

	.euiFormControlLayout--group,
	.euiFieldSearch {
		max-width: unset;
		width: 100%;
	}

	input {
		height: 100%;
		padding-top: var(--sp-1);
		padding-bottom: var(--sp-1);
		height: 33px;
	}

	.euiFormControlLayoutIcons--right {
		right: 36px;
		.euiFormControlLayoutClearButton {
			border-radius: 16px;
		}
	}

	button {
		border-radius: 0;
		padding: var(--sp-1) 0;
	}
`;

const SuggestionList = styled.div`
	max-height: 350px;
	overflow: auto;
`;

const SuggestionItem = styled.div`
	cursor: pointer;

	&:hover {
		background: var(--bg-color);
	}
`;
