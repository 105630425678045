import React, { useState } from "react";
import styled from "styled-components";
import { EuiFlexGroup, EuiFlexItem, EuiText, EuiCheckbox } from "@elastic/eui";
import { htmlIdGenerator } from "@elastic/eui/lib/services";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMapMarkerAlt } from "@fortawesome/pro-regular-svg-icons";

import { LOCATION_COMMUTE_TYPES } from "./constants";

import variables from "assets/css/variables.scss";

const CompensationLocationCommute = (props) => {
	const { detailLocation } = props;

	const [checkedItems, setCheckedItems] = useState({
		[LOCATION_COMMUTE_TYPES.relocate.key]: false,
		[LOCATION_COMMUTE_TYPES.remote.key]: false,
	});

	const onChange = (e, key) => {
		setCheckedItems({
			...checkedItems,
			[key]: e.target.checked,
		});
	};

	return (
		<Wrapper>
			<EuiFlexGroup gutterSize="m" direction="column" alignItems="flexStart">
				<EuiFlexItem grow={1} className="type-title">
					<EuiText textAlign="center">
						<span>{`Location & Commute`}</span>
					</EuiText>
				</EuiFlexItem>
				<EuiFlexItem className="type-content">
					<EuiFlexGroup gutterSize="m" direction="column">
						<EuiFlexItem>
							<EuiFlexGroup gutterSize="s" direction="row">
								<EuiFlexItem grow={false}>
									<FontAwesomeIcon icon={faMapMarkerAlt} />
								</EuiFlexItem>
								<EuiFlexItem>
									<EuiFlexGroup gutterSize="xs" direction="column">
										<EuiFlexItem>
											<EuiText textAlign="left" size="s">
												Tempe, Arizona
											</EuiText>
										</EuiFlexItem>
										<EuiFlexItem>
											<EuiText textAlign="left" size="s">
												Within&nbsp;
												<span className="value">25</span>
												&nbsp;Miles&nbsp;or&nbsp;
												<span className="value">45</span>
												&nbsp;Minutes
											</EuiText>
										</EuiFlexItem>
									</EuiFlexGroup>
								</EuiFlexItem>
							</EuiFlexGroup>
						</EuiFlexItem>
						<EuiFlexItem grow={false}>
							<EuiFlexGroup gutterSize="s" direction="row">
								<EuiFlexItem grow={false}>
									<EuiCheckbox
										id={htmlIdGenerator()()}
										label=""
										checked={
											checkedItems[
												LOCATION_COMMUTE_TYPES.relocate.key
											]
										}
										onChange={(e) =>
											onChange(
												e,
												LOCATION_COMMUTE_TYPES.relocate.key
											)
										}
										className="d-flex check-option"
									/>
								</EuiFlexItem>
								<EuiFlexItem>
									<EuiText textAlign="left" size="s">
										{LOCATION_COMMUTE_TYPES.relocate.label}
									</EuiText>
								</EuiFlexItem>
							</EuiFlexGroup>
						</EuiFlexItem>
						<EuiFlexItem grow={false}>
							<EuiFlexGroup gutterSize="s" direction="row">
								<EuiFlexItem grow={false}>
									<EuiCheckbox
										id={htmlIdGenerator()()}
										label=""
										checked={
											checkedItems[
												LOCATION_COMMUTE_TYPES.remote.key
											]
										}
										onChange={(e) =>
											onChange(e, LOCATION_COMMUTE_TYPES.remote.key)
										}
										className="d-flex check-option"
									/>
								</EuiFlexItem>
								<EuiFlexItem>
									<EuiFlexGroup gutterSize="xs" direction="column">
										<EuiFlexItem>
											<EuiText textAlign="left" size="s">
												{LOCATION_COMMUTE_TYPES.remote.label}
											</EuiText>
										</EuiFlexItem>
										{checkedItems[
											LOCATION_COMMUTE_TYPES.remote.key
										] ? (
											<EuiFlexItem>
												<EuiText textAlign="left" size="s">
													<span>Minimum&nbsp;</span>
													<span className="value">5</span>
													<span>&nbsp;days&nbsp;per&nbsp;</span>
													<span className="value">week</span>
												</EuiText>
											</EuiFlexItem>
										) : null}
									</EuiFlexGroup>
								</EuiFlexItem>
							</EuiFlexGroup>
						</EuiFlexItem>
					</EuiFlexGroup>
				</EuiFlexItem>
			</EuiFlexGroup>
		</Wrapper>
	);
};

export default CompensationLocationCommute;

const Wrapper = styled.div`
	display: flex;
	position: relative;
	width: 100%;

	.type-title {
		width: 100%;

		span {
			font-weight: 600;
			font-size: 0.75rem;
			line-height: 2;

			color: ${variables.lightColor};
		}
	}

	.type-content {
		width: calc(100% - 16px);
		margin-top: 0;
	}

	.value {
		color: ${variables.linkColor};
	}

	.check-option {
		.euiCheckbox__square {
			border-radius: 50%;
			border-color: ${variables.lightColor};
			background-color: ${variables.lightColor};
			background-image: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' width='10' height='8' viewBox='0 0 10 8'><path d='M.375 2.625L3.375 5.625M3.375 5.625L8.625.375' fill='none' fill-rule='evenodd' stroke='rgb%28255, 255, 255%29' stroke-linecap='round' stroke-width='1.5' transform='translate(.5 1)'/></svg>");
		}
	}
`;
