export const SOCKET_TYPES = {
	webClient: {
		key: "web-client",
		type: "web-client",
	},
	dansClient: {
		key: "dans-client",
		type: "dans-client",
	},
};

export const SOCKET_EVENTS = {
	apiRequest: "api-request",
};
