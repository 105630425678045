import React, { useState } from "react";
import styled from "styled-components";
import { EuiFlexGroup, EuiFlexItem, EuiIcon, EuiButtonEmpty } from "@elastic/eui";

import { googleMapLogoIcon, menuDotsHorizontal } from "components/Common/Icons";
import AddressSettingPopover from "./AddressSettingPopover";

import variables from "assets/css/variables.scss";

/**
 * Component for Address Setting Bar
 */
const AddressSettingBar = (props) => {
    const { onEdit, onDelete } = props;

    const [isOpenPopover, setIsOpenPopover] = useState(false);

    const handleOpenPopover = () => {
        setIsOpenPopover(!isOpenPopover);
    };
    const handleClosePopover = () => setIsOpenPopover(false);

    return (
        <Wrapper>
            <EuiFlexGroup gutterSize="s" alignItems="center">
                <EuiFlexItem>
                    <EuiButtonEmpty size="xs" className="setting-icon-button">
                        <EuiIcon type="copy" size="m" color="text" />
                    </EuiButtonEmpty>
                </EuiFlexItem>
                <EuiFlexItem>
                    <EuiButtonEmpty size="xs" className="setting-icon-button">
                        <div className="icon-button-img">{googleMapLogoIcon()}</div>
                    </EuiButtonEmpty>
                </EuiFlexItem>
                <EuiFlexItem>
                    <AddressSettingPopover
                        button={
                            <EuiButtonEmpty
                                size="xs"
                                className="setting-icon-button"
                                onClick={handleOpenPopover}
                            >
                                <div className="icon-button-img">
                                    {menuDotsHorizontal(variables.primaryColor)}
                                </div>
                            </EuiButtonEmpty>
                        }
                        isOpenPopover={isOpenPopover}
                        onClosePopover={handleClosePopover}
                        onEdit={onEdit}
                        onDelete={onDelete}
                    />
                </EuiFlexItem>
            </EuiFlexGroup>
        </Wrapper>
    );
};

export default AddressSettingBar;

const Wrapper = styled.div`
    & > div {
        max-width: 6rem;
    }
    .setting-icon-button {
        padding: 0 !important;
        height: fit-content !important;

        span {
            padding: 0;
            line-height: 1.5rem;
        }

        .icon-button-img {
            display: flex;
            align-items: center;
        }
    }
`;
