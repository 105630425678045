import Plivo from "plivo-browser-sdk";

import { setPlivoCallState, setPlivoConnection, setPlivoLogin } from "helpers/actions";
import { PLIVO_CALL_STATES } from "components/global/constants";

/**
 * Plivo Setting
 */
const options = {
    debug: "INFO",
    permOnClick: true,
    codecs: ["OPUS", "PCMU"],
    enableIPV6: false,
    audioConstraints: {
        optional: [
            {
                googAutoGainControl: false,
            },
        ],
    },
    dscp: true,
    enableTracking: true,
    closeProtection: false,
    maxAverageBitrate: 48000,
    allowMultipleIncomingCalls: false,
    dtmfOptions: {
        sendDtmfType: ["outband", "inband"],
    },
};

/**
 * User information
 */
const plivoUsername = "EMPGerby3866377698664690";
const plivoUserpass = "dogCAT12301$";
const callerId = "+172086366769";

/**
 * Functions for processing events of Plivo Browser SDK
 */

/**
 * global variables
 */
let dispatch;

/**
 * Class : Plivo Service
 */
class PlivoService {
    plivoBrowserSdk;
    username;
    callId;
    incomingNotifications;

    constructor() {
        this.plivoBrowserSdk = null;
        this.username = "";
        this.callId = "";
    }

    init(_dispatch) {
        dispatch = _dispatch;

        if (dispatch) {
            this.plivoBrowserSdk = new Plivo(options);

            this.plivoBrowserSdk.client.on(
                "onWebrtcNotSupported",
                this.onWebrtcNotSupported
            );
            this.plivoBrowserSdk.client.on("onLogin", this.onLogin);
            this.plivoBrowserSdk.client.on("onLogout", this.onLogout);
            this.plivoBrowserSdk.client.on("onLoginFailed", this.onLoginFailed);
            this.plivoBrowserSdk.client.on(
                "onCallRemoteRinging",
                this.onCallRemoteRinging
            );
            this.plivoBrowserSdk.client.on(
                "onIncomingCallCanceled",
                this.onIncomingCallCanceled
            );
            this.plivoBrowserSdk.client.on(
                "onIncomingCallIgnored",
                this.onIncomingCallCanceled
            );
            this.plivoBrowserSdk.client.on("onCallFailed", this.onCallFailed);
            this.plivoBrowserSdk.client.on("onMediaConnected", this.onMediaConnected);
            this.plivoBrowserSdk.client.on("onCallAnswered", this.onCallAnswered);
            this.plivoBrowserSdk.client.on("onCallTerminated", this.onCallTerminated);
            this.plivoBrowserSdk.client.on("onCalling", this.onCalling);
            this.plivoBrowserSdk.client.on("onIncomingCall", this.onIncomingCall);
            this.plivoBrowserSdk.client.on("onMediaPermission", this.onMediaPermission);
            this.plivoBrowserSdk.client.on("mediaMetrics", this.mediaMetrics);
            this.plivoBrowserSdk.client.on("audioDeviceChange", this.audioDeviceChange);
            this.plivoBrowserSdk.client.on("onPermissionDenied", this.onPermissionDenied);
            this.plivoBrowserSdk.client.on("onConnectionChange", this.onConnectionChange); // To show connection change events
            this.plivoBrowserSdk.client.on("volume", this.volume);
            //onSessionExpired

            // Methods
            this.plivoBrowserSdk.client.setRingTone(true);
            this.plivoBrowserSdk.client.setRingToneBack(false);
            this.plivoBrowserSdk.client.setConnectTone(true); // Dial beep will play till we get alert response from network.
            this.plivoBrowserSdk.client.setDebug("ALL"); // Allowed values are OFF, ERROR, WARN, INFO, DEBUG, ALL

            /** Handle browser issues
             * Sound devices won't work in firefox
             */
            // checkBrowserComplaince(this.plivoBrowserSdk.client);
            // starFeedback();
        }

        // Login
        if (dispatch) {
            this.login(plivoUsername, plivoUserpass);
        }

        // Initialize
        this.incomingNotifications = new Map();
    }

    onWebrtcNotSupported() {
        console.warn("no webRTC support");
        alert(
            "Webrtc is not supported in this broswer, Please use latest version of chrome/firefox/opera/IE Edge"
        );
    }

    onLogin() {
        // Logged in Plivo
        console.info("Logged in");
        dispatch(setPlivoLogin(true));
        document.body.style.backgroundImage = "none";
        let customCallerId = localStorage.getItem("callerId");
        if (customCallerId) {
            let callerid = document.getElementById("callerid");
            callerid.value = customCallerId;
        }
    }

    onLoginFailed(reason) {
        // Login Failed
        if (this.dispatch) {
            this.dispatch(setPlivoLogin(false));
        }
        console.info("onLoginFailed ", reason);
        if (Object.prototype.toString.call(reason) === "[object Object]") {
            reason = JSON.stringify(reason);
        }
    }

    performLogout() {
        // document.body.style.backgroundImage = "url(img/background.svg)";
        // $("#loginContainer").show();
        // $("#callContainer").hide();
        // $(".loader").hide();
        // $("#toNumber").val("");
        // iti.setCountry("us");
    }

    onLogout() {
        console.info("onLogout");
        this.performLogout();
    }

    onCalling() {
        console.info("onCalling");
        const callState = {
            state: PLIVO_CALL_STATES.onCalling.value,
            callInfo: null,
        };
        dispatch(setPlivoCallState(callState));
    }

    onCallRemoteRinging(callInfo) {
        console.info("onCallRemoteRinging");

        const callState = {
            state: PLIVO_CALL_STATES.onCallRemoteRinging.value,
            info: callInfo,
        };

        dispatch(setPlivoCallState(callState));
    }

    onMediaConnected(callInfo) {
        console.info("onMediaConnected");

        let state = PLIVO_CALL_STATES.onMediaConnected.value;

        if (callInfo && callInfo.direction === "incoming") {
            state = PLIVO_CALL_STATES.answered.value;
        }

        const callState = {
            state: state,
            info: callInfo,
        };

        dispatch(setPlivoCallState(callState));
    }

    onIncomingCallCanceled(callInfo) {}

    resetMute() {
        this.plivoBrowserSdk.client.mute();
    }

    callOff(reason) {
        this.resetMute();
    }

    onCallFailed(reason, callInfo) {
        let state = PLIVO_CALL_STATES.faild.value;

        if (callInfo) {
            console.log(JSON.stringify(callInfo));
            console.info(
                `onCallFailed ${reason} ${callInfo.callUUID} ${callInfo.direction}`
            );
        } else {
            console.info(`onCallFailed ${reason}`);
        }

        const callState = {
            state: state,
            info: callInfo,
            reason,
        };

        dispatch(setPlivoCallState(callState));

        if (!callInfo) {
            this.callOff(reason);
            return;
        }

        if (this.incomingNotifications?.has(callInfo.callUUID)) {
            const incomingCall = this.incomingNotifications.get(callInfo.callUUID);
            if (incomingCall) {
                incomingCall.hide();
            }
            this.incomingNotifications.delete(callInfo.callUUID);
        }

        if (
            this.incomingNotifications?.size === 0 &&
            !this.plivoBrowserSdk.client.getCallUUID()
        ) {
            this.callOff(reason);
        } else if (
            this.incomingNotifications?.size === 0 &&
            callInfo.direction === "outgoing"
        ) {
            this.callOff(reason);
        }
    }

    onCallAnswered(callInfo) {
        console.info("onCallAnswered");
        let state = PLIVO_CALL_STATES.answered.value;

        if (callInfo && callInfo.direction === "incoming") {
            if (this.incomingNotifications.has(callInfo.callUUID)) {
                const incomingCall = this.incomingNotifications.get(callInfo.callUUID);
                if (incomingCall) incomingCall.hide();
                this.incomingNotifications.delete(callInfo.callUUID);
            }
        }

        const callState = {
            state: state,
            info: callInfo,
        };

        dispatch(setPlivoCallState(callState));
    }

    onCallTerminated(evt, callInfo) {
        console.info(`onCallTerminated ${evt}`);
        let state = PLIVO_CALL_STATES.terminated.value;
        if (
            callInfo &&
            callInfo.callUUID === this.plivoBrowserSdk?.client.getCallUUID()
        ) {
            console.info(JSON.stringify(callInfo));
            this.callOff(evt);
        } else if (!callInfo) {
            this.callOff(evt);
        }

        const callState = {
            state: state,
            info: callInfo,
            reason: evt,
        };

        dispatch(setPlivoCallState(callState));
    }

    onIncomingCall(callerName, extraHeaders, callInfo, caller_Name) {
        // console.info(
        // 	"onIncomingCall : ",
        // 	callerName,
        // 	extraHeaders,
        // 	callInfo,
        // 	caller_Name
        // );
        // let prevIncoming = isIncomingCallPresent;
        // isIncomingCallPresent = true;
        // callStorage.startTime = date();
        // callStorage.mode = "in";
        // callStorage.num = caller_Name;
        // if (
        // 	document.getElementById("callstatus").innerHTML == "Idle" &&
        // 	!prevIncoming
        // ) {
        // 	$("#incomingCallDefault").show();
        // 	$("#phone").hide();
        // 	$("#callstatus").html("Ringing...");
        // 	$("#callernum").html(caller_Name);
        // 	incomingCallInfo = callInfo;
        // 	if (callInfo) {
        // 		incomingNotifications.set(callInfo.callUUID, null);
        // 	}
        // } else {
        // 	$("#callstatus").html("Ringing...");
        // 	const incomingNotification = Notify.success(
        // 		`Incoming Call: ${caller_Name}`
        // 	)
        // 		.button("Answer", () => {
        // 			isIncomingCallPresent = false;
        // 			console.info("Call accept clicked");
        // 			if (callInfo) {
        // 				plivoBrowserSdk.client.answer(callInfo.callUUID);
        // 			} else {
        // 				plivoBrowserSdk.client.answer();
        // 			}
        // 		})
        // 		.button("Reject", () => {
        // 			isIncomingCallPresent = false;
        // 			console.info("callReject");
        // 			if (callInfo) {
        // 				plivoBrowserSdk.client.reject(callInfo.callUUID);
        // 			} else {
        // 				plivoBrowserSdk.client.reject();
        // 			}
        // 		})
        // 		.button("Ignore", () => {
        // 			isIncomingCallPresent = false;
        // 			console.info("call Ignored");
        // 			if (callInfo) {
        // 				plivoBrowserSdk.client.ignore(callInfo.callUUID);
        // 			} else {
        // 				plivoBrowserSdk.client.ignore();
        // 			}
        // 		});
        // 	if (callInfo) {
        // 		console.info(JSON.stringify(callInfo));
        // 		incomingNotifications.set(callInfo.callUUID, incomingNotification);
        // 	} else {
        // 		incomingNotificationAlert = incomingNotification;
        // 	}
        // }
    }

    onMediaPermission() {}

    mediaMetrics(obj) {
        console.log("obj: ", obj);
        /**
         * Set a trigger for Quality FB popup when there is an warning druing call using sessionStorage
         * During `onCallTerminated` event check for `triggerFB` flag
         */
        sessionStorage.setItem("triggerFB", true);
        console.table([obj]);
        var classExist = document.querySelector(".-" + obj.type);
        var message = obj.type;
        /**
         * If there is a same audio level for 3 samples then we will get a trigger
         * If audio level is greater than 30 then it could be some continuous echo or user is not speaking
         * Set message "same level" for audio greater than 30. Less than 30 could be a possible mute
         */
        if (obj.type.match("audio") && obj.value > 1) {
            message = "same level";
        }
        if (obj.active) {
            if (classExist) {
                classExist.remove();
            }
        }
        if (!obj.active && classExist) {
            document.querySelector(".-" + obj.type).remove();
        }
        // Handle no mic input even after mic access
        if (obj.desc === "no access to your microphone") {
        }

        const callState = {
            media: obj,
        };

        dispatch(setPlivoCallState(callState));
    }

    audioDeviceChange() {}

    onPermissionDenied() {}

    onConnectionChange(obj) {
        // Logged in Plivo
        if (obj.state === "connected") {
            console.log(obj.state, "info", "info");
        } else if (obj.state === "disconnected") {
            if (obj.eventCode && obj.eventReason) {
                console.log(
                    obj.state + " " + obj.eventCode + " " + obj.eventReason,
                    "info"
                );
            } else if (obj.eventCode && !obj.eventReason) {
                console.log(obj.state + " " + obj.eventCode, "info");
            } else if (!obj.eventCode && obj.eventReason) {
                console.log(obj.state + " " + obj.eventReason, "info");
            } else {
                console.log(obj.state, "info");
            }
        } else {
            console.log("unknown connection state ");
        }

        dispatch(setPlivoConnection(obj));
    }

    volume(audioStats) {
        // inputVolume = audioStats.inputVolume;
        // outputVolume = audioStats.outputVolume;
        // colorPids(Math.floor(inputVolume * 325), "localaudio");
        // colorPids(Math.floor(outputVolume * 325), "remoteaudio");
    }

    login(username, password) {
        if (username && password) {
            //start UI load spinner
            this.plivoBrowserSdk.client.login(username, password);
        } else {
            console.error("username/password missing!");
        }
    }

    call(phoneNumber) {
        let extraHeaders = {};
        if (callerId) {
            const customCallerId = callerId.replace("+", "");
            extraHeaders = { "X-PH-callerId": customCallerId };
        }
        extraHeaders["X-PH-conference"] = "true";

        if (!phoneNumber || !this.plivoBrowserSdk) {
            return;
        }

        if (!this.plivoBrowserSdk.client.isLoggedIn) {
            alert("You're not Logged in!");
            return;
        }

        this.plivoBrowserSdk.client.call(phoneNumber, extraHeaders);

        const callState = {
            mode: "out",
            startTime: new Date(),
            num: phoneNumber,
            state: PLIVO_CALL_STATES.onCalling.value,
            callInfo: null,
            reason: null,
        };

        dispatch(setPlivoCallState(callState));
    }

    hangup() {
        if (this.plivoBrowserSdk.client.callSession) {
            this.plivoBrowserSdk.client.hangup();
        } else {
            this.callOff();
        }
    }

    setMute(isMute) {
        if (isMute) {
            this.plivoBrowserSdk.client.mute();
        } else {
            this.plivoBrowserSdk.client.unmute();
        }
    }

    toggleMute() {
        if (this.plivoBrowserSdk.client.isCallMuted) {
            this.plivoBrowserSdk.client.unmute();
        } else {
            this.plivoBrowserSdk.client.mute();
        }
    }
}

export default new PlivoService();
