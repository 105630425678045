import Service from "core/service";
import * as actionTypes from "./actionTypes";

import { MAX_GLOBAL_SEARCH_CNT } from "core/service/constant";
import { objectChecker } from "helpers/utilities";
import { logError } from "store/error";

export const setGlobalSearchItems = (data) => {
	return {
		type: actionTypes.GLOBAL_SEARCH_ITEMS,
		payload: data,
	};
};

export const getGlobalSearchItems = (params, successCallback, failCallback) => {
	return async (dispatch) => {
		try {
			const payload = {
				max_count_person: MAX_GLOBAL_SEARCH_CNT,
				max_count_company: MAX_GLOBAL_SEARCH_CNT,
				max_count_job: MAX_GLOBAL_SEARCH_CNT,
				...params,
			};

			const result = await Service.globalSearch.getGlobalSearchItems(payload);

			if (!objectChecker(result, ["error"])) {
				dispatch(
					setGlobalSearchItems(objectChecker(result, ["response", "data"], []))
				);

				if (successCallback) {
					successCallback();
				}
			} else {
				dispatch(setGlobalSearchItems(null));
				if (failCallback) {
					failCallback();
				}
			}
		} catch (err) {
			logError("fetch_failure", "none", {
				Error: "Error fetching global search Items: " + JSON.stringify(err),
			});
			dispatch(setGlobalSearchItems(null));
			if (failCallback) {
				failCallback();
			}
		}
	};
};
