import React from "react";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faLongArrowDown, faLongArrowUp } from "@fortawesome/pro-light-svg-icons";

/**
 * List for item_state_c in workflow_item table
 */
export const WORKFLOW_ITEM_STATES = {
	pending: { key: "pending", label: "Pending", value: 1 },
	active: { key: "active", label: "Active", value: 2 },
	complete: { key: "complete", label: "Complete", value: 3 },
	cancelled: { key: "cancelled", label: "Cancelled", value: 4 },
	disabled: { key: "disabled", label: "Disabled", value: 5 },
	skipped: { key: "skipped", label: "Skipped", value: 6 },
	error: { key: "error", label: "Error", value: 7 },
};

/**
 * Option list for selecting filering in workflow_item table
 */
export const WORKFLOW_ITEM_STATE_OPTIONS = [
	{
		id: "queue-tasks",
		text: "Queue",
		value: "queue-tasks",
		filter: {
			filter: "queue",
			states: null,
		},
		returnKey: "workflow_item_queue",
	},
	{
		id: "active-tasks",
		value: "active-tasks",
		text: "Active",
		filter: {
			filter: "item",
			states: [1, 2],
		},
		returnKey: "workflow_item",
	},
	{
		id: "completed-tasks",
		value: "completed-tasks",
		text: "Complete",
		filter: {
			filter: "item",
			states: [3],
		},
		returnKey: "workflow_item",
	},
	{
		id: "Other-tasks",
		value: "Other-tasks",
		text: "Other",
		filter: {
			filter: "item",
			states: [4, 5, 6, 7],
		},
		returnKey: "workflow_item",
	},
	{
		id: "All-tasks",
		value: "All-tasks",
		text: "All",
		filter: {
			filter: "item",
			states: [1, 2, 3, 4, 5, 6, 7],
		},
		returnKey: "workflow_item",
	},
];

/**
 * Priority for Workflow Item
 */
export const WORKFLOW_ITEM_PRIORITY_LIST = {
	high: {
		key: "high",
		value: 3,
		label: "High",
		className: "high",
		color: "var(--eui-danger-color)",
		icon: <FontAwesomeIcon icon={faLongArrowUp} size="lg" />,
	},
	normal: {
		key: "normal",
		value: 2,
		label: "Normal",
		className: "normal",
		color: "var(--eui-warning-color)",
		icon: <FontAwesomeIcon icon={faLongArrowUp} size="lg" />,
	},
	low: {
		key: "low",
		value: 1,
		label: "Low",
		className: "low",
		color: "var(--eui-secondary-color)",
		icon: <FontAwesomeIcon icon={faLongArrowDown} size="lg" />,
	},
};
