import React, { useState, useMemo } from "react";
import styled from "styled-components";
import { get } from "lodash";

import CmsDraftStaticItem from "./CmsDraftStaticItem";
import { CmsDraftEditItem } from "./CmsDraftEditItem";
import { getGCSValue } from "components/global/utils";
import { useAccount, useGlobal } from "core/useHooks";

/**
 * Component for CMS Item in CMS Communication Draft table
 */
const CmsDraftItem = (props) => {
	const { data, person, onSaveRAS } = props;

	const { globals } = useGlobal();
	const { account, accounts } = useAccount();

	const [isEdit, setIsEdit] = useState(false);

	const { owners, assignees, restriction } = useMemo(() => {
		const assignees = [];
		const owners = [];
		const rasRRecords = get(data, "cms_association.ras_assignment_main");

		if (rasRRecords && globals?.length > 0) {
			const ownerAssignType = getGCSValue(
				globals,
				"ras_assignment_main",
				"assignment_type_c",
				"owner"
			);
			const assignedAssignType = getGCSValue(
				globals,
				"ras_assignment_main",
				"assignment_type_c",
				"assigned"
			);

			for (let i in rasRRecords) {
				const { assignment_type_c: assignmentTypeC } = rasRRecords[i];

				if (assignmentTypeC === ownerAssignType) {
					owners.push(rasRRecords[i]);
				}

				if (assignmentTypeC === assignedAssignType) {
					assignees.push(rasRRecords[i]);
				}
			}
		}

		const restriction = get(data, "cms_association.ras_restriction_main[0]", null);

		return {
			owners,
			assignees,
			restriction,
		};
	}, [data, globals]);

	const handleClickEdit = () => {
		setIsEdit(true);
	};

	const handleClickView = () => {
		setIsEdit(false);
	};

	const handleSaveRAS = (rasData) => {
		onSaveRAS(data?.id, restriction?.id, rasData);
	};

	return (
		<Wrapper>
			{isEdit ? (
				<CmsDraftEditItem
					data={data}
					accounts={accounts}
					restriction={restriction}
					owners={owners}
					assignees={assignees}
					account={account}
					onChangeEdit={handleClickView}
					onSaveRAS={handleSaveRAS}
					person={person}
				/>
			) : (
				<CmsDraftStaticItem
					data={data}
					accounts={accounts}
					restriction={restriction}
					owners={owners}
					assignees={assignees}
					onChangeEdit={handleClickEdit}
					onSaveRAS={handleSaveRAS}
				/>
			)}
		</Wrapper>
	);
};

export default CmsDraftItem;

const Wrapper = styled.div`
	display: flex;
	position: relative;
	width: 100%;
`;
