import * as actionTypes from "./actionTypes";

/**
 * Functoins
 */
const setQualifiers = (state, payload) => {
	return {
		...state,
		qualifiers: [...payload],
	};
};

const setQualifierFylout = (state, payload) => {
	return {
		...state,
		qualifierFlyout: payload,
	};
};

const setQualifier = (state, payload) => {
	return {
		...state,
		loading: false,
		qualifierID: payload.id,
		parentQualifierId: payload.parentId,
		themeId: payload.themeId,
		associations: payload.associations,
		qualifierAction: payload.action,
		qualifierOverride: payload.override,
		qualifierName: payload.qualifierName,
		themeDetails: payload.theme,
	};
};

const changeQualifier = (state, payload) => {
	return {
		...state,
		loading: false,
		changeQualifier: payload,
	};
};

const deleteQualifier = (state, payload) => {
	return {
		...state,
		loading: false,
		deleteQualifierSuccess: true,
	};
};

const getThemeDetail = (state, payload) => {
	return {
		...state,
		loading: false,
		themeDetails: payload,
	};
};

const editTheme = (state, payload) => {
	return {
		...state,
		loading: false,
		editThemeRes: payload,
		editThemeSuccess: true,
		themeId: payload?.procedure_results?.theme_item_main[0].id,
		errors: false,
	};
};

const createTheme = (state, payload) => {
	return {
		...state,
		loading: false,
		createThemeRes: payload,
		createThemeSuccess: true,
		themeId: payload?.procedure_results?.theme_item_main[0].id,
		errors: false,
	};
};

const createQualifier = (state, payload) => {
	return {
		...state,
		loading: false,
		qualifierFlyout: false,
		createQualifierSuccess: true,
	};
};

const editQualifier = (state, payload) => {
	return {
		...state,
		loading: false,
		qualifierFlyout: false,
		createQualifierSuccess: true,
	};
};

/** ---------- NEW ------------ */
// Qualifier Main Table
const requestQualifierMainList_NEW = (state) => {
	return {
		...state,
		qualifierMainList_NEW: {
			...state.qualifierMainList_NEW,
			isRequesting: true,
			isReceived: false,
		},
	};
};

const receivedQualifierMainList_NEW = (state) => {
	return {
		...state,
		qualifierMainList_NEW: {
			...state.qualifierMainList_NEW,
			isRequesting: false,
			isReceived: true,
		},
	};
};

const setQualifierMainList_NEW = (state, payload) => {
	return {
		...state,
		qualifierMainList_NEW: {
			...state.qualifierMainList_NEW,
			...payload,
			isRequesting: false,
			isReceived: true,
		},
	};
};
/** --------------------------- */

/**
 * Initial State
 */
const initialState = {
	qualifiers: [],
	loading: false,
	errors: {},
	qualifierFlyout: false,
	qualifierID: null,
	editQualifierFlyout: false,
	parentQualifierId: null,
	themeId: null,
	qualifier: [],
	themeDetails: {},
	createThemeRes: {},
	createThemeSuccess: false,
	editThemeSuccess: false,
	createQualifierSuccess: false,
	deleteQualifierSuccess: false,
	associations: [],
	qualifierAction: "",
	qualifierName: "",
	changeQualifier: false,

	/* ------------------ NEW ---------------- */
	qualifierMainList_NEW: {
		totalCount: 0,
		pageCount: 30,
		pageNumber: 1,
		data: [],
		searchTerm: "",
		isRequesting: false,
		isReceived: false,
	},
};

/**
 * Reducer
 */
const Reducer = (state = initialState, action) => {
	switch (action.type) {
		case actionTypes.SET_QUALIFIERS:
			return setQualifiers(state, action.payload);
		case actionTypes.SET_QUALIFIERS_FLYOUT:
			return setQualifierFylout(state, action.payload);
		case actionTypes.SET_QUALIFIER_ID:
			return setQualifier(state, action.payload);
		case actionTypes.SET_QUALIFIER_NODE:
			return changeQualifier(state, action.payload);
		case actionTypes.DELETE_QUALIFIER:
			return deleteQualifier(state, action.payload);
		case actionTypes.GET_QUALIFIER_THEME_DETAIL:
			return getThemeDetail(state, action.payload);
		case actionTypes.UPDATE_QUALIFIER_THEME:
			return editTheme(state, action.payload);
		case actionTypes.CREATE_QUALIFIER_THEME:
			return createTheme(state, action.payload);
		case actionTypes.CREATE_QUALIFIER:
			return createQualifier(state, action.payload);
		case actionTypes.UPDATE_QUALIFIER:
			return editQualifier(state, action.payload);
		/** --------------- NEW --------------- */
		// Qualifier Main Table
		case actionTypes.SET_QUALIFIER_MAINLIST_NEW:
			return setQualifierMainList_NEW(state, action.payload);

		case actionTypes.REQUEST_QUALIFIER_MAINLIST_NEW:
			return requestQualifierMainList_NEW(state);

		case actionTypes.RECEIVED_QUALIFIER_MAINLIST_NEW:
			return receivedQualifierMainList_NEW(state);
		/** ------------------------------------*/
		default: {
			return state;
		}
	}
};

export default Reducer;
