import * as actionTypes from "./actionTypes";

/**
 * Functions
 */
const setThemeList = (state, payload) => {
	return {
		...state,
		themeList: payload,
	};
};

/**
 * Initial State
 */
const initialState = {
	themeList: null,
};

/**
 * Reducer
 */
const Reducer = (state = initialState, action) => {
	switch (action.type) {
		case actionTypes.THEME_LIST:
			return setThemeList(state, action.payload);
		default:
			return state;
	}
};

export default Reducer;
