import loadable from "@loadable/component";

/**
 * Login
 */
export const AsyncLogin = loadable(() =>
	import(/* webpackChunkName: "Login" */ "components/Pages/Login/Login")
);

/**
 * Dashboard
 */
export const AsyncDashboard = loadable(() =>
	import(/* webpackChunkName: "Dashboard" */ "components/Pages/Dashboard/Dashboard")
);

/**
 * Campaign
 */
export const AsyncCampaign = loadable(() =>
	import(/* webpackChunkName: "Campaign" */ "components/Pages/Campaign/Campaign")
);

/**
 * Company
 */
export const AsyncCompany = loadable(() =>
	import(/* webpackChunkName: "Company" */ "components/Pages/Company/Company")
);

/**
 * Person
 */
export const AsyncPerson = loadable(() =>
	import(/* webpackChunkName: "Person" */ "components/Pages/Person/Person")
);

/**
 * Job
 */
export const AsyncJob = loadable(() =>
	import(/* webpackChunkName: "Job" */ "components/Pages/Job/Job")
);

/**
 * Sourcing
 */
export const AsyncSourcing = loadable(() =>
	import(/* webpackChunkName: "Sourcing" */ "components/Pages/Sourcing/Sourcing")
);

/**
 * Related to Configuration
 */
export const AsyncSystemConfiguration = loadable(() =>
	import(
		/* webpackChunkName: "SystemConfiguration" */ "components/Pages/SystemConfiguration/SystemConfiguration"
	)
);

export const AsyncAccountEdit = loadable(() =>
	import(/* webpackChunkName: "AccountEdit" */ "components/Pages/Account/AccountEdit")
);

export const AsyncSIT = loadable(() =>
	import(/* webpackChunkName: "SIT" */ "components/Pages/SIT/SIT")
);

export const AsyncMyProfile = loadable(() =>
	import(/* webpackChunkName: "MyProfile" */ "components/Pages/MyProfile/MyProfile")
);

export const AsyncMessaging = loadable(() =>
	import(/* webpackChunkName: "Messaging" */ "components/Pages/Messaging/Messaging")
);
