import React, { useMemo, useState } from "react";
import styled from "styled-components";
import { EuiPopover, EuiFlexGroup, EuiFlexItem, EuiFieldSearch } from "@elastic/eui";

import { JOB_APPLICANT_STATUS_LIST } from "./constants";
import { checkLightIcon } from "components/Common/Icons";

/**
 * Component for Job Applicantion Stage
 */
const JobApplicantionStageItem = (props) => {
	const { onChangeStatus, status = 1, showLabel = false } = props;

	const [isOpenPopover, setIsOpenPopover] = useState(false);
	const [searchValue, setSearchValue] = useState("");

	const { itemStatus, isNewItem } = useMemo(() => {
		const itemStatus =
			Object.values(JOB_APPLICANT_STATUS_LIST).find(
				(item) => item.value === status
			) || JOB_APPLICANT_STATUS_LIST.new;

		const isNewItem = !itemStatus || itemStatus === JOB_APPLICANT_STATUS_LIST.new;

		return {
			itemStatus,
			isNewItem,
		};
	}, [status]);

	const handleClosePopover = () => {
		setIsOpenPopover(false);
	};

	const handleChangeStatus = (newState) => {
		if (onChangeStatus) {
			onChangeStatus(newState);
		}

		setIsOpenPopover(false);
	};

	const handleChangeSearchValue = (e) => {
		const newSearchVal = e.target.value;
		setSearchValue(newSearchVal);
	};

	return (
		<Wrapper
			isOpen={isOpenPopover}
			button={
				<StatusLabel
					onClick={() => setIsOpenPopover(!isOpenPopover)}
					className="cursor-pointer"
					style={{
						color: !isNewItem
							? "var(--eui-text-color)"
							: "var(--eui-disabled-text-color)",
					}}
				>
					<div
						style={{ background: itemStatus.color }}
						className="item-icon type-p-medium"
					>
						{itemStatus.icon}
					</div>
					{showLabel && (
						<span className="semibold">&nbsp;&nbsp;{itemStatus.label}</span>
					)}
				</StatusLabel>
			}
			closePopover={handleClosePopover}
			panelStyle={{
				width: 210,
				padding: "0 0",
			}}
			hasArrow={false}
		>
			<BodyContainer gutterSize="none" direction="column">
				<EuiFlexItem>
					<div className="search-bar">
						<EuiFieldSearch
							placeholder="Search"
							value={searchValue}
							onChange={handleChangeSearchValue}
						/>
					</div>
				</EuiFlexItem>
				<EuiFlexItem>
					<EuiFlexGroup direction="column" gutterSize="none">
						{Object.values(JOB_APPLICANT_STATUS_LIST).map((item) => {
							const isShowItem = item.label
								.toLowerCase()
								.includes(searchValue.toLowerCase());
							const isNewItem = item.value === 0;
							const isSelected = item.value === itemStatus.value;
							if (!isShowItem) {
								return null;
							}
							return (
								<EuiFlexItem
									key={`job-appication-state-item-${item.value}`}
									className="job-appication-state-item"
								>
									<EuiFlexGroup gutterSize="s" alignItems="center">
										<EuiFlexItem grow={1}>
											{isSelected ? (
												checkLightIcon()
											) : (
												<div>&nbsp;</div>
											)}
										</EuiFlexItem>
										<EuiFlexItem grow={9}>
											<StatusListItem
												gutterSize="s"
												alignItems="center"
												className="cursor-pointer"
												onClick={() =>
													handleChangeStatus(item.value)
												}
											>
												<EuiFlexItem grow={false}>
													<div
														style={{ background: item.color }}
														className="item-icon type-p-medium"
													>
														{item.icon}
													</div>
												</EuiFlexItem>
												<EuiFlexItem>
													<span
														style={{
															color: isNewItem
																? "var(--eui-disabled-text-color)"
																: "var(--eui-text-color)",
														}}
													>
														{item.label}
													</span>
												</EuiFlexItem>
											</StatusListItem>
										</EuiFlexItem>
									</EuiFlexGroup>
								</EuiFlexItem>
							);
						})}
					</EuiFlexGroup>
				</EuiFlexItem>
			</BodyContainer>
		</Wrapper>
	);
};

export default JobApplicantionStageItem;

const Wrapper = styled(EuiPopover)`
	display: flex !important;

	.item-icon {
		display: flex;
		width: var(--sp-12);
		height: var(--sp-12);
		border-radius: var(--sp-3);
		align-items: center;
		justify-content: center;
		color: var(--white-color);
	}
`;

const StatusListItem = styled(EuiFlexGroup)`
	.item-icon {
		display: flex;
		width: var(--sp-12);
		height: var(--sp-12);
		border-radius: var(--sp-3);
		align-items: center;
		justify-content: center;
		color: var(--white-color);
	}

	svg {
		width: var(--sp-8);
		height: auto;
	}
`;

const StatusLabel = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;
`;

const BodyContainer = styled(EuiFlexGroup)`
	.search-bar {
		padding: var(--sp-4);
		border-bottom: 1px solid rgba(211, 218, 230, 0.5);

		.euiFormControlLayout {
			height: fit-content;
		}
	}

	.job-appication-state-item {
		padding: var(--sp-4) var(--sp-3) var(--sp-4) var(--sp-4);
		border-bottom: 1px solid rgba(211, 218, 230, 0.5);
	}
`;
