// Campaign Execution Types
export const CAMPAIGN_EXECUTION_TYPES = {
	start: { key: "start", value: 1, message: "initiated" },
	pause: { key: "pause", value: 2, message: "paused" },
	stop: { key: "stop", value: 3, message: "stopped" },
};

/**
 * Campaign Execution State List
 */
export const CAMPAIGM_EXECUTION_STATE_LIST = {
	noInited: { key: "noInited", value: 0 },
	queued: { key: "queued", value: 1 },
	running: { key: "running", value: 2 },
	complete: { key: "complete", value: 3 },
	paused: { key: "paused", value: 4 },
	stopped: { key: "stopped", value: 5 },
};

/**
 * Time Types inside campaign.campaign_details.workflow.schedules
 */
export const CAMPAIGN_WORLFLOW_SCHEDULE_TYPES = {
	email: { key: "email", label: "Email", value: "email" },
	linkedIn: { key: "linkedIn", label: "LinkedIn", value: "linkedIn" },
	sms: { key: "sms", label: "Text", value: "sms" },
	timezone: { key: "timezone", label: "Timezone", value: "timezone" },
};

/**
 * Initial Days of campaign.campaign_details.workflow.schedules
 */
export const CAMPAIGN_WORLFLOW_SCHEDULE_INITIAL_DAYS = [
	{ day: "Mon", time_start: null, time_end: null, active: false },
	{ day: "Tue", time_start: null, time_end: null, active: false },
	{ day: "Wed", time_start: null, time_end: null, active: false },
	{ day: "Thu", time_start: null, time_end: null, active: false },
	{ day: "Fri", time_start: null, time_end: null, active: false },
	{ day: "Sat", time_start: null, time_end: null, active: false },
	{ day: "Sun", time_start: null, time_end: null, active: false },
];
