import {
	ADD_GLOBAL_CUSTOM_FIELD,
	SET_GLOBALS,
	UPDATE_GLOBAL_CUSTOM_FIELD,
	SET_GLOBAL_STATIC_SETTING_NEW,
	SET_MATCH_GLOBAL_RECORDS,
} from "./actionTypes";

const setGlobals = (state, payload) => {
	return {
		...state,
		globals: [...payload],
	};
};

const updateGlobalCustomField = (state, payload) => {
	return {
		...state,
		updateGlobalCustomField: payload,
	};
};

const addGlobalCustomField = (state, payload) => {
	return {
		...state,
		addGlobalCustomField: payload,
	};
};

/** ---------- NEW ------------ */
const setGSSLists_NEW = (state, payload) => {
	return {
		...state,
		gssList_NEW: payload,
	};
};
/** --------------------------- */

const initialState = {
	globals: [],
	gssList_NEW: null,
	loading: false,
	updateGlobalCustomField: {},
	addGlobalCustomField: {},
};

const Reducer = (state = initialState, action) => {
	switch (action.type) {
		case SET_GLOBALS:
			return setGlobals(state, action.payload);

		case UPDATE_GLOBAL_CUSTOM_FIELD:
			return updateGlobalCustomField(state, action.payload);

		case ADD_GLOBAL_CUSTOM_FIELD:
			return addGlobalCustomField(state, action.payload);

		/** --------------- NEW --------------- */
		case SET_GLOBAL_STATIC_SETTING_NEW:
			return setGSSLists_NEW(state, action.payload);
		/** ------------------------------------*/
		default: {
			return state;
		}
	}
};

export default Reducer;
