import styled from "styled-components";

export const TableWrapper = styled.div`
	.euiTableCellContent__text {
		width: 100%;
	}

	.per-page,
	.euiButtonEmpty.euiButtonEmpty--primary {
		height: var(--sp-12);
	}

	.euiPaginationButton,
	.custom-eui-table-flex-group-header {
		.euiButtonEmpty__text {
			font-size: var(--sp-7) !important;
		}
	}

	.custom-eui-table {
		&-flex-group {
			&-header {
				margin-bottom: var(--sp-2_5);
			}

			&-footer {
				margin-top: var(--sp-2_5);
			}
		}

		&-select-all-btn {
			border: 1px solid var(--link-color);
			box-sizing: border-box;
			border-radius: var(--sp-2);
			padding: var(--sp-2);
			color: var(--link-color);
			height: unset !important;

			&:hover,
			&:focus {
				text-decoration: none !important;
				background: var(--link-color);
				color: white;
			}
		}

		&-bulk-action-popover-btn {
			background: #fbfcfd;
			border: 1px solid rgba(15, 39, 118, 0.1);
			border-radius: var(--sp-2);
			padding: var(--sp-2);
			box-sizing: border-box;
			height: unset !important;
			box-shadow: 0px 1px 1px rgba(152, 162, 179, 0.1),
				0px 3px 2px rgba(152, 162, 179, 0.1);

			&:hover,
			&:focus {
				text-decoration: none !important;
				background: none;
			}
		}

		&-header {
			border-top: 1px solid var(--eui-light-shade-color);
		}

		&-header-cell > span {
			font-style: normal;
			font-weight: bold;
			font-size: var(--sp-5);
			line-height: var(--sp-9);
			letter-spacing: -0.08px;
			color: var(--primary-color);
		}

		&-row > td {
			padding: 10px 0px;
		}

		&-popover {
			&-btn {
				text-decoration: none;

				&-link {
					color: var(--link-color);
					text-decoration: underline;
				}
			}
		}
	}
`;
