import React, { useState, useMemo } from "react";
import { cloneDeep, size } from "lodash";
import {
    EuiButtonEmpty,
    EuiFlexGroup,
    EuiFlexItem,
    EuiToolTip,
    EuiContextMenuPanel,
    EuiSpacer,
} from "@elastic/eui";
import PhoneInput, { parsePhoneNumber } from "react-phone-number-input";
import flags from "react-phone-number-input/flags";

import { faCheckCircle } from "@fortawesome/pro-solid-svg-icons/faCheckCircle";
import { faPhoneAlt } from "@fortawesome/pro-solid-svg-icons/faPhoneAlt";
import { faPhoneOffice } from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import "react-phone-number-input/style.css";

import { hasPrimaryChanged } from "helpers/utilities";
import Popover from "components/Common/Popover/Popover";

import variables from "assets/css/variables.scss";

import { AsyncEditableContent } from "components/Common/asyncComponents";
import {
    phoneHomeIcon,
    phoneCellIcon,
    phoneWorkHomeIcon,
    phoneWorkCellIcon,
    removeIcon,
} from "components/Common/Icons";

/**
 * Constants
 */
const TRACKING_KEY = "person_phone_id";
const DISPLAY_KEY = "phone_number";

const PHONE_EDITOR_TYPES = {
    personal: {
        type: "personal",
        trackingKey: "person_phone_id",
        placehold: "Home/Cell Phone",
        home: { key: "home", icon: phoneHomeIcon },
        cell: { key: "cell", icon: phoneCellIcon },
        icon: faPhoneAlt,
    },
    employment: {
        type: "employment",
        trackingKey: "person_phone_id",
        placehold: "Work Phone",
        home: { key: "work", icon: phoneWorkHomeIcon },
        cell: { key: "work_cell", icon: phoneWorkCellIcon },
        icon: faPhoneOffice,
    },
};

/**
 * Main component for Person Phone
 */
const PersonPhone = (props) => {
    const { personId, data, syncData, loadData, type } = props;

    const [loading, setLoading] = useState(false);

    const phoneEditorType = useMemo(() => {
        const _typeKey = Object.getOwnPropertyNames(PHONE_EDITOR_TYPES).find(
            (key) => PHONE_EDITOR_TYPES[key].type === type
        );

        return _typeKey ? PHONE_EDITOR_TYPES[_typeKey] : undefined;
    }, [type]);

    const editDisplayOverride = (
        editData,
        setEditData,
        editTrackingKey,
        editDisplayKey,
        handleRemove,
        getPrimaryOption
    ) => {
        if (!editData || !setEditData || !editTrackingKey || !handleRemove || !getPrimaryOption) return <></>;

        const handleChange = (val, editTrackingValue, editDisplayKey, callback = null) => {
            if (!val || !editDisplayKey) return;
            // Tracking ID.

            let value = val[editDisplayKey];
            const newEditedData = cloneDeep(editData);
            for (let i in newEditedData) {
                const trackingId = newEditedData[i][editTrackingKey];

                if (trackingId === editTrackingValue) {
                    if (editDisplayKey === "formatted_phone_number") {
                        const phoneMeta = parsePhoneNumber(value);
                        if (phoneMeta) {
                            newEditedData[i]["phone_country_code"] = phoneMeta["countryCallingCode"];
                            value = value.replace("+", "");
                            value = value.replace(phoneMeta["countryCallingCode"], "");
                        }
                        editDisplayKey = "phone_number";
                    }
                    newEditedData[i][editDisplayKey] = value;
                    break;
                }
            }

            setEditData(newEditedData);
            if (callback !== null) {
                callback();
            }
        };

        return (
            <div>
                {editData.map((val, id) => {
                    if (!val) return <></>;

                    const {
                        phone_type,
                        phone_country_code,
                        phone_number,
                        phone_extension,
                        [editTrackingKey]: editTrackingValue,
                    } = val;

                    const phoneNumberCode = phone_country_code?.toString() ?? "";
                    const phoneNumber = phone_number
                        ? `+ ${phoneNumberCode} ${phone_number}`.replace(/\s/g, "")
                        : "";
                    const phoneMeta = parsePhoneNumber(phoneNumber);

                    const phoneCountry = phoneMeta ? phoneMeta?.country : "US";

                    if (phoneMeta && phoneNumber) {
                        val["formatted_phone_number"] = phoneNumber;
                    }

                    if (phoneEditorType === PHONE_EDITOR_TYPES.employment) {
                        val["phone_extension"] =
                            phone_type === phoneEditorType.home.key ? phone_extension : "";
                    }

                    const renderRemove = (
                        <EuiFlexGroup
                            style={{
                                display: "flex",
                                alignItems: "center",
                            }}
                            gutterSize="none"
                            responsive={false}
                        >
                            <EuiFlexItem grow={false}>
                                <EuiToolTip position="bottom" content="Remove">
                                    <EuiButtonEmpty
                                        onClick={(e) => handleRemove(editTrackingKey, editTrackingValue)}
                                        size="s"
                                        color="danger"
                                        style={{ marginLeft: "3px" }}
                                    >
                                        {removeIcon()}
                                    </EuiButtonEmpty>
                                </EuiToolTip>
                            </EuiFlexItem>

                            <EuiFlexItem grow={false}>{getPrimaryOption(val)}</EuiFlexItem>
                        </EuiFlexGroup>
                    );

                    return (
                        <>
                            <div
                                key={`${editTrackingValue ?? "edit-phone-"}-${id}`}
                                className={"edit-display react-phone2"}
                            >
                                <Popover
                                    render={(close, open) => {
                                        return (
                                            <EuiContextMenuPanel>
                                                <div
                                                    style={{
                                                        cursor: "pointer",
                                                    }}
                                                    name={phoneEditorType.home.key}
                                                    value={phoneEditorType.home.key}
                                                    onClick={() => {
                                                        handleChange(
                                                            {
                                                                ...val,
                                                                phone_type: phoneEditorType.home.key,
                                                            },
                                                            editTrackingValue,
                                                            "phone_type",
                                                            close
                                                        );
                                                    }}
                                                >
                                                    {phoneEditorType.home.icon(
                                                        phone_type === phoneEditorType.home.key
                                                            ? variables.linkColor
                                                            : variables.primaryColor
                                                    )}
                                                </div>
                                                <EuiSpacer size="m" />
                                                <div
                                                    style={{
                                                        cursor: "pointer",
                                                        marginLeft: "2px",
                                                    }}
                                                    value={phoneEditorType.cell.key}
                                                    name={phoneEditorType.cell.key}
                                                    onClick={() => {
                                                        handleChange(
                                                            {
                                                                ...val,
                                                                phone_type: phoneEditorType.cell.key,
                                                            },
                                                            editTrackingValue,
                                                            "phone_type",
                                                            close
                                                        );
                                                    }}
                                                >
                                                    {phoneEditorType.cell.icon(
                                                        phone_type === phoneEditorType.cell.key
                                                            ? variables.linkColor
                                                            : variables.primaryColor
                                                    )}
                                                </div>
                                            </EuiContextMenuPanel>
                                        );
                                    }}
                                    display={(close, open, click) => {
                                        return (
                                            <EuiButtonEmpty
                                                size="s"
                                                iconType="arrowDown"
                                                iconSide="right"
                                                onClick={click}
                                            >
                                                {phone_type === phoneEditorType.home.key ? (
                                                    <div
                                                        style={{
                                                            marginLeft: "1px",
                                                        }}
                                                    >
                                                        {phoneEditorType.home.icon()}
                                                    </div>
                                                ) : (
                                                    <div
                                                        style={{
                                                            marginLeft: "2px",
                                                            marginRight: "3px",
                                                        }}
                                                    >
                                                        {phoneEditorType.cell.icon()}
                                                    </div>
                                                )}
                                            </EuiButtonEmpty>
                                        );
                                    }}
                                    hasArrow={false}
                                    panelStyle={{
                                        minWidth: "auto",
                                    }}
                                />
                                <PhoneInput
                                    flags={flags}
                                    placeholder="Phone number"
                                    name={"phone_number"}
                                    value={val["formatted_phone_number"]}
                                    defaultCountry={phoneCountry}
                                    onChange={(value) =>
                                        handleChange(
                                            {
                                                ...val,
                                                formatted_phone_number: value || "",
                                            },
                                            val[editTrackingKey],
                                            "formatted_phone_number"
                                        )
                                    }
                                />
                                {phoneEditorType === PHONE_EDITOR_TYPES.personal ? renderRemove : null}
                            </div>
                            {phoneEditorType === PHONE_EDITOR_TYPES.employment ? (
                                <div class="phone-extension">
                                    {phoneEditorType === PHONE_EDITOR_TYPES.employment ? (
                                        <input
                                            maxlength="5"
                                            type="text"
                                            compressed
                                            style={{
                                                width: "50px",
                                                marginLeft: "0.15em",
                                            }}
                                            placeholder={"Ext"}
                                            className={`edit-display--phone-number`}
                                            key={editTrackingValue}
                                            name={"phone_extension"}
                                            value={val["phone_extension"]}
                                            disabled={
                                                phone_type !== phoneEditorType.home.key ? "disabled" : ""
                                            }
                                            onChange={(e) =>
                                                handleChange(
                                                    {
                                                        ...val,
                                                        phone_extension: e.target.value,
                                                    },
                                                    editTrackingValue,
                                                    "phone_extension"
                                                )
                                            }
                                        />
                                    ) : null}
                                    {renderRemove}
                                </div>
                            ) : null}
                        </>
                    );
                })}
            </div>
        );
    };

    const onAddHandler = (editedData, setEditState, addedItemNewIndex, setAddedItemNewIndex) => {
        if (
            !editedData ||
            !setEditState ||
            addedItemNewIndex === undefined ||
            addedItemNewIndex === null ||
            setAddedItemNewIndex === undefined ||
            setAddedItemNewIndex === null
        )
            return;

        const newData = editedData.length > 0 ? cloneDeep(editedData) : [];
        const newId = "new__" + addedItemNewIndex;

        newData.push({
            person_phone_id: newId,
            phone_number: "",
            phone_country_code: "",
            phone_type: phoneEditorType.home.key,
            person_main_id: personId,
            ...(phoneEditorType === PHONE_EDITOR_TYPES.employment ? { phone_extension: "" } : {}),
        });

        setEditState(newData);
        setAddedItemNewIndex(addedItemNewIndex + 1);
    };

    const onSaveHandler = (editedData, data) => {
        /**
         * Determine deleted items.
         */

        // Get all tracking ids for both.
        const editedDataTrackingIds = [];

        for (let i in editedData) {
            editedDataTrackingIds.push(editedData[i][TRACKING_KEY]);
        }

        // Determine removed tracking ids from the "editedData" (we will delete these)
        const deletedItems = [];

        for (let i in data) {
            const trackingId = data[i][TRACKING_KEY];

            if (!editedDataTrackingIds.includes(trackingId)) {
                deletedItems.push({ person_phone_id: trackingId });
            }
        }

        /**
         * Determine edited external links.
         */

        // Do updates (Check similar ids and if they are different, then push them into update container)
        const editedItems = {};

        for (let i in data) {
            for (let j in editedData) {
                const {
                    [TRACKING_KEY]: trackingId,
                    phone_number: phoneNumber,
                    phone_type: phoneType,
                    phone_country_code: phoneCountryCode,
                    phone_extension: phoneExtension,
                } = data[i];

                const {
                    [TRACKING_KEY]: editedTrackingId,
                    phone_number: editedPhoneNumber,
                    phone_type: editedPhoneType,
                    phone_country_code: editPhoneCountryCode,
                    phone_extension: editedPhoneExtension,
                } = editedData[j];

                const editedDataPersonEmailId = editedData[j][TRACKING_KEY];

                if (trackingId === editedTrackingId) {
                    if (
                        phoneNumber !== editedPhoneNumber ||
                        phoneType !== editedPhoneType ||
                        phoneCountryCode !== editPhoneCountryCode ||
                        (phoneEditorType === PHONE_EDITOR_TYPES.employment &&
                            phoneExtension !== editedPhoneExtension)
                    ) {
                        let filterList = [
                            "person_phone_id",
                            "person_main_ref_id",
                            "phone_type",
                            "phone_number",
                            "phone_country_code",
                        ];

                        phoneEditorType === PHONE_EDITOR_TYPES.employment &&
                            filterList.push("phone_extension");
                        let filteredEditedData = {
                            ...editedData[j],
                        };

                        for (let i in filteredEditedData) {
                            if (!filterList.includes(i)) {
                                delete filteredEditedData[i];
                            }
                        }

                        editedItems[editedDataPersonEmailId] = {
                            ...filteredEditedData,
                            phone_number: editedPhoneNumber,
                            phone_type: editedPhoneType,
                            phone_country_code: editPhoneCountryCode,
                        };
                    }
                }
            }
        }

        /**
         * Do insert (pending).
         */
        const addedItems = [];

        for (let i in editedData) {
            const personEmailId = editedData[i][TRACKING_KEY];

            if (personEmailId.toString().search(/new__/) !== -1) {
                const newValue = editedData[i][DISPLAY_KEY];

                if (newValue !== "") {
                    let addedData = cloneDeep(editedData[i]);

                    addedItems.push(addedData);
                }
            }
        }

        const isPrimaryUpdated = hasPrimaryChanged(editedData, data, TRACKING_KEY);

        /**
         * Perform delete, update, and add operations if any variables change.
         */
        if (
            size(deletedItems) !== 0 ||
            size(editedItems) !== 0 ||
            size(addedItems) !== 0 ||
            isPrimaryUpdated
        ) {
            // Format json output for the "Add Email" API.
            if (size(addedItems) !== 0) {
                for (let i in addedItems) {
                    delete addedItems[i][TRACKING_KEY];

                    // Attach this property because it's required for the "add" endpoint
                    addedItems[i]["person_main_ref_id"] = personId;
                }
            }

            if (size(editedItems) !== 0) {
                for (let i in editedItems) {
                    // Attach this property because it's required for the "edit" endpoint
                    editedItems[i]["person_main_ref_id"] = personId;
                }
            }

            const addedItemsComposed = [];
            const editedItemsComposed = [];
            const deletedItemsComposed = [];

            for (let i in addedItems) {
                addedItemsComposed.push({
                    // action: ADD_UPDATE_PERSON_PHONE,
                    args: addedItems[i],
                });
            }

            for (let i in editedItems) {
                editedItemsComposed.push({
                    // action: ADD_UPDATE_PERSON_PHONE,
                    args: editedItems[i],
                });
            }

            for (let i in deletedItems) {
                deletedItemsComposed.push({
                    // action: DELETE_PERSON_PHONE,
                    args: deletedItems[i],
                });
            }

            const isPrimaryUpdatedData = [];

            // Append an is primary command.
            if (isPrimaryUpdated) {
                isPrimaryUpdatedData.push({
                    args: {
                        person_main_ref_id: personId,
                        person_table_type_c: "phone",
                        person_record_ref_id: isPrimaryUpdated,
                    },
                });
            }

            syncData(
                deletedItemsComposed,
                editedItemsComposed,
                addedItemsComposed,
                isPrimaryUpdatedData,
                setLoading,
                loadData
            );
        } else {
            alert("not changing anything");
        }
    };

    if (!phoneEditorType) return <></>;

    return (
        <AsyncEditableContent
            isLoading={loading}
            icon={phoneEditorType.icon}
            data={data}
            placeholder={phoneEditorType.placehold}
            editTrackingKey={TRACKING_KEY}
            editDisplayKey={DISPLAY_KEY}
            editDisplayOverride={editDisplayOverride}
            onSaveHandler={onSaveHandler}
            onAddHandler={onAddHandler}
            render={(data) =>
                data?.map((phone, id) => {
                    if (!phone) return <></>;

                    const {
                        person_phone_id,
                        phone_type,
                        phone_country_code,
                        phone_number,
                        is_primary,
                        phone_extension,
                    } = phone;

                    return (
                        <div key={person_phone_id ?? `phone-id-${id}`}>
                            {phoneEditorType === PHONE_EDITOR_TYPES.personal ? (
                                phone_type === phoneEditorType.home.key ? (
                                    phoneHomeIcon(variables.primaryColor)
                                ) : (
                                    <div
                                        style={{
                                            display: "inline",
                                            marginRight: "4px",
                                            marginLeft: "1.5px",
                                        }}
                                    >
                                        {phoneCellIcon(variables.primaryColor)}
                                    </div>
                                )
                            ) : null}
                            {phoneEditorType === PHONE_EDITOR_TYPES.employment ? (
                                phone_type === phoneEditorType.home.key ? (
                                    <div
                                        style={{
                                            display: "inline",
                                            marginRight: "4px",
                                            marginLeft: "3px",
                                        }}
                                    >
                                        {phoneWorkHomeIcon(variables.primaryColor)}
                                    </div>
                                ) : (
                                    <div
                                        style={{
                                            display: "inline",
                                            marginRight: "8px",
                                            marginLeft: "3px",
                                        }}
                                    >
                                        {phoneWorkCellIcon(variables.primaryColor)}
                                    </div>
                                )
                            ) : null}{" "}
                            <span>
                                {`+${phone_country_code ?? ""} ${phone_number ?? ""}`}
                                {phone_type === "work" ? ` ext. ${phone_extension ?? ""}` : ""}
                            </span>
                            {is_primary === 1 ? (
                                <span
                                    style={{
                                        marginLeft: "5px",
                                        color: "#006BB4",
                                    }}
                                >
                                    <FontAwesomeIcon icon={faCheckCircle} />
                                </span>
                            ) : null}
                        </div>
                    );
                })
            }
        />
    );
};

export default PersonPhone;
