import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { useHistory } from "react-router";

import { SVGIcon } from "components/Common";
import { checkIcon, matchIcon } from "components/Common/Icons";

import { EMAIL_STATUS_OPTIONS } from "./constants";
import { useGlobal } from "core/useHooks";
import { EuiPopover, EuiText, EuiLink } from "@elastic/eui";

import StaticItemBase from "../StaticItemBase";
import EmailSettingBar from "./EmailSettingBar";
import { getGSSValue } from "components/global/utils";
import {
	GLOBAL_EMAIL_PHONE_RANGES,
	GLOBAL_EMAIL_TYPE_OPTIONS,
} from "components/global/constants";

/**
 * Main Components
 */
const StaticEmailItem = (props) => {
	const {
		personId = null,
		email,
		isPrimary = false,
		hasIcon = true,
		hasIconLabel = false,
		onEdit,
		onDelete,
		onValidate,
		onChangeStatus,
		onSendMessage,
		disabled = false,
	} = props;

	const history = useHistory();
	const { gssList, getMatchGlobalRecords } = useGlobal();

	const [isPopoverOpen, setIsPopoverOpen] = useState(false);
	const [matchedPerson, setMatchedPerson] = useState(null);

	useEffect(() => {
		if (email?.global_email_details && gssList?.length > 0) {
			const emailDetail = email?.global_email_details;
			const query = [
				{
					source_base_type_sc: getGSSValue(gssList, "OBJECT_BASE", "PERSON"),
					source_record_id: Number(personId),
					global_email_details: {
						email_value: emailDetail?.email_value,
					},
				},
			];

			getMatchGlobalRecords(query, (res) => {
				if (res?.person_main?.[0]) {
					const person = res?.person_main?.[0];
					setMatchedPerson({
						id: person?.id,
						fullName: `${person?.name_first} ${person?.name_last}`,
					});
				} else {
					setMatchedPerson(null);
				}
			});
		}
	}, [email, gssList]);

	/** Icon for Email Type - default: Home Email icon */
	const emailTypeInfo =
		GLOBAL_EMAIL_TYPE_OPTIONS.find((item) => item.value === email?.email_type_c) ||
		GLOBAL_EMAIL_TYPE_OPTIONS[0];
	const rangeInfo = GLOBAL_EMAIL_PHONE_RANGES.find(
		(item) => item.value === emailTypeInfo.range
	);
	const icon = emailTypeInfo?.icon || GLOBAL_EMAIL_TYPE_OPTIONS[0].icon;

	/** Icon for Email Status - default: none icon */
	const iconEmailStatus = EMAIL_STATUS_OPTIONS.find(
		(item) => item.value === email?.global_email_details?.email_status_c
	)?.icon;

	// functions
	const onButtonClick = () => {
		setIsPopoverOpen((isPopoverOpen) => !isPopoverOpen);
	};

	const closePopover = () => {
		setIsPopoverOpen(false);
	};

	const gotoPersonDetail = () => {
		history.push(`/person/${matchedPerson?.id}`);
	};

	/** Rendering */
	if (!email) {
		return null;
	}

	const renderIcons = () => {
		if (!(email && iconEmailStatus) && !isPrimary && !matchedPerson) {
			return null;
		}
		return (
			<>
				{email && iconEmailStatus ? (
					<div className="status-icon mt-1">{iconEmailStatus}</div>
				) : null}
				{isPrimary && <div className="check-icon ml-2  mt-1">{checkIcon()}</div>}
				{matchedPerson && (
					<EuiPopover
						button={
							<div
								className="check-icon cursor-pointer ml-2 mt-1"
								onClick={onButtonClick}
							>
								{matchIcon()}
							</div>
						}
						isOpen={isPopoverOpen}
						closePopover={closePopover}
						anchorPosition="rightCenter"
						size="s"
						panelPaddingSize="s"
						display="block"
					>
						<PopoverContainer>
							<EuiText size="s">
								Matches:&nbsp;
								<EuiLink onClick={gotoPersonDetail}>
									{matchedPerson?.id}-{matchedPerson?.fullName}
								</EuiLink>
							</EuiText>
						</PopoverContainer>
					</EuiPopover>
				)}
			</>
		);
	};

	return (
		<Wrapper>
			<StaticItemBase
				extraSetting={
					<EmailSettingBar
						onEdit={onEdit}
						onDelete={onDelete}
						onValidate={onValidate}
						onChangeStatus={onChangeStatus}
						onSendMessage={onSendMessage}
					/>
				}
				iconGroups={renderIcons()}
				disabled={disabled}
			>
				{hasIcon ? (
					<div className="icon--wrapper mr-2">{rangeInfo?.icon() || null}</div>
				) : null}
				{hasIconLabel ? <span>{emailTypeInfo?.iconLabel}&nbsp;</span> : null}
				<StaticEmailWrapper>
					<div className="d-flex">
						{email?.global_email_details?.email_value || ""}
					</div>
				</StaticEmailWrapper>
			</StaticItemBase>
		</Wrapper>
	);
};

export default StaticEmailItem;

/**
 * Styled Components
 */
const Wrapper = styled.div`
	display: flex;
	width: 100%;

	.email-valid {
		margin-top: auto;
		margin-bottom: auto;
		margin-left: auto;
	}

	.euiCheckbox .euiCheckbox__input + .euiCheckbox__square {
		border-radius: 50%;
	}

	.icon--wrapper {
		display: flex;
		align-items: center;
	}
`;

const StaticEmailWrapper = styled.div`
	display: flex;
	flex-direction: row;
	align-items: center;

	.status-icon {
		margin-left: 1rem;

		svg {
			width: 1.333rem;
			height: auto;
		}
	}

	.check-icon {
		margin-left: 0.5rem;
		line-height: 1;

		svg {
			margin-top: 0.25rem;
		}
	}
`;

const PopoverContainer = styled.div`
	.euiContextMenuItem {
		padding: 2px;
	}
`;
