import React from "react";
import styled from "styled-components";

import ButtonsWrapper from "./ButtonsWrapper";

/**
 * Component for Outbound Button
 */
const OutboundButton = (props) => {
	const { onOutbound } = props;

	const buttons = [
		{
			label: "Outbound",
			callback: () => onOutbound(),
			icon: "",
		},
	];

	return (
		<ButtonWrapper>
			<ButtonsWrapper buttons={buttons} color="secondary" />
		</ButtonWrapper>
	);
};

export default OutboundButton;

const ButtonWrapper = styled.div`
	margin-left: auto;
	min-width: 74px;
	height: var(--sp-12);

	.main-button,
	.dropdown-button {
		background-color: var(--eui-medium-shade-color) !important;
		border-color: var(--eui-medium-shade-color) !important;
	}
`;
