import React, { useCallback, useLayoutEffect, useMemo, useState } from "react";
import { NavLink } from "react-router-dom";
import { EuiAvatar, EuiFlexGroup, EuiFlexItem, EuiText, EuiToolTip } from "@elastic/eui";
import styled from "styled-components";
import { orderBy } from "lodash";

import { sortByKey, truncate } from "helpers/utilities";
import { getGCSValue } from "components/global/utils";
import * as MODEL from "core/model";

import EnrichmentIcons from "./EnrichmentIcons";

import variables from "assets/css/variables.scss";

/**
 * Component for Profile
 */
const Profile = (props) => {
	const {
		profile,
		enrichmentDetails,
		personInfo,
		globals,
		onClickDetailInfo,
		editable = true,
	} = props;

	const [maxTitleWidth, setMaxTitleWidth] = useState(100);
	const [maxCompanyWidth, setMaxCompanyWidth] = useState(100);
	/**
	 * Get currentCompany from person information
	 *
	 * @param {*} person_support
	 * @param {*} person_history_work
	 */
	const getCurrentCompany = (person_history_work) => {
		const currentCompany = {
			companyId: null,
			companyName: "",
			jobTitle: "",
		};

		// Check if work_history has the company_name for the most recent START date
		const sortedHistory = sortByKey(person_history_work, "date_start", -1);
		if (sortedHistory?.[0]?.company_main) {
			currentCompany.companyId = sortedHistory?.[0]?.company_main?.id;
			currentCompany.companyName = sortedHistory?.[0]?.company_main?.company_name;
		}
		currentCompany.jobTitle = sortedHistory?.[0]?.work_title;

		return currentCompany;
	};

	const {
		id,
		photoURL,
		firstName,
		lastName,
		currentJobTitle,
		currentCompany,
		currentCompanyId,
		personId,
		rejectedList,
		type,
	} = useMemo(() => {
		if (profile) {
			return { ...profile };
		}

		if (personInfo) {
			const {
				id: personId,
				person_external_link: externalLinks,
				name_first,
				name_last,
				person_history_work: work_history,
				type,
			} = personInfo;

			const photoURL =
				externalLinks?.filter((link) => link?.global_link_type_c === 3)?.[0]
					?.global_url_details?.url_value ?? "";

			const currentCompany = getCurrentCompany(work_history);

			return {
				id: personId,
				photoURL,
				firstName: name_first || "First Name N/A",
				lastName: name_last || "Last Name N/A",
				currentJobTitle: currentCompany.jobTitle || "Job Title N/A",
				currentCompany: currentCompany.companyName,
				currentCompanyId: currentCompany?.companyId,
				personId,
				type,
			};
		}
	}, [profile, personInfo, enrichmentDetails]);

	const handleClickDetailInfo = () => {
		if (editable && onClickDetailInfo) {
			onClickDetailInfo();
		}
	};

	// update layout size
	const getMaxTitleWidth = () => {
		const widthProfileElem = document.getElementsByClassName("profile");
		const widthProfileNameElem = document.getElementById("profile-name-" + id);

		let res = 100;
		if (widthProfileElem.length && widthProfileNameElem) {
			if (window.innerWidth >= 1500) {
				res =
					widthProfileElem[0].offsetWidth -
					widthProfileNameElem?.offsetWidth -
					60;
			} else {
				res = widthProfileElem[0].offsetWidth - 60;
			}
		}

		return res;
	};

	const getMaxCompanyWidth = () => {
		const widthProfileElem = document.getElementsByClassName("profile");

		let res = 100;
		if (widthProfileElem.length) {
			res = widthProfileElem[0].offsetWidth - 60;
		}

		return res;
	};

	useLayoutEffect(() => {
		window.addEventListener("resize", updateSize);
		updateSize();
		return () => window.removeEventListener("resize", updateSize);
	}, []);

	const updateSize = () => {
		setMaxTitleWidth(getMaxTitleWidth());
		setMaxCompanyWidth(getMaxCompanyWidth());
	};

	return (
		<Wrapper className="profile">
			<EuiFlexGroup gutterSize="s" className="w-100">
				<EuiFlexItem grow={false}>
					<PhotoButton currentCompany={currentCompany}>
						<EuiAvatar
							size="s"
							imageUrl={photoURL || ""}
							name={firstName + " " + lastName}
							onClick={handleClickDetailInfo}
						/>
					</PhotoButton>
				</EuiFlexItem>
				<EuiFlexItem grow={false}>
					<div className="profile-name-and-title">
						<div className="profile-name--container">
							<span id={"profile-name-" + id} className="profile-name">
								{type === "company" ? (
									<NavLink to={editable ? `/company/${id}` : "#"}>
										{firstName}({id})
									</NavLink>
								) : (
									<NavLink
										to={
											!editable || rejectedList
												? "#"
												: `/person/${id}`
										}
									>
										{firstName + " " + lastName}
									</NavLink>
								)}
							</span>
						</div>
						<div>
							<EuiToolTip position="top" content={currentJobTitle}>
								<p
									className="profile-job-title"
									style={{ maxWidth: maxTitleWidth }}
								>
									{currentJobTitle !== "null" ? (
										currentJobTitle
									) : (
										<i>Title Not Provided</i>
									)}
								</p>
							</EuiToolTip>
						</div>
					</div>
					<div className="profile-company">
						<div className="profile-company-container">
							{type === "company" ? (
								<EuiText
									className="profile-company-label"
									size="s"
									style={{
										display: currentCompany ? "block" : "none",
									}}
								>
									<NavLink
										className="profile-company-title"
										style={{ maxWidth: maxCompanyWidth }}
										to={`company/${currentCompanyId}`}
									>
										{currentCompany}
									</NavLink>
								</EuiText>
							) : (
								<EuiText className="profile-company-label" size="s">
									{!currentCompany ? (
										<i>Company Not Provided</i>
									) : (
										<EuiToolTip
											position="top"
											content={currentCompany}
										>
											<EuiText
												style={{
													cursor: "pointer",
												}}
												size="s"
											>
												<NavLink
													className="profile-company-title"
													style={{
														maxWidth: maxCompanyWidth,
													}}
													to={
														currentCompanyId
															? `company/${currentCompanyId}`
															: `company/${id}`
													}
												>
													{currentCompany}
												</NavLink>
											</EuiText>
										</EuiToolTip>
									)}
								</EuiText>
							)}
						</div>
					</div>
				</EuiFlexItem>
				<EnrichmentIcons
					globals={globals}
					enrichmentDetails={enrichmentDetails}
					type={type}
				/>
			</EuiFlexGroup>
		</Wrapper>
	);
};

export default Profile;

const Wrapper = styled.div`
	width: 100%;

	.profile-name {
		font-style: normal;
		font-weight: 600;
		font-size: 1rem;
		line-height: 1.5rem;
		letter-spacing: -0.08px;
		color: #006bb4;
	}

	.profile-job-title {
		font-style: normal;
		font-weight: normal;
		font-size: 1rem;
		line-height: 1.5rem;
		letter-spacing: -0.08px;
		color: ${variables.primaryColor};

		cursor: pointer;
		white-space: nowrap;
		overflow: hidden;
		text-overflow: ellipsis;
		/* max-width: 9rem; */
	}

	.profile-company {
		display: flex;
	}

	.profile-company-label {
		font-style: normal;
		font-weight: normal;
		font-size: 1rem;
		line-height: 1.5rem;
		letter-spacing: -0.08px;
		color: ${variables.primaryColor};
	}

	.profile-company-title {
		cursor: pointer;
		white-space: nowrap;
		overflow: hidden;
		text-overflow: ellipsis;
		display: inline-block;
	}

	.profile-name--container {
		margin-right: 0.5rem;
	}

	.profile-name-and-title {
		display: flex;
	}

	@media only screen and (max-width: 1500px) {
		.profile-name-and-title {
			flex-direction: column;
		}
	}
`;

const PhotoButton = styled.button`
	padding-top: ${({ currentCompany }) => (currentCompany ? "9px" : "0")};
`;
