/** @format */

import React, { useState } from "react";
import {
    EuiHeaderSectionItemButton,
    EuiIcon,
    EuiKeyPadMenu,
    EuiKeyPadMenuItem,
    EuiPopover,
} from "@elastic/eui";
import Disabled from "../../Pages/Disabled/Disabled";

const UserAppMenu = () => {
    const [isOpen, setIsOpen] = useState(false);

    const closeMenu = () => {
        setIsOpen(false);
    };

    const onMenuButtonClick = () => {
        setIsOpen(!isOpen);
    };

    const button = (
        <EuiHeaderSectionItemButton
            aria-controls="keyPadMenu"
            aria-expanded={isOpen}
            aria-haspopup="true"
            aria-label="Apps menu with 1 new app"
            onClick={onMenuButtonClick}
        >
            <EuiIcon type="apps" size="m" />
        </EuiHeaderSectionItemButton>
    );
    return (
        <Disabled>
            <EuiPopover
                id="headerAppMenu"
                ownFocus
                button={button}
                isOpen={isOpen}
                anchorPosition="downRight"
                closePopover={closeMenu}
            >
                <EuiKeyPadMenu id="keyPadMenu" style={{ width: 288 }}>
                    <EuiKeyPadMenuItem
                        className={"show-border-non-dynamic"}
                        label="Templates"
                        href="#"
                    >
                        <EuiIcon type="emsApp" size="l" />
                    </EuiKeyPadMenuItem>

                    <EuiKeyPadMenuItem
                        className={"show-border-non-dynamic"}
                        label="Reports"
                        href="#"
                    >
                        <EuiIcon type="reportingApp" size="l" />
                    </EuiKeyPadMenuItem>

                    <EuiKeyPadMenuItem
                        className={"show-border-non-dynamic"}
                        label="Duplicate Queue"
                        href="#"
                    >
                        <EuiIcon type="sqlApp" size="l" />
                    </EuiKeyPadMenuItem>
                </EuiKeyPadMenu>
            </EuiPopover>
        </Disabled>
    );
};

export default UserAppMenu;
