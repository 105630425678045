import API from "../../api";
import {
    ADD_ACCOUNT_TO_SECURITY_GROUP,
    SAVE_SECURITY_GROUP,
    DELETE_SECURITY_GROUP,
    GET_SECURITY_ACCESS_LEVEL,
    GET_SECURITY_GROUP_MEMBER_ACCESS,
    GET_SECURITY_GROUP_MEMBERS,
    REMOVE_ACCOUNT_FROM_SECURITY_GROUP,
    REMOVE_SECURITY_ACCESS_FOR_GROUP,
    SAVE_SECURITY_ACCESS_FOR_GROUP,
    SET_SECURITY_GROUP,
    SET_SECURITY_MAIN,
} from "./actionTypes";
import Service from "core/service";
import { logError } from "store/error";
import { objectChecker } from "helpers/utilities";

export const setSecurityMain = (data) => {
    return {
        type: SET_SECURITY_MAIN,
        payload: data,
    };
};

export const setSecurityGroup = (data) => {
    return {
        type: SET_SECURITY_GROUP,
        payload: data,
    };
};

export const fetchAllSecurityGroup = () => {
    return async (dispatch) => {
        try {
            const data = await Service.security.getSecurityDetail();

            if (data?.data) {
                const result = objectChecker(data, ["data", "0", "security_main"]);
                const security_main = {
                    security_name: result?.security_name,
                    security_type_c: result?.security_type_c,
                };
                dispatch(setSecurityMain(security_main));
                dispatch(setSecurityGroup(result?.security_group));
            }
        } catch (err) {
            dispatch(
                logError("fetch_failure", "none", {
                    Error: "Error fetching security main: " + JSON.stringify(err),
                })
            );
            dispatch(setSecurityMain(null));
            dispatch(setSecurityGroup(null));
        }
    };
};

export const getSecurityAccessLevel = () => {
    return async (dispatch) => {
        try {
            const result = await API().SecurityGroup().getSecurityAccessLevel({});

            if (result) {
                dispatch({
                    type: GET_SECURITY_ACCESS_LEVEL,
                    payload: result,
                });
            }
        } catch (err) {
            alert(
                "Error fetching security access object group data: " + JSON.stringify(err)
            );
        }
    };
};

export const setDeleteSecurityGroup = (data) => {
    return {
        type: DELETE_SECURITY_GROUP,
        payload: data,
    };
};

export const deleteSecurityGroup = (params) => {
    return async (dispatch) => {
        try {
            const result = await Service.security.deleteSecurity(params);

            if (result) {
                dispatch(setDeleteSecurityGroup(result));
            }
        } catch (err) {
            dispatch(
                logError("fetch_failure", "none", {
                    Error: "Error deleteing security group: " + JSON.stringify(err),
                })
            );
            dispatch(setDeleteSecurityGroup(null));
        }
    };
};

export const setSaveSecurityGroup = (data) => {
    return {
        type: SAVE_SECURITY_GROUP,
        payload: data,
    };
};

export const saveSecurityGroup = (params) => {
    return async (dispatch) => {
        try {
            const result = await Service.security.updateSecurity(params);

            if (result) {
                dispatch(setSaveSecurityGroup(result));
            }
        } catch (err) {
            dispatch(
                logError("fetch_failure", "none", {
                    Error: "Error adding security group: " + JSON.stringify(err),
                })
            );
            dispatch(setSaveSecurityGroup(null));
        }
    };
};

export const setSecurityGroupMembers = (data) => {
    return {
        type: GET_SECURITY_GROUP_MEMBERS,
        payload: data,
    };
};

export const getSecurityGroupMembers = (params) => {
    return async (dispatch) => {
        try {
            const result = await Service.security.getSecurityGroupMembersById(params);

            if (result?.data) {
                const result = result?.data?.map((item) => item?.security_group_member);
                dispatch(setSecurityGroupMembers(result));
            }
        } catch (err) {
            dispatch(
                logError("fetch_failure", "none", {
                    Error: "Error fetching security group member: " + JSON.stringify(err),
                })
            );
            dispatch(setSecurityGroupMembers(null));
        }
    };
};

export const setRemoveAccountFromSecurityGroup = (data) => {
    return {
        type: REMOVE_ACCOUNT_FROM_SECURITY_GROUP,
        payload: data,
    };
};

export const removeAccountFromSecurityGroup = (params) => {
    return async (dispatch) => {
        try {
            const result = await Service.security.deleteSecurity(params);

            if (result) {
                dispatch(setRemoveAccountFromSecurityGroup(result));
            }
        } catch (err) {
            dispatch(
                logError("fetch_failure", "none", {
                    Error:
                        "Error deleting account from security group member: " +
                        JSON.stringify(err),
                })
            );
            dispatch(setRemoveAccountFromSecurityGroup(null));
        }
    };
};

export const setAddAccountToSecurityGroup = (data) => {
    return {
        type: ADD_ACCOUNT_TO_SECURITY_GROUP,
        payload: data,
    };
};

export const addAccountToSecurityGroup = (params) => {
    return async (dispatch) => {
        try {
            const result = await Service.security.updateSecurity(params);

            if (result) {
                dispatch(setAddAccountToSecurityGroup(result));
            }
        } catch (err) {
            dispatch(
                logError("fetch_failure", "none", {
                    Error:
                        "Error adding account to security group member: " +
                        JSON.stringify(err),
                })
            );
            dispatch(setAddAccountToSecurityGroup(null));
        }
    };
};

export const setGetSecurityGroupMemberAccess = (data) => {
    return {
        type: GET_SECURITY_GROUP_MEMBER_ACCESS,
        payload: data,
    };
};

export const getSecurityGroupMemberAccess = (params) => {
    return async (dispatch) => {
        try {
            const result = await Service.security.getSecurityGroupAccessById(params);

            if (result) {
                dispatch(setGetSecurityGroupMemberAccess(result));
            }
        } catch (err) {
            dispatch(
                logError("fetch_failure", "none", {
                    Error: "Error getting security group access: " + JSON.stringify(err),
                })
            );
            dispatch(setGetSecurityGroupMemberAccess(null));
        }
    };
};

export const setUpdateSecurityAccessForGroup = (data) => {
    return {
        type: SAVE_SECURITY_ACCESS_FOR_GROUP,
        payload: data,
    };
};

export const saveSecurityAccessForGroup = (params) => {
    return async (dispatch) => {
        try {
            const result = await Service.security.updateSecurity(params);

            if (result) {
                dispatch(setUpdateSecurityAccessForGroup(result));
            }
        } catch (err) {
            dispatch(
                logError("fetch_failure", "none", {
                    Error: "Error updating security group access: " + JSON.stringify(err),
                })
            );
            dispatch(setUpdateSecurityAccessForGroup(null));
        }
    };
};

export const setRemoveSecurityAccessForGroup = (data) => {
    return {
        type: REMOVE_SECURITY_ACCESS_FOR_GROUP,
        payload: data,
    };
};

export const removeSecurityAccessForGroup = (params) => {
    return async (dispatch) => {
        try {
            const result = await Service.security.deleteSecurity(params);

            if (result) {
                dispatch(setRemoveSecurityAccessForGroup(result));
            }
        } catch (err) {
            dispatch(
                logError("fetch_failure", "none", {
                    Error: "Error removing security group access: " + JSON.stringify(err),
                })
            );
            dispatch(setRemoveSecurityAccessForGroup(null));
        }
    };
};
