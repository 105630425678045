import * as actionTypes from "./actionTypes";

/**
 * Functions for Actions
 */
export const setPlivoLogin = (logged) => {
    return {
        type: actionTypes.SET_PLIVO_LOGIN,
        payload: logged,
    };
};

export const setPlivoConnection = (connectionState) => {
    return {
        type: actionTypes.SET_PLIVO_CONNECTION,
        payload: connectionState,
    };
};

export const setPlivoCallState = (callState) => {
    return {
        type: actionTypes.SET_PLIVO_CALL_STATE,
        payload: callState,
    };
};
