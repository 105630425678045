import React, { useMemo, useState } from "react";
import styled from "styled-components";
import { EuiPopover, EuiFlexGroup, EuiFlexItem } from "@elastic/eui";

import { checkLightIcon } from "components/Common/Icons";
import { CAMPAIGN_ITEM_DISPOSITIONS } from "./constants";
import { useCampaign, useNotification, usePerson } from "core/useHooks";

/**
 * Component for Result Item of Campaign
 */
const CampaignResultItem = (props) => {
	const { onChangeStatus, status = 1, showLabel = false, itemId, person } = props;

	const { skipWorkflowForCampaign } = useCampaign();
	const { setNotificationMessage } = useNotification();
	const { getPersonName } = usePerson();

	const [isOpenPopover, setIsOpenPopover] = useState(false);

	const personName = getPersonName(person);

	const { itemStatus } = useMemo(() => {
		const itemStatus =
			Object.values(CAMPAIGN_ITEM_DISPOSITIONS).find(
				(item) => item.value === status
			) || CAMPAIGN_ITEM_DISPOSITIONS.none;

		return {
			itemStatus,
		};
	}, [status]);

	const handleClosePopover = () => {
		setIsOpenPopover(false);
	};

	const handleChangeStatus = (newState) => {
		if (newState !== itemStatus.value) {
			if (onChangeStatus) {
				onChangeStatus(newState);
			}

			if (itemId) {
				skipWorkflowForCampaign(
					{
						campaign_item: [{ id: itemId }],
					},
					(res) => {
						setNotificationMessage({
							type: "normal",
							title: "Successfully",
							message: `Workflow paused for ${personName}`,
							status: "success",
						});
					},
					(err) => {
						setNotificationMessage({
							type: "normal",
							title: "Error",
							message:
								err?.message ??
								`Workflow failed to pause for ${personName}`,
							status: "error",
						});
					}
				);
			}

			setIsOpenPopover(false);
		}
	};

	return (
		<Wrapper
			isOpen={isOpenPopover}
			button={
				<StatusLabel
					onClick={() => setIsOpenPopover(!isOpenPopover)}
					className="cursor-pointer"
					style={{
						color: itemStatus.value
							? "var(--eui-text-color)"
							: "var(--button-disabled-color)",
					}}
				>
					<div
						style={{ background: itemStatus.color }}
						className="item-icon type-p-medium"
					>
						{itemStatus.icon}
					</div>
					{showLabel && <span className="semibold">{itemStatus.label}</span>}
				</StatusLabel>
			}
			closePopover={handleClosePopover}
			panelStyle={{
				width: 180,
				padding: "var(--sp-8) var(--sp-4)",
			}}
			hasArrow={false}
		>
			<EuiFlexGroup direction="column" gutterSize="s">
				{Object.values(CAMPAIGN_ITEM_DISPOSITIONS).map((item) => {
					const isSelected = item.value === itemStatus.value;
					return (
						<EuiFlexItem
							key={`campagin-item-disposition-status-item-${item.value}`}
						>
							<EuiFlexGroup gutterSize="m" alignItems="center">
								<EuiFlexItem grow={1}>
									{isSelected ? checkLightIcon() : <div>&nbsp;</div>}
								</EuiFlexItem>
								<EuiFlexItem grow={9}>
									<StatusListItem
										gutterSize="s"
										alignItems="center"
										className="cursor-pointer"
										onClick={() => handleChangeStatus(item.value)}
									>
										<EuiFlexItem grow={false}>
											<div
												style={{ background: item.color }}
												className="item-icon type-p-medium"
											>
												{item.icon}
											</div>
										</EuiFlexItem>
										<EuiFlexItem>{item.label}</EuiFlexItem>
									</StatusListItem>
								</EuiFlexItem>
							</EuiFlexGroup>
						</EuiFlexItem>
					);
				})}
			</EuiFlexGroup>
		</Wrapper>
	);
};

export default CampaignResultItem;

const Wrapper = styled(EuiPopover)`
	display: flex !important;

	.item-icon {
		display: flex;
		width: var(--sp-12);
		height: var(--sp-12);
		margin-right: var(--sp-2);
		min-width: var(--sp-12);
		border-radius: var(--sp-3);
		align-items: center;
		justify-content: center;
		color: var(--white-color);
	}
`;

const StatusListItem = styled(EuiFlexGroup)`
	.item-icon {
		display: flex;
		width: var(--sp-12);
		height: var(--sp-12);
		min-width: var(--sp-12);
		margin-right: var(--sp-2);
		border-radius: var(--sp-3);
		align-items: center;
		justify-content: center;
		color: var(--white-color);
	}

	svg {
		width: var(--sp-8);
		height: auto;
	}
`;

const StatusLabel = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;
`;
