import { SocketCall, token } from "./SocketProvider";
import axios from "./axiosCustom";

const postData = async (url, obj) => {
	return await axios.post(url, obj === "undefined" ? {} : obj);
};

const socketCall = async (command, params) => {
	let _token = token;

	if (!_token) {
		_token = localStorage.getItem("token");
	}
	const payload = {
		id: command,
		api: "v1",
		arguments: params ? params : {},
		token: _token,
	};

	// console.log("payload: ", JSON.stringify(payload));
	const result = await SocketCall(payload);

	if (result.data.error) {
		// send error message to handler
		if (result.data.errors?.length) {
			handleErrorMessage(result.data.errors, payload);
		}

		throw result.data;
	}

	return result.data;
};

// Error Message Handling with alert
const handleErrorMessage = (errs, payload) => {
	console.log("ERROR payload: ", JSON.stringify(payload));
	if (errs?.length) {
		errs.map((item, index) => {
			const err = JSON.parse(item);
			console.log("error ", index, ": ", err);

			// showing error message and error code.
			console.log(
				"Error (SP_ERROR_CODE): " +
					err?.database_error?.internal?.details +
					" (" +
					err?.database_error?.internal?.SP_ERROR_CODE +
					")" +
					"\n" +
					"Message: " +
					err?.database_error?.details?.mysql_error?.mysql_message
			);
		});
	}
};

/* -- APIs -- */
const Auth = () => {
	return {
		// REST API
		login: (params) => postData("/login", params),
	};
};

const Dashboard = () => {
	return {
		// custom API
		getDashboardData: (params) => socketCall("get-dashboard-data", params),
	};
};

const Person = () => {
	return {
		// custom API
		personList: (params) => socketCall("get-person-list", params),
		getPersonSourcedPlatform: (params) =>
			socketCall("get-person-sourced-platform", params),

		// will depricate
		addUpdatePersonAddress: (params) =>
			socketCall("add-update-person-address", params),
		bulkUpdatePersonSIT: (params) => socketCall("add-update-person-sit", params),
		bulkValidateEmails: (params) => socketCall("validate-email", params),

		// REST API
		uploadFiles_NEW: (params) => postData("/upload-resume", params),
		parseFile_NEW: (params) => postData("/parse-resume", params),

		// Location
		getZipAreas: (params) => socketCall("get-zip-radius", params),
	};
};

const Account = () => {
	return {
		accountList: (params) => socketCall("get-account-list", params),
		getAccountLogsActivity: (params) =>
			socketCall("get-account-logs-activity", params),
		logout: (params) => socketCall("action-account-logout", params),
	};
};

const Company = () => {
	return {
		companyList: (params) => socketCall("get-company-list", params),
		getCompanyDetail: (params) => socketCall("get-company-main-data", params),
	};
};

const Global = () => {
	return {
		globalList: (params) => socketCall("get-global-custom-fields", params),
		updateGlobalCustomField: (params) =>
			socketCall("update-global-custom-field", params),
		addGlobalCustomField: (params) => socketCall("add-global-custom-field", params),
		matchGlobalRecords: (params) =>
			socketCall("check-matching-global-records", params),
	};
};

const SIT = () => {
	return {
		getSitListByKeyword: (params) => socketCall("get-sit-list-by-keyword", params),
		getSitList: (params) => socketCall("get-sit-list", params),
	};
};

const Qualifier = () => {
	return {
		getQualifireList: (params) => socketCall("get-qualifier-list", params),
	};
};

const SecurityGroup = () => {
	return {
		getSecurityAccessLevel: (params) =>
			socketCall("get-security-objects-and-access-levels", params),
	};
};

const Credential = () => {
	return {
		verifyCredential: (params) => socketCall("action-verify-credential", params),
		verifyO365Email: (params) => socketCall("verify-o365-email", params),
		verifyVOIPPhone: (params) => socketCall("verify-voip-plivo-phone", params),
		verifySMSPhone: (params) => socketCall("verify-sms-plivo-phone", params),
		getCredentialList: (params) => socketCall("get-credential-list", params),
		getUnassignedPlivoPhone: (params) =>
			socketCall("get-unassigned-plivo-phone", params),
		getAssignedPlivoPhone: (params) => socketCall("get-assigned-plivo-phone", params),
		saveLinkedinVerificationCode: (params) =>
			socketCall("save-linkedin-verification-code", params),
		getMsgraphAuthorizeUrl: (params) =>
			socketCall("get-msgraph-authorize-url", params),
	};
};

const Sourcing = () => {
	return {
		// ================== NEW ==============
		getSourcingList: (params) => socketCall("get-sourcing-list", params),
		getSourcingPeopleList: (params) =>
			socketCall("get-sourcing-main-list-people", params),
		runSourcingProcess: (params) => socketCall("action-execute-sourcing", params),
		hideRecordsFromSourcingSearch: (params) =>
			socketCall("action-sourcing-hide-records", params),
		bulkEnrichRecords: (params) =>
			socketCall("action-execute-sourcing-enrichment", params),
		getSourcingProcess: (params) => socketCall("get-sourcing-main-progress", params),
	};
};

const Messaging = () => {
	return {
		getCMSConversationMessages: (params) =>
			socketCall("get-cms-conversation-messages", params),
		sendLinkedinMessage: (params) => socketCall("send-linkedin-message", params),
		actionSendLinkedinConnectionRequest: (params) =>
			socketCall("action-send-linkedin-connection-request", params),
		actionCmsLinkedinMessage: (params) =>
			socketCall("action-send-cms-linkedin-message", params),
		sendCmsEmailO365Message: (params) => socketCall("send-cms-email-o365", params),
		sendCmsSms: (params) => socketCall("send-cms-sms", params),
		creatMeetingEvent: (params) => socketCall("create-msgraph-event", params),
	};
};

const Jobs = () => {
	return {
		// ============= NEW ===============
		getJobList: (params) => socketCall("get-job-list", params),
		getJobApplicationList: (params) => socketCall("get-job-applicant-list", params),
		getJobApplicationsForPerson: (params) =>
			socketCall("get-job-applications-for-person", params),
	};
};

const SystemLogs = () => {
	return {
		getSystemErrorLogs: (params) => socketCall("get-system-error-logs", params),
		getSystemActivityLogs: (params) => socketCall("get-system-activity-logs", params),
		logError: (params) => socketCall("log-error", params),
	};
};

const Campaigns = () => {
	return {
		// ---------- OLD -----------
		getTemplates: (params) => socketCall("get-templates", params),
		getTemplate: (params) => socketCall("get-template", params),
		saveTemplates: (params) => socketCall("save-templates", params),

		// ---------- NEW -----------
		getCampaignList: (params) => socketCall("get-campaign-list", params),
		executeCampaign: (params) => socketCall("action-execute-campaign", params),
		deleteCampaignData: (params) => socketCall("delete-campaign-data", params),
		saveCampaignData: (params) => socketCall("save-campaign-data", params),
		actionCopyCampaign: (params) => socketCall("action-copy-campaign", params),
		actionWorkflowSkip: (params) => socketCall("action-workflow-skip", params),
	};
};

const Workflow = () => {
	return {
		getWorkflowList: (params) => socketCall("get-workflow-list", params),
		getWorkflowTemplateList: (params) =>
			socketCall("get-workflow-template-list", params),
		getWorkflowItemsList: (params) => socketCall("get-workflow-list-items", params),
		getWorkflowItemCount: (params) => socketCall("get-workflow-item-count", params),
		getWorkflowSummary: (params) => socketCall("get-workflow-summary", params),
		getWorkflowStepDetails: (params) =>
			socketCall("get-workflow-step-details", params),
		actionWorkflowRestart: (params) => socketCall("action-workflow-restart", params),
	};
};

const Template = () => {
	return {
		getTemplateList: (params) => socketCall("get-template-list", params),
	};
};

const Theme = () => {
	return {
		getThemeList: (params) => socketCall("get-theme-item-list", params),
	};
};

const Ams = () => {
	return {
		getAmsList: (params) => socketCall("get-ams-list", params),
		getAmsReminders: (params) => socketCall("ams-get-reminders", params),
	};
};

const Cms = () => {
	return {
		getCmsCommunicationList: (params) =>
			socketCall("get-cms-communication-list", params),
		getCmsCommunicationDraftList: (params) =>
			socketCall("get-cms-communication-draft-list", params),
	};
};

const Macros = () => {
	return {
		getMacroList: (params) => socketCall("get-macro-list", params),
		processTemplateMacro: (params) => socketCall("process-template-macros", params),
	};
};

const GlobalSearch = () => {
	return {
		getGlobalSearch: (params) => socketCall("search-global-all", params),
	};
};

const GenericAPI = () => {
	return {
		getTableData: (params) => socketCall("get-table-data", params),
		updateTableData: (params) => socketCall("update-table-data", params),
		deleteTableData: (params) => socketCall("delete-table-data", params),
		getMainData: (params) => socketCall("get-main-data", params),
	};
};

const API = () => ({
	Auth,
	Dashboard,
	Person,
	Account,
	Company,
	Global,
	SIT,
	SecurityGroup,
	Credential,
	Sourcing,
	Messaging,
	Jobs,
	SystemLogs,
	Campaigns,
	Workflow,
	Qualifier,
	Template,
	Theme,
	Ams,
	Cms,
	Macros,
	GlobalSearch,
	GenericAPI,
});

export default API;
