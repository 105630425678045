import API from "api";
import { accountId } from "SocketProvider";

const getMainData = (data) => {
    const query = {
        account_id: accountId,
    };
    return API().Dashboard().getDashboardData(query);
};

export default {
    getMainData,
};
