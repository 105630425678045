import React, { useCallback, useEffect, useState } from "react";
import styled from "styled-components";
import {
	EuiPopover,
	EuiFlexItem,
	EuiFlexGroup,
	EuiButtonIcon,
	EuiFieldSearch,
} from "@elastic/eui";
import _, { cloneDeep, includes, remove } from "lodash";

import { CAT_ICONS } from "./constants";
import GlobalSearchCompany from "./GlobalSearchCompany";
import GlobalSearchJob from "./GlobalSearchJob";
import GlobalSearchPerson from "./GlobalSearchPerson";
import { SaveCancelBtn } from "../CustomComponents";
import { companyIcon, jobIcon, peopleIcon } from "../Icons";

import { useGlobal } from "core/useHooks";
import { getGCSValue, getGSSValue } from "components/global/utils";
import * as MODEL from "core/model";

/**
 * Constants
 */
const DEFAULT_PANEL_WIDTH = 200;

/**
 * Component for Editable Global Search
 *
 */
const EditableGlobalSearch = (props) => {
	const {
		globals,
		globalSearchItems,
		getGlobalSearchItem,
		label = null,
		panelWidth,
		hasCompany = true,
		hasPerson = true,
		hasJob = true,
		savePersonPhone,
		deletePerson,
		savePersonEmail,
		saveCompanyPhone,
		deleteCompany,
		saveCompanyEmail,
		onSaveItems,
	} = props;

	const [isOpenPopover, setIsOpenPopover] = useState(false);
	const [searchValue, setSeachValue] = useState("");
	const [selectedCatIcon, setSelectedCatIcon] = useState(null);
	const [selectedSearchItems, setSelectedSearchItems] = useState(globalSearchItems);
	const [selectedItems, setSelectedItems] = useState({
		person: [],
		job: [],
		company: [],
	});

	const { gssList } = useGlobal();

	const enabledSave =
		(hasPerson && selectedItems.person.length !== 0) ||
		(hasCompany && selectedItems.company.length !== 0) ||
		(hasJob && selectedItems.job.length !== 0);

	const handleOpenPopover = () => {
		setIsOpenPopover(true);
	};

	const handleClosePopover = () => {
		setIsOpenPopover(false);
	};

	// functions
	const showSuggestions = () => {
		handleSearchItem(searchValue);
	};

	const handleSearchItem = (val) => {
		getGlobalSearchItems(val);
		setSeachValue(val);
	};

	// calling search items by using useLayout
	const getGlobalSearchItems = useCallback(
		// debounce calling
		_.debounce((val) => {
			getGlobalSearchItem(val);
		}, 500),
		[]
	);

	// change the selected category icon
	const handleChangeCategory = (val) => {
		let _selectedCatIcon = selectedCatIcon;

		if (selectedCatIcon === val) {
			_selectedCatIcon = null;
		} else {
			_selectedCatIcon = val;
		}

		setSelectedCatIcon(_selectedCatIcon);
		getSelectedSearchItems(_selectedCatIcon);
	};

	const getSelectedSearchItems = (selectedCat) => {
		let _selectedSearchItems = null;
		if (selectedCat === CAT_ICONS.PEOPLE) {
			_selectedSearchItems = {
				people: globalSearchItems?.people,
			};
		} else if (selectedCat === CAT_ICONS.COMPANY) {
			_selectedSearchItems = {
				companies: globalSearchItems?.companies,
			};
		} else if (selectedCat === CAT_ICONS.JOB) {
			_selectedSearchItems = {
				jobs: globalSearchItems?.jobs,
			};
		} else {
			_selectedSearchItems = globalSearchItems;
		}
		setSelectedSearchItems(_selectedSearchItems);
	};

	const handleGlobalSearch = () => {
		getGlobalSearchItem(searchValue);
	};

	const handleChangeSeletedPersonItems = (personId, checked) => {
		if (personId !== undefined || personId !== null) {
			const personIds = cloneDeep(selectedItems.person);
			const isPersonId = includes(personIds, personId);

			if (checked) {
				// Add person id to selectedItems
				if (!isPersonId) {
					personIds.push(personId);
				}
			} else {
				// Delete person Id from selectedItems
				if (isPersonId) {
					remove(personIds, (id) => id === personId);
				}
			}

			setSelectedItems({
				...selectedItems,
				person: personIds,
			});
		}
	};

	const handleChangeSeletedCompanyItems = (companyId, checked) => {
		if (companyId !== undefined || companyId !== null) {
			const companyIds = cloneDeep(selectedItems.company);
			const isCompanyId = includes(companyIds, companyId);

			if (checked) {
				// Add company id to selectedItems
				if (!isCompanyId) {
					companyIds.push(companyId);
				}
			} else {
				// Delete company Id from selectedItems
				if (isCompanyId) {
					remove(companyIds, (id) => id === companyId);
				}
			}

			setSelectedItems({
				...selectedItems,
				company: companyIds,
			});
		}
	};

	const handleChangeSeletedJobItems = (jobId, checked) => {
		if (jobId !== undefined || jobId !== null) {
			let jobIds = cloneDeep(selectedItems.job);
			const isJobId = includes(jobIds, jobId);

			if (checked) {
				// Add job id to selectedItems
				if (!isJobId) {
					jobIds.push(jobId);
				}
			} else {
				// Delete job Id from selectedItems
				if (isJobId) {
					remove(jobIds, (id) => id === jobId);
				}
			}

			setSelectedItems({
				...selectedItems,
				job: jobIds,
			});
		}
	};

	const initData = () => {
		setSelectedItems({
			person: [],
			company: [],
			job: [],
		});
		setIsOpenPopover(false);
	};

	const handleCancel = () => {
		initData();
	};
	const handleSave = () => {
		// Save selected data
		onSaveItems(selectedItems);

		// Close modal
		initData();
	};

	// effect
	useEffect(() => {
		getSelectedSearchItems(selectedCatIcon);
	}, [globalSearchItems]);

	return (
		<Wrapper>
			<EuiPopover
				button={
					<div className={`button-wrapper`} onClick={handleOpenPopover}>
						<EuiButtonIcon
							color="primary"
							iconType="plusInCircleFilled"
							aria-label="Add"
						/>
						{label}
					</div>
				}
				isOpen={isOpenPopover}
				closePopover={handleClosePopover}
				panelPaddingSize="s"
				anchorPosition="downCenter"
				panelStyle={{
					width: panelWidth ? panelWidth : DEFAULT_PANEL_WIDTH,
					minWidth: 0,
					paddingTop: "var(--sp-8)",
				}}
			>
				<EuiFlexGroup direction="column" gutterSize="s">
					<EuiFlexItem className="search-width-fit-content">
						<EuiFieldSearch
							value={searchValue}
							onChange={(e) => handleSearchItem(e.target.value)}
							placeholder="Search"
							aria-label="global-search"
							isClearable={false}
							onFocus={showSuggestions}
						/>
					</EuiFlexItem>
					<EuiFlexItem>
						<SearchPanel>
							<EuiFlexGroup direction="column" gutterSize="s">
								<EuiFlexItem className="search-header">
									{hasPerson && (
										<CategoryIcon
											onClick={(e) =>
												handleChangeCategory(CAT_ICONS.PEOPLE)
											}
											className={
												selectedCatIcon === CAT_ICONS.PEOPLE
													? "selected"
													: ""
											}
										>
											{peopleIcon()}
										</CategoryIcon>
									)}
									{hasCompany && (
										<CategoryIcon
											onClick={(e) =>
												handleChangeCategory(CAT_ICONS.COMPANY)
											}
											className={
												selectedCatIcon === CAT_ICONS.COMPANY
													? "selected"
													: ""
											}
										>
											{companyIcon()}
										</CategoryIcon>
									)}
									{hasJob && (
										<CategoryIcon
											onClick={(e) =>
												handleChangeCategory(CAT_ICONS.JOB)
											}
											className={
												selectedCatIcon === CAT_ICONS.JOB
													? "selected"
													: ""
											}
										>
											{jobIcon()}
										</CategoryIcon>
									)}
								</EuiFlexItem>
								<EuiFlexItem className="search-body mt-4">
									{hasPerson && (
										<GlobalSearchPerson
											data={selectedSearchItems?.people}
											onGlobalSearch={handleGlobalSearch}
											selectedItems={selectedItems.person}
											onChangeSeletedItems={
												handleChangeSeletedPersonItems
											}
											savePersonPhone={savePersonPhone}
											deletePerson={deletePerson}
											savePersonEmail={savePersonEmail}
										/>
									)}
									{hasCompany && (
										<GlobalSearchCompany
											data={selectedSearchItems?.companies}
											onGlobalSearch={handleGlobalSearch}
											selectedItems={selectedItems.company}
											onChangeSeletedItems={
												handleChangeSeletedCompanyItems
											}
											savePersonEmailCompany={saveCompanyPhone}
											deleteCompany={deleteCompany}
											saveCompanyEmail={saveCompanyEmail}
										/>
									)}
									{hasJob && (
										<GlobalSearchJob
											data={selectedSearchItems?.jobs}
											globals={globals}
											selectedItems={selectedItems.job}
											onChangeSeletedItems={
												handleChangeSeletedJobItems
											}
										/>
									)}
								</EuiFlexItem>
								<EuiFlexItem>
									<SaveCancelBtn
										onChangeCancel={handleCancel}
										onChangeSave={handleSave}
										disabled={!enabledSave}
									/>
								</EuiFlexItem>
							</EuiFlexGroup>
						</SearchPanel>
					</EuiFlexItem>
				</EuiFlexGroup>
			</EuiPopover>
		</Wrapper>
	);
};

export default EditableGlobalSearch;

const Wrapper = styled.div`
	display: flex;
	position: relative;

	.euiFormControlLayout {
		height: unset;
	}

	.euiPopover__panel {
		min-width: 0 !important;
	}

	.button-wrapper {
		display: flex;
		align-items: flex-start;
	}

	.euiButtonEmpty.euiButtonEmpty--primary {
		text-decoration: none !important;
	}
`;

const SearchPanel = styled.div`
	.search-header {
		display: flex;
		flex-direction: row;
		padding: var(--sp-2);
		justify-content: center;
		border-bottom: solid 1px var(--border-color);
	}

	.search-body {
		padding: 0 var(--sp-4);
		margin-top: 0 !important;
	}

	.search-title {
		font-size: var(--sp-7);
		color: var(--base-color);
		text-decoration: underline;
	}

	.search-count {
		font-size: var(--sp-7);
		color: var(--ligth-color);
	}

	.search-ellipsis {
		text-overflow: ellipsis;
		overflow: hidden;
		white-space: nowrap;
		width: 250px;
	}

	.search-card {
		padding: var(--sp-1) 0;
		border-bottom: 1px solid var(--border-color);
	}
`;

const CategoryIcon = styled.div`
	width: fit-content;
	padding: 4px 8px;
	display: flex;
	margin: auto 0;
	border-bottom: 2px solid transparent;
	border-radius: 2px;

	&:hover {
		background: var(--self-bg-color);
	}

	&.selected {
		border-bottom: 2px solid var(--eui-link-color);
	}
`;
