/** @format */

import React from "react";
import { EuiFlexGroup, EuiFlexItem, EuiText } from "@elastic/eui";
import Moment from "react-moment";
import { linkedInIcon } from "../Icons/Icons";

const Source = (props) => {
    return (
        <EuiFlexGroup className="table-column-source" gutterSize="xs">
            <EuiFlexItem grow={false}>{linkedInIcon()}</EuiFlexItem>
            <EuiFlexItem>
                <EuiText className="source-date-created" size="s">
                    {props.source.date ? (
                        <Moment format="MM/DD/YYYY">{props.source.date}</Moment>
                    ) : (
                        "Invalid Date"
                    )}
                </EuiText>
            </EuiFlexItem>
        </EuiFlexGroup>
    );
};

export default Source;
