import React, { useState, useMemo, useEffect } from "react";
import styled from "styled-components";
import { EuiLink, EuiText, EuiFlexGroup, EuiFlexItem } from "@elastic/eui";
import { cloneDeep, concat, get, remove, set } from "lodash";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCommentsAlt, faStickyNote } from "@fortawesome/pro-duotone-svg-icons";

import * as MODEL from "core/model";
import { EditableSelect, TextInputEditor, TextEditor } from "components/Common";
import { SVGIcon } from "components/Common";
import {
	addressBookDuoNoteIcon,
	emailIcon,
	amsRecruitIcon,
} from "components/Common/Icons/Icons";
import { getGCSValue, getGSSValue } from "components/global/utils";

import {
	useAccount,
	useCms,
	useCredential,
	useGlobal,
	useMacro,
	useNotification,
	useQualifier,
} from "core/useHooks";

import {
	SendButton,
	SaveButton,
	ReplyButton,
	LoggedButton,
	OutboundButton,
	AddPersonEmailItem,
	PersonEmailBadgeItem,
} from "../components";

import {
	MESSAGE_STATES,
	COMMUNICATION_TYPE,
	COMM_DIRECTIONS,
	VALIDATE_MESSAGE,
} from "./constants";
import RelatedItem from "./RelatedItem";
import { MESSAGE_MANUAL_QUALIFIER_NAMES, PERSON_SELECT_TYPES } from "../constants";
import { MessageAms } from "../../MessageAms";

import useTranlation from "./useTranlation";

/**
 * Component for sending email
 */
const MessageSenderEmail = (props) => {
	const {
		person,
		onSendEmailMessage,
		savePersonPhone,
		deletePerson,
		savePersonEmail,
		initEmail,
		data,
		getCmsDraftList,
		getCmsMainList,
	} = props;

	const { macroOptions } = useMacro();
	const { account, accounts } = useAccount();
	const { globals, gssList } = useGlobal();
	const { credentialsByAccount } = useCredential();
	const { setNotificationMessage } = useNotification();
	const { callUpdateCmsCommunicaionDraft } = useCms();
	const { qualifiers, saveQualifiers } = useQualifier();
	const { translateTexts } = useTranlation(person);

	// email value
	const [CCVal, setCCVal] = useState(false);
	const [BCCVal, setBCCVal] = useState(false);
	const [toPersons, setToPersons] = useState([]);
	const [ccPersons, setCcPersons] = useState([]);
	const [bccPersons, setBccPersons] = useState([]);
	const [selectedCredential, setSelectedCredential] = useState(null);
	const [relateds, setRelateds] = useState([]);
	const [subject, setSubject] = useState("");
	const [message, setMessage] = useState("");
	const [plainMessage, setPlainMessage] = useState("");
	const [messageStatus, setMessageStatus] = useState(MESSAGE_STATES.send.value);
	const [commType, setcommType] = useState(COMMUNICATION_TYPE.recruiting.value);
	const [commDirection, setCommDirection] = useState(COMM_DIRECTIONS.outgoing.value);
	const [isRecruiting, setRecruiting] = useState(true);
	const [commDirect, setCommDirect] = useState(true); // false: 0 - incoming, true: 1 - outgoing,
	const [validationMessage, setValidationMessage] = useState("");

	const { credentialOptions } = useMemo(() => {
		// Recruiting or Sales check
		const commDetails = data ? get(data, "comm_details") : null;
		const commTypeValue = commDetails ? get(commDetails, "communication_type") : null;
		commTypeValue
			? setcommType(commTypeValue)
			: setcommType(COMMUNICATION_TYPE.recruiting.value);

		// Incoming or Outgoing check
		const commDirectionValue = data ? get(data, "comm_direction_c") : null;
		commDirectionValue
			? setCommDirection(commDirectionValue)
			: setCommDirection(COMM_DIRECTIONS.outgoing.value);

		// init Subject and Messagebox
		const messageDetails = data ? get(data, "comm_details") : null;
		const messageBodyItem = messageDetails ? get(messageDetails, "body") : null;
		const messageBodyContent = messageBodyItem
			? get(messageBodyItem, "content")
			: null;
		messageBodyContent ? setMessage(messageBodyContent) : setMessage("");
		const messageSubject = messageDetails ? get(messageDetails, "subject") : null;
		messageSubject ? setSubject(messageSubject) : setSubject("");

		const linkedInTypeC = getGCSValue(
			globals,
			"credential_main",
			"credential_type_c",
			"o365"
		);
		const authenticatedStatusC = getGCSValue(
			globals,
			MODEL.credential_main._name,
			MODEL.credential_main.credential_status_c,
			"authenticated"
		);
		const credentialOptions = [];
		if (credentialsByAccount?.length && globals?.length) {
			credentialsByAccount.forEach((credential) => {
				const credentialName =
					get(
						credential,
						`${MODEL.credential_main._name}.${MODEL.credential_main.credential_name}`
					) || "-";
				const credentialId =
					get(
						credential,
						`${MODEL.credential_main._name}.${MODEL.credential_main.id}`
					) || "-";
				const credentialTypeC = get(
					credential,
					`${MODEL.credential_main._name}.${MODEL.credential_main.credential_type_c}`
				);

				const credentialStatusC = get(
					credential,
					`${MODEL.credential_main._name}.${MODEL.credential_main.credential_status_c}`
				);

				if (
					credentialTypeC === linkedInTypeC //&&
					// credentialStatusC === authenticatedStatusC
				) {
					credentialOptions.push({
						id: credentialId,
						value: credentialId,
						label: credentialName,
					});
				}
			});
		}

		setSelectedCredential(credentialOptions?.[0]);

		return { credentialOptions };
	}, [credentialsByAccount, globals, setSelectedCredential]);

	const handleChangeCredential = (val) => {
		const credential = credentialOptions?.find((item) => item.value === val);
		setSelectedCredential(credential);
	};

	const showCC = () => {
		if (CCVal) {
			setCcPersons([]);
			setCCVal(false);
		} else {
			setCCVal(true);
		}
	};

	const showBCC = () => {
		if (BCCVal) {
			setBccPersons([]);
			setBCCVal(false);
		} else {
			setBCCVal(true);
		}
	};

	const getAddPersons = (oldPersons, newPersons) => {
		const addedPersons = [];
		newPersons.forEach((person) => {
			const existedPerson = oldPersons.find((oldPerson) => {
				let isRight = false;
				if (oldPerson.type === person.type) {
					if (person.type === PERSON_SELECT_TYPES.direct.value) {
						isRight = oldPerson.value === person.value;
					} else {
						isRight = oldPerson.info.id === person.info.id;
					}
				}

				return isRight;
			});
			if (!existedPerson) {
				addedPersons.push(person);
			}
		});
		const updatedPersons = concat(oldPersons, addedPersons);
		return updatedPersons;
	};

	const handleSaveToUser = (persons) => {
		const newPersons = getAddPersons(toPersons, persons);
		setToPersons(newPersons);
	};

	const handleSaveCC = (persons) => {
		const newPersons = getAddPersons(persons, ccPersons);
		setCcPersons(newPersons);
	};

	const handleSaveBCC = (persons) => {
		const newPersons = getAddPersons(persons, bccPersons);
		setBccPersons(newPersons);
	};

	const handleDeleteToPerson = (person) => {
		const newToPersons = cloneDeep(toPersons);
		remove(newToPersons, (toPerson) => {
			return toPerson.id === person.id;
		});

		setToPersons(newToPersons);
	};

	const handleChangeToUsers = (idx, newPerson) => {
		const newToPersons = cloneDeep(toPersons);
		set(newToPersons, `[${idx}]`, newPerson);

		setToPersons(newToPersons);
	};

	const handleDeleteCcPerson = (person) => {
		const newCcPersons = cloneDeep(ccPersons);
		remove(newCcPersons, (toPerson) => {
			return toPerson.id === person.id;
		});

		setCcPersons(newCcPersons);
	};

	const handleChangeCcPerson = (idx, newPerson) => {
		const newCcPersons = cloneDeep(ccPersons);
		set(newCcPersons, `[${idx}]`, newPerson);

		setCcPersons(newCcPersons);
	};

	const handleDeleteBccPerson = (person) => {
		const newBccPersons = cloneDeep(bccPersons);
		remove(newBccPersons, (toPerson) => {
			return toPerson.id === person.id;
		});

		setBccPersons(newBccPersons);
	};

	const handleChangeBcPerson = (idx, newPerson) => {
		const newBcPersons = cloneDeep(bccPersons);
		set(newBcPersons, `[${idx}]`, newPerson);

		setCcPersons(newBcPersons);
	};

	const handleSubject = (value) => {
		setSubject(value);
	};

	const changeEditVal = (value, text) => {
		setMessage(value);
		setPlainMessage(text);
	};

	// Functions related to Message
	const handleChangeMessage = (state) => {
		setMessageStatus(state);
	};

	// Send Message
	const getEmailFromPerson = (person) => {
		const selectedEmailIndex = person.selected || 0;
		const email = get(
			person,
			`info.${MODEL.person_email._name}[${selectedEmailIndex}].${MODEL.global_email_details._name}.${MODEL.global_email_details.email_value}`
		);
		return email;
	};

	const getEmailFromAccount = (person) => {
		const selectedEmailIndex = person.selected || 0;
		const email = get(
			person,
			`info.${MODEL.global_email_details._name}[${selectedEmailIndex}].${MODEL.global_email_details.email_value}`
		);
		return email;
	};

	const getToRecipients = () => {
		const toRecipients = [];

		toPersons.forEach((person) => {
			let emailAddress = "";
			if (person.type === PERSON_SELECT_TYPES.direct.value) {
				emailAddress = person.value;
			} else if (person.type === PERSON_SELECT_TYPES.person.value) {
				emailAddress = getEmailFromPerson(person);
			} else {
				emailAddress = getEmailFromAccount(person);
			}

			if (emailAddress) {
				toRecipients.push({
					emailAddress: {
						address: emailAddress,
					},
				});
			}
		});
		return toRecipients;
	};

	const getCcRecipients = () => {
		const ccRecipients = [];
		ccPersons.forEach((person) => {
			let emailAddress = "";
			if (person.type === PERSON_SELECT_TYPES.direct.value) {
				emailAddress = person.value;
			} else if (person.type === PERSON_SELECT_TYPES.person.value) {
				emailAddress = getEmailFromPerson(person);
			} else {
				emailAddress = getEmailFromAccount(person);
			}

			if (emailAddress) {
				ccRecipients.push({
					emailAddress: {
						address: emailAddress,
					},
				});
			}
		});
		return ccRecipients;
	};

	const getBccRecipients = () => {
		const bccRecipients = [];
		bccPersons.forEach((person) => {
			let emailAddress = "";
			if (person.type === PERSON_SELECT_TYPES.direct.value) {
				emailAddress = person.value;
			} else if (person.type === PERSON_SELECT_TYPES.person.value) {
				emailAddress = getEmailFromPerson(person);
			} else {
				emailAddress = getEmailFromAccount(person);
			}

			if (emailAddress) {
				bccRecipients.push({
					emailAddress: {
						address: emailAddress,
					},
				});
			}
		});
		return bccRecipients;
	};

	const getSendInfo = (isReply, inSubject, inMessage) => {
		const credentialId = selectedCredential?.value;

		const emailMessage = {
			subject: `${isReply ? "Re: " : inSubject ?? subject}`,
			body: {
				contentType: "Html",
				content: inMessage ?? message,
			},
			toRecipients: [],
		};

		const toRecipients = getToRecipients();
		const ccRecipients = getCcRecipients();
		const bccRecipients = getBccRecipients();

		emailMessage.toRecipients = toRecipients;
		emailMessage.ccRecipients = ccRecipients;
		emailMessage.bccRecipients = bccRecipients;

		return {
			credentialId,
			emailMessage,
		};
	};

	const handleSendMessage = (isReply) => {
		if (selectedCredential?.value == null) {
			setValidationMessage(VALIDATE_MESSAGE.credential.value);
			return;
		} else if (subject === "") {
			setValidationMessage(VALIDATE_MESSAGE.subject.value);
			return;
		} else if (message === "") {
			setValidationMessage(VALIDATE_MESSAGE.message.value);
			return;
		}
		setValidationMessage("");

		// Translation subject and message before calling "action-*"
		// we need to call api "process-template-macro"

		const texts = [subject, message];
		translateTexts(texts).then((res) => {
			const translatedtexts = res.map((translatedtext, index) =>
				translatedtext["error"] ? texts[index] : translatedtext["results"]
			);

			const { credentialId, emailMessage } = getSendInfo(
				isReply,
				translatedtexts[0],
				translatedtexts[1]
			);

			onSendEmailMessage(
				credentialId,
				emailMessage,
				(res) => {
					console.log("res: ", res);
					getCmsMainList();
					setMessageStatus(MESSAGE_STATES.logged.value);
				},
				(err) => {
					setNotificationMessage({
						type: "normal",
						title: <div>{emailIcon()} Email</div>,
						message: `Failed to Send: ${err?.message || ""}`,
						status: "error",
					});
				}
			);
		});
	};

	const handleSaveMessage = (isReply) => {
		// Save a record for cms_communication_draft
		const { credentialId, emailMessage } = getSendInfo(isReply);

		if (credentialId == null) {
			setValidationMessage(VALIDATE_MESSAGE.credential.value);
			return;
		} else if (emailMessage.subject === "") {
			setValidationMessage(VALIDATE_MESSAGE.subject.value);
			return;
		} else if (emailMessage.body.content === "") {
			setValidationMessage(VALIDATE_MESSAGE.message.value);
			return;
		}
		setValidationMessage("");

		if (account?.id && credentialId) {
			const params = {
				[MODEL.cms_communication_draft.account_main_ref_id]: account?.id,
				[MODEL.cms_communication_draft.credential_main_ref_id]: credentialId,
				[MODEL.cms_communication_draft.comm_platform_c]: getGCSValue(
					globals,
					"cms_communication_main",
					"comm_platform_c",
					"manual"
				),
				[MODEL.cms_communication_draft.conversation_type_c]: getGCSValue(
					globals,
					"cms_conversation_main",
					"conversation_type_c",
					"email"
				),
				[MODEL.cms_communication_draft.member_object_type_sc]: getGSSValue(
					gssList,
					"OBJECT_BASE",
					"PERSON"
				),
				[MODEL.cms_communication_draft.member_object_subtype_sc]: getGSSValue(
					gssList,
					"OBJECT_PERSON",
					"MAIN"
				),
				[MODEL.cms_communication_draft.member_object_record_id]: person?.id,
				[MODEL.cms_communication_draft.comm_details]: {
					details: emailMessage,
					comm_direction: commDirect ? 1 : 0,
					comm_type: isRecruiting,
				},
			};

			const cmsQualifier = qualifiers.find(
				(qualifier) =>
					qualifier[MODEL.qualifier_main.qualifier_name] ===
					(isRecruiting
						? MESSAGE_MANUAL_QUALIFIER_NAMES.recruiting
						: MESSAGE_MANUAL_QUALIFIER_NAMES.sales)
			);

			new Promise((resolve, reject) => {
				if (cmsQualifier) {
					callUpdateCmsCommunicaionDraft(
						[params],
						(res) => {
							const cmsDraftId =
								res?.response?.procedure_results
									?.cms_communication_draft?.[0]?.id;
							if (cmsDraftId) {
								saveQualifiers(
									cmsDraftId,
									"cms-communication-draft",
									[cmsQualifier.id],
									(res) => {
										resolve({ error: false, data: res });
									},
									(err) => {
										reject({
											error: true,
											data: err,
											message:
												"Saved a new cms_communication_draft record, but failed to assign qualifiers to the record.",
										});
									}
								);
							} else {
								reject({
									error: true,
									data: res,
									message:
										"Saved a new cms_communication_draft record, but we are not getting its id from response.",
								});
							}
						},
						(err) => {
							reject({
								error: true,
								data: err,
								message: "Failed to save cms_communication_draft record.",
							});
						}
					);
				} else {
					reject({
						error: true,
						message:
							"Did not find a proper qualifier. Please check qualifier list.",
					});
				}
			})
				.then((res) => {
					handleChangeMessage(MESSAGE_STATES.logged.value);
					setNotificationMessage({
						type: "normal",
						title: <div>{emailIcon()} Email</div>,
						message: "Message Saved",
						status: "success",
					});
					getCmsDraftList();
				})
				.catch((err) => {
					setNotificationMessage({
						type: "normal",
						title: <div>{emailIcon()} Email</div>,
						message:
							err.message + ` ${err.data ? JSON.stringify(err.data) : ""}`,
						status: "error",
					});
				});
		}
	};

	const handleReplyMessage = () => {
		// handleChangeMessage(MESSAGE_STATES.send.value);
		handleSaveMessage(true);
	};

	const handleReplyAllMessage = () => {};

	const handleForwardMessage = () => {};

	const handleLoggedMessage = () => {};

	const handleOutboundMessage = () => {
		setCommDirect(true);
	};

	const handleInboundMessage = () => {
		setCommDirect(false);
	};

	// Functions related to Relation
	const addRelateds = (value) => {
		let _relateds = [...relateds];
		if (!relateds.includes(value)) {
			_relateds.push(value);
		}
		setRelateds(_relateds);
	};

	useEffect(() => {
		// data for Communication Draft Record
		const commDetails = data?.comm_details?.details;
		if (commDetails) {
			const initSubject = commDetails.subject;
			const initMessage = commDetails.body?.content;

			if (initSubject) {
				setSubject(initSubject);
			}
			if (initMessage) {
				setMessage(initMessage);
			}
		}

		// Person
		const personType = get(person, `type`) || "person";
		const personTypeInfo = Object.values(PERSON_SELECT_TYPES).find(
			(item) => item.key === personType
		);
		let personPrimary = null;
		let personEmails = null;
		let personInfo = person;
		if (personType === "person") {
			personPrimary = get(person, `${MODEL.person_primary_setting._name}`);
			personEmails = get(person, `${MODEL.person_email._name}`);
		} else if (personType === "account") {
			// If person type is "account"
			const personEmailsValue = get(person, `${MODEL.global_email_details._name}`);
			personEmails = [personEmailsValue];
			personInfo[MODEL.global_email_details._name] = personEmails;
		} else {
			personEmails = [];
		}
		let selected = 0;
		if (personEmails?.length) {
			if (initEmail) {
				selected = personEmails.findIndex((email) => email?.id === initEmail?.id);
				selected = selected > -1 ? selected : 0;
			} else {
				// find primary email
				if (globals?.length && gssList?.length && personPrimary?.length) {
					const objectValue = getGSSValue(gssList, "OBJECT_BASE", "PERSON");
					const tableValue = getGSSValue(gssList, "OBJECT_PERSON", "EMAIL");
					const primaryLevelC = getGCSValue(
						globals,
						"global",
						"primary_level_c",
						"primary"
					);

					const primaryInfo = personPrimary.find(
						(primary) =>
							primary?.ref_object_base_sc === objectValue &&
							primary?.ref_object_table_sc === tableValue &&
							primary?.primary_level_gc === primaryLevelC
					);

					if (primaryInfo) {
						selected = personEmails.findIndex(
							(email) => email?.id === primaryInfo.ref_object_record_id
						);
						selected = selected > -1 ? selected : 0;
					}
				}
			}
		}

		const initData = {
			label: person?.name_first + " " + person?.name_last,
			id: person?.id,
			value:
				personTypeInfo.value === PERSON_SELECT_TYPES.direct.value
					? initEmail
					: person?.id,
			info: person,
			type: personTypeInfo.value,
			selected: selected,
		};
		setToPersons([initData]);
	}, [person, globals, gssList, initEmail, data]);

	// Buttons
	const renderButtons = () => {
		switch (messageStatus) {
			case MESSAGE_STATES.send.value:
				return (
					<div className="cms-buttons-wrapper">
						<div className="left-side">
							<SVGIcon
								icon={
									commType == 1
										? amsRecruitIcon
										: addressBookDuoNoteIcon
								}
								onClick={() =>
									commType == 1 ? setcommType(2) : setcommType(1)
								}
								className="recruiting-sales-button"
							/>
							<FontAwesomeIcon
								icon={faCommentsAlt}
								color="var(--eui-darkest-shade-color)"
								className="message-status-icon"
								onClick={() =>
									setMessageStatus(MESSAGE_STATES.logNotes.value)
								}
							/>
						</div>
						<div className="right-side">
							<SendButton onSend={() => handleSendMessage()} />
						</div>
					</div>
				);

			case MESSAGE_STATES.logged.value:
				return (
					<div className="cms-buttons-wrapper">
						<div className="left-side">
							<FontAwesomeIcon
								icon={faStickyNote}
								color="var(--eui-text-disabled-color)"
								className="message-status-icon"
								onClick={() =>
									setMessageStatus(MESSAGE_STATES.send.value)
								}
							/>
							<LoggedButton
								isDisabled={true}
								onLogged={() => handleLoggedMessage()}
							/>
						</div>
						<div className="right-side">
							<ReplyButton
								onReply={handleReplyMessage}
								onReplyAll={handleReplyAllMessage}
								onForward={handleForwardMessage}
							/>
						</div>
					</div>
				);

			case MESSAGE_STATES.logNotes.value:
				return (
					<div className="cms-buttons-wrapper">
						<div className="left-side">
							<SVGIcon
								icon={
									commType == 1
										? amsRecruitIcon
										: addressBookDuoNoteIcon
								}
								onClick={() =>
									commType == 1 ? setcommType(2) : setcommType(1)
								}
								className="recruiting-sales-button"
							/>

							<FontAwesomeIcon
								icon={faStickyNote}
								color="var(--eui-text-disabled-color)"
								className="message-status-icon"
								onClick={() =>
									setMessageStatus(MESSAGE_STATES.send.value)
								}
							/>

							<OutboundButton
								onOutbound={() => handleOutboundMessage()}
								onInbound={() => handleInboundMessage()}
							/>
						</div>
						<div className="right-side">
							<SaveButton onSave={() => handleSaveMessage()} />
						</div>
					</div>
				);
			default:
				return null;
		}
	};

	return (
		<Wrapper>
			<EuiFlexGroup direction="column" gutterSize="xs">
				<EuiFlexItem>
					<div className="d-flex mb-1">
						<EuiText className="label my-auto mr-4">From:</EuiText>
						<EditableSelect
							options={credentialOptions}
							value={selectedCredential?.value}
							onChange={handleChangeCredential}
							hasUnderline={true}
							panelWidth={200}
						/>
						{/* <EuiCheckbox
                            id={"sendor-email"}
                            className="my-auto mr-4"
                            checked={true}
                            onChange={() => {}}
                        /> */}
						<EuiText className="label my-auto mr-4 ml-4">Add:</EuiText>
						<EuiLink className="mr-1 my-auto" onClick={showCC}>
							CC
						</EuiLink>
						<EuiText className="primary-color">/</EuiText>
						<EuiLink className="ml-1 my-auto" onClick={showBCC}>
							BCC
						</EuiLink>
					</div>
					<div className="d-flex mb-1 mt-2">
						<EuiText className="label mr-4">To:</EuiText>
						<div className="icon-badges">
							<EuiFlexGroup gutterSize="s" alignItems="center" wrap={true}>
								{toPersons &&
									toPersons.length > 0 &&
									toPersons.map((item, index) => {
										return (
											<EuiFlexItem
												key={"to-person-item-" + index}
												grow={false}
											>
												<PersonEmailBadgeItem
													detail={item}
													onRemove={() =>
														handleDeleteToPerson(item)
													}
													onChangeItem={(emailIndex) => {
														handleChangeToUsers(index, {
															...item,
															selected: emailIndex,
														});
													}}
												/>
											</EuiFlexItem>
										);
									})}
								<EuiFlexItem grow={false}>
									<AddPersonEmailItem
										onSave={handleSaveToUser}
										accounts={accounts}
										savePersonPhone={savePersonPhone}
										deletePerson={deletePerson}
										savePersonEmail={savePersonEmail}
									/>
								</EuiFlexItem>
							</EuiFlexGroup>
						</div>
					</div>
					{CCVal && (
						<div className="d-flex mb-1 mt-2">
							<EuiText className="label mr-4">CC:</EuiText>
							<div className="icon-badges">
								<EuiFlexGroup
									gutterSize="s"
									alignItems="center"
									wrap={true}
								>
									{ccPersons &&
										ccPersons.length > 0 &&
										ccPersons.map((item, index) => {
											return (
												<EuiFlexItem
													key={"cc-person-item-" + index}
													grow={false}
												>
													<PersonEmailBadgeItem
														detail={item}
														onRemove={() =>
															handleDeleteCcPerson(item)
														}
														onChangeItem={(emailIndex) => {
															handleChangeCcPerson(index, {
																...item,
																selected: emailIndex,
															});
														}}
													/>
												</EuiFlexItem>
											);
										})}
									<EuiFlexItem grow={false}>
										<AddPersonEmailItem
											onSave={handleSaveCC}
											accounts={accounts}
											savePersonPhone={savePersonPhone}
											deletePerson={deletePerson}
											savePersonEmail={savePersonEmail}
										/>
									</EuiFlexItem>
								</EuiFlexGroup>
							</div>
						</div>
					)}

					{BCCVal && (
						<div className="d-flex mb-1 mt-2">
							<EuiText className="label mr-4">BCC:</EuiText>
							<div className="icon-badges">
								<EuiFlexGroup
									gutterSize="s"
									alignItems="center"
									wrap={true}
								>
									{bccPersons &&
										bccPersons.length > 0 &&
										bccPersons.map((item, index) => {
											return (
												<EuiFlexItem
													key={"bcc-person-item-" + index}
													grow={false}
												>
													<PersonEmailBadgeItem
														detail={item}
														onRemove={() =>
															handleDeleteBccPerson(item)
														}
														onChangeItem={(emailIndex) => {
															handleChangeBcPerson(index, {
																...item,
																selected: emailIndex,
															});
														}}
													/>
												</EuiFlexItem>
											);
										})}
									<EuiFlexItem grow={false}>
										<AddPersonEmailItem
											onSave={handleSaveBCC}
											accounts={accounts}
											savePersonPhone={savePersonPhone}
											deletePerson={deletePerson}
											savePersonEmail={savePersonEmail}
										/>
									</EuiFlexItem>
								</EuiFlexGroup>
							</div>
						</div>
					)}
				</EuiFlexItem>
				<EuiFlexItem>
					<EuiFlexGroup gutterSize="s" direction="column">
						<EuiFlexItem>
							<EuiFlexGroup gutterSize="s" direction="column">
								<EuiFlexItem>
									<TextInputEditor
										editorVal={subject}
										autocompletOptions={macroOptions}
										changeEditVal={handleSubject}
									/>
								</EuiFlexItem>
								<EuiFlexItem>
									<TextEditor
										id="message-email-sender"
										editorVal={message}
										autocompletOptions={macroOptions}
										changeEditVal={changeEditVal}
									/>
								</EuiFlexItem>
								<EuiFlexItem>{renderButtons()}</EuiFlexItem>
							</EuiFlexGroup>
						</EuiFlexItem>
						<EuiFlexItem>
							<RelatedItem
								message={validationMessage}
								items={relateds}
								onAddItem={addRelateds}
							/>
						</EuiFlexItem>
					</EuiFlexGroup>
				</EuiFlexItem>

				<EuiFlexItem>
					<MessageAms
						person={person}
						subject={subject}
						message={plainMessage}
					/>
				</EuiFlexItem>
			</EuiFlexGroup>
		</Wrapper>
	);
};

export default MessageSenderEmail;

/**
 * Styled Components
 */
const Wrapper = styled.div`
	.label {
		color: var(--light-color);
		font-size: 0.75rem;
		font-weight: 600;
		margin-right: 0.3rem;
		margin-top: 0.25rem;
	}

	.icon-badges {
		flex-wrap: wrap;
		display: flex;
	}

	.euiCheckbox .euiCheckbox__input + .euiCheckbox__square {
		border-radius: 50%;
	}

	.primary-color {
		color: var(--link-color);
	}
	.my {
		width: 70%;
		height: 50%;
		background-color: red;
		margin: 5px;
		position: static;
		right: 40%;
		bottom: 30%;
	}
`;
