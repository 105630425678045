import React from "react";
import styled from "styled-components";
import { EuiFlexGroup, EuiFlexItem, EuiRadio, EuiText } from "@elastic/eui";
import { cloneDeep, set } from "lodash";

import { WORK_TYPES } from "./constants";
import { compensation_main } from "core/model";

/**
 * Component for Work Type of Compenstion
 */
const CompensationWorkType = (props) => {
	const { data, onChange } = props;

	const workTypeId = data?.[compensation_main.placement_type_c] || WORK_TYPES.perm.id;

	const handleChangeType = (e) => {
		const updatedType = Number(e.target.value);
		const newData = cloneDeep(data);
		set(newData, `${compensation_main.placement_type_c}`, updatedType);

		onChange(newData);
	};

	return (
		<Wrapper>
			<EuiFlexGroup gutterSize="m" direction="column" alignItems="flexStart">
				<EuiFlexItem grow={1} className="type-title">
					<EuiText textAlign="center">
						<span>Work Type</span>
					</EuiText>
				</EuiFlexItem>
				<EuiFlexItem>
					{Object.values(WORK_TYPES).map((item) => {
						return (
							<EuiRadio
								key={`compensation-work-type-item-${item.key}`}
								id={`compensation-work-type-item-${item.key}`}
								checked={item.id === workTypeId}
								label={item.label}
								onChange={handleChangeType}
								value={item.id}
								className="work-type-option"
							/>
						);
					})}
				</EuiFlexItem>
			</EuiFlexGroup>
		</Wrapper>
	);
};

export default CompensationWorkType;

const Wrapper = styled.div`
	display: flex;
	position: relative;
	width: 100%;

	.type-title {
		span {
			font-weight: 600;
			font-size: 0.75rem;
			line-height: 2;

			color: var(--light-color);
		}
	}

	.work-type-option {
		display: flex;
		margin-bottom: 0.833rem;

		.euiRadio__circle {
			background-image: none !important;
		}

		label {
			font-size: 1rem !important;
			line-height: 1.71 !important;
			color: #1a1c21;
		}
	}
`;
