import { get, isArray } from "lodash";

import API from "api";
import * as actionTypes from "./actionTypes";
import * as MODEL from "core/model";
import Service from "core/service";
import { getResultFromApiResponse } from "helpers/utilities";

export const setSITLoading = (data) => {
	return {
		type: actionTypes.SET_SIT_MAINLIST_LOADING,
		payload: data,
	};
};

export const setSIT = (data) => {
	return {
		type: actionTypes.SET_SIT_DATA,
		payload: data,
	};
};

export const setSITMode = (data) => {
	return {
		type: actionTypes.SET_SIT_MODE,
		payload: data,
	};
};

export const setSitKeywords = (data) => {
	return {
		type: actionTypes.GET_SIT_KEYWORDS,
		payload: data,
	};
};

export const setFlyoutVisible = (data) => {
	return {
		type: actionTypes.SET_FLYOUT_VISIBLE,
		payload: data,
	};
};

export const fetchAllSITByOrder = (type) => {
	return async (dispatch) => {
		try {
			/* this code will be removed in the future. For now resolving the get-table-data error */
			// const result = await API()
			// 	.GenericAPI()
			// 	.getTableData({
			// 		object_base: "sit",
			// 		sql: `SELECT SM.ID, SM.sit_name, SM.sit_order_value, SM.sit_type_c, SM.sit_parent_id, SM.sit_children_count, SKD.keyword_detail_notcase, SKD.keyword_detail_case FROM sit_main SM LEFT JOIN sit_keyword_detail SKD ON SM.ID = SKD.sit_main_ref_id WHERE SM.sit_type_c = ${type} ORDER BY IF(SM.sit_order_value IS NULL,0, SM.sit_order_value)`,
			// 	});

			const result = await null;
			/** ---------------------------------------------------------------------------------- */

			const data = get(result, "data");

			if (data) {
				dispatch(setSIT(data));
			}
		} catch (err) {
			alert("Error fetching SIT order by id data: " + JSON.stringify(err));
		}
	};
};

export const updateSITKeyword = (id, keyword) => {
	return async (dispatch) => {
		try {
			const result = await API()
				.GenericAPI()
				.updateTableData({
					object: "sit",
					sit_keyword: {
						id: id,
						keyword: keyword,
					},
				});

			if (result) {
				dispatch({
					type: actionTypes.UPDATE_SIT_KEYWORD,
					payload: result,
				});
			}
		} catch (err) {
			alert("Error updating SIT keyword data: " + JSON.stringify(err));
		}
	};
};

export const updateSITCaseSensitive = (id, caseSensitive) => {
	return async (dispatch) => {
		try {
			const result = await API()
				.GenericAPI()
				.updateTableData({
					object: "sit",
					sit_keyword: {
						id: id,
						case_sensitive: caseSensitive,
					},
				});

			if (result) {
				dispatch({
					type: actionTypes.UPDATE_SIT_KEYWORD_CASE_SENSITIVE,
					payload: result,
				});
			}
		} catch (err) {
			alert(
				"Error updating SIT keyword case sensitive data: " + JSON.stringify(err)
			);
		}
	};
};

export const createSITKeyword = (sitMainRefId, keyword, caseSensitive) => {
	return async (dispatch) => {
		try {
			const result = await API()
				.GenericAPI()
				.updateTableData({
					object: "sit",
					sit_keyword: {
						sit_main_ref_id: sitMainRefId,
						keyword: keyword,
						case_sensitive: caseSensitive,
					},
				});

			if (result) {
				dispatch({
					type: actionTypes.CREATE_SIT_KEYWORD,
					payload: result,
				});
			}
		} catch (err) {
			alert("Error create SIT keyword data: " + JSON.stringify(err));
		}
	};
};

/* -------------------------------------- NEW -------------------------------------- */
export const setSitList_NEW = (data) => {
	return {
		type: actionTypes.SET_SIT_LIST_NEW,
		payload: data,
	};
};

export const getSITList_NEW = (params) => {
	return async (dispatch) => {
		try {
			const _params = {
				searchTerm: params?.searchTerm ?? "",
				pageNumber: params?.pageNumber ?? 1,
				pageCount: params?.pageCount ?? 30,
				param: params?.param ?? null,
				type_c: params?.type,
			};
			const data = await Service.sit.getSitList(_params);

			if (data?.data) {
				const sits = data?.data?.map((item) => item.sit_main);
				const payload = {
					data: sits,
				};
				dispatch(setSitList_NEW(payload));
			} else {
				dispatch(setSitList_NEW({ data: [] }));
			}
		} catch (err) {
			dispatch(setSitList_NEW({ data: [] }));
		} finally {
		}
	};
};

export const setSitKeywords_NEW = (data) => {
	return {
		type: actionTypes.SET_SIT_KEYWORDS_NEW,
		payload: data,
	};
};

export const getSitKeywords_NEW = (params) => {
	return async (dispatch) => {
		try {
			const _params = {
				searchTerm: params?.searchTerm ?? "",
				pageNumber: params?.pageNumber ?? 1,
				pageCount: params?.pageCount ?? 100,
				param: params?.param ?? null,
				main_id: params?.sit_id,
			};
			const data = await Service.sit.getSitKeywords(_params);

			if (data?.data) {
				const sits = data?.data?.map((item) => item.sit_keyword);
				const payload = {
					data: sits,
				};
				dispatch(setSitKeywords_NEW(payload));
			} else {
				dispatch(setSitKeywords_NEW({ data: [] }));
			}
		} catch (err) {
			console.log("getSITKeywords_NEW error : ", err);
			dispatch(setSitKeywords_NEW({ data: [] }));
		} finally {
		}
	};
};

export const getAllSit = () => {
	return async (dispatch, getState) => {
		const state = getState();
		const isLoading = state.sit.loading;

		if (isLoading) return;

		dispatch(setSITLoading(true));

		try {
			const result = await Service.sit.getSitAllMainList();

			const data = get(result, "response.data");
			const sits = data?.map((item) => item.sit_main) ?? [];

			dispatch(setSIT(sits));
		} catch (err) {
			alert("Error fetching SIT data: " + JSON.stringify(err));
		} finally {
			dispatch(setSITLoading(false));
		}
	};
};

export const getSitListByKeyword = (sitTypeC, keyword, successCallback, failCallBack) => {
	return async (dispatch) => {
		let sitList = [];
		try {
			const result = await Service.sit.getSitListByKeyword({ sitTypeC, keyword });

			const data = get(result, "response.data");
			const sits = data?.map((item) => item.sit_main) ?? [];

			if (isArray(sits)) {
				if (successCallback) {
					successCallback(data);
				}

				sitList = sits;
			} else {
				if (failCallBack) {
					failCallBack(data);
				}
			}
		} catch (err) {
			if (failCallBack) {
				failCallBack(err);
			}
		} finally {
			dispatch(setSIT(sitList));
		}
	};
};

export const updateSITs = (items, successCallback, failCallback) => {
	return async (dispatch, getState) => {
		let res = null;

		const state = getState();
		const isLoading = state.sit.loading;

		if (isLoading) return;

		dispatch(setSITLoading(true));

		try {
			const payload = items;
			const result = await Service.sit.updateSit(payload);

			if (!get(result, "error")) {
				if (successCallback) {
					res = getResultFromApiResponse(result);
					successCallback(res?.procedure_results);
				}
			}
		} catch (err) {
			if (failCallback) {
				failCallback(err);
			}
		} finally {
			dispatch({
				type: actionTypes.UPDATE_SIT_PARENT,
				payload: res,
			});
			dispatch(setSITLoading(false));
		}
	};
};

export const updateSitParent = (id, parentId, successCallback, failCallback) => {
	return async (dispatch, getState) => {
		let res = null;

		const state = getState();
		const isLoading = state.sit.loading;

		if (isLoading) return;

		dispatch(setSITLoading(true));

		try {
			const payload = {
				[MODEL.sit_main._name]: {
					[MODEL.sit_main.id]: id,
					[MODEL.sit_main.sit_parent_id]: parentId,
				},
			};
			const result = await Service.sit.updateSit(payload);

			if (!get(result, "error")) {
				if (successCallback) {
					res = getResultFromApiResponse(result);
					successCallback(res?.procedure_results);
				}
			}
		} catch (err) {
			if (failCallback) {
				failCallback(err);
			}
		} finally {
			dispatch({
				type: actionTypes.UPDATE_SIT_PARENT,
				payload: res,
			});
			dispatch(setSITLoading(false));
		}
	};
};

export const deleteSit = (id, successCallback, failCallback) => {
	return async (dispatch) => {
		let res = null;

		try {
			const payload = {
				[MODEL.sit_main._name]: {
					[MODEL.sit_main.id]: id,
				},
			};

			const result = await Service.sit.deleteSit(payload);

			if (!get(result, "error")) {
				if (successCallback) {
					res = getResultFromApiResponse(result);
					successCallback(res?.procedure_results);
				}
			}
		} catch (err) {
			if (failCallback) {
				failCallback(err);
			}
		} finally {
			dispatch({
				type: actionTypes.DELETE_SIT,
				payload: res,
			});
		}
	};
};

export const deleteSits = (parmas, successCallback, failCallback) => {
	return async (dispatch) => {
		let res = null;

		try {
			const payload = parmas;

			const result = await Service.sit.deleteSit(payload);

			if (!get(result, "error")) {
				if (successCallback) {
					res = getResultFromApiResponse(result);
					successCallback(res?.procedure_results);
				}
			}
		} catch (err) {
			if (failCallback) {
				failCallback(err);
			}
		} finally {
			dispatch({
				type: actionTypes.DELETE_SIT,
				payload: res,
			});
		}
	};
};

export const addUpdateSit = (params, successCallback, failCallback) => {
	return async (dispatch) => {
		let res = null;

		try {
			const payload = {
				[MODEL.sit_main._name]: {
					[MODEL.sit_main.id]: params[MODEL.sit_main.id],
					[MODEL.sit_main.sit_name]: params[MODEL.sit_main.sit_name],
					[MODEL.sit_main.sit_type_c]: params[MODEL.sit_main.sit_type_c],
					[MODEL.sit_main.sit_parent_id]: params[MODEL.sit_main.sit_parent_id],
					[MODEL.sit_main.sit_order_value]:
						params[MODEL.sit_main.sit_order_value],
				},
			};

			const result = await Service.sit.updateSit(payload);

			if (!get(result, "error")) {
				if (successCallback) {
					res = getResultFromApiResponse(result);
					successCallback(res?.procedure_results);
				}
			}
		} catch (err) {
			if (failCallback) {
				failCallback(err);
			}
		} finally {
			dispatch({
				type: actionTypes.ADD_UPDATE_SIT,
				payload: res,
			});
		}
	};
};

/* ----------------------------------------------------------------------------------- */
