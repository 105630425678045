import React, { useEffect, useState, useMemo } from "react";
import { EuiLoadingSpinner } from "@elastic/eui";
import { flattenDeep, get } from "lodash";
import styled from "styled-components";

import { getTreeFromFlatData, toggleExpandedForAll } from "react-sortable-tree";
import DropdownTreeSelect from "react-dropdown-tree-select";
import "react-dropdown-tree-select/dist/styles.css";

import * as MODEL from "core/model";
import { useSIT } from "core/useHooks";

/**
 * Main Component for SIT flyout
 */
const SitFlyoutItem = (props) => {
	const { sitType, sits, onChange } = props;

	const { allSits, getSITsByKeyword } = useSIT();

	const [isLoading, setLoading] = useState(false);

	const [optionsSitTree, setOptionsSitTree] = useState([]);

	const filteredSitList = useMemo(() => {
		const filteredSitList =
			allSits?.filter((sit) => sit[MODEL.sit_main.sit_type_c] === sitType) || [];

		return filteredSitList;
	}, [allSits, sitType]);

	useEffect(() => {
		const options = [];
		const optionsInit = [];

		// Search parent node without null
		if (filteredSitList?.length > 0) {
			filteredSitList.forEach((sit) => {
				const { id, sit_name } = sit;

				const inputSit = sits?.find((sitId) => sitId === id);

				if (id) {
					const optionCount = options.length;
					const initNode = {
						label: sit_name,
						id: id,
						value: id,
						index: optionCount,
						sit_parent_id: sit?.sit_parent_id,
						checked: false,
					};
					const node = {
						...initNode,
						checked: inputSit ? true : false,
					};
					optionsInit.push(initNode);
					options.push(node);
				}
			});
		}

		const optionTree = toggleExpandedForAll({
			treeData: getTreeFromFlatData({
				flatData: options,
				getKey: (node) => node.id, // resolve a node's key
				getParentKey: (node) =>
					node.sit_parent_id === 0 ? null : node.sit_parent_id, // resolve a node's parent's key
				rootKey: null, // The value of the parent key when there is no parent (i.e., at root level)
			}),
		});

		const optionTreeInit = toggleExpandedForAll({
			treeData: getTreeFromFlatData({
				flatData: optionsInit,
				getKey: (node) => node.id, // resolve a node's key
				getParentKey: (node) =>
					node.sit_parent_id === 0 ? null : node.sit_parent_id, // resolve a node's parent's key
				rootKey: null, // The value of the parent key when there is no parent (i.e., at root level)
			}),
		});

		const assignObjectPaths = (obj, stack) => {
			Object.keys(obj).forEach((k) => {
				const node = obj[k];
				if (node && typeof node === "object") {
					node.path = stack ? `${stack}.${k}` : `${k}`;
					assignObjectPaths(node, node.path);
				}
			});
		};

		assignObjectPaths(optionTreeInit);
		assignObjectPaths(optionTree);

		optionTree.forEach((parentItem) => {
			const children = get(parentItem, "children");
			if (children) {
				children.forEach((item) => {
					item.checked = parentItem.checked ? parentItem.checked : item.checked;
				});
			}
		});

		setOptionsSitTree(optionTree);
	}, [sitType, sits, filteredSitList]);

	/**
	 * Handlers
	 */
	const getSitListByKeyword = (searchValue) => {
		setLoading(true);
		getSITsByKeyword(
			sitType,
			searchValue,
			() => {
				setLoading(false);
			},
			() => {
				setLoading(false);
			}
		);
	};

	const getChildrenIds = (tree) => {
		if (!tree?.length) {
			return [];
		}

		const ids = [];
		const nodesCount = tree.length;
		for (let i = 0; i < nodesCount; i++) {
			const node = tree[i];
			ids.push(node.id);
			const children = node.children;
			if (children) {
				const childrenIds = getChildrenIds(children);
				if (childrenIds?.length) {
					ids.push(...flattenDeep(childrenIds));
				}
			}
		}

		return ids;
	};

	const getNodeFromTree = (tree, nodeId) => {
		if (!tree?.length) {
			return null;
		}

		let node = tree.find((d) => d.id === nodeId);
		if (node) {
			return node;
		} else {
			// Find children
			const nodesCount = tree.length;
			for (let i = 0; i < nodesCount; i++) {
				const children = tree[i].children;
				if (children) {
					node = getNodeFromTree(children, nodeId);
					if (node) {
						break;
					}
				}
			}

			return node;
		}
	};

	const getAllChildrenIds = (selectedNodes) => {
		if (!selectedNodes?.length) {
			return [];
		}

		const ids = selectedNodes.map((selectedNode) => {
			// Get node from tree
			const node = getNodeFromTree(optionsSitTree, selectedNode.id);
			if (node) {
				const cIds = getChildrenIds(node.children);
				return [...cIds, selectedNode.id];
			} else {
				return [];
			}
		});

		return flattenDeep(ids);
	};

	const onSitTreeChange = (currentNode, selectedNodes) => {
		const ids = getAllChildrenIds(selectedNodes);
		onChange(ids);
	};

	useEffect(() => {
		// Simulate initial load.
		if (sitType) {
			getSitListByKeyword("");
		}
	}, [sitType]);

	return (
		<Wrapper>
			<div>
				{isLoading ? (
					<EuiLoadingSpinner size="l" />
				) : (
					<DropdownTreeSelect
						data={optionsSitTree}
						onChange={onSitTreeChange}
						mode="multiSelect"
					/>
				)}
			</div>
		</Wrapper>
	);
};

export default SitFlyoutItem;

const Wrapper = styled.div`
	.loading-icon {
		margin-left: var(--sp-4);
	}

	.dropdown-content {
		max-height: 50vh;
		overflow-y: auto;
	}
`;
