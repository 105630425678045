import React, { useMemo, useState } from "react";
import styled from "styled-components";
import {
	EuiFlexGroup,
	EuiFlexItem,
	EuiText,
	EuiHorizontalRule,
	EuiButtonIcon,
} from "@elastic/eui";
import { cloneDeep, get } from "lodash";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
	faIdBadge,
	faSmile,
	faCarSide,
	faPlaneDeparture,
} from "@fortawesome/pro-regular-svg-icons";

import { mapWorkType } from "components/global/utils";
import * as MODEL from "core/model";

import CompensationWorkType from "./CompensationWorkType";
import { CompensationPayStructure } from "./CompensationPayStructure";
import CompensationBenefit from "./CompensationBenefit";
import CompensationLocationCommute from "./CompensationLocationCommute";
import CompensationTravel from "./CompensationTravel";
import { SaveCancelBtn } from "../CustomComponents";
import { CompensationNoteList } from "./CompensationNoteList";

/**
 * Constants
 */
const COMPENSATION_TYPE_ICONS = {
	workType: {
		id: 0,
		icon: faIdBadge,
		width: 12,
		component: CompensationWorkType,
	},
	payStructure: {
		id: 1,
		text: "$",
		component: CompensationPayStructure,
		hasTotal: true,
	},
	benefit: {
		id: 2,
		icon: faSmile,
		width: 15,
		component: CompensationBenefit,
		hasTotal: true,
	},
	locCommute: {
		id: 3,
		icon: faCarSide,
		width: 20,
		component: CompensationLocationCommute,
	},
	travel: {
		id: 4,
		icon: faPlaneDeparture,
		width: 20,
		component: CompensationTravel,
	},
};

/**
 * Main Component for Editable Compensation Item
 */
const CompensationEditItem = (props) => {
	const { compensation, onCloseItem } = props;

	const [data, setData] = useState(null);
	const [activatedStep, setActivatedStep] = useState(0);
	const [note, setNote] = useState("");

	const handleSaveNote = (data) => {
		setNote(data);
	};

	const handleDeleteNote = (data) => {};

	const { title } = useMemo(() => {
		const workTypeC = get(
			compensation,
			`${MODEL.compensation_main.placement_type_c}`,
			1
		);
		const workType = mapWorkType(workTypeC);

		const title = `${
			compensation?.payStructure?.salary?.toLocaleString("en-US") || "-"
		} ($${compensation?.payStructure?.hourly || "-"}/hr) - ${workType?.label || "-"}`;

		setData(compensation);

		return {
			title: title,
		};
	}, [compensation]);

	// Component and Detail for Current Step
	const currentItem = Object.values(COMPENSATION_TYPE_ICONS).find(
		(item) => item.id === activatedStep
	);

	const ContentComponent = currentItem?.component;
	const detailStep = currentItem;

	// Common Functions
	const handleChangeData = (updatedData) => {
		setData(cloneDeep(updatedData));
	};

	const handleClose = () => {
		// onCloseItem();
	};

	const handleActivateItem = (id) => {
		setActivatedStep(id);
	};

	const handleCancel = () => {
		onCloseItem();
	};

	const handleSave = () => {
		onCloseItem();
	};

	const renderCompensationComponents = () => {
		if (ContentComponent) {
			return <ContentComponent data={data} onChange={handleChangeData} />;
		} else {
			return null;
		}
	};

	const renderCompensationIcons = () => {
		return (
			<EuiFlexGroup gutterSize="s" direction="column" alignItems="center">
				{Object.getOwnPropertyNames(COMPENSATION_TYPE_ICONS).map((key) => {
					const compType = COMPENSATION_TYPE_ICONS[key];
					let typeSelected = "";
					if (compType.id < activatedStep) {
						typeSelected = "isEdited";
					} else if (compType.id === activatedStep) {
						typeSelected = "isActivated";
					}

					return (
						<EuiFlexItem
							key={`compensation-${key}-${compType.id}`}
							className={`type-item type-text ${typeSelected}`}
							onClick={() => handleActivateItem(compType.id)}
						>
							{compType.text ? (
								<EuiText grow={false} size="m">
									{compType.text}
								</EuiText>
							) : (
								<FontAwesomeIcon icon={compType.icon} />
							)}
						</EuiFlexItem>
					);
				})}
			</EuiFlexGroup>
		);
	};

	return (
		<Wrapper>
			<EuiFlexGroup
				gutterSize="s"
				direction="column"
				justifyContent="flexStart"
				alignItems="stretch"
				className="edit-compensation"
			>
				<EuiFlexItem>
					<EuiFlexGroup
						gutterSize="s"
						direction="row"
						alignItems="center"
						justifyContent="flexStart"
					>
						<EuiFlexItem grow={false} onClick={handleClose}>
							<EuiButtonIcon
								iconType="arrowLeft"
								aria-label="Previous"
								color="text"
							/>
						</EuiFlexItem>
						<EuiFlexItem>
							<EuiText size="s" textAlign="left">
								<strong className="contract-summary">{title}</strong>
							</EuiText>
						</EuiFlexItem>
					</EuiFlexGroup>
				</EuiFlexItem>
				<EuiFlexItem>
					<EuiFlexGroup
						gutterSize="s"
						direction="row"
						alignItems="flexStart"
						justifyContent="center"
					>
						<EuiFlexItem>{renderCompensationComponents()}</EuiFlexItem>
						<EuiFlexItem grow={false}>
							{renderCompensationIcons()}
						</EuiFlexItem>
					</EuiFlexGroup>
				</EuiFlexItem>
				<EuiFlexItem>
					<EuiHorizontalRule margin="none" />
				</EuiFlexItem>
				<EuiFlexItem>
					<EuiFlexGroup direction="row" gutterSize="s">
						{detailStep?.hasTotal ? (
							<EuiFlexItem>
								<div className="total">
									<EuiText>{`TOTAL: $${
										detailStep?.total || 0
									}`}</EuiText>
								</div>
							</EuiFlexItem>
						) : null}
						<EuiFlexItem grow={false} className="w-100">
							<SaveCancelBtn
								onChangeSave={handleSave}
								onChangeCancel={handleCancel}
							/>
						</EuiFlexItem>
					</EuiFlexGroup>
				</EuiFlexItem>

				<EuiFlexItem>
					<EuiHorizontalRule margin="none" />
				</EuiFlexItem>
				<EuiFlexItem>
					<EuiText textAlign="left" className="location-title">
						<small>Notes</small>
					</EuiText>
				</EuiFlexItem>
				<EuiFlexItem>
					<CompensationNoteList
						data={[]}
						onSaveNote={handleSaveNote}
						onDeleteNote={handleDeleteNote}
					/>
				</EuiFlexItem>
			</EuiFlexGroup>
		</Wrapper>
	);
};

export default CompensationEditItem;

const Wrapper = styled.div`
	display: flex;
	position: relative;
	align-items: center;
	justify-content: left;
	border: 1px solid var(--border-color);
	box-sizing: border-box;

	box-shadow: 0px 2px 2px rgba(152, 162, 179, 0.15),
		0px 1px 5px rgba(152, 162, 179, 0.15);
	border-radius: 4px;
	padding: 10px;

	.type-title {
		width: calc(100% - var(--sp-8));
	}

	.type-item {
		color: #d5dce7;
		cursor: pointer;
		margin-top: 6px;
		margin-bottom: 6px;

		&.isEdited {
			color: #69707d;
		}

		&.isActivated {
			color: var(--eui-link-color);
		}

		&.type-icon {
			svg {
				height: auto;
			}
		}

		&.type-text {
		}
	}

	.total {
		padding: 0 5px;
		width: max-content;

		border: 1px solid #d3dae6;
		box-sizing: border-box;
		border-radius: 1rem;

		& > div {
			font-weight: bold;
			font-size: 1rem;
			line-height: 2;
			letter-spacing: 0.6px;
			text-transform: uppercase;
			color: var(--eui-link-color);
		}
	}

	.location-title {
		color: var(--light-color);
	}
`;
