/**
 * Credential
 */
export const ALL_CREDENTIALS = "@@credential/ALL_CREDENTIALS";
export const VERIFY_CREDENTIAL = "@@credential/VERIFY_CREDENTIAL";
export const VERIFY_O365_EMAIL = "@@credential/VERIFY_O365_EMAIL";
export const VERIFY_VOIP_PHONE = "@@credential/VERIFY_VOIP_PHONE";
export const VERIFY_SMS_PHONE = "@@credential/VERIFY_SMS_PHONE";
export const CREDENTIAl_VERIFICATION_NOFICATION =
    "@@credential/CREDENTIAl_VERIFICATION_NOFICATION";
export const SET_CREDENTIAL_VERIFICATION = "@@credential/SET_CREDENTIAL_VERIFICATION";
export const EDIT_CREDENTIAL = "@@credential/EDIT_CREDENTIAL";
export const DELETE_CREDENTIAL = "@@credential/DELETE_CREDENTIAL";
export const CREATE_CREDENTIAL = "@@credential/CREATE_CREDENTIAL";
export const SAVE_LINKEDIN_VERIFICATION = "@@credential/SAVE_LINKEDIN_VERIFICATION";
export const SET_CREDENTIAL_MAIN_ID = "@@credential/SET_CREDENTIAL_MAIN_ID";

/** ============= NEW ======================== */
export const CREDENTIAL_DETAILS = "@@credential/CREDENTIAL_DETAILS";
export const CREDENTIALS_BY_ACCOUNT = "@@credential/CREDENTIALS_BY_ACCOUNT";
export const UNASSIGNED_PLIVO_PHONE = "@@credential/UNASSIGNED_PLIVO_PHONE";
export const ASSIGNED_PLIVO_PHONE = "@@credential/ASSIGNED_PLIVO_PHONE";
