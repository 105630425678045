import { REMOVE_PERSON_MESSAGE, ADD_PERSON_MESSAGE } from "./actionTypes";

export const removePersonMessage = (data) => {
	return {
		type: REMOVE_PERSON_MESSAGE,
		payload: data,
	};
};

export const addPersonMessage = (data) => {
	return {
		type: ADD_PERSON_MESSAGE,
		payload: data,
	};
};
