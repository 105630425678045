import React from "react";
import styled from "styled-components";
import {
    EuiFieldText
} from '@elastic/eui';

import {briefcaseIcon} from "components/Common/Icons";
import {SVGIcon} from "components/Common";

/**
 * Main Components
 */
const StaticTitle = (props) => {

	const {
        title,
		...restProps
	} = props;

	return (
		<Wrapper {...restProps} className="mb-2">
			<SVGIcon
                icon={briefcaseIcon}
                className="mr-2"
            />
            <div>
                {title || ''}
            </div>
		</Wrapper>
	);
};

export default StaticTitle;

/**
 * Styled Components
 */
const Wrapper = styled.div`
    display: flex;
`;
