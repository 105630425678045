import { useState } from "react";

const UseToggle = (initialVal = false) => {
	//call State, "reserve piece of state"
	const [value, setValue] = useState(initialVal);

	const toggle = () => {
		setValue(!value);
	};

	// return piece of state And a function to toggle it
	return [value, toggle];
};

export default UseToggle;
