import { useDispatch } from "react-redux";

import { getGlobalSearchItems } from "helpers/actions";

/**
 * Hook for Global Search Item
 */
const useGlobalSearch = () => {
	const dispatch = useDispatch();

	const callGetGlobalSearchItems = (term) => {
		dispatch(getGlobalSearchItems({ search_term: term }));
	};

	return {
		getGlobalSearchItems: callGetGlobalSearchItems,
	};
};

export default useGlobalSearch;
