import React from 'react';
import './BulkActionPopoverNotification.scss'
import {EuiLoadingContent} from "@elastic/eui";

const BulkActionPopoverNotification = props => {

    let {
        // "type": Either pass or fail
        type,
        loading,
        affectedRecordsCount,
    } = props

    const renderMessage = () => {
        if (type === 'Success') {
            return 'Successfully Updated'
        }

        if (type === 'Failure') {
            return 'Unsuccessful Updates'
        }

        return ''
    }

    const renderNotification = () => {
        if (loading) {
            return <EuiLoadingContent lines={3} />
        } else {
            return (
                <div className={"msg-container"}>
                    {
                        affectedRecordsCount && (
                            <React.Fragment>
                                <p className="msg">
                                    {renderMessage()}
                                </p>

                                <p className="count">
                                    {affectedRecordsCount} {parseFloat(affectedRecordsCount) === 1 ? 'Record' : 'Records'}
                                </p>
                            </React.Fragment>
                        )
                    }
                </div>
            )
        }
    }

    return renderNotification()
};

export default BulkActionPopoverNotification;
