import React, { useState } from "react";
import styled from "styled-components";
import {
	EuiFlexGroup,
	EuiFlexItem,
	EuiSelect,
	EuiText,
	EuiFieldNumber,
} from "@elastic/eui";

import { CheckBoxWithRoundCheckIcon, SelectWithHeader } from "components/Common";

import variables from "assets/css/variables.scss";

/**
 * Main Components
 */
const ScreeningOtherPerson = (props) => {
	const { ...restProps } = props;

	const optionsWorkAuthorizationPrimary = [
		{ value: "usCitizen", text: "U.S.Citizen" },
		{ value: "h1b", text: "H1B" },
		{ value: "h1b", text: "H1B" },
	];

	const optionsWorkAuthorizationSecond = [
		{ value: "transfer", text: "Transfer" },
		{ value: "transfer1", text: "Transfer 1" },
	];

	const optionsWorkShift = [
		{ value: "normal", text: "Normal" },
		{ value: "test", text: "Test" },
	];

	const [selectedWorkAuthorizationPrimary, setSelectedWorkAuthorizationPrimary] =
		useState(optionsWorkAuthorizationPrimary?.[0].value);
	const [selectedWorkAuthorizationSecond, setSelectedWorkAuthorizationSecond] =
		useState(optionsWorkAuthorizationSecond?.[0].value);
	const [selectedWorkShift, setSelectedWorkShift] = useState(
		optionsWorkShift?.[0].value
	);
	const [checkedRequiredGreenCard, setCheckedRequiredGreenCard] = useState(false);
	const [daysNoticeRequired, setDaysNoticeRequired] = useState(0);

	const onChangeWorkAuthorizationPrimary = (e) => {
		setSelectedWorkAuthorizationPrimary(e.target.value);
	};

	const onChangeWorkAuthorizationSecond = (e) => {
		setSelectedWorkAuthorizationSecond(e.target.value);
	};

	const onChangeWorkShift = (value) => {
		setSelectedWorkShift(value);
	};

	const onChangeGreenCard = (value) => {
		setCheckedRequiredGreenCard(value);
	};

	const onChangeDaysNoticeRequired = (e) => {
		setDaysNoticeRequired(e.target.value);
	};

	return (
		<Wrapper {...restProps}>
			<EuiFlexGroup gutterSize="m" direction="column">
				<EuiFlexItem>
					<EuiFlexGroup gutterSize="xs" direction="column">
						<EuiFlexItem>
							<EuiText size="xs" textAlign="left" className="item-title">
								Work Authorization
							</EuiText>
						</EuiFlexItem>
						<EuiFlexItem>
							<EuiFlexGroup gutterSize="m">
								<EuiFlexItem grow={false} className="detail-select">
									<EuiSelect
										id="person-sourcing-work-authorization-primary-item"
										options={optionsWorkAuthorizationPrimary}
										value={selectedWorkAuthorizationPrimary}
										onChange={onChangeWorkAuthorizationPrimary}
										aria-label="work authorization primary item"
									/>
								</EuiFlexItem>
								{selectedWorkAuthorizationPrimary !==
								optionsWorkAuthorizationPrimary[0].value ? (
									<EuiFlexItem grow={false} className="detail-select">
										<EuiSelect
											id="person-sourcing-work-authorization-second-item"
											options={optionsWorkAuthorizationSecond}
											value={selectedWorkAuthorizationSecond}
											onChange={onChangeWorkAuthorizationSecond}
											aria-label="work authorization second item"
										/>
									</EuiFlexItem>
								) : null}
							</EuiFlexGroup>
						</EuiFlexItem>
					</EuiFlexGroup>
				</EuiFlexItem>
				{selectedWorkAuthorizationPrimary !==
				optionsWorkAuthorizationPrimary[0].value ? (
					<EuiFlexItem>
						<EuiFlexGroup gutterSize="s" direction="column">
							<EuiFlexItem>
								<EuiText size="s">
									Valid until{" "}
									<span className="detail-value">October</span>&nbsp;
									<span className="detail-value">2020</span>
								</EuiText>
							</EuiFlexItem>
							<EuiFlexItem>
								<CheckBoxWithRoundCheckIcon
									label={
										<EuiText size="s">
											Requires Green Card Sponsorship
										</EuiText>
									}
									onChange={onChangeGreenCard}
									checked={checkedRequiredGreenCard}
								/>
							</EuiFlexItem>
						</EuiFlexGroup>
					</EuiFlexItem>
				) : null}
				<EuiFlexItem grow={false}>
					<SelectWithHeader
						id="person-sourcing-other-work-shift"
						label="Work Shift"
						options={optionsWorkShift}
						value={selectedWorkShift}
						onChange={onChangeWorkShift}
						ariaLabel="work shift"
					/>
				</EuiFlexItem>
				<EuiFlexItem grow={false}>
					<EuiFlexGroup gutterSize="xs" direction="column">
						<EuiFlexItem>
							<EuiText size="xs" textAlign="left" className="item-title">
								Notice Required
							</EuiText>
						</EuiFlexItem>
						<EuiFlexItem grow={false} className="input-days">
							<EuiFieldNumber
								placeholder=""
								value={daysNoticeRequired}
								onChange={onChangeDaysNoticeRequired}
								min={0}
								append={<EuiText size="s">days</EuiText>}
								compressed={true}
								fullWidth={false}
								aria-label="notice required"
							/>
						</EuiFlexItem>
					</EuiFlexGroup>
				</EuiFlexItem>
			</EuiFlexGroup>
		</Wrapper>
	);
};

export default ScreeningOtherPerson;

/**
 * Styled Components
 */
const Wrapper = styled.div`
	padding: 14px 10px 0 10px;

	.item-title {
		font-weight: 600;
		color: ${variables.lightColor};
	}

	.detail-value {
		color: ${variables.linkColor};
	}

	.detail-select {
		& > div {
			height: auto;
		}
	}

	.input-days {
		.euiFormControlLayout {
			width: fit-content;
			height: 25px;
		}

		.euiFieldNumber {
			max-width: 80px;
			padding: 3px 8px;
		}

		.euiFormControlLayout__append {
			padding: 3px 8px;
			width: fit-content;
			align-items: center;
			display: flex;
		}
	}
`;
