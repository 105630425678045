export const CAMPAIGN_ITEM_DISPOSITIONS = {
	none: {
		key: "none",
		value: 0,
		label: "No Status",
		icon: "-",
		color: "var(--button-disabled-color)",
		textColor: "var(--text-color)",
	},
	proceed: {
		key: "proceed",
		value: 1,
		label: "Proceed",
		icon: "P",
		color: "var(--button-secondary-color)",
		textColor: "var(--button-secondary-color)",
	},
	future: {
		key: "future",
		value: 2,
		label: "Future Interest",
		icon: "F",
		color: "var(--core-warning-color)",
		textColor: "var(--core-warning-color)",
	},
	declined: {
		key: "declined",
		value: 3,
		label: "Declined",
		icon: "D",
		color: "var(--eui-failed-color)",
		textColor: "var(--text-color)",
	},
	stale: {
		key: "stale",
		value: 4,
		label: "Stale",
		icon: "S",
		color: "var(--badge-color)",
		textColor: "var(--text-color)",
	},
};
