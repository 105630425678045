import React, { useState } from "react";
import styled from "styled-components";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPen } from "@fortawesome/pro-light-svg-icons";

const ScreeningFlyoutEdit = (props) => {
	const { title, onEditHandler, children } = props;

	const [isHovered, setIsHovered] = useState(false);

	const handleMouseEnter = () => {
		setIsHovered(true);
	};

	const handleMouseLeave = () => {
		setIsHovered(false);
	};

	return (
		<Wrapper>
			<div className={"screening-flyout-editable-component"}>
				<div className="skill-name" onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
					<span className={"screening-flyout-editable-component--title"}>{title}</span>
				</div>

				{isHovered && (
					<div>
						<button
							className={"screening-flyout-editable-component--btn"}
							onClick={onEditHandler}
						>
							<FontAwesomeIcon
								onMouseEnter={handleMouseEnter}
								onMouseLeave={handleMouseLeave}
								icon={faPen}
							/>
						</button>
					</div>
				)}
			</div>
			<div>{children}</div>
		</Wrapper>
	);
};

export default ScreeningFlyoutEdit;

/**
 * Styled Components
 */
const Wrapper = styled.div`
	margin-bottom: 5px;

	.skill-name {
		margin: 4px 0px;
	}
	.screening-flyout-editable-component {
		display: flex;
		align-items: center;
		font-size: 0.9rem;
	}

	.screening-flyout-editable-component--title {
		margin-right: 10px;
		color: #98a2b3;
	}

	.screening-flyout-editable-component--btn {
		font-size: 0.8rem;
	}
`;
