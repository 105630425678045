import { useAccount, useMacro } from "core/useHooks";

const useTranlation = (person) => {
	const { getMacroTranslatedString } = useMacro();
	const { account } = useAccount();

	const translatePromise = (text) => {
		return new Promise((resolve) => {
			const params = {
				data_sources: [
					{
						object: "person",
						record_main_id: person?.id,
					},
					{
						object: "account",
						record_main_id: account?.id,
					},
				],
				template: text,
			};
			getMacroTranslatedString(
				params,
				(res) => {
					resolve(res);
				},
				(err) => {
					resolve({ error: true, message: err });
				}
			);
		});
	};

	const translateTexts = (texts) => {
		return Promise.all(texts.map((text) => translatePromise(text)));
	};

	return {
		translatePromise,
		translateTexts,
	};
};

export default useTranlation;
