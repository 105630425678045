import React from "react";
import { EuiButton, EuiButtonEmpty, EuiFlexGroup, EuiFlexItem } from "@elastic/eui";
import styled from "styled-components";

import { useGlobal, useGlobalFlyout } from "core/useHooks";
import { capitalizeFirstLetter } from "helpers/utilities";
import { getGCSLabel } from "components/global/utils";
import SitFlyoutItem from "./SitFlyoutItem";

/**
 * Hook for Sit Flyout
 */
const useSitFlyout = () => {
	const { setFlyout, closeFlyout } = useGlobalFlyout();
	const { globals } = useGlobal();

	const getTitle = (sitType) => {
		let title = "";
		if (globals?.length > 0) {
			title = getGCSLabel(globals, "sit_main", "sit_type_c", sitType);
		}

		return title;
	};

	const setSitFlyout = (sitType, sits, handleSave, onClose) => {
		const title = getTitle(sitType);
		let selecteSits = [];

		const handleChangeSits = (newSits) => {
			selecteSits = newSits;
		};

		const handleClose = () => {
			if (onClose) {
				onClose();
			}
			closeFlyout();
		};

		const saveAndClose = () => {
			handleSave(sitType, selecteSits);
			handleClose();
		};

		const paramsFlyout = {
			size: "m",
			title: "",
			maxWidth: "450px",
			header: (
				<span className="type-title-medium">
					<p style={{ fontSize: "1.8rem" }}>
						Add {capitalizeFirstLetter(title)}
					</p>
				</span>
			),
			body: (
				<BodyContainer gutterSize="none" direction="column">
					<SitFlyoutItem
						sitType={sitType}
						sits={sits}
						onChange={handleChangeSits}
					/>
				</BodyContainer>
			),
			footer: (
				<EuiFlexGroup justifyContent="spaceBetween">
					<EuiFlexItem grow={false}>
						<EuiButtonEmpty
							iconType="cross"
							onClick={handleClose}
							flush="left"
						>
							Close
						</EuiButtonEmpty>
					</EuiFlexItem>
					<EuiFlexItem grow={false}>
						<EuiButton onClick={saveAndClose} fill>
							Save
						</EuiButton>
					</EuiFlexItem>
				</EuiFlexGroup>
			),
			isOpen: true,
			...(onClose ? { closeFlyout: onClose } : {}),
		};

		setFlyout(paramsFlyout);
	};

	return {
		setSitFlyout,
	};
};

export default useSitFlyout;

const BodyContainer = styled(EuiFlexGroup)`
	.no-items-label {
		width: 100%;
		padding: var(--sp-5);
		text-align: center;
	}
`;
