import {
    ALL_CREDENTIALS,
    VERIFY_CREDENTIAL,
    CREDENTIAl_VERIFICATION_NOFICATION,
    SET_CREDENTIAL_MAIN_ID,
    CREDENTIALS_BY_ACCOUNT,
    UNASSIGNED_PLIVO_PHONE,
    ASSIGNED_PLIVO_PHONE,
    VERIFY_O365_EMAIL,
    VERIFY_VOIP_PHONE,
    VERIFY_SMS_PHONE,
    CREDENTIAL_DETAILS,
} from "./actionTypes";

/**
 * Functions
 */

const getAllCredentials = (state, payload) => {
    return {
        ...state,
        credentials: payload,
    };
};

const verifyCredential = (state, payload) => {
    return {
        ...state,
        verifyCredential: payload,
    };
};

const verifyO365Email = (state, payload) => {
    return {
        ...state,
        verifyO365Email: payload,
    };
};

const verifyVOIPPhone = (state, payload) => {
    return {
        ...state,
        verifyVOIPPhone: payload,
    };
};

const verifySMSPhone = (state, payload) => {
    return {
        ...state,
        verifySMSPhone: payload,
    };
};

const credentialVerificationNotification = (state, payload) => {
    return {
        ...state,
        credentialVerificationNotification: payload,
    };
};

const setCredentialMainId = (state, payload) => {
    return {
        ...state,
        credentialMainId: payload,
    };
};

const setCredentialsByAccount = (state, payload) => {
    return {
        ...state,
        credentialsByAccount: payload,
    };
};

const setUnassignedPlivoPhone = (state, payload) => {
    return {
        ...state,
        unassignedPlivePhone: payload,
    };
};

const setAssignedPlivoPhone = (state, payload) => {
    return {
        ...state,
        assignedPlivePhone: payload,
    };
};

const setCredentialDetails = (state, payload) => {
    return {
        ...state,
        credentialDetails: payload,
    };
};

/**
 * Initial State
 */
const initialState = {
    credentials: [],
    loading: false,
    verifyCredential: {},
    verifyO365Email: {},
    verifyVOIPPhone: {},
    verifySMSPhone: {},
    credentialVerificationNotification: {},
    credentialMainId: 0,
    credentialsByAccount: null,
    unassignedPlivePhone: [],
    assignedPlivePhone: [],
    credentialDetails: {},
};

/**
 * Reducer
 */
const Reducer = (state = initialState, action) => {
    switch (action.type) {
        case ALL_CREDENTIALS:
            return getAllCredentials(state, action.payload);

        case VERIFY_CREDENTIAL:
            return verifyCredential(state, action.payload);

        case VERIFY_O365_EMAIL:
            return verifyO365Email(state, action.payload);

        case VERIFY_VOIP_PHONE:
            return verifyVOIPPhone(state, action.payload);

        case VERIFY_SMS_PHONE:
            return verifySMSPhone(state, action.payload);

        case CREDENTIAl_VERIFICATION_NOFICATION:
            return credentialVerificationNotification(state, action.payload);

        case SET_CREDENTIAL_MAIN_ID:
            return setCredentialMainId(state, action.payload);

        /** --------------- NEW --------------- */
        case CREDENTIALS_BY_ACCOUNT:
            return setCredentialsByAccount(state, action.payload);

        case UNASSIGNED_PLIVO_PHONE:
            return setUnassignedPlivoPhone(state, action.payload);

        case ASSIGNED_PLIVO_PHONE:
            return setAssignedPlivoPhone(state, action.payload);

        case CREDENTIAL_DETAILS:
            return setCredentialDetails(state, action.payload);

        default:
            return state;
    }
};

export default Reducer;
