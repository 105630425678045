import React from "react";
import styled from "styled-components";
import Header from "./Header/Header";
import Notifications from "./NotificationChannel/NotificationChannel";
import SecondaryHeader from "./SecondaryHeader/SecondaryHeader";
import { GlobalFlyout } from "./GlobalFlyout";

const Layout = (props) => {
    const { children, hasAuth } = props;

    return (
        <Wrapper>
            {hasAuth ? (
                <HeaderWrapper>
                    <Header {...props} />
                    <SecondaryHeader />
                </HeaderWrapper>
            ) : null}
            <Notifications />
            <GlobalFlyout />
            {children}
        </Wrapper>
    );
};

export default Layout;

const Wrapper = styled.div`
    display: flex;
    position: relative;
    width: 100%;
`;

const HeaderWrapper = styled.div`
    .main-header-logo img {
        width: 100px;
        height: auto;
    }
    .header-item {
        padding: 8px 16px;
        display: flex;
        flex-direction: column;
        min-width: 100px;
        width: 100px;
        margin: auto;

        &__icon {
            margin: 0 auto -6px;
            svg {
                height: 20px;
            }
        }

        &__text {
            color: var(--eui-text-color) !important;
            text-align: center;
        }

        &:hover,
        &.active {
            background: var(--hovered-btn-bg-color);
            border-radius: 4px;
        }

        @media (max-width: 1120px) {
            min-width: 50px;
            width: 50px;

            &__text {
                display: none;
            }
        }
    }

    #root-main-header {
        position: fixed;
        top: 0;
        width: 100%;

        .euiHeaderSection.euiHeaderSection--dontGrow.euiHeaderSection--left:first-child {
            overflow: hidden;
        }

        .euiHeaderSectionItem:nth-child(2) {
            overflow: hidden;
        }

        .euiHeaderSection.euiHeaderSection--dontGrow.euiHeaderSection--left {
            flex-shrink: unset;
        }
    }

    #root-second-header {
        position: fixed;
        top: 0;
        width: 100%;
        margin-top: 48px;
        border-top: 1px solid #d3dae6;
        height: 40px;
        overflow-x: auto;
        overflow-y: hidden;
    }
`;
