import { useDispatch } from "react-redux";

import { _getSITLoading, _getSITs } from "helpers/selectors";
import { getSitListByKeyword } from "helpers/actions";

const useSIT = () => {
	const dispatch = useDispatch();

	const sitLoading = _getSITLoading();

	const allSits = _getSITs();

	return {
		sitLoading,
		allSits,

		getSITsByKeyword: (sitTypeC, keyword, successCallback, failCallback) => {
			dispatch(
				getSitListByKeyword(sitTypeC, keyword, successCallback, failCallback)
			);
		},
	};
};

export default useSIT;
