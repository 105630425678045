// Credential Types
export const CREDENTIAL_TYPES = {
	linkedin: { key: "linkedin", label: "LinkedIn", value: 0 },
	o365: { key: "o365", label: "O365", value: 1 },
	sms: { key: "sms", label: "Text", value: 2 },
	trackerRms: { key: "trackerRms", label: "Tracker RMS", value: 3 },
	seamlessAi: { key: "seamlessAi", label: "Seamless AI", value: 4 },
	phone: { key: "phone", label: "Phone", value: 5 },
	ziprecruiter: { key: "ziprecruiter", label: "Zip Recruiter", value: 6 },
	dice: { key: "dice", label: "Dice", value: 7 },
	monster: { key: "monster", label: "Monster", value: 8 },
};
