import React, { useState, useMemo } from "react";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMapMarkerAlt } from "@fortawesome/pro-regular-svg-icons";

import { global_address_details } from "core/model";

import CommonItemView from "../CommonItemView";
import EditAddressItem from "./EditAddressItem";
import EditableAddress from "./EditableAddress";

/**
 * Component for displaying Address Items
 */
const AddressItemsView = (props) => {
	const { addresses, primaryAddressId, onSaveAddress, onDeleteAddress, onSavePrimary } =
		props;

	const newAddress = {
		address_type_c: 1,
		[global_address_details._name]: {
			[global_address_details.address_name]: undefined,
			[global_address_details.address_line_1]: undefined,
			[global_address_details.address_line_2]: undefined,
			[global_address_details.address_line_3]: undefined,
			[global_address_details.address_city]: undefined,
			[global_address_details.address_state]: undefined,
			[global_address_details.address_zip]: undefined,
			[global_address_details.address_country]: undefined,
			[global_address_details.address_confirmed_c]: undefined,
		},
	};

	const [isEditAddAddress, setIsEditAddAddress] = useState(false);

	const saveAddressPrimary = (primaryAddressId) => {
		onSavePrimary(primaryAddressId);
	};

	const handleDelete = (addressId) => {
		onDeleteAddress(addressId);
	};

	const handleSaveNewAddress = (data, primary) => {
		onSaveAddress(data, (res, successCallBack) => {
			const newAddress = res.find((item) => item.id);
			if (primary) {
				saveAddressPrimary(newAddress?.id);
			} else {
				if (successCallBack) {
					successCallBack();
				}
			}
		});

		setIsEditAddAddress(false);
	};

	const handleCancelNewAddress = () => {
		setIsEditAddAddress(false);
	};

	const getAddressPrimary = (itemIndex) => {
		let res = false;
		if (addresses?.[itemIndex]?.id) {
			if (addresses?.[itemIndex]?.id === primaryAddressId) {
				res = true;
			}
		}
		return res;
	};

	const handleSaveAddress = (data, primary) => {
		onSaveAddress(data, (res, successCallBack) => {
			if (primary || (!primary && data.id === primaryAddressId)) {
				saveAddressPrimary(primary ? data?.id : null);
			} else {
				if (successCallBack) {
					successCallBack();
				}
			}
		});
	};

	const { addressItems, addressMainItem } = useMemo(() => {
		const addressItems = addresses?.map((item, idx) => {
			return (
				<EditableAddress
					key={`address-existing-item-${idx}`}
					address={item}
					isPrimary={getAddressPrimary(idx)}
					onSave={handleSaveAddress}
					onDelete={handleDelete}
				/>
			);
		});

		let mainAddressIndex = 0;
		const primayIndex = addresses?.findIndex((item) => item.id === primaryAddressId);
		if (primayIndex !== -1) {
			mainAddressIndex = primayIndex;
		}
		const addressMainItem = addresses?.[mainAddressIndex] ? (
			<EditableAddress
				address={addresses[mainAddressIndex]}
				addressIndex={mainAddressIndex}
				isPrimary={getAddressPrimary(mainAddressIndex)}
				onSave={handleSaveAddress}
				onDelete={handleDelete}
				hasIcon={false}
			/>
		) : null;

		return { addressItems, addressMainItem };
	}, [addresses, primaryAddressId]);

	return (
		<CommonItemView
			title="Address"
			icon={<FontAwesomeIcon icon={faMapMarkerAlt} />}
			items={addressItems}
			mainItem={addressMainItem}
			newItem={
				<EditAddressItem
					address={newAddress}
					addressPrimary={false}
					onSave={handleSaveNewAddress}
					onCancel={handleCancelNewAddress}
				/>
			}
			isEdit={isEditAddAddress}
			setIsEdit={setIsEditAddAddress}
			className="address-view"
		/>
	);
};

export default AddressItemsView;
