import React, { useMemo } from "react";
import styled from "styled-components";
import { EuiText } from "@elastic/eui";
import { isNil } from "lodash";

import { getJobStatusIcon } from "components/Common";
import { capitalizeFirstLetter } from "helpers/utilities";
import { getGCSLabel } from "components/global/utils";
import { useGlobal } from "core/useHooks";

/**
 * Component for job status
 *
 * jobStatusValue : job_status_c of job_main table
 */
const JobStatusItem = (props) => {
	const { jobStatusValue } = props;

	const { globals } = useGlobal();

	const getJobStatusLabelColor = (status) => {
		switch (status) {
			case "open-accepting":
				return "--eui-secondary-color";
			case "open-notaccepting":
				return "--eui-text-disabled-color";
			case "closed-cancelled":
			case "closed-contract-terms":
			case "closed-client-filled":
			case "closed-competition":
				return "--eui-light-shade-color";
			case "won-active":
				return "--eui-success-color";
			case "won-complete":
				return "--eui-complete-color";
			case "won-falloff":
				return "--eui-failed-color";
			default:
				return "--eui-secondary-color";
		}
	};

	const { icon, statusLabel, labelColor } = useMemo(() => {
		let icon = null;
		let statusLabel = "";
		let labelColor = "--eui-secondary-color";

		if (!isNil(jobStatusValue) && !!globals?.length) {
			const jobStatus = getGCSLabel(
				globals,
				"job_main",
				"job_status_c",
				jobStatusValue || 1
			);
			statusLabel = jobStatus.split(" ")?.[1];
			statusLabel = statusLabel ? capitalizeFirstLetter(statusLabel) : "";
			icon = getJobStatusIcon(jobStatus);
			labelColor = getJobStatusLabelColor(jobStatus);
		}

		return {
			icon,
			statusLabel,
			labelColor,
		};
	}, [jobStatusValue, globals]);

	return (
		<Wrapper>
			{icon}
			<EuiText
				size="s"
				style={{
					color: `var(${labelColor})`,
					paddingLeft: "var(--sp-2)",
					paddingTop: "var(--sp-1)",
				}}
			>
				{statusLabel}
			</EuiText>
		</Wrapper>
	);
};

export default JobStatusItem;

/**
 * Styled Components
 */
const Wrapper = styled.div`
	display: flex;
	flex-wrap: wrap;
`;
