import React, { useEffect, useState } from "react";
import { EuiFlexGroup, EuiFlexItem } from "@elastic/eui";
import styled from "styled-components";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faLinkedin } from "@fortawesome/free-brands-svg-icons/faLinkedin";

import { seamlessAiIcon, contactOutIcon } from "components/Common/Icons";

import variables from "assets/css/variables.scss";
import * as MODEL from "core/model";
import { getGCSValue } from "components/global/utils";

/**
 * Component for Enrichment Icons
 */
const EnrichmentIcons = (props) => {
	const { enrichmentDetails, type, globals } = props;

	const initSource = {
		linkedin: { value: 0, label: "linkedin", state: 0 },
		seamless: { value: 0, label: "seamless-ai", state: 0 },
		linkedinSalesNav: { value: 0, label: "linkedin-sales-navigator", state: 0 },
		linkedinRecruiter: { value: 0, label: "linkedin-recruiter-lit", state: 0 },
		contactOut: { value: 0, label: "contact-out", state: 0 },
		zoomInfo: { value: 0, label: "zoom-info", state: 0 },
	};

	const [source, setSource] = useState(initSource);

	useEffect(() => {
		let _source = initSource;

		if (globals) {
			Object.keys(_source).map((key) => {
				_source[key].value = getGCSValue(
					globals,
					MODEL.sourcing_process._name,
					MODEL.sourcing_process.process_type_c,
					_source[key].label
				);
			});
		}
		if (enrichmentDetails) {
			enrichmentDetails.forEach((enrichmentDetail) => {
				Object.keys(_source).map((key) => {
					if (_source[key].value === enrichmentDetail?.process_type_c) {
						_source[key].state = enrichmentDetail?.process_state_c;
					}
				});
			});
		}

		setSource(_source);
	}, [enrichmentDetails, globals]);

	const getEnrichmentCompleteStatus = (state) => {
		let res = variables.lightColor;
		if (globals) {
			let queueState = getGCSValue(
				globals,
				MODEL.sourcing_process._name,
				MODEL.sourcing_process.process_state_c,
				"queued"
			);
			let executingState = getGCSValue(
				globals,
				MODEL.sourcing_process._name,
				MODEL.sourcing_process.process_state_c,
				"executing"
			);
			let completeState = getGCSValue(
				globals,
				MODEL.sourcing_process._name,
				MODEL.sourcing_process.process_state_c,
				"completed"
			);
			let failedState = getGCSValue(
				globals,
				MODEL.sourcing_process._name,
				MODEL.sourcing_process.process_state_c,
				"failed"
			);
			if (state === queueState) {
				res = variables.lightColor;
			} else if (state === executingState) {
				res = variables.lightColor;
			} else if (state === completeState) {
				res = variables.linkColor;
			} else if (state === failedState) {
				res = variables.dangerColor;
			}
		}
		return res;
	};

	switch (type) {
		case "sourcing":
		case "person":
			return (
				<EuiFlexItem grow={false}>
					<EuiFlexGroup>
						<EuiFlexItem grow={false} className="icon-wrapper ml-2 mr-0">
							{source.linkedin.state !== 0 && (
								<FontAwesomeIcon
									icon={faLinkedin}
									size="lg"
									color={getEnrichmentCompleteStatus(
										source.linkedin.state
									)}
								/>
							)}
						</EuiFlexItem>
						<EuiFlexItem grow={false} className="icon-wrapper ml-1 mr-0">
							{source.linkedinSalesNav.state !== 0 && (
								<SalesNavIcon
									color={getEnrichmentCompleteStatus(
										source.linkedinSalesNav.state
									)}
								/>
							)}
						</EuiFlexItem>
					</EuiFlexGroup>
					<EuiFlexGroup>
						<EuiFlexItem grow={false} className="icon-wrapper ml-2 mr-0">
							{source.seamless.state !== 0 &&
								seamlessAiIcon(
									getEnrichmentCompleteStatus(source.seamless.state)
								)}
						</EuiFlexItem>
						<EuiFlexItem grow={false} className="icon-wrapper ml-1 mr-0">
							{source.contactOut.state !== 0 &&
								contactOutIcon(
									getEnrichmentCompleteStatus(source.contactOut.state)
								)}
						</EuiFlexItem>
					</EuiFlexGroup>
				</EuiFlexItem>
			);

		default:
			return null;
	}
};

export default EnrichmentIcons;

const SalesNavIcon = styled.div`
	background-image: url("/linkedInSalesNav.png");
	background-color: ${({ color }) =>
		color === variables.linkColor ? "" : variables.dangerColor};
	background-blend-mode: ${({ color }) =>
		color === variables.lightColor ? "soft-light" : "color-dodge"};
	width: 100%;
	height: 100%;
`;
