import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
    EuiAvatar,
    EuiFlexGroup,
    EuiFlexItem,
    EuiHeaderSectionItemButton,
    EuiIcon,
    EuiKeyPadMenuItem,
    EuiLink,
    EuiLoadingChart,
    EuiPopover,
    EuiSpacer,
    EuiText,
} from "@elastic/eui";
import useAccountDetail from "../../Hooks/useAccountDetail";
import { setAuthInfo } from "helpers/actions";
import useLayout from "../useLayout";
import { accountId, token } from "SocketProvider";
import { ip2int } from "helpers/utilities";
const publicIp = require("public-ip");

const UserHeaderMenu = (props) => {
    const dispatch = useDispatch();

    const { logoutAccount } = useLayout();

    const accountDetail = useSelector((state) => state.account.accountDetail);

    const { accountDetailLoaded } = useAccountDetail();

    const getUserName = () => {
        return accountDetail
            ? accountDetail.name_first + " " + accountDetail.name_last
            : "";
    };

    const [isOpen, setIsOpen] = useState(false);
    const history = useHistory();
    const closeMenu = () => {
        setIsOpen(false);
    };

    const onMenuButtonClick = () => {
        setIsOpen(!isOpen);
    };

    const userAvatar = (
        <>
            <EuiHeaderSectionItemButton
                aria-controls="headerUserMenu"
                aria-expanded={isOpen}
                aria-haspopup="true"
                aria-label="Account menu"
                onClick={onMenuButtonClick}
            >
                <EuiAvatar name={getUserName()} size="s" />
                {/* <ShowNonDynamicBorder /> */}
            </EuiHeaderSectionItemButton>
        </>
    );

    const removeToken = () => {
        dispatch(
            setAuthInfo({
                emai: "",
                token: "",
                id: "",
            })
        );

        // Remove token
        localStorage.removeItem("token");
        localStorage.removeItem("id");
        localStorage.removeItem("login_state");

        // Redirect
        window.location.replace("/login");
    };

    const handleLogout = async () => {
        const ip = await publicIp.v4();
        const query = {
            account_id: accountId,
            token: token,
            source_ip: ip2int(ip),
        };

        logoutAccount(query, () => {
            removeToken();
        });
    };

    const renderUserHeaderMenu = () => {
        return (
            <EuiPopover
                id="headerUserMenu"
                button={userAvatar}
                isOpen={isOpen}
                anchorPosition="downLeft"
                closePopover={closeMenu}
                panelPaddingSize="s"
            >
                <EuiFlexGroup
                    gutterSize="m"
                    className="euiHeaderProfile"
                    responsive={false}
                >
                    <EuiFlexItem grow={false}>
                        <EuiAvatar name={getUserName()} size="xl" />
                    </EuiFlexItem>
                    <EuiFlexItem>
                        <EuiFlexGroup responsive={false} direction={"column"}>
                            <EuiFlexItem grow={false} className={"mt-3 mb-2"}>
                                <EuiText>
                                    <p>{getUserName()}</p>
                                </EuiText>
                            </EuiFlexItem>
                            <EuiFlexItem grow={false} className={"my-0 mb-3"}>
                                <EuiLink
                                    onClick={() => {
                                        history.push("/profile");
                                        onMenuButtonClick();
                                    }}
                                >
                                    My Profile
                                </EuiLink>
                            </EuiFlexItem>
                            <EuiFlexItem grow={false} className={"my-0 mb-3"}>
                                <EuiLink
                                    onClick={() => {
                                        history.push(
                                            "/system-configuration/user-accounts"
                                        );
                                        onMenuButtonClick();
                                    }}
                                >
                                    System Administration
                                </EuiLink>
                            </EuiFlexItem>
                            <EuiFlexItem grow={false} className={"my-0 mb-3"}>
                                <EuiLink onClick={handleLogout}>Logout</EuiLink>
                            </EuiFlexItem>
                        </EuiFlexGroup>
                    </EuiFlexItem>
                </EuiFlexGroup>
            </EuiPopover>
        );
    };

    const renderLoading = () => {
        return (
            <div
                style={{
                    marginRight: "10px",
                }}
            >
                <EuiLoadingChart size="m" />
            </div>
        );
    };

    return accountDetailLoaded() ? renderUserHeaderMenu() : renderLoading();
};

export default UserHeaderMenu;
