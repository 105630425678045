export const qualifier_main = {
    _name: "qualifier_main",
    id: "id",
    qualifier_name: "qualifier_name",
    qualifier_parent_id: "qualifier_parent_id",
    qualifier_children_count: "qualifier_children_count",
    qualifier_override: "qualifier_override",
    theme_item_main_ref_id: "theme_item_main_ref_id",
};

export const qualifier_assignment = {
    _name: "qualifier_assignment",
    id: "id",
    qualifier_main_ref_id: "qualifier_main_ref_id",
    object_type_c: "object_type_c",
    object_record_ref_id: "object_record_ref_id",
};

export const qualifier_association = {
    _name: "qualifier_association",
    id: "id",
    association_type_c: "association_type_c",
    qualifier_main_ref_id: "qualifier_main_ref_id",
    security_group_ref_id: "security_group_ref_id",
};

export const qualifier_log_detail = {
    _name: "qualifier_log_detail",
    id: "id",
    detail_text: "detail_text",
};

export const qualifier_log_activity = {
    _name: "qualifier_log_activity",
    id: "id",
    date_created: "date_created",
    account_main_ref_id: "account_main_ref_id",
    qualifier_table_ref_id: "qualifier_table_ref_id",
    log_object_c: "log_object_c",
    log_type_c: "log_type_c",
    log_action_c: "log_action_c",
    qualifier_log_detail_ref_id: "qualifier_log_detail_ref_id",
};
