import { useDispatch } from "react-redux";

import {
	setNotification,
	setProgressNotification,
	removeNotification,
} from "helpers/actions";
import { _getNotifications } from "helpers/selectors";

/**
 * Hooks for Notification
 */
const useNotification = () => {
	const dispatch = useDispatch();

	const notifications = _getNotifications();

	const setNotificationMessage = (params) => {
		dispatch(setNotification(params));
	};

	const removeNotificationMessage = (data) => {
		dispatch(removeNotification(data));
	};

	return {
		notifications,
		setNotificationMessage,
		removeNotificationMessage,
		setProgressNotification: (params) => {
			dispatch(setProgressNotification(params));
		},
	};
};

export default useNotification;
