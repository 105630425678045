import storage from "redux-persist/lib/storage";

const whitelist = ["auth", "message"];

// Custom state reconciliation.
// NOTE: redux-persist is a shitty library for a few reasons.
// 1) stateReconciler defaults are ALL broken in some way.
// 2) uses one localStorage key for everything, which is horribly inefficient.
// eslint-disable-next-line no-unused-vars
function stateReconciler(inboundState, originalState, reducedState, config) {
    const newState = Object.assign({}, originalState);

    // This is why the author of redux-persist is a ZOOMER dev...
    // Doesn't know how object references work.
    for (const key in inboundState) {
        if (newState[key] && typeof newState[key] !== "object")
            Object.assign(newState[key], inboundState[key]);
        else newState[key] = inboundState[key];
    }

    return newState;
}

export default {
    // Auto-merge is actually unreliable, had to implement custom reconciler.
    stateReconciler,
    key: "empower",
    storage: storage,
    whitelist,
};
