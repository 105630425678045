import React, { useState } from "react";
import styled from "styled-components";
import { EuiSuperSelect, EuiFieldText, EuiSelect } from "@elastic/eui";

import { emailGlobeIcon, emailIcon } from "components/Common/Icons/Icons";
import { global_email_details, person_email } from "core/model";

import { plusIcon, minusIcon } from "components/Common/Icons";
import { SVGIcon } from "components/Common";

/**
 * Main Components
 */
const SelectCreateEmail = (props) => {
	const { idx, email, options, onChangeEmail, ...restProps } = props;

	// define variable from email
	const emailType = email?.person_email?.email_type_c || 1;
	const emailValue = email?.person_email?.global_email_details?.email_value || "";

	// state variable.
	const [isCreate, setIsCreate] = useState(false);

	const emailTypeOptions = [
		{
			value: 1,
			label: "generic",
			inputDisplay: emailGlobeIcon(),
		},
		{
			value: 2,
			label: "personal",
			inputDisplay: emailIcon(),
		},
		{
			value: 3,
			label: "audio",
			inputDisplay: emailGlobeIcon(),
		},
		{
			value: 4,
			label: "video",
			inputDisplay: emailGlobeIcon(),
		},
	];

	const changeNewEmailValue = (fieldName, value, global = false) => {
		let _email = email?.person_email || {};
		if (global) {
			_email[global_email_details._name] = {
				[fieldName]: value,
			};
		} else {
			_email[fieldName] = value;
		}

		onChangeEmail({
			value: null,
			person_email: _email,
		});
	};

	const handleEmailValue = (e) => {
		let idx = options.findIndex(
			(elem) => elem.value === parseInt(e.target.value, 10)
		);
		if (idx > -1) {
			onChangeEmail({
				value: options[idx]?.value,
			});
		}
	};

	const handleChangeNewEmailType = (val) => {
		changeNewEmailValue(person_email.email_type_c, val);
	};

	const handleNewEmailValue = (e) => {
		changeNewEmailValue(global_email_details.email_value, e.target.value, true);
	};

	const changeStatus = () => {
		setIsCreate(isCreate ? false : true);
	};

	return (
		<Wrapper {...restProps}>
			{isCreate ? (
				<WrapperContainer>
					<EuiSuperSelect
						size="s"
						options={emailTypeOptions}
						valueOfSelected={emailType}
						onChange={handleChangeNewEmailType}
					/>
					<EuiFieldText
						placeholder="Email"
						value={emailValue}
						onChange={handleNewEmailValue}
					/>
					<SVGIcon
						icon={minusIcon}
						onClick={changeStatus}
						className="my-auto ml-2 cursor-pointer"
					/>
				</WrapperContainer>
			) : (
				<WrapperContainer>
					<EuiSelect
						options={options}
						value={email?.value || undefined}
						onChange={handleEmailValue}
					/>
					<SVGIcon
						icon={plusIcon}
						onClick={changeStatus}
						className="my-auto ml-2 cursor-pointer"
					/>
				</WrapperContainer>
			)}
		</Wrapper>
	);
};

export default SelectCreateEmail;

/**
 * Styled Components
 */
const Wrapper = styled.div`
	display: flex;
	width: 100%;

	.euiSuperSelectControl {
		height: 25px;
		line-height: 28px;
		padding-right: 25px;
		padding-left: 6px;
	}

	.euiFormControlLayoutIcons--right {
		right: 6px;
	}

	.euiFieldText {
		padding: 4px;
	}
`;

const WrapperContainer = styled.div`
	position: relative;
	display: flex;
	width: 100%;
`;
