import React, { useState } from "react";
import styled from "styled-components";
import { EuiCard } from "@elastic/eui";

import CompensationOverviewItem from "./CompensationOverviewItem";
import CompensationEditItem from "./CompensationEditItem";

/**
 * Component for Compensation Item
 */
const CompensationItem = (props) => {
	const { compensation, editable = false, itemId } = props;

	const [isEdit, setIsEdit] = useState(true);

	const handleOpenCompensation = () => {
		setIsEdit(true);
	};

	const handleCloseCompensation = () => {
		setIsEdit(false);
	};

	const handleCopyCompensation = () => {
		console.log("copy compensation");
	};

	const handleDeleteCompensation = () => {
		console.log("delete compensation");
	};

	return (
		<Wrapper editable={editable}>
			<EuiCard title="" description="">
				{isEdit ? (
					<CompensationEditItem
						compensation={compensation}
						onCloseItem={handleCloseCompensation}
						itemId={itemId}
					/>
				) : (
					<CompensationOverviewItem
						compensation={compensation}
						editable={editable}
						onOpenItem={handleOpenCompensation}
					/>
				)}
			</EuiCard>
		</Wrapper>
	);
};

export default CompensationItem;

const Wrapper = styled.div`
	display: flex;
	position: relative;
	align-items: center;
	justify-content: left;

	.euiTitle.euiCard__title {
		display: none;
	}

	.euiText.euiCard__description {
		display: none;
	}

	& > div {
		padding: 10px 14px;
	}

	.euiCard__description {
		margin: 0;
	}
`;
