export const object_ras_name = "ras";

export const ras_assignment_main = {
	_name: "ras_assignment_main",
	id: "id",
	object_type_c: "object_type_c",
	object_ref_id: "object_ref_id",
	assignment_type_c: "assignment_type_c",
	assignment_ref_id: "assignment_ref_id",
};

export const ras_restriction_main = {
	_name: "ras_restriction_main",
	id: "id",
	object_type_c: "object_type_c",
	object_ref_id: "object_ref_id",
	restriction_type_c: "restriction_type_c",
};

export const ras_log_activity = {
	_name: "ras_log_activity",
	id: "id",
	date_created: "date_created",
	account_main_ref_id: "account_main_ref_id",
	ras_table_ref_id: "ras_table_ref_id",
	log_object_c: "log_object_c",
	log_type_c: "log_type_c",
	log_action_c: "log_action_c",
	ras_log_detail_ref_id: "ras_log_detail_ref_id",
};

export const ras_log_detail = {
	_name: "ras_log_detail",
	id: "id",
	detail_text: "detail_text",
};
