import React, { useMemo, useState } from "react";
import styled from "styled-components";
import { EuiPopover, EuiFlexGroup, EuiFlexItem } from "@elastic/eui";

import { AMS_TASK_TYPE_LIST } from "../../constants";

/**
 * Component for AMS Task Type Item with editable feature
 */
const AmsTaskTypeItem = (props) => {
	const { onChange, type = 1, isEdit = true, isIcon = true } = props;

	const [isOpenPopover, setIsOpenPopover] = useState(false);

	const { amsTaskType } = useMemo(() => {
		const amsTaskType =
			Object.values(AMS_TASK_TYPE_LIST).find((item) => item.value === type) ||
			AMS_TASK_TYPE_LIST.taskItem;

		return {
			amsTaskType,
		};
	}, [type]);

	const handleOpenPopover = () => {
		if (isEdit) {
			setIsOpenPopover(true);
		} else {
			setIsOpenPopover(false);
		}
	};

	const handleClosePopover = () => {
		setIsOpenPopover(false);
	};

	const handleChangeType = (newState) => {
		if (onChange) {
			onChange(newState);
		}

		setIsOpenPopover(false);
	};

	return (
		<EuiPopover
			isOpen={isOpenPopover}
			button={
				<div
					onClick={handleOpenPopover}
					className={`${
						isEdit && "cursor-pointer"
					} hover-underline ams-task-type-item-label"`}
					style={{ color: "var(--eui-link-color)" }}
				>
					{isIcon ? amsTaskType.icon : amsTaskType.label}
				</div>
			}
			closePopover={handleClosePopover}
			panelStyle={{
				width: 110,
				padding: "0.5rem 0.75rem",
			}}
		>
			<EuiFlexGroup direction="column" gutterSize="s">
				{Object.values(AMS_TASK_TYPE_LIST).map((item) => {
					const selected = item.value === amsTaskType.value;
					return (
						<EuiFlexItem key={`ams-status-item-${item.value}`}>
							<TypeListItem
								gutterSize="s"
								alignItems="center"
								className={`cursor-pointer ${selected ? "seleted" : ""}`}
								onClick={() => handleChangeType(item.value)}
							>
								<EuiFlexItem grow={false}>
									<div style={{ color: item.color }}>{item.icon}</div>
								</EuiFlexItem>
								<EuiFlexItem>{item.label}</EuiFlexItem>
							</TypeListItem>
						</EuiFlexItem>
					);
				})}
			</EuiFlexGroup>
		</EuiPopover>
	);
};

export default AmsTaskTypeItem;

const TypeListItem = styled(EuiFlexGroup)`
	svg {
		width: var(--sp-8);
		height: auto;
	}

	&.seleted {
		color: var(--eui-link-color);
	}

	&:hover {
		color: var(--eui-link-color);
	}
`;
