import React from "react";
import styled from "styled-components";

/**
 * Main Components
 */
const JobCompensation = (props) => {
    const { ...restProps } = props;

    return <Wrapper {...restProps}>Job Compensation</Wrapper>;
};

export default JobCompensation;

/**
 * Styled Components
 */
const Wrapper = styled.div``;
