import {
	SET_MESSAGES,
	REMOVE_MESSAGES,
	ADD_MESSAGE,
	SET_MESSAGE_CHATBOX,
	SET_RELATED_SELECTED_OPTIONS,
	SET_MESSAGING_PERSON_FLYOUT_VISIBLE,
	SET_SELECTED_CREDENTIAL_ID,
} from "./actionTypes";

/**
 * Functions
 */

const setSelectedCredentialId = (state, payload) => {
	return {
		...state,
		selectedCredentialID: payload,
	};
};

const setMessagingPersonFlyoutVisible = (state, payload) => {
	return {
		...state,
		messagingPersonFlyoutVisibility: payload.visibility,
		messagingPersonFlyoutVisibilityPersonId: payload.personId,
	};
};

const setRelatedSelectedOptions = (state, payload) => {
	return {
		...state,
		relatedSelectedOptions: payload,
	};
};

const setMessageChatbox = (state, payload) => {
	return {
		...state,
		chatboxMessage: payload,
	};
};

const addMessage = (state, payload) => {
	const newMessages = [...state.messages];

	newMessages.push({
		type: "self",
		content: payload.chatboxMessage,
		message: payload.message,
		emailDetails: {
			link: "dmcchy@gmail.com",
			text: "RE .Net Developer (Scottsdale)",
		},
		comments: [
			{
				date: "Jan 11, 2020",
				message: "Could not reach will try back",
			},
			{
				date: "Jan 9, 2020",
				message: "Status changed from Scheduled to Deferred",
			},
			{
				date: "Jan 1, 2020",
				message: "Saved as Draft",
			},
		],
		relatedOptions: payload.relatedOptions,
		sending: payload.sending,
		timestamp: payload.timestamp,
	});

	return {
		...state,
		messages: newMessages,
	};
};

const setMessages = (state, payload) => {
	// const newMessage = [...payload]

	return {
		...state,
		messages: payload,
	};
};

const removeMessages = (state, payload) => {
	return {
		...state,
		messages: state.messages.filter((toast) => toast.id !== payload.id),
	};
};

/**
 * Initial State
 */
const initialState = {
	messages: [],
	chatboxMessage: "",

	/**
	 * Related options
	 */
	relatedSelectedOptions: [
		{
			label: "A",
		},
	],
	relatedOptions: [
		{
			label: "A",
		},
		{
			label: "B",
		},
		{
			label: "C",
		},
	],

	/**
	 * For Person
	 */
	messagingPersonFlyoutVisibility: false,
	messagingPersonFlyoutVisibilityPersonId: false,

	selectedCredentialID: null,

	messagingPersonTypes: ["email", "linked-in"],
	messagingPersonType: "linked-in",

	/**
	 * Job orders
	 */
	messagingJobOrders: [
		{ value: "option_one", text: "Option one" },
		{ value: "option_two", text: "Option two" },
		{ value: "option_three", text: "Option three" },
	],
	messagingJobInsertDescription: false,
};

/**
 * Reducer
 */
const Reducer = (state = initialState, action) => {
	switch (action.type) {
		case SET_SELECTED_CREDENTIAL_ID:
			return setSelectedCredentialId(state, action.payload);

		case SET_MESSAGES:
			return setMessages(state, action.payload);

		case REMOVE_MESSAGES:
			return removeMessages(state, action.payload);

		case ADD_MESSAGE:
			return addMessage(state, action.payload);

		case SET_MESSAGE_CHATBOX:
			return setMessageChatbox(state, action.payload);

		case SET_RELATED_SELECTED_OPTIONS:
			return setRelatedSelectedOptions(state, action.payload);

		case SET_MESSAGING_PERSON_FLYOUT_VISIBLE:
			return setMessagingPersonFlyoutVisible(state, action.payload);

		default:
			return state;
	}
};

export default Reducer;
