import React, { useEffect, useState } from "react";
import styled from "styled-components";
import {
	EuiFieldNumber,
	EuiButtonEmpty,
	EuiPopover,
	EuiFlexItem,
	EuiButtonIcon,
	EuiFlexGroup,
} from "@elastic/eui";
import { isNumber } from "lodash";

/**
 * Component for Editable Number
 */
const EditableNumber = (props) => {
	const {
		value,
		onChange,
		prepend,
		append,
		minimumIntegerDigits = 1,
		maximumFractionDigits = 0,
		numberStyle,
		onSave,
		disabled = false,
		minVal,
		maxVal,
		hasUnderline = false,
		placeholder,
	} = props;

	const [isOpenPopover, setIsOpenPopover] = useState(false);
	const [number, setNumber] = useState(value);

	const handleOpenPopover = () => {
		setIsOpenPopover(true);
	};

	const handleClosePopover = () => {
		setIsOpenPopover(false);
	};

	const handleChangeValue = (e) => {
		let newValue = Number(e.target.value);

		if (isNumber(minVal)) {
			newValue = Math.max(newValue, minVal);
		}

		if (isNumber(maxVal)) {
			newValue = Math.min(newValue, maxVal);
		}

		if (onChange) {
			onChange(newValue);
		}

		setNumber(newValue);
	};

	const handleSave = () => {
		if (onSave) {
			onSave(number);
		}
		setIsOpenPopover(false);
	};

	const handleCancel = () => {
		setNumber(value);
		setIsOpenPopover(false);
	};

	useEffect(() => {
		setNumber(value);
	}, [value]);

	return (
		<Wrapper hasUnderline={hasUnderline} disabled={disabled}>
			<EuiPopover
				button={
					<div
						className={`button-wrapper ${hasUnderline && "underline"} ${
							disabled && "disabled"
						}`}
					>
						<EuiButtonEmpty
							className="button-fit-content number-button"
							onClick={handleOpenPopover}
							disabled={disabled}
						>
							<p
								style={{
									...(numberStyle ? numberStyle : {}),
								}}
							>
								{prepend ? prepend : ""}
								{value !== undefined && value !== null ? (
									typeof value === "number" ? (
										value.toLocaleString("en-US", {
											minimumIntegerDigits: minimumIntegerDigits,
											maximumFractionDigits: maximumFractionDigits,
										})
									) : (
										value
									)
								) : (
									<span className="placeholder">
										{placeholder
											? placeholder
											: (0).toLocaleString("en-US", {
													minimumIntegerDigits:
														minimumIntegerDigits,
													maximumFractionDigits:
														maximumFractionDigits,
											  })}
									</span>
								)}
							</p>
							{!!append && <span className="append-text">{append}</span>}
						</EuiButtonEmpty>
					</div>
				}
				isOpen={isOpenPopover}
				closePopover={handleClosePopover}
				panelPaddingSize="s"
				anchorPosition="downCenter"
				panelStyle={{
					minWidth: 0,
				}}
			>
				<EuiFlexGroup direction="column" gutterSize="s">
					<EuiFlexItem grow={false}>
						<EuiFieldNumber
							placeholder={"Enter value"}
							value={number}
							onChange={handleChangeValue}
							compressed
							min={minVal}
							disabled={disabled}
							max={maxVal}
						/>
					</EuiFlexItem>
					{onSave && (
						<EuiFlexItem grow={false}>
							<EuiFlexGroup gutterSize="s" justifyContent="center">
								<EuiFlexItem grow={false}>
									<EuiButtonIcon
										color="success"
										onClick={handleSave}
										iconType="check"
									/>
								</EuiFlexItem>
								<EuiFlexItem grow={false}>
									<EuiButtonIcon
										color="danger"
										onClick={handleCancel}
										iconType="cross"
									/>
								</EuiFlexItem>
							</EuiFlexGroup>
						</EuiFlexItem>
					)}
				</EuiFlexGroup>
			</EuiPopover>
		</Wrapper>
	);
};

export default EditableNumber;

const Wrapper = styled.div`
	display: flex;
	position: relative;

	.button-wrapper {
		display: flex;
		align-items: flex-start;

		&.underline {
			border-bottom: 1px solid var(--eui-link-color);

			&.disabled {
				border-bottom: 1px solid var(--eui-text-disabled-color);
			}
		}

		.append-text {
			color: var(--link-color);
			margin-left: var(--sp-1);
		}

		&.disabled {
			.append-text {
				color: var(--eui-text-disabled-color);
			}
		}

		.number-button {
			.euiButtonEmpty__text {
				display: inline-flex;
			}

			.placeholder {
				opacity: 0.3;
				color: var(--eui-text-color);
			}
		}
	}

	.euiButtonEmpty.euiButtonEmpty--primary {
		text-decoration: none !important;
	}
`;
