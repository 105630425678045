/**
 * Jobs
 */
export const GET_JOBS = "@@jobs/GET_JOBS";
export const SET_JOBS = "@@jobs/SET_JOBS";
export const SET_JOB = "@@jobs/SET_JOB";
export const SET_JOB_SELECTED_SKILLS = "@@jobs/SET_JOB_SELECTED_SKILLS";
export const SET_MESSAGING_JOB_ORDERS = "@@jobs/SET_MESSAGING_JOB_ORDERS";
export const SET_JOB_FLYOUT_VISIBILITY = "@@jobs/SET_JOB_FLYOUT_VISIBILITY";

/* ------------------------ NEW ----------------------------- */
// Job List for all properties
export const GET_JOB_LIST_NEW = "@@company/GET_JOB_LIST_NEW";
export const SET_JOB_LIST_NEW = "@@company/SET_JOB_LIST_NEW";

// Job Main Table
export const GET_JOB_MAINLIST_NEW = "@@company/GET_JOB_MAINLIST_NEW";
export const SET_JOB_MAINLIST_NEW = "@@company/SET_JOB_MAINLIST_NEW";
export const REQUEST_JOB_MAINLIST_NEW = "@@company/REQUEST_JOB_MAINLIST_NEW";
export const RECEIVED_JOB_MAINLIST_NEW = "@@company/RECEIVED_JOB_MAINLIST_NEW";

// Job Details
export const GET_JOB_DETAIL_NEW = "@@company/GET_JOB_DETAIL_NEW";
export const SET_JOB_DETAIL_NEW = "@@company/SET_JOB_DETAIL_NEW";

// Job Application List
export const SET_JOB_APPLICATION_LIST_NEW = "@@sourcing/SET_JOB_APPLICATION_LIST_NEW";
export const GET_JOB_APPLICATION_LIST_NEW = "@@sourcing/GET_JOB_APPLICATION_LIST_NEW";
export const REQUEST_JOB_APPLICATION_LIST_NEW =
    "@@sourcing/REQUEST_JOB_APPLICATION_LIST_NEW";
export const RECEIVED_JOB_APPLICATION_LIST_NEW =
    "@@sourcing/RECEIVED_JOB_APPLICATION_LIST_NEW";
export const SET_JOB_APPLICATION_LIST_PARAMS_NEW =
    "@@sourcing/SET_JOB_APPLICATION_LIST_PARAMS_NEW";

// Job Applications for Person
export const JOB_APPLICATIONS_FOR_PERSON = "@@job/JOB_APPLICATIONS_FOR_PERSON";
// Job Contacts for Person
export const JOB_CONTACTS_FOR_PERSON = "@@job/JOB_CONTACTS_FOR_PERSON";

/* ---------------------------------------------------------- */
