import React, { useState } from "react";
import styled from "styled-components";
import { EuiTabs, EuiTab, EuiFlexGroup, EuiFlexItem, EuiText } from "@elastic/eui";
import { get, set } from "lodash";
import * as MODEL from "core/model";
import { BENEFIT_TYPE_STRUCTURE } from "./constants";
import { RoundButtonWithPlusIcon } from "components/Common";
import OvertimeItem from "./CompensationPayStructure/OvertimeItem";

const CompensationBenifit = (props) => {
	const { data, onChange } = props;

	const benefits = get(data, `${MODEL.compensation_benefit._name}`, []) || [];

	// benefit structure make as array as the whole.
	let benefitInfoList = [];
	Object.values(BENEFIT_TYPE_STRUCTURE).map((benefitTypeInfo) => {
		benefitInfoList = benefitInfoList.concat(
			Object.values(benefitTypeInfo.categories).map((benefitInfo) => {
				const benefitIndex = benefits?.findIndex(
					(_benefit) =>
						_benefit?.benefit_type_c === benefitInfo?.value &&
						_benefit?.benefit_details?.benefit_type_category ===
							benefitInfo?.initData?.benefit_details?.benefit_type_category
				);

				return {
					...benefitInfo,
					benefitIndex,
					benefit: benefitIndex !== -1 ? benefits[benefitIndex] : null,
				};
			})
		);
	});

	const handleClickNewItem = (index) => {
		const countBenfit = benefits?.length || 0;
		const benefitInfo = benefitInfoList[index];
		const initData = benefitInfo.initData;
		const _newData = set(
			data,
			`${MODEL.compensation_benefit._name}[${countBenfit}]`,
			initData
		);
		onChange(_newData);
	};

	const handleChange = (index, itemValue) => {
		const _newData = set(
			data,
			`${MODEL.compensation_benefit._name}[${index}]`,
			itemValue
		);
		onChange(_newData);
	};

	const renderBenefitList = (benefitType) => {
		return benefitInfoList.map((item) => {
			const {
				key: benefitKey,
				label: benefitLabel,
				benefitIndex,
				value: type,
				benefit,
			} = item;
			const hasBenefit = benefitIndex !== -1;

			const data = {
				value: Number(benefit?.benefit_details?.benefit_value || "0"),
			};
			const handleChangeOvertime = (values) => {
				handleChange(benefitIndex, {
					...(benefit ?? {}),
					benefit_details: {
						...benefit?.benefit_details,
						benefit_value: values.value.toString(),
					},
				});
			};

			if (!hasBenefit || type !== benefitType.value) return null;

			return (
				<EuiFlexItem key={`pay-structure-value-${benefitKey}`}>
					<EuiFlexGroup
						gutterSize="xs"
						direction="row"
						className="detail"
						justifyContent="spaceBetween"
					>
						<EuiFlexItem grow={false}>
							<p className="type-x-small">{`${benefitLabel}: `}</p>
						</EuiFlexItem>
						<EuiFlexItem grow={false}>
							<OvertimeItem data={data} onChange={handleChangeOvertime} />
						</EuiFlexItem>
					</EuiFlexGroup>
				</EuiFlexItem>
			);
		});
	};

	const renderBenefitNewList = (benefitType) => {
		// Render new benefit information to add
		return benefitInfoList.map((item, index) => {
			const {
				key: benefitKey,
				label: benefitLabel,
				benefitIndex,
				value: type,
			} = item;
			const hasBenefit = benefitIndex !== -1;

			if (!hasBenefit && type === benefitType.value) {
				return (
					<EuiFlexItem
						key={`benefit-structure-empty-${benefitKey}`}
						grow={false}
					>
						<RoundButtonWithPlusIcon
							label={benefitLabel}
							onClick={() => handleClickNewItem(index)}
						/>
					</EuiFlexItem>
				);
			}
			return <div key={`benefit-structure-empty-${benefitKey}`} />;
		});
	};

	return (
		<Wrapper>
			<EuiFlexGroup gutterSize="m" direction="column" alignItems="flexStart">
				<EuiFlexItem className="type-title">
					<p className="type-small-definication-term text-center">
						Annual Benefits
					</p>
				</EuiFlexItem>
				<EuiFlexItem className="content__container">
					{Object.values(BENEFIT_TYPE_STRUCTURE).map((benefitTypeInfo) => (
						<EuiFlexGroup
							gutterSize="s"
							direction="column"
							className="content-item"
						>
							<EuiFlexItem>
								<EuiText size="s" className="title-label">
									{benefitTypeInfo.label}
								</EuiText>
							</EuiFlexItem>
							<EuiFlexItem>
								<EuiFlexGroup gutterSize="xs" direction="column">
									{renderBenefitList(benefitTypeInfo)}
								</EuiFlexGroup>
							</EuiFlexItem>
							<EuiFlexItem>
								<EuiFlexGroup gutterSize="s" direction="row" wrap>
									{renderBenefitNewList(benefitTypeInfo)}
								</EuiFlexGroup>
							</EuiFlexItem>
						</EuiFlexGroup>
					))}
				</EuiFlexItem>
			</EuiFlexGroup>
		</Wrapper>
	);
};

export default CompensationBenifit;

const Wrapper = styled.div`
	display: flex;
	position: relative;
	width: 100%;
	padding: 0 var(--sp-6) 0 0;

	.title-label {
		font-weight: 600;
		text-align: center;
	}

	.type-title {
		color: var(--light-color);
	}

	.detail {
		.value {
			color: var(--eui-link-color);
		}
	}

	.content__container {
		width: 100%;

		.icon-button {
			margin: 0;
		}
		.content-item {
			&:not(:last-child) {
				margin-bottom: 8px;
			}
		}
	}
`;
