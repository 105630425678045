import React, { Component } from "react";
import Error from "./Error";
import { withRouter } from "react-router-dom";
import StackTrace from "stacktrace-js";
import axios from "axios";

class ErrorBoundary extends Component {
    constructor(props) {
        super(props);
        this.state = { hasError: false, version: null, error: null };
    }

    // error catching with class component.
    componentDidCatch(error) {
        if (!error) return;

        if (error.name === "ChunkLoadError") {
            console.log("forcing reload due to chunk load error");
            if (window) window.location.reload();
            return;
        }
        this.setState({
            hasError: true,
            error: `Loading error (0). Please wait...`,
        });

        StackTrace.fromError(error)
            .then(async (err) => {
                const chunkRegex = /\.chunk.js$/;
                const [errDetails] = err;
                const { fileName, lineNumber, columnNumber, functionName } = errDetails;

                if (chunkRegex.test(fileName)) {
                    // It's enough to get into scope the map files for react to detect them.
                    await axios.get(`${fileName}.map`, {
                        onDownloadProgress: ({ loaded }) => {
                            // The user doesn't know what the info inside () is, but it's a visual indication that the empower app
                            // is doing something. This will usually help the user wait and not leave the page sooner.
                            this.setState({
                                error: `Loading error (${(loaded / 1000).toFixed(
                                    0
                                )}). Please wait...`,
                            });
                        },
                    });
                }
                const errMessage = `${error.message}\nin ${fileName}\nat line ${lineNumber}\n at column ${columnNumber}\nin '${functionName}' method`;
                this.setState({ error: errMessage });
                console.log("errMessage: ", errMessage);
            })
            .catch((err) => {
                const errMessage = `${error.message}:\n ${error.stack
                    .split("\n")
                    .slice(0, 4)
                    .join("\n")} \n ----------- \n ${err.message}`;
                this.setState({ error: errMessage });
                console.log("errMessage of err: ", errMessage);
            });
    }

    render() {
        if (this.state.hasError) {
            return <Error error={this.state.error} />;
        }
        return this.props.children;
    }
}

export default withRouter(ErrorBoundary);
