/**
 * Here the name convention is the same with the name convention of the table in database.
 * So here the same name will be used.
 */
/**
 * Models for AMS ( Activity Management System )
 */
const ams_activity_main = {
	_name: "ams_activity_main",
	id: "id",
	activity_creator_account_ref_id: "activity_creator_account_ref_id",
	activity_name: "activity_name",
	activity_type_c: "activity_type_c",
	activity_status_c: "activity_status_c",
	activity_location_c: "activity_location_c",
	activity_view_type_c: "activity_view_type_c",
};

const ams_activity_link = {
	_name: "ams_activity_link",
	id: "id",
	link_to_ams_activity_ref_id: "link_to_ams_activity_ref_id",
	link_from_ams_activity_ref_id: "link_from_ams_activity_ref_id",
	link_type_c: "link_type_c",
};

const ams_activity_support_data = {
	_name: "ams_activity_support_data",
	id: "id",
	ams_activity_main_ref_id: "ams_activity_main_ref_id",
	data_name: "data_name",
	data_value: "data_value",
	data_order: "data_order",
	data_source_c: "data_source_c",
};

const ams_activity_task = {
	_name: "ams_activity_task",
	id: "id",
	ams_activity_main_ref_id: "ams_activity_main_ref_id",
	task_name: "task_name",
	task_due_date: "task_due_date",
	task_status_c: "task_status_c",
	task_priority_c: "task_priority_c",
	task_type_c: "task_type_c",
};

const ams_activity_time = {
	_name: "ams_activity_time",
	id: "id",
	ams_activity_main_ref_id: "ams_activity_main_ref_id",
	date_start: "date_start",
	date_end: "date_end",
};

const ams_activity_reference = {
	_name: "ams_activity_reference",
	id: "id",
	ams_activity_main_ref_id: "ams_activity_main_ref_id",
	reference_category_c: "reference_category_c",
	reference_object_type_sc: "reference_object_type_sc",
	reference_object_subtype_sc: "reference_object_subtype_sc",
	reference_object_record_ref_id: "reference_object_record_ref_id",
};

const ams_activity_reminder = {
	_name: "ams_activity_reminder",
	id: "id",
	ams_activity_task_ref_id: "ams_activity_task_ref_id",
	reminder_type_c: "reminder_type_c",
	reminder_triggered: "reminder_triggered",
	reminder_result_c: "reminder_result_c",
	reminder_time_value: "reminder_time_value",
	reminder_time_type_c: "reminder_time_type_c",
	reminder_time_frame_c: "reminder_time_frame_c",
	reminder_processed: "reminder_processed",
	reminder_details: "reminder_details",
};

const ams_activity_note = {
	_name: "ams_activity_note",
	id: "id",
	ams_activity_main_ref_id: "ams_activity_main_ref_id",
	date_created: "date_created",
	note_text: "note_text",
};

const ams_log_activity = {
	_name: "ams_log_activity",
	id: "id",
	date_created: "date_created",
	account_main_ref_id: "account_main_ref_id",
	log_object_c: "log_object_c",
	log_type_c: "log_type_c",
	log_action_c: "log_action_c",
	record_ref_id: "record_ref_id",
	log_details: "log_details",
};

export {
	ams_activity_main,
	ams_activity_link,
	ams_activity_support_data,
	ams_activity_task,
	ams_activity_time,
	ams_activity_reference,
	ams_activity_reminder,
	ams_activity_note,
	ams_log_activity,
};
