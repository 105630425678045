import React from "react";
import styled from "styled-components";
import {
    EuiFieldText
} from '@elastic/eui';

/**
 * Main Components
 */
const EditTitle = (props) => {

	const {
        title,
        onChangeTitle,
		...restProps
	} = props;

    const handleTitle = (e) => {
        onChangeTitle(e.target.value);
    }

	return (
		<Wrapper {...restProps}>
            <EuiFieldText
                placeholder="Text field (global)"
                value={title ? title : ''}
                onChange={(e) => handleTitle(e)}
            />
		</Wrapper>
	);
};

export default EditTitle;

/**
 * Styled Components
 */
const Wrapper = styled.div`
    width: 100%;
`;
