import { EuiFlexGroup, EuiFlexItem, EuiText } from "@elastic/eui";
import React, { useEffect, useMemo, useState } from "react";
import styled from "styled-components";
import { EuiTabbedContent } from "@elastic/eui";

import { AccordionWithHeader } from "components/Common";

import CmsListViewHeader from "./CmsListItemHeader";
import CmsItem from "./CmsDraftItem";
import CmsLoggedItem from "./CmsLoggedItem";
import { PrevNextButtonsItem } from "../CustomComponents";

/**
 * Main Component for CMS List
 */
const CmsListItem = (props) => {
	const {
		cmsAutomatedList,
		cmsManualList,
		person,
		onSaveRAS,
		onChangeAutomatedListParams,
		onChangeManualListParams,
		onSaveEmail,
		onDeleteEmail,
		onSavePhone,
		onDeletePhone,
		onSaveLink,
		onDeleteLink,
	} = props;

	const [cmsAutomatedDdata, setCmsAutomatedDdata] = useState([]);
	const [cmsManualData, setCmsManualDdata] = useState([]);

	const { cmsAutomatedPage, cmsAutomatedTotalPages, cmsAutomatedIsLoading } =
		useMemo(() => {
			const page = cmsAutomatedList?.pageNumber || 1;
			const pageCount = cmsAutomatedList?.pageCount || 5;
			const totalCount = cmsAutomatedList?.totalCount || 0;
			const totalPages = Math.ceil(totalCount / pageCount);
			const isLoading = cmsAutomatedList?.isRequsting;

			return {
				cmsAutomatedPage: page,
				cmsAutomatedTotalPages: totalPages,
				cmsAutomatedIsLoading: isLoading,
			};
		}, [cmsAutomatedList]);

	const { cmsManualPage, cmsManualTotalPages, cmsManualIsLoading } = useMemo(() => {
		const page = cmsManualList?.pageNumber || 1;
		const pageCount = cmsManualList?.pageCount || 5;
		const totalCount = cmsManualList?.totalCount || 0;
		const totalPages = Math.ceil(totalCount / pageCount);
		const isLoading = cmsManualList?.isRequsting;

		return {
			cmsManualPage: page,
			cmsManualTotalPages: totalPages,
			cmsManualIsLoading: isLoading,
		};
	}, [cmsManualList]);

	const handleChangeAutomatedPageNumber = (newPage) => {
		const params = {
			pageNumber: newPage,
		};
		onChangeAutomatedListParams(params);
	};

	const handleChangeManualPageNumber = (newPage) => {
		const params = {
			pageNumber: newPage,
		};
		onChangeManualListParams(params);
	};

	const handleChangeTab = (tab) => {};

	useEffect(() => {
		setCmsAutomatedDdata(cmsAutomatedList?.data || []);
	}, [cmsAutomatedList]);

	useEffect(() => {
		setCmsManualDdata(cmsManualList?.data || []);
	}, [cmsManualList]);

	const tabs = [
		{
			id: "cms_automated_list",
			name: "Logged",
			content: (
				<div className="content__wrapper">
					{cmsAutomatedDdata?.map((elem, index) => (
						<CmsLoggedItem
							data={elem}
							key={index}
							onSaveRAS={onSaveRAS}
							person={person}
						/>
					))}
					{cmsAutomatedDdata?.length > 0 && (
						<Footer>
							<EuiFlexGroup
								justifyContent="spaceBetween"
								gutterSize="none"
								alignItems="center"
							>
								<EuiFlexItem className="page-number">
									<EuiText size="xs" color="subdued">
										<p>
											{cmsAutomatedPage} of {cmsAutomatedTotalPages}
										</p>
									</EuiText>
								</EuiFlexItem>
								<EuiFlexItem grow={false} className="d-flex">
									<PrevNextButtonsItem
										page={cmsAutomatedPage}
										totalPageCount={cmsAutomatedTotalPages}
										onChange={handleChangeAutomatedPageNumber}
									/>
								</EuiFlexItem>
							</EuiFlexGroup>
						</Footer>
					)}
					{!(cmsAutomatedDdata?.length > 0) ? (
						<div className="no-available-label">
							<span>No available data</span>
						</div>
					) : null}
				</div>
			),
		},
		{
			id: "cms_manual_list",
			name: "Draft",
			content: (
				<div className="content__wrapper">
					{cmsManualData?.map((elem, index) => {
						const data = elem?.cms_communication_draft;
						return (
							<CmsItem
								data={data}
								key={index}
								onSaveRAS={onSaveRAS}
								person={person}
							/>
						);
					})}
					{cmsManualData?.length > 0 && (
						<Footer>
							<EuiFlexGroup
								justifyContent="spaceBetween"
								gutterSize="none"
								alignItems="center"
							>
								<EuiFlexItem className="page-number">
									<EuiText size="xs" color="subdued">
										<p>
											{cmsManualPage} of {cmsManualTotalPages}
										</p>
									</EuiText>
								</EuiFlexItem>
								<EuiFlexItem grow={false} className="d-flex">
									<PrevNextButtonsItem
										page={cmsManualPage}
										totalPageCount={cmsManualTotalPages}
										onChange={handleChangeManualPageNumber}
									/>
								</EuiFlexItem>
							</EuiFlexGroup>
						</Footer>
					)}
					{!(cmsManualData?.length > 0) ? (
						<div className="no-available-label">
							<span>No available data</span>
						</div>
					) : null}
				</div>
			),
		},
	];

	return (
		<Wrapper>
			<AccordionWithHeader
				headerItems={
					<CmsListViewHeader
						title="Communication"
						person={person}
						onSavePhone={onSavePhone}
						onDeletePhone={onDeletePhone}
						onSaveEmail={onSaveEmail}
						onDeleteEmail={onDeleteEmail}
						onSaveLink={onSaveLink}
						onDeleteLink={onDeleteLink}
					/>
				}
				isLoading={cmsAutomatedIsLoading || cmsManualIsLoading}
				isOpen={cmsAutomatedIsLoading || cmsManualIsLoading ? "closed" : "open"}
			>
				<EuiTabbedContent
					tabs={tabs}
					initialSelectedTab={tabs[0]}
					autoFocus="selected"
					onTabClick={handleChangeTab}
				/>
			</AccordionWithHeader>
		</Wrapper>
	);
};

export default CmsListItem;

const Wrapper = styled.div`
	.content__wrapper {
		padding-bottom: var(--sp-4);
	}

	.no-available-label {
		display: flex;
		width: 100%;
		justify-content: center;
		padding: var(--sp-4);
	}
`;

const Footer = styled.div`
	padding: var(--sp-4) var(--sp-4);
`;
