import { SET_ERROR } from "./actionTypes";

const setError = (state, payload) => {
	return {
		...state,
		errors: [...state.errors, payload],
	};
};

const initialState = {
	errors: [],
};

const Reducer = (state = initialState, action) => {
	switch (action.type) {
		case SET_ERROR:
			return setError(state, action.payload);

		default:
			return state;
	}
};

export default Reducer;
