import React from "react";
import styled from "styled-components";
import { EuiCheckbox, EuiFlexGroup, EuiFlexItem, EuiIcon } from "@elastic/eui";
import { NavLink } from "react-router-dom";
import { includes } from "lodash";

import { companyIcon, companyMarkIcon } from "components/Common/Icons";
import { getDate } from "helpers/utilities";
import AmsCompanyContactItem from "components/Common/Ams/AmsEditItem/AmsEditActivityItem/AmsCompanyContactItem";
import * as MODEL from "core/model";

/**
 * Main Components
 */
const GlobalSearchCompany = (props) => {
	const {
		data,
		selectedItems,
		onChangeSeletedItems,
		onGlobalSearch,
		saveCompanyPhone,
		saveCompanyEmail,
		deleteCompany,
	} = props;

	// common functions
	const getCompanySize = (size) => {
		switch (size) {
			case 1:
				return "0 ~ 10";
			case 2:
				return "10 ~ 50";
			case 3:
				return "50 ~ 250";
			case 4:
				return "250 ~ 1,500";
			case 5:
				return "1,500 ~ 10,000";
			case 6:
				return "10,000 ~ ";
			default:
				return "";
		}
	};

	const handleSavePhone = (companyId, data) => {
		saveCompanyPhone(companyId, data, (res) => {
			onGlobalSearch();
		});
	};

	const handleDeletePhone = (phoneId) => {
		const query = {
			[MODEL.person_phone._name]: {
				[MODEL.person_phone.id]: phoneId,
			},
		};
		deleteCompany(query, () => {
			onGlobalSearch();
		});
	};

	const handleSaveEmail = (companyId, data) => {
		saveCompanyEmail(companyId, data, (res) => {
			onGlobalSearch();
		});
	};

	const handleDeleteEmail = (emailId) => {
		const query = {
			[MODEL.person_email._name]: {
				[MODEL.person_email.id]: emailId,
			},
		};
		deleteCompany(query, () => {
			onGlobalSearch();
		});
	};

	const handleChangeSelectedItem = (e, companyId) => {
		const checked = e.target.checked;
		onChangeSeletedItems(companyId, checked);
	};

	if (!data || data?.length <= 0) return null;

	return (
		<Wrapper>
			<EuiFlexGroup>
				<EuiFlexItem className="d-flex">
					<div className="pr-4">{companyIcon()}</div>
					<div className="search-title pr-2">Company</div>
					<div className="search-count">({data.length})</div>
				</EuiFlexItem>
			</EuiFlexGroup>
			{data.map((company, index) => {
				const companyInfo = company?.company_main;
				const {
					id: companyId,
					company_name,
					company_date_founded,
					company_size_c: company_size,
					company_email: emails = [],
					company_phone: phones = [],
				} = companyInfo;

				const emailsContact = [];
				const emailsWork = [];
				if (emails?.length > 0) {
					emails.forEach((email) => {
						const isContactEmail = email.email_type_c === 1;

						if (isContactEmail) {
							emailsContact.push(email);
						} else {
							emailsWork.push(email);
						}
					});
				}

				const phonesContact = [];
				const phonesWork = [];
				if (phones?.length > 0) {
					phones.forEach((phone) => {
						const isContactPhone = phone.phone_type_c === 3;

						if (isContactPhone) {
							phonesContact.push(phone);
						} else {
							phonesWork.push(phone);
						}
					});
				}

				const checked = includes(selectedItems, companyId);

				return (
					<EuiFlexGroup
						key={"global-search-company-" + index}
						gutterSize="s"
						alignItems="center"
					>
						{onChangeSeletedItems && (
							<EuiFlexItem grow={false}>
								<EuiCheckbox
									id={`global-search-company-check-item-` + companyId}
									label=""
									checked={checked}
									onChange={(e) =>
										handleChangeSelectedItem(e, companyId)
									}
								/>
							</EuiFlexItem>
						)}
						<EuiFlexItem>
							<EuiFlexGroup className="search-card" gutterSize="s">
								<EuiFlexItem grow={1} className="d-flex content-center">
									{companyMarkIcon()}
								</EuiFlexItem>
								<EuiFlexItem grow={8}>
									<div className="d-flex">
										<NavLink
											className="pr-2 search-ellipsis"
											to={`/company/${companyId}`}
										>
											{company_name}
										</NavLink>
									</div>
									<div className="d-flex">
										<div className="search-ellipsis">
											<span>
												Founded{" "}
												{getDate(company_date_founded, "YYYY") ||
													"N/A"}
											</span>
											<EuiIcon type="dot" size="s" />
											<span>
												{getCompanySize(company_size) || "N/A "}{" "}
												Employees
											</span>
										</div>
									</div>
								</EuiFlexItem>
								<EuiFlexItem grow={2}>
									<EuiFlexGroup direction="column" gutterSize="xs">
										<EuiFlexItem>
											<AmsCompanyContactItem
												type="contact"
												emails={emailsContact}
												phones={phonesContact}
												onSavePhone={(data) =>
													handleSavePhone(companyId, data)
												}
												onDeletePhone={handleDeletePhone}
												onSaveEmail={(data) =>
													handleSaveEmail(companyId, data)
												}
												onDeleteEmail={handleDeleteEmail}
											/>
										</EuiFlexItem>
										<EuiFlexItem>
											<AmsCompanyContactItem
												type="work"
												emails={emailsWork}
												phones={phonesWork}
												onSavePhone={(data) =>
													handleSavePhone(companyId, data)
												}
												onDeletePhone={handleDeletePhone}
												onSaveEmail={(data) =>
													handleSaveEmail(companyId, data)
												}
												onDeleteEmail={handleDeleteEmail}
											/>
										</EuiFlexItem>
									</EuiFlexGroup>
								</EuiFlexItem>
							</EuiFlexGroup>
						</EuiFlexItem>
					</EuiFlexGroup>
				);
			})}
		</Wrapper>
	);
};

export default GlobalSearchCompany;

/**
 * Styled Components
 */
const Wrapper = styled.div`
	padding-bottom: 16px;
`;
