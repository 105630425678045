import React, { useEffect, useState } from "react";
import styled from "styled-components";
import {
    EuiFlexGroup,
    EuiFlexItem,
    EuiText,
    EuiFieldText,
    EuiButtonIcon,
} from "@elastic/eui";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPen } from "@fortawesome/pro-light-svg-icons";

import variables from "assets/css/variables.scss";

const EditablePersonName = (props) => {
    const { description, favourite, onSaveName, name } = props;

    const [innerName, setInnerName] = useState({
        first: "",
        last: "",
        middle: "",
        nick: "",
    });
    const [editState, setEditState] = useState(false);
    const [hoverState, setHoverState] = useState(false);

    const handleChangeName = (e) => {
        setInnerName({ ...innerName, [e.target.innerName]: e.target.value });
    };

    const handleSave = () => {
        onSaveName(innerName);
    };

    const handleCancel = () => {
        setEditState(false);
    };

    useEffect(() => {
        const { first, last, middle, nick } = name;

        setInnerName({
            first,
            last,
            middle,
            nick,
        });
    }, [name]);

    return (
        <Wrapper
            onMouseEnter={() => !editState && setHoverState(true)}
            onMouseLeave={() => !editState && setHoverState(false)}
        >
            {!editState ? (
                <EuiFlexGroup gutterSize="xs" alignItems="center">
                    <EuiFlexItem grow={false}>
                        <EuiText size="s">{description}</EuiText>
                    </EuiFlexItem>

                    <EuiFlexItem grow={false}>{favourite}</EuiFlexItem>

                    {editState && hoverState ? (
                        <EuiFlexItem grow={false}>
                            <button
                                onClick={() => setEditState(true)}
                                className="icon-button mt-2"
                            >
                                <FontAwesomeIcon
                                    className="icon-styles"
                                    icon={faPen}
                                    style={
                                        editState
                                            ? {
                                                  background: variables.hoveredBtnBgColor,
                                              }
                                            : ""
                                    }
                                />
                            </button>
                        </EuiFlexItem>
                    ) : null}
                </EuiFlexGroup>
            ) : (
                <EuiFlexGroup gutterSize="xs" className="mt-2">
                    <EuiFlexItem grow={false}>
                        <EuiFieldText
                            placeholder="First"
                            innerName="first"
                            value={innerName.first}
                            compressed
                            onChange={(e) => handleChangeName(e)}
                        />
                    </EuiFlexItem>
                    <EuiFlexItem grow={false}>
                        <EuiFieldText
                            placeholder="Middle"
                            innerName="middle"
                            value={innerName.middle}
                            compressed
                            onChange={(e) => handleChangeName(e)}
                        />
                    </EuiFlexItem>
                    <EuiFlexItem grow={false}>
                        <EuiFieldText
                            placeholder="Last"
                            innerName="last"
                            value={innerName.last}
                            compressed
                            onChange={(e) => handleChangeName(e)}
                        />
                    </EuiFlexItem>
                    <EuiFlexItem grow={false}>
                        <EuiFieldText
                            placeholder="(Nickn.."
                            innerName="nick"
                            value={innerName.nick}
                            compressed
                            onChange={(e) => handleChangeName(e)}
                        />
                    </EuiFlexItem>
                    <EuiFlexItem grow={false}>
                        <div className="button-icon-wrapper">
                            <EuiButtonIcon
                                className="ml-0"
                                style={{
                                    color: variables.successColor,
                                }}
                                onClick={handleSave}
                                iconType="check"
                                aria-label="Next"
                            />
                        </div>
                    </EuiFlexItem>
                    <EuiFlexItem grow={false}>
                        <div className="button-icon-wrapper">
                            <EuiButtonIcon
                                className="ml-0"
                                style={{
                                    color: variables.warningColor,
                                }}
                                onClick={handleCancel}
                                iconType="cross"
                                aria-label="Next"
                            />
                        </div>
                    </EuiFlexItem>
                </EuiFlexGroup>
            )}
        </Wrapper>
    );
};

export default EditablePersonName;

const Wrapper = styled.div``;
