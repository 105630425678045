import React, { useMemo, useState } from "react";
import styled from "styled-components";
import { EuiPopover, EuiFlexGroup, EuiFlexItem } from "@elastic/eui";

import { AMS_ACTIVITY_TYPE_LIST } from "../../constants";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

/**
 * Component for AMS Type Item with editable feature
 */
const AmsActivityTypeItem = (props) => {
	const { onChangeType, activityType = 1 } = props;

	const [isOpenPopover, setIsOpenPopover] = useState(false);

	const { amsType } = useMemo(() => {
		const amsType =
			Object.values(AMS_ACTIVITY_TYPE_LIST).find(
				(item) => item.value === activityType
			) || AMS_ACTIVITY_TYPE_LIST.outreach;

		return {
			amsType,
		};
	}, [activityType]);

	const handleClosePopover = () => {
		setIsOpenPopover(false);
	};

	const handleChangeType = (newState) => {
		if (onChangeType) {
			onChangeType(newState);
		}

		setIsOpenPopover(false);
	};

	return (
		<EuiPopover
			isOpen={isOpenPopover}
			button={
				<div onClick={() => setIsOpenPopover(true)} className="cursor-pointer">
					<TypeListItem
						gutterSize="s"
						alignItems="center"
						className="cursor-pointer selected-item type-small-default"
						onClick={() => handleChangeType(amsType.value)}
					>
						<EuiFlexItem grow={false} className="type-icon">
							<FontAwesomeIcon icon={amsType.icon} />
						</EuiFlexItem>
						<EuiFlexItem>{amsType.label}</EuiFlexItem>
					</TypeListItem>
				</div>
			}
			closePopover={handleClosePopover}
			panelStyle={{
				width: 120,
				padding: "0.5rem 0.75rem",
			}}
			style={{
				textAlign: "center",
			}}
		>
			<EuiFlexGroup direction="column" gutterSize="s">
				{Object.values(AMS_ACTIVITY_TYPE_LIST).map((item) => {
					return (
						<EuiFlexItem key={`ams-activity-type-item-${item.value}`}>
							<TypeListItem
								gutterSize="s"
								alignItems="center"
								className="cursor-pointer"
								onClick={() => handleChangeType(item.value)}
							>
								<EuiFlexItem grow={false} className="type-icon">
									<FontAwesomeIcon icon={item.icon} />
								</EuiFlexItem>
								<EuiFlexItem>{item.label}</EuiFlexItem>
							</TypeListItem>
						</EuiFlexItem>
					);
				})}
			</EuiFlexGroup>
		</EuiPopover>
	);
};

export default AmsActivityTypeItem;

const TypeListItem = styled(EuiFlexGroup)`
	&.selected-item {
		color: var(--eui-link-color);
		font-size: var(--sp-7);
		line-height: var(--sp-12);
	}

	.type-icon {
		width: var(--sp-7);

		svg {
			margin: 0 auto;
		}
	}
`;
