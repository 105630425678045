import dashboard from "./dashboardService";
import account from "./accountService";
import person from "./personService";
import job from "./jobService";
import company from "./companyService";
import global from "./globalService";
import ras from "./rasService";
import qualifier from "./qualifierService";
import campaign from "./campaignService";
import sit from "./sitService";
import sourcing from "./sourcingService";
import credential from "./credentialService";
import template from "./templateService";
import theme from "./themeService";
import workflow from "./workflowService";
import security from "./securityService";
import ams from "./amsService";
import cms from "./cmsService";
import macro from "./macroService";
import globalSearch from "./globalSearchService";
import compensation from "./compensationService";

const ApiService = {
	dashboard,
	account,
	person,
	job,
	company,
	global,
	ras,
	qualifier,
	campaign,
	sit,
	sourcing,
	credential,
	template,
	theme,
	workflow,
	security,
	ams,
	cms,
	macro,
	globalSearch,
	compensation,
};

export default ApiService;
