import React from "react";
import { EuiFlexItem, EuiFlexGroup } from "@elastic/eui";
import { get, orderBy } from "lodash";

import { ScreeningFlyoutEdit, SITItem } from "components/Common";
import * as MODEL from "core/model";

/**
 * Main components for SIT
 */
const SIT = (props) => {
	const { sitType, title, sits, onEditSIT, onChangeObjectSitType, onDeleteItem } =
		props;

	const handleDeleteItem = (id) => {
		onDeleteItem(id);
	};

	const handleChangeObjectSitType = (id, sitTypeC) => {
		onChangeObjectSitType(id, sitTypeC);
	};

	const renderSIT = () => {
		const sortedSits = orderBy(
			sits,
			["sit_focus_gc", `${MODEL.sit_main._name}.${MODEL.sit_main.sit_name}`],
			["desc", "asc"]
		);
		return sortedSits?.map((sit, id) => {
			const objectSitType = get(sit, `sit_focus_gc`, null);
			const objectId = get(sit, `id`, null);
			const sitMainInfo = get(sit, `${MODEL.sit_main._name}`, null);
			const sitTypeC = get(sitMainInfo, `${MODEL.sit_main.sit_type_c}`, null);
			const sitName = get(sitMainInfo, `${MODEL.sit_main.sit_name}`, "");

			if (sitTypeC === sitType) {
				return (
					<EuiFlexItem grow={false} key={`${sit["id"]}-${sitType}-${id}`}>
						<SITItem
							className="badge--item"
							label={sitName || ""}
							objectSitType={objectSitType}
							onChangeObjectSitType={(sitTypeC) =>
								handleChangeObjectSitType(objectId, sitTypeC)
							}
							onDelete={() => handleDeleteItem(objectId)}
						/>
					</EuiFlexItem>
				);
			}

			return null;
		});
	};

	const handleEdit = () => {
		onEditSIT(sitType);
	};

	return (
		<ScreeningFlyoutEdit title={title} onEditHandler={handleEdit}>
			<EuiFlexGroup direction="row" wrap={true} gutterSize="s">
				{renderSIT()}
			</EuiFlexGroup>
		</ScreeningFlyoutEdit>
	);
};

export default SIT;
