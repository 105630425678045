/**
 * Campaign
 */
export const GET_CAMPAIGNS = "@@campaigns/GET_CAMPAIGNS";
export const SET_CAMPAIGNS = "@@campaigns/SET_CAMPAIGNS";
export const GET_CAMPAIGN = "@@campaigns/GET_CAMPAIGN";
export const SET_CAMPAIGN = "@@campaigns/SET_CAMPAIGN";
export const GET_WORKFLOWS = "@@campaigns/GET_WORKFLOWS";
export const SET_WORKFLOWS = "@@campaigns/SET_WORKFLOWS";
export const SET_WORKFLOW_TEMPLATE_LIST = "@@campaigns/SET_WORKFLOW_TEMPLATE_LIST";
export const GET_WORKFLOW = "@@campaigns/GET_WORKFLOW";
export const SET_WORKFLOW = "@@campaigns/SET_WORKFLOW";
export const SET_WORKFLOW_TEMPLATE = "@@campaigns/SET_WORKFLOW_TEMPLATE";
export const SAVE_WORKFLOW = "@@campaigns/SAVE_WORKFLOW";
export const GET_TEMPLATES = "@@campaigns/GET_TEMPLATES";
export const GET_TEMPLATE = "@@campaigns/GET_TEMPLATE";
export const SAVE_CAMPAIGNS = "@@campaigns/SAVE_CAMPAIGNS";
export const DELETE_CAMPAIGNS = "@@campaigns/DELETE_CAMPAIGNS";
export const SAVE_TEMPLATE = "@@campaigns/SAVE_TEMPLATE";
export const SAVE_WORKFLOW_CONFIG = "@@campaigns/SAVE_WORKFLOW_CONFIG";
export const INITIALIZE_CAMPAIGN_SCHEDULE = "@@campaigns/INITIALIZE_CAMPAIGN_SCHEDULE";
export const EXECUTE_CAMPAIGN_REQUEST = "@@campaigns/EXECUTE_CAMPAIGN_REQUEST";
export const EXECUTE_CAMPAIGN_SUCCESS = "@@campaigns/EXECUTE_CAMPAIGN_SUCCESS";
export const EXECUTE_CAMPAIGN_FAIL = "@@campaigns/EXECUTE_CAMPAIGN_FAIL";
export const EXECUTE_CAMPAIGN_UPDATE_REQUEST =
	"@@campaigns/EXECUTE_CAMPAIGN_UPDATE_REQUEST";
export const EXECUTE_CAMPAIGN_UPDATE_SUCCESS =
	"@@campaigns/EXECUTE_CAMPAIGN_UPDATE_SUCCESS";
export const EXECUTE_CAMPAIGN_UPDATE_FAIL = "@@campaigns/EXECUTE_CAMPAIGN_UPDATE_FAIL";
export const CANCEL_CAMPAIGN_REQUEST = "@@campaigns/CANCEL_CAMPAIGN_REQUEST";
export const CANCEL_CAMPAIGN_SUCCESS = "@@campaigns/CANCEL_CAMPAIGN_SUCCESS";
export const CANCEL_CAMPAIGN_FAIL = "@@campaigns/CANCEL_CAMPAIGN_FAIL";
export const COPY_CAMPAIGN_REQUEST = "@@campaigns/COPY_CAMPAIGN_REQUEST";
export const COPY_CAMPAIGN_SUCCESS = "@@campaigns/COPY_CAMPAIGN_SUCCESS";
export const COPY_CAMPAIGN_FAIL = "@@campaigns/COPY_CAMPAIGN_FAIL";

/* --------------------- NEW -------------------- */
// Campaign List for all properties
export const GET_CAMPAIGN_LIST_NEW = "@@campaigns/GET_CAMPAIGN_LIST_NEW";
export const SET_CAMPAIGN_LIST_NEW = "@@campaigns/SET_CAMPAIGN_LIST_NEW";
export const REQUEST_CAMPAIGN_LIST_NEW = "@@campaigns/REQUEST_CAMPAIGN_LIST_NEW";
export const RECEIVED_CAMPAIGN_LIST_NEW = "@@campaigns/RECEIVED_CAMPAIGN_LIST_NEW";
export const SET_CAMPAIGN_LIST_PARAMS = "@@campaigns/SET_CAMPAIGN_LIST_PARAMS";

// Campaign Main Table
export const GET_CAMPAIGN_MAINLIST_NEW = "@@campaigns/GET_CAMPAIGN_MAINLIST_NEW";
export const SET_CAMPAIGN_MAINLIST_NEW = "@@campaigns/SET_CAMPAIGN_MAINLIST_NEW";
export const SET_ALL_CAMPAIGN_MAINLIST = "@@campaigns/SET_ALL_CAMPAIGN_MAINLIST";
export const REQUEST_CAMPAIGN_MAINLIST_NEW = "@@campaigns/REQUEST_CAMPAIGN_MAINLIST_NEW";
export const RECEIVED_CAMPAIGN_MAINLIST_NEW =
	"@@campaigns/RECEIVED_CAMPAIGN_MAINLIST_NEW";

// Campaign Details
export const GET_CAMPAIGN_DETAIL_NEW = "@@campaigns/GET_CAMPAIGN_DETAIL_NEW";
export const SET_CAMPAIGN_DETAIL_NEW = "@@campaigns/SET_CAMPAIGN_DETAIL_NEW";

// Workflow Items List for a campaign
export const GET_CAMPAIGN_WORKFLOW_ITEMS_LIST =
	"@@campaigns/GET_CAMPAIGN_WORKFLOW_ITEMS_LIST";
export const SET_CAMPAIGN_WORKFLOW_ITEMS_LIST =
	"@@campaigns/SET_CAMPAIGN_WORKFLOW_ITEMS_LIST";
export const REQUEST_CAMPAIGN_WORKFLOW_ITEMS_LIST =
	"@@campaigns/REQUEST_CAMPAIGN_WORKFLOW_ITEMS_LIST";
export const RECEIVED_CAMPAIGN_WORKFLOW_ITEMS_LIST =
	"@@campaigns/RECEIVED_CAMPAIGN_WORKFLOW_ITEMS_LIST";
export const SET_CAMPAIGN_WORKFLOW_ITEMS_PARAMS =
	"@@campaigns/SET_CAMPAIGN_WORKFLOW_ITEMS_PARAMS";
export const SET_WORKFLOW_ITEM_COUNTS = "@@campaigns/SET_WORKFLOW_ITEM_COUNTS";

// Person List for a campaign
export const GET_CAMPAIGN_PERSON_LIST = "@@campaigns/GET_CAMPAIGN_PERSON_LIST";
export const SET_CAMPAIGN_PERSON_LIST = "@@campaigns/SET_CAMPAIGN_PERSON_LIST";
export const REQUEST_CAMPAIGN_PERSON_LIST = "@@campaigns/REQUEST_CAMPAIGN_PERSON_LIST";
export const RECEIVED_CAMPAIGN_PERSON_LIST = "@@campaigns/RECEIVED_CAMPAIGN_PERSON_LIST";
export const SET_CAMPAIGN_PERSON_LIST_PARAMS =
	"@@campaigns/SET_CAMPAIGN_PERSON_LIST_PARAMS";

// Job Main List for creating a campaign
export const SET_CAMPAIGN_CREATE_JOB_LIST = "@@campaigns/SET_CAMPAIGN_CREATE_JOB_LIST";

// Workflow Summary
export const SET_WORKFLOW_SUMMARY = "@@campaigns/SET_WORKFLOW_SUMMARY";
export const SET_WORKFLOW_NODE_DETAILS = "@@campaigns/SET_WORKFLOW_NODE_DETAILS";

// campaign List by per person
export const CAMPAIGN_LIST_BY_PERSON = "@@campaigns/CAMPAIGN_LIST_BY_PERSON";
export const CAMPAIGN_LIST_BY_SEARCH = "@@campaigns/CAMPAIGN_LIST_BY_SEARCH";
