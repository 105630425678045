import React, { useState } from "react";
import styled from "styled-components";
import { EuiButtonEmpty, EuiPopover, EuiFlexItem, EuiFlexGroup } from "@elastic/eui";

/**
 * Constants
 */
const DEFAULT_PANEL_WIDTH = 107;

/**
 * Component for Editable Select
 *
 * options = [{
 * 		id,
 * 		value,
 * 		label,
 * }]
 *
 * labelWrapper = true - span, false - none
 */
const EditableSelect = (props) => {
	const {
		options,
		value,
		onChange,
		prepend,
		append,
		labelStyle,
		disabled = false,
		hasUnderline = false,
		panelWidth,
		panelStyle = {},
		hasArrow = true,
		hasLabelWrapper = false,
		...restProps
	} = props;

	const optionItem = options?.find((option) => option.value === value);

	const [isOpenPopover, setIsOpenPopover] = useState(false);

	const handleOpenPopover = () => {
		if (!!options?.length) {
			setIsOpenPopover(true);
		}
	};

	const handleClosePopover = () => {
		setIsOpenPopover(false);
	};

	const handleChangeValue = (item) => {
		if (onChange) {
			onChange(item.value);
		}
		handleClosePopover();
	};

	return (
		<Wrapper {...restProps}>
			<Container
				button={
					<div
						className={`button-wrapper ${hasUnderline && "underline"} ${
							disabled && "disabled"
						}`}
					>
						<EuiButtonEmpty
							className="button-fit-content"
							onClick={handleOpenPopover}
							disabled={disabled}
						>
							<p
								style={{
									lineHeight: "var(--sp-9)",
									...(labelStyle ? labelStyle : {}),
								}}
							>
								{prepend ? prepend : ""}
								{optionItem?.label || "-"}
							</p>
						</EuiButtonEmpty>
						<span className="append-text">{append ? append : ""}</span>
					</div>
				}
				isOpen={isOpenPopover}
				closePopover={handleClosePopover}
				panelPaddingSize="s"
				anchorPosition="downCenter"
				panelStyle={{
					width: panelWidth ? panelWidth : DEFAULT_PANEL_WIDTH,
					minWidth: 0,
					...panelStyle,
				}}
				hasArrow={hasArrow}
			>
				<EuiFlexGroup direction="column" gutterSize="s">
					<EuiFlexItem>
						<EuiFlexGroup gutterSize="s" direction="column">
							{options?.map((option, index) => {
								const isSeletecItem = option.value === value;
								const disabled = option.disabled;
								return (
									<SelectItem
										key={`select-item-${option.id}-${index}`}
										className={`select-item-wrapper ${
											disabled ? "disabled" : ""
										}`}
									>
										{hasLabelWrapper ? (
											option.label
										) : (
											<span
												className={isSeletecItem && "selected"}
												onClick={() =>
													!disabled && handleChangeValue(option)
												}
											>
												{option.label}
											</span>
										)}
									</SelectItem>
								);
							})}
						</EuiFlexGroup>
					</EuiFlexItem>
				</EuiFlexGroup>
			</Container>
		</Wrapper>
	);
};

export default EditableSelect;

const Wrapper = styled.div`
	display: flex;
	position: relative;

	.euiPopover__panel {
		min-width: 0 !important;
	}

	.button-wrapper {
		display: flex;
		align-items: flex-start;

		&.underline {
			border-bottom: 1px solid var(--eui-link-color);

			&.disabled {
				border-bottom: 1px solid var(--eui-text-disabled-color);
			}
		}

		.append-text {
			color: var(--link-color);
			margin-left: var(--sp-1);
		}

		&.disabled {
			.append-text {
				color: var(--eui-text-disabled-color);
			}
		}
	}

	.euiButtonEmpty.euiButtonEmpty--primary {
		text-decoration: none !important;
	}
`;

const Container = styled(EuiPopover)`
	display: flex !important;
	align-items: center;
	justify-content: center;
`;

const SelectItem = styled(EuiFlexItem)`
	cursor: pointer;

	&:hover,
	span.selected {
		color: var(--link-color);
		text-decoration: underline;
	}

	&.disabled {
		cursor: default;

		&:hover,
		span {
			color: var(--eui-disabled-text-color);
			text-decoration: none;
		}
	}
`;
