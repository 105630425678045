import React from "react";
import styled from "styled-components";
import {
	EuiTextArea,
} from "@elastic/eui";

/**
 * Main Components
 */
const PersonJobSummary = (props) => {

	const {
		value,
		onChangeValue,
		...restProps
	} = props; 

	const handleValue = (e) => {
		onChangeValue(e.target.value);
	}

	return (
		<Wrapper {...restProps}>
			<div className="sub-header">Summary</div>
			<EuiTextArea
				placeholder="Textarea (placeholder)"
				aria-label="job-summary-textarea"
				value={value}
				onChange={(e) => handleValue(e)}
			/>
		</Wrapper>
	);
};

export default PersonJobSummary;

/**
 * Styled Components
 */
const Wrapper = styled.div`
	display: flex;
	width: 100%;
	flex-direction: column;

	.euiTextArea {
		max-width: 100%;
	}
	.icon-button {
		margin: auto 0 0 0.5rem;
	}
`;
