import API from "api";
import { sourcing_main } from "core/model";
import { makeSort, makeFieldContent } from "./commonService";

/** ---------------- Generic API ---------------------- */
const updateSourcing = (data) => {
	const request = {
		object: "sourcing",
		data: data || {},
	};

	return API().GenericAPI().updateTableData(request);
};

const deleteSourcing = (data) => {
	const query = {
		object: "sourcing",
		...data,
	};

	return API().GenericAPI().deleteTableData(query);
};

const getSourcingMainList = ({
	searchTerm = "",
	pageNumber = 1,
	pageCount = 30,
	params = null,
}) => {
	const query = {
		page_number: pageNumber,
		page_count: pageCount,
		table_main: sourcing_main._name,
		fields: [
			makeFieldContent(sourcing_main, sourcing_main.id),
			makeFieldContent(sourcing_main, sourcing_main.sourcing_name),
			makeFieldContent(sourcing_main, sourcing_main.sourcing_type_c),
			makeFieldContent(sourcing_main, sourcing_main.sourcing_status_c),
			makeFieldContent(sourcing_main, sourcing_main.sourcing_owner_account_ref_id),
			makeFieldContent(sourcing_main, sourcing_main.sourcing_threshold),
			makeFieldContent(sourcing_main, sourcing_main.sourcing_max_records),
			makeFieldContent(sourcing_main, sourcing_main.sourcing_result_c),
			makeFieldContent(sourcing_main, sourcing_main.sourcing_result_value),
			makeFieldContent(sourcing_main, sourcing_main.default_credential_ref_id),
		],
		sort: [makeSort(sourcing_main, sourcing_main.sourcing_name)],
		search_fields: [
			{
				table: sourcing_main._name,
				field: sourcing_main.id,
			},
			{
				table: sourcing_main._name,
				field: sourcing_main.job_name,
			},
		],
		search_type: "term",
		search_term: searchTerm,
		...(params ? { ...params } : {}),
	};

	return API().GenericAPI().getTableData(query);
};

const getSourcingDetail = ({ id }) => {
	const query = {
		object: "sourcing",
		record_main_id: id,
	};

	return API().GenericAPI().getMainData(query);
};

/** --------------------------------------------------------- */

/** ----------------------- Custom API ---------------------- */
const getSourcingList = (params) => {
	const query = {
		page_number: params.page_number || 1,
		page_count: params.page_count || 30,
		totalCount: 0,
		data: [],
		search_term: params.search_term || undefined,
		sourcing_owner_account_id: params.sourcing_owner_account_id,
		...(params?.sort ? { sort: params.sort } : {}),
	};

	return API().Sourcing().getSourcingList(query);
};

const getSourcingPeopleList = (params) => {
	const query = params;

	return API().Sourcing().getSourcingPeopleList(query);
};

const getSourcingPeopleIdList = (data) => {
	const query = {
		...data,
		array_only: true,
	};
	return API().Sourcing().getSourcingPeopleList(query);
};
const getSourcingProcessProgress = (params) => {
	return API().Sourcing().getSourcingProcess(params);
};

/* ---------------------------------------------------------- */
export default {
	updateSourcing,
	getSourcingList,
	getSourcingPeopleList,
	getSourcingPeopleIdList,
	getSourcingMainList,
	getSourcingDetail,
	deleteSourcing,
	getSourcingProcessProgress,
};
