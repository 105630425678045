import React, { useEffect, useState } from "react";
import { EuiLoadingContent, EuiTextArea } from "@elastic/eui";

import { objectChecker } from "helpers/utilities";
import { ScreeningFlyoutEdit, EditControls } from "components/Common";

import {
	_getLoaderPersonSITSummary,
	_getPersonInfo,
	_getSITMode,
} from "helpers/selectors";

const ScreeningSummary = (props) => {
	const isLoading = _getLoaderPersonSITSummary();
	const personDetails = _getPersonInfo();
	const sitMode = _getSITMode();

	const [isEdited, setIsEdited] = useState(false);
	const [summary, setSummary] = useState("");

	// This function picks the first summary and extracts the details.
	// Had to make this function because in the API it has multiple returns,
	// but we only utilize 1 of them. So we just update 1 lol.
	const getFirstSummary = (data) => {
		let firstSummary = objectChecker(data, ["details", "summary"]);

		if (firstSummary) {
			if (firstSummary.length > 0) {
				return firstSummary[0]["summary_text"];
			}
		}

		return "";
	};

	const handleSave = (e) => {
		console.log("handle Save Screening Summary");
	};

	const handleCancel = (e) => {
		// Restore summary to it's original value.
		setSummary(getFirstSummary(personDetails));
		setIsEdited(!isEdited);
	};

	useEffect(() => {
		setSummary(getFirstSummary(personDetails));
	}, [personDetails]);

	const renderEdit = () => {
		return (
			<div
				style={{
					padding: "0",
					margin: "0",
				}}
			>
				<EuiTextArea
					fullWidth
					placeholder="Summary"
					aria-label="Use aria labels when no actual label is in use"
					value={summary}
					onChange={(e) => setSummary(e.target.value)}
				/>

				<EditControls
					handleCancel={(e) => handleCancel()}
					setEditClicked={(e) => setIsEdited(!isEdited)}
					onSaveHandler={handleSave}
				/>
			</div>
		);
	};

	const renderSummary = () => {
		return isLoading ? <EuiLoadingContent lines={2} /> : summary;
	};

	const renderDisplay = () => {
		if (sitMode === "Person") {
			return (
				<ScreeningFlyoutEdit
					title={"Summary"}
					onEditHandler={(e) => setIsEdited(!isEdited)}
				>
					{isEdited ? renderEdit() : renderSummary()}
				</ScreeningFlyoutEdit>
			);
		}

		if (sitMode === "Job") {
			// We have no summary for job, so return a null display
			return null;
		}

		return null;
	};

	return renderDisplay();
};

export default ScreeningSummary;
