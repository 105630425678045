import * as actionTypes from "./actionTypes";

/**
 * Functions
 */
const setTemplatesByAccount = (state, payload) => {
	return {
		...state,
		templatesByAccount: payload,
	};
};

const setTemplatesByCredential = (state, payload) => {
	return {
		...state,
		templatesByCredential: payload,
	};
};

/**
 * Initial State
 */
const initialState = {
	templatesByAccount: null,
	templatesByCredential: null,
};

/**
 * Reducer
 */
const Reducer = (state = initialState, action) => {
	switch (action.type) {
		case actionTypes.TEMPLATE_BY_ACCOUNT:
			return setTemplatesByAccount(state, action.payload);
		case actionTypes.TEMPLATE_BY_CREDENTIAL:
			return setTemplatesByCredential(state, action.payload);
		default:
			return state;
	}
};

export default Reducer;
