import React, { useState, useEffect } from "react";
import styled from "styled-components";

import {
	EuiFieldText,
	EuiCheckbox,
	EuiFlexGroup,
	EuiFlexItem,
} from "@elastic/eui";
import { get } from "lodash";

import * as MODEL from "core/model";
import { SaveCancelBtn } from "components/Common";
import { getLinkInfoFromUrl } from "components/global/utils";

/**
 * Main Components
 */
const EditLinkItem = (props) => {
	const { link, linkPrimary, onSave, onCancel, className } = props;

	// state variable
	const [primary, setPrimary] = useState(false);
	const [data, setData] = useState(null);

	const handleLinkPrimary = (e) => {
		setPrimary(e.target.checked);
	};

	const handleLinkVal = (e) => {
		const _data = {
			...data,
			global_url_details: {
				...data?.global_url_details,
				url_value: e.target.value,
			},
		};

		setData(_data);
	};

	const handleSave = () => {
		const url = get(
			data,
			`${MODEL.global_url_details._name}.${MODEL.global_url_details.url_value}`
		);

		if (url) {
			const urlTypeInfo = getLinkInfoFromUrl(url);
			const params = {
				...data,
				global_link_type_c: urlTypeInfo.linkType.value,
			};

			onSave(params, primary);
		}
	};

	const handleCancel = () => {
		onCancel();
	};

	useEffect(() => {
		setPrimary(linkPrimary);
	}, [linkPrimary]);

	useEffect(() => {
		setData(link);
	}, [link]);

	const linkDetail = data?.global_url_details;

	return (
		<Wrapper className={className}>
			<EuiFlexGroup gutterSize="m" direction="column">
				<EuiFlexItem>
					<div className="setting__container">
						<EuiFieldText
							placeholder="Link"
							name="link"
							value={linkDetail?.url_value ? linkDetail?.url_value : ""}
							onChange={(e) => handleLinkVal(e)}
							className="link-input"
						/>
						<EuiCheckbox
							id={"extension-link"}
							checked={primary}
							onChange={(e) => handleLinkPrimary(e)}
							className="link-primary"
						/>
					</div>
				</EuiFlexItem>
				<EuiFlexItem>
					<SaveCancelBtn
						onChangeSave={handleSave}
						onChangeCancel={handleCancel}
					/>
				</EuiFlexItem>
			</EuiFlexGroup>
		</Wrapper>
	);
};

export default EditLinkItem;

/**
 * Styled Components
 */
const Wrapper = styled.div`
	display: flex;

	.setting__container {
		display: flex;

		.euiFormControlLayout {
			height: fit-content;
		}
	}

	.link-input {
		border-radius: var(--sp-2) var(--sp-2) var(--sp-2) var(--sp-2);
	}

	.euiSuperSelectControl {
		height: var(--sp-24);
		line-height: var(--sp-27);
		padding-right: var(--sp-24);
		padding-left: var(--sp-5);
	}

	.euiFormControlLayoutIcons--right {
		right: var(--sp-5);
	}

	.link-primary {
		margin-top: auto;
		margin-bottom: auto;
		margin-left: 0.3rem;
	}

	.euiCheckbox .euiCheckbox__input + .euiCheckbox__square {
		border-radius: 50%;
	}
`;
