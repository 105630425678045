import React from "react";
import styled from "styled-components";
import { EuiButtonEmpty, EuiButton } from "@elastic/eui";

/**
 * Constants
 */
const PAGE_MIN_VALUE = 1;

/**
 * Component for Prev/Next buttons
 * This component is used in footer of Accodian
 *
 * In props,
 * page: current page number,           default: 1, start from PAGE_MIN_VALUE
 * totalPageCount: total page count,    default: 1, start from 1
 * onChange: event handler for prev and next buttons
 */
const PrevNextButtonsItem = (props) => {
	const { page = PAGE_MIN_VALUE, totalPageCount = 1, onChange } = props;

	const disabledPrevButton = totalPageCount <= 0 || page <= PAGE_MIN_VALUE;
	const disabledNextButton = totalPageCount <= 0 || totalPageCount <= page;

	const handleClickPrev = () => {
		const prevPage = Math.max(page - 1, PAGE_MIN_VALUE);
		onChange(prevPage);
	};

	const handleClickNext = () => {
		const nextPage = Math.min(page + 1, totalPageCount);
		onChange(nextPage);
	};

	return (
		<Wrapper>
			<EuiButtonEmpty
				size="s"
				disabled={disabledPrevButton}
				onClick={handleClickPrev}
			>
				Prev
			</EuiButtonEmpty>
			<EuiButton
				style={{ minWidth: "unset" }}
				disabled={disabledNextButton}
				size="s"
				onClick={handleClickNext}
			>
				Next
			</EuiButton>
		</Wrapper>
	);
};

export default PrevNextButtonsItem;

const Wrapper = styled.div``;
