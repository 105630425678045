import React from "react";
import { EuiDatePicker, EuiButtonEmpty } from "@elastic/eui";
import PropTypes from "prop-types";
import moment from "moment";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCalendarAlt } from "@fortawesome/pro-light-svg-icons";

import { getDate, isValidDate } from "helpers/utilities";

// Button for displaying date
const DateButton = (props) => {
	const { value, onClick, typeDate } = props;

	return (
		<EuiButtonEmpty className="button-fit-content p-0" onClick={onClick}>
			{value ? (
				<p className="date-picker-item-label">{getDate(value, typeDate)}</p>
			) : (
				<FontAwesomeIcon icon={faCalendarAlt} size="lg" />
			)}
		</EuiButtonEmpty>
	);
};

DateButton.propTypes = {
	onClick: PropTypes.func,
	value: PropTypes.string,
	typeDate: PropTypes.string,
};

/**
 * Component for Date Picker with editable feature
 */
const AmsDatePickerItem = (props) => {
	const { value, typeDate = "MMM D, YYYY @ h:mma", onChange, isEdit = true } = props;

	const curDate = value && isValidDate(value) ? moment(new Date(value)) : null;

	const handleChange = (newDate) => {
		const formattedDate = newDate.format("YYYY-MM-DD H:mm:ss");
		onChange(formattedDate);
	};

	if (isEdit) {
		return (
			<EuiDatePicker
				selected={curDate}
				onChange={handleChange}
				showTimeSelect
				customInput={<DateButton typeDate={typeDate} />}
				popoverPlacement="top"
				popperProps={{
					innerRef: (ref) => {
						if (ref) {
							ref.style.zIndex = "9999";
						}
					},
				}}
			/>
		);
	} else {
		return <DateButton typeDate={typeDate} value={value} />;
	}
};

export default AmsDatePickerItem;
