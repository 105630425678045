import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { useHistory } from "react-router";

import { SVGIcon } from "components/Common";
import { checkIcon, matchIcon } from "components/Common/Icons/Icons";

import StaticItemBase from "../StaticItemBase";
import AddressSettingBar from "./PhoneSettingBar";

import { PHONE_STATUS_OPTIONS } from "./constants";
import { useGlobal } from "core/useHooks";
import { EuiPopover, EuiText, EuiLink } from "@elastic/eui";
import { getGSSValue } from "components/global/utils";
import {
	GLOBAL_EMAIL_PHONE_RANGES,
	GLOBAL_PHONE_TYPE_OPTIONS,
} from "components/global/constants";
/**
 * Main Components
 */
const StaticPhoneItem = (props) => {
	const {
		personId = null,
		phone,
		isPrimary = false,
		hasIcon = true,
		onEdit,
		onDelete,
		onValidate,
		onChangeStatus,
		onCallPhone,
		disabled = false,
	} = props;
	console.log("personId: ", personId);

	const history = useHistory();

	const { gssList, getMatchGlobalRecords } = useGlobal();

	const [isPopoverOpen, setIsPopoverOpen] = useState(false);
	const [matchedPerson, setMatchedPerson] = useState(null);

	useEffect(() => {
		if (phone?.global_phone_details && gssList?.length > 0) {
			const phoneDetail = phone?.global_phone_details;
			const query = [
				{
					source_base_type_sc: getGSSValue(gssList, "OBJECT_BASE", "PERSON"),
					source_record_id: Number(personId),
					global_phone_details: {
						phone_number:
							"+" +
							phoneDetail?.phone_country_code +
							phoneDetail?.phone_number,
					},
				},
			];

			getMatchGlobalRecords(query, (res) => {
				if (res?.person_main?.[0]) {
					const person = res?.person_main?.[0];
					setMatchedPerson({
						id: person?.id,
						fullName: `${person?.name_first} ${person?.name_last}`,
					});
				} else {
					setMatchedPerson(null);
				}
			});
		}
	}, [phone, gssList]);

	/** Icon for Phone Type - default: Home Phone icon */
	const phoneTypeInfo =
		GLOBAL_PHONE_TYPE_OPTIONS.find((item) => item.value === phone?.phone_type_c) ||
		GLOBAL_PHONE_TYPE_OPTIONS[0];
	const rangeInfo = GLOBAL_EMAIL_PHONE_RANGES.find(
		(item) => item.value === phoneTypeInfo.range
	);

	/** Icon for Phone Status - default: Invalid Phone icon */
	const iconPhoneStatus = PHONE_STATUS_OPTIONS.find(
		(item) => item.value === phone?.global_phone_details?.phone_status_c
	)?.icon;

	// functions
	const onButtonClick = () => {
		setIsPopoverOpen((isPopoverOpen) => !isPopoverOpen);
	};

	const closePopover = () => {
		setIsPopoverOpen(false);
	};

	const gotoPersonDetail = () => {
		history.push(`/person/${matchedPerson?.id}`);
	};

	/** Rendering */
	if (!phone) {
		return null;
	}

	const renderIcons = () => {
		if (!(phone && iconPhoneStatus) && !isPrimary && !matchedPerson) {
			return null;
		}
		return (
			<>
				{phone && iconPhoneStatus ? (
					<div className="status-icon">{iconPhoneStatus}</div>
				) : null}
				{isPrimary && <div className="check-icon">{checkIcon()}</div>}
				{matchedPerson && (
					<EuiPopover
						button={
							<div
								className="check-icon cursor-pointer"
								onClick={onButtonClick}
							>
								{matchIcon()}
							</div>
						}
						isOpen={isPopoverOpen}
						closePopover={closePopover}
						anchorPosition="rightCenter"
						size="s"
						panelPaddingSize="s"
						display="block"
					>
						<PopoverContainer>
							<EuiText size="s">
								Matches:&nbsp;
								<EuiLink onClick={gotoPersonDetail}>
									{matchedPerson?.id}-{matchedPerson?.fullName}
								</EuiLink>
							</EuiText>
						</PopoverContainer>
					</EuiPopover>
				)}
			</>
		);
	};

	return (
		<Wrapper>
			<StaticItemBase
				extraSetting={
					<AddressSettingBar
						onEdit={onEdit}
						onDelete={onDelete}
						onValidate={onValidate}
						onChangeStatus={onChangeStatus}
						onCallPhone={onCallPhone}
					/>
				}
				iconGroups={renderIcons()}
				disabled={disabled}
			>
				{hasIcon ? (
					<div className="icon--wrapper">
						{rangeInfo?.icon() || null}
						<SVGIcon icon={phoneTypeInfo?.icon} className="mr-2 ml-2" />
					</div>
				) : null}
				<StaticAddressWrapper>
					<span>{phone?.global_phone_details?.phone_number || "-"}</span>
				</StaticAddressWrapper>
			</StaticItemBase>
		</Wrapper>
	);
};

export default StaticPhoneItem;

/**
 * Styled Components
 */
const Wrapper = styled.div`
	display: flex;
	width: 100%;

	& > div {
		align-items: center;
	}

	.euiFormControlLayout {
		height: 25px;
	}

	.euiCheckbox .euiCheckbox__input + .euiCheckbox__square {
		border-radius: 50%;
	}

	.icon--wrapper {
		display: flex;
		align-items: center;
	}
`;

const StaticAddressWrapper = styled.div`
	display: flex;
	flex-direction: row;
	align-items: center;
	line-height: 1;

	.status-icon {
		margin-left: 1rem;

		svg {
			width: 1.333rem;
			height: auto;
		}
	}

	.check-icon {
		margin-left: 0.5rem;
		line-height: 1;
	}
`;

const PopoverContainer = styled.div`
	.euiContextMenuItem {
		padding: 2px;
	}
`;
