import API from "api";

import { account_main, global_email_details, account_notification } from "core/model";

import { makeFieldContent, makeExternalJoin, makeRefJoinTable } from "./commonService";

const getAccountMainList = () => {
	const query = {
		table_main: account_main._name,
		ref_table_join: [makeRefJoinTable(global_email_details)],
		external_join: [makeExternalJoin(account_main, global_email_details)],
		fields: [
			makeFieldContent(account_main, account_main.id),
			makeFieldContent(account_main, account_main.account_title),
			makeFieldContent(account_main, account_main.name_first),
			makeFieldContent(account_main, account_main.name_last),
			makeFieldContent(account_main, account_main.name_screen),
			makeFieldContent(account_main, account_main.account_type_c),
			makeFieldContent(account_main, account_main.record_state_sc),
			makeFieldContent(account_main, account_main.global_email_details_ref_id),
			makeFieldContent(account_main, account_main.global_password_details_ref_id),

			makeFieldContent(global_email_details, global_email_details.id),
			makeFieldContent(global_email_details, global_email_details.email_value),
			makeFieldContent(global_email_details, global_email_details.email_status_c),
		],
		search_type: "term",
		search_term: "",
	};
	return API().GenericAPI().getTableData(query);
};

const getAccountDetailInfo = () => {
	const query = {
		table_main: account_main._name,
		fields: [
			makeFieldContent(account_main, account_main.id),
			makeFieldContent(account_main, account_main.account_title),
			makeFieldContent(account_main, account_main.name_first),
			makeFieldContent(account_main, account_main.name_last),
			makeFieldContent(account_main, account_main.account_type_c),
			makeFieldContent(account_main, account_main.record_state_sc),
			makeFieldContent(account_main, account_main.global_email_details_ref_id),
		],
		search_type: "token",
	};
	return API().GenericAPI().getTableData(query);
};

const getAccountNotifications = ({ id }) => {
	const query = {
		table_main: account_notification._name,
		fields: [
			makeFieldContent(account_notification, account_notification.id),
			makeFieldContent(account_notification, account_notification.date_viewed),
			makeFieldContent(
				account_notification,
				account_notification.notification_type_c
			),
			makeFieldContent(
				account_notification,
				account_notification.account_main_ref_id
			),
			makeFieldContent(account_notification, account_notification.detail_value),
		],
		search_fields: [
			makeFieldContent(
				account_notification,
				account_notification.account_main_ref_id
			),
		],
		search_type: "ref",
		search_ref: [id],
	};
	return API().GenericAPI().getTableData(query);
};

const getAccountList = (data) => {
	return API().Account().accountList(data);
};

const updateAccount = (data) => {
	const query = {
		object: "account",
		data: data || {},
	};

	return API().GenericAPI().updateTableData(query);
};

const getAccountDetail = ({ accountId, gssList = [] }) => {
	const query = {
		object: "account",
		record_main_id: accountId,
	};
	return API().GenericAPI().getMainData(query);
};

const deleteAccount = (data) => {
	const request = {
		object: "account",
		data: data || {},
	};

	return API().GenericAPI().deleteTableData(request);
};

const logoutAccount = (data) => {
	return API().Account().logout(data);
};

export default {
	getAccountMainList,
	getAccountDetailInfo,
	getAccountDetail,
	getAccountNotifications,
	getAccountList,
	updateAccount,
	deleteAccount,
	logoutAccount,
};
