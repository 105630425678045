import { makeUniqueId } from "helpers/utilities";
import { isNumber } from "lodash";

import * as actionTypes from "./actionTypes";

const MESSAGE_ID_LENGTH = 32;

/**
 * Functions
 */
const removeMessage = (state, payload) => {
	// payload = { personId, messageId }
	const personId = payload.personId;
	const messageId = payload.messageId;

	if (isNumber(personId) && isNumber(messageId)) {
		const personMessages = state.messages[personId] || [];
		const newPersonMessages =
			personMessages.filter((message) => message.id !== messageId) || [];

		return {
			...state,
			messages: {
				...state.messages,
				[personId]: newPersonMessages,
			},
		};
	} else {
		return {
			...state,
		};
	}
};

const addMessage = (state, payload) => {
	// payload = { personId, message }
	const personId = payload.personId;
	const message = payload.message;

	if (isNumber(personId) && message) {
		const personMessages = state.messages[personId] || [];
		const newMessageId = makeUniqueId(MESSAGE_ID_LENGTH);
		personMessages.push({
			...message,
			id: newMessageId,
		});

		return {
			...state,
			messages: {
				...state.messages,
				[personId]: personMessages,
			},
		};
	} else {
		return {
			...state,
		};
	}
};

/**
 * Initial State
 */
const initialState = {
	messages: {},
};

/**
 * Reducer
 */
const Reducer = (state = initialState, action) => {
	switch (action.type) {
		case actionTypes.REMOVE_PERSON_MESSAGE:
			return removeMessage(state, action.payload);

		case actionTypes.ADD_PERSON_MESSAGE:
			return addMessage(state, action.payload);

		default:
			return state;
	}
};

export default Reducer;
