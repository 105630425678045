import React from "react";
import styled from "styled-components";

import ButtonsWrapper from "./ButtonsWrapper";

/**
 * Component for Reply Button
 */
const ReplyButton = (props) => {
	const { onReply, onReplyAll, onForward } = props;

	const buttons = [
		{
			label: "Reply",
			callback: () => onReply(),
			icon: "",
		},
	];

	if (onReplyAll) {
		buttons.push({
			label: "Reply All",
			callback: () => {
				if (onReplyAll) onReplyAll();
			},
			icon: "",
		});
	}

	if (onForward) {
		buttons.push({
			label: "Forward",
			callback: () => {
				if (onForward) onForward();
			},
			icon: "",
		});
	}

	return (
		<ButtonWrapper>
			<ButtonsWrapper buttons={buttons} color="secondary" />
		</ButtonWrapper>
	);
};

export default ReplyButton;

const ButtonWrapper = styled.div`
	margin-left: auto;
	min-width: 74px;
	height: var(--sp-12);
`;
