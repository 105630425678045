import React, { useState, useEffect } from "react";
import styled from "styled-components";
import "react-phone-number-input/style.css";
import PhoneInput, { parsePhoneNumber } from "react-phone-number-input";
import {
	EuiSuperSelect,
	EuiFieldText,
	EuiFlexGroup,
	EuiFlexItem,
	EuiSelect,
} from "@elastic/eui";

import {
	cellIcon,
	otherPhoneIcon,
	workCellIcon,
	workIcon,
	homeIcon,
	plusIcon,
	minusIcon,
} from "components/Common/Icons/Icons";
import { SVGIcon } from "components/Common";
import { global_phone_details, person_phone } from "core/model";

/**
 * Main Components
 */
const SelectCreatePhone = (props) => {
	const { idx, phone, options, onChangePhone, ...restProps } = props;

	// define variable from phone
	const phoneType = phone?.person_phone?.phone_type_c || 1;
	const phoneValue = phone?.person_phone?.global_phone_details?.phone_number || "";
	const phoneExt = phone?.person_phone?.global_phone_details?.phone_extension || "";

	// state variable
	const [isCreate, setIsCreate] = useState(false);

	const phoneTypeOptions = [
		{
			value: 1,
			label: "home",
			inputDisplay: homeIcon(),
		},
		{
			value: 2,
			label: "cell",
			inputDisplay: cellIcon(),
		},
		{
			value: 3,
			label: "work",
			inputDisplay: workIcon(),
		},
		{
			value: 4,
			label: "workcell",
			inputDisplay: workCellIcon(),
		},
		{
			value: 5,
			label: "other",
			inputDisplay: otherPhoneIcon(),
		},
	];

	const changeNewPhoneValue = (fieldName, value, global = false) => {
		let _phone = phone?.person_phone || {};
		if (global) {
			_phone[global_phone_details._name] = {
				[fieldName]: value,
			};
		} else {
			_phone[fieldName] = value;
		}
		onChangePhone({
			value: null,
			person_phone: _phone,
		});
	};

	const handlePhoneValue = (e) => {
		let idx = options.findIndex(
			(elem) => elem.value === parseInt(e.target.value, 10)
		);
		if (idx > -1) {
			onChangePhone({
				value: options[idx]?.value,
			});
		}
	};

	const handleChangeNewPhoneType = (val) => {
		changeNewPhoneValue(person_phone.phone_type_c, val);
	};

	const handleNewPhoneValue = (val) => {
		if (val) {
			const phoneNumber = parsePhoneNumber(val);
			changeNewPhoneValue(
				global_phone_details.phone_country_code,
				phoneNumber?.countryCallingCode,
				true
			);
			changeNewPhoneValue(global_phone_details.phone_number, val, true);
		}
	};

	const handleNewPhoneExt = (e) => {
		changeNewPhoneValue(global_phone_details.phone_extension, e.target.value, true);
	};

	const changeStatus = () => {
		setIsCreate(isCreate ? false : true);
	};

	return (
		<Wrapper {...restProps}>
			{isCreate ? (
				<WrapperContainer>
					<EuiFlexGroup gutterSize="none">
						<EuiFlexItem grow={2}>
							<EuiSuperSelect
								size="s"
								options={phoneTypeOptions}
								valueOfSelected={phoneType}
								onChange={handleChangeNewPhoneType}
							/>
						</EuiFlexItem>
						<EuiFlexItem grow={8}>
							<PhoneInput
								placeholder="Enter phone number"
								value={phoneValue}
								onChange={handleNewPhoneValue}
								defaultCountry="US"
							/>
						</EuiFlexItem>
						<EuiFlexItem grow={2}>
							<EuiFieldText
								placeholder="Ext"
								value={phoneExt}
								onChange={handleNewPhoneExt}
							/>
						</EuiFlexItem>
					</EuiFlexGroup>
					<SVGIcon
						icon={minusIcon}
						onClick={changeStatus}
						className="my-auto ml-2 cursor-pointer"
					/>
				</WrapperContainer>
			) : (
				<WrapperContainer>
					<EuiSelect
						options={options}
						value={phone?.value || undefined}
						onChange={handlePhoneValue}
					/>
					<SVGIcon
						icon={plusIcon}
						onClick={changeStatus}
						className="my-auto ml-2 cursor-pointer"
					/>
				</WrapperContainer>
			)}
		</Wrapper>
	);
};

export default SelectCreatePhone;

/**
 * Styled Components
 */
const Wrapper = styled.div`
	display: flex;
	width: 100%;

	.euiSuperSelectControl {
		height: 25px;
		line-height: 28px;
		padding-right: 25px;
		padding-left: 6px;
	}

	.euiFormControlLayoutIcons--right {
		right: 6px;
	}

	.euiFieldText {
		padding: 4px;
	}
`;

const WrapperContainer = styled.div`
	position: relative;
	display: flex;
	width: 100%;
`;
