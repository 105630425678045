import io from "socket.io-client";

import { timeoutPromise } from "helpers/utilities";
import { SOCKET_EVENTS } from "./constants";

/**
 * Class : Socket Service
 */
class SocketService {
	baseUrl;
	token;
	instance;
	type;

	constructor() {
		this.baseUrl = "";
		this.token = "";
		this.init("");
	}

	init(token, type) {
		this.token = token;
		this.baseUrl = this.getBaseUrl();
		this.type = type;

		if (token) {
			this.initInstance();
		}
	}

	initFromCookie() {
		this.token = localStorage.getItem("token");
	}

	initInstance() {
		if (this.baseUrl && this.token) {
			this.instance = io.connect(this.baseUrl, {
				query: {
					token: this.token,
					type: this.type,
				},
			});
		}
	}

	getBaseUrl() {
		const API_ENDPOINT_SANDBOX = "https://api.sandbox.crm.empower.associates";
		const API_ENDPOINT_DEVELOPMENT = "https://api.development.crm.empower.associates";

		const url = window.location.href;

		let defaultBaseUrl = API_ENDPOINT_SANDBOX;

		if (url.search("sandbox") !== -1) {
			defaultBaseUrl = API_ENDPOINT_SANDBOX;
		} else if (url.search("development") !== -1) {
			defaultBaseUrl = API_ENDPOINT_DEVELOPMENT;
		}

		return process.env.REACT_APP_SOCKET_URL || defaultBaseUrl;
	}

	async establishConnection() {
		return new Promise(async (resolve, reject) => {
			let increments = 0;

			while (this.instance.connected === false) {
				await timeoutPromise(10);
				increments++;
				break;
			}

			resolve(true);
		});
	}

	async socketEmit(payload) {
		return new Promise(async (resolve, reject) => {
			if (payload) {
				await this.establishConnection();

				payload.token = this.token;

				this.instance.emit(SOCKET_EVENTS.apiRequest, payload, (res) => {
					resolve(res);
				});
			}
			reject({ error: "Incorrect payload", data: payload });
		});
	}

	async call(command, params) {
		const payload = {
			id: command,
			api: "v1",
			arguments: {
				...params,
			},
			token: this.token,
		};

		//console.log('payload', JSON.stringify(payload))

		const result = await this.socketEmit(payload);

		if (result.data.error) {
			console.log("Socket Error: ", payload, JSON.stringify(payload));

			throw result.data;
		}

		return result.data;
	}
}

export default new SocketService();
