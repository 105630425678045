import API from "../../api";
import { objectChecker } from "../../helpers/utilities";

export const logError = (errorType, errorAction, errorData) => {
    return async (dispatch) => {
        try {
            const payload = {
                external_source: "client",
                error_type: errorType,
                error_action: errorAction,
                error_data: errorData,
            };

            const result = await API().SystemLogs().logError(payload);

            const data = objectChecker(result, ["data"]);
        } catch (err) {}
    };
};
