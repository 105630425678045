import { useSelector } from "react-redux";
import { createSelector } from "reselect";
import { size } from "lodash";

/**
 * Selectors
 */
const _state = (state) => state;
const _openTabs = (state) => state.openTab.tabs;

const _auth = (state) => state.auth;
const _authInfo = createSelector(_auth, (auth) => auth.auth);
const _authToken = createSelector(_authInfo, (authInfo) => authInfo?.token);

// dashboard
const _dashboard = (state) => state.dashboard;
const _dashboardMainData = createSelector(_dashboard, (dashboard) => dashboard.mainData);

// account
const _account = (state) => state.account;
const _accountList = createSelector(_account, (account) => account.accounts);
const _accountAmsList = createSelector(_account, (account) => account.accountAmsList);
const _accountDetail = createSelector(_account, (account) => account.accountDetail);
const _accountDetailLoaded = createSelector(
	_accountDetail,
	(detail) => size(detail) !== 0
);
const _accountSettings = createSelector(_account, (account) => account.accountSettings);
const _createAccount = createSelector(_account, (account) => account.createAccount);
const _updatePassword = createSelector(
	_account,
	(account) => account.updateAccountPassword
);
const _updateAccount = createSelector(_account, (account) => account.updateAccount);

// credentials
const _credential = (state) => state.credential;
const _credentailsByAccount = createSelector(
	_credential,
	(credential) => credential.credentialsByAccount
);
const _credentials = createSelector(_credential, (credential) => credential.credentials);
const _unassignedPlivoPhone = createSelector(
	_credential,
	(credential) => credential.unassignedPlivePhone
);
const _assignedPlivoPhone = createSelector(
	_credential,
	(credential) => credential.assignedPlivePhone
);
const _credentialDetails = createSelector(
	_credential,
	(credential) => credential.credentialDetails
);

// loaders
const _loaders = (state) => state.loader.loaders;
const _loaderPerson = createSelector(_loaders, (loaders) => loaders.person);
const _loaderPersonSIT = createSelector(_loaderPerson, (loader) => loader.sit);
const _loaderPersonSITSummary = createSelector(
	_loaderPersonSIT,
	(loader) => loader.summary
);
const _loaderJob = createSelector(_loaders, (loaders) => loaders.job);
const _loaderJobSIT = createSelector(_loaderJob, (loader) => loader.sit);

// company
const _company = (state) => state.company;
const _companyDetail = createSelector(_company, (company) => company.company.details);
const _companyList = createSelector(_company, (company) => company.companies);
const _companyPagination = createSelector(_company, (company) => company.pagination);
const _companySearchValue = createSelector(
	_company,
	(company) => company.companySearchValue
);
const _companyLoading = createSelector(_company, (company) => company.loading);
const _companyMainList_NEW = createSelector(
	_company,
	(company) => company.companyMainList_NEW
);
const _companyList_NEW = createSelector(_company, (company) => company.companyList_NEW);
const _companyDetail_NEW = createSelector(_company, (company) => company.company_NEW);

// job
const _job = (state) => state.job;
const _jobInfo = createSelector(_job, (job) => job.job);
const _jobs = createSelector(_job, (job) => job.jobs);
const _jobList_NEW = createSelector(_job, (job) => job.jobList_NEW);
const _jobMainList_NEW = createSelector(_job, (job) => job.jobMainList_NEW);
const _jobDetail_NEW = createSelector(_job, (job) => job.job_NEW);
const _jobApplicationList_NEW = createSelector(_job, (job) => job.jobApplicationList_NEW);
const _jobApplicationsForPerson = createSelector(
	_job,
	(job) => job.jobApplicationsForPerson
);
const _jobContactsForPerson = createSelector(_job, (job) => job.jobContactsForPerson);

// campaign
const _campaign = (state) => state.campaign;
const _campaigns = createSelector(_campaign, (campaign) => campaign.campaigns);
const _campaignDetail = createSelector(_campaign, (campaign) => campaign.campaign_NEW);
const _campaignList = createSelector(_campaign, (campaign) => campaign.campaignList_NEW);
const _campaignMainList_NEW = createSelector(
	_campaign,
	(campaign) => campaign.campaignMainList_NEW
);
const _campaignAllMainList = createSelector(
	_campaign,
	(campaign) => campaign.campaignAllList
);
const _campaignWorkflow = createSelector(_campaign, (campaign) => campaign.workflow);
const _campaignWorkflowTemplate = createSelector(
	_campaign,
	(campaign) => campaign.workflowTemplate
);
const _campaignWorkflows = createSelector(_campaign, (campaign) => campaign.workflows);
const _campaignTemplates = createSelector(_campaign, (campaign) => campaign.templates);
const _campaignWorkflowList = createSelector(
	_campaign,
	(campaign) => campaign.workflowItems
);
const _campaignWorkflowTemplateList = createSelector(
	_campaign,
	(campaign) => campaign.workflowTemplateList
);
const _campaignWorkflowSummary = createSelector(
	_campaign,
	(campaign) => campaign.workflowSummary
);
const _campaignWorkflowNodeDetails = createSelector(
	_campaign,
	(campaign) => campaign.workflowNodeDetails
);
const _campaignPersonList = createSelector(
	_campaign,
	(campaign) => campaign.campaignPersonList
);
const _campaignJobListForCreate = createSelector(
	_campaign,
	(campaign) => campaign.create.jobs
);

// Flyout
const _flyout = (state) => state.flyout;

// person
const _person = (state) => state.person;
const _personFlyoutMode = createSelector(_person, (person) => person.flyoutMode);
const _personInfo = createSelector(_person, (person) => person.person);
const _personDetails = createSelector(_personInfo, (personInfo) => personInfo.details);
const _personEnrichmentDetails = createSelector(
	_personInfo,
	(personInfo) => personInfo.enrichmentDetails
);
const _personList = createSelector(_person, (person) => person.persons);
const _personMainList_NEW = createSelector(
	_person,
	(person) => person.personMainList_NEW
);
const _isOpenPersonListFilter = createSelector(
	_person,
	(person) => person.isOpenListFilter
);
const _personAmsList = createSelector(_person, (person) => person.personAmsList);
const _personCmsAutomatedList = createSelector(_person, (person) => person.personCmsList);
const _personCmsManualList = createSelector(
	_person,
	(person) => person.personCmsDraftList
);

// Message
const _message = (state) => state.message;
const _messageList = createSelector(_message, (message) => message.messages);

// sit
const _sit = (state) => state.sit;
const _sits = createSelector(_sit, (sit) => sit.sits);
const _sitMode = createSelector(_sit, (sit) => sit.sitMode);
const _sitMainList = createSelector(_sit, (sit) => sit.sitMainList);
const _sitLoading = createSelector(_sit, (sit) => sit.loading);

// RAS
const _ras = (state) => state.ras;
const _restrictions = createSelector(_sit, (sit) => sit.restriction);

// qualifier
const _qualifier = (state) => state.qualifier;
const _qualifierList = createSelector(_qualifier, (qualifier) => qualifier.qualifiers);
const _qualifierMainList_NEW = createSelector(
	_qualifier,
	(qualifier) => qualifier.qualifierMainList_NEW
);

// sourcing
const _sourcing = (state) => state.sourcing;
const _sourcingList_NEW = createSelector(
	_sourcing,
	(sourcing) => sourcing.sourcingList_NEW
);
const _sourcingPeopleList_NEW = createSelector(
	_sourcing,
	(sourcing) => sourcing.sourcingPeopleList_NEW
);
const _sourcingMainList_NEW = createSelector(
	_sourcing,
	(sourcing) => sourcing.sourcingMainList_NEW
);
const _sourcingDetail_NEW = createSelector(
	_sourcing,
	(sourcing) => sourcing.sourcing_NEW
);
const _sourcringCredentails = createSelector(
	_sourcing,
	(sourcing) => sourcing.credentials
);
const _saveSourcing = createSelector(_sourcing, (sourcing) => sourcing.saveSourcing);

// template
const _template = (state) => state.template;
const _templatesByAccount = createSelector(
	_template,
	(_template) => _template.templatesByAccount
);
const _templatesByCredential = createSelector(
	_template,
	(_template) => _template.templatesByCredential
);

// theme
const _theme = (state) => state.theme;
const _themeList = createSelector(_theme, (_theme) => _theme.themeList);

// security group
const _security = (state) => state.securityGroup;
const _securityMain = createSelector(_security, (_security) => _security.securityMain);
const _securityGroups = createSelector(
	_security,
	(_security) => _security.securityGroups
);
const _stateSaveSecurityGroup = createSelector(
	_security,
	(_security) => _security.saveSecurityGroup
);
const _stateDeleteSecurityGroup = createSelector(
	_security,
	(_security) => _security.deleteSecurityGroup
);
const _securityAccessLevel = createSelector(
	_security,
	(_security) => _security.securityObjectAccess
);
const _securityAccessList = createSelector(
	_security,
	(_security) => _security.securityObjectAccess
);
const _stateSaveSecurityAccess = createSelector(
	_security,
	(_security) => _security.saveSecurityAccessForGroup
);
const _stateDeleteSecurityAccess = createSelector(
	_security,
	(_security) => _security.removeSecurityAccessForGroup
);
const _stateAddSecurityGroupMember = createSelector(
	_security,
	(_security) => _security.addFromSecurityGroup
);
const _stateDeleteSecurityGroupMember = createSelector(
	_security,
	(_security) => _security.removeFromSecurityGroup
);

// macros
const _macro = (state) => state.macro;
const _macroList = createSelector(_macro, (macro) => macro.macroList);

// global
const _globalInfo = (state) => state.global;
const _globals = createSelector(_globalInfo, (globalInfo) => globalInfo.globals);
const _gssList = createSelector(_globalInfo, (globalInfo) => globalInfo.gssList_NEW);

// global search
const _globalSearch = (state) => state.globalSearch;
const _globalSearchItems = createSelector(
	_globalSearch,
	(globalSearch) => globalSearch.globalSearchItems
);

// plivo
const _plivoInfo = (state) => state.plivo;

// Notification
const _notificationInfo = (state) => state.notification;
const _notifications = createSelector(
	_notificationInfo,
	(notificationInfo) => notificationInfo.notifications
);

/**
 * State
 */
export const _getState = () => useSelector(_state);

/**
 * Auth Information
 */
export const _getAuthInfo = () => useSelector(_authInfo);
export const _getAuthToken = () => useSelector(_authToken);

/**
 * Open Tabs
 */
export const _getOpenTabs = () => useSelector(_openTabs);

/**
 * Dashboard
 */
export const _getDashboardMainData = () => useSelector(_dashboardMainData);

/**
 * Account
 */
export const _getAccountState = () => useSelector(_account);
export const _getAccountDetail = () => useSelector(_accountDetail);
export const _getAccountDetailLoaded = () => useSelector(_accountDetailLoaded);
export const _getAccountSettings = () => useSelector(_accountSettings);
export const _getAccounts = () => useSelector(_accountList);
export const _getAccountAmsList = () => useSelector(_accountAmsList);
export const _getCreateAccount = () => useSelector(_createAccount);
export const _getUpdateAccountPassword = () => useSelector(_updatePassword);
export const _getUpdateAccount = () => useSelector(_updateAccount);

/**
 * Credentials
 */
export const _getCrendentialsByAccount = () => useSelector(_credentailsByAccount);
export const _getCredentials = () => useSelector(_credentials);
export const _getUnassignedPlivoPhone = () => useSelector(_unassignedPlivoPhone);
export const _getAssignedPlivoPhone = () => useSelector(_assignedPlivoPhone);
export const _getCredentialDetails = () => useSelector(_credentialDetails);

/**
 * Loaders
 */
export const _getLoadersState = () => useSelector(_loaders);
export const _getLoaderPerson = () => useSelector(_loaderPerson);
export const _getLoaderPersonSIT = () => useSelector(_loaderPersonSIT);
export const _getLoaderPersonSITSummary = () => useSelector(_loaderPersonSITSummary);
export const _getLoaderJob = () => useSelector(_loaderJob);
export const _getLoaderJobSIT = () => useSelector(_loaderJobSIT);

/**
 * Flyout
 */
export const _getGlobalFlyout = () => useSelector(_flyout);

/**
 * Company
 */
export const _getCompanyState = () => useSelector(_company);
export const _getCompanyDetail = () => useSelector(_companyDetail);
export const _getCompanies = () => useSelector(_companyList);
export const _getCompanyPagination = () => useSelector(_companyPagination);
export const _getCompanySearchValue = () => useSelector(_companySearchValue);
export const _getCompanyLoading = () => useSelector(_companyLoading);

export const _getCompanyMainList_NEW = () => useSelector(_companyMainList_NEW);
export const _getCompanyList_NEW = () => useSelector(_companyList_NEW);
export const _getCompanyDetail_NEW = () => useSelector(_companyDetail_NEW);

/**
 * Job
 */
export const _getJobState = () => useSelector(_job);
export const _getJobInfo = () => useSelector(_jobInfo);
export const _getJobs = () => useSelector(_jobs);
export const _getJobList_NEW = () => useSelector(_jobList_NEW);
export const _getJobMainList_NEW = () => useSelector(_jobMainList_NEW);
export const _getJobDetail_NEW = () => useSelector(_jobDetail_NEW);
export const _getJobApplicationList_NEW = () => useSelector(_jobApplicationList_NEW);
export const _getJobApplicationsForPerson = () => useSelector(_jobApplicationsForPerson);
export const _getJobContactsForPerson = () => useSelector(_jobContactsForPerson);

/**
 * Campaign
 */
export const _getCampaignState = () => useSelector(_campaign);
export const _getCampaignDetail = () => useSelector(_campaignDetail);
export const _getCampaigns = () => useSelector(_campaigns);
export const _getCampaignList = () => useSelector(_campaignList);
export const _getCampaignMainList_NEW = () => useSelector(_campaignMainList_NEW);
export const _getAllCampaignMainList = () => useSelector(_campaignAllMainList);
export const _getCampaignWorkflow = () => useSelector(_campaignWorkflow);
export const _getCampaignWorkflows = () => useSelector(_campaignWorkflows);
export const _getCampaignTemplates = () => useSelector(_campaignTemplates);
export const _getCampaignWorkflowList = () => useSelector(_campaignWorkflowList);
export const _getCampaignWorkflowSummary = () => useSelector(_campaignWorkflowSummary);
export const _getCampaignWorkflowNodeDetails = () =>
	useSelector(_campaignWorkflowNodeDetails);
export const _getCampaignPersonList = () => useSelector(_campaignPersonList);
export const _getCampaignJobListForCreate = () => useSelector(_campaignJobListForCreate);
export const _getWorkflowTemplate = () => useSelector(_campaignWorkflowTemplate);
export const _getWorkflowTemplateList = () => useSelector(_campaignWorkflowTemplateList);

/**
 * Person
 */
export const _getPersonState = () => useSelector(_person);
export const _getPersonInfo = () => useSelector(_personInfo);
export const _getPersonDetails = () => useSelector(_personDetails);
export const _getPersonEnrichmentDetails = () => useSelector(_personEnrichmentDetails);
export const _getPersonFlyoutMode = () => useSelector(_personFlyoutMode);
export const _getPersons = () => useSelector(_personList);
export const _getPersonMainList_NEW = () => useSelector(_personMainList_NEW);
export const _getIsOpenPersonListFilter = () => useSelector(_isOpenPersonListFilter);
export const _getPersonDetails_NEW = () =>
	useSelector((state) => state.person.person_NEW);
export const _getPersonDetailSelectedFile_NEW = () =>
	useSelector((state) => state.person.personDetailSelectedFile);
export const _getPersonAmsList = () => useSelector(_personAmsList);
export const _getPersonCmsAutomatedList = () => useSelector(_personCmsAutomatedList);
export const _getPersonCmsManualList = () => useSelector(_personCmsManualList);
export const _getPersonDetailsForFlyout = () =>
	useSelector((state) => state.person.personFlyout);

/**
 * Message
 */
export const _getMessageState = () => useSelector(_message);
export const _getMessageList = () => useSelector(_messageList);

/**
 * SIT
 */
export const _getSITState = () => useSelector(_sit);
export const _getSITs = () => useSelector(_sits);
export const _getSITMode = () => useSelector(_sitMode);
export const _getSitKeyword = (sit_main_id) => useSelector();
export const _getSitMainList_NEW = () => useSelector(_sitMainList);
export const _getSITLoading = () => useSelector(_sitLoading);

/**
 * RAS
 */
export const _getRASState = () => useSelector(_ras);
export const _getRASRestrictions = () => useSelector(_restrictions);

/**
 * Qualifier
 */
export const _getQualifierState = () => useSelector(_qualifier);
export const _getQualifiers = () => useSelector(_qualifierList);
export const _getQualifierMainList_NEW = () => useSelector(_qualifierMainList_NEW);

/**
 * Sourcing
 */
export const _getSourcingState = () => useSelector(_sourcing);
export const _getSourcingList_NEW = () => useSelector(_sourcingList_NEW);
export const _getSourcingPeopleList_NEW = () => useSelector(_sourcingPeopleList_NEW);
export const _getSourcingMainList_NEW = () => useSelector(_sourcingMainList_NEW);
export const _getSourcingDetail_NEW = () => useSelector(_sourcingDetail_NEW);
export const _getSourcingCredentials = () => useSelector(_sourcringCredentails);
export const _getSourcingSave = () => useSelector(_saveSourcing);

/**
 * Templates
 */
export const _getTemplatesByAccount = () => useSelector(_templatesByAccount);
export const _getTemplatesByCredential = () => useSelector(_templatesByCredential);

/**
 * Theme
 */
export const _getThemeList = () => useSelector(_themeList);

/**
 * Security Group
 */
export const _getSecurityMain = () => useSelector(_securityMain);
export const _getSecurityGroups = () => useSelector(_securityGroups);
export const _getStateSaveSecurityGroup = () => useSelector(_stateSaveSecurityGroup);
export const _getStateDeleteSecurityGroup = () => useSelector(_stateDeleteSecurityGroup);
export const _getSecurityAccessLevel = () => useSelector(_securityAccessLevel);
export const _getSecurityAccessList = () => useSelector(_securityAccessList);
export const _getStateSaveSecurityAccess = () => useSelector(_stateSaveSecurityAccess);
export const _getStateDeleteSecurityAccess = () =>
	useSelector(_stateDeleteSecurityAccess);
export const _getStateAddSecurityGroupMember = () =>
	useSelector(_stateAddSecurityGroupMember);
export const _getStateDeleteSecurityGroupMember = () =>
	useSelector(_stateDeleteSecurityGroupMember);

/**
 * Macro
 */
export const _getMacroList = () => useSelector(_macroList);

/**
 * Global custom fields
 */
export const _getGlobalState = () => useSelector(_globalInfo);
export const _getGlobals = () => useSelector(_globals);
export const _getGSSList = () => useSelector(_gssList);

/**
 * Global search
 */
export const _getGlobalSearchItems = () => useSelector(_globalSearchItems);

/**
 * Plivo
 */
export const _getPlivoInfo = () => useSelector(_plivoInfo);

/**
 * Notifictions
 */
export const _getNotifications = () => useSelector(_notifications);
