import { get } from "lodash";

import API from "api";
import { objectChecker } from "helpers/utilities";
import { getResultFromApiResponse } from "helpers/utilities";

import {
	CREATE_ACCOUNT,
	GET_ACCOUNT_DETAIL,
	SET_ACCOUNT_SETTINGS,
	SET_ACCOUNTS,
	SET_ACCOUNTLIST,
	UPDATE_ACCOUNT_FIELD,
	UPDATE_ACCOUNT_PASSWORD,
	GET_ACCOUNT_LOG_ACTIVITIES,
	FETCH_ACCOUNT_CAMPAIGN_DEFAULTS,
	RECEIVED_ACCOUNT_AMS_LIST,
	REQUEST_ACCOUNT_AMS_LIST,
	SET_ACCOUNT_AMS_LIST,
	SET_ACCOUNT_AMS_LIST_PARAMS,
} from "./actionTypes";

import Service from "core/service";
import { account_main } from "core/model";

import { logError } from "../error";
import { ROW_COUNT } from "components/global/constants";
import { accountId } from "SocketProvider";

/**
 * Functions for Actions
 */
export const setAccount = (data) => {
	return {
		type: SET_ACCOUNTS,
		payload: data,
	};
};

export const setAccountList = (data) => {
	return {
		type: SET_ACCOUNTLIST,
		payload: data,
	};
};

export const createAccount = (data) => {
	return {
		type: CREATE_ACCOUNT,
		payload: data,
	};
};

export const updateAccountData = (data) => {
	return {
		type: UPDATE_ACCOUNT_FIELD,
		payload: data,
	};
};

export const updateAccountPasswordData = (data) => {
	return {
		type: UPDATE_ACCOUNT_PASSWORD,
		payload: data,
	};
};

export const fetchAllAccount = () => {
	return async (dispatch) => {
		try {
			// const result = await Service.account.getAccountMainList();

			const result = await Service.account.getAccountList();
			const data = objectChecker(result, ["response", "data"]);

			if (data?.length) {
				let accounts = [];
				data.map((account) => {
					accounts.push(account?.account_main);
				});
				dispatch(setAccount(accounts));
			}
		} catch (err) {
			alert("Error fetching account data: " + JSON.stringify(err));
		}
	};
};

export const getAccountDetail = () => {
	return async (dispatch) => {
		try {
			const res = await Service.account.getAccountDetail({ accountId });
			const data = objectChecker(res.data[0], [account_main._name]);

			if (data) {
				dispatch({
					type: GET_ACCOUNT_DETAIL,
					payload: data,
				});
			}
		} catch (err) {
			alert("Error fetching account data: " + JSON.stringify(err));
		}
	};
};

export const updateAccount_NEW = (params, successCallback, failCallback) => {
	return async (dispatch) => {
		if (!params) {
			failCallback(null);
		}

		try {
			let payload = params;

			const result = await Service.account.updateAccount(payload);

			if (!objectChecker(result, ["error"])) {
				if (successCallback) {
					const res = getResultFromApiResponse(result);
					successCallback(res?.procedure_results);
				}
			}
		} catch (err) {
			if (failCallback) {
				failCallback(err);
			}
		}
	};
};

export const createNewAccount = (params) => {
	return async (dispatch) => {
		try {
			const result = await Service.account.updateAccount(params);

			if (result) {
				dispatch(createAccount(result));
			}
		} catch (err) {
			alert("Error adding new account data: " + JSON.stringify(err));
		}
	};
};

export const setAccountSettingValues = (data) => {
	return {
		type: SET_ACCOUNT_SETTINGS,
		payload: data,
	};
};

export const updateAccountField = (params) => {
	return async (dispatch) => {
		try {
			const result = await Service.account.updateAccount(params);

			if (result) {
				dispatch(updateAccountData(result));
			}
		} catch (err) {
			alert("Error update account data: " + JSON.stringify(err));
		}
	};
};

export const updateAccountPassword = (params) => {
	return async (dispatch) => {
		try {
			const result = await Service.global.updateGlobal(params);

			if (result) {
				dispatch(updateAccountPasswordData(result));
			}
		} catch (err) {
			alert("Error update account password data: " + JSON.stringify(err));
		}
	};
};

export const setAccountSettings = (payload, successCallback, failCallback) => {
	return async (dispatch) => {
		try {
			const result = await API().GenericAPI().updateTableData(payload);

			if (successCallback) {
				successCallback();
			}
		} catch (err) {
			if (failCallback) {
				failCallback();
			}

			alert("Error updating account settings: " + JSON.stringify(err));
		}
	};
};

export const getAccountLogsActivity = () => {
	return async (dispatch) => {
		const payload = {
			// before: "2020-10-31",
			account_id: accountId,
			offset: 0,
			rowcount: ROW_COUNT,
		};

		try {
			const result = await API().Account().getAccountLogsActivity(payload);
			const data = objectChecker(result, ["data"]);
			if (data) {
				dispatch({
					type: GET_ACCOUNT_LOG_ACTIVITIES,
					payload: data,
				});
			}
		} catch (err) {
			console.log("getAccountLogsActivity error", err);
		}
	};
};

export const addTab = (data) => {};

export const fetchAccountCampaignDefaults = (accountId) => {
	return async (dispatch) => {
		try {
			/* this code will be removed in the future. For now resolving the get-table-data error */
			// const result = await API()
			// 	.GenericAPI()
			// 	.getTableData({
			// 		object_base: "account_main",
			// 		sql: `SELECT account_setting.setting_value, account_main.ID, account_setting.ID as account_setting_id, account_setting.setting_type_c FROM account_main LEFT JOIN account_setting ON account_main.ID = account_setting.account_main_ref_id WHERE account_main.ID = ${accountId} AND account_setting.setting_type_c = 5`,
			// 	});

			const result = await null;
			/** ---------------------------------------------------------------------------------- */

			const data = objectChecker(result, ["data"]);

			if (data) {
				dispatch({
					type: FETCH_ACCOUNT_CAMPAIGN_DEFAULTS,
					payload: data,
				});
			}
		} catch (err) {
			dispatch(
				logError("fetch_failure", "none", {
					Error: "Error fetching account campaign: " + JSON.stringify(err),
				})
			);
		}
	};
};

export const setAccountSettingsOpenTabs = (payload, successCallback, failCallback) => {
	return async (dispatch) => {
		try {
			const result = await Service.account.updateAccount(payload);

			if (successCallback) {
				successCallback();
			}
		} catch (err) {
			if (failCallback) {
				failCallback();
			}

			console.log("Error updating account settings: " + JSON.stringify(err));
		}
	};
};

export const deleteAccount_NEW = (params, successCallback, failCallback) => {
	return async (dispatch) => {
		if (!params) {
			failCallback(null);
		}

		try {
			let payload = params;

			const result = await Service.account.deleteAccount(payload);

			if (!objectChecker(result, ["error"])) {
				if (successCallback) {
					const res = getResultFromApiResponse(result);
					successCallback(res?.procedure_results);
				}
			}
		} catch (err) {
			if (failCallback) {
				failCallback(err);
			}
		}
	};
};

export const logoutAccount = (params, successCallback, failCallback) => {
	return async (dispatch) => {
		if (!params) {
			failCallback(null);
		}

		try {
			const result = await Service.account.logoutAccount(params);

			if (!objectChecker(result, ["error"])) {
				if (successCallback) {
					const res = getResultFromApiResponse(result);
					successCallback(res?.procedure_results);
				}
			}
		} catch (err) {
			if (failCallback) {
				failCallback(err);
			}
		}
	};
};

/** Get AMS list for Account */
export const requestAccountAmsList = () => {
	return {
		type: REQUEST_ACCOUNT_AMS_LIST,
	};
};

export const receivedAccountAmsList = () => {
	return {
		type: RECEIVED_ACCOUNT_AMS_LIST,
	};
};

export const setAccountAmsList = (data) => {
	return {
		type: SET_ACCOUNT_AMS_LIST,
		payload: data,
	};
};

export const setAccountAmsListParams = (data) => {
	return {
		type: SET_ACCOUNT_AMS_LIST_PARAMS,
		payload: data,
	};
};

export const getAmsListByAccount = (params, successCallback, failCallback) => {
	return async (dispatch, getState) => {
		const state = getState();

		const curAccountAmsList = state.account.accountAmsList;

		if (curAccountAmsList.isRequesting) {
			return;
		}

		dispatch(requestAccountAmsList());

		const _params = {
			page_number: params?.pageNumber ?? curAccountAmsList?.pageNumber ?? 1,
			page_count: params?.pageCount ?? curAccountAmsList?.pageCount ?? 1000,
			ams_assigned_account_id:
				params?.accountId ??
				Number(curAccountAmsList?.filters.amsAssignedAccountId) ??
				0,
		};

		const payload = {
			pageNumber: _params.page_number,
			pageCount: _params.page_count,
			totalCount: 0,
			data: [],
			filters: {
				amsAssignedAccountId: _params?.ams_assigned_account_id,
			},
		};

		try {
			const result = await Service.ams.getAmsList(_params);

			const data = get(result, `response[0].data`);
			const totalCount = get(result, `response[0].total_count`);

			if (data) {
				const amslist = data.map((item) => item.ams_activity_main);
				payload.data = amslist;
				payload.totalCount = totalCount;

				if (successCallback) {
					successCallback(amslist);
				}
			} else {
				if (failCallback) {
					failCallback(data);
				}
			}
		} catch (err) {
			if (failCallback) {
				failCallback(err);
			}
		} finally {
			dispatch(setAccountAmsList(payload));
		}
	};
};
