import React from "react";
import { EuiFlexItem, EuiFlexGroup } from "@elastic/eui";
import styled from "styled-components";

import { getDate } from "helpers/utilities";
import { NODE_ICON_TYPES, WORKFLOW_NODE_LIST } from "../constants";
import { useGlobal } from "core/useHooks";
import { getGCSValue } from "components/global/utils";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck } from "@fortawesome/pro-regular-svg-icons";

/**
 * Component for displaying Workflow History List Item
 */
const WorkflowHistoryItem = (props) => {
	const { data, nodes } = props;
	const { globals } = useGlobal();

	const {
		item_node_id,
		time,
		message,
		translatedContent = "",
		status,
		item_state_c,
	} = data;
	const node = nodes.find((item) => item.id === item_node_id);
	const nodeInfo = Object.values(WORKFLOW_NODE_LIST).find(
		(item) => item.nodeType === node?.type
	);
	const itemSuccessStateC = getGCSValue(
		globals,
		"workflow_item",
		"item_state_c",
		"success"
	);
	const itemPendingStateC = getGCSValue(
		globals,
		"workflow_item",
		"item_state_c",
		"pending"
	);
	const itemCompleteStateC = getGCSValue(
		globals,
		"workflow_item",
		"item_state_c",
		"complete"
	);
	const isPending = item_state_c === itemPendingStateC;
	const isSuccess = item_state_c === itemSuccessStateC;
	const isComplete = item_state_c === itemCompleteStateC;
	const hiddenIcon = (!isSuccess && status !== 1) || isPending;
	const iconInfo = Object.values(NODE_ICON_TYPES).find(
		(item) => item.key === nodeInfo?.iconType
	);
	const icon = iconInfo?.icon;
	const badge = iconInfo?.badge;

	return (
		<Wrapper>
			<EuiFlexGroup gutterSize="s">
				<EuiFlexItem grow={false}>
					<IconContainer>
						{node ? (
							<NodeIcon className={isComplete ? "complete" : ""}>
								{!hiddenIcon ? (
									isComplete ? (
										<FontAwesomeIcon icon={faCheck} size="lg" />
									) : (
										icon
									)
								) : null}
								{badge ? (
									<div
										className={`workflow-editor-item-icon-badage ${
											!badge ? "badge-background" : ""
										}`}
									>
										<span>{badge}</span>
									</div>
								) : null}
							</NodeIcon>
						) : null}
					</IconContainer>
				</EuiFlexItem>
				<EuiFlexItem>
					<div className="workflow-history__container">
						<EuiFlexGroup direction="column" gutterSize="s">
							<EuiFlexItem>
								<div className="workflow-history-content_container">
									<div className="workflow-history-date">
										<p>
											{nodeInfo && (
												<span className="type-p-regular">
													{nodeInfo?.name || ""}&nbsp; &nbsp;
												</span>
											)}
											<span>
												{time
													? getDate(
															time,
															"MMM D, YYYY @ hh:mma"
													  )
													: "-"}
											</span>
										</p>
										<p
											className={`semibold workflow-status ${
												status === 1
													? "success"
													: status === -1
													? "error"
													: ""
											}`}
										>
											{status === 1 && "Success"}
											{status === -1 && "Error"}
											{status !== 1 &&
												status !== -1 &&
												"Processing..."}
										</p>
									</div>
									<div className="workflow-history-comment">
										<EuiFlexGroup gutterSize="s" direction="column">
											<EuiFlexItem>
												<p
													style={{ overflowWrap: "anywhere" }}
													className="type-p-small-regular"
												>
													{String(message || "")}
												</p>
											</EuiFlexItem>
											<EuiFlexItem>
												<TranslatedContentWrapper
													dangerouslySetInnerHTML={{
														__html: translatedContent,
													}}
													className="translated-content"
												/>
											</EuiFlexItem>
										</EuiFlexGroup>
									</div>
								</div>
							</EuiFlexItem>
						</EuiFlexGroup>
					</div>
				</EuiFlexItem>
			</EuiFlexGroup>
			<div className="left-sidebar" />
		</Wrapper>
	);
};

export default WorkflowHistoryItem;

const Wrapper = styled.div`
	display: flex;
	width: 100%;
	position: relative;

	.workflow-history__container {
		width: 100%;
		margin-bottom: var(--sp-5);
	}

	.left-sidebar {
		position: absolute;
		top: 0;
		left: var(--sp-7_5);
		content: "";
		height: 100%;
		width: var(--sp-1);
		background: #d4dae5;
	}

	.icon-button-img {
		align-items: center;
		display: flex;
	}

	.workflow-history-content_container {
		width: 100%;

		.workflow-history-date {
			display: flex;
			justify-content: space-between;
			align-items: center;
			height: var(--sp-10);

			padding: 0 var(--sp-5);
			background: linear-gradient(0deg, #f5f7fa, #f5f7fa);
			border: 1px solid #d4dae5;
			box-sizing: border-box;
			border-radius: var(--sp-3) var(--sp-3) 0 0;
		}

		.workflow-status {
			color: var(--eui-text-color);

			&.success {
				color: var(--eui-secondary-color);
			}
			&.error {
				color: var(--eui-danger-color);
			}
		}

		.workflow-history-comment {
			padding: var(--sp-3) var(--sp-4);
			border: 1px solid #d4dae5;
			box-sizing: border-box;
			border-radius: 0 0 var(--sp-3) var(--sp-3);
			border-top: none;

			.euiFormControlLayout {
				max-width: 100%;
			}
		}
	}
`;

const NodeIcon = styled.div`
	display: flex;
	position: relative;

	& > svg {
		width: var(--sp-10) !important;
		height: var(--sp-10);
		color: var(--link-color);
	}

	&.complete {
		& > svg {
			color: var(--success-color);
		}
	}

	.workflow-editor-item-icon-badage {
		position: absolute;
		top: calc(-1 * var(--sp-2_5));
		display: flex;
		align-items: center;

		font-weight: 600;
		font-size: var(--sp-4_5);
		line-height: 1;

		background: #ffffff;
		border-radius: 50%;
		left: var(--sp-8);

		&.badge-background {
			left: var(--sp-6);
			border: 1px solid #d3dae6;
			box-sizing: border-box;
			border-radius: var(--sp-2);
			padding: 1px var(--sp-1_5);
		}
	}
`;

const IconContainer = styled.div`
	display: flex;
	width: var(--sp-15);
	justify-content: center;
	background: var(--white-color);
	z-index: 1;
`;

const TranslatedContentWrapper = styled.div`
	img {
		width: 100%;
	}
`;
