import React from "react";
import styled from "styled-components";
import { EuiSpacer } from "@elastic/eui";

import { MessageList } from "./MessageList";
import { MessageEditor } from "./MessageEditor";

/**
 * Main Components
 */
const MessagePanel = (props) => {
	const { person, params } = props;
	return (
		<Wrapper>
			<MessageList person={person} />
			<EuiSpacer size="s" />
			<MessageEditor person={person} params={params} />
		</Wrapper>
	);
};

export default MessagePanel;

/**
 * Styled Components
 */
const Wrapper = styled.div`
	margin-top: var(--sp-1);
	padding-bottom: var(--sp-9);
`;
