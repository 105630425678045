import React from "react";
import { EuiFlexGroup, EuiFlexItem, EuiButtonEmpty } from "@elastic/eui";
import styled from "styled-components";
import { get } from "lodash";

import { CustomQualifierBadge } from "components/Common";

/**
 * Component for Static Qaulifier Item
 */
const StaticQualifierItem = (props) => {
	const { qualifiers, editable = true } = props;

	if (!qualifiers?.length) {
		if (editable) {
			return (
				<Wrapper>
					<EuiButtonEmpty size="xs">
						<span>Add Qualifier</span>
					</EuiButtonEmpty>
				</Wrapper>
			);
		} else {
			return null;
		}
	}

	return (
		<Wrapper>
			<EuiFlexGroup gutterSize="xs" alignItems="center">
				{qualifiers?.map((qualifier, id) => {
					const styles = get(qualifier, "qualifier_main.theme_item_main", null);

					const iconStyles = get(styles, "item_details.icon", {});
					const textStyles = get(styles, "item_details.text", {});
					const badgeStyles = get(styles, "item_details.badge", {});

					const qualifierName = get(
						qualifier,
						"qualifier_main.qualifier_name",
						""
					);
					const qualifierAssignId = get(qualifier, "qualifier_main.id", "");

					return (
						<EuiFlexItem
							key={`qualifier-list-static-item-${id}-${qualifier?.[qualifierAssignId]}`}
						>
							<CustomQualifierBadge
								data={{
									name: qualifierName,
									text: {
										...textStyles,
										name: qualifierName,
									},
									badge: {
										...badgeStyles,
									},
									icon: {
										...iconStyles,
									},
								}}
							/>
						</EuiFlexItem>
					);
				}) || null}
			</EuiFlexGroup>
		</Wrapper>
	);
};

export default StaticQualifierItem;

const Wrapper = styled.div`
	display: flex;

	.euiButtonEmpty {
		height: fit-content !important;

		.euiButtonContent.euiButtonEmpty__content {
			padding: 0 !important;
		}
		span {
			font-size: 1rem;
		}
	}
`;
