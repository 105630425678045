import React from "react";
import styled from "styled-components";
import { EuiButton } from "@elastic/eui";

/**
 * Component for Logged Button
 */
const LoggedButton = (props) => {
	const { onLogged } = props;

	return (
		<ButtonWrapper>
			<EuiButton
				onClick={() => {
					onLogged();
				}}
				fill
				color="secondary"
				className="button"
			>
				Logged
			</EuiButton>
		</ButtonWrapper>
	);
};

export default LoggedButton;

const ButtonWrapper = styled.div`
	margin-left: auto;
	min-width: 74px;
	height: var(--sp-12);

	.button {
		padding: 0 var(--sp-2);
		height: 100%;
		width: 100%;
		min-width: unset;
		/* background-color: var(--badge-secondary) !important;
		border-color: var(--badge-secondary) !important; */

		.euiButtonContent.euiButton__content {
			padding: 0;
		}

		.euiButton__text {
			margin-top: -1px;
		}
	}
`;
