import React from "react";
import { EuiFlexGroup, EuiFlexItem } from "@elastic/eui";
import { isArray } from "lodash";

import { RASItem } from "../Items/RAS";
import { getGCSLabel } from "components/global/utils";

/**
 * Component for Owner
 */
const Owners = (props) => {
	const { data, recordId, recordType, rasRestriction, globals } = props;

	let owners = [];
	let assigns = [];

	const currentRasRestriction =
		rasRestriction?.find((restriction) => {
			return (
				restriction.object_ref_id === recordId &&
				restriction.object_type === recordType
			);
		}) ?? {};

	if (globals && data?.length > 0) {
		let tmpOwners = [];
		let tmpAssigns = [];
		if (isArray(data)) {
			data.forEach((item) => {
				const assignment_type = getGCSLabel(
					globals,
					"ras_assignment_main",
					"assignment_type_c",
					item?.assignment_type_c
				);

				if (assignment_type === "Owner") {
					tmpOwners.push(item);
				}
				if (assignment_type === "Assigned") {
					tmpAssigns.push(item);
				}
			});
		}
		owners = tmpOwners;
		assigns = tmpAssigns;
	}

	return (
		<EuiFlexGroup className="table-column-source" gutterSize="xs">
			<EuiFlexItem style={{ flexDirection: "row" }} grow={false}>
				<RASItem
					owners={owners}
					assignees={assigns}
					restriction={currentRasRestriction}
					editable={false}
				/>
			</EuiFlexItem>
		</EuiFlexGroup>
	);
};

export default Owners;
