export const PERSON_SELECT_TYPES = {
	person: { key: "person", value: 0 },
	direct: { key: "direct", value: 1 },
	account: { key: "account", value: 2 },
};

export const MESSAGE_MANUAL_QUALIFIER_NAMES = {
	recruiting: "Recruiting",
	sales: "Sales",
};

export const LINKEDIN_CREDENTIAL_DEGREE = {
    first: { key: "first", value: "1st" },
    second: { key: "second", value: "2nd" }
};

export const LINKEDIN_CREDENTIAL_FILEDS = {
    connDegree: { key: "connectionDegree", value: "connection-degree" }
}
