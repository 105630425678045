/**
 * Security Group
 */
export const SET_SECURITY_MAIN = "@@security-group/SET_SECURITY_MAIN";
export const SET_SECURITY_GROUP = "@@security-group/SET_SECURITY_GROUP";
export const GET_SECURITY_ACCESS_LEVEL = "@@security-group/GET_SECURITY_ACCESS_LEVEL";
export const GET_SECURITY_GROUP_ACCESS = "@@security-group/GET_SECURITY_GROUP_ACCESS";
export const CHECK_ACCOUNT_SECURITY_ACCESS =
    "@@security-group/CHECK_ACCOUNT_SECURITY_ACCESS";
export const DELETE_SECURITY_GROUP = "@@security-group/DELETE_SECURITY_GROUP";
export const SAVE_SECURITY_GROUP = "@@security-group/SAVE_SECURITY_GROUP";
export const EDIT_SECURITY_GROUP = "@@security-group/EDIT_SECURITY_GROUP";
export const GET_SECURITY_GROUP_MEMBERS = "@@security-group/GET_SECURITY_GROUP_MEMBERS";
export const REMOVE_ACCOUNT_FROM_SECURITY_GROUP =
    "@@security-group/REMOVE_ACCOUNT_FROM_SECURITY_GROUP";
export const ADD_ACCOUNT_TO_SECURITY_GROUP =
    "@@security-group/ADD_ACCOUNT_TO_SECURITY_GROUP";
export const GET_SECURITY_GROUP_MEMBER_ACCESS =
    "@@security-group/GET_SECURITY_GROUP_MEMBER_ACCESS";
export const SAVE_SECURITY_ACCESS_FOR_GROUP =
    "@@security-group/SAVE_SECURITY_ACCESS_FOR_GROUP";
export const REMOVE_SECURITY_ACCESS_FOR_GROUP =
    "@@security-group/REMOVE_SECURITY_ACCESS_FOR_GROUP";
