import React, { useState, useMemo } from "react";
import styled from "styled-components";
import { get } from "lodash";

import { AmsStaticItem } from "./AmsStaticItem";
import { AmsEditItem } from "./AmsEditItem";
import { getGCSValue } from "components/global/utils";
import { useAccount, useGlobal } from "core/useHooks";

const AmsItem = (props) => {
	const {
		data,
		person,
		onUpdateAms,
		onDeleteAms,
		onDeleteAmsItem,
		onSaveEmail,
		onDeleteEmail,
		onSavePhone,
		onDeletePhone,
		onSaveRAS,
		loadPersonAmsData,
		globalSearchItems,
		getGlobalSearchItems,
	} = props;

	const { globals } = useGlobal();
	const { accounts, account } = useAccount();

	const [isEdit, setIsEdit] = useState(false);

	const { owners, assignees, restriction } = useMemo(() => {
		const assignees = [];
		const owners = [];
		const rasRRecords = get(data, "associations.ras_assignment_main");

		if (rasRRecords && globals?.length > 0) {
			const ownerAssignType = getGCSValue(
				globals,
				"ras_assignment_main",
				"assignment_type_c",
				"owner"
			);
			const assignedAssignType = getGCSValue(
				globals,
				"ras_assignment_main",
				"assignment_type_c",
				"assigned"
			);

			for (let i in rasRRecords) {
				const { assignment_type_c: assignmentTypeC } = rasRRecords[i];

				if (assignmentTypeC === ownerAssignType) {
					owners.push(rasRRecords[i]);
				}

				if (assignmentTypeC === assignedAssignType) {
					assignees.push(rasRRecords[i]);
				}
			}
		}

		const restriction = get(data, "associations.ras_restriction_main[0]", null);

		return {
			owners,
			assignees,
			restriction,
		};
	}, [data, globals]);

	const handleClickEdit = () => {
		setIsEdit(true);
	};

	const handleClickView = () => {
		setIsEdit(false);
	};

	const handleSaveRAS = (rasData) => {
		onSaveRAS(data?.id, restriction?.id, rasData);
	};

	const handleDeleteAmsItem = () => {
		onDeleteAmsItem();
	};

	return (
		<Wrapper>
			{isEdit ? (
				<AmsEditItem
					data={data}
					accounts={accounts}
					restriction={restriction}
					owners={owners}
					assignees={assignees}
					person={person}
					account={account}
					onChangeEdit={handleClickView}
					onUpdateAms={onUpdateAms}
					onDeleteAms={onDeleteAms}
					onSavePhone={onSavePhone}
					onDeletePhone={onDeletePhone}
					onSaveEmail={onSaveEmail}
					onDeleteEmail={onDeleteEmail}
					onSaveRAS={handleSaveRAS}
					loadPersonAmsData={loadPersonAmsData}
					globalSearchItems={globalSearchItems}
					getGlobalSearchItems={getGlobalSearchItems}
				/>
			) : (
				<AmsStaticItem
					data={data}
					accounts={accounts}
					restriction={restriction}
					owners={owners}
					assignees={assignees}
					onChangeEdit={handleClickEdit}
					onUpdateAms={onUpdateAms}
					onSaveRAS={handleSaveRAS}
					onDeleteAmsItem={handleDeleteAmsItem}
					loadPersonAmsData={loadPersonAmsData}
					onDeleteAms={onDeleteAms}
				/>
			)}
		</Wrapper>
	);
};

export default AmsItem;

const Wrapper = styled.div`
	display: flex;
	position: relative;
	width: 100%;
`;
