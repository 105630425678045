import React, { useEffect } from "react";
import styled from "styled-components";

import { useSelector } from "react-redux";
import { EuiText, EuiToast, EuiProgress } from "@elastic/eui";
import {
	contactOutIcon,
	linkedInIcon,
	salesNavIcon,
	seamlessAiIcon,
	atomIcon,
} from "components/Common/Icons";
import { useNotification } from "core/useHooks";
import useSourcing from "components/Pages/Sourcing/useSourcing";

const sourcingIcons = [
	{
		type_c: 1,
		name: "linkedin",
		icon: linkedInIcon(),
	},
	{
		type_c: 2,
		name: "seamless-ai",
		icon: seamlessAiIcon(),
	},
	{
		type_c: 3,
		name: "linkedin-sales-navigator",
		icon: salesNavIcon(),
	},
	{
		type_c: 4,
		name: "linkedin-recruiter-lit",
		icon: linkedInIcon(),
	},
	{
		type_c: 5,
		name: "contact-out",
		icon: contactOutIcon(),
	},
	{
		type_c: 6,
		name: "zoom-info",
		icon: linkedInIcon(),
	},
];

const ShowIconProgressProcess = ({ icon, totalCnt, processedCnt, failedCnt = 0 }) => {
	return (
		<IconProgressProcess className="d-flex">
			<div className="icon">{icon}</div>
			<div className="progress-bar">
				<EuiProgress
					value={processedCnt}
					max={totalCnt}
					color="secondary"
					size="s"
				/>
				<EuiText size="xs">
					{processedCnt} of {totalCnt}
				</EuiText>
			</div>
			<div className="process">{Math.floor((processedCnt * 100) / totalCnt)}%</div>
			{failedCnt > 0 && <div className="failed">({failedCnt})</div>}
		</IconProgressProcess>
	);
};

const ProgressMessage = (props) => {
	const { type, sourcingProcess, closeProcessMessageToast } = props;

	const getProgressIcon = (type) => {
		const idx = sourcingIcons.findIndex((item) => item.type_c === type);
		if (idx > -1) {
			return sourcingIcons[idx].icon;
		} else return sourcingIcons[0].icon;
	};

	const sourcingDetails = (type) => {
		const idx = sourcingIcons.findIndex((item) => item.type_c === type);
		if (idx > -1 && sourcingProcess?.sourcing_item_details) {
			return sourcingProcess?.sourcing_item_details[sourcingIcons[idx].name];
		}
		return null;
	};

	const sourcingItemDetails = sourcingDetails(
		sourcingProcess?.sourcing_main?.sourcing_type_c
	);

	return (
		<ProgressToast
			title={
				sourcingProcess?.sourcing_main?.sourcing_name
					? `${type} ${sourcingProcess?.sourcing_main?.sourcing_name}`
					: ""
			}
			color="success"
			onClose={() => closeProcessMessageToast()}
			BorderColor={
				sourcingProcess?.sourcing_main?.count_total_items ===
				sourcingProcess?.sourcing_main?.count_total_processes
					? "var(--success-color)"
					: "var(--border-color)"
			}
		>
			<EuiProgress size="xs" color="secondary" position="absolute" />
			{sourcingProcess?.sourcing_item_details && (
				<>
					<ShowIconProgressProcess
						icon={getProgressIcon(
							sourcingProcess?.sourcing_main?.sourcing_type_c
						)}
						totalCnt={sourcingItemDetails?.count_total || 1}
						processedCnt={sourcingItemDetails?.count_crawled || 0}
						failedCnt={sourcingItemDetails?.count_failed || 0}
					/>
					<ShowIconProgressProcess
						icon={atomIcon()}
						totalCnt={sourcingItemDetails?.count_total || 1}
						processedCnt={sourcingItemDetails?.count_processed || 0}
						failedCnt={sourcingItemDetails?.count_failed || 0}
					/>
				</>
			)}
			<EuiText size="xs" className="time-ellapsed">
				{sourcingProcess?.ext_time_elapsed || ""}
			</EuiText>
		</ProgressToast>
	);
};

/**
 * Main Component for Credential Notification
 */
const SourcingNotification = () => {
	const { setProgressNotification } = useNotification();
	const { getSourcingProcessProgress } = useSourcing();

	const notifications = useSelector((state) => state.notification.notifications);
	const progressNotification = useSelector(
		(state) => state.notification.progressNotification
	);
	let msg = null;

	const normalNotificationToast = [];
	notifications.map((data) => {
		if (
			typeof data.message === "string" ||
			data.type === "normal" ||
			data.type === "from-crawler"
		) {
			const status = data?.status;
			const color = status === "error" ? "danger" : "success";

			normalNotificationToast.push({
				title: data.title ? data.title : "",
				color: color,
				id: data.id,
				toastdata: data,
				text: data.message?.length > 0 ? data.message.toString() : "",
				toastLifeTimeMs: 60000,
			});
		} else if (data.message && data.type === "get-sourcing-main-progress") {
			msg = data.message;
		}
		return null;
	});

	useEffect(() => {
		if (msg) {
			const sourcingMain = msg?.sourcing_main;
			const query = {
				sourcing_main_id: sourcingMain?.id,
				action: "unsubscribe",
			};
			if (
				sourcingMain?.count_total_items > 0 &&
				sourcingMain?.count_total_items === sourcingMain?.count_total_processes
			) {
				setTimeout(() => {
					// kill sourcing progress notification
					getSourcingProcessProgress(query);
					setProgressNotification(null);
				}, [60000]);
			}

			// remove progress notification if the loading time is exceed.
			if (sourcingMain?.time_ended) {
				const curTime = new Date().getTime();
				const timeEnded = new Date(sourcingMain?.time_ended);
				const timeDiff = curTime - timeEnded;
				if (timeDiff > 60000) {
					// kill sourcing progress notification
					getSourcingProcessProgress(query);
					setProgressNotification(null);
				}
			} else {
				setProgressNotification({
					...msg,
					// time_arrived: new Date().getTime(),
				});
			}
		}
	}, [msg]);

	const closeProcessMessageToast = () => {
		setProgressNotification(null);
	};

	if (!progressNotification) return null;

	return (
		<Wrapper>
			<ProgressMessage
				type={"Sourcing"}
				sourcingProcess={progressNotification}
				closeProcessMessageToast={closeProcessMessageToast}
			/>
		</Wrapper>
	);
};

export default SourcingNotification;

/**
 * Styled Components
 */
const Wrapper = styled.div`
	position: fixed;
	left: 17px;
	bottom: 10px;
	width: 320px;
	z-index: 100000;
`;

const ProgressToast = styled(EuiToast)`
	border-top: 2px solid ${(props) => props.BorderColor} !important;
	.euiProgress--absolute {
		top: -2px;
	}
	.time-ellapsed {
		text-align: right;
	}
`;

const IconProgressProcess = styled.div`
	display: flex;
	.icon {
		width: 32px;
		display: flex;
	}
	.progress-bar {
		width: 75%;
		display: flex;
		flex-direction: column;
		align-items: center;
		.euiProgress--native {
			border-radius: 4px;
			margin-top: 6px;
		}
		.euiText--extraSmall {
			margin-top: 4px;
		}
	}
	.process {
		/* margin-left: auto; */
	}
	.failed {
		color: var(--eui-failed-color);
	}
`;
