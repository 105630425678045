import React from "react";
import styled from "styled-components";

import { SelectCreatePhone, SVGIcon } from "components/Common";
import { workIcon } from "components/Common/Icons";

/**
 * Main Components
 */
const PersonJobPhone = (props) => {

	const {
        phone,
        options,
        onChangePhone,
		...restProps
	} = props;

    const handleChangePhone = (val) => {
        onChangePhone(val);
    }

	return (
		<Wrapper {...restProps} className="mb-3">
            <SVGIcon
                icon={workIcon}
                className="mt-2 mr-2"
            />
            <SelectCreatePhone
                idx={0}
                phone={phone}
                options={options}
                onChangePhone={handleChangePhone}
            />
		</Wrapper>
	);
};

export default PersonJobPhone;

/**
 * Styled Components
 */
const Wrapper = styled.div`
    display: flex;
    width: 100%;

    .icon-button {
        margin: auto 0 0 0.5rem;
    }

    .euiFormControlLayout {
        height: 25px;
    }
`;
