import React, { useState, useEffect } from "react";
import styled from "styled-components";

import { useDispatch, useSelector } from "react-redux";
import {
	EuiButton,
	EuiCallOut,
	EuiFieldText,
	EuiFlexGroup,
	EuiFlexItem,
	EuiLoadingChart,
	EuiModal,
	EuiModalBody,
	EuiModalFooter,
	EuiModalHeader,
	EuiModalHeaderTitle,
	EuiOverlayMask,
	EuiText,
	EuiToast,
} from "@elastic/eui";
import {
	removeNotification,
	credentialVerificationNotification,
	saveLinkedInVerificationCode,
} from "helpers/actions";
import { useNotification } from "core/useHooks";

const CredentialSuccess = (props) => {
	let title = `${props.credentialName}'s authenticated!`;
	return (
		<EuiToast
			title={title}
			color="success"
			onClose={() => props.closeCredentialToast()}
		/>
	);
};

const CredentialLoading = (props) => {
	let title = `Authenticating !!`;
	return (
		<EuiToast
			title={title}
			color="warning"
			onClose={() => props.closeCredentialToast()}
		>
			<EuiLoadingChart size="m" mono />
		</EuiToast>
	);
};

const CredentialError = (props) => {
	const [isModalVisible, setIsModalVisible] = useState(false);
	const closeModal = () => setIsModalVisible(false);
	let title = `Error authenticating ${props.credentialName}`;

	return (
		<EuiToast
			title={title}
			color="danger"
			iconType="alert"
			onClose={() => props.closeCredentialToast()}
		>
			{isModalVisible && <ErrorModal data={props.data} closeModal={closeModal} />}
			<EuiButton
				onClick={() => setIsModalVisible(!isModalVisible)}
				style={{ display: "block", marginLeft: "auto" }}
				size="s"
				color="danger"
			>
				See the full error
			</EuiButton>
		</EuiToast>
	);
};

const CredentialEnterCode = (props) => {
	const { credentialVerification } = props;
	let title = `Enter ${props.credentialName}'s code`;

	return (
		<EuiToast
			title={title}
			color="warning"
			onClose={() => props.closeCredentialToast()}
		>
			<EuiText size="s">
				<p>Enter latest code:</p>
			</EuiText>
			<CredentialCodeInput
				setLoadingVerifyToast={props.setLoadingVerifyToast}
				credentialVerification={credentialVerification}
			/>
		</EuiToast>
	);
};

const CredentialReEnterError = (props) => {
	const { credentialVerification } = props;
	let title = `${props.credentialName}'s code error`;
	return (
		<EuiToast
			title={title}
			color="danger"
			iconType="alert"
			onClose={() => props.closeCredentialToast()}
		>
			<EuiText>
				<p>Enter latest code:</p>
			</EuiText>
			<CredentialCodeInput
				setLoadingVerifyToast={props.setLoadingVerifyToast}
				credentialVerification={credentialVerification}
			/>
		</EuiToast>
	);
};

const CredentialCodeInput = (props) => {
	//const socketContext = useContext(SocketContext)
	const credentialMainId = useSelector((state) => state.credential.credentialMainId);
	const dispatch = useDispatch();
	const [code, setCode] = useState("");
	const saveCredentialCodeAPI = () => {
		dispatch(
			saveLinkedInVerificationCode({
				credential_main_id: credentialMainId,
				verification_code: code,
			})
		);
		dispatch(removeNotification(""));
	};
	return (
		<EuiFlexGroup responsive={false} gutterSize="s">
			<EuiFlexItem grow={false}>
				<EuiFieldText
					value={code}
					onChange={(e) => setCode(e.target.value)}
					compressed
					placeholder="code"
				/>
			</EuiFlexItem>
			<EuiFlexItem grow={false}>
				<EuiButton
					iconSide="right"
					size="s"
					onClick={() => saveCredentialCodeAPI()}
				>
					Verify
				</EuiButton>
			</EuiFlexItem>
		</EuiFlexGroup>
	);
};

const ErrorModal = (props) => {
	return (
		<EuiOverlayMask>
			<EuiModal onClose={() => props.closeModal()} initialFocus="[name=popswitch]">
				<EuiModalHeader>
					<EuiModalHeaderTitle>Error!</EuiModalHeaderTitle>
				</EuiModalHeader>

				<EuiModalBody>
					<EuiCallOut
						title={props.data.message.toString()}
						color="danger"
						iconType="alert"
					/>
				</EuiModalBody>

				<EuiModalFooter>
					<EuiButton onClick={() => props.closeModal()} fill>
						Close
					</EuiButton>
				</EuiModalFooter>
			</EuiModal>
		</EuiOverlayMask>
	);
};

/**
 * Main Component for Credential Notification
 */
const CredentialNotification = (props) => {
	const dispatch = useDispatch();

	const { setProgressNotification } = useNotification();

	const notifications = useSelector((state) => state.notification.notifications);
	const credentialVerification = useSelector(
		(state) => state.credential.credentialVerificationNotification
	);
	const progressNotification = useSelector(
		(state) => state.notification.progressNotification
	);
	let msg = null;

	const normalNotificationToast = [];
	notifications.map((data) => {
		if (
			typeof data.message === "string" ||
			data.type === "normal" ||
			data.type === "from-crawler"
		) {
			const status = data?.status;
			const color = status === "error" ? "danger" : "success";

			normalNotificationToast.push({
				title: data.title ? data.title : "",
				color: color,
				id: data.id,
				toastdata: data,
				text: data.message?.length > 0 ? data.message.toString() : "",
				toastLifeTimeMs: 60000,
			});
		} else if (data.message && data.type === "get-sourcing-process-progress") {
			msg = data.message;
		}
		return null;
	});

	useEffect(() => {
		if (msg) {
			if (msg?.total_items > 0 && msg?.total_items === msg?.processed_items) {
				setTimeout(() => {
					// kill sourcing progress notification
					closeCredentialToast();
				}, [60000]);
			}

			// remove progress notification if the loading time is exceed.
			if (msg?.processed_items === progressNotification?.processed_items) {
				const curTime = new Date().getTime();
				const timeDiff = curTime - progressNotification?.time_arrived;
				if (timeDiff > 60000) {
					// kill sourcing progress notification
					closeCredentialToast();
				}
			} else {
				setProgressNotification({
					...msg,
					time_arrived: new Date().getTime(),
				});
			}
		}
	}, [msg]);

	const closeCredentialToast = () => {
		//props.removeVerify()
		dispatch(credentialVerificationNotification(null));
	};

	return (
		<Wrapper>
			{credentialVerification !== null &&
				credentialVerification.id === "loading" && (
					<CredentialLoading
						closeCredentialToast={closeCredentialToast}
						credentialName={credentialVerification.credential_name}
					/>
				)}
			{credentialVerification !== null &&
				credentialVerification.toastColor === "success" &&
				credentialVerification.payloadFromCrawler.status === 1 && (
					<CredentialSuccess
						closeCredentialToast={closeCredentialToast}
						credentialName={credentialVerification.credential_name}
					/>
				)}
			{credentialVerification !== null &&
				credentialVerification.type === "credential-authentication" &&
				credentialVerification.payloadFromCrawler.status === 0 && (
					<CredentialEnterCode
						setLoadingVerifyToast={props.setLoadingVerifyToast}
						credentialVerification={credentialVerification}
						closeCredentialToast={closeCredentialToast}
						credentialName={credentialVerification.credential_name}
					/>
				)}
			{credentialVerification !== null &&
				credentialVerification.type === "verification-code-application" &&
				credentialVerification.payloadFromCrawler.status === 0 && (
					<CredentialReEnterError
						setLoadingVerifyToast={props.setLoadingVerifyToast}
						credentialVerification={credentialVerification}
						closeCredentialToast={closeCredentialToast}
						credentialName={credentialVerification.credential_name}
					/>
				)}
			{credentialVerification !== null &&
				credentialVerification.type === "verification-code-application" &&
				credentialVerification.payloadFromCrawler.status === -1 && (
					<CredentialError
						data={credentialVerification}
						closeCredentialToast={closeCredentialToast}
						credentialName={credentialVerification.credential_name}
					/>
				)}
			{credentialVerification !== null &&
				credentialVerification.type === "credential-authentication" &&
				credentialVerification.payloadFromCrawler.status === -1 && (
					<CredentialError
						data={credentialVerification}
						closeCredentialToast={closeCredentialToast}
						credentialName={credentialVerification.credential_name}
					/>
				)}
		</Wrapper>
	);
};

export default CredentialNotification;

/**
 * Styled Components
 */
const Wrapper = styled.div`
	position: fixed;
	left: 17px;
	bottom: 10px;
	width: 320px;
	z-index: 100000;
`;
