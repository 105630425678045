import React, { useMemo } from "react";
import styled from "styled-components";
import { EuiButtonIcon } from "@elastic/eui";
import { useHistory } from "react-router";

import { getActivityViewTypeInfo } from "components/global/utils";
import ReminderNotificationItem from "./ReminderNotificationItem";
import { AMS_REFERENCE_NAME_SYSTEMS } from "components/global/constants";
import { getNeedShowForReminder } from "./utils";

const ActivityNotificationItem = (props) => {
	const { activity, selectedReminder, onSelectReminder } = props;

	const history = useHistory();

	const { activityName, activityViewInfo, amsTasks, person, personName } =
		useMemo(() => {
			const {
				activity_name: activityName,
				activity_view_type_c,
				ams_activity_task: amsTasks = [],
				ams_activity_reference = [],
			} = activity || {};
			const activityViewInfo = getActivityViewTypeInfo(activity_view_type_c);
			const persons = ams_activity_reference?.filter(
				(item) =>
					item.details?.name_system === AMS_REFERENCE_NAME_SYSTEMS.person.value
			);
			const person = persons?.[0];
			const personName = person
				? `${person?.details?.name_first || "-"} ${
						person?.details?.name_last || ""
				  }`
				: "-";
			return {
				activityName,
				activityViewInfo,
				amsTasks,
				person,
				personName,
			};
		}, [activity]);

	const handleGotoPerson = () => {
		if (person) {
			history.push(`/person/${person?.reference_object_record_ref_id}`);
		}
	};

	return (
		<Wrapper>
			<div className="activity_notification_header--container">
				{activityViewInfo?.icon || null}
				<div className="type-small-default activity_name">
					{activityName || "-"}
				</div>
			</div>
			<div className="reminder_list--container">
				{amsTasks?.map((task, idt) => {
					const { ams_activity_reminder = [] } = task;
					return ams_activity_reminder?.map((reminder, idr) => {
						const reminderId = reminder.id;
						const isSelected = reminderId === selectedReminder?.id;
						const isShow = getNeedShowForReminder(reminder);
						const handleClickReminder = (e) => {
							onSelectReminder(reminder);
						};
						if (!isShow) {
							return null;
						}

						return (
							<ReminderNotificationItem
								key={`reminder_list-item-${idt}-${idr}`}
								activity={activity}
								task={task}
								reminder={reminder}
								selected={isSelected}
								onClick={handleClickReminder}
								personName={personName}
							/>
						);
					});
				})}
			</div>
			<div className="export_button">
				<EuiButtonIcon
					iconType="popout"
					size="m"
					onClick={handleGotoPerson}
					color="text"
				/>
			</div>
		</Wrapper>
	);
};

export default ActivityNotificationItem;

const Wrapper = styled.div`
	display: flex;
	flex-direction: column;
	position: relative;
	border: 1px solid var(--eui-lightest-shade-color);
	box-sizing: border-box;
	border-radius: var(--sp-2);

	.activity_notification_header {
		&--container {
			display: flex;
			padding: 0 0 0 var(--sp-2);
			align-items: center;
		}
	}

	.activity_name {
		color: var(--eui-darkshade-color);
		margin-left: var(--sp-4);
	}

	.export_button {
		display: none;
		position: absolute;
		cursor: pointer;
		z-index: 1;
		top: var(--sp-1);
		right: var(--sp-2);
		line-height: var(--sp-8);
	}

	.reminder_list--container {
		display: flex;
		width: 100%;
		flex-direction: column;
		margin-top: var(--sp-2);
	}

	&:hover {
		box-shadow: var(--shadow-bottom-medium);
		border-color: transparent;

		.export_button {
			display: flex;
		}
	}
`;
