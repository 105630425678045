import io from "socket.io-client";
import { getCurrentRoute, timeoutPromise } from "./helpers/utilities";

const API_ENDPOINT_SANDBOX = "https://api.sandbox.crm.empower.associates";
const API_ENDPOINT_DEVELOPMENT = "https://api.development.crm.empower.associates";

const url = window.location.href;

let defaultBaseUrl = API_ENDPOINT_SANDBOX;

if (url.search("sandbox") !== -1) {
	defaultBaseUrl = API_ENDPOINT_SANDBOX;
} else if (url.search("development") !== -1) {
	defaultBaseUrl = API_ENDPOINT_DEVELOPMENT;
}

// let SOCKET_ENDPOINT = 'https://api.sandbox.crm.empower.associates'
let SOCKET_ENDPOINT = process.env.REACT_APP_SOCKET_URL || defaultBaseUrl;
//alert(SOCKET_ENDPOINT)

// let SOCKET_ENDPOINT = 'http://localhost:3001'

export const token = localStorage.getItem("token");
export const accountId = parseInt(localStorage.getItem("id"), 10);
export const instance = io(SOCKET_ENDPOINT, {
	query: {
		token: token,
		type: "web-client",
	},
});

instance.on("connect", () => {
	// console.log("API SOCKET IO CLIENT CONNECTED");
});

instance.on("error", (err) => {
	const token = localStorage.getItem("token");
	console.log("API SOCKET IO CLIENT ", err, token);

	if (err === "Authentication error.") {
		const url = getCurrentRoute();

		if (url !== "login") {
			//alert('Authentication error, you are being logged out.')

			localStorage.removeItem("token");
			localStorage.removeItem("id");
			localStorage.removeItem("login_state");
			localStorage.removeItem("keep_login");
			return window.location.replace("/login");
		}
	}

	if (token === "" || token === null) {
		console.log("error", err);
	}
});

export function establishConnection() {
	return new Promise(async (resolve, reject) => {
		let increments = 0;

		while (instance.connected === false) {
			await timeoutPromise(1000);
			increments++;
			break;
		}

		resolve(true);
	});
}

export async function SocketCall(payload) {
	if (!token) {
		const _token = payload.token;
	}
	await establishConnection();

	return new Promise((resolve, reject) => {
		instance.emit("api-request", payload, (res) => {
			resolve(res);
		});
	});
}
