import { difference } from "lodash";
import moment from "moment";

import { global_static_setting } from "core/model";
import {
	ACTIVITY_VIEW_TYPES,
	AVAILABLE_LINK_LIST,
	GLOBAL_EMAIL_TYPE_OPTIONS,
	GLOBAL_PHONE_TYPE_OPTIONS,
	WORK_TYPES,
} from "./constants";

export const mapWorkType = (key) => {
	const workKey = Object.getOwnPropertyNames(WORK_TYPES).find(
		(workType) => WORK_TYPES[workType].key === key
	);

	if (workKey) {
		return WORK_TYPES[workKey];
	}

	return;
};

/**
 * Get Global Custom Setting Value form Label as integer
 * @param {*} globals list
 * @param {*} nameSystem
 * @param {*} nameField
 * @param {*} nameValue
 */
export const getGCSValue = (globals, nameSystem, nameField, nameValue) => {
	const globalValue = globals?.find(
		(item) =>
			(!nameSystem || item.nameSystem === nameSystem) &&
			(!nameValue || item.nameValue === nameValue) &&
			(!nameField || item.nameField === nameField)
	);

	if (globalValue) {
		return globalValue.customValue;
	}

	return;
};

/**
 * Get Global Custom Setting name label as string
 * @param {*} globals
 * @param {*} nameSystem
 * @param {*} nameField
 * @param {*} customValue
 */
export const getGCSLabel = (globals, nameSystem, nameField, customValue) => {
	const globalNameField = globals?.find(
		(item) =>
			item.nameSystem === nameSystem &&
			item.nameField === nameField &&
			item.customValue === customValue
	);

	if (globalNameField) {
		return changeFormat(globalNameField.nameValue);
	}

	return;
};

/**
 * Get all Global Custom Settings List.
 * @param {*} globals
 * @param {*} nameSystem
 * @param {*} nameField
 */
export const getGCSLists = (globals, nameSystem, nameField) => {
	const lists = globals?.filter(
		(item) => item.nameSystem === nameSystem && item.nameField === nameField
	);
	const res =
		lists &&
		lists.length > 0 &&
		lists.map((list) => {
			return { value: list?.customValue, text: changeFormat(list?.nameValue) };
		});
	return res;
};

/**
 * Get Global Static Setting value as integer
 * @param {*} gssList
 * @param {*} category
 * @param {*} field
 */
export const getGSSValue = (gssList, category, field) => {
	const gssValue = gssList?.find(
		(item) =>
			(!category || item[global_static_setting.setting_category] === category) &&
			(!field || item[global_static_setting.setting_name] === field)
	);

	if (gssValue) {
		return gssValue[global_static_setting.setting_value];
	}

	return;
};

/**
 * Get Global Static Setting name value as string
 * @param {*} gssList
 * @param {*} category
 * @param {*} value
 */
export const getGSSLabel = (gssList, category, value) => {
	const gssName = gssList?.find(
		(item) =>
			(!category || item[global_static_setting.setting_category] === category) &&
			(!value || item[global_static_setting.setting_value] === value)
	);

	if (gssName) {
		return gssName[global_static_setting.setting_name];
	}

	return;
};

/**
 * Get Global Statci Setting Lists.
 * @param {*} gssList
 * @param {*} category
 */
export const getGSSLists = (gssList, category) => {
	const lists = gssList?.filter(
		(item) => item[global_static_setting.setting_category] === category
	);
	const res =
		lists &&
		lists.length > 0 &&
		lists.map((list) => {
			return {
				value: list[global_static_setting.setting_value],
				text: list[global_static_setting.setting_name],
			};
		});
	return res;
};

/**
 * Get adding ids and deletign ids from records
 *
 * @param {*} oldList : array of Index
 * @param {*} newList : array of Index
 * @param {*} records : array of Record
 * @returns
 */
export const getAddDeleteListFromRecords = (oldList, newList, records) => {
	const deleteList = difference(oldList, newList);
	const deleteIds = deleteList.map((id) => records[id].id);
	const addIds = difference(newList, oldList);

	return {
		deleteIds: deleteIds,
		addIds: addIds,
	};
};

/**
 * Change the first letter as capitalization and remove underline and hypen
 */
export const changeFormat = (str) => {
	let res = str;
	if (str?.length) {
		res = str.charAt(0).toUpperCase() + str.slice(1);
		res = res.replace(/-|_/gi, " ");
	}
	return res;
};

/**
 * Get node ID
 */
const NODE_ID_LENGTH = 32;
export const makeNodeId = () => {
	let result = "";
	const characters = "abcdefghijklmnopqrstuvwxyz0123456789";
	const charactersLength = characters.length;
	for (let i = 0; i < NODE_ID_LENGTH; i++) {
		result += characters.charAt(Math.floor(Math.random() * charactersLength));
	}
	return result;
};

/**
 * Convert html to plain text
 *
 * @param {String} html: html string with any tags
 * @returns
 */
export const getPlainTextFromHtml = (html) => {
	if (!html) {
		return "";
	}

	// Create a new div element
	const tempDivElement = document.createElement("div");

	// Set the HTML content with the given value
	tempDivElement.innerHTML = html;

	// Retrieve the text property of the element
	return tempDivElement.textContent || tempDivElement.innerText || "";
};

/**
 * Check url
 *
 * @param {string} url
 * @returns
 */
export const isUrl = (url) => {
	var regexp =
		/(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/g;
	return regexp.test(url);
};

/**
 * Get link_type information from url
 *
 * @param {string} url
 * @returns
 */
export const getLinkInfoFromUrl = (url) => {
	if (!isUrl(url)) {
		return AVAILABLE_LINK_LIST.general;
	}

	return Object.values(AVAILABLE_LINK_LIST).find(
		(item) => isUrl(url) && url.includes(item.linkUrl)
	);
};

/**
 * Get clickable link
 *
 * @param {string} link
 * @returns
 */
export const getClickableLink = (link) => {
	return link.startsWith("http://") || link.startsWith("https://") ? link : `//${link}`;
};

/**
 * Get difference elements between 2 arrays
 * ret = dest - base
 *
 * @param {*} dest
 * @param {*} base
 */
export const differences = (dest, base) => {
	if (!dest?.length) {
		return [];
	}

	return dest.filter((destItem) => !base.includes(destItem)) || [];
};

/**
 * Get email type information for global_email_details
 *
 * @param {number} typeC - email_type_c in global_email_details
 * @returns
 */
export const getEmailTypeInfo = (typeC) => {
	return GLOBAL_EMAIL_TYPE_OPTIONS.find((item) => item.value === typeC);
};

/**
 * Get phone type information for global_phone_details
 *
 * @param {number} typeC - phone_type_c in global_phone_details
 * @returns
 */
export const getPhoneTypeInfo = (typeC) => {
	return GLOBAL_PHONE_TYPE_OPTIONS.find((item) => item.value === typeC);
};

/**
 * Get date string with UTC format
 * @param {String} date
 * @returns
 */
export const getUTCDate = (date) => {
	if (date) {
		return moment(date).utc().toISOString();
	}

	return "";
};

/**
 * Get date string from UTC to locale
 * @param {String} date
 * @returns
 */
export const getLocaleDate = (date) => {
	if (date) {
		const utcString = date.replace(" ", "T") + "Z";
		return moment(`${utcString}`).toLocaleString();
	}
};

/**
 * Get information for activity view type
 *
 * @param {Number} activityViewTypeC :  refer to ACTIVITY_VIEW_TYPES
 * @returns
 */
export const getActivityViewTypeInfo = (activityViewTypeC) => {
	return (
		Object.values(ACTIVITY_VIEW_TYPES).find(
			(item) => item.value === activityViewTypeC
		) || ACTIVITY_VIEW_TYPES.note
	);
};
