
const personActivities = [
    {
        category: 'phone',
        status: 'scheduled',
        subject: 'Call Regarding Job 1156 - Software Engineer',
        content: 'Because I have a meeting with him now',
        tags: [
            'on Click on icon within badge',
            '1234'
        ],
        data: '12/01/2020',
        iconStatus: 'return',
    },
    {
        category: 'phone',
        status: 'scheduled',
        subject: 'Call Regarding Job 1156 - Software Engineer',
        content: 'Because I have a meeting with him now',
        tags: [
            'on Click on icon within badge',
            '1234'
        ],
        data: '12/01/2020',
        iconStatus: 'return',
    },
    {
        category: 'phone',
        status: 'scheduled',
        subject: 'Call Regarding Job 1156 - Software Engineer',
        content: 'Because I have a meeting with him now',
        tags: [
            'on Click on icon within badge',
            '1234'
        ],
        data: '12/01/2020',
        iconStatus: 'return',
    }
];

export {
    personActivities,
};
