import { MACROS_LIST } from "./actionTypes";

/**
 * Functions
 */
const setMacroList = (state, payload) => {
    return {
        ...state,
        macroList: payload,
    };
};

/**
 * Initial State
 */
const initialState = {
    macroList: null,
};

const Reducer = (state = initialState, action) => {
    switch (action.type) {
        case MACROS_LIST:
            return setMacroList(state, action.payload);

        default:
            return state;
    }
};

export default Reducer;
