import React from "react";
import styled from "styled-components";

import MessageFilter from "./MessageFilter";
import variables from "assets/css/variables.scss";

/**
 * Main Components
 */
const MessageHeader = (props) => {
	const { title, count = 0, ...restProps } = props;

	return (
		<Wrapper {...restProps}>
			<p className="title">{title}</p>
			<div className="count-badge">{count}</div>
			<MessageFilter />
		</Wrapper>
	);
};

export default MessageHeader;

/**
 * Styled Components
 */
const Wrapper = styled.div`
	display: flex;
	justify-content: space-between;

	.title {
		font-weight: bold;
		margin-top: auto;
		margin-bottom: auto;
	}

	.count-badge {
		background: ${variables.linkColor};
		color: #ffffff;
		border-radius: 4px;
		margin: auto auto auto 0.5rem;
		padding-left: 0.3rem;
		padding-right: 0.3rem;
	}
`;
