import React, { useState } from "react";
import {
	EuiFlexItem,
	EuiFlexGroup,
	EuiAvatar,
	EuiFieldText,
	EuiButtonIcon,
	EuiTextArea,
} from "@elastic/eui";
import styled from "styled-components";
import { getDate } from "helpers/utilities";

/**
 * Component for New Note List Item of AMS
 */
const CompensationNoteListNewItem = (props) => {
	const { onSave, onCancel } = props;

	// Reference ams_activity_note model
	const [note, setNote] = useState({
		id: null,
		index: -1,
		name: "",
		date_created: null,
		note_text: "",
	});

	const handleSave = () => {
		const _note = {
			...note,
			date_created: getDate(new Date(), "YYYY-MM-DD HH:mm:ss"),
		};
		onSave(_note);
	};

	const handleCancel = () => {
		onCancel();
	};

	const handleChangeNoteText = (e) => {
		const noteText = e.target.value;
		setNote({ ...note, note_text: noteText });
	};

	return (
		<Wrapper>
			<EuiFlexGroup gutterSize="s">
				<EuiFlexItem grow={false}>
					<EuiAvatar size="s" name={note.name} />
				</EuiFlexItem>
				<EuiFlexItem>
					<div className="note__container">
						<EuiFlexGroup direction="column" gutterSize="s">
							<EuiFlexItem>
								<div className="note-content_container">
									<div className="note-date">
										<p>
											<span className="type-p-regular">
												{note.name}
											</span>
											&nbsp;
											<span>adding a comment</span>
										</p>
									</div>
									<div className="note-comment">
										<EuiTextArea
											placeholder="Enter note"
											value={note.note_text}
											onChange={handleChangeNoteText}
											className="textfield-fit-conent"
										/>
									</div>
								</div>
							</EuiFlexItem>
							<EuiFlexItem grow={false}>
								<EuiFlexGroup gutterSize="s" justifyContent="center">
									<EuiFlexItem grow={false}>
										<EuiButtonIcon
											color="success"
											onClick={handleSave}
											iconType="check"
										/>
									</EuiFlexItem>
									<EuiFlexItem grow={false}>
										<EuiButtonIcon
											color="danger"
											onClick={handleCancel}
											iconType="cross"
										/>
									</EuiFlexItem>
								</EuiFlexGroup>
							</EuiFlexItem>
						</EuiFlexGroup>
					</div>
				</EuiFlexItem>
			</EuiFlexGroup>
		</Wrapper>
	);
};

export default CompensationNoteListNewItem;

const Wrapper = styled.div`
	display: flex;
	width: 100%;
	position: relative;

	.note__container {
		width: 100%;
		margin-bottom: var(--sp-5);
	}

	.icon-button-img {
		align-items: center;
		display: flex;
	}

	.note-content_container {
		width: 100%;

		.note-date {
			display: flex;
			justify-content: space-between;
			align-items: center;

			padding: 0 var(--sp-5);
			background: linear-gradient(0deg, #f5f7fa, #f5f7fa);
			border: 1px solid #d4dae5;
			box-sizing: border-box;
			border-radius: var(--sp-3) var(--sp-3) 0 0;
		}

		.note-comment {
			padding: var(--sp-3) var(--sp-4);
			border: 1px solid #d4dae5;
			box-sizing: border-box;
			border-radius: 0 0 var(--sp-3) var(--sp-3);
			border-top: none;
		}
	}
`;
