import { get, isNil, omitBy } from "lodash";

import API from "api";
import Service from "core/service";
import { removeEmpty, getResultFromApiResponse } from "helpers/utilities";
import * as MODEL from "core/model";

import * as actionTypes from "./actionTypes";
import { getGCSValue } from "components/global/utils";
import { setNotification } from "store/notification";
import { logError } from "../error";
import { WORKFLOW_ITEM_STATE_OPTIONS } from "components/Pages/Campaign/CampaignEdit/CampaignTabs/CampaignQueue/constants";

export const getCampaigns = (data) => {
	return {
		type: actionTypes.GET_CAMPAIGNS,
		payload: data,
	};
};

export const setCampaigns = (data) => {
	return {
		type: actionTypes.SET_CAMPAIGNS,
		payload: data,
	};
};

export const fetchCampaigns = (
	param = {
		page: 1,
		per_page: 30,
		filters: {
			include: ["campaign_main"],
		},
	}
) => {
	return async (dispatch) => {
		try {
			const result = await API().Campaigns().getCampaignList(param);

			const data = get(result, "response.data");
			const totalCount = get(result, "response.total_count");

			if (data && totalCount) {
				//dispatch(setLoading(false))
				dispatch(
					getCampaigns({
						message: "Success getting campaigns",
						error: null,
					})
				);
				dispatch(setCampaigns({ data, totalCount, pageNumber: param.page }));
			} else {
				//alert('System Error Logs empty: ' + JSON.stringify({ totalCount }))
				dispatch(setCampaigns({ data: [], totalCount: 0, pageNumber: 1 }));
			}
		} catch (err) {
			dispatch(getCampaigns({ message: "Error Fetch Campaign", err }));
			dispatch(setCampaigns({ data: [], totalCount: 0, pageNumber: 1 }));
		}
	};
};

export const setCampaign = (data) => {
	return {
		type: actionTypes.SET_CAMPAIGN,
		payload: data,
	};
};

export const setWorkflows = (data) => {
	return {
		type: actionTypes.SET_WORKFLOWS,
		payload: data,
	};
};

export const setWorkflow = (data) => {
	return {
		type: actionTypes.SET_WORKFLOW,
		payload: data,
	};
};

export const setWorkflowTemplates = (data) => {
	return {
		type: actionTypes.SET_WORKFLOW_TEMPLATE_LIST,
		payload: data,
	};
};

export const setWorkflowTemplate = (data) => {
	return {
		type: actionTypes.SET_WORKFLOW_TEMPLATE,
		payload: data,
	};
};

export const updateWorkflowForCampaign = (data) => {
	return {
		type: actionTypes.SAVE_WORKFLOW,
		payload: data,
	};
};

export const saveCampaign = (data) => {
	return {
		type: actionTypes.SAVE_CAMPAIGNS,
		payload: data,
	};
};

export const saveTemplate = (data) => {
	return {
		type: actionTypes.SAVE_TEMPLATE,
		payload: data,
	};
};

export const createTemplate = (data, successCallback, failCallback) => {
	return async (dispatch) => {
		try {
			const dataId = data?.id;
			const templateDetailId = data?.templateDetailId;
			const payload = {
				template_main: {
					...(dataId ? { id: dataId } : {}),
					template_name: data.name,
					template_type_c: 1,
					template_status_c: 1,
				},
				template_details: [
					{
						...(templateDetailId ? { id: templateDetailId } : {}),
						template_delivery_type_c: 1,
						template_subject: data.subject,
						template_body: data.body,
						template_settings: data.settings,
						template_note: data.note,
					},
				],
				// template_attachment: [{
				// 	attachment_type_sc: 1,
				// 	attachment_ref_id: 1,
				// 	attachment_details: data.attachmentDetails
				// }],
				// template_permission: [{
				// 	security_group_ref_id: data.securityGroupRefId
				// }],
				// ras_assignment: [{
				// 	assignment_owner_ref_id: data.assignmentOwnerId
				// }],
				// qualifier_assignment: [{
				// 	qualifier_main_ref_id: data.qualifierId
				// }],
				// template_association: [{
				// 	ref_object_ref_id: data.templateAssociation
				// }]
			};

			const filtered = removeEmpty(payload);

			const result = await API().Campaigns().saveTemplates(filtered);
			dispatch(saveTemplate(result));

			if (successCallback) {
				successCallback(result);
			}
		} catch (err) {
			dispatch(saveTemplate(err));
			if (failCallback) {
				failCallback(err);
			}
		}
	};
};

export const createUpdateSignatureTemplate = (data) => {
	return async (dispatch) => {
		try {
			let payload = {};

			payload = {
				template_main: {
					template_name: data.name,
					template_type_c: 3,
					template_status_c: 1,
				},
				template_details: [
					{
						id: data.templateDetailId,
						template_delivery_type_c: 1,
						template_subject: data.subject,
						template_body: data.body,
						template_settings: data.settings,
						template_note: data.note,
					},
				],
				// template_attachment: [{
				// 	attachment_type_sc: 1,
				// 	attachment_ref_id: 1,
				// 	attachment_details: data.attachmentDetails
				// }],
				// template_permission: [{
				// 	security_group_ref_id: data.securityGroupRefId
				// }],
				ras_assignment: [
					{
						assignment_owner_ref_id: data.assignmentOwnerId,
					},
				],
				// qualifier_assignment: [{
				// 	qualifier_main_ref_id: data.qualifierId
				// }],
				// template_association: [{
				// 	ref_object_ref_id: data.templateAssociation
				// }]
			};

			const filtered = removeEmpty(payload);

			const result = await API().Campaigns().saveTemplates(filtered);
			dispatch(saveTemplate(result));
		} catch (err) {
			dispatch(saveTemplate(err));
		}
	};
};

export const saveWorkflowConfigData = (data) => {
	return {
		type: actionTypes.SAVE_WORKFLOW_CONFIG,
		payload: data,
	};
};

export const saveWorkflowConfig = (data) => {
	return async (dispatch) => {
		try {
			const payload = {
				campaign_item_details: {
					campaign_item_ref_id: data.campaignId,
					id: data.campaignDetailId,
					item_details: {
						workflow: {
							schedules: data.schedules,
						},
					},
				},
			};
			const filtered = removeEmpty(payload);
			const result = await Service.campaign.updateCampaign(filtered);
			dispatch(saveWorkflowConfigData(result));
		} catch (err) {
			dispatch(saveWorkflowConfigData(err));
		}
	};
};

export const initializedCampaignSchedules = (data) => {
	return async (dispatch) => {
		try {
			const payload = {
				campaign_item_details: {
					campaign_item_ref_id: data.campaignItemId,
					//id: data.campaignItemId,
					item_details: {
						workflow: {
							schedules: [
								{
									type: "email",
									recurring: true,
									times: [
										"7:00-20:00",
										"7:00-20:00",
										"7:00-20:00",
										"7:00-20:00",
										"7:00-20:00",
										"7:00-20:00",
										"7:00-20:00",
									],
									days: [
										"Monday",
										"Tuesday",
										"Wednesday",
										"Thursday",
										"Friday",
										"Saturday",
										"Sunday",
									],
								},
								{
									type: "sms",
									recurring: true,
									times: [
										"7:00-20:00",
										"7:00-20:00",
										"7:00-20:00",
										"7:00-20:00",
										"7:00-20:00",
										"11:00-16:00",
										"11:00-16:00",
									],
									days: [
										"Monday",
										"Tuesday",
										"Wednesday",
										"Thursday",
										"Friday",
										"Saturday",
										"Sunday",
									],
								},
							],
						},
					},
				},
			};
			const filtered = removeEmpty(payload);
			const result = await Service.campaign.updateCampaign(filtered);
			dispatch(saveWorkflowConfigData(result));
		} catch (err) {
			dispatch(saveWorkflowConfigData(err));
		}
	};
};

export const saveWorkflowState = (data) => {
	return async (dispatch) => {
		try {
			const payload = {
				campaign_main: {
					campaign_main_id: data.id,
				},
				//Intial Campaign items includes default values for send times schedule and text send
				campaign_item: [
					{
						campaign_workflow_id: data.campaign_workflow_id,
					},
				],
				campaign_credential: [
					{
						credential_main_ref_id: data.credentialId,
					},
				],
			};
			const filtered = removeEmpty(payload);
			const result = await Service.campaign.updateCampaign(filtered);

			dispatch(saveCampaign(result));
		} catch (err) {
			dispatch(saveCampaign(err));
		}
	};
};

export const saveCurrentSchedule = (data) => {
	return async (dispatch) => {
		try {
			const payload = {
				campaign_main: {
					campaign_main_id: data.id,
					campaign_details: {
						workflow: {
							schedules: data.schedules,
						},
					},
				},
			};
			const filtered = removeEmpty(payload);
			const result = await Service.campaign.updateCampaign(filtered);

			dispatch(saveCampaign(result));
		} catch (err) {
			dispatch(saveCampaign(err));
		}
	};
};

export const saveCampaignWithWorkflow = (data) => {
	return async (dispatch) => {
		try {
			const payload = {
				campaign_main: {
					campaign_main_id: data.campaignId,
					credential_main_ref_id: data.credentialId,
					workflow_main_ref_id: data.workflowMainId,
				},
			};
			const filtered = removeEmpty(payload);
			const result = await Service.campaign.updateCampaign(filtered);

			dispatch(saveCampaign(result));
		} catch (err) {
			dispatch(saveCampaign(err));
		}
	};
};

/* --------------------------- NEW --------------------------------- */
export const setCampaignList_NEW = (data) => {
	return {
		type: actionTypes.SET_CAMPAIGN_LIST_NEW,
		payload: data,
	};
};

export const requestCampaignList_NEW = () => {
	return {
		type: actionTypes.REQUEST_CAMPAIGN_LIST_NEW,
	};
};

export const setCampaignListParams = (data) => {
	return {
		type: actionTypes.SET_CAMPAIGN_LIST_PARAMS,
		payload: data,
	};
};

export const getCampaignList_NEW = (params, successCallback, failCallback) => {
	return async (dispatch, getState) => {
		const state = getState();
		const campaignListParams = state.campaign.campaignList_NEW;
		const isRequesting = campaignListParams.isRequesting;

		if (isRequesting) {
			return;
		}

		dispatch(requestCampaignList_NEW());

		const filters = params?.filters ?? campaignListParams.filters ?? {};
		const pageNumber = params?.page_number ?? campaignListParams.page_number ?? 1;
		const pageCount = params?.page_count ?? campaignListParams.page_count ?? 30;

		const _params = {
			page_number: pageNumber,
			page_count: pageCount,
			...omitBy(filters, isNil),
		};

		const payload = {
			page_number: pageNumber,
			page_count: pageCount,
			data: [],
			totalCount: 0,
			isRequesting: false,
			isReceived: true,
			filters,
		};

		try {
			const result = await Service.campaign.getCampaignList(_params);
			const data = get(result, "response.data", []);
			const totalCount = get(result, "response.total_count", 0);

			if (result && !result?.error) {
				payload.data = data?.map((item) => {
					return {
						...item.campaign_main,
						campaign_main_id: item.campaign_main.id,
					};
				});
				payload.totalCount = totalCount;

				if (totalCount === 0 && payload.page_number > 1) {
					payload.page_number = payload.page_number - 1;
				}

				if (successCallback) {
					successCallback({ data });
				}
			} else {
				if (failCallback) {
					failCallback({ error: "Unknown Error" });
				}
			}
		} catch (err) {
			if (failCallback) {
				failCallback(err);
			}
		} finally {
			dispatch(setCampaignList_NEW(payload));
		}
	};
};

export const setCampaignDetail_NEW = (data) => {
	return {
		type: actionTypes.SET_CAMPAIGN_DETAIL_NEW,
		payload: data,
	};
};

export const getCampaignDetail_NEW = (id, successCallback, failCallback) => {
	return async (dispatch) => {
		let detail = [];
		try {
			const data = await Service.campaign.getCampaignDetail({ id });
			const campaignDetail = get(data, `data[0].${MODEL.campaign_main._name}`);

			if (campaignDetail) {
				detail = campaignDetail;

				if (successCallback) {
					successCallback(campaignDetail);
				}
			} else {
				if (failCallback) {
					failCallback(data);
				}
			}
		} catch (err) {
			if (failCallback) {
				failCallback(err);
			}
		} finally {
			dispatch(setCampaignDetail_NEW(detail));
		}
	};
};

export const requestCampaignMainList_NEW = () => {
	return {
		type: actionTypes.REQUEST_CAMPAIGN_MAINLIST_NEW,
	};
};

export const setCampaignMainList_NEW = (data) => {
	return {
		type: actionTypes.SET_CAMPAIGN_MAINLIST_NEW,
		payload: data,
	};
};

export const getCampaignMainList_NEW = (params, successCallback, failCallback) => {
	return async (dispatch) => {
		dispatch(requestCampaignMainList_NEW());

		const _params = {
			searchTerm: params?.searchTerm ?? "",
			pageNumber: params?.pageNumber ?? 1,
			pageCount: params?.pageCount ?? 30,
			param: params?.param ?? null,
		};

		const payload = {
			searchTerm: _params.searchTerm,
			pageNumber: _params.pageNumber,
			pageCount: _params.pageCount,
			totalCount: 0,
			data: [],
		};

		try {
			const data = await Service.campaign.getCampaignMainList(_params);

			if (data?.data) {
				const campaigns = data?.data?.map((item) => item.campaign_main);
				payload.data = campaigns;
				payload.totalCount = campaigns.length;

				if (successCallback) {
					successCallback(campaigns);
				}
			} else {
				if (failCallback) {
					failCallback(data);
				}
			}
		} catch (err) {
			if (failCallback) {
				failCallback(err);
			}
		} finally {
			dispatch(setCampaignMainList_NEW(payload));
		}
	};
};

export const createCampaign = (data, successCallback, failCallback) => {
	return async (dispatch, getState) => {
		const campaignResult = null;
		const state = getState();
		const globals = get(state, "global.globals", null);
		let errMsg = null;
		const details = data.details || {};
		const associationsCampaign = data.associationsCampaign || [];

		try {
			const payloadCampaign = [
				{
					[MODEL.campaign_main._name]: {
						[MODEL.campaign_main.id]: data.id,
						[MODEL.campaign_main.campaign_name]: data.name,
						[MODEL.campaign_main.campaign_type_c]: data.type,
						[MODEL.campaign_main.campaign_status_c]: data.status,
						[MODEL.campaign_main.record_state_sc]: data.record,
						[MODEL.campaign_main.campaign_details]: data?.details,
					},
				},
				...associationsCampaign,
			];

			const result = await Service.campaign.updateCampaign(payloadCampaign);

			if (result && !result.error) {
				const res = getResultFromApiResponse(result);

				const campaignId = get(
					res,
					`procedure_results.${MODEL.campaign_main._name}[0].${MODEL.campaign_main.id}`,
					null
				);

				if (campaignId && globals.length > 0) {
					const object_type_c = getGCSValue(
						globals,
						"ras_assignment_main",
						"object_type_c",
						"campaign"
					);
					const assignment_type_c_owner = getGCSValue(
						globals,
						"ras_assignment_main",
						"assignment_type_c",
						"owner"
					);
					const rasId = get(data, `rasId`, null);

					// Save RAS
					const payloadRAS = {
						[MODEL.ras_assignment_main._name]: {
							[MODEL.ras_assignment_main.id]: rasId,
							[MODEL.ras_assignment_main.object_type_c]: object_type_c,
							[MODEL.ras_assignment_main.object_ref_id]: campaignId,
							[MODEL.ras_assignment_main.assignment_type_c]:
								assignment_type_c_owner,
							[MODEL.ras_assignment_main.assignment_ref_id]:
								data.owner || 0,
						},
					};

					const resultRAS = await Service.ras.updateRAS(payloadRAS);

					if (!get(resultRAS, "error")) {
						const resRAS = getResultFromApiResponse(resultRAS);

						if (successCallback) {
							successCallback([
								res?.procedure_results,
								resRAS?.procedure_results,
							]);
						}
					} else {
						errMsg = resultRAS;
					}
				} else {
					errMsg = result;
				}
			} else {
				errMsg = result;
			}
		} catch (err) {
			errMsg = err;
		} finally {
			dispatch(saveCampaign(campaignResult));
			if (errMsg && failCallback) {
				failCallback(errMsg);
			}
		}
	};
};

export const updateCampaign = (params, successCallback, failCallback) => {
	return async (dispatch) => {
		try {
			const payload = params;
			const result = await Service.campaign.updateCampaign(payload);
			if (successCallback) {
				successCallback(result);
			}
		} catch (err) {
			if (failCallback) {
				failCallback(err);
			}
		} finally {
		}
	};
};

export const deleteCampaign = (id, successCallback, failCallback) => {
	return async (dispatch) => {
		try {
			const payload = {
				[MODEL.campaign_main._name]: {
					[MODEL.campaign_main.id]: id,
				},
			};
			const result = await Service.campaign.deleteCampaign(payload);
			if (successCallback) {
				successCallback(result);
			}
		} catch (err) {
			if (failCallback) {
				failCallback(err);
			}
		} finally {
		}
	};
};

export const deleteGeneralCampaign = (params, successCallback, failCallback) => {
	return async (dispatch) => {
		try {
			const payload = params;
			const result = await Service.campaign.deleteCampaign(payload);
			if (successCallback) {
				successCallback(result);
			}
		} catch (err) {
			if (failCallback) {
				failCallback(err);
			}
		} finally {
		}
	};
};

export const copyCampaign = (data, successCallback, failCallback) => {
	return async (dispatch) => {
		try {
			const payload = {
				campaign_id: data?.campaign_id,
				copy_campaign_items: data?.copy_campaign_items,
				copy_workflow: data?.copy_workflow,
				copy_permissions_campaign: data?.copy_permissions_campaign,
				copy_permissions_workflow: data?.copy_permissions_workflow,
			};
			const result = await Service.campaign.actionCopyCampaign(payload);

			if (result) {
				if (successCallback) {
					successCallback(result);
				}
			}
		} catch (err) {
			if (failCallback) {
				failCallback(err);
			}
		}
	};
};

export const saveCampaignItems = (params, successCallback, failCallback) => {
	return async (dispatch) => {
		try {
			const payload = params || [];

			const result = await Service.campaign.saveCampaignItems(payload);

			if (result) {
				if (successCallback) {
					successCallback(result);
				}
			}
		} catch (err) {
			if (failCallback) {
				failCallback(err);
			}
		}
	};
};

export const deleteCampaignItems = (params, successCallback, failCallback) => {
	return async (dispatch) => {
		try {
			const payload = {
				campaign_id: params?.campaign_id || null,
				data: params?.data || [],
			};

			const result = await Service.campaign.deleteCampaignItems(payload);

			if (result) {
				if (successCallback) {
					successCallback(result);
				}
			}
		} catch (err) {
			if (failCallback) {
				failCallback(err);
			}
		}
	};
};

export const executeCampaign = (data, successCallback, failCallback) => {
	return async (dispatch) => {
		try {
			const execution = data.execution;

			const payload = {
				campaign_main: {
					campaign_main_id: data.id,
				},
				execution: execution,
			};

			const result = await Service.campaign.executeCampaign(payload);
			if (result && !result?.error) {
				if (successCallback) {
					successCallback(result);
				}
			} else {
				if (result?.error) {
					dispatch(
						setNotification({
							type: "normal",
							title: "Campaign did not execute successfully.",
							message: `${result?.message || "-"}`,
							status: "error",
						})
					);
				}

				if (failCallback) {
					failCallback(result);
				}
			}
		} catch (err) {
			dispatch(
				setNotification({
					type: "normal",
					title: `${err?.data || "Campaign did not execute successfully."}`,
					message: `${err?.message || "-"}`,
					status: "error",
				})
			);

			if (failCallback) {
				failCallback(err);
			}
		}
	};
};

export const getWorkflow = (id, successCallback, failCallback) => {
	return async (dispatch) => {
		let res = {};
		try {
			const payload = {
				id: id,
			};

			const result = await Service.workflow.getWorkflow(payload);

			const data = get(result, "data[0].workflow_main");

			if (data) {
				res = data;
				if (successCallback) {
					successCallback(data);
				}
			} else {
				if (failCallback) {
					failCallback(result);
				}
			}
		} catch (err) {
			if (failCallback) {
				failCallback(err);
			}
		} finally {
			dispatch(setWorkflow(res));
		}
	};
};

export const getWorkflows = (params, successCallback, failCallback) => {
	return async (dispatch) => {
		let res = {};
		try {
			const payload = {
				offset: params?.offset || 1,
				rowcount: params?.rowcount || 100,
			};

			const result = await Service.workflow.getWorkflowList(payload);

			const data = get(result, `response.data`, []);

			if (data) {
				res = data;
				if (successCallback) {
					successCallback(data);
				}
			} else {
				if (failCallback) {
					failCallback(result);
				}
			}
		} catch (err) {
			if (failCallback) {
				failCallback(err);
			}
		} finally {
			dispatch(setWorkflows(res));
		}
	};
};

export const saveWorkflowForCampaign = (params, successCallback, failCallback) => {
	return async (dispatch) => {
		let res = {};
		try {
			const payload = {
				[MODEL.workflow_main._name]: {
					[MODEL.workflow_main.id]: params?.id,
					[MODEL.workflow_main.workflow_name]: params?.name || "",
					[MODEL.workflow_main.workflow_construct]:
						params?.workflowConstruct || null,
					[MODEL.workflow_main.workflow_status_c]: params?.workflowStatus || 0,
					[MODEL.workflow_main.workflow_type_c]: params?.workflowType || 0,
				},
			};

			if (params?.id && params?.campaignId) {
				payload[MODEL.workflow_item_queue._name] = {
					[MODEL.workflow_item_queue.workflow_main_ref_id]: params?.id,
					[MODEL.workflow_item_queue.campaign_item_ref_id]: params?.campaignId,
				};
			}

			const filtered = removeEmpty(payload);

			const result = await Service.workflow.updateWorkflow(filtered);

			if (!get(result, ["error"])) {
				if (successCallback) {
					res = getResultFromApiResponse(result);
					successCallback(res?.procedure_results);
				}
			} else {
				if (failCallback) {
					failCallback(result);
				}
			}
		} catch (err) {
			if (failCallback) {
				failCallback(err);
			}
		} finally {
			dispatch(updateWorkflowForCampaign(res));
		}
	};
};

export const updateWorkflow = (params, successCallback, failCallback) => {
	return async (dispatch) => {
		let res = {};
		try {
			const payload = {
				[MODEL.workflow_main._name]: {
					[MODEL.workflow_main.id]: params?.id,
					[MODEL.workflow_main.workflow_name]: params?.name || "",
					[MODEL.workflow_main.workflow_construct]:
						params?.workflowConstruct || null,
					[MODEL.workflow_main.workflow_status_c]: params?.workflowStatus || 0,
					[MODEL.workflow_main.workflow_type_c]: params?.workflowType || 0,
				},
			};

			const filtered = removeEmpty(payload);

			const result = await Service.workflow.updateWorkflow(filtered);

			if (!get(result, "error")) {
				if (successCallback) {
					res = getResultFromApiResponse(result);

					dispatch(updateWorkflowForCampaign(res));
					successCallback(res?.procedure_results);
				}
			} else {
				dispatch(updateWorkflowForCampaign(res));

				if (failCallback) {
					failCallback(result);
				}
			}
		} catch (err) {
			dispatch(updateWorkflowForCampaign(res));

			if (failCallback) {
				failCallback(err);
			}
		}
	};
};

export const updateWorkflowNormal = (params, successCallback, failCallback) => {
	return async (dispatch) => {
		let res = {};
		try {
			const payload = {
				[MODEL.workflow_main._name]: params,
			};

			const filtered = removeEmpty(payload);

			const result = await Service.workflow.updateWorkflow(filtered);

			if (!get(result, "error")) {
				if (successCallback) {
					res = getResultFromApiResponse(result);

					dispatch(updateWorkflowForCampaign(res));
					successCallback(res?.procedure_results);
				}
			} else {
				dispatch(updateWorkflowForCampaign(res));

				if (failCallback) {
					failCallback(result);
				}
			}
		} catch (err) {
			dispatch(updateWorkflowForCampaign(res));

			if (failCallback) {
				failCallback(err);
			}
		}
	};
};

export const deleteWorkflow = (id, successCallback, failCallback) => {
	return async (dispatch) => {
		let res = {};
		try {
			const payload = {
				[MODEL.workflow_main._name]: {
					[MODEL.workflow_main.id]: id,
				},
			};

			const result = await Service.workflow.deleteWorkflow(payload);

			if (!get(result, ["error"])) {
				res = getResultFromApiResponse(result);
				if (successCallback) {
					successCallback(res?.procedure_results);
				}
			} else {
				if (failCallback) {
					failCallback(result);
				}
			}
		} catch (err) {
			if (failCallback) {
				failCallback(err);
			}
		} finally {
			dispatch(updateWorkflowForCampaign(res));
		}
	};
};

const getCampaignTemplatesData = (data) => {
	return {
		type: actionTypes.GET_TEMPLATES,
		payload: data,
	};
};

export const getCampaignTemplates = (params, successCallback, failCallback) => {
	return async (dispatch) => {
		let res = {};
		try {
			const _params = {
				page: params?.page || 1,
				per_page: params?.per_page || 30,
				search_name_term: params?.search_name_term || null,
				search_subject_term: params?.search_subject_term || null,
				search_body_term: params?.search_body_term || null,
				association: params?.association || null,
			};

			const removeEmptyObject = removeEmpty(_params);
			const result = await Service.template.getTemplateList(removeEmptyObject);
			const data = get(result, "response.data");

			if (data) {
				res = data;

				if (successCallback) {
					successCallback(data);
				}
			} else {
				if (failCallback) {
					failCallback(result);
				}
			}
		} catch (err) {
			if (failCallback) {
				failCallback(err);
			}
		} finally {
			dispatch(getCampaignTemplatesData(res));
		}
	};
};

export const getCampaignTemplateData = (data) => {
	return {
		type: actionTypes.GET_TEMPLATE,
		payload: data,
	};
};

export const getCampaignTemplate = (templateId, successCallback, failCallback) => {
	return async (dispatch) => {
		let res = {};
		try {
			const payload = {
				id: templateId,
			};
			const result = await Service.template.getTemplate(payload);
			const data = get(result, "data[0].template_main");

			if (data) {
				res = data;
				if (successCallback) {
					successCallback(data);
				}
			} else {
				if (failCallback) {
					failCallback(result);
				}
			}
		} catch (err) {
			if (failCallback) {
				failCallback(err);
			}
		} finally {
			dispatch(getCampaignTemplateData(res));
		}
	};
};

/**
 * Save workflow items list of a campaign into store
 */
export const setWorkflowItems = (data) => {
	return {
		type: actionTypes.SET_CAMPAIGN_WORKFLOW_ITEMS_LIST,
		payload: data,
	};
};

const requestWorkflowItems = () => {
	return {
		type: actionTypes.REQUEST_CAMPAIGN_WORKFLOW_ITEMS_LIST,
	};
};

export const setWorkflowItemsParams = (data) => {
	return {
		type: actionTypes.SET_CAMPAIGN_WORKFLOW_ITEMS_PARAMS,
		payload: data,
	};
};

/**
 * Get Workflow Items List
 *
 * @param {*} params
 * @param {*} successCallback
 * @param {*} failCallback
 */
export const getCampaignWorkflowItems = (params, successCallback, failCallback) => {
	return async (dispatch, getState) => {
		const state = getState();
		const curWorkflowItems = state.campaign.workflowItems;

		if (curWorkflowItems.isRequesting) {
			return;
		}

		dispatch(requestWorkflowItems());

		const states = params?.states ?? curWorkflowItems.states;

		const _params = {
			page_number: params?.page ?? curWorkflowItems.page ?? 1,
			page_count: params?.per_page ?? curWorkflowItems.per_page ?? 30,
			workflow_main_id:
				params?.workflow_main_id ?? curWorkflowItems.workflow_main_id ?? 0,
			filter: params?.filter ?? curWorkflowItems.filter ?? "item",
			states,
		};

		const payload = {
			page: _params.page_number,
			per_page: _params.page_count,
			totalCount: 0,
			data: [],
			filter: _params.filter,
			workflow_main_id: _params.workflow_main_id,
			...(_params?.states ? { states: _params?.states } : {}),
		};

		try {
			const result = await Service.workflow.getWorkflowItemsList(_params);
			const filterOption =
				WORKFLOW_ITEM_STATE_OPTIONS.find(
					(item) => item.filter.filter === payload?.filter
				) || WORKFLOW_ITEM_STATE_OPTIONS[0];
			const data = get(result, `response[0].${filterOption.returnKey}`, []);
			const totalCount = get(result, "response[0].total_count", 0);

			if (data) {
				payload.data = data;
				payload.totalCount = totalCount || 0;

				if (successCallback) {
					successCallback();
				}
			}
		} catch (err) {
			dispatch(
				logError("fetch_failure", "none", {
					Error: "Error fetching company data: " + JSON.stringify(err),
				})
			);

			if (failCallback) {
				failCallback();
			}
		} finally {
			dispatch(setWorkflowItems(payload));
		}
	};
};

/**
 * Save person list of a campaign into store
 */
const setCampaignPersonList = (data) => {
	return {
		type: actionTypes.SET_CAMPAIGN_PERSON_LIST,
		payload: data,
	};
};

const requestCampaignPersonList = () => {
	return {
		type: actionTypes.REQUEST_CAMPAIGN_PERSON_LIST,
	};
};

export const setCampaignPersonListParams = (data) => {
	return {
		type: actionTypes.SET_CAMPAIGN_PERSON_LIST_PARAMS,
		payload: data,
	};
};

/**
 * Get Workflow Items List
 *
 * @param {*} params
 * @param {*} successCallback
 * @param {*} failCallback
 */
export const getCampaignPersonList = (params, successCallback, failCallback) => {
	return async (dispatch, getState) => {
		const state = getState();
		const curCampaignPersonList = state.campaign.campaignPersonList;

		if (curCampaignPersonList.isRequesting) {
			return;
		}

		dispatch(requestCampaignPersonList());

		const _params = {
			page_number: params?.page ?? curCampaignPersonList.page ?? 1,
			page_count: params?.per_page ?? curCampaignPersonList.per_page ?? 30,
			search_term: params?.search_term ?? curCampaignPersonList.search_term ?? "",
			filter: params?.filter ?? curCampaignPersonList.filter ?? [],
		};

		const payload = {
			page: _params.page_number,
			per_page: _params.page_count,
			totalCount: 0,
			data: [],
			ids: [],
			filter: _params.filter,
			search_term: _params.search_term,
		};

		try {
			const result = await Service.person.getPersonList(_params);
			const idsRes = await Service.person.getPersonIdList(payload);

			const data = get(result, "response.data", []);
			const totalCount = get(result, "response.total_count", 0);
			const ids = get(idsRes, "response", []);

			if (data) {
				payload.data = data;
				payload.totalCount = totalCount || 0;
				payload.ids = ids;

				if (successCallback) {
					successCallback();
				}
			}
		} catch (err) {
			dispatch(
				logError("fetch_failure", "none", {
					Error: "Error fetching company data: " + JSON.stringify(err),
				})
			);

			if (failCallback) {
				failCallback();
			}
		} finally {
			dispatch(setCampaignPersonList(payload));
		}
	};
};

/**
 * Job list for campaign create
 */
export const getJobMainListForCreateCampaign = (
	searchTerm,
	successCallback,
	failCallback
) => {
	return async (dispatch) => {
		const _params = {
			pageNumber: 1,
			pageCount: 20,
			searchTerm,
		};

		const payload = {
			data: [],
			searchTerm: _params.searchTerm,
		};

		try {
			const result = await Service.job.getJobMainList(_params);
			const data = get(result, "data", []);

			if (data) {
				payload.data = data;

				if (successCallback) {
					successCallback();
				}
			}
		} catch (err) {
			dispatch(
				logError("fetch_failure", "none", {
					Error: "Error fetching company data: " + JSON.stringify(err),
				})
			);

			if (failCallback) {
				failCallback();
			}
		} finally {
			dispatch({
				type: actionTypes.SET_CAMPAIGN_CREATE_JOB_LIST,
				payload: payload,
			});
		}
	};
};

/**
 * Get Workflow Summary information
 *
 * @param {String, Number} id
 * @param {Function} successCallback
 * @param {Function} failCallback
 * @returns
 */
const setWorkflowSummary = (data) => {
	return {
		type: actionTypes.SET_WORKFLOW_SUMMARY,
		payload: data,
	};
};

export const getWorkflowSummary = (id, successCallback, failCallback) => {
	return async (dispatch) => {
		let res = {};

		try {
			dispatch(setWorkflowSummary({ isLoading: true }));

			const payload = {
				id: id,
			};

			const result = await Service.workflow.getWorkflowSummary(payload);

			const data = get(result, "response.summary");

			if (data) {
				res = data;
				if (successCallback) {
					successCallback(data);
				}
			} else {
				if (failCallback) {
					failCallback(result);
				}
			}
		} catch (err) {
			if (failCallback) {
				failCallback(err);
			}
		} finally {
			dispatch(setWorkflowSummary({ ...res, isLoading: false }));
		}
	};
};

/**
 * Get Workflow Step Details information
 *
 * @param {id, nodeId} params
 * @param {Function} successCallback
 * @param {Function} failCallback
 * @returns
 */
const setWorkflowStepDetails = (data) => {
	return {
		type: actionTypes.SET_WORKFLOW_NODE_DETAILS,
		payload: data,
	};
};

export const getWorkflowStepDetails = (params, successCallback, failCallback) => {
	return async (dispatch) => {
		let res = {};
		try {
			dispatch(setWorkflowStepDetails({ isLoading: true }));

			const payload = {
				id: params?.id || null,
				nodeId: params?.nodeId || null,
			};

			const result = await Service.workflow.getWorkflowStepDetails(payload);

			const data = get(result, "response");

			if (data) {
				res = data;
				if (successCallback) {
					successCallback(data);
				}
			} else {
				if (failCallback) {
					failCallback(result);
				}
			}
		} catch (err) {
			if (failCallback) {
				failCallback(err);
			}
		} finally {
			dispatch(setWorkflowStepDetails({ ...res, isLoading: false }));
		}
	};
};

/**
 * Get person list for job applicant table
 */
export const getPersonListForCampaign = (params, successCallback, failCallback) => {
	return async (dispatch) => {
		try {
			const result = await Service.person.getPersonList(params);
			const data = get(result, "response.data", null);

			if (data) {
				if (successCallback) {
					successCallback(data);
				}
			} else {
				if (failCallback) {
					failCallback(result);
				}
			}
		} catch (err) {
			dispatch(
				logError("fetch_failure", "none", {
					Error: "Error fetching job application data: " + JSON.stringify(err),
				})
			);

			if (failCallback) {
				failCallback(err);
			}
		}
	};
};

///////////////////// Workflow Template ///////////////////////////////
export const getWorkflowTemplate = (id, successCallback, failCallback) => {
	return async (dispatch) => {
		let res = {};
		try {
			const payload = {
				id: id,
			};

			const result = await Service.workflow.getWorkflowTemplate(payload);

			const data = get(result, "data[0].workflow_main");

			if (data) {
				res = data;
				if (successCallback) {
					successCallback(data);
				}
			} else {
				if (failCallback) {
					failCallback(result);
				}
			}
		} catch (err) {
			if (failCallback) {
				failCallback(err);
			}
		} finally {
			dispatch(setWorkflowTemplate(res));
		}
	};
};

export const getWorkflowTemplates = (params, successCallback, failCallback) => {
	return async (dispatch) => {
		let res = {};
		try {
			const payload = {
				page_number: params?.page_number || 0,
				page_count: params?.page_count || 100,
			};

			const result = await Service.workflow.getWorkflowTemplateList(payload);

			const data = get(result, `response.data`, []);

			if (data) {
				res = data;
				if (successCallback) {
					successCallback(data);
				}
			} else {
				if (failCallback) {
					failCallback(result);
				}
			}
		} catch (err) {
			if (failCallback) {
				failCallback(err);
			}
		} finally {
			dispatch(setWorkflowTemplates(res));
		}
	};
};

export const updateWorkflowTemplate = (params, successCallback, failCallback) => {
	return async (dispatch) => {
		let res = {};
		try {
			const payload = {
				[MODEL.workflow_template._name]: {
					[MODEL.workflow_template.id]: params?.id,
					[MODEL.workflow_template.workflow_name]: params?.name || "",
					[MODEL.workflow_template.workflow_construct]:
						params?.workflowConstruct || null,
					[MODEL.workflow_template.workflow_status_c]:
						params?.workflowStatus || 0,
					[MODEL.workflow_template.workflow_type_c]: params?.workflowType || 0,
				},
			};

			const filtered = removeEmpty(payload);

			const result = await Service.workflow.updateWorkflowTemplate(filtered);

			if (!get(result, ["error"])) {
				if (successCallback) {
					res = getResultFromApiResponse(result);

					successCallback(res?.procedure_results);
				}
			} else {
				if (failCallback) {
					failCallback(result);
				}
			}
		} catch (err) {
			if (failCallback) {
				failCallback(err);
			}
		}
	};
};

export const deleteWorkflowTemplate = (params, successCallback, failCallback) => {
	return async (dispatch) => {
		let res = {};
		try {
			const payload = params;

			const result = await Service.workflow.deleteWorkflow(payload);

			if (!get(result, ["error"])) {
				res = getResultFromApiResponse(result);
				if (successCallback) {
					successCallback(res?.procedure_results);
				}
			} else {
				if (failCallback) {
					failCallback(result);
				}
			}
		} catch (err) {
			if (failCallback) {
				failCallback(err);
			}
		}
	};
};

export const actionWorkflowRestart = (params, successCallback, failCallback) => {
	return async (dispatch) => {
		let res = {};
		try {
			const payload = params;

			const result = await Service.workflow.actionWorkflowRestart(payload);

			if (!get(result, ["error"])) {
				res = getResultFromApiResponse(result);
				if (successCallback) {
					successCallback(res?.procedure_results);
				}
			} else {
				if (failCallback) {
					failCallback(result);
				}
			}
		} catch (err) {
			if (failCallback) {
				failCallback(err);
			}
		}
	};
};

export const setWorkflowItemCounts = (data) => {
	return {
		type: actionTypes.SET_WORKFLOW_ITEM_COUNTS,
		payload: data,
	};
};

export const getWorkflowItemCounts = (params, successCallback, failCallback) => {
	return async (dispatch) => {
		let res = {};
		try {
			const payload = params;

			const result = await Service.workflow.getWorkflowItemCount(payload);

			if (!get(result, "error")) {
				res = get(result, "response[0]");
				dispatch(setWorkflowItemCounts(res));
				if (successCallback) {
					successCallback(res);
				}
			} else {
				dispatch(setWorkflowItemCounts(null));
				if (failCallback) {
					failCallback(result);
				}
			}
		} catch (err) {
			dispatch(setWorkflowItemCounts(null));
			if (failCallback) {
				failCallback(err);
			}
		}
	};
};

export const setCampaignListBySearch = (data) => {
	return {
		type: actionTypes.CAMPAIGN_LIST_BY_SEARCH,
		payload: data,
	};
};

export const getCampaignListBySearch = (params, successCallback, failCallback) => {
	return async (dispatch) => {
		let res = {
			data: [],
			totalCount: 0,
		};
		try {
			const _params = {
				...params,
				page_number: params?.page_number ?? 1,
				page_count: params?.page_count ?? 30,
			};

			const result = await Service.campaign.getCampaignList(_params);
			const data = get(result, "response.data", []);
			const totalCount = get(result, "response.total_count", 0);

			if (data?.length > 0) {
				res.data = data.map((item) => {
					return {
						...item.campaign_main,
						campaign_main_id: item.campaign_main.id,
					};
				});
				res.totalCount = totalCount;

				if (successCallback) {
					successCallback({ data });
				}
			} else {
				if (failCallback) {
					failCallback({ error: "Unknown Error" });
				}
			}
		} catch (err) {
			if (failCallback) {
				failCallback(err);
			}
		} finally {
			dispatch(setCampaignListBySearch(res));
		}
	};
};

export const setCampaignListByPerson = (data) => {
	return {
		type: actionTypes.CAMPAIGN_LIST_BY_PERSON,
		payload: data,
	};
};

export const getCampaignListByPerson = (params, successCallback, failCallback) => {
	return async (dispatch) => {
		let res = {
			data: [],
			totalCount: 0,
		};
		try {
			const _params = {
				...params,
				page_number: params?.page_number ?? 1,
				page_count: params?.page_count ?? 30,
			};

			const result = await Service.campaign.getCampaignList(_params);
			const data = get(result, "response.data", []);
			const totalCount = get(result, "response.total_count", 0);

			if (data?.length > 0) {
				res.data = data.map((item) => {
					return {
						...item.campaign_main,
						campaign_main_id: item.campaign_main.id,
					};
				});
				res.totalCount = totalCount;

				if (successCallback) {
					successCallback({ data });
				}
			} else {
				if (failCallback) {
					failCallback({ error: "Unknown Error" });
				}
			}
		} catch (err) {
			if (failCallback) {
				failCallback(err);
			}
		} finally {
			dispatch(setCampaignListByPerson(res));
		}
	};
};

/**
 * Update item_priority of workflow_item/workflow_item_queue record
 */
export const setWorkflowItemPriority = (
	id,
	priority,
	tableName,
	successCallback,
	failCallback
) => {
	return async (dispatch) => {
		let res = {};
		try {
			const payload = {
				[tableName]: {
					id: id,
					[MODEL.workflow_item.item_priority]: priority,
				},
			};

			const result = await Service.workflow.updateWorkflow(payload);

			if (result && !result?.error) {
				if (successCallback) {
					successCallback(res);
				}
			} else {
				if (failCallback) {
					failCallback(result);
				}
			}
		} catch (err) {
			if (failCallback) {
				failCallback(err);
			}
		}
	};
};

/**
 * Get all campaign main list
 */
export const setAllCampaignMainList = (data) => {
	return {
		type: actionTypes.SET_ALL_CAMPAIGN_MAINLIST,
		payload: data,
	};
};

export const getAllCampaignList = (successCallback, failCallback) => {
	return async (dispatch) => {
		const payload = {
			totalCount: 0,
			data: [],
		};

		try {
			const res = await Service.campaign.getAllCampaignMainList();

			if (res && !res.error) {
				const campaigns = res?.data?.map((item) => item.campaign_main);
				payload.data = campaigns;
				payload.totalCount = campaigns.length;

				if (successCallback) {
					successCallback(campaigns);
				}
			} else {
				if (failCallback) {
					failCallback(res);
				}
			}
		} catch (err) {
			if (failCallback) {
				failCallback(err);
			}
		} finally {
			dispatch(setAllCampaignMainList(payload));
		}
	};
};

export const actionWorkflowSkip = (params, successCallback, failCallback) => {
	return async (dispatch) => {
		try {
			console.log("params: ", params);
			const res = await Service.campaign.actionWorkflowSkip(params);
			console.log("res===: ", res);

			if (res && !res.error) {
				if (successCallback) {
					successCallback(res);
				}
			} else {
				if (failCallback) {
					failCallback(res);
				}
			}
		} catch (err) {
			if (failCallback) {
				failCallback(err);
			}
		}
	};
};
/* ----------------------------------------------------------------- */
