import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { EuiPopover } from "@elastic/eui";

import EditableQaulifier from "./EditableQualifier";
import StaticQaulifierItem from "./StaticQualifierItem";

import * as MODEL from "core/model";

/**
 * Component for Qaulifier Item
 */
const QaulifierItem = (props) => {
	const {
		onSave,
		editable = true,
		qualifiers: initQualifiers = [],
		qualifierList,
		associationType,
		globals,
	} = props;

	const qualifiers = initQualifiers?.map(
		(item) => item[MODEL.qualifier_assignment._name]
	);

	const [isPopoverOpen, setIsPopoverOpen] = useState(false);

	const closePopover = () => {
		setIsPopoverOpen(false);
	};

	const handleCancel = () => {
		closePopover();
	};

	const handleChangePopover = () => {
		if (editable) {
			setIsPopoverOpen((isPopoverOpen) => !isPopoverOpen);
		}
	};

	const handleSaveQaulifier = (data) => {
		// data = {
		// 		deleteQualifierIds,
		// 		addQualifierIds,
		// }
		onSave(data);
		closePopover();
	};

	useEffect(() => {
		if (!editable) {
			setIsPopoverOpen(false);
		}
	}, [editable]);

	return (
		<Wrapper editable={editable}>
			<EuiPopover
				style={{
					display: "flex",
					justifyContent: "flex-start",
					alignContent: "center",
				}}
				ownFocus
				initialFocus
				button={
					<div onClick={handleChangePopover} className="static-item--container">
						<StaticQaulifierItem qualifiers={qualifiers} />
					</div>
				}
				isOpen={isPopoverOpen}
				closePopover={closePopover}
			>
				<EditableQaulifier
					qualifiers={qualifiers}
					globals={globals}
					qualifierList={qualifierList}
					associationType={associationType}
					onSave={handleSaveQaulifier}
					onCancel={handleCancel}
				/>
			</EuiPopover>
		</Wrapper>
	);
};

export default QaulifierItem;

const Wrapper = styled.div`
	.static-item--container {
		cursor: ${({ editable }) => (editable ? "pointer" : "default")};
	}
`;
