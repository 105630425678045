import React, { useMemo } from "react";
import { EuiFlexItem, EuiFlexGroup } from "@elastic/eui";
import styled from "styled-components";

import { EditableGlobalSearch } from "components/Common/GlobalSearch";
import useGlobal from "core/useHooks/useGlobal";
import { CustomBadge } from "components/Common";
import * as MODEL from "core/model";

import { peopleIcon, companyIcon, jobIcon } from "components/Common/Icons";
import { differences, getGSSValue } from "components/global/utils";
import { truncate } from "helpers/utilities";

/**
 * Constants
 */
const MAX_NAME_LENGTH = 15;

/**
 * Component for displaying Related Record list of AMS Simple Note
 */
const AmsRelatedRecordList = (props) => {
	const {
		data,
		globalSearchItems,
		getGlobalSearchItems,
		onSaveReference,
		onDeleteReference,
	} = props;

	const { globals, gssList } = useGlobal();

	const {
		list,
		personList,
		companyList,
		jobList,
		refObjTypeSCForPerson,
		refObjTypeSCForCompany,
		refObjTypeSCForJob,
	} = useMemo(() => {
		const refObjTypeSCForPerson = getGSSValue(gssList, "OBJECT_BASE", "PERSON");
		const refObjTypeSCForCompany = getGSSValue(gssList, "OBJECT_BASE", "COMPANY");
		const refObjTypeSCForJob = getGSSValue(gssList, "OBJECT_BASE", "JOB");

		let list = [];
		let personList = [];
		let companyList = [];
		let jobList = [];

		if (data) {
			list = data[MODEL.ams_activity_reference._name];
			personList =
				list?.filter(
					(item) =>
						item[MODEL.ams_activity_reference.reference_object_type_sc] ===
						refObjTypeSCForPerson
				) || [];
			companyList =
				list?.filter(
					(item) =>
						item[MODEL.ams_activity_reference.reference_object_type_sc] ===
						refObjTypeSCForCompany
				) || [];
			jobList =
				list?.filter(
					(item) =>
						item[MODEL.ams_activity_reference.reference_object_type_sc] ===
						refObjTypeSCForJob
				) || [];
		}

		return {
			list,
			refObjTypeSCForPerson,
			refObjTypeSCForCompany,
			refObjTypeSCForJob,
			personList,
			companyList,
			jobList,
		};
	}, [data, gssList]);

	const handleDeleteReference = (index) => {
		const reference = list[index];
		onDeleteReference(reference);
	};

	const handelSaveItems = (items) => {
		const oldPersonIds = personList.map(
			(item) => item[MODEL.ams_activity_reference.reference_object_record_ref_id]
		);
		const oldCompanyIds = companyList.map(
			(item) => item[MODEL.ams_activity_reference.reference_object_record_ref_id]
		);
		const oldJobIds = jobList.map(
			(item) => item[MODEL.ams_activity_reference.reference_object_record_ref_id]
		);
		const personIds = differences(items.person || [], oldPersonIds);
		const companyIds = differences(items.company || [], oldCompanyIds);
		const jobIds = differences(items.job || [], oldJobIds);

		onSaveReference({
			person: personIds,
			company: companyIds,
			job: jobIds,
		});
	};

	return (
		<Wrapper>
			<EuiFlexGroup direction="column" gutterSize="m">
				<EuiFlexItem>
					<EuiFlexGroup gutterSize="s" wrap={true}>
						{list?.map((item, index) => {
							const refObjType =
								item?.[
									MODEL.ams_activity_reference.reference_object_type_sc
								];
							// Refer gss list for ref_object_base_sc
							let refObjIcon = null;
							let refObjInfo = null;
							let refObjName = "";
							switch (refObjType) {
								case refObjTypeSCForPerson: // person
									refObjIcon = peopleIcon();
									refObjInfo = item["details"];
									refObjName = `${refObjInfo?.name_first || ""} ${
										refObjInfo?.name_last || ""
									}`;
									break;
								case refObjTypeSCForCompany: // company
									refObjIcon = companyIcon();
									refObjInfo = item["details"];
									refObjName = `${refObjInfo?.company_name || ""}`;
									break;
								case refObjTypeSCForJob: // job
									refObjIcon = jobIcon();
									refObjInfo = item["details"];
									refObjName = `${refObjInfo?.company_name || ""}`;
									break;
								default:
									break;
							}

							refObjName = truncate(refObjName, MAX_NAME_LENGTH);

							return (
								<EuiFlexItem key={item} grow={false}>
									<CustomBadge
										icon={refObjIcon}
										label={refObjName}
										onRemove={() => handleDeleteReference(index)}
									/>
								</EuiFlexItem>
							);
						})}
					</EuiFlexGroup>
				</EuiFlexItem>
				<EuiFlexItem>
					<EditableGlobalSearch
						globals={globals}
						label={
							<p className="type-small-default add-record-label">
								Related Record
							</p>
						}
						globalSearchItems={globalSearchItems}
						getGlobalSearchItem={getGlobalSearchItems}
						panelWidth={400}
						hasCompany={true}
						hasPerson={true}
						hasJob={true}
						onSaveItems={handelSaveItems}
						savePersonPhone={() => {}}
						deletePerson={() => {}}
						savePersonEmail={() => {}}
						saveCompanyPhone={() => {}}
						deleteCompany={() => {}}
						saveCompanyEmail={() => {}}
					/>
				</EuiFlexItem>
			</EuiFlexGroup>
		</Wrapper>
	);
};

export default AmsRelatedRecordList;

const Wrapper = styled.div`
	display: flex;
	width: 100%;

	.add-record-label {
		margin-left: var(--sp-4);
		cursor: pointer;
		color: var(--eui-link-color);
	}
`;
