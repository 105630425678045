import React from "react";
import styled from "styled-components";
import { EuiButton } from "@elastic/eui";
import variables from "assets/css/variables.scss";

/**
 * Main Components
 */
const SaveCancelBtn = (props) => {
    const { onChangeCancel, onChangeSave, disabled = false } = props;

    return (
        <Wrapper disabled={disabled}>
            <EuiButton
                onClick={onChangeCancel}
                className="btn-cancel"
                color="text"
                disabled={disabled}
            >
                Cancel
            </EuiButton>
            <EuiButton
                onClick={onChangeSave}
                className="btn-save ml-2"
                disabled={disabled}
            >
                Save
            </EuiButton>
        </Wrapper>
    );
};

export default SaveCancelBtn;

/**
 * Styled Components
 */
const Wrapper = styled.div`
    display: flex;
    justify-content: center;
    margin-left: auto;
    margin-right: auto;

    .euiButton {
        min-width: 65px;
        text-decoration: none !important;
        height: 1.8rem;

        .euiButton__text {
            font-size: 1rem;
        }
    }

    .btn-cancel {
        border: none !important;

        .euiButton__text {
            ${({ disabled }) => (!disabled ? `color: ${variables.primaryBtnColor};` : "")}
        }
    }
    .btn-cancel.euiButton--text {
        box-shadow: none;
    }
`;
