export const workflow_main = {
	_name: "workflow_main",
	id: "id",
	workflow_name: "workflow_name",
	workflow_construct: "workflow_construct",
	workflow_type_c: "workflow_type_c",
	workflow_status_c: "workflow_status_c",
};

export const workflow_item = {
	_name: "workflow_item",
	id: "id",
	workflow_main_ref_id: "workflow_main_ref_id",
	campaign_main_ref_id: "campaign_main_ref_id",
	item_state_c: "item_state_c",
	item_statue_c: "item_statue_c",
	item_node_id: "item_node_id",
	item_wait_start: "item_wait_start",
	item_wait_end: "item_wait_end",
	item_priority: "item_priority",
};

export const workflow_item_queue = {
	_name: "workflow_item_queue",
	id: "id",
	workflow_main_ref_id: "workflow_main_ref_id",
	campaign_item_ref_id: "campaign_item_ref_id",
	item_priority: "item_priority",
};

export const workflow_permission = {
	_name: "workflow_permission",
	id: "id",
	security_group_ref_id: "security_group_ref_id",
	workflow_main_ref_id: "workflow_main_ref_id",
};

export const workflow_association = {
	_name: "workflow_association",
	id: "id",
	workflow_object_table_sc: "workflow_object_table_sc",
	workflow_object_ref_id: "workflow_object_ref_id",
	ref_object_base_sc: "ref_object_base_sc",
	ref_object_table_sc: "ref_object_table_sc",
	ref_object_record_ref_id: "ref_object_record_ref_id",
};

export const workflow_log_activity = {
	_name: "workflow_log_activity",
	id: "id",
	date_created: "date_created",
	account_main_ref_id: "account_main_ref_id",
	workflow_table_ref_id: "workflow_table_ref_id",
	log_object_c: "log_object_c",
	log_type_c: "log_type_c",
	log_action_c: "log_action_c",
	log_details: "log_details",
};

export const workflow_template = {
	_name: "workflow_template",
	id: "id",
	workflow_name: "workflow_name",
	workflow_construct: "workflow_construct",
	workflow_type_c: "workflow_type_c",
	workflow_status_c: "workflow_status_c",
};
