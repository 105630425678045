import { get, isEmpty, omitBy, isNil } from "lodash";

import API from "api";
import Service from "core/service";
import { getResultFromApiResponse, objectChecker, refineObject } from "helpers/utilities";
import * as MODEL from "core/model";
import { logError } from "../error";
import { SIT_TYPES } from "components/global/constants";

import * as actionTypes from "./actionTypes";
import { setLoading } from "../loader";
import { getGCSValue } from "components/global/utils";

export const setPersons = (data) => {
	return {
		type: actionTypes.SET_PERSON_LIST,
		payload: data,
	};
};

export const setFetchPersonsLoading = (data) => {
	return {
		type: actionTypes.SET_FETCH_PERSON_LOADING,
		payload: data,
	};
};

const getPayloadFromPersonListFilters = (filters) => {
	const preFilters = {};
	Object.keys(filters).forEach((key) => {
		switch (key) {
			case "contact_email_include":
			case "contact_email_type":
			case "contact_phone_include":
			case "contact_phone_type":
				break;

			case "contact_email":
				if (filters["contact_email_include"]) {
					preFilters["contact_email_any"] = filters[key];
				} else {
					preFilters["contact_email"] = filters[key] || null;
				}
				break;

			case "contact_phone":
				if (filters["contact_phone_include"]) {
					preFilters["contact_phone_any"] = filters[key];
				} else {
					preFilters["contact_phone"] = filters[key] || null;
				}
				break;

			case "association_sit_skill":
			case "association_sit_title":
			case "association_sit_industry":
				preFilters[key] = filters[key]?.length ? filters[key] : null;
				break;

			default:
				preFilters[key] = filters[key];
				break;
		}
	});

	const refinedFilter = omitBy(preFilters, isNil);
	const payload = [];

	Object.keys(refinedFilter).forEach((key) => {
		switch (key) {
			case "radius_zip_codes":
				const zipcodes = refinedFilter[key];
				const zipAreas = zipcodes?.["zip_areas"] || [];
				const includeMatchingAreas = zipcodes?.["include_match_area"] || false;
				if (zipAreas?.length) {
					payload.push({
						type: "radius_zip_codes",
						value: zipAreas,
					});
					if (includeMatchingAreas) {
						payload.push({
							type: "radius_match_area_code",
						});
					}
				}
				break;

			case "date_created":
				if (!!refinedFilter[key]?.type && !!refinedFilter[key]?.date) {
					const dateType = `${key}_${refinedFilter[key]?.type}`;
					const dateValue = refinedFilter[key]?.date;

					payload.push({
						type: dateType,
						value: dateValue,
					});
				}
				break;

			case "contact_email_any":
				payload.push({
					type: "contact_email_any",
				});
				break;

			case "contact_phone_any":
				payload.push({
					type: "contact_phone_any",
				});
				break;

			default:
				payload.push({
					type: key,
					value: refinedFilter[key],
				});
				break;
		}
	});

	return payload;
};

export const fetchAllPersons = (
	inPage,
	inPerPage,
	search = "",
	filter = {},
	sort = [],
	callback = null
) => {
	return async (dispatch, getState) => {
		const state = getState();
		const isLoading = state.person.loading;

		if (isLoading) {
			return;
		}

		const curPersonListFilter = state.person.personList.filter;
		const curPersonListPagination = state.person.pagination;

		dispatch(setFetchPersonsLoading(true));

		const newFilter = { ...curPersonListFilter, ...filter };

		const payloadFilter = getPayloadFromPersonListFilters(newFilter);
		const page = inPage ?? curPersonListPagination.currentPage ?? 1;
		const perPage = inPerPage ?? curPersonListPagination.perPage ?? 30;

		let tableData = {
			data: [],
			totalCount: 0,
			page: 0,
			ids: [],
			filter: newFilter,
		};

		let payload = {
			page_number: page,
			page_count: perPage,
			filter: payloadFilter || [],
		};

		if (sort.length > 0) payload.sort = sort;

		try {
			const result = await Service.person.getPersonList(payload);

			const data = objectChecker(result, ["response", "data"]);
			const totalCount = objectChecker(result, ["response", "count_filter"]);

			tableData = {
				data: Array.isArray(data) ? data : [],
				totalCount: data && totalCount ? totalCount : 0,
				page: data && page ? page : 0,
				perPage,
				filter: newFilter,
			};

			if (callback) {
				callback();
			}
		} catch (err) {
			dispatch(
				logError("fetch_failure", "none", {
					Error: "Error fetching person data: " + JSON.stringify(err),
				})
			);
		} finally {
			dispatch(setPersons(tableData));
			dispatch(setFetchPersonsLoading(false));
		}
	};
};

export const setPersonAllIds = (data) => {
	return {
		type: actionTypes.PERSON_ALL_IDS,
		payload: data,
	};
};
export const getPersonAllIds = (successCallBack, failCallback) => {
	return async (dispatch) => {
		try {
			const idsRes = await Service.person.getPersonIdList();

			const ids = objectChecker(idsRes, ["response"], []);
			dispatch(setPersonAllIds(ids));

			if (ids) {
				if (successCallBack) {
					successCallBack(ids);
				}
			}
		} catch (err) {
			dispatch(
				logError("fetch_failure", "none", {
					Error: "Error fetching person ids data: " + JSON.stringify(err),
				})
			);
			dispatch(setPersonAllIds(null));
			if (failCallback) {
				failCallback(err);
			}
		}
	};
};

export const setPersonRas = (data) => {
	return {
		type: actionTypes.SET_PERSON_RAS,
		payload: data,
	};
};

export const setPersonRasRestrictions = (data) => {
	return {
		type: actionTypes.SET_PERSON_RAS_RESTRICTIONS,
		payload: data,
	};
};

export const setPersonDetail = (data) => {
	return {
		type: actionTypes.SET_PERSON_DETAIL,
		payload: data,
	};
};

// ras-get-restriction-by-object
export const fetchPersonRashRestrictionByObject = (id) => {
	return async (dispatch) => {
		try {
			const result = await Service.ras.getRASRestrictionByObject({
				object_ref_id: id,
				object_type_c: 1,
			});

			if (get(result, "records")) {
				dispatch(setPersonRasRestrictions(result));
			}
		} catch (err) {
			alert("Something went wrong when trying to fetch the person RAS");
		}
	};
};

export const fetchPersonRashAssignmentByObject = (id) => {
	return async (dispatch) => {
		try {
			const result = await Service.ras.getRASAssignmentByObject({
				object_ref_id: id,
				object_type_c: 1,
			});

			if (get(result, "records")) {
				dispatch(setPersonRas(result));
			}
		} catch (err) {
			alert("Something went wrong when trying to fetch the person RAS");
		}
	};
};

export const fetchPersonDetail = (id) => {
	return async (dispatch) => {
		try {
			const result = await API().Person().personDetail({
				person_main_id: id,
			});

			const data = objectChecker(result, ["data"]);
			dispatch(setPersonDetail(data));
		} catch (err) {
			alert(
				"error payload: " +
					JSON.stringify({
						person_main_id: id,
					})
			);
			alert(
				"Something went wrong when trying to fetch the person detail: " +
					JSON.stringify(err)
			);
		}
	};
};

export const setPersonFlyoutVisibility = (type) => {
	return async (dispatch) => {
		dispatch({
			type: actionTypes.SET_PERSON_FLYOUT_VISIBILITY,
			payload: type,
		});
	};
};

export const togglePersonFlyout = (type) => {
	return async (dispatch) => {
		dispatch(setPersonFlyoutVisibility(type));
	};
};

export const setSelectedPersonTitle = (data) => {
	return async (dispatch) => {
		dispatch({
			type: actionTypes.SET_SELECTED_PERSON_TITLES,
			payload: data,
		});
	};
};

export const setSelectedPersonSkill = (data) => {
	return async (dispatch) => {
		dispatch({
			type: actionTypes.SET_SELECTED_PERSON_SKILLS,
			payload: data,
		});
	};
};

export const setSelectedPersonIndustry = (data) => {
	return async (dispatch) => {
		dispatch({
			type: actionTypes.SET_SELECTED_PERSON_INDUSTRIES,
			payload: data,
		});
	};
};

export const addUpdateDeletePersonSit = (
	deletedItems,
	addedItems,
	personId,
	successCallback,
	failCallback
) => {
	return async () => {
		const socketCalls = [];

		if (addedItems.length > 0) {
			for (let i in addedItems) {
				socketCalls.push(API().SIT().addUpdatePersonSIT(addedItems[i]));
			}
		}

		if (deletedItems.length > 0) {
			for (let i in deletedItems) {
				socketCalls.push(API().SIT().deletePersonSIT(deletedItems[i]));
			}
		}

		Promise.all(socketCalls).then(
			(res) => successCallback(res),
			(err) => failCallback(err)
		);
	};
};

//Bulk Actions
export const bulkActionResponse = (data) => {
	return {
		type: actionTypes.BULK_UPDATE_PERSON_SIT,
		payload: data,
	};
};

export const bulkUpdatePersonSIT = (params) => {
	return async (dispatch) => {
		try {
			const result = await API().Person().bulkUpdatePersonSIT(params);

			dispatch(bulkActionResponse(result));
		} catch (err) {
			alert("Something went wrong when trying to bulk update person SIT");
		}
	};
};

export const updatePersonMain = (
	personId,
	firstName,
	middleName,
	lastName,
	nickName,
	successCallback,
	failCallback
) => {
	return async (dispatch) => {
		try {
			const payload = {
				person_main: {
					person_main_id: personId,
					name_first: firstName,
					name_middle: middleName,
					name_last: lastName,
					name_nick: nickName,
				},
			};

			const result = await Service.person.updatePerson(payload);

			if (!result["error"]) {
				if (successCallback) {
					successCallback();
				}
			} else {
				if (failCallback) {
					failCallback();
				}
			}
		} catch (err) {
			failCallback();
		}
	};
};

export const bulkUpdatePersonAddresses = (personAddresses, callback) => {
	return async () => {
		try {
			const requests = [];

			for (let i in personAddresses) {
				let params = {
					...personAddresses[i],
				};

				requests.push(API().Person().addUpdatePersonAddress(params));
			}

			let result = null;

			await Promise.all(requests).then((res) => {
				result = res;
			});

			if (callback) {
				callback();
			}
		} catch (err) {
			alert("Error fetching persons data: " + JSON.stringify(err));
		}
	};
};

export const setPersonSearchValue = (data) => {
	return async (dispatch) => {
		dispatch({
			type: actionTypes.SET_PERSON_SEARCH_VALUE,
			payload: data,
		});
	};
};

export const saveCampaigns = (data) => {
	return async (dispatch) => {
		try {
			alert("I would update campaigns, but I need some APIs first LOLOLL");
		} catch (err) {}
	};
};

export const updatePersonDisplaySettings = (action, values, callback) => {
	return async (dispatch, getState) => {
		try {
			const state = getState();
			const globals = state.global.globals;
			const params = {
				setting_type_c: getGCSValue(
					globals,
					"account_setting",
					"setting_type_c",
					"empower-sourcing"
				),
				"setting-value": {
					action,
					values,
				},
			};

			const result = await API().GenericAPI().updateTableData(params);

			if (result) {
				if (callback) {
					callback();
				}
			}
		} catch (e) {
			alert(
				"Something went wrong when trying to hide the sourcing search data: " +
					JSON.stringify(e)
			);
		}
	};
};

export const bulkValidateEmails = (payload, successCallback, failCallback) => {
	return async (dispatch) => {
		try {
			const result = await API().Person().bulkValidateEmails(payload);

			if (result) {
				if (successCallback) {
					successCallback(result);
				}
			}
		} catch (err) {
			if (failCallback) {
				failCallback(err);
			}
		}
	};
};

export const getPersonSourcedPlatform = (payload) => {
	return async (dispatch) => {
		try {
			const { data } = await API().Person().getPersonSourcedPlatform(payload);

			if (objectChecker(data, ["data"])) {
				dispatch(setPersonEnrichmentDetails(data.data));
			}
		} catch (err) {
			alert(
				"Something went wrong when trying to fetch the person sourced platform data: " +
					JSON.stringify(err)
			);
		}
	};
};

export const setPersonEnrichmentDetails = (data) => {
	return async (dispatch) => {
		dispatch({
			type: actionTypes.SET_PERSON_ENRICHMENT_DETAILS,
			payload: data,
		});
	};
};

export const updatePersonName = (id, name, successCallback, failCallback) => {
	return async (dispatch) => {
		try {
			const result = await API()
				.GenericAPI()
				.updateTableData({
					object: "person",
					person_main: {
						id: id,
						name_first: name.first,
						name_last: name.last,
						name_middle: name.middle,
						name_nick: name.nick,
					},
				});

			if (result) {
				if (successCallback) {
					successCallback(id);
				}
			}
		} catch (err) {
			if (failCallback) {
				failCallback(err);
			}
		}
	};
};

/** --------------- NEW ------------------- */
export const setPersonDetail_NEW = (data) => {
	return {
		type: actionTypes.SET_PERSON_DETAIL_NEW,
		payload: data,
	};
};

export const getPersonDetail_NEW = (id) => {
	return async (dispatch, getState) => {
		try {
			const state = getState();
			const gssList = state.global.gssList_NEW;
			const data = await Service.person.getPersonDetial({ id, gssList });
			const personDetail = objectChecker(data.data[0], [MODEL.person_main._name]);

			if (personDetail) {
				dispatch(setPersonDetail_NEW(personDetail));
			} else {
				dispatch(setPersonDetail_NEW(null));
			}
		} catch (err) {
			dispatch(setPersonDetail_NEW(null));
		} finally {
		}
	};
};

export const requestPersonMainList_NEW = () => {
	return {
		type: actionTypes.REQUEST_PERSON_AMS_LIST,
	};
};

export const receivedPersonMainList_NEW = () => {
	return {
		type: actionTypes.RECEIVED_PERSON_AMS_LIST,
	};
};

export const setPersonMainList_NEW = (data) => {
	return {
		type: actionTypes.SET_PERSON_MAINLIST_NEW,
		payload: data,
	};
};

export const getPersonMainList_NEW = (params) => {
	return async (dispatch) => {
		dispatch(requestPersonMainList_NEW());

		const _params = {
			searchTerm: params?.searchTerm ?? "",
			pageNumber: params?.pageNumber ?? 1,
			pageCount: params?.pageCount ?? 30,
			param: params?.param ?? null,
		};

		const payload = {
			searchTerm: _params.searchTerm,
			pageNumber: _params.pageNumber,
			pageCount: _params.pageCount,
			totalCount: 0,
			data: [],
		};

		try {
			const data = await Service.person.getPersonMainList(_params);

			if (data?.data) {
				const persons = data?.data?.map((item) => item.person_main);
				payload.data = persons;
				payload.totalCount = persons.length;
			}
		} catch (err) {
			console.log("getPersonMainList_NEW error : ", err);
		} finally {
			dispatch(setPersonMainList_NEW(payload));
		}
	};
};

export const setOpenPersonListFilter = (isOpen) => {
	return {
		type: actionTypes.SET_OPEN_PERSON_LIST_FILTER,
		payload: isOpen,
	};
};

export const updatePerson_NEW = (params, successCallback, failCallback) => {
	return async (dispatch) => {
		if (!params) {
			failCallback(null);
		}

		try {
			let payload = params;

			const result = await Service.person.updatePerson(payload);

			if (!objectChecker(result, ["error"])) {
				if (successCallback) {
					const res = getResultFromApiResponse(result);
					successCallback(res?.procedure_results);
				}
			}
		} catch (err) {
			if (failCallback) {
				failCallback(err);
			}
		}
	};
};

export const deletePerson_NEW = (params, successCallback, failCallback) => {
	return async (dispatch) => {
		if (!params) {
			failCallback(null);
		}

		try {
			let payload = params;

			const result = await Service.person.deletePerson(payload);

			if (!objectChecker(result, ["error"])) {
				const res = getResultFromApiResponse(result);
				if (successCallback) {
					successCallback(res?.procedure_results);
				}
			}
		} catch (err) {
			if (failCallback) {
				failCallback(err);
			}
		}
	};
};

export const setPersonSelectedFile_NEW = (data) => {
	return {
		type: actionTypes.SET_PERSON_FILE_VALUE,
		payload: data,
	};
};

export const setSit = (data, sitType) => {
	return async (dispatch) => {
		dispatch({
			type: actionTypes.SET_SIT,
			payload: {
				data,
				type: sitType,
			},
		});
	};
};

export const getPersonSit = (sitTypeKey) => {
	return async (dispatch) => {
		const loadingKey = "loaders.person.sitList." + sitTypeKey;
		const sitType = SIT_TYPES[sitTypeKey];

		if (sitType) {
			dispatch(setLoading({ key: loadingKey, value: true }));

			let payload = {
				pageNumber: 1,
				pageCount: 1000,
				type_c: sitType.sitType,
				params: {
					limit_records: 1000,
				},
			};

			try {
				const result = await Service.sit.getSitList(payload);

				let sitData = objectChecker(result, ["response", "data"]);

				if (sitData) {
					dispatch(setSit(result.sit, sitType));
				}

				dispatch(setLoading({ key: loadingKey, value: false }));
			} catch (err) {
				dispatch(setLoading({ key: loadingKey, value: false }));
				alert(
					"Something went wrong when trying to update the person summary: " +
						JSON.stringify(err)
				);
			}
		}
	};
};

/**
 * Add sits from person
 */
export const addPersonSITs_NEW = (personId, ids, successCallback, failCallback) => {
	return async () => {
		try {
			if (ids?.length > 0) {
				const params = ids?.map((id) => {
					return {
						[MODEL.person_sit._name]: {
							[MODEL.person_sit.person_main_ref_id]: personId,
							[MODEL.person_sit.sit_main_ref_id]: id,
						},
					};
				});

				const result = await Service.person.updatePerson(params);

				if (!get(result, "error")) {
					if (successCallback) {
						const res = getResultFromApiResponse(result);
						successCallback(res?.procedure_results);
					}
				}
			} else {
				if (successCallback) {
					successCallback({ error: false });
				}
			}
		} catch (err) {
			if (failCallback) {
				failCallback(err);
			}
		}
	};
};

/**
 * delete sits from person
 */
export const deletePersonSITs_NEW = (personId, ids, successCallback, failCallback) => {
	return async () => {
		try {
			if (ids?.length > 0) {
				const params = ids?.map((id) => {
					return {
						[MODEL.person_sit._name]: {
							[MODEL.person_sit.id]: id,
						},
					};
				});

				const result = await Service.person.deletePerson(params);

				if (!get(result, "error")) {
					if (successCallback) {
						const res = getResultFromApiResponse(result);
						successCallback(res?.procedure_results);
					}
				}
			} else {
				if (successCallback) {
					successCallback({ error: false });
				}
			}
		} catch (err) {
			if (failCallback) {
				failCallback(err);
			}
		}
	};
};

/** Get AMS list for Person */
export const requestPersonAmsList = () => {
	return {
		type: actionTypes.REQUEST_PERSON_AMS_LIST,
	};
};

export const receivedPersonAmsList = () => {
	return {
		type: actionTypes.RECEIVED_PERSON_AMS_LIST,
	};
};

export const setPersonAmsList = (data) => {
	return {
		type: actionTypes.SET_PERSON_AMS_LIST,
		payload: data,
	};
};

export const setPersonAmsListParams = (data) => {
	return {
		type: actionTypes.SET_PERSON_AMS_LIST_PARAMS,
		payload: data,
	};
};

export const getPersonAmsList = (params, successCallback, failCallback) => {
	return async (dispatch, getState) => {
		const state = getState();

		const curPersonAmsList = state.person.personAmsList;

		if (curPersonAmsList.isRequesting) {
			return;
		}

		dispatch(requestPersonAmsList());

		const _params = {
			page_number: params?.pageNumber ?? curPersonAmsList?.pageNumber ?? 1,
			page_count: params?.pageCount ?? curPersonAmsList?.pageCount ?? 30,
			ams_assigned_person_id:
				params?.personId ??
				Number(curPersonAmsList?.filters.amsAssignedPersonId) ??
				0,
			ams_activity_name:
				params?.amsActivityName ?? curPersonAmsList?.amsActivityName ?? null,
			ams_task_name:
				params?.amsTaskName ?? curPersonAmsList?.filters.amsTaskName ?? null,
		};
		if (!_params.ams_activity_name || _params.ams_activity_name === "")
			delete _params.ams_activity_name;
		if (!_params.ams_task_name || _params.ams_task_name === "")
			delete _params.ams_task_name;

		const payload = {
			pageNumber: _params.page_number,
			pageCount: _params.page_count,
			totalCount: 0,
			data: [],
			filters: {
				amsTaskName: _params?.ams_task_name,
				amsActivityName: _params?.ams_activity_name,
				amsAssignedPersonId: _params?.ams_assigned_person_id,
			},
		};

		try {
			const result = await Service.ams.getAmsList(_params);

			const data = get(result, `response[0].data`);
			const totalCount = get(result, `response[0].total_count`);

			if (data) {
				const amslist = data.map((item) => item.ams_activity_main);
				payload.data = amslist;
				payload.totalCount = totalCount;

				if (successCallback) {
					successCallback(amslist);
				}
			} else {
				if (failCallback) {
					failCallback(data);
				}
			}
		} catch (err) {
			if (failCallback) {
				failCallback(err);
			}
		} finally {
			dispatch(setPersonAmsList(payload));
		}
	};
};

/** Get CMS list for Person */
export const requestPersonCmsList = () => {
	return {
		type: actionTypes.REQUEST_PERSON_CMS_LIST,
	};
};

export const receivedPersonCmsList = () => {
	return {
		type: actionTypes.RECEIVED_PERSON_CMS_LIST,
	};
};

export const setPersonCmsList = (data) => {
	return {
		type: actionTypes.SET_PERSON_CMS_LIST,
		payload: data,
	};
};

export const setPersonCmsListParams = (data) => {
	return {
		type: actionTypes.SET_PERSON_CMS_LIST_PARAMS,
		payload: data,
	};
};

export const getPersonCmsList = (params, successCallback, failCallback) => {
	return async (dispatch, getState) => {
		const state = getState();

		const curPersonCmsList = state.person.personCmsList;

		if (curPersonCmsList.isRequesting) {
			return;
		}

		dispatch(requestPersonCmsList());

		// Get cms_conversation_main filter information
		const paramsCmsConversationMain = params?.cms_conversation_main;
		const curCmsConversationMain = curPersonCmsList?.filters?.cms_conversation_main;
		const raw_cms_conversation_main = {
			conversation_type_c:
				paramsCmsConversationMain?.conversation_type_c ??
				curCmsConversationMain?.conversation_type_c,
			owner_account_main_ref_id:
				paramsCmsConversationMain?.owner_account_main_ref_id ??
				curCmsConversationMain?.owner_account_main_ref_id,
		};
		const cms_conversation_main = refineObject(raw_cms_conversation_main);

		// Get cms_conversation_member filter information
		const paramsCmsConversationMember = params?.cms_conversation_member;
		const curCmsConversationMember =
			curPersonCmsList?.filters?.cms_conversation_member;
		const raw_cms_conversation_member = {
			member_object_type:
				paramsCmsConversationMember?.member_object_type ??
				curCmsConversationMember?.member_object_type,
			member_object_subtype:
				paramsCmsConversationMember?.member_object_subtype ??
				curCmsConversationMember?.member_object_subtype,
			member_object_record_id:
				paramsCmsConversationMember?.member_object_record_id ??
				curCmsConversationMember?.member_object_record_id,
			member_communication_key:
				paramsCmsConversationMember?.member_communication_key ??
				curCmsConversationMember?.member_communication_key,
		};
		const cms_conversation_member = refineObject(raw_cms_conversation_member);

		// Get cms_communication_main filter information
		const paramsCmsCommunicationMain = params?.cms_communication_main;
		const curCmsCommunicationMain = curPersonCmsList?.filters?.cms_communication_main;
		const raw_cms_communication_main = {
			date_created:
				paramsCmsCommunicationMain?.date_created ??
				curCmsCommunicationMain?.date_created,
			comm_id_external:
				paramsCmsCommunicationMain?.comm_id_external ??
				curCmsCommunicationMain?.comm_id_external,
			comm_platform_c:
				paramsCmsCommunicationMain?.comm_platform_c ??
				curCmsCommunicationMain?.comm_platform_c,
			comm_direction_c:
				paramsCmsCommunicationMain?.comm_direction_c ??
				curCmsCommunicationMain?.comm_direction_c,
			search_text:
				paramsCmsCommunicationMain?.search_text ??
				curCmsCommunicationMain?.search_text,
		};
		const cms_communication_main = refineObject(raw_cms_communication_main);

		const filters = {
			...(isEmpty(cms_conversation_main) ? {} : { cms_conversation_main }),
			...(isEmpty(cms_conversation_member) ? {} : { cms_conversation_member }),
			...(isEmpty(cms_communication_main) ? {} : { cms_communication_main }),
		};

		// Total params for query
		const _params = {
			page_number: params?.pageNumber ?? curPersonCmsList?.pageNumber ?? 1,
			page_count: params?.pageCount ?? curPersonCmsList?.pageCount ?? 30,
			...filters,
		};

		// Update payload
		const payload = {
			pageNumber: _params.page_number,
			pageCount: _params.page_count,
			totalCount: 0,
			data: [],
			filters,
		};

		try {
			const result = await Service.cms.getCommunicationList(_params);
			const data = get(result, `response.data.cms_communication_main`);
			const totalCount = get(result, `response.total_count`);

			if (data) {
				const cmslist = data;
				payload.data = cmslist;
				payload.totalCount = totalCount;

				if (successCallback) {
					successCallback(cmslist);
				}
			} else {
				if (failCallback) {
					failCallback(data);
				}
			}
		} catch (err) {
			if (failCallback) {
				failCallback(err);
			}
		} finally {
			dispatch(setPersonCmsList(payload));
		}
	};
};

/** Get CMS Draft list for Person */
export const requestPersonCmsDraftList = () => {
	return {
		type: actionTypes.REQUEST_PERSON_CMS_DRAFT_LIST,
	};
};

export const receivedPersonCmsDraftList = () => {
	return {
		type: actionTypes.RECEIVED_PERSON_CMS_DRAFT_LIST,
	};
};

export const setPersonCmsDraftList = (data) => {
	return {
		type: actionTypes.SET_PERSON_CMS_DRAFT_LIST,
		payload: data,
	};
};

export const setPersonCmsDraftListParams = (data) => {
	return {
		type: actionTypes.SET_PERSON_CMS_DRAFT_LIST_PARAMS,
		payload: data,
	};
};

export const getPersonCmsDraftList = (params, successCallback, failCallback) => {
	return async (dispatch, getState) => {
		const state = getState();

		const curPersonCmsDraftList = state.person.personCmsDraftList;

		if (curPersonCmsDraftList.isRequesting) {
			return;
		}

		dispatch(requestPersonCmsDraftList());

		// Get filter information
		const raw_filters = {
			account_main_id:
				params?.account_main_id ??
				curPersonCmsDraftList?.filters?.account_main_id,
			credental_main_id:
				params?.credental_main_id ??
				curPersonCmsDraftList?.filters?.credental_main_id,
			member_object_type_sc:
				params?.member_object_type_sc ??
				curPersonCmsDraftList?.filters?.member_object_type_sc,
			member_object_subtype_sc:
				params?.member_object_subtype_sc ??
				curPersonCmsDraftList?.filters?.member_object_subtype_sc,
			member_object_record_id:
				params?.member_object_record_id ??
				curPersonCmsDraftList?.filters?.member_object_record_id,
		};
		const filters = refineObject(raw_filters);

		// Total params for query
		const _params = {
			page_number: params?.pageNumber ?? curPersonCmsDraftList?.pageNumber ?? 1,
			page_count: params?.pageCount ?? curPersonCmsDraftList?.pageCount ?? 30,
			...filters,
		};

		// Update payload
		const payload = {
			pageNumber: _params.page_number,
			pageCount: _params.page_count,
			totalCount: 0,
			data: [],
			filters,
		};

		try {
			const result = await Service.cms.getCommunicationDraftList(_params);
			const data = get(result, `response.data`);
			const totalCount = get(result, `response.total_count`);

			if (data) {
				const cmslist = data;
				payload.data = cmslist;
				payload.totalCount = totalCount;

				if (successCallback) {
					successCallback(cmslist);
				}
			} else {
				if (failCallback) {
					failCallback(data);
				}
			}
		} catch (err) {
			if (failCallback) {
				failCallback(err);
			}
		} finally {
			dispatch(setPersonCmsDraftList(payload));
		}
	};
};

/**
 * Save Person profile information for Person Flyout
 */
export const setPersonDetailForFlyout = (data) => {
	return {
		type: actionTypes.SET_PERSON_DETAIL_FLYOUT,
		payload: data,
	};
};

export const getPersonDetailForFlyout = (id, successCallback, failCallback) => {
	return async (dispatch) => {
		try {
			const data = await Service.person.getPersonDetial({ id });
			const personDetail = objectChecker(data.data[0], [MODEL.person_main._name]);

			if (personDetail) {
				dispatch(setPersonDetailForFlyout(personDetail));
				if (successCallback) {
					successCallback();
				}
			} else {
				dispatch(setPersonDetailForFlyout(null));
				if (failCallback) {
					failCallback();
				}
			}
		} catch (err) {
			dispatch(setPersonDetailForFlyout(null));
			if (failCallback) {
				failCallback();
			}
		} finally {
		}
	};
};

export const getZipAreas = (params) => {
	return async (dispatch) => {
		return Service.person.getZipAreas(params);
	};
};
/** --------------------------------------- */
