import React, { useEffect, useMemo } from "react";
import styled from "styled-components";
import { EuiFlexGroup, EuiFlexItem, EuiSelect } from "@elastic/eui";

import * as MODEL from "core/model";
import { CREDENTIAL_TYPES } from "components/global/constants";
import { get } from "lodash";

/**
 * Component for selecting credentials
 */
const CredentialSelectionItem = (props) => {
	const {
		globals,
		selectedCredentials,
		credentials,
		setSelectedCredentials,
		hideOptions,
	} = props;

	const { credentialOptions } = useMemo(() => {
		const authendCredentails = credentials;
		// .filter(
		//     (data) =>
		//         data[MODEL.credential_main._name]?.[
		//             MODEL.credential_main.credential_status_c
		//         ] === 1 // "authenticated"
		// );

		const credentialOptions = {};
		Object.entries(CREDENTIAL_TYPES).forEach(([key, typeInfo]) => {
			const filteredCredentials =
				authendCredentails.filter(
					(item) =>
						item?.[MODEL.credential_main._name]?.[
							MODEL.credential_main.credential_type_c
						] === typeInfo.value
				) || [];
			const options = [{ value: -1, text: "None", label: "None" }];
			filteredCredentials.forEach((item) => {
				if (item.credential_main) {
					options.push({
						value: item.credential_main?.id,
						text: item.credential_main?.credential_name,
						label: item.credential_main?.credential_name,
						detail: item?.[MODEL.credential_main._name],
					});
				}
			});

			credentialOptions[key] = options;
		});

		return {
			credentialOptions,
		};
	}, [credentials]);

	const handleChangeCredential = (e, key) => {
		const credentials = { ...selectedCredentials };
		credentials[key] = Number(e.target.value);
		setSelectedCredentials(credentials);
	};

	useEffect(() => {}, [credentials, globals, selectedCredentials]);

	return (
		<Wrapper>
			<EuiFlexGroup direction="column" gutterSize="s">
				{Object.entries(credentialOptions)?.map(([key, options], id) => {
					const isHideOption = get(hideOptions, key, false);

					if (isHideOption) {
						return null;
					}

					const credentialId = selectedCredentials[key];

					return (
						<EuiFlexItem key={`campaign-credential-item-${id}`}>
							<div className="credentail-item-main__container select-fit-content">
								<span className="credentail-item-main--label">
									{CREDENTIAL_TYPES[key].label}
								</span>
								<EuiSelect
									options={options}
									value={credentialId}
									onChange={(e) => handleChangeCredential(e, key)}
								/>
							</div>
						</EuiFlexItem>
					);
				})}
			</EuiFlexGroup>
		</Wrapper>
	);
};

export default CredentialSelectionItem;

const Wrapper = styled.div`
	padding: var(--sp-4);
	.credentail-item-main__container {
		display: inline-flex;
		align-items: center;
		gap: var(--sp-4);

		.credentail-item-main--label {
			min-width: 90px;
		}
	}
`;
