import React, { useState } from "react";
import styled from "styled-components";
import { EuiFlexGroup, EuiFlexItem, EuiIcon, EuiButtonEmpty } from "@elastic/eui";

import { linkedPlusOutIcon, menuDotsHorizontal } from "components/Common/Icons";
import EmailSettingPopover from "./LinkSettingPopover";

import variables from "assets/css/variables.scss";

/**
 * Component for Email Setting Bar
 */
const EmailSettingBar = (props) => {
	const { onEdit, onDelete } = props;

	const [isOpenPopover, setIsOpenPopover] = useState(false);

	const handleOpenPopover = () => {
		setIsOpenPopover(!isOpenPopover);
	};
	const handleClosePopover = () => setIsOpenPopover(false);

	return (
		<Wrapper>
			<EuiFlexGroup gutterSize="s" alignItems="center">
				<EuiFlexItem>
					<EuiButtonEmpty size="xs" className="setting-icon-button">
						<EuiIcon type="copy" size="m" color="text" />
					</EuiButtonEmpty>
				</EuiFlexItem>
				<EuiFlexItem>
					<EuiButtonEmpty size="xs" className="setting-icon-button">
						<div className="icon-button-img">{linkedPlusOutIcon()}</div>
					</EuiButtonEmpty>
				</EuiFlexItem>
				<EuiFlexItem>
					<EmailSettingPopover
						button={
							<EuiButtonEmpty
								size="xs"
								className="setting-icon-button"
								onClick={handleOpenPopover}
							>
								<div className="icon-button-img">
									{menuDotsHorizontal(variables.primaryColor)}
								</div>
							</EuiButtonEmpty>
						}
						isOpenPopover={isOpenPopover}
						onClosePopover={handleClosePopover}
						onEdit={onEdit}
						onDelete={onDelete}
					/>
				</EuiFlexItem>
			</EuiFlexGroup>
		</Wrapper>
	);
};

export default EmailSettingBar;

const Wrapper = styled.div`
	.setting-icon-button {
		padding: 0 !important;
		height: fit-content !important;

		span {
			padding: 0;
			line-height: 1.5rem;
		}

		.icon-button-img {
			display: flex;
			align-items: center;
			z-index: 10000;
		}
	}
`;
