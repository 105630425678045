import React from "react";
import styled from "styled-components";
import { EuiFlexGroup, EuiFlexItem } from "@elastic/eui";
import { get, set } from "lodash";

import { RoundButtonWithPlusIcon } from "components/Common";
import * as MODEL from "core/model";

import { PAY_CONSTRUCTURES } from "../constants";

import SalaryItem from "./SalaryItem";
import HourlyItem from "./HourlyItem";
import OvertimeItem from "./OvertimeItem";
import DoubletimeItem from "./DoubletimeItem";
import CommissionItem from "./CommissionItem";
import PerformanceBonusItem from "./PerformanceBonusItem";

/**
 * Component for Pay Structure of Compensation
 */
const CompensationPayStructure = (props) => {
	const { data, onChange } = props;

	const payments = get(data, `${MODEL.compensation_financial._name}`, []) || [];

	const paymentInfoList = Object.values(PAY_CONSTRUCTURES).map((paymentInfo) => {
		const paymentIndex = payments?.findIndex(
			(_payment) =>
				_payment[MODEL.compensation_financial.pay_type_c] === paymentInfo.value
		);

		return {
			...paymentInfo,
			paymentIndex,
			payment: paymentIndex !== -1 ? payments[paymentIndex] : null,
		};
	});
	console.log("paymentInfoList: ", paymentInfoList);

	const handleClickNewItem = (paymentKey) => {
		const countPayment = payments?.length || 0;
		const paymentInfo = PAY_CONSTRUCTURES[paymentKey];
		const initData = paymentInfo.initData;
		const _newData = set(
			data,
			`${MODEL.compensation_financial._name}[${countPayment}]`,
			initData
		);
		onChange(_newData);
	};

	const handleChange = (index, itemValue) => {
		const _newData = set(
			data,
			`${MODEL.compensation_financial._name}[${index}]`,
			itemValue
		);
		onChange(_newData);
	};

	const renderPaymentList = () => {
		return paymentInfoList.map((item) => {
			const { key: paymentKey, label: paymentLabel, payment, paymentIndex } = item;
			const hasPayment = paymentIndex !== -1;

			if (!hasPayment) return null;

			let paymentComponent = null;

			switch (paymentKey) {
				case PAY_CONSTRUCTURES.salary.key:
					// Render Salary Editable Item
					const salayData = {
						value: payment?.[MODEL.compensation_financial.rate_fixed] || 0,
						low: payment?.[MODEL.compensation_financial.rate_low] || 0,
						high: payment?.[MODEL.compensation_financial.rate_high] || 0,
					};
					const handleChangeSalary = (values) => {
						handleChange(paymentIndex, {
							...(payment ?? {}),
							[MODEL.compensation_financial.rate_fixed]: values.value,
							[MODEL.compensation_financial.rate_low]: values.low,
							[MODEL.compensation_financial.rate_high]: values.high,
						});
					};

					paymentComponent = (
						<SalaryItem data={salayData} onChange={handleChangeSalary} />
					);
					break;

				case PAY_CONSTRUCTURES.hourly.key:
					// Render Hourly Editable Item
					const houlyData = {
						value: payment?.[MODEL.compensation_financial.rate_fixed] || 0,
						low: payment?.[MODEL.compensation_financial.rate_low] || 0,
						high: payment?.[MODEL.compensation_financial.rate_high] || 0,
					};
					const handleChangeHourly = (values) => {
						handleChange(paymentIndex, {
							...(payment ?? {}),
							[MODEL.compensation_financial.rate_fixed]: values.value,
							[MODEL.compensation_financial.rate_low]: values.low,
							[MODEL.compensation_financial.rate_high]: values.high,
						});
					};

					paymentComponent = (
						<HourlyItem data={houlyData} onChange={handleChangeHourly} />
					);
					break;

				case PAY_CONSTRUCTURES.overtime.key:
					// Render Overtime Editable Item
					const overtimeData = {
						value: payment?.[MODEL.compensation_financial.rate_overtime] || 0,
					};
					const handleChangeOvertime = (values) => {
						handleChange(paymentIndex, {
							...(payment ?? {}),
							[MODEL.compensation_financial.rate_overtime]: values.value,
						});
					};

					paymentComponent = (
						<OvertimeItem
							data={overtimeData}
							onChange={handleChangeOvertime}
						/>
					);
					break;

				case PAY_CONSTRUCTURES.dblOvertime.key:
					// Render Double Overtime Editable Item
					const doubleTimeData = {
						value:
							payment?.[MODEL.compensation_financial.rate_doubletime] || 0,
					};
					const handleChangeDoubleTime = (values) => {
						handleChange(paymentIndex, {
							...(payment ?? {}),
							[MODEL.compensation_financial.rate_doubletime]: values.value,
						});
					};

					paymentComponent = (
						<DoubletimeItem
							data={doubleTimeData}
							onChange={handleChangeDoubleTime}
						/>
					);
					break;

				case PAY_CONSTRUCTURES.commission.key:
					// Render Commission Editable Item
					const commissionData = {
						value: payment?.[MODEL.compensation_financial.rate_fixed] || 0,
						low: payment?.[MODEL.compensation_financial.rate_low] || 0,
						high: payment?.[MODEL.compensation_financial.rate_high] || 0,
					};
					const handleChangeCommission = (values) => {
						handleChange(paymentIndex, {
							...(payment ?? {}),
							[MODEL.compensation_financial.rate_fixed]: values.value,
							[MODEL.compensation_financial.rate_low]: values.low,
							[MODEL.compensation_financial.rate_high]: values.high,
						});
					};

					paymentComponent = (
						<CommissionItem
							data={commissionData}
							onChange={handleChangeCommission}
						/>
					);
					break;

				case PAY_CONSTRUCTURES.performanceBonus.key:
					// Render Performance Bonus Editable Item
					const performaceBonusData = {
						value: payment?.[MODEL.compensation_financial.rate_fixed] || 0,
						low: payment?.[MODEL.compensation_financial.rate_low] || 0,
						high: payment?.[MODEL.compensation_financial.rate_high] || 0,
					};
					const handleChangePerformaceBonus = (values) => {
						handleChange(paymentIndex, {
							...(payment ?? {}),
							[MODEL.compensation_financial.rate_fixed]: values.value,
							[MODEL.compensation_financial.rate_low]: values.low,
							[MODEL.compensation_financial.rate_high]: values.high,
						});
					};

					paymentComponent = (
						<PerformanceBonusItem
							data={performaceBonusData}
							onChange={handleChangePerformaceBonus}
						/>
					);
					break;

				default:
					paymentComponent = null;
			}

			if (paymentComponent) {
				return (
					<EuiFlexItem key={`pay-structure-value-${paymentKey}`}>
						<EuiFlexGroup
							gutterSize="xs"
							direction="row"
							className="detail"
							justifyContent="spaceBetween"
						>
							<EuiFlexItem grow={false}>
								<p className="type-x-small">{`${paymentLabel}: `}</p>
							</EuiFlexItem>
							<EuiFlexItem grow={false}>{paymentComponent}</EuiFlexItem>
						</EuiFlexGroup>
					</EuiFlexItem>
				);
			} else {
				return <div key={`pay-structure-${item.key}`} />;
			}
		});
	};

	const renderPaymentNewList = () => {
		// Render new payment information to add
		return paymentInfoList.map((item) => {
			const { key: paymentKey, label: paymentLabel, paymentIndex } = item;
			const hasPayment = paymentIndex !== -1;

			if (!hasPayment) {
				return (
					<EuiFlexItem key={`pay-structure-empty-${paymentKey}`} grow={false}>
						<RoundButtonWithPlusIcon
							label={paymentLabel}
							onClick={() => handleClickNewItem(paymentKey)}
						/>
					</EuiFlexItem>
				);
			}
			return <div key={`pay-structure-empty-${paymentKey}`} />;
		});
	};

	return (
		<Wrapper>
			<EuiFlexGroup gutterSize="m" direction="column" alignItems="flexStart">
				<EuiFlexItem className="type-title">
					<p className="type-small-definication-term text-center">
						Pay Structure
					</p>
				</EuiFlexItem>
				<EuiFlexItem className="content__container">
					<EuiFlexGroup gutterSize="m" direction="column">
						<EuiFlexItem>
							<EuiFlexGroup gutterSize="xs" direction="column">
								{renderPaymentList()}
							</EuiFlexGroup>
						</EuiFlexItem>
						<EuiFlexItem>
							<EuiFlexGroup gutterSize="s" direction="row" wrap>
								{renderPaymentNewList()}
							</EuiFlexGroup>
						</EuiFlexItem>
					</EuiFlexGroup>
				</EuiFlexItem>
			</EuiFlexGroup>
		</Wrapper>
	);
};

export default CompensationPayStructure;

const Wrapper = styled.div`
	display: flex;
	position: relative;
	width: 100%;
	padding: 0 var(--sp-6) 0 0;

	.type-title {
		color: var(--light-color);
	}

	.detail {
		.value {
			color: var(--eui-link-color);
		}
	}

	.content__container {
		width: 100%;

		.icon-button {
			margin: 0;
		}
	}
`;
