import React from "react";
import { convertToRaw } from "draft-js";
import draftToHtml from "draftjs-to-html";
import { get, isUndefined, isNull, pickBy } from "lodash";

import CustomQualifierBadge from "../components/Common/CustomQualifierBadge/CustomQualifierBadge";
import {
	fetchPersonRashAssignmentByObject,
	fetchPersonRashRestrictionByObject,
	getPersonSourcedPlatform,
} from "../store/person";
import moment from "moment";

export const editorStateToHtml = (editorState) => {
	return draftToHtml(convertToRaw(editorState.getCurrentContent()));
};

export const serialize = function (obj) {
	let str = [];
	for (let p in obj)
		if (obj.hasOwnProperty(p)) {
			str.push(encodeURIComponent(p) + "=" + encodeURIComponent(obj[p]));
		}
	return str.join("&");
};

export const objectChecker = (object, objectProperties, customReturn = null) => {
	// If it is null return false OR return the custom return value
	if (object === null) {
		if (customReturn) {
			return customReturn;
		}

		return false;
	}

	if (typeof object !== "undefined") {
		// Check if it is in "dot" format and convert if it is.
		if (typeof objectProperties === "string") {
			objectProperties = objectProperties.split(".");
		}

		for (let i = 0; i < objectProperties.length; i++) {
			if (typeof object !== "object" || object === null) {
				object = false;
				break;
			}

			if (!(objectProperties[i] in object)) {
				object = false;
				break;
			} else {
				object = object[objectProperties[i]];
			}
		}
	}

	if (!object) {
		return customReturn;
	}

	/*if (customReturn !== null && object === false) {
        return customReturn
    }*/

	return object;
};

export const setData = (key, val, obj) => {
	// if (!obj) obj = data; //outside (non-recursive) call, use "data" as our base object

	console.log("key", key);

	var ka = key.split(/\./); //split the key by the dots

	if (ka.length < 2) {
		obj[ka[0]] = val; //only one part (no dots) in key, just set value
	} else {
		if (!obj[ka[0]]) obj[ka[0]] = {}; //create our "new" base obj if it doesn't exist
		obj = obj[ka.shift()]; //remove the new "base" obj from string array, and hold actual object for recursive call
		setData(ka.join("."), val, obj); //join the remaining parts back up with dots, and recursively set data on our new "base" obj
	}
};

export const getFormattedQualifiers = (data) => {
	try {
		// Extract qualifiers and render
		const qualifiers = get(data, "qualifier_assignment", []);
		const formattedQualifiers = [];

		for (let i in qualifiers) {
			let styles = JSON.parse(qualifiers[i]["theme"])[0];

			const iconStyles = get(styles, "item_details.icon", {});
			const textStyles = get(styles, "item_details.text", {});
			const badgeStyles = get(styles, "item_details.badge", {});

			formattedQualifiers.push(
				<CustomQualifierBadge
					key={qualifiers[i]["qualifier_assignment_id"]}
					data={{
						name: qualifiers[i]["qualifier_name"],
						text: {
							...textStyles,
							name: qualifiers[i]["qualifier_name"],
						},
						badge: {
							...badgeStyles,
						},
						icon: {
							...iconStyles,
						},
					}}
				/>
			);
		}

		return formattedQualifiers;
	} catch (err) {
		alert(JSON.stringify(err));
		alert("err inside");
	}
};

export const hasPrimaryChanged = (editedData, data, trackingKey) => {
	// Find
	let editedDataPrimaryValue = null;
	let dataPrimaryValue = null;

	for (let i in editedData) {
		if (editedData[i]["is_primary"]) {
			editedDataPrimaryValue = editedData[i][trackingKey];
		}
	}

	for (let i in data) {
		if (data[i]["is_primary"]) {
			dataPrimaryValue = data[i][trackingKey];
		}
	}

	// Return the tracking key of the editedData, we will use that as our identifier when updating the primaries.
	if (editedDataPrimaryValue !== dataPrimaryValue) {
		return editedDataPrimaryValue;
	}

	return false;
};

export const getExternalLinkDetailsByLinkTypes = (
	personDetails,
	linkTypes,
	one = true
) => {
	let externalLinks = objectChecker(personDetails, ["externalLinks"]);

	const externalLinksData = [];

	if (externalLinks) {
		for (let i in externalLinks) {
			const dataLinkType = externalLinks[i]["link_type"];

			if (linkTypes.includes(dataLinkType)) {
				if (one) {
					return externalLinks[i]["url_value"];
				} else {
					externalLinksData.push(externalLinks[i]);
				}
			}
		}
	}

	if (!one) {
		return externalLinksData;
	}

	return false;
};

export const getEmails = (data, filters) => {
	data = objectChecker(data, ["emails"], []);

	data = data.filter((val) => {
		return filters.includes(val["email_type"]);
	});

	return data;
};

export const getPhones = (data, filters) => {
	data = objectChecker(data, ["phones"], []);

	return data.filter((val) => {
		return filters.includes(val["phone_type"]);
	});
};

export const getAddresses = (data, dataKey) => {
	const addressFields = [
		"person_address_id",
		"address_line_1",
		"address_line_2",
		"address_line_3",
		"address_city",
		"address_state",
		"address_zip",
		"address_country",
	];
	const addressFieldsPresent = {};

	for (let i in addressFields) {
		const addressField = addressFields[i];

		addressFieldsPresent[addressField] = objectChecker(data, [
			dataKey,
			0,
			addressField,
		]);

		if (addressFieldsPresent[addressField] === null) {
			addressFieldsPresent[addressField] = "";
		}
	}

	return addressFieldsPresent;
};

export const getCurrentEmployment = (data) => {
	const latestWorkHistory = objectChecker(data, ["workHistory"], []);

	if (latestWorkHistory.length === 0) {
		return [];
	}

	let maxPersonHistoryWorkId = null;
	let maxPersonHistoryWorkDetails = null;

	// for (let i in latestWorkHistory) {
	// 	let personHistoryWorkId = parseFloat(latestWorkHistory[i]['person_history_work_id'])

	// 	if (maxPersonHistoryWorkId === null) {
	// 		maxPersonHistoryWorkId = personHistoryWorkId
	// 		maxPersonHistoryWorkDetails = latestWorkHistory[i]
	// 	} else if (personHistoryWorkId > maxPersonHistoryWorkId) {
	// 		maxPersonHistoryWorkId = personHistoryWorkId
	// 		maxPersonHistoryWorkDetails = latestWorkHistory[i]
	// 	}
	// }

	if (latestWorkHistory.length > 1) {
		maxPersonHistoryWorkDetails = latestWorkHistory[1];
	} else {
		maxPersonHistoryWorkDetails = latestWorkHistory[0];
	}
	return [maxPersonHistoryWorkDetails];
};

export const bulkSync = (
	syncDeletes,
	syncEdited,
	syncAdded,
	syncPrimary,
	setLoading,
	callback
) => {
	setLoading(true);

	const socketCalls = [];

	const { data: deletedData, syncFunction: syncFuncDelete } = syncDeletes;
	const { data: editedData, syncFunction: syncFuncEdit } = syncEdited;
	const { data: addedData, syncFunction: syncFuncAdd } = syncAdded;
	const { data: isPrimaryUpdatedData, syncFunction: syncFuncPrimary } = syncPrimary;

	// Trim deletedData

	console.log({
		deletedData,
		editedData,
		addedData,
	});

	for (let i in deletedData) {
		for (let j in deletedData[i].args) {
			deletedData[i].args[j] = trimSpaceAndTrails(deletedData[i].args[j]);
		}

		socketCalls.push(syncFuncDelete(deletedData[i].args));
	}

	for (let i in editedData) {
		for (let j in editedData[i].args) {
			editedData[i].args[j] = trimSpaceAndTrails(editedData[i].args[j]);
		}

		socketCalls.push(syncFuncEdit(editedData[i].args));
	}

	for (let i in addedData) {
		for (let j in addedData[i].args) {
			addedData[i].args[j] = trimSpaceAndTrails(addedData[i].args[j]);
		}

		socketCalls.push(syncFuncAdd(addedData[i].args));
	}

	for (let i in isPrimaryUpdatedData) {
		socketCalls.push(syncFuncPrimary(isPrimaryUpdatedData[i].args));
	}

	Promise.all(socketCalls)
		.then((res) => {
			callback();
			setLoading(false);
		})
		.catch((err) => {
			alert(
				"Something went wrong when trying to update the Personal External Links" +
					JSON.stringify(err)
			);
			setLoading(false);
		});
};

export const sortByKey = (array, key, dsc = 1) => {
	if (array) {
		return array.sort((a, b) => {
			let x = a[key];
			let y = b[key];

			return x < y ? -1 * dsc : x > y ? 1 * dsc : 0;
		});
	} else {
		// Just return the value if it's not valid
		return array;
	}
};

export const truncate = (str, leng = 20) => {
	if (str) {
		return str.length > leng ? str.substring(0, leng - 3) + "..." : str;
	}
};

export const compareObject = (object1, object2) => {
	let equal = true;

	for (let i in object1) {
		if (!object2.hasOwnProperty(i)) {
			equal = false;
		}
	}

	return equal;
};

export const trimSpaceAndTrails = (str) => {
	if (!isNaN(str)) {
		return str;
	}

	return str.replace(/^\s+|\s+$/g, "");
};

export const compareJSON = function (obj1, obj2) {
	var ret = {};
	for (var i in obj2) {
		if (!obj1.hasOwnProperty(i) || obj2[i] !== obj1[i]) {
			ret[i] = obj2[i];
		}
	}
	return ret;
};

export const getCurrentRoute = () => {
	const url = window.location.href;

	let route = url.split("/");

	// return route[route.length]
	return route[route.length - 1];
};

export const isValidJSON = (text) => {
	if (typeof text !== "string") {
		return false;
	}

	if (
		/^[\],:{}\s]*$/.test(
			text
				.replace(/\\["\\\/bfnrtu]/g, "@")
				.replace(
					/"[^"\\\n\r]*"|true|false|null|-?\d+(?:\.\d*)?(?:[eE][+\-]?\d+)?/g,
					"]"
				)
				.replace(/(?:^|:|,)(?:\s*\[)+/g, "")
		)
	) {
		return true;
	} else {
		return false;
	}
};

export const capitalizeFirstLetter = (string) => {
	if (typeof string === "string") {
		return string.charAt(0).toUpperCase() + string.slice(1);
	}
	return "";
};

export const loadPersonAndRasData = (dispatch, personId) => {
	dispatch(fetchPersonRashAssignmentByObject(personId));
	// dispatch(fetchPersonDetail(personId));
	dispatch(fetchPersonRashRestrictionByObject(personId));
	dispatch(getPersonSourcedPlatform({ person_main_ref_ids: [personId] }));
};

export const deepDiffMapper = (function () {
	return {
		VALUE_CREATED: "created",
		VALUE_UPDATED: "updated",
		VALUE_DELETED: "deleted",
		VALUE_UNCHANGED: "unchanged",
		map: function (obj1, obj2) {
			if (this.isFunction(obj1) || this.isFunction(obj2)) {
				throw "Invalid argument. Function given, object expected.";
			}
			if (this.isValue(obj1) || this.isValue(obj2)) {
				return {
					type: this.compareValues(obj1, obj2),
					data: obj1 === undefined ? obj2 : obj1,
				};
			}

			var diff = {};
			for (var key in obj1) {
				if (this.isFunction(obj1[key])) {
					continue;
				}

				var value2 = undefined;
				if (obj2[key] !== undefined) {
					value2 = obj2[key];
				}

				diff[key] = this.map(obj1[key], value2);
			}
			for (var key in obj2) {
				if (this.isFunction(obj2[key]) || diff[key] !== undefined) {
					continue;
				}

				diff[key] = this.map(undefined, obj2[key]);
			}

			return diff;
		},
		compareValues: function (value1, value2) {
			if (value1 === value2) {
				return this.VALUE_UNCHANGED;
			}
			if (
				this.isDate(value1) &&
				this.isDate(value2) &&
				value1.getTime() === value2.getTime()
			) {
				return this.VALUE_UNCHANGED;
			}
			if (value1 === undefined) {
				return this.VALUE_CREATED;
			}
			if (value2 === undefined) {
				return this.VALUE_DELETED;
			}
			return this.VALUE_UPDATED;
		},
		isFunction: function (x) {
			return Object.prototype.toString.call(x) === "[object Function]";
		},
		isArray: function (x) {
			return Object.prototype.toString.call(x) === "[object Array]";
		},
		isDate: function (x) {
			return Object.prototype.toString.call(x) === "[object Date]";
		},
		isObject: function (x) {
			return Object.prototype.toString.call(x) === "[object Object]";
		},
		isValue: function (x) {
			return !this.isObject(x) && !this.isArray(x);
		},
	};
})();

export const removeEmpty = (obj) => {
	return Object.entries(obj).reduce((a, [k, v]) => (v ? ((a[k] = v), a) : a), {});
};

export const isStringEmptyOrNull = (data) => {
	return data === null || data === "";
};

export const getFilteredSourcingPeopleList = (
	_accountSettings,
	_sourcingPeopleList,
	_hidden
) => {
	let filteredSourcing = [];

	const sourcingHiddenItems = objectChecker(_accountSettings, [
		"sourcing_hidden_items",
	]);

	if (sourcingHiddenItems) {
		filteredSourcing = _sourcingPeopleList.filter((val) => {
			if (_hidden) {
				return !sourcingHiddenItems.includes(val.person_main_id);
			}
			return true;
		});
	} else {
		filteredSourcing = _sourcingPeopleList;
	}

	return filteredSourcing;
};

export const jsonParserWithTypeOf = (obj) => {
	const jsonObj = {
		data: JSON.parse(obj),
		type: typeof JSON.parse(obj),
	};
	return jsonObj;
};

export const timeoutPromise = (ms) => new Promise((resolve) => setTimeout(resolve, ms));

export const executeArrayOfFunctionsWithDelay = async (
	arrayOfFunctions,
	delayInMilliseconds
) => {
	// Execute functions sequentially but with a time delay
	for (let i in arrayOfFunctions) {
		let func = arrayOfFunctions[i];

		if (typeof func === "function") {
			func();

			await timeoutPromise(1000);
		}
	}
};

export const getSocketEndpoint = () => {
	const API_ENDPOINT_SANDBOX = "https://api.sandbox.crm.empower.associates";
	const API_ENDPOINT_DEVELOPMENT = "https://api.development.crm.empower.associates";

	const url = window.location.href;

	let defaultBaseUrl = API_ENDPOINT_SANDBOX;

	if (url.search("sandbox") !== -1) {
		defaultBaseUrl = API_ENDPOINT_SANDBOX;
	} else if (url.search("development") !== -1) {
		defaultBaseUrl = API_ENDPOINT_DEVELOPMENT;
	}

	return process.env.REACT_APP_SOCKET_URL || defaultBaseUrl;
};

export const getAPIEndpoint = () => {
	const API_ENDPOINT_SANDBOX = "https://api.sandbox.crm.empower.associates/api/v1";
	const API_ENDPOINT_DEVELOPMENT =
		"https://api.development.crm.empower.associates/api/v1";

	const url = window.location.href;

	let defaultBaseUrl = API_ENDPOINT_SANDBOX;

	if (url.search("sandbox") !== -1) {
		defaultBaseUrl = API_ENDPOINT_SANDBOX;
	} else if (url.search("development") !== -1) {
		defaultBaseUrl = API_ENDPOINT_DEVELOPMENT;
	}

	return process.env.REACT_APP_API_URL || defaultBaseUrl;
};

export var swapArrayLocs = (arr, index1, index2) => {
	console.log("index2: ", index2);
	console.log("index1: ", index1);
	const temp = arr[index1];
	console.log("temp: ", temp);

	arr[index1] = arr[index2];
	console.log("arr[index2]: ", arr[index2]);
	arr[index2] = temp;
	console.log("arr: ", arr);
};

/**
 * Get truncated text from input string
 */
export const truncateString = (str, maxLength, replaceText = "...") => {
	const lengthString = str?.length || 0;
	let appendString = replaceText;

	if (lengthString <= maxLength) {
		appendString = "";
	}

	return str?.slice(0, maxLength) + appendString;
};

export const getResultFromApiResponse = (res) => {
	return res?.response ? res.response : {};
};

export const makeOptionsWithLabel = (list, bindValue, bindText, labelName = null) => {
	let res = [];
	if (list?.length) {
		list.map((item) => {
			res.push({
				value: item[bindValue],
				[labelName ? labelName : "text"]: item[bindText],
			});
		});
	}
	return res;
};

export const getDate = (date, type = "YYYY-MM-DD") => {
	if (date) {
		let tmp = new Date(date);
		return moment(tmp).format(type);
	} else {
		return null;
	}
};

export const getDifferenceDateFromToday = (date) => {
	if (date) {
		let tmp = new Date(date);
		return moment(tmp).toNow(true);
	} else {
		return null;
	}
};

export const getInitialName = (value) => {
	let res = "";
	if (value) {
		const names = value.split(" ");
		if (names?.length) {
			res = names[0].substring(0, 1).toUpperCase();

			if (names.length > 1) {
				res += names[names.length - 1].substring(0, 1).toUpperCase();
			}
		}
	}
	return res;
};

export const getMeta = (metaName) => {
	const metas = document.getElementsByTagName("meta");

	for (let i = 0; i < metas.length; i++) {
		if (metas[i].getAttribute("name") === metaName) {
			return metas[i].getAttribute("content");
		}
	}

	return "";
};

export const showDateFormat = (str) => {
	let res = "";
	if (str) {
		const time = new Date(str);
		if (time) {
			res =
				time.getMonth() +
				1 +
				"/" +
				time.getDate() +
				"/" +
				time.getFullYear() +
				" @ " +
				time.getHours() +
				":" +
				time.getMinutes() +
				":" +
				time.getSeconds();
		}
	}
	return res;
};

export const stripHtmlTagsEditor = (html) => {
	return html ? html.replace(/<(.|\n)*?>/g, "") : "";
};

// Check invalid date
export const isValidDate = (date) => {
	var timestamp = Date.parse(date);
	return !isNaN(timestamp);
};

// Get tomorrow date
export const getTomorrow = () => {
	const tomorrow = moment().add(1, "days");
	return tomorrow;
};

export const escapeRegExp = (string) => {
	return string.replace(/[.*+?^${}()|[\]\\]/g, "\\$&");
};

/**
 *  Remove null or undefined elements from object
 *
 * @param {*} obj
 * @param {*} type: refine type
 * @returns refined obj
 */
export const refineObject = (obj = {}, type = 1) => {
	const refinedObj = pickBy(obj, (item) => !isNull(item) && !isUndefined(item));

	return refinedObj;
};

export const ip2int = (ip) => {
	return (
		ip.split(".").reduce(function (ipInt, octet) {
			return (ipInt << 8) + parseInt(octet, 10);
		}, 0) >>> 0
	);
};

export const removeTags = (str) => {
	if (str === null || str === "") return false;
	else str = str.toString();
	return str.replace(/(<([^>]+)>)/gi, "");
};

/**
 * Get unique ID
 */
export const makeUniqueId = (idLength) => {
	let result = "";
	const characters = "abcdefghijklmnopqrstuvwxyz0123456789";
	const charactersLength = characters.length;
	for (let i = 0; i < idLength; i++) {
		result += characters.charAt(Math.floor(Math.random() * charactersLength));
	}
	return result;
};
