import { cloneDeep } from "lodash";

import { setData } from "helpers/utilities";

import { SET_LOADING } from "./actionTypes";

/**
 * This reducer contains ALL the loading states of this app
 */

const initialState = {
	loaders: {
		person: {
			sitList: {
				skill: false,
				industry: false,
				title: false,
			},

			sit: {
				skill: false,
				industry: false,
				title: false,
				summary: false,
			},
		},
		job: {
			sitList: {
				skill: false,
				industry: false,
				title: false,
			},

			sit: {
				skill: false,
				industry: false,
				title: false,
				summary: false,
			},
		},
		company: {
			headquarters: {
				email: false,
				socialMedia: false,
				website: false,
			},
			summary: {
				details: false,
			},
		},
	},
};

const Reducer = (state = initialState, action) => {
	switch (action.type) {
		case SET_LOADING:
			return setLoading(state, action.payload);
		default: {
			return state;
		}
	}
};

// I love this setup, easily target loader variables by passing a string
// (e.g "loaders.person.sit.summary", and pass a value "false" or "true")
const setLoading = (state, payload) => {
	let newState = cloneDeep(state);

	setData(payload.key, payload.value, newState);

	return newState;
};

export default Reducer;
