import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
	faEnvelope,
	faClock,
	faCommentAltLines,
	faPlus,
} from "@fortawesome/pro-regular-svg-icons";
import {
	linkedInIcon,
	sendConnectionRequestIcon,
	sendEmailIcon,
	sendSMSIcon,
	waitIcon,
} from "components/Common/Icons/Icons";

export const EDGE_TYPES = {
	success: { key: "success", id: "success", label: "Success" },
	connect: { key: "connect", id: "connect", label: "Connect" },
	noConnect: { key: "no-connect", id: "no-connect", label: "No Connect" },
	fail: { key: "fail", id: "fail", label: "Fail" },
	generalOut: { key: "general-out", id: "general-out", label: "" },
};

export const POINT_TYPES = {
	/** Input Points */
	generalInPoint: {
		key: "general-in-point",
		type: "target",
		position: "top",
		className: "in-point-general",
		tooltip: "In",
		id: "general-in",
	},
	inPoint: {
		key: "in-point",
		type: "target",
		position: "top",
		className: "in-point-top-left",
		tooltip: "In",
		id: "in",
	},
	/** Output points */
	generalOutPoint: {
		key: "general-out-point",
		type: "source",
		position: "bottom",
		className: "out-point-general",
		tooltip: "Out",
		id: EDGE_TYPES.generalOut.id,
	},
	successPoint: {
		key: "success-point",
		type: "source",
		position: "bottom",
		className: "success-point",
		tooltip: "Success",
		id: EDGE_TYPES.success.id,
	},
	connectedPoint: {
		key: "connected-point",
		type: "source",
		position: "bottom",
		className: "connected-point",
		tooltip: "Connected",
		id: EDGE_TYPES.connect.id,
	},
	failPoint: {
		key: "fail-point",
		type: "source",
		position: "right",
		className: "fail-point",
		tooltip: "Alt route if no data or error",
		id: EDGE_TYPES.fail.id,
	},
	noConnectPoint: {
		key: "no-connect-point",
		type: "source",
		position: "bottom",
		className: "no-connect-point",
		tooltip: "No Connect",
		id: EDGE_TYPES.noConnect.id,
	},
};

export const NODE_POINT_TYPES = {
	successFail: {
		key: "success-fail",
		points: [POINT_TYPES.inPoint, POINT_TYPES.successPoint, POINT_TYPES.failPoint],
	},
	connect: {
		key: "connect-no-connect",
		points: [
			POINT_TYPES.inPoint,
			POINT_TYPES.connectedPoint,
			POINT_TYPES.noConnectPoint,
		],
	},
	general: {
		key: "generel-in-out",
		points: [POINT_TYPES.generalInPoint, POINT_TYPES.generalOutPoint],
	},
};

export const NODE_ICON_TYPES = {
	email: {
		key: "email",
		icon: <FontAwesomeIcon icon={faEnvelope} />,
		flyoutIcon: sendEmailIcon(),
	},
	sms: {
		key: "sms",
		icon: <FontAwesomeIcon icon={faCommentAltLines} />,
		flyoutIcon: sendSMSIcon(),
	},
	send_linkedin_connection_request: {
		key: "send_linkedin_connection_request",
		icon: linkedInIcon(),
		badge: <FontAwesomeIcon icon={faPlus} />,
		flyoutIcon: sendConnectionRequestIcon(),
	},
	await_acceptance: {
		key: "await_acceptance",
		icon: linkedInIcon(),
		badge: <FontAwesomeIcon icon={faClock} />,
		flyoutIcon: sendConnectionRequestIcon(),
	},
	send_linkedin_message: {
		key: "send_linkedin_message",
		icon: linkedInIcon(),
		badge: "1st",
		flyoutIcon: sendConnectionRequestIcon(),
	},
	logic_wait: {
		key: "logic_wait",
		icon: <FontAwesomeIcon icon={faClock} />,
		flyoutIcon: waitIcon(),
	},
};

// let nodeType = "send_linkedin_connection_request";
// 	switch (name) {
// 		case "Send Connection Request":
// 			nodeType = "send_linkedin_connection_request";
// 			break;
// 		case "Send Message":
// 			nodeType = "send_linkedin_message";
// 			break;
// 		case "Visit Profile":
// 			nodeType = "visitProfileNode";
// 			break;
// 		case "Send Email":
// 			nodeType = "send_email";
// 			break;
// 		case "Send SMS":
// 			nodeType = "send_sms_message";
// 			break;
// 		case "Conditional Logic":
// 			nodeType = "logic_conditional";
// 			break;
// 		case "Wait":
// 			nodeType = "logic_wait";
// 			break;
// 		case "Update Record":
// 			nodeType = "update_record";
// 			break;
// 		case "Create Activity":
// 			nodeType = "create_activity";
// 			break;
// 		default:
// 			nodeType = "send_linkedin_connection_request";
// 	}

export const WORKFLOW_NODE_LIST = {
	send_email: {
		id: "0",
		nodeType: "send_email",
		name: "Send Email",
		iconType: NODE_ICON_TYPES.email.key,
		pointType: NODE_POINT_TYPES.successFail.key,
	},
	send_sms_message: {
		id: "1",
		nodeType: "send_sms",
		name: "Send SMS",
		iconType: NODE_ICON_TYPES.sms.key,
		pointType: NODE_POINT_TYPES.successFail.key,
	},
	send_linkedin_connection_request: {
		id: "2",
		nodeType: "send_linkedin_connection_request",
		name: "Send Invite",
		iconType: NODE_ICON_TYPES.send_linkedin_connection_request.key,
		pointType: NODE_POINT_TYPES.successFail.key,
	},
	await_acceptance: {
		id: "3",
		nodeType: "await_acceptance",
		name: "Await Acceptance",
		iconType: NODE_ICON_TYPES.await_acceptance.key,
		pointType: NODE_POINT_TYPES.connect.key,
		isBadgeIcon: true,
	},
	send_linkedin_message: {
		id: "4",
		nodeType: "send_linkedin_message",
		name: "Send Message",
		iconType: NODE_ICON_TYPES.send_linkedin_message.key,
		pointType: NODE_POINT_TYPES.successFail.key,
	},
	logic_wait: {
		id: "5",
		nodeType: "logic_wait",
		name: "Wait",
		iconType: NODE_ICON_TYPES.logic_wait.key,
		pointType: NODE_POINT_TYPES.general.key,
	},
};
