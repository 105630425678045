const job_main = {
	_name: "job_main",
	id: "id",
	job_name: "job_name",
	job_sit_ref_id: "job_sit_ref_id",
	job_state_c: "job_state_c",
	job_status_c: "job_status_c",
	job_priority_c: "job_priority_c",
	company_main_ref_id: "company_main_ref_id",
	date_start: "date_start",
	date_end: "date_end",
	number_openings: "number_openings",
	job_detail: "job_detail",
	target_margin: "target_margin",
	count_placed: "count_placed",
	count_inprocess: "count_inprocess",
	count_pipelined: "count_pipelined",
	count_rejected: "count_rejected",
	count_total: "count_total",
};

const job_appication_counts = {
	_name: "application_counts",
	count_placed: "count_placed",
	count_inprocess: "count_inprocess",
	count_pipelined: "count_pipelined",
	count_rejected: "count_rejected",
	count_total: "count_total",
};

const job_detail = {
	public: "public",
	internal: "internal",
	publish: "publish",
};

const job_contact = {
	_name: "job_contact",
	id: "id",
	job_main_ref_id: "job_main_ref_id",
	person_main_ref_id: "person_main_ref_id",
	primary_level_gc: "primary_level_gc",
};

const job_compensation = {
	_name: "job_compensation",
	id: "id",
	job_main_ref_id: "job_main_ref_id",
	compensation_main_ref_id: "compensation_main_ref_id",
};

const job_requirement_sit = {
	_name: "job_requirement_sit",
	id: "id",
	job_main_ref_id: "job_main_ref_id",
	sit_main_ref_id: "sit_main_ref_id",
	sit_level_gc: "sit_level_gc",
	sit_focus_gc: "sit_focus_gc",
	requirement_type_c: "requirement_type_c",
};

const job_note = {
	_name: "job_note",
	id: "id",
	created_by_account_ref_id: "created_by_account_ref_id",
	table_source_c: "table_source_c",
	record_ref_id: "record_ref_id",
	note_details: "note_details",
};

const job_publication = {
	_name: "job_publication",
	id: "id",
	job_main_ref_id: "job_main_ref_id",
	credential_main_ref_id: "credential_main_ref_id",
	publication_status_c: "publication_status_c",
	publication_settings: "publication_settings",
};

const job_application = {
	_name: "job_application",
	id: "id",
	job_main_ref_id: "job_main_ref_id",
	person_main_ref_id: "person_main_ref_id",
	owner_account_ref_id: "owner_account_ref_id",
	application_type_c: "application_type_c",
	application_stage_c: "application_stage_c",
	application_source_type_c: "application_source_type_c",
	application_source_ref_id: "application_source_ref_id",
	submission_compensation_main_ref_id: "submission_compensation_main_ref_id",
	placement_compensation_main_ref_id: "placement_compensation_main_ref_id",
	application_details: "application_details",
};

const job_application_rating = {
	_name: "job_application_rating",
	id: "id",
	job_application_ref_id: "job_application_ref_id",
	rating_type_c: "rating_type_c",
	rating_state_c: "rating_state_c",
	rating_reason_c: "rating_reason_c",
	rating_value: "rating_value",
};

const job_location = {
	_name: "job_location",
	id: "id",
	job_main_ref_id: "job_main_ref_id",
	location_name: "location_name",
	location_type_c: "location_type_c",
	global_address_details_ref_id: "global_address_details_ref_id",
	contact_person_ref_id: "contact_person_ref_id",
};

const job_log_activity = {
	_name: "job_log_activity",
	id: "id",
	date_created: "date_created",
	account_main_ref_id: "account_main_ref_id",
	job_table_ref_id: "job_table_ref_id",
	log_object_c: "log_object_c",
	log_type_c: "log_type_c",
	log_action_c: "log_action_c",
	log_details: "log_details",
};

const job_requirement_visa = {
	_name: "job_requirement_visa",
	id: "id",
	job_main_ref_id: "job_main_ref_id",
	visa_type_sc: "visa_type_sc",
	requirement_type_c: "requirement_type_c",
};

const job_interview = {
	_name: "job_interview",
	id: "id",
	job_application_ref_id: "job_application_ref_id",
	interview_type_c: "interview_type_c",
	interview_stage_c: "interview_stage_c",
	interview_result_c: "interview_result_c",
	interview_datetime: "interview_datetime",
	interview_details: "interview_details",
	interview_note: "interview_note",
};

const job_requirement_education = {
	_name: "job_requirement_education",
	id: "id",
	job_main_ref_id: "job_main_ref_id",
	education_category_c: "education_category_c",
	education_type_c: "education_type_c",
	education_name: "education_name",
	education_specialization: "education_specialization",
	requirement_type_c: "requirement_type_c",
};

const job_attachment = {
	_name: "job_attachment",
	id: "id",
	attachment_type_sc: "attachment_type_sc",
	attachment_ref_id: "attachment_ref_id",
	system_file_ref_id: "system_file_ref_id",
};

const job_application_compensation = {
	_name: "job_application_compensation",
	id: "id",
	job_application_ref_id: "job_application_ref_id",
	compensation_main_ref_id: "compensation_main_ref_id",
	application_compensation_type_c: "application_compensation_type_c",
};

export {
	job_main,
	job_detail,
	job_contact,
	job_compensation,
	job_requirement_sit,
	job_note,
	job_publication,
	job_application,
	job_application_rating,
	job_location,
	job_log_activity,
	job_requirement_visa,
	job_interview,
	job_requirement_education,
	job_attachment,
	job_application_compensation,
	job_appication_counts,
};
