/**
 * Accounts
 */
export const FETCH_ACCOUNTS = "@@accounts/FETCH_ACCOUNTS";
export const SET_ACCOUNTS = "@@accounts/SET_ACCOUNTS";
export const SET_ACCOUNTLIST = "@@accounts/SET_ACCOUNTLIST";
export const GET_ACCOUNT_DETAIL = "@@accounts/GET_ACCOUNT_DETAIL";
export const CREATE_ACCOUNT = "@@accounts/CREATE_ACCOUNT";
export const UPDATE_ACCOUNT_FIELD = "@@accounts/UPDATE_ACCOUNT_FIELD";
export const UPDATE_ACCOUNT_PASSWORD = "@@accounts/UPDATE_ACCOUNT_PASSWORD";
export const SET_ACCOUNT_SETTINGS = "@@accounts/SET_ACCOUNT_SETTINGS";
export const GET_ACCOUNT_LOG_ACTIVITIES = "@@accounts/GET_ACCOUNT_LOG_ACTIVITIES";
export const SET_ACCOUNT_CAMPAIGN_DEFAULTS = "@@accounts/SET_ACCOUNT_CAMPAIGN_DEFAULTS";
export const FETCH_ACCOUNT_CAMPAIGN_DEFAULTS =
	"@@accounts/FETCH_ACCOUNT_CAMPAIGN_DEFAULTS";

// AMS for Account
export const REQUEST_ACCOUNT_AMS_LIST = "@@accounts/REQUEST_ACCOUNT_AMS_LIST";
export const RECEIVED_ACCOUNT_AMS_LIST = "@@accounts/RECEIVED_ACCOUNT_AMS_LIST";
export const SET_ACCOUNT_AMS_LIST = "@@accounts/SET_ACCOUNT_AMS_LIST";
export const SET_ACCOUNT_AMS_LIST_PARAMS = "@@accounts/SET_ACCOUNT_AMS_LIST_PARAMS";
