import React from "react";
import styled from "styled-components";

import EditableSelect from "../../CustomComponents/EditableSelect";

/**
 * Selection for phone type
 */
const PhoneTypeSelectionItem = (props) => {
	const { type, options, onChange, ...restProps } = props;

	const refinedOptions = options?.map((option) => {
		return {
			...option,
			label: <RangeIconWrapper>{option.inputDisplay}</RangeIconWrapper>,
		};
	});

	return (
		<Wrapper {...restProps}>
			<EditableSelect
				options={refinedOptions}
				value={type}
				onChange={onChange}
				hasUnderline={false}
				panelWidth={30}
				panelStyle={{
					padding: "var(--sp-2)",
					marginLeft: "5px",
					marginTop: "4px",
				}}
				hasArrow={false}
			/>
		</Wrapper>
	);
};

export default PhoneTypeSelectionItem;

const Wrapper = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;
	min-width: var(--sp-12);
	min-height: var(--sp-12);
	background: var(--eui-form-background-color);
	border: var(--sp-0_5) solid var(--border-color);
	border-radius: var(--sp-1_5);
	padding-left: 1px;
`;

const RangeIconWrapper = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
`;
