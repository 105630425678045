import React, { useMemo } from "react";
import styled from "styled-components";
import { EuiGlobalToastList } from "@elastic/eui";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck, faExclamationTriangle } from "@fortawesome/pro-regular-svg-icons";

import { useNotification, useCms, useAccount } from "core/useHooks";
import { NOTIFICATION_SHOW_TIME } from "components/global/constants";
import { CMS_LINKEDIN_STATES, NOTIFICATION_TYPES } from "./constants";
import { ActivityNotificationBody, ActivityNotificationTitle } from "./components";

/**
 * Main Component for Normal Notification
 */
const NormalNotification = () => {
	const { notifications, removeNotificationMessage } = useNotification();
	const { getPersonCommunicationList } = useCms();
	const { account, getAccountAmsList } = useAccount();

	const normalNotificationToast = useMemo(() => {
		const normalNotificationToast = [];
		notifications.map((notification) => {
			const notificationType = notification?.type;
			const notificationStatus = notification?.status;
			const notificationSubject = notification?.subject;
			const notificationError = notificationStatus?.error;
			const notificationMessage = notificationStatus?.message;
			const notificationState = notificationStatus?.state;

			let defaultTitle = "Success";

			let hasNotificationToast = true;
			let title = "";
			let color = "success";
			let text = "";
			let deliveredCms = notificationState === CMS_LINKEDIN_STATES.delivered.value;
			const notificationTypeInfo = Object.values(NOTIFICATION_TYPES).find(
				(item) => item.value === notificationType
			);
			let isError = false;

			switch (notificationType) {
				case NOTIFICATION_TYPES.fromCrawler.value:
				case NOTIFICATION_TYPES.normal.value:
					isError = notificationStatus === "error";
					defaultTitle = isError ? "Error" : "Success";
					title = notification?.title || defaultTitle;
					color = isError ? "danger" : "success";

					text = (
						<NotificationTextContainer>
							{notificationStatus === "error" ? (
								<div className="check-icon">
									<FontAwesomeIcon
										icon={faExclamationTriangle}
										color="var(--danger-color)"
									/>
								</div>
							) : null}
							<div className="message-body">
								{notification.message?.length > 0
									? notification.message.toString()
									: defaultTitle}
							</div>
						</NotificationTextContainer>
					);
					break;

				case NOTIFICATION_TYPES.general.value:
					color = notificationError ? "danger" : "success";
					title = (
						<NotificationTitleContainer>
							<span className="type-p-default">{notificationSubject}</span>
						</NotificationTitleContainer>
					);
					text = (
						<NotificationTextContainer>
							{notificationError ? (
								<div className="check-icon">
									<FontAwesomeIcon
										icon={faExclamationTriangle}
										color="var(--danger-color)"
									/>
								</div>
							) : null}
							<div className="message-body">
								{notificationMessage || ""}
							</div>
						</NotificationTextContainer>
					);
					break;

				case NOTIFICATION_TYPES.connectionRequestForCmsLinkedIn.value:
				case NOTIFICATION_TYPES.messageForCmsLinkedIn.value:
				case NOTIFICATION_TYPES.inMailForCmsLinkedIn.value:
					color = notificationError ? "danger" : "success";
					title = (
						<NotificationTitleContainer>
							{notificationTypeInfo?.icon}
							<span className="type-p-default">
								{notificationTypeInfo?.label}
							</span>
						</NotificationTitleContainer>
					);
					text = (
						<NotificationTextContainer>
							{notificationError ? (
								<div className="check-icon">
									<FontAwesomeIcon
										icon={faExclamationTriangle}
										color="var(--danger-color)"
									/>
								</div>
							) : deliveredCms ? (
								<div className="check-icon">
									<FontAwesomeIcon
										icon={faCheck}
										color="var(--success-color)"
									/>
								</div>
							) : null}
							<div className="message-body">
								{notificationMessage || ""}
							</div>
						</NotificationTextContainer>
					);
					getPersonCommunicationList();
					break;

				case NOTIFICATION_TYPES.cmsEmail.value:
				case NOTIFICATION_TYPES.cmsSms.value:
				case NOTIFICATION_TYPES.cmsMeeting.value:
					color = notificationError ? "danger" : "success";
					title = (
						<NotificationTitleContainer>
							{notificationTypeInfo?.icon}
							<span className="type-p-default">
								{notificationTypeInfo?.label}
							</span>
						</NotificationTitleContainer>
					);
					text = (
						<NotificationTextContainer>
							{notificationError ? (
								<div className="check-icon">
									<FontAwesomeIcon
										icon={faExclamationTriangle}
										color="var(--danger-color)"
									/>
								</div>
							) : (
								<div className="check-icon">
									<FontAwesomeIcon
										icon={faCheck}
										color="var(--success-color)"
									/>
								</div>
							)}
							<div className="message-body">
								{notificationMessage || ""}
							</div>
						</NotificationTextContainer>
					);
					getPersonCommunicationList();
					break;

				case NOTIFICATION_TYPES.amsOnScreenNotification.value:
					// Get ams onscreen notification from backend
					color = "success";
					title = (
						<NotificationTitleContainer>
							<ActivityNotificationTitle
								{...(notificationStatus?.data || {})}
							/>
						</NotificationTitleContainer>
					);
					text = (
						<NotificationTextContainer>
							<ActivityNotificationBody
								{...(notificationStatus?.data || {})}
							/>
						</NotificationTextContainer>
					);

					// Update ams list for account
					getAccountAmsList(account?.id);
					break;

				default:
					hasNotificationToast = false;
					break;
			}

			if (hasNotificationToast) {
				normalNotificationToast.push({
					title,
					color,
					text,
					id: notification.id,
					toastdata: notification,
					toastLifeTimeMs: NOTIFICATION_SHOW_TIME,
				});
			}

			return null;
		});

		return normalNotificationToast;
	}, [notifications]);

	const removeNotificationHandler = (data) => {
		removeNotificationMessage(data);
	};

	return (
		<Wrapper>
			<EuiGlobalToastList
				toasts={normalNotificationToast}
				dismissToast={removeNotificationHandler}
				toastLifeTimeMs={NOTIFICATION_SHOW_TIME}
			/>
		</Wrapper>
	);
};

export default NormalNotification;

/**
 * Styled Components
 */
const Wrapper = styled.div``;

const NotificationTitleContainer = styled.div`
	display: flex;
	align-items: center;
	span {
		color: var(--title-color);
		margin-left: var(--sp-2_5);
	}
`;

const NotificationTextContainer = styled.div`
	display: flex;
	align-items: center;

	.check-icon {
		margin-right: var(--sp-5);
		width: var(--sp-7);
		height: auto;
	}
`;
