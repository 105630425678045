import * as actionTypes from "./actionTypes";

const setFlyoutParams = (state, payload) => {
	return {
		...state,
		...payload,
	};
};

const openFlyout = (state) => {
	return {
		...state,
		isOpen: true,
	};
};

const closeFlyout = (state) => {
	return {
		...state,
		isOpen: false,
	};
};

/**
 * Initial State
 */
const initialState = {
	className: "",
	title: "Global Flyout",
	header: () => {},
	body: () => null,
	footer: null,
	size: "s",
	ownFocus: false,
	closeFlyout: () => {},
	isOpen: false,
};

const Reducer = (state = initialState, action) => {
	switch (action.type) {
		case actionTypes.SET_FLYOUT_PARAMS:
			return setFlyoutParams(state, action.payload);
		case actionTypes.OPEN_FLYOUT:
			return openFlyout(state);
		case actionTypes.CLOSE_FLYOUT:
			return closeFlyout(state);
		default:
			return state;
	}
};

export default Reducer;
