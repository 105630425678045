import React, { useState, useMemo, useEffect } from "react";
import styled from "styled-components";
import { EuiText, EuiFlexGroup, EuiFlexItem } from "@elastic/eui";
import { get } from "lodash";

import { SVGIcon } from "components/Common";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCommentsAlt, faStickyNote } from "@fortawesome/pro-duotone-svg-icons";

import * as MODEL from "core/model";
import { EditableSelect, TextAreaEditor } from "components/Common";
import { addressBookDuoNoteIcon, amsRecruitIcon } from "components/Common/Icons/Icons";
import { getGCSValue, getGSSValue } from "components/global/utils";
import { VALIDATE_MESSAGE } from "./constants";

import {
	useGlobal,
	useMacro,
	useCredential,
	useAccount,
	useNotification,
	usePerson,
	useQualifier,
	useCms,
} from "core/useHooks";

import {
	SendButton,
	SaveButton,
	ReplyButton,
	LoggedButton,
	OutboundButton,
} from "../components";
import { linkedInIcon } from "components/Common/Icons";

import { MESSAGE_STATES } from "./constants";
import RelatedItem from "./RelatedItem";

import { LINKEDIN_ACTION_TYPES } from "./constants";
import { LINKEDIN_CREDENTIAL_DEGREE, MESSAGE_MANUAL_QUALIFIER_NAMES } from "../constants";
import { MessageAms } from "../../MessageAms";
import useTranlation from "./useTranlation";
/**
 * Component for sending linkedin message
 */
const MessageSenderLinkedIn = (props) => {
	const {
		person,
		onSendLinkedinConnectionRequest,
		onSendLinkeInMessage,
		data,
		getCmsDraftList,
		getCmsMainList,
	} = props;

	const { translateTexts } = useTranlation(person);
	const { macroOptions } = useMacro();
	const { globals, gssList } = useGlobal();
	const { credentialsByAccount } = useCredential();
	const { account } = useAccount();
	const { setNotificationMessage } = useNotification();
	const { getPersonName } = usePerson();
	const { qualifiers, saveQualifiers } = useQualifier();
	const { callUpdateCmsCommunicaionDraft } = useCms();

	const [linkedInType, setLinkedInType] = useState(
		LINKEDIN_ACTION_TYPES.CONNENCTION_REQUEST.value
	);

	const [selectedCredential, setSelectedCredential] = useState(null);
	const [relateds, setRelateds] = useState([]);
	const [message, setMessage] = useState("");
	const [plainMessage, setPlainMessage] = useState("");
	const [messageStatus, setMessageStatus] = useState(MESSAGE_STATES.send.value);

	const personName = getPersonName(person);
	const personHistoryWork = get(person, `${MODEL.person_history_work._name}`);
	const personExternalLinks = get(person, `${MODEL.person_external_link._name}`);
	const personAddress = get(person, `${MODEL.person_address._name}`);
	const personEmails = get(person, `${MODEL.person_email._name}`);
	const [isRecruiting, setRecruiting] = useState(true);
	const [commDirect, setCommDirect] = useState(true); // false: 0 - incoming, true: 1 - outgoing,
	const [validationMessage, setValidationMessage] = useState("");

	const { credentialOptions } = useMemo(() => {
		const linkedInTypeC = getGCSValue(
			globals,
			"credential_main",
			"credential_type_c",
			"linkedin"
		);
		const authenticatedStatusC = getGCSValue(
			globals,
			MODEL.credential_main._name,
			MODEL.credential_main.credential_status_c,
			"authenticated"
		);
		const credentialOptions = [];
		if (credentialsByAccount?.length && globals?.length) {
			credentialsByAccount.forEach((credential) => {
				const credentialName =
					get(
						credential,
						`${MODEL.credential_main._name}.${MODEL.credential_main.credential_name}`
					) || "-";
				const credentialId =
					get(
						credential,
						`${MODEL.credential_main._name}.${MODEL.credential_main.id}`
					) || "-";
				const credentialTypeC = get(
					credential,
					`${MODEL.credential_main._name}.${MODEL.credential_main.credential_type_c}`
				);

				const credentialStatusC = get(
					credential,
					`${MODEL.credential_main._name}.${MODEL.credential_main.credential_status_c}`
				);

				if (
					credentialTypeC === linkedInTypeC &&
					credentialStatusC === authenticatedStatusC
				) {
					credentialOptions.push({
						id: credentialId,
						value: credentialId,
						label: credentialName,
					});
				}
			});
		}

		setSelectedCredential(credentialOptions?.[0]);

		return { credentialOptions };
	}, [credentialsByAccount, globals, setSelectedCredential]);

	const { linkinTypeOptions } = useMemo(() => {
		// Get first degree credential
		let firstDegreeRelationId = null;

		const credentialRelationship = person?.[MODEL.credential_relationship._name];
		if (!!globals?.length && !!credentialRelationship?.length) {
			const linkedInRelationTypeC = getGCSValue(
				globals,
				"credential_relationship",
				"relationship_type_c",
				"linkedin-connection"
			);
			const relationCredentials = credentialRelationship.filter((relationship) => {
				return (
					relationship[MODEL.credential_relationship.relationship_type_c] ===
					linkedInRelationTypeC
				);
			});
			const firstDegreeCredential = relationCredentials?.find(
				(item) =>
					item[MODEL.credential_relationship.relationship_details][
						"connection-degree"
					] === LINKEDIN_CREDENTIAL_DEGREE.first.value ||
					item[MODEL.credential_relationship.relationship_details][
						"connection-degree"
					] === 1
			);
			firstDegreeRelationId =
				firstDegreeCredential?.[
					MODEL.credential_relationship.credential_main_ref_id
				];
		}

		// Disable linkedin request
		let linkinTypeOptions = Object.values(LINKEDIN_ACTION_TYPES);
		if (selectedCredential?.value === firstDegreeRelationId) {
			linkinTypeOptions[0].disabled = true;

			setLinkedInType(LINKEDIN_ACTION_TYPES.STANDARD_MESSAGE.value);
		} else {
			linkinTypeOptions[0].disabled = false;
			setLinkedInType(LINKEDIN_ACTION_TYPES.CONNENCTION_REQUEST.value);
		}

		return {
			linkinTypeOptions,
		};
	}, [person, globals, selectedCredential]);

	const handleChangeCredential = (val) => {
		const credential = credentialOptions?.find((item) => item.value === val);
		setSelectedCredential(credential);
	};

	const changeEditAreaVal = (value, text) => {
		setMessage(value);
		setPlainMessage(text);
	};

	// Functions related to Message
	const handleChangeMessage = (state) => {
		setMessageStatus(state);
	};

	// Send Message
	const getProfileURLForLinkedInMessage = () => {
		let profileURL = "";

		const linkedInLinkType = getGCSValue(
			globals,
			"global",
			"link_type_c",
			"linkedin-profile"
		);
		const linkInfo = personExternalLinks?.find(
			(item) =>
				item[MODEL.person_external_link.global_link_type_c] === linkedInLinkType
		);

		if (linkInfo) {
			profileURL =
				linkInfo[MODEL.global_url_details._name][
					MODEL.global_url_details.url_value
				];
		}

		return profileURL;
	};

	const getNameForLinkedInMessage = () => {
		let name = "";

		const personFirstName = get(person, `${MODEL.person_main.name_first}`);
		const personMiddleName = get(person, `${MODEL.person_main.name_middle}`);
		const personLastName = get(person, `${MODEL.person_main.name_last}`);

		name =
			(personFirstName || "") +
			(personMiddleName ? ` ${personMiddleName}` : "") +
			(personLastName ? ` ${personLastName}` : "");

		return name;
	};

	const getTitleForLinkedInMessage = () => {
		// Set title of linkedin message
		let title = "";

		// Get company and work_title from person_work_history
		const company = get(personHistoryWork, `[0].${MODEL.company_main._name}`);
		const workTitle = get(
			personHistoryWork,
			`[0].${MODEL.person_history_work.work_title}`
		);

		if (company && workTitle) {
			title = `${workTitle} at ${company[MODEL.company_main.company_name]}`;
		} else {
			// if company is missing, use address of person
			const city = get(
				personAddress,
				`[0].${MODEL.global_address_details._name}.${MODEL.global_address_details.address_city}`
			);
			const country = get(
				personAddress,
				`[0].${MODEL.global_address_details._name}.${MODEL.global_address_details.address_country}`
			);

			title = (city ? `${city} ` : "") + (country || "");
		}

		return title || " ";
	};

	const getPersonEmail = () => {
		const email = get(
			personEmails,
			`[0].${MODEL.global_email_details._name}.${MODEL.global_email_details.email_value}`
		);

		return email;
	};

	const getSendInfo = (isReply) => {
		return {
			name: getNameForLinkedInMessage(),
			title: getTitleForLinkedInMessage(),
			profileUrl: getProfileURLForLinkedInMessage(),
			credentialId: selectedCredential?.value,
		};
	};

	const handleSuccessResponse = (res, type) => {
		const notificationTitle = (
			<EuiFlexGroup gutterSize="s">
				<EuiFlexItem grow={false}>{linkedInIcon()}</EuiFlexItem>
				<EuiFlexItem>{type.label}</EuiFlexItem>
			</EuiFlexGroup>
		);

		getCmsMainList();
		if (!res || res?.error) {
			setNotificationMessage({
				type: "normal",
				title: notificationTitle,
				message:
					res?.message ||
					`Failed to send message to linkedin for ${personName}`,
				status: "error",
			});
		}
	};

	const handleErrorResponse = (err, type) => {
		const notificationTitle = (
			<EuiFlexGroup gutterSize="s">
				<EuiFlexItem grow={false}>{linkedInIcon()}</EuiFlexItem>
				<EuiFlexItem>{type.label}</EuiFlexItem>
			</EuiFlexGroup>
		);

		setNotificationMessage({
			type: "normal",
			title: notificationTitle,
			message:
				err?.message || `Failed to send message to linkedin for ${personName}`,
			status: "error",
		});
	};

	const handleSendMessage = (isReply) => {
		if (message === "") {
			setValidationMessage(VALIDATE_MESSAGE.message.value);
			return;
		}
		const { name, title, profileUrl, credentialId } = getSendInfo(isReply);
		let email = "";

		if (credentialId == null) {
			setValidationMessage(VALIDATE_MESSAGE.credential.value);
			return;
		}
		setValidationMessage("");
		const texts = [plainMessage];
		translateTexts(texts).then((res) => {
			const translatedtexts = res.map((translatedtext, index) =>
				translatedtext["error"] ? texts[index] : translatedtext["results"]
			);
			switch (linkedInType) {
				case LINKEDIN_ACTION_TYPES.CONNENCTION_REQUEST.value:
					email = getPersonEmail();

					onSendLinkedinConnectionRequest(
						credentialId,
						profileUrl,
						translatedtexts[0],
						title,
						name,
						email,
						(res) => {
							handleSuccessResponse(
								res,
								LINKEDIN_ACTION_TYPES.CONNENCTION_REQUEST
							);
						},
						(err) => {
							handleErrorResponse(
								err,
								LINKEDIN_ACTION_TYPES.CONNENCTION_REQUEST
							);
						}
					);
					break;
				case LINKEDIN_ACTION_TYPES.STANDARD_MESSAGE.value:
					onSendLinkeInMessage(
						credentialId,
						profileUrl,
						translatedtexts[0],
						title,
						name,
						(res) => {
							handleSuccessResponse(
								res,
								LINKEDIN_ACTION_TYPES.STANDARD_MESSAGE
							);
						},
						(err) => {
							handleErrorResponse(
								err,
								LINKEDIN_ACTION_TYPES.CONNENCTION_REQUEST
							);
						}
					);
					break;
				case LINKEDIN_ACTION_TYPES.INMAIL.value:
					break;
				default:
					break;
			}
			setMessageStatus(MESSAGE_STATES.logged.value);
		});
	};

	const handleSaveMessage = (isReply) => {
		// Save a record for cms_communication_draft
		if (message === "") {
			setValidationMessage(VALIDATE_MESSAGE.message.value);
			return;
		}

		const { name, title, profileUrl, credentialId } = getSendInfo(isReply);

		if (credentialId == null) {
			setValidationMessage(VALIDATE_MESSAGE.credential.value);
			return;
		}
		setValidationMessage("");

		if (account?.id && credentialId) {
			const params = {
				[MODEL.cms_communication_draft.account_main_ref_id]: account?.id,
				[MODEL.cms_communication_draft.credential_main_ref_id]: credentialId,
				[MODEL.cms_communication_draft.comm_platform_c]: getGCSValue(
					globals,
					"cms_communication_main",
					"comm_platform_c",
					"manual"
				),
				[MODEL.cms_communication_draft.conversation_type_c]: getGCSValue(
					globals,
					"cms_conversation_main",
					"conversation_type_c",
					"message"
				),
				[MODEL.cms_communication_draft.member_object_type_sc]: getGSSValue(
					gssList,
					"OBJECT_BASE",
					"PERSON"
				),
				[MODEL.cms_communication_draft.member_object_subtype_sc]: getGSSValue(
					gssList,
					"OBJECT_PERSON",
					"MAIN"
				),
				[MODEL.cms_communication_draft.member_object_record_id]: person?.id,
				[MODEL.cms_communication_draft.comm_details]: {
					details: {
						email: getPersonEmail(),
						credentialId,
						profileUrl,
						plainMessage,
						title,
						name,
						linkedInType,
					},
					comm_direction: commDirect ? 1 : 0,
					comm_type: isRecruiting,
				},
			};

			const cmsQualifier = qualifiers.find(
				(qualifier) =>
					qualifier[MODEL.qualifier_main.qualifier_name] ===
					(isRecruiting
						? MESSAGE_MANUAL_QUALIFIER_NAMES.recruiting
						: MESSAGE_MANUAL_QUALIFIER_NAMES.sales)
			);

			new Promise((resolve, reject) => {
				if (cmsQualifier) {
					callUpdateCmsCommunicaionDraft(
						[params],
						(res) => {
							const cmsDraftId =
								res?.response?.procedure_results
									?.cms_communication_draft?.[0]?.id;
							if (cmsDraftId) {
								saveQualifiers(
									cmsDraftId,
									"cms-communication-draft",
									[cmsQualifier.id],
									(res) => {
										resolve({ error: false, data: res });
									},
									(err) => {
										reject({
											error: true,
											data: err,
											message:
												"Saved a new cms_communication_draft record, but failed to assign qualifiers to the record.",
										});
									}
								);
							} else {
								reject({
									error: true,
									data: res,
									message:
										"Saved a new cms_communication_draft record, but we are not getting its id from response.",
								});
							}
						},
						(err) => {
							reject({
								error: true,
								data: err,
								message: "Failed to save cms_communication_draft record.",
							});
						}
					);
				} else {
					reject({
						error: true,
						message:
							"Did not find a proper qualifier. Please check qualifier list.",
					});
				}
			})
				.then((res) => {
					handleChangeMessage(MESSAGE_STATES.logged.value);
					getCmsDraftList();
					setNotificationMessage({
						type: "normal",
						message: "LinkedIn Message Saved",
						status: "success",
					});
				})
				.catch((err) => {
					setNotificationMessage({
						type: "normal",
						message:
							err.message + ` ${err.data ? JSON.stringify(err.data) : ""}`,
						status: "error",
					});
				});
		}
	};

	const handleReplyMessage = () => {
		// handleChangeMessage(MESSAGE_STATES.send.value);
		handleSaveMessage(true);
	};

	const handleLoggedMessage = () => {};

	const handleOutboundMessage = () => {
		setCommDirect(true);
	};

	const handleInboundMessage = () => {
		setCommDirect(false);
	};

	// Functions related to Relation
	const addRelateds = (value) => {
		let _relateds = [...relateds];
		if (!relateds.includes(value)) {
			_relateds.push(value);
		}
		setRelateds(_relateds);
	};

	useEffect(() => {
		// data for Communication Draft Record
		const commDetails = data?.comm_details?.details;
		if (commDetails) {
			const initMessage = commDetails.plainMessage;

			if (initMessage) {
				setMessage(initMessage);
			}
		}
	}, [data]);

	// Buttons
	const renderButtons = () => {
		switch (messageStatus) {
			case MESSAGE_STATES.send.value:
				return (
					<div className="cms-buttons-wrapper">
						<div className="left-side">
							<SVGIcon
								icon={
									isRecruiting ? amsRecruitIcon : addressBookDuoNoteIcon
								}
								onClick={() =>
									isRecruiting
										? setRecruiting(false)
										: setRecruiting(true)
								}
								className="recruiting-sales-button"
							/>
							<FontAwesomeIcon
								icon={faCommentsAlt}
								color="var(--eui-darkest-shade-color)"
								className="message-status-icon"
								onClick={() =>
									setMessageStatus(MESSAGE_STATES.logNotes.value)
								}
							/>
						</div>
						<div className="right-side">
							<SendButton onSend={() => handleSendMessage()} />
						</div>
					</div>
				);

			case MESSAGE_STATES.logged.value:
				return (
					<div className="cms-buttons-wrapper">
						<div className="left-side">
							<FontAwesomeIcon
								icon={faStickyNote}
								color="var(--eui-text-disabled-color)"
								className="message-status-icon"
								onClick={() =>
									setMessageStatus(MESSAGE_STATES.send.value)
								}
							/>
							<LoggedButton onLogged={() => handleLoggedMessage()} />
						</div>
						<div className="right-side">
							<ReplyButton onReply={() => handleReplyMessage()} />
						</div>
					</div>
				);

			case MESSAGE_STATES.logNotes.value:
				return (
					<div className="cms-buttons-wrapper">
						<div className="left-side">
							<SVGIcon
								icon={
									isRecruiting ? amsRecruitIcon : addressBookDuoNoteIcon
								}
								onClick={() =>
									isRecruiting
										? setRecruiting(false)
										: setRecruiting(true)
								}
								className="recruiting-sales-button"
							/>
							<FontAwesomeIcon
								icon={faStickyNote}
								color="var(--eui-primary-color)"
								className="message-status-icon"
								onClick={() =>
									setMessageStatus(MESSAGE_STATES.send.value)
								}
							/>
							<OutboundButton
								onOutbound={() => handleOutboundMessage()}
								onInbound={() => handleInboundMessage()}
							/>
						</div>
						<div className="right-side">
							<SaveButton onSave={() => handleSaveMessage()} />
						</div>
					</div>
				);
			default:
				return null;
		}
	};

	return (
		<Wrapper>
			<EuiFlexGroup direction="column" gutterSize="xs">
				<EuiFlexItem>
					<div className="d-flex">
						<EuiText className="label my-auto mr-4">From:</EuiText>
						<EditableSelect
							options={credentialOptions}
							value={selectedCredential?.value}
							onChange={handleChangeCredential}
							hasUnderline={true}
							panelWidth={200}
						/>
						{/* <EuiCheckbox
                            id={"sendor-linkedin"}
                            className="my-auto mr-4"
                            checked={true}
                            onChange={() => {}}
                        /> */}
						<EuiText className="label my-auto mr-4 ml-4">Type:</EuiText>
						<EditableSelect
							options={linkinTypeOptions}
							value={linkedInType}
							onChange={setLinkedInType}
							hasUnderline={true}
							panelWidth={150}
						/>
					</div>
				</EuiFlexItem>
				<EuiFlexItem>
					<EuiFlexGroup gutterSize="s" direction="column">
						<EuiFlexItem>
							<EuiFlexGroup gutterSize="s" direction="column">
								<EuiFlexItem>
									<TextAreaEditor
										id="message-linkedin-editor"
										editorVal={message}
										autocompletOptions={macroOptions}
										changeEditVal={changeEditAreaVal}
									/>
								</EuiFlexItem>
								<EuiFlexItem>{renderButtons()}</EuiFlexItem>
							</EuiFlexGroup>
						</EuiFlexItem>
						<EuiFlexItem>
							<RelatedItem
								message={validationMessage}
								items={relateds}
								onAddItem={addRelateds}
							/>
						</EuiFlexItem>
					</EuiFlexGroup>
				</EuiFlexItem>
				<EuiFlexItem>
					<MessageAms person={person} subject={""} message={plainMessage} />
				</EuiFlexItem>
			</EuiFlexGroup>
		</Wrapper>
	);
};

export default MessageSenderLinkedIn;

/**
 * Styled Components
 */
const Wrapper = styled.div`
	.label {
		color: var(--light-color);
		font-size: 0.75rem;
		font-weight: 600;
		margin-right: 0.3rem;
		margin-top: 0.25rem;
	}

	.icon-badges {
		flex-wrap: wrap;
		display: flex;
	}

	.euiCheckbox .euiCheckbox__input + .euiCheckbox__square {
		border-radius: 50%;
	}

	.primary-color {
		color: var(--link-color);
	}
`;
