import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { EuiCheckbox, EuiFlexGroup, EuiFlexItem } from "@elastic/eui";
import { get, set } from "lodash";

import { getTomorrow, truncate } from "helpers/utilities";
import { EditableTextareaItem, SaveCancelBtn, AmsTaskTypeItem } from "components/Common";
import * as MODEL from "core/model";
import { useAccount, useAms, useGlobal } from "core/useHooks";

import MessageAmsReminderList from "./MessageAmsReminderList";
import MessageAccountSettingItem from "./MessageAccountSettingItem";
import AmsDatePickerItem from "./AmsDatePickerItem";
import moment from "moment";
import { getGCSValue, getGSSValue } from "components/global/utils";

const getToDay = () => {
	return moment().format("YYYY-MM-DD H:mm:ss");
};
/**
 * Component for AMS of Person Message
 */
const MessageAms = (props) => {
	const { person, subject, message } = props;

	const { account, accounts } = useAccount();
	const { updateAms: onUpdateAms } = useAms();
	const { globals, gssList } = useGlobal();

	const [isCheckedAms, setIsCheckedAms] = useState(false);
	const [reminders, setReminders] = useState([]);
	const [accountId, setAccountId] = useState(account.id);
	const [taskType, setTaskType] = useState(1);
	const [taskDate, setTaskDate] = useState(getTomorrow);
	const [amsName, setAmsName] = useState("");

	const initData = () => {
		// Init
		setReminders([]);
	};

	const handleChangeCheckedAms = (e) => {
		const checked = e.target.checked;

		setIsCheckedAms(checked);
	};

	const handleChangeAccount = (newOwner) => {
		setAccountId(newOwner?.id);
	};

	const handleChangeDueDate = (newDate) => {
		setTaskDate(newDate);
	};

	const handleChangeAmsName = (newName) => {
		setAmsName(newName);
	};

	const handleSaveAms = () => {
		// Create new AMS Main Item
		const newActivity = {
			[MODEL.ams_activity_main.activity_creator_account_ref_id]: accountId,
			[MODEL.ams_activity_main.activity_name]: amsName,
			[MODEL.ams_activity_main.activity_type_c]: 1, // generic
			[MODEL.ams_activity_main.activity_status_c]: 1, // pending
			[MODEL.ams_activity_main.activity_view_type_c]: 1, // note view
		};

		const newTask = {
			[MODEL.ams_activity_task.task_name]: amsName,
			[MODEL.ams_activity_task.task_due_date]: taskDate.toISOString(),
			[MODEL.ams_activity_task.task_status_c]: 1, // pending
			[MODEL.ams_activity_task.task_priority_c]: 0, // low
			[MODEL.ams_activity_task.task_type_c]: taskType, // task item
		};

		// Get ams_activity_time information for AMS
		const amsTimeInfo = {
			[MODEL.ams_activity_time._name]: {
				[MODEL.ams_activity_time.date_start]: getToDay(),
				[MODEL.ams_activity_time.date_end]: getToDay(),
			},
		};

		// Get ams_activity_reference information for AMS
		const objTypeSC = getGSSValue(gssList, "OBJECT_BASE", "PERSON");
		const objSubtypeSC = getGSSValue(gssList, "OBJECT_PERSON", "MAIN");
		const categoryC = getGCSValue(
			globals,
			"ams_activity_reference",
			"reference_category_c",
			"assigned"
		);
		const amsReferenceInfo = {
			[MODEL.ams_activity_reference._name]: {
				[MODEL.ams_activity_reference.reference_object_type_sc]: objTypeSC,
				[MODEL.ams_activity_reference.reference_object_subtype_sc]: objSubtypeSC,
				[MODEL.ams_activity_reference.reference_object_record_ref_id]: person?.id,
				[MODEL.ams_activity_reference.reference_category_c]: categoryC,
			},
		};

		// Create query for creating new AMS
		const amsMainInfo = {
			[MODEL.ams_activity_main._name]: newActivity,
		};
		const taskInfo = {
			[MODEL.ams_activity_task._name]: newTask,
		};

		const amsInfo = [amsMainInfo, amsReferenceInfo, amsTimeInfo, taskInfo];

		// Create a new ams_activity_main record
		onUpdateAms(
			amsInfo,
			(res) => {
				const amsTaskId = get(
					res,
					`${MODEL.ams_activity_task._name}[0].id`,
					null
				);
				if (amsTaskId) {
					// Get reminder information of AMS
					const newRemindersInfo = [];
					reminders.forEach((reminder) => {
						if (reminder.checked) {
							const _reminder = { ...reminder };
							delete _reminder.checked;
							delete _reminder.id;
							delete _reminder.index;
							const newReminder = {
								[MODEL.ams_activity_reminder._name]: {
									..._reminder,
									[MODEL.ams_activity_reminder
										.ams_activity_task_ref_id]: amsTaskId,
								},
							};

							newRemindersInfo.push(newReminder);
						}
					});

					if (newRemindersInfo.length > 0) {
						onUpdateAms(
							newRemindersInfo,
							(res) => {
								setIsCheckedAms(false);
								initData();
							},
							(err) => {
								setIsCheckedAms(false);
								initData();
							}
						);
					} else {
						setIsCheckedAms(false);
						initData();
					}
				} else {
					setIsCheckedAms(false);
					initData();
				}
			},
			() => {
				setIsCheckedAms(false);
				initData();
			}
		);
	};

	const handleCancelAms = () => {
		setIsCheckedAms(false);
		initData();
	};

	const handleUpdateReminder = (id, reminder) => {
		const _reminders = [...reminders];

		if (id === null) {
			_reminders.push({
				...reminder,
				index: _reminders.length,
			});
		} else {
			set(_reminders, `[${id}]`, reminder);
		}

		setReminders(_reminders);
	};

	const handleDeleteReminder = (id) => {
		if (id > 0) {
			const _reminders = [...reminders];
			_reminders.splice(id, 1);
			setReminders(_reminders);
		}
	};

	const handleChangeTaskType = (newType) => {
		setTaskType(newType);
	};

	useEffect(() => {
		const initName = truncate(subject || message || "New Activity", 20);

		const defaultTaskType = getGCSValue(
			globals,
			"ams_activity_task",
			"task_type_c",
			"phone"
		);
		setAmsName(initName);
		setTaskType(defaultTaskType);
	}, [subject, message, globals]);

	return (
		<Wrapper>
			<EuiFlexGroup gutterSize="s">
				<EuiFlexItem grow={false}>
					<EuiCheckbox
						id="person-message-ams-check-item"
						label=""
						checked={isCheckedAms}
						onChange={handleChangeCheckedAms}
						className="message-ams-check-item"
					/>
				</EuiFlexItem>
				{!isCheckedAms && (
					<EuiFlexItem>
						<p className="type-small-default disbled">
							Create an Activity for{" "}
							<span className="text-underline">me</span> to follow up on
							this
						</p>
					</EuiFlexItem>
				)}
				{isCheckedAms && (
					<EuiFlexItem>
						<EuiFlexGroup
							direction="column"
							gutterSize="s"
							className="type-small-default"
						>
							<EuiFlexItem>
								<div className="type-small-default">
									<span>Create an Activity for </span>
									<MessageAccountSettingItem
										accounts={accounts}
										account={account}
										ownerId={accountId}
										onChange={handleChangeAccount}
									/>
									<span> to follow up on this by </span>
									<AmsTaskTypeItem
										type={taskType}
										onChange={handleChangeTaskType}
										isIcon={false}
									/>
									&nbsp;
									<AmsDatePickerItem
										value={taskDate}
										type="MMM D, YYYY at h:mma"
										onChange={handleChangeDueDate}
									/>
									<span> with Subject: </span>
									<EditableTextareaItem
										value={amsName}
										onSave={handleChangeAmsName}
									/>
								</div>
							</EuiFlexItem>
							<EuiFlexItem>
								<MessageAmsReminderList
									data={reminders}
									onUpdateReminder={handleUpdateReminder}
									onDeleteReminder={handleDeleteReminder}
								/>
							</EuiFlexItem>
							<EuiFlexItem>
								<SaveCancelBtn
									onChangeSave={handleSaveAms}
									onChangeCancel={handleCancelAms}
								/>
							</EuiFlexItem>
						</EuiFlexGroup>
					</EuiFlexItem>
				)}
			</EuiFlexGroup>
		</Wrapper>
	);
};

export default MessageAms;

const Wrapper = styled.div`
	display: flex;
	padding: 0 var(--sp-4);

	.message-ams-check-item {
		margin-top: var(--sp-2);
	}

	p.disbled {
		color: var(--text-disabled-color);
	}

	.euiPopover.euiPopover--anchorDownCenter {
		margin-top: calc(-1 * var(--sp-1_5));
	}

	.date-picker-item-label {
		margin-top: -1px;
	}

	.euiFormControlLayout {
		margin-top: calc(-1 * var(--sp-1_5));
		width: fit-content !important;
		display: inline-flex !important;
	}

	.ams-task-type-item-label {
		margin-top: var(--sp-1);
	}

	.euiCheckbox__square {
		border-radius: var(--sp-2) !important;
	}
`;
