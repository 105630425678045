const file_main = {
    _name: 'file_main',
    id: 'id',
    file_type_c: 'file_type_c',
    file_name_original: 'file_name_original',
    file_name_internal: 'file_name_internal',
    file_storage_confirmed: 'file_storage_confirmed',
    bucket_name: 'bucket_name',
    file_details: 'file_details',
}

const file_log_activity = {
    _name: 'file_log_activity',
    id: 'id',
    date_created: 'date_created',
    account_main_ref_id: 'account_main_ref_id',
    file_table_ref_id: 'file_table_ref_id',
    log_object_c: 'log_object_c',
    log_type_c: 'log_type_c',
    log_action_c: 'log_action_c',
    file_log_detail_ref_id: 'file_log_detail_ref_id'
}

const file_log_detail = {
    _name: 'file_log_detail',
    id: 'id',
    detail_text: 'detail_text'
}

export {
    file_main,
    file_log_activity,
    file_log_detail,
}
