import API from "api";

const getThemeDetail = ({ id }) => {
	const query = {
		object: "theme-item",
		record_main_id: id,
	};
	return API().GenericAPI().getMainData(query);
};

const updateTheme = (data) => {
	const request = {
		object: "theme",
		data: data || {},
	};

	return API().GenericAPI().updateTableData(request);
};

const getThemeList = (query) => {
	return API().Theme().getThemeList(query);
};

export default {
	getThemeDetail,
	updateTheme,
	getThemeList,
};
