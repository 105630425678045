import React from "react";

import { useGlobalFlyout } from "core/useHooks";

import MessageFlyoutHeader from "./MessageFlyoutHeader";
import MessageFlyoutBody from "./MessageFlyoutBody";

/**
 * Hook for Message Flyout in CMS
 */
const useMessageFlyout = () => {
	const { setFlyout } = useGlobalFlyout();

	const setMessageFlyout = (person, onClose, params) => {
		const paramsFlyout = {
			size: "m",
			title: "",
			header: <MessageFlyoutHeader personId={person?.id} person={person} />,
			body: <MessageFlyoutBody person={person} params={params} />,
			footer: "",
			isOpen: true,
			...(onClose ? { closeFlyout: onClose } : {}),
		};

		setFlyout(paramsFlyout);
	};

	return {
		setMessageFlyout,
	};
};

export default useMessageFlyout;
