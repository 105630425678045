import { useDispatch } from "react-redux";

import { deleteRAS_NEW, updateRAS_NEW } from "helpers/actions";
import { _getRASRestrictions } from "helpers/selectors";
import { getGCSValue } from "components/global/utils";
import * as MODEL from "core/model";

import useGlobal from "./useGlobal";

/**
 * Hooks for RAS
 */
const useRas = () => {
	const dispatch = useDispatch();

	const { globals } = useGlobal();

	const rasRestriction = _getRASRestrictions();

	const saveRASIds = (
		object_ref_id,
		object_type,
		oweners,
		assigneds,
		successCallback,
		failCallback
	) => {
		const object_type_c = getGCSValue(
			globals,
			"ras_assignment_main",
			"object_type_c",
			object_type
		);
		const assignment_type_c_owner = getGCSValue(
			globals,
			"ras_assignment_main",
			"assignment_type_c",
			"owner"
		);
		const assignment_type_c_assigned = getGCSValue(
			globals,
			"ras_assignment_main",
			"assignment_type_c",
			"assigned"
		);

		const rasAssignmentList = [];

		oweners.forEach((id) => {
			rasAssignmentList.push({
				[MODEL.ras_assignment_main._name]: {
					object_type_c: object_type_c,
					object_ref_id: object_ref_id,
					assignment_type_c: assignment_type_c_owner,
					assignment_ref_id: id || 0,
				},
			});
		});

		assigneds.forEach((id) => {
			rasAssignmentList.push({
				[MODEL.ras_assignment_main._name]: {
					object_type_c: object_type_c,
					object_ref_id: object_ref_id,
					assignment_type_c: assignment_type_c_assigned,
					assignment_ref_id: id || 0,
				},
			});
		});

		dispatch(updateRAS_NEW(rasAssignmentList, successCallback, failCallback));
	};

	const deleteRASIds = (oweners, assigneds, successCallback, failCallback) => {
		const rasAssignmentList = [];

		[...oweners, ...assigneds].forEach((id) => {
			rasAssignmentList.push({
				[MODEL.ras_assignment_main._name]: {
					[MODEL.ras_assignment_main.id]: id,
				},
			});
		});

		dispatch(deleteRAS_NEW(rasAssignmentList, successCallback, failCallback));
	};

	const updateRestriction = (
		object_ref_id,
		object_type,
		restriction_type_c,
		restriction_id,
		successCallback,
		failCallback
	) => {
		const object_type_c = getGCSValue(
			globals,
			"ras_restriction_main",
			"object_type_c",
			object_type
		);

		if (restriction_type_c > 0) {
			const restriction = {
				[MODEL.ras_restriction_main._name]: {
					[MODEL.ras_restriction_main.id]: restriction_id,
					[MODEL.ras_restriction_main.object_type_c]: object_type_c,
					[MODEL.ras_restriction_main.object_ref_id]: object_ref_id,
					[MODEL.ras_restriction_main.restriction_type_c]: restriction_type_c,
				},
			};

			dispatch(updateRAS_NEW(restriction, successCallback, failCallback));
		} else {
			if (restriction_id) {
				const restriction = {
					[MODEL.ras_restriction_main._name]: {
						[MODEL.ras_restriction_main.id]: restriction_id,
					},
				};

				dispatch(deleteRAS_NEW(restriction, successCallback, failCallback));
			} else {
				if (successCallback) {
					successCallback("no operation");
				}
			}
		}
	};

	return {
		rasRestriction,
		saveRASIds,
		deleteRASIds,
		updateRestriction,
	};
};

export default useRas;
