import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import styled from "styled-components";
import {
	EuiFieldText,
	EuiText,
	EuiButtonIcon,
	EuiFlexGroup,
	EuiFlexItem,
} from "@elastic/eui";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPen } from "@fortawesome/pro-light-svg-icons/faPen";
import { get } from "lodash";

import { AvatarWithPicture, QualifierItem } from "components/Common";
import { getPersonDetail_NEW, updatePersonName } from "helpers/actions";
import { _getQualifiers } from "helpers/selectors";

import variables from "assets/css/variables.scss";

/**
 * Main Component for Company Person Header
 */
const CompanyPersonHeaderDisplay = (props) => {
	const dispatch = useDispatch();

	const {
		description,
		avatarLink,
		favourite,
		editable,
		recordId,
		firstName,
		middleName,
		lastName,
		nickName,
		globals,
		details,
		onSave,
	} = props;

	const qualifierList = _getQualifiers();

	const [isHoveredName, setIsHoveredName] = useState(false);
	const [editClickedName, setEditClickedName] = useState(false);

	const [name, setName] = useState({
		first: "",
		middle: "",
		last: "",
		nick: "",
	});

	useEffect(() => {
		setName({
			first: firstName,
			middle: middleName,
			last: lastName,
			nick: nickName,
		});

		setEditClickedName(false);
		setIsHoveredName(false);
	}, [firstName, middleName, lastName, nickName]);

	const handleChangeName = (e) => {
		setName({ ...name, [e.target.name]: e.target.value });
	};

	const handleSubmitClickedName = () => {
		dispatch(
			updatePersonName(recordId, name, () => {
				dispatch(getPersonDetail_NEW(recordId));
				setEditClickedName(false);
				setIsHoveredName(false);
			})
		);
	};

	const handleCancelClickedName = () => {
		setName({
			first: firstName,
			middle: middleName,
			last: lastName,
			nick: nickName,
		});

		setEditClickedName(false);
		setIsHoveredName(false);
	};

	const handleSaveQualifiers = (data) => {
		onSave(data);
	};

	return (
		<Wrapper>
			<div className="company-person-header-display--avatar">
				<AvatarWithPicture size="m" initials={description} picture={avatarLink} />
			</div>
			<div className="information-section">
				<div
					className="information-section--name"
					onMouseEnter={() => !editClickedName && setIsHoveredName(true)}
					onMouseLeave={() => !editClickedName && setIsHoveredName(false)}
				>
					{!editClickedName ? (
						<EuiFlexGroup gutterSize="xs" alignItems="center">
							<EuiFlexItem grow={false}>
								<EuiText size="s">{description}</EuiText>
							</EuiFlexItem>

							{/* <EuiFlexItem grow={false}>{favourite}</EuiFlexItem> */}

							{editable && isHoveredName && (
								<EuiFlexItem grow={false}>
									<button
										onClick={() =>
											setEditClickedName(
												(prevEditClicked) => !prevEditClicked
											)
										}
										className="icon-button mt-2"
									>
										<FontAwesomeIcon
											className="icon-styles"
											icon={faPen}
											style={
												editClickedName
													? {
															background:
																variables.hoveredBtnBgColor,
													  }
													: ""
											}
										/>
									</button>
								</EuiFlexItem>
							)}
						</EuiFlexGroup>
					) : (
						<EuiFlexGroup gutterSize="xs" className="mt-2">
							<EuiFlexItem grow={false}>
								<EuiFieldText
									placeholder="First"
									name="first"
									value={name.first}
									compressed
									onChange={(e) => handleChangeName(e)}
								/>
							</EuiFlexItem>
							<EuiFlexItem grow={false}>
								<EuiFieldText
									placeholder="Middle"
									name="middle"
									value={name.middle}
									compressed
									onChange={(e) => handleChangeName(e)}
								/>
							</EuiFlexItem>
							<EuiFlexItem grow={false}>
								<EuiFieldText
									placeholder="Last"
									name="last"
									value={name.last}
									compressed
									onChange={(e) => handleChangeName(e)}
								/>
							</EuiFlexItem>
							<EuiFlexItem grow={false}>
								<EuiFieldText
									placeholder="(Nickn.."
									name="nick"
									value={name.nick}
									compressed
									onChange={(e) => handleChangeName(e)}
								/>
							</EuiFlexItem>
							<EuiFlexItem grow={false}>
								<EuiFlexGroup gutterSize="s" justifyContent="center">
									<EuiFlexItem grow={false}>
										<EuiButtonIcon
											color="success"
											onClick={() => handleSubmitClickedName()}
											iconType="check"
										/>
									</EuiFlexItem>
									<EuiFlexItem grow={false}>
										<EuiButtonIcon
											color="danger"
											onClick={() => handleCancelClickedName()}
											iconType="cross"
										/>
									</EuiFlexItem>
								</EuiFlexGroup>
							</EuiFlexItem>
						</EuiFlexGroup>
					)}
				</div>
				<div>
					<QualifierItem
						qualifiers={get(details, "associations.qualifier_assignment", [])}
						qualifierList={qualifierList}
						associationType={"person"}
						globals={globals}
						onSave={handleSaveQualifiers}
					/>
				</div>
			</div>
		</Wrapper>
	);
};

export default CompanyPersonHeaderDisplay;

const Wrapper = styled.div`
	display: flex;
	align-items: center;

	.company-person-header-display--avatar {
		margin-bottom: auto;
		margin-top: auto;
		margin-right: 10px;
	}

	.information-section {
		display: flex;
		flex-direction: column;

		.information-section--name {
			display: flex;
			align-content: center;
			align-items: center;
			font-size: 1rem;
			line-height: 1.5rem;
			height: 25px;

			p {
				font-weight: 600;
			}

			input {
				&[name="first"],
				&[name="last"] {
					min-width: 80px;
					width: 80px;
				}

				&[name="middle"],
				&[name="nick"] {
					min-width: 65px;
					width: 65px;
				}
			}
		}

		.information-section--description {
			display: flex;
		}

		.information-section--identification {
			display: flex;
			align-items: center;

			div:not(.euiPopover) {
				margin-right: 5px;
			}

			.euiPopover {
				height: 20px;
				margin-top: -14px;
			}
		}
	}

	.qualifier-popover-panel {
		width: 155px;

		.custom-context-menu-item {
			& .euiContextMenu__itemLayout {
				flex-direction: row-reverse;

				& svg {
					margin-right: 0;
				}
			}
		}
	}
`;
