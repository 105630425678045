import React from "react";
import styled from "styled-components";
import { EuiSpacer, EuiCard, EuiTitle, EuiIcon, EuiText, EuiButton } from "@elastic/eui";
import { getMeta } from "helpers/utilities";

const { version } = require("../../../../package.json");

function DisplayError({ error, ...restProps }) {
    const handleToClipboard = () => {
        if (error) {
            const elem = document.createElement("p");
            elem.textContent = error;
            document.body.appendChild(elem);

            const selection = document.getSelection();
            const range = document.createRange();

            range.selectNode(elem);
            selection.removeAllRanges();
            selection.addRange(range);
            document.execCommand("copy");

            selection.removeAllRanges();
            document.body.removeChild(elem);
        }
    };

    const buildVersion = getMeta("build-version");
    console.log("buildVersion: ", buildVersion);

    return (
        <Wrapper {...restProps}>
            <div className="parent-div">
                <div className="child-div">
                    <EuiCard textAlign="center" className="err-card">
                        <EuiTitle>
                            <h2>Empower app has encountered the error and died. 💀</h2>
                        </EuiTitle>
                        <EuiSpacer />

                        <EuiIcon type="alert" size="xxl" color="accent" />
                        <EuiSpacer />

                        <EuiTitle>
                            <h5>The app is crashed. Sorry about that!</h5>
                        </EuiTitle>
                        <EuiSpacer />
                        <EuiTitle>
                            <h3>v{version}</h3>
                        </EuiTitle>
                        <EuiSpacer />

                        {error && <EuiText>{error}</EuiText>}
                        <EuiSpacer />

                        <EuiButton
                            className="mr-2"
                            onClick={() => {
                                handleToClipboard();
                            }}
                        >
                            Copy Error to Clipboard!
                        </EuiButton>

                        <EuiButton
                            onClick={() => {
                                console.log("Reload Clicked.");
                                window.location.reload();
                            }}
                        >
                            Click here to try refreshing...
                        </EuiButton>
                    </EuiCard>
                </div>
            </div>
        </Wrapper>
    );
}

export default DisplayError;

/**
 * Styled Components
 */
const Wrapper = styled.div`
    padding: 40px;
    width: 100vw;
    height: 100vh;

    .parent-div {
        width: 100%;
        height: 100%;
        max-width: 900px;
        display: flex;
        margin: auto;
    }

    .child-div {
        margin: auto;
    }

    .err-card {
        padding: 40px;
    }
`;
