import React from "react";
import styled from "styled-components";

import { AccordionWithHeader } from "components/Common";
import ActivityHeader from "./AmsListViewHeader";
import OpenActivityContent from "./OpenActivityContent";

/**
 * Main Components
 */
const OpenActivities = (props) => {
    const { isLoading, ...restProps } = props;

    return (
        <Wrapper>
            <AccordionWithHeader
                {...restProps}
                headerItems={
                    <ActivityHeader
                        title="Open Activities & Messages"
                        isPlusIcon={true}
                    />
                }
                isLoading={isLoading}
                isOpen={isLoading ? "closed" : "open"}
            >
                <OpenActivityContent />
            </AccordionWithHeader>
        </Wrapper>
    );
};

export default OpenActivities;

const Wrapper = styled.div``;
