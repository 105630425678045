import React from "react";
import { EuiPopover, EuiIcon, EuiContextMenu, EuiText } from "@elastic/eui";
import styled from "styled-components";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPen } from "@fortawesome/pro-light-svg-icons";

const flattenPanelTree = (tree, array = []) => {
	array.push(tree);

	if (tree.items) {
		tree.items.forEach((item) => {
			if (item.panel) {
				flattenPanelTree(item.panel, array);
				item.panel = item.panel.id;
			}
		});
	}

	return array;
};

/**
 * Component for Ams Note Item Setting Popover
 */
const AddressSettingPopover = (props) => {
	const { button, isOpenPopover, onClosePopover, onEdit, onDelete } = props;

	const panelTree = {
		id: 0,
		items: [
			{
				name: (
					<EuiText size="s">
						<span>Edit</span>
					</EuiText>
				),
				icon: (
					<FontAwesomeIcon icon={faPen} className="button-icon" fontSize={16} />
				),
				onClick: () => {
					onEdit();
					onClosePopover();
				},
			},
			{
				name: (
					<EuiText size="s">
						<span>Delete</span>
					</EuiText>
				),
				icon: <EuiIcon type="trash" color="danger" size="s" />,
				onClick: () => {
					onDelete();
					onClosePopover();
				},
			},
		],
	};

	const panels = flattenPanelTree(panelTree);

	return (
		<EuiPopover
			isOpen={isOpenPopover}
			button={button}
			closePopover={onClosePopover}
			anchorPosition="downRight"
			hasArrow={true}
			panelStyle={{
				padding: 0,
			}}
			withTitle={false}
		>
			<MenuContainer initialPanelId={0} panels={panels} />
		</EuiPopover>
	);
};

export default AddressSettingPopover;

const MenuContainer = styled(EuiContextMenu)`
	width: 6rem !important;

	.euiContextMenuItem {
		padding: 0.25rem 0.5rem;
	}
`;
