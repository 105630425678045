import React, { useEffect, useState, useMemo } from "react";
import {
	EuiButton,
	EuiButtonEmpty,
	EuiFlexGroup,
	EuiFlexItem,
	EuiFlyout,
	EuiFlyoutFooter,
	EuiFlyoutHeader,
	EuiFlyoutBody,
	EuiLoadingSpinner,
} from "@elastic/eui";
import { get } from "lodash";
import styled from "styled-components";

import { getTreeFromFlatData, toggleExpandedForAll } from "react-sortable-tree";
import DropdownTreeSelect from "react-dropdown-tree-select";
import "react-dropdown-tree-select/dist/styles.css";

import { capitalizeFirstLetter } from "helpers/utilities";
import { getGCSLabel } from "components/global/utils";
import * as MODEL from "core/model";
import { useGlobal } from "core/useHooks";

/**
 * Main Component for SIT flyout
 */
const SITFlyout = (props) => {
	const { sitType, handleSave, setOpen, open, allSits, sits, getAllSitByKeyword } =
		props;

	const { globals } = useGlobal();

	const [isLoading, setLoading] = useState(false);

	// variables
	let selectedSITs = [];

	const [optionsSitTree, setOptionsSitTree] = useState([]);

	const title = useMemo(() => {
		let title = "";
		if (globals?.length > 0) {
			title = getGCSLabel(globals, "sit_main", "sit_type_c", sitType);
		}

		return title;
	}, [sitType, globals]);

	const filteredSitList = useMemo(() => {
		const filteredSitList =
			allSits?.filter((sit) => sit[MODEL.sit_main.sit_type_c] === sitType) || [];

		return filteredSitList;
	}, [allSits, sitType]);

	useEffect(() => {
		const options = [];
		const optionsInit = [];

		// Search parent node without null
		if (filteredSitList?.length > 0) {
			filteredSitList.forEach((sit) => {
				const { id, sit_name, sit_keyword } = sit;

				const inputSit = sits?.find(
					(item) => item?.[MODEL.sit_main._name]?.[MODEL.sit_main.id] === id
				);

				const keywords = [];
				if (sit_keyword?.length > 0) {
					sit_keyword.map((keyword) => {
						if (keyword?.keyword) keywords.push(keyword.keyword);
					});
				}
				const searchKey = keywords.join(" ");

				if (id) {
					const optionCount = options.length;
					const initNode = {
						label: sit_name, // sit_name,
						id: id,
						value: sit_name, // searchKey,
						keywords: searchKey,
						index: optionCount,
						sit_parent_id: sit?.sit_parent_id,
						checked: false,
					};
					const node = {
						...initNode,
						checked: inputSit ? true : false,
					};
					optionsInit.push(initNode);
					options.push(node);
				}
			});
		}

		const optionTree = toggleExpandedForAll({
			treeData: getTreeFromFlatData({
				flatData: options,
				getKey: (node) => node.id, // resolve a node's key
				getParentKey: (node) =>
					node.sit_parent_id === 0 ? null : node.sit_parent_id, // resolve a node's parent's key
				rootKey: null, // The value of the parent key when there is no parent (i.e., at root level)
			}),
		});

		const optionTreeInit = toggleExpandedForAll({
			treeData: getTreeFromFlatData({
				flatData: optionsInit,
				getKey: (node) => node.id, // resolve a node's key
				getParentKey: (node) =>
					node.sit_parent_id === 0 ? null : node.sit_parent_id, // resolve a node's parent's key
				rootKey: null, // The value of the parent key when there is no parent (i.e., at root level)
			}),
		});

		const assignObjectPaths = (obj, stack) => {
			Object.keys(obj).forEach((k) => {
				const node = obj[k];
				if (node && typeof node === "object") {
					node.path = stack ? `${stack}.${k}` : `${k}`;
					assignObjectPaths(node, node.path);
				}
			});
		};

		assignObjectPaths(optionTreeInit);
		assignObjectPaths(optionTree);

		optionTree.forEach((parentItem) => {
			const children = get(parentItem, "children");
			if (children) {
				children.forEach((item) => {
					item.checked = parentItem.checked ? parentItem.checked : item.checked;
				});
			}
		});

		// setOptionsSitTreeInitial(optionTreeInit);
		setOptionsSitTree(optionTree);
	}, [sitType, sits, filteredSitList]);

	/**
	 * Handlers
	 */
	const saveAndClose = () => {
		handleSave(selectedSITs);
	};

	const getSitListByKeyword = (searchValue) => {
		setLoading(true);
		getAllSitByKeyword(
			sitType,
			searchValue,
			() => {
				setLoading(false);
			},
			() => {
				setLoading(false);
			}
		);
	};

	const searchPredicate = (node, searchTerm) => {
		return node.keywords && node.keywords.toLowerCase().indexOf(searchTerm) >= 0;
	};

	const onSitTreeChange = (currentNode, selectedNodes) => {
		selectedSITs = selectedNodes;
	};

	useEffect(() => {
		// Simulate initial load.
		if (sitType) {
			getSitListByKeyword("");
		}
	}, [sitType]);

	const renderFlyout = () => {
		return (
			<Wrapper
				onClose={() => setOpen(false)}
				size="s"
				aria-labelledby="flyoutComplicatedTitle"
			>
				<EuiFlyoutHeader hasBorder>
					<div>
						<p style={{ fontSize: "1.8rem" }}>
							Add {capitalizeFirstLetter(title)}
						</p>
					</div>
				</EuiFlyoutHeader>

				<EuiFlyoutBody>
					<div>
						{isLoading ? (
							<EuiLoadingSpinner size="l" />
						) : (
							<DropdownTreeSelect
								data={optionsSitTree}
								onChange={onSitTreeChange}
								mode="multiSelect"
								keepTreeOnSearch={true}
								searchPredicate={searchPredicate}
							/>
						)}
					</div>
				</EuiFlyoutBody>

				<EuiFlyoutFooter className="mb-0">
					<EuiFlexGroup justifyContent="spaceBetween">
						<EuiFlexItem grow={false}>
							<EuiButtonEmpty
								iconType="cross"
								onClick={() => setOpen(false)}
								flush="left"
							>
								Close
							</EuiButtonEmpty>
						</EuiFlexItem>
						<EuiFlexItem grow={false}>
							<EuiButton onClick={saveAndClose} fill>
								Save
							</EuiButton>
						</EuiFlexItem>
					</EuiFlexGroup>
				</EuiFlyoutFooter>
			</Wrapper>
		);
	};

	if (open) {
		return renderFlyout();
	}

	return null;
};

export default SITFlyout;

const Wrapper = styled(EuiFlyout)`
	.loading-icon {
		margin-left: var(--sp-4);
	}

	.dropdown-content {
		max-height: 50vh;
		overflow-y: auto;
	}
`;
