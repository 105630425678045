import API from "api";
import { SECURITY_MAIN_ID } from "components/global/constants";

import {
	security_group,
	security_group_access,
	security_group_member,
	security_main,
} from "core/model";

import {
	makeSort,
	makeFieldContent,
	makeTableJoin,
	makeSearchField,
} from "./commonService";

/** ---------------- Generic API ---------------------- */
const getSecurityMainList = ({
	searchTerm = "",
	pageNumber = 1,
	pageCount = 30,
	params = null,
}) => {
	const query = {
		page_number: pageNumber,
		page_count: pageCount,
		table_main: security_main._name,
		table_join: makeTableJoin([security_group]),
		fields: [
			makeFieldContent(security_main, security_main.id),
			makeFieldContent(security_main, security_main.security_name),
			makeFieldContent(security_main, security_main.security_type_c),

			makeFieldContent(security_group, security_group.id),
			makeFieldContent(security_group, security_group.group_name),
			makeFieldContent(security_group, security_group.group_owner_account_ref_id),
			makeFieldContent(security_group, security_group.security_main_ref_id),
		],
		sort: [makeSort(security_main, security_main.id)],
		search_fields: [
			{
				table: security_main._name,
				field: security_main.security_name,
			},
		],
		search_type: "term",
		search_term: searchTerm,
		...(params ? { ...params } : {}),
	};
	return API().GenericAPI().getTableData(query);
};

const getSecurityGroupMembersById = ({ id }) => {
	const query = {
		table_main: security_group_member._name,
		fields: [
			makeFieldContent(security_group_member, security_group_member.id),
			makeFieldContent(
				security_group_member,
				security_group_member.account_main_ref_id
			),
			makeFieldContent(
				security_group_member,
				security_group_member.security_group_ref_id
			),
		],
		search_type: "ref",
		search_ref: [parseInt(id, 10)],
		search_fields: [
			makeSearchField(
				security_group_member,
				security_group_member.security_group_ref_id
			),
		],
	};

	return API().GenericAPI().getTableData(query);
};

const getSecurityGroupAccessById = ({ id }) => {
	const query = {
		table_main: security_group_access._name,
		fields: [
			makeFieldContent(security_group_access, security_group_access.id),
			makeFieldContent(security_group_access, security_group_access.object_base_sc),
			makeFieldContent(
				security_group_access,
				security_group_access.security_access_sc
			),
			makeFieldContent(
				security_group_access,
				security_group_access.security_group_ref_id
			),
		],
		search_type: "ref",
		search_ref: [parseInt(id, 10)],
		search_fields: [
			makeSearchField(
				security_group_access,
				security_group_access.security_group_ref_id
			),
		],
	};

	return API().GenericAPI().getTableData(query);
};

const getSecurityDetail = () => {
	const query = {
		object: "security",
		record_main_id: SECURITY_MAIN_ID,
	};

	return API().GenericAPI().getMainData(query);
};

const getSecurityDetial = ({ id }) => {
	const query = {
		object: "security",
		record_main_id: id,
	};

	return API().GenericAPI().getMainData(query);
};

const updateSecurity = (data) => {
	const query = {
		object: "security",
		data: data || {},
	};

	return API().GenericAPI().updateTableData(query);
};

const deleteSecurity = (data) => {
	const query = {
		object: "security",
		data: data || {},
	};

	return API().GenericAPI().deleteTableData(query);
};
/** --------------------------------------------------------- */

/** ----------------------- Custom API ---------------------- */

/* ---------------------------------------------------------- */

export default {
	getSecurityMainList,
	getSecurityDetail,
	getSecurityDetial,
	getSecurityGroupMembersById,
	getSecurityGroupAccessById,
	updateSecurity,
	deleteSecurity,
};
