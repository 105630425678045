import React from "react";

import {
    faReply,
    faUsers,
    faEnvelope,
    faCommentAltLines,
} from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
    chatIcon,
    emailIcon,
    linkTypeLinkedInIcon,
    phoneIcon,
} from "components/Common/Icons/Icons";

/**
 * Communication Direction Type List
 */
export const CMS_COMM_DIRECTION_TYPE_LIST = {
    incoming: {
        key: "incoming",
        value: 0,
        icon: <FontAwesomeIcon icon={faReply} />,
    },
    outgoing: {
        key: "outgoing",
        value: 1,
        icon: <FontAwesomeIcon icon={faReply} flip="horizontal" />,
    },
};

/**
 * Communication Platform Type List
 */
export const CMS_COMM_PLATFORM_TYPE_LIST = {
    manual: {
        key: "manual",
        value: 0,
        label: "Manual",
        className: "manual",
        icon: null,
        messageType: "deriect",
        detailKey: "call",
    },
    linkedIn: {
        key: "linkedIn",
        value: 1,
        label: "LinkedIn",
        className: "linkedIn",
        icon: linkTypeLinkedInIcon(),
        editIcon: linkTypeLinkedInIcon("var(--white-color)"),
        messageType: "linkedin",
        detailKey: "message",
    },
    spark: {
        key: "spark",
        value: 2,
        label: "Spark",
        className: "spark",
        messageType: "email",
        icon: emailIcon(),
        editIcon: emailIcon("var(--white-color)"),
        detailKey: "email",
    },
    plivo: {
        key: "plivo",
        value: 3,
        label: "Plivo",
        className: "plivo",
        messageType: "phone",
        icon: chatIcon(),
        editIcon: chatIcon("var(--white-color)"),
        detailKey: "sms",
    },
    phone: {
        key: "phone",
        value: 4,
        label: "Phone",
        className: "phone",
        messageType: "phone",
        icon: phoneIcon(),
        editIcon: phoneIcon("var(--white-color)"),
        detailKey: "call",
    },
    o365: {
        key: "o365",
        value: 5,
        label: "O365",
        className: "o365",
        messageType: "email",
        icon: emailIcon(),
        editIcon: emailIcon("var(--white-color)"),
        detailKey: "email",
    },
    import: {
        key: "import",
        value: 6,
        label: "Import",
        className: "import",
        icon: <FontAwesomeIcon icon={faEnvelope} size="lg" />,
        detailKey: "message",
    },
    skype: {
        key: "skype",
        value: 7,
        label: "Skype",
        className: "skype",
        icon: <FontAwesomeIcon icon={faEnvelope} size="lg" />,
        detailKey: "message",
    },
    msTeam: {
        key: "msTeam",
        value: 8,
        label: "MS Teamn",
        className: "ms-team",
        icon: <FontAwesomeIcon icon={faCommentAltLines} size="lg" />,
        detailKey: "message",
    },
    zoom: {
        key: "zoom",
        value: 9,
        label: "Zoom",
        className: "zoom",
        icon: <FontAwesomeIcon icon={faUsers} size="lg" />,
        detailKey: "message",
    },
};

/**
 * Conversation Type List
 */
export const CMS_CONVERSATION_TYPE_LIST = {
    email: {
        key: "email",
        value: 1,
        label: "Email",
        className: "email",
        messageType: "email",
        icon: emailIcon(),
        editIcon: emailIcon("var(--white-color)"),
        detailKey: "email",
    },
    message: {
        key: "message",
        value: 2,
        label: "Message",
        className: "message",
        icon: linkTypeLinkedInIcon(),
        editIcon: linkTypeLinkedInIcon("var(--white-color)"),
        messageType: "linkedin",
        detailKey: "message",
    },
    sms: {
        key: "sms",
        value: 3,
        label: "SMS",
        className: "sms",
        icon: <FontAwesomeIcon icon={faEnvelope} size="lg" />,
        detailKey: "message",
    },
    phone: {
        key: "phone",
        value: 4,
        label: "Phone",
        className: "phone",
        messageType: "phone",
        icon: phoneIcon(),
        editIcon: phoneIcon("var(--white-color)"),
        detailKey: "call",
    },
    video: {
        key: "video",
        value: 5,
        label: "Video",
        className: "video",
        messageType: "video",
        icon: <FontAwesomeIcon icon={faCommentAltLines} size="lg" />,
        detailKey: "video",
    },
};

/**
 * Constants related to Message Editor
 */
export const ICON_NAME = {
    EMAIL_ICON: "email",
    LINKEDIN_ICON: "linkedin",
    CALENDAR_ICON: "calendar",
    BAG_ICON: "bag",
    ONLINE_ICON: "online",
};

export const MESSAGE_BTNS = {
    EMAIL_BTN: "email",
    LINKEDIN_BTN: "linkedin",
    CHAT_BTN: "chat",
    PHONE_BTN: "phone",
    GROUP_BTN: "group",
    URL_LINK_BTN: "url_link",
};

export const MESSAGE_STATES = {
    send: { key: "send", label: "Send", value: "send" },
    logged: { key: "logged", label: "Logged", value: "logged" },
    logNotes: { key: "logNotes", label: "Log Notes Only", value: "logNotes" },
};

export const CALL_STATES = {
    send: { key: "send", label: "Send", value: "send" },
    logged: { key: "logged", label: "Logged", value: "logged" },
    logNotes: { key: "logNotes", label: "Log Notes Only", value: "logNotes" },
    end: { key: "end", label: "End & Save Notes Only", value: "end" },
};
