import React from "react";
import { EuiLink } from "@elastic/eui";
import styled from "styled-components";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/pro-regular-svg-icons";

/**
 * Badge Component for CMS Static Item
 */
const CmsRelatedBadgeItem = (props) => {
    const { data } = props;

    const handleClickTaskDelete = () => {};

    return (
        <Wrapper className="badge-hollow-border">
            <div className={`badge-icon ${data?.status ? "active" : ""}`} />
            <div className="badge-label">
                <EuiLink>
                    <span>{data?.name}</span>
                </EuiLink>
            </div>
            <div className="badge-delete" onClick={handleClickTaskDelete}>
                <FontAwesomeIcon icon={faTimes} size="sm" />
            </div>
        </Wrapper>
    );
};

export default CmsRelatedBadgeItem;

const Wrapper = styled.div`
    display: inline-flex;
    flex-direction: row;
    align-items: center;
    padding: var(--sp-px) var(--sp-4);
    background: var(--white-color);

    border: var(--sp-px) solid var(--border-color);
    box-sizing: border-box;
    border-radius: var(--sp-6);

    .badge-icon {
        margin-right: var(--sp-1_5);
        width: var(--sp-4);
        height: var(--sp-4);
        border-radius: 50%;
        background: var(--eui-darkshade-color);

        &.active {
            background: var(--eui-vis0-color);
        }
    }

    .badge-label {
        margin-right: var(--sp-2_5);
    }
`;
