import { GLOBAL_SEARCH_ITEMS } from "./actionTypes";

const setGlobalSearchItems = (state, payload) => {
	return {
		...state,
		globalSearchItems: payload,
	};
};

const initialState = {
	globalSearchItems: null,
};

const Reducer = (state = initialState, action) => {
	switch (action.type) {
		case GLOBAL_SEARCH_ITEMS:
			return setGlobalSearchItems(state, action.payload);

		default: {
			return state;
		}
	}
};

export default Reducer;
