import React from "react";
import { EuiFlexGroup, EuiFlexItem, EuiCheckbox } from "@elastic/eui";
import { htmlIdGenerator } from "@elastic/eui/lib/services";
import styled from "styled-components";

import variables from "assets/css/variables.scss";

const CheckBoxWithDefaultCheckIcon = (props) => {
	const { label, onChange, checked, disabled } = props;

	const handleChange = (e) => {
		onChange(e.target.checked);
	};

	return (
		<Wrapper>
			<EuiFlexGroup gutterSize="s" direction="row">
				<EuiFlexItem grow={false}>
					<EuiCheckbox id={htmlIdGenerator()()} label="" checked={checked} onChange={handleChange} className="d-flex check-option" disabled={disabled} />
				</EuiFlexItem>
				<EuiFlexItem className={disabled ? "disabled" : ""} grow={false}>
					{label}
				</EuiFlexItem>
			</EuiFlexGroup>
		</Wrapper>
	);
};

export default CheckBoxWithDefaultCheckIcon;

const Wrapper = styled.div`
	display: flex;
	position: relative;
	width: 100%;

	.disabled {
		color: ${variables.lightColor};
	}
`;
