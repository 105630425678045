import React, { useState, useMemo } from "react";
import styled from "styled-components";
import { EuiFlexGroup, EuiFlexItem, EuiPopover, EuiButtonIcon } from "@elastic/eui";

import {
	global_email_details,
	global_phone_details,
	global_url_details,
} from "core/model";

import {
	emailIcon,
	phoneIcon,
	pBadgeIcon,
	wBadgeIcon,
	bluePlusIcon,
	linkTypeLinkedInIcon,
} from "components/Common/Icons/Icons";
import { EditableEmail, EditablePhone, EditableLink } from "components/Common";
import EditPhoneItem from "components/Common/Items/Phone/EditPhoneItem";
import EditEmailItem from "components/Common/Items/Email/EditEmailItem";
import EditLinkItem from "components/Common/Items/Link/EditLinkItem";

/**
 * Component for displaying information of AMS Personal Contact
 */
const AmsPersonContactItem = (props) => {
	const {
		type,
		emails = [],
		phones = [],
		linkes = [],
		onSaveEmail,
		onDeleteEmail,
		onSavePhone,
		onDeletePhone,
		onSaveLink,
		onDeleteLink,
	} = props;

	const [isOpenPhonePopover, setIsOpenPhonePopover] = useState(false);
	const [isOpenEmailPopover, setIsOpenEmailPopover] = useState(false);
	const [isOpenLinkPopover, setIsOpenLinkPopover] = useState(false);
	const [isPhoneAddItem, setIsPhoneAddItem] = useState(false);
	const [isEmailAddItem, setIsEmailAddItem] = useState(false);
	const [isLinkAddItem, setIsLinkAddItem] = useState(false);

	const [isEmailButtonOver, setIsEmailButtonOver] = useState(false);
	const [isLinkButtonOver, setIsLinkButtonOver] = useState(false);
	const [isPhoneButtonOver, setIsPhoneButtonOver] = useState(false);

	const newPhone = {
		phone_type_c: type == "contact" ? 1 : 3,
		[global_phone_details._name]: {
			[global_phone_details.phone_number]: undefined,
			[global_phone_details.phone_country_code]: undefined,
			[global_phone_details.phone_extension]: undefined,
			[global_phone_details.phone_status_c]: null,
		},
	};

	const newEmail = {
		email_type_c: type == "contact" ? 1 : 2,
		[global_email_details._name]: {
			[global_email_details.email_value]: undefined,
			[global_email_details.email_status_date]: undefined,
			[global_email_details.email_status_c]: null,
		},
	};

	const newLink = {
		global_link_type_c: 2,
		[global_url_details._name]: {
			[global_url_details.url_value]: undefined,
			[global_url_details.url_confirmed_c]: undefined,
		},
	};

	const handleClickPhone = () => {
		setIsOpenPhonePopover((IsOpenPhonePopover) => !IsOpenPhonePopover);
	};

	const handleClickEmail = () => {
		setIsOpenEmailPopover((isOpenEmailPopover) => !isOpenEmailPopover);
	};

	const handleClickLink = () => {
		setIsOpenLinkPopover((isOpenLinkedPopover) => !isOpenLinkedPopover);
	};

	const handleCancelNewPhone = () => {
		setIsPhoneAddItem(false);
	};

	const handleCancelNewEmail = () => {
		setIsEmailAddItem(false);
	};

	const handleCancelNewLink = () => {
		setIsLinkAddItem(false);
	};

	const handleSaveEmail = (data, primary) => {
		onSaveEmail(data, (res, successCallBack) => {
			if (successCallBack) {
				successCallBack();
			}
		});
		handleClickEmail();
	};

	const handleDeleteEmail = (emailId) => {
		onDeleteEmail(emailId);
	};

	const handleSavePhone = (data, primary) => {
		onSavePhone(data, (res, successCallBack) => {
			if (successCallBack) {
				successCallBack();
			}
		});
	};

	const handleDeletePhone = (phoneId) => {
		onDeletePhone(phoneId);
	};

	const handleSaveLink = (data, primary) => {
		onSaveLink(data, (res, successCallBack) => {
			if (successCallBack) {
				successCallBack();
			}
		});
	};

	const handleDeleteLink = (linkId) => {
		onDeleteLink(linkId);
	};

	const { emailItems, isEmailEmpty } = useMemo(() => {
		let isEmailEmpty = false;
		if (emails.length == 0) {
			isEmailEmpty = true;
		} else {
			isEmailEmpty = false;
		}
		const emailItems = emails?.map((item, idx) => {
			return (
				<EditableEmail
					key={`email-existing-item-${idx}`}
					email={item}
					isPrimary={false}
					onSave={handleSaveEmail}
					onDelete={handleDeleteEmail}
				/>
			);
		});

		return { emailItems, isEmailEmpty };
	}, [emails]);

	const { phoneItems, isPhoneEmpty } = useMemo(() => {
		let isPhoneEmpty = false;
		if (phones.length == 0) {
			isPhoneEmpty = true;
		} else {
			isPhoneEmpty = false;
		}
		const phoneItems = phones?.map((item, idx) => {
			return (
				<EditablePhone
					key={`phone-existing-item-${idx}`}
					phone={item}
					isPrimary={false}
					onSave={handleSavePhone}
					onDelete={handleDeletePhone}
					isFixedType={false}
					fixedType={0}
				/>
			);
		});

		return { phoneItems, isPhoneEmpty };
	}, [phones]);

	const { linkItems, isLinkEmpty } = useMemo(() => {
		let isLinkEmpty = false;
		if (linkes.length == 0) {
			isLinkEmpty = true;
		} else {
			isLinkEmpty = false;
		}
		const linkItems = linkes?.map((item, idx) => {
			return (
				<EditableLink
					key={`link-existing-item-${idx}`}
					link={item}
					isPrimary={false}
					onSave={handleSaveLink}
					onDelete={handleDeleteLink}
				/>
			);
		});

		return { linkItems, isLinkEmpty };
	}, [linkes]);

	const renderPhoneButton = () => {
		return (
			<div
				onMouseEnter={() => {
					setIsPhoneButtonOver(true);
				}}
				onMouseLeave={() => {
					setIsPhoneButtonOver(false);
				}}
				onClick={() => {
					setIsPhoneButtonOver(false);
					handleClickPhone();
				}}
			>
				{isPhoneEmpty && !isPhoneButtonOver && (
					<EuiFlexGroup gutterSize="s" alignItems="center" style={opacityStyle}>
						<EuiFlexItem grow={false}>{phoneIcon()}</EuiFlexItem>
					</EuiFlexGroup>
				)}
				{isPhoneEmpty && isPhoneButtonOver && (
					<EuiFlexGroup gutterSize="s" alignItems="center">
						<EuiFlexItem grow={false}>{bluePlusIcon()}</EuiFlexItem>
					</EuiFlexGroup>
				)}
				{!isPhoneEmpty && (
					<EuiFlexGroup gutterSize="s" alignItems="center">
						<EuiFlexItem grow={false}>{phoneIcon()}</EuiFlexItem>
					</EuiFlexGroup>
				)}
			</div>
		);
	};

	const renderEmailButton = () => {
		return (
			<div
				onMouseEnter={() => {
					setIsEmailButtonOver(true);
				}}
				onMouseLeave={() => {
					setIsEmailButtonOver(false);
				}}
				onClick={() => {
					setIsEmailButtonOver(false);
					handleClickEmail();
				}}
			>
				{isEmailEmpty && !isEmailButtonOver && (
					<EuiFlexGroup gutterSize="s" alignItems="center" style={opacityStyle}>
						<EuiFlexItem grow={false}>{emailIcon()}</EuiFlexItem>
					</EuiFlexGroup>
				)}
				{isEmailEmpty && isEmailButtonOver && (
					<EuiFlexGroup gutterSize="s" alignItems="center">
						<EuiFlexItem grow={false}>{bluePlusIcon()}</EuiFlexItem>
					</EuiFlexGroup>
				)}
				{!isEmailEmpty && (
					<EuiFlexGroup gutterSize="s" alignItems="center">
						<EuiFlexItem grow={false}>{emailIcon()}</EuiFlexItem>
					</EuiFlexGroup>
				)}
			</div>
		);
	};

	const renderLinkButton = () => {
		return (
			<div
				onMouseEnter={() => {
					setIsLinkButtonOver(true);
				}}
				onMouseLeave={() => {
					setIsLinkButtonOver(false);
				}}
				onClick={() => {
					setIsLinkButtonOver(false);
					handleClickLink();
				}}
			>
				{isLinkEmpty && !isLinkButtonOver && (
					<EuiFlexGroup gutterSize="s" alignItems="center" style={opacityStyle}>
						<EuiFlexItem grow={false}>
							{linkTypeLinkedInIcon("black")}
						</EuiFlexItem>
					</EuiFlexGroup>
				)}
				{isLinkEmpty && isLinkButtonOver && (
					<EuiFlexGroup gutterSize="s" alignItems="center">
						<EuiFlexItem grow={false}>{bluePlusIcon()}</EuiFlexItem>
					</EuiFlexGroup>
				)}
				{!isLinkEmpty && (
					<EuiFlexGroup gutterSize="s" alignItems="center">
						<EuiFlexItem grow={false}>
							{linkTypeLinkedInIcon("black")}
						</EuiFlexItem>
					</EuiFlexGroup>
				)}
			</div>
		);
	};

	return (
		<Wrapper>
			{type == "contact" && <div className="pBadgeDiv">{pBadgeIcon()}</div>}
			{type == "work" && <div className="wBadgeDiv">{wBadgeIcon()}</div>}
			<EuiPopover
				isOpen={isOpenPhonePopover}
				button={renderPhoneButton()}
				closePopover={handleClickPhone}
				anchorPosition="downCenter"
				hasArrow={true}
				panelStyle={{
					width: 300,
					padding: "0.5rem 0.75rem",
					marginTop: -10,
				}}
				className="firstPopOver"
			>
				<EuiFlexGroup direction="column" gutterSize="s">
					<EuiFlexItem>
						<EuiFlexGroup gutterSize="s" direction="column">
							{phoneItems?.map((item, idx) => {
								return (
									<EuiFlexItem key={`ams-person-phone-item-${idx}`}>
										{item}
									</EuiFlexItem>
								);
							}) || null}
						</EuiFlexGroup>
					</EuiFlexItem>
					{!isPhoneAddItem ? (
						<EuiFlexItem grow={false} style={flexItemPlusButton}>
							<AddButton
								iconType="plusInCircleFilled"
								onClick={setIsPhoneAddItem}
								color="primary"
								className="plus-button"
							/>
						</EuiFlexItem>
					) : (
						<EditPhoneItem
							phone={newPhone}
							phonePrimary={false}
							onSave={handleSavePhone}
							onCancel={handleCancelNewPhone}
							isFixedType={false}
							fixedType={0}
						/>
					)}
				</EuiFlexGroup>
			</EuiPopover>
			<EuiPopover
				isOpen={isOpenEmailPopover}
				button={renderEmailButton()}
				closePopover={handleClickEmail}
				anchorPosition="downCenter"
				hasArrow={true}
				panelStyle={{
					width: 280,
					padding: "0.5rem 0.75rem",
					marginTop: -10,
				}}
				className={type == "contact" ? "secondPopOver" : "secondLastPopOver"}
			>
				<EuiFlexGroup direction="column" gutterSize="s">
					<EuiFlexItem>
						<EuiFlexGroup gutterSize="s" direction="column">
							{emailItems?.map((item, idx) => {
								return (
									<EuiFlexItem key={`ams-person-email-item-${idx}`}>
										{item}
									</EuiFlexItem>
								);
							}) || null}
						</EuiFlexGroup>
					</EuiFlexItem>
					{!isEmailAddItem ? (
						<EuiFlexItem grow={false} style={flexItemPlusButton}>
							<AddButton
								iconType="plusInCircleFilled"
								onClick={setIsEmailAddItem}
								color="primary"
								className="plus-button"
							/>
						</EuiFlexItem>
					) : (
						<EditEmailItem
							email={newEmail}
							emailPrimary={false}
							onSave={handleSaveEmail}
							onCancel={handleCancelNewEmail}
						/>
					)}
				</EuiFlexGroup>
			</EuiPopover>
			{type == "contact" && (
				<EuiPopover
					isOpen={isOpenLinkPopover}
					button={renderLinkButton()}
					closePopover={handleClickLink}
					anchorPosition="downCenter"
					hasArrow={true}
					panelStyle={{
						width: 280,
						padding: "0.5rem 0.75rem",
						marginTop: -10,
					}}
					className="thirdPopOver"
				>
					<EuiFlexGroup direction="column" gutterSize="s">
						<EuiFlexItem>
							<EuiFlexGroup gutterSize="s" direction="column">
								{linkItems?.map((item, idx) => {
									return (
										<EuiFlexItem
											key={`ams-person-linked-item-${idx}`}
										>
											{item}
										</EuiFlexItem>
									);
								}) || null}
							</EuiFlexGroup>
						</EuiFlexItem>
						{!isLinkAddItem ? (
							<EuiFlexItem grow={false} style={flexItemPlusButton}>
								<AddButton
									iconType="plusInCircleFilled"
									onClick={setIsLinkAddItem}
									color="primary"
									className="plus-button"
								/>
							</EuiFlexItem>
						) : (
							<EditLinkItem
								link={newLink}
								linkPrimary={false}
								onSave={handleSaveLink}
								onCancel={handleCancelNewLink}
							/>
						)}
					</EuiFlexGroup>
				</EuiPopover>
			)}
		</Wrapper>
	);
};

export default AmsPersonContactItem;

const Wrapper = styled.div`
	display: flex;
	margin-left: -0.9em;
	.edit-button {
		.euiButtonContent.euiButtonEmpty__content {
			// padding: 0 !important;
		}
	}

	.pBadgeDiv {
		margin-top: 6px;
		margin-right: 4px;
	}

	.wBadgeDiv {
		margin-top: 6px;
		margin-right: 4px;
	}

	.firstPopOver {
		height: 27px;
		padding: 4px 4px;
		color: #d3dae6;
		border: 1.3px solid;
		border-right: none;
		border-top-left-radius: 0.3em;
		border-bottom-left-radius: 0.3em;
	}

	.secondPopOver {
		height: 27px;
		padding: 4px 4px;
		color: #d3dae6;
		border: 1.3px solid;
	}

	.thirdPopOver {
		height: 27px;
		padding: 4px 4px;
		color: #d3dae6;
		border: 1.3px solid;
		border-left: none;
		border-top-right-radius: 0.3em;
		border-bottom-right-radius: 0.3em;
	}

	.secondLastPopOver {
		height: 27px;
		padding: 4px 4px;
		color: #d3dae6;
		border: 1.3px solid;
		border-left-radius: none;
		border-top-right-radius: 0.3em;
		border-bottom-right-radius: 0.3em;
	}
`;

const opacityStyle = {
	opacity: "0.3",
};

const flexItemPlusButton = {
	width: "1.5em",
};

const AddButton = styled(EuiButtonIcon)`
	padding: 0 !important;
	min-width: 0 !important;
	min-height: 0 !important;
	line-height: 1 !important;
`;
