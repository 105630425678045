import {
	compensation_main,
	compensation_financial,
	compensation_commute,
	compensation_benefit,
	compensation_detail,
	compensation_note,
	compensation_location,
} from "core/model";

export const newCompensation = {
	[compensation_main._name]: {
		[compensation_main.compensation_name]: "",
		[compensation_main.compensation_rating_c]: 1,
		[compensation_financial._name]: {
			[compensation_financial.bill_type_c]: 1, // get_gcs(compensation_financial, bill_type_c, pay)
			[compensation_financial.financial_type_c]: "1", // get_gcs(compensation_financial, financial_type_c, permanent)
			[compensation_financial.financial_subtype_c]: 1, // get_gcs(compensation_financial, financial_subtype_c, W2)
			[compensation_financial.pay_type_c]: 1, // get_gcs(compensation_financial, pay_type_c, salary)
			[compensation_financial.pay_frequency_c]: 1, // get_gcs(compensation_financial, pay_frequency_c, hour)
			[compensation_financial.shift_type_c]: 1, // get_gcs(compensation_financial, shift_type_c, normal)
			[compensation_financial.rate_fixed]: 0,
			[compensation_financial.rate_low]: 0,
			[compensation_financial.rate_high]: 0,
			[compensation_financial.rate_overtime]: 0,
			[compensation_financial.rate_doubletime]: 0,
			[compensation_location._name]: {
				[compensation_location.location_type_gc]: 1, // get_gcs(global, location_type_c, country)
				[compensation_location.location_ref_id]: null,
			},
			[compensation_benefit._name]: {
				[compensation_benefit.benefit_type_c]: 0, // get_gcs(compensation_benefit, benefit_type_c, other)
				[compensation_benefit.benefit_details]: null,
			},
			[compensation_detail._name]: {
				[compensation_detail.detail_type_c]: 1,
				[compensation_detail.detail_value]: "",
			},
		},
		[compensation_commute._name]: {
			[compensation_commute.rating_miles]: 0,
			[compensation_commute.rating_minutes]: 0,
			[compensation_commute.travel_required]: 0,
			[compensation_commute.travel_percentage]: 0,
			[compensation_commute.travel_occurance_type_c]: 1, // get_gcs(compensation_commute, travel_occurance_type_c, day)
			[compensation_commute.travel_occurance_value]: 0,
			[compensation_commute.onsite_occurance_type_c]: 1, // get_gcs(compensation_commute, onsite_occurance_type_c, day)
			[compensation_commute.onsite_occurance_value]: 0,
		},
		[compensation_benefit._name]: {
			[compensation_benefit.benefit_type_c]: 0, // get_gcs(compensation_benefit, benefit_type_c, other)
			[compensation_benefit.benefit_details]: null,
		},
		[compensation_note._name]: {
			[compensation_note.object_table_sc]: 11, // get_gss(OBJECT_JOB, COMPENSATION)
			[compensation_note.record_ref_id]: null,
			[compensation_note.note_details]: "",
		},
	},
};
