import React, { useState } from "react";
import styled from "styled-components";
import { EuiCard } from "@elastic/eui";

/**
 * Main Components
 */
const SelectableCard = (props) => {
	const { children, onClickCard, selected, className, ...restProps } = props;

	const [internalSelected, setInternalSelected] = useState(false);

	const handleClickCard = () => {
		if (onClickCard) {
			onClickCard();
		}
		setInternalSelected(!internalSelected);
	};

	const cardSelected =
		selected !== undefined && selected !== null ? selected : internalSelected;

	return (
		<Wrapper
			onClick={handleClickCard}
			className={`${className} ${cardSelected && "selected"}`}
			{...restProps}
		>
			{children}
		</Wrapper>
	);
};

export default SelectableCard;

/**
 * Styled Components
 */
const Wrapper = styled(EuiCard)`
	border-radius: var(--sp-2);

	.euiCard__title,
	.euiCard__description {
		display: none;
	}

	&.selected {
		border-color: var(--eui-link-color);
		filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
	}
`;
