/**
 * Here the name convention is the same with the name convention of the table in database.
 * So here the same name will be used.
 */
const template_main = {
	_name: "template_main",
	id: "id",
	template_name: "template_name",
	template_type_c: "template_type_c",
	template_status_c: "template_status_c",
	template_state_sc: "template_state_sc",
};

const template_details = {
	_name: "template_details",
	id: "id",
	template_delivery_type_c: "template_delivery_type_c",
	template_subject: "template_subject",
	template_body: "template_body",
	template_settings: "template_settings",
	template_main_ref_id: "template_main_ref_id",
	template_note: "template_note",
};

const template_permission = {
	_name: "template_permission",
	id: "id",
	security_group_ref_id: "security_group_ref_id",
	template_main_ref_id: "template_main_ref_id",
};

const template_attachment = {
	_name: "template_attachment",
	id: "id",
	template_main_ref_id: "template_main_ref_id",
	attachment_type_sc: "attachment_type_sc",
	attachment_ref_id: "attachment_ref_id",
	attachment_details: "attachment_details",
};

const template_association = {
	_name: "template_association",
	id: "id",
	template_object_table_sc: "template_object_table_sc",
	template_object_ref_id: "template_object_ref_id",
	ref_object_base_sc: "ref_object_base_sc",
	ref_object_table_sc: "ref_object_table_sc",
	ref_object_record_id: "ref_object_record_id",
};

const template_log_activity = {
	_name: "template_log_activity",
	id: "id",
	date_created: "date_created",
	account_main_ref_id: "account_main_ref_id",
	template_table_ref_id: "template_table_ref_id",
	log_object_c: "log_object_c",
	log_type_c: "log_type_c",
	log_action_c: "log_action_c",
	template_log_detail_ref_id: "template_log_detail_ref_id",
};

const template_log_detail = {
	_name: "template_log_detail",
	id: "id",
	detail_text: "detail_text",
};

export {
	template_main,
	template_details,
	template_permission,
	template_attachment,
	template_association,
	template_log_activity,
	template_log_detail,
};
