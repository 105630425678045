import React, { useMemo } from "react";
import styled from "styled-components";
import {
	EuiFlexGroup,
	EuiFlexItem,
	EuiSwitch,
	EuiButton,
	EuiButtonGroup,
	EuiAvatar,
} from "@elastic/eui";
import { get } from "lodash";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMinus, faStickyNote } from "@fortawesome/pro-light-svg-icons";

import { EditableGeneralName, RASItem } from "components/Common";
import * as MODEL from "core/model";

import { AmsNoteList } from "../AmsNoteList";
import { AMS_NOTE_TYPE_LIST } from "../../constants";
import AmsStatusItem from "../../AmsStatusItem";

/**
 * Component for AMS Note Item with editable feature
 */
const AmsEditNoteItem = (props) => {
	const {
		data,
		accounts,
		person,
		account,
		restriction,
		owners,
		assignees,
		onChangeEdit,
		onChangeViewType,
		onChangeAmsType,
		onChangeAmsStatus,
		onChangeAmsName,
		onUpdateNote,
		onDeleteNote,
		onSaveRAS,
	} = props;

	const { personName, personPhotoUrl } = useMemo(() => {
		let personName = "";
		let personPhotoUrl = "";
		if (person) {
			const { person_external_link, name_first, name_last } = person;

			// Get photo url from person_external_link, global_link_type_c = 3 for linkedin avartar
			personPhotoUrl =
				person_external_link?.filter(
					(link) => link[MODEL.person_external_link.global_link_type_c] === 3
				)?.[0]?.global_url_details?.url_value ?? "";

			personName = `${name_first} ${name_last}`;
		}

		return {
			personName,
			personPhotoUrl,
		};
	}, [person]);

	const { amsStatus, selectedType, name, notes } = useMemo(() => {
		// Get AMS Status
		const amsStatus = get(data, `${MODEL.ams_activity_main.activity_status_c}`, 1);

		// Get AMS type
		const activityTypeC = get(
			data,
			MODEL.ams_activity_main.activity_type_c,
			AMS_NOTE_TYPE_LIST.generic.value
		);
		const activityType =
			Object.values(AMS_NOTE_TYPE_LIST).find(
				(item) => item.value === activityTypeC
			) || AMS_NOTE_TYPE_LIST.generic;
		const selectedType = activityType.value.toString();

		// Get AMS name
		const name = get(data, MODEL.ams_activity_main.activity_name, "") || "";

		// Get account information
		let accountName = "";
		let accountPhotoUrl = "";
		if (account) {
			const { account_external_link, name_first, name_last } = account;
			accountPhotoUrl =
				account_external_link?.[0]?.global_url_details?.url_value ?? "";
			accountName = `${name_first} ${name_last}`;
		}

		// Get AMS Notes
		const activityNotes = get(data, MODEL.ams_activity_note._name, []) || [];
		const notes =
			activityNotes?.map((item, index) => {
				return {
					...item,
					index,
					name: accountName,
				};
			}) || [];

		return {
			selectedType,
			name,
			notes,
			amsStatus,
		};
	}, [data, account]);

	const toggleButtonsForAmsTypes = useMemo(() => {
		return [
			{
				id: AMS_NOTE_TYPE_LIST.logistics.value.toString(),
				label: <FontAwesomeIcon icon={AMS_NOTE_TYPE_LIST.logistics.icon} />,
			},
			{
				id: AMS_NOTE_TYPE_LIST.generic.value.toString(),
				label: <FontAwesomeIcon icon={AMS_NOTE_TYPE_LIST.generic.icon} />,
			},
			{
				id: AMS_NOTE_TYPE_LIST.meeting.value.toString(),
				label: <FontAwesomeIcon icon={AMS_NOTE_TYPE_LIST.meeting.icon} />,
			},
		];
	}, []);

	const handleClickEdit = () => {
		onChangeEdit();
	};

	const handleChangeAmsType = (optionId) => {
		onChangeAmsType(optionId);
	};

	const handleSaveName = (newName) => {
		onChangeAmsName(newName);
	};

	const handleSaveNote = (note) => {
		onUpdateNote(note);
	};

	const handleDeleteNote = (note) => {
		onDeleteNote(note);
	};

	const handleChangeStatus = (newStatus) => {
		onChangeAmsStatus(newStatus);
	};

	return (
		<Wrapper>
			<EuiFlexGroup gutterSize="m" direction="column">
				<EuiFlexItem>
					<div className="ams-edit-item-header__container">
						<EuiFlexGroup gutterSize="l" alignItems="center">
							<EuiFlexItem grow={false}>
								<EuiFlexGroup
									direction="column"
									gutterSize="s"
									justifyContent="center"
								>
									<EuiFlexItem>
										<EuiFlexGroup gutterSize="s">
											<EuiFlexItem>
												<FontAwesomeIcon
													icon={faStickyNote}
													size="lg"
												/>
											</EuiFlexItem>
											<EuiFlexItem>
												<EuiSwitch
													label="Simple Note"
													checked={false}
													onChange={onChangeViewType}
													className="simple-note-switch"
													compressed
												/>
											</EuiFlexItem>
										</EuiFlexGroup>
									</EuiFlexItem>
									<EuiFlexItem>
										<AmsStatusItem
											status={amsStatus}
											onChangeStatus={handleChangeStatus}
											showLabel={true}
										/>
									</EuiFlexItem>
								</EuiFlexGroup>
							</EuiFlexItem>
							<EuiFlexItem>
								<EuiFlexGroup
									gutterSize="s"
									alignItems="center"
									justifyContent="center"
								>
									<EuiFlexItem grow={false}>
										<EuiAvatar
											size="l"
											name={personName}
											imageUrl={personPhotoUrl || ""}
										/>
									</EuiFlexItem>
									<EuiFlexItem grow={false}>
										<EuiButtonGroup
											legend="This is list of AMS types"
											name="primary"
											options={toggleButtonsForAmsTypes}
											idSelected={selectedType}
											onChange={handleChangeAmsType}
											color="primary"
											className="ams-type-list-buttons__container"
										/>
									</EuiFlexItem>
								</EuiFlexGroup>
							</EuiFlexItem>
							<EuiFlexItem grow={false}>
								<RASItem
									restriction={restriction}
									owners={owners}
									assignees={assignees}
									accounts={accounts}
									onSave={onSaveRAS}
								/>
							</EuiFlexItem>
							<EuiFlexItem grow={false}>
								<EuiButton
									color="text"
									className="button-fit-content close-button"
									onClick={handleClickEdit}
								>
									<FontAwesomeIcon icon={faMinus} size="1x" />
								</EuiButton>
							</EuiFlexItem>
						</EuiFlexGroup>
					</div>
				</EuiFlexItem>
				<EuiFlexItem>
					<div className="option-subject__container">
						<div className="option-subject__wrapper">
							<EditableGeneralName name={name} onSave={handleSaveName} />
						</div>
					</div>
				</EuiFlexItem>
				<EuiFlexItem>
					<div className="ams-note-list__wrapper">
						<AmsNoteList
							data={notes}
							onSaveNote={handleSaveNote}
							onDeleteNote={handleDeleteNote}
						/>
					</div>
				</EuiFlexItem>
				{/* <EuiFlexItem>
					<AmsRelatedRecordList data={[]} />
				</EuiFlexItem> */}
			</EuiFlexGroup>
		</Wrapper>
	);
};

export default AmsEditNoteItem;

/**
 * Styled Components
 */
const Wrapper = styled.div`
	width: 100%;
	padding: var(--sp-3) var(--sp-4_5) var(--sp-4) var(--sp-4_5);
	border-bottom: solid var(--sp-px) var(--border-color);

	.ams-edit-item-header__container {
		width: 100%;
		background: var(--eui-lightest-shade-color);
		padding: var(--sp-3_5) var(--sp-8);

		.simple-note-switch {
			span {
				white-space: nowrap;
			}
		}

		.ams-type-list-buttons__container {
			.euiButton {
				min-width: var(--sp-17);
				min-height: var(--sp-16);

				&.euiButton--primary {
					color: var(--eui-text-color);

					&.euiButton--fill {
						color: var(--white-color);
					}
				}

				.euiButtonContent {
					padding: var(--sp-4);
				}
			}
		}
	}

	.close-button {
		background: var(--eui-lightest-shade-color);
		border-color: var(--transparent);

		min-width: 0 !important;
		height: auto !important;
		line-height: 1 !important;

		.euiButtonContent.euiButton__content {
			padding: var(--sp-2) !important;
		}

		.euiButton__text {
			font-size: var(--sp-6);
		}

		svg {
			width: var(--sp-6);
			height: var(--sp-6);
		}
	}

	.option-subject__container {
		display: flex;
		width: 100%;
		justify-content: center;

		.option-subject__wrapper {
			display: flex;
			width: fit-content;

			h3 {
				font-size: var(--sp-8) !important;
				line-height: var(--sp-12) !important;
			}
		}
	}

	.ams-note-list__wrapper {
		padding: 0 var(--sp-9);
	}
`;
