import React, { useMemo } from "react";
import styled from "styled-components";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
	faThumbsUp,
	faThumbsDown,
	faPlus,
	faMinus,
	faHeadSideBrain,
} from "@fortawesome/pro-solid-svg-icons";
import { faClock } from "@fortawesome/pro-regular-svg-icons";

import variables from "assets/css/variables.scss";

/**
 * Constants
 */
const RATING_TYPES = {
	account: { value: 1, color: variables.primaryColor },
	person: { value: 2, color: variables.secondaryColor },
	company: { value: 3, color: variables.empowerCore },
};

const RATING_STATES = {
	pending: { value: 1, icon: faClock },
	approved: { value: 2, icon: faThumbsUp },
	declined: { value: 3, icon: faThumbsDown },
	considering: { value: 4, icon: faHeadSideBrain },
	backup: { value: 5, icon: faThumbsDown },
	backBurner: { value: 6, icon: faThumbsDown },
};

const RATING_VALUES = {
	5: { value: 5, icon: faPlus, count: 2, color: variables.secondaryColor },
	4: { value: 4, icon: faPlus, count: 1, color: variables.secondaryColor },
	3: { value: 3, icon: null, count: 0, color: variables.primaryColor },
	2: { value: 2, icon: faMinus, count: 1, color: variables.dangerColor },
	1: { value: 1, icon: faMinus, count: 2, color: variables.dangerColor },
};

/**
 * Application Rating Item
 */
const ApplicationRatingItem = (props) => {
	const { rating } = props;

	const { ratingTypeValue, ratingStateValue, ratingValue } = useMemo(() => {
		const { rating_type_c, rating_state_c, rating_value } = rating;

		return {
			ratingTypeValue: rating_type_c || 1,
			ratingStateValue: rating_state_c || 1,
			ratingValue: rating_value || 3,
		};
	}, [rating]);

	const renderRatingIcon = () => {
		const ratingType = Object.values(RATING_TYPES).find(
			(type) => type.value === ratingTypeValue
		);
		const ratingColor = ratingType ? ratingType.color : variables.primaryColor;
		const ratingState = Object.values(RATING_STATES).find(
			(state) => state.value === ratingStateValue
		);
		const ratingIcon = ratingState ? ratingState.icon : null;

		return (
			<RatingIconWrapper color={ratingColor}>
				<FontAwesomeIcon icon={ratingIcon} color={ratingColor} />
			</RatingIconWrapper>
		);
	};

	const renderRatingState = () => {
		const rating = Object.values(RATING_VALUES).find(
			(rate) => rate.value === ratingValue
		);
		if (rating) {
			if (rating.count) {
				const icons = [];
				for (let index = 0; index < rating.count; index++) {
					icons.push(
						<FontAwesomeIcon
							icon={rating.icon}
							color={rating.color}
							key={`job-application-rating-icon-${index}`}
						/>
					);
				}
				return <RatingStateWrapper>{icons}</RatingStateWrapper>;
			}
		}

		return null;
	};

	return (
		<Wrapper>
			{renderRatingIcon()}
			{renderRatingState()}
		</Wrapper>
	);
};

export default ApplicationRatingItem;

const Wrapper = styled.div`
	display: flex;
	box-sizing: border-box;
	padding: 0.333rem 0.5rem 0.333rem 0.75rem;
	border: 1px solid ${variables.borderColor};
	border-radius: 1rem;
	align-items: center;
	width: fit-content;
`;

const RatingIconWrapper = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;
	margin-right: 0.333rem;

	width: 1rem;
	height: 1rem;
	color: ${({ color }) => color};

	svg {
		width: 1rem;
		height: 1rem;
	}
`;

const RatingStateWrapper = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;

	svg {
		margin-left: 2px;
		width: 1rem;
		height: 1rem;
	}
`;
