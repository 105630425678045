import React from "react";
import styled from "styled-components";
import {
    EuiText,
    EuiTable,
    EuiTableHeader,
    EuiTableHeaderCell,
    EuiTableBody,
    EuiTableRow,
    EuiTableRowCell,
} from "@elastic/eui";

/**
 * Main Components
 */
const CustomTable = (props) => {

    const {
        items,
        columns,
        handleRowClicked,
        handleRowHover,
        ...restProps
    } = props;

    const handleClickRow = (idx, item) => {
        handleRowClicked(idx, item);
    }

    const handleMouseEnter = (idx) => {
        handleRowHover(idx, true);
    }

    const handleMouseLeave = (idx) => {
        handleRowHover(idx, false);
    }

    const renderRows = () => {
        const rows = [];

        items && items.length > 0 && items.map((item, itemIndex) => {
            rows.push(renderRow(item, itemIndex));
        });

        return rows;
    };

    const renderRow = (item, itemIndex) => {
        return (
            <EuiTableRow
                key={`row-${itemIndex}`}
                isSelectable={true}
                hasActions={true}
                onClick={() => handleClickRow(itemIndex, item)}
                onMouseEnter={() => handleMouseEnter(itemIndex)}
                onMouseLeave={() => handleMouseLeave(itemIndex)}
            >
                {renderRowCells(item, itemIndex)}
            </EuiTableRow>
        );
    };

    const renderRowCells = (item, rowIndex) => {
        return columns && columns.length > 0 && columns.map((column, index) => {

            let cellContent = null;

            if (column.render) {
                cellContent = column.render(item[column.field], rowIndex, item['editable']);
            }
            return (
                <EuiTableRowCell
                    key={`row-cell-${rowIndex}-${index}`}
                >
                    {cellContent}
                </EuiTableRowCell>
            );
        });
    };

    if (!items || items.length === 0) return <></>;

    return (
        <Wrapper {...restProps}>
            <EuiTable>
                <EuiTableHeader>
                    {columns && columns.length > 0 && columns.map((column, index) => (
                        <EuiTableHeaderCell
                            key={index}
                            width={column.width}
                        >
                            {column.name}
                        </EuiTableHeaderCell>
                    ))}
                </EuiTableHeader>
                <EuiTableBody>
                    {renderRows()}
                </EuiTableBody>
            </EuiTable>
        </Wrapper>
    );
};

export default CustomTable;

/**
 * Styled Components
 */
const Wrapper = styled.div`
    .euiTableCellContent__text {
        display: flex;
    }
`;
