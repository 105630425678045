import { useDispatch } from "react-redux";
import { get } from "lodash";

import {
    getAccountDetail,
    getAmsListByAccount,
    updateAccount_NEW,
} from "helpers/actions";
import { _getAccountDetail, _getAccounts, _getAccountAmsList } from "helpers/selectors";
import * as MODEL from "core/model";

/**
 * Hooks for Account
 */
const useAccount = () => {
    const dispatch = useDispatch();
    const account = _getAccountDetail();
    const accounts = _getAccounts();
    const accountAmsList = _getAccountAmsList();

    const getAccountName = (inAccount) => {
        const accountDetail = inAccount || account;
        const nameFirst = get(accountDetail, `${MODEL.account_main.name_first}`) || "";
        const nameLast = get(accountDetail, `${MODEL.account_main.name_last}`) || "";
        const accountName = `${nameFirst} ${nameLast}`;

        return accountName;
    };

    const callGetAccountDetail = () => {
        dispatch(getAccountDetail());
    };

    const saveAccountSetting = (data, successCallBack, failCallBack) => {
        const query = {
            [MODEL.account_setting._name]: {
                [MODEL.account_setting.id]: data?.id,
                [MODEL.account_setting.account_main_ref_id]: data?.account_main_ref_id,
                [MODEL.account_setting.setting_type_c]: data?.setting_type_c,
                [MODEL.account_setting.setting_value]: data?.setting_value,
            },
        };

        return new Promise((resolve, reject) => {
            dispatch(
                updateAccount_NEW(
                    query,
                    (res) => {
                        if (successCallBack) successCallBack(res);
                        resolve(res);
                    },
                    (err) => {
                        if (failCallBack) failCallBack(err);
                        reject(err);
                    }
                )
            );
        });
    };

    const getAccountAmsList = (
        accountId = account?.id,
        successCallBack,
        failCallBack
    ) => {
        const params = { accountId };
        dispatch(getAmsListByAccount(params, successCallBack, failCallBack));
    };

    return {
        account,
        accounts,
        accountAmsList,

        getAccountName,

        getAccountDetail: callGetAccountDetail,
        saveAccountSetting,
        getAccountAmsList,
    };
};

export default useAccount;
