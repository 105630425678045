import React, { useRef, useEffect } from "react";
import styled from "styled-components";
import { Editor } from "@tinymce/tinymce-react";

import { TINYMCE_APIKEY, TINYMCE_INPUT_HEIGHT } from "components/global/constants";

/**
 * Main Components
 */
const TextInputEditor = (props) => {
	const { editorVal, autocompletOptions, changeEditVal, disabled, ...restProps } =
		props;

	const editorRef = useRef(null);
	const editorRefCurrent = editorRef?.current;

	const autoCompleteSetup = (editor) => {
		const onAction = function (autocompleteApi, rng, value) {
			editor.selection.setRng(rng);
			editor.insertContent(value);
			autocompleteApi.hide();
		};

		const getMatchedChars = function (pattern) {
			if (autocompletOptions?.length) {
				return autocompletOptions.filter(function (char) {
					return (
						char.value.toLowerCase().indexOf(pattern) !== -1 ||
						char.value.toUpperCase().indexOf(pattern) !== -1
					);
				});
			} else {
				return [];
			}
		};

		/* An autocompleter that allows you to insert special characters */
		editor.ui.registry.addAutocompleter("specialchars", {
			ch: "[",
			minChars: 0,
			columns: 1,
			highlightOn: ["char_name"],
			onAction: onAction,
			fetch: function (pattern) {
				return new window.tinymce.util.Promise(function (resolve) {
					var results = getMatchedChars(pattern).map(function (char) {
						return {
							type: "cardmenuitem",
							value: "[" + char.value + "]",
							items: [
								{
									type: "cardcontainer",
									direction: "vertical",
									items: [
										{
											type: "cardtext",
											text: char.value,
											name: "char_name",
										},
									],
								},
							],
						};
					});
					resolve(results);
				});
			},
		});
	};

	useEffect(() => {
		if (editorRefCurrent && autocompletOptions?.length > 0) {
			autoCompleteSetup(editorRefCurrent);
		}
	}, [editorRefCurrent, autocompletOptions]);

	return (
		<Wrapper {...restProps}>
			<Editor
				onInit={(evt, editor) => (editorRef.current = editor)}
				value={editorVal}
				apiKey={TINYMCE_APIKEY}
				init={{
					height: TINYMCE_INPUT_HEIGHT,
					menubar: false,
					statusbar: false,
					toolbar: false,
					setup: function (editor) {},
					content_style:
						`body {
                            font-family: Inter_Regular;
                            font-size:12px;
                            color: #343741;
                            margin: 0 4px;
                            overflow: hidden;
                        }` +
						`.mce-content-body p {
                            margin: 4px;
                        }`,
					entity_encoding: "raw",
				}}
				onEditorChange={(newValue, editor) => {
					const text = editor.getContent({ format: "text" });
					changeEditVal(text);
				}}
				disabled={disabled}
			/>
		</Wrapper>
	);
};

export default TextInputEditor;

/**
 * Styled Components
 */
const Wrapper = styled.div``;
