import React, { useMemo } from "react";
import styled from "styled-components";
import { get } from "lodash";

import CmsDraftEditItemHeader from "./CmsLoggedEditItemHeader";

import { MessageEditor } from "../../Flyouts/MessageFlyout/MessageEditor";
import { CMS_CONVERSATION_TYPE_LIST } from "../constants";

/**
 * Component for CMS Logged Item with editable feature
 */
const CmsLoggedEditItem = (props) => {
	const { data, person, account, accounts, onUpdateAms, onChangeEdit } = props;

	const { messageParam } = useMemo(() => {
		// Get CMS platform
		const cmsConversationTypeValue = data?.conversation_type_c;

		const personEmails = get(person, "person_email");
		const personPhones = get(person, "person_phone");

		let messageParam = null;
		switch (cmsConversationTypeValue) {
			case CMS_CONVERSATION_TYPE_LIST.email.value:
			case CMS_CONVERSATION_TYPE_LIST.video.value:
				messageParam = {
					type: "email",
					value: personEmails[0],
				};
				break;
			case CMS_CONVERSATION_TYPE_LIST.phone.value:
			case CMS_CONVERSATION_TYPE_LIST.sms.value:
				messageParam = {
					type: "phone",
					value: personPhones[0],
				};
				break;
			case CMS_CONVERSATION_TYPE_LIST.message.value:
				messageParam = {
					type: "linkedin",
				};
				break;
			default:
				break;
		}

		return { messageParam };
	}, [data, person]);

	const handleDeleteCms = (cmsRecord) => {
		console.log("cmsRecord: ", cmsRecord);
	};

	return (
		<Wrapper>
			<CmsDraftEditItemHeader
				data={data}
				onChangeEdit={onChangeEdit}
				onDeleteCms={handleDeleteCms}
			/>
			<div className="item-body__container">
				<MessageEditor
					hasHeader={false}
					person={person}
					params={messageParam}
					data={data}
					isListItem={true}
				/>
			</div>
		</Wrapper>
	);
};

export default CmsLoggedEditItem;

const Wrapper = styled.div`
	width: 100%;

	border-top: var(--sp-1) solid var(--link-color);
	border-bottom: var(--sp-1) solid var(--link-color);
	margin-bottom: var(--sp-1);
	padding: var(--sp-2_5) 0;
`;
