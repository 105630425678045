import React, { useState } from "react";
import styled from "styled-components";
import { EuiPopover, EuiFlexGroup, EuiFlexItem, EuiBadge, EuiIcon } from "@elastic/eui";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBellPlus } from "@fortawesome/pro-light-svg-icons";

import * as MODEL from "core/model";

import AmsReminderList from "./AmsReminderList";

/**
 * Component for AMS Reminder Item with editable feature
 */
const AmsReminderItem = (props) => {
	const { data, taskId, onUpdateReminder, onDeleteReminder, loadPersonAmsData } = props;

	const reminders = data || [];
	const countReminders = reminders.length || 0;

	const [isOpenPopover, setIsOpenPopover] = useState(false);

	const handleClosePopover = () => {
		setIsOpenPopover(false);
	};

	const handleChangeReminder = (newReminder) => {
		const reminder = {
			...newReminder,
			[MODEL.ams_activity_reminder.ams_activity_task_ref_id]: taskId,
		};
		onUpdateReminder(reminder);
	};

	const handleDeleteReminder = (reminder) => {
		onDeleteReminder(reminder);
	};

	return (
		<EuiPopover
			isOpen={isOpenPopover}
			button={
				<ReminderButton
					onClick={() => setIsOpenPopover(true)}
					className="cursor-pointer"
				>
					<EuiFlexGroup gutterSize="xs" alignItems="center">
						<EuiFlexItem grow={false}>
							{countReminders > 0 ? (
								<EuiIcon
									type="bell"
									color="var(--link-color)"
									className="reminder-icon"
								/>
							) : (
								<FontAwesomeIcon
									icon={faBellPlus}
									color="var(--link-color)"
									className="reminder-icon"
								/>
							)}
						</EuiFlexItem>
						{countReminders ? (
							<EuiFlexItem grow={false}>
								<EuiBadge
									color="default"
									className="badge-item text-notification"
								>
									{countReminders}
								</EuiBadge>
							</EuiFlexItem>
						) : null}
					</EuiFlexGroup>
				</ReminderButton>
			}
			closePopover={handleClosePopover}
			panelStyle={{
				width: 390,
				padding: "var(--sp-6) var(--sp-4_5)",
			}}
			style={{
				alignItems: "center",
			}}
		>
			<AmsReminderList
				data={reminders}
				onChangeReminder={handleChangeReminder}
				onDeleteReminder={handleDeleteReminder}
				loadPersonAmsData={loadPersonAmsData}
			/>
		</EuiPopover>
	);
};

export default AmsReminderItem;

const ReminderButton = styled.div`
	.badge-item {
		padding: 0 var(--sp-2);
	}

	.reminder-icon {
		width: var(--sp-7);
		height: auto;
	}
`;
