const compensation_main = {
	_name: "compensation_main",
	id: "id",
	compensation_name: "compensation_name",
	rating_c: "rating_c",
	placement_type_c: "placement_type_c",
};

const compensation_commute = {
	_name: "compensation_commute",
	id: "id",
	compensation_location_ref_id: "compensation_location_ref_id",
	rating_miles: "rating_miles",
	rating_minutes: "rating_minutes",
	travel_required: "travel_required",
	travel_percentage: "travel_percentage",
	travel_occurance_type_c: "travel_occurance_type_c",
	travel_occurance_value: "travel_occurance_value",
	onsite_occurance_type_c: "onsite_occurance_type_c",
	onsite_occurance_value: "onsite_occurance_value",
};

const compensation_financial = {
	_name: "compensation_financial",
	id: "id",
	compensation_main_ref_id: "compensation_main_ref_id",
	bill_type_c: "bill_type_c",
	financial_type_c: "financial_type_c",
	financial_subtype_c: "financial_subtype_c",
	pay_type_c: "pay_type_c",
	pay_frequency_c: "pay_frequency_c",
	shift_type_c: "shift_type_c",
	rate_fixed: "rate_fixed",
	rate_low: "rate_low",
	rate_high: "rate_high",
	rate_overtime: "rate_overtime",
	rate_doubletime: "rate_doubletime",
};

const compensation_detail = {
	_name: "compensation_detail",
	id: "id",
	compensation_main_ref_id: "compensation_main_ref_id",
	compensation_financial_ref_id: "compensation_financial_ref_id",
	detail_type_c: "detail_type_c",
	detail_value: "detail_value",
};

const compensation_location = {
	_name: "compensation_location",
	id: "id",
	compensation_main_ref_id: "compensation_main_ref_id",
	compensation_financial_ref_id: "compensation_financial_ref_id",
	location_type_gc: "location_type_gc",
	location_ref_id: "location_ref_id",
};

const compensation_log_activity = {
	_name: "compensation_log_activity",
	id: "id",
	date_created: "date_created",
	account_main_ref_id: "account_main_ref_id",
	log_object_c: "log_object_c",
	log_type_c: "log_type_c",
	log_action_c: "log_action_c",
	record_ref_id: "record_ref_id",
	log_details: "log_details",
};

const compensation_benefit = {
	_name: "compensation_benefit",
	id: "id",
	compensation_main_ref_id: "compensation_main_ref_id",
	compensation_financial_ref_id: "compensation_financial_ref_id",
	benefit_type_c: "benefit_type_c",
	benefit_details: "benefit_details",
};

const compensation_primary_setting = {
	_name: "compensation_primary_setting",
	id: "id",
	compensation_main_ref_id: "compensation_main_ref_id",
	primary_level_gc: "primary_level_gc",
	ref_object_base_sc: "ref_object_base_sc",
	ref_object_table_sc: "ref_object_table_sc",
	ref_object_record_id: "ref_object_record_id",
};

const compensation_note = {
	_name: "compensation_note",
	id: "id",
	object_table_sc: "object_table_sc",
	record_ref_id: "record_ref_id",
	note_details: "note_details",
};

export {
	compensation_main,
	compensation_commute,
	compensation_financial,
	compensation_detail,
	compensation_location,
	compensation_log_activity,
	compensation_benefit,
	compensation_primary_setting,
	compensation_note,
};
