import React, { useMemo } from "react";
import styled from "styled-components";
import { EuiButtonEmpty, EuiFlexGroup, EuiFlexItem } from "@elastic/eui";

import { plusIcon } from "components/Common/Icons/Icons";
import * as MODEL from "core/model";

import CmsFilter from "./CmsFilter";
import AmsPersonContactItem from "../Ams/AmsEditItem/AmsEditActivityItem/AmsPersonContactItem";
import { getEmailTypeInfo, getPhoneTypeInfo } from "components/global/utils";

/**
 * Main Components
 */
const CmsListItemHeader = (props) => {
	const {
		title,
		person,
		isPlusIcon = false,
		onAddNewItem,
		onSaveEmail,
		onDeleteEmail,
		onSavePhone,
		onDeletePhone,
		onSaveLink,
		onDeleteLink,
	} = props;

	const onHandleAdd = () => {
		if (onAddNewItem) {
			onAddNewItem();
		}
	};

	const {
		personId,
		phonesContact,
		phonesWork,
		emailsContact,
		emailsWork,
		linkesContact,
	} = useMemo(() => {
		let personName = "";
		let personPhotoUrl = "";
		let phonesContact = [];
		let phonesWork = [];
		let emailsContact = [];
		let emailsWork = [];
		let linkesContact = [];
		let personId = null;

		if (person) {
			const {
				person_external_link,
				name_first,
				name_last,
				person_email: emails = [],
				person_phone: phones = [],
				person_external_link: linkes = [],
				id,
			} = person;

			personId = id;
			// Get photo url from person_external_link, global_link_type_c = 3 for linkedin avartar
			personPhotoUrl =
				person_external_link?.filter(
					(link) => link[MODEL.person_external_link.global_link_type_c] === 3
				)?.[0]?.global_url_details?.url_value ?? "";
			personName = `${name_first} ${name_last}`;

			if (emails?.length > 0) {
				emails.forEach((email) => {
					const emailTypeInfo = getEmailTypeInfo(email.email_type_c);

					if (!emailTypeInfo?.range) {
						emailsContact.push(email);
					} else {
						emailsWork.push(email);
					}
				});
			}

			if (phones?.length > 0) {
				phones.forEach((phone) => {
					const phoneTypeInfo = getPhoneTypeInfo(phone.phone_type_c);

					if (!phoneTypeInfo?.range) {
						phonesContact.push(phone);
					} else {
						phonesWork.push(phone);
					}
				});
			}
			if (linkes?.length > 0) {
				linkes.forEach((link) => {
					const isLinkShowType =
						link.link_type_c === 2 || link.link_type_c === 6;
					if (isLinkShowType) linkesContact.push(link);
				});
			}
		}

		return {
			personId,
			personName,
			personPhotoUrl,
			phonesContact,
			phonesWork,
			emailsContact,
			emailsWork,
			linkesContact,
		};
	}, [person]);

	return (
		<Wrapper>
			<p className="title">{title}</p>
			<div className="icon-list__container">
				<EuiFlexGroup gutterSize="xs">
					<EuiFlexItem>
						<div className="icon-item_container">
							<AmsPersonContactItem
								type="contact"
								personId={personId}
								emails={emailsContact}
								phones={phonesContact}
								linkes={linkesContact}
								onSavePhone={onSavePhone}
								onDeletePhone={onDeletePhone}
								onSaveEmail={onSaveEmail}
								onDeleteEmail={onDeleteEmail}
								onSaveLink={onSaveLink}
								onDeleteLink={onDeleteLink}
							/>
						</div>
					</EuiFlexItem>
					<EuiFlexItem>
						<div className="icon-item_container">
							<AmsPersonContactItem
								type="work"
								personId={personId}
								emails={emailsWork}
								phones={phonesWork}
								onSavePhone={onSavePhone}
								onDeletePhone={onDeletePhone}
								onSaveEmail={onSaveEmail}
								onDeleteEmail={onDeleteEmail}
							/>
						</div>
					</EuiFlexItem>
				</EuiFlexGroup>
			</div>
			<CmsFilter />
			{isPlusIcon && (
				<EuiButtonEmpty onClick={() => onHandleAdd()} className="icon-btn">
					{plusIcon()}
				</EuiButtonEmpty>
			)}
		</Wrapper>
	);
};

export default CmsListItemHeader;

/**
 * Styled Components
 */
const Wrapper = styled.div`
	display: flex;
	justify-content: space-between;

	.title {
		font-weight: bold;
		margin-top: auto;
		margin-bottom: auto;
		margin-right: auto;
	}

	.icon-btn {
		height: 32px;
	}

	.icon-list__container {
		align-items: center;
		display: flex;
		margin-left: auto;
		margin-right: auto;
	}

	.icon-item_container {
		display: flex;
		align-items: center;
		justify-content: center;
		padding: var(--sp-1);
		background: var(--eui-lightest-shade-color);
		border-radius: var(--sp-2);
		margin-left: 1.5em;
	}
`;
