import React from "react";
import styled from "styled-components";
import { EuiCheckbox } from "@elastic/eui";

import { SVGIcon } from "components/Common";
import {
	GLOBAL_EMAIL_PHONE_RANGES,
	GLOBAL_EMAIL_TYPE_OPTIONS,
} from "components/global/constants";
/**
 * Main Components
 */
const StaticEmail = (props) => {
	const { email, primary = false, hasIcon = true, ...restProps } = props;

	const emailTypeInfo = GLOBAL_EMAIL_TYPE_OPTIONS.find(
		(item) => item.value === email?.email_type_c
	);
	const rangeInfo = GLOBAL_EMAIL_PHONE_RANGES.find(
		(item) => item.value === emailTypeInfo?.range
	);
	const icon = emailTypeInfo?.icon || GLOBAL_EMAIL_TYPE_OPTIONS[0].icon;

	return (
		<Wrapper {...restProps} className="mb-2">
			{hasIcon ? (
				<div className="icon--wrapper">
					{rangeInfo?.icon() || null}
					<SVGIcon icon={icon} className="mr-2 ml-2" />
				</div>
			) : null}
			<div className="d-flex">
				{email?.global_email_details?.email_value || ""}
				{primary && (
					<EuiCheckbox
						id={"static-email-value"}
						checked={true}
						onChange={() => {}}
						className="email-valid ml-2"
					/>
				)}
			</div>
		</Wrapper>
	);
};

export default StaticEmail;

/**
 * Styled Components
 */
const Wrapper = styled.div`
	display: flex;
	width: 100%;

	.email-valid {
		margin-top: auto;
		margin-bottom: auto;
		margin-left: auto;
	}

	.euiCheckbox .euiCheckbox__input + .euiCheckbox__square {
		border-radius: 50%;
	}
`;
