import { SET_OPEN_TABS } from "./actionTypes";

function setOpenTabs(state, data) {
    return {
        ...state,
        tabs: data,
    };
}

const initialState = {
    // openTabs: null,
    tabs: [],
};

const Reducer = (state = initialState, action) => {
    switch (action.type) {
        case SET_OPEN_TABS:
            return setOpenTabs(state, action.payload);

        default: {
            return state;
        }
    }
};

export default Reducer;
