import React, { useState, useMemo, useEffect } from "react";
import styled from "styled-components";
import { EuiText, EuiFlexGroup, EuiFlexItem, EuiLoadingSpinner } from "@elastic/eui";
import { cloneDeep, concat, get, includes, remove, set } from "lodash";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCommentsAlt, faStickyNote } from "@fortawesome/pro-duotone-svg-icons";

import * as MODEL from "core/model";
import { TextInputEditor, TextEditor } from "components/Common";
import { SVGIcon } from "components/Common";
import { addressBookDuoNoteIcon, amsRecruitIcon } from "components/Common/Icons/Icons";
import plivoService from "core/api/plivo.service";
import { VALIDATE_MESSAGE } from "./constants";

import {
	useMacro,
	useCms,
	useAccount,
	useGlobal,
	useNotification,
	useQualifier,
	useCredential,
} from "core/useHooks";

import {
	SaveButton,
	LoggedButton,
	OutboundButton,
	SettingButton,
	CallButton,
	SpokeButton,
	PhoneButton,
	EndButton,
	PersonPhoneBadgeItem,
	AddPersonPhoneItem,
} from "../components";

import { CALL_STATES } from "./constants";
import RelatedItem from "./RelatedItem";

import {
	PLIVO_CONNECTING_STATE_LIST,
	PLIVO_IDLE_STATE_LIST,
	PLIVO_CALLED_STATE_LIST,
} from "./constants";
import { MESSAGE_MANUAL_QUALIFIER_NAMES, PERSON_SELECT_TYPES } from "../constants";
import { getGCSValue, getGSSValue } from "components/global/utils";
import { MessageAms } from "../../MessageAms";

const CALL_OUTCOME_ITEMS = {
	spoke: { key: "spoke", value: "Spoke" },
	noAnswer: { key: "noAnswer", value: "No Answer" },
	leftVoiceEmail: { key: "leftVoiceEmail", value: "Left VoiceEmail" },
	receivedVoiceEmail: { key: "receivedVoiceEmail", value: "Received VoiceEmail" },
	missedCall: { key: "missedCall", value: "Missed Call" },
};

/**
 * Component for sending phone
 */
const MessageSenderPhone = (props) => {
	const {
		person,
		savePersonPhone,
		deletePerson,
		savePersonEmail,
		initPhone,
		data,
		getCmsDraftList,
		getCmsMainList,
	} = props;

	const { macroOptions } = useMacro();
	const { plivoInfo } = useCms();
	const { account, accounts } = useAccount();
	const { globals, gssList } = useGlobal();
	const { setNotificationMessage } = useNotification();
	const { callUpdateCmsCommunicaionDraft } = useCms();
	const { qualifiers, saveQualifiers } = useQualifier();
	const { credentialsByAccount } = useCredential();

	const [toPersons, setToPersons] = useState([]);

	const [relateds, setRelateds] = useState([]);
	const [subject, setSubject] = useState("");
	const [message, setMessage] = useState("");
	const [plainMessage, setPlainMessage] = useState("");
	const [callStatus, setCallStatus] = useState(CALL_STATES.send.value);
	const [isMuted, setIsMuted] = useState(false);
	const [isConnectingStatus, setConnectingStatus] = useState(false);
	const [isRecruiting, setRecruiting] = useState(true);
	const [commDirect, setCommDirect] = useState(true); // false: 0 - incoming, true: 1 - outgoing,
	const [callOutcome, setCallOutcome] = useState(CALL_OUTCOME_ITEMS.spoke);
	const [validationMessage, setValidationMessage] = useState("");
	const [credentialId, setCredentialId] = useState(null);

	const { isConnectingCall, isCalling, isIdle } = useMemo(() => {
		const plivoCallState = get(plivoInfo, "callState.state");
		const isConnectingCall = includes(PLIVO_CONNECTING_STATE_LIST, plivoCallState);
		const isCalling = includes(PLIVO_CALLED_STATE_LIST, plivoCallState);
		const isIdle = includes(PLIVO_IDLE_STATE_LIST, plivoCallState);

		// If call is terminated or failed
		if (callStatus === CALL_STATES.end.value) {
			if (isIdle) {
				//setCallStatus(CALL_STATES.logNotes.value);
			}
		}
		// Set credential information
		const phoneTypeC = getGCSValue(
			globals,
			"credential_main",
			"credential_type_c",
			"phone-plivo"
		);
		const credentialOptions = [];
		if (credentialsByAccount?.length && globals?.length) {
			credentialsByAccount.forEach((credential) => {
				const credentialName =
					get(
						credential,
						`${MODEL.credential_main._name}.${MODEL.credential_main.credential_name}`
					) || "-";
				const credentialId =
					get(
						credential,
						`${MODEL.credential_main._name}.${MODEL.credential_main.id}`
					) || "-";
				const credentialTypeC = get(
					credential,
					`${MODEL.credential_main._name}.${MODEL.credential_main.credential_type_c}`
				);

				const credentialStatusC = get(
					credential,
					`${MODEL.credential_main._name}.${MODEL.credential_main.credential_status_c}`
				);

				if (credentialTypeC === phoneTypeC) setCredentialId(credentialId);
			});
		}

		return {
			isConnectingCall,
			isCalling,
			isIdle,
			credentialOptions,
		};
	}, [plivoInfo, callStatus, globals, credentialsByAccount]);

	const getAddPersons = (oldPersons, newPersons) => {
		const addedPersons = [];
		newPersons.forEach((person) => {
			const existedPerson = oldPersons.find((oldPerson) => {
				let isRight = false;
				if (oldPerson.type === person.type) {
					if (person.type === PERSON_SELECT_TYPES.direct.value) {
						isRight = oldPerson.value === person.value;
					} else {
						isRight = oldPerson.info.id === person.info.id;
					}
				}

				return isRight;
			});
			if (!existedPerson) {
				addedPersons.push(person);
			}
		});
		const updatedPersons = concat(oldPersons, addedPersons);
		return updatedPersons;
	};

	const handleSaveToUser = (presons) => {
		const newPersons = getAddPersons(toPersons, presons);
		setToPersons(newPersons);
	};

	const handleDeleteToPerson = (person) => {
		const newToPersons = cloneDeep(toPersons);
		remove(newToPersons, (toPerson) => {
			return toPerson.id === person.id;
		});

		setToPersons(newToPersons);
	};

	const handleChangeToUsers = (idx, newPerson) => {
		const newToPersons = cloneDeep(toPersons);
		set(newToPersons, `[${idx}]`, newPerson);

		setToPersons(newToPersons);
	};

	const handleSubject = (val) => {
		setSubject(val);
	};

	const changeEditAreaVal = (value, text) => {
		setMessage(value);
		setPlainMessage(text);
	};

	// Functions related to Phone
	const handleSendCall = () => {
		// Call with selected person
		const phone = get(
			person,
			`${MODEL.person_phone._name}[0].${MODEL.global_phone_details._name}`
		);
		let phoneNumber =
			(phone?.[MODEL.global_phone_details.phone_country_code] || "") +
			(phone?.[MODEL.global_phone_details.phone_number] || "");
		if (!phoneNumber.includes("+")) {
			phoneNumber = "+" + phoneNumber;
		}
		plivoService.call(phoneNumber);
		setConnectingStatus(true);
		setCallStatus(CALL_STATES.end.value);
	};

	const handleSpokeCall = () => {
		setCallOutcome(CALL_OUTCOME_ITEMS.spoke);
	};

	const handleNoAnswerCall = () => {
		setCallOutcome(CALL_OUTCOME_ITEMS.noAnswer);
	};

	const handleLeftVoicemailCall = () => {
		setCallOutcome(CALL_OUTCOME_ITEMS.leftVoiceEmail);
	};

	const handleReceivedVoicemailCall = () => {
		setCallOutcome(CALL_OUTCOME_ITEMS.receivedVoiceEmail);
	};

	const handleMissedCall = () => {
		setCallOutcome(CALL_OUTCOME_ITEMS.missedCall);
	};

	const handleSettingCall = () => {};

	const handleLoggedCall = () => {};

	const handleOutboundCall = () => {
		setCommDirect(false);
	};

	const handleInboundCall = () => {
		setCommDirect(true);
	};

	const handleSaveCall = (isReply) => {
		if (subject === "") {
			setValidationMessage(VALIDATE_MESSAGE.subject.value);
			return;
		}
		if (message === "") {
			setValidationMessage(VALIDATE_MESSAGE.message.value);
			return;
		}
		setValidationMessage("");

		if (!credentialId) {
			setNotificationMessage({
				type: "normal",
				message:
					"The account doesn't have any credential for phone. Please add the correct credential.",
				status: "error",
			});
			return;
		}

		if (account?.id && credentialId) {
			const params = {
				[MODEL.cms_communication_draft.account_main_ref_id]: account?.id,
				[MODEL.cms_communication_draft.credential_main_ref_id]: credentialId,
				[MODEL.cms_communication_draft.comm_platform_c]: getGCSValue(
					globals,
					"cms_communication_main",
					"comm_platform_c",
					"manual"
				),
				[MODEL.cms_communication_draft.conversation_type_c]: getGCSValue(
					globals,
					"cms_conversation_main",
					"conversation_type_c",
					"phone"
				),
				[MODEL.cms_communication_draft.member_object_type_sc]: getGSSValue(
					gssList,
					"OBJECT_BASE",
					"PERSON"
				),
				[MODEL.cms_communication_draft.member_object_subtype_sc]: getGSSValue(
					gssList,
					"OBJECT_PERSON",
					"MAIN"
				),
				[MODEL.cms_communication_draft.member_object_record_id]: person?.id,
				[MODEL.cms_communication_draft.comm_details]: {
					details: {
						message,
						subject: isReply ? `Re: ${subject}` : subject,
					},
					comm_direction: commDirect ? 1 : 0,
					comm_type: isRecruiting,
				},
			};

			const cmsOwnerQualifier = qualifiers.find(
				(qualifier) =>
					qualifier[MODEL.qualifier_main.qualifier_name] ===
					(isRecruiting
						? MESSAGE_MANUAL_QUALIFIER_NAMES.recruiting
						: MESSAGE_MANUAL_QUALIFIER_NAMES.sales)
			);

			const cmsCalloutcomeQualifier = qualifiers.find(
				(qualifier) =>
					qualifier[MODEL.qualifier_main.qualifier_name] === callOutcome.value
			);

			new Promise((resolve, reject) => {
				if (cmsOwnerQualifier && cmsCalloutcomeQualifier) {
					callUpdateCmsCommunicaionDraft(
						[params],
						(res) => {
							const cmsDraftId =
								res?.response?.procedure_results
									?.cms_communication_draft?.[0]?.id;
							if (cmsDraftId) {
								saveQualifiers(
									cmsDraftId,
									"cms-communication-draft",
									[cmsOwnerQualifier.id, cmsCalloutcomeQualifier.id],
									(res) => {
										resolve({ error: false, data: res });
									},
									(err) => {
										reject({
											error: true,
											data: err,
											message:
												"Saved a new cms_communication_draft record, but failed to assign qualifiers to the record.",
										});
									}
								);
							} else {
								reject({
									error: true,
									data: res,
									message:
										"Saved a new cms_communication_draft record, but we are not getting its id from response.",
								});
							}
						},
						(err) => {
							reject({
								error: true,
								data: err,
								message: "Failed to save cms_communication_draft record.",
							});
						}
					);
				} else {
					reject({
						error: true,
						message:
							"Did not find a proper qualifier. Please check qualifier list.",
					});
				}
			})
				.then((res) => {
					setCallStatus(CALL_STATES.logged.value);
					getCmsDraftList();
					setNotificationMessage({
						type: "normal",
						message: "Call Message Saved",
						status: "success",
					});
				})
				.catch((err) => {
					setNotificationMessage({
						type: "normal",
						message:
							err.message + ` ${err.data ? JSON.stringify(err.data) : ""}`,
						status: "error",
					});
				});
		}
	};

	const handleEndCall = () => {
		if (isConnectingStatus) {
			plivoService.hangup();
			setConnectingStatus(false);
			setCallStatus(CALL_STATES.end.value);
			getCmsMainList();
		} else if (!isConnectingStatus) {
			setCallStatus(CALL_STATES.ended.value);
		}
	};

	const handlePhoneMute = () => {
		setIsMuted(!isMuted);
		plivoService.setMute(!isMuted);
	};

	// Functions related to Relation
	const addRelateds = (value) => {
		let _relateds = [...relateds];
		if (!relateds.includes(value)) {
			_relateds.push(value);
		}
		setRelateds(_relateds);
	};

	useEffect(() => {
		// data for Communication Draft Record
		const commDetails = data?.comm_details?.details;
		if (commDetails) {
			const initSubject = commDetails.subject;
			const initMessage = commDetails.body?.content;

			if (initSubject) {
				setSubject(initSubject);
			}
			if (initMessage) {
				setMessage(initMessage);
			}
		}

		// Person
		const personType = get(person, `type`) || "person";
		const personTypeInfo = Object.values(PERSON_SELECT_TYPES).find(
			(item) => item.key === personType
		);
		let personPrimary = null;
		let personPhones = null;
		let personInfo = person;
		if (personType === "person") {
			personPrimary = get(person, `${MODEL.person_primary_setting._name}`);
			personPhones = get(person, `${MODEL.person_phone._name}`);
		} else if (personType === "account") {
			// If person type is "account"
			const personPhonesValue = get(
				person,
				`${MODEL.account_phone._name}[0]${MODEL.global_phone_details._name}`
			);
			personPhones = [personPhonesValue];
			personInfo[MODEL.global_phone_details._name] = personPhones;
		} else {
			personPhones = [];
		}
		let selected = 0;
		if (personPhones?.length) {
			if (initPhone) {
				selected = personPhones.findIndex((phone) => phone?.id === initPhone.id);
				selected = selected > -1 ? selected : 0;
			} else {
				// find primary phone
				if (globals?.length && gssList?.length && personPrimary?.length) {
					const objectValue = getGSSValue(gssList, "OBJECT_BASE", "PERSON");
					const tableValue = getGSSValue(gssList, "OBJECT_PERSON", "PHONE");
					const primaryLevelC = getGCSValue(
						globals,
						"global",
						"primary_level_c",
						"primary"
					);
					const primaryInfo = personPrimary.find(
						(primary) =>
							primary?.ref_object_base_sc === objectValue &&
							primary?.ref_object_table_sc === tableValue &&
							primary?.primary_level_gc === primaryLevelC
					);

					if (primaryInfo) {
						selected = personPhones.findIndex(
							(phone) => phone?.id === primaryInfo.ref_object_record_id
						);
						selected = selected > -1 ? selected : 0;
					}
				}
			}
		}

		const initData = {
			label: person?.name_first + " " + person?.name_last,
			id: person?.id,
			value:
				personTypeInfo.value === PERSON_SELECT_TYPES.direct.value
					? initPhone
					: person?.id,
			info: person,
			type: personTypeInfo.value,
			selected: selected,
		};
		setToPersons([initData]);
	}, [person, globals, gssList, initPhone, data]);

	// Buttons
	const renderButtons = () => {
		switch (callStatus) {
			case CALL_STATES.send.value:
				return (
					<div className="cms-buttons-wrapper">
						<div className="left-side">
							<SVGIcon
								icon={
									isRecruiting ? amsRecruitIcon : addressBookDuoNoteIcon
								}
								onClick={() =>
									isRecruiting
										? setRecruiting(false)
										: setRecruiting(true)
								}
								className="recruiting-sales-button"
							/>
							<FontAwesomeIcon
								icon={faCommentsAlt}
								color="var(--eui-darkest-shade-color)"
								className="message-status-icon"
								onClick={() => setCallStatus(CALL_STATES.logNotes.value)}
							/>
						</div>
						<div className="right-side">
							<SettingButton onSetting={() => handleSettingCall()} />
							<CallButton onCall={() => handleSendCall()} />
						</div>
					</div>
				);

			case CALL_STATES.logged.value:
				return (
					<div className="cms-buttons-wrapper">
						<div className="left-side">
							<SVGIcon
								icon={
									isRecruiting ? amsRecruitIcon : addressBookDuoNoteIcon
								}
								onClick={() =>
									isRecruiting
										? setRecruiting(false)
										: setRecruiting(true)
								}
								className="recruiting-sales-button"
							/>
							<FontAwesomeIcon
								icon={faCommentsAlt}
								color="var(--eui-text-disabled-color)"
								className="message-status-icon"
							/>
							<LoggedButton onLogged={() => handleLoggedCall()} />
						</div>
						<div className="right-side">
							<SpokeButton
								onSpoke={() => handleSpokeCall()}
								onNoAnswer={() => handleNoAnswerCall()}
								onLeftVoicemail={() => handleLeftVoicemailCall()}
								onReceivedVoicemail={() => handleReceivedVoicemailCall()}
								onMissedCall={() => handleMissedCall()}
							/>
							<SaveButton onSave={() => handleSaveCall()} />
						</div>
					</div>
				);

			case CALL_STATES.logNotes.value:
				return (
					<div className="cms-buttons-wrapper">
						<div className="left-side">
							<SVGIcon
								icon={
									isRecruiting ? amsRecruitIcon : addressBookDuoNoteIcon
								}
								onClick={() =>
									isRecruiting
										? setRecruiting(false)
										: setRecruiting(true)
								}
								className="recruiting-sales-button"
							/>
							<FontAwesomeIcon
								icon={faStickyNote}
								color="var(--eui-primary-color)"
								className="message-status-icon"
								onClick={() => setCallStatus(CALL_STATES.send.value)}
							/>
							<SpokeButton
								onSpoke={() => handleSpokeCall()}
								onNoAnswer={() => handleNoAnswerCall()}
								onLeftVoicemail={() => handleLeftVoicemailCall()}
								onReceivedVoicemail={() => handleReceivedVoicemailCall()}
								onMissedCall={() => handleMissedCall()}
							/>
							<OutboundButton
								onOutbound={() => handleOutboundCall()}
								onInbound={() => handleInboundCall()}
							/>
						</div>
						<div className="right-side">
							<SaveButton
								onSave={() => {
									handleSaveCall();
									setCallStatus(CALL_STATES.logged.value);
								}}
							/>
						</div>
					</div>
				);

			case CALL_STATES.end.value:
				return (
					<div className="cms-buttons-wrapper">
						<div className="left-side">
							<FontAwesomeIcon
								icon={faCommentsAlt}
								color="var(--eui-text-disabled-color)"
								className="message-status-icon"
							/>
							<SpokeButton
								onSpoke={() => handleSpokeCall()}
								onNoAnswer={() => handleNoAnswerCall()}
								onLeftVoicemail={() => handleLeftVoicemailCall()}
								onReceivedVoicemail={() => handleReceivedVoicemailCall()}
								onMissedCall={() => handleMissedCall()}
							/>
							<SpokeButton />
						</div>
						<div className="right-side">
							<SettingButton onSetting={() => handleSettingCall()} />
							{
								<PhoneButton
									isMuted={isMuted}
									onChangeMute={() => handlePhoneMute()}
								/>
							}
							{isConnectingStatus && <EuiLoadingSpinner size="m" />}
							<EndButton onEnd={() => handleEndCall()} />
						</div>
					</div>
				);
			case CALL_STATES.ended.value:
				return (
					<div className="cms-buttons-wrapper">
						<div className="left-side">
							<FontAwesomeIcon
								icon={faCommentsAlt}
								color="var(--eui-text-disabled-color)"
								className="message-status-icon"
							/>
						</div>
						<div className="right-side">
							<SpokeButton
								onSpoke={() => handleSpokeCall()}
								onNoAnswer={() => handleNoAnswerCall()}
								onLeftVoicemail={() => handleLeftVoicemailCall()}
								onReceivedVoicemail={() => handleReceivedVoicemailCall()}
								onMissedCall={() => handleMissedCall()}
							/>
							<SaveButton
								onSave={setCallStatus(CALL_STATES.logged.value)}
							/>
						</div>
					</div>
				);

			default:
				return null;
		}
	};

	return (
		<Wrapper>
			<EuiFlexGroup direction="column" gutterSize="xs">
				<EuiFlexItem>
					<div className="d-flex mb-1">
						<EuiText className="label mr-4">To:</EuiText>
						<div className="icon-badges">
							<EuiFlexGroup gutterSize="s" alignItems="center" wrap={true}>
								{toPersons &&
									toPersons.length > 0 &&
									toPersons.map((item, index) => {
										return (
											<EuiFlexItem
												key={"to-person-item-" + index}
												grow={false}
											>
												<PersonPhoneBadgeItem
													detail={item}
													onRemove={() =>
														handleDeleteToPerson(item)
													}
													onChangeItem={(phoneIndex) => {
														handleChangeToUsers(index, {
															...item,
															selected: phoneIndex,
														});
													}}
												/>
											</EuiFlexItem>
										);
									})}
								<EuiFlexItem grow={false}>
									<AddPersonPhoneItem
										onSave={handleSaveToUser}
										accounts={accounts}
										savePersonPhone={savePersonPhone}
										deletePerson={deletePerson}
										savePersonEmail={savePersonEmail}
									/>
								</EuiFlexItem>
							</EuiFlexGroup>
						</div>
					</div>
				</EuiFlexItem>
				<EuiFlexItem>
					<EuiFlexGroup gutterSize="s" direction="column">
						<EuiFlexItem>
							<EuiFlexGroup gutterSize="s" direction="column">
								<EuiFlexItem>
									<TextInputEditor
										editorVal={subject}
										autocompletOptions={macroOptions}
										changeEditVal={handleSubject}
									/>
								</EuiFlexItem>
								<EuiFlexItem>
									<TextEditor
										id="message-linkedin-editor"
										editorVal={message}
										autocompletOptions={macroOptions}
										changeEditVal={changeEditAreaVal}
									/>
								</EuiFlexItem>
								<EuiFlexItem>{renderButtons()}</EuiFlexItem>
							</EuiFlexGroup>
						</EuiFlexItem>
						<EuiFlexItem>
							<RelatedItem
								message={validationMessage}
								items={relateds}
								onAddItem={addRelateds}
							/>
						</EuiFlexItem>
					</EuiFlexGroup>
				</EuiFlexItem>
				<EuiFlexItem>
					<MessageAms
						person={person}
						subject={subject}
						message={plainMessage}
					/>
				</EuiFlexItem>
			</EuiFlexGroup>
		</Wrapper>
	);
};

export default MessageSenderPhone;

/**
 * Styled Components
 */
const Wrapper = styled.div`
	.label {
		color: var(--light-color);
		font-size: 0.75rem;
		font-weight: 600;
		margin-right: 0.3rem;
		margin-top: 0.25rem;
	}

	.icon-badges {
		flex-wrap: wrap;
		display: flex;
	}

	.euiCheckbox .euiCheckbox__input + .euiCheckbox__square {
		border-radius: 50%;
	}

	.primary-color {
		color: var(--link-color);
	}
`;
