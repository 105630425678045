import React, { useCallback, useEffect, useState } from "react";
import styled from "styled-components";
import { EuiFieldText } from "@elastic/eui";

import { plusIcon, minusIcon } from "components/Common/Icons";
import { SVGIcon } from "components/Common";
import { EuiSelect, EuiComboBox } from "@elastic/eui";
import { _getCompanyMainList_NEW } from "helpers/selectors";
/**
 * Main Components
 */
const SelectCreateCompany = (props) => {
	const { company, options, onChangeCompany, onChangeSearch, ...restProps } = props;

	// define variables from company main
	const companyName = company?.company_main?.company_name;

	const [isCreate, setIsCreate] = useState(false);
	const [selectedOptions, setSelected] = useState([]);
	const [optionList, setOptionList] = useState(options);

	const companies = _getCompanyMainList_NEW();
	const isLoading = companies.isRequesting;

	const handleChangeCompany = (val) => {
		setSelected(val);
		onChangeCompany({
			value: val?.[0]?.value,
			company_main: {
				company_name: val?.[0]?.label,
				id: val?.[0]?.value,
			},
		});
	};

	const handleChangeNewCompany = (e) => {
		onChangeCompany({
			value: undefined,
			company_main: {
				company_name: e.target.value,
			},
		});
	};

	const handleSearchChange = useCallback((search) => {
		onChangeSearch(search);
	}, []);

	const changeStatus = () => {
		setIsCreate(isCreate ? false : true);
	};

	// effect
	useEffect(() => {
		setOptionList(options);
		if (company.value && options?.length) {
			let _optionList = [...options];
			const idx = _optionList.findIndex((item) => item.value === company.value);
			if (idx === -1) {
				_optionList.push({
					value: company?.value,
					label: company?.company_main?.company_name,
				});
			}
			_optionList.map((option) => {
				if (option.value === company.value) {
					setSelected([option]);
				}
			});
			setOptionList(_optionList);
		}
	}, [company, options]);

	return (
		<Wrapper {...restProps}>
			{isCreate ? (
				<WrapperContainer>
					<EuiFieldText
						placeholder="Create Company"
						value={companyName || ""}
						onChange={handleChangeNewCompany}
					/>
					<SVGIcon
						icon={minusIcon}
						onClick={changeStatus}
						className="my-auto ml-2 cursor-pointer"
					/>
				</WrapperContainer>
			) : (
				<WrapperContainer>
					<EuiComboBox
						id="person-job-company"
						async
						singleSelection={{ asPlainText: true }}
						options={optionList}
						selectedOptions={selectedOptions}
						onChange={handleChangeCompany}
						isLoading={isLoading}
						onSearchChange={handleSearchChange}
						isClearable={false}
					/>
					<SVGIcon
						icon={plusIcon}
						onClick={changeStatus}
						className="my-auto ml-2 cursor-pointer"
					/>
				</WrapperContainer>
			)}
		</Wrapper>
	);
};

export default SelectCreateCompany;

/**
 * Styled Components
 */
const Wrapper = styled.div`
	display: flex;
	width: 100%;
`;

const WrapperContainer = styled.div`
	position: relative;
	display: flex;
	width: 100%;

	.euiComboBox .euiComboBox__inputWrap {
		position: relative;
	}
	.euiComboBoxPill,
	.euiComboBoxPill + .euiComboBoxPill {
		position: absolute;
	}
`;
