import React, { useState } from "react";
import { EuiFlexItem, EuiFlexGroup, EuiIcon } from "@elastic/eui";
import styled from "styled-components";

import AmsTaskListItem from "./AmsTaskListItem";
import AmsTaskListNewItem from "./AmsTaskListNewItem";

/**
 * Component for displaying Task list of AMS
 */
const AmsTaskList = (props) => {
	const { data, onSaveTask, onDeleteTask, onUpdateReminder, onDeleteReminder } = props;

	const [isAddNewTask, setIsAddNewTask] = useState(false);

	const handleClickAddTask = () => {
		setIsAddNewTask(true);
	};

	const handleSaveNewTask = (task) => {
		onSaveTask(task);
		setIsAddNewTask(false);
	};

	const handleCancelNewTask = () => {
		setIsAddNewTask(false);
	};

	return (
		<Wrapper>
			<EuiFlexGroup direction="column" gutterSize="s">
				{data?.map((task, id) => {
					return (
						<EuiFlexItem key={`ams-task-list-item-${id}`}>
							<AmsTaskListItem
								data={task}
								onChangeTask={onSaveTask}
								onDeleteTask={onDeleteTask}
								onUpdateReminder={onUpdateReminder}
								onDeleteReminder={onDeleteReminder}
							/>
						</EuiFlexItem>
					);
				})}
				<EuiFlexItem grow={false}>
					{isAddNewTask ? (
						<AmsTaskListNewItem
							onSave={handleSaveNewTask}
							onCancel={handleCancelNewTask}
						/>
					) : (
						<div className="add-task_container" onClick={handleClickAddTask}>
							<p className="add-task-icon">
								<EuiIcon type="plusInCircle"></EuiIcon>
							</p>
							<p className="type-small-default add-task-label">Task</p>
						</div>
					)}
				</EuiFlexItem>
			</EuiFlexGroup>
		</Wrapper>
	);
};

export default AmsTaskList;

const Wrapper = styled.div`
	display: flex;
	width: 100%;
	flex-direction: column;

	.add-task_container {
		display: flex;
		width: fit-content;
		color: var(--eui-link-color);
		cursor: pointer;
		align-items: center;

		.add-task-label {
			margin-left: var(--sp-4);
		}
	}
`;
