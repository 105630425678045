import { useDispatch } from "react-redux";
import { get } from "lodash";

import {
	deletePerson_NEW,
	fetchAllPersons,
	setMessagingPersonFlyoutVisible,
	setOpenPersonListFilter,
	updatePerson_NEW,
	getZipAreas,
} from "helpers/actions";
import * as MODEL from "../model";
import { _getIsOpenPersonListFilter } from "helpers/selectors";
import { _getPersonCmsAutomatedList, _getPersonCmsManualList } from "helpers/selectors";

/**
 * Hooks for Person
 */
const usePerson = () => {
	const dispatch = useDispatch();

	const isOpenListFilter = _getIsOpenPersonListFilter();
	const personCmsAutomatedList = _getPersonCmsAutomatedList();
	const personCmsManualList = _getPersonCmsManualList();

	const getPersonName = (person) => {
		const nameFirst = get(person, `${MODEL.person_main.name_first}`) || "";
		const nameLast = get(person, `${MODEL.person_main.name_last}`) || "";
		const personName = `${nameFirst} ${nameLast}`;

		return personName;
	};

	const savePersonPhone = (personId, phone, successCallBack, failCallBack) => {
		const global = phone?.global_phone_details;
		const query = {
			[MODEL.person_phone._name]: {
				[MODEL.person_phone.id]: phone?.id,
				[MODEL.person_phone.person_main_ref_id]: personId,
				[MODEL.person_phone.phone_type_c]: phone?.phone_type_c,
				[MODEL.global_phone_details._name]: {
					[MODEL.global_phone_details.id]: global?.id,
					[MODEL.global_phone_details.phone_number]: global?.phone_number,
					[MODEL.global_phone_details.phone_country_code]:
						global?.phone_country_code,
					[MODEL.global_phone_details.phone_extension]: global?.phone_extension,
					[MODEL.global_phone_details.phone_status_c]: global?.phone_status_c,
				},
			},
		};

		return new Promise((resolve, reject) => {
			dispatch(
				updatePerson_NEW(
					query,
					(res) => {
						if (successCallBack) successCallBack(res);
						resolve(res);
					},
					(err) => {
						if (failCallBack) failCallBack(err);
						reject(err);
					}
				)
			);
		});
	};

	const savePersonEmail = (personId, email, successCallBack, failCallBack) => {
		const global = email?.global_email_details;
		const query = {
			[MODEL.person_email._name]: {
				[MODEL.person_email.id]: email?.id,
				[MODEL.person_email.person_main_ref_id]: personId,
				[MODEL.person_email.email_type_c]: email?.email_type_c,
				[MODEL.global_email_details._name]: {
					[MODEL.global_email_details.id]: global?.id,
					[MODEL.global_email_details.email_value]: global?.email_value,
					[MODEL.global_email_details.email_status_date]:
						global?.email_status_date,
					[MODEL.global_email_details.email_status_c]: global?.email_status_c,
				},
			},
		};

		return new Promise((resolve, reject) => {
			dispatch(
				updatePerson_NEW(
					query,
					(res) => {
						if (successCallBack) successCallBack(res);
						resolve(res);
					},
					(err) => {
						if (failCallBack) failCallBack(err);
						reject(err);
					}
				)
			);
		});
	};

	const deletePerson = (query, successCallBack, failCallBack) => {
		dispatch(deletePerson_NEW(query, successCallBack, failCallBack));
	};

	const setMessagePersonFlyoutVisible = (params) => {
		dispatch(setMessagingPersonFlyoutVisible(params));
	};

	const toggleOpenListFilter = () => {
		dispatch(setOpenPersonListFilter(!isOpenListFilter));
	};

	const getPersonList = ({ page = 1, perPage = 20, filter = {}, sort = [] }) => {
		dispatch(fetchAllPersons(page, perPage, "", filter, sort));
	};

	const callGetZipAreas = (params) => {
		return dispatch(getZipAreas(params));
	};

	return {
		isOpenListFilter,

		getPersonList,
		getPersonName,
		savePersonPhone,
		deletePerson,
		savePersonEmail,
		setMessagePersonFlyoutVisible,
		toggleOpenListFilter,

		personCmsAutomatedList,
		personCmsManualList,

		getZipAreas: callGetZipAreas,
	};
};

export default usePerson;
