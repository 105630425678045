import React, { Fragment } from "react";
import styled from "styled-components";
import { EuiFlexGroup, EuiFlexItem, EuiIcon, EuiTabbedContent } from "@elastic/eui";

import ScreeningOverview from "./ScreeningOverview";
import ScreeningCompLocation from "./ScreeningCompLocation";
import ScreeningOtherJob from "./ScreeningOtherJob";
import ScreeningOtherPerson from "./ScreeningOtherPerson";
import {
	COMPENSATION_EDIT_STATUES,
	COMPENSATION_SUMMARY_ITEMS,
} from "components/global/constants";

import { compensation_summary_items_dummy } from "./dummy";

import variables from "assets/css/variables.scss";

/**
 * Constants
 */
const SCREENING_TYPES = {
	job: { key: "job", path: "jobs" },
	person: { key: "person", path: "persons" },
};

/**
 * Main component for Screening content
 */
const ScreeningContent = (props) => {
	const {
		sits,
		globals,
		onEditSIT,
		personId,
		compensations,
		type,
		hasSummaryList,
		onChangeSitTypeGc,
		onDeleteSit,
	} = props;

	const tabs = [
		{
			id: "overview",
			name: "Overview",
			content: (
				<Fragment>
					<ScreeningOverview
						globals={globals}
						sits={sits}
						onEditSIT={onEditSIT}
						personId={personId}
						compensations={compensations}
						onChangeObjectSitType={onChangeSitTypeGc}
						onDeleteSit={onDeleteSit}
					/>
				</Fragment>
			),
		},
		{
			id: "comp_location",
			name: "Comp & Location",
			content: (
				<Fragment>
					<ScreeningCompLocation compensations={compensations} />
				</Fragment>
			),
		},
		{
			id: "other",
			name: "Other",
			content: (
				<Fragment>
					{type === SCREENING_TYPES.person.key ? (
						<ScreeningOtherPerson type={type} />
					) : (
						<ScreeningOtherJob type={type} />
					)}
				</Fragment>
			),
		},
	];

	const getItemStatus = (state) => {
		const statusItemKey = Object.getOwnPropertyNames(COMPENSATION_EDIT_STATUES).find(
			(key) => COMPENSATION_EDIT_STATUES[key].value === state
		);
		return statusItemKey ? COMPENSATION_EDIT_STATUES[statusItemKey] : null;
	};

	const renderSummaryList = () => {
		return Object.getOwnPropertyNames(COMPENSATION_SUMMARY_ITEMS).map(
			(mainItemKey) => {
				const mainItem = compensation_summary_items_dummy[mainItemKey];
				const statusItem = getItemStatus(mainItem.value);

				const renderSubItems =
					mainItem?.items &&
					Object.getOwnPropertyNames(mainItem?.items).map((subItemKey) => {
						const subItem = mainItem?.items[subItemKey];
						const statueSubItem = getItemStatus(subItem.value);

						return (
							<EuiFlexItem
								key={`compensation-summary-main-item-${mainItemKey}-${subItemKey}`}
								grow={false}
							>
								<EuiIcon type="dot" color={statueSubItem.color} />
							</EuiFlexItem>
						);
					});

				return (
					<EuiFlexItem key={`compensation-summary-main-item-${mainItemKey}`}>
						<SummaryItem>
							<EuiFlexGroup gutterSize="none" direction="row">
								<EuiFlexItem>
									<EuiFlexGroup gutterSize="none" direction="row">
										<EuiFlexItem grow={false}>
											<EuiIcon
												type="dot"
												className="main-item"
												color={statusItem.color}
											/>
										</EuiFlexItem>
										<EuiFlexItem grow={false}>
											<span>{mainItem.label}</span>
										</EuiFlexItem>
									</EuiFlexGroup>
								</EuiFlexItem>
								<EuiFlexItem grow={false}>
									<EuiFlexGroup gutterSize="none" direction="row">
										{renderSubItems}
									</EuiFlexGroup>
								</EuiFlexItem>
							</EuiFlexGroup>
						</SummaryItem>
					</EuiFlexItem>
				);
			}
		);
	};

	return (
		<Wrapper>
			<EuiFlexGroup gutterSize="s" direction="row" justifyContent="spaceBetween">
				<EuiFlexItem>
					<EuiTabbedContent
						tabs={tabs}
						initialSelectedTab={tabs[0]}
						autoFocus="selected"
						onTabClick={() => {}}
					/>
				</EuiFlexItem>
				{hasSummaryList ? (
					<EuiFlexItem grow={false} className="summary-list">
						<EuiFlexGroup gutterSize="xs" direction="column">
							{renderSummaryList()}
						</EuiFlexGroup>
					</EuiFlexItem>
				) : null}
			</EuiFlexGroup>
		</Wrapper>
	);
};

export default ScreeningContent;

/**
 * Styled Components
 */
const Wrapper = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	padding: 0.25rem 0 1.5rem 0;
	letter-spacing: -0.08px;

	color: ${variables.primaryColor};

	.euiBadge {
		border-radius: 12px;
		padding: 1px 8px 1px 8px;
		margin: 0px 4px 4px 0px;
	}

	.summary-list {
		max-width: 11.5rem;
		padding: 4px 5px 0 0;
	}
`;

const SummaryItem = styled.div`
	display: inline-flex;
	align-items: center;

	.main-item {
		margin-right: 0.75rem;
	}
`;
