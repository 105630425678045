const placement_main = {
	_name: "placement_main",
	id: "id",
	placement_type_c: "placement_type_c",
	placement_status_c: "placement_status_c",
	link_job_application_id: "link_job_application_id",
	link_compensation_main_id: "link_compensation_main_id",
	date_details: "date_details",
	billing_details: "billing_details",
	burden_details: "burden_details",
	estimation_details: "estimation_details",
	invoice_details: "invoice_details",
	completion_details: "completion_details",
	placement_details: "placement_details",
	record_state_sc: "record_state_sc",
};

const placement_commission = {
	_name: "placement_commission",
	id: "id",
	link_account_main_id: "link_account_main_id",
	link_placement_main_id: "link_placement_main_id",
	commission_type_c: "commission_type_c",
	commission_value: "commission_value",
	commission_value_type_c: "commission_value_type_c",
};

const placement_contact = {
	_name: "placement_contact",
	id: "id",
	link_placement_main_id: "link_placement_main_id",
	link_person_main_id: "link_person_main_id",
	contact_type_c: "contact_type_c",
};

const placement_log_activity = {
	_name: "placement_log_activity",
	id: "id",
	date_created: "date_created",
	account_main_ref_id: "account_main_ref_id",
	placement_table_ref_id: "placement_table_ref_id",
	log_object_c: "log_object_c",
	log_type_c: "log_type_c",
	log_action_c: "log_action_c",
	log_details: "log_details",
};

export {
	placement_main,
	placement_commission,
	placement_contact,
	placement_log_activity,
};
