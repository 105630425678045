export const ICON_NAME = {
	EMAIL_ICON: "email",
	LINKEDIN_ICON: "linkedin",
	CALENDAR_ICON: "calendar",
	BAG_ICON: "bag",
	ONLINE_ICON: "online",
};

export const MESSAGE_BTNS = {
	EMAIL_BTN: "email",
	LINKEDIN_BTN: "linkedin",
	CHAT_BTN: "chat",
	PHONE_BTN: "phone",
	GROUP_BTN: "group",
	URL_LINK_BTN: "url_link",
};

export const MESSAGE_TYPES = {
	email: "email",
	linkedin: "linkedin",
	chat: "chat",
	phone: "phone",
	group: "group",
	urlLink: "url_link",
};

export const MESSAGE_STATES = {
	send: { key: "send", label: "Send", value: "send" },
	logged: { key: "logged", label: "Logged", value: "logged" },
	logNotes: { key: "logNotes", label: "Log Notes Only", value: "logNotes" },
};

export const CALL_STATES = {
	send: { key: "send", label: "Send", value: "send" },
	logged: { key: "logged", label: "Logged", value: "logged" },
	logNotes: { key: "logNotes", label: "Log Notes Only", value: "logNotes" },
	end: { key: "end", label: "End & Save Notes Only", value: "end" },
};
