import React, { useMemo, useState } from "react";
import styled from "styled-components";
import { EuiPopover, EuiFlexGroup, EuiFlexItem } from "@elastic/eui";

import { AMS_TASK_STATUS_LIST } from "../../constants";

/**
 * Component for AMS Task Status Item with editable feature
 */
const AmsTaskStatusItem = (props) => {
	const { onChangeStatus, status = 1, isEdit = true } = props;

	const [isOpenPopover, setIsOpenPopover] = useState(false);

	const { amsTaskStatus } = useMemo(() => {
		const amsTaskStatus =
			Object.values(AMS_TASK_STATUS_LIST).find((item) => item.value === status) ||
			AMS_TASK_STATUS_LIST.pending;

		return {
			amsTaskStatus,
		};
	}, [status]);

	const handleOpenPopover = () => {
		if (isEdit) {
			setIsOpenPopover(true);
		} else {
			setIsOpenPopover(false);
		}
	};

	const handleClosePopover = () => {
		setIsOpenPopover(false);
	};

	const handleChangeStatus = (newState) => {
		if (onChangeStatus) {
			onChangeStatus(newState);
		}

		setIsOpenPopover(false);
	};

	return (
		<EuiPopover
			isOpen={isOpenPopover}
			button={
				<div
					onClick={handleOpenPopover}
					className="cursor-pointer"
					style={{ color: amsTaskStatus?.color }}
				>
					{amsTaskStatus.icon}
				</div>
			}
			closePopover={handleClosePopover}
			panelStyle={{
				width: 110,
				padding: "0.5rem 0.75rem",
			}}
		>
			<EuiFlexGroup direction="column" gutterSize="s">
				{Object.values(AMS_TASK_STATUS_LIST).map((item) => {
					return (
						<EuiFlexItem key={`ams-status-item-${item.value}`}>
							<StatusListItem
								gutterSize="s"
								alignItems="center"
								className="cursor-pointer"
								onClick={() => handleChangeStatus(item.value)}
							>
								<EuiFlexItem grow={false}>
									<div style={{ color: item.color }}>{item.icon}</div>
								</EuiFlexItem>
								<EuiFlexItem>{item.label}</EuiFlexItem>
							</StatusListItem>
						</EuiFlexItem>
					);
				})}
			</EuiFlexGroup>
		</EuiPopover>
	);
};

export default AmsTaskStatusItem;

const StatusListItem = styled(EuiFlexGroup)`
	svg {
		width: var(--sp-8);
		height: auto;
	}
`;
