import React from "react";
import { NavLink } from "react-router-dom";
import styled from "styled-components";
import { EuiFlexItem, EuiFlexGroup, EuiText } from "@elastic/eui";
import { get } from "lodash";

import * as MODEL from "core/model";
import { CampaignResultItem } from "../Items";

/**
 * Component for Application Campaign Column
 */
const ApplicationCampaign = (props) => {
	const { person, profile, onChangeDisposition } = props;

	const itemDisposition = get(profile, `${MODEL.campaign_item.item_disposition_c}`);
	const itemId = profile?.[MODEL.campaign_item.id];

	const handleChangeDisposition = (newDisposition) => {
		onChangeDisposition(newDisposition);
	};

	return (
		<Wrapper>
			<EuiFlexGroup gutterSize="s" alignItems="center">
				<EuiFlexItem grow={false}>
					<CampaignResultItem
						status={itemDisposition}
						showLabel={false}
						onChangeStatus={handleChangeDisposition}
						itemId={itemId}
						person={person}
					/>
				</EuiFlexItem>
				<EuiFlexItem>
					<EuiFlexGroup
						direction="column"
						gutterSize="xs"
						className="profile-content"
					>
						<EuiFlexItem grow={false}>
							<EuiText className="profile-name" size="s">
								<NavLink to={`/campaigns/${profile?.id}`}>
									{profile?.name ?? "-"}
								</NavLink>
							</EuiText>
						</EuiFlexItem>
						<EuiFlexItem>
							<EuiFlexGroup
								responsive={false}
								gutterSize="s"
								justifyContent="spaceBetween"
							>
								<EuiFlexItem grow={false}>
									<EuiText className="profile-values" size="s">
										<span>{profile?.name ?? "-"}</span>
									</EuiText>
								</EuiFlexItem>
								<EuiFlexItem grow={false}>
									<EuiText className="profile-contact" size="s">
										<span>12/20/2020</span>
									</EuiText>
								</EuiFlexItem>
							</EuiFlexGroup>
						</EuiFlexItem>
					</EuiFlexGroup>
				</EuiFlexItem>
			</EuiFlexGroup>
		</Wrapper>
	);
};

export default ApplicationCampaign;

const Wrapper = styled.div`
	display: flex;
	position: relative;
	width: 100%;

	.profile-name,
	.profile-values,
	.profile-contact {
		span {
			font-size: var(--sp-6);
			line-height: 1.5;
		}
	}

	.profile-name {
		font-weight: 600;
	}

	.profile-contact {
		display: flex;
		align-items: center;
		margin-left: var(--sp-2);

		svg {
			width: var(--sp-4);
			height: auto;
			margin-right: var(--sp-2);
		}
	}

	.profile-values {
		display: flex;
		span {
			white-space: nowrap;
			overflow: hidden;
			text-overflow: ellipsis;
			max-width: var(--sp-40);
		}
	}
`;
