import React, { useMemo } from "react";
import { EuiFlexItem, EuiFlexGroup, EuiIcon, EuiButtonEmpty } from "@elastic/eui";
import styled from "styled-components";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAlarmSnooze } from "@fortawesome/pro-light-svg-icons";

import { EditableNumber } from "components/Common";
import { amsReminderPendingIcon } from "components/Common/Icons";

import * as MODEL from "core/model";

import AmsReminderTypeItem from "./AmsReminderTypeItem";
import AmsReminderTimeTypeItem from "./AmsReminderTimeTypeItem";
import AmsReminderTimeFrameItem from "./AmsReminderTimeFrameItem";
import { useAccount, useNotification } from "core/useHooks";
import { AMS_REMINDER_TYPE_LIST } from "components/global/constants";

/**
 * Component for displaying Reminder List Item of AMS
 */
const AmsReminderListItem = (props) => {
	const { data, onChangeReminder, onDeleteReminder } = props;

	// Reference ams_activity_reminder model
	const reminder = {
		id: data?.id || null,
		index: data?.index,
		reminder_type_c: data?.reminder_type_c,
		reminder_time_value: data?.reminder_time_value,
		reminder_time_frame_c: data?.reminder_time_frame_c,
		reminder_time_type_c: data?.reminder_time_type_c,
		reminder_triggered: data?.[MODEL.ams_activity_reminder.reminder_triggered],
		reminder_result_c: data?.[MODEL.ams_activity_reminder.reminder_result_c] || 1,
		reminder_details: data?.[MODEL.ams_activity_reminder.reminder_details] || null,
	};

	const isEditable = !reminder.reminder_triggered;

	const { account } = useAccount();
	const { setNotificationMessage } = useNotification();

	const { accountEmailId, accountPhoneId } = useMemo(() => {
		const accountEmailId =
			account?.[MODEL.account_email._name]?.[0]?.[
				MODEL.global_email_details._name
			]?.[MODEL.global_email_details.id];
		const accountPhoneId =
			account?.[MODEL.account_phone._name]?.[0]?.[
				MODEL.global_phone_details._name
			]?.[MODEL.global_phone_details.id];

		return {
			accountEmailId,
			accountPhoneId,
		};
	}, [account]);

	const handleClickDelete = () => {
		onDeleteReminder(reminder);
	};

	const handleReminderType = (newType) => {
		const saveReminderType = (details) => {
			const newReminder = {
				...reminder,
				[MODEL.ams_activity_reminder.reminder_type_c]: newType,
				[MODEL.ams_activity_reminder.reminder_details]: details,
			};

			onChangeReminder(newReminder);
		};

		if (newType === AMS_REMINDER_TYPE_LIST.email.value) {
			if (!accountEmailId) {
				setNotificationMessage({
					type: "normal",
					title: "Error",
					message: "No cell email set in My Profile section",
					status: "error",
				});
			} else {
				saveReminderType({
					[MODEL.global_email_details._name]: accountEmailId,
				});
			}
		} else if (newType === AMS_REMINDER_TYPE_LIST.sms.value) {
			if (!accountPhoneId) {
				setNotificationMessage({
					type: "normal",
					title: "Error",
					message: "No cell phone set in My Profile section",
					status: "error",
				});
			} else {
				saveReminderType({
					[MODEL.global_phone_details._name]: accountPhoneId,
				});
			}
		} else {
			saveReminderType(null);
		}
	};

	const handleReminderTimeType = (newTimeType) => {
		const newReminder = {
			...reminder,
			[MODEL.ams_activity_reminder.reminder_time_type_c]: newTimeType,
		};

		onChangeReminder(newReminder);
	};

	const handleReminderTimeFrame = (newFrame) => {
		const newReminder = {
			...reminder,
			[MODEL.ams_activity_reminder.reminder_time_frame_c]: newFrame,
		};

		onChangeReminder(newReminder);
	};

	const handleSaveTimeValue = (timeValue) => {
		const newReminder = {
			...reminder,
			[MODEL.ams_activity_reminder.reminder_time_value]: timeValue,
		};

		onChangeReminder(newReminder);
	};

	const handleCancelTimeValue = () => {};

	return (
		<Wrapper>
			<EuiFlexGroup gutterSize="s" alignItems="center">
				<EuiFlexItem grow={false}>
					{!isEditable ? (
						reminder.reminder_result_c === 1 ? (
							amsReminderPendingIcon()
						) : (
							<FontAwesomeIcon
								icon={faAlarmSnooze}
								color="var(--eui-warning-color)"
								size="lg"
							/>
						)
					) : null}
				</EuiFlexItem>
				<EuiFlexItem grow={false}>
					<AmsReminderTypeItem
						type={reminder.reminder_type_c}
						onChange={handleReminderType}
						isEdit={isEditable}
					/>
				</EuiFlexItem>
				<EuiFlexItem grow={false}>
					<EditableNumber
						value={reminder.reminder_time_value}
						numberStyle={{
							fontSize: 12,
						}}
						isEdit={isEditable}
						onSave={handleSaveTimeValue}
						onCancel={handleCancelTimeValue}
					/>
				</EuiFlexItem>
				<EuiFlexItem grow={false}>
					<AmsReminderTimeTypeItem
						type={reminder.reminder_time_type_c}
						onChange={handleReminderTimeType}
						isEdit={isEditable}
					/>
				</EuiFlexItem>
				<EuiFlexItem grow={false}>
					<AmsReminderTimeFrameItem
						frame={reminder.reminder_time_frame_c}
						onChange={handleReminderTimeFrame}
						isEdit={isEditable}
					/>
				</EuiFlexItem>
				<EuiFlexItem></EuiFlexItem>
				{isEditable ? (
					<EuiFlexItem grow={false}>
						<EuiButtonEmpty
							className="button-fit-content"
							onClick={handleClickDelete}
						>
							<EuiIcon type="trash" color="danger" size="s" />
						</EuiButtonEmpty>
					</EuiFlexItem>
				) : null}
			</EuiFlexGroup>
			<div className="left-sidebar" />
		</Wrapper>
	);
};

export default AmsReminderListItem;

const Wrapper = styled.div`
	display: flex;
	width: 100%;
	position: relative;
`;
