import React from "react";

import { emailBouncedIcon } from "components/Common/Icons/Icons";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faExclamationTriangle } from "@fortawesome/pro-regular-svg-icons";
import { faCheckCircle } from "@fortawesome/pro-light-svg-icons";

export const EMAIL_STATUS_OPTIONS = [
	{
		value: 1,
		label: "Valid",
		icon: (
			<FontAwesomeIcon
				icon={faCheckCircle}
				color={"var(--success-color)"}
				className="button-icon"
			/>
		),
	},
	{
		value: 2,
		label: "Invalid",
		icon: (
			<FontAwesomeIcon
				icon={faExclamationTriangle}
				color={"var(--danger-color)"}
				className="button-icon"
			/>
		),
	},
	{
		value: 3,
		label: "Undeliverable",
		icon: (
			<FontAwesomeIcon
				icon={faExclamationTriangle}
				color={"var(--border-color)"}
				className="button-icon"
			/>
		),
	},
	{
		value: 4,
		label: "Unknown",
		icon: (
			<FontAwesomeIcon
				icon={faExclamationTriangle}
				color={"var(--warning-color)"}
				className="button-icon"
			/>
		),
	},
	{
		value: 5,
		label: "Bounced",
		icon: emailBouncedIcon(),
	},
	{
		value: 6,
		label: "Opt-out",
		icon: (
			<FontAwesomeIcon
				icon={faExclamationTriangle}
				color={"var(--border-color)"}
				className="button-icon"
			/>
		),
	},
];
