import React, { useState } from "react";
import styled from "styled-components";
import { EuiLink, EuiFlexGroup, EuiFlexItem, EuiTextArea } from "@elastic/eui";

import { TextInputEditor } from "components/Common";
import { addressBookDuoNoteIcon } from "components/Common/Icons/Icons";
import RelatedItem from "./RelatedItem";

import { useMacro } from "core/useHooks";

/**
 * Component for sending url link
 */
const MessageSenderUrlLink = (props) => {
	const { person } = props;

	const [relateds, setRelateds] = useState([]);
	const [subject, setSubject] = useState("");
	const [validationMessage, setValidationMessage] = useState("");


	const { macroOptions } = useMacro();

	const handleSubject = (val) => {
		setSubject(val);
	};

	// Functions related to Relation
	const addRelateds = (value) => {
		let _relateds = [...relateds];
		if (!relateds.includes(value)) {
			_relateds.push(value);
		}
		setRelateds(_relateds);
	};

	return (
		<Wrapper>
			<EuiFlexGroup direction="column" gutterSize="xs">
				<EuiFlexItem>
					<EuiFlexGroup gutterSize="s" direction="column" className="mt-4">
						<EuiFlexItem>
							<EuiFlexGroup gutterSize="s" direction="column">
								<EuiFlexItem>
									<EuiFlexGroup gutterSize="s" alignItems="center">
										<EuiFlexItem grow={false}>
											{addressBookDuoNoteIcon()}
										</EuiFlexItem>
										<EuiFlexItem>
											<TextInputEditor
												editorVal={subject}
												autocompletOptions={macroOptions}
												changeEditVal={handleSubject}
											/>
										</EuiFlexItem>
									</EuiFlexGroup>
								</EuiFlexItem>
							</EuiFlexGroup>
						</EuiFlexItem>
						<EuiFlexItem>
							<RelatedItem message={validationMessage} items={relateds} onAddItem={addRelateds} />
						</EuiFlexItem>
					</EuiFlexGroup>
				</EuiFlexItem>
			</EuiFlexGroup>
		</Wrapper>
	);
};

export default MessageSenderUrlLink;

/**
 * Styled Components
 */
const Wrapper = styled.div`
	.euiText {
		width: 40px;
	}
	.label {
		color: var(--light-color);
		font-size: 0.75rem;
		font-weight: 600;
		margin-right: 0.3rem;
		margin-top: 0.25rem;
	}
`;
